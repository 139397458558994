import React, { Component } from "react";
import _ from "lodash";
import * as Permissions from "../Permissions/PermissionValues";
import AuthService from "../Auth/AuthService";
import EmailService from "./EmailListService";
import chistory from "../Shared/history";
import MailingListTable from "./TableData/MailingListTable";
import MG_ReSubscriberModal from "./ModalPopups/MG_ReSubscriberModal";
import RemoveActionType from "./Enums/RemoveActionType";
import EmailConfigTables from "./Enums/EmailConfigTables";
import EmailListService from "./EmailListService";
import UnSubscribeModal from "../AdvanceSearch/SavedSearches/UnSubscribeModal";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "../Shared/auth-header";
class EmailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailLists: [],
      searchText: null,
      allEmailLists: [],
      loading: false,
      showUnsubscribeModal: false,
      showReSubscribeModal: false,
      listAddress: "",
      resubscribeEmail: "",
      unsubscribeEmail: "",
    };
    ///The method used Resubscribe
    this.confirmResubscriber = () => {
      this.confirmed = async () => {
        const {
          resubscribeEmail,
          removeActionType,
          listAddress,
          emailLists,
        } = this.state;

        const response = await EmailListService.ResubscribeUnBlockRecipient(
          removeActionType,
          resubscribeEmail,
          listAddress
        );
        if (response && response.status.toLowerCase() == "success") {
          let updatedList = emailLists.map((x) => {
            if (x.email_Address == listAddress) {
              return { ...x, subscribed: true };
            } else {
              return x;
            }
          });
          this.setState({ emailLists: updatedList });
          this.toggleResubscribersModal();
        }
      };

      return this;
    };
    // For Unsubscribe
    this.confirmUnsubcribe = () => {
      this.confirmed = () => {
        if (this.state.listAddress == "") {
          return false;
        }
        $(".loading").show();
        const url = "/api/Mailgun/UnsubscribeMemberFromList";
        const { unsubscribeEmail, listAddress, emailLists } = this.state;
        const body = {
          address: unsubscribeEmail,
          ListName: listAddress,
          subscribed: false,
        };

        axios.post(url, body).then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            let updatedList = emailLists.map((x) => {
              if (x.email_Address == listAddress) {
                return { ...x, subscribed: false };
              } else {
                return x;
              }
            });
            this.setState({ emailLists: updatedList });
            $(".loading").hide();
            toast.success(data.message);
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        });

        this.toggleUnsubscribersModal();
      };
      return this;
    };
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        chistory.push(`/admin/email-list/view/${row.id}`, {
          emailListDetails: { ...row },
        });
        //console.log("click rowEvents disabled in prod.");
      },
    };
  }

  componentDidMount = async () => {
    // checking if user have email list permissions then request the API.

    if (this.canViewEmailList()) {
      await this.refreshData();
    }
  };

  refreshData = async () => {
    const { loadAllEmails } = this.props;
    let loggedInMember = AuthService.getProfile().memberData.id;
    this.showSpinner();
    let data;
    // fetch the data from email list api
    if (loadAllEmails) {
      // All System Email
      data = await EmailService.GetEmailList();
    } else {
      // My Email List
      data = await EmailService.GetMyAllEmailList(loggedInMember);
    }
    // if we have appropriate data then set it in state
    if (data != null) {
      this.setState({
        emailLists: data,
        allEmailLists: data,
      });
    }
    // if we don't have appropriate data then empty the state
    else {
      this.setState({
        emailLists: [],
        allEmailLists: [],
      });
    }
    this.hideSpinner();
  };

  showSpinner = () => {
    //$(".loading").show();
    this.setState({ loading: true });
  };

  hideSpinner = () => {
    // $(".loading").hide();
    this.setState({ loading: false });
  };

  //used to changge the respective textbox states
  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  //for checking the email list view permissions
  canViewEmailList() {
    return AuthService.canView(Permissions.Email_Lists);
  }
  handleSearch = (val) => {
    let { allEmailLists, searchText } = this.state;
    let filteredData = [];
    //check if there is any allEmailLists exist in state date

    if (allEmailLists && !_.isEmpty(allEmailLists) && searchText) {
      //clone the allEmailLists object and apply the live searching on collection to filter the allEmailLists.

      filteredData = allEmailLists.map((m, idx) => ({
        ...m,
        rowNumber: idx,
      }));
      filteredData = filteredData.filter(
        (itemQ) =>
          _.toLower(itemQ.email_Title).includes(_.toLower(searchText || "")) ||
          _.toLower(itemQ.email_Address).includes(_.toLower(searchText || ""))
      );

      var uniqueMailingListAddresses = {};
      filteredData = filteredData.filter(function(entry) {
        if (uniqueMailingListAddresses[entry.rowNumber]) {
          return false;
        }
        uniqueMailingListAddresses[entry.rowNumber] = true;
        return true;
      });

      this.setState({
        emailLists: filteredData,
      });
    } else if (_.isEmpty(val)) {
      this.setState({
        emailLists: allEmailLists,
      });
    }
  };
  openConfirmUnsubscribeModal = (row) => {
    let emailAddress = row.address;
    let listAddress = row.email_Address;
    this.setState({
      showUnsubscribeModal: true,
      listAddress,
      unsubscribeEmail: emailAddress,
    });
  };
  openConfirmResubscribeModal = (row) => {
    let emailAddress = row.address;
    let listAddress = row.email_Address;
    this.setState({
      showReSubscribeModal: true,
      listAddress,
      resubscribeEmail: emailAddress,
      removeActionType: RemoveActionType.ReSubscribe,
    });
  };
  toggleResubscribersModal = () => {
    this.setState({
      showReSubscribeModal: !this.state.showReSubscribeModal,
    });
  };
  toggleUnsubscribersModal = () => {
    this.setState({
      showUnsubscribeModal: !this.state.showUnsubscribeModal,
    });
  };
  render() {
    // fetch data from data state
    //let data = _.cloneDeep(this.state.emailLists);
    let { emailLists, loading } = this.state;

    return (
      <>
        {//Chack user have permission to view email list page
        this.canViewEmailList() ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-lg-6">
                      {/*Heading*/}
                      <h4 className="text-uppercase page-title-search">
                        Email List
                      </h4>
                      {/*Search Form*/}
                      <div className="app-search mt-0">
                        <div className="form-group mb-0">
                          <input
                            type="text"
                            name="searchText"
                            //type="text"
                            id="searchText"
                            value={this.state.searchText}
                            onChange={this.handleTextChange}
                            onKeyUp={(e) => {
                              this.handleSearch(e.target.value);
                            }}
                            placeholder="Search email list"
                            className="form-control rounded border mb-1 w-100"
                          />
                          <button
                            type="button"
                            className="c-pointer text-secondary bg-transparent border-0"
                            onClick={(e) => {
                              this.handleTextChange(e);
                            }}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*End Of Search Form*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {/*Data Table*/}
                <MailingListTable
                  listData={emailLists}
                  loading={loading}
                  myUserId={AuthService.getProfile().memberData.id}
                  openConfirmUnsubscribeModal={this.openConfirmUnsubscribeModal}
                  openConfirmResubscribeModal={this.openConfirmResubscribeModal}
                  isAllListsTable={this.props.loadAllEmails}
                />
                {/*End Of Data Table*/}
              </div>
            </div>
            <MG_ReSubscriberModal
              email={this.state.resubscribeEmail}
              modal={this.state.showReSubscribeModal}
              confirm={this.confirmResubscriber}
              toggle={this.toggleResubscribersModal}
              removeActionType={RemoveActionType.ReSubscribe}
              confirmModelType={EmailConfigTables.DefaultMembers}
            />
            <UnSubscribeModal
              confirm={this.confirmUnsubcribe}
              modal={this.state.showUnsubscribeModal}
              toggle={this.toggleUnsubscribersModal}
              listName={this.state.listAddress}
            />
          </div>
        ) : (
          //if user don't have permission to view email list page
          <div className="text-center font-italic">
            You don't have permissions to view this page.
          </div>
        )}
      </>
    );
  }
}

export default EmailList;
