import React, { Component, Fragment } from "react";
import _ from "lodash";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import AuthService from "../../Auth/AuthService";
import Scope from "../Scope/Scope";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import Services from "../PermissionService";
import LinkedPositionContent from "../General/LinkedPositionContent";
import RolesModal from "../../Shared/RolesModal";
import { Link } from "react-router-dom";
import ConfirmLinkedPositionModal from "../General/ConfirmLinkedPositionModal";
const ButtonToNavigate = ({ title, history, color, classes }) => (
    <Button type="button" onClick={() => history.push({ pathname: "/admin/positions" })} color={color} className={classes}>
        {title}
    </Button>
);

class LinkedPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamId: this.props.teamId,
            CLPermissions: [],
            CLPermission:true,
            CLPositionName: "",
            CLTeamName: "",
            CLNewId: null,
            dataLoaded: false,
            check: true,
            isNational: this.props.isNational,
            positionId: this.props.positionId,
            linkedTeamData: null,
            alreadyLinkedPosition: [],
            confirmHeading: "ConfirmReLinkedPositionModal",
            linkedPositionTeams:[]
        };

        this.confirmLinkedPosition = this.confirmLinkedPosition.bind(this);
        this.confirmDeleteLinkedPosition = this.confirmDeleteLinkedPosition.bind(this);
        this.cancelButtonBottom = this.cancelButtonBottom.bind(this);
        this.loadLinkedPositionTeams = this.loadLinkedPositionTeams.bind(this);
        this.saveTeamButton = this.saveTeamButton.bind(this);
        this.handleManagedLinkedTeam = this.handleManagedLinkedTeam.bind(this);
        this.saveLinkedPosition = this.saveLinkedPosition.bind(this);
        this.cancelLinkedPosition = this.cancelLinkedPosition.bind(this);
    }
    ConvertPermissionToNewScheme(permissions) {
        let originalPermissions = [];
        const createPermissions = (permissions) => {
            permissions.map((item) => {
                originalPermissions.push(Object.assign({}, item));
                if (item.childs) {
                    createPermissions(item.childs);
                }
            });
        };
        createPermissions(permissions);
        return originalPermissions;
    }
    cancelButtonBottom = () => {
        return (
            <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.cancelLinkedPosition}
            >
                Cancel
            </button>

        );
    }
    saveLinkedPosition = async (e) => {
        let obj = {
            teamPositionID: this.state.positionId,
            teams: []
        };
        $(".loading").show();
        let linkedTeamData = _.cloneDeep(this.state.linkedPositionTeams);
        if (this.state.alreadyLinkedPosition.length > 0) {
            this.state.alreadyLinkedPosition.map(item => {
                let index = linkedTeamData.findIndex(itemL => itemL.teamId == item.id);
                if (index == -1) {
                    obj.teams.push({ teamLinkedPositionID: item.teamLinkedPositionID, teamID: item.id, isOwner: false,  isDeleted: true });
                }
                else if (index > -1) {
                    obj.teams.push({ teamLinkedPositionID: item.teamLinkedPositionID, teamID: item.id, isOwner: linkedTeamData[index].isOwner, isDeleted: false });
                    linkedTeamData.splice(index, 1);
                }
            });
        }
        if (linkedTeamData.length > 0) {
            
            linkedTeamData.map(item => {
                obj.teams.push({ teamLinkedPositionID: null, teamID: item.teamId, isOwner: item.isOwner, isDeleted: false });
            });    
        }
        
        let data = await Services.SaveLinkedPosition(obj);
        if (!_.isEmpty(data)) {
            if (data.statusCode == 200) {
                toast.success("Position linked successfully!");
                this.props.setReloader();
                this.cancelLinkedPosition();
            }
        }
        $(".loading").hide();
    }
    cancelLinkedPosition = () => {
        this.setState({ positionId: -1 });
        window.$("#LinkedPositionModal").modal("hide");
    }
    saveTeamButton = () => {
        return (
            <Fragment>
                <hr />
                <div className="row pt-3">
                    <div className="col-4">

                    </div>
                    <div className="col-8">
                        <div className="text-right">
                            {this.cancelButtonBottom()}
                            <button
                                onClick={this.saveLinkedPosition}
                                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                                type="submit"
                            >
                                Save
                            </button>

                        </div>
                    </div>

                </div>


            </Fragment>


        );

    }
    
    componentWillUnmount() {

    }
    componentDidMount() {
        this.loadLinkedPosition();
    }
  
    handleManagedLinkedTeam = async (e, operation) => {
        let newId = e.target.dataset.id;
        let CLTeamName = e.target.dataset.name;
        let isowner = e.target.dataset.isowner;
        let ids = this.state.linkedPositionTeams;
        if (newId) {
            let idIndex = ids.findIndex(x => x.teamId == newId);
            switch (operation) {
                case "select":
                    if (idIndex == -1) {
                        $(".loading").show();
                        let data = await Services.TeamPermissions(newId);
                        let dataPerm = await Services.PositionPermissions(this.props.positionId,newId)
                        if (data.statusCode == 200) {
                            let teamperm = this.ConvertPermissionToNewScheme(data.result);
                            let posperm = this.ConvertPermissionToNewScheme(dataPerm.result);
                            let exceedpermissions = [];

                            posperm.map((item, index) => {
                                if (item.isView) {
                                    if (!teamperm[index].isView) {
                                        exceedpermissions.push(teamperm[index].featureName + " for view.");
                                    }
                                }
                                if (item.isAdd) {
                                    if (!teamperm[index].isAdd) {
                                        exceedpermissions.push(teamperm[index].featureName + " for add.");
                                    }
                                }
                                if (item.isUpdate) {
                                    if (!teamperm[index].isUpdate) {
                                        exceedpermissions.push(teamperm[index].featureName + " for update.");
                                    }
                                }
                                if (item.isDelete) {
                                    if (!teamperm[index].isDelete) {
                                        exceedpermissions.push(teamperm[index].featureName + " for delete.");
                                    }
                                }
                            });
                            if (exceedpermissions.length > 0) {

                                this.setState({ CLPermission: true, CLPermissions: exceedpermissions, CLPositionName: this.props.positionName, CLTeamName: CLTeamName, CLNewId: { teamId: newId, isOwner: (isowner== "true")} });
                                window.$("#" + this.state.confirmHeading).modal({
                                    backdrop: 'static',
                                    keyboard: true,
                                    show: true
                                });
                                window.$("#" + this.state.confirmHeading).on('hidden.bs.modal', function () {
                                    if (!$("body").hasClass("modal-open")) {
                                        $("body").addClass("modal-open");
                                    }
                                });

                            }
                            else {
                                this.state.linkedPositionTeams.push({ teamId: newId, isOwner: (isowner == "true")});
                            }

                        }

                        $(".loading").hide();
                    }
                    break;


                case "delete":
                    let alreadyIndex = -1;
                    if (this.state.alreadyLinkedPosition.length > 0) {
                        alreadyIndex = this.state.alreadyLinkedPosition.findIndex(x => x.id == newId);
                    }
                    if (alreadyIndex != -1) {
                        this.setState({ CLPermission: false, CLPermissions: [], CLPositionName: this.props.positionName, CLTeamName: CLTeamName, CLNewId: { teamId: newId, isOwner: false } });
                        window.$("#" + this.state.confirmHeading).modal({
                            backdrop: 'static',
                            keyboard: true,
                            show: true
                        });
                        window.$("#" + this.state.confirmHeading).on('hidden.bs.modal', function () {
                            if (!$("body").hasClass("modal-open")) {
                                $("body").addClass("modal-open");
                            }
                        });

                    }
                    else if (idIndex != -1 && alreadyIndex == -1) {
                        this.state.linkedPositionTeams.splice(idIndex, 1);
                    }
                    break;
                case "owner":
                    
                    if (idIndex != -1) {
                        this.state.linkedPositionTeams[idIndex].isOwner = (isowner == "true");
                    }
                    break;

                default:
            }



            this.setState({ linkedPositionTeams: this.state.linkedPositionTeams });

        }

    }
    componentWillReceiveProps(nextProps) {
        if (this.state.positionId != nextProps.positionId) {
            this.state.positionId = nextProps.positionId;
            this.setState({ positionId: nextProps.positionId });
            this.loadLinkedPositionTeams();
        }
        if (this.props.isNational != nextProps.isNational || this.props.teamId != nextProps.teamId) {
            this.state.isNational = nextProps.isNational
            this.state.teamId = nextProps.teamId;
            this.state.linkedTeamData = null;
            this.setState({ isNational: nextProps.isNational, teamId: nextProps.teamId });

            this.loadLinkedPosition();
        }

    }
    async loadLinkedPosition() {
        if (!this.state.linkedTeamData) {
            let data = null;
            
            data = await Services.LoadLinkedTeams({ teamID: this.state.teamId });        
            
            if (data) {

                if (data.statusCode == 200) {
                    this.setState({ linkedTeamData: data.result, dataLoaded: true });
                }
            }
        }

    }
    async loadLinkedPositionTeams() {
        
        let data = await Services.LinkedPositionTeams(this.state.positionId);
        if (data) {
            if (data.statusCode == 200) {
                let positions = [];
                if (data.result.length > 0) {
                    data.result.map(item => {
                        positions.push({ teamId: item.id, isOwner: item.isOwner });
                    }
                    )
                }
                this.setState({ linkedPositionTeams: positions, alreadyLinkedPosition: data.result });
                return true;
            }
        }
        
    }
    linkedPosition() {
        if (this.state.dataLoaded) {
                    return (
                        <LinkedPositionContent teamData={this.state.linkedTeamData} positionMemberCount={this.props.positionMemberCount} linkedPosition={this.state.linkedPositionTeams} handleManagedLinkedTeam={this.handleManagedLinkedTeam} positionName={this.props.positionName} />
                    );
        }
    }
    confirmLinkedPosition() {
        if (this.state.CLNewId != null && this.state.CLNewId.teamId) {
            this.state.linkedPositionTeams.push(this.state.CLNewId);
            this.setState({ linkedPositionTeams: this.state.linkedPositionTeams, CLNewId: null });

        }
        window.$("#" + this.state.confirmHeading).modal("hide");

    }
    confirmDeleteLinkedPosition() {
        if (this.state.CLNewId != null && this.state.CLNewId.teamId) {
            let idIndex = this.state.linkedPositionTeams.findIndex(x => x.teamId == this.state.CLNewId.teamId);
            this.state.linkedPositionTeams.splice(idIndex, 1);
            this.setState({ linkedPositionTeams: this.state.linkedPositionTeams, CLNewId: null });

        }
        window.$("#" + this.state.confirmHeading).modal("hide");

    }
    render() {
        return (
            <AUX>
                <div
                    class="modal fade"
                    id="LinkedPositionModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="CreateTeamModalTitle"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-header bg-secondary py-2">
                                <h5 class="modal-title text-uppercase text-white mt-0" id="CreateTeamModalTitle">
                                    LINK POSITION
                                </h5>
                                <button
                                    type="button"
                                    class="close text-white"
                                    data-dismiss="modal"
                                    onClick={this.cancelLinkedPosition}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">

                                {this.linkedPosition()}
                                {this.saveTeamButton()}
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.CLPermission) ? (
                    <ConfirmLinkedPositionModal PositionName={this.state.CLPositionName} Heading={this.state.confirmHeading} TeamName={this.state.CLTeamName} Permissions={this.state.CLPermissions} confirmLinkedPosition={this.confirmLinkedPosition} permission={this.state.CLPermission} />
                ) : (<ConfirmLinkedPositionModal PositionName={this.state.CLPositionName} Heading={this.state.confirmHeading} TeamName={this.state.CLTeamName} Permissions={this.state.CLPermissions} confirmLinkedPosition={this.confirmDeleteLinkedPosition} permission={this.state.CLPermission} />
                        )}
                
            </AUX>
        );
    }
}

export default LinkedPosition;
