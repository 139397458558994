import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import AuthService from "../../Auth/AuthService";
import Scope from "../Scope/Scope";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import RolesModal from "../../Shared/RolesModal";
import { Link } from "react-router-dom";
import history from '../../Shared/history';
import Services from "../../Permissions/PermissionService";
import _ from "lodash";
const ButtonToNavigate = ({ title, history, color, classes }) => (
  <Button
    type="button"
    onClick={() => history.push({ pathname: "/admin/positions" })}
    color={color}
    className={classes}
  >
    {title}
  </Button>
);

const modalHeadings = {
  Orignal: "Create New Team",
  Permissions: "Configure Permissions",
  Member: "Assign Member"
};
class ViewTeam extends Component {
  constructor(props) {
    super(props);
    console.log("EditTeam");
    console.log(props);
    this.state = {
        teamPositionId: this.props.viewTeamId,
      check: true,
      position: [
        {
          positionName: "",
          permissionConfig: "",
          Owner: true,
          scopeType: 1
        }
        ],
        teamList:[],
      mockup_position_scope: 1,
      name: "",
      teamName: ""
    
    };

    this.viewTeam = this.viewTeam.bind(this);
   // this.handleSelectTeam = this.handleSelectTeam.bind(this);
    this.loadTeam = this.loadTeam.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.viewTeamId != nextProps.viewTeamId) {
            this.state.teamPositionId = nextProps.viewTeamId;
            this.setState({ teamPositionId: this.state.teamPositionId });
            this.getTeams();
        }
        else {
            if (this.props.openModal) {
                this.openModal();
            }
            
        }
    }
    componentWillMount() {
        if (this.props.viewTeamId>0) {
            this.getTeams();
        }
    }
    openModal() {
        
        window.$("#ViewTeamModal").modal("show");
        this.props.closeAllModal();
    }
    async getTeams() {
        $(".loading").show();
        let data = await Services.ViewTeam(this.props.memberId, this.state.teamPositionId);
        if (!_.isEmpty(data)) {
            this.setState({
                teamList: data.result
            });
            this.openModal();    
        }
        $(".loading").hide();

    }
  loadTeam = () => {
    const TeamData = this.state.teamList;
    return (
      <Fragment>
        <div id="TeamList">
          <ul className="w-100">
            {TeamData.map((item, index) => {
              return (
                <li
                  data-id={item.id}
                  className="d-md-flex px-4 border py-2 card-text w-100"
                  
                >
                      <div className="pr-md-1" data-id={item.id}>{item.teamName}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </Fragment>
    );
  };

  viewTeam = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            Following are the on which this position has been assign.
          </div>
          <div className="col-12 mb-2">
            <h5 className="text-primary font-weight-bold">Teams</h5>
          </div>
          <div id="memberList" className="col-lg-12">
            {this.loadTeam()}
          </div>
        </div>
      </Fragment>
    );
  };
  componentWillUnmount() {}
  componentDidMount() {
    //this.getPositions();
  }

  render() {
    return (
      <AUX>
        <div
          class="modal team-style fade"
          id="ViewTeamModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="ViewTeamModal"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5
                  class="modal-title text-uppercase text-white mt-0"
                  id="CreateTeamModalTitle"
                >
                  VIEW TEAM
                </h5>

                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body py-4">
                <div>{this.viewTeam()}</div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default ViewTeam;
