import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class AlreadyRegisteredModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showModal: false,
    });
  };
  render() {
    return (
      <Modal
        className="AlreadyRegister"
        show={this.props.showModal}
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="modal-title mt-0"> INFORMATION </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="row">
              <div className="col-9"></div>
              <h6>It appears you have already registered, please Login.</h6>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/login">
            <Button renderAs="button">
              <span>Login</span>
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }
}
