import React, { Component, Fragment } from "react";
import {
  Card,
  Collapse,
  CardImg,
  CardText,
  CardBody,
  Spinner,
} from "reactstrap";
import $ from "jquery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import axios from "../../Shared/auth-header";
import actions from "../../../store/facility/action";
import paginationFactory from "react-bootstrap-table2-paginator";
import Services from "../../Permissions/PermissionService";
import _ from "lodash";
import moment from "moment";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import HistoricMembersModel from "./Modals/HistoricMembersModel";
import OfficerButton from "./OfficerButton";
export class RepresentativesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 5,
      totalCount: 0,
      rows: [],
      regionMemberPosition: [],
      expanded: [],
      nonExpand: [],
      region: null,
      positionData: [],
      showHideHistoricMemberText: "Show Representative History",
      showHistoricMembers: false,
      completePositionData: [],
      inActiveMembers: [],
      historicModelToggle: false,
      isConfirmModalOpen: false,
    };
    this.expandRow = {
      renderer: (row) => (
        <React.Fragment>
          {this.state.regionMemberPosition.map((item, index) => (
            <div
              key={"expend-" + row.id + "-" + index}
              className="d-flex px-0 w-100 border-top border-bottom"
            >
              <div className="px-3 py-2 border-right" style={{ width: "30%" }}>
                {item.positionName}
              </div>
              <div className="px-3 py-2 border-left" style={{ width: "30%" }}>
                <Link
                  to={{
                    pathname: "/directory/userAction/" + item.memberID,
                  }}
                >
                  {item.memberName}
                </Link>
                {/* {item.memberName} */}
              </div>
              <div className="px-3 py-2 border-left" style={{ width: "40%" }}>
                {item.email}
              </div>
            </div>
          ))}
        </React.Fragment>
      ),
      nonExpandable: [0],
      expanded: [1],
      onExpand: (row, isExpand, rowIndex, e) => { },
      //nonExpandable: this.state.nonExpand
    };
    this.rowClasses = (row, rowIndex) => {
      let classes = null;

      if (row.memberName == "regionData") {
        classes = "bg-dark text-white";
        if (this.state.expanded.findIndex((x) => x == rowIndex) == -1) {
          this.state.expanded.push(rowIndex);
          this.setState({ expanded: this.state.expanded });
        }
      } else {
        if (this.state.nonExpand.findIndex((x) => x == rowIndex) == -1) {
          this.state.nonExpand.push(rowIndex);
          this.setState({ nonExpand: this.state.nonExpand });
        }
      }
      return classes;
    };
    this.columns = [
      {
        text: "Position",
        dataField: "positionName",
        headerAttrs: {
          width: "30%",
        },
        sort: true,
        formatter: (cell, row) => {
          return cell;
        },
      },
      {
        text: "Member Name",
        dataField: "memberName",
        sort: true,
        headerAttrs: {
          width: "30%",
        },
        formatter: (cellContent, row) => {
          const currentDate = new Date();
          const dateToCompare = new Date(row.startDate);
          let isDateGreater = false;
          if (dateToCompare > currentDate) {
            isDateGreater = true;
          }
          return (
            <div>
              {row.memberID != null ? (
                <Link
                  to={{
                    pathname: "/directory/userAction/" + row.memberID,
                  }}
                >
                  {row.memberName}{" "}
                  {isDateGreater && (
                    <>
                      (
                      {moment(row.startDate.split("T")[0]).format("MM/DD/YYYY")}
                      )
                    </>
                  )}
                </Link>
              ) : (
                <span>Unassigned</span>
              )}
            </div>
          );
        },
      },
      {
        text: "Email",
        dataField: "email",
        hidden: !AuthService.canFacilityView(
          PermissionFeature.FacilityRepresentativesEmail
        ),
        headerAttrs: {
          width: "40%",
        },
        sort: true,
        formatter: (cell, row) => {
          return cell;
        },
      },
    ];
    this.RemotePagination = this.RemotePagination.bind(this);
    this.sizePerPageChange = this.sizePerPageChange.bind(this);
    this.handleHistoricMembers = this.handleHistoricMembers.bind(this);
    this.toggleSearchModel = this.toggleSearchModel.bind(this);
  }

  toggleSearchModel = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
      historicModelToggle: !this.state.historicModelToggle,
    });
  };

  handleHistoricMembers = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ historicModelToggle: true, isConfirmModalOpen: true });
  };

  async getRepresentatives() {
    if (this.props.facilityId && this.props.facilityId != undefined) {
      $(".loading").show();
      let data = await Services.FacilityMembers(this.props.facilityId);

      if (!_.isEmpty(data)) {
        let Data = _.filter(data.result.members, function (o) {
          return o.nothistory;
        });

        let inActive = _.filter(data.result.members, function (o) {
          return !o.nothistory;
        });

        let sortedInActiveMembers = _.orderBy(inActive, ["endDate"], ["asc"]);

        await this.setState({
          completePositionData: data.result.members,
          positionData: Data,
          region: data.result.region,
          regionMemberPosition: data.result.regionMembers,
          inActiveMembers: sortedInActiveMembers,
        });
      }
      $(".loading").hide();
    } else {
      await this.setState({
        positionData: [],
        region: null,
        regionMemberPosition: [],
      });
    }
  }

  sizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPagePositions: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage
    // rowEvents
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
    };
    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowClasses={this.rowClasses}
          expandRow={this.expandRow}
        />
      </div>
    );
  }
  componentDidUpdate(prevProps) {
    if (this.props.facilityId != prevProps.facilityId) {
      this.getRepresentatives();
    }
  }
  componentDidMount() {
    this.getRepresentatives();
  }
  render() {
    return (
      (!_.isEmpty(this.state.positionData) ||
        !_.isEmpty(this.state.regionMemberPosition)) &&
      AuthService.canFacilityView(
        PermissionFeature.FacilityRepresentatives
      ) && (
        <Card>
          <h5
            className="c-pointer card-header"
            onClick={(e) => {
              this.props.setFacilityCardsVisibility(
                !this.props.facilityDashboardState.IsOpenFacilityCards
              );
            }}
          >
            <div className="row">
              <div className="col-sm-12 col-lg-5">
                <i class="fas fa-sitemap mr-2"></i>
                Representatives
              </div>
              <div className="col-sm-12 col-lg-7 pt-md-0 pt-lg-0 pt-xl-0 pt-2">
                <button
                  onClick={this.handleHistoricMembers}
                  style={{ float: "right" }}
                  className="btn btn-success btn-sm"
                >
                  <span class="d-none d-md-inline">
                    {this.state.showHideHistoricMemberText}
                  </span>
                  <span class="d-md-none">Show Rep. History</span>
                </button>
                <OfficerButton class="inline-block px-0 float-right pr-1" />
              </div>
            </div>
          </h5>
          <Collapse
            isOpen={this.props.facilityDashboardState.IsOpenFacilityCards}
          >
            {!this.props.facilityId && (
              <CardBody style={{ padding: "4rem" }}>
                <Spinner className="spinnerCard" />
              </CardBody>
            )}

            {this.props.facilityId && (
              <CardBody
                className="p-0"
                style={{ minHeight: 150, maxheight: 300, overflowY: "auto" }}
              >
                {!_.isEmpty(this.state.positionData) &&
                  this.state.positionData.map((item, index) => (
                    <div
                      key={"expend-" + item.id + "-" + index}
                      className="d-flex px-0 w-100 border-top border-bottom"
                    >
                      <div
                        className="px-3 py-1 border-right"
                        style={{
                          width: AuthService.canFacilityView(
                            PermissionFeature.FacilityRepresentativesEmail
                          )
                            ? "30%"
                            : "50%",
                        }}
                      >
                        {item.positionName}
                      </div>
                      <div
                        className="px-3 py-1 border-left"
                        style={{
                          width: AuthService.canFacilityView(
                            PermissionFeature.FacilityRepresentativesEmail
                          )
                            ? "30%"
                            : "50%",
                        }}
                      >
                        <Link
                          to={{
                            pathname: "/directory/userAction/" + item.memberID,
                          }}
                        >
                          {item.memberName}
                        </Link>
                        {/* {item.memberName}{" "} */}
                        {new Date(item.startDate) > new Date() && (
                          <>
                            (
                            {moment(item.startDate.split("T")[0]).format(
                              "MM/DD/YYYY"
                            )}
                            )
                          </>
                        )}
                      </div>
                      {AuthService.canFacilityView(
                        PermissionFeature.FacilityRepresentativesEmail
                      ) && (
                          <div
                            className="px-3 py-1 border-left"
                            style={{ width: "40%" }}
                          >
                            {item.email}
                          </div>
                        )}
                    </div>
                  ))}
                {this.state.region && (
                  <div className="d-flex px-0 w-100 bg-dark text-white border-top border-bottom">
                    <div className="px-3 py-1" style={{ width: "100%" }}>
                      {this.state.region}
                    </div>
                  </div>
                )}
                {!_.isEmpty(this.state.regionMemberPosition) &&
                  this.state.regionMemberPosition.map((item, index) => (
                    <div
                      key={"expendPosition-" + item.id + "-" + index}
                      className="d-flex px-0 w-100 border-top border-bottom"
                    >
                      <div
                        className="px-3 py-1 border-right"
                        style={{
                          width: AuthService.canFacilityView(
                            PermissionFeature.FacilityRepresentativesEmail
                          )
                            ? "30%"
                            : "50%",
                        }}
                      >
                        {item.positionName}
                      </div>
                      <div
                        className="px-3 py-1 border-left"
                        style={{
                          width: AuthService.canFacilityView(
                            PermissionFeature.FacilityRepresentativesEmail
                          )
                            ? "30%"
                            : "50%",
                        }}
                      >
                        <Link
                          to={{
                            pathname: "/directory/userAction/" + item.memberID,
                          }}
                        >
                          {item.memberName}
                        </Link>
                        {/* {item.memberName} */}
                      </div>
                      {AuthService.canFacilityView(
                        PermissionFeature.FacilityRepresentativesEmail
                      ) && (
                          <div
                            className="px-3 py-1 border-left"
                            style={{ width: "40%" }}
                          >
                            {item.email}
                          </div>
                        )}
                    </div>
                  ))}
                <HistoricMembersModel
                  modal={this.state.isConfirmModalOpen}
                  InActiveMembers={this.state.inActiveMembers}
                  toggle={this.toggleSearchModel}
                />
              </CardBody>
            )}
          </Collapse>
        </Card>
      )
    );
  }
}

export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(RepresentativesCard);
