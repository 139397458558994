import React, { Component } from "react";
import AddRoleBody from "./AddRoleBody";

class AddRoleFirstLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feature: this.props.feature,
            updateMethods: this.props.updateMethods
        };
    }

    changeMenuPerms = e => {
        this.state.feature.hasAccess = e.target.checked;
        this.state.updateMethods().updateFeatures(this.state.feature);
    };
    render() {
        return (
            <div className="acc-body mt-2">
                <div className="table-rep-plugin">
                    <div className="table-responsive mb-0">
                        <table className="table no-t-border table-hover">
                            <thead>
                                <tr>
                                    <th />
                                    <th className="text-center">View</th>
                                    <th className="text-center">Add</th>
                                    <th className="text-center">Update</th>
                                    <th className="text-center">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr data-row={this.state.feature.featuretaskid}>
                                    <td>Menu</td>
                                    <td width="70">
                                        <input
                                            type="checkbox"
                                            id={this.state.feature.featuretaskid}
                                            switch="bool"
                                            onChange={this.changeMenuPerms}
                                            checked={this.state.feature.hasAccess}
                                        />
                                        <label htmlFor={this.state.feature.featuretaskid} data-on-label="Yes" data-off-label="No" />
                                    </td>
                                    <td width="70" />
                                    <td width="70" />
                                    <td width="70" />
                                </tr>

                                <AddRoleBody
                                    featureId={this.state.feature.featuretaskid}
                                    data={this.state.feature.subFeatures}
                                    updateMethods={this.props.updateMethods}
                                />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddRoleFirstLevel;
