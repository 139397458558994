import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import * as $AB from "jquery";
import axios from "../../Shared/auth-header";
import authAxios from "../../Shared/auth-header";
import Facilities from "./Facilities";
import ConfirmDeleteModalFacility from "../../Shared/DeleteModalFacility";
import RegionModal from "../../Shared/RegionModal";
import { toast } from "react-toastify";
import TypeModal from "../../Shared/TypeModal";
import Areas from "../Areas/Areas";
import Users from "../../Admin/Users/Users";
import AuthService from "../../Auth/AuthService.js";
import RFService from "../RFService";
import _ from "lodash";
import $ from "jquery";
import { Button, Row, Col } from "reactstrap";
import FacilityCategories from "../../Dashboard/FacilityDashboard/FacilityCategories";
import Service from "../../Dashboard/FacilityDashboard/FacilityDashoardService";
import OfficerModal from "../../Dashboard/FacilityDashboard/Modals/OfficerModal";
import FacilityInformationCard from "../../Dashboard/FacilityDashboard/FacilityInformationCard";
import RepresentativesCard from "../../Dashboard/FacilityDashboard/RepresentativesCard";
import actions from "../../../store/facility/action";
import Directory from "../Directory";
import "../../Dashboard/FacilityDashboard/facilityDashboard.css";

import {
  oshaTierOptions,
  oshaOwnerOptions,
  facilityLevelOptions,
  facilityRegionsOptions,
  facilityTypeOptions,
  SRF12Numbers,
  facilityStatusOptions,
} from "./facilitySelectOptions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import PermissionFeature from "../../Permissions/PermissionFeature";

const ButtonToNavigate = ({ title, history, color, classes, location }) => (
  <Button
    type="button"
    onClick={() =>
      history.push({
        pathname: "/directory/facility",
        tab: Facilities,
        name: "Facilities",
        keyword: location.keyword,
      })
    }
    color={color}
    className={classes}
  >
    {title}
  </Button>
);

class EditFacility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.location.type,
      uid: this.props.location.uid,
      //Pending Officers Positions request
      pendingrequests: [],
      code: "",
      description: "",
      region: "",
      id: 0,
      showLevel: true,
      regionid: 0,
      facilitytype: "",
      facilitytypeid: 0,
      isFacilityActive: true,
      regionModal: false,
      facility: "",
      headerLabel: "",
      BUEData: {},
      facilityRegionId: "",
      employerList: [],
      notificationCall: false,
      oshatier: "",
      facilitystatus: "",
      oshamaintainer: "",
      srfrxiistatus: "",
      level: "",
      type: "",
      facility_edit_region: "",
      latitude: "",
      longitude: "",
      defaultAccordion: "",
    };
    this.regionMethods = () => {
      this.updateRegion = (args) => {
        this.setState({ region: args.code, regionid: args.id });
        $AB(".cancelModal").trigger("click");
      };

      return this;
    };
    this.updateFacilityType = (args) => {
      this.setState({
        facilitytype: args.code,
        facilitytypeid: args.id,
      });
      $AB(".cancelModal").trigger("click");
    };
    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.uid == 0) {
          return false;
        }

        authAxios
          .delete("/api/facilities/" + this.state.uid)
          .then(({ data }) => {
            if (data.status && data.status == "Success") {
              toast.success("Facility Deleted Successfuly.");
              //this.setState({ delId: 0 });
              //$(".cancelModal").trigger("click");
              //this.getFacilitites();
              this.props.history.push("/directory/facility");
            } else {
              toast.error(data.message);
            }
            $(".cancelModal").trigger("click");
          })
          .catch(function(error) {
            console.log(error);
            $(".cancelModal").trigger("click");
          });
      };
      return this;
    };
    this.setNotificationCall = this.setNotificationCall.bind(this);
    this.ShowLevel = this.ShowLevel.bind(this);
    this.loadEmployerList = this.loadEmployerList.bind(this);
  }

  handleChange = (evt) => {
    if (evt.target.id == "isFacilityActive") {
      this.setState({ [evt.target.name]: evt.target.checked });
    } else {
      this.setState({ [evt.target.name]: evt.target.value });
    }
  };
  changeValue(e, type) {
    // debugger
    //  const value = e.target.value;

    const value = e.target.textContent;
    if (type == "type") {
      this.ShowLevel(value);
    }
    if (value != "") {
      const nextState = {};
      nextState[type] = value;
      this.setState(nextState);
    } else {
      const emptyState = {};
      emptyState[type] = "";
      this.setState(emptyState);
    }
  }
  handleSubmit = (evt) => {
    evt.preventDefault();

    const allrefs = this.refs;
    const isSubmit = allrefs.editFacilityMatForm.submit();

    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      return false;
    }

    allrefs.editFacilityMatForm
      .isFormValid()
      .then((isTrue) => {
        // console.log(isTrue);
        if (!isTrue) {
          return false;
        } else {
          this.saveFacility();
        }
      })
      .catch((message) => {
        console.log(message);
      });

    //evt.preventDefault();
  };

  saveFacility() {
    let selectedRegion = 0;
    let selectedFacilityType = 0;
    try {
      selectedRegion =
        this.state.facility_edit_region != "" &&
        this.props.facilityDashboardState.facilityRegionOptions.find((el) => {
          return el.description === this.state.facility_edit_region;
        }).id;
      selectedFacilityType =
        this.state.type != "" &&
        this.props.facilityDashboardState.facilityTypeOptions.find((el) => {
          return el.description === this.state.type;
        }).id;
    } catch (err) {
      console.log(err);
    }

    if (this.state.latitude < -90 || this.state.latitude > 90) {
      toast.error("Latitude must be between -90 and 90 degrees inclusive.");
      return false;
    } else if (this.state.longitude < -180 || this.state.longitude > 180) {
      toast.error("Longitude must be between -180 and 180 degrees inclusive.");
      return false;
    }

    const data = {
      Id: this.state.id,
      code: this.state.code,
      description: this.state.description,
      region: this.state.region,
      isActive: this.state.isFacilityActive,
      afdfilename: this.state.afdfilename,
      level: this.state.level,
      srfrxiistatus: this.state.srfrxiistatus,
      owner: this.state.oshamaintainer,
      status: this.state.facilitystatus,
      oshatier: this.state.oshatier,
      regionid: selectedRegion,
      facilitytypeid: selectedFacilityType,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      lmivnumber: this.state.lm4No,
    };
    axios
      .post("/api/Facilities/SaveFacility", data)
      .then(({ data }) => {
        if (data.status && data.status == "Success") {
          toast.success(data.message);
          this.props.history.push({
            pathname: "/directory/facility",
            tab: Facilities,
            name: "Facilities",
          });
        } else {
          toast.error(data.message);
        }
        //$('.modal-backdrop').hide();
        this.props.forcelyCloseModal("EditFacilityModal");
      })
      .catch(function(error) {
        //$('.modal-backdrop').hide();
        this.props.forcelyCloseModal("EditFacilityModal");
        console.log(error);
      });
  }
  pendingOfficerRequest = async () => {
    let id = this.state.id > 0 ? this.state.id : this.props.match.params.id;
    let data = null;
    if (AuthService.canSPView(PermissionFeature.OfficerUpdate, undefined, id)) {
      data = await Service.GetPendingOfficersPositionsRequest(id);
    }
    if (data && data != null && !_.isEmpty(data)) {
      this.setState({ pendingrequests: data });
      this.props.showPendingRequestAlert(true);
    } else {
      this.setState({ pendingrequests: [] });
      this.props.showPendingRequestAlert(false);
    }
  };
  setNotificationCall(val) {
    this.setState({ notificationCall: val });
  }
  async loadEmployerList() {
    let result = await RFService.EmployerList();
    if (result != null) {
      this.setState({ employerList: result });
    }
  }
  componentDidMount = async () => {
    this.props.setStartupFacilityActive();

    this.props.getFacilityOptions();

    let url = "";
    this.loadEmployerList();
    $AB(".loading").show();
    var notificationCall = this.props.location.state
      ? this.props.location.state.notificationCall
      : false;
    if (notificationCall) {
      await this.setState({ notificationCall: notificationCall });
      this.props.showOfficerModal(true);
    }
    if (!_.isEmpty(this.props.location)) {
      if (this.props.location.uid !== undefined) {
        url = "/api/Facilities/" + this.props.location.uid;
      } else if (
        this.props.history.location.backRef !== undefined &&
        this.props.history.location.backRef.uid !== undefined
      ) {
        url = "/api/Facilities/" + this.props.history.location.backRef.uid;
      } else if (this.props.match.params.id !== undefined) {
        url = "/api/Facilities/" + this.props.match.params.id;
      } else if (_.isEmpty(this.props.location.uid)) {
        this.props.history.push("/directory/facility");
      }
    }

    //  this.pendingOfficerRequest();

    axios.get(url).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        let region =
          data.data.regionid != null &&
          data.data.regionid != "" &&
          data.data.regionid != 0
            ? data.data.regionid
            : null;
        let facility =
          data.data.id != null && data.data.id != "" && data.data.id != 0
            ? data.data.id
            : null;
        sessionStorage.setItem(
          "facilitypermissions",
          JSON.stringify({ regionId: region, facilityId: facility })
        );
        this.ShowLevel(data.data.facilitytypeid);
        this.setState({
          id: data.data.id,
          code: data.data.code,
          description: data.data.description,
          region: data.data.region,
          regionid: data.data.regionid,
          facilitytype: data.data.facilitytype,
          facilitytypeid: data.data.facilitytypeid,
          facility: data.data.code,
          isFacilityActive: data.data.isActive,
          afdfilename: data.data.afdfilename,
          headerLabel: data.data.description + " - " + data.data.code + "  ",
          facilityInfoCard: data.data.facilityInfoCard,
          lm4No: data.data.lmivnumber,
          oshatier: data.data.oshatier,
          facilitystatus: data.data.status ? data.data.status : "Represented",
          oshamaintainer: data.data.owner,
          srfrxiistatus: data.data.srfrxiistatus,
          level: data.data.level,
          type: data.data.facilityDescription,
          facility_edit_region: data.data.regionDescription,
          latitude: data.data.latitude,
          longitude: data.data.longitude,
        });

        this.props.setVFRMapKey(data.vfrMapKey);

        //   debugger
        if (data.data.facilityInfoCard.phyAddresses.length > 0) {
          this.props.setFacilityPhyAddress(
            data.data.facilityInfoCard.phyAddresses
          );
        }

        if (data.data.facilityInfoCard.mailingAddresses.length > 0) {
          this.props.setFacilityMailingAddress(
            data.data.facilityInfoCard.mailingAddresses
          );
        }

        if (data.data.facilityInfoCard.phoneNumbers.length > 0) {
          this.props.setFacilityPhoneNumbers(
            data.data.facilityInfoCard.phoneNumbers
          );
        }

        if (data.data.facilityInfoCard.mailingAddresses.length === 0) {
          this.props.resetMailingAddresses();
        }

        if (data.data.facilityInfoCard.phyAddresses.length === 0) {
          this.props.resetPhysicalAddresses();
        }

        if (data.data.facilityInfoCard.phoneNumbers.length === 0) {
          this.props.resetPhoneNumbers();
        }

        const { location } = this.props;
        const searchParams = new URLSearchParams(location.search);
        const accordionParam = searchParams.get("defaultAccordion");

        // Check if the query parameter specifies an accordion to open
        if (accordionParam) {
          this.setState({ defaultAccordion: accordionParam });
        }
      } else {
        $AB(".loading").hide();
        toast.error("An Error has occurred.");
      }
    });
    if (this.state.code == "") $AB(".loading").hide();
  };
  ShowLevel(value) {
    if (
      value != undefined &&
      (value == "Federal Contract Tower (FCT)" ||
        value == "DoD Facility" ||
        value == 5 ||
        value == 11)
    ) {
      this.setState({ showLevel: false, level: "" });
    } else {
      this.setState({ showLevel: true });
    }
  }
  componentWillUnmount() {
    if (this.props.history.action === "POP") {
      if (this.props.location.backRef) {
        this.props.history.location.keyword = this.props.location.keyword;
        this.props.history.location.page = this.props.location.page;
        this.props.history.location.uid = this.props.location.backRef.uid;
      } else {
        this.props.history.location.keyword = this.props.location.keyword;
        this.props.history.location.page = this.props.location.page;
        this.props.history.location.tab = Facilities;
        this.props.history.location.name = "Facilities";
      }
    }
    let commingFromBucketSearch =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname.includes("/directory/myfilters");
    if (
      commingFromBucketSearch == undefined ||
      commingFromBucketSearch == false
    ) {
      $(".modal-backdrop").length && $(".modal-backdrop").remove();
    }
  }
  handleEditMaterialSubmit = () => {};
  render() {
    let facId = 0;
    if (!_.isEmpty(this.props.location)) {
      if (this.props.location.uid !== undefined) {
        facId = this.props.location.uid;
      } else if (this.props.match.params.id !== undefined) {
        facId = this.props.match.params.id;
      }
    }

    return (
      <AUX>
        <Directory {...this.props} />

        <div className="container-fluid">
          <br />
          <div className="row">
            <div className="container-fluid">
              <Row xl={12} lg={12} md={12} sm={12} xs={12}>
                {AuthService.canFacilityView(
                  PermissionFeature.FacilityDetails
                ) && (
                  <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                    <FacilityInformationCard
                      facilityName={this.state.headerLabel}
                      facilityInfo={this.state.facilityInfoCard}
                      facilityId={facId}
                    />
                  </Col>
                )}
                {AuthService.canFacilityView(
                  PermissionFeature.FacilityRepresentatives
                ) && (
                  <Col xl={7} lg={7} md={6} sm={12} xs={12}>
                    <RepresentativesCard facilityId={facId} />
                  </Col>
                )}
              </Row>

              <Row>
                <FacilityCategories
                  facilityId={facId}
                  his={this.props.history}
                  facility={this.state.facility}
                  defaultAccordion={this.state.defaultAccordion}
                />
              </Row>
            </div>
            <OfficerModal
              Id={this.state.id}
              notificationCall={this.state.notificationCall}
              setNotificationCall={this.setNotificationCall}
            />
            <ConfirmDeleteModalFacility confirm={this.confirm} />
            {/* <TypeModal typeMethods={this.updateFacilityType} type="FacilityType" label="Facility Type" /> */}
            {/* {this.state.regionModal && <RegionModal regionMethods={this.regionMethods} />} */}

            {/* Edit Facility modal */}
            <div
              className="modal fade"
              id="EditFacilityModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="EditFacilityModalTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="EditFacilityModalTitle">
                      {" "}
                      Edit Facility{" "}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <form
                    className="needs-validation"
                    onSubmit={this.handleSubmit}
                    noValidate
                  >
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12">
                          <Fragment>
                            <div className="row">
                              {AuthService.canFacilityView(
                                PermissionFeature.FacilityCode
                              ) && (
                                <div className="col-4">
                                  <div className="form-group">
                                    <MDBInput
                                      value={this.state.code}
                                      name="code"
                                      onChange={this.handleChange}
                                      label="Facility Code"
                                      group
                                      type="text"
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityCode
                                        )
                                      }
                                      required
                                    >
                                      <div className="invalid-feedback">
                                        Code is required.
                                      </div>
                                    </MDBInput>
                                  </div>
                                </div>
                              )}
                              {AuthService.canFacilityView(
                                PermissionFeature.FacilityDescription
                              ) && (
                                <div className="col-4">
                                  <div className="form-group">
                                    <MDBInput
                                      value={this.state.description}
                                      name="description"
                                      onChange={this.handleChange}
                                      label="Description"
                                      group
                                      type="text"
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityDescription
                                        )
                                      }
                                      required
                                    >
                                      <div className="invalid-feedback">
                                        Description is required.
                                      </div>
                                    </MDBInput>
                                  </div>
                                </div>
                              )}

                              {/* <div className="col-2">
                                                                    <span className="d-block mb-2">Is Active?</span>
                                                                    <div class="input-group mb-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            id="isFacilityActive"
                                                                            name="isFacilityActive"
                                                                            switch="bool"
                                                                            onChange={this.handleChange}
                                                                            checked={this.state.isFacilityActive}
                                                                        />
                                                                        <label htmlFor="isFacilityActive" data-on-label="Yes" data-off-label="No" />
                                                                    </div>
        
                                                                </div> */}

                              {AuthService.canFacilityView(
                                PermissionFeature.FacilityFAAAFDFilename
                              ) && (
                                <div className="col-4">
                                  <div className="form-group">
                                    <MDBInput
                                      value={this.state.afdfilename}
                                      id="afdfilename"
                                      name="afdfilename"
                                      onChange={this.handleChange}
                                      label="FAA AFD Filename"
                                      group
                                      type="text"
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityFAAAFDFilename
                                        )
                                      }
                                      //required
                                    >
                                      <div className="invalid-feedback">
                                        File Name is required.
                                      </div>
                                    </MDBInput>
                                  </div>
                                </div>
                              )}
                            </div>

                            <Row>
                              {AuthService.canFacilityView(
                                PermissionFeature.FacilityLmNumber
                              ) && (
                                <Col lg={4} xl={4} sm={4} xs={4} md={4}>
                                  <MDBInput
                                    value={this.state.lm4No}
                                    id="lm4No"
                                    name="lm4No"
                                    onChange={this.handleChange}
                                    label="LM-4 File Number"
                                    group
                                    type="number"
                                    disabled={
                                      !AuthService.canFacilityUpdate(
                                        PermissionFeature.FacilityLmNumber
                                      )
                                    }
                                    //required
                                  >
                                    <div className="invalid-feedback">
                                      LM-4 File No is required.
                                    </div>
                                  </MDBInput>
                                </Col>
                              )}
                              {AuthService.canFacilityView(
                                PermissionFeature.FacilityLatitude
                              ) && (
                                <Col lg={4} xl={4} sm={4} xs={4} md={4}>
                                  <MDBInput
                                    value={this.state.latitude}
                                    id="latitude"
                                    name="latitude"
                                    onChange={this.handleChange}
                                    label="Latitude"
                                    group
                                    type="number"
                                    //required
                                  >
                                    <div className="invalid-feedback">
                                      Latitude is required.
                                    </div>
                                  </MDBInput>
                                </Col>
                              )}
                              {AuthService.canFacilityView(
                                PermissionFeature.FacilityLongitude
                              ) && (
                                <Col lg={4} xl={4} sm={4} xs={4} md={4}>
                                  <MDBInput
                                    value={this.state.longitude}
                                    id="longitude"
                                    name="longitude"
                                    onChange={this.handleChange}
                                    label="Longitude"
                                    group
                                    type="number"
                                    // required
                                  >
                                    <div className="invalid-feedback">
                                      Longitude No is required.
                                    </div>
                                  </MDBInput>
                                </Col>
                              )}
                            </Row>

                            <ValidatorForm
                              ref="editFacilityMatForm"
                              onSubmit={this.handleEditMaterialSubmit}
                              onError={(errors) => console.log(errors)}
                            >
                              <Row>
                                {AuthService.canFacilityView(
                                  PermissionFeature.FacilityRegion
                                ) && (
                                  <Col
                                    lg={3}
                                    xl={3}
                                    sm={3}
                                    xs={3}
                                    md={3}
                                    className="mb-4"
                                  >
                                    <Autocomplete
                                      id="combo-box-facilityEditregion"
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityRegion
                                        )
                                      }
                                      options={
                                        this.props.facilityDashboardState
                                          .facilityRegionOptions &&
                                        this.props.facilityDashboardState
                                          .facilityRegionOptions
                                      }
                                      getOptionLabel={(option) =>
                                        option.description
                                      }
                                      value={
                                        this.state.facility_edit_region !== ""
                                          ? this.props.facilityDashboardState.facilityRegionOptions.find(
                                              (el) => {
                                                return (
                                                  el.description ===
                                                  this.state
                                                    .facility_edit_region
                                                );
                                              }
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        this.changeValue(
                                          e,
                                          "facility_edit_region"
                                        )
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          name="facility_edit_region"
                                          value={
                                            this.state.facility_edit_region
                                          }
                                          validators={["required"]}
                                          errorMessages={["Region is required"]}
                                          label="Region"
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </Col>
                                )}

                                {AuthService.canFacilityView(
                                  PermissionFeature.FacilityOwner
                                ) && (
                                  <Col
                                    lg={3}
                                    xl={3}
                                    sm={3}
                                    xs={3}
                                    md={3}
                                    className="mb-4"
                                  >
                                    <Autocomplete
                                      id="combo-box-oshamaintainer"
                                      options={this.state.employerList}
                                      getOptionLabel={(option) =>
                                        option.options
                                      }
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityOwner
                                        )
                                      }
                                      value={
                                        this.state.oshamaintainer !== ""
                                          ? this.state.employerList.find(
                                              (el) => {
                                                return (
                                                  el.options ===
                                                  this.state.oshamaintainer
                                                );
                                              }
                                            )
                                          : null
                                      }
                                      onChange={(e) =>
                                        this.changeValue(e, "oshamaintainer")
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          value={this.state.oshamaintainer}
                                          //validators={["required"]}
                                          //errorMessages={[
                                          //  "Facility Employer is required",
                                          //]}
                                          label="Facility Employer"
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </Col>
                                )}
                                {AuthService.canFacilityView(
                                  PermissionFeature.FacilityStatus
                                ) && (
                                  <Col
                                    lg={3}
                                    xl={3}
                                    sm={3}
                                    xs={3}
                                    md={3}
                                    className="mb-4"
                                  >
                                    <Autocomplete
                                      id="combo-box-facilitystatus"
                                      options={facilityStatusOptions}
                                      getOptionLabel={(option) => option.text}
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityStatus
                                        )
                                      }
                                      onChange={(e) =>
                                        this.changeValue(e, "facilitystatus")
                                      }
                                      value={
                                        this.state.facilitystatus &&
                                        this.state.facilitystatus !== "" &&
                                        this.state.facilitystatus !== null
                                          ? facilityStatusOptions.find((el) => {
                                              return (
                                                el.value ===
                                                this.state.facilitystatus
                                              );
                                            })
                                          : null
                                        //: facilityStatusOptions.find((el) => {
                                        //    return (
                                        //        el.value ===
                                        //        "Represented"
                                        //    );
                                        //})
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          label="Facility Status"
                                          value={this.state.facilitystatus}
                                          validators={["required"]}
                                          errorMessages={["Status is required"]}
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                {AuthService.canFacilityView(
                                  PermissionFeature.FacilityType
                                ) && (
                                  <Col
                                    lg={3}
                                    xl={3}
                                    sm={3}
                                    xs={3}
                                    md={3}
                                    className="mt-2 mb-2"
                                  >
                                    <Autocomplete
                                      id="combo-box-type"
                                      options={
                                        this.props.facilityDashboardState
                                          .facilityTypeOptions &&
                                        this.props.facilityDashboardState
                                          .facilityTypeOptions
                                      }
                                      getOptionLabel={(option) =>
                                        option.description
                                      }
                                      onChange={(e) =>
                                        this.changeValue(e, "type")
                                      }
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityType
                                        )
                                      }
                                      value={
                                        this.state.type != ""
                                          ? this.props.facilityDashboardState.facilityTypeOptions.find(
                                              (el) => {
                                                return (
                                                  el.description ===
                                                  this.state.type
                                                );
                                              }
                                            )
                                          : null
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          label="Facility Type"
                                          value={this.state.type}
                                          validators={["required"]}
                                          errorMessages={[
                                            "Facility type is required",
                                          ]}
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </Col>
                                )}
                                {AuthService.canFacilityView(
                                  PermissionFeature.FacilityLevel
                                ) &&
                                  this.state.showLevel && (
                                    <Col
                                      lg={3}
                                      xl={3}
                                      sm={3}
                                      xs={3}
                                      md={3}
                                      className="mt-2 mb-2"
                                    >
                                      <Autocomplete
                                        id="combo-box-level"
                                        options={facilityLevelOptions}
                                        getOptionLabel={(option) => option.text}
                                        onChange={(e) =>
                                          this.changeValue(e, "level")
                                        }
                                        disabled={
                                          !AuthService.canFacilityUpdate(
                                            PermissionFeature.FacilityLevel
                                          )
                                        }
                                        value={
                                          this.state.level !== ""
                                            ? facilityLevelOptions.find(
                                                (el) => {
                                                  return (
                                                    el.key === this.state.level
                                                  );
                                                }
                                              )
                                            : null
                                        }
                                        renderInput={(params) => (
                                          <TextValidator
                                            {...params}
                                            label="Facility Level"
                                            value={this.state.level}
                                            validators={["required"]}
                                            errorMessages={[
                                              "Level is required",
                                            ]}
                                            variant="standard"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </Col>
                                  )}
                                {AuthService.canFacilityView(
                                  PermissionFeature.FacilityOSHATier
                                ) && (
                                  <Col
                                    lg={3}
                                    xl={3}
                                    sm={3}
                                    xs={3}
                                    md={3}
                                    className="mt-2 mb-2"
                                  >
                                    <Autocomplete
                                      id="combo-box-oshatier"
                                      options={oshaTierOptions}
                                      getOptionLabel={(option) => option.text}
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilityOSHATier
                                        )
                                      }
                                      value={
                                        this.state.oshatier != ""
                                          ? oshaTierOptions.find((el) => {
                                              return (
                                                el.key === this.state.oshatier
                                              );
                                            })
                                          : null
                                      }
                                      onChange={(e) =>
                                        this.changeValue(e, "oshatier")
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          value={this.state.oshatier}
                                          //validators={["required"]}
                                          //errorMessages={[
                                          //  "OSHA Tier is required",
                                          //]}
                                          label="OSHA Tier"
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </Col>
                                )}

                                {AuthService.canFacilityView(
                                  PermissionFeature.FacilitySrfStatus
                                ) && (
                                  <Col
                                    lg={3}
                                    xl={3}
                                    sm={3}
                                    xs={3}
                                    md={3}
                                    className="mt-2 mb-2"
                                  >
                                    <Autocomplete
                                      id="combo-box-edit_srfrxiistatus"
                                      options={SRF12Numbers}
                                      getOptionLabel={(option) => option.text}
                                      disabled={
                                        !AuthService.canFacilityUpdate(
                                          PermissionFeature.FacilitySrfStatus
                                        )
                                      }
                                      value={
                                        this.state.srfrxiistatus != ""
                                          ? SRF12Numbers.find((el) => {
                                              return (
                                                el.key ===
                                                this.state.srfrxiistatus
                                              );
                                            })
                                          : null
                                      }
                                      onChange={(e) =>
                                        this.changeValue(e, "srfrxiistatus")
                                      }
                                      renderInput={(params) => (
                                        <TextValidator
                                          {...params}
                                          label="SRF-12"
                                          variant="standard"
                                          value={this.state.srfrxiistatus}
                                          //validators={["required"]}
                                          //errorMessages={["SRF-12 is required"]}
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </Col>
                                )}
                              </Row>
                            </ValidatorForm>
                          </Fragment>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>

                      <Button to="/roles" color="primary" type="submit">
                        Save Changes
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

// export default Users;
export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(EditFacility);

// export default EditFacility;
