import React, { Component } from "react";
import axios from "./auth-header";
import { toast } from "react-toastify";
import $ from "jquery";
class PrimaryStar extends Component {
  constructor(props) {
    super(props);
  }

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  HandleClick = (e, type, memberid, id) => {
    //debugger;
    if (this.props.starTarget === "address") {
      e.preventDefault();
      e.stopPropagation();
    }
    let Url =
      "/api/Member/EnablePrimary?primaryType=" +
      type +
      "&memberid=" +
      memberid +
      "&id=" +
      id;
    this.showSpinner();
    axios.post(Url).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);
          this.props.refreshData(memberid);
        } else {
          this.hideSpinner();
          toast.error(data.message);
        }
      } else {
        toast.error("Could not complete the opertaion.");
      }
    });
  };

  render() {
    let styles = {
      position: "absolute",
      left: "18px",
      top: "1px",
    };
    return (
      <span className="position-relative">
        <i
          // style={styles}
          title={this.props.isprimary ? "Primary" : "Make Primary"}
          className={
            this.props.isprimary ? "fa fa-star text-warning" : "far fa-star"
          }
          onClick={(e) =>
            this.HandleClick(
              e,
              this.props.checkType,
              this.props.memberid,
              this.props.id
            )
          }
        ></i>
      </span>
    );
  }
}
export default PrimaryStar;
