import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";

import { FilePond, registerPlugin, setOptions, File } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "moment/locale/en-ca";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import actionFilter from "../../../../store/facility/action";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);
export class FileUploadModal extends Component {
  state = {
    acceptedFileTypes: [
      "image/png",
      "image/jpeg",
      "application/pdf",
      "image/gif",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
    ],
  };

  handleInitFileUpload() {
    console.log("handleInitFileUpload instance has initialised", this.pond2);
  }
  handleSubmitFileUpload = (evt) => {
    evt.preventDefault();

    if (!this.props.files.length) {
      toast.error("Please select file!");
      return false;
    }

    const data = {
      parentid: this.props.parentId,
      facilityid: this.props.facilityId,
    };
    var formData = new FormData();
    //formData.append("File", this.props.files[0]);
    for (var i = 0; i < this.props.files.length; i++) {
      formData.append("File" + i, this.props.files[i]);
    }
    formData.append("data", JSON.stringify(data));

    //console.log(formData);
    this.props.uploadfacilityfile(formData);
  };

  render() {
    return (
      <Modal
        centered={true}
        className="modal-lg"
        role="dialog"
        autoFocus={true}
        isOpen={this.props.modal}
        toggle={this.props.toggle}
        backdrop="static"
      >
        <ModalHeader toggle={this.props.toggle}>
          {this.props.header}
        </ModalHeader>
        <ModalBody>
          <form
            className="needs-validation"
            id="fileUploadForm2"
            noValidate
            onSubmit={(e) => this.handleSubmitFileUpload(e)}
          >
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <Fragment>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <FilePond
                            ref={(ref) => (this.pond2 = ref)}
                            files={this.props.files}
                            allowMultiple={true}
                            maxFiles={this.props.allowedFiles}
                            allowFileTypeValidation={true}
                            allowImageExifOrientation={true}
                            allowImagePreview={true}
                            allowImageCrop={true}
                            allowFileEncode={true}
                            acceptedFileTypes={this.state.acceptedFileTypes}
                            fileValidateTypeDetectType={(source, type) =>
                              new Promise((resolve, reject) => {
                                //  debugger
                                let rejectedExtensions = [
                                  "ade",
                                  "adp",
                                  "apk",
                                  "bat",
                                  "chm",
                                  "cmd",
                                  "com",
                                  "cpl",
                                  "dll",
                                  "dmg",
                                  "exe",
                                  "hta",
                                  "ins",
                                  "isp",
                                  "jar",
                                  "js",
                                  "jse",
                                  "lib",
                                  "lnk",
                                  "mde",
                                  "msc",
                                  "msi",
                                  "msp",
                                  "mst",
                                  "nsh",
                                  "pif",
                                  "scr",
                                  "sct",
                                  "shb",
                                  "sys",
                                  "vb",
                                  "vbe",
                                  "vbs",
                                  "vxd",
                                  "wsc",
                                  "wsf",
                                  "wsh",
                                  "cab",
                                ];
                                // If the file extension is not in our rejected list.
                                if (
                                  !rejectedExtensions.some((ext) =>
                                    source.name.endsWith("." + ext)
                                  )
                                ) {
                                  resolve(type);
                                } else {
                                  // Otherwise it is rejected.
                                  toast.error("File type is unsupported!");
                                  //reject(new Error('Files contains unsupported format!'));
                                  reject(type);
                                }
                              })
                            }
                            oninit={() => this.handleInitFileUpload()}
                            onupdatefiles={(fileItems) => {
                              // debugger
                              // Set currently active file objects to this.state
                              this.props.updateFiles(fileItems);
                              // console.log(this.props.oshaState.files);
                            }}
                          ></FilePond>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button to="/roles" color="primary" type="submit">
                Save Changes
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({ facilityDashboardState: state.facilityDashboard }),
  {
    ...actionFilter,
  }
)(FileUploadModal);
