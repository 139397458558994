import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import actionFilter from "../../../../store/advancedfilter/action";

import "react-input-range/lib/css/index.css";

import SelectedFilter from "../SelectedFilter";
import TagsActionButtons from "../TagsButtons";
import Tags from "../Tags";

import { TabContent, TabPane, Row, Col } from "reactstrap";
import FE_Statuses from "../NavItemsContent/FE_Statuses";
import FE_Budgets from "../NavItemsContent/FE_Budgets";
import FE_BookVals from "../NavItemsContent/FE_BookVals";
import FE_Types from "../NavItemsContent/FE_Types";
import FE_Description from "../NavItemsContent/FE_Description";
import FE_AF_Nav_Items from "../FE_AF_Nav_Items";
import FE_INV_Purchase_Date from "../../../F&E/AdvanceFilter/NavItemsContent/FE_INV_Purchase_Date";
import FE_ActualVals from "../../../F&E/AdvanceFilter/NavItemsContent/FE_ActualVals";
import FE_INV_Years from "../../../F&E/AdvanceFilter/NavItemsContent/FE_INV_Years";
import FE_INV_StickerNo from "../../../F&E/AdvanceFilter/NavItemsContent/FE_INV_StickerNo";

import FE_Facilities from "../NavItemsContent/FE_Facilities";

import FE_Regions from "../NavItemsContent/FE_Regions";

import FE_Teams from "../NavItemsContent/FE_Teams";

import FE_INV_Users from "../NavItemsContent/FE_INV_Users";
import FE_Users from "../NavItemsContent/FE_Users";
import FE_NA_Office from "../NavItemsContent/FE_NA_Office";

// ##################Inside FE Module###########################
export class DeskTopTabContents extends Component {
  render() {
    return (
      <Col xs="9" sm="9" md="9" xl="9" className="pr-0">
        <TabContent activeTab={this.props.festate.activeTab}>
          <TabPane tabId={FE_AF_Nav_Items.Status}>
            <FE_Statuses
              isInventoryTab={
                this.props.festate.activeSubHeader == 1 ? true : false
              }
              isMemberAF={false}
            />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.Budget}>
            <FE_Budgets
              isInventoryTab={
                this.props.festate.activeSubHeader == 1 ? true : false
              }
              isMemberAF={false}
            />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.BookValueRequest}>
            <FE_BookVals isFERequests={true} formId="fe_req_bookValsForm" />
          </TabPane>

          <TabPane tabId={FE_AF_Nav_Items.Type}>
            <FE_Types
              isInventoryTab={
                this.props.festate.activeSubHeader == 1 ? true : false
              }
              isMemberAF={false}
            />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.Description}>
            <FE_Description
              formId="fe_req_descriptionForm"
              isInventoryTab={true}
              isMemberAF={true}
            />
          </TabPane>
          {/*############# REQ-1 Requests Tabs############### */}
          <TabPane tabId={FE_AF_Nav_Items.FacilityRequest}>
            <FE_Facilities />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.RegionRequest}>
            <FE_Regions />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.TeamRequest}>
            <FE_Teams />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.UserRequest}>
            <FE_Users />
          </TabPane>
          {/*######################### (FE Module) Reporting on Active Equipment ######################### */}
          <TabPane tabId={FE_AF_Nav_Items.Year}>
            <FE_INV_Years />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.ActualCost}>
            <FE_ActualVals />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.PurchaseDate}>
            <FE_INV_Purchase_Date />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.StickerNo}>
            <FE_INV_StickerNo />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.BookValueInventory}>
            <FE_BookVals isFEInventoryTab={true} formId="fe_Inv_bookValsForm" />
          </TabPane>
          {/*################# REQ-1 Inventory Tabs############################### */}
          {/* <TabPane tabId={FE_AF_Nav_Items.FacilityInventory}>
            <FE_Facilities isInventoryTab={true} />
          </TabPane> */}
          <TabPane tabId={FE_AF_Nav_Items.RegionInventory}>
            <FE_Regions isInventoryTab={true} />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.TeamInventory}>
            <FE_Teams isInventoryTab={true} />
          </TabPane>
          <TabPane tabId={FE_AF_Nav_Items.NAOffice}>
            <FE_NA_Office isInventoryTab={true} />
          </TabPane>

          <TabPane tabId={FE_AF_Nav_Items.UserInventory}>
            <FE_INV_Users isInventoryTab={true} />
          </TabPane>
        </TabContent>
        <div style={{ height: "95px" }} />
        <div className="bottom-stick">
          <Row>
            <Col className="apply-filter-selection pl-0">
              <Row>
                <Col md={6} sm={6} xs={6} lg={8} xl={9} className="pr-0">
                  <TagsActionButtons />
                </Col>
                <Col md={6} sm={6} xs={6} lg={4} xl={3} className="pl-0">
                  <SelectedFilter />
                </Col>
              </Row>
              <Row />
            </Col>
          </Row>
          <Row className="setFETagsPadding">
            {/* Tags for advance filter */}
            <Col className="filtersBorder p-2 tagsScroll">
              <Tags />
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

export default connect(
  (state) => ({ festate: state.fetracker, formValues: state.form }),
  {
    ...actionFilter,
  }
)(DeskTopTabContents);
