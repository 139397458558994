import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../Shared/DeleteModalTeam";
import ConfirmDeletePositionModal from "../../Shared/DeleteModalPosition";
import ConfirmDeleteLinkedPositionModal from "../General/DeleteModalLinkedPosition";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import PermissionHeader from "../General/PermissionHeader";
import FacilityTeamEmailListNameModal from "../../Shared/FacilityTeamEmailListNameModal";
import Teams from "./Teams";
import AuthService from "../../Auth/AuthService";
import MembersModal from "../../Shared/MembersModal";
import Select from "react-select";
import TeamsModal from "../../Shared/TeamsModal";
import EditTeam from "./EditTeam";
import AssignMemberModal from "../General/AssignMemberModal";
import PositionMembersModal from "../General/PositionMembersModal";
import PositionPSModal from "../General/PositionPermissionScopeModal";
import EditPositionModal from "../General/EditPositionModal";
import LinkedPosition from "./LinkedPosition";
import AssignChildTeam from "./AssignChildTeam";
import DeleteTeam from "../General/DeleteTeam";
import TeamPermission from "../General/TeamPermission";
import Services from "../PermissionService";
import * as Constants from "../../AdvanceSearch/SavedSearches/Constants";
import CompositionModal from "../../AdvanceSearch/SavedSearches/CompositionModal";
import _ from "lodash";
import PermissionFeature from "../PermissionFeature";
import * as Permissions from "../PermissionValues";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PermissionService from "../PermissionService";
import TeamPositionMembers from "../Positions/TeamPositionMembers";
import MailGunMembersListModal from "../../Shared/MailGunMembersListModal";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import { TeamActivityLogging } from "../General/TeamActivityLogging";
import actionActivity from "../../../store/activitylogging/action";
import { unregisterField } from "redux-form";
import mailGunEmailActions from "../../../store/mailgunEmails/action";

import EquipmentsCardList from "../../Admin/Users/EditUser/EquipmentsCardList";
import FE_Equipment_Card_Types from "../../F&E/FE_Equipment_Card_Types";
import MailGunEmailTypes from "../../Email/Enums/MailGunEmailTypes";
import { CompositionModalMode } from "../../Registrations/Helpers/LookUpValues";
const { SearchBar } = Search;
const ButtonToNavigate = ({ title, history, color, classes }) => (
  <Button
    type="button"
    onClick={() =>
      history.push({
        pathname: "/permissions/teams",
        tab: Teams,
        name: "Teams",
      })
    }
    color={color}
    className={classes}
  >
    {title}
  </Button>
);
let timeOutTask;
class TeamDetail extends Component {
  constructor(props) {
    super(props);
    this.randomNo = (maxno) => {
      return Math.floor(Math.random() * Math.floor(maxno));
    };
    const { Id, parentId, isChild } = this.props.match.params;
    this.state = {
      hasAnyEquipment: false,
      parentId: parentId,
      isChild: isChild,
      memberPosition: "",
      type: this.props.location.type,
      id: Id,
      positions: [],
      displayedPositions: [],
      position: null,
      member: null,
      assignMemberOnlyDates: false,
      LinkedTeams: [],
      childTeams: [],
      positionsOptions: [],
      searchPositionTerm: "",
      prevSearchPositionTerm: "", // for tracing search term change or not
      searchLinkedTeamTerm: "",
      searchChildTeamTerm: "",
      sizePerPagePositions: 25,
      sizePerPageLinkedTeams: 10,
      teamName: "",
      deletePosition: null,
      deleteLinkedPosition: null,
      selectTeamId: -1,
      isNational: null,
      editTeam: false,
      editTeamName: "",
      editTeamName_err: "",
      reloadPosition: true,
      assignTeamData: [],
      scopes: [],
      showEmailMembersModal: false,
      EmailNumbers: "",
      showModal: false,
      emails: [],
      check: "",
      emailListExists: null,
      mgListAddress: null,
      bulkProgress: false,
      bulkProgressAlert: false,
      teamPermissionChangeObj: null,
      childTeamOverflowY: "",
      //position columns width
      columnWidth: 0,
      columnSecWidth: 0,
      columnFirWidth: 0,
      columnSortWidth: 0,
      positionNotExpandable: [],
      linkedPositionMemberCount: 0,
      expanded: [],
      backUpExpanded: [],
      emailListId: -1,
    };

    this.BeginEditTeam = this.BeginEditTeam.bind(this);
    this.positionSizePerPageChange = this.positionSizePerPageChange.bind(this);
    this.linkedTeamsSizePerPageChange = this.linkedTeamsSizePerPageChange.bind(
      this
    );
    this.listChildTeam = this.listChildTeam.bind(this);
    this.AssignTeamData = this.AssignTeamData.bind(this);
    this.confirmDeleteChildTeam = this.confirmDeleteChildTeam.bind(this);
    this.setReloader = this.setReloader.bind(this);
    this.resetTeamName = this.resetTeamName.bind(this);
    this.handleChangeTeamName = this.handleChangeTeamName.bind(this);
    this.notNullValidation = this.notNullValidation.bind(this);
    this.textValidation = this.textValidation.bind(this);
    this.BeginTeamPermission = this.BeginTeamPermission.bind(this);
    this.handleFormSubmitTeamName = this.handleFormSubmitTeamName.bind(this);
    this.handleRemovePosition = this.handleRemovePosition.bind(this);
    this.setReloadPosition = this.setReloadPosition.bind(this);
    this.setReloadPositiontoFalse = this.setReloadPositiontoFalse.bind(this);
    this.setPositionNotExpandable = this.setPositionNotExpandable.bind(this);
    this.getTeamPermissionChangeStatus = this.getTeamPermissionChangeStatus.bind(
      this
    );
    this.BulkPermissionAlert = this.BulkPermissionAlert.bind(this);
    this.BulkPermisssionProgress = this.BulkPermisssionProgress.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.expandRow = this.expandRow.bind(this);
    this.fetchPositionColumns = this.fetchPositionColumns.bind(this);
    this.filterPositionMembers = this.filterPositionMembers.bind(this);
    this.checkExpandables = this.checkExpandables.bind(this);
    this.handleRemoveLinkedPosition = this.handleRemoveLinkedPosition.bind(
      this
    );
    this.rowMove = this.rowMove.bind(this);
    this.columns = this.columns.bind(this);
    this.teamColumns = [
      {
        text: "Team Name",
        dataField: "teamName",

        headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
        classes: "pl-3 py-2 c-pointer",
        sort: true,
      },
      {
        text: "Admin",
        dataField: "ownerName",
        headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
        classes: "pl-3 py-2 c-pointer",
        sort: true,
      },
    ];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {},
    };
    this.LinkedTeamRowEvents = {
      onClick: (e, row, rowIndex) => {
        if (row.id) {
          this.props.history.push("/permissions/teams/teamDetail/" + row.id);
        }
      },
    };
    this.positionOrderArrows = this.positionOrderArrows.bind(this);
    this.activityLogs = React.createRef();
    this.setAssignPositionMember = this.setAssignPositionMember.bind(this);
  }
  columns = () => {
    return [
      {
        text: "",
        dataField: "",
        headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
        //    hidden: !AuthService.canView(PermissionFeature.FacilityMemberTieBreaker),
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "";
          if (_.isArray(row.members) && row.members.length > 1) {
            borderClass = " bg-light";
          }
          return "pl-3 py-2" + borderClass;
        },
        sort: false,
        formatExtraData: this.state.displayedPositions,
        formatter: (cell, row, rowIndex) => {
          let { upArrow, downArrow } = this.positionOrderArrows(row, rowIndex);

          return (
            <div className="text-secondary">
              {upArrow ? (
                <i
                  className="d-inline c-pointer fas fa-lg fa-caret-up"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.rowMove(row, rowIndex, "Up");
                  }}
                ></i>
              ) : (
                <i className="d-inline invisible fas fa-lg fa-caret-up"></i>
              )}
              {downArrow ? (
                <i
                  className="d-inline c-pointer fas fa-lg fa-caret-down"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.rowMove(row, rowIndex, "Down");
                  }}
                ></i>
              ) : (
                <i class="d-inline invisible fas fa-lg fa-caret-down"></i>
              )}
            </div>
          );
        },
      },
      {
        text: "Position Name",
        dataField: "positionName",
        headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "";
          if (_.isArray(row.members) && row.members.length > 1) {
            borderClass = " c-pointer bg-light";
          }
          return "pl-3 py-2" + borderClass;
        },
        sort: true,
        attrs: (cell, row, rowIndex, colIndex) => {
          if (_.isArray(row.members) && row.members.length > 1) {
            return { colSpan: `2` };
          }
        },
        formatter: (cellContent, row) => {
          return (
            <>
              {row.positionName}
              {row.isOwner && (
                <span className="pl-2 font-weight-bold text-black-50">
                  <i>(Owner)</i>
                </span>
              )}
              {row.isLinked == 3 && row.linkedPositionTeam && (
                <span className="pl-2 font-weight-bold text-black-50">
                  <i>({row.linkedPositionTeam})</i>
                </span>
              )}
              {_.isArray(row.members) && row.members.length > 1 && (
                <span className="pl-2 font-weight-bold text-black-50">
                  <i>
                    (
                    {row.members.length +
                      (row.members.length > 1 ? " Members" : " Member")}
                    )
                  </i>
                </span>
              )}
            </>
          );
        },
      },
      {
        isDummyField: true,
        text: "Member Name",
        sort: false,
        headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        attrs: (cell, row, rowIndex, colIndex) => {
          if (_.isArray(row.members) && row.members.length > 1) {
            return { hidden: true };
          }
        },
        formatter: (cellContent, row) => {
          return (
            <div>
              {_.isArray(row.members) && row.members.length == 1 ? (
                <>
                  <Link
                    to={{
                      pathname:
                        "/directory/userAction/" + row.members[0].memberID,
                    }}
                  >
                    {row.members[0].memberName}
                  </Link>
                  {row.isLinked != 3 && (
                    <span className="pl-2">
                      {AuthService.canUpdate(
                        PermissionFeature.TeamsPositions
                      ) &&
                        !row.isOwner && (
                          <i
                            onClick={() => {
                              this.setAssignPositionMember(
                                row,
                                row.members.length == 1 ? row.members[0] : null,
                                true
                              );
                            }}
                            className="fas mr-1 c-pointer fa-pencil-alt"
                          ></i>
                        )}
                      {AuthService.canDelete(
                        PermissionFeature.TeamsPositions
                      ) && (
                        <i
                          onClick={() => {
                            this.deleteMember(
                              row,
                              row.members.length == 1 ? row.members[0] : null
                            );
                          }}
                          className="mr-1 c-pointer text-danger mx-1 fas fa-user-times"
                        ></i>
                      )}
                    </span>
                  )}
                </>
              ) : (
                <span>Unassigned</span>
              )}
            </div>
          );
        },
      },
      {
        text: "",
        headerClasses:
          "text-center text-nowrap bg-dark text-white px-sm-1 py-2",
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "";
          if (_.isArray(row.members) && row.members.length > 1) {
            borderClass = " bg-light border-left-0";
          }
          return "px-1 text-center" + borderClass;
        },

        headerAttrs: {
          width: "120px",
        },
        formatter: (cellContent, row) => {
          return (
            <div className="text-center">
              {row.isLinked != 3 && (
                <>
                  {AuthService.canUpdate(PermissionFeature.TeamsPositions) && (
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        if (row.isOwner && row.members.length == 1) {
                          this.setAssignPositionMember(row, row.members[0]);
                        } else {
                          this.setAssignPositionMember(row, null);
                        }
                        this.props.openAssignMemberModal();
                      }}
                      className={
                        "fas mr-1 c-pointer fa-user" +
                        (row.members.length > 0
                          ? " text-info"
                          : " text-secondary")
                        /*  +
                                                                                                (!row.isOwner && row.members.length > 0
                                                                                                  ? " fa-user-plus"
                                                                                                  : " fa-user")*/
                      }
                      title={
                        !row.isOwner && row.members.length > 0
                          ? "Add User"
                          : row.members.length == 0
                          ? "Add User"
                          : "Edit User"
                      }
                    ></i>
                  )}

                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      this.props.openPositionPSModal();
                      this.setState({ position: row });
                    }}
                    className="fas mr-1 c-pointer fa-lock"
                  ></i>
                  {AuthService.canUpdate(PermissionFeature.TeamsPositions) && (
                    <i
                      onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        this.props.openEditPositionModal(false);
                        this.setState({ position: row });
                      }}
                      className="fas mr-1 c-pointer fa-pencil-alt"
                    ></i>
                  )}
                </>
              )}
              {AuthService.canUpdate(PermissionFeature.TeamsPositions) &&
                (row.isLinked != 3 ? (
                  <span
                    data-target="#LinkedPositionModal"
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      await this.setState({
                        linkedPositionName: row.positionName,
                        linkedPositionId: row.id,
                        linkedPositionMemberCount: row.members.length,
                      });
                      window.$("#LinkedPositionModal").modal({
                        backdrop: "static",
                        keyboard: true,
                        show: true,
                      });
                    }}
                    className={
                      row.isLinked == 1 ? "text-secondary" : "text-success"
                    }
                  >
                    <span className="fas mr-1 fa-paperclip"></span>
                  </span>
                ) : row.linkedPositionTeamId != null ? (
                  <a
                    target="_blank"
                    href={
                      "/permissions/teams/teamDetail/" +
                      row.linkedPositionTeamId
                    }
                    className={"text-info"}
                  >
                    <span className="mr-1 fas fa-paperclip"></span>
                  </a>
                ) : (
                  <span className={"text-info"}>
                    <span className="mr-1 fas fa-paperclip"></span>
                  </span>
                ))}
              {AuthService.canDelete(PermissionFeature.TeamsPositions) &&
                !row.isOwner &&
                (row.isLinked != 3 ? (
                  <i
                    data-toggle="modal"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      this.state.deletePosition = row;
                      this.setState({ deletePosition: row });
                      window.$("#DeleteModalPosition").modal({
                        backdrop: "static",
                        keyboard: true,
                        show: true,
                      });
                    }}
                    className="mr-1 c-pointer text-danger mx-1 fas fa-trash"
                  ></i>
                ) : (
                  <i
                    data-toggle="modal"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      this.state.deleteLinkedPosition = row;
                      this.setState({ deleteLinkedPosition: row });
                      window.$("#DeleteModalLinkedPosition").modal({
                        backdrop: "static",
                        keyboard: true,
                        show: true,
                      });
                    }}
                    className="mr-1 c-pointer text-danger mx-1 fas fa-trash"
                  ></i>
                ))}
            </div>
          );
        },
        sort: false,
      },
    ];
  };
  positionOrderArrows(row, rowIndex) {
    let upArrow = false;
    let downArrow = false;
    if (
      !_.isEmpty(this.state.displayedPositions) &&
      this.state.displayedPositions.length > 0
    ) {
      // for up Arrow
      if (rowIndex != 0) {
        upArrow = true;
      }
      // for down Arrow
      if (this.state.displayedPositions.length - 1 != rowIndex) {
        downArrow = true;
      }
    }
    return { upArrow, downArrow };
  }
  rowMove = async (row, rowIndex, action) => {
    let tempRow = _.cloneDeep(row);
    let data = this.state.displayedPositions;
    let positions = _.cloneDeep(this.state.positions);
    if (action == "Up" && rowIndex > 0 && data.length > 0) {
      let result = await Services.SwapPositionOrderId(row, data[rowIndex - 1]);
      if (result != null) {
        // swap in display positions state
        row.orderId = data[rowIndex - 1].orderId;
        data[rowIndex - 1].orderId = tempRow.orderId;
        data[rowIndex] = data[rowIndex - 1];
        data[rowIndex - 1] = row;
        console.log(positions);
        // swap in positions stat
        // find the index of current source position.
        let indexSource = positions.findIndex((x) => x.id == row.id);
        // find the index of current target position that is existing in data[rowIndex] after the execution of above swaping process.
        let indexTarget = positions.findIndex((x) => x.id == data[rowIndex].id);
        console.log(indexSource, "row index");
        console.log(indexTarget, "row index 2");
        // after finding the source position index, now, swap target position that is existing in data[rowIndex] after the execution of above swaping process
        positions[indexSource] = data[rowIndex];
        // after finding the target position index, now, swap source position that is existing in data[rowIndex+1] after the execution of above swaping process
        positions[indexTarget] = data[rowIndex - 1];
        console.log(positions);
      }
    } else if (
      action == "Down" &&
      rowIndex < data.length - 1 &&
      data.length > 0
    ) {
      let result = await Services.SwapPositionOrderId(row, data[rowIndex + 1]);
      if (result != null) {
        // swap in display positions state
        row.orderId = data[rowIndex + 1].orderId;
        data[rowIndex + 1].orderId = tempRow.orderId;
        data[rowIndex] = data[rowIndex + 1];
        data[rowIndex + 1] = row;
        console.log(positions);
        // swap in positions state
        // find the index of current source position.
        let indexSource = positions.findIndex((x) => x.id == row.id);
        // find the index of current target position that is existing in data[rowIndex] after the execution of above swaping process.
        let indexTarget = positions.findIndex((x) => x.id == data[rowIndex].id);
        console.log(indexSource, "row index");
        console.log(indexTarget, "row index 2");
        // after finding the source position index, now, swap target position that is existing in data[rowIndex] after the execution of above swaping process
        positions[indexSource] = data[rowIndex];
        // after finding the target position index, now, swap source position that is existing in data[rowIndex+1] after the execution of above swaping process
        positions[indexTarget] = data[rowIndex + 1];
        console.log(positions);
      }
    }
    this.setState({ displayedPositions: data, positions: positions });
  };

  getTeamPermissionChangeStatus = async () => {
    let data = await Services.GetTeamPermissionChangeStatus(this.state.id);
    if (data && data.result) {
      let TCSObj = data.result;

      if (TCSObj.status == "Progress") {
        await this.setState({
          bulkProgress: true,
          bulkProgressAlert: false,
        });
        let func = this.getTeamPermissionChangeStatus;
        if (
          this.props.location.pathname ===
            "/permissions/teams/teamDetail/" + this.state.id &&
          AuthService.canView(PermissionFeature.TeamsPermissions)
        ) {
          timeOutTask = setTimeout(function() {
            func();
          }, 10000);
        }
      } else {
        await Services.SetTeamPermissionChangeStatusSeen(TCSObj.id);
        await this.setState({
          bulkProgress: false,
          bulkProgressAlert: true,
          teamPermissionChangeObj: TCSObj,
        });
        this.setReloadPosition();
      }
    } else {
      await this.setState({
        bulkProgress: false,
        bulkProgressAlert: false,
        teamPermissionChangeObj: null,
      });
    }
  };
  BulkPermisssionProgress = () => {
    if (this.state.bulkProgress && !this.state.bulkProgressAlert) {
      return (
        <div className="col-12">
          <div
            className="spinner-border text-primary"
            style={{ width: "1.2rem", height: "1.2rem" }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          <span>
            Child teams and positions permission bulk changing process is in
            progress...
          </span>
        </div>
      );
    }
  };
  BulkPermissionAlert = () => {
    if (
      !_.isEmpty(this.state.teamPermissionChangeObj) &&
      !this.state.bulkProgress &&
      this.state.bulkProgressAlert
    ) {
      let Result = this.state.teamPermissionChangeObj;

      let Message = "",
        classes = "";

      switch (Result.status) {
        case "Done":
          {
            classes = "alert-success";
            Message =
              "The team permissions change process completed successfully!";
          }
          break;
        case "Fail":
          {
            classes = "alert-danger";
            Message =
              "The team permission change process Failed to change the child permissions!";
          }
          break;
        case "Expire":
          {
            classes = "alert-warning";
            Message = "The team permission change requires some verifications!";
          }
          break;
        default:
          break;
      }
      return (
        <div className="col-12">
          <div
            className={"alert alert-dismissible fade show" + " " + classes}
            role="alert"
          >
            {Message}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      );
    }
  };
  fetchPositionColumns = async () => {
    let columnWidth = $(".positionList thead tr")
      .children("th")
      .eq(3)
      .innerWidth();
    let columnSecWidth = $(".positionList thead tr")
      .children("th")
      .eq(2)
      .innerWidth();
    let columnFirWidth = $(".positionList thead tr")
      .children("th")
      .eq(1)
      .innerWidth();
    let columnSortWidth = $(".positionList thead tr")
      .children("th")
      .eq(0)
      .innerWidth();
    if (
      this.state.columnWidth != columnWidth ||
      this.state.columnSecWidth != columnSecWidth ||
      this.state.columnFirWidth != columnFirWidth ||
      this.state.columnSortWidth != columnSortWidth
    ) {
      this.state.columnWidth = columnWidth;
      this.state.columnSecWidth = columnSecWidth;
      this.state.columnFirWidth = columnFirWidth;
      this.state.columnSortWidth = columnSortWidth;
      await this.setState({
        columnWidth: this.state.columnWidth,
        columnSecWidth: this.state.columnSecWidth,
        columnFirWidth: this.state.columnFirWidth,
        columnSortWidth: this.state.columnSortWidth,
      });
    }
  };
  expandRow = () => {
    return {
      renderer: (row, rowIndex) => {
        if (row.members.length > 1) {
          return (
            <TeamPositionMembers
              columnWidth={this.state.columnWidth}
              columnFirWidth={this.state.columnFirWidth}
              columnSecWidth={this.state.columnSecWidth}
              columnSortWidth={this.state.columnSortWidth}
              position={row}
              deleteMember={this.deleteMember}
              setAssignPositionMember={this.setAssignPositionMember}
            />
          );
        } else {
        }
      },
      onExpand: (row, isExpand, rowIndex, e) => {
        var $this2 = this;
        if (isExpand) {
          var $this = $(e.target);

          this.setState(() => ({
            expanded: [...this.state.expanded, row.id],
            backUpExpanded: [...this.state.backUpExpanded, row.id],
          }));
          setTimeout(function() {
            //$this.parent().next().children().attr("colSpan", "2");
            $this
              .parent()
              .next()
              .children()
              .addClass("member-row-expansion");
            $(window).resize($this2.fetchPositionColumns);
            $this2.fetchPositionColumns();
            //$this.parent().next().prepend('<td></td>');
          }, 50);
        } else {
          this.setState(() => ({
            expanded: this.state.expanded.filter((x) => x !== row.id),
            backUpExpanded: this.state.backUpExpanded.filter(
              (x) => x !== row.id
            ),
          }));
        }
        setTimeout(function() {
          //$this.parent().next().children().attr("colSpan", "2");
          $(window).resize($this2.fetchPositionColumns);
          $this2.fetchPositionColumns();
          //$this.parent().next().prepend('<td></td>');
        }, 600);
      },
      expanded: this.state.expanded,
      nonExpandable: this.state.positionNotExpandable,
    };
  };
  async deleteMember(position, member) {
    $(".loading").show();

    let data = await Services.EditPositionMember({
      teamID: this.state.id,
      positions: [
        {
          id: position.id,
          PositionName: position.positionName,
          Member: {
            ID: member.id,
            MemberId: null,
          },
        },
      ],
    });
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        toast.error("Member deleted successfully!");
        if (
          this.state.positions.filter(
            (itemP) =>
              itemP.isLinked != 3 &&
              itemP.members.find(
                (itemM) =>
                  itemM.id != member.id && itemM.memberID == member.memberID
              )
          ).length == 0
        ) {
          let teamID = this.state.id;
          let url = `/api/FacilityRegionEmail/SyncTeamMembers?teamId=${teamID}`;
          axios.post(url).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
            } else {
            }
          });
        }
        this.setReloader();
      }
    }

    $(".loading").hide();
  }

  setPositionNotExpandable() {
    this.state.positionNotExpandable = [];
    if (!_.isEmpty(this.state.positions)) {
      this.state.positions.map((item) => {
        if (item.members.length <= 1) {
          this.state.positionNotExpandable.push(item.id);
        }
      });
    }
    this.setState({ positionNotExpandable: this.state.positionNotExpandable });
  }
  setAssignPositionMember(position, member, onlyDates = false) {
    if (member == null) {
      member = {
        ID: null,
        MemberId: null,
        StartDate: null,
        EndDate: null,
      };
    }

    this.setState({
      position: position,
      member: member,
      assignMemberOnlyDates: onlyDates,
    });
    this.props.openAssignMemberModal();
  }

  handleRemovePosition = async (position, isHistory = false) => {
    if (!position.isOwner) {
      if (position.id) {
        $(".loading").show();
        let data = await Services.DeletePosition(position.id, isHistory);
        if (!_.isEmpty(data)) {
          toast.success("Position deleted successfully!");
          this.setReloader();

          var teamID = this.state.id;
        }
        $(".loading").hide();
        window.$("#DeleteModalPosition").modal("hide");
        if (!_.isEmpty(this.state.deletePosition.members)) {
          let deletePosition = this.state.deletePosition;

          this.state.positions.forEach((item) => {
            if (item.id != deletePosition.id && item.isLinked != 3) {
              for (var i = 0; i < item.members.length; i++) {
                if (
                  !_.isEmpty(deletePosition.members) &&
                  deletePosition.members.indexOf(
                    (x) => x.memberID == item.members[i].memberID
                  ) != -1
                ) {
                  deletePosition.members.splice(
                    deletePosition.members.indexOf(
                      (x) => x.memberID == item.members[i].memberID
                    ),
                    1
                  );
                }
              }
            }
          });
          if (!_.isEmpty(deletePosition.members)) {
            var MembersID = deletePosition.members.map((item) => item.memberID);
            let url = `/api/FacilityRegionEmail/SyncTeamMembers?teamId=${teamID}`;
            axios.post(url).then(({ data }) => {
              if (data.status && data.status.toLowerCase() == "success") {
              } else {
              }
            });
          }
        }
      }
    }
  };
  handleRemoveLinkedPosition = async (position) => {
    if (position.linkedPositionId) {
      $(".loading").show();
      let data = await Services.DeleteLinkedPosition(position.linkedPositionId);
      if (!_.isEmpty(data)) {
        toast.success(data.message);
        this.setReloader();

        this.setState({ deleteLinkedPosition: null });
      }
      $(".loading").hide();
    } else {
      toast.error("An error occurred please try later");
    }
    window.$("#DeleteModalLinkedPosition").modal("hide");
  };

  GetEmailNumbers() {
    localStorage.removeItem("EmailNumbers");
    axios
      .get(`/api/Permission/GetEmailNumbers?teamid=${this.state.id}`)
      .then(({ data }) => {
        if (data.message && data.message == "Success") {
          this.setState({ username_err: "", EmailNumbers: data.result.result });
          localStorage.setItem(
            "EmailNumbers",
            JSON.stringify(this.state.EmailNumbers)
          );
        } else {
          this.setState({ username_err: data.message });
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  linkedTeamsSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageLinkedTeams: sizePerPage });
  };
  componentWillUnmount() {
    clearTimeout(timeOutTask);
  }
  positionSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPagePositions: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    //totalSize,
    rowEvents,
    expandRow
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
      sizePerPage,
      //hidePageListOnlyOnePage: true
    };

    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowEvents={rowEvents}
          expandRow={expandRow}
        />
      </div>
    );
  }

  setReloadPosition() {
    this.setState({ reloadPosition: true });
  }
  setReloadPositiontoFalse() {
    this.setState({ reloadPosition: false });
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };
  async getTeam() {
    $(".loading").show();
    let uid = this.state.id;
    if (!uid) {
      this.props.history.push("/permissions/teams");
    }
    let data = await Services.Team(uid);
    if (!_.isEmpty(data)) {
      await this.setState({
        teamName: data.result.teamName,
        isNational: data.result.isNational,
        hasAnyEquipment: data.result.hasAnyEquipment,
      });
      await this.getPositions();
      await this.getLinkedTeams();
      await this.getChildTeams();
      this.resetTeamName();
    }
    $(".loading").hide();
  }
  async getLinkedTeams() {
    $(".loading").show();
    let uid = this.state.id;
    let data = await Services.LinkedTeams(uid);
    if (!_.isEmpty(data)) {
      await this.setState({ LinkedTeams: data.result });
    }
    $(".loading").hide();
  }
  async getChildTeams() {
    $(".loading").show();
    let uid = this.state.id;
    let data = await Services.ChildTeams(uid);
    if (!_.isEmpty(data)) {
      await this.setState({ childTeams: data.result });
    }
    $(".loading").hide();
  }

  async getPositions() {
    $(".loading").show();

    let data = await Services.TeamPositions(this.state.id);
    if (!_.isEmpty(data)) {
      this.state.positions = data.result;
      await this.setState({
        positions: this.state.positions,
        displayedPositions: this.state.positions,
      });

      this.setPositionNotExpandable();
    }
    $(".loading").hide();
  }
  createMailingList = () => {
    this.setState({ showCreateMgListModal: true });
  };
  toggleCreateMgListModal = () => {
    this.setState({ showCreateMgListModal: !this.state.showCreateMgListModal });
  };
  componentDidMount() {
    const id = this.props.match.params.Id;
    this.activityLogs.current.refreshLogs(id);
    if (this.state.id != 0) {
      this.getTeam();
      this.getTeamPermissionChangeStatus();
    }

    this.getPositions();

    //this.GetEmailNumbers();

    let teamId = this.state.id;
    if (teamId > 0) {
      let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${teamId}&entityType=${Constants.TEAM_EMAIL_TYPE_ID}`;
      $(".loading").show();
      axios.get(url).then(({ data }) => {
        $(".loading").hide();
        if (data.exists != undefined) {
          this.setState({
            emailListExists: data.exists,
            mgListAddress: data.mgListAddress,
            emailListId: data.emailListId,
            selectedPrivilege: data.selected_email_privilege_id,
          });
          this.props.setMgListFullAddress(data.mgListAddress);
        }
      });
    }
    this.showEmailConfigModal();
  }
  showEmailConfigModal = () => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const accordionParam = searchParams.get("showEmailConfigModal");
    // Check if the query parameter showEmailConfig is true
    if (accordionParam) {
      this.configureTeamsEmail();
    }
  };
  async componentWillReceiveProps(nextProps) {
    const { Id, parentId, isChild } = nextProps.match.params;
    const breadcrumbs =
      !_.isEmpty(nextProps.location.state) &&
      nextProps.location.state.hasOwnProperty("breadcrumbs")
        ? nextProps.location.state.breadcrumbs
        : [];
    if (this.state.id != Id) {
      clearTimeout(timeOutTask);
      this.state.id = Id;
      this.state.parentId = parentId;
      this.state.isChild = isChild;
      await this.setState({
        id: Id,
        parentId: this.state.parentId,
        isChild: this.state.isChild,
        positions: [],
        LinkedTeams: [],
        childTeams: [],
        reloadPosition: true,
        breadcrumbs: breadcrumbs,
      });
      this.getTeamPermissionChangeStatus();
      this.getTeam();
      let teamId = this.state.id;
      if (teamId > 0) {
        let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${teamId}&entityType=${Constants.TEAM_EMAIL_TYPE_ID}`;
        $(".loading").show();
        axios.get(url).then(({ data }) => {
          $(".loading").hide();
          if (data.exists != undefined) {
            this.setState({
              emailListExists: data.exists,
              mgListAddress: data.mgListAddress,
              emailListId: data.emailListId,
            });
          }
        });
      }
    }

    if (this.props.location !== nextProps.location) {
      //if (nextProps.match.params && nextProps.match.params.isChild) {
      const id = nextProps.match.params.Id;
      this.activityLogs.current.refreshLogs(id);
      //}
    }
  }
  componentDidUpdate() {
    if (this.state.reloadEverything) {
      this.state.reloadEverything = false;
      this.getTeam();
      this.activityLogs.current.fetchLogs();
      this.setState({ reloadEverything: this.state.reloadEverything });
    }
  }

  BeginChildTeamCreation = async (id, parentTeamName) => {
    $(".loading").show();
    let data = await Services.TeamScopes(id);
    let data2 = await Services.Team(id);
    if (data.statusCode == 200 && data2.statusCode == 200) {
      !_.isEmpty(data) &&
        (await this.setState({
          scopes: data.result,
          parentTeamName: parentTeamName,
          parentTeamId: id,
          isParentNational: data2.result.isNational,
        }));
      window.$("#CreateTeamModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    $(".loading").hide();
  };
  BeginTeamPermission = async (id, permissionType, parentTeamId) => {
    $(".loading").show();
    this.setState({
      permissionTeamId: id,
      parentTeamId: parentTeamId,
      permissionType: permissionType,
    });
    window.$("#TeamPermission").modal({
      backdrop: "static",
      keyboard: true,
      show: true,
    });

    $(".loading").hide();
  };
  BeginEditTeam = async () => {
    window.$("#EditPositionModal").modal({
      backdrop: "static",
      keyboard: true,
      show: true,
    });
  };

  AssignTeamData = async (id, parentTeamName) => {
    $(".loading").show();
    let data = await Services.TeamsByTeam(id);
    if (data.statusCode) {
      !_.isEmpty(data) &&
        (await this.setState({
          assignTeamData: data.result,
          parentTeamName: parentTeamName,
          parentTeamId: id,
        }));
      window.$("#AssignChildTeamModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }

    $(".loading").hide();
  };
  DeleteChildTeam = async (teamName, parentChildID) => {
    $(".loading").show();
    this.state.deleteChild = true;
    this.setState({
      deleteTeamName: teamName,
      deleteParentChildId: parentChildID,
      deleteChild: true,
    });
    window.$("#DeleteModalTeam").modal({
      backdrop: "static",
      keyboard: true,
      show: true,
    });

    $(".loading").hide();
  };
  confirmDeleteChildTeam = async () => {
    $(".loading").show();

    let data = await Services.DeleteChild(this.state.deleteParentChildId);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        toast.success("Child deleted successfully!");
        window.$("#DeleteModalTeam").modal("hide");

        this.setReloader();
      }
    }
    $(".loading").hide();
  };
  setReloader() {
    this.setState({ reloadEverything: true });
  }
  OverflowChildTeam() {
    let overflowY =
      $("#childTeamList").innerHeight() == 300 ? "scroll" : "visible";
    if (this.state.childTeamOverflowY != overflowY) {
      this.setState({ childTeamOverflowY: overflowY });
    }
  }
  listChildTeam = () => {
    const createHierarchy = (
      arr,
      showVal,
      parentTeamId,
      parentTeamName,
      breadcrumb
    ) => {
      let show = /*(this.state.searchChildTeamTerm == '') ? showVal : */ true;
      return arr.map((item, idx) => {
        //if (idx > 0 && this.state.searchChildTeamTerm == '') {
        //    show = false;
        //}
        let breadcrumbs = [];
        breadcrumbs.push(...breadcrumb);
        breadcrumbs.push({
          parentTeamName: parentTeamName,
          parentTeamId: parentTeamId,
        });

        return (
          <div>
            <div className="pl-2">
              <div className="card-text row mx-0 border px-3 py-1 w-100">
                <div
                  data-toggle="collapse"
                  href={"#collapse" + idx + item.id}
                  aria-expanded={show}
                  id={"heading" + idx + item.id}
                  aria-controls={"collapse" + idx + item.id}
                  className="mb-0 col-11 px-0 c-pointer "
                >
                  <Link
                    to={{
                      pathname:
                        "/permissions/teams/teamDetail/" +
                        item.id +
                        "/true/" +
                        parentTeamId,
                      state: { breadcrumbs: _.cloneDeep(breadcrumbs) },
                    }}
                  >
                    {item.teamName}
                  </Link>
                  <span className="float-right font-italic px-2">
                    {item.ownerName}
                  </span>
                </div>
                <div className="col-1 px-0">
                  <UncontrolledDropdown
                    setActiveFromChild
                    className="text-right dropdown-menu-toggle"
                  >
                    <DropdownToggle tag="a" className="nav-link-font-size">
                      <i
                        onClick={() => {
                          this.OverflowChildTeam();
                        }}
                        className="fas fa-ellipsis-h"
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-body">
                      {AuthService.canAdd(PermissionFeature.TeamsTeams) && (
                        <React.Fragment>
                          <DropdownItem
                            className="dropdown-item-custom"
                            onClick={() => {
                              this.BeginChildTeamCreation(
                                item.id,
                                item.teamName
                              );
                            }}
                          >
                            {" "}
                            <i className="fas fa-paperclip"></i> Create Child
                            Team{" "}
                          </DropdownItem>

                          <DropdownItem
                            className="dropdown-item-custom"
                            onClick={() => {
                              this.AssignTeamData(item.id, item.teamName);
                            }}
                          >
                            {" "}
                            <i className="fas fa-paperclip"></i> Link Existing
                            Team
                          </DropdownItem>
                        </React.Fragment>
                      )}
                      {AuthService.canView(
                        PermissionFeature.TeamsPermissions
                      ) && (
                        <DropdownItem
                          className="dropdown-item-custom"
                          onClick={() => {
                            this.BeginTeamPermission(item.id, null, null);
                          }}
                        >
                          {" "}
                          <i className="fas fa-lock"></i> Team Permissions
                        </DropdownItem>
                      )}
                      {AuthService.canDelete(PermissionFeature.TeamsTeams) && (
                        <DropdownItem
                          className="dropdown-item-custom"
                          onClick={() => {
                            this.DeleteChildTeam(
                              item.teamName,
                              item.parentChildID
                            );
                          }}
                        >
                          {" "}
                          <i className="fas fa-trash"></i> Delete Team
                        </DropdownItem>
                      )}

                      {/* {this.canConfigureTeamsEmail() === true &&
                        this.state.emailListExists === true && (
                          <DropdownItem
                            className="dropdown-item-custom"
                            onClick={this.configureTeamsEmail}
                          >
                            {" "}
                            <i className="fas fa-cog"></i> Configure Email{" "}
                          </DropdownItem>
                        )}

                      {this.canCreateTeamsEmail() === true &&
                        this.state.emailListExists === false && (
                          <DropdownItem
                            // className="dropdown-item-custom"
                            // onClick={this.createTeamsEmail}
                            className="dropdown-item-custom"
                            data-toggle="modal"
                            data-target="#FacilityTeamEmailListNameModal"
                            data-backdrop="static"
                          >
                            {" "}
                            <i className="fas fa-plus"></i> Create Email List{" "}
                          </DropdownItem>
                        )}

                      {AuthService.canDelete(
                        PermissionFeature.EmailListsLists
                      ) &&
                        this.state.emailListExists === true && (
                          <DropdownItem
                            className="dropdown-item-custom"
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Are you sure you want to delete email list?`
                                )
                              ) {
                                this.DeleteEmailList();
                              }
                            }}
                          >
                            {" "}
                            <i className="fas fa-trash"></i> Delete Email List{" "}
                          </DropdownItem>
                        )} */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              <div
                id={"collapse" + idx + item.id}
                className={"collapse" + (show ? " show" : "")}
              >
                {item.childs.length > 0 &&
                  createHierarchy(
                    item.childs,
                    false,
                    item.id,
                    item.teamName,
                    breadcrumbs
                  )}
              </div>
            </div>
          </div>
        );
      });
    };
    const filterChild = (search) => {
      return search
        .map((item) => {
          let haveChilds = false;
          if (item.childs.length > 0) {
            item.childs = filterChild(item.childs);
            if (item.childs.length > 0) {
              haveChilds = true;
            }
          }
          if (
            _.toLower(item.teamName).includes(
              _.toLower(this.state.searchChildTeamTerm || "")
            ) ||
            _.toLower(item.ownerName).includes(
              _.toLower(this.state.searchChildTeamTerm || "")
            ) ||
            haveChilds
          ) {
            return item;
          }
        })
        .filter(function(el) {
          return el;
        });
    };
    let searchChildTeam = filterChild(_.cloneDeep(this.state.childTeams));
    this.OverflowChildTeam();
    return createHierarchy(
      searchChildTeam,
      true,
      this.state.id,
      this.state.teamName,
      []
    );
  };

  resetTeamName() {
    this.setState({
      editTeam: false,
      editTeamName: this.state.teamName,
      editTeamName_err: "",
    });
  }
  handleChangeTeamName = (evt) => {
    this.state.editTeamName_err = this.notNullValidation(evt.target.value);
    this.state.editTeamName = evt.target.value;
    // if (!this.state.editTeamName_err) {
    //     this.state.editTeamName_err = this.textValidation(evt.target.value);
    // }
    this.setState({
      editTeamName: evt.target.value,
      editTeamName_err: this.state.editTeamName_err,
    });
  };
  async handleFormSubmitTeamName(e) {
    $(".loading").show();
    if (!this.notNullValidation(this.state.editTeamName)) {
      let data = await Services.EditTeam({
        id: this.state.id,
        teamName: this.state.editTeamName,
      });
      if (!_.isEmpty(data)) {
        if (data.statusCode == 200) {
          toast.success("Team edited successfully!");
          this.setState({ editTeam: false });
          this.setReloader();
        }
      }
    } else {
      toast.error("Please provide appropriate team name.");
    }
    $(".loading").hide();
  }

  textValidation = (text) => {
    let textReg = /^[a-zA-z ]*$/;
    const text_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Please provide only text.",
      },
    ];
    if (textReg.test(text)) {
      return text_States.find(({ state }) => state == "Valid").error;
    } else {
      return text_States.find(({ state }) => state == "Not_Match_To_Regex")
        .error;
    }
  };
  notNullValidation(text) {
    if (text == null || text == "") {
      return "Please provide the above value";
    }
    return null;
  }
  createBreadCrumbs() {
    let breadcrumbsString = "";
    if (!_.isEmpty(this.state.breadcrumbs)) {
      return (
        <div>
          {this.state.breadcrumbs.map((item, index) => (
            <>
              {index > 0 ? " / " : ""}
              <a href={"/permissions/teams/teamDetail/" + item.parentTeamId}>
                {item.parentTeamName}
              </a>
            </>
          ))}
          {" / "}
          {this.state.teamName}
        </div>
      );
    }
    return <div></div>;
  }
  onDeleteEmailList = () => {
    this.setState({
      emailListExists: false,
      emailListId: 0,
    });
  };
  createTeamsEmail = (emailAlias) => {
    //debugger;
    let teamId = this.state.id;
    if (teamId > 0) {
      $(".loading").show();

      let url = `/api/FacilityRegionEmail/CreateEntityEmail?emailAlias=${emailAlias}&entityId=${teamId}&entityType=${Constants.TEAM_EMAIL_TYPE_ID}`;

      axios.post(url, []).then(({ data }) => {
        $(".loading").hide();
        if (
          data &&
          data.status != undefined &&
          data.status.trim().toLowerCase() === "success"
        ) {
          toast.success(data.message);
          this.setState({
            emailListExists: true,
            emailListId: data.emailListId,
          });
        } else {
          toast.error(data.message);
        }
        // $("#FacilityTeamEmailListNameModal").hide();
        // $(".modal-backdrop").hide();
      });
    }
  };

  configureTeamsEmail = () => {
    ///Open Modal for Configue Email List
    this.setState({
      showEmailMembersModal: true,
    });

    let teamId = this.state.id;
    if (teamId > 0) {
      let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${teamId}&entityType=${Constants.TEAM_EMAIL_TYPE_ID}`;
      $(".loading").show();
      axios.get(url).then(({ data }) => {
        $(".loading").hide();
        if (data.exists != undefined) {
          this.setState({
            emailListExists: data.exists,
            mgListAddress: data.mgListAddress,
            emailListId: data.emailListId,
            selectedPrivilege: data.selected_email_privilege_id,
            listPrivilige: data.selectedPrivilege,
          });
          this.props.setReplyPref(data.reply_preference);
          this.props.setCopyToSender(data.copyToSender);
          this.props.setPrivilege(data.selectedPrivilege);
          this.props.setEmailListName(data.email_list_title);
        }
      });
    }
  };

  DeleteEmailList = () => {
    $(".loading").show();
    let url = `/api/FacilityRegionEmail/DeleteEmailList?emailListId=${this.state.emailListId}`;
    axios.delete(url).then(({ data: response }) => {
      $(".loading").hide();
      if (response.status === "success") {
        toast.success("Email list removed successfully!");
        this.setState({
          emailListExists: false,
        });
      } else {
        toast.error(response.message);
      }
    });
  };

  mailGunMemberListClosed = () => {
    this.setState({
      showEmailMembersModal: false,
    });
    this.activityLogs.current.fetchLogs();
  };

  canCreateTeamsEmail = () => {
    return true;
  };

  canConfigureTeamsEmail = () => {
    return true;
  };

  canSendEmail = () => {
    return AuthService.canView(Permissions.Email);
  };

  canSendSMS = () => {
    return AuthService.canView(Permissions.Text);
  };

  onEmail = () => {
    this.setState({ showModal: true, check: CompositionModalMode.Email });

    var rowsToAdd = [];
    var email = JSON.parse(JSON.stringify(this.state.positions));
    for (let i = 0; i < email.length; i++) {
      for (let j = 0; j < email[i].members.length; j++) {
        if (email[i].members[j].email != null) {
          if (
            email[i].members[j].allowemails === true &&
            rowsToAdd.findIndex((x) => x == email[i].members[j].email) == -1
          ) {
            rowsToAdd.push(email[i].members[j].email);
          }
        }
      }
    }
    if (rowsToAdd.length > 0) {
      this.setState({ emails: rowsToAdd });
    } else {
      toast.warn(
        "This list doesn't contains any member who has primary email."
      );
      this.setState({ showModal: false });
    }
  };

  onSMS = () => {
    var rowsToAdd = [];
    this.setState({ showModal: true, check: CompositionModalMode.SMS });
    var positions = JSON.parse(JSON.stringify(this.state.positions));
    for (let i = 0; i < positions.length; i++) {
      if (positions[i].members != null && !_.isEmpty(positions[i].members)) {
        for (let j = 0; j < positions[i].members.length; j++) {
          if (positions[i].members[j].number !== null) {
            if (positions[i].members[j].allowtextmessages === true) {
              rowsToAdd.push(positions[i].members[j].number);
            }
          }
        }
      }
    }
    if (rowsToAdd.length > 0) {
      this.setState({ emails: rowsToAdd });
    } else {
      toast.warn(
        "This list doesn't contains any member who has verified number."
      );
      this.setState({ showModal: false });
    }
  };
  async filterPositionMembers(positions) {
    if (_.trim(this.state.searchPositionTerm)) {
      const filterMemberPosition = (search) => {
        // we maps all the positions to filter member search
        return (
          search
            .map((item) => {
              //if position have member then processed
              if (item.members.length > 0) {
                //filter members who have search values
                item.members = item.members
                  .map(
                    (itemQ) => {
                      //split the member name on space for achieve first name and last name
                      let memberName = itemQ.memberName.split(" ");
                      //finding the space in search term to filter both first and last name
                      if (this.state.searchPositionTerm.indexOf(" ") > 0) {
                        //spliting search term for first and last name
                        let name = this.state.searchPositionTerm.split(" ");
                        //checking:
                        // first name membername[0] start with search term name[0]
                        // last name membername[1] start with search term name[1]
                        // if condition pass then return member
                        if (
                          _.startsWith(
                            _.toLower(memberName[0]),
                            _.toLower(name[0] || "")
                          ) &&
                          _.startsWith(
                            _.toLower(memberName[1]),
                            _.toLower(name[1] || "")
                          )
                        ) {
                          return itemQ;
                        }
                      } // end of IF space in search term
                      //if no space then filter from last name
                      else {
                        //checking:
                        // last name membername[1] start with search term.
                        // if condition pass then return member
                        if (
                          _.startsWith(
                            _.toLower(memberName[1]),
                            _.toLower(this.state.searchPositionTerm || "")
                          )
                        ) {
                          return itemQ;
                        }
                      } // end of ELSE space in search term
                    } // end of member filter
                    //filtering the not null members
                  )
                  .filter(function(el) {
                    return el;
                  }); //end of filtering the not nul members
                //if position have any member after filter then return position
                if (item.members.length > 0) {
                  return item;
                } //end if position have any member after filter then return position
              } //end if position have any member then process
            }) // end of position map
            //filtering the not null positions
            .filter(function(el) {
              return el;
            })
        );
      };

      let filteredPosition = filterMemberPosition(_.cloneDeep(positions));
      if (this.state.searchPositionTerm != this.state.prevSearchPositionTerm) {
        let expanded = [];
        filteredPosition.map((item) => {
          if (item.members.length > 1) {
            expanded.push(item.id);
          }
        });
        this.setState({ expanded: expanded });

        this.setState({
          prevSearchPositionTerm: this.state.searchPositionTerm,
        });
      }
      if (
        JSON.stringify(this.state.displayedPositions) !=
        JSON.stringify(filteredPosition)
      ) {
        await this.setState({ displayedPositions: filteredPosition });
      }
    } else {
      //if (
      //  JSON.stringify(this.state.expanded) !=
      //  JSON.stringify(this.state.backUpExpanded)
      //) {
      //  this.state.expanded = _.cloneDeep(this.state.backUpExpanded);
      //  this.setState({ expanded: this.state.expanded });
      //  }
      //  if (JSON.stringify(this.state.displayedPositions) != JSON.stringify(positions)) {
      //      await this.setState({ displayedPositions: positions});
      //  }

      if (
        JSON.stringify(this.state.displayedPositions) !=
        JSON.stringify(positions)
      ) {
        await this.setState({ displayedPositions: positions });
      }
    }
  }
  // for checking the expands of position member
  checkExpandables(positions) {
    if (
      JSON.stringify(this.state.expanded) !=
      JSON.stringify(this.state.backUpExpanded)
    ) {
      this.state.expanded = _.cloneDeep(this.state.backUpExpanded);
      this.setState({ expanded: this.state.expanded });
    }
    if (positions) {
      return positions;
    } else {
      return [];
    }
  }

  render() {
    var { Id } = this.props.match && this.props.match.params;
    return (
      <AUX>
        <PermissionHeader
          breadcrumbs={
            !_.isEmpty(this.state.breadcrumbs) && this.state.breadcrumbs
          }
          BeginTeamPermission={() => {
            if (this.state.isChild) {
              this.BeginTeamPermission(
                this.state.id,
                "childteam",
                this.state.parentId
              );
            } else {
              this.BeginTeamPermission(this.state.id, null, null);
            }
          }}
          AssignTeamData={() => {
            this.AssignTeamData(this.state.id, this.state.teamName);
          }}
          EditPositionModal={() => {
            this.props.openEditPositionModal(true);
            this.setState({ position: null });
          }}
          BeginChildTeamCreation={() => {
            this.BeginChildTeamCreation(this.state.id, this.state.teamName);
          }}
          onDeleteEmailList={this.DeleteEmailList}
          configureTeamsEmail={this.configureTeamsEmail}
          createTeamsEmail={this.createTeamsEmail}
          exists={this.state.emailListExists}
          //New Props for Create Email Modal
          toggleModal={this.toggleCreateMgListModal}
        />
        <FacilityTeamEmailListNameModal
          showModal={this.state.showCreateMgListModal}
          toggleModal={this.toggleCreateMgListModal}
          createEmail={this.createTeamsEmail}
        />
        <div className="team-container container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              {!this.state.editTeam ? (
                <div
                  className="col"
                  id="team-inline"
                  style={{ marginBottom: 1 + "em" }}
                >
                  {!_.isEmpty(this.state.breadcrumbs) &&
                    this.createBreadCrumbs()}
                  <h3>
                    {this.state.teamName}{" "}
                    {AuthService.canUpdate(PermissionFeature.TeamsTeams) && (
                      <i
                        className="fas fa-pencil-alt h4 mb-0 cursorPointer"
                        onClick={() => {
                          this.setState({ editTeam: true });
                        }}
                      ></i>
                    )}{" "}
                    {this.canSendEmail() === true && (
                      <i
                        class="fas fa-envelope cursorPointer"
                        onClick={this.onEmail}
                        //title={`Send email to members ${this.state.data.length}`}
                      ></i>
                    )}{" "}
                    {this.canSendSMS() === true && (
                      <i
                        class="fas fa-sms cursorPointer"
                        onClick={this.onSMS}
                        title="Send SMS to members"
                      ></i>
                    )}{" "}
                  </h3>
                </div>
              ) : (
                <div className="col-lg-6" id="team-inline">
                  <div className="d-block w-100 pr-4 position-absolute text-right">
                    <i
                      className="c-pointer text-black-50 fas fa-times"
                      onClick={() => {
                        this.resetTeamName();
                      }}
                    ></i>
                  </div>
                  <MDBInput
                    value={this.state.editTeamName}
                    name="teamName"
                    onChange={this.handleChangeTeamName}
                    className="bold-input"
                    containerClass="mt-0 mb-2"
                    group
                    type="text"
                    hint="Please Provide Team Name"
                    required
                  >
                    <div className="invalid-feedback">Name is required.</div>
                  </MDBInput>
                  <p className="text-danger">{this.state.editTeamName_err}</p>

                  <div className="mt-2">
                    <button
                      onClick={this.handleFormSubmitTeamName}
                      className="btn btn-success btn-sm mr-1 px-3 waves-effect waves-light"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-sm btn-danger px-3 waves-effect waves-light"
                      type="button"
                      onClick={() => {
                        this.resetTeamName();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              {this.BulkPermissionAlert()}
              {this.BulkPermisssionProgress()}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 px-lg-2">
              {AuthService.canView(PermissionFeature.TeamsPositions) && (
                <div className="card directory-card border-rounded shadow">
                  <h5 className="card-header mt-0  px-3 py-1">Positions</h5>
                  <div className="card-body pb-2 px-0 pt-0">
                    <form role="search" className="app-search px-2 my-2">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          id="positionKeyword"
                          className="form-control rounded border mb-1 py-2 w-100"
                          placeholder="Member Search.."
                          autoComplete="off"
                          value={this.state.searchPositionTerm || ""}
                          onChange={async (event) => {
                            await this.setState({
                              searchPositionTerm: event.target.value,
                            });
                            await this.filterPositionMembers(
                              this.state.positions
                            );
                          }}
                        />
                        <button
                          onClick={async (event) => {
                            await this.setState({
                              searchPositionTerm: $("#positionKeyword").val(),
                            });

                            await this.filterPositionMembers(
                              this.state.positions
                            );
                          }}
                          className="c-pointer text-secondary bg-transparent border-0"
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </form>
                    <div className="permissionTablePagination positionList">
                      {this.RemotePagination(
                        this.columns(),
                        "id",
                        //this.state.positions.filter(
                        //    (itemQ) =>
                        //        _.toLower(itemQ.positionName).includes(
                        //            _.toLower(this.state.searchPositionTerm || "")
                        //        ) ||
                        //        _.toLower(itemQ.memberName).includes(
                        //            _.toLower(this.state.searchPositionTerm || "")
                        //        )
                        //),
                        this.checkExpandables(this.state.displayedPositions),
                        this.positionSizePerPageChange,
                        this.state.sizePerPagePositions,
                        this.rowEvents,
                        this.expandRow()
                      )}
                    </div>
                  </div>
                </div>
              )}
              {this.state.hasAnyEquipment &&
                AuthService.canView(PermissionFeature.FETeamEquipment) && (
                  <EquipmentsCardList
                    recordId={Id}
                    tableType={FE_Equipment_Card_Types.Team}
                  />
                )}
            </div>

            <div className="col-lg-6 px-lg-2">
              {!_.isEmpty(this.state.LinkedTeams) && (
                <div className="card directory-card border-rounded shadow">
                  <h5 className="card-header mt-0  px-3 py-1">Linked Teams</h5>
                  <div className="card-body pb-0 px-0 pt-0">
                    <form role="search" className="app-search px-2 my-2">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          id="linkedTeamKeyword"
                          className="form-control rounded border mb-1 py-2 w-100"
                          placeholder="Linked Team Search.."
                          autoComplete="off"
                          value={this.state.searchLinkedTeamTerm || ""}
                          onChange={(event) =>
                            this.setState({
                              searchLinkedTeamTerm: event.target.value,
                            })
                          }
                        />
                        <button
                          onClick={() => {
                            this.setState({
                              searchLinkedTeamTerm: $(
                                "#linkedTeamKeyword"
                              ).val(),
                            });
                          }}
                          className="c-pointer text-secondary bg-transparent border-0"
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </form>
                    <div className="permissionTablePagination">
                      {this.RemotePagination(
                        this.teamColumns,
                        "id",
                        this.state.LinkedTeams.filter(
                          (itemQ) =>
                            _.toLower(itemQ.teamName).includes(
                              _.toLower(this.state.searchLinkedTeamTerm || "")
                            ) ||
                            _.toLower(itemQ.ownerName).includes(
                              _.toLower(this.state.searchLinkedTeamTerm || "")
                            )
                        ),
                        this.linkedTeamsSizePerPageChange,
                        this.state.sizePerPageLinkedTeams,
                        this.LinkedTeamRowEvents
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!_.isEmpty(this.state.childTeams) && (
                <div className="card directory-card border-rounded shadow">
                  <h5 className="card-header mt-0  px-3 py-1">Child Teams</h5>
                  <div className="card-body px-2 pt-0">
                    <form role="search" className="app-search px-2 my-2">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          id="childTeamKeyword"
                          className="form-control rounded border mb-1 py-2 w-100"
                          placeholder="Child Team Search.."
                          autoComplete="off"
                          value={this.state.searchChildTeamTerm || ""}
                          onChange={(event) =>
                            this.setState({
                              searchChildTeamTerm: event.target.value,
                            })
                          }
                        />
                        <button
                          onClick={() => {
                            this.setState({
                              searchChildTeamTerm: $("#childTeamKeyword").val(),
                            });
                          }}
                          className="c-pointer text-secondary bg-transparent border-0"
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </form>
                    <div
                      id="childTeamList"
                      style={{ overflowY: this.state.childTeamOverflowY }}
                      className="pr-2"
                    >
                      {this.listChildTeam()}
                    </div>
                  </div>
                </div>
              )}

              <TeamActivityLogging
                getActicityLoggingByModuleId={
                  this.props.getActicityLoggingByModuleId
                }
                ref={this.activityLogs}
                toggleTeamLogAccordion={this.props.toggleTeamLogAccordion}
                auditState={this.props.activityLoggingState}
                paramId={Id}
              />
            </div>
          </div>
        </div>
        {!_.isEmpty(this.state.scopes) && (
          <Fragment>
            <EditTeam
              setReloader={this.setReloader}
              parentTeamName={this.state.parentTeamName}
              parentTeamId={this.state.parentTeamId}
              isParentNational={this.state.isParentNational}
              scopes={this.state.scopes}
              type="childteam"
            />
          </Fragment>
        )}
        <PositionPSModal
          isNational={this.state.isNational}
          reloadPosition={this.state.reloadPosition}
          setReloader={this.setReloader}
          setReloadPositiontoFalse={this.setReloadPositiontoFalse}
          position={this.state.position}
          teamName={this.state.teamName}
          teamId={this.state.id}
        />
        <EditPositionModal
          teamId={this.state.id}
          setReloader={this.setReloader}
          position={this.state.position}
        />
        <AssignMemberModal
          teamId={this.state.id}
          setReloader={this.setReloader}
          position={this.state.position}
          onlyDates={this.state.assignMemberOnlyDates}
          member={this.state.member}
        />
        <AssignChildTeam
          assignTeamData={this.state.assignTeamData}
          setReloader={this.setReloader}
          teamId={this.state.parentTeamId}
          parentTeamName={this.state.parentTeamName}
        />
        {/*(this.state.deleteChild) && ()*/}
        <ConfirmDeleteModal
          confirm={this.confirmDeleteChildTeam}
          teamName={this.state.deleteTeamName}
          deleteChild={true}
        />
        <DeleteTeam
          parentTeamName={this.state.teamName}
          setReloader={this.setReloader}
          parentTeamId={this.state.id}
          childTeams={this.state.childTeams}
          fatherTeam={
            this.props.match.params && this.props.match.params.parentId
          }
          isChild={this.props.match.params && this.props.match.params.isChild}
        />
        <LinkedPosition
          positionName={this.state.linkedPositionName}
          positionId={this.state.linkedPositionId}
          positionMemberCount={this.state.linkedPositionMemberCount}
          setReloader={this.setReloader}
          isNational={this.state.isNational}
          teamId={this.state.id}
        />
        <ConfirmDeletePositionModal
          confirm={(isHistory) => {
            this.handleRemovePosition(this.state.deletePosition, isHistory);
          }}
          positionName={
            this.state.deletePosition
              ? this.state.deletePosition.positionName
              : ""
          }
        />
        <ConfirmDeleteLinkedPositionModal
          confirm={() => {
            this.handleRemoveLinkedPosition(this.state.deleteLinkedPosition);
          }}
          positionName={
            this.state.deleteLinkedPosition
              ? this.state.deleteLinkedPosition.positionName
              : ""
          }
        />
        <TeamPermission
          permissionTeamId={this.state.permissionTeamId}
          type={this.state.permissionType}
          setReloadPosition={this.setReloadPosition}
          parentTeamId={this.state.parentTeamId}
          BulkProcessStart={this.getTeamPermissionChangeStatus}
          ReloadActivityLogging={async () =>
            this.activityLogs.current.fetchLogs()
          }
        />
        <CompositionModal
          showModal={this.state.showModal}
          onClose={this.hideModal}
          check={this.state.check}
          addresses={
            this.state.check == CompositionModalMode.Email
              ? [...this.state.emails, ...this.props.melstate.addtlMemEmails]
              : this.state.emails
          }
          listType={MailGunEmailTypes.Team}
          entityId={Id} // Facility Id
          getAdditionalSubsribersEmailAddresses={
            this.props.getAdditionalSubsribersEmailAddresses
          }
          isFRTCS={true}
        />
        <MailGunMembersListModal
          showModal={this.state.showEmailMembersModal}
          onModalClose={this.mailGunMemberListClosed}
          mgListAddress={this.state.mgListAddress}
          TeamCheck={true}
          TeamId={this.state.id}
          isTeam={true}
          MailGunEmailType={MailGunEmailTypes.Team}
          emailListId={this.state.emailListId}
          teamName={this.state.teamName}
          onDeleteEmailList={this.onDeleteEmailList}
          isRegistration={false}
        ></MailGunMembersListModal>
      </AUX>
    );
  }
}

export default connect(
  (state) => ({
    activityLoggingState: state.activityLogging,
    permissionState: state.permission,
    melstate: state.mailGunEmail,
  }),
  {
    ...PermissionAction,
    ...actionActivity,
    ...mailGunEmailActions,
  }
)(TeamDetail);
