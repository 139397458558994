import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
class ConfirmDeleteModal extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextprops) {}
  render() {
    return (
      <>
        <Modal
          centered={true}
          autoFocus={true}
          isOpen={this.props.openModal}
          className="styled-modal"
          backdrop="static"
          // size='sm'
        >
          <ModalHeader
            className="modal-header bg-secondary py-2"
            toggle={this.props.closeModal}
          >
            Confirm Delete
          </ModalHeader>
          <ModalBody>
            <div className="col-12 pb-2 text-danger text-center">
              <span className="h1 fa fa-trash-alt" />
            </div>
            <div className="col-12 mb-4">
              <p className="h5 text-center">
                Are you sure you want to delete this {this.props.content}?
              </p>
            </div>
            <div className="col-12 text-center mb-3 border-top pt-4">
              <button
                className="btn btn-outline-secondary w-md waves-effect cancelModal waves-light mr-2"
                onClick={() => {
                  this.props.closeModal();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-outline-danger w-md waves-effect waves-light"
                onClick={this.props.confirm}
              >
                Delete
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ConfirmDeleteModal;
