// eslint-disable-next-line jsx-a11y/label-has-associated-control
import React from "react";
import "./spinner.scss";

const Spinner = () => (
  <div className="spinner_noti">
    <div className="loaders_noti">Loading...</div>
  </div>
);

export default Spinner;
