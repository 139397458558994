import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Payfile/payfilestyle.css";
import { connect } from "react-redux";
import FinanceAction from "../../../store/finance/action";
import { makeStyles } from "@material-ui/core/styles";
import Services from "../../Payfile/PayfileService";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { debounce } from "throttle-debounce";
import TextField from "@material-ui/core/TextField";
import AuthService from "../../Auth/AuthService";
import FinanceService from "../FinanceService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { MDBInput } from "mdbreact";
import { toast } from "react-toastify";
import $ from "jquery";
let formate_textbox_amount = (n) => {
  var num_parts = n
    .toString()
    .replaceAll(",", "")
    .split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
  //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};
let addFormObject = {
  Id: null,
  Mode: null,
  MemberID: null,
  FacilityId: null,
  ExpectedAmount: null,
  Amount: null,
  MemberNumber: null,
  Refund: false,
  DuesCollectionTypeId: null,
  DuesPostedTypeId: 2,
  FinanceTypeId: null,
  RegionId: null,
  Period: null,
  Year: null,
  CheckNumber: null,
  AddTransaction: null,
  TransactionDate: null,
};
let updateFormObject = {
  Id: null,
  Mode: null,
  MemberID: null,

  FacilityId: null,
  ExpectedAmount: null,
  MemberNumber: null,
  DuesCollectionTypeId: null,
  DuesPostedTypeId: 2,
  FinanceTypeId: null,
  RegionId: null,
  Period: null,
  Year: null,
};
let formObject = addFormObject;

class FinanceFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberId: -1,
      //FinanceID
      FI: -1,
      FacilityObj: null,
      openModal: false,
      updateData: [],
      Members: [],
      FacilityCode: null,
      FormCode: -1,
      SubmissionObj: _.clone(formObject),
      BackUp_SubmissionObj: {},
      DuesCollectionTypes: [],
      DuesPostedTypes: [],
      FinanceTypes: [],
      Facilities: [],
      AddForm: true,
      //            Employers: [],
      FinanceLoader: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.LoadUpdatesData = this.LoadUpdatesData.bind(this);
    this.changeHandlerDropdown = this.changeHandlerDropdown.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.submitForm = this.submitForm.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.handleChangeRefund = this.handleChangeRefund.bind(this);
    this.handleChangeAddTransaction = this.handleChangeAddTransaction.bind(
      this
    );
    this.loadDropdowns = this.loadDropdowns.bind(this);
    this.handlePeriodYearChange = this.handlePeriodYearChange.bind(this);
    this.PeriodYearValidation = this.PeriodYearValidation.bind(this);
    this.amountValidation = this.amountValidation.bind(this);
    this.dateValidate = this.dateValidate.bind(this);
    //        this.SetFacilityObj = this.SetFacilityObj.bind(this);
  }
  dateValidate(date, name, state) {
    let error = "Please provide " + name + ".";
    if (moment(date).isValid()) {
      error = "";
    }
    this.state.SubmissionObj[state] = error;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
    return error;
  }
  ///**
  // * We have to set facility in an objec instead or putting value directly in autocomplete. Because autocomplete set only state values*/
  //async SetFacilityObj() {
  //    let { Facilities } = this.state;
  //    let facilityObj = !_.isEmpty(Facilities) && Facilities.find(x => x.id == this.state.SubmissionObj.FacilityId);
  //    if (JSON.stringify(facilityObj) != JSON.stringify(this.state.FacilityObj)) {

  //        await this.setState({FacilityObj: facilityObj});
  //    }
  //}
  async ResetObject() {
    let obj = _.clone(formObject);
    //in case objec already reset then not update the state for componentdidupdate method
    if (JSON.stringify(obj) != JSON.stringify(this.state.SubmissionObj)) {
      await this.setState({
        MemberId: -1,
        //FinanceID
        FI: -1,
        AddForm: true,
        SubmissionObj: obj,
        BackUp_SubmissionObj: _.cloneDeep(obj),
      });
    }
  }
  MemberNumberSearchHandler = (SearchTerms) => {
    this.setState({ searchTerm: SearchTerms }, () => {
      this.autocompleteSearchDebounced();
    });
  };
  loadMemberData = async (SearchTerms) => {
    let data = await Services.LoadFinanceMembers(SearchTerms, 0, 5);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.setState({ Members: data.result });
      }
    }
  };

  componentDidMount() {
    this.loadMemberData("");
    this.loadDropdowns();
  }
  async loadDropdowns() {
    let data = await FinanceService.FinanceDropdowns();
    await this.setState({
      FinanceLoader: true,
    });

    if (data != null) {
      this.state.DuesCollectionTypes = data.result.duesCollectionTypeDD;
      this.state.DuesPostedTypes = data.result.duesPostedTypeDD;
      this.state.FinanceTypes = data.result.financeTypeDD;
      this.state.Facilities = data.result.facilityDD;
      //this.state.Employers = data.result.employerDD;
    }
    await this.setState({
      DuesCollectionTypes: this.state.DuesCollectionTypes,
      DuesPostedTypes: this.state.DuesPostedTypes,
      Facilities: this.state.Facilities,
      FinanceTypes: this.state.FinanceTypes,
      //Employers : this.state.Employers,
      FinanceLoader: false,
    });
  }
  async LoadUpdatesData() {
    let data = await FinanceService.GetFinanceSingleObject(this.state.FI);
    await this.setState({
      FinanceLoader: true,
    });

    let obj = _.cloneDeep(this.state.SubmissionObj);
    if (data != null) {
      obj.Id = this.state.FI;
      obj.MemberID = data.memberID; //--
      obj.ExpectedAmount = data.expectedAmount; //--
      obj.MemberNumber = data.memberNumber;
      obj.DuesCollectionTypeId = data.duesCollectionTypeId; //--
      obj.DuesPostedTypeId = data.duesPostedTypeId;
      obj.FinanceTypeId = data.financeTypeId;
      obj.Year = data.year;
      obj.Period = data.period;
      obj.FacilityId = data.facilityId;
      obj.RegionId = data.regionId;
    } else {
      await this.ResetObject();
    }
    await this.setState({
      SubmissionObj: obj,
      BackUp_SubmissionObj: _.cloneDeep(obj),
      FinanceLoader: false,
    });
  }
  handleChangeRefund = (e) => {
    this.state.SubmissionObj.Refund = e.target.checked;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  handleChangeAddTransaction = (e) => {
    this.state.SubmissionObj.AddTransaction = e.target.checked;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  amountValidation(amount, fieldName) {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    let error = "";
    if (!amount || amount == null || amount == "" || amount == undefined) {
      error = "Invalid Amount!";
    } else if (amountReg.test(amount)) {
      error = "";
    } else {
      error = "Invalid amount";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  handlePeriodYearChange = (evt) => {
    this.PeriodYearValidation(evt.target.value, evt.target.name);
    this.state.SubmissionObj[evt.target.name] = evt.target.value;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  PeriodYearValidation(val, fieldName) {
    let error = "";
    if (!val || val == null || val == "" || val < 1) {
      error = "Invalid Value!";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  handleAmountChange = (evt) => {
    if (evt.target.name == "ExpectedAmount" || evt.target.name == "Amount") {
      this.amountValidation(
        evt.target.value.replaceAll(",", ""),
        evt.target.name
      );
      this.state.SubmissionObj[evt.target.name] = evt.target.value.replaceAll(
        ",",
        ""
      );
    } else {
      this.state.SubmissionObj[evt.target.name] = evt.target.value.replaceAll(
        ",",
        ""
      );
    }

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };

  changeHandlerDropdown = (e) => {
    this.state.SubmissionObj[e.target.name] = e.target.value;

    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    this.DropDownValidate(e.target.value, e.target.name);
  };

  changeHandler_amount = (e) => {
    this.state.SubmissionObj[e.target.name] = e.target.value;
    this.state.SubmissionObj[e.target.name + "_err"] = this.amountValidation(
      e.target.value
    ).error;

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };

  async handleChangeMemberNumber(value) {
    if (this.state.SubmissionObj != null) {
      if (value != null && value != undefined && value != "") {
        this.state.SubmissionObj.MemberNumber = value.memberNumber;
        this.state.SubmissionObj.MemberID = value.id;
        this.state.SubmissionObj.FacilityId = value.facilityID;
        this.state.FacilityCode = value.facility;
        this.state.SubmissionObj.RegionId = value.regionID;
      } else {
        this.state.SubmissionObj.MemberNumber = null;
        this.state.SubmissionObj.MemberID = null;
        this.state.SubmissionObj.FacilityId = null;
        this.state.FacilityCode = null;
        this.state.SubmissionObj.RegionId = null;
      }

      this.MemberIdValidate(this.state.SubmissionObj.MemberID);
      this.setState({
        SubmissionObj: this.state.SubmissionObj,
        FacilityCode: this.state.FacilityCode,
      });
    }
  }
  MemberIdValidate(val) {
    let error = "";
    if (val == null || val == undefined || val == "") {
      error = "Please, provide appropriate Member Number.";
    }
    this.state.SubmissionObj.MemberNumber_err = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  DropDownValidate(val, fieldName) {
    let error = "";
    if (!val || val == null || val == "" || val < 1) {
      error = "Please, select appropriate value";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }

  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.loadMemberData(this.state.searchTerm);
  };
  async submitForm() {
    let obj = this.state.SubmissionObj;
    if (
      this.MemberIdValidate(obj.MemberID) == "" &&
      this.DropDownValidate(obj.DuesCollectionTypeId, "DuesCollectionTypeId") ==
        "" &&
      this.DropDownValidate(obj.DuesPostedTypeId, "DuesPostedTypeId") == "" &&
      this.amountValidation(obj.ExpectedAmount, "ExpectedAmount") == "" &&
      this.PeriodYearValidation(obj.Period, "Period") == "" &&
      this.PeriodYearValidation(obj.Year, "Year") == "" &&
      this.DropDownValidate(obj.FinanceTypeId, "FinanceTypeId") == "" &&
      this.DropDownValidate(obj.FacilityId, "FacilityId") == "" &&
      (!obj.AddTransaction ||
        (obj.AddTransaction == true &&
          this.amountValidation(obj.Amount, "Amount") == ""))
    ) {
      $(".loading").show();
      var data = await FinanceService.FinanceUpdate(this.state.SubmissionObj);
      $(".loading").hide();
      if (data && data.statusCode == 200) {
        this.props.refreshFinanceTable();
        this.closeModal();
      }
    }
  }

  async componentDidUpdate(prevprops) {
    if (this.props.Finance.openFinanceModal) {
      if (this.props.Finance.FinanceFormData !== null) {
        let { FinanceFormData } = this.props.Finance;
        if (
          FinanceFormData.id != this.state.FI ||
          this.state.MemberId != FinanceFormData.memberID
        ) {
          formObject = updateFormObject;
          await this.setState({
            FI: FinanceFormData.id,
            AddForm: false,
            MemberId: FinanceFormData.memberID,
            FinanceLoader: true,
            SubmissionObj: _.cloneDeep(updateFormObject),
          });
          this.LoadUpdatesData();
        }
      } else {
        formObject = addFormObject;
        await this.ResetObject();
        //if (this.props.Finance.mode == "Facility") {
        //    this.state.SubmissionObj.FacilityId = this.props.Finance.facilityId;
        //    this.state.SubmissionObj.RegionId = this.props.Finance.regionId;
        //    this.state.FacilityCode = this.props.Finance.facilityCode;
        //    this.state.SubmissionObj.Mode = this.props.Finance.mode;
        //    await this.setState({
        //        SubmissionObj: this.state.SubmissionObj,
        //        FacilityCode: this.state.FacilityCode
        //    });
        //}
        if (this.props.Finance.mode == "Member") {
          this.state.SubmissionObj.Mode = this.props.Finance.mode;
          this.state.SubmissionObj.MemberID = this.props.Finance.memberId;
          await this.setState({
            SubmissionObj: this.state.SubmissionObj,
          });
        }
      }

      this.openModal();
    }
  }

  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeFinanceModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
    // to clear the header
    this.props.clearFinanceFormMemberHeader();
  }
  cancelUpdate() {
    this.setState({
      SubmissionObj: this.state.BackUp_SubmissionObj,
      FormCode: -1,
    });
    this.closeModal();
  }

  render() {
    if (!AuthService.canUpdate(PermissionFeature.FinancesRecords)) {
      return false;
    }
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          fontSize: 18,
        },
      },
    });
    let {
      DuesCollectionTypes,
      /*Employers,*/ Facilities,
      Members,
      DuesPostedTypes,
      FinanceTypes,
    } = this.state;
    let obj = this.state.SubmissionObj;
    let { MemberHeaderData } = this.props.Finance;
    let header = "";

    if (MemberHeaderData != null) {
      header =
        " - " +
        (MemberHeaderData.firstName || "").toUpperCase() +
        " " +
        (MemberHeaderData.lastName || "").toUpperCase() +
        " (" +
        (!_.isEmpty(MemberHeaderData.regionCode)
          ? MemberHeaderData.regionCode
          : "--") +
        "/" +
        (!_.isEmpty(MemberHeaderData.facilityCode)
          ? MemberHeaderData.facilityCode
          : "--") +
        ")";
    }
    return (
      ((this.state.AddForm &&
        AuthService.canAdd(PermissionFeature.FinancesRecords)) ||
        (!this.state.AddForm &&
          AuthService.canUpdate(PermissionFeature.FinancesRecords))) && (
        <>
          <Modal
            centered={true}
            autoFocus={true}
            isOpen={this.state.openModal}
            className="errorignoremodel styled-modal"
            backdrop="static"
          >
            <ModalHeader
              className="modal-header bg-secondary py-2"
              toggle={this.cancelUpdate}
            >
              FINANCE FORM{header}
            </ModalHeader>
            <ModalBody>
              <div className="row">
                {this.state.FinanceLoader ? (
                  <div
                    class="m-3 position-relative d-block mx-auto spinner-border text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  !this.state.FinanceLoader &&
                  obj != null && (
                    <>
                      {(this.state.FI == -1 || this.state.FI == null) &&
                        obj.Mode == null &&
                        (this.state.AddForm ||
                          (!this.state.AddForm &&
                            AuthService.canSPUpdate(
                              PermissionFeature.FinanceMember,
                              obj.regionId,
                              obj.facilityId,
                              obj.memberID
                            ))) && (
                          <div className="col-12">
                            <div className="">
                              <div className="row mt-2 mb-2">
                                <div className="col-sm-8">
                                  <label className="ml-0">Member Number</label>
                                  <Autocomplete
                                    //  style={{ width: 300 }}
                                    options={
                                      !_.isEmpty(this.state.Members)
                                        ? this.state.Members
                                        : []
                                    }
                                    //classes={{option: classes.option}}

                                    autoComplete={true}
                                    //autoSelect={true}
                                    autoHighlight={true}
                                    onChange={(e, value) => {
                                      this.handleChangeMemberNumber(value);
                                    }}
                                    onInputChange={(e, value, reason) => {
                                      this.MemberNumberSearchHandler(value);
                                    }}
                                    value={
                                      !_.isEmpty(Members) &&
                                      Members.find((x) => x.id == obj.MemberID)
                                    }
                                    getOptionLabel={(option) => {
                                      if (option != undefined) {
                                        return option.memberNumber;
                                      } else {
                                        return "";
                                      }
                                    }}
                                    renderOption={(option) => (
                                      <React.Fragment>
                                        {option.firstName +
                                          " " +
                                          option.lastName +
                                          " (" +
                                          option.memberNumber +
                                          ")" +
                                          " " +
                                          (option.facility
                                            ? option.facility
                                            : "-") +
                                          "/" +
                                          (option.region ? option.region : "-")}
                                      </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Provide Member Number"
                                        variant="standard"
                                        fullWidth
                                        inputProps={{
                                          ...params.inputProps,
                                          autoComplete: "disabled", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                  />
                                  <span className="text-danger">
                                    {obj.MemberNumber_err}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      <div className="col-12">
                        <div className="">
                          <div className="row mt-2 mb-2">
                            <div className="col-sm-3">
                              <label className="ml-0">Period</label>
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canSPUpdate(
                                  PermissionFeature.FinancePayPeriod,
                                  obj.regionId,
                                  obj.facilityId,
                                  obj.memberID
                                )) ? (
                                <>
                                  <input
                                    type="text"
                                    id="keyword"
                                    name="Period"
                                    value={obj.Period}
                                    className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                    placeholder="PayPeriod"
                                    onChange={this.handlePeriodYearChange}
                                  />
                                  <label className="text-danger">
                                    {obj.Period_Err}
                                  </label>
                                </>
                              ) : (
                                <div>{obj.Period}</div>
                              )}
                            </div>

                            <div className="col-sm-3">
                              <label className="ml-0">Year</label>
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canUpdate(
                                  PermissionFeature.FinanceYear,
                                  obj.regionId,
                                  obj.facilityId,
                                  obj.memberID
                                )) ? (
                                <>
                                  <input
                                    type="text"
                                    id="keyword"
                                    value={obj.Year}
                                    name="Year"
                                    className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                    placeholder="Year"
                                    onChange={this.handlePeriodYearChange}
                                  />
                                  <label className="text-danger">
                                    {obj.Year_Err}
                                  </label>
                                </>
                              ) : (
                                <div>{obj.Year}</div>
                              )}
                            </div>

                            <div className="col-sm-3">
                              <label>Dues Posted Type</label>
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canUpdate(
                                  PermissionFeature.FinanceDuesPostedType
                                )) ? (
                                <>
                                  <select
                                    //className="browser-default custom-select"
                                    onChange={this.changeHandlerDropdown}
                                    name="DuesPostedTypeId"
                                    className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                  >
                                    <option value="">Select Value</option>

                                    {DuesPostedTypes.map((item, index) => {
                                      return (
                                        <option
                                          value={item.id}
                                          selected={
                                            obj.DuesPostedTypeId == item.id
                                          }
                                        >
                                          {item.type}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label className="text-danger">
                                    {obj.DuesPostedTypeId_Err}
                                  </label>
                                </>
                              ) : (
                                <div>
                                  {(!_.isEmpty(DuesPostedTypes) &&
                                    DuesPostedTypes.find(
                                      (x) => x.id == obj.DuesPostedTypes
                                    )) ||
                                    null}
                                </div>
                              )}
                            </div>

                            <div className="col-sm-3">
                              <label>Finance Type</label>
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canUpdate(
                                  PermissionFeature.FinanceType
                                )) ? (
                                <>
                                  <select
                                    //className="browser-default custom-select"
                                    onChange={this.changeHandlerDropdown}
                                    name="FinanceTypeId"
                                    className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                  >
                                    <option value="">Select Value</option>

                                    {FinanceTypes.map((item, index) => {
                                      return (
                                        <option
                                          value={item.id}
                                          selected={
                                            obj.FinanceTypeId == item.id
                                          }
                                        >
                                          {item.type}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label className="text-danger">
                                    {obj.FinanceTypeId_Err}
                                  </label>
                                </>
                              ) : (
                                <div>
                                  {(!_.isEmpty(FinanceTypes) &&
                                    FinanceTypes.find(
                                      (x) => x.id == obj.FinanceTypeId
                                    )) ||
                                    null}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12" id="FinanceDetail">
                        <div className="">
                          <div className="row mt-2 mb-2">
                            <div className="col-sm-3">
                              <label>Expected Amount</label>
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canMemberUpdate(
                                  PermissionFeature.FinanceExpAmount
                                )) ? (
                                <div className="">
                                  <MDBInput
                                    containerClass="mt-2 mb-2 small-prefix"
                                    className="py-0 mb-0 ml-3"
                                    name="ExpectedAmount"
                                    hint="$$"
                                    icon="dollar-sign"
                                    value={
                                      obj.ExpectedAmount != null
                                        ? formate_textbox_amount(
                                            obj.ExpectedAmount
                                          )
                                        : ""
                                    }
                                    onChange={this.handleAmountChange}
                                    disabled={
                                      !AuthService.canMemberUpdate(
                                        PermissionFeature.FinanceExpAmount
                                      )
                                    }
                                    group
                                    type="text"
                                  />
                                  <span className="d-block text-danger">
                                    {obj.ExpectedAmount_Err}
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  {obj.ExpectedAmount != null
                                    ? formate_textbox_amount(obj.ExpectedAmount)
                                    : ""}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-3">
                              <label>Dues Collection Type</label>
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canUpdate(
                                  PermissionFeature.FinanceDuesCollectionType
                                )) ? (
                                <>
                                  <select
                                    //className="browser-default custom-select"
                                    onChange={this.changeHandlerDropdown}
                                    name="DuesCollectionTypeId"
                                    className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                  >
                                    <option value="">Select Value</option>

                                    {DuesCollectionTypes.map((item, index) => {
                                      return (
                                        <option
                                          value={item.id}
                                          selected={
                                            obj.DuesCollectionTypeId == item.id
                                          }
                                        >
                                          {item.type}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label className="text-danger">
                                    {obj.DuesCollectionTypeId_Err}
                                  </label>
                                </>
                              ) : (
                                <div>
                                  {(!_.isEmpty(DuesCollectionTypes) &&
                                    DuesCollectionTypes.find(
                                      (x) => x.id == obj.DuesCollectionTypeId
                                    )) ||
                                    null}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-3">
                              <label className="ml-0">Facilities</label>
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canUpdate(
                                  PermissionFeature.FinanceFacility
                                )) ? (
                                <>
                                  <Autocomplete
                                    //  style={{ width: 300 }}
                                    options={
                                      !_.isEmpty(Facilities)
                                        ? Facilities.map((o) => {
                                            return o;
                                          })
                                        : []
                                    }
                                    //classes={{option: classes.option}}

                                    autoComplete={true}
                                    //autoSelect={true}
                                    autoHighlight={true}
                                    selectOnFocus
                                    clearOnBlur
                                    defaultValue={null}
                                    name="FacilityId"
                                    value={
                                      (!_.isEmpty(Facilities) &&
                                        Facilities.find(
                                          (x) =>
                                            x.id ==
                                            this.state.SubmissionObj.FacilityId
                                        )) ||
                                      null
                                    }
                                    onChange={(e, value) => {
                                      if (value && value.id) {
                                        this.state.SubmissionObj.FacilityId =
                                          value.id;
                                        this.state.SubmissionObj.RegionId =
                                          value.regionid;
                                        this.state.FacilityCode = value.type;
                                        //if (this.props.Finance.mode == null) {
                                        //    this.props.setFinanceFormMode("Facility");
                                        //}
                                      } else {
                                        this.state.SubmissionObj.FacilityId = null;
                                        this.state.SubmissionObj.RegionId = null;

                                        this.state.FacilityCode = null;
                                        if (
                                          this.state.SubmissionObj.Mode ==
                                          "Facility"
                                        ) {
                                          this.state.SubmissionObj.Mode = null;
                                        }
                                      }
                                      if (
                                        this.state.SubmissionObj.Mode ==
                                        "Facility"
                                      ) {
                                        this.DropDownValidate(
                                          this.state.SubmissionObj.FacilityId,
                                          "FacilityId"
                                        );
                                      }

                                      this.setState({
                                        SubmissionObj: this.state.SubmissionObj,
                                        FacilityCode: this.state.FacilityCode,
                                      });
                                    }}
                                    getOptionLabel={(option) => {
                                      if (option && option.type) {
                                        return option.type;
                                      }
                                      if (option != undefined) {
                                        return option;
                                      }

                                      // Regular option
                                      return "";
                                    }}
                                    renderOption={(option) => {
                                      if (option && option.type) {
                                        return option.type;
                                      }
                                      if (option != undefined) {
                                        return option;
                                      }
                                      // Regular option
                                      return "";
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Facility"
                                        variant="standard"
                                        fullWidth
                                        inputProps={{
                                          ...params.inputProps,

                                          autoComplete: "disabled", // disable autocomplete and autofill
                                        }}
                                      />
                                    )}
                                  />
                                  <label className="text-danger">
                                    {obj.FacilityId_Err}
                                  </label>
                                </>
                              ) : (
                                <div>
                                  {(!_.isEmpty(Facilities) &&
                                    Facilities.find(
                                      (x) => x.id == obj.FacilityId
                                    )) ||
                                    null}
                                </div>
                              )}
                            </div>
                            {/*<div className="col-sm-3">
                                                        <label className="ml-0">Employers</label>
                                                        <Autocomplete
                                                            //  style={{ width: 300 }}
                                                            options={
                                                                !_.isEmpty(Employers)
                                                                    ? Employers.map((o) => {

                                                                        return o;
                                                                    })
                                                                    : []
                                                            }
                                                            //classes={{option: classes.option}}

                                                            autoComplete={true}
                                                            //autoSelect={true}
                                                            autoHighlight={true}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            defaultValue={null}
                                                            name="FacilityId"
                                                            value={(!_.isEmpty(Employers) && Employers.find(x => x.id == this.state.SubmissionObj.EmployerId)) || null}
                                                            onChange={(e, value) => {


                                                                if (value && value.id) {
                                                                    this.state.SubmissionObj.EmployerId = value.id;

                                                                } else {
                                                                    this.state.SubmissionObj.EmployerId = null;


                                                                }


                                                                this.setState({ SubmissionObj: this.state.SubmissionObj});
                                                            }}
                                                            getOptionLabel={(option) => {
                                                                if (option && option.type) {
                                                                    return option.type;
                                                                }
                                                                if (option != undefined) {
                                                                    return option;
                                                                }

                                                                // Regular option
                                                                return "";
                                                            }}

                                                            renderOption={(option) => {

                                                                if (option && option.type) {
                                                                    return option.type;
                                                                }
                                                                if (option != undefined) {
                                                                    return option;
                                                                }
                                                                // Regular option
                                                                return "";
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Select Employer"
                                                                    variant="standard"
                                                                    fullWidth
                                                                    inputProps={{
                                                                        ...params.inputProps,


                                                                        autoComplete: "disabled", // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        <label className="text-danger">{obj.EmployerId_Err}</label>
                                                    </div>*/}
                            <div className="clearfix" />
                            {/*  <>
                                                        {((this.state.FI == -1 || this.state.FI == null) && (obj.Mode == null || obj.Mode == "Facility")) && (
                                                            <div className="col-sm-6">

                                                                <div className="pt-4 custom-checkbox">
                                                                    <input
                                                                        id={"FacilityMode"}
                                                                        name={"FacilityMode"}
                                                                        type="checkbox"
                                                                        disabled={(obj.FacilityId == null || obj.FacilityId == "" || obj.FacilityId == undefined)}
                                                                        checked={obj.Mode == "Facility"}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked && obj.Mode == null) {
                                                                                obj.Mode = "Facility";

                                                                            }
                                                                            else if (obj.Mode == "Facility") {
                                                                                obj.Mode = null;
                                                                            }



                                                                            this.setState({ SubmissionObj: obj });
                                                                        }}
                                                                        className="custom-control-input pr-2"
                                                                    />
                                                                    <label
                                                                        className="text-italic custom-control-label"
                                                                        for={"FacilityMode"}
                                                                        style={{ paddingTop: 2 }}
                                                                    >
                                                                        Apply Finance to all the members of selected Facility.
                  </label>
                                                                </div>

                                                            </div>

                                                        )}
                                                        <div className="clearfix"></div>
                                                            </>*/}
                          </div>
                        </div>
                      </div>
                      {this.state.AddForm &&
                        AuthService.canAdd(
                          PermissionFeature.FinanceTransaction
                        ) && (
                          <>
                            <div className="col-12">
                              <div className="pt-1 pb-3 pl-2 custom-checkbox">
                                <input
                                  id={"AddTransaction"}
                                  name={"AddTransaction"}
                                  type="checkbox"
                                  checked={obj.AddTransaction}
                                  onChange={this.handleChangeAddTransaction}
                                  className="custom-control-input pr-2"
                                />
                                <label
                                  className="custom-control-label"
                                  for={"AddTransaction"}
                                  style={{ paddingTop: 2 }}
                                >
                                  Create Transaction
                                </label>
                              </div>
                            </div>
                            {obj.AddTransaction && (
                              <>
                                <div className="col-12 text-white bg-secondary">
                                  <h6 className="py-2 w-100">
                                    Transaction Detail
                                  </h6>
                                </div>

                                <div className="row mx-0 mt-2 mb-2">
                                  <div className="col-sm-3">
                                    <label>Amount</label>

                                    <div className="">
                                      <MDBInput
                                        containerClass="mt-2 mb-2 small-prefix"
                                        className="py-0 mb-0 ml-3"
                                        name="Amount"
                                        hint="$$"
                                        icon="dollar-sign"
                                        value={
                                          obj.Amount != null
                                            ? formate_textbox_amount(obj.Amount)
                                            : ""
                                        }
                                        onChange={this.handleAmountChange}
                                        disabled={
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.FinanceTransactionAmount
                                          )
                                        }
                                        group
                                        type="text"
                                      />
                                      <span className="d-block text-danger">
                                        {obj.Amount_Err}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">
                                    <div className="pt-4 custom-checkbox">
                                      <input
                                        id={"Refund"}
                                        name={"Refund"}
                                        type="checkbox"
                                        checked={obj.Refund}
                                        onChange={this.handleChangeRefund}
                                        className="custom-control-input pr-2"
                                      />
                                      <label
                                        className="h6 custom-control-label"
                                        for={"Refund"}
                                        style={{ paddingTop: 2 }}
                                      >
                                        Refund
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <label>Check Number</label>

                                    <div className="">
                                      <input
                                        type="text"
                                        id="keyword"
                                        className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                        placeholder="check number"
                                        name="CheckNumber"
                                        onChange={(e) => {
                                          this.state.SubmissionObj[
                                            e.target.name
                                          ] = e.target.value;

                                          this.setState({
                                            SubmissionObj: this.state
                                              .SubmissionObj,
                                          });
                                        }}
                                        disabled={
                                          !AuthService.canMemberUpdate(
                                            PermissionFeature.FinanceTransactionCheckNumber
                                          )
                                        }
                                        value={
                                          obj.CheckNumber ? obj.CheckNumber : ""
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <label className="ml-0">
                                      Transaction Date
                                    </label>

                                    <DatePicker
                                      dateForm="MM/DD/YYYY"
                                      peekNextMonth
                                      className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                      showMonthDropdown
                                      showYearDropdown
                                      onChange={(date) => {
                                        if (date == null) {
                                          obj.TransactionDate = null;
                                        } else if (moment(date).isValid()) {
                                          obj.TransactionDate = moment(
                                            date
                                          ).format("YYYY-MM-DD");
                                        }
                                        this.setState({ SubmissionObj: obj });
                                      }}
                                      selected={
                                        obj.TransactionDate
                                          ? moment(obj.TransactionDate).toDate()
                                          : null
                                      }
                                      placeholderText={"MM/DD/YYYY"}
                                    />
                                  </div>

                                  {/*
                                                     ADD TRANSACTION AND CHECK NUMBER
                                                     
                                                     */}
                                </div>
                              </>
                            )}
                          </>
                        )}
                    </>
                  )
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div class="text-right">
                <Button
                  className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
                  onClick={this.cancelUpdate}
                >
                  Cancel
                </Button>

                <button
                  class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </ModalFooter>
          </Modal>
        </>
      )
    );
  }
}
export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(FinanceFormModal);
