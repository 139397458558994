import React from 'react'
import { FaEye, FaTrash } from 'react-icons/fa';
import FileIcon, { defaultStyles } from 'react-file-icon';


const filesList = props => {

    const { deleteFileAttach } = props;
    const files = props.files && props.files.map((item) => {
        let fileExtention = item.src.split('.').pop()
        return (
            <div>

                <div className="list-group mt-2 ">

                    <span className="list-group-item list-group-item-action list-group-item-light pt-2 pb-2">
                        <FileIcon extension={fileExtention} size={44} {...defaultStyles[`${fileExtention}`]} /> {item.src.replace(/^.*[\\\/]/, '')}

                        <FaTrash ref={(el) => {

                        }} id="faTrah" style={{ cursor: 'pointer', color: '#5b626b' }} size={24} onClick={() => deleteFileAttach(item.src)} className="float-right align-self-center"></FaTrash>

                        <a target="_new" href={item && `${item.src}`}
                            className='ml-1'

                            ref={(el) => {
                                if (el) {
                                    el.style.setProperty('color', '#5b626b', 'important');
                                }
                            }}

                            style={{ cursor: 'pointer' }}
                        >


                            <FaEye id="viewEye" size={28} className="float-right align-self-center"></FaEye>

                        </a>
                    </span>




                </div>



            </div >
        )
    });
    return <div>

        {files}
        {files === undefined || files.length === 0 && (

            <div class="container h-100">
                <div class="row spinnerFacilityCategory">
                    <h5>No Files Attached!</h5>
                </div>
            </div>

        )}


    </div>
};

export default filesList;