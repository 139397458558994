import InventoryStatus from "../../modules/F&E/InventoryStatus";
import FE_Tabs_Type from "../../modules/F&E/FE_Tabs";
import RequestsType from "../../modules/F&E/FERequestsType";
import FE_AF_Nav_Items from "../../modules/F&E/AdvanceFilter/FE_AF_Nav_Items";

import actions from "./action";
import FE_AF_SearchType from "../../modules/F&E/FE_AF_SEARCH_TYPE";
import FE_List_Types from "../../modules/F&E/FE_List_Types";

const initialState = {
  openAddBudgetApproverModal: false,
  checkingApproverPerson: false,
  showReviewRequest: "",
  isEVPApprover: "",
  isAccountingPerson: false,
  isBudgetlineApprover: "",
  isFEInventoryAdvanceSearchApplied: true, // after 886 by default AF is applied
  isFERequestAdvanceSearchApplied: false,
  equipmentLoggingVisible: false,
  activeSubHeader: FE_Tabs_Type.Inventory,
  isReviewRequestOpen: false,
  currentEqUserAttachments: "",
  disposeEquipmentId: 0,
  deleteEquipmentId: 0,
  openEquipmentModal: false,
  openEquipmentDisposeModal: false,
  openMyRequestModal: false,
  openReviewRequestModal: false,
  openFEAdvanceFilterModal: false,
  openEquipmentFilesModal: false,
  accountingReviewRows: [],
  equipmentRows: [],
  myRequestsRows: [],

  approvedReviewRequestsRows: [],
  pendingEVPReviewRequestsRows: [],
  submittedReviewRequestsRows: [],

  totalAccountingReviewCount: 0,
  totalEquipmentCount: 0,
  totalMyEquipmentRequestsCount: 0,
  totalSubmittedReviewRequestsCount: 0,
  totalApprovedReviewRequestsCount: 0,
  totalPendingEVPReviewRequestsCount: 0,

  showTotal: true,
  accountingReviewSizePerPage: 25,
  equipmentSizePerPage: 25,
  erSizePerPage: 10,
  //rrSizePerPage: 10,

  approvedRequestSizePerPage: 10,
  submittedRequestSizePerPage: 10,
  pendingEVPRequestSizePerPage: 10,

  accountingReviewPageNo: 1,
  equipmentPageNo: 1,
  erPageNo: 1,
  submittedRRPageNo: 1,
  approvedRRPageNo: 1,
  pendingEVPRRPageNo: 1,

  equipmentId: 0,
  isLoadingAccountingReviewList: false,
  isLoadingEquipments: false,
  isLoadingMyRequests: false,
  isLoadingSubmittedReviewRequests: false,
  isLoadingApprovedReviewRequests: false,
  isLoadingPendingEVPReviewRequests: false,

  currentEquipment: 0,
  currentEquipmentRow: {},
  currentMyRequestRow: {},
  currentEquipmentFiles: [],
  showHisMyRequests: false,
  showHisReviewRequests: false,
  showHisActiveInv: false,

  ///Advance Filter
  filterData: {
    searchApplied: {
      inventories: [],
      requests: [],
    },
    years: [new Date().getFullYear().toString()],
  },
  filterResult: [],
  activeTab: FE_AF_Nav_Items.UserInventory,
  collaps: 2,
  isAdvancedFilterSelected: false,
  showWebSideBar: false,
  showMobileSideBar: false,
  resetAdvanceFilter: false,
  TempFilterData: [],
  returnResultNo: { inventories: 0, requests: 0 },
  basicSearchKeyWord: "",
  currentFilterResultPage: 1,
  currentFilterResultPageSize: 25,
  loadingAdvanceFilter: false,
  setSidebarState: false,
  currentStatusFilters: [],
  currentBudgetLineFilters: [],
};

export default function feTracker(state = initialState, action) {
  switch (action.type) {
    case actions.TOGGLE_BUDGET_LINE_MODAL:
      return {
        ...state,
        openAddBudgetApproverModal: !state.openAddBudgetApproverModal,
      };

    case actions.CHECKING_APPROVER_PERSON:
      return {
        ...state,
        checkingApproverPerson: action.payload,
      };
    case actions.IS_BUG_APPROVER_PERSON:
      return {
        ...state,
        showReviewRequest: action.payload.showReviewRequest,
        isEVPApprover: action.payload.is_evp_approver,
        isBudgetlineApprover: action.payload.is_budget_approver,
      };
    case actions.SLICE_ATTACHMENTS_FILES:
      return {
        ...state,
        currentEquipmentFiles: state.currentEquipmentFiles.filter(
          (el, index) => {
            return !el.filePath.includes(action.payload);
          }
        ),
      };

    case actions.TOGGLE_EQUIP_LOG_CARD:
      return {
        ...state,
        equipmentLoggingVisible: action.payload,
      };
    case actions.RESET_MYREQUEST_ROW:
      return {
        ...state,
        currentMyRequestRow: [],
      };
    case actions.CLOSE_FILES_MODAL:
      return {
        ...state,
        currentEquipmentFiles: [],
        openEquipmentFilesModal: false,
      };
    case actions.OPEN_FILES_MODAL:
      return {
        ...state,
        currentEquipmentFiles: action.payload.files,
        openEquipmentFilesModal: true,
        currentEqUserAttachments: `${action.payload.userMemberNo}-${action.payload.userName}-${action.payload.description}`,
      };

    case actions.OPEN_EQUIPMENT_MODAL:
      return {
        ...state,
        currentEquipmentRow: action.payload,
        openEquipmentModal: true,
      };
    case actions.CLOSE_EQUIPMENT_MODAL:
      return {
        ...state,

        openEquipmentModal: false,
      };
    case actions.OPEN_DISPOSE_MODAL:
      return {
        ...state,
        disposeEquipmentId: action.payload,
        openEquipmentDisposeModal: true,
      };
    case actions.CLOSE_DISPOSE_MODAL:
      return {
        ...state,

        openEquipmentDisposeModal: false,
      };
    case actions.CLOSE_EQUIP_DEL_MODAL:
      return {
        ...state,
        openEquipmentDeleteModal: false,
      };
    case actions.OPEN_EQUIP_DEL_MODAL:
      return {
        ...state,
        deleteEquipmentId: action.payload,
        openEquipmentDeleteModal: true,
      };
    case actions.OPEN_MYREQUEST_MODAL:
      return {
        ...state,
        currentMyRequestRow: action.payload,
        openMyRequestModal: true,
      };

    case actions.SET_REVIEW_REQUEST_OPEN:
      return {
        ...state,
        isReviewRequestOpen: action.payload,
      };

    case actions.CLOSE_MYREQUEST_MODAL:
      return {
        ...state,

        openMyRequestModal: false,
      };

    case actions.SET_LOADING_REQ: {
      var actionPayload = action.payload;
      if (actionPayload.type === RequestsType.MyRequests) {
        return Object.assign({}, state, {
          isLoadingMyRequests: actionPayload.val,
        });
      } else if (actionPayload.type === RequestsType.SubmittedReviewRequests) {
        return Object.assign({}, state, {
          isLoadingSubmittedReviewRequests: actionPayload.val,
        });
      } else if (actionPayload.type === RequestsType.ApprovedReviewRequests) {
        return Object.assign({}, state, {
          isLoadingApprovedReviewRequests: actionPayload.val,
        });
      } else if (actionPayload.type === RequestsType.PendingEVPReviewRequests) {
        return Object.assign({}, state, {
          isLoadingPendingEVPReviewRequests: actionPayload.val,
        });
      }
    }
    case actions.SET_LOADING_FE: {
      var actionPayload = action.payload;
      if (actionPayload.type === InventoryStatus.InAccReview) {
        return Object.assign({}, state, {
          isLoadingAccountingReviewList: actionPayload.val,
        });
      }
      if (actionPayload.type === InventoryStatus.NonAccReview) {
        return Object.assign({}, state, {
          isLoadingEquipments: actionPayload.val,
        });
      }
    }
    case actions.SET_DATA_SIZE_ACTIVE_EQUIP: {
      return {
        ...state,
        totalAccountingReviewCount: action.payload,
      };
    }
    case actions.SET_DATA_SIZE_INACC_EQUIP: {
      return {
        ...state,
        totalEquipmentCount: action.payload,
      };
    }
    case actions.SET_LIST_SCREEN_DATA: {
      var actionPayload = action.payload;
      if (actionPayload.type === InventoryStatus.InAccReview) {
        return Object.assign({}, state, {
          accountingReviewRows: actionPayload.data.data,
          totalAccountingReviewCount: actionPayload.data.totalCount,
        });
      }
      if (actionPayload.type === InventoryStatus.NonAccReview) {
        return Object.assign({}, state, {
          equipmentRows: actionPayload.data.data,
          totalEquipmentCount: actionPayload.data.totalCount,
        });
      }
    }

    case actions.SET_LIST_SCREEN_DATA_REQ: {
      var actionPayload = action.payload;
      if (actionPayload.type === RequestsType.MyRequests) {
        return Object.assign({}, state, {
          myRequestsRows: actionPayload.data.data,
          totalMyEquipmentRequestsCount: actionPayload.data.totalCount,
        });
      } else if (actionPayload.type === RequestsType.ApprovedReviewRequests) {
        return Object.assign({}, state, {
          approvedReviewRequestsRows: actionPayload.data.data,
          totalApprovedReviewRequestsCount: actionPayload.data.totalCount,
        });
      } else if (actionPayload.type === RequestsType.SubmittedReviewRequests) {
        return Object.assign({}, state, {
          submittedReviewRequestsRows: actionPayload.data.data,
          totalSubmittedReviewRequestsCount: actionPayload.data.totalCount,
        });
      } else if (actionPayload.type === RequestsType.PendingEVPReviewRequests) {
        return Object.assign({}, state, {
          pendingEVPReviewRequestsRows: actionPayload.data.data,
          totalPendingEVPReviewRequestsCount: actionPayload.data.totalCount,
        });
      }
    }

    case actions.SET_TOTAL_DATA_COUNT_REQ: {
      var actionPayload = action.payload;

      if (actionPayload.tableType === RequestsType.MyRequests) {
        return Object.assign({}, state, {
          totalMyEquipmentRequestsCount: actionPayload.count,
        });
      } else if (
        actionPayload.tableType === RequestsType.ApprovedReviewRequests
      ) {
        return Object.assign({}, state, {
          totalApprovedReviewRequestsCount: actionPayload.count,
        });
      } else if (
        actionPayload.tableType === RequestsType.SubmittedReviewRequests
      ) {
        return Object.assign({}, state, {
          totalSubmittedReviewRequestsCount: actionPayload.count,
        });
      } else if (
        actionPayload.tableType === RequestsType.PendingEVPReviewRequests
      ) {
        return Object.assign({}, state, {
          totalPendingEVPReviewRequestsCount: actionPayload.count,
        });
      }
    }
    case actions.SET_PAGINATION_INFO_INV: {
      var actionData = action.payload;

      if (actionData.tableType === InventoryStatus.InAccReview) {
        return Object.assign({}, state, {
          accountingReviewSizePerPage: actionData.sizePerPage,
          accountingReviewPageNo: actionData.pageNo,
        });
      } else if (actionData.tableType === InventoryStatus.NonAccReview) {
        return Object.assign({}, state, {
          equipmentSizePerPage: actionData.sizePerPage,
          equipmentPageNo: actionData.pageNo,
        });
      }
    }
    case actions.SET_PAGINATION_INFO_REQ: {
      var actionData = action.payload;
      if (actionData.tableType === RequestsType.MyRequests) {
        return Object.assign({}, state, {
          erSizePerPage: actionData.sizePerPage,
          erPageNo: actionData.pageNo,
        });
      } else if (actionData.tableType === RequestsType.ApprovedReviewRequests) {
        return Object.assign({}, state, {
          approvedRequestSizePerPage: actionData.sizePerPage,
          approvedRRPageNo: actionData.pageNo,
        });
      } else if (
        actionData.tableType === RequestsType.SubmittedReviewRequests
      ) {
        return Object.assign({}, state, {
          submittedRequestSizePerPage: actionData.sizePerPage,
          submittedRRPageNo: actionData.pageNo,
        });
      } else if (
        actionData.tableType === RequestsType.PendingEVPReviewRequests
      ) {
        return Object.assign({}, state, {
          pendingEVPRequestSizePerPage: actionData.sizePerPage,
          pendingEVPRRPageNo: actionData.pageNo,
        });
      }
    }
    case actions.SET_FE_HEADER_ACTIVE_TAB: {
      return {
        ...state,
        activeSubHeader: action.payload,
      };
    }

    ///AdvanceFilter
    case actions.OPEN_FE_ADVANCE_FILTER: {
      return {
        ...state,
        openFEAdvanceFilterModal: action.payload,
      };
    }
    case actions.SET_FE_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };
    case actions.SET_FE_TEMP_FILTER_DATA:
      return {
        ...state,
        TempFilterData: action.payload,
      };
    case actions.SET_FE_RETURN_RESULTSNO:
      var actionData = action.payload;
      if (actionData.type == FE_AF_SearchType.Inventory) {
        return Object.assign({}, state, {
          returnResultNo: Object.assign(
            {},
            {
              inventories: actionData.Result,
              requests: state.returnResultNo.requests,
            }
          ),
        });
      } else if (actionData.type > FE_AF_SearchType.Inventory) {
        return Object.assign({}, state, {
          returnResultNo: Object.assign(
            {},
            {
              requests: actionData.Result,
              inventories: state.returnResultNo.requests,
            }
          ),
        });
      }

    case actions.SET_FE_FILTER_RESULT:
      return {
        ...state,
        filterResult: action.payload,
      };
    case actions.SET_FE_AF_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case actions.SET_FE_COLLAPSE_TAB:
      return {
        ...state,
        collaps:
          state.collaps !== action.payload ? action.payload : state.collaps,
      };

    case actions.SHOW_FE_WEB_SIDE_BAR:
      return {
        ...state,
        showWebSideBar: action.payload,
      };

    case actions.SHOW_FE_MOBILE_SIDE_BAR:
      return {
        ...state,
        showMobileSideBar: action.payload,
      };
    case actions.SET_FE_FACILITY_CHILD_ACCORDION:
      return {
        ...state,
        childAccordion: action.payload,
      };

    case actions.SET_FE_RESET_ADVANCE_FILTER:
      return {
        ...state,
        resetAdvanceFilter: action.payload,
      };

    case actions.SET_FE_ADVANCED_FILTER:
      return {
        ...state,
        isAdvancedFilterSelected: action.payload,
      };
    case actions.SET_FE_BASIC_SEARCH_KEYWORD:
      return {
        ...state,
        basicSearchKeyWord: action.payload,
      };
    case actions.SET_FE_FILTER_RESULT_PAGE_NO:
      return {
        ...state,
        currentFilterResultPage: action.payload,
      };

    case actions.SET_FE_NEW_FILTERED_DATA:
      console.log(action.payload.data, "421 applied New data");
      return {
        ...state,
        filterData: action.payload.data,
        TempFilterData: action.payload.data,
        loadingAdvanceFilter: false,
      };
    case actions.SET_FE_ADVANCE_FILTER_LOADNG:
      return {
        ...state,
        loadingAdvanceFilter: action.payload,
      };
    case actions.SET_FE_SIDEBAR_STATE:
      return {
        ...state,
        setSidebarState: action.payload,
      };
    case actions.SET_FILTER_STATUS_ACTIVE:
      const defaultStatuses = [...state.filterData.fe_equipment_statuses];
      const activeIndex = defaultStatuses.findIndex(
        (f) => f.status == "Active"
      );
      defaultStatuses[activeIndex].checked = true;
      return {
        ...state,
        filterData: {
          ...state.filterData,
          searchApplied: {
            ...state.filterData.searchApplied,
            inventories: [
              {
                searchType: 1,
                isApplied: true,
                isSearched: true,
                selectedItems: ["Active"],
              },
            ],
          },
          fe_equipment_statuses: [...defaultStatuses],
        },
      };
    case actions.SET_FE_LOCAL_PAGING:
      return {
        ...state,
        localPaging: action.payload,
      };
    case actions.FE_RESET_LOCAL_PAGING:
      return {
        ...state,
        localPaging: action.payload,
      };
    case actions.SET_FE_ACCORDIAN_TYPE:
      return {
        ...state,
        resultsAccordianType: action.payload,
      };
    case actions.SET_FE_FILTER_RESULT_PAGE_SIZE:
      return {
        ...state,
        currentFilterResultPageSize: action.payload,
      };
    case actions.SET_FE_FILTER_RESULT_TOTAL_COUNT:
      return {
        ...state,
        currentFilterResultTotalCount: action.payload,
      };
    case actions.SET_IS_ACC_PERSON:
      return {
        ...state,
        isAccountingPerson: action.payload,
      };
    case actions.RESET_PAGINATION:
      var actionPayload = action.payload;
      ///Reset Table Pagination for Active and "In Acc Review"
      if (actionPayload === FE_Tabs_Type.Inventory) {
        return Object.assign({}, state, {
          accountingReviewSizePerPage: 25,
          accountingReviewPageNo: 1,
          equipmentSizePerPage: 25,
          equipmentPageNo: 1,
        });
        ///Reset Table Pagination for "My Requests"
      } else if (actionPayload === FE_Tabs_Type.MyRequests) {
        return Object.assign({}, state, {
          erSizePerPage: 10,
          erPageNo: 10,
        });
      }
      ///Reset Table Pagination for Approved,"Pending EVP" & Submitted
      else if (actionPayload === FE_Tabs_Type.ReviewRequests) {
        return Object.assign({}, state, {
          approvedRequestSizePerPage: 10,
          approvedRRPageNo: 1,
          pendingEVPRequestSizePerPage: 10,
          pendingEVPRRPageNo: 1,
          submittedRequestSizePerPag: 10,
          submittedRRPageNo: 1,
        });
      }
    case actions.SET_FE_RESET_ALL:
      return {
        ...state,
        filterData: {},
        filterResult: [],
        activeTab: FE_AF_Nav_Items.Status,
        collaps: 0,
        isAdvancedFilterSelected: false,
        showWebSideBar: false,
        showMobileSideBar: false,
        resetAdvanceFilter: false,
        TempFilterData: [],
        returnResultNo: 0,

        childAccordion: 0,
        localPaging: false,
      };
    case actions.SET_FE_AF_FLAG:
      var actionData = action.payload;
      if (actionData.type == FE_AF_SearchType.Inventory) {
        return Object.assign({}, state, {
          isFEInventoryAdvanceSearchApplied: actionData.val,
        });
      } else if (actionData.type == FE_AF_SearchType.Request) {
        return Object.assign({}, state, {
          isFERequestAdvanceSearchApplied: actionData.val,
        });
      }
    case actions.SET_FE_MY_REQ_SHOW_HIS:
      var actionData = action.payload;
      if (actionData.type == FE_List_Types.Active) {
        return Object.assign({}, state, {
          showHisActiveInv: actionData.val,
        });
      } else if (actionData.type == FE_List_Types.MyRequests) {
        return Object.assign({}, state, {
          showHisMyRequests: actionData.val,
        });
      } else if (actionData.type == FE_List_Types.ReviewRequests) {
        return Object.assign({}, state, {
          showHisReviewRequests: actionData.val,
        });
      }
    case actions.RESET_FE_SEARCH_APPLIED:
      var actionData = action.payload;
      if (state.activeSubHeader == FE_AF_SearchType.Inventory) {
        return {
          ...state,
          filterData: {
            ...state.filterData,
            searchApplied: {
              ...state.filterData.searchApplied,
              inventories: actionData,
            },
          },
        };
      } else if (state.activeSubHeader > FE_AF_SearchType.Inventory) {
        var result = {
          ...state,
          filterData: {
            ...state.filterData,
            searchApplied: {
              ...state.filterData.searchApplied,
              requests: actionData,
            },
          },
        };

        return result;
      }
    case actions.SET_FE_SEARCH_APPLIED:
      if (state.activeSubHeader == FE_AF_SearchType.Inventory) {
        return {
          ...state,
          filterData: {
            ...state.filterData,
            searchApplied: {
              ...state.filterData.searchApplied,
              inventories: state.filterData.searchApplied.inventories.map(
                (item) => {
                  if (item.isApplied) {
                    item.isSearched = true;
                  } else {
                    item.isSearched = false;
                  }
                  return item;
                }
              ),
            },
          },
        };
      } else if (state.activeSubHeader > FE_AF_SearchType.Inventory) {
        var result = {
          ...state,
          filterData: {
            ...state.filterData,
            searchApplied: {
              ...state.filterData.searchApplied,
              requests: state.filterData.searchApplied.requests.map((item) => {
                if (item.isApplied) {
                  item.isSearched = true;
                } else {
                  item.isSearched = false;
                }
                return item;
              }),
            },
          },
        };

        return result;
      }
    case actions.SET_YEARS_FILTER_DATA:
      var actionData = action.payload;
      return {
        ...state,
        filterData: {
          ...state.filterData,
          years: actionData,
        },
      };
    case actions.SET_STATUS_FILTER_DATA:
      var actionData = action.payload;
      return {
        ...state,
        currentStatusFilters: actionData,
      };
    case actions.SET_BG_LINE_FILTER_DATA:
      var actionData = action.payload;
      return {
        ...state,
        currentBudgetLineFilters: actionData,
      };

    default:
      return state;
  }
}
