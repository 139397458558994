import actions from "./action";

const initialState = {
  IsOpenFacilityCards: true,
  facilityActiveTab: 0,
  IsMemberAccordianLoading: true,
  facilityPhysicalAddresses: [],
  facilityMailingAddresses: [],
  facilityPhoneNumbers: [],
  isSavedSuccefully: false,
  facilityTypeOptions: [],
  facilityRegionOptions: [],
  facilityOptions: [],
  vfrMapApiKey: "",
  currentFileTab: 1,
  IsFilesTreeLoading: false,
  facilityFilesTreeData: [],
  IsFacilityFileUploading: false,
  IsFacilityAttachmentsLoading: false,
  facilityAttachmentFiles: [],
  selectedMapIds: [],
  isAccordionLoading: false,
  showOfficerModal: false,
  showPendingRequestAlert: false,
};

export default function facilityDashboard(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FACILITY_ACTIVE_TAB:
      return {
        ...state,
        facilityActiveTab: action.payload,
        IsOpenFacilityCards: false,
      };
    case actions.SET_STARTUP_FACILITY_ACTIVE:
      return {
        ...state,
        facilityActiveTab: 0,
        IsOpenFacilityCards: true,
      };
    case actions.SET_FACILITY_CARDS_VISIBILITY:
      return {
        ...state,
        IsOpenFacilityCards: action.payload,
        facilityActiveTab: 0,
      };

    case actions.SET_FACILITY_MEMBERS_LOADING:
      return {
        ...state,
        IsMemberAccordianLoading: action.payload,
      };
    case actions.SHOW_OFFICER_MODAL:
      return {
        ...state,
        showOfficerModal: action.payload,
      };
    case actions.SHOW_PENDING_REQUEST_ALERT:
      return {
        ...state,
        showPendingRequestAlert: action.payload,
      };
    case actions.ADD_FACILITY_PHY_ADDRESS:
      return {
        ...state,
        facilityPhysicalAddresses: [
          ...state.facilityPhysicalAddresses,
          {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            isprimary: false,
            addresstypeid: 4,
          },
        ],
      };
    case actions.REMOVE_FACILITY_PHY_ADDRESS:
      return {
        ...state,
        facilityPhysicalAddresses: state.facilityPhysicalAddresses.filter(
          (el, index) => {
            return index !== action.payload;
          }
        ),
      };

    case actions.ADD_FACILITY_MAIL_ADDRESS:
      return {
        ...state,
        facilityMailingAddresses: [
          ...state.facilityMailingAddresses,
          {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            isprimary: false,
            addresstypeid: 1,
          },
        ],
      };
    case actions.REMOVE_FACILITY_MAIL_ADDRESS:
      return {
        ...state,
        facilityMailingAddresses: state.facilityMailingAddresses.filter(
          (el, index) => {
            return index !== action.payload;
          }
        ),
      };

    case actions.ADD_FACILITY_PHONE_NO:
      return {
        ...state,
        facilityPhoneNumbers: [
          ...state.facilityPhoneNumbers,
          {
            number: "",
            isprimary: false,
            phonetypeid: 1,
            id: 0,
          },
        ],
      };

    case actions.REMOVE_FACILITY_PHONE_NO:
      return {
        ...state,
        facilityPhoneNumbers: state.facilityPhoneNumbers.filter((el, index) => {
          return index !== action.payload;
        }),
      };
    case actions.SET_FACILTY_PHY_ADDRESS:
      return {
        ...state,
        facilityPhysicalAddresses: action.payload,
      };

    case actions.SET_FACILTY_MAILING_ADDRESS:
      return {
        ...state,
        facilityMailingAddresses: action.payload,
      };
    case actions.SAVED_SUCCESSFULLY:
      return {
        ...state,
        isSavedSuccefully: true,
      };

    case actions.SET_FACILITY_PHONE_NUMBERS:
      return {
        ...state,
        facilityPhoneNumbers: action.payload,
      };

    case actions.RESET_FACILITY_PHONE_NUMBERS:
      return {
        ...state,
        facilityPhoneNumbers: [
          {
            number: "",
            isprimary: false,
            phonetypeid: 1,
            id: 0,
          },
        ],
      };
    case actions.RESET_FACILTY_PHY_ADDRESS:
      return {
        ...state,
        facilityPhysicalAddresses: [
          {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            isprimary: false,
            addresstypeid: 4,
          },
        ],
      };
    case actions.RESET_FACILTY_MAILING_ADDRESS:
      // debugger
      return {
        ...state,
        facilityMailingAddresses: [
          {
            id: 0,
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipcode: "",
            isprimary: false,
            addresstypeid: 1,
          },
        ],
      };
    case actions.SET_FACILITY_TYPE_OPTIONS:
      return {
        ...state,
        facilityTypeOptions: action.payload,
      };

    case actions.SET_FACILITY_REGIONS_OPTIONS:
      return {
        ...state,
        facilityRegionOptions: action.payload,
      };

    case actions.SET_FACILITY_OPTIONS:
      return {
        ...state,
        facilityOptions: action.payload,
      };

    case actions.SET_VFR_MAP_KEY:
      return {
        ...state,
        vfrMapApiKey: action.payload,
      };

    case actions.SET_FACILITY_FILE_TAB:
      //    debugger
      return {
        ...state,
        currentFileTab: action.payload,
      };

    case actions.SET_FACILITY_FILES_LOADING:
      //    debugger
      return {
        ...state,
        IsFilesTreeLoading: action.payload,
      };

    case actions.SET_FACILITY_FILES_TREE_DATA:
      //    debugger
      return {
        ...state,
        IsFilesTreeLoading: false,
        facilityFilesTreeData: action.payload,
      };

    case actions.SET_FACILITY_FILE_UPLOADING:
      //    debugger
      return {
        ...state,
        IsFacilityFileUploading: action.payload,
      };

    case actions.SET_OSHA_ATTACHMENTS_LOADING:
      //    debugger
      return {
        ...state,
        IsFacilityAttachmentsLoading: action.payload,
      };

    case actions.SET_OSHA_ATTACHMENTS_FILES:
      //    debugger
      return {
        ...state,
        facilityAttachmentFiles: action.payload,
      };

    case actions.SLICE_OSHA_ATTACHMENTS_FILES:
      return {
        ...state,
        facilityAttachmentFiles: {
          data: state.facilityAttachmentFiles.data.filter((el, index) => {
            return !el.includes(action.payload);
          }),
        },
      };

    case actions.SET_FACILITY_SELECTED_MAPS:
      return {
        ...state,
        selectedMapIds: action.payload,
      };

    case actions.ADD_SELECTED_MAPS:
      return {
        ...state,
        selectedMapIds: action.payload,
      };

    case actions.REMOVE_SELECTED_MAPS:
      return {
        ...state,
        selectedMapIds: state.selectedMapIds.filter((el, index) => {
          return index !== action.payload;
        }),
      };
    case actions.IS_ACCORDION_LOADING:
      return {
        ...state,
        isAccordionLoading: action.payload,
      };

    default:
      return state;
  }
}
