import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import BootstrapTable from "react-bootstrap-table-next";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import MembersModal from "../../Shared/MembersModal";
import TeamsModal from "../../Shared/TeamsModal";
import PositionsModal from "../../Shared/PositionsModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from '../../Shared/TableCustomTotal'
import Teams from "./Teams";

class ChangeMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.location.type,
            member: this.props.location.member,
            teamId: this.props.location.teamId,
            Id: 0,
            IsTeam: true,
            associatedTeams: [],
            memberId: 0,
            memberName: "",
            memberType: "Team",
            replacingMemberId: 0,
            teamMemberId: 0,
            outgoingMemberType: ""
        };
        this.membersMethods = () => {
            this.setMember = args => {
                this.setState({ replacingMemberId: args.id, MemberName: args.firstName + " " + args.lastName });
                $(".cancelModal").trigger("click");
            };

            return this;
        };
        this.teamsMethods = () => {
            this.setTeam = args => {
                this.setState({ replacingMemberId: args.id, MemberName: args.name });
                $(".cancelModal").trigger("click");
            };

            return this;
        };

        this.columns = [
            {
                dataField: "teamName",
                text: "Team Name"
            },
            {
                dataField: "positionName",
                text: "Position Name"
            }
        ];

        this.selectRow = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };
    }
    handleOnSelect = (row, isSelect) => {
        this.state.associatedTeams.filter(el => {
            if (isSelect && el.id == row.id) {
                el.isSelected = isSelect;
            }
        });
        this.setState({
            associatedTeams: this.state.associatedTeams
        });
        return true; // return true or dont return to approve current select action
    };

    handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            this.state.associatedTeams.map(el => {
                el.isSelected = isSelect;
            });
        }

        this.setState({
            associatedTeams: this.state.associatedTeams
        });
    };
    setMemberType = e => {
        this.setState({
            IsTeam: e.target.value == "Team",
            memberType: e.target.value
        });
    };
    handleChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleSubmit = evt => {
        evt.preventDefault();
        evt.target.className += " was-validated";
        if (evt.target.checkValidity() === false) {
            return false;
        }
        const data = {
            MemberId: this.state.memberId,
            MemberName: this.state.memberName,
            OutgoingMemberType: this.state.outgoingMemberType,

            Id: this.state.Id,
            AssociatedTeams: this.state.associatedTeams,
            ReplacingMemberId: this.state.replacingMemberId,
            InComingMemberType: this.state.memberType
        };
        axios
            .post("/api/TeamMember/SaveTeamMemberDetails", data)
            .then(({ data }) => {
                if (data.status && data.status == "Success") {
                    toast.success("Team Member Saved.");
                    this.props.history.push({ pathname: "/teams/edit-team", type: "edit", uid: this.state.teamId });
                } else {
                    toast.error(data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    componentWillUnmount() {
        if (this.props.history.action === "POP") {
            //if (this.props.location.backRef) {
            this.props.history.location.uid = this.props.location.teamId;


        }
    }
    componentDidMount() {
        $(".loading").show();
        if (!this.state.type && !this.state.teamId) {
            this.props.history.push({ pathname: "/admin/teams" });
        } else {


            const data = {
                MemberId: this.state.member.teamMemberId,
                Id: this.state.member.id,
                IsTeam: this.state.member.type == "Team"
            };
            axios.post("/api/TeamMember/GetTeamMemberDetails", data).then(({ data }) => {
                if (data.status && data.status.toLowerCase() == "success") {
                    this.setState({
                        associatedTeams: data.data.associatedTeams,
                        memberId: data.data.memberId,
                        memberName: data.data.memberName,
                        Id: data.data.id,
                        outgoingMemberType: data.data.outgoingMemberType
                    });
                    $(".loading").hide();
                } else {
                    $(".loading").hide();
                    toast.error(data.message);
                }
            });
        }
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                        <div className="page-title-box">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h4 className="page-title">Change Member</h4>
                                </div>
                                <div className="col-sm-6 float-right text-right">
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            this.props.history.push({ pathname: "/admin/teams/edit-team", type: "edit", uid: this.state.teamId })
                                        }
                                        className="primary">
                                        Back to Team
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4">
                                                <MDBInput
                                                    value={this.state.memberName}
                                                    name="memberName"
                                                    onChange={this.handleChange}
                                                    label="Current Member"
                                                    group
                                                    type="text"
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-4">
                                                <label>New Member Type:</label>
                                                <div className="form-group">
                                                    <select
                                                        className="browser-default custom-select"
                                                        id="memberType"
                                                        onChange={this.setMemberType}>
                                                        <option value="Team" selected={this.state.memberType == "Team"}>
                                                            Team
                                                        </option>
                                                        <option value="Member" selected={this.state.memberType == "Member"}>
                                                            Member
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            {this.state.IsTeam && (
                                                <div className="col-4">
                                                    <label>Replacing Team</label>
                                                    <div class="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            class="form-control disabled"
                                                            required
                                                            onKeyPress={e => {
                                                                e.preventDefault();
                                                                e.target.value = "";
                                                            }}
                                                            value={this.state.MemberName}
                                                        />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary"
                                                                type="button"
                                                                data-toggle="modal"
                                                                data-target=".teams-modal">
                                                                <i class="fa fa-search" />
                                                            </button>
                                                        </div>
                                                        <div className="invalid-feedback">Team is required.</div>
                                                    </div>
                                                </div>
                                            )}
                                            {!this.state.IsTeam && (
                                                <div className="col-4">
                                                    <label>Replacing Member</label>
                                                    <div class="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            class="form-control disabled"
                                                            required
                                                            onKeyPress={e => {
                                                                e.preventDefault();
                                                                e.target.value = "";
                                                            }}
                                                            value={this.state.MemberName}
                                                        />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary"
                                                                type="button"
                                                                data-toggle="modal"
                                                                data-target=".members-modal">
                                                                <i class="fa fa-search" />
                                                            </button>
                                                        </div>
                                                        <div className="invalid-feedback">Member is required.</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 mb-2">This Person is also member of the following team: </div>
                                            <div className="col-6">
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={this.state.associatedTeams}
                                                    columns={this.columns}
                                                    pagination={paginationFactory({
                                                        paginationTotalRenderer: TableCustomTotal
                                                    })}
                                                    selectRow={this.selectRow}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col float-right text-right">
                                <Button
                                    type="button"
                                    onClick={() =>
                                        this.props.history.push({ pathname: "/admin/teams/edit-team", type: "edit", uid: this.state.teamId })
                                    }
                                    className="mr-2"
                                    color="danger">
                                    Cancel
                                </Button>
                                <Button to="/roles" color="primary" type="submit">
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
                <MembersModal membersMethods={this.membersMethods} />
                <TeamsModal teamsMethods={this.teamsMethods} />
                <PositionsModal positionMethods={this.positionMethods} />
            </AUX>
        );
    }
}

export default ChangeMember;
