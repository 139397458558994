import { QuestionTypes } from "./LookUpValues";
import * as Yup from "yup";
export const initForm = (formSchema) => {
  /////////////////////////////////////////////////////////////////

  let _formData = {};
  let _validationSchema = {};

  for (var key of Object.keys(formSchema)) {
    if (formSchema[key].questionType === QuestionTypes.Text) {
      _validationSchema[key] = Yup.string();
      _formData[key] = formSchema[key].value || "";
      if (formSchema[key].isRequired) {
        _validationSchema[key] = Yup.string().required("*Required.");
      }
    } else if (formSchema[key].questionType === QuestionTypes.Email) {
      _validationSchema[key] = Yup.string().email();
      _formData[key] = formSchema[key].value || "";
      if (formSchema[key].isRequired) {
        _validationSchema[key] = Yup.string().required("*Required.");
      }
    } else if (formSchema[key].questionType === QuestionTypes.DropDown) {
      _validationSchema[key] = Yup.string().oneOf(
        formSchema[key].questionOptions.map((o) => o.id.toString())
      );
      _formData[key] = formSchema[key].value || "";
      if (formSchema[key].isRequired) {
        _validationSchema[key] = Yup.string().required(
          "*Please select an option."
        );
      }
    } else if (formSchema[key].questionType === QuestionTypes.Switch) {
      _validationSchema[key] = Yup.bool();
      _formData[key] = formSchema[key].value || false;
    } else if (formSchema[key].questionType === QuestionTypes.Checkboxes) {
      _validationSchema[key] = Yup.array(
        Yup.string().oneOf(
          formSchema[key].questionOptions.map((o) => o.id.toString())
        )
      );
      _formData[key] = formSchema[key].value || [];
      if (formSchema[key].isRequired) {
        _validationSchema[key] = Yup.array().min(
          1,
          "*Please select atleast one option."
        );
      }
    } else if (formSchema[key].questionType === QuestionTypes.Radiobuttons) {
      _validationSchema[key] = Yup.string().oneOf(
        formSchema[key].questionOptions.map((o) => o.id.toString())
      );
      _formData[key] = formSchema[key].value || "";

      if (formSchema[key].isRequired) {
        _validationSchema[key] = Yup.string().required(
          "*Please select an option."
        );
      }
    }
  }
  return {
    _formData,
    _validationSchema: Yup.object().shape({ ..._validationSchema }),
  };
};
export const makeuid = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
