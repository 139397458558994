import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../Shared/Modals";
import { toast } from "react-toastify";
import TeamSearch from "./TeamSearch";
import EditTeam from "./EditTeam";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import PermissionHeader from "../General/PermissionHeader";
import Services from "../PermissionService";
import _ from "lodash";
import DefaultPermissionModal from "../DefaultPermission/DefaultPermissionModal";
import AuthService from "../../Auth/AuthService";
import TableCustomTotal from "../../Shared/TableCustomTotal";
class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      page: 1,
      sizePerPage: localStorage.getItem("teamsPageSize")
        ? parseInt(localStorage.getItem("teamsPageSize"))
        : 10,
      keyword: this.props.location.keyword || "",
      data: [],
      totalCount: "",
      delId: 0,
      advSearch: {
        Name: "",
        MemberName: "",
        PositionName: "",
        City: "",
        State: "",
        County: "",
      },

      currentTab: 1,
      scopes: [],
    };

    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        axios
          .delete("/api/Team/" + this.state.delId)
          .then(({ data }) => {
            if (data.status && data.status == "Success") {
              toast.success("Team Deleted Successfuly.");
              this.setState({ delId: 0 });
              $(".cancelModal").trigger("click");
              this.getTeams();
            } else {
              toast.error(data.message);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      };
      return this;
    };
    this.columns = [
      {
        text: "Team Name",
        dataField: "teamName",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
      },
      {
        text: "Owner",
        //dataField: "count",
        dataField: "ownerName",
        headerClasses: "bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
      },
    ];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.teamDetailPage(e, row.id);
      },
    };

    this.teamDetailPage = (e, id) => {
      if (e.target.className.indexOf("del") < 0) {
        this.props.history.push({
          pathname: "/permissions/teams/teamDetail/" + id,
          type: "add",
          uid: id,
          keyword: this.state.searchTerm,
        });
      }
    };
    const columns = [
      {
        dataField: "region",
        text: "TEAM NAME",
        headerAttrs: {
          width: "85%",
        },
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        sort: true,
        classes: "px-3 py-2 border-right-0 bg-secondary text-white",
      },
      {
        text: "OWNER",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2 text-center",
        classes: "px-3 py-2 border-left-0 bg-secondary text-white",
        headerAttrs: {
          width: "15%",
        },
        formatter: (cellContent, row, isExpand) => {
          return (
            <div className="text-right">
              <i className={"fas fa-chevron-down fa-lg "} />
            </div>
          );
        },
      },
    ];
    const columnsFacility = [
      {
        text: "TEAM NAME",
        headerAttrs: {
          width: "85%",
        },
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 border-right-0 bg-secondary text-white",
        formatter: (cellContent, row, isExpand) => {
          return <Fragment>{row.region + " - " + row.facility}</Fragment>;
        },
      },
      {
        text: "OWNER",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2 text-center",
        classes: "px-3 py-2 border-left-0 bg-secondary text-white",
        headerAttrs: {
          width: "15%",
        },
        formatter: (cellContent, row, isExpand) => {
          return (
            <div className="text-right">
              <i className={"fas fa-chevron-down fa-lg "} />
            </div>
          );
        },
      },
    ];

    const expandRow = {
      renderer: (row) => (
        <React.Fragment>
          {row.teams.map((item, index) => (
            <div
              key={"expend-" + row.id + "-" + index}
              className="d-flex px-0 w-100 border-top c-pointer border-bottom"
              onClick={(e) => {
                this.teamDetailPage(e, item.id);
              }}
            >
              <div className="px-3 py-2 border-right" style={{ width: "85%" }}>
                {item.teamName}
              </div>
              <div className="px-3 py-2 border-left" style={{ width: "15%" }}>
                {item.ownerName}
              </div>
            </div>
          ))}
        </React.Fragment>
      ),
      onExpand: (row, isExpand, rowIndex, e) => {
        console.log($(e.target).find(".fa-chevron-down"));
        if (isExpand) {
          $(e.target)
            .parent()
            .find(".fa-chevron-down")
            .addClass("fa-chevron-up");

          $(e.target)
            .parent()
            .find(".fa-chevron-down")
            .removeClass("fa-chevron-down");
        } else {
          $(e.target)
            .parent()
            .find(".fa-chevron-up")
            .addClass("fa-chevron-down");

          $(e.target)
            .parent()
            .find(".fa-chevron-up")
            .removeClass("fa-chevron-up");
        }
      },
    };

    this.getTeams();
    this.RemotePagination = (rowEvents) => {
      console.log("Remote Pagination");
      return (
        <div>
          {this.props.permissionState.permissionActiveTab === 1 && (
            <BootstrapTable
              keyField="id"
              data={this.nationalFilters()}
              columns={this.columns}
              pagination={paginationFactory({
                paginationTotalRenderer: TableCustomTotal,
                page: localStorage.getItem("teamsPageNumber")
                  ? parseInt(localStorage.getItem("teamsPageNumber"))
                  : this.state.page,
                sizePerPage: localStorage.getItem("teamsPageSize")
                  ? parseInt(localStorage.getItem("teamsPageSize"))
                  : this.state.sizePerPage,
                onPageChange: (page, pageSize) => {
                  localStorage.setItem("teamsPageNumber", page);
                  localStorage.setItem("teamsPageSize", pageSize);
                },
              })}
              rowEvents={rowEvents}
            />
          )}
          {this.props.permissionState.permissionActiveTab === 2 && (
            <BootstrapTable
              keyField="regionID"
              data={this.regionfacilityFilters(this.state.data.regionTeams)}
              // pagination={paginationFactory({
              //   paginationTotalRenderer: TableCustomTotal,
              // })}
              columns={columns}
              expandRow={expandRow}
            />
          )}
          {this.props.permissionState.permissionActiveTab === 3 && (
            <BootstrapTable
              keyField="facilityID"
              data={this.regionfacilityFilters(this.state.data.facilityTeams)}
              // pagination={paginationFactory({
              //   paginationTotalRenderer: TableCustomTotal,
              // })}
              columns={columnsFacility}
              expandRow={expandRow}
            />
          )}
        </div>
      );
    };
    this.setReloader = this.setReloader.bind(this);
    this.handleDefaultPermissionModal = this.handleDefaultPermissionModal.bind(
      this
    );
    this.closeDefaultPermissionModal = this.closeDefaultPermissionModal.bind(
      this
    );
  }
  nationalFilters() {
    return this.state.data.nationalTeams.filter(
      (itemQ) =>
        _.toLower(itemQ.teamName).includes(
          _.toLower(this.state.searchTerm || "")
        ) ||
        _.toLower(itemQ.ownerName).includes(
          _.toLower(this.state.searchTerm || "")
        )
    );
  }
  regionfacilityFilters(data) {
    let as = data.filter(
      (itemR) =>
        _.toLower(itemR.region).includes(
          _.toLower(this.state.searchTerm || "")
        ) ||
        _.toLower(itemR.facility).includes(
          _.toLower(this.state.searchTerm || "")
        ) ||
        itemR.teams.find(
          (itemT) =>
            _.toLower(itemT.teamName).includes(
              _.toLower(this.state.searchTerm || "")
            ) ||
            _.toLower(itemT.ownerName).includes(
              _.toLower(this.state.searchTerm || "")
            )
        )
    );
    return _.cloneDeep(as).map((item) => {
      item.teams = item.teams.filter(
        (itemT) =>
          _.toLower(itemT.teamName).includes(
            _.toLower(this.state.searchTerm || "")
          ) ||
          _.toLower(itemT.ownerName).includes(
            _.toLower(this.state.searchTerm || "")
          )
      );
      return item;
    });
  }

  async getTeams() {
    $(".loading").show();
    let data = await Services.GetTeams();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        await this.setState({ data: data.result });
      } else {
        toast.error("Data loading error. Please, refresh your page!");
      }
    }

    $(".loading").hide();
  }

  componentDidMount() {
    this.props.history.push("/permissions/teams");
    //if (AuthService.canNational()) {
    //  this.props.setPermissionActiveTab(1);
    //} else if (AuthService.canRegion()) {
    //  this.props.setPermissionActiveTab(2);
    //} else if (AuthService.canFacility()) {
    //  this.props.setPermissionActiveTab(3);
    //} else {
    //  this.props.setPermissionActiveTab(0);
    //}
  }
  setReloader() {
    this.setState({ reloadEverything: true });
  }
  componentDidUpdate() {
    if (this.state.reloadEverything) {
      this.state.reloadEverything = false;

      this.getTeams();
      this.setState({ reloadEverything: this.state.reloadEverything });
    }
  }
  componentWillUnmount() {}
  closeDefaultPermissionModal() {
    this.props.closeDefaultPermissionModal();
  }
  handleDefaultPermissionModal = () => {
    this.props.openDefaultPermissionModal();
  };
  handleEditTeam = async () => {
    $(".loading").show();
    let data = await Services.TeamScopes(null);
    !_.isEmpty(data) && (await this.setState({ scopes: data.result }));
    window.$("#CreateTeamModal").modal({
      backdrop: "static",
      keyboard: true,
      show: true,
    });
    $(".loading").hide();
  };
  render() {
    // New Work
    const { data } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <PermissionHeader
          beginEditTeam={this.handleEditTeam}
          handleDefaultPermissionModal={this.handleDefaultPermissionModal}
        />
        <div className="team-container container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <h4 className="text-uppercase page-title-search">Teams</h4>

                <form role="search" className="app-search mt-0">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      id="keyword"
                      className="form-control rounded border mb-1 w-100"
                      placeholder="Team Search.."
                      onChange={(event) =>
                        this.setState({ searchTerm: event.target.value })
                      }
                    />
                    <button
                      type="button"
                      className="c-pointer text-secondary bg-transparent border-0"
                      onClick={(event) =>
                        this.setState({ searchTerm: $("#keyword").val() })
                      }
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {(data.nationalTeams || data.regionTeams || data.facilityTeams) &&
                this.RemotePagination(this.rowEvents)}
            </div>
          </div>
        </div>
        <ConfirmDeleteModal confirm={this.confirm} />
        <DefaultPermissionModal />
        {!_.isEmpty(this.state.scopes) && (
          <EditTeam scopes={this.state.scopes} setReloader={this.setReloader} />
        )}
      </AUX>
    );
  }
}

export default connect((state) => ({ permissionState: state.permission }), {
  ...PermissionAction,
})(Teams);
