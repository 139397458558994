import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../../Shared/auth-header";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import $ from "jquery";
import ConfirmDeleteModal from "../../Shared/Modals";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MDBInput } from "mdbreact";
import EditPosition from "./EditPosition";
import PermissionHeader from "../General/PermissionHeader";
import TableCustomTotal from '../../Shared/TableCustomTotal'
const { SearchBar } = Search;
class Positions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      page: 1,
      sizePerPage: 10,
      data: [],
      totalCount: "",
      delId: 0
    };
    this.handleFormSearch = this.handleFormSearch.bind(this);
    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        axios
          .delete("/api/Position/" + this.state.delId)
          .then(({ data }) => {
            if (data.status && data.status == "Success") {
              toast.success("Position Deleted Successfuly.");
              this.setState({ delId: 0 });
              $(".cancelModal").trigger("click");
              this.getPositions();
            } else {
              toast.error(data.message);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      };
      return this;
    };
    this.columns = [
      {
        text: "Position Name",
        dataField: "name",
        headerClasses: "bg-dark text-white px-3 py-2",
        classes: "px-3 py-2",
        sort: true
      },
      //{
      //  text: "Scope",
      //  dataField: "scopeType",
      //  sort: true
      //},
      {
        isDummyField: true,
        text: "",
        headerClasses: "bg-dark",
        headerAttrs: {
          width: "80px"
        },
        formatter: (cellContent, row) => {
          return (
            <div className="actionCol">
              {this.state.perms.canEdit && (
                <a
                  className="btn btn-sm btn-secondary waves-effect waves-light"
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                  }}
                  data-toggle="modal"
                  data-backdrop="static"
                  data-target="#EditPositionModal"
                  //to={{
                  //  pathname: "/admin/positions/edit-position",
                  //  type: "edit",
                  //  uid: row.id
                  //}}
                  color="secondary"
                >
                  <i className="ion ion-md-arrow-round-forward" />
                </a>
              )}
              {this.state.perms.canDelete && (
                <Link
                  className="ml-2 btn btn-sm btn-danger waves-effect waves-light del"
                  onClick={() => {
                    this.setState({ delId: row.id });
                  }}
                  to="#"
                  data-target=".delete-modal"
                  data-toggle="modal"
                  size="sm"
                >
                  <i className="fas fa-trash-alt del" />
                </Link>
              )}
            </div>
          );
        }
      }
    ];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (e.target.className.indexOf("del") < 0) {
          if (this.state.perms.canEdit) {
            //this.props.history.push({
            //  pathname: "/admin/positions/edit-position",
            //  type: "edit",
            //  uid: row.id,
            //  keyword: $("#keyword").val()
            //});
          }
        }
      }
    };
    this.RemotePagination = (
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
      showTotal,
      rowEvents
    ) => (
        <div>
          <BootstrapTable
            remote
            keyField="id"
            data={data}
            columns={this.columns}
            pagination={paginationFactory({
              page,
              sizePerPage,
              paginationTotalRenderer: TableCustomTotal,
              totalSize,
              showTotal
            })}
            onTableChange={onTableChange}
            rowEvents={rowEvents}
          />
        </div>
      );
  }
  getPositions() {
    $(".loading").show();
    const url = `/api/Position/GetPositions`;
    const formData = {
      Keyword: this.props.location.keyword,
      PageNo: this.state.page,
      PageSize: this.state.sizePerPage,
      Name: "",
      ScopeType: "",
      Facility: "",
      Region: "",
      Area: "",
      Role: "",
      OperatorType: "AND",
      SortOrder: "NameAsc"
    };
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }
  componentDidMount() {
    if (this.props.location.keyword) {
      $("#keyword").val(this.props.location.keyword);
    }
    this.getPositions();
  }
  handleFormSearch(e) {
    e.preventDefault();
    $(".loading").show();
    let url = `/api/Position/GetPositions`;
    const formData = {
      Keyword: $("#keyword").val(),
      Name: "",
      PageNo: 1,
      PageSize: 10,
      SortOrder: "NameAsc",
      ScopeType: "",
      Facility: "",
      Region: "",
      Area: "",
      Role: "",
      OperatorType: "AND"
    };

    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          page: 1
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }
  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    let url = `/api/Position/GetPositions`;
    if (type === "sort") {
      this.state.SortOrder = sortField + sortOrder;
      this.setState({ SortOrder: this.state.SortOrder });
    }
    const formData = {
      Keyword: $("#keyword").val(),
      PageNo: page,
      PageSize: sizePerPage,
      SortOrder: this.state.SortOrder,
      ScopeType: "",
      Facility: "",
      Region: "",
      Area: "",
      Role: "",
      OperatorType: "AND"
    };

    $(".loading").show();

    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          page,
          sizePerPage
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  };
  render() {
    const { data, sizePerPage, page, totalCount } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <PermissionHeader />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-6 text-center">
                    <h4 className="page-title-search text-uppercase">
                      Positions
                    </h4>
                    <form
                      role="search"
                      className="app-search mt-0"
                      onSubmit={this.handleFormSearch}
                    >
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          id="keyword"
                          className="form-control rounded border mb-1 w-100"
                          placeholder="Position Search.."
                        />
                        <button
                          type="submit"
                          className="c-pointer text-secondary bg-transparent border-0"
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {this.RemotePagination(
                    data,
                    page,
                    sizePerPage,
                    this.handleTableChange,
                    totalCount,
                    showTotal,
                    this.rowEvents
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 text-right">
              Total Results : {this.state.totalCount}
            </div>
          </div>
        </div>
        <ConfirmDeleteModal confirm={this.confirm} />
        <EditPosition type={"add"} uid={0} />
      </AUX>
    );
  }
}

export default Positions;
