import React from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { ToggleSwitchV2, TextField } from "./FormElements";
import styled from "styled-components";
import { QuestionTypes } from "../../Registrations/Helpers/LookUpValues";
import RegistrationsService from "../../Registrations/RegistrationsService";

function QuestionsBuilder() {
  const initialValues = {
    questions: [],
  };

  const validationSchema = Yup.object().shape({
    questionType: Yup.string().required("Question Type is required"),
    questions: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required("Label is required"),
        // isRequired: Yup.string().required("Please select a value"),
      })
    ),
  });
  const IsRequiredOptions = [
    { key: "True", value: "true" },
    { key: "False", value: "false" },
  ];
  function onChangeNumberOfQuestions(e, field, values, setValues) {
    // update dynamic form
    const questions = [...values.questions];
    const numberOfQuestions = e.target.value || 0;
    const previousNumber = parseInt(questions.length || "0");
    if (previousNumber < numberOfQuestions) {
      for (let i = previousNumber; i < numberOfQuestions; i++) {
        questions.push({
          label: "",
          isRequired: false,
          questionType: 1,
        });
      }
    } else {
      for (let i = previousNumber; i >= numberOfQuestions; i--) {
        questions.splice(i, 1);
      }
    }
    setValues({ ...values, questions });

    // call formik onChange method
    field.onChange(e);
  }
  function onChangeQuestionType(e, field, values, setValues, index) {
    // update dynamic form
    const questions = [...values.questions];
    const questionType = parseInt(e.target.value || "1");
    questions[index].questionType = questionType;
    questions[index].isRequired = false;

    if (questionType == QuestionTypes.DropDown) {
      questions[index].hasOptions = true;
      questions[index].options = [""];
    } else if (questionType == QuestionTypes.Checkbox) {
      questions[index].options = [""];
      questions[index].hasOptions = true;
    } else {
      questions[index].hasOptions = false;
      questions[index].options = []; // clear options
    }
    setValues({ ...values, questions });

    // call formik onChange method
    field.onChange(e);
  }
  function addQuestion(e, values, setValues) {
    var questions = [...values.questions];
    questions.push({
      label: "",
      isRequired: false,
    });
    setValues({ ...values, questions });
  }
  const onSubmit = async (fields) => {
    // display form field values on success
    //alert("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 4));
    console.log("In onSubmit Method", JSON.stringify(fields));

    await RegistrationsService.AddUpdateQuestions(fields).then((data) => {
      if (data && data.status && data.status == "Success") {
        toast.success(data.message);
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  function deleteQuestion(i, values, setValues) {
    var questions = [...values.questions];
    questions = questions.filter((el, index) => {
      return index !== i;
    });

    setValues({ ...values, questions });
  }
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values, touched, setValues }) => (
        <Form>
          <div className="card m-3">
            <h5 className="card-header">Create Questions </h5>
            <div className="card-body border-bottom">
              <div className="form-row">
                <div className="form-group">
                  <label>Total Number of Questions</label>
                  <Field name="numberOfQuestion">
                    {({ field }) => (
                      <select
                        {...field}
                        className={
                          "form-control" +
                          (errors.numberOfQuestion && touched.numberOfQuestion
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) =>
                          onChangeNumberOfQuestions(e, field, values, setValues)
                        }
                      >
                        {["Choose", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                          <option key={i} value={i} selected={i == "Choose"}>
                            {i}
                          </option>
                        ))}
                      </select>
                    )}
                  </Field>
                  <ErrorMessage
                    name="numberOfQuestion"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <button
                  type="button"
                  className="btn btn-primary btn-sm w-md waves-effect waves-light ml-2"
                  onClick={(e) => addQuestion(e, values, setValues)}
                >
                  + Add Question
                </button>
              </div>
            </div>
            <FieldArray name="questions">
              {() =>
                values.questions.map((question, i) => {
                  const questionErrors =
                    (errors.questions &&
                      errors.questions.length &&
                      errors.questions[i]) ||
                    {};
                  const questionTouched =
                    (touched.questions &&
                      touched.questions.length &&
                      touched.questions[i]) ||
                    {};

                  return (
                    <div key={i} className="list-group list-group-flush">
                      <div className="list-group-item">
                        <button
                          type="button"
                          onClick={() => deleteQuestion(i, values, setValues)}
                          className="btn btn-danger btn-sm float-right"
                        >
                          <i className="fas fa-trash-alt " />
                        </button>
                        <h5 className="card-title font-weight-bold text-center">
                          Question {i + 1}
                        </h5>
                        <div className="form-row">
                          <div className="form-group col-2">
                            <label className=" font-weight-bold">
                              Select Control Type
                            </label>
                            <Field name={`questions.${i}.questionType`}>
                              {({ field }) => (
                                <select
                                  {...field}
                                  className={
                                    "form-control" +
                                    (questionErrors.questionType &&
                                    questionTouched.questionType
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={(e) =>
                                    onChangeQuestionType(
                                      e,
                                      field,
                                      values,
                                      setValues,
                                      i
                                    )
                                  }
                                >
                                  <option value="" />
                                  {Object.keys(QuestionTypes).map(
                                    (i, index) => (
                                      <option
                                        key={i}
                                        value={index + 1}
                                        selected={i == "Text"}
                                      >
                                        {i}
                                      </option>
                                    )
                                  )}
                                </select>
                              )}
                            </Field>
                            <ErrorMessage
                              name="questionType"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-6">
                            <TextField
                              name={`questions.${i}.label`}
                              type="text"
                              lable="Enter Question Label"
                              placeholder="Enter Question Label"
                            />
                          </div>
                          <div className="form-group col-6 ">
                            <SwitchDiv>
                              <ToggleSwitchV2
                                label="Is Required"
                                name={`${`questions.${i}.isRequired`}`}
                              />
                            </SwitchDiv>
                          </div>
                        </div>

                        {question.hasOptions && (
                          <FieldArray
                            name={`questions.${i}.options`}
                            className="form-row"
                          >
                            {(fieldArrayProps) => {
                              const { push, remove, form } = fieldArrayProps;
                              const { values } = form;
                              debugger;
                              const { options } = values.questions[i]
                                ? values.questions[i]
                                : {};
                              //console.log("fieldArrayProps", fieldArrayProps);
                              //console.log("Form errors", form.errors);
                              return (
                                <>
                                  <span className="font-weight-bold">
                                    Add Options
                                  </span>
                                  {options &&
                                    options.map((options, index) => (
                                      <div key={index} className="row">
                                        <div className="col-3">
                                          <Field
                                            id={`questions.${i}.questionOptions[${index}].option`}
                                            name={`questions.${i}.questionOptions[${index}].option`}
                                            className="form-control mt-2"
                                            placeholder={`Option #${index + 1}`}
                                          />
                                        </div>
                                        {index > 0 && (
                                          <div className="col-3 mt-2">
                                            <button
                                              type="button"
                                              className="btn btn-danger btn-sm ml-2 w-md waves-effect waves-light"
                                              onClick={() => remove(index)}
                                            >
                                              -
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  <div className="col mt-2 pl-0">
                                    <button
                                      type="button"
                                      className="btn btn-success btn-sm w-md waves-effect waves-light"
                                      onClick={() => push("")}
                                    >
                                      + Add
                                    </button>
                                  </div>
                                </>
                              );
                            }}
                          </FieldArray>
                        )}
                      </div>
                    </div>
                  );
                })
              }
            </FieldArray>
            <div className="card-footer text-center border-top-0">
              <button type="submit" className="btn btn-primary mr-1">
                Submit
              </button>
              <button className="btn btn-secondary mr-1" type="reset">
                Reset
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export { QuestionsBuilder };
const SwitchDiv = styled.div`
  margin-top: -2rem;
  padding-left: 2rem;
`;
