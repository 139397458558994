import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { toast } from "react-toastify";
import "../payfilestyle.css";
import { connect } from "react-redux";
import PayfileAction from "../../../store/payfileTwo/action";
import Services from "../PayfileService";
import _ from "lodash";
import $ from "jquery";
import ImportDetailModal from "./ImportDetailModal";
import ConfirmDeleteModal from "./General/DeleteModal";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "../../../modules/Shared/auth-header";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
class ImportSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      fileId: -1,
      offset: 0,
      fetch: 20,
      errorsOffset: 0,
      updatesOffset: 0,
      ignoresOffset: 0,
      deleteRefresh: false,
      deleteStatus: "",
      searchTerm: "",
      payFileData: null,
      errorsIsMore: true,
      ignoresIsMore: true,
      updatesIsMore: true,
      detailIgnoreStatus: false,
      deleteImport: null,
      deleteModal: false,
      deleteECI: "",
      importData: null,
      updatedJobAndField: [],
      payfileDetail: null,
    };
    this.searchKeyword = this.searchKeyword.bind(this);
    this.handleRemoveEmpoloyeeJobs = this.handleRemoveEmpoloyeeJobs.bind(this);
    this.refreshOnIgnore = this.refreshOnIgnore.bind(this);
    this.refreshOnUpdate = this.refreshOnUpdate.bind(this);
  }
  Status = {
    Skip: "Skip",
    Update: "Update",
    Error: "Error",
  };
  Modes = {
    excel: "excel",
    pdf: "pdf",
  };
  refreshOnUpdate = async () => {
    $(".loading").show();
    this.state.deleteRefresh = true;
    await this.setState({ deleteRefresh: this.state.deleteRefresh });
    if (this.state.detailIgnoreStatus) {
      await this.LoadPaginationData("skip");
    } else {
      await this.LoadPaginationData("error");
    }
    await this.LoadPaginationData("update");
    // await this.LoadData();
    this.LoadDetail();
    $(".loading").hide();
    await this.setState({ deleteRefresh: false });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/admin/uploads");
    }
    this.state.fileId = id;
    this.setState({ fileId: this.state.fileId });
    this.LoadData();
    this.LoadDetail();
  }

  async LoadData() {
    await this.setState({
      ignoresOffset: 0,
      updatesOffset: 0,
      errorsOffset: 0,
    });
    let data = await Services.LoadPayFileTwoData(
      this.state.searchTerm,
      this.state.fileId,
      this.state.offset,
      this.state.fetch
    );
    if (data && data.statusCode == 200) {
      this.state.payFileData = data.result;
      if (
        this.state.payFileData.errors &&
        !_.isEmpty(this.state.payFileData.errors)
      ) {
        this.state.errorsIsMore =
          this.state.payFileData.errors.length ==
          this.state.fetch + this.state.errorsOffset;
      }
      if (
        this.state.payFileData.updates &&
        !_.isEmpty(this.state.payFileData.updates)
      ) {
        this.state.updatesIsMore =
          this.state.payFileData.updates.length ==
          this.state.fetch + this.state.updatesOffset;
      }
      if (
        this.state.payFileData.ignores &&
        !_.isEmpty(this.state.payFileData.ignores)
      ) {
        this.state.ignoresIsMore =
          this.state.payFileData.ignores.length ==
          this.state.fetch + this.state.ignoresOffset;
      }
      this.setState({
        payFileData: this.state.payFileData,
        ignoresIsMore: this.state.ignoresIsMore,
        updatesIsMore: this.state.updatesIsMore,
        errorsIsMore: this.state.errorsIsMore,
        ignoresOffset: this.state.fetch,
        errorsOffset: this.state.fetch,
        updatesOffset: this.state.fetch,
      });
    }
  }
  async LoadDetail() {
    let data = await Services.LoadPayFileTwoDetail(this.state.fileId);
    if (data && data.statusCode == 200) {
      this.setState({ payfileDetail: data.result });
    }
  }
  async LoadPaginationData(status) {
    switch (status) {
      case "skip": {
        let data = null;
        if (this.state.deleteRefresh) {
          data = await Services.LoadPayFileTwoPGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            0,
            this.state.ignoresOffset
          );
        } else {
          data = await Services.LoadPayFileTwoPGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            this.state.ignoresOffset,
            this.state.fetch
          );
        }
        if (data && data.statusCode == 200) {
          if (this.state.payFileData.ignores) {
            if (this.state.deleteRefresh) {
              this.state.payFileData.ignores = data.result;
            } else {
              this.state.payFileData.ignores.push(...data.result);
            }
            this.state.ignoresIsMore =
              this.state.payFileData.ignores.length ==
              (!this.state.deleteRefresh
                ? this.state.fetch + this.state.ignoresOffset
                : this.state.ignoresOffset);
            this.state.ignoresOffset =
              this.state.ignoresIsMore && !this.state.deleteRefresh
                ? this.state.ignoresOffset + this.state.fetch
                : this.state.ignoresOffset;
          }

          this.setState({
            payFileData: this.state.payFileData,
            ignoresIsMore: this.state.ignoresIsMore,
            ignoresOffset: this.state.ignoresOffset,
          });
        }
        break;
      }
      case "update": {
        let data = null;
        if (this.state.deleteRefresh) {
          data = await Services.LoadPayFileTwoPGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            0,
            this.state.updatesOffset
          );
        } else {
          data = await Services.LoadPayFileTwoPGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            this.state.updatesOffset,
            this.state.fetch
          );
        }
        if (data && data.statusCode == 200) {
          if (this.state.payFileData.updates) {
            if (this.state.deleteRefresh) {
              this.state.payFileData.updates = data.result;
            } else {
              this.state.payFileData.updates.push(...data.result);
            }
            this.state.updatesIsMore =
              this.state.payFileData.updates.length ==
              (!this.state.deleteRefresh
                ? this.state.fetch + this.state.updatesOffset
                : this.state.updatesOffset);
            this.state.updatesOffset =
              this.state.updatesIsMore && !this.state.deleteRefresh
                ? this.state.updatesOffset + this.state.fetch
                : this.state.updatesOffset;
          }

          this.setState({
            payFileData: this.state.payFileData,
            updatesIsMore: this.state.updatesIsMore,
            updatesOffset: this.state.updatesOffset,
          });
        }
        break;
      }
      case "error": {
        let data = null;
        if (this.state.deleteRefresh) {
          data = await Services.LoadPayFileTwoPGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            0,
            this.state.errorsOffset
          );
        } else {
          data = await Services.LoadPayFileTwoPGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            this.state.errorsOffset,
            this.state.fetch
          );
        }
        if (data && data.statusCode == 200) {
          if (this.state.payFileData.errors) {
            if (this.state.deleteRefresh) {
              this.state.payFileData.errors = data.result;
            } else {
              this.state.payFileData.errors.push(...data.result);
            }
            this.state.errorsIsMore =
              this.state.payFileData.errors.length ==
              (!this.state.deleteRefresh
                ? this.state.fetch + this.state.errorsOffset
                : this.state.errorsOffset);

            this.state.errorsOffset =
              this.state.errorsIsMore && !this.state.deleteRefresh
                ? this.state.errorsOffset + this.state.fetch
                : this.state.errorsOffset;
          }
          this.setState({
            payFileData: this.state.payFileData,
            errorsIsMore: this.state.errorsIsMore,
            errorsOffset: this.state.errorsOffset,
          });
        }
        break;
      }
      default:
    }
  }

  async LoadUpdates(memberID, fileID, payfielTwoID) {
    // on expand it will call
    // result will stored with memberId as a key
    // next time if key not found then object will be create.
    // NB ==> flag assign to those whose value not exist in DB
    let update = false;
    let index = this.state.updatedJobAndField.findIndex(
      (x) => x.Id == memberID
    );
    let Obj = null;
    if (index > -1) {
      Obj = this.state.updatedJobAndField[index];
    } else {
      update = true;
      Obj = this.updatedJobAndFieldObj(memberID);
    }
    if (Obj.Fields == null && Obj.Fields != "NB") {
      update = true;
      let data = await Services.LoadUpdateField(fileID, memberID);
      if (data != null) {
        Obj.Fields = data.result;
      } else {
        Obj.Fields = "NB";
      }
    }
    if (Obj.Jobs == null && Obj.Jobs != "NB") {
      update = true;
      let data = await Services.LoadUpdateJob(fileID, memberID, payfielTwoID);
      if (data != null) {
        Obj.Jobs = data.result;
      } else {
        Obj.Jobs = "NB";
      }
    }
    if (!(index > -1)) {
      this.state.updatedJobAndField.push(Obj);
    }
    if (update) {
      this.setState({ updatedJobAndField: this.state.updatedJobAndField });
    }
  }
  updatedJobAndFieldObj(memberId) {
    return {
      Id: memberId,
      Jobs: null,
      Fields: null,
    };
  }
  erroroggle = () => {
    this.setState({ isError: !this.state.isError });
  };
  refreshOnIgnore = async () => {
    $(".loading").show();
    this.state.deleteRefresh = true;
    await this.setState({ deleteRefresh: this.state.deleteRefresh });
    await this.LoadPaginationData("error");
    await this.LoadPaginationData("skip");
    // await this.LoadData();
    this.LoadDetail();
    $(".loading").hide();
    await this.setState({ deleteRefresh: false });
  };
  handleRemoveEmpoloyeeJobs = async () => {
    $(".loading").show();
    if (!_.isEmpty(this.state.deleteECI) && !_.isEmpty(this.state.fileId)) {
      var data = await Services.DeleteEmployeeJobs(
        this.state.deleteECI,
        this.state.fileId,
        this.state.deleteStatus
      );
      if (data != null) {
        this.state.deleteRefresh = true;
        await this.setState({ deleteRefresh: this.state.deleteRefresh });
        await this.LoadPaginationData(this.state.deleteStatus);
        // await this.LoadData();
        this.LoadDetail();
        $(".loading").hide();
        await this.setState({
          deleteRefresh: false,
          deleteModal: false,
          deleteStatus: "",
        });
      } else {
        $(".loading").hide();
        await this.setState({ deleteModal: false });
      }
    } else {
      toast.error("Invalid state, Try again after few moments.");
      await this.setState({ deleteModal: false });
      $(".loading").hide();
    }
  };
  searchKeyword() {
    $(".show:not(.scroll-view)").removeClass("show");
    this.state.searchTerm = $("#keywordIS2").val();
    this.state.ignoresOffset = 0;
    this.state.updatesOffset = 0;
    this.state.errorsOffset = 0;
    this.setState({
      searchTerm: $("#keywordIS2").val(),
      ignoresOffset: 0,
      updatesOffset: 0,
      errorsOffset: 0,
    });
    this.LoadData();
  }
  render() {
    return (
      <AUX>
        <div className="container-fluid">
          {AuthService.canView(PermissionFeature.Payfile1ReviewSummary) ? (
            <React.Fragment>
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <h4 className="text-uppercase page-title-search">
                      Payfile 2 Import Summary
                    </h4>
                    <div role="search" className="app-search mt-0">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          id="keywordIS2"
                          className="form-control rounded border mb-1 w-100"
                          placeholder="Import Search.."
                          onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.keyCode == 13) {
                              this.searchKeyword();
                            }
                          }}
                        />
                        <button
                          type="button"
                          className="c-pointer text-secondary bg-transparent border-0"
                          onClick={this.searchKeyword}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-4 text-right payfiledetail">
                    {!_.isEmpty(this.state.payfileDetail) && (
                      <div className="row">
                        <div className="col-md-4">
                          <h4>Imported Date</h4>
                          <p>
                            {moment(this.state.payfileDetail.createDate).format(
                              "MM/DD/YYYY"
                            )}
                          </p>
                        </div>
                        <div className="col-md-4">
                          <h4>Total Records</h4>
                          <p>{this.state.payfileDetail.totalRecord}</p>
                        </div>
                        <div className="col-md-4">
                          <h4>Year/PP</h4>
                          <p>
                            {this.state.payfileDetail.year}/
                            {this.state.payfileDetail.payPeriod}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-summary-main">
                <div className="import-table">
                  <div className="top-th dark-gray">
                    <div className="w17-5 td">FIRST NAME</div>
                    <div className="w17-5 td">LAST NAME</div>
                    <div className="w17-5 td">REGION</div>
                    <div className="w17-5 td">FACILITY</div>
                    <div className="w17-5 td">FAA ID</div>
                    <div className="w12-5 td">MEMBER #</div>
                  </div>
                  {this.state.payFileData &&
                    this.state.payFileData.errors &&
                    !_.isEmpty(this.state.payFileData.errors) && (
                      <>
                        <div className="top-th red-white">
                          <div
                            data-toggle="collapse"
                            data-target="#errors"
                            aria-expanded="true"
                            aria-controls="errors"
                            className="w93 td"
                          >
                            IMPORT ERRORS{" "}
                            {!_.isEmpty(this.state.payfileDetail) && (
                              <span className="sectionCount">
                                ({this.state.payfileDetail.totalErrorEffective}{" "}
                                members effective out of{" "}
                                {this.state.payfileDetail.totalError} payfiles)
                              </span>
                            )}
                          </div>
                          <div className="w7 icons-file">
                            <a
                              className="text-white"
                              target="_blank"
                              href={`${axios.defaults.baseURL}/api/Payfile/PayfileTwoExcel?status=${this.Status.Error}&fileID=${this.state.fileId}`}
                            >
                              <i class="fas fa-file-csv"></i>
                            </a>
                            <a
                              className="text-white"
                              target="_blank"
                              href={`${axios.defaults.baseURL}/api/Payfile/PayfileTwoPDF?status=${this.Status.Error}&fileID=${this.state.fileId}`}
                            >
                              <i class="fas fa-file-pdf"></i>
                            </a>
                          </div>
                        </div>
                        <div id="errors" className="collapse show scroll-view">
                          <InfiniteScroll
                            dataLength={this.state.payFileData.errors.length}
                            next={() => {
                              this.LoadPaginationData("error");
                            }}
                            hasMore={this.state.errorsIsMore}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="errors"
                          >
                            {this.state.payFileData.errors.map((item, idx) => (
                              <>
                                <div
                                  className="top-td light-white"
                                  data-toggle="collapse"
                                  data-target={"#err-" + idx}
                                  aria-expanded={idx == 0 ? "true" : "false"}
                                  aria-controls="errorsdivs"
                                >
                                  <div className="w17-5 td resW99">
                                    {item.firstName}
                                  </div>
                                  <div className="w17-5 td resW99">
                                    {item.lastName}
                                  </div>
                                  <div className="w17-5 td resW72">
                                    {item.regionCode}
                                  </div>
                                  <div className="w17-5 td resW72">
                                    {item.facilityCodeTo}
                                  </div>
                                  <div className="w17-5 td resW65">
                                    {item.eci}
                                  </div>
                                  <div className="w12-5 td resW85">
                                    {item.memberNumber}
                                  </div>
                                </div>

                                <div
                                  className={
                                    "w100 bdr-bottom collapse" +
                                    (idx == 0 ? " show" : "")
                                  }
                                  id={"err-" + idx}
                                >
                                  <div className="rowdiv pt-3">
                                    <div className="w100 pdl-45">
                                      <p className="infoError reason-area">
                                        Reason: <span>{item.reason}</span>
                                      </p>
                                    </div>
                                  </div>

                                  <h6 className="error-i mb-1  mt-0">
                                    Errors{" "}
                                    {AuthService.canUpdate(
                                      PermissionFeature.Payfile1ReviewSummary
                                    ) && (
                                      <i
                                        className="fas fa-pen c-pointer"
                                        onClick={() => {
                                          this.setState({
                                            importData: item,
                                            detailIgnoreStatus: false,
                                          });
                                          this.props.openImportDetailModal();
                                        }}
                                      ></i>
                                    )}
                                    {AuthService.canDelete(
                                      PermissionFeature.Payfile1ReviewSummary
                                    ) && (
                                      <i
                                        onClick={(e) => {
                                          this.state.deleteECI = item.eci;
                                          this.setState({
                                            deleteECI: this.state.deleteECI,
                                            deleteModal: true,
                                            deleteStatus: "error",
                                          });
                                        }}
                                        className="fas fa-trash c-pointer"
                                      ></i>
                                    )}
                                  </h6>
                                  <div className="rowdiv pb-3">
                                    <div className="width35 pdl-45">
                                      <p className="infoError">
                                        NOA CODES:{" "}
                                        <span>
                                          {(item.noA1 != null
                                            ? item.noA1
                                            : "--") +
                                            " / " +
                                            (item.noA2 != null
                                              ? item.noA2
                                              : "--")}
                                        </span>
                                      </p>
                                      <p className="infoError">
                                        ORG TO/FROM:{" "}
                                        <span>
                                          {(item.orgTo != null
                                            ? item.orgTo
                                            : "--") +
                                            " / " +
                                            (item.orgFrom != null
                                              ? item.orgFrom
                                              : "--")}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="width35 ">
                                      <p className="infoError">
                                        BUS TO/FROM:{" "}
                                        <span>
                                          {(item.busTo != null
                                            ? item.busTo
                                            : "--") +
                                            " / " +
                                            (item.busFrom != null
                                              ? item.busFrom
                                              : "--")}
                                        </span>
                                      </p>
                                      <p className="infoError">
                                        FACILITY TO/FROM:{" "}
                                        <span>
                                          {" "}
                                          {(item.facilityCodeTo != null
                                            ? item.facilityCodeTo
                                            : "--") +
                                            " / " +
                                            (item.facilityCodeFrom != null
                                              ? item.facilityCodeFrom
                                              : "--")}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                  {this.state.payFileData &&
                    this.state.payFileData.ignores &&
                    !_.isEmpty(this.state.payFileData.ignores) && (
                      <>
                        <div className="top-th yellow-black">
                          <div
                            data-toggle="collapse"
                            data-target="#ignore"
                            aria-expanded="false"
                            aria-controls="ignore"
                            className="w93 td"
                          >
                            IGNORED UPDATES{" "}
                            {!_.isEmpty(this.state.payfileDetail) && (
                              <span className="sectionCount">
                                ({this.state.payfileDetail.totalIgnoreEffective}{" "}
                                members effective out of{" "}
                                {this.state.payfileDetail.totalIgnore} payfiles)
                              </span>
                            )}
                          </div>
                          <div className="w7 icons-file">
                            <a
                              className="text-dark"
                              target="_blank"
                              href={`${axios.defaults.baseURL}/api/Payfile/PayfileTwoExcel?status=${this.Status.Skip}&fileID=${this.state.fileId}`}
                            >
                              <i class="fas fa-file-csv"></i>
                            </a>
                            <a
                              className="text-dark"
                              target="_blank"
                              href={`${axios.defaults.baseURL}/api/Payfile/PayfileTwoPDF?status=${this.Status.Skip}&fileID=${this.state.fileId}`}
                            >
                              <i class="fas fa-file-pdf"></i>
                            </a>
                          </div>
                        </div>
                        <div
                          id="ignore"
                          className={
                            "collapse scroll-view" +
                            (!_.isEmpty(this.state.searchTerm) ||
                            (this.state.payFileData.errors &&
                              _.isEmpty(this.state.payFileData.errors))
                              ? " show"
                              : "")
                          }
                        >
                          <InfiniteScroll
                            dataLength={this.state.payFileData.ignores.length}
                            next={() => {
                              this.LoadPaginationData("skip");
                            }}
                            hasMore={this.state.ignoresIsMore}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="ignore"
                          >
                            {this.state.payFileData.ignores.map((item, idx) => (
                              <>
                                <div
                                  className="top-td light-white"
                                  data-toggle="collapse"
                                  data-target={"#igr-" + idx}
                                  aria-expanded={idx == 0 ? "true" : "false"}
                                  aria-controls="ignoresdivs"
                                >
                                  <div className="w17-5 td resW99">
                                    {item.firstName}
                                  </div>
                                  <div className="w17-5 td resW99">
                                    {item.lastName}
                                  </div>
                                  <div className="w17-5 td resW72">
                                    {item.regionCode}
                                  </div>
                                  <div className="w17-5 td resW72">
                                    {item.facilityCodeTo}
                                  </div>
                                  <div className="w17-5 td resW65">
                                    {item.eci}
                                  </div>
                                  <div className="w12-5 td resW85">
                                    {item.memberNumber}
                                  </div>
                                </div>

                                <div
                                  className={
                                    "w100 bdr-bottom collapse" +
                                    (idx == 0 ? " show" : "")
                                  }
                                  id={"igr-" + idx}
                                >
                                  <div className="rowdiv pt-3">
                                    <div className="w100 pdl-45">
                                      <p className="infoError reason-area">
                                        Reason: <span>{item.reason}</span>
                                      </p>
                                    </div>
                                  </div>
                                  <h6 className="ignore-i mb-1 pdl-45 mt-0">
                                    Import Values
                                    {AuthService.canUpdate(
                                      PermissionFeature.Payfile1ReviewSummary
                                    ) && (
                                      <i
                                        className="fas fa-pen c-pointer"
                                        onClick={() => {
                                          this.setState({
                                            importData: item,
                                            detailIgnoreStatus: true,
                                          });
                                          this.props.openImportDetailModal();
                                        }}
                                      ></i>
                                    )}
                                    {AuthService.canDelete(
                                      PermissionFeature.Payfile1ReviewSummary
                                    ) && (
                                      <i
                                        onClick={(e) => {
                                          this.state.deleteECI = item.eci;
                                          this.setState({
                                            deleteECI: this.state.deleteECI,
                                            deleteModal: true,
                                            deleteStatus: "skip",
                                          });
                                        }}
                                        className="fas fa-trash c-pointer"
                                      ></i>
                                    )}
                                  </h6>
                                  <div className="rowdiv pb-3">
                                    <div className="width35 pdl-45">
                                      <p className="infoError">
                                        NOA CODES:{" "}
                                        <span>
                                          {(item.noA1 != null
                                            ? item.noA1
                                            : "--") +
                                            " / " +
                                            (item.noA2 != null
                                              ? item.noA2
                                              : "--")}
                                        </span>
                                      </p>
                                      <p className="infoError">
                                        ORG TO/FROM:{" "}
                                        <span>
                                          {(item.orgTo != null
                                            ? item.orgTo
                                            : "--") +
                                            " / " +
                                            (item.orgFrom != null
                                              ? item.orgFrom
                                              : "--")}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="width35 ">
                                      <p className="infoError">
                                        BUS TO/FROM:{" "}
                                        <span>
                                          {(item.busTo != null
                                            ? item.busTo
                                            : "--") +
                                            " / " +
                                            (item.busFrom != null
                                              ? item.busFrom
                                              : "--")}
                                        </span>
                                      </p>
                                      <p className="infoError">
                                        FACILITY TO/FROM:{" "}
                                        <span>
                                          {" "}
                                          {(item.facilityCodeTo != null
                                            ? item.facilityCodeTo
                                            : "--") +
                                            " / " +
                                            (item.facilityCodeFrom != null
                                              ? item.facilityCodeFrom
                                              : "--")}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                  {this.state.payFileData &&
                    this.state.payFileData.updates &&
                    !_.isEmpty(this.state.payFileData.updates) && (
                      <>
                        <div className="top-th light-gray">
                          <div
                            data-toggle="collapse"
                            data-target="#success"
                            aria-expanded="false"
                            aria-controls="success"
                            className="w93 td"
                          >
                            SUCCESSFUL UPDATES{" "}
                            {!_.isEmpty(this.state.payfileDetail) && (
                              <span className="sectionCount">
                                ({this.state.payfileDetail.totalUpdateEffective}{" "}
                                members effective out of{" "}
                                {this.state.payfileDetail.totalUpdate} payfiles)
                              </span>
                            )}
                          </div>
                          <div className="w7 icons-file">
                            <a
                              className="text-white"
                              target="_blank"
                              href={`${axios.defaults.baseURL}/api/Payfile/PayfileTwoExcel?status=${this.Status.Update}&fileID=${this.state.fileId}`}
                            >
                              <i class="fas fa-file-csv"></i>
                            </a>
                            <a
                              className="text-white"
                              target="_blank"
                              href={`${axios.defaults.baseURL}/api/Payfile/PayfileTwoPDF?status=${this.Status.Update}&fileID=${this.state.fileId}`}
                            >
                              <i class="fas fa-file-pdf"></i>
                            </a>
                          </div>
                        </div>
                        <div
                          id="success"
                          className={
                            "collapse scroll-view" +
                            (!_.isEmpty(this.state.searchTerm) ||
                            (this.state.payFileData.errors &&
                              _.isEmpty(this.state.payFileData.errors) &&
                              this.state.payFileData.ignores &&
                              _.isEmpty(this.state.payFileData.ignores))
                              ? " show"
                              : "")
                          }
                        >
                          <InfiniteScroll
                            dataLength={this.state.payFileData.updates.length}
                            next={() => {
                              this.LoadPaginationData("update");
                            }}
                            hasMore={this.state.updatesIsMore}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="success"
                          >
                            {this.state.payFileData.updates.map((item, idx) => {
                              if (idx == 0) {
                                this.LoadUpdates(
                                  item.memberID,
                                  this.state.fileId,
                                  item.id
                                );
                              }
                              return (
                                <>
                                  <div
                                    className="top-td light-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.LoadUpdates(
                                        item.memberID,
                                        this.state.fileId,
                                        item.id
                                      );
                                      if (
                                        $("#upd-" + item.id).hasClass("show")
                                      ) {
                                        $("#upd-" + item.id).removeClass(
                                          "show"
                                        );
                                      } else {
                                        $("#upd-" + item.id).addClass("show");
                                      }
                                    }}
                                    aria-expanded={
                                      idx == 0 &&
                                      !_.isEmpty(
                                        this.state.updatedJobAndField
                                      ) &&
                                      this.state.updatedJobAndField.findIndex(
                                        (x) => x.Id == item.memberID
                                      ) > -1
                                        ? "true"
                                        : "false"
                                    }
                                    aria-controls="updatesdivs"
                                  >
                                    <div className="w17-5 td resW99">
                                      {item.firstName}
                                    </div>
                                    <div className="w17-5 td resW99">
                                      {item.lastName}
                                    </div>
                                    <div className="w17-5 td resW72">
                                      {item.regionCode}
                                    </div>
                                    <div className="w17-5 td resW72">
                                      {item.facilityCodeTo}
                                    </div>
                                    <div className="w17-5 td resW65">
                                      {item.eci}
                                    </div>
                                    <div className="w12-5 td resW85">
                                      {item.memberNumber}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      "w100 bdr-bottom collapse" +
                                      (idx == 0 &&
                                      !_.isEmpty(
                                        this.state.updatedJobAndField
                                      ) &&
                                      this.state.updatedJobAndField.findIndex(
                                        (x) => x.Id == item.memberID
                                      ) > -1
                                        ? " show"
                                        : "")
                                    }
                                    id={"upd-" + item.id}
                                  >
                                    {!_.isEmpty(
                                      this.state.updatedJobAndField
                                    ) &&
                                      this.state.updatedJobAndField.findIndex(
                                        (x) => x.Id == item.memberID
                                      ) > -1 &&
                                      (() => {
                                        let indexx = this.state.updatedJobAndField.findIndex(
                                          (x) => x.Id == item.memberID
                                        );
                                        return (
                                          <div className="rowdiv pb-3">
                                            <div className="width35 resW31person pdl-45 respdl-20">
                                              {!_.isEmpty(
                                                this.state.updatedJobAndField[
                                                  indexx
                                                ].Fields
                                              ) &&
                                                this.state.updatedJobAndField[
                                                  indexx
                                                ].Fields != "NB" && (
                                                  <>
                                                    <h6 className="ignore-i mb-1">
                                                      Update Fields
                                                    </h6>
                                                    {this.state.updatedJobAndField[
                                                      indexx
                                                    ].Fields.map((itemF) => (
                                                      <p className="infoError">
                                                        {itemF.fieldName}:{" "}
                                                        <span>
                                                          {_.isEmpty(
                                                            itemF.fieldValue
                                                          )
                                                            ? "Null"
                                                            : itemF.fieldValue}
                                                        </span>
                                                      </p>
                                                    ))}
                                                  </>
                                                )}
                                            </div>

                                            {!_.isEmpty(
                                              this.state.updatedJobAndField[
                                                indexx
                                              ].Jobs
                                            ) &&
                                              this.state.updatedJobAndField[
                                                indexx
                                              ].Jobs != "NB" && (
                                                <div className="width40  resW69person">
                                                  <h6 className="ignore-i mb-1">
                                                    Job Changes
                                                  </h6>
                                                  <div className="mini-table">
                                                    <div className="tablehead">
                                                      <div className="width15 th-mini td">
                                                        STATUS
                                                      </div>
                                                      <div class="width20 th-mini td">
                                                        ORGINZATION
                                                      </div>
                                                      <div class="width25 th-mini td">
                                                        REGION/FACILITY
                                                      </div>
                                                      <div class="width20 th-mini td">
                                                        START
                                                      </div>
                                                      <div class="width20 th-mini td">
                                                        END
                                                      </div>
                                                    </div>
                                                    <div className="tablebody">
                                                      {this.state.updatedJobAndField[
                                                        indexx
                                                      ].Jobs.map((itemJ) => (
                                                        <div className="tr-body">
                                                          <div className="width15 th-mini td bold-dark">
                                                            {itemJ.status}
                                                          </div>
                                                          <div class="width20 th-mini td ">
                                                            {
                                                              itemJ.organizationCode
                                                            }
                                                          </div>
                                                          <div class="width25 th-mini td ">
                                                            {(itemJ.regionCode !=
                                                            null
                                                              ? itemJ.regionCode
                                                              : "--") +
                                                              " / " +
                                                              (itemJ.facilityCode !=
                                                              null
                                                                ? itemJ.facilityCode
                                                                : "--")}
                                                          </div>
                                                          <div class="width20 th-mini td">
                                                            {moment(
                                                              itemJ.startDate
                                                            ).isValid()
                                                              ? moment(
                                                                  itemJ.startDate
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )
                                                              : ""}
                                                          </div>
                                                          <div class="width20 th-mini td">
                                                            {moment(
                                                              itemJ.endDate
                                                            ).isValid()
                                                              ? moment(
                                                                  itemJ.endDate
                                                                ).format(
                                                                  "MM/DD/YYYY"
                                                                )
                                                              : ""}
                                                          </div>
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        );
                                      })()}
                                  </div>
                                </>
                              );
                            })}
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-lg-2"></div>
                <div className="col-lg-6 text-center">
                  <h4 className=" text-center text-uppercase page-title-search">
                    You're unauthorized to access the payfiles
                  </h4>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          )}
        </div>
        <ImportDetailModal
          ImportData={this.state.importData}
          refreshOnIgnore={this.refreshOnIgnore}
          refreshOnUpdate={this.refreshOnUpdate}
          fileId={this.state.fileId}
          ignoreStatus={this.state.detailIgnoreStatus}
        />
        <ConfirmDeleteModal
          id={"DeleteECI"}
          content={"Employee Jobs"}
          confirm={() => {
            this.handleRemoveEmpoloyeeJobs();
          }}
          closeModal={() => {
            this.setState({ deleteModal: false });
          }}
          openModal={this.state.deleteModal}
        />
      </AUX>
    );
  }
}
export default connect((state) => ({ payfileTwo: state.payfileTwo }), {
  ...PayfileAction,
})(ImportSummary);
