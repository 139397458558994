import React, { Component, Fragment } from "react";
import _ from "lodash";
import "../../../assets/css/TeamStyle.css";
import PermissionAlgo from "../General/PermissionAlgo";
import Services from "../PermissionService";
import $ from "jquery";
import { Button } from "reactstrap";
import permission from "../../../store/permission/reducer";

class PermissionsST extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prefix: "",
      collapse: false,
      permissions: [],
      applyChild: false,
      defaultPermissions: [],
      userPermissions: [],
    };
    this.handleChangeApplyTeam = this.handleChangeApplyTeam.bind(this);
    this.handleChangeTeamPermission = this.handleChangeTeamPermission.bind(
      this
    );
    this.handleConfigureDefaultPermission = this.handleConfigureDefaultPermission.bind(
      this
    );
  }
  handleChangeApplyTeam(e) {
    var checked = e.target.checked;
    this.setState({ applyChild: checked });
    this.props.handleChangeApplyChild(checked);
  }
  componentDidMount() {
    if (this.props.prefix) {
      this.state.prefix = this.props.prefix;
    }
    if (!_.isEmpty(this.props.permissions)) {
      let permissions = PermissionAlgo.changeSpecialPermissionsToByte(
        _.cloneDeep(this.props.permissions)
      );
      this.state.permissions = permissions;

      this.state.userPermissions = _.cloneDeep(this.props.permissions);
    }

    this.setState({
      prefix: this.state.prefix,
      permissions: this.state.permissions,
      userPermissions: this.state.userPermissions,
    });
    this.handleConfigureDefaultPermission();
  }

  handleChangeTeamPermission(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    var permissions = this.state.permissions;
    this.state.permissions = PermissionAlgo.changeChildST(permissions, e);
    this.setState({
      permissions: this.state.permissions,
    });
    this.props.handleChangePermission(this.state.permissions);
  }
  async handleConfigureDefaultPermission() {
    $(".loading").show();
    let data = await Services.DefaultPermissions();

    if (!_.isEmpty(data)) {
      let defaultPermissions = PermissionAlgo.ConvertPermissionToNewScheme(
        data.result
      );
      this.state.defaultPermissions = PermissionAlgo.cloneDefaultPermission(
        defaultPermissions
      );
      await this.setState({
        defaultPermissions: this.state.defaultPermissions,
      });
    }

    $(".loading").hide();
  }
  performSlide(parentid) {
    let prefix = this.state.prefix;
    let element = $(
      "." +
        prefix +
        "slide-collapsers[data-slide-Id='" +
        prefix +
        "slide" +
        parentid +
        "']"
    );
    let expand = element.attr("data-slide-expand");

    const collapseChild = (parentid, expand) => {
      let element = $(
        "." + prefix + "slide-collapsers[data-slide-Id='" + parentid + "']"
      );

      if (expand) {
        $(
          "." +
            prefix +
            "slide-collapsers[data-slide-parentId='" +
            parentid +
            "']"
        ).addClass("perm-row-hide");
      } else {
        let expandChild = element.attr("data-slide-expand");

        if (expandChild == "true") {
          $(
            "." +
              prefix +
              "slide-collapsers[data-slide-parentId='" +
              parentid +
              "']"
          ).removeClass("perm-row-hide");
          //this.setState({collapse: false});
        }
        //in case false
        else {
          expand = true;
        }
      }
      $(
        "." +
          prefix +
          "slide-collapsers[data-slide-parentId='" +
          parentid +
          "']"
      ).each(function(i) {
        let parentid2 = $(this).attr("data-slide-Id");
        if (
          $(
            "." +
              prefix +
              "slide-collapsers[data-slide-parentId='" +
              parentid2 +
              "']"
          ).length > 0
        ) {
          collapseChild(parentid2, expand);
        }
      });
    };

    if (expand == "true") {
      $(
        "." +
          prefix +
          "slide-collapsers[data-slide-parentId='" +
          prefix +
          "slide" +
          parentid +
          "']"
      ).addClass("perm-row-hide");
      element.attr("data-slide-expand", false);
      $("#" + prefix + "slide" + parentid).addClass("fa-level-down-alt");
      $("#" + prefix + "slide" + parentid).removeClass("fa-level-up-alt");
    } else {
      $(
        "." +
          prefix +
          "slide-collapsers[data-slide-parentId='" +
          prefix +
          "slide" +
          parentid +
          "']"
      ).removeClass("perm-row-hide");
      $("#" + prefix + "slide" + parentid).removeClass("fa-level-down-alt");
      $("#" + prefix + "slide" + parentid).addClass("fa-level-up-alt");
      element.attr("data-slide-expand", true);
    }
    collapseChild(prefix + "slide" + parentid, expand == "true" ? true : false);
  }

  checkChildNode(parentid) {
    let { prefix } = this.state;
    var getChildNode = document.querySelectorAll(
      "." +
        prefix +
        "slide-collapsers[data-slide-parentid ='" +
        prefix +
        "slide" +
        parentid +
        "']"
    );
    if (getChildNode.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { listId } = this.props;
    const {
      prefix,
      permissions,
      userPermissions,
      defaultPermissions,
    } = this.state;

    return (
      <>
        <div className="mb-3 permissionsList" id={listId}>
          <div className="w-100">
            <div className="row mx-0">
              <div className="perm-col-1"></div>
              <div className="perm-col-2 text-center">VIEW</div>
              <div className="perm-col-2 text-center">ADD</div>
              <div className="perm-col-2 text-center">UPDATE</div>
              <div className="perm-col-2 text-center">DELETE</div>
            </div>

            <div>
              {permissions.map((item, idx) => {
                if (item) {
                  let rowClasses = "";
                  switch (item.featureType) {
                    case 1:
                      rowClasses = " bg-dark text-white";
                      break;
                    case 2:
                      rowClasses = " bg-perm-info text-white perm-row-hide";
                      break;
                    case 3:
                      rowClasses = " bg-primary text-white perm-row-hide";
                      break;
                    case 4:
                      rowClasses = " perm-row-hide";
                      break;
                    default:
                  }
                  let pId2 = $(this).attr("data-slide-Id");
                  let tempData = _.cloneDeep(permissions)
                    .splice(0, idx)
                    .reverse();
                  let lessIndex = tempData.findIndex(
                    (x) => x.featureType < permissions[idx].featureType
                  );
                  let currentParent =
                    lessIndex != -1 ? idx - lessIndex - 1 : -1;
                  let viewCond = userPermissions[idx].isView;
                  let addCond = userPermissions[idx].isAdd;
                  let updCond = userPermissions[idx].isUpdate;
                  let delCond = userPermissions[idx].isDelete;
                  let viewChildCheck = false;
                  let addChildCheck = false;
                  let updChildCheck = false;
                  let delChildCheck = false;
                  let lastIndex = userPermissions.length - 1;
                  if (idx != lastIndex) {
                    //creating Default permission verifier
                    let viewDFChildCheckIndex = -1;
                    let addDFChildCheckIndex = -1;
                    let updDFChildCheckIndex = -1;
                    let delDFChildCheckIndex = -1;
                    //Splicing the permissions
                    let tempData2 = _.cloneDeep(userPermissions).splice(
                      idx + 1,
                      lastIndex
                    );
                    //filtering the childrens of the current permission
                    let lessIndex2 = tempData2.findIndex(
                      (x) => x.featureType <= userPermissions[idx].featureType
                    );
                    //if there is all are child no next item with greater them then
                    if (lessIndex2 == -1) {
                      //since ther is no greater item then we will save the lover items length for splice
                      lessIndex2 = tempData2.filter(
                        (x) => x.featureType > userPermissions[idx].featureType
                      ).length;
                    }
                    //first we check we have default permission or not
                    if (!_.isEmpty(defaultPermissions)) {
                      //splice for get all children
                      let tempDFData2 = _.cloneDeep(defaultPermissions).splice(
                        idx + 1,
                        lessIndex2
                      );
                      viewDFChildCheckIndex = tempDFData2.findIndex(
                        (x) => x.isView == true
                      );
                      addDFChildCheckIndex = tempDFData2.findIndex(
                        (x) => x.isAdd == true
                      );
                      updDFChildCheckIndex = tempDFData2.findIndex(
                        (x) => x.isUpdate == true
                      );
                      delDFChildCheckIndex = tempDFData2.findIndex(
                        (x) => x.isDelete == true
                      );
                    }

                    //splice for get all children
                    tempData2 = _.cloneDeep(permissions).splice(
                      idx + 1,
                      lessIndex2
                    );
                    viewChildCheck =
                      viewDFChildCheckIndex != -1 &&
                      !_.isEmpty(tempData2) &&
                      tempData2[viewDFChildCheckIndex].isView == 0
                        ? true
                        : tempData2.findIndex((x) => x.isView == 1) != -1;
                    addChildCheck =
                      addDFChildCheckIndex != -1 &&
                      !_.isEmpty(tempData2) &&
                      tempData2[addDFChildCheckIndex].isAdd == 0
                        ? true
                        : tempData2.findIndex((x) => x.isAdd == 1) != -1;
                    updChildCheck =
                      updDFChildCheckIndex != -1 &&
                      !_.isEmpty(tempData2) &&
                      tempData2[updDFChildCheckIndex].isUpdate == 0
                        ? true
                        : tempData2.findIndex((x) => x.isUpdate == 1) != -1;
                    delChildCheck =
                      delDFChildCheckIndex != -1 &&
                      !_.isEmpty(tempData2) &&
                      tempData2[delDFChildCheckIndex].isDelete == 0
                        ? true
                        : tempData2.findIndex((x) => x.isDelete == 1) != -1;
                  }
                  let viewDFCond = false;
                  let addDFCond = false;
                  let updDFCond = false;
                  let delDFCond = false;

                  if (!_.isEmpty(defaultPermissions)) {
                    try {
                      viewDFCond = defaultPermissions[idx].isView;
                      addDFCond = defaultPermissions[idx].isAdd;
                      updDFCond = defaultPermissions[idx].isUpdate;
                      delDFCond = defaultPermissions[idx].isDelete;
                    } catch (e) {}
                  }

                  return (
                    <Fragment>
                      <div
                        className={
                          prefix +
                          "slide-collapsers perm-row row mx-0" +
                          rowClasses
                        }
                        onClick={(e) => {
                          this.performSlide(idx);
                        }}
                        data-slide-expand={false}
                        data-slide-Id={prefix + "slide" + idx}
                        data-slide-parentId={
                          currentParent != -1 &&
                          prefix + "slide" + currentParent
                        }
                        featureTypeId={permissions[idx].featureType}
                      >
                        <div className="perm-col-1 p-2">
                          {permissions[idx].featureName}
                          {permissions[idx].featureType != 4 &&
                            this.checkChildNode(idx) == true && (
                              <i
                                id={prefix + "slide" + idx}
                                class="fas fa-level-down-alt"
                                style={{ float: "right" }}
                              ></i>
                            )}
                        </div>
                        <div className="perm-col-2 text-center p-2">
                          <div>
                            <label
                              class={
                                "ST-check" +
                                (item.isView == 1
                                  ? " check-success"
                                  : item.isView == 0
                                  ? viewDFCond
                                    ? " check-default"
                                    : " check-danger"
                                  : item.isView == -1
                                  ? " check-light"
                                  : "")
                              }
                              /*this value willnot be same as item.isView value this only used to set the child values of the disabled check boxes*/
                              data-value={item.isView}
                              data-disabled={!viewCond}
                              data-child-check={viewChildCheck}
                              data-index={idx}
                              data-permission="isView"
                              data-parent={currentParent}
                              onClick={this.handleChangeTeamPermission}
                            ></label>
                          </div>
                        </div>
                        <div className="perm-col-2 text-center p-2">
                          <div>
                            <label
                              class={
                                "ST-check" +
                                (item.isAdd == 1
                                  ? " check-success"
                                  : item.isAdd == 0
                                  ? addDFCond
                                    ? " check-default"
                                    : " check-danger"
                                  : item.isAdd == -1
                                  ? " check-light"
                                  : "")
                              }
                              /*this value willnot be same as item.isView value this only used to set the child values of the disabled check boxes*/
                              data-value={item.isAdd}
                              data-disabled={!addCond}
                              data-child-check={addChildCheck}
                              data-index={idx}
                              data-permission="isAdd"
                              data-parent={currentParent}
                              onClick={this.handleChangeTeamPermission}
                            ></label>
                          </div>
                        </div>

                        <div className="perm-col-2 text-center p-2">
                          <div>
                            <label
                              class={
                                "ST-check" +
                                (item.isUpdate == 1
                                  ? " check-success"
                                  : item.isUpdate == 0
                                  ? updDFCond
                                    ? " check-default"
                                    : " check-danger"
                                  : item.isUpdate == -1
                                  ? " check-light"
                                  : "")
                              }
                              /*this value willnot be same as item.isView value this only used to set the child values of the disabled check boxes*/
                              data-value={item.isUpdate}
                              data-disabled={!updCond}
                              data-child-check={updChildCheck}
                              data-index={idx}
                              data-permission="isUpdate"
                              data-parent={currentParent}
                              onClick={this.handleChangeTeamPermission}
                            ></label>
                          </div>
                        </div>

                        <div className="perm-col-2 text-center p-2">
                          <div>
                            <label
                              class={
                                "ST-check" +
                                (item.isDelete == 1
                                  ? " check-success"
                                  : item.isDelete == 0
                                  ? delDFCond
                                    ? " check-default"
                                    : " check-danger"
                                  : item.isDelete == -1
                                  ? " check-light"
                                  : "")
                              }
                              /*this value willnot be same as item.isView value this only used to set the child values of the disabled check boxes*/
                              data-value={item.isDelete}
                              data-disabled={!delCond}
                              data-child-check={delChildCheck}
                              data-index={idx}
                              data-permission="isDelete"
                              data-parent={currentParent}
                              onClick={this.handleChangeTeamPermission}
                            ></label>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="col-12 pl-0">
          {!_.isEmpty(this.state.userPermissions) && (
            <div className="w-100 position-relative pl-4">
              <input
                type="checkbox"
                style={{ bottom: "5px", left: "27px" }}
                checked={this.state.applyChild}
                title="It will change the 'Turn ON' permissions of the child teams and their positions."
                onChange={this.handleChangeApplyTeam}
                className="form-check-input"
                id="ApplyChild"
              />
              <label
                className="form-check-label font-italic text-secondary"
                for="ApplyChild"
              >
                Apply to Child Teams
              </label>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default PermissionsST;
