import React, { Component } from "react";
import { Row, Col, Form } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
// import AuthService from "../../../modules/Auth/AuthService";
import DatePicker from "react-datepicker";
import moment from "moment";
import _ from "lodash";

export class ActiveDatePosition extends Component {
  constructor(props) {
    super(props);
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  renderInputDate = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <label>{label} </label>
        ) : (
          <label> </label>
        )}
        <DatePicker
          {...input}
          dateForm="MM/DD/YYYY"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          placeholderText={this.props.isMobile === true ? label : ""}
          onChangeRaw={this.handleDateChangeRaw}
          className={classForm}
          selected={input.value ? moment(input.value).toDate() : null}
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };
  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Active Dates</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}

        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-sm-1 mt-md-2 pl-2 pr-1">
              <Field
                name="fromDate"
                id="fromDate"
                classForm="form-control"
                placeholder="From Date"
                label="From Date"
                component={this.renderInputDate}
                labelFor="fromDate"
              />
            </Col>
            <Col className="m-sm-1 m-md-2 pr-2 pl-1">
              <Field
                name="toDate"
                id="toDate"
                classForm="form-control"
                placeholder="To Date"
                label="To Date"
                component={this.renderInputDate}
                labelFor="toDate"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "activeDatePositionForm",
  destroyOnUnmount: false,
})(ActiveDatePosition);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
