import React, { Component } from "react";
import InputMask from "react-input-mask";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
export class Phone extends Component {
  Checkbox = ({ input, meta: { touched, error }, data }) => (
    <div className="ml-3 w-100 m-2 custom-checkbox">
      <input
        {...input}
        type="checkbox"
        id={input.name}
        htmlFor={input.name}
        checked={input.value == true}
        className="custom-control-input pr-2"
      />
      <label
        className="h6 custom-control-label"
        htmlFor={input.name}
        //    style={{ paddingTop: 2, zIndex: 0 }}
      >
        {data.hasPrimaryPhone}
        {this.props.filterState.filterData && (
          <small className="totalCount">({data.total})</small>
        )}
      </label>
    </div>
  );
  renderInput = ({ input, label, type, meta, placeholder }) => {
    return (
      /*<div className="form-group">
                      {label !== "" && this.props.isMobile !== true ? (
                          <label>{label} </label>
                      ) : (
                              <label> </label>
                          )}
                      <input
                          {...input}
                          className={classForm}
                          type={type}
                          autoComplete="off"
                          placeholder={this.props.isMobile == true ? placeholder : ""}
                          style={
                              meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
                          }
                      />
                      <div>{this.renderError(meta)}</div>
                  </div>*/
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <label>{label} </label>
        ) : (
          <label> </label>
        )}
        <InputMask
          {...input}
          //                  value={this.state.number}
          //                    onChange={this.handleChangePhone}

          autoComplete="off"
          mask="(999) 999-9999"
          className="form-control"
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
          type={type}
          placeholder={this.props.isMobile == true ? placeholder : ""}
          //pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          //type="tel"
          //required="Phone No. is required"
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  onSubmit = (formValues) => {
    console.log(formValues);
  };
  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }
  handleChangePhone() {
    //this.setState({ number: e.target.value });
    //let PhoneReg = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
    //if (e.target.value === "(___) ___-____" || e.target.value === "") {
    //} else if (PhoneReg.test(e.target.value)) {
    //    this.setState({ phone_err: "" });
    //    this.setState({ isValid: true });
    //} else {
    //    this.setState({ phone_err: "Enter valid phone" });
    //    this.setState({ isValid: false });
    //}
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    let nullPrimaryPhone =
      this.props.filterState.filterData &&
      this.props.filterState.filterData.nullPrimaryPhone
        ? this.props.filterState.filterData.nullPrimaryPhone
        : null;
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Phone Number</h4>
            <hr />
            <br />
          </>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2">
              <Field
                name="Phone"
                validate={this.handleChangePhone}
                id="Phone"
                placeholder="Phone"
                label="Phone"
                component={this.renderInput}
                labelFor="Phone"
              />
            </Col>
            <div className="clearfix" />
            <Col xs="12">
              <div className="">
                <ul class="chechbox-list">
                  <li
                    className="subheading-chks"
                    style={{ color: this.props.isMobile && "white" }}
                  >
                    Has Primary Phone
                  </li>

                  {this.props.filterState.filterData &&
                    nullPrimaryPhone &&
                    nullPrimaryPhone.map((item, index) => (
                      <Field
                        name={"NullPrimaryPhone" + item.hasPrimaryPhone}
                        component={this.Checkbox}
                        data={item}
                      />
                    ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "phoneForm",
  destroyOnUnmount: false,
})(Phone);

const mapStateToProps = (state) => ({
  errorMessage: {},
  filterState: state.advancedFilter,
});

export default connect(mapStateToProps, {})(formWrapped);
