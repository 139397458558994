import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Collapse, Card } from "reactstrap";

import AuthService from "../../Auth/AuthService";
import PermissionServices from "../../../modules/Permissions/PermissionService";
import AreasServices from "./AreasService";
import areasStateActions from "../../../store/areas/action";
import ConfigDataType from "../../../modules/Email/Enums/ConfigDataType";
import AUX from "../../../hoc/Aux_";

class AssignMembersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTermMember: "",
      MemberObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: true,
        FetchedFirst: true,
      },
      memberId: AuthService.getProfile().memberData.id,
    };

    this.searchMember = this.searchMember.bind(this);
    this.searchDebounceMember = debounce(500, this.searchMember);
  }

  handleClose = () => {
    this.setState({
      searchTermMember: "",
      MemberObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: true,
        FetchedFirst: true,
      },
      memberId: AuthService.getProfile().memberData.id,
    });
    this.props.deSelectAllMember();
    this.props.closeModal();
  };
  searchMember = () => {
    let MemberDataObj = this.state.MemberObj;
    MemberDataObj.Offset = 0;
    MemberDataObj.Fetch = 10;
    MemberDataObj.SearchTerms = this.state.searchTermMember;
    MemberDataObj.IsMore = true;
    MemberDataObj.FetchedFirst = true;
    this.setState({ MemberObj: MemberDataObj });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleSelectMember = (e) => {
    let index = e.target.dataset.index;
    index = !index ? e.target.parentElement.dataset.index : index;
    let memberId = this.state.MemberObj.Data[index].id;
    let selectedObj;

    selectedObj = this.props.areastate.selectedMembers;

    let isAlreadySelected = selectedObj.some((s) => s == memberId);
    if (isAlreadySelected) {
      this.props.deSelectMember(memberId);
      return;
    }
    let id = 0;
    this.props.selectMember(memberId);
  };

  assignMembers = () => {
    let selectedMembers = this.props.areastate.selectedMembers;
    let areaId = this.props.areaId;
    console.log(selectedMembers);
    console.log(areaId);
    if (selectedMembers != null && areaId != null) {
      var formdata = {
        areaId: areaId,
        memberIds: selectedMembers,
      };

      this.assignMembersCall(formdata);

      this.handleClose();
    } else {
      toast.error("Please select members to assign.");
    }
  };

  async assignMembersCall(formdata) {
    let response = await AreasServices.AssignMembers(formdata);
    if (!_.isEmpty(response)) {
      // Process the response
    }
  }

  showActiveMemberItem = (item) => {
    let currentObj;

    if (this.props.areastate) {
      currentObj = this.props.areastate.selectedMembers;
    }
    var result =
      !_.isEmpty(currentObj) &&
      currentObj.findIndex(
        (x) => x == item.id // just on the client side
      ) != -1;
    return result;
  };

  loadMember = () => {
    if (this.state.MemberObj.FetchedFirst) {
      this.state.MemberObj.FetchedFirst = false;
      this.loadMemberData();
    }
    let MemberData = this.state.MemberObj.Data;
    //MemberData = MemberData.filter((item) => item.areaID != this.props.areaId);

    return (
      <Fragment>
        <p className="text-black-50">
          <b>Hint:</b> <i>Name [Email] Region/Facility</i>
        </p>
        <div id="MemberlistScroll">
          {MemberData ? (
            <InfiniteScroll
              dataLength={MemberData.length}
              next={this.loadMemberData}
              hasMore={this.state.MemberObj.IsMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget="MemberlistScroll"
            >
              {MemberData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "c-pointer px-4 border py-2 card-text w-100" +
                      (this.showActiveMemberItem(item)
                        ? " active" // selected
                        : "")
                    }
                    onClick={this.handleSelectMember}
                    data-index={index}
                  >
                    {`${item.lastName ? item.lastName + ", " : ""}${item.firstName
                      } ${item.nickName != null ? '"' + item.nickName + '" ' : ""
                      }`}{" "}
                    {item.email && <b>[{item.email}]</b>}
                    {" - "}
                    {item.region + "/" + item.facility}
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : (<></>)}
        </div>
      </Fragment>
    );
  };

  loadMemberData = async () => {
    let MemberObj = this.state.MemberObj;
    let data;
    data = await PermissionServices.LoadMembersByScope(
      MemberObj.SearchTerms,
      MemberObj.Offset,
      MemberObj.Fetch,
      this.props.facilityId ? this.props.facilityId : 0
    );
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        // console.log(data.result.length, "result length:");
        // console.log(this.state.MemberObj.Data.length + 10, "data length");
        if (!_.isEmpty(data.result) && data.result.length > 0) {
          this.state.MemberObj.IsMore =
            data.result.length == this.state.MemberObj.Fetch;
          this.state.MemberObj.Data = data.result;

          this.state.MemberObj.Fetch =
            data.result.length == this.state.MemberObj.Fetch
              ? MemberObj.Fetch + 10
              : MemberObj.Fetch;

          await this.setState({ MemberObj: this.state.MemberObj });
        } else {
          var mainObj = { ...this.state.MemberObj };
          mainObj.IsMore = false;
          mainObj.Data = data.result;
          mainObj.Fetch = 10;
          await this.setState({ MemberObj: mainObj });
        }
      }
    }
  };

  assignMember = () => {
    return (
      <Fragment>
        <Card className="mb-1">
          <h6
            className="card-header bg-primary text-white mb-3 mt-0 cursorPointer"
            onClick={() => { }}
          >
            <i class="far fa-user pr-1"></i>
            Members{" "}
          </h6>
          <Collapse isOpen={true}>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 text-center">
                <form role="search" className="app-search mb-3">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control rounded border mb-1 py-2 w-100"
                      placeholder="Member Search.."
                      onChange={(e) => {
                        this.setState({ searchTermMember: e.target.value });
                        this.searchDebounceMember();
                      }}
                    />
                    <button
                      type="submit"
                      className="c-pointer text-secondary bg-transparent border-0"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div id="memberList" className="col-lg-10">
                {this.loadMember()}
              </div>
            </div>
          </Collapse>
        </Card>
      </Fragment>
    );
  };

  buttons = () => {
    let btnText = "Assign to Area";
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-sm-10 offset-sm-2 col-12">
            <div className="text-right">
              <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                onClick={this.handleClose}
              >
                Cancel
              </button>
              <button
                onClick={this.assignMembers}
                className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                type="submit"
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  onShow = () => { };

  render() {
    let modalHeader = "Select Members";
    return (
      <AUX>
        <Modal
          id="AssignMembersToArea"
          show={this.props.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
          backdrop="static"
          dialogClassName="modal-45w"
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                ASSIGN MEMBERS{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="AuthSenderModal modalshadow pl-5 pr-5">
            <div className="col-12">
              <h4 className="text-primary font-weight-bold changeColor">
                {modalHeader}
              </h4>
            </div>
            {this.assignMember()}

            {this.buttons()}
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

// export default AssignMembersModal;

export default connect(
  (state) => ({
    areastate: state.areas,
  }),
  {
    ...areasStateActions,
  }
)(AssignMembersModal);
