import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import registrationsAction from "../../../../../store/registrations/action";
import { RegisWizardSteps } from "../../../Helpers/LookUpValues";
import RegistrationQuestions from "../RegistrationQuestions";

class StepTwo extends Component {
  render() {
    let idx = this.props.steps.findIndex(
      (i) => i.key == RegisWizardSteps.SecondStep
    );
    const { currentStep } = this.props;
    if (currentStep !== idx) {
      // Prop: The current step
      return null;
    }
    return (
      <RegistrationQuestions
        erros={this.props.errors}
        setValues={this.props.setValues}
        values={this.props.values}
        questions={this.props.registrationsState.selectedOpenRegQuestions}
        isDisabled={this.props.isDisabled}
      />
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(StepTwo)
);
