import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Services from "../../Permissions/PermissionService";
import { debounce } from "throttle-debounce";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import FEServices from "../F&ERequests/FEService";
import DeleteModal from "../../Dashboard/FacilityDashboard/Modals/DeleteModal";

const initialState = {
  searchTermMembers: "",
  name: "",
  approverMember: null,
  id: 0,
  issubmit: false,
  approverName: "",
};
export default class AddBudgetApprover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      usersOptions: [],
    };

    this.autocompleteSearchDebouncedUsers = debounce(
      500,
      this.autocompleteSearchUsers
    );

    this.onSubmit = this.onSubmit.bind(this);
  }

  autocompleteSearchUsers = () => {
    this._fetchUsersMembers();
  };
  onShow = async () => {
    if (_.isEmpty(this.props.editBudgetline)) {
      // Add Mode
      await this.loadUsersData("");
    } else {
      // Edit Mode
      var userNameList = this.props.editBudgetline.approverName.split(" ");
      let userFLName = "";
      if (userNameList && userNameList.length == 1) {
        // incase there is no last name of the member, then send first name
        userFLName = `${userNameList[0].charAt(0)}${userNameList[0].charAt(1)}`;
      } else if (userNameList && userNameList.length > 1) {
        /// else there are(first and last name) so send "Ja Do" to the Api.
        userFLName = `${userNameList[0].charAt(0)}${userNameList[0].charAt(
          1
        )} ${userNameList[1].charAt(0)}${userNameList[1].charAt(1)}`;
      }
      ///Prefill the options list with selected Value
      await this.loadUsersData(userFLName);
      this.setState({ id: this.props.editBudgetline.id });
      this.setState({ name: this.props.editBudgetline.name });

      await this.setState({
        approverMember:
          this.state.usersOptions.find(
            (el) => el.id == this.props.editBudgetline.approverMember
          ) || null,
      });
    }
  };
  handleClose = () => {
    this.props.onClose();
    this.setState(initialState);
    $("#addBudgetlineForm").trigger("reset");
  };
  cancelModal = () => {
    this.props.toggleModal();
    this.setState(initialState);
    $("#addBudgetlineForm").trigger("reset");
  };

  loadUsersData = async (SearchTerms) => {
    $(".loading").show();
    let data = await Services.LoadMembers(SearchTerms, 0, 100);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.usersOptions = data.result != null ? data.result : [];
        await this.setState({
          usersOptions: this.state.usersOptions,
        });
      }
    }
    $(".loading").hide();
  };
  _fetchUsersMembers = async () => {
    await this.loadUsersData(this.state.searchTermMembers);
  };
  usersSearchHandler = (SearchTerms) => {
    this.setState({ searchTermMembers: SearchTerms }, () => {
      this.autocompleteSearchDebouncedUsers();
    });
  };
  changeHanlderServerSiderDropDown = async (e, value, reason) => {
    if (reason !== "clear") {
      this.setState({ approverMember: value });
    } else {
      this.setState({ approverMember: null });
      await this.loadUsersData("");
    }
  };
  onSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ issubmit: true });
    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      return false;
    }
    evt.preventDefault();
    const formdata = {
      id: this.state.id,
      name: this.state.name,
      approverMember: _.isEmpty(this.state.approverMember)
        ? null
        : this.state.approverMember.id,
    };
    this.AddUpdateBudgetLine(formdata);
  };

  AddUpdateBudgetLine = async (formdata) => {
    FEServices.showSpinner();
    let response = await FEServices.AddUpdateBudgetLine(formdata);
    if (!_.isEmpty(response)) {
      toast.success("Budget Line saved successfully");
    }
    FEServices.hideSpinner();
    this.handleClose();
    await this.props.refreshBudgetLines();
  };
  handleChange = (evt) => {
    this.setState({
      [evt.target.name]:
        [evt.target.name] == "name"
          ? evt.target.value
          : evt.target.value.trim(),
    });
  };
  render() {
    return (
      <>
        <Modal
          className="modal fade team-style"
          id="addRequestModal"
          size="lg"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title className=" w-100 modal-title font-italic">
              <div className="feModalHeader align-items-center text-uppercase d-flex">
                <div className="col-sm-6 mt-2">
                  {this.state.id == 0 ? (
                    <h5 className="text-white mt-0">Add New Budget Line</h5>
                  ) : (
                    ""
                  )}
                  {this.state.id > 0 ? (
                    <h5 className="text-white mt-0">Edit Budget Line</h5>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <form
            className="needs-validation"
            id="addBudgetlineForm"
            onSubmit={this.onSubmit}
            noValidate
          >
            <Modal.Body>
              <>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 pl-3 pl-md-3">
                        <div className="form-group">
                          <MDBInput
                            value={this.state.name}
                            name="name"
                            id="equipment-BudgelineName"
                            onChange={this.handleChange}
                            label="Budget Line"
                            group
                            type="text"
                            //disabled={this.canEdit("name")}
                            required
                          >
                            <div className="invalid-feedback">
                              Name is required.
                            </div>
                          </MDBInput>
                        </div>
                      </div>

                      <div className="col-md-6 mt-3 pl-1 pl-md-3">
                        <div className="col-lg-12">
                          <Autocomplete
                            id="equipment-approverMember"
                            options={this.state.usersOptions}
                            filterOptions={(options) => options}
                            autoHighlight={true}
                            getOptionLabel={(option) => {
                              if (!_.isEmpty(option)) {
                                return `${option.name} - ${option.memberNumber} ${option.region}/${option.facility}`;
                              } else {
                                return "";
                              }
                            }}
                            ChipProps={{
                              classes: { root: "py-2" },
                            }}
                            onInputChange={(e, value, reason) => {
                              if (reason == "input") {
                                this.usersSearchHandler(value);
                              }
                            }}
                            // disabled={
                            //   !AuthService.canUpdate(
                            //     PermissionFeature.FEAccountingUser
                            //   ) || shouldDisableControl
                            // }
                            value={this.state.approverMember}
                            onChange={async (e, value, reason) => {
                              await this.changeHanlderServerSiderDropDown(
                                e,
                                value,
                                reason
                              );
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    className:
                                      "pb-1" +
                                      (params.inputProps &&
                                        " " + params.inputProps.className),
                                  }}
                                  variant="standard"
                                  label="Approved Member"
                                  value={this.state.approverMember}
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: { shrink: "mt-n1" },
                                  }}
                                  error={
                                    this.state.issubmit &&
                                    _.isEmpty(this.state.approverMember)
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    this.state.issubmit &&
                                    _.isEmpty(this.state.approverMember)
                                      ? "Required"
                                      : ""
                                  }
                                  required={true}
                                  placeholder="Select User"
                                />
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </Modal.Body>
            <div className="actionBtns form-group mb-4 row">
              <div className="col-12 m-t-20 mx-auto text-center">
                {
                  <>
                    <button
                      className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                      data-dismiss="modal"
                      aria-hidden="true"
                      type="reset"
                      onClick={this.cancelModal}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn btn-success w-md mr-2 mc"
                      // disabled={allDisabled}
                      type="submit"
                      //onClick={this.onSubmit}
                    >
                      Save
                    </button>
                  </>
                }
              </div>
            </div>
          </form>
        </Modal>
        <DeleteModal
          //confirm={this.props.confirm}
          modal={this.props.isConfirmModalOpen}
          toggle={this.props.toggleConfirmModal}
          confirm={this.props.confirmDeleteAction}
        />
      </>
    );
  }
}
