import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { connect } from "react-redux";
import PACTrackerAction from "../../../store/pacTracker/action";

import "../../../assets/css/PermissionHeader.css";
//import Regions from "./Region/Region";
//import Users from "../Admin/Users/Users";
//import Facilities from "./Facility/Facilities";

class PACHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PACActiveTab: -1,
      page: "",
      subPage: "",
      secondSubPage: "",
    };
    this.setPACActiveTab = this.setPACActiveTab.bind(this);
  }
  componentDidMount() {
    let addresses = window.location.pathname.split("/");
    let page = addresses.length > 1 ? addresses[1] : "";
    let subPage = addresses.length > 2 ? addresses[2] : "";
    let secondSubPage = addresses.length > 3 ? addresses[3] : "";
    this.setState({
      page: page,
      subPage: subPage,
      secondSubPage: secondSubPage,
    });
    if (page == "PACTracker" && !subPage) {
      this.setState({ PACActiveTab: 1 });
    } else if (page == "PACTracker" && subPage == "reviewRequest") {
      this.setState({ PACActiveTab: 2 });
    }
  }
  componentDidUpdate() {}
  componentWillReceiveProps(nextProps) {}
  setPACActiveTab(value) {
    this.setState({ PACActiveTab: value });
  }
  render() {
    let { page, subPage } = this.state;
    return (
      <nav className="navbar navbar-expand-sm permission-nav">
        <Fragment>
          {AuthService.canView(PermissionFeature.Requests) && (
            <a
              className={
                "p-tabs c-pointer" +
                (this.state.PACActiveTab === 1 ? " active" : "")
              }
              data-tab="MyRequests"
              onClick={() => {
                this.props.history.push("/PACTracker");
                this.setPACActiveTab(1);
              }}
            >
              <i class="fas fa-hand-holding-usd"></i> My Requests
            </a>
          )}

          {(AuthService.canView(PermissionFeature.ReviewRequests) ||
            AuthService.canView(PermissionFeature.PABReview)) && (
            <a
              className={
                "p-tabs c-pointer" +
                (this.state.PACActiveTab === 2 ? " active" : "")
              }
              data-tab="Region"
              onClick={() => {
                this.props.history.push("/PACTracker/reviewRequest");
                this.setPACActiveTab(2);
              }}
            >
              <i class="fas fa-history"></i> Review Requests
            </a>
          )}
        </Fragment>
        <UncontrolledDropdown className="dropdown-menu-toggle directory-navbar-brand">
          <DropdownToggle tag="a" className="nav-link nav-link-font-size">
            <i className="fas fa-cog"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-body">
            {page == "PACTracker" &&
              subPage != "candidate" &&
              AuthService.canView(PermissionFeature.Candidates) && (
                <DropdownItem
                  className="dropdown-item-custom"
                  onClick={() => {
                    this.props.history.push("/PACTracker/candidate");
                  }}
                >
                  {" "}
                  <i className="fas pr-2 fa-users"></i> Candidate
                </DropdownItem>
              )}
            {page == "PACTracker" &&
              subPage == "candidate" &&
              AuthService.canAdd(PermissionFeature.Candidates) && (
                <DropdownItem
                  className="dropdown-item-custom"
                  onClick={() => {
                    this.props.openAddCandidateModal();
                  }}
                >
                  {" "}
                  <i className="fas pr-2 fa-plus-circle"></i> Create New
                  Candidate{" "}
                </DropdownItem>
              )}
            {/* {page == "PACTracker" &&
              subPage == "" &&
              AuthService.canAdd(PermissionFeature.Requests) && (
                <DropdownItem
                  className="dropdown-item-custom"
                  onClick={() => {
                    this.props.openRequestModal({ requestType: "New" });
                  }}
                >
                  {" "}
                  <i className="fas pr-2 fa-plus-circle"></i> Create New Request{" "}
                </DropdownItem>
              )} */}
          </DropdownMenu>
        </UncontrolledDropdown>
        {/* {page == "PACTracker" &&
          subPage != "candidate" &&
          AuthService.canView(PermissionFeature.Candidates) && (
            <button
              className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
              onClick={() => {
                this.props.history.push("/PACTracker/candidate");
              }}
              type="submit"
            >
              Candidate
            </button>
          )}
        {page == "PACTracker" &&
          subPage == "candidate" &&
          AuthService.canAdd(PermissionFeature.Candidates) && (
            <button
              className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
              onClick={() => {
                this.props.openAddCandidateModal();
              }}
              type="submit"
            >
              Create New Candidate
            </button>
          )} */}
        {page == "PACTracker" &&
          subPage == "" &&
          AuthService.canAdd(PermissionFeature.Requests) && (
            <button
              className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
              onClick={() => {
                this.props.openRequestModal({ requestType: "New" });
              }}
              type="submit"
            >
              Create New Request
            </button>
          )}
      </nav>
    );
  }
}
export default withRouter(
  connect((state) => ({ pacState: state.pacTracker }), {
    ...PACTrackerAction,
  })(PACHeader)
);
