import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { connect } from "react-redux";
import * as actionTypes from "../../store/action";
import { Link } from "react-router-dom";
import axios from "../Shared/auth-header";
import $ from "jquery";

class EmailConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVerified: false,
            msg: "Please Verify Your Email Address."
        };
    }

    componentWillMount() {
        if (this.props.loginpage === false) {
            this.props.UpdateLogin();
        }
    }
    componentDidMount() {
        $(".loading").show();
        if (this.props.loginpage) {
            const urlParams = new URLSearchParams(window.location.search);
            const keydata = urlParams.get("key");

            if (keydata && keydata != null) {
                const data = { verificationcode: keydata };
                axios
                    .post("/api/Account/ConfirmEmail", data)
                    .then(({ data }) => {
                        if (data.status.toLowerCase() == "success") {
                            this.setState({ isVerified: true });
                            $(".loading").hide();
                        } else {
                            this.setState({ isVerified: false });
                            this.setState({ msg: data.message });
                        }
                        $(".loading").hide();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    }
    render() {
        let message;
        if (this.state.isVerified)
            message = (
                <h4 className="text-center m-t-20">
                    Thanks you for verification
                    <br />
                    You may login now.
                </h4>
            );
        else message = <h4 className="text-center m-t-20">{this.state.msg}</h4>;
        return (
            <div className="wrapper-page">
                <div className="card overflow-hidden account-card mx-3">
                    <div className="bg-primary ptb-login text-white text-center position-relative">
                        <h4 className="font-20 m-b-5">Email Verification!</h4>
                        <p className="text-white-50 mb-4">Account setup</p>
                        <span className="logo logo-admin">
                            <img src="assets/images/natca-logo.png" height="142" alt="logo" />
                        </span>
                    </div>
                    <div className="account-card-content m-t-40">{message}</div>
                    <div className="col-12 mb-4 text-center">
                        <Link to="login" className="font-500 text-primary text-right">
                            {" "}
                            Login{" "}
                        </Link>{" "}
                        <br />
                        <Link to="register" className="font-500 text-primary text-right">
                            {" "}
                            Register{" "}
                        </Link>{" "}
                    </div>
                </div>

                <div className="m-t-40 text-center">
                    <p>© {new Date().getFullYear()} NATCA</p>
                </div>
            </div>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage
    };
};

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
        UpdateLoginAgain: () => dispatch({ type: actionTypes.LOGINPAGE, value: false })
    };
};

export default connect(
    mapStatetoProps,
    mapDispatchtoProps
)(EmailConfirmation);
