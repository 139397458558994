import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { TextField } from "@material-ui/core";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
let classes = makeStyles({
  option: {
    fontSize: 10,
    "& > span": {
      marginRight: 10,
      fontSize: 10,
    },
    backgroundColor: "white",
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "15px",
    },
  },
});

export default function GrievanceSearchModal(props) {
  const { modal, toggle } = props;

  return (
    <Modal
      size="xl"
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader>Grievance Filter</ModalHeader>
      <ModalBody>
        <div className="col-md-12 float-left">
          <div className="col-md-4 float-left">
            <select
              className="browser-default custom-select"
              id="status"
              style={{
                color: "#B7B7B7",
                border: "1px solid #C4C4C4",
                height: "41px",
                fontSize: "15px",
                marginTop: "0px",
              }}
              onChange={(e) => {
                e.preventDefault();
                props.filterMyRequest(e.target.value, "status", null);
              }}
            >
              <option value="0">Filter Status</option>
              {props.statuses.map((item, i) => {
                return <option value={item.status}>{item.status}</option>;
              })}
            </select>
          </div>
          <div className="col-md-4 float-left">
            <Autocomplete
              id="region"
              size="small"
              value={props.regionValue}
              options={props.regions}
              style={{ fontSize: "15px" }}
              getOptionLabel={(option) => option.region || ""}
              onChange={(e, value, reason) => {
                if (value) {
                  e.preventDefault();
                  props.filterMyRequest(value.region, "region", value);
                } else {
                  props.handleClearSearch();
                }
              }}
              renderInput={(params) => (
                <TextField
                  classes={{
                    root: classes.customTextField,
                  }}
                  {...params}
                  placeholder="Filter Region"
                  style={{ backgroundColor: "white" }}
                  variant="outlined"
                />
              )}
            />
          </div>
          <div className="col-md-4 float-left">
            <Autocomplete
              id="facility"
              size="small"
              value={props.facilityValue}
              options={props.facilities}
              style={{ fontSize: "15px" }}
              getOptionLabel={(option) => option.facility || ""}
              onChange={(e, value, reason) => {
                if (value) {
                  e.preventDefault();
                  props.filterMyRequest(value.facility, "facility", value);
                } else {
                  props.handleClearSearch();
                }
              }}
              renderInput={(params) => (
                <TextField
                  classes={{
                    root: classes.customTextField,
                  }}
                  {...params}
                  placeholder="Filter Facility"
                  style={{ backgroundColor: "white" }}
                  variant="outlined"
                />
              )}
            />
          </div>
        </div>
        <div className="col-12 pt-2 float-left">
          <div className="col-md-4 float-left">
            <select
              className="browser-default custom-select"
              id="article"
              style={{
                color: "#B7B7B7",
                border: "1px solid #C4C4C4",
                height: "41px",
                fontSize: "15px",
              }}
              //value={_.toLower(props.searchTermArticle)}
              onChange={(e) => {
                e.preventDefault();
                props.filterMyRequest(e.target.value, "article", null);
              }}
              onBlur={(e) => {
                e.target.value = "";
              }}
            >
              <option value="">Article List</option>
              {props.articlesList &&
                !_.isEmpty(props.articlesList) &&
                props.articlesList.map((item, i) => {
                  return (
                    <option value={_.toLower(item.articleName)}>
                      {item.articleName}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div
          className="col-md-12 float-left"
          style={{ marginTop: "35px", marginLeft: "15px" }}
        >
          <Label>
            {" "}
            <b>Total Records :</b> {props.grievanceCount}{" "}
          </Label>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.handleClearSearch}>
          Clear Filters
        </Button>

        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
