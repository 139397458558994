import React, { Component } from "react";
import InputRange from "react-input-range";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
import _ from "lodash";
export class PAC extends Component {
  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <div class="mb-3">
            <label>Min </label>
            <label style={{ float: "right" }}>Max</label>
          </div>
        ) : (
          <label> </label>
        )}
        <div class="range-slider">
          <InputRange
            formatLabel={(value) => `$${value}`}
            maxValue={this.props.filterData.pacMax}
            minValue={0}
            value={input.value}
            onChange={input.onChange}
          />
        </div>
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>PAC</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2 ad-range-box">
              <Field
                name="PAC"
                id="PAC"
                classForm="form-control"
                component={this.renderInput}
                labelFor="PAC"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

// const formWrapped = reduxForm({
//   form: "pacForm",
//   destroyOnUnmount: false,
//   initialValues: {
//     PAC: { min: 0, max: 300 }
//   }
// })(PAC);

// const mapStateToProps = state => ({
//   errorMessage: {}
// });

// export default connect(mapStateToProps, {})(formWrapped);

const formWrapped = reduxForm({
  form: "pacForm",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(PAC);

const mapStateToProps = (state, props) => ({
  errorMessage: {},
  initialValues: {
    PAC: {
      min: 0,
      max: props.filterData.pacMax,
    },
  },
});

export default connect(mapStateToProps, {})(formWrapped);
