import React, { Component, Fragment } from "react";
import InputMask from "react-input-mask";
import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";

class PhonePopup extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      phones,
      handleChangePhone,
      handleChangePPhone,
      handleRemovePhone,
      handleFormSubmitPhone,
      handleResetProfilePhones,
      handleAddPhone,
      handleSelectChangePhoneType,
      phoneTypes,
    } = this.props;
    return (
      <div
        class="modal fade latest"
        id="EditPhonesModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="EditPhonesModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header bg-secondary py-2">
              <h5 class="modal-title text-white mt-0" id="EditPhonesModalTitle">
                Edit Phones
              </h5>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                onClick={handleResetProfilePhones}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {phones.map((item, idx) => {
                return (
                  <div key={"phoneedit-" + idx} data-repeater-list="group-a">
                    <div data-repeater-item className="row mb-4">
                      <div className="col-11 row">
                        <div className="col-sm-8">
                          <div data-id={idx}>
                            <div class="md-form form-group mb-0">
                              <InputMask
                                value={item.number}
                                onChange={handleChangePhone}
                                name="number"
                                disabled={
                                  !AuthService.canOwnUpdate(
                                    PermissionFeature.ContactPhones,
                                    this.props.memberid
                                  ) &&
                                  !AuthService.canMemberUpdate(
                                    PermissionFeature.ContactPhones
                                  )
                                }
                                mask="(999) 999-9999"
                                data-obj="phones"
                                className="form-control"
                                type="text"
                                id={"phone" + idx}
                                placeholder=""
                                required
                              />
                              <span class="text-danger">{item.number_err}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div data-id={idx} className="align-self-center">
                            <label>Phone Type</label>
                            <select
                              className="browser-default custom-select"
                              onChange={handleSelectChangePhoneType}
                              name="phonetypeid"
                              //data-id={idx}
                              data-obj="phones"
                              value={item.phonetypeid}
                              disabled={
                                !AuthService.canOwnUpdate(
                                  PermissionFeature.ContactPhones,
                                  this.props.memberid
                                ) &&
                                !AuthService.canMemberUpdate(
                                  PermissionFeature.ContactPhones
                                )
                              }
                            >
                              {phoneTypes.map((item1, idx) => (
                                <option
                                  key={idx}
                                  value={item1.id}
                                  selected={item1.id == item.phonetypeid}
                                >
                                  {item1.phonetype}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-sm-4 pt-3">
                          <div data-id={idx} className="pt-sm-4">
                            <label>Primary?</label>
                            <input
                              type="checkbox"
                              id={`PhonePrimary-${idx}`}
                              name="isprimary"
                              data-obj="phones"
                              data-name="Phone"
                              disabled={!AuthService.canUpdate(
                                PermissionFeature.ContactPhones
                              )}
                              switch="bool"
                              checked={item.isprimary}
                              onChange={handleChangePPhone}
                            />
                            <label
                              className="ml-4 mb-n2"
                              htmlFor={`PhonePrimary-${idx}`}
                              data-on-label="Yes"
                              data-off-label="No"
                            />
                            <br />
                            <span class="d-block text-danger">
                              {item.isprimary_err}
                            </span>
                          </div>
                              </div>*/}
                      </div>
                      {/* <div className="col-1 pt-sm-4 align-self-center">
                        {AuthService.canDelete(
                          PermissionFeature.ContactPhones
                        ) && (
                          <button
                            type="button"
                            data-id={idx}
                            onClick={handleRemovePhone}
                            className="btn btn-danger btn-sm"
                          >
                            <i data-id={idx} className="fas fa-trash-alt" />
                          </button>
                        )}
                      </div> */}
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <div className="col-12">
                  <div className="text-centered d-flex justify-content-center">
                    <button
                      className="btn btn-sm btn-danger w-md waves-effect waves-light"
                      type="button"
                      id="phonePopupCloseBtn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleResetProfilePhones}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={handleFormSubmitPhone}
                      className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
                {/* <div className="col-4 align-self-center">
                  {AuthService.canAdd(PermissionFeature.ContactPhones) && (
                    <a
                      onClick={handleAddPhone}
                      disabled={
                        !AuthService.canAdd(PermissionFeature.ContactPhones)
                      }
                      className="text-success c-pointer mo-mt-2 float-right"
                    >
                      <i className="fas fa-plus"></i> Add Phone
                    </a>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PhonePopup;
