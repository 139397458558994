import axios from "../../../Shared/auth-header";
import { toast } from "react-toastify";
import _ from "lodash";
export default {
  BargainingUnitStatus: async () => {
    let url = "/api/Member/BargainingUnitStatus";
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error("Error occured, please try later!");
      return null;
    }
  },

  CivicResults: async (memberid) => {
    let url = "/api/Civic/CivicData?memberid=" + memberid;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      if (data.message == "No Address Found!") {
        return data.message;
      } else {
        console.log("Civic Error", data.message);
        return null;
      }
    }
  },
  SaveFacebookId: async (memberId, facebookId) => {
    let url = "/api/Member/SaveFacebookId";
    var { data } = await axios.post(url, null, {
      params: {
        memberId: memberId,
        facebookId: facebookId,
      },
    });
    if (data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  //GetVirtualCard: async (memberId) => {
  //  let url = "/api/Member/MemberCard/" + memberId;

  //  var data = await axios.get(url);
  //  if (data.status != 204) {
  //    toast.error("An error occured, please try again.");
  //    return null;
  //  }
  //},
  SaveJobSeniority: async (obj) => {
    let url = "/api/Member/MemberSeniority";
    var { data } = await axios.post(url, obj);
    if (data.statusCode && data.statusCode == 200) {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },
  TiebreakerUpdate: async (
    currentID,
    currentTiebreaker,
    oldID,
    oldTiebreaker
  ) => {
    let url = "/api/Member/TiebreakerUpdate";
    var { data } = await axios.post(url, {
      currentID: currentID,
      currentTiebreaker: currentTiebreaker,
      oldID: oldID,
      oldTiebreaker: oldTiebreaker,
    });
    if (data.statusCode && data.statusCode == 200) {
      //     toast.success(data.message);
    } else {
      //   toast.error(data.message);
    }
  },
  MemberEquipments: async (formData) => {
    if (!formData) {
      toast.error("Error occured, please try later!");
    }
    let url = "/api/Member/MemberEquipments/";

    var response = await axios.post(url, formData);
    if (
      response.status == 200 &&
      response.data &&
      response.data.status !== "Fail"
    ) {
      var { data } = response;
      return data;
    } else {
      toast.error("Error occured, please try later!");
      return null;
    }
  },
  JobHistoryDropdown: async () => {
    let url = "/api/Payfile/JobHistoryDropdown";
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 200 && !_.isEmpty(data.result)) {
      return data.result;
    } else {
      toast.error("Error occured, please try later!");
      return null;
    }
  },
  PayCardDropdown: async () => {
    let url = "/api/Member/GetListsOfPayCard";
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 200 && !_.isEmpty(data.result)) {
      return data.result;
    } else {
      toast.error("Error occured, please try later!");
      return null;
    }
  },
  JobUpdate: async (job, memberid) => {
    let url = "/api/Payfile/CUJobHistory";
    var { data } = await axios.post(url, {
      Job: job,
      MemberId: memberid,
    });
    if (data.statusCode && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  JobDelete: async (jobid, memberid) => {
    let url = "/api/Payfile/Job";
    var { data } = await axios.delete(url, {
      params: {
        id: jobid,
        memberId: memberid,
      },
    });
    if (data.statusCode && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  ReSubscribedEmail: async (MailAddress, PrimaryEmail) => {
    const url = `/api/MailGun/ResubscribeMember?mailAddress=${MailAddress}&email=${PrimaryEmail}`;

    let { data } = await axios.put(url);
    if (data.status && data.status.toLowerCase() == "success") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },

  MemberTypes: async () => {
    let url = "/api/Member/MemberTypes";
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error("Error occured, please try later!");
      return null;
    }
  },
  Jobs: async (memberid) => {
    let url = "/api/Member/MemberJobs/" + memberid;
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  MemberSeniorityHistory: async (memberid) => {
    let url = "/api/Member/MemberSeniorityHistory/" + memberid;
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 200) {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  ReCalculateJobSeniority: async (memberid) => {
    let url = "/api/Member/ReCalculateMemberSeniority/" + memberid;
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 200) {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  UpdateMembership: async (obj) => {
    let url = `/api/Member/UpdateMembership`;
    var { data } = await axios.post(url, obj);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  UpdateJobDates: async (obj) => {
    let url = `/api/Payfile/JobHistoryDates`;
    var { data } = await axios.put(url, obj);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },
  UpdatePaycard: async (obj) => {
    let url = `/api/Member/UpdatePayCard`;
    var { data } = await axios.post(url, obj);
    if (data.status && data.status == "Success") {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  },
  AddressUSPS: async (obj) => {
    let url = `/api/Member/AddressValidate`;
    var { data } = await axios.post(url, obj);
    if (data && data.statusCode == 200) {
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  UpdateIsVerified: async (id, isverified) => {
    var { data } = await axios.post("/api/Member/UpdateMemberVerified", null, {
      params: {
        id,
        isverified,
      },
    });
    if (data.status && data.status == "Success") {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  UpdateIsEnabled: async (id, isenabled) => {
    var { data } = await axios.post(
      "/api/Member/UpdateMemberProfileEnabled",
      null,
      {
        params: {
          id,
          isenabled,
        },
      }
    );
    if (data.status && data.status == "Success") {
      toast.success("Account enabled has been updated.");
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  States: async () => {
    var { data } = await axios.get("/api/Member/States");
    if (data.status && data.status == "Success") {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  UpdateIsLocked: async (id, islocked) => {
    var { data } = await axios.post(
      "/api/Member/UpdateMemberProfileLock",
      null,
      {
        params: {
          id,
          islocked,
        },
      }
    );
    if (data.status && data.status == "Success") {
      toast.success("Account lock has been updated.");
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  UpdateWallCalendarRequest: async (id, isChecked) => {
    var { data } = await axios.put(
      "/api/Member/WallCalendarRequest?id=" + id + "&isChecked=" + isChecked

    );
    if (data.statusCode && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
};
