import React, { Component } from "react";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { Row, Col, Form } from "reactstrap";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class CareerLevel extends Component {
  onChange(item) {
    var careerLevelIndex = _.findIndex(
      this.props.filterState.filterData.careerLevel,
      o => {
        return o.id === item.id;
      }
    );
    item.checked = !this.props.filterState.filterData.careerLevel[
      careerLevelIndex
    ].checked;
    this.props.filterState.filterData.careerLevel[careerLevelIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData
    });
  }
  chechboxes(item) {
    return (
      <li class="chechbox-list_item" key={"clliitem-" + item.id}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"clitem-" + item.id}
        >
          <input
            type="checkbox"
            htmlFor={"clitem-" + item.id}
            value={item.id}
            checked={item.checked}
            className="custom-control-input"
            id={"clitem-" + item.id}
            name={"clitem-" + item.id}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"clitem-" + item.id}
          >
            {item.description}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    // debugger
    let noData = <>No filters available</>;
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Career Level</h4>
            <hr />
          </>
        ) : (
            ""
          )}
        <div className="filter-fields-scroll">
          <ul className="chechbox-list longTextchbx">
            {!_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.careerLevel == undefined && (this.props.filterState.filterData.careerLevel === null || this.props.filterState.filterData.careerLevel.length === 0)
              ? noData
              : !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.careerLevel.map(item =>
                this.chechboxes(item)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect(state => ({ filterState: state.advancedFilter }), {
  ...actionFilter
})(CareerLevel);
