import React, { Component } from "react";
import AUX from "../../hoc/Aux_";

export default function ConfirmDeleteModal(props) {
    return (
        <AUX>
            <div className="modal fade delete-modal p-0" role="dialog" aria-labelledby="" aria-hidden="true">
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">Confirm Delete</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                                ×
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 mb-4">
                                <h5>Are you sure you want to delete?</h5>
                            </div>
                            <div className="col-12 text-center mb-3 mt-50 pt-4">
                                <button
                                    className="btn btn-secondary w-md waves-effect cancelModal waves-light mr-2"
                                    data-dismiss="modal"
                                    aria-hidden="true">
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    onClick={() => {
                                        props.confirm().confirmed();
                                    }}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AUX>
    );
}