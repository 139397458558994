import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import PermissionAlgo from './PermissionAlgo';
import Permissions from './Permissions';
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import AssignMemberList from './AssignMemberList';
import Services from "../PermissionService";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import $ from "jquery";
import Scope from "../Scope/Scope";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../PermissionFeature";
class ConfigurePositionPermissionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MemberId: this.props.MemberId,
            data: [],
            openModal: false,
            ModalHeading: 'PositionPSModal',
            position: null,
            scopeData: null,
            teamPermissions: null,
            submissionObject: {
                teamName: "",

                isNational: false,
                Scopes: [],
                Permissions: [],
                Positions: [],
            },
            spinner: false

        }
        this.cancelModal = this.cancelModal.bind(this);
        this.saveModal = this.saveModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.resetEditPositionData = this.resetEditPositionData.bind(this);
        this.positionPermissions = this.positionPermissions.bind(this);
        this.handleResetPosition = this.handleResetPosition.bind(this);
        this.handleChangePositionScope = this.handleChangePositionScope.bind(this);
        this.handleChangePositionPermission = this.handleChangePositionPermission.bind(this);
        this.createPositionScope = this.createPositionScope.bind(this);
        this.saveCancelButton = this.saveCancelButton.bind(this);
        this.handleChangePositionIsOwner = this.handleChangePositionIsOwner.bind(this);
        this.handleChangePositionScopeType = this.handleChangePositionScopeType.bind(this);
    }
    componentWillUnmount() {
    }
    async saveModal() {
        $(".loading").show();
        // For saving the request
        let data = await Services.EditPositionPermissionScope({
            teamID: this.props.teamId,
            positions: this.state.submissionObject.Positions,
        });
        if (!_.isEmpty(data)) {
            if (data.statusCode == 200) {
                toast.success("position edited successfully!");
                let teamID = this.props.teamId;
                this.props.setReloader();
                this.cancelModal();

            }
        }


        $(".loading").hide();
    }
    filterAllCheckedScope(scopeData) {
        var result = _.filter(_.cloneDeep(scopeData), {
            facility: [{ isChecked: true }],
        });
        result = _.map(result, (item) => {
            item.facility = item.facility.filter((x) => x.isChecked === true);
            return item;
        });
        return result;
    }
    createPositionScope() {
        if (this.state.submissionObject.isNational) {
            this.state.positionScopeData = this.state.scopeData;
            this.setState({ positionScopeData: _.cloneDeep(this.state.scopeData) });
        } else {
            this.state.positionScopeData = this.filterAllCheckedScope(
                this.state.scopeData
            );

            this.setState({ positionScopeData: this.state.positionScopeData });
        }
    }
    handleChangePositionPermission(e) {
        let PositionIndex = 0;
        var permissions = this.state.submissionObject.Positions[PositionIndex].Permissions;
        this.state.submissionObject.Positions[PositionIndex].Permissions = PermissionAlgo.changeChild(permissions, e);
        this.state.submissionObject.Positions[PositionIndex].LinkedPositionTeams = [];
        this.setState({ submissionObject: this.state.submissionObject });
    }
    handleChangePositionIsOwner = (e) => {
        const idx = 0;

        this.state.submissionObject.Positions[idx].IsOwner = e.target.checked;
        this.state.position[idx] = _.cloneDeep(
            this.state.submissionObject.Positions[idx]
        );

        this.setState({
            submissionObject: this.state.submissionObject,
            position: this.state.position,
        });
    };

    handleChangePositionScope(e) {
        //e.preventdefault();
        let PositionIndex = 0;
        let Result = PermissionAlgo.handleChangeScopes(
            e,
            this.state.submissionObject.Positions[PositionIndex],
            this.state.submissionObject.Positions[PositionIndex].ScopeConfig
        );
        this.state.submissionObject.Positions[PositionIndex].ScopeConfig =
            Result.Data;
        this.state.submissionObject.Positions[PositionIndex] = Result.gameChanger;
        this.setState({
            submissionObject: this.state.submissionObject,
        }); //************************
    }
    handleChangePositionScopeType = (e) => {
        let positionIndex = 0;
        this.state.submissionObject.Positions[positionIndex].PositionScopeType =
            e.target.value;
        this.setState({ submissionObject: this.state.submissionObject });
    };
    async handleResetPosition(e) {
        this.createPositionScope();
        this.state.position = [];
        if (!_.isEmpty(this.state.editPositionData)) {
            //this.state.editPositionData.map(async (item, idx) => {
            let item = this.state.editPositionData[0];
            //$(".loading").show();
            let permissionData = await Services.PositionPermissions(
                item.id,
                this.props.teamId
            );
            let confirmScopeData = [];
            if (item.positionScopeType == 3) {
                let scopeData = await Services.PositionScope(
                    item.id,
                    this.props.teamId
                );
                confirmScopeData = !_.isEmpty(scopeData) ? scopeData.result : [];
            }
            confirmScopeData = _.isEmpty(confirmScopeData)
                ? PermissionAlgo.changeToFalse(_.cloneDeep(this.state.positionScopeData))
                : confirmScopeData;
            let obj = {
                id: item.id,
                PositionName: item.positionName,
                Permissions: permissionData
                    ? !_.isEmpty(permissionData.result)
                        ? PermissionAlgo.cloneEditPositionPermission(
                            PermissionAlgo.ConvertPermissionToNewScheme(permissionData.result)
                        )
                        : []
                    : [],
                IsOwner: item.isOwner,
                isNational: item.isNational,
                Scopes: PermissionAlgo.createSubmissionScopeObject(confirmScopeData),
                ScopeConfig: confirmScopeData,
                PositionScopeType: item.positionScopeType,
                MemberName: item.memberName,
            };
            this.state.position.push(obj);
            this.state.submissionObject.Positions = _.cloneDeep(
                this.state.position
            );
            this.setState({
                position: this.state.position,
                submissionObject: this.state.submissionObject,
            });
            //  $(".loading").hide();
            //});
        }
    }
    positionPermissions = () => {
        let positionIndex = 0;
        if (!_.isEmpty(this.state.submissionObject.Positions)) {

            console.log(this.state.originalPermissions, 'originalpermission');
            console.log(this.state.submissionObject.Positions[positionIndex].Permissions, 'positionpermission');

            return (
                <Fragment>

                    <Permissions prefix={'PP-'} permissionFeature={this.state.originalPermissions} permissions={this.state.submissionObject.Positions[positionIndex].Permissions} originalPermissions={this.state.teamPermissions} uncheckClass=" uncheck-danger" handleChangePermission={AuthService.canUpdate(PermissionFeature.TeamsPositions) ? this.handleChangePositionPermission : ''} hasParent={true} listId={"PositionPermission" + positionIndex} />

                    <div className="col-12 ml-lg-1 pl-lg-3">

                        <div className="card-text py-1 w-100 custom-checkbox">
                            {(this.props.position.members.length <= 1 && AuthService.canUpdate(PermissionFeature.TeamsPositions)) ?
                                (<>
                                    <input
                                        id={"IsOwner"}
                                        name={"IsOwner"}
                                        type="checkbox"
                                        checked={this.state.submissionObject.Positions[0].IsOwner}

                                        onChange={this.handleChangePositionIsOwner}
                                        className="custom-control-input pr-2"
                                    />
                                    <label
                                        className="h6 custom-control-label"
                                        for={"IsOwner"}
                                    >
                                        Owner
                                </label>
                                </>)
                                : (<>
                                    <input
                                        id={"IsOwner"}
                                        name={"IsOwner"}
                                        type="checkbox"
                                        checked={this.state.submissionObject.Positions[0].IsOwner}

                                        disabled={true}

                                        className="custom-control-input pr-2"
                                    />
                                    <label
                                        className="custom-control-label font-italic text-secondary"
                                        for={"IsOwner"}
                                    >
                                        Owner is disabled because position have more than one member.
                                </label></>

                            )}

                        </div>
                    </div>

                    <div className="mx-0 row">
                        <div className="col-12 pl-lg-2">
                            <h4 className="pb-2 text-primary font-weight-bold">
                                Position Scope
            </h4>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 pt-2 pl-4">
                            <div className="">
                                <input
                                    type="radio"
                                    name="scope"
                                    onChange={AuthService.canUpdate(PermissionFeature.TeamsPositions) ? this.handleChangePositionScopeType : ''}
                                    value="1"
                                    checked={
                                        this.state.submissionObject.Positions[positionIndex]
                                            .PositionScopeType == "1"
                                    }
                                />{" "}
                                <label>Same as team</label>
                            </div>

                            <div className="">
                                <input
                                    type="radio"
                                    name="scope"
                                    onChange={AuthService.canUpdate(PermissionFeature.TeamsPositions) ? this.handleChangePositionScopeType : ''}
                                    value="2"
                                    checked={
                                        this.state.submissionObject.Positions[positionIndex]
                                            .PositionScopeType == "2"
                                    }
                                />{" "}
                                <label>This Team</label>
                            </div>
                            <div className="">
                                <input
                                    type="radio"
                                    name="scope"
                                    onChange={AuthService.canUpdate(PermissionFeature.TeamsPositions) ? this.handleChangePositionScopeType : ''}
                                    value="3"
                                    checked={
                                        this.state.submissionObject.Positions[positionIndex]
                                            .PositionScopeType == "3"
                                    }
                                />{" "}
                                <label>Custom</label>
                            </div>
                        </div>
                        <div
                            id="permissionScopeList"
                            className={
                                "col-lg-8" +
                                (this.state.submissionObject.Positions[positionIndex]
                                    .PositionScopeType == "3"
                                    ? ""
                                    : " d-none")
                            }
                        >
                            <Scope
                                originalScopes={this.state.positionScopeData}
                                scopes={
                                    this.state.submissionObject.Positions[positionIndex].ScopeConfig
                                }
                                national={
                                    this.state.submissionObject.Positions[positionIndex].isNational
                                }
                                isTeamNational={this.state.submissionObject.isNational}
                                hasParent={true}
                                handleChangeScope={AuthService.canUpdate(PermissionFeature.TeamsPositions) ? this.handleChangePositionScope : ''}
                            />
                        </div>
                    </div>
                </Fragment>
            );
        }
    };
    async resetEditPositionData() {
        //$(".loading").show();
        await this.setState({ spinner: true });
        let data = await Services.TeamScopes(this.props.teamId);
        let data2 = await Services.TeamPermissions(this.props.teamId);
        if (!_.isEmpty(data) && !_.isEmpty(data2)) {
            if (
                data.statusCode == 200 &&
                data2.statusCode == 200
            ) {
                this.state.originalPermissions = PermissionAlgo.ConvertPermissionToNewScheme(
                    data2.result
                );
                this.state.teamPermissions = PermissionAlgo.cloneTeamPermission(
                    this.state.originalPermissions
                );
                this.state.scopeData = data.result;
                this.state.submissionObject.isNational = this.props.isNational;
                await this.setState({
                    submissionObject: this.state.submissionObject,
                    scopeData: this.state.scopeData,
                    teamPermissions: this.state.teamPermissions,
                    editPositionData: [this.props.position]
                });
                await this.handleResetPosition();
            }
        }
        await this.setState({ spinner: false });
        //$(".loading").hide();
    }
    componentDidUpdate(prevProps) {
        if (this.props.permissionState.openPositionPSModal && !_.isEmpty(this.props.position)) {
            this.resetEditPositionData();
            this.setState({ openModal: this.props.permissionState.openPositionPSModal })
            this.openModal();
        }
    }
    componentDidMount() {

    }

    cancelModal() {
        this.setState({ openModal: false });
    }
    openModal() {

        this.props.closePositionPSModal();
    }
    saveCancelButton = () => {
        return (
            <Fragment>
                <hr />
                <div className="row pt-3">
                    <div className="col-4"></div>
                    <div className="col-8">
                        <div className="text-right">
                            <button
                                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                                type="button"
                                onClick={this.cancelModal}
                            >
                                Cancel
      </button>
                            <button
                                onClick={this.saveModal}
                                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                                type="submit"
                            >
                                Save
                                </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
    render() {
        return (
            <AUX>
                <Modal id={this.state.ModalHeading} className="styled-modal" isOpen={this.state.openModal} size='lg' backdrop='static' keyboard={false}>
                    <ModalHeader toggle={this.cancelModal} className="modal-header text-uppercase bg-secondary py-2">
                        Configure Permissions
                    </ModalHeader>
                    <ModalBody className="modal-body py-2">
                        {(this.state.spinner) ? (
                            <div class="w-100 text-center p-2">
                                <div class="spinner-border text-info" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (<>
                            {this.positionPermissions()}
                            {(AuthService.canUpdate(PermissionFeature.TeamsPositions)) &&
                                (
                                    <>
                                        {this.saveCancelButton()}
                                    </>
                                )}
                        </>
                            )}
                    </ModalBody>
                </Modal>
            </AUX>
        );
    }
}

export default connect((state) => ({ permissionState: state.permission }), {
    ...PermissionAction,
})(ConfigurePositionPermissionModal);
