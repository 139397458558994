import axios from "../../modules/Shared/auth-header";
import permissionService from "../Permissions/PermissionService";
import { toast } from "react-toastify";
import _ from "lodash";

const ReviewRequest = [
  {
    id: 1,
    candidateName: "Ben",
    candidateId: 1,
    state: "MN",
    eventName: "US House/MN-02",
    amount: "10000",
    natcaMember: "Mitchell Becker",
    natcaMemberId: 13409,
    status: "Approved",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    payableTo: "Craig Angle",
    flyerFile: "flyer.pdf",
    flyerFileLink: "flyer.pdf",
    flyer: true,
    flyerJustification: "",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "Flyer is not attached.",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
  {
    id: 2,
    candidateName: "John Doe",
    candidateId: "2",
    state: "MN",
    eventName: "US House/MN-02",
    amount: "10000",
    natcaMember: "Mitchell Becker",
    natcaMemberId: 13409,
    status: "Submitted",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    flyerFile: "flyer.pdf",
    flyerFileLink: "flyer.pdf",
    flyer: true,
    flyerJustification: "",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    payableTo: "Craig Angle",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "Flyer is not attached",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
  {
    id: 3,
    candidateId: 3,
    candidateName: "Jason Doss",

    state: "MN",
    eventName: "US House/MN-02",
    amount: "4000",
    natcaMember: "Amir Iqbal",
    natcaMemberId: 13409,
    status: "Supported",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    payableTo: "Craig Angle",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    flyerFile: "",
    flyerFileLink: "",
    flyer: false,
    flyerJustification: "This Request doesn't require any flyer.",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
  {
    id: 4,
    candidateId: 4,
    candidateName: "Craig Angle",

    state: "MN",
    eventName: "US House/MN-02",
    amount: "3000",
    natcaMember: "Ben",
    natcaMemberId: 13409,
    status: "Returned",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    payableTo: "Craig Angle",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    flyerFile: "",
    flyerFileLink: "",
    flyer: false,
    flyerJustification: "This Request doesn't require any flyer.",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
  {
    id: 5,
    candidateName: "Craig Angle",
    candidateId: 5,
    state: "MN",
    eventName: "US House/MN-02",
    amount: "13000",
    natcaMember: "John Doe",
    natcaMemberId: 13409,
    status: "Closed",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    payableTo: "Craig Angle",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    flyerFile: "flyer.pdf",
    flyerFileLink: "flyer.pdf",
    flyer: true,
    flyerJustification: "",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
  {
    id: 6,
    candidateName: "Rizwan",
    candidateId: 6,
    state: "MN",
    eventName: "US House/MN-02",
    amount: "2000",
    natcaMember: "Becker",
    natcaMemberId: 13409,
    status: "Denied",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    payableTo: "Craig Angle",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    flyerFile: "",
    flyerFileLink: "",
    flyer: false,
    flyerJustification: "This Request doesn't require any flyer.",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
  {
    id: 7,
    candidateName: "Wasim",
    candidateId: 8,
    state: "MN",
    eventName: "US House/MN-02",
    amount: "100",
    natcaMember: "Becker",
    natcaMemberId: 13409,
    status: "Returned",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    payableTo: "Craig Angle",
    flyerFile: "flyer.pdf",
    flyerFileLink: "flyer.pdf",
    flyer: true,
    flyerJustification: "",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
  {
    id: 8,
    candidateName: "Wasim",
    candidateId: 8,
    state: "MN",
    eventName: "US House/MN-02",
    amount: "5000",
    natcaMember: "Becker",
    natcaMemberId: 13409,
    status: "Withdrawn",
    poc: "Chris Kluth",
    phone: "(952) 994-9316",
    eventDate: "2020-10-10T00:00:00",
    attendees: "J.Doss, Rich Santa",
    checkDate: "2020-10-10T00:00:00",
    checkNumber: "10465",
    campaignAddress: "123 street, area",
    payableTo: "Craig Angle",
    eventNotes:
      "This is the mail notes for approved request that is provided by candidate",
    pictureLink: "event.png",
    flyerFile: "",
    flyerFileLink: "",
    flyer: false,
    flyerJustification: "This Request doesn't require any flyer.",
    history: [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ],
  },
];

export default {
  GetAddressTypes: async () => {
    let tempData = [
      { code: "mail", id: 1 },
      { code: "delivery", id: 2 },
      { code: "home", id: 3 },
      { code: "physical", id: 4 },
    ];
    let data = { status: "Success", data: tempData };

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  GetMyRequests: async (includeClosed) => {
    let url = "/api/PacRequest/GetRequests";
    var { data } = await axios.post(url, null, {
      params: {
        includeClosed: includeClosed,
      },
    });
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  GetReviewRequests: async (permissionType, selectedStatuses) => {
    let url = "/api/PacRequest/GetReviewRequestData";
    var { data } = await axios.post(url, null, {
      params: {
        permissionType: permissionType,
        selectedStatuses: selectedStatuses,
      },
    });
    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  GetReviewRequestDetail: async (reviewRequestId) => {
    // let tempData = ReviewRequest;
    // let data = {
    //   status: "Success",
    //   data: tempData.find((x) => x.id == reviewRequestId),
    // };

    let url = "/api/PacRequest/GetRequestById";
    var { data } = await axios.post(url, null, {
      params: {
        requestId: reviewRequestId == null ? 0 : reviewRequestId,
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  GetPrimaryAddressOfCurrentUser: async () => {
    let tempData = {
      address1: "2162 Harbor Lake Dr",
      address2: "",
      address3: "",
      addresstypeid: 3,
      city: "Fleming Isle",
      county: "CLAY",
      id: 367614,
      isprimary: true,
      state: "FL",
      zipcode: "32003-7792",
    };
    tempData = !_.isEmpty(tempData) ? tempData : null;
    let data = { status: "Success", data: tempData };

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  LoadMembers: async (searchTerms, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/Permission/Members?searchTerms=` +
          searchTerms +
          `&offset=0&fetch=10`
        : `api/Permission/Members?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadPreviousNatcavist: async (selected) => {
    let url = "/api/PacRequest/PreviouslySelectedNatcavist";
    var { data } = await axios.post(url, null, {
      params: {
        selected: selected,
      },
    });
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetLastReviewRequestDetail: async (candidateId) => {
    let tempData = ReviewRequest.filter((x) => x.candidateId == candidateId);
    tempData = !_.isEmpty(tempData) ? tempData[tempData.length - 1] : [];
    let data = { status: "Success", data: tempData };

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  GetPreviousRequestDataByCandidateId: async (candidateId) => {
    let url = "/api/PacRequest/GetPreviousDataByCandidateId";
    var { data } = await axios.post(url, null, {
      params: {
        candidateId: candidateId == null ? 0 : candidateId,
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      //toast.error("Invalid Request");
      return null;
    }
  },

  GetCandidates: async (candidateId) => {
    let url = "/api/PacRequest/GetCandidates";
    var { data } = await axios.post(url, null, {
      params: {
        id: candidateId == null ? 0 : candidateId,
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  GetCandidates: async (candidateId) => {
    let url = "/api/PacRequest/GetCandidates";
    var { data } = await axios.post(url, null, {
      params: {
        id: candidateId == null ? 0 : candidateId,
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  GetRequestHistory: async (requestId) => {
    let tempData = [
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Submitted",
        comments: "Flyer is not attached",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Approved",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },

      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Returned",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
      {
        actionDate: "2020-10-10T10:04:00",
        actionType: "Supported",
        comments: "",
        actionBy: 12985,
        memberName: "J. Doss",
      },
    ];
    let data = { status: "Success", data: tempData };

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  SaveCandidate: async (formdata) => {
    let url = "/api/PacRequest/SaveCandidate";
    var { data } = await axios.post(url, formdata);

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  DeleteCandidate: async (candidateId) => {
    let url = "/api/PacRequest/DeleteCandidate/" + candidateId;
    var { data } = await axios.delete(url, null);

    if (data.status == "Success") {
      toast.success(data.message);
      return data;
    } else {
      //toast.error("Invalid Request");
      return null;
    }
  },

  SaveRequest: async (formdata) => {
    let url = "/api/PacRequest/SaveRequest";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  UpdateRequest: async (formdata) => {
    let url = "/api/PacRequest/ReviewRequestAction";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  DeleteRequest: async (requestId) => {
    let url = "/api/PacRequest/DeleteRequest/" + requestId;
    var { data } = await axios.delete(url, null);

    if (data.status == "Success") {
      toast.success(data.message);
      return data;
    } else {
      //toast.error("Invalid Request");
      return null;
    }
  },

  BatchProcessStatusUpdate: async (processType, checkedIds) => {
    let url = "/api/PacRequest/BatchProcessStatusUpdate";
    var { data } = await axios.post(url, null, {
      params: {
        processType: processType,
        checkedIds: checkedIds,
      },
    });

    if (data.status == "Success") {
      return data;
    } else {
      toast.error("Some error occured while saving.");
      return null;
    }
  },
  GeneratePDF: async (checkedIds) => {
    let uniqueID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

    let url =
      "/api/PacRequest/ReviewRequestPDF?Ids=" +
      checkedIds.join() +
      "&uniqueID=" +
      uniqueID;
    //var { data } = await axios.post(url, checkedIds, { params: { uniqueID } });
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 500) {
      toast.error(data.message);
      return null;
    } else {
      if (data) {
        console.log(data);
        //const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = axios.defaults.baseURL + url;
        // link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
};
