import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../Shared/Modals";
import { toast } from "react-toastify";
import TeamSearch from "./TeamSearch";
import EditTeam from "./EditTeam";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import PermissionHeader from "../General/PermissionHeader";
import Services from "../PermissionService";
import _ from "lodash";
import AuthService from "../../Auth/AuthService";
import TableCustomTotal from '../../Shared/TableCustomTotal'
class ArchivedTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perms: "",
            page: 1,
            sizePerPageTeams: 10,
            keyword: this.props.location.keyword || "",
            data: [],
            totalCount: "",

            currentTab: 1,
            scopes: [],
        };
        this.columns = [
            {
                text: "Team Name",
                dataField: "teamName",
                headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
                classes: "px-3 py-2",
                sort: true,
            },
            {
                text: "Owner",
                //dataField: "count",
                dataField: "ownerName",
                headerClasses: "bg-dark text-white px-3 py-2",
                classes: "px-3 py-2",
                sort: true,
            },
        ];


        this.getTeams();
        this.teamFilters = this.teamFilters.bind(this);
        this.SizePerPageChange = this.SizePerPageChange.bind(this);
        this.RemotePagination = this.RemotePagination.bind(this);
    }
    teamFilters() {
        return this.state.data.filter(
            (itemQ) =>
                _.toLower(itemQ.teamName).includes(
                    _.toLower(this.state.searchTerm || "")
                ) ||
                _.toLower(itemQ.ownerName).includes(
                    _.toLower(this.state.searchTerm || "")
                )
        );
    }
    SizePerPageChange = (sizePerPage) => {
        this.setState({ sizePerPage: sizePerPage });
    }
    RemotePagination(
        columns,
        keyFields,
        data,
    ) {
        let paginationOptions = {
            totalSize: (data) && data.length,
            showTotal: true, onSizePerPageChange: this.SizePerPageChange,
            paginationTotalRenderer: TableCustomTotal
        };
        return (
            <div>
                <BootstrapTable
                    keyField={keyFields}
                    data={data}
                    columns={columns}
                    className="table"
                    pagination={(data.length > this.state.sizePerPage) ? (paginationFactory({ ...paginationOptions })) : false}
                />
            </div>
        );
    }

    async getTeams() {

        $(".loading").show();
        let data = await Services.GetArchivedTeams();
        if (!_.isEmpty(data)) {
            if (data.statusCode == 200) {
                await this.setState({ data: data.result });
            } else {
                toast.error("Data loading error. Please, refresh your page!");
            }
        }

        $(".loading").hide();
    }

    componentDidMount() {
        this.props.setPermissionActiveTab(0);
    }

    render() {
        // New Work
        const { data } = this.state;
        const showTotal = true;
        return (
            <AUX>
                <PermissionHeader />
                <div className="team-container container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <h4 className="text-uppercase page-title-search">Archived Teams</h4>

                                <form role="search" className="app-search mt-0">
                                    <div className="form-group mb-0">
                                        <input
                                            type="text"
                                            id="keyword"
                                            className="form-control rounded border mb-1 w-100"
                                            placeholder="Team Search.."
                                            onChange={(event) =>
                                                this.setState({ searchTerm: event.target.value })
                                            }
                                        />
                                        <button
                                            type="button"
                                            className="c-pointer text-secondary bg-transparent border-0"
                                            onClick={(event) =>
                                                this.setState({ searchTerm: $("#keyword").val() })
                                            }
                                        >
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {(data) &&
                                this.RemotePagination(this.columns, "id", this.teamFilters()

                                )}
                        </div>
                    </div>
                </div>

            </AUX>
        );
    }
}

export default connect((state) => ({ permissionState: state.permission }), {
    ...PermissionAction,
})(ArchivedTeams);
