import { toast } from "react-toastify";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import FE_Tabs_Type from "../../../modules/F&E/FE_Tabs";
import FERequestsType from "../FERequestsType";
import InventoryStatus from "../InventoryStatus";

export default {
  getStrCutLength: (budgetlineName) => {
    let strLength = 8;
    if (budgetlineName.length > 80 && budgetlineName.length <= 90) {
      strLength = 75;
    }
    if (budgetlineName.length > 70 && budgetlineName.length <= 80) {
      strLength = 65;
    }
    if (budgetlineName.length > 60 && budgetlineName.length <= 70) {
      strLength = 55;
    }
    if (budgetlineName.length > 50 && budgetlineName.length <= 60) {
      strLength = 45;
    }
    if (budgetlineName.length > 40 && budgetlineName.length <= 50) {
      strLength = 30;
    }
    if (budgetlineName.length > 30 && budgetlineName.length <= 40) {
      strLength = 20;
    }
    if (budgetlineName.length > 20 && budgetlineName.length <= 30) {
      strLength = 15;
    }
    return strLength;
  },
  setStateAsync: async (state, myself) => {
    let result = await new Promise((resolve) => {
      myself.setState(state, resolve);
    });
    return result;
  },
  ///Dropdown change handler
  changeValueHandler: (e, value, reason, control, MySelf) => {
    const val = e.target.innerText;
    if (
      !_.isEmpty(value) ||
      (MySelf.state.category == 4 && !_.isEmpty(val)) ||
      (MySelf.state.category == 4 && !_.isEmpty(value))
    ) {
      const nextState = {};

      if (!_.isEmpty(value) && MySelf.state.category == 4) {
        if (typeof value == "string") {
          let currentItem = MySelf.state.equipmentFRTOptions.find(
            (valEle) => valEle.val == value
          );
          if (currentItem) {
            value = { key: currentItem.key, val: value };
          } else {
            value = { key: 0, val: value };
          }

          nextState[control] = value;
        } else if (value && value.inputValue) {
          // New Option Added in dropdown
          value = { key: 0, val: value.inputValue };
          nextState[control] = value;
        } else {
          nextState[control] = value;
        }
      } else {
        nextState[control] = value;
      }

      MySelf.setState(nextState);

      if (control == "equipmentType") {
        MySelf.setState({ type: value.key });
        if (value.key !== 5) {
          //This will be used only in Equipement Screen
          if (!_.isEmpty(MySelf.state.usefullLifeOptions)) {
            var usefulLifeList = MySelf.state.usefullLifeOptions;

            var selectedUsefulLife = usefulLifeList.find(
              (el) => el.val == value.usefulLife
            );
            MySelf.setState({ usefullLife: selectedUsefulLife });
          }
        }
      } else if (control == "equipmentCategory") {
        if (value.key === 1) {
          ///Team
          MySelf.setState({ equipmentFRTOptions: MySelf.state.teamsOptions });
          MySelf.setState({ category: value.key });
        } else if (value.key === 2) {
          //Region
          MySelf.setState({ equipmentFRTOptions: MySelf.state.regionsOptions });
          MySelf.setState({ category: value.key });
        } else if (value.key === 3) {
          //Facility
          MySelf.setState({
            equipmentFRTOptions: MySelf.state.facilitiesOptions,
          });
        } else if (value.key === 4) {
          //National Office
          MySelf.setState({
            equipmentFRTOptions: MySelf.state.naOfficeSubCatsOptions,
          });
          MySelf.setState({ category: value.key });
        }
        MySelf.setState({ equipmentFRT: null });
      } else if (
        control == "equipmentFRT" &&
        !_.isEmpty(MySelf.state.equipmentCategory)
      ) {
        if (MySelf.state.equipmentCategory.val == "Team") {
          MySelf.setState({ category: 1 });
          MySelf.setState({ teamid: value.key });
          MySelf.setState({ facilityid: null });
          MySelf.setState({ regionid: null });
        } else if (MySelf.state.equipmentCategory.val == "Region") {
          MySelf.setState({ category: 2 });
          MySelf.setState({ regionid: value.key });
          MySelf.setState({ facilityid: null });
          MySelf.setState({ teamid: null });
        } else if (MySelf.state.equipmentCategory.val == "Facility") {
          MySelf.setState({ category: 3 });
          MySelf.setState({ facilityid: value.key });
          MySelf.setState({ teamid: null });
          MySelf.setState({ regionid: null });
        } else if (MySelf.state.equipmentCategory.val == "National Office") {
          MySelf.setState({ category: 4 });
          MySelf.setState({ facilityid: null });
          MySelf.setState({ teamid: null });
          MySelf.setState({ regionid: null });
          MySelf.setState({ nationalofficecategoryid: value.key });
          MySelf.setState({ nationalofficecategoryname: value.val });
        }
      } else if (control == "disposeReason") {
        if (value.key === 2 || value.key === 4) {
          //Sold || Lost
          MySelf.setState({ showAmount: true });
        } else {
          MySelf.setState({ disposedAmount: "" });
          MySelf.setState({ showAmount: false });
        }
      }
    } else {
      const emptyState = {};
      emptyState[control] = "";
      MySelf.setState(emptyState);
    }
  },
  onDateChange: (jsDate, MySelf, control) => {
    MySelf.setState({
      [control]: moment(jsDate).format("MM/DD/YYYY"),
    });

    MySelf.setState({ date_err: "" });
  },
  handleChangeAmount: (e, control, MySelf) => {
    if (e.target.value != "") {
      var expression = /^[\d.,]+$/;
      if (!expression.test(e.target.value)) {
        MySelf.setState({
          [`${control}_err`]: "Invalid amount, only numbers allowed.",
        });

        return false;
      } else {
        MySelf.setState({
          [`${control}_err`]: "",
        });
      }
    } else {
      MySelf.setState({
        [`${control}_err`]: "",
      });
    }
    var num_parts = e.target.value
      .toString()
      .split(",")
      .join("")
      .split(".");
    if (num_parts[0].length > 10) {
      return false;
    }
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    MySelf.setState({
      [control]:
        num_parts.length > 2
          ? num_parts[0] + "." + num_parts[1]
          : num_parts.join("."),
    });
  },
  LoadBudgetLines: async (searchTerms, offset, fetch) => {
    $(".loading").show();
    let url =
      offset === null || fetch === null
        ? `api/Equipment/BudgetLines?searchTerms=` +
          searchTerms +
          `&offset=0&fetch=10`
        : `api/Equipment/BudgetLines?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      $(".loading").hide();
      return data;
    } else {
      $(".loading").hide();
      toast.error(data.message);
      return null;
    }
  },
  LoadAllBudgetLines: async () => {
    $(".loading").show();
    let url = "api/Equipment/BudgetLines?loadAll=true";
    var { data } = await axios.get(url);
    if (data.statusCode) {
      $(".loading").hide();
      return data;
    } else {
      $(".loading").hide();
      toast.error(data.message);
      return null;
    }
  },
  LoadEquipmentDropDownOptions: async () => {
    $(".loading").show();

    var { data } = await axios.get(`/api/Equipment/EquipmentDropdownsOptions`);

    if (data.status && data.status.toLowerCase() == "success") {
      $(".loading").hide();
      return data;
    } else {
      $(".loading").hide();
      toast.error(data.message);
      return null;
    }
  },

  SaveEquipment: async (formdata) => {
    let url = "/api/Equipment/AddUpdateEquipment";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  AddUpdateBudgetLine: async (formdata) => {
    let url = "/api/Equipment/AddUpdateBudgetLine";
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  ///Used as a method to Dispose the Equipment
  DisposeInventory: async (formData) => {
    let url = "/api/Equipment/DeleteInventory/";
    var { data } = await axios.post(url, formData);

    if (data.status == "Success") {
      return data;
    } else {
      return null;
    }
  },
  //Makes the Equipment to be removed from DB
  DeleteEquipment: async (invId) => {
    let url = "/api/Equipment/DeleteEquipment/";
    $(".loading").show();
    var { data } = await axios.post(url, null, {
      params: { id: invId },
    });
    $(".loading").hide();

    if (data.status == "Success") {
      return data;
    } else {
      return null;
    }
  },
  SubmitReview: async (formData) => {
    let url = "/api/Equipment/ReviewSubmission";
    var { data } = await axios.post(url, formData);

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  RemoveRequest: async (id) => {
    let url = "/api/Equipment/RemoveRequest";
    var { data } = await axios.post(url, null, {
      params: {
        id,
      },
    });
    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteBudgetline: async (id) => {
    $(".loading").show();
    let url = `/api/Equipment/DeleteBudgetLine?budgetld=${id}`;
    var { data } = await axios.delete(url);
    if (data.status && data.status.toLowerCase() == "success") {
      $(".loading").hide();
      toast.success("Budget line removed successfully!");
      return data;
    } else {
      $(".loading").hide();
      toast.error(data.message);
      return null;
    }
  },
  SaveFeRequest: async (formdata) => {
    let url = "/api/Equipment/AddUpdateFERequest";
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  //MDB text input change handler
  handleChange: (e, MySelf) => {
    MySelf.setState({
      [e.target.name]: e.target.value,
    });
  },
  showSpinner: () => {
    $(".loading").show();
  },

  hideSpinner: () => {
    $(".loading").hide();
  },
  isAllDisabled: (...ids) => {
    let result = true;
    ids.forEach(function(id, index) {
      var memberPermissions = JSON.parse(
        localStorage.getItem("memberpermissions")
      ).filter((x) => ids.includes(x.id));
      if (
        _.some(memberPermissions, { id: id, isUpdate: true }) &&
        _.some(memberPermissions, { id: id, isView: true })
      ) {
        result = false;
      }
    });
    return result;
  },
  isAllHidden: (...ids) => {
    let result = true;
    ids.forEach(function(id, index) {
      var memberPermissions = JSON.parse(
        localStorage.getItem("memberpermissions")
      );
      if (_.some(memberPermissions, { id: id, isView: true })) {
        result = false;
      }
    });
    return result;
  },
  changeHanlderServerSiderDropDown: (
    e,
    value,
    reason,
    control,
    MySelf,
    idx,
    dataObj
  ) => {
    if (control === "equipmentUser") {
      if (reason !== "clear") {
        MySelf.setState({ equipmentUser: value });
      } else {
        MySelf.setState({ equipmentUser: null });
        MySelf.loadUsersData("");
      }
    } else if (control === "equipmentPurchaser") {
      if (reason !== "clear") {
        MySelf.setState({ equipmentPurchaser: value });
        MySelf.setState({ purchaser: value.id });
      } else {
        MySelf.setState({ equipmentPurchaser: null });
        MySelf.loadPurchasersData("");
      }
    } else if (control === "equipmentBudgetline") {
      if (reason !== "clear") {
        MySelf.setState({ equipmentBudgetline: value });
        MySelf.setState({ budgetLine: value.id });

        var memberData = JSON.parse(localStorage.getItem("memberData"));
        let selectedEquipmentBudgetlines = [
          ...MySelf.state.selectedEquipmentBudgetlines,
        ];
        let currentSelectedEquipmentBudgetline = {
          ...selectedEquipmentBudgetlines[idx],
        };
        if (value.approverMember.id !== memberData.id) {
          currentSelectedEquipmentBudgetline.showAuthorizedPerson = true;
        } else {
          currentSelectedEquipmentBudgetline.showAuthorizedPerson = false;
        }

        ///Change for multi selection of budgetlines

        currentSelectedEquipmentBudgetline.equipmentBudgetline = value;
        currentSelectedEquipmentBudgetline.budgetid = value.positionId;
        currentSelectedEquipmentBudgetline.budgetauthorizer =
          value.approverMember.firstname + "-" + value.approverMember.lastname;

        currentSelectedEquipmentBudgetline.budgetAuthorizerId =
          value.approverMember.id;

        currentSelectedEquipmentBudgetline.selectedOption = value.name;

        selectedEquipmentBudgetlines[idx] = currentSelectedEquipmentBudgetline;

        MySelf.setState({
          selectedEquipmentBudgetlines,
        });
      } else {
        MySelf.setState({ equipmentBudgetline: null });
        MySelf.setState({ budgetAuthorizer: null });
      }
    }
  },
  handleAllocatedPct: (e, MySelf, idx, dataObj, control) => {
    if (e.target.value != "") {
      var expression = /^[\d.,]+$/;
      if (!expression.test(e.target.value)) {
        MySelf.setState({
          [`${control}_err`]: "Invalid amount, only numbers allowed.",
        });

        return false;
      } else {
        MySelf.setState({
          [`${control}_err`]: "",
        });
      }
    } else {
      MySelf.setState({
        [`${control}_err`]: "",
      });
    }
    var num_parts = e.target.value
      .toString()
      .split(",")
      .join("")
      .split(".");
    if (num_parts[0].length > 10) {
      return false;
    }
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    MySelf.state.selectedEquipmentBudgetlines[idx][control] =
      num_parts.length > 2
        ? num_parts[0] + "." + num_parts[1]
        : num_parts.join(".");

    MySelf.setState({
      selectedEquipmentBudgetlines: MySelf.state.selectedEquipmentBudgetlines,
    });
  },
  getDataForEquipmentsExport: async (formData) => {
    $(".loading").show();
    var response = await axios.post(
      "/api/Equipment/GetFEInventoryList/",
      formData
    );

    if (response.data && response.data.status.toLowerCase() == "success") {
      var { data } = response;
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  getDataForFEExport: async (body) => {
    $(".loading").show();
    // debugger;
    //Try this
    // Incase it is for **Inventories** like InAccReview OR NonAccReview
    if (body.activeTab == FE_Tabs_Type.Inventory) {
      var activeSections = [];
      if (body.recordtype == InventoryStatus.NonAccReview) {
        activeSections.push(InventoryStatus.NonAccReview);
      } else if (body.recordtype == InventoryStatus.InAccReview) {
        activeSections.push(InventoryStatus.InAccReview);
      }
      body.recordsTypeList = activeSections;
      body = {
        isAdvanceSearch: body.isAdvanceSearchApplied,
        isTotalCount: false,
        ...body,
        ...body.searchCriteria,
      };

      //use this
      const { data } = await axios.post(
        "/api/Equipment/GetFEInventoryList/",
        body
      );
      if (data.status == "Success") {
        $(".loading").hide();
        let response = data.data.find((el, index) => {
          return el.recordType === activeSections[0];
        });
        return response;
      } else {
        toast.error("Invalid Request");
        $(".loading").hide();
        return null;
      }
    }
    // Incase it is for **Requests** like My Requests OR Review Requests
    else if (body.activeTab > FE_Tabs_Type.Inventory) {
      var activeSections = [];
      if (body.recordtype == FERequestsType.MyRequests) {
        activeSections.push(FERequestsType.MyRequests);
      } else if (body.recordtype > FERequestsType.MyRequests) {
        activeSections.push(body.recordtype);
      }
      body.feRequestsTypeList = activeSections;

      body = {
        isMyRequests: body.activeTab == FE_Tabs_Type.MyRequests,
        isAdvanceSearch: body.isAdvanceSearchApplied,
        isTotalCount: false,
        ...body,
        ...body.searchCriteria,
      };

      //use this
      const { data } = await axios.post("/api/Equipment/GetFeRequests/", body);
      if (data.status == "Success") {
        $(".loading").hide();
        let response = data.data.find((el, index) => {
          return el.recordType === activeSections[0];
        });
        return response;
      } else {
        toast.error("Invalid Request");
        $(".loading").hide();
        return null;
      }
    }
    $(".loading").hide();
  },
  getRequestById: async (id) => {
    $(".loading").show();
    let url = "/api/Equipment/GetRequestById";
    var { data } = await axios.post(url, null, {
      params: {
        equipmentid: id == null ? 0 : id,
      },
    });

    if (data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },
  markRequestArchiveById: async (id) => {
    $(".loading").show();
    let url = "/api/Equipment/MarkRequestArchive";
    var { data } = await axios.post(url, null, {
      params: {
        requestId: id == null ? 0 : id,
      },
    });

    if (data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },
  validateBudgetPercentages: (state) => {
    let totalPercentages = state.selectedEquipmentBudgetlines.reduce(
      (pv, cv) => pv + (Number(cv.allocatedpct) || 0),
      0
    );
    if (totalPercentages > 100 || totalPercentages < 100) {
      return false;
    } else {
      return true;
    }
  },
  deleteEquipmentBudgetline: (id, myself) => {
    axios
      .delete(`/api/Equipment/DeleteEquipmentBudgetLine/?id=${id}`)
      .then(({ data }) => {
        if (data.status && data.status == "Success") {
          toast.success("Budget Line Deleted Successfuly.");
        } else {
          toast.error(data.message);
        }
        myself.setState({
          isConfirmModalOpen: !myself.state.isConfirmModalOpen,
        });
      })

      .catch(function(error) {
        myself.setState({
          isConfirmModalOpen: !myself.state.isConfirmModalOpen,
        });
        console.log(error);
      });
  },
  deleteMyRequest: (id, myself) => {
    axios
      .delete(`/api/Equipment/DeleteEquipmentBudgetLine/?id=${id}`)
      .then(({ data }) => {
        if (data.status && data.status == "Success") {
          toast.success("Request Deleted Successfuly.");
        } else {
          toast.error(data.message);
        }
      })

      .catch(function(error) {
        console.log(error);
      });
  },
  FetchRequestById: async (id) => {
    $(".loading").show();
    let url = "/api/Equipment/FetchRequest";
    var { data } = await axios.post(url, null, {
      params: {
        requestId: id == null ? 0 : id,
      },
    });

    if (data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },

  getEqBudgetLineFilterOptions: async () => {
    $(".loading").show();
    var { data } = await axios.get(
      "/api/Equipment/GetEqBudgetLineFilterOptions"
    );
    if (data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error("Something went wrong.");
      $(".loading").hide();
      return null;
    }
  },
  advanceFilterPreLoadData: async () => {
    var { data } = await axios.get("/api/Equipment/AdvanceFilterPreLoadData");
    if (data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error("Something went wrong.");
      $(".loading").hide();
      return null;
    }
  },
  generatePDFFileFromHTML: async (html) => {
    var { data } = await axios.post(
      "/api/Equipment/GeneratePDFFileFromHTML",
      html,
      {
        responseType: "blob",
      }
    );

    return data;
  },
};
