import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import RolesModal from "../../Shared/RolesModal";
import ScopeSelector from "./ScopeSelector";
import { Link } from "react-router-dom";

const ButtonToNavigate = ({ title, history, color, classes }) => (
    <Button type="button" onClick={() => history.push({ pathname: "/admin/positions" })} color={color} className={classes}>
        {title}
    </Button>
);

class EditPosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.location.type,
            uid: this.props.location.uid || 0,
            name: "",
            scope: {
                Id: 1,
                Code: "N",
                Description: "National",
                PositionScopeId: 0,
                positionScopeName: "",
                PositionScopeDesc: ""
            },

            role: {
                id: 0,
                name: "",
                description: ""
            },
            delId: 0,
            assignmentTypeCode: 1,
            assignmentTypeDesc: "A"
        };
        this.rolesMethods = () => {
            this.updateRole = args => {
                this.state.role.description = args.description;
                this.state.role.id = args.id;
                this.state.role.name = args.name;

                this.setState({ role: this.state.role });
                $(".roles-modal .cancelModal").trigger("click");
            };

            return this;
        };
        this.setScope = args => {
            this.state.scope.positionScopeId = args.id;
            this.state.scope.positionScopeName = args.code;
            this.setState({
                scope: this.state.scope
            });
            $(".cancelModal").trigger("click");
        };
        this.scopeMethods = () => {
            this.regionMethods = () => {
                this.updateRegion = args => {
                    this.setScope(args);
                };
                return this;
            };
            this.facilityMethods = () => {
                this.updateFacilityId = args => {
                    this.setScope(args);
                };
                return this;
            };
            this.areaMethods = () => {
                this.updateArea = args => {
                    this.setScope(args);
                };
                return this;
            };
            return this;
        };

        this.confirm = () => {
            this.confirmed = () => {
                if (this.state.delId == 0) {
                    return false;
                }
                axios
                    .delete("/api/Position/" + this.state.delId)
                    .then(({ data }) => {
                        if (data.status && data.status == "Success") {
                            toast.success("Position Deleted Successfuly.");
                            this.setState({ delId: 0 });
                            $(".cancelModal").trigger("click");
                            this.getPositions();
                        } else {
                            toast.error(data.message);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            };
            return this;
        };
    }
    handleChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };
    scopeChange = e => {
        const type = e.target.selectedOptions[0].text.toUpperCase();
        this.state.scope = {
            Code: e.target.selectedOptions[0].dataset.code,
            Description: e.target.selectedOptions[0].text,
            Id: e.target.value,
            positionScopeId: 0,
            positionScopeName: ""
        };
        this.setState({
            scope: this.state.scope
        });
        if (type != "NATIONAL1") {
            this.setState({
                scopeType: type
            });
        }
    };

    handleSubmit = evt => {
        evt.preventDefault();
        evt.target.className += " was-validated";
        if (evt.target.checkValidity() === false) {
            return false;
        }
        const data = {
            Id: this.state.uid,
            Name: this.state.name,
            Scope: this.state.scope,
            Role: this.state.role,

            AssignmentTypeCode: this.state.facilitytype,
            AssignmentTypeDesc: this.state.facilitytypeid
        };
        axios
            .post("/api/Position/SavePosition", data)
            .then(({ data }) => {
                if (data.status && data.status == "Success") {
                    toast.success("Position Saved.");
                    this.props.history.push("/admin/positions");
                } else {
                    toast.error(data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    getPositions() {
        $(".loading").show();
        if (!this.state.type) {
            this.props.history.push("/admin/positions");
        }
        axios.get("/api/Position/" + this.state.uid).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({
                    name: data.data.name,
                    scope: data.data.scope,
                    role: data.data.role,
                    assignmentTypeCode: data.data.assignmentTypeCode,
                    assignmentTypeDesc: data.data.assignmentTypeCode,
                    scopeType: data.data.scope.description.toUpperCase()
                });
                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    }
    componentWillUnmount() {
        if (this.props.history.action === "POP") {
            if (this.props.location.backRef) {
                this.props.history.location.uid = this.props.location.backRef.teamId;
            }

            this.props.history.location.keyword = this.props.location.keyword;
        }
    }
    componentDidMount() {
        this.getPositions();
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                        <div className="page-title-box">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h4 className="page-title"> Add/Edit Position</h4>
                                </div>
                                <div className="col-sm-6 float-right text-right">
                                    <Route
                                        path="/"
                                        render={props => <ButtonToNavigate {...props} color="primary" title="Back to Positions" />}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <MDBInput
                                                        value={this.state.name}
                                                        name="name"
                                                        onChange={this.handleChange}
                                                        label="Position Name"
                                                        group
                                                        type="text"
                                                        required>
                                                        <div className="invalid-feedback">Name is required.</div>
                                                    </MDBInput>
                                                </div>
                                            </div>

                                            <div className="col-3">
                                                <label htmlFor="region">Role</label>
                                                <div class="input-group mb-3">
                                                    <span type="text" id="region" name="region" class="form-control disabled">
                                                        {this.state.role.name ? (
                                                            <Link
                                                                className=" waves-effect waves-light"
                                                                to={{
                                                                    pathname: "/admin/roles/add-roles",
                                                                    type: "edit",
                                                                    uid: this.state.role.id
                                                                }}
                                                                color="secondary">
                                                                {this.state.role.name}
                                                            </Link>
                                                        ) : (
                                                                <a
                                                                    className="waves-effect waves-light d-block h-100"
                                                                    data-toggle="modal"
                                                                    data-target=".roles-modal"
                                                                    color="secondary"
                                                                    href="#"
                                                                />
                                                            )}
                                                    </span>
                                                    <div class="input-group-append">
                                                        <button
                                                            class="btn btn-outline-secondary"
                                                            type="button"
                                                            data-toggle="modal"
                                                            data-target=".roles-modal">
                                                            <i class="fa fa-search" />
                                                        </button>
                                                    </div>
                                                    <div className="invalid-feedback">Role is required.</div>
                                                </div>
                                            </div>

                                            <div className="col-3">
                                                <label>Scope: </label>
                                                <div className="form-group">
                                                    <select
                                                        className="browser-default custom-select"
                                                        id="scope"
                                                        onChange={this.scopeChange}
                                                        value={this.state.scope.id}>
                                                        <option data-code="N" value="1">
                                                            National
                                                        </option>
                                                        <option data-code="R" value="2">
                                                            Region
                                                        </option>
                                                        <option data-code="F" value="3">
                                                            Facility
                                                        </option>
                                                        <option data-code="A" value="4">
                                                            Area
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <ScopeSelector
                                                scopeName={this.state.scope.positionScopeName}
                                                scopeId={this.state.scope.PositionScopeId}
                                                type={this.state.scopeType}
                                                scopeMethods={this.scopeMethods}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col float-right text-right">
                                <Route
                                    path="/"
                                    render={props => <ButtonToNavigate {...props} color="danger" title="Cancel" classes="mr-2" />}
                                />
                                <Button to="admin/positions" color="primary" type="submit">
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
                <RolesModal rolesMethods={this.rolesMethods} />
            </AUX>
        );
    }
}

export default EditPosition;
