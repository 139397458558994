
export default [
    {
        text: "Active",
        value: "Active",
    },
    {
        text: "Retired",
        value: "Retired",
    },
    {
        text: "Terminated",
        value: "Terminated",
    },
    {
        text: "Supervisor",
        value: "Supervisor",
    },
    {
        text: "Resigned",
        value: "Resigned",
    },
    {
        text: "Deceased",
        value: "Deceased",
    },
    {
        text: "pass",
        value: "pass",
    }
];
