import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../../Shared/Modals";
import { toast } from "react-toastify";
import TableCustomTotal from '../../Shared/TableCustomTotal';
const { SearchBar } = Search;

class Areas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            totalCount: "",
            delId: 0,
            facility: props.facility
        };
        this.confirm = () => {
            this.confirmed = () => {
                if (this.state.delId == 0) {
                    return false;
                }

                axios
                    .delete("/api/Areas/" + this.state.delId)
                    .then(({ data }) => {
                        if (data.status && data.status == "Success") {
                            toast.success("Area Deleted Successfuly.");
                            this.setState({ delId: 0 });
                            $(".cancelModal").trigger("click");
                            this.getAreas();
                        } else {
                            toast.error(data.message);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            };
            return this;
        };
        this.columns = [
            {
                text: "Area Name",
                dataField: "description",
                sort: true
            },
            {
                text: "Area ID",
                dataField: "code",
                sort: true
            },
            {
                text: "Facility",
                dataField: "facility",
                sort: true
            },
            {
                isDummyField: true,
                text: "",
                headerAttrs: {
                    width: "80px"
                },
                formatter: (cellContent, row) => {
                    return (
                        <div className="actionCol">
                            {true && (
                                <Link
                                    className="btn btn-sm btn-secondary waves-effect waves-light"
                                    to={{ pathname: "/area/edit", type: "add", uid: row.id }}
                                    color="secondary">
                                    <i className="ion ion-md-arrow-round-forward" />
                                </Link>
                            )}
                            {true && (
                                <Link
                                    className="ml-2 btn btn-sm btn-danger waves-effect waves-light del"
                                    onClick={() => {
                                        this.setState({ delId: row.id });
                                    }}
                                    to="#"
                                    data-target=".delete-modal"
                                    data-toggle="modal"
                                    size="sm">
                                    <i className="fas fa-trash-alt del" />
                                </Link>
                            )}
                        </div>
                    );
                }
            }
        ];
        this.rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.className.indexOf("del") < 0) {
                    if (true) {
                        if (this.props.facility) {
                            this.props.his.push({ pathname: "/area/edit", type: "add", uid: row.id });
                        } else {
                            this.props.history.push({ pathname: "/area/edit", type: "add", uid: row.id });
                        }
                    }
                }
            }
        };
    }
    getAreas() {
        $(".loading").show();
        axios.get("/api/Areas").then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({ rows: data.data, totalCount: data.totalCount });
                //$("#search-bar-0").val(this.props.facility).blur();
                // $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    }
    componentDidMount() {
        this.getAreas();
        $("#search-bar-0").hide();
    }
    render() {

        return (
            <AUX>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col">
                                <h4 className="page-title">Areas</h4>
                            </div>
                            <div className="col text-right align-self-baseline">
                                {true && (
                                    <Link
                                        className="btn btn-primary waves-effect waves-light"
                                        to={{ pathname: "/area/edit", type: "add", uid: 0 }}
                                        color="secondary">
                                        Add Area
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <ToolkitProvider keyField="id" data={this.state.rows} columns={this.columns} search={{ defaultSearch: this.props.facility }}>
                                {props => (
                                    <Fragment>
                                        <div className="d-flex justify-content-center mb-2">
                                            <div className="col-6 text-center app-search">
                                                <SearchBar value="a" className="form-control mb-1 w-100" {...props.searchProps} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <BootstrapTable
                                                    striped
                                                    hover
                                                    pagination={paginationFactory({ paginationTotalRenderer: TableCustomTotal, showTotal: true })}
                                                    {...props.baseProps}
                                                    rowEvents={this.rowEvents}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>
                <ConfirmDeleteModal confirm={this.confirm} />
            </AUX>
        );
    }
}

export default Areas;
