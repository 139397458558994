import React, { Component } from "react";
import FEHeader from "../FEHeader";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RequestsListTable from "./RequestsListTable";
import FETrackerActions from "../../../store/feTracker/action";
import _ from "lodash";
import { Collapse, Card } from "reactstrap";
import { connect } from "react-redux";
import FERequestsType from "../FERequestsType";
import { Tags } from "../../F&E/AdvanceFilter/Tags";
import FE_List_Types from "../FE_List_Types";
import FE_API_Actions from "../FE_API_Actions";
import FEServices from "../F&ERequests/FEService";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
class FEReviewRequests extends Component {
  state = {
    isapprovedreviewrequestlistopen: true,
    issubmittedreviewrequestlistopen: true,
    ispendingevpreviewrequestlistopen: true,
    includeClosedTitle: "Show History",
    activeTabKey: "SubmittedRequest",
    includeClosed: false,
    currentRequestId: 0,
    isNotificationCall: false,
  };
  setActiveTab = (k) => {
    this.setState({ activeTabKey: k });
  };
  callListTable = (type) => {
    // let isAFTagsSelected =
    //   this.props.festate.filterData &&
    //   this.props.festate.filterData.searchApplied &&
    //   this.props.festate.filterData.searchApplied.requests &&
    //   this.props.festate.filterData.searchApplied.requests.length >= 1;
    if (this.props.festate.isFERequestAdvanceSearchApplied) {
      // We have to show the advance filter result against selected Tags/filters
      // const formData = {
      //   ...this.props.festate.filterData,
      //   recordsTypeList: [],
      //   PageNo: 1,
      //   PageSize: 10,
      //   SortOrder: "id",
      // };
      // this.props.actions.getResultRowsForAdvanceFilter(formData);
    } else {
      const formData = {
        isMyRequests: false,
        feRequestsTypeList: type,
        showClosed: false,
        PageNo: 1,
        PageSize: 10,
        SortOrder: "id", //"firstnameAsc",
      };

      this.props.searchFeRequestsRecords(formData);
    }
  };
  componentDidMount() {
    //////////Incase notificaiton is clicked/////////////
    const { id } = this.props.match.params;

    if (!_.isEmpty(id)) {
      var notificationCall = this.props.location.state.notificationCall;
      if (notificationCall) {
        this.setState({ isNotificationCall: true });
        this.setState({ currentRequestId: id });
        this.showMyRequest(id);
      }
    } else {
      //Normal call//
      /////////////////////////////////////////////////////////////
      let isBudgetApprover = this.props.festate.isBudgetlineApprover;
      let isEVPApprover = this.props.festate.isEVPApprover;

      if (isBudgetApprover && !isEVPApprover) {
        // only budget approver
        this.callListTable([
          FERequestsType.SubmittedReviewRequests,
          FERequestsType.ApprovedReviewRequests,
          FERequestsType.PendingEVPReviewRequests,
        ]);
      } else if (!isBudgetApprover && isEVPApprover) {
        // only evp approver
        this.callListTable([FERequestsType.PendingEVPReviewRequests]);
      } else if (isBudgetApprover && isEVPApprover) {
        //both evp and budget approver
        this.callListTable([
          FERequestsType.SubmittedReviewRequests,
          FERequestsType.ApprovedReviewRequests,
          FERequestsType.PendingEVPReviewRequests,
        ]);
      } else {
        console.log("Not a budget or evp approver member");
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if (this.state.isNotificationCall && this.state.currentRequestId != id) {
      this.showMyRequest(this.state.currentRequestId);
      this.setState({ currentRequestId: id });
    }
  }
  showMyRequest = async (id) => {
    let response = await FEServices.FetchRequestById(id);
    if (!_.isEmpty(response)) {
      let requestRow = response.data;
      this.props.openEquipmentRelatedRequest(requestRow);
    }
  };
  rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (e.target.className.indexOf("pdfDoc") < 0) {
        this.props.openReviewRequestModal(row);
      }
    },
  };
  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage },
    requestType
  ) => {
    if (type == "pagination" && _.isEmpty(sortOrder) && _.isEmpty(sortField)) {
      sortField = "id";
      sortOrder = "asc";
    }
    /// Advance search applied
    if (this.props.festate.isFERequestAdvanceSearchApplied) {
      this.props.setRequestsTablePaginationInfo(page, sizePerPage, requestType);

      const formData = {
        ...this.props.festate.filterData,
        /// recordsTypeList: [], // used in Inventory
        feRequestsTypeList: [requestType],
        PageNo: page,
        PageSize: sizePerPage,
        SortBy: sortField,
        SortOrder: sortOrder,
        EventType: FE_API_Actions.TableChange,
      };
      this.props.getResultRowsForAdvanceFilter(formData);
    }
    //Normal Search
    else {
      this.props.setRequestsTablePaginationInfo(page, sizePerPage, requestType);

      const formData = {
        isMyRequests: false,
        feRequestsTypeList: [requestType],
        showClosed: this.props.festate.showHisReviewRequests,
        PageNo: page,
        PageSize: sizePerPage,
        SortBy: sortField,
        SortOrder: sortOrder,
        EventType: FE_API_Actions.TableChange,
      };

      this.props.searchFeRequestsRecords(formData);
    }
  };

  toggleClosedRequests = () => {
    var title = this.state.includeClosedTitle;
    if (this.state.includeClosed) {
      title = "Show History";
      this.state.includeClosed = false;
      this.props.setFEShowHistory(false, FE_List_Types.ReviewRequests);
    } else {
      title = "Hide History";
      this.state.includeClosed = true;
      this.props.setFEShowHistory(true, FE_List_Types.ReviewRequests);
    }
    this.setState({
      includeClosed: this.state.includeClosed,
      includeClosedTitle: title,
    });

    const formData = {
      Keyword: "",
      isMyRequests: false,
      feRequestsTypeList: [FERequestsType.ApprovedReviewRequests],
      showClosed: this.state.includeClosed,
      PageNo: 1,
      PageSize: 10,
      OperatorType: "AND",
      SortOrder: "id", //"firstnameAsc",
    };

    this.props.searchFeRequestsRecords(formData);
  };
  showSubmittedApprovedAccordion = () => {
    //Submitted means here the request that are submitted to budget line for sending it to EVP
    let isEVPApprover = this.props.festate.isEVPApprover;
    let isBudgetlineApprover = this.props.festate.isBudgetlineApprover;
    if (isBudgetlineApprover && !isEVPApprover) {
      return true; // only budget aprover
    } else if (isBudgetlineApprover && isEVPApprover) {
      return true; // both budget and evp approver
    } else if (!isBudgetlineApprover && isEVPApprover) {
      return false; // only evp approver
    } else {
      return false; // none
    }
  };

  shouldShowRequestTable = () => {
    var allColsVisible =
      AuthService.canView(PermissionFeature.FERequestUser) &&
      AuthService.canView(PermissionFeature.FERequestDescription) &&
      AuthService.canView(PermissionFeature.FERequestRequester) &&
      AuthService.canView(PermissionFeature.FERequestBudgetID) &&
      AuthService.canView(PermissionFeature.FERequestCategory) &&
      AuthService.canView(PermissionFeature.FERequestType) &&
      AuthService.canView(PermissionFeature.FERequestStatus);
    return allColsVisible;
  };
  render() {
    return (
      <>
        <FEHeader />
        <div className="fet-container container-fluid">
          <React.Fragment>
            <br />
            <div className="row feequip-tags">
              {this.props.festate.isFERequestAdvanceSearchApplied && (
                <Tags isRequest={true} RefreshResult={true} {...this.props} />
              )}
            </div>
            <div className="col-xs-8 col-sm-8">
              {
                <h4 className="page-title-search feheaderTitle">
                  <button
                    type="button"
                    className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2"
                    onClick={() => {
                      this.props.toggleFEAdvanceFilter(true);
                    }}
                  >
                    <i class="cursorPointer pr-1 fab fa-searchengin" />
                    Filter
                  </button>
                </h4>
              }
            </div>
            {/* New Accordion Submitted*/}
            <Tabs
              defaultActiveKey="SubmittedRequest"
              id="uncontrolled-tab-example"
              className="mb-3"
              activeKey={this.state.activeTabKey}
              onSelect={(k) => this.setActiveTab(k)}
            >
              <Tab
                eventKey="SubmittedRequest"
                title={
                  <>
                    <span className="font-weight-bold"> Submitted Request</span>
                  </>
                }
              >
                {this.showSubmittedApprovedAccordion() && (
                  <div className="row reviewParentDiv">
                    <div className="col">
                      <Card className="mb-1">
                        <h6 className="card-header bg-primary text-white mb-3 mt-0 cursorPointer">
                          {/* <i class="fas fa-layer-group"></i> */}
                          <i class="far fa-user pr-1" />
                          <i
                            className="pull-right float-right fa fa-refresh c-pointer"
                            aria-hidden="true"
                            title="Refresh Filters"
                            onClick={() => {
                              const formData = {
                                isMyRequests: false,
                                feRequestsTypeList: [
                                  FERequestsType.SubmittedReviewRequests,
                                ],
                                showClosed: false,
                                PageNo: 1,
                                PageSize: 10,
                                SortOrder: "id", //"firstnameAsc",
                              };

                              this.state.issubmittedreviewrequestlistopen &&
                                this.props.searchFeRequestsRecords(formData);
                              // this.callListTable([
                              //   FERequestsType.SubmittedReviewRequests,
                              // ])
                            }}
                          />
                        </h6>

                        <Collapse
                          isOpen={this.state.issubmittedreviewrequestlistopen}
                        >
                          <div className="col-12">
                            {this.shouldShowRequestTable() ? (
                              <RequestsListTable
                                listData={
                                  this.props.festate.submittedReviewRequestsRows
                                }
                                page={this.props.festate.submittedRRPageNo}
                                sizePerPage={
                                  this.props.festate.submittedRequestSizePerPage
                                }
                                handleTableChange={this.handleTableChange}
                                totalSize={
                                  this.props.festate
                                    .totalSubmittedReviewRequestsCount
                                }
                                loading={
                                  this.props.festate
                                    .isLoadingSubmittedReviewRequests
                                }
                                key={FERequestsType.SubmittedReviewRequests}
                                requestType={
                                  FERequestsType.SubmittedReviewRequests
                                }
                                //feReviewRequestType={FE_Review_Requests_Types.Submitted}
                                viewRequest={this.props.openReviewRequestModal}
                                isAdvanceSearchApplied={
                                  this.props.festate
                                    .isFERequestAdvanceSearchApplied
                                }
                                searchCriteria={this.props.festate.filterData}
                                activeSubHeader={
                                  this.props.festate.activeSubHeader
                                }
                                showClosed={
                                  this.props.festate.showHisReviewRequests
                                }
                                rowEvents={this.rowEvents}
                              />
                            ) : (
                              "No Visible Columns."
                            )}
                          </div>
                        </Collapse>
                      </Card>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab
                eventKey="PendingEVPRequests"
                title={
                  <>
                    <span className="font-weight-bold">
                      {" "}
                      Pending EVP Requests
                    </span>
                  </>
                }
              >
                {/* New Accordion Pending EVP*/}
                {/* Displayed for Both Budget and EVP Approver */}
                <div className="row reviewParentDiv">
                  <div className="col">
                    <Card className="mb-1">
                      <h6 className="card-header bg-primary text-white mb-3 mt-0 cursorPointer">
                        {/* <i class="fas fa-layer-group"></i> */}
                        <i class="far fa-user pr-1" />
                        <i
                          className="pull-right float-right fa fa-refresh c-pointer"
                          aria-hidden="true"
                          title="Refresh Filters"
                          onClick={() => {
                            const formData = {
                              isMyRequests: false,
                              feRequestsTypeList: [
                                FERequestsType.PendingEVPReviewRequests,
                              ],
                              showClosed: false,
                              PageNo: 1,
                              PageSize: 10,
                              SortOrder: "id", //"firstnameAsc",
                            };

                            this.state.ispendingevpreviewrequestlistopen &&
                              this.props.searchFeRequestsRecords(formData);
                          }}
                        />
                      </h6>

                      <Collapse
                        isOpen={this.state.ispendingevpreviewrequestlistopen}
                      >
                        <div className="col-12">
                          {this.shouldShowRequestTable() ? (
                            <RequestsListTable
                              listData={
                                this.props.festate.pendingEVPReviewRequestsRows
                              }
                              page={this.props.festate.pendingEVPRRPageNo}
                              sizePerPage={
                                this.props.festate.pendingEVPRequestSizePerPage
                              }
                              handleTableChange={this.handleTableChange}
                              totalSize={
                                this.props.festate
                                  .totalPendingEVPReviewRequestsCount
                              }
                              loading={
                                this.props.festate
                                  .isLoadingPendingEVPReviewRequests
                              }
                              key={FERequestsType.PendingEVPReviewRequests}
                              requestType={
                                FERequestsType.PendingEVPReviewRequests
                              }
                              viewRequest={this.props.openReviewRequestModal}
                              isAdvanceSearchApplied={
                                this.props.festate
                                  .isFERequestAdvanceSearchApplied
                              }
                              searchCriteria={this.props.festate.filterData}
                              activeSubHeader={
                                this.props.festate.activeSubHeader
                              }
                              showClosed={
                                this.props.festate.showHisReviewRequests
                              }
                              rowEvents={this.rowEvents}
                            />
                          ) : (
                            "No Visible Columns."
                          )}
                        </div>
                      </Collapse>
                    </Card>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="ApprovedRequest"
                title={
                  <>
                    <span className="font-weight-bold"> Approved Requests</span>
                  </>
                }
              >
                {this.showSubmittedApprovedAccordion() && (
                  <>
                    {/* Show History link Above (Approved Requests) */}
                    <div className="align-items-center d-flex">
                      <div className="col-xs-8 col-sm-8">
                        <h4 className="page-title-search feheaderTitle">
                          {/*  Approved Requests  List */}
                        </h4>
                      </div>
                      <div className="col-xs-4 col-sm-4">
                        <div className="cursorPointer hover text-right font-weight-bold small mt-2 textlink">
                          <a onClick={this.toggleClosedRequests}>
                            {this.state.includeClosedTitle}
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* Show History END*/}

                    {/* Last Accordion Approved  */}
                    <div className="row reviewParentDiv">
                      <div className="col">
                        <Card className="mb-1">
                          <h6 className="card-header bg-primary text-white mb-3 mt-0 cursorPointer">
                            {/* <i class="fas fa-layer-group"></i> */}
                            <i class="far fa-user pr-1" />
                            <i
                              className="pull-right float-right fa fa-refresh c-pointer"
                              aria-hidden="true"
                              title="Refresh Filters"
                              onClick={() => {
                                const formData = {
                                  isMyRequests: false,
                                  feRequestsTypeList: [
                                    FERequestsType.ApprovedReviewRequests,
                                  ],
                                  showClosed: false,
                                  PageNo: 1,
                                  PageSize: 10,
                                  SortOrder: "id", //"firstnameAsc",
                                };
                                if (
                                  this.state.isapprovedreviewrequestlistopen &&
                                  this.state.includeClosedTitle ==
                                    "Hide History"
                                ) {
                                  this.setState({
                                    includeClosed: false,
                                    includeClosedTitle: "Show History",
                                  });
                                }
                                this.state.isapprovedreviewrequestlistopen &&
                                  this.props.searchFeRequestsRecords(formData);
                              }}
                            />
                          </h6>

                          <Collapse
                            isOpen={this.state.isapprovedreviewrequestlistopen}
                          >
                            <div className="col-12">
                              {this.shouldShowRequestTable() ? (
                                <RequestsListTable
                                  listData={
                                    this.props.festate
                                      .approvedReviewRequestsRows
                                  }
                                  page={this.props.festate.approvedRRPageNo}
                                  sizePerPage={
                                    this.props.festate
                                      .approvedRequestSizePerPage
                                  }
                                  handleTableChange={this.handleTableChange}
                                  totalSize={
                                    this.props.festate
                                      .totalApprovedReviewRequestsCount
                                  }
                                  loading={
                                    this.props.festate
                                      .isLoadingApprovedReviewRequests
                                  }
                                  key={FERequestsType.ApprovedReviewRequests}
                                  requestType={
                                    FERequestsType.ApprovedReviewRequests
                                  }
                                  //   feReviewRequestType={FE_Review_Requests_Types.Approved}
                                  viewRequest={
                                    this.props.openReviewRequestModal
                                  }
                                  isAdvanceSearchApplied={
                                    this.props.festate
                                      .isFERequestAdvanceSearchApplied
                                  }
                                  searchCriteria={this.props.festate.filterData}
                                  activeSubHeader={
                                    this.props.festate.activeSubHeader
                                  }
                                  showClosed={
                                    this.props.festate.showHisReviewRequests
                                  }
                                  rowEvents={this.rowEvents}
                                />
                              ) : (
                                "No Visible Columns."
                              )}
                            </div>
                          </Collapse>
                        </Card>
                      </div>
                    </div>
                  </>
                )}
              </Tab>
            </Tabs>
          </React.Fragment>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    festate: state.fetracker,
  }),
  {
    ...FETrackerActions,
  }
)(FEReviewRequests);
