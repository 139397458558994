import React, { Component, Fragment } from "react";
import { MDBInput } from "mdbreact";
import axios from "../Shared/auth-header";
import AuthService from "../Auth/AuthService";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CurrentPassword: "",
      Password: "",
      RePassword: "",
      Password_err: "",
      RePassword_err: "",
      CurPassword_err: "",
      valid: false,
      message: "",
      status: false,
      VerificationCode: this.props.vcode,
      type: this.props.type,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
  }
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleFormSubmit(e) {
    e.preventDefault();

    if (this.state.Password === "" || this.state.Password_err != "") {
      this.setState({ Password_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.RePassword === "" || this.state.RePassword_err != "") {
      this.setState({ RePassword_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.valid) {
      debugger;
      $(".loading").show();
      if (this.state.type == "account") {
        const data = {
          Id: AuthService.getProfile().memberData.id,
          CurrentPassword: this.state.CurrentPassword,
          NewPassword: this.state.Password,
        };
        axios
          .post("/api/Member/UpdatePassword", data)
          .then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
              debugger;
              this.setState({ status: true });
              this.setState({ message: "Password changed successfully." });
              this.setState({ Password: "" });
              this.setState({ CurrentPassword: "" });
              this.setState({ RePassword: "" });
              $(".loading").hide();
            } else {
              this.setState({ status: true });
              this.setState({ message: data.message });
              $(".loading").hide();
            }
            //$(".loading").hide();
          })
          .catch(function(error) {
            console.log(error);
            $(".loading").hide();
          });
      } else {
        const data = {
          //model: {
          VerificationCode: this.state.VerificationCode,
          Password: this.state.Password,
          //}
        };
        axios
          .post("/api/Account/UpdatePassword", data)
          .then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
              debugger;

              this.setState({ status: true });
              this.setState({
                message:
                  "Password changed successfully.Please login with your new password",
              });

              document.querySelector(".resetForm").style.display = "none";

              if (document.querySelector(".loginc") != null) {
                document.querySelector(".loginc").classList.remove("d-none");
              }
              this.props.confirmChangeEvent();
              $(".loading").hide();
            } else {
              this.setState({ status: true });
              this.setState({ message: data.message });
              $(".loading").hide();
            }
          })
          .catch(function(error) {
            console.log(error);
            $(".loading").hide();
          });
      }
    }
  }
  handleChangePwd(e) {
    this.setState({ [e.target.name]: e.target.value });
    let passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (e.target.value === "") {
      this.setState({ [`${e.target.name}_err`]: "Required Field" });
      this.setState({ valid: false });
    } else {
      if (e.target.name == "Password") {
        if (!passRegex.test(e.target.value)) {
          this.setState({
            Password_err:
              "Password must be 8 characters long and must contain 1 uppercase, 1 lower case and 1 number.",
          });
          this.setState({ valid: false });
        } else {
          if (this.state.RePassword != e.target.value) {
            this.setState({
              RePassword_err: "Password should be the same.",
              Password_err: "",
            });
            this.setState({ valid: false });
          } else {
            this.setState({ RePassword_err: "", Password_err: "" });
            this.setState({ valid: true });
          }
        }
      } else {
        if (this.state.Password != e.target.value) {
          this.setState({ RePassword_err: "Password should be the same." });
          this.setState({ valid: false });
        } else {
          this.setState({ RePassword_err: "" });
          this.setState({ valid: true });
        }
      }
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.status && (
          <>
            <div className="alert alert-success" role="alert">
              {this.state.message}
            </div>
            <div class="container">
              <div class="row">
                <div class="col text-center">
                  <Link to="/login">
                    <Button size="lg" renderAs="button">
                      <span>Login</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
        <form
          className="resetForm form-horizontal m-t-40"
          onSubmit={this.handleFormSubmit}
        >
          {this.state.type == "account" && (
            <div className="form-group">
              <MDBInput
                name="CurrentPassword"
                value={this.state.CurrentPassword}
                onChange={this.changeHandler}
                label="Current Password"
                group
                type="password"
              >
                <span className="text-danger">
                  {this.state.CurPassword_err}
                </span>
              </MDBInput>
            </div>
          )}
          <div className="form-group">
            <MDBInput
              name="Password"
              value={this.state.Password}
              onChange={this.handleChangePwd}
              label="New Password"
              group
              type="password"
            >
              <span className="text-danger">{this.state.Password_err}</span>
            </MDBInput>
          </div>
          <div className="form-group">
            <MDBInput
              name="RePassword"
              value={this.state.RePassword}
              onChange={this.handleChangePwd}
              label="Confirm New Password"
              group
              type="password"
            >
              <span className="text-danger">{this.state.RePassword_err}</span>
            </MDBInput>
          </div>
          <div className="form-group  mb-4 row">
            <div className="col-12 mx-auto text-center">
              <button
                disabled={!this.state.valid}
                className="btn btn-primary btn-block w-md waves-effect waves-light"
                type="submit"
              >
                Save Password
              </button>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default ChangePasswordForm;
