import React, { Component } from "react";
import { connect } from "react-redux";
import actionFilter from "../../../store/advancedfilter/action";
import { Accordion, Card, Button, Dropdown } from "react-bootstrap";
import AuthService from "../../Auth/AuthService";
import ReactDOM from "react-dom";
import savedSearchAction from "../../../store/saveSearch/action";
import { Link, withRouter } from "react-router-dom";
import mailGunEmailActions from "../../../store/mailgunEmails/action";
import PermissionFeature from "../../Permissions/PermissionFeature";
import facilityActions from "../../../store/facility/action";
import axios from "../../Shared/auth-header";
import OfficerButton from "../../Dashboard/FacilityDashboard/OfficerButton";
import "../../../assets/css/scroller.css";
import { toast } from "react-toastify";
import $ from "jquery";

import _ from "lodash";
import * as Permissions from "../../Permissions/PermissionValues";

import ConfirmLoadBucketSearchModal from "../../Shared/ConfirmLoadBucketSearchModal";
import FiltersService from "./Listing/FiltersService";
import * as SavedSearchTypes from "../../AdvanceSearch/SavedSearches/SavedSearchTypes";

const OWNERSHIP_ITEM = 1;
const BUCKET_ITEM = 2;
const SHARED_ITEM = 3;

class AdvancedSavedSearch extends Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
    this.state = {
      refreshState: false,
      memberId: AuthService.getProfile().memberData.id,
      ownershipItems: [],
      bucketSearchItems: [],
      sharedSearchItems: [],
      showMenu: false,
      advanced_search_title: "",
      isRefreshingSharedSearch: false,
      searchResultData: [],
      showDropdown: false,
      savedSearchId: null,
      savedSearchTypeId: null,
      isAdvancedFilterSelected: false,
      showEditSearchDialog: false,
      sharedSearchLoader: false,
      confirmBucketSearch: null,
      allFilters: [],
    };
    this.renderItemType.bind(this);
    this.refreshState.bind(this);
    this.fetchSearchCriteria.bind(this);

    this.editSearchDialog = React.createRef();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleKeyUp = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  _handleClick = (e) => {
    // e.stopPropagation(); //  <------ Here is the magic
    // e.preventDefault();
    const { history } = this.props;
    this.props.setfilterActionClick(true);

    //Assuming it for Directory Tabs
    if (history && history.location.pathname == "/directory/members") {
      $("#clickAdvancedFilter").trigger("click");
    } else {
      //Assuming it for Directory Tabs
      this.props.history.push("/directory/members", {
        routeFromProfile: false,
        routeFromDDLFiler: true,
      });
      this.props.history.location.link = "/directoryTabs";
    }
  };
  // Saved Filters List Link click
  _handleClickFilterList = (e) => {
    //const { history } = this.props;
    this.props.redirectToFiltersList();
  };
  handleClose = () => {
    this.setState({ showEditSearchDialog: false });
  };

  componentDidMount() {
    const { isFiltersLoaded } = this.props.savedSearchState;
    if (!isFiltersLoaded) {
      this.refreshState();
    }
    //console.log("kehna ghalat ghalat");
    // if (this.props && this.props.location) {
    //   let savedSearchId = this.props.savedSearchState.SaveSearchId; //this.props.location;
    //   if (
    //     this.props.filterState.isAdvancedFilterSelected === true &&
    //     savedSearchId &&
    //     savedSearchId > 0
    //   ) {
    //     this.fetchSearchCriteria(this.props.savedSearchState.SavedSearchDetail);
    //   }
    // }
  }

  // componentWillMount = () => {};
  componentDidUpdate = async (prevProps) => {
    if (this.props.savedSearchState.IconTriggerEvent != "") {
      this.triggerIconEvents(this.props.savedSearchState.IconTriggerEvent);
    }
    if (this.props.savedSearchState.ResetSavedSearchComp) {
      this.resetSavedSearch();
    }
    const { RefreshSelectId } = this.props.savedSearchState;
    if (this.props.savedSearchState.RefreshState) {
      await this.props.updateAndSelect(0, false);
      this.refreshState(true, RefreshSelectId);
    }
  };
  resetSavedSearch() {
    this.setState({
      savedSearchId: null,
      savedSearchTypeId: null,
      activeSearchId: null,
    });
    this.props.setSavedSearchCriteria(null);
    //this.props.setSavedSearchDetail(null);
    this.props.stopResetSavedSearchComp();
  }
  // refreshSharedSearch = () => {
  //   this.state.sharedSearchLoader = true;
  //   axios
  //     .get("/api/SavedSearch/getMemberSearches/" + this.state.memberId)
  //     .then(({ data }) => {
  //       this.state.sharedSearchLoader = false;
  //       this.setState({ sharedSearchItems: data.sharedSearchItems });
  //     });
  //   this.setState({ sharedSearchLoader: this.state.sharedSearchLoader });
  // };
  // refreshState = async () => {
  //   this.showSpinner();
  //   var data = await FiltersService.getMemberSearches(this.state.memberId); // ??? Which member is this
  //   if (
  //     _.isArray(data.ownershipItems) &&
  //     this.props.savedSearchState.SavedSearchDetail != null &&
  //     this.props.savedSearchState.SavedSearchDetail.search_type_id ==
  //       SavedSearchTypes.OWNERSHIP_ITEM
  //   ) {
  //     let currentObj = data.ownershipItems.filter(
  //       (x) => x.id == this.props.savedSearchState.SavedSearchDetail.id
  //     );
  //     if (currentObj.length == 1) {
  //       //delete currentObj[0].search_criteria;
  //       this.props.setSavedSearchDetail(currentObj[0]);
  //     }
  //   } else if (
  //     _.isArray(data.bucketSearchItems) &&
  //     this.props.savedSearchState.SavedSearchDetail != null &&
  //     this.props.savedSearchState.SavedSearchDetail.search_type_id ==
  //       BUCKET_ITEM
  //   ) {
  //     let currentObj = data.bucketSearchItems.filter(
  //       (x) => x.id == this.props.savedSearchState.SavedSearchDetail.id
  //     );
  //     if (currentObj.length == 1) {
  //       //delete currentObj[0].search_criteria;
  //       this.props.setSavedSearchDetail(currentObj[0]);
  //     }
  //   } else if (
  //     _.isArray(data.sharedSearchItems) &&
  //     this.props.savedSearchState.SavedSearchDetail != null &&
  //     this.props.savedSearchState.SavedSearchDetail.search_type_id ==
  //       SHARED_ITEM
  //   ) {
  //     let currentObj = data.sharedSearchItems.filter(
  //       (x) => x.id == this.props.savedSearchState.SavedSearchDetail.id
  //     );
  //     if (currentObj.length == 1) {
  //       //delete currentObj[0].search_criteria;
  //       this.props.setSavedSearchDetail(currentObj[0]);
  //     }
  //   }
  //   if (this.props.savedSearchState.RefreshState) {
  //     let selectId = this.props.savedSearchState.RefreshSelectId;
  //     await this.props.updateAndSelect(0, false);
  //     var data2 = data.ownershipItems.filter((x) => x.id == selectId);
  //     if (data2.length == 1) {
  //       this.fetchSearchCriteria(data2[0]);
  //     } else {
  //       data2 = data.bucketSearchItems.filter((x) => x.id == selectId);
  //       if (data2.length == 1) {
  //         this.fetchSearchCriteria(data2[0]);
  //       } else {
  //         data2 = data.sharedSearchItems.filter((x) => x.id == selectId);
  //         if (data2.length == 1) {
  //           this.fetchSearchCriteria(data2[0]);
  //         }
  //       }
  //     }
  //   }
  //   // this.setState({ ownershipItems: data.ownershipItems });
  //   // this.setState({ bucketSearchItems: data.bucketSearchItems });
  //   // this.setState({ sharedSearchItems: data.sharedSearchItems });
  //   this.setState({
  //     ownershipItems: data.ownershipItems,
  //     bucketSearchItems: data.bucketSearchItems,
  //     sharedSearchItems: data.sharedSearchItems,
  //   });
  //   let allFilters = [
  //     ...data.ownershipItems,
  //     ...data.bucketSearchItems,
  //     ...data.sharedSearchItems,
  //   ];
  //   let truncatedList = allFilters.map((obj) => {
  //     // Create a copy of the object without the "search_criteria" property
  //     const { search_criteria, ...rest } = obj;
  //     return rest;
  //   });
  //   this.props.setMemberFilters(truncatedList);
  //   //let pinnedOnly = allFilters.filter((item) => item.is_pinned === 1);
  //   // this.setState({
  //   //   allFilters: pinnedOnly,
  //   // });

  //   this.hideSpinner();

  //   console.log("refreshState is called!!!");
  //   this.props.setIsFiltersLoaded(true);
  // };
  refreshState = async (refreshStateFlag = false, selectId = 0) => {
    this.showSpinner();
    let data = await FiltersService.getMemberSearches(this.state.memberId);
    this.hideSpinner();
    if (data != null) {
      const { ownershipItems, bucketSearchItems, sharedSearchItems } = data;
      const { SavedSearchDetail } = this.props.savedSearchState;
      const searchType = SavedSearchDetail
        ? SavedSearchDetail.search_type_id
        : null;

      // const findSearchCriteria = (searchItems) => {
      //   const currentObj = searchItems.find((x) => x.id === selectId);
      //   if (currentObj) {
      //     this.fetchSearchCriteria(currentObj);
      //   }
      // };

      let selectedSearchItem = null;

      if (Array.isArray(ownershipItems) && searchType === OWNERSHIP_ITEM) {
        selectedSearchItem = ownershipItems.find(
          (x) => x.id === SavedSearchDetail.id
        );
      } else if (
        Array.isArray(bucketSearchItems) &&
        searchType === BUCKET_ITEM
      ) {
        selectedSearchItem = bucketSearchItems.find(
          (x) => x.id === SavedSearchDetail.id
        );
      } else if (
        Array.isArray(sharedSearchItems) &&
        searchType === SHARED_ITEM
      ) {
        selectedSearchItem = sharedSearchItems.find(
          (x) => x.id === SavedSearchDetail.id
        );
      }

      if (selectedSearchItem) {
        //delete selectedSearchItem.search_criteria;
        this.props.setSavedSearchDetail(selectedSearchItem);
      }

      if (refreshStateFlag) {
        // let filter = ownershipItems.find((x) => x.id === selectId);
        // if (!filter) {
        //   filter = bucketSearchItems.find((x) => x.id === selectId);
        // }
        // if (!filter) {
        //   filter = sharedSearchItems.find((x) => x.id === selectId);
        // }
        // if (filter) {
        //   this.fetchSearchCriteria(filter);
        // }
        const {
          location: { pathname },
        } = this.props.history;
        const {
          allSystemFilters,
          loggedInUserFilters,
        } = this.props.savedSearchState;
        let isExistsInAllFilters = allSystemFilters.some(
          (f) =>
            f.id == selectId ||
            (searchType == SHARED_ITEM && f.saved_search_id == selectId)
        );
        let isExistsInMyFilters = loggedInUserFilters.some(
          (f) =>
            f.id == selectId ||
            (searchType == SHARED_ITEM && f.saved_search_id == selectId)
        );
        let filter = 0;
        if (isExistsInMyFilters) {
          filter = loggedInUserFilters.find(
            (f) =>
              f.id == selectId ||
              (searchType == SHARED_ITEM && f.saved_search_id == selectId)
          );
        } else if (isExistsInAllFilters) {
          filter = allSystemFilters.find(
            (f) =>
              f.id == selectId ||
              (searchType == SHARED_ITEM && f.saved_search_id == selectId)
          );
        }
        if (filter) {
          this.fetchSearchCriteria(filter);
        } else {
          console.log("filter not found!");
        }
      }

      this.setState({
        ownershipItems,
        bucketSearchItems,
        sharedSearchItems,
        //allFilters: pinnedOnly,
      });

      const allFilters = [
        ...ownershipItems,
        ...bucketSearchItems,
        ...sharedSearchItems,
      ];
      let truncatedList = allFilters.map((obj) => {
        // Create a copy of the object without the "search_criteria" property
        const { search_criteria, ...rest } = obj;
        return rest;
      });
      this.props.setMemberFilters(truncatedList);

      //const pinnedOnly = allFilters.filter((item) => item.is_pinned === 1);
      this.props.setIsFiltersLoaded(true); // Linked with componentDidMount
    }
  };
  triggerIconEvents(Event) {
    this.props.unSetIconEvent();
    let Events = this.props.savedSearchState.IconEvents;
    let currentSearch = this.props.savedSearchState.SavedSearchDetail;
    if (currentSearch != null) {
      switch (Event) {
        case Events.STAR_ICON:
          this.togglePin(currentSearch.id, currentSearch.search_type_id);
          break;
        case Events.EDIT_ICON:
          this.editSearchItem(currentSearch);
          break;
        case Events.DELETE_EVENT:
          this.deleteSearch(
            currentSearch.search_name,
            currentSearch.id,
            currentSearch.search_type_id
          );
          break;
        default:
      }
    }
  }

  hasPermissionToSave() {
    // Logic to get permission to save search.
    return AuthService.canAdd(Permissions.Saved_Search);
  }

  hasPermissionToDelete = () => {
    return AuthService.canDelete(Permissions.Saved_Search);
  };

  togglePin = async (searchId, searchTypeId) => {
    const { memberId } = this.state;
    this.showSpinner();
    const data = await FiltersService.togglePin(
      searchId,
      searchTypeId,
      memberId
    );
    if (data.status) {
      if (data.status.toLowerCase() === "success") {
        toast.success(data.message);
        this.refreshState();
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Search cannot be updated.");
    }
    this.hideSpinner();
    // let patchUrl =
    //   "/api/SavedSearch/ToggleSearchPin?savedSearchId=" +
    //   searchId +
    //   "&searchType=" +
    //   searchTypeId +
    //   "&memberId=" +
    //   this.state.memberId;
    // this.showSpinner();
    // axios.patch(patchUrl).then(({ data }) => {
    //   this.hideSpinner();
    //   if (data.status) {
    //     if (data.status.toLowerCase() === "success") {
    //       toast.success(data.message);
    //       this.refreshState();
    //     } else {
    //       toast.error(data.message);
    //     }
    //   } else {
    //     toast.error("Search cannot be updated.");
    //   }
    // });
  };

  editSearchItem = (item) => {
    if (item) {
      this.editSearchDialog.current.editSearchItem(
        item.id,
        item.search_type_id,
        item.search_name
      );
      this.setState({ showEditSearchDialog: true });
    }
  };

  renderPinnedIcon = (item) => {
    if (item.is_pinned === 1) {
      return (
        <i
          className="fas fa-star pt-1 pb-1"
          title={`Unpin ${item.search_name}.`}
          onClick={() => {
            this.togglePin(item.id, item.search_type_id);
            $("body").trigger("click");
          }}
        ></i>
      );
    } else {
      return (
        <i
          className="far fa-star"
          title={`Pin ${item.search_name}.`}
          onClick={() => {
            this.togglePin(item.id, item.search_type_id);
          }}
        ></i>
      );
    }
  };
  renderEditIcon = (item) => {
    if (item.member_id === this.state.memberId) {
      return (
        <i
          className="fas fa-pencil-alt"
          title={`Edit ${item.search_name}.`}
          onClick={() => {
            this.editSearchItem(item);
            $("body").trigger("click");
          }}
        ></i>
      );
    }
  };
  renderBucketIcon = (item) => {
    if (item.search_type_id === BUCKET_ITEM) {
      return <i className="fab fa-bitbucket notification text-info"></i>;
    }
  };
  renderDeleteIcon = (item) => {
    if (
      (item.member_id === this.state.memberId ||
        item.search_type_id == SHARED_ITEM) &&
      this.hasPermissionToDelete() === true
    ) {
      return (
        <i
          className="fas fa-trash-alt"
          title={`Delete ${item.search_name}.`}
          onClick={() => {
            this.deleteSearch(item.search_name, item.id, item.search_type_id);
            $("body").trigger("click");
          }}
        ></i>
      );
    }
  };

  deleteSearch(searchName, searchId, searchType) {
    if (
      window.confirm(
        `This will delete the saved search ${searchName} permanently.\r\nIf this search is shared with other users they will lose access.\r\nAre you sure?`
      )
    ) {
      let deleteUrl =
        "/api/SavedSearch/DeleteSearch?savedSearchId=" +
        searchId +
        "&searchType=" +
        searchType +
        "&memberId=" +
        this.state.memberId;
      this.showSpinner();
      axios.delete(deleteUrl).then(({ data }) => {
        this.hideSpinner();
        if (data.status) {
          if (data.status.toLowerCase() === "success") {
            toast.success(data.message);
            this.refreshState();
            this.props.resetSavedSearch();
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("Search cannot be deleted.");
        }
      });
    }
  }

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  refreshResults(searchCriteria, columnHeaders) {
    if (searchCriteria) {
      const body = searchCriteria;
      var url =
        "/api/Member/AdvancedFilter?pageOffset=0&pageFetch=10&sortName=seniorityasc";
      this.showSpinner();
      axios.post(url, body).then(({ data: response }) => {
        /// console.clear();
        console.log("Response GetSavedSearchFilter : ");
        console.log(response);
        this.setState({ searchResultData: response.data });
        //this.props.savedFilterSelected(searchCriteria, response, columnHeaders);
        //this.props.setSavedSearchCriteria(searchCriteria);
        this.hideSpinner();
      });
    }
  }
  handleDropdownItemClick = (item) => {
    const {
      location: { pathname },
    } = this.props.history;
    const {
      allSystemFilters,
      loggedInUserFilters,
    } = this.props.savedSearchState;
    let isExistsInAllFilters = allSystemFilters.some((f) => f.id == item.id);
    let isExistsInMyFilters = loggedInUserFilters.some((f) => f.id == item.id);
    let filterId = 0;
    //Whenever their is real_search_type_id property present it indicates shared saved-search
    if (item.real_search_type_id) {
      filterId = item.saved_search_id;
    } else {
      filterId = item.id;
    }
    //If click action happened from "All Email-Lists" then route to "All Filters"
    if (isExistsInMyFilters) {
      this.props.redirectToFiltersList(item);
    } else if (isExistsInAllFilters) {
      if (isExistsInMyFilters) {
        this.props.redirectToFiltersList(item);
      } else {
        this.props.redirectToAllFiltersList(item);
      }
    }
  };
  fetchSearchCriteria = async (item, isDDLClick = false) => {
    // const {
    //   location: { pathname },
    // } = this.props.history;
    if (isDDLClick) {
      this.handleDropdownItemClick(item); //dont' want to re-route it to "directory/members",from New Search Filters screen.
    } else {
      //debugger;
      //console.log("fetchSearchCriteria called from savedSearch.component.js");
      let searchId = item.id,
        ///item.search_type_id == SHARED_ITEM ? item.saved_search_id : item.id,
        //searchTitle = item.search_name,
        owner_member = item.owner_id || item.member_id, // member_id in "myfilter" and "owner_id" in allfilters listing
        searchTypeId = item.search_type_id;
      const { isOpenFilterModal = false } = this.props.savedSearchState;

      if (searchId && searchTypeId) {
        this.props.ResetlocalPaging();
        this.props.isSavedSearchSelected(true);
        this.props.setIsBasicSearchSelected(false);
        this.props.setAdvancedSearch(false);
        if (!isOpenFilterModal) {
          this.props.redirectToMembers(); //dont' want to re-route it to "directory/members",from New Search Filters screen.
        }
        this.props.setSavedSearchCriteria(null);
        this.props.setSavedSearchDetail(null);
        this.setState({
          savedSearchId: searchId,
          savedSearchTypeId: searchTypeId,
          activeSearchId: searchId,
        });
        this.setState({ showDropdown: false });
        const body = {
          SearchId: searchId, /// Must be primary key of "SharedSearch" table in case of "Shared Search"
          /// else if primary key of Saved Searches
          MemberId: owner_member, // Useless in case if Shared Search item
          SearchType: searchTypeId,
        };
        let shared_type_id = 0;
        let shared_search_id = 0;
        if (
          item.search_type_id == SHARED_ITEM &&
          item.real_search_type_id == BUCKET_ITEM
        ) {
          shared_type_id = item.real_search_type_id;
          shared_search_id = item.saved_search_id;
        }
        this.props.setSavedSearchDDLObject({
          ...body,
          savedSearchPerformed: true,
        });
        this.showSpinner();
        // var _this = this;
        // axios
        //   .post("/api/SavedSearch/GetSavedSearchFilter", body)
        //   .then(({ data: response }) => {
        //     this.hideSpinner();
        //     if (response.status) {
        //       if (response.status.toLowerCase() === "success") {
        //         this.props.setSavedSearchCriteria({
        //           savedSearchCriteriaDetail: response,
        //           searchTypeId,
        //           searchId,
        //           sharedSaveSearchTypeId: shared_type_id,
        //           sharedBucketSearchId: shared_search_id,
        //         });
        //         //delete item.search_criteria;
        //         this.props.setSavedSearchDetail(item);

        //         if (searchTypeId == BUCKET_ITEM) {
        //           this.props.isSavedBucket(true);
        //           this.props.setSavedBucketId(searchId);
        //         } else {
        //           this.props.isSavedBucket(false);
        //           this.props.setSavedBucketId(0);
        //         }

        //         $("body").trigger("click");
        //       } else {
        //         toast.error(response.message);
        //       }
        //     } else {
        //       toast.error("Could not retrieve saved search detail.");
        //     }
        //   });
        let response = await FiltersService.GetSavedSearchFilter(body);
        if (response) {
          this.props.setSavedSearchCriteria({
            savedSearchCriteriaDetail: response,
            searchTypeId,
            searchId,
            sharedSaveSearchTypeId: shared_type_id,
            sharedBucketSearchId: shared_search_id,
          });
          //delete item.search_criteria;
          this.props.setSavedSearchDetail(item);
          if (searchTypeId == BUCKET_ITEM) {
            this.props.isSavedBucket(true);
            this.props.setSavedBucketId(searchId);
          } else {
            this.props.isSavedBucket(false);
            this.props.setSavedBucketId(0);
          }
          $("body").trigger("click");
        }
      } //END IF BLOCK
    }
  }; //END MAIN FUNC
  fetchBucketSearchCriteria(item, isDDLClick) {
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    if (items !== null && items.length > 0) {
      this.setState({ confirmBucketSearch: item });
      window.$("#CLBSModal").modal("show");
    } else {
      this.fetchSearchCriteria(item, isDDLClick);
    }
  }
  renderItemType(itemsToRender, emptyMessage) {
    if (itemsToRender && itemsToRender.length) {
      return (
        <ul>
          {itemsToRender.map((item) => (
            <li key={item.id}>
              <div className="row">
                <div className="col-8 pr-1 pl-1">
                  <a
                    className={
                      this.state.activeSearchId == item.id
                        ? "search active"
                        : "search"
                    }
                    title={`Fetch ${item.search_name} results.`}
                    onClick={() => {
                      if (
                        item.search_type_id == BUCKET_ITEM ||
                        (item.search_type_id == SHARED_ITEM &&
                          item.real_search_type_id == BUCKET_ITEM)
                      ) {
                        this.fetchBucketSearchCriteria(item, true);
                        //      this.fetchSearchCriteria(item);
                      } else {
                        this.fetchSearchCriteria(item, true);
                      }
                      $("body").trigger("click");
                    }}
                  >
                    {item.search_name}
                  </a>
                </div>
                <div className="col-4 pl-1">
                  <div className="float-right">
                    {this.renderPinnedIcon(item)}
                    {/* {this.renderEditIcon(item)}
                    {this.renderDeleteIcon(item)} */}
                    {this.renderBucketIcon(item)}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div
          ref={(div) => {
            if (div) {
              div.style.setProperty("font-style", "italic", "important");
            }
          }}
        >
          {emptyMessage}
        </div>
      );
    }
  }

  canViewBucketResult = () => {
    return AuthService.canView(Permissions.Buckets);
  };
  canViewSharedResult = () => {
    return AuthService.canView(Permissions.Share_Search);
  };
  createConfirmModal() {
    const app = (
      <ConfirmLoadBucketSearchModal
        confirm={() => {
          this.fetchSearchCriteria(this.state.confirmBucketSearch);
        }}
      ></ConfirmLoadBucketSearchModal>
    );
    let existItem = document.getElementById("confirmBSwrapper");
    if (existItem) {
      ReactDOM.render(app, existItem);
    } else {
      let g = document.createElement("div");
      g.setAttribute("id", "confirmBSwrapper");
      ReactDOM.render(app, document.body.appendChild(g));
    }
  }
  render() {
    let {
      allFilters = [],
      loggedInUserFilters = [],
    } = this.props.savedSearchState;
    const { match, location, history } = this.props;
    const currentRouteUrl = location.pathname;
    let pinned_filters = [];
    if (currentRouteUrl.includes("all")) {
      pinned_filters = allFilters.filter((item) => item.is_pinned === 1);
    } else {
      pinned_filters = loggedInUserFilters.filter(
        (item) => item.is_pinned === 1
      );
    }

    this.createConfirmModal();
    return (
      <div className="col-12 advanced-search-container">
        <div className="row justify-content-end d-flex">
          {/* Text input for search name */}

          {/* Button to save advanced search ends here*/}
          {/*(this.props.location.pathname.indexOf("/directory/facility/view") >
                    -1 && AuthService.canSPView(PermissionFeature.OfficerUpdate, undefined, this.props.match.params.id)) && (
                    <div className="col-xl-5 d-none d-xl-inline-block px-0 text-right">
                    <button
                            type="button"
                            className="btn btn-success"
                            id="OfficerModal"
                            onClick={() => {
                                this.props.showOfficerModal(true);
                            }}
                        //data-toggle="modal"
                        //data-target=".AdvanceFilterModal"
                        //data-backdrop="static"
                        //data-keyboard="false"
                        >
                            Officer Update {(this.props.facilityDashboardState.showPendingRequestAlert && AuthService.canSPUpdate(PermissionFeature.OfficerUpdate, undefined, this.props.match.params.id)) && (<i className="text-warning fas fa-exclamation-triangle"></i>)}
                        </button>
                    </div>
                    )*/}

          {/* <OfficerButton class="col-xl-5 d-none d-xl-inline-block px-0 text-right" /> */}
          <div
            className={`${
              this.props.location.pathname.indexOf("/directory/facility/view") >
                -1 &&
              AuthService.canSPView(
                PermissionFeature.OfficerUpdate,
                undefined,
                this.props.match.params.id
              )
                ? "col-xl-7"
                : "col-12"
            }`}
          >
            {/* List of saved advanced searches */}
            <Dropdown
              onSelect={() => this.setState({ showDropdown: false })}
              onToggle={(isOpen, event) => {
                this.setState({ showDropdown: isOpen });
              }}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Saved Filters
              </Dropdown.Toggle>
              <Dropdown.Menu show={this.state.showDropdown}>
                {/* <Accordion>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        className="panel-heading"
                        as={Button}
                        variant="link"
                        eventKey="0"
                        onClick={this._handleClick}
                      >
                        <i class="fas fa-chevron-circle-down"></i>Saved Searches
                      </Accordion.Toggle>
                      <i className="fas fa-save float-right d-inline-block"></i>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        {this.renderItemType(
                          this.state.ownershipItems,
                          "No saved search item"
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  {this.canViewBucketResult() === true && (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                        >
                          <i class="fas fa-chevron-circle-down"></i>Bucket
                          Searches
                        </Accordion.Toggle>
                        <i className="fab fa-bitbucket float-right"></i>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          {this.renderItemType(
                            this.state.bucketSearchItems,
                            "No bucket search item"
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )}
                  {this.canViewSharedResult() === true && (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="2"
                        >
                          <i class="fas fa-chevron-circle-down"></i>Shared With
                          Me
                        </Accordion.Toggle>
                        <i className="fas fa-share-alt float-right"></i>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <div className="row">
                            <div className="col-md-6">
                              {!this.state.isRefreshingSharedSearch && (
                                <i
                                  class="fas fa-sync-alt float-right refresh-shared-search"
                                  title="Refresh shared list"
                                  onClick={this.refreshSharedSearch}
                                ></i>
                              )}
                              {this.state.isRefreshingSharedSearch && (
                                <i class="fas fa-sync fa-spin float-right"></i>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              {this.state.sharedSearchLoader ? (
                                <div className="text-center pb-2">
                                  <div
                                    role="status"
                                    class="spinner-border spinner-border-sm"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                this.renderItemType(
                                  this.state.sharedSearchItems,
                                  "No shared search item"
                                )
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )}
                </Accordion> */}
                <Card>
                  <Card.Header>
                    <i className="fas fa-save float-right d-inline-block"></i>
                  </Card.Header>
                  <Card.Body>
                    {this.renderItemType(
                      pinned_filters,
                      "No Stared Filters Found"
                    )}
                  </Card.Body>
                  <ul className="list-group">
                    <li>
                      <div>
                        <li className="list-group-item list-group-item-primary text-center c-pointer">
                          <a
                            // to="/directory/filter"
                            id="filtersList"
                            onClick={this._handleClickFilterList}
                            className="active"
                          >
                            Saved Filters List
                          </a>
                        </li>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Dropdown.Menu>
            </Dropdown>
            {/* List of saved advanced searches ends here*/}
          </div>
        </div>
        {/* <EditSavedSearch
          ref={this.editSearchDialog}
          showModal={this.state.showEditSearchDialog}
          setSavedSearchCriteria={this.props.setSavedSearchCriteria}
          updateAndSelect={this.props.updateAndSelect}
          onClose={this.handleClose}
        ></EditSavedSearch> */}
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      filterState: state.advancedFilter,
      savedSearchState: state.saveSearch,
      melstate: state.mailGunEmail,
      facilityDashboardState: state.facilityDashboard,
    }),
    {
      ...actionFilter,
      ...savedSearchAction,
      ...facilityActions,
      ...mailGunEmailActions,
    }
  )(AdvancedSavedSearch)
);
