import actions from "./action";
let IconEvents = {
  DELETE_EVENT: "DELETE_EVENT",
  STAR_ICON: "STAR_ICON",
  EDIT_ICON: "EDIT_ICON",
};
const initialState = {
  IconEvents: IconEvents,
  IconTriggerEvent: "",
  SaveSearchName: "",
  SaveSearchType: "",
  SaveSearchId: "",
  SharedSaveSearchTypeId: 0,
  SharedBucketSearchId: 0,
  SavedSearchDetail: null,
  SearchTitle: "",
  RefreshState: false,
  RefreshSelectId: 0,
  ResetSavedSearch: false,
  ResetSavedSearchComp: false,
  data: "",
  totalCount: 0,
  savedSearchCriteria: null,
  performSavedSearchCriteria: false,
  customizedColumns: "",
  isSavedSearchSelected: false,
  page: 1,
  sizePerPage: 25,
  isBucketSearch: false,
  BucketSearchId: 0,
  allFilters: [],
  filtersDDLClicked: false,
  editFilterRow: {}, ///Saved Search Entry Row
  isOpenFilterModal: false,
  showManageSubscribersModal: false,
  editEmailListRow: false, /// Email List Row Entry
  isAdvancedFilterOpenedInTab: false,
  loggedInUserFilters: [],
  allSystemFilters: [],
  isLoadingAllFilters: false,
  isFiltersLoaded: false,
  allowFilterUpdateMembersTab: false,
};

export default function saveSearch(state = initialState, action) {
  switch (action.type) {
    case actions.SetSaveSearchName:
      return {
        ...state,
        SaveSearchName: action.payload.search_name,
        SaveSearchType: action.payload.search_type_id,
        SaveSearchId: action.payload.id,
      };

    case actions.SET_SHARED_BUCKET_SEARCH_ID:
      return {
        ...state,
        SharedBucketSearchId: action.payload,
      };
    case actions.LOAD_ALL_FILTERS:
      return {
        ...state,
        isLoadingAllFilters: action.payload,
      };
    case actions.SET_SHARED_SEARCH_TYPE:
      return {
        ...state,
        SharedSaveSearchTypeId: action.payload,
      };
    case actions.ResetSaveSearchName:
      return {
        ...state,
        SaveSearchName: action.payload,
      };
    case actions.RESET_SAVED_SEARCH:
      return {
        ...state,
        ResetSavedSearch: true,
        ResetSavedSearchComp: true,
      };
    case actions.STOP_RESET_SAVED_SEARCH:
      return {
        ...state,
        ResetSavedSearch: false,
      };
    case actions.STOP_RESET_SAVED_SEARCH_COMP:
      return {
        ...state,
        ResetSavedSearchComp: false,
      };

    case actions.setSavedSearchDetail:
      return {
        ...state,
        SavedSearchDetail: action.payload,
      };

    case actions.IS_SAVED_SEARCH_SELECTED:
      return {
        ...state,
        isSavedSearchSelected: action.payload,
      };
    case actions.SET_ICON_EVENT:
      return {
        ...state,
        IconTriggerEvent: action.payload,
      };
    case actions.UNSET_ICON_EVENT:
      return {
        ...state,
        IconTriggerEvent: "",
      };
    //case actions.setSavedSearchDetail:
    //    return {
    //        ...state,
    //        data: action.payload.response.data,
    //        totalCount: action.payload.response.totalCount,
    //        savedSearchCriteria: action.payload.response.searchCriteria
    //    };

    case actions.setSavedSearchCriteria:
      return {
        ...state,
        savedSearchCriteria: action.payload,
        performSavedSearchCriteria: action.payload != null,
      };
    case actions.stopSavedSearchCriteria:
      return {
        ...state,
        performSavedSearchCriteria: false,
      };
    case actions.UPDATE_AND_SELECT:
      return {
        ...state,
        RefreshState: action.payload.state,
        RefreshSelectId: action.payload.state ? action.payload.id : 0,
      };

    case actions.UPDATE_AND_REFRESH:
      return {
        ...state,
        RefreshState: action.payload.state,
      };

    case actions.REST_TABLE:
      return {
        ...state,
        totalCount: action.payload.data.totalCount,
        page: action.payload.data.page,
        sizePerPage: action.payload.data.sizePerPage,
      };

    case actions.SET_IS_SAVED_BUCKET:
      return {
        ...state,
        isBucketSearch: action.payload,
      };

    case actions.SET_SAVED_BUCKET_ID:
      return {
        ...state,
        BucketSearchId: action.payload,
      };
    case actions.SET_MEMBERS_FILTERS:
      return {
        ...state,
        allFilters: action.payload,
        loggedInUserFilters: action.payload,
      };
    case actions.SET_ALL_SYS_FILTERS:
      return {
        ...state,
        allFilters: action.payload,
        allSystemFilters: action.payload,
      };
    case actions.SET_CLICK_FILTER_DDL:
      return {
        ...state,
        filtersDDLClicked: action.payload,
      };
    case actions.OPEN_EDIT_FILTER_MODAL:
      return {
        ...state,
        isOpenFilterModal: action.payload.toggleModal,
        editFilterRow: action.payload.row,
      };
    case actions.SHOW_MANAG_SUB_MODAL:
      return {
        ...state,
        showManageSubscribersModal: action.payload.toggleModal,
        editEmailListRow: action.payload.row,
      };
    case actions.IS_AD_OPENDED_IN_TAB:
      return {
        ...state,
        isAdvancedFilterOpenedInTab: action.payload,
      };
    case actions.SET_FILTER_NAME:
      return {
        ...state,
        editFilterRow: {
          ...state.editFilterRow,
          search_name: action.payload,
        },
      };
    case actions.SET_IS_FILTERS_LOADED:
      return {
        ...state,
        isFiltersLoaded: action.payload,
      };
    case actions.SET_ALLOW_FILTER_MEM_TAB_UPDATE:
      return {
        ...state,
        allowFilterUpdateMembersTab: action.payload,
      };
    default:
      return state;
  }
}
