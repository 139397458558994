import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import Areas from "./Areas";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import FacilityModal from "../../Shared/FacilityModal";
import Facilities from "../Facility/Facilities";

const ButtonToNavigate = ({ title, history, color, classes }) => (
    <Button type="button" onClick={() => history.push({ pathname: "/directory", tab: Facilities, name: "Facilities" })} color={color} className={classes}>
        {title}
    </Button>
);

class EditArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.location.type,
            uid: this.props.location.uid,
            code: "",
            description: "",
            facility: "",
            facilityId: 0,
            id: 0,
            getFacilities: false
        };
        this.facilityMethods = () => {
            this.updateFacilityId = args => {
                this.setState({ facility: args.code, facilityid: args.id });
                $(".cancelModal").trigger("click");
            };
            return this;
        };
    }
    handleChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleSubmit = evt => {
        evt.preventDefault();
        evt.target.className += " was-validated";
        if (evt.target.checkValidity() === false) {
            return false;
        }
        const data = {
            Id: this.state.id,
            code: this.state.code,
            description: this.state.description,
            facility: this.state.facility,
            facilityid: this.state.facilityid
        };
        axios
            .post("/api/Areas/SaveArea", data)
            .then(({ data }) => {
                if (data.status && data.status == "Success") {
                    toast.success("Area Saved.");
                    this.props.history.push({ pathname: "/directory", tab: Facilities, name: "Facilities" })
                } else {
                    toast.error(data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    componentDidMount() {
        $(".loading").show();
        if (!this.state.type) {
            this.props.history.push("/directory");
        }
        axios.get("/api/Areas/" + this.state.uid).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({
                    id: data.data.id,
                    code: data.data.code,
                    description: data.data.description,
                    facility: data.data.facility,
                    facilityid: data.data.facilityid
                });
                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                        <div className="page-title-box">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h4 className="page-title"> Add/Edit Area</h4>
                                </div>
                                <div className="col-sm-6 float-right text-right">
                                    {/* <Route
                                        path="/"
                                        render={props => <ButtonToNavigate {...props} color="primary" title="Back to Areas" />}
                                    /> */}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <MDBInput
                                                        value={this.state.code}
                                                        name="code"
                                                        onChange={this.handleChange}
                                                        label="Area Code"
                                                        group
                                                        type="text"
                                                        required>
                                                        <div className="invalid-feedback">Code is required.</div>
                                                    </MDBInput>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <MDBInput
                                                        value={this.state.description}
                                                        name="description"
                                                        onChange={this.handleChange}
                                                        label="Description"
                                                        group
                                                        type="text"
                                                        required>
                                                        <div className="invalid-feedback">Description is required.</div>
                                                    </MDBInput>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <label>Facility</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control disabled"
                                                        required
                                                        onKeyPress={e => {
                                                            e.preventDefault();
                                                            e.target.value = "";
                                                        }}
                                                        value={this.state.facility}
                                                    />
                                                    <div class="input-group-append">
                                                        <button
                                                            class="btn btn-outline-secondary"
                                                            type="button"
                                                            data-toggle="modal"
                                                            onClick={() => {
                                                                this.setState({ getFacilities: true });
                                                            }}
                                                            data-target=".facility-modal">
                                                            <i class="fa fa-search" />
                                                        </button>
                                                    </div>
                                                    <div className="invalid-feedback">Facility is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col float-right text-right">
                                <Route
                                    path="/"
                                    render={props => <ButtonToNavigate {...props} color="danger" title="Cancel" classes="mr-2" />}
                                />
                                <Button to="/roles" color="primary" type="submit">
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
                <FacilityModal getFacilities={this.state.getFacilities} facilityMethods={this.facilityMethods} />
            </AUX>
        );
    }
}

export default EditArea;
