import React, { Component } from "react";
import { connect } from "react-redux";
import actionActivity from "../../store/activitylogging/action";
import ActivityLoggingList from "./ActivityLoggingList.js";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "reactstrap";
import $ from "jquery";
import AuditChangeModal from "./AuditChangeModal";
class ActivityLogging extends Component {
  state = {
    isOpen: false,
  };
  componentDidUpdate() {}
  onToggle = (id) => {
    //debugger
    var myself = this;
    this.props.showActivityLogChange(id, myself);
  };
  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    //debugger;
    const logsResponse = this.props.activityLoggingState.activityLoggingRecords;
    const { targetDiv = "LogslistScroll" } = this.props;
    //const targetDiv = "LogslistScroll";
    const listItems = (
      <div className="parentLogsDiv">
        <ActivityLoggingList
          logsData={logsResponse}
          onToggleModal={this.onToggle}
          isApiResponded={this.props.activityLoggingState.IsLoading}
        />
      </div>
    );

    return (
      <>
        <div id={`${targetDiv}`}>
          <InfiniteScroll
            dataLength={logsResponse.length}
            next={() =>
              this.props.getActicityLoggingByModuleId(
                this.props.activityLoggingState.moduletypeid,
                this.props.recordId,
                0,
                this.props.activityLoggingState.fetch
              )
            }
            hasMore={this.props.activityLoggingState.hasMore}
            loader={<Spinner className="loggingLoading" />}
            scrollableTarget={`${targetDiv}`}
          >
            {listItems}
          </InfiniteScroll>
          {this.props.activityLoggingState.IsLoading &&
            listItems &&
            logsResponse.length == 0 &&
            $(".loggingLoading").length === 0 && (
              <Spinner className="mainLoader" />
            )}
        </div>

        <AuditChangeModal
          modal={this.state.isOpen}
          toggle={this.toggleModal}
          changeLogData={this.props.activityLoggingState.logChangeObject}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({ activityLoggingState: state.activityLogging }),
  {
    ...actionActivity,
  }
)(ActivityLogging);
