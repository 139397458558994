import $ from "jquery";
import AuthService from "../modules/Auth/AuthService";


const aux = props => {
    const membernumber = $("#membernumber").val();
    const loggeduser = AuthService.getProfile();
    if (membernumber && loggeduser.memberData != null) {
        if (membernumber != loggeduser.memberData.membernumber) {
            window.location = window.location
        }
    }
    
    
    return props.children;
};

export default aux;
