import "../../assets/css/TeamStyle.css";
import AUX from "../../hoc/Aux_";
import AuthService from "../Auth/AuthService";
import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import { MDBInput } from "mdbreact";
import axios from "./auth-header";

class VerificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVerficationModal: false,
      primaryNumber: "",
      phoneVerificationCode: "",
      allowtext_newsalerts1: false,
      allowtext_legislative1: false,
      requestId: "",
      verified: false,
      phoneNumber: "",
      verifiedDel: "",
    };
    this.sendCode = this.sendCode.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({
    //   allowtext_legislative: nextProps.Legislative,
    //   allowtext_newsalerts: nextProps.NewsAlert,
    // });
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showVerficationModal: false,
      primaryNumber: "",
    });

    if (!this.state.verified) {
      this.props.resetTextMessageSubscription();
    } else {
      this.props.refreshData(this.props.memberid);
    }
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onShow = () => {
    // debugger;
    this.setState({ requestId: "" });
    // this.showSpinner();
    // let Url = "/api/Member/GetVerifiedDeleted?memberid=" + this.props.memberid;
    // axios.post(Url).then(({ data }) => {
    //   this.hideSpinner();
    //   if (data.status) {
    //     if (data.status.toLowerCase() === "success") {
    //       this.setState({ verifiedDel: data.verified });
    //     } else {
    //       this.hideSpinner();
    //       toast.error(data.message);
    //     }
    //   } else {
    //     toast.error("Information can not be deleted.");
    //   }
    // });
  };

  sendCode() {
    this.showSpinner();
    let Url = `/api/Nexcom/SendVerificationCode?number=${
      this.state.primaryNumber
    }&membernumber=${this.props.memberNumber}&isRegistration=${false}`;
    axios.post(Url).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);
          this.setState({ requestId: data.RequestID });
        } else {
          this.hideSpinner();
          toast.error(data.message);
          this.setState({ requestId: "" });
        }
      } else {
        toast.error(data.message);
      }
    });
  }

  verifyCode() {
    this.showSpinner();
    let Url = `/api/Nexcom/VerifyCode?code=${
      this.state.phoneVerificationCode
    }&membernumber=${this.props.memberNumber}&phoneNumber=${
      this.state.primaryNumber
    }&isRegistration=${false}`;
    axios.post(Url).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);

          this.setState({
            verified: true,
            primaryNumber: "",
            phoneNumber: data.pNumber,
          });
        } else {
          this.hideSpinner();
          toast.error(data.message);
          this.setState({ verified: false });
        }
      } else {
        toast.error(data.message);
      }
    });
  }

  validatePhoneInput = () => {
    //debugger;
    var result = false;
    if (!this.state.primaryNumber) {
      result = true;
    }
    if (!(this.state.primaryNumber.replace(/[^\d.]/g, "").length === 10)) {
      result = true;
    }
    return result;
  };

  onChangeCB = (e) => {
    //   debugger;
    this.setState({
      [e.target.name]: e.currentTarget.checked,
    });
    // console.log(this.state);
  };

  onChangeNew = (e) => {
    // debugger;
    this.setState({
      [e.target.name]: e.currentTarget.checked,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      // phoneNumber: e.target.value,
    });
  };

  onSubmit() {
    this.showSpinner();
    let Url = `/api/Member/SaveMemberAlerts?memberid=${this.props.memberid}&NewsAlert=${this.state.allowtext_newsalerts1}&Legislative=${this.state.allowtext_legislative1}`;
    axios.post(Url).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);
          this.setState({ requestId: "", primaryNumber: "", verified: false });
          this.handleClose();
          this.props.refreshData(this.props.memberid);
        } else {
          this.hideSpinner();
          toast.error(data.message);
          this.setState({ requestId: "" });
        }
      } else {
        toast.error(data.message);
      }
    });
  }

  render() {
    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="TextSubscription"
          size="lg"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                Text Message Subscriptions{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {
                //this.props.allowtextmessages && (
                <div className="onTextSelection pl-1">
                  <div className="col">
                    <small>
                      <p>
                        I hereby consent to receive text message alerts from or
                        on behalf of NATCA at the telephone number provided
                        below. I understand and agree that any text message I
                        receive may be sent by an autodialer, that message and
                        data rates may apply, and that NATCA is not responsible
                        for any fees that are incurred by my wireless carrier.
                      </p>
                    </small>
                  </div>
                  {this.state.verified && (
                    <Fragment>
                      <div className="col">
                        <div className="form-group mb-1 ml-3 mr-4">
                          <span className="card-text custom-checkbox">
                            <input
                              type="checkbox"
                              htmlFor={"allowtext_newsalerts1"}
                              value={this.state.allowtext_newsalerts1}
                              checked={this.state.allowtext_newsalerts1}
                              className="custom-control-input"
                              id={"allowtext_newsalerts1"}
                              name={"allowtext_newsalerts1"}
                              onChange={(e) => this.onChangeNew(e)}
                              //disabled={this.state.verifiedDel}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"allowtext_newsalerts1"}
                            >
                              <h6 className="mt-1">NATCA NEWS Alerts</h6>
                              <small>
                                Important news alerts sent from NATCA
                                leadership. No more than two messages per month.
                              </small>
                            </label>
                          </span>
                        </div>
                        <div className="form-group mb-1">
                          <span className="card-text custom-checkbox ml-3 mr-4">
                            <input
                              type="checkbox"
                              htmlFor={"allowtext_legislative1"}
                              value={this.state.allowtext_legislative1}
                              checked={this.state.allowtext_legislative1}
                              className="custom-control-input"
                              id={"allowtext_legislative1"}
                              name={"allowtext_legislative1"}
                              onChange={(e) => this.onChangeCB(e)}
                              //disabled={this.state.verifiedDel}
                            />

                            <label
                              className="custom-control-label"
                              htmlFor={"allowtext_legislative1"}
                            >
                              <h6 className="mt-1">
                                NATCA Legislative Updates and Alerts
                              </h6>
                              <small>
                                <span>
                                  Legislative news, call-to-action, and
                                  important updates. No more than four messages
                                  per month.
                                </span>
                              </small>
                            </label>
                          </span>
                        </div>
                      </div>

                      <div className="col">
                        {this.state.phoneNumber !== "" && this.state.verified && (
                          <div className="form-group mb-3">
                            <h5>
                              {this.state.phoneNumber} has been successfully
                              verified.
                            </h5>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  )}
                  {this.state.requestId !== "" &&
                    this.state.verified === false && (
                      <div className="col">
                        <h6>Verify Your Mobile Number</h6>
                        <small>
                          A 6-digit code was sent to .Enter it below to verify
                          the phone number to receive the text alerts.
                        </small>
                      </div>
                    )}
                  <div class="d-flex justify-content-center">
                    {this.state.requestId !== "" &&
                      this.state.verified === false && (
                        <Fragment>
                          <div className="col-md-4 col-lg-4 col-xl-4 col-xs-6 col-sm-6 pl-0 pr-1 align-self-center">
                            <div className="form-group mb-2 text-left">
                              <MDBInput
                                name="phoneVerificationCode"
                                value={this.phoneVerificationCode}
                                onChange={this.handleChange}
                                label="Verification Code"
                                group
                                type="text"
                              ></MDBInput>
                            </div>
                          </div>
                        </Fragment>
                      )}
                    {this.state.requestId === "" &&
                      this.state.verified == false && (
                        <div
                          className="col-md-6 col-lg-6 col-xl-4 col-sm-6 col-xs-6 pl-0 pr-1 align-self-center"
                          style={{ marginTop: "0.4rem" }}
                        >
                          <div className="form-group mb-2 text-left">
                            <InputMask
                              placeholder="Mobile Number"
                              name="primaryNumber"
                              containerClass="mb-0"
                              value={this.state.primaryNumber}
                              onChange={this.handleChange}
                              label="Mobile Number"
                              data-type="P"
                              mask="(999) 999-9999"
                              className="form-control"
                              group
                              required
                              type="text"
                            ></InputMask>
                          </div>
                        </div>
                      )}

                    {this.state.requestId === "" &&
                      this.state.verified == false && (
                        <div className="col-md-6 col-lg-6 col-xl-4 col-xs-6 col-sm-6 align-self-center pl-1 pr-0">
                          <button
                            className="btn btn-primary btn-block w-md waves-effect waves-light"
                            type="button"
                            //disabled={this.validatePhoneInput}
                            onClick={this.sendCode}
                          >
                            Send SMS
                          </button>
                        </div>
                      )}
                    {this.state.requestId !== "" &&
                      this.state.verified === false && (
                        <div className="col-md-6 col-lg-6 col-xl-4 col-xs-6 col-sm-6 mt-lg-4 mt-md-4 mt-xl-4 mt-sm-1 mb-lg-2 mb-sm-4 align-self-center text-right pl-1 pr-0">
                          <button
                            className="btn btn-primary btn-block w-md waves-effect waves-light"
                            type="button"
                            //disabled={!this.state.phoneVerificationCode}
                            onClick={this.verifyCode}
                          >
                            Verify
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="col pl-3">
                    {this.state.requestId !== "" &&
                      this.state.verified === false && (
                        <p>
                          <small className="font-weight-bold">
                            Unsubscribe at any time by updating your profile.
                          </small>
                        </p>
                      )}
                  </div>
                </div>
                //)
              }

              <div className="form-group mb-4 row">
                <div className="col-12 m-t-20 mx-auto text-center">
                  {
                    <Fragment>
                      <button
                        className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>

                      {
                        <button
                          className="btn btn-success w-md mc"
                          disabled={!this.state.verified}
                          type="button"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </button>
                      }
                    </Fragment>
                  }
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default VerificationModal;
