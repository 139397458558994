import React, { useState } from "react";
// import CardSplits from "../../../../Payments/CardSplits";
// import MasterPaymentElement from "../../../../Payments/MasterPaymentElement";
import {
  PaymentStatuses,
  RegisWizardSteps,
} from "../../../Helpers/LookUpValues";
import PrebuiltCheckout from "../../../../Payments/PrebuiltCheckout";

const stepThree = (props) => {
  let idx = props.steps.findIndex((i) => i.key == RegisWizardSteps.ThirdStep);
  if (props.currentStep !== idx || props.isFinishedPaymentFlow) {
    // Prop: The current step
    return null;
  }
  const showCardDetails = props.paymentStatusId != PaymentStatuses.Success;
  return (
    <div>
      <div className="row d-flex justify-content-center">
        {/* <CardSplits /> */}
        {/* <MasterPaymentElement
          nextStep={props.nextStep}
          closeRegistrationModal={props.closeRegistrationModal}
          occasionId={props.occasionId}
          registrationId={props.registrationId}
        /> */}
        <PrebuiltCheckout
          occasionId={props.occasionId}
          registrationId={props.registrationId}
          questionsFee={props.questionsFee}
        />
      </div>
    </div>
  );
};
export default stepThree;
