import React, { useState, useEffect } from "react";
//import Stripe from "stripe";
//import axios from "../../modules/Shared/auth-header";
//import authService from "../../modules/Auth/AuthService";
import {
  TextField,
  TextFieldInpGrp,
} from "../Shared/CustomizedForms/FormControls";
import RegistrationsService from "../Registrations/RegistrationsService";
import PaymentService from "./PaymentService";
import _ from "lodash";

//const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// const ProductDisplay = () => (
//   <section>
//     <div className="product">
//       <img
//         src="https://i.imgur.com/EHyR2nP.png"
//         alt="The cover of Stubborn Attachments"
//       />
//       <div className="description">
//         <h3>Stubborn Attachments</h3>
//         <h5>$20.00</h5>
//       </div>
//     </div>
//     <form action="/create-checkout-session" method="POST">
//       <button type="submit">Checkout</button>
//     </form>
//   </section>
// );

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function PrebuiltCheckout(props) {
  const [message, setMessage] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [totalFee, setTotalFee] = useState("");
  const [feeAfterDiscount, setFeeAfterDiscount] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [discountId, setDiscountId] = useState(0);

  // const handleCheckoutOnClient = async (event) => {
  //   // Create a checkout session
  //   const session = await stripe.checkout.sessions.create({
  //     payment_method_types: ["card"],
  //     line_items: [
  //       {
  //         name: "Product 1",
  //         amount: 1000,
  //         currency: "usd",
  //         quantity: 1,
  //       },
  //     ],
  //     success_url: "http://localhost:3000/success",
  //     cancel_url: "http://localhost:3000/cancel",
  //   });

  //   // Redirect the user to the checkout page
  //   window.location.href = session.url;
  // };
  // const handleCheckoutOnServer = async () => {
  //   // Create a checkout session
  //   // const response = await axios.post("/api/Payments/create-checkout-session", {
  //   //   lineItems: [
  //   //     {
  //   //       name: "Product 1",
  //   //       amount: 1000,
  //   //       currency: "usd",
  //   //       quantity: 1,
  //   //     },
  //   //   ],
  //   //   successUrl: "http://localhost:3000/success",
  //   //   cancelUrl: "http://localhost:3000/cancel",
  //   // });

  //   // Redirect the user to the checkout page
  //   //window.location.href = response.data.sessionUrl;
  //   const response = await axios
  //     .post("/api/Payments/create-checkout-session")
  //     .then((response) => {
  //       if (response.status === 303) {
  //         // The resource has been moved temporarily to a new URL
  //         const location = response.headers.get("Location");
  //         // You can redirect the user to the new URL by changing the window location
  //         window.location = location;
  //       } else {
  //         // Handle other status codes as needed
  //         console.log("Status not 303");
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle errors

  //       console.log("Error");
  //     });
  // };
  // const AUTH_TOKEN = authService.getJwtToken();
  const [formData, setFormData] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "discountCode") {
      setDiscountCode(value);
    }
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
    console.log("formData", formData);
  };
  const btnApplyDiscountOnClick = async () => {
    let { occasionId, registrationId } = props;
    let formBody = {
      discountCode,
      occasionId,
      regisId: registrationId,
    };
    var res = await RegistrationsService.ApplyDiscount(formBody);
    if (!_.isEmpty(res)) {
      let { feeAfterDiscount, discountAmount, discountId } = res.result;
      setFeeAfterDiscount(feeAfterDiscount);
      setDiscountAmount(discountAmount);
      setDiscountId(discountId);
      setEditMode(true);
    }
  };
  const btnRemoveDiscountOnClick = async () => {
    let { occasionId } = props;
    let formBody = {
      discountId,
      occasionId,
    };
    var res = await RegistrationsService.RemoveDiscountCode(formBody);
    if (!_.isEmpty(res)) {
      setEditMode(false);
    }
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   // Create a new XMLHttpRequest object
  //   const xhr = new XMLHttpRequest();
  //   // Open the request
  //   xhr.open("POST", "/api/Payments/create-checkout-session");
  //   // Set the authorization header
  //   xhr.setRequestHeader("Authorization", `Bearer ${AUTH_TOKEN} `);

  //   // Set the request content type to application/x-www-form-urlencoded
  //   xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  //   // Send the request with the form data
  //   xhr.send();
  // };
  // const handleSubmit = (event) => {
  //   // Get the form element
  //   const form = document.querySelector(".paymentForm");
  //   const AUTH_TOKEN = authService.getJwtToken();

  //   // Submit the form
  //   // Set the authorization header on the form's submit event
  //   event.target.setRequestHeader("Authorization", `Bearer ${AUTH_TOKEN} `);
  //   form.submit();
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let { registrationId, occasionId } = props;
    let payload = {
      registrationId,
      occasionId,
    };
    var res = await PaymentService.CreateCheckoutSesssion(payload);
    if (!_.isEmpty(res)) {
      window.location = res.result.url;
    }
  };
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    // const query = new URLSearchParams(window.location.search);

    // if (query.get("success")) {
    //   setMessage("Order placed! You will receive an email confirmation.");
    // }

    // if (query.get("canceled")) {
    //   setMessage(
    //     "Order canceled -- continue to shop around and checkout when you're ready."
    //   );
    // }
    let { registrationId, occasionId } = props;

    const onload = async () => {
      var res = await PaymentService.GetAppliedDiscountDetails(
        occasionId,
        registrationId
      );
      if (res != null && res.discountDetails != null) {
        let {
          isDiscountApplied = false,
          discountDetails: { code } = "",
          discountDetails: { type } = 0,
          discountDetails: { id } = 0,
        } = res;
        if (isDiscountApplied) {
          let {
            feeAfterDiscount,
            discountAmount,
          } = res.calculationDetails.result;
          setFeeAfterDiscount(feeAfterDiscount);
          setDiscountAmount(discountAmount);
          setDiscountCode(code);
          setEditMode(true);
          setDiscountId(id);
        } else {
          setEditMode(false);
        }
      }
    };
    onload();
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <>
      {/* <button onClick={handleCheckoutOnServer}>Checkout on Server</button>
      <form
        className="paymentForm"
        //action="/api/Payments/create-checkout-session"
        onSubmit={handleSubmit}
        method="POST"
      >
        <button type="submit">Checkout</button>
      </form> */}
      <form
        className="paymentForm"
        //action="/api/Payments/create-checkout-session"
        //method="POST"
        onSubmit={handleSubmit}
      >
        {/*<input*/}
        {/*  type="hidden"*/}
        {/*  name="token"*/}
        {/*  value={`${AUTH_TOKEN}`}*/}
        {/*  onChange={handleChange}*/}
        {/*/>*/}
        <input
          type="hidden"
          name="registrationId"
          value={props.registrationId}
          onChange={handleChange}
        />

        <input
          type="hidden"
          name="occasionId"
          value={props.occasionId}
          onChange={handleChange}
        />
        <div className="checkoutPage">
          <div className="row">
            <div className="col-xs-12">
              <div className="d-flex justify-content-center heading-checkout">
                <h5>Checkout For Registration </h5>
              </div>
              <div className="discountInput">
                <div className="discountInput">
                  <span>
                    Total Fee: $
                    {props.questionsFee.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                  <br />
                  {editMode && discountAmount ? (
                    <span>
                      Discount Amount: $-
                      {discountAmount.toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  ) : (
                    ""
                  )}
                  <br />
                  {editMode && feeAfterDiscount ? (
                    <span className="font-weight-bold">
                      Fee After Discount: $
                      {feeAfterDiscount.toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="discountInput">
                  <TextFieldInpGrp
                    name="discountCode"
                    value={discountCode}
                    type="text"
                    editMode={editMode}
                    label="Discount Code"
                    placeholder="Enter Discount Code"
                    onChange={handleChange}
                    inpGrpText="Apply"
                    btnApplyDiscountOnClick={btnApplyDiscountOnClick}
                    btnRemoveDiscountOnClick={btnRemoveDiscountOnClick}
                  />
                </div>
                <div className="checkout-btn mt-2">
                  <div className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-success mb-1">
                      Go to checkout
                    </button>
                  </div>
                </div>
              </div>
              <img
                className="img-fluid pl-5 pr-5 paynow"
                src="assets/images/payNow.png"
                alt="Register Now"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
