import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

export default class FacilityPAC extends Component {
  render() {
    return (
      <div className="paddinglt marginAdjust mt-3 mb-3">
        <div className="d-flex justify-content-center">
          <form
            role="search"
            className="form-inline marginRight width100"
            onSubmit={(e) => this.props.handleSearchClick(e)}
          >
            <Col md={2} md={2} md={2} md={2} />

            <Col className="pr-0 pl-0" md={6} lg={6} xl={6} sm={12} xs={12}>
              <div className="input-group width100">
                <input
                  name="pacMemberSearchTxt"
                  type="text"
                  id="pacMemberSearchkeyword"
                  value={this.props.pacMemberSearchTxt}
                  onChange={this.props.searchTextChangeHandler}
                  onKeyUp={this.props.searchTextKeyupHandler}
                  className="form-control pacMemberSrchkeyword"
                  placeholder="Member Search .."
                />
                <div className="input-group-append">
                  <button type="submit" className="pacTopBarSearchBtn">
                    <i className="fa fa-search" /> Search
                  </button>
                </div>
                <small className="text-danger w-100">
                  {this.props.pacMemberSearchTxt_err}
                </small>
              </div>
            </Col>
          </form>
        </div>
        <Row className="pl-2">
          <Col>
            <Row>
              <h6>Amount Per Pay Period</h6>
            </Row>
            <Row>
              {`$${this.props.AmountPerPayPeriod.toLocaleString(
                navigator.language,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}`}
            </Row>
          </Col>
          <Col>
            <Row>
              <h6>Amount Per Cycle</h6>
            </Row>
            <Row>
              {`$${this.props.AmountPerPayPeriod &&
                Math.round(this.props.AmountPerPayPeriod * 52).toLocaleString(
                  navigator.language,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`}
            </Row>
          </Col>
          <Col>
            <Row>
              <h6>PAC %</h6>
            </Row>
            <Row>
              {`${this.props.CountTotalMembers &&
                (
                  (this.props.PACCount / this.props.CountTotalMembers) *
                  100
                ).toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}%`}
            </Row>
          </Col>
          <Col>
            <Row>
              <h6>PAC Avg.</h6>
            </Row>
            <Row>
              {`$${this.props.CountTotalMembers &&
                (
                  this.props.AmountPerPayPeriod / this.props.CountTotalMembers
                ).toLocaleString(navigator.language, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}/pp`}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
