import React, { Component } from "react";
import { MDBInput } from "mdbreact";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import actions from "../../../store/facility/action";
import {
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
} from "reactstrap";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MapsModal from "./Modals/MapsModal";
import { facilityTypeOptions } from "../../Directory/Facility/facilitySelectOptions";
import { Col } from "react-bootstrap";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

export class Maps extends Component {
  state = {
    facilities: this.props.facilityDashboardState.selectedMapIds,
    isMapsModalOpen: false,
    mapType: "",
    mapLat: 0,
    mapLong: 0,
    headerText: "",
    facilityOptions: [],
  };
  onFacilityChange = (event, values) => {
    // this.setState({
    //     facilities: values
    // }, () => {

    //     //console.log(this.state.facilities);
    // });

    const newlyAddedVals = values
      .filter((item) => {
        return item.mapid === 0;
      })
      .map((m) => m.code);

    let dbSelectedVals = this.props.facilityDashboardState.selectedMapIds;

    let alreadySelectedCodes = dbSelectedVals
      .filter((item) => {
        return newlyAddedVals.includes(item.code);
      })
      .map((m) => m.code);

    values = values.filter((item) => {
      return !alreadySelectedCodes.includes(item.code);
    });

    this.props.addSelectedMapfacilities(values);

    let selectedIds = values.map((it) => {
      return it.id;
    });

    this.props.saveSelectedMapsForFacility(selectedIds, this.props.facilityId);
  };
  onTextChange = (value) => {
    this.state.facilityOptions = this.props.facilityDashboardState.facilityOptions.filter(
      (x) =>
        _.toLower(x.description).includes(_.toLower(value || "")) ||
        _.toLower(x.code).includes(_.toLower(value || ""))
    );
    this.setState({ facilityOptions: this.state.facilityOptions });
  };

  removeFacility = (e) => {
    const idx = parseInt(e.target.dataset.id);
    const deleteMapId = e.currentTarget.dataset.mapid;
    var facilityid = e.currentTarget.dataset.selectedfacilityid;
    // this.state.facilities.splice(idx, 1);
    // this.setState({
    //     facilities: this.state.facilities
    // });
    this.props.removeSelectedMapfacilities(idx, deleteMapId, facilityid);
  };
  toggleModal = () => {
    this.setState({ isMapsModalOpen: !this.state.isMapsModalOpen });
  };

  imageClick = (e) => {
    // debugger
    this.setState({ mapType: e.target.name });
    this.setState({ mapLat: e.target.getAttribute("lat") });
    this.setState({ mapLong: e.target.getAttribute("long") });

    this.setState({ headerText: e.target.alt });
    this.toggleModal();
  };

  componentDidMount() {
    // console.log("maps componentDidMount")
    // let preloadedOptions = this.props.facilityDashboardState.selectedMapIds;
    // if (!!preloadedOptions) {
    //     this.setState({
    //         facilities: preloadedOptions
    //     }, () => {
    //         //console.log(this.state.facilities);
    //     });
    // }
    this.state.facilityOptions = this.props.facilityDashboardState.facilityOptions.map(
      (item) => ({
        id: item.id,
        description: item.description,
        code: item.code,
        latitude: item.latitude,
        longitude: item.longitude,
        mapid: item.hasOwnProperty("mapid") ? item.mapid : 0,
        afdfilename: item.afdfilename,
      })
    );
    this.setState({ facilityOptions: this.state.facilityOptions });
  }
  render() {
    //console.log("render maps")
    let preloadedValues = this.props.facilityDashboardState.selectedMapIds.map(
      (item) => ({
        id: item.id,
        description: item.description,
        code: item.code,
        latitude: item.latitude,
        longitude: item.longitude,
        mapid: item.hasOwnProperty("mapid") ? item.mapid : 0,
        afdfilename: item.afdfilename,
      })
    );

    return (
      <div>
        {AuthService.canFacilityAdd(PermissionFeature.FacilityMaps) && (
          <Row style={{ margin: "auto" }}>
            <Col md={8} xl={8} lg={8} className="mt-2">
              <Autocomplete
                multiple
                id="tags-standard"
                filterOptions={(options, { inputValue }) => {
                  console.log(inputValue);
                  return options.filter(
                    (x) =>
                      _.toLower(x.description).includes(
                        _.toLower(inputValue || "")
                      ) ||
                      _.toLower(x.code).includes(_.toLower(inputValue || ""))
                  );
                }}
                options={this.state.facilityOptions}
                ChipProps={{
                  onDelete: null,
                }}
                value={preloadedValues}
                getOptionLabel={(option) => {
                  if (option != undefined) {
                    return option.description;
                  } else {
                    return "";
                  }
                }}
                onChange={(e, value, reason) => {
                  this.onFacilityChange(e, value);
                }}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.code} - {option.description}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Facilities"
                    placeholder="Choose Facilites"
                    fullWidth
                  />
                )}
              />
            </Col>
          </Row>
        )}
        {preloadedValues.length > 0 && (
          <div className="mapsList overflow-auto p-3">
            <div className="border  p-3" style={{ width: "52rem" }}>
              <Row
                className="scrollSettings scrollWidthMapDiv  p-1"
                style={{ margin: "auto" }}
              >
                <Col xl={11} lg={11} md={11} sm={11} xs={11} className="m-0">
                  <Row className="m-0">
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="p-0">
                      <h6>Facility Code</h6>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="p-0">
                      <h6>VFR Sectional</h6>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="p-0">
                      <h6>IFR Low</h6>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="p-0">
                      <h6>IFR High</h6>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="p-0">
                      <h6>Airport Diagram</h6>
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="p-0">
                      <h6 className="ml-2 text-center">AirNav</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {preloadedValues &&
                preloadedValues.map((item, idx) => (
                  <>
                    {/* <form ref={`${idx}-addressForm`} className="form-horizontal m-t-40" onSubmit={this.handleFormSubmit} noValidate> */}
                    <Card className="mb-1 scrollWidthMapDiv text-nowrap">
                      <CardBody className="p-2">
                        <div
                          id={`add${idx}`}
                          key={idx}
                          data-repeater-list="group-a"
                        >
                          <div data-repeater-item className="row">
                            <div
                              className="row col-11 "
                              style={{ margin: "auto" }}
                            >
                              <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                <h6>{item.code}</h6>
                              </Col>
                              <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                <img
                                  src="/assets/icons/maps-icons/vfr-icon.png"
                                  className="cursorPointer"
                                  lat={item.latitude}
                                  long={item.longitude}
                                  id={item.code}
                                  height="30"
                                  alt={`${item.code} VFR CHART`}
                                  name="sectc"
                                  onClick={(e) => this.imageClick(e)}
                                />
                              </Col>
                              <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                <img
                                  src="/assets/icons/maps-icons/ifr-lo-icon.png"
                                  className="cursorPointer"
                                  lat={item.latitude}
                                  long={item.longitude}
                                  id={item.code}
                                  height="30"
                                  alt={`${item.code} IFR-LOW CHART`}
                                  name="ifrlc"
                                  onClick={(e) => this.imageClick(e)}
                                />
                              </Col>
                              <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                <img
                                  src="/assets/icons/maps-icons/ifr-hi-icon.png"
                                  className="cursorPointer"
                                  lat={item.latitude}
                                  long={item.longitude}
                                  id={item.code}
                                  height="30"
                                  alt={`${item.code} IFR-HI CHART`}
                                  name="ehc"
                                  onClick={(e) => this.imageClick(e)}
                                />
                              </Col>
                                            <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                                                {(item.afdfilename != null && item.afdfilename != '' && item.afdfilename && !_.isEmpty(item.afdfilename)) && (
                                                    <a
                                                        target="_new"
                                                        // href={`../../../assets/AirportDiagrams/${item.code}.pdf`}
                                                        href={`https://aeronav.faa.gov/d-tpp/${moment(
                                                            new Date()
                                                        ).format("YYMM")}/${item.afdfilename}`}
                                                    >
                                                        <img
                                                            src="/assets/icons/maps-icons/airport-diagram-icon.png"
                                                            lat={item.latitude}
                                                            long={item.longitude}
                                                            className="cursorPointer"
                                                            id={item.code}
                                                            height="30"
                                                            alt="airport"
                                                        />
                                                    </a>

                                                    )}
                              </Col>
                              <Col
                                xl={2}
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                                className="text-center"
                              >
                                <a
                                  target="_new"
                                  href={`https://www.airnav.com/airport/K${item.code}`}
                                >
                                  <img
                                    src="/assets/icons/maps-icons/airnav-icon.png"
                                    className="cursorPointer"
                                    height="30"
                                    alt="airnav"
                                  />
                                </a>
                              </Col>
                            </div>
                            <div className="col-1 align-self-center">
                              <div data-id={idx}>
                                {AuthService.canFacilityDelete(
                                  PermissionFeature.FacilityMaps
                                ) && (
                                  <button
                                    type="button"
                                    data-mapid={item.mapid}
                                    data-selectedfacilityid={item.id}
                                    data-id={idx}
                                    onClick={this.removeFacility}
                                    className="btn btn-danger btn-sm"
                                  >
                                    <i
                                      data-id={idx}
                                      className="fas fa-trash-alt"
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <Button className={`${idx}-addAddress`} type="submit"> <i className="fas fa-plus"></i> {" "}Add Address</Button> */}
                      </CardBody>
                    </Card>
                    {/* //  </form> */}
                  </>
                ))}
            </div>
          </div>
        )}
        <MapsModal
          headerText={this.state.headerText}
          latitude={this.state.mapLat}
          longitude={this.state.mapLong}
          apiKey={this.props.facilityDashboardState.vfrMapApiKey}
          modal={this.state.isMapsModalOpen}
          type={this.state.mapType}
          toggle={this.toggleModal}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(Maps);
