import { toast } from "react-toastify";
import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import _ from "lodash";

export default {
  AddUpdateQuestions: async (formdata) => {
    let url = "/api/Registrations/AddUpdateQuestions";
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  AddUpdateQuestionAnswers: async (formdata) => {
    $(".loading").show();
    let url = "/api/Registrations/AddUpdateQuestionAnswers";
    var { data } = await axios.post(url, formdata);
    $(".loading").hide();
    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetOpenedEventDetails: async (occasionId) => {
    let url = `/api/Registrations/GetOpenedEventDetails?occasionId=${occasionId}`;
    var { data } = await axios.get(url);

    if (
      data &&
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      var res = data.result || {};
      if (data.result == null) {
        res.regisExists = false;
      } else {
        res.regisExists = true;
      }
      return res;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  SubmitOpenedRegistration: async (formdata) => {
    $(".loading").show();
    let url = "/api/Registrations/SubmitOpenedRegistration";
    var { data } = await axios.post(url, formdata);
    $(".loading").hide();
    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetSystemQuestions: async () => {
    let url = "/api/Registrations/GetSystemQuestions";
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetOccasionRegistrationQuestions: async (occasionId) => {
    let url =
      "/api/Registrations/GetOccasionRegistrationQuestions?occasionId=" +
      occasionId;
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetOpenedRegistrations: async (showClosed, isSuperAdmin, isBasicAdmin) => {
    let url = `/api/Registrations/GetOpenedRegistrations?showClosed=${showClosed}&isSuperAdmin=${isSuperAdmin}&isBasicAdmin=${isBasicAdmin}`;
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetMyRegistrations: async () => {
    let url = "/api/Registrations/GetMyRegistrations";
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetAdminChildTemplates: async () => {
    let url = "/api/Registrations/GetAdminChildTemplates";
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetAdminTemplates: async () => {
    let url = "/api/Registrations/GetAdminTemplates";
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetTemplateAttachments: async (templateId) => {
    let url = `/api/Registrations/GetTemplateAttachments/${templateId}`;
    var { data } = await axios.get(url);

    if (data.status && data.status.toLowerCase() == "success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteTemplateFile: async (filePath) => {
    $(".loading").show();
    let url = `/api/Registrations/DeleteFile?filePath=${filePath}`;
    var { data } = await axios.post(url);

    if (data.status && data.status.toLowerCase() == "success") {
      $(".loading").hide();
      toast.success("File delete successfully.");
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },
  GetDiscountCodes: async () => {
    let url = "/api/Registrations/GetDiscountCodes";
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  DeleteDiscountCode: async (id) => {
    let url = `/api/Registrations/DeleteDiscountCode?discountId=${id}`;
    $(".loading").show();
    var { data } = await axios.get(url);
    $(".loading").hide();

    if (data.message == "Success") {
      toast.success("Record deleted successfully");
      $(".loading").hide();
      return data;
    }

    toast.error(data.message);
    $(".loading").hide();
    return null;
  },

  deleteTemplateService: async (id) => {
    let url = `/api/Registrations/DeleteTemplate?templateId=${id}`;
    $(".loading").show();
    var { data } = await axios.get(url);
    $(".loading").hide();

    if (data.message == "Success") {
      toast.success("Record deleted successfully");
      $(".loading").hide();
      return data;
    }

    toast.error(data.message);
    $(".loading").hide();
    return null;
  },

  GetRegistrationsByMemberId: async (memberId) => {
    let url = `/api/Registrations/GetRegistrationsByMemberId/${memberId}`;
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  GetChildTemplateById: async (templateId) => {
    $(".loading").show();
    let url = `/api/Registrations/GetChildTemplateByIdAsync/${templateId}`;
    var { data } = await axios.get(url);
    $(".loading").hide();

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      //toast.error(data.message);
      return null;
    }
  },

  GetMasterTemplateById: async (templateId) => {
    $(".loading").show();
    let url = `/api/Registrations/GetMasterTemplateByIdAsync/${templateId}`;
    var { data } = await axios.get(url);
    $(".loading").hide();
    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      //toast.error(data.message);
      return null;
    }
  },

  DeleteQuestion: async (id) => {
    let url = "/api/Registrations/DeleteQuestion/";
    $(".loading").show();
    var { data } = await axios.post(url, null, {
      params: { id: id },
    });
    $(".loading").hide();

    if (data.status == "Success") {
      return data;
    } else {
      return null;
    }
  },
  RemoveRegistration: async (id) => {
    $(".loading").show();
    let url = `/api/Registrations/RemoveRegistrationById/${id}`;
    var { data } = await axios.delete(url).catch((ex) => {
      console.log(ex);
      $(".loading").hide();
    });
    if (data.statusCode == 200) {
      $(".loading").hide();
      toast.success(data.message);
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },

  CancelRegistration: async (id) => {
    $(".loading").show();
    let url = "/api/Registrations/CancelRegistration";
    var { data } = await axios.post(url, null, {
      params: {
        registrationId: id == null ? 0 : id,
      },
    });

    if (data.statusCode == 200) {
      $(".loading").hide();
      toast.error("Registration Cancelled.");
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },
  CancelOccasion: async (id) => {
    $(".loading").show();
    let url = "/api/Registrations/CancelChildTemplate";
    var { data } = await axios.post(url, null, {
      params: {
        occasionId: id == null ? 0 : id,
      },
    });

    if (data.statusCode == 200) {
      $(".loading").hide();
      toast.success("Registration Cancelled.");
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },
  PauseRegistrationSteps: async (id) => {
    $(".loading").show();
    let url = "/api/Registrations/PauseRegistrationSteps";
    var { data } = await axios.post(url, null, {
      params: {
        occasionId: id == null ? 0 : id,
      },
    });

    if (data.statusCode == 200) {
      $(".loading").hide();
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },

  GetPendingApprovalRegistrations: async () => {
    $(".loading").show();
    let url = "/api/Registrations/GetPendingApprovalRegistrations";
    var { data } = await axios.get(url);
    if (data.message == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  GetAdminRegisRecords: async () => {
    $(".loading").show();
    let url = "/api/Registrations/GetAdminRegisRecords";
    var { data } = await axios.get(url);
    if (data.message == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  GetOccasionRegQuestionsWithAnswers: async (occasionId, registrationId) => {
    $(".loading").show();
    let url = `/api/Registrations/GetOccasionRegQuestionsWithAnswers?occasionId=${occasionId}&registrationId=${registrationId}`;
    var { data } = await axios.get(url);
    if (data.message == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },

  GetAttendanceChildTemplateRegistrants: async (occasionId) => {
    $(".loading").show();
    let url = `/api/Registrations/GetAttendanceChildTemplateRegistrants?childTemplateId=${occasionId}`;
    var { data } = await axios.get(url);
    if (data.message == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  GetChildTemplateRegistrants: async (occasionId) => {
    $(".loading").show();
    let url = `/api/Registrations/GetChildTemplateRegistrants?childTemplateId=${occasionId}`;
    var { data } = await axios.get(url);
    if (data.message == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  GetChildTemplateInstructors: async (occasionId) => {
    $(".loading").show();
    let url = `/api/Registrations/GetChildTemplateInstructors?childTemplateId=${occasionId}`;
    var { data } = await axios.get(url);
    if (data.message == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  ReviewRequest: async (formdata) => {
    let url = "/api/Registrations/ReviewRequest";
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  AddAdditionalStudent: async (formdata) => {
    let url = "/api/Registrations/AddAdditionalStudents";
    $(".loading").show();
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      $(".loading").hide();
      toast.success("Created Additional Registrant(s).");
      return data.response;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  GetAdditionalStudents: async (childTemplateId) => {
    let url = `/api/Registrations/GetAdditionalStudents?childTemplateId=${childTemplateId}`;
    var { data } = await axios.get(url);

    if (data.status && data.status == "Success") {
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  MarkAttendance: async (formdata) => {
    let url = "/api/Registrations/MarkAttendance";
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetPreRequisites: async () => {
    let url = "/api/Registrations/GetPreRequisites";
    var { data } = await axios.get(url);

    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data.result;
    } else {
      //toast.error(data.message);
      return null;
    }
  },

  GetLastTemplateRecord: async (templateType) => {
    $(".loading").show();
    let url = `/api/Registrations/GetLastTemplateRecord?templateType=${templateType}`;
    var { data } = await axios.get(url);
    $(".loading").hide();
    if (
      data.statusCode &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      return data;
    } else {
      //toast.error(data.message);
      return null;
    }
  },

  SaveMasterTemplate: async (formdata) => {
    $(".loading").show();
    let url = "/api/Registrations/SaveMasterTemplate";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    $(".loading").hide();
    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  SaveChildTemplate: async (formdata) => {
    $(".loading").show();
    let url = "/api/Registrations/SaveChildTemplate";
    var { data } = await axios.post(url, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    $(".loading").hide();
    if (data.status && data.status == "Success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  SaveDiscountCode: async (formdata) => {
    let url = "/api/Registrations/SaveDiscountCode";
    $(".loading").show();
    var { data } = await axios.post(url, formdata);

    if (data.message && data.message == "Success") {
      $(".loading").hide();
      toast.success("Record saved successfully.");
      return data;
    }

    toast.error(data.message);
    $(".loading").hide();
    return null;
  },

  GetDiscountCodeById: async (discountId) => {
    $(".loading").show();
    let url = `/api/Registrations/GetDiscountCodeById?discountId=${discountId}`;
    var { data } = await axios.get(url);

    if (data.message == "Success") {
      $(".loading").hide();
      return data.result;
    }

    toast.error(data.message);
    $(".loading").hide();
    return null;
  },

  LoadMembers: async (searchTerms, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/Permission/Members?searchTerms=` +
          searchTerms +
          `&offset=0&fetch=10`
        : `api/Permission/Members?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetEmailDetails: async (entityId, mailGunEmailType) => {
    if (entityId > 0) {
      let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${entityId}&entityType=${mailGunEmailType}`;
      var { data } = await axios.get(url);
      if (data.status == "success") {
        return data;
      } else {
        toast.error("Something went wrong.");
        return null;
      }
    } else {
      toast.error("Invalid Parameter.");
    }
  },

  CreateFRTEmail: async (
    emailAlias,
    entityId,
    mailGunEmailType,
    mailingListName,
    privilege,
    replyPref,
    emailAuthorizedSenders //FormBody
  ) => {
    if (entityId > 0) {
      $(".loading").show();
      let url = `/api/FacilityRegionEmail/CreateEntityEmail?emailAlias=${emailAlias}&entityId=${entityId}&entityType=${mailGunEmailType}&mailingListName=${mailingListName}&selectedPrivilige=${privilege}&replyPref=${replyPref}`;
        let response = await axios.post(url, emailAuthorizedSenders); 
        let { data } = response;
        $(".loading").hide();
        ///debugger
        if (
          response &&
          data.status != undefined &&
          data.status.trim().toLowerCase() === "success"
        ) {
          toast.success(data.message);
          return data;
        } else {
          toast.error(data.message);
          return null;
        }
       
    }
  },
  GetMailingListSubsribersEmailAddresses: async (mailingListAddress) => {
    $(".loading").show();
    let url = `/api/MailGun/GetMailingListSubsribersEmailAddresses?listAlias=${mailingListAddress}`;
    var { data } = await axios.post(url);
    if (data.success) {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  DiscountFeeCalculation: async (fromBody) => {
    $(".loading").show();
    let url = `/api/Payments/DiscountFeeCalculation`;
    var { data } = await axios.post(url, fromBody);
    if (data.statusCode == 200) {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  ApplyDiscount: async (fromBody) => {
    $(".loading").show();
    let url = `/api/Registrations/ApplyDiscount`;
    var { data } = await axios.post(url, fromBody);
    if (
      data.statusCode == 200 &&
      data.message &&
      data.message.toLowerCase() == "success"
    ) {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  RemoveDiscountCode: async (formBody) => {
    let { discountId = 0, occasionId = 0 } = formBody;
    if (!discountId) {
      toast.warn("Invalid discount id.");
      return;
    }
    if (!occasionId) {
      toast.warn("Invalid occasion id.");
      return;
    }
    $(".loading").show();
    let url = `/api/Registrations/RemoveDiscount?discountId=${discountId}&childTemplateId=${occasionId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      $(".loading").hide();
      toast.success(data.message);
      return data.result;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  CheckEmailAliasValidity: async (alias) => {
    let url = `/api/Email/CheckIsValidEmailAlias?emailAlias=${alias}`;
    var { data } = await axios.get(url);

    if (data.statusCode == 200) {
      return data;
    }

    toast.error(data.message);
    return null;
  },
};
