import React, { Component } from "react";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import facilityActions from "../../../store/facility/action";
function OfficerButton(props) {
  return (
    <>
      {props.location.pathname.indexOf("/directory/facility/view") > -1 &&
        AuthService.canSPView(
          PermissionFeature.OfficerUpdate,
          undefined,
          props.match.params.id
        ) && (
          <div className={props.class}>
            <button
              type="button"
              className="btn btn-success btn-sm"
              id="OfficerModal"
              onClick={(e) => {
                props.showOfficerModal(true);
                e.stopPropagation();
              }}
            //data-toggle="modal"
            //data-target=".AdvanceFilterModal"
            //data-backdrop="static"
            //data-keyboard="false"
            >
              Officer Update{" "}
              {props.facilityDashboardState.showPendingRequestAlert &&
                AuthService.canSPUpdate(
                  PermissionFeature.OfficerUpdate,
                  undefined,
                  props.match.params.id
                ) && (
                  <i className="text-warning fas fa-exclamation-triangle"></i>
                )}
            </button>
          </div>
        )}{" "}
    </>
  );
}
export default withRouter(
  connect(
    (state) => ({
      facilityDashboardState: state.facilityDashboard,
    }),
    {
      ...facilityActions,
    }
  )(OfficerButton)
);
