import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../Shared/auth-header";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import $ from "jquery";
import ConfirmDeleteModal from "../Shared/Modals";
import { toast } from "react-toastify";
const { SearchBar } = Search;

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      page: 1,
      sizePerPage: 10,
      data: [],
      totalCount: "",
      delId: 0,
      keyword: this.props.location ? this.props.location.keyword : ""
    };
    this.handleFormSearch = this.handleFormSearch.bind(this);
    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        axios
          .delete("/api/Role/" + this.state.delId)
          .then(({ data }) => {
            if (data.status && data.status == "Success") {
              toast.success("Role Deleted Successfuly.");
              this.setState({ delId: 0 });
              $(".cancelModal").trigger("click");
              this.getRoles();
            } else {
              toast.error(data.message);
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      };
      return this;
    };
    this.columns = [
      {
        text: "Role Name",
        dataField: "name",
        sort: true
      },
      {
        text: "Description",
        dataField: "description",
        sort: true
      },
      {
        isDummyField: true,
        text: "",
        headerAttrs: {
          width: "80px"
        },
        formatter: (cellContent, row) => {
          return (
            <div className="actionCol">
              {this.state.perms.canEdit && (
                <Link
                  className="btn btn-sm btn-secondary waves-effect waves-light"
                  to={{
                    pathname: "/admin/roles/add-roles",
                    type: "edit",
                    uid: row.id,
                    keyword: $("#keyword").val()
                  }}
                  color="secondary"
                >
                  <i className="ion ion-md-arrow-round-forward" />
                </Link>
              )}
              {this.state.perms.canDelete && (
                <Link
                  className="ml-2 btn btn-sm btn-danger waves-effect waves-light del"
                  onClick={() => {
                    this.setState({ delId: row.id });
                  }}
                  to="#"
                  data-target=".delete-modal"
                  data-toggle="modal"
                  size="sm"
                >
                  <i className="fas fa-trash-alt del" />
                </Link>
              )}
            </div>
          );
        }
      }
    ];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (e.target.className.indexOf("del") < 0) {
          if (this.state.perms.canEdit) {
            this.props.history.push({
              pathname: "/admin/roles/add-roles",
              type: "edit",
              uid: row.id,
              keyword: $("#keyword").val()
            });
          }
        }
      }
    };
    this.propMethods = () => {
      this.updateTable = ({ page, data, totalCount, keyword }) => {
        this.setState({
          data,
          totalCount,
          page,
          keyword
        });
      };
      return this;
    };
    this.RemotePagination = (
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
      showTotal,
      rowEvents
    ) => (
      <div>
        <BootstrapTable
          remote
          keyField="id"
          data={data}
          columns={this.columns}
          pagination={paginationFactory({
            page,
            sizePerPage,
            totalSize,
            showTotal
          })}
          onTableChange={onTableChange}
          rowEvents={rowEvents}
        />
      </div>
    );
  }
  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    let url = `/api/Role/GetRoles`;
    if (type === "sort") {
      this.state.SortOrder = sortField + sortOrder;
      this.setState({ SortOrder: this.state.SortOrder });
    }
    const formData = {
      PageNo: page,
      PageSize: sizePerPage,
      SortOrder: this.state.SortOrder,
      Name: "",
      Keyword: $("#keyword").val(),
      MemberName: "",
      PositionName: "",
      OperatorType: "AND"
    };

    $(".loading").show();

    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          page,
          sizePerPage
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  };
  handleFormSearch(e) {
    e.preventDefault();
    $(".loading").show();
    let url = `/api/Role/GetRoles`;
    const formData = {
      PageNo: 1,
      PageSize: 10,
      SortOrder: this.state.SortOrder,
      Name: "",
      Keyword: $("#keyword").val(),
      MemberName: "",
      PositionName: "",
      OperatorType: "AND"
    };

    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          page: 1
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }
  getRoles() {
   
    $(".loading").show();
    const url = `/api/Role/GetRoles`;
    const formData = {
      PageNo: this.state.page,
      PageSize: this.state.sizePerPage,
      Keyword: this.props.location.keyword,
      Name: "",
      MemberName: "",
      PositionName: "",
      OperatorType: "AND",
      SortOrder: "NameAsc"
    };
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
    console.log(this.state.data);
    console.log(this.state.perms);
  }
  componentDidMount() {
    if (this.props.location.keyword) {
      $("#keyword").val(this.props.location.keyword);
    }
    this.getRoles();
  }
  render() {
    const { data, sizePerPage, page, totalCount } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ToolkitProvider
                keyField="id"
                data={this.state.data}
                columns={this.columns}
                search
              >
                {props => (
                  <Fragment>
                    <div className="page-title-box">
                      <div className="row align-items-center">
                        <div className="col-6 text-center">
                          <h4 className="page-title-search">Roles</h4>
                          <form
                            role="search"
                            className="app-search mt-0"
                            onSubmit={this.handleFormSearch}
                          >
                            <div className="form-group mb-0">
                              <input
                                type="text"
                                id="keyword"
                                className="form-control mb-1 w-100"
                                placeholder="Role Search.."
                              />
                              <button type="submit">
                                <i className="fa fa-search" /> Search
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="col-6 text-left">
                          <div className="row">
                            <div className="col-12 mb-2">
                              <div class="search-btn-panel">
                                <a
                                  className="search-link"
                                  href="javascript:void(0)">
                                  Advanced Filter
                                </a>

                                {this.state.perms.canAdd && (
                                  <React.Fragment>
                                    <span class="search-link-line">|</span>
                                    <Link
                                      className="search-link"
                                      to={{
                                        pathname: "/admin/roles/add-roles",
                                        type: "add",
                                        uid: 0
                                      }}
                                      color="secondary"
                                    >
                                      Add Role
                                    </Link>
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {this.RemotePagination(
                          data,
                          page,
                          sizePerPage,
                          this.handleTableChange,
                          totalCount,
                          showTotal,
                          this.rowEvents
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>
        <ConfirmDeleteModal confirm={this.confirm} />
      </AUX>
    );
  }
}

export default Roles;
