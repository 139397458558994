import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import RemoveActionType from "../Enums/RemoveActionType";
import EmailConfigTables from "../Enums/EmailConfigTables";
export default function MG_ReSubscriberModal(props) {
  const {
    modal,
    toggle,
    confirm,
    email,
    teamName,
    removeActionType,
    confirmModelType,
  } = props;
  let unSubTeam = "'" + teamName + "'";
  let confirmText = "";
  let messageLastPart = "";

  if (removeActionType == RemoveActionType.ReSubscribe) {
    confirmText = "Resubscribe";
  } else if (removeActionType == RemoveActionType.UnBlock) {
    confirmText = "UnBlock";
  }
  if (confirmModelType == EmailConfigTables.AdditionalRecipientsMember) {
    messageLastPart = "Member with Email " + email;
  } else if (confirmModelType == EmailConfigTables.AdditionalRecipientsTeam) {
    messageLastPart = unSubTeam + " Team.";
  } else {
    messageLastPart = email;
  }
  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Confirm {confirmText}</ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          <h5>
            Are you sure you want to {confirmText} {messageLastPart}
          </h5>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary w-md waves-effect waves-light"
          onClick={() => {
            confirm().confirmed();
          }}
        >
          Confirm
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
