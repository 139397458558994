import React, { useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  BlobProvider,
  Canvas,
} from "@react-pdf/renderer";
// import { Document as Dc, Page as Pc } from "react-pdf";

import moment from "moment";
// import { Viewer } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import axios from "../../Shared/auth-header";

// import { FilesArray } from "../../../../build/assets/FETrackerFiles/47";

// Import styles
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import { SampleTest } from "../F&ERequests/SampleTest";

// Create new plugin instance
// const defaultLayoutPluginInstance = defaultLayoutPlugin();

const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2 + 7;
const COLN_PURCHASE_WIDTH = 100 - COL1_WIDTH;
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
      fontWeight: "light",
    },
  ],
});
Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});
const styles = StyleSheet.create({
  HeaderLeft: {
    width: "25%",
    float: "left",
  },
  HeaderRight: {
    width: "50%",
    marginLeft: 455,
    marginTop: -60,
    float: "right",
  },
  headerImageView: {
    marginLeft: 200,
  },

  image: {
    width: 80,
    height: 80,
    float: "left",
  },
  pdfimage: {
    width: 500,
    height: 580,
  },
  floatLeft: {
    float: "left",
  },

  afterDateText: {
    marginTop: 30,
  },
  dateText: {
    marginTop: 50,
  },
  labelText: {
    fontSize: 13,
    marginTop: 6,
    marginLeft: 35,
  },
  labelValue: {
    marginTop: 4,
    fontSize: 11,
    marginLeft: 35,
  },
  equipmentRequestHeader: {
    marginTop: 50,
    fontSize: 16,
    fontWeight: 1000,
    fontFamily: "Lato Bold",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  logoImage: {
    width: 80,
    height: 80,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    fontSize: 12,
    paddingHorizontal: 35,
  },

  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  //////////////////// ######################Table css Design############################///////////////////////////
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: COLN_WIDTH + "%",
    fontFamily: "Lato Italic",
  },
  tableCol: {
    width: COLN_WIDTH + "%",
  },
  purchaseName: {
    marginLeft: 5,
    paddingLeft: 5,
    fontSize: 10,
  },
  purchaseTableColHeader: {
    width: COLN_PURCHASE_WIDTH + "%",
    fontFamily: "Lato Italic",
  },
  descTableColHeader: {
    width: COLN_PURCHASE_WIDTH + "%",
    fontFamily: "Lato Italic",
    paddingLeft: 10,
  },
  equipTypeColHeader: {
    width: COLN_PURCHASE_WIDTH + "%",
  },

  purchaseTableCol: {
    width: COLN_PURCHASE_WIDTH + "%",
  },

  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 13,
    fontWeight: 500,
    fontFamily: "Lato Italic",
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    marginTop: 3,
    fontSize: 10,
    paddingLeft: 5,
  },

  tableCellForBudgetLines: {
    marginTop: 3,
    fontSize: 10,
  },
  tableCol1Header: {
    width: COL1_WIDTH + "%",
  },
  tableHeaderForEQType: {
    width: 80 + "%",
  },
  tableCol1: {
    width: COL1_WIDTH + "%",
  },
  //////////////Table design END////////////////////
});
Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

// const getAllRelatedAttachments = (id) => {
//   axios
//     .get("/api/Equipment/GetFEAttachments", {
//       params: { equipmentid: 47, requestid: 0 },
//     })
//     .then((res) => {
//       console.log(res);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
const currencyFormatter = (cell) => {
  var amount =
    cell &&
    `${cell.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
      currency: "USD",
    })}`;

  return amount;
};
export function PdfDocument(props) {
  debugger;
  // const [numPages, setNumPages] = useState(null);
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }
  console.clear();
  //console.log("Test", FilesArray);
  //getAllRelatedAttachments(props.attachmentid);
  const showYear = props.data.year != null ? true : false;
  const allBudgetLines = props.data.budgetLineFullName.replace(/<br>/g, "\n");
  return (
    <>
      {/* <View>
        <SampleTest />
      </View> */}
      {/* <BlobProvider
        document={`${process.env.REACT_APP_API_BASE_URL}/${props.data.equipmentfiles[0].filepath}`}
      >
        {({ blob, url, loading, error }) => {
          // Do whatever you need with blob here
          return <div>There's something going on on the fly</div>;
        }}
      </BlobProvider> */}
      {/* <Canvas
        paint={
          (`${process.env.REACT_APP_API_BASE_URL}/${props.data.equipmentfiles[2].filepath}`,
          112,
          113)
        }
      ></Canvas> */}
      {/* <Document
        file={`${process.env.REACT_APP_API_BASE_URL}/${props.data.equipmentfiles[2].filepath}`}
      ></Document> */}
      <Document>
        <Page style={styles.body}>
          {/* <View style={styles.HeaderLeft} debug>
            <Image
              style={styles.image}
              src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
            />
          </View> */}
          <View style={[styles.headerImageView]}>
            <Image
              style={styles.image}
              src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
            />
          </View>
          {/* <View style={[styles.headerImageView, { marginTop: -60 }]}>
            <Text style={styles.equipmentRequestHeader}>Equipment Request</Text>
            <Text
              debug={true}
              style={{
                width: "600px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {props.data.budgetLineFullName}
            </Text>
          </View> */}
          <View style={styles.HeaderRight}>
            <Text>{moment(props.data.requestdate).format("MM/DD/YYYY")}</Text>
          </View>
          {/* #######Header END HERE################ */}
          <Text style={styles.equipmentRequestHeader}>
            Equipment Request{`\n`}
          </Text>
          <Text style={styles.tableCellForBudgetLines}>{allBudgetLines}</Text>
          <Text style={styles.equipmentRequestHeader}>Request Details</Text>
          {/* <View
            debug
            style={{
              width: "500px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text style={styles.labelText}>Equipment Type{`\n`} </Text>
            <Text style={styles.labelText}>Year{`\n`} </Text>
            <Text style={styles.labelText}>Description{`\n`} </Text>
          </View>
          <View
            debug
            style={{
              width: "500px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Text style={styles.labelValue}>{props.data.typeName}</Text>
            <Text style={styles.labelValue}>{props.data.typeName}</Text>
            <Text style={styles.labelValue}>{props.data.description}</Text>
          </View> */}
          {/* Table */}
          {/* ##################################Request Detail section start here#########################################*/}
          <View style={styles.table}>
            {/* 1st row for col Header values  */}
            <View style={styles.tableRow}>
              <View
                style={
                  showYear ? styles.equipTypeColHeader : styles.tableColHeader
                }
              >
                <Text
                  style={
                    !showYear
                      ? styles.tableCellHeader
                      : { fontFamily: "Lato Italic" }
                  }
                >
                  Equipment Type
                </Text>
              </View>

              <View
                style={
                  showYear
                    ? styles.purchaseTableColHeader
                    : styles.tableColHeader
                }
              >
                <Text
                  style={
                    showYear ? styles.purchaseTableCol : styles.tableCellHeader
                  }
                >
                  Description
                </Text>
              </View>
              {showYear && (
                <View
                  style={
                    showYear
                      ? styles.purchaseTableColHeader
                      : styles.tableColHeader
                  }
                >
                  <Text
                    style={
                      showYear
                        ? styles.purchaseTableCol
                        : styles.tableCellHeader
                    }
                  >
                    Year
                  </Text>
                </View>
              )}
              {!showYear && (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1Header}>
                    <Text style={styles.tableCellHeader}>
                      Approved Amount (Requested Amount)
                    </Text>
                  </View>
                </View>
              )}
            </View>
            {/* #################2nd row for Col Values of Request Details########### */}
            <View style={styles.tableRow}>
              <View
                style={
                  showYear ? styles.purchaseTableColHeader : styles.tableCol
                }
              >
                <Text style={styles.tableCell}>{props.data.typeName}</Text>
              </View>
              <View
                style={
                  showYear ? styles.purchaseTableColHeader : styles.tableCol
                }
              >
                <Text style={styles.tableCell}>{props.data.description}</Text>
              </View>
              {showYear && (
                <View
                  style={
                    showYear ? styles.purchaseTableColHeader : styles.tableCol
                  }
                >
                  <Text style={styles.tableCell}>{props.data.year}</Text>
                </View>
              )}
              {!showYear && (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={[styles.tableCell, { paddingLeft: 3 }]}>
                      {`${currencyFormatter(
                        props.data.approvedamount
                      )}    (${currencyFormatter(props.data.requestedamount)})`}
                    </Text>
                  </View>
                </View>
              )}
            </View>
            {/* ###############Show Approved/requested amount in a separate row################## */}
            {showYear && (
              <>
                {/* <View style={styles.tableRow}>
                  <View style={styles.tableCol1Header}>
                    <Text style={styles.tableCellHeader}>
                      Approved Amount (Requested Amount)        
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}>
                      {`${props.data.approvedamount}          (${props.data.requestedamount})`}
                    </Text>
                  </View>
                </View> */}

                <View>
                  <Text style={{ fontFamily: "Lato Italic" }}>
                    Approved Amount (Requested Amount)
                  </Text>
                  <Text style={{ marginTop: 3, fontSize: 10, paddingLeft: 3 }}>
                    {`${currencyFormatter(
                      props.data.approvedamount
                    )}    (${currencyFormatter(props.data.requestedamount)})`}
                  </Text>
                </View>
              </>
            )}
          </View>
          {/* ##################################Request Detail section end here#########################################*/}
          <Text style={styles.equipmentRequestHeader}>Purchase Details</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.purchaseTableColHeader}>
                <Text style={styles.tableCellHeader}>Purchaser</Text>
              </View>

              <View style={styles.purchaseTableColHeader}>
                <Text style={styles.tableCellHeader}>Date</Text>
              </View>

              <View style={styles.purchaseTableColHeader}>
                <Text style={styles.tableCellHeader}>Cost</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.purchaseTableCol}>
                <Text style={styles.purchaseName}>
                  {props.data.purchaserName}
                </Text>
              </View>
              <View style={styles.purchaseTableCol}>
                <Text style={styles.tableCell}>
                  {moment(props.data.purchasedate).format("MM/DD/YYYY")}
                </Text>
              </View>

              <View style={styles.purchaseTableCol}>
                <Text style={styles.tableCell}>
                  {currencyFormatter(props.data.cost)}
                </Text>
              </View>
            </View>

            {/* Second Row of Purchase Details  */}
            <View style={styles.tableRow}>
              <View style={styles.purchaseTableColHeader}>
                <Text style={styles.tableCellHeader}>Sticker #</Text>
              </View>

              <View style={styles.purchaseTableColHeader}>
                <Text style={styles.tableCellHeader}>Warranty</Text>
              </View>

              <View style={styles.purchaseTableColHeader}>
                <Text style={styles.tableCellHeader}>Serial #</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.purchaseTableCol}>
                <Text style={styles.tableCell}>
                  {!props.data.stickernumber ? "N/A" : props.data.stickernumber}
                </Text>
              </View>
              <View style={styles.purchaseTableCol}>
                <Text style={styles.tableCell}>
                  {!props.data.warrantyinformation
                    ? "N/A"
                    : props.data.warrantyinformation}
                </Text>
              </View>
              <View style={styles.purchaseTableCol}>
                <Text style={styles.tableCell}>
                  {!props.data.serialnumber ? "N/A" : props.data.serialnumber}
                </Text>
              </View>
            </View>
          </View>
          {/* Second table for Purchase Details */}
          {/* <Text style={styles.afterDateText}>{props.data.approvedamount}</Text>
          <Text style={styles.afterSalution}>{props.data.requestedamount}</Text>
          <Text style={styles.afterSalution}>
            {moment(props.data.purchasedate).format("MM/DD/YYYY")}
          </Text>
          <Text style={styles.afterSalution}>{props.data.cost}</Text>
          <Text style={styles.afterDateText}>{props.data.stickernumber}</Text>
          <Text style={styles.afterSalution}>
            {props.data.warrantyinformation}
          </Text>
          <Text style={styles.afterSalution}>{props.data.serialnumber}</Text>
          <Text>Description : {props.data.description}</Text>
          <Text style={styles.Dear}>Dear {props.data.purchaserName},</Text> */}
          {/* {props.data.equipmentfiles && (
            <View>
              <Image
                style={styles.pdfimage}
                src={`${process.env.REACT_APP_API_BASE_URL}/${props.data.equipmentfiles[0].filepath}`}
                ///src="http://localhost:45874/assets/FETrackerFiles/46/099c0bb5-1108-421b-81d5-2838b1f111e1_darkcolor.png`}
              />
            </View>
          )} */}
          {props.data.relatedAttachments &&
            props.data.relatedAttachments.map((item, idx) => (
              <View key={idx + 1}>
                <Image
                  key={idx}
                  style={styles.pdfimage}
                  src={`${process.env.REACT_APP_API_BASE_URL}/${item}`}
                />
              </View>
            ))}

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
      {/* <Dc
        file={`${process.env.REACT_APP_API_BASE_URL}/assets/FETrackerFiles\\47\\b4766acd-2d18-41f2-888a-b6c252010278_payment-receipt.pdf`}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Pc key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Dc> */}
      {/* <Document file={`${props.data.equipmentfiles[0].filepath}`}></Document> */}

      {/* <Viewer fileUrl="/assets/OSHAFiles/29/07333114-88e9-4775-ac55-94b2493ba37f_test (6).pdf" /> */}
    </>
  );
}
