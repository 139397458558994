import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import Services from "../PACService";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { Field } from "redux-form";
import PACTrackerAction from "../../../store/pacTracker/action";
import "moment/locale/en-ca";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { FilePond, registerPlugin, setOptions, File } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);

const requestType = {
  New: "New",
  Draft: "Draft",
  Returned: "Returned",
  Submitted: "Submitted",
  Supported: "Supported",
  Approved: "Approved",
  Withdrawn: "Withdrawn",
  Denied: "Denied",
  Closed: "Closed",
  CheckIssued: "CheckIssued",
};

class RequestDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalHeading: "NewRequest",
      reviewRequestId: -1,
      requestDetail: {},
      acceptedFileTypes: [
        "image/png",
        "image/jpeg",
        "application/pdf",
        "image/gif",
      ],
      submissionObj: {
        Name: "",
      },
      acceptedFileTypes: ["image/png", "image/jpeg"],
      approvedamount_err: "",
      formateAmount: null,
      reviewcomments: "",
      status: "",
      checkNumber: "",
      checkNumberChanged: false,
      checkNumber_err: "",
      checkRemarks: "",
      isrecallfromsubmitted: false,
      isrecallfromsupported: false,
      eventNotes: "",
      checkDate: new Date(),
      checkDateString: new Date().toString(),
      files: [],
    };
    this.openModal = this.openModal.bind(this);

    this.cancelModal = this.cancelModal.bind(this);
    this.getRequestDataModal = this.getRequestDataModal.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.onCheckDateChange = this.onCheckDateChange.bind(this);
    this.valueChangeHandler = this.valueChangeHandler.bind(this);
    this.setSupportStatus = this.setSupportStatus.bind(this);
    this.setDenyStatus = this.setDenyStatus.bind(this);
    this.setReturnStatus = this.setReturnStatus.bind(this);
    this.setCloseStatus = this.setCloseStatus.bind(this);
    this.setWithdrawStatus = this.setWithdrawStatus.bind(this);
    this.setApproveStatus = this.setApproveStatus.bind(this);
    this.customValidation = this.customValidation.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.setDraftStatus = this.setDraftStatus.bind(this);
  }

  handleInitFileUpload() {
    //console.log("handleInitFileUpload instance has initialised", this.pond2);
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (!this.customValidation()) {
      return false;
    }
    var formdata = new FormData();
    const data = {
      id: this.state.reviewRequestId,
      approvedamount: this.state.requestDetail.approvedamount,
      status: this.state.status,
      reviewcomments: this.state.reviewcomments,
      checkNumber:
        this.state.checkNumberChanged == true ? this.state.checkNumber : null,
      checkDate:
        this.state.checkNumberChanged == true ? this.state.checkDate : null,
      checkRemarks: this.state.checkRemarks,
      eventNotes: this.state.eventNotes,
      isrecallfromsupported: this.state.isrecallfromsupported,
      isrecallfromsubmitted: this.state.isrecallfromsubmitted,
    };
    for (var i = 0; i < this.state.files.length; i++) {
      formdata.append("File" + i, this.state.files[i]);
    }

    formdata.append("data", JSON.stringify(data));

    this.UpdateRequestObject(formdata);
  };

  async UpdateRequestObject(formdata) {
    $(".loading").show();

    let response = await Services.UpdateRequest(formdata);
    if (!_.isEmpty(response)) {
      this.props.getMyRequests();
      toast.success("Request updated successfully");
      this.cancelModal();
    }

    $(".loading").hide();
  }

  onFileUpload(files) {
    this.state.files = files
      .filter((el) => {
        return el.status !== 8;
      })
      .map((fileItem) => fileItem.file);
    this.setState({ files: this.state.files });
  }

  resetModal() {
    this.setState({
      reviewRequestId: -1,
      formateAmount: "",
      status: "",
      reviewcomments: "",
      approvedamount: "",
      approvedamount_err: "",
      checkNumber: "",
      checkNumber_err: "",
      checkNumberChanged: false,
      checkRemarks: "",
      eventNotes: "",
      checkDate: new Date(),
      checkDateString: new Date().toString(),
      files: [],
    });
  }

  handleChangeAmount(e) {
    if (e.target.value != "") {
      var expression = /^[\d.,]+$/;
      if (!expression.test(e.target.value)) {
        this.state.approvedamount_err =
          "Invalid amount entered. Only numbers are allowed.";
        //return false;
      } else {
        this.state.approvedamount_err = "";
      }
    } else {
      this.state.approvedamount_err = "";
    }
    var num_parts = e.target.value
      .toString()
      .split(",")
      .join("")
      .split(".");
    var amount = num_parts.join(".");
    if (num_parts[0].length > 10) {
      this.setState({
        approvedamount_err: this.state.approvedamount_err,
      });
      return false;
    }
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.state.requestDetail.approvedamount = amount;
    this.setState({
      formateAmount:
        num_parts.length > 2
          ? num_parts[0] + "." + num_parts[1]
          : num_parts.join("."),
      requestDetail: this.state.requestDetail,
    });
  }

  getRequestDataModal = async () => {
    $(".loading").show();
    let data = await Services.GetReviewRequestDetail(
      this.state.reviewRequestId
    );
    if (!_.isEmpty(data)) {
      this.setState({
        requestDetail: !_.isEmpty(data.data) ? data.data : [],
        status: !_.isEmpty(data.data) ? data.data.status : "",
      });
      this.openModal();
    }
    $(".loading").hide();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.pacState.openRequestDetailModal) {
      if (
        this.state.reviewRequestId !=
        nextProps.pacState.RequestDetail.reviewRequestId
      ) {
        this.state.reviewRequestId =
          nextProps.pacState.RequestDetail.reviewRequestId;
        this.setState({ reviewRequestId: this.state.reviewRequestId });
        this.getRequestDataModal();
      } else {
        this.openModal();
      }
    }
  }
  componentDidMount() {
    if (this.state.reviewRequestId > 0) {
      this.getRequestDataModal();
    }
  }
  openModal() {
    window.$("#RequestDetailModal").modal("show");
    this.props.closeRequestDetailModal();
  }

  cancelModal() {
    this.resetModal();
    window.$("#RequestDetailModal").modal("hide");
  }

  onCheckDateChange = (jsDate, dateString) => {
    this.setState({
      checkDate: jsDate,
      checkDateString: dateString,
    });
  };

  valueChangeHandler = (evt) => {
    if (
      (evt.target.name === "checkNumber" && evt.target.value.length < 12) ||
      evt.target.name !== "checkNumber"
    ) {
      this.setState({
        [evt.target.name]: evt.target.value,
        [evt.target.name + "Changed"]: true,
        status: requestType.CheckIssued,
      });
    } else {
      return false;
    }
  };

  formate_amount = (n) => {
    if (n != undefined) {
      var num_parts = n.toString().split(".");
      num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    }
  };

  setSupportStatus() {
    this.setState({ status: requestType.Supported });
  }

  setApproveStatus() {
    this.setState({ status: requestType.Approved });
  }

  setDraftStatus(isrecall, recallType) {
    if (isrecall) {
      if (recallType == "Submitted") {
        this.setState({
          status: requestType.Draft,
          isrecallfromsubmitted: true,
        });
      } else {
        this.setState({
          status: requestType.Draft,
          isrecallfromsupported: true,
        });
      }
    }
  }

  setReturnStatus() {
    this.setState({ status: requestType.Returned });
  }

  setDenyStatus() {
    this.setState({ status: requestType.Denied });
  }

  setCloseStatus() {
    this.setState({ status: requestType.Closed });
  }

  setWithdrawStatus() {
    this.setState({ status: requestType.Withdrawn });
  }

  customValidation() {
    var isValid = true;

    if (!_.isEmpty(this.state.approvedamount_err)) {
      isValid = false;
    }

    return isValid;
  }

  render() {
    let { requestDetail } = this.state;
    return (
      <AUX>
        <div
          class="modal fade"
          id="RequestDetailModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby={this.state.ModalHeading + "Title"}
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5 class="modal-title text-uppercase text-white mt-0">
                  {AuthService.canView(PermissionFeature.CandidateName) &&
                    requestDetail.candidateName +
                    " - " +
                    requestDetail.eventName}
                </h5>

                <button
                  type="button"
                  class="close text-white"
                  onClick={this.cancelModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                className="needs-validation"
                id="reviewPacRequestForm"
                onSubmit={this.handleSubmit}
                noValidate
              >
                <div className="modal-body pac-form">
                  <div className="candidate-information pb-3">
                    <h5 className="mt-0 text-uppercase">
                      Candidate Information:
                      <hr className="mt-2" />
                    </h5>
                    <div className="mx-0 row">
                      <div className="col-md-6 pl-1 mb-3">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>Campaign POC:</small>
                        </span>
                        <b className="h5 text-secondary d-block">
                          {AuthService.canView(
                            PermissionFeature.CampaignPOCNamePhone
                          ) && requestDetail.poc + " " + requestDetail.phone}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div className="event-detail pb-3">
                    <h5 className="mt-0 text-uppercase">
                      Event Details:
                      <hr className="mt-2" />
                    </h5>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="pl-1 col-12 mb-3">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small>Event Name/Description:</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {AuthService.canView(PermissionFeature.EventDate) &&
                              requestDetail.eventName +
                              " - " +
                              (moment(requestDetail.eventDate).isValid()
                                ? moment(requestDetail.eventDate).format(
                                  "MM/DD/YYYY"
                                )
                                : "")}
                          </b>
                        </div>
                        <div className="pl-1 col-12 mb-3">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small>NATCAvists Attending:</small>
                          </span>
                          {AuthService.canView(
                            PermissionFeature.NATCAvistsAttendingEvent
                          ) &&
                            !_.isEmpty(requestDetail.attendees) &&
                            requestDetail.attendees.split(",").map((item) => (
                              <span className="h5">
                                <span class="badge px-3 py-2 m-1 badge-pill text-info bg-transparent border border-info">
                                  {item}
                                </span>
                              </span>
                            ))}
                        </div>
                        <div className="pl-1 col-12 mb-3">
                          {AuthService.canView(PermissionFeature.Flyer) &&
                            requestDetail.isflyer != true && (
                              <Fragment>
                                <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                  <small>Flyer Justification:</small>
                                </span>
                                <span className="font-italic">
                                  <b className="d-block">
                                    {requestDetail.flyerJustification}
                                  </b>
                                </span>
                              </Fragment>
                            )}
                          {AuthService.canView(PermissionFeature.Flyer) &&
                            requestDetail.isflyer == true && (
                              <Fragment>
                                <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                  <small>Flyer:</small>
                                </span>
                                <a
                                  target="blank"
                                  href={requestDetail.flyerFilePath}
                                >
                                  <span className="fas fa-file" />{" "}
                                  {requestDetail.flyerFileName}
                                </a>
                              </Fragment>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {(requestDetail.status == "Approved" ||
                          requestDetail.status == "CheckIssued") &&
                          this.props.pacState.RequestDetail.type ==
                          this.props.pacState.ReviewRequestType.Requester && (
                            <Fragment>
                              {AuthService.canUpdate(
                                PermissionFeature.Pictures
                              ) && (
                                  <div className="pl-1 col-12 mb-3">
                                    <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                      <small>Pictures:</small>
                                    </span>
                                    <FilePond
                                      ref={(ref) => (this.pond2 = ref)}
                                      files={this.state.files}
                                      allowMultiple={true}
                                      maxFiles={10}
                                      allowFileTypeValidation={true}
                                      allowImageExifOrientation={true}
                                      allowImagePreview={true}
                                      allowImageCrop={true}
                                      allowFileEncode={true}
                                      acceptedFileTypes={
                                        this.state.acceptedFileTypes
                                      }
                                      fileValidateTypeDetectType={(
                                        source,
                                        type
                                      ) =>
                                        new Promise((resolve, reject) => {
                                          let rejectedExtensions = [
                                            "ade",
                                            "adp",
                                            "apk",
                                            "bat",
                                            "chm",
                                            "cmd",
                                            "com",
                                            "cpl",
                                            "dll",
                                            "dmg",
                                            "exe",
                                            "hta",
                                            "ins",
                                            "isp",
                                            "jar",
                                            "js",
                                            "jse",
                                            "lib",
                                            "lnk",
                                            "mde",
                                            "msc",
                                            "msi",
                                            "msp",
                                            "mst",
                                            "nsh",
                                            "pif",
                                            "scr",
                                            "sct",
                                            "shb",
                                            "sys",
                                            "vb",
                                            "vbe",
                                            "vbs",
                                            "vxd",
                                            "wsc",
                                            "wsf",
                                            "wsh",
                                            "cab",
                                          ];
                                          // If the file extension is not in our rejected list.
                                          if (
                                            !rejectedExtensions.some((ext) =>
                                              source.name.endsWith("." + ext)
                                            )
                                          ) {
                                            resolve(type);
                                          } else {
                                            // Otherwise it is rejected.
                                            toast.error(
                                              "File type is unsupported!"
                                            );
                                            //reject(new Error('Files contains unsupported format!'));
                                            reject(type);
                                          }
                                        })
                                      }
                                      oninit={() => this.handleInitFileUpload()}
                                      onupdatefiles={(fileItems) => {
                                        // Set currently active file objects to this.state
                                        this.onFileUpload(fileItems);
                                      }}
                                    />
                                  </div>
                                )}
                              {AuthService.canUpdate(
                                PermissionFeature.Notes
                              ) && (
                                  <div className="pl-1 col-12 mb-3">
                                    <div class="md-form form-group mb-0">
                                      <textarea
                                        name="eventNotes"
                                        containerClass="mb-0"
                                        className="w-100 border"
                                        value={this.state.eventNotes}
                                        rows="5"
                                        onChange={this.valueChangeHandler}
                                      />
                                      <label
                                        class="active"
                                        data-error=""
                                        data-success=""
                                        id=""
                                        aria-labelledby=""
                                      >
                                        Event Notes:
                                      </label>
                                      {/*<span class="text-danger">{item.number_err}</span>*/}
                                    </div>
                                  </div>
                                )}
                            </Fragment>
                          )}
                        {requestDetail.status == "Approved" &&
                          this.props.pacState.RequestDetail.type ==
                          this.props.pacState.ReviewRequestType.Reviewer && (
                            <Fragment>
                              {AuthService.canView(
                                PermissionFeature.Pictures
                              ) && (
                                  <div className="pl-1 col-12 mb-3">
                                    <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                      <small>Pictures:</small>
                                    </span>
                                    <a
                                      target="blank"
                                      href={requestDetail.pictureLink}
                                    >
                                      <span className="fas fa-file" />{" "}
                                      {requestDetail.pictureLink}
                                    </a>
                                  </div>
                                )}

                              {AuthService.canView(PermissionFeature.Notes) && (
                                <div className="pl-1 col-12 mb-3">
                                  <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                    <small>Event Notes:</small>
                                  </span>
                                  <b className="d-block">
                                    {requestDetail.eventNotes}
                                  </b>
                                </div>
                              )}
                            </Fragment>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="event-detail pb-3">
                    <h5 className="mt-0 text-uppercase">
                      Check Details:
                      <hr className="mt-2" />
                    </h5>
                    <div className="row">
                      <div className="h3 mb-3 mb-lg-2 col-6 col-lg-3">
                        <div>
                          {AuthService.canView(
                            PermissionFeature.AmountApproved
                          ) && (
                              <span className="align-middle">
                                {requestDetail.approvedamount &&
                                  this.formate_amount(
                                    requestDetail.approvedamount
                                  )}
                              </span>
                            )}
                        </div>
                        <div className="mt-2">
                          {AuthService.canView(
                            PermissionFeature.AmountRequested
                          ) && (
                              <>
                                <span class="mb-1 h6 font-weight-normal text-secondary d-block">
                                  <small>Requested</small>
                                </span>
                                <span className="text-secondary">
                                  {requestDetail.requestedamount &&
                                    this.formate_amount(
                                      requestDetail.requestedamount
                                    )}
                                </span>
                              </>
                            )}
                        </div>
                      </div>
                      <div className="font-italic mb-3 mb-lg-2 col-6 col-lg-3">
                        <b className="d-block">
                          {AuthService.canView(PermissionFeature.PayableTo) &&
                            requestDetail.payableTo}
                        </b>
                        <b className="d-block">
                          {AuthService.canView(
                            PermissionFeature.MailingAddress
                          ) && requestDetail.campaignAddress}
                        </b>
                      </div>
                      {(requestDetail.status == "CheckIssued" ||
                        requestDetail.status == "Closed") && (
                          <Fragment>
                            {AuthService.canView(
                              PermissionFeature.CheckNumber
                            ) && (
                                <div className="mb-3 mb-lg-2 col-6 col-lg-3">
                                  <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                    <small>Check Number:</small>
                                  </span>
                                  <b className="h6 text-secondary d-block">
                                    {requestDetail.checkNumber}
                                  </b>
                                </div>
                              )}

                            {AuthService.canView(PermissionFeature.CheckDate) && (
                              <div className="mb-3 mb-lg-2 col-6 col-lg-3">
                                <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                  <small>Date Processed:</small>
                                </span>
                                <b className="h6 text-secondary d-block">
                                  {moment(requestDetail.checkDate).isValid()
                                    ? moment(requestDetail.checkDate).format(
                                      "MM/DD/YYYY"
                                    )
                                    : ""}
                                </b>
                              </div>
                            )}

                            {AuthService.canView(
                              PermissionFeature.CheckRemarks
                            ) && (
                                <div className="event-detail col-sm-6 offset-lg-6 pb-3">
                                  <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                    <small>Check Remarks:</small>
                                  </span>
                                  <b className="h6 text-secondary d-block">
                                    {requestDetail.checkRemarks}
                                  </b>
                                </div>
                              )}
                          </Fragment>
                        )}
                    </div>
                    <div className="row">
                      {requestDetail.status == "Approved" &&
                        AuthService.canUpdate(PermissionFeature.PABReview) && (
                          <Fragment>
                            {AuthService.canUpdate(
                              PermissionFeature.CheckNumber
                            ) && (
                                <div className="mb-3 mb-lg-2 col-sm-6 col-lg-4">
                                  <MDBInput
                                    name="checkNumber"
                                    containerClass="mt-3 mb-0"
                                    value={this.state.checkNumber}
                                    label="Check Number:"
                                    hint="Provide Check Number"
                                    type="number"
                                    onChange={this.valueChangeHandler}
                                    maxlength="11"
                                  />
                                  <span className="text-danger">
                                    {this.state.checkNumber_err}
                                  </span>
                                </div>
                              )}

                            {AuthService.canUpdate(
                              PermissionFeature.CheckDate
                            ) && (
                                <div className="mb-3 mb-lg-2 col-sm-6 col-lg-4">
                                  <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                    <small>Check Date:</small>
                                  </span>
                                  <DatePickerInput
                                    readonly="readonly"
                                    onChange={this.onCheckDateChange}
                                    value={this.state.checkDate}
                                  />
                                  <span className="text-danger">
                                    {this.state.checkDate_err}
                                  </span>
                                </div>
                              )}

                            <br />
                            {AuthService.canUpdate(
                              PermissionFeature.CheckRemarks
                            ) && (
                                <div className="event-detail col-sm-6 col-lg-4 pb-3">
                                  <div className="pl-1">
                                    <div class="md-form form-group mb-0">
                                      <textarea
                                        name="checkRemarks"
                                        containerClass="mb-0"
                                        className="w-100 border"
                                        value={this.state.checkRemarks}
                                        onChange={this.valueChangeHandler}
                                        rows="5"
                                      />
                                      <label
                                        class="active"
                                        data-error=""
                                        data-success=""
                                        id=""
                                        aria-labelledby=""
                                      >
                                        Check Remarks:
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </Fragment>
                        )}
                    </div>
                  </div>
                  {!_.isEmpty(requestDetail.history) &&
                    AuthService.canView(PermissionFeature.RequestHistory) && (
                      <div className="event-detail pb-3">
                        <h5 className="mt-0 text-uppercase">
                          History:
                          <hr className="mt-2" />
                        </h5>
                        <div className="history historyheight">
                          {!_.isEmpty(requestDetail.history) &&
                            requestDetail.history.map((item) => (
                              <div className="row py-1">
                                <div className="px-3">
                                  {moment(item.actionDate).isValid()
                                    ? moment(item.actionDate).format(
                                      "MMMM DD, YYYY @ HHMM"
                                    )
                                    : ""}
                                </div>
                                <div className="px-3">
                                  <div>
                                    Request {item.actionType} by{" "}
                                    {item.memberName}
                                  </div>
                                  {!_.isEmpty(item.comments) && (
                                    <div>
                                      <b>Comments:</b> {item.comments}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}

                  {!_.isEmpty(requestDetail.history) &&
                    AuthService.canView(PermissionFeature.RequestHistory) && (
                      <div className="event-detail pb-3">
                        <h5 className="mt-0 text-uppercase">
                          Additional Information:
                          <hr className="mt-2" />
                        </h5>
                        <div className="additionalInformation historyheight">
                          {!_.isEmpty(requestDetail.additionalinfo) &&
                            <div>
                              {requestDetail.additionalinfo}
                            </div>
                          }
                        </div>
                      </div>
                    )}

                  {AuthService.canUpdate(PermissionFeature.PABReview) &&
                    AuthService.canUpdate(PermissionFeature.AmountApproved) &&
                    this.props.pacState.RequestDetail.type ==
                    this.props.pacState.ReviewRequestType.Reviewer &&
                    (requestDetail.status == "Submitted" ||
                      requestDetail.status == "Supported") && (
                      <div className="col-md-6 mb-3">
                        <MDBInput
                          containerClass="mb-0"
                          className="font-weight-bold"
                          name="amountrequested"
                          label="Amount Approved"
                          hint="Provide the approved amount of this request"
                          icon="dollar-sign"
                          value={this.state.formateAmount}
                          maxlength="16"
                          onChange={this.handleChangeAmount}
                          //disabled={canEdit("pacdonation")}
                          group
                          type="text"
                        />
                        <span className="text-danger">
                          {this.state.approvedamount_err}
                        </span>
                      </div>
                    )}
                  {AuthService.canUpdate(PermissionFeature.Comments) &&
                    this.props.pacState.RequestDetail.type ==
                    this.props.pacState.ReviewRequestType.Reviewer &&
                    ((requestDetail.status == "Submitted" &&
                      AuthService.canUpdate(
                        PermissionFeature.ReviewRequests
                      )) ||
                      (requestDetail.status == "Supported" &&
                        AuthService.canUpdate(
                          PermissionFeature.PABReview
                        ))) && (
                      <div className="event-detail pb-3">
                        <h5 className="mt-0 text-uppercase">
                          Comment Box:
                          <hr className="mt-2" />
                        </h5>
                        <div className="pl-1">
                          <div class="md-form form-group mb-0">
                            <textarea
                              name="reviewcomments"
                              containerClass="mb-0"
                              className="w-100 border"
                              value={this.state.reviewcomments}
                              rows="5"
                              onChange={this.valueChangeHandler}
                            />
                            {/*<span class="text-danger">{item.number_err}</span>*/}
                          </div>
                        </div>
                      </div>
                    )}
                  <hr />
                  <div className="row pt-3">
                    <div className="col-sm-12">
                      <div className="text-right">
                        <button
                          className="btn btn-dark btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                          onClick={this.cancelModal}
                          type="button"
                        >
                          Cancel
                        </button>
                        <Fragment>
                          {((this.props.pacState.RequestDetail.type ==
                            this.props.pacState.ReviewRequestType.Reviewer &&
                            AuthService.canUpdate(
                              PermissionFeature.PABReview
                            ) &&
                            requestDetail.status == "Approved") ||
                            (this.props.pacState.RequestDetail.type ==
                              this.props.pacState.ReviewRequestType.Requester &&
                              requestDetail.status == "CheckIssued")) && (
                              <>
                                <button
                                  className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                  type="submit"
                                  onClick={this.setCloseStatus}
                                >
                                  Save and Close
                                </button>
                              </>
                            )}
                        </Fragment>

                        {(AuthService.canUpdate(
                          PermissionFeature.ReviewRequests
                        ) ||
                          AuthService.canUpdate(PermissionFeature.PABReview)) &&
                          this.props.pacState.RequestDetail.type ==
                          this.props.pacState.ReviewRequestType.Reviewer && (
                            <Fragment>
                              {requestDetail.status == "Submitted" && (
                                <>
                                  <button
                                    className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                    onClick={() => {
                                      this.setDraftStatus(true, "Submitted");
                                    }}
                                    type="submit"
                                  >
                                    Recall
                                  </button>
                                  <button
                                    className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                    type="submit"
                                    onClick={this.setSupportStatus}
                                  >
                                    Support
                                  </button>
                                  <button
                                    className="btn btn-info btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                    type="submit"
                                    onClick={this.setReturnStatus}
                                  >
                                    Return
                                  </button>
                                </>
                              )}
                              {AuthService.canUpdate(
                                PermissionFeature.PABReview
                              ) &&
                                requestDetail.status == "Supported" && (
                                  <>
                                    <button
                                      className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                      onClick={() => {
                                        this.setDraftStatus(true, "Supported");
                                      }}
                                      type="submit"
                                    >
                                      Recall
                                    </button>
                                    <button
                                      className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                      type="submit"
                                      onClick={this.setApproveStatus}
                                    >
                                      Approve
                                    </button>
                                    <button
                                      className="btn btn-danger btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                      type="submit"
                                      onClick={this.setDenyStatus}
                                    >
                                      Deny
                                    </button>
                                    <button
                                      className="btn btn-warning btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                      onClick={this.setWithdrawStatus}
                                      type="submit"
                                    >
                                      Withdraw
                                    </button>
                                  </>
                                )}
                              {AuthService.canUpdate(
                                PermissionFeature.PABReview
                              ) &&
                                requestDetail.status == "CheckIssued" && (
                                  <button
                                    className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                                    type="submit"
                                    onClick={this.setCloseStatus}
                                  >
                                    Close
                                  </button>
                                )}
                            </Fragment>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default connect(
  (state) => ({ pacState: state.pacTracker }),
  {
    ...PACTrackerAction,
  }
)(RequestDetailModal);
