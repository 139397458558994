const actions = {
  OPEN_CANDIDATE_MODAL: "OPEN_CANDIDATE_MODAL",
  CLOSE_CANDIDATE_MODAL: "CLOSE_CANDIDATE_MODAL",
  OPEN_REQUEST_DETAIL_MODAL: "OPEN_REQUEST_DETAIL_MODAL",
  CLOSE_REQUEST_DETAIL_MODAL: "CLOSE_REQUEST_DETAIL_MODAL",
  OPEN_REQUEST_MODAL: "OPEN_REQUEST_MODAL",
  CLOSE_REQUEST_MODAL: "CLOSE_REQUEST_MODAL",
  OPEN_ADD_CANDIDATE_MODAL: "OPEN_ADD_CANDIDATE_MODAL",
  CLOSE_ADD_CANDIDATE_MODAL: "CLOSE_ADD_CANDIDATE_MODAL",
  openCandidateDetailModal: (Id) => (dispatch) => {
    dispatch({
      type: actions.OPEN_CANDIDATE_MODAL,
      payload: Id,
    });
  },
  closeCandidateDetailModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_CANDIDATE_MODAL,
    });
  },
  openRequestDetailModal: (detail) => (dispatch) => {
    dispatch({
      type: actions.OPEN_REQUEST_DETAIL_MODAL,
      payload: detail,
    });
  },
  closeRequestDetailModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_REQUEST_DETAIL_MODAL,
    });
  },
  openRequestModal: (detail) => (dispatch) => {
    dispatch({
      type: actions.OPEN_REQUEST_MODAL,
      payload: detail,
    });
  },
  openAddCandidateModal: (detail) => (dispatch) => {
    dispatch({
      type: actions.OPEN_ADD_CANDIDATE_MODAL,
      payload: detail,
    });
  },
  closeRequestModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_REQUEST_MODAL,
    });
  },
  closeAddCandidateModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_ADD_CANDIDATE_MODAL,
    });
  },
};

export default actions;
