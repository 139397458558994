import React, { Component, Fragment } from "react";
import {
  Button,
  Row,
  Col,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import OSHAReportModal from "./OSHAReportModal";
import { connect } from "react-redux";
import axios from "../../Shared/auth-header";
import actions from "../../../store/facility/action";
import $ from "jquery";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import OSHAFilesModal from "./Modals/OSHAFiles";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import TableCustomTotal from "../../Shared/TableCustomTotal";
export class OSHA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      page: 1,
      sizePerPage: 5,
      totalCount: 0,
      rows: [],
      showPagination: false,
      hiddenRowKeys: [],
      popoverOpen: false,
      toggle: false,
      oshaTier: "",
      activeTabView: "1",
      oshaOwner: "",
      showCompleted: false,
      alphanumericRegex: /^[a-zA-Z0-9., ]*$/,
      oshaModalState: {
        id: 0,
        description: "",
        addInfo: "",
        isNotified: false,
        status: "Pending",
        dateIdentified: new Date(),
        dateIdentifiedString: new Date().toString(),
        filesUpload: [],
        files: [],
        modalHeader: "Add OSHA",
        currentState: "Add",
      },
    };
    this.columns = [
      {
        text: "Report Title",
        dataField: "description",
        sort: true,
        formatter: (cell, row) => {
          return cell;
        },
      },
      {
        text: "Status",
        // headerAttrs: {
        //     width: "180px"
        // },
        dataField: "status",
        sort: true,
        formatter: (cell, row) => {
          return cell;
        },
      },
      {
        isDummyField: true,
        text: "",
        headerAttrs: {
          width: "80px",
        },
        formatter: (cellContent, row) => {
          return (
            <div className="actionCol">
              {AuthService.canFacilityUpdate(PermissionFeature.OshaReports) && (
                <span
                  className={`${this.showNested} edit`}
                  size="sm"
                  color="secondary"
                  handleEditInvoke
                  onClick={() => {
                    this.handleEditInvoke(row.id);
                  }}
                >
                  <i className="fas fa-pencil-alt edit-pencil-icon" />
                </span>
              )}
              {AuthService.canFacilityView(PermissionFeature.OshaReports) && (
                //row.permissions.canDelete
                <a
                  className={`del ml-2 ${this.showNested}`}
                  onClick={() => {
                    this.handleFileListClick(row.id);
                  }}
                  //  to="#"
                  //   data-target=".delete-modal"
                  //   data-toggle="modal"
                  // size="sm"
                >
                  <i className="fas fa-images del" />
                </a>
              )}
            </div>
          );
        },
      },
    ];

    this.handleFileListClick = (id) => {
      this.setState({ delId: id });

      this.props.getOSHAAttachments(this, id);
    };
    this.handleModalChange = (evt) => {
      var property = { ...this.state.oshaModalState };

      if (evt.target.id === "isNotified")
        property[evt.target.name] = evt.target.checked;
      else if (
        ((evt.target.id === "description" || evt.target.id === "addInfo") &&
          this.state.alphanumericRegex.test(evt.target.value)) ||
        (evt.target.id !== "description" && evt.target.id !== "addInfo")
      )
        property[evt.target.name] = evt.target.value;

      this.setState({
        oshaModalState: property,
      });
    };
    this.onModalDateChange = (jsDate, dateString) => {
      var property = { ...this.state.oshaModalState };
      property.dateIdentified = jsDate;
      property.dateIdentifiedString = dateString;
      this.setState({
        oshaModalState: property,
      });
    };
    this.updateModalFiles = (fileItems) => {
      // debugger
      var property = { ...this.state.oshaModalState };
      property.files = fileItems
        .filter((el) => {
          return el.status !== 8;
        })
        .map((fileItem) => fileItem.file);
      this.setState({
        oshaModalState: property,
      });
    };
    this.onModalClose = () => {
      //  debugger
      this.setState({
        oshaModalState: {
          id: 0,
          description: "",
          addInfo: "",
          isNotified: false,
          status: "Pending",
          dateIdentified: new Date(),
          dateIdentifiedString: new Date().toString(),
          files: [],
          modalHeader: "Add OSHA",
          currentState: "Add",
        },
      });
    };
    this.getOSHA = () => {
      let url = "";
      if (this.props && this.props.facilityId) {
        url = "/api/OSHA/" + this.props.facilityId;
      } else {
        url = "/api/OSHA/" + this.facilityId;
      }
      $(".loading").show();

      axios.get(url).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.setState({
            oshaTier: data.data.oshaTier,
            oshaOwner: data.data.facilitySponsor,
          });
          if (data.data.reportList) {
            if (data.data.reportList.length > this.state.sizePerPage) {
              this.setState({
                rows: data.data.reportList,
                showPagination: true,
              });
            } else {
              this.setState({
                rows: data.data.reportList,
                showPagination: false,
              });
            }
          }
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      });
    };

    this.getOSHAByStatus = (showCompleted) => {
      let url = "";
      if (this.props && this.props.facilityId) {
        url = `/api/OSHA/${this.props.facilityId}/${showCompleted}`;
      } else {
        url = `/api/OSHA/${this.facilityId}/${showCompleted}`;
      }
      $(".loading").show();

      axios.get(url).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          if (data.data.reportList) {
            if (data.data.reportList.length > this.state.sizePerPage) {
              this.setState({
                rows: data.data.reportList,
                showPagination: true,
              });
            } else {
              this.setState({
                rows: data.data.reportList,
                showPagination: false,
              });
            }
          }
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      });
    };
  }
  toggleModal = () => {
    this.setState({ activeTabView: 1 });
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  togglePopover = () => {
    let popOverStatus = !this.state.popoverOpen;
    this.setState({
      popoverOpen: popOverStatus,
    });
    if (popOverStatus) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
  };

  handleEditInvoke = (id) => {
    var property = { ...this.state.oshaModalState };
    this.state.rows.map((rowItem) => {
      if (rowItem.id === id) {
        property.id = rowItem.id;
        property.description = rowItem.description;
        property.addInfo = rowItem.additionalInfo;
        property.dateIdentified = new Date(rowItem.dateIdentified);
        property.dateIdentifiedString = rowItem.dateIdentified.toString();
        property.status = rowItem.status;
        property.isNotified = rowItem.managementNotified;
        property.currentState = "Edit";
        property.modalHeader = "Edit OSHA";
      }
    });
    this.setState({
      oshaModalState: property,
    });
    $("#editModalOpenButton").click();
  };

  handleChange = (evt) => {
    if (evt.target.id == "showCompleted") {
      this.setState({ [evt.target.name]: evt.target.checked });
    }
  };

  handleOutsideClick = (evt) => {
    let self = this;

    if (
      evt.target.control &&
      evt.target.control.id &&
      evt.target.control.id === "showCompleted"
    ) {
      let showCompleted = !evt.target.control.checked;
      this.setState({ [evt.target.control.name]: showCompleted });
      self.getOSHAByStatus(showCompleted);
    }
    this.togglePopover();
  };

  componentDidMount() {
    this.getOSHA();
  }

  render() {
    return (
      <>
        <OSHAReportModal
          getOSHA={this.getOSHA}
          facilityId={this.props.facilityId}
          oshaState={this.state.oshaModalState}
          onModalClose={this.onModalClose}
          handleChangeParent={this.handleModalChange}
          onDateChangeParent={this.onModalDateChange}
          updateFilesParent={this.updateModalFiles}
        />
        <button
          type="button"
          className="btn btn-primary"
          id="editModalOpenButton"
          data-toggle="modal"
          data-target="#AddOSHAModal"
          style={{ display: "none" }}
        ></button>
        {AuthService.canFacilityAdd(PermissionFeature.FacilityOsha) && (
          <div>
            <Popover
              className="pl-1 pr-1"
              placement="left"
              isOpen={this.state.popoverOpen}
              target="popoverMenu"
              toggle={this.togglePopover}
            >
              <PopoverBody>
                {AuthService.canFacilityAdd(PermissionFeature.OshaReports) && (
                  <p
                    className="text-success m-0 hover"
                    data-toggle="modal"
                    data-target="#AddOSHAModal"
                  >
                    {" "}
                    <i className="fas fa-plus-circle"></i> Create New Report{" "}
                  </p>
                )}
                <hr className="mt-1 mb-2" />
                {AuthService.canFacilityUpdate(
                  PermissionFeature.OshaReports
                ) && (
                  <div className="display-inline-flex">
                    <p className="mr-4 mb-0">Show Completed</p>
                    <input
                      type="checkbox"
                      id="showCompleted"
                      name="showCompleted"
                      switch="bool"
                      onChange={this.handleChange}
                      checked={this.state.showCompleted}
                    />
                    <label
                      htmlFor="showCompleted"
                      className="mb-0"
                      data-on-label="Yes"
                      data-off-label="No"
                    />
                  </div>
                )}
              </PopoverBody>
            </Popover>
          </div>
        )}
        <Row className="m-3">
          {AuthService.canFacilityView(PermissionFeature.OshaOshaTier) &&
            this.state.oshaTier && (
              <Col md={2} sm={5}>
                <Row>
                  <h5 className="text-primary mb-0 reqColor"> OSHA Tier </h5>
                </Row>
                <Row>
                  <h6 className="mb-0">{this.state.oshaTier}</h6>
                </Row>
              </Col>
            )}
          {AuthService.canFacilityView(PermissionFeature.OshaOshaOwner) &&
            this.state.oshaOwner && (
              <Col>
                <Row>
                  <h5 className="text-primary mb-0"> Facility Sponsor </h5>
                </Row>
                <Row>
                  <h6 className="mb-0">{this.state.oshaOwner}</h6>
                </Row>
              </Col>
            )}
          {AuthService.canFacilityView(PermissionFeature.FacilityOsha) && (
            <Col sm={{ size: 6, offset: 6 }} className="popOverCol">
              {/* <Row className="pt-5"></Row> */}
              <Row className="float-right">
                <i
                  class="fas fa-ellipsis-h hover font-20px"
                  id="popoverMenu"
                ></i>
              </Row>
            </Col>
          )}
        </Row>

        {AuthService.canFacilityView(PermissionFeature.OshaReports) && (
          <Row className="m-3">
            <Col className="p-0">
              <ToolkitProvider
                keyField="id"
                data={this.state.rows}
                columns={this.columns}
                search
              >
                {(props) => (
                  <Fragment>
                    <div className="row">
                      <div className="col-12">
                        {!this.state.showPagination && (
                          <BootstrapTable
                            hover
                            {...props.baseProps}
                            rowEvents={this.rowEvents}
                            id="reportsListing"
                          />
                        )}
                        {this.state.showPagination && (
                          <BootstrapTable
                            hover
                            pagination={paginationFactory({
                              paginationTotalRenderer: TableCustomTotal,
                              showTotal: true,
                              sizePerPage: this.state.sizePerPage,
                            })}
                            {...props.baseProps}
                            rowEvents={this.rowEvents}
                            id="reportsListing"
                          />
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        )}

        <OSHAFilesModal
          facilityId={this.props.facilityId}
          modal={this.state.isModalOpen}
          activeTabView={this.activeTabView}
          toggle={this.toggleModal}
        />
      </>
    );
  }
}
export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(OSHA);
