import React from "react";
import { Modal } from "react-bootstrap";
import { Collapse, Card, Button } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RegistrationsAction from "../../../../../store/registrations/action";
import RegistrationsService from "../../../RegistrationsService";
import _ from "lodash";
import RegistrantsMembersList from "../../RegistrantsMembersList";
import $ from "jquery";
import ReviewRequestQuestions from "./ReviewRequestQuestions";
import {
  CompositionModalMode,
  RegisTableType,
  RegistrationStatusesOptions,
  ReviewRequestActions,
} from "../../../Helpers/LookUpValues";
import { toast } from "react-toastify";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "../../../registration.css";
import AddAdditionalStudentModal from "./AddAdditionalStudentModal";

const filter = createFilterOptions();

class EventAttendanceModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      detailsAccOpen: true,
      registraintsAccOpen: true,
      registrants: [],
      filteredRegistrants: [],
      instructors: [],
      selectedRegistrationIds: [],
      showQuestionsModal: false,
      compositionModalMode: "",
      composeModalEmails: [],
      composeModalPhoneNos: [],
      showConfigEmailMembersModal: false,
      selectedInstrIds: [],
      regisStatusesOptions: RegistrationStatusesOptions,
      showAddStudentModal: false,
    };
  }
  onShow = async () => {
    const { props } = this;

    const { result: data = {} } =
      (await RegistrationsService.GetAttendanceChildTemplateRegistrants(
        props.clickedChildTemplateId
      )) || {};
    debugger;
    //console.log(data);
    if (!_.isEmpty(data)) {
      await this.setState({
        registrants: data,
        isloading: false,
        filteredRegistrants: data,
      });
    } else {
      this.setState({
        registrants: [],
        filteredRegistrants: [],
        isloading: false,
      });
    }
    await this.props.getAdditionalStudents(props.clickedChildTemplateId);
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  /// Gets the Questions with choosen Answers
  getRegistrantQuestions = async (row) => {
    await RegistrationsService.GetOccasionRegQuestionsWithAnswers(
      this.props.clickedChildTemplateId, //occasionId,
      row.registrationId
    )
      .then((data) => {
        if (data.statusCode == 200) {
          let questionsResponse = data.result;
          this.props.setRegistrationQuestions(questionsResponse);
          this.setState({
            showQuestionsModal: true,
          });
          this.hideSpinner();
        } else {
          this.hideSpinner();
        }
      })
      .catch((error) => {
        this.hideSpinner();
      });
  };
  handleSubmitModal = async () => {
    let formData = {
      childTemplateId: this.props.clickedChildTemplateId,
      registrationIds: this.state.selectedRegistrationIds,
    };
    const data = await RegistrationsService.MarkAttendance(formData);
    if (!_.isEmpty(data) && data.status == "Success") {
      toast.success("Attendance Marked Successfully.");
      this.props.onClose(); // Hide the Modal
    } else {
      toast.error("Something went wrong");
    }
  };
  setSelectedRegistrationIds = (selectedIds) => {
    this.setState({
      selectedRegistrationIds: selectedIds,
    });
  };
  setSelectedInstrIds = (selectedIds) => {
    this.setState({
      selectedInstrIds: selectedIds,
    });
  };
  ///Change Handler for Status
  selectStatus = async (value, reason, details) => {
    let { regisStatusesOptions, registrants = [] } = this.state;
    var allChecked = regisStatusesOptions.filter((a) =>
      value.some((curVal) => a.status == curVal.status)
    );
    if (!_.isEmpty(allChecked)) {
      regisStatusesOptions.forEach((element) => {
        if (allChecked.some((s) => s.status == element.status)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    } else {
      regisStatusesOptions.forEach((element) => {
        element.checked = false;
      });
    }
    if (reason == "remove-option") {
      let { option } = details || {};
      this.setState({
        regisStatusesOptions: regisStatusesOptions.map((item) => {
          if (item.id == option.id) {
            item.checked = false;
            return item;
          } else {
            return item;
          }
        }),
      });
      this.filterRegistrants(allChecked);
    } else if (reason == "clear") {
      this.setState({
        regisStatusesOptions: regisStatusesOptions.map((m) => ({
          ...m,
          [m.checked]: true,
        })),
      });
      this.filterRegistrants(allChecked);
    } else {
      this.setState({ regisStatusesOptions });
      //Filter Registrants
      this.filterRegistrants(allChecked);
    }
  };
  filterRegistrants = (allChecked) => {
    let { registrants = [] } = this.state;
    if (allChecked.length > 0) {
      let filteredRecords = registrants.filter((reg) => {
        if (
          allChecked.some(
            (checkedRegis) => checkedRegis.id == reg.registrationStatusId
          )
        ) {
          return reg;
        }
      });
      this.setState({ filteredRegistrants: filteredRecords });
    } else {
      this.setState({ filteredRegistrants: registrants });
    }
  };
  onClose_ASM = () => {
    this.setState({
      showAddStudentModal: false,
    });
  };
  toggleModal_ASM = () => {
    this.setState({
      showAddStudentModal: !this.state.showAddStudentModal,
    });
  };
  deleteAddtlRegistrant = async (registrantId) => {
    let myself = this;
    const { clickedChildTemplateId } = myself.props;

    await RegistrationsService.RemoveRegistration(registrantId)
      .then((data) => {
        if (!_.isEmpty(data)) {
          if (data.statusCode == 200) {
            myself.props.getAdditionalStudents(clickedChildTemplateId);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    const { props } = this;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { clickedChildTemplateRow } = this.props;
    let { regisStatusesOptions } = this.state;

    const selectedStatuses =
      regisStatusesOptions.length > 0
        ? regisStatusesOptions.filter((f) => f.checked)
        : [];
    const { additionalRegistrantMembers } = this.props.registrationsState;
    return (
      <>
        <Modal
          className="modal fade team-style"
          id="reviewRegRequestsModal"
          size="xl"
          backdrop="static"
          show={props.showModal}
          centered
          onShow={this.onShow}
          onHide={props.onClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title className=" w-100 modal-title font-italic">
              <div className="feModalHeader align-items-center text-uppercase d-flex">
                <div className="col-sm-6 mt-2">
                  <h5 className="text-white mt-0">Class Details</h5>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body reviewRegRequestBody">
            {/* ///First Accordion of Registration Details/// */}
            <div className="row">
              <div className="col">
                <Card className="mb-1">
                  <h6
                    className="card-header bg-primary text-white mb-3 mt-0 cursorPointer"
                    onClick={() => {
                      this.setState({
                        detailsAccOpen: !this.state.detailsAccOpen,
                      });
                    }}
                  >
                    <i class="fa fa-address-card" aria-hidden="true" /> Event
                    Details
                  </h6>

                  <Collapse isOpen={this.state.detailsAccOpen}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small>Title</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.title}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Dates</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.dates}
                          </b>

                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Window</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.registrationWindow}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Location</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.location}
                          </b>
                        </div>
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Category</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.category}
                          </b>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Card>
              </div>
            </div>
            {/* // Filter Dropdown && Actions Icons for EMail Config,SMS// */}
            <div className="regisMems">
              {/* ///Second Accordion Members (Registrants + Instructors) */}
              <div className="row">
                <div className="col-12">
                  <Card className="mb-1">
                    <div className="card-header bg-primary mb-3 mt-0 text-white pb-1">
                      <div className="row">
                        <div className="col-6">
                          <h6
                            className="cursorPointer mt-1"
                            onClick={() => {
                              this.setState({
                                registraintsAccOpen: !this.state
                                  .registraintsAccOpen,
                              });
                            }}
                          >
                            {/* Members which have requested for an Occasion Registration */}
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Attendance
                          </h6>
                        </div>
                        <div className="col-6">
                          <button
                            type="button"
                            className="btn btn-primary float-right"
                            onClick={this.toggleModal_ASM}
                          >
                            Add Student
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* ***************************************Call for Members List(Registrants) Component************************************* */}
                    <Collapse isOpen={this.state.registraintsAccOpen}>
                      {/* Table for Default Registrants */}
                      <div className="col-12">
                        <RegistrantsMembersList
                          listData={this.state.filteredRegistrants}
                          isloading={this.state.isloading}
                          getRegistrantQuestions={this.getRegistrantQuestions}
                          setSelectedRegistrationIds={
                            this.setSelectedRegistrationIds
                          }
                          registrantTableType={RegisTableType.MarkAttendance}
                          caption={"Registrants"}
                        />
                      </div>
                      {/* Table for Additional Registrants */}
                      <div className="col-12">
                        <RegistrantsMembersList
                          listData={additionalRegistrantMembers}
                          isloading={this.state.isloading}
                          getRegistrantQuestions={this.getRegistrantQuestions}
                          setSelectedRegistrationIds={
                            this.setSelectedRegistrationIds
                          }
                          registrantTableType={
                            RegisTableType.AdditionalRegistrants
                          }
                          deleteRegistration={this.deleteAddtlRegistrant}
                          caption={"Additional Registrants"}
                        />
                      </div>
                    </Collapse>
                  </Card>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="card-footer text-center border-top-0">
            <button
              type="button"
              disabled={
                this.state.selectedRegistrationIds &&
                this.state.selectedRegistrationIds.length == 0
              }
              onClick={() => this.handleSubmitModal()}
              className="btn btn-success pr-1"
            >
              Start Class
            </button>
            <button
              type="button"
              className="btn btn-primary ml-1 cancelModal"
              onClick={props.onClose}
            >
              Close
            </button>
          </div>
        </Modal>
        <AddAdditionalStudentModal
          childTemplateId={clickedChildTemplateRow.id}
          showModal={this.state.showAddStudentModal}
          onClose={this.onClose_ASM}
          toggleModal={this.toggleAttendance_Modal}
          deleteAddtlRecipient={this.deleteAddtlRegistrant}
        />
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...RegistrationsAction,
    }
  )(EventAttendanceModal)
);
