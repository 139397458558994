import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import AssignMemberModal from './AssignMemberModal';
import TableCustomTotal from "../../Shared/TableCustomTotal";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Services from "../PermissionService";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
const positionsColumns = [
    {
        text: "Member Name",
        dataField: "memberName",
        sort: true,
        classes: "c-pointer",
    },
    {
        text: "Start Date",
        dataField: "memberStartDate",
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
            return cellContent && cellContent.split("T")[0];
        },
    },
    {
        text: "End Date",
        dataField: "memberEndDate",
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
            return cellContent && cellContent.split("T")[0];
        },
    },
];

class PositionMemberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MemberId: this.props.MemberId,
            data: [],
            openModal: false,
            ModalHeading: "PositionMembersModal",
            position: null,
            positionsDisplayer: [],
            positions: [],
            sizePerPagePositions: 10,
            showHistoryAvail: true,
            showHistory: true,
            positionName: '',
            openModal: false,
            viewTeamId: 0,
        };
        this.positionSizePerPageChange = this.positionSizePerPageChange.bind(this);
        this.handleShowAllPosition = this.handleShowAllPosition.bind(this);
        this.handleLessAllPosition = this.handleLessAllPosition.bind(this);
        this.getPositionMembers = this.getPositionMembers.bind(this);
        this.RemotePagination = this.RemotePagination.bind(this);
        this.openAssignMultiMemberModal = this.openAssignMultiMemberModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }
    componentWillUnmount() { }

    handleShowAllPosition = (e) => {
        e.preventDefault();
        this.setState({
            positionsDisplayer: _.cloneDeep(this.state.positions),
            showHistory: false,
        });
    };
    handleLessAllPosition = (e) => {
        e.preventDefault();
        this.state.positionsDisplayer = this.state.positions.filter(
            (x) => x.isActive === true
        );
        this.setState({
            positionsDisplayer: this.state.positionsDisplayer,
            showHistory: true,
        });
    };
    componentDidUpdate(prevProps) {
        if (
            this.props.permissionState.openPositionMembersModal &&
            !_.isEmpty(this.props.position)
        ) {
            this.getPositionMembers();
            this.setState({
                openModal: this.props.permissionState.openPositionMembersModal,
            });
            this.openModal();
        }
    }
    componentDidMount() { }
    positionSizePerPageChange = (sizePerPage) => {
        this.setState({ sizePerPagePositions: sizePerPage });
    };
    RemotePagination(
        columns,
        keyFields,
        data,
        SizePerPageChange,
        sizePerPage,
    ) {
        let paginationOptions = {
            totalSize: data && data.length,
            paginationTotalRenderer: TableCustomTotal,
            showTotal: true,
            onSizePerPageChange: SizePerPageChange,
        };
        return (
            <div>
                {!_.isEmpty(data) && (
                    <BootstrapTable
                        keyField={keyFields}
                        data={data}
                        columns={columns}
                        classes="table mb-0"
                        pagination={
                            data.length > sizePerPage
                                ? paginationFactory({ ...paginationOptions })
                                : false
                        }
                    />
                )}
            </div>
        );
    }
    getPositionMembers = () => {
        if (!_.isEmpty(this.props.position)) {
            this.state.positions = this.props.position.members;
            this.state.positionName = this.props.position.positionName;
            this.state.positionsDisplayer = _.cloneDeep(
                this.state.positions.filter((x) => x.isActive == true)
            );
            if (
                JSON.stringify(this.state.positions) ==
                JSON.stringify(this.state.positionsDisplayer)
            ) {
                this.state.showHistoryAvail = false;
            }
            this.setState({
                showHistoryAvail: this.state.showHistoryAvail,
                positions: this.state.positions,
                positionName: this.state.positionName,
                positionsDisplayer: this.state.positionsDisplayer,
            });
        }

    };
    cancelModal() {
        this.setState({ openModal: false });
    }
    openModal() {
        this.props.closePositionMembersModal();
    }
    openAssignMultiMemberModal(e) {
        e.preventDefault();
        this.props.openAssignMultiMemberModal();
    }
    render() {
        return (
            <AUX>
                <Modal
                    id={this.state.ModalHeading}
                    className="styled-modal"
                    isOpen={this.state.openModal}
                    size="lg"
                    backdrop="static"
                    keyboard={false}
                >
                    <ModalHeader
                        toggle={this.cancelModal}
                        className="modal-header text-uppercase bg-secondary py-2"
                    >
                        assign position member
                    </ModalHeader>
                    <ModalBody className="modal-body py-2">
                        <div className="col-12">
                            <h5 className="text-info font-weight-bold"><i>Assing Member...</i></h5>
                            <h4 className="font-weight-bold">{this.state.positionName}</h4>
                        </div>
                        <div className="col-12">
                            <div className="text-right">
                                {this.state.showHistoryAvail &&
                                    (this.state.showHistory ? (
                                        <a href="" onClick={this.handleShowAllPosition}>
                                            Show History
                                        </a>
                                    ) : (
                                        <a href="" onClick={this.handleLessAllPosition}>
                                            Hide History
                                        </a>
                                    ))}
                            </div>
                            <div className="card directory-card border-rounded shadow">
                                <div className="card-header row mx-0 mt-0 text-uppercase px-0 py-1">
                                    <div className='col mb-0 h6'> Position Members</div>
                                    <div className='col text-right'>
                                        <a href="" onClick={this.openAssignMultiMemberModal}><span className='fas fa-user-plus'></span> Add Member</a>
                                    </div>
                                </div>
                                <div className="card-body px-0 py-0">
                                    {this.RemotePagination(
                                        positionsColumns,
                                        "id",
                                        this.state.positionsDisplayer,
                                        this.state.positionSizePerPageChange,
                                        this.state.sizePerPagePositions
                                    )}
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
                <AssignMemberModal
                    teamId={this.props.id}
                    setReloader={this.props.setReloader}
                    position={this.props.position}
                    openModal={this.props.permissionState.openAssignMultiMemberModal}
                    closeModal={this.props.closeAssignMultiMemberModal}
                />
            </AUX>
        );
    }
}

export default connect((state) => ({ permissionState: state.permission }), {
    ...PermissionAction,
})(PositionMemberModal);
