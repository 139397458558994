import React, { Component } from "react";
import { MDBInput } from "mdbreact";
import _ from "lodash";
import InputMask from "react-input-mask";
export default class Step4 extends Component {
  validatePhoneInput = () => {
    var result = false;
    if (!this.props.phoneNumber) {
      result = true;
    }
    if (
      !(
        this.props.phoneNumber &&
        this.props.phoneNumber.replace(/[^\d.]/g, "").length === 10
      )
    ) {
      result = true;
    }
    return result;
  };

  validatePhoneVerificationCode = () => {
    var result = false;
    if (!this.props.phoneVerificationCode) {
      result = true;
    }
    if (
      !(this.props.phoneVerificationCode.replace(/[^\d.]/g, "").length === 6)
    ) {
      result = true;
    }
    return result;
  };

  validateCompleteBtn = () => {
    var result = false;
    if (!this.props.isPhoneNoVerified && this.props.allowtextmessages) {
      result = true;
    }

    if (this.props.currentStep > 5 && this.props.isPhoneNoVerified) {
      return false;
    }

    return result;
  };

  render() {
    if (this.props.currentStep < 4) {
      return null;
    }

    return (
      <>
        <h4 className="headerPos">Subscribe to Alerts</h4>
        <p>
          From time to time NATCA sends alerts when we need your help! Please
          let us know below how you would like to receive these communications.
          This does not impact subscriptions for notifications sent to members
          of regions and/or facilities, or members who hold appointed or elected
          positions in NATCA.
        </p>
        <div
          className={`form-group ${
            this.props.allowtextmessages ? "mb-0" : "mb-2"
          }`}
        >
          <span className="card-text custom-checkbox ml-3 mr-4">
            <input
              type="checkbox"
              htmlFor={"allowtextmessages"}
              value={this.props.allowtextmessages}
              checked={this.props.allowtextmessages}
              className="custom-control-input"
              id={"allowtextmessages"}
              name={"allowtextmessages"}
              onChange={(e) => this.props.onChangeCB(e)}
            />

            <label
              className="custom-control-label"
              htmlFor={"allowtextmessages"}
            >
              Text Messages
            </label>
          </span>
        </div>

        {this.props.allowtextmessages && (
          <div className="onTextSelection pl-4">
            <small>
              <p className="ml-2 mb-1">
                I hereby consent to receive text message alerts from or on
                behalf of NATCA at the telephone number provided below. I
                understand and agree that any text message I receive may be sent
                by an autodialer, that message and data rates may apply, and
                that NATCA is not responsible for any fees that are incurred by
                my wireless carrier.
              </p>
            </small>
            {this.props.currentStep > 5 && (
              <>
                <div className="form-group mb-1">
                  <span className="card-text custom-checkbox ml-3 mr-4">
                    <input
                      type="checkbox"
                      htmlFor={"allowtext_newsalerts"}
                      value={this.props.allowtext_newsalerts}
                      checked={this.props.allowtext_newsalerts}
                      className="custom-control-input"
                      id={"allowtext_newsalerts"}
                      name={"allowtext_newsalerts"}
                      onChange={(e) => this.props.onChangeCB(e)}
                    />

                    <label
                      className="custom-control-label"
                      htmlFor={"allowtext_newsalerts"}
                    >
                      <h6 className="mt-1">NATCA NEWS Alerts</h6>
                      <small>
                        Important news alerts sent from NATCA leadership. No
                        more than two messages per month.
                      </small>
                    </label>
                  </span>
                </div>
                <div className="form-group mb-2">
                  <span className="card-text custom-checkbox ml-3 mr-4">
                    <input
                      type="checkbox"
                      htmlFor={"allowtext_legislative"}
                      value={this.props.allowtext_legislative}
                      checked={this.props.allowtext_legislative}
                      className="custom-control-input"
                      id={"allowtext_legislative"}
                      name={"allowtext_legislative"}
                      onChange={(e) => this.props.onChangeCB(e)}
                    />

                    <label
                      className="custom-control-label"
                      htmlFor={"allowtext_legislative"}
                    >
                      <h6 className="mt-1">
                        NATCA Legislative Updates and Alerts
                      </h6>
                      <small>
                        Legislative news, call-to-action, and important updates.
                        No more than four messages per month.
                      </small>
                    </label>
                  </span>
                </div>
              </>
            )}
            {this.props.currentStep === 5 && (
              <>
                <h6>Verify Your Mobile Number</h6>
                <small>
                  A 6-digit code was sent to .Enter it below to verify the phone
                  number to receive the text alerts.
                </small>
              </>
            )}

            <div className="row">
              {this.props.currentStep === 5 && (
                <div className="col-md-6 col-lg-6 col-xl-6 col-xs-12 col-sm-12">
                  <div className="form-group mb-2 text-left">
                    <MDBInput
                      name="phoneVerificationCode"
                      value={this.props.phoneVerificationCode}
                      onChange={this.props.handleChange}
                      label="Verification Code"
                      group
                      type="text"
                    ></MDBInput>
                  </div>
                </div>
              )}
              {this.props.currentStep === 4 && (
                <div className="col-md-6 col-lg-6 col-xl-6 col-xs-12 col-sm-12">
                  <div className="form-group mb-2 text-left">
                    <InputMask
                      placeholder="Mobile Number"
                      name="phoneNumber"
                      containerClass="mb-0"
                      value={this.props.phoneNumber}
                      onChange={this.props.handleChange}
                      label="Mobile Number"
                      data-type="P"
                      mask="(999) 999-9999"
                      className="form-control"
                      group
                      required
                      type="text"
                    ></InputMask>
                  </div>
                </div>
              )}

              {this.props.currentStep === 4 && (
                <div className="col-md-6 col-lg-6 col-xl-6 col-xs-12 col-sm-12">
                  <button
                    className="btn btn-primary btn-block w-md waves-effect waves-light"
                    type="button"
                    disabled={this.validatePhoneInput()}
                    onClick={this.props.sendSMS}
                  >
                    Verify
                  </button>
                </div>
              )}
              {this.props.currentStep === 5 && (
                <div className="col-md-6 col-lg-6 col-xl-6 col-xs-12 col-sm-12 mt-lg-4 mt-md-4 mt-xl-4 mt-sm-1 mb-lg-2 mb-sm-4 mx-auto text-right">
                  <button
                    className="btn btn-primary btn-block w-md waves-effect waves-light"
                    type="button"
                    disabled={this.validatePhoneVerificationCode()}
                    onClick={this.props.verifySMSCode}
                  >
                    Verify
                  </button>
                </div>
              )}

              <div className="pl-3">
                {this.props.currentStep === 4 && (
                  <p>
                    <small className="font-weight-bold">
                      Unsubscribe by logging into this site and disabling
                      alerts.
                    </small>
                  </p>
                )}

                {this.props.currentStep > 5 &&
                  !_.isEmpty(this.props.phoneNumber) && (
                    <p className="mb-1">
                      <span className="font-weight-bold">
                        {`${this.props.phoneNumber} has been successfully verified.`}
                      </span>
                    </p>
                  )}
                {this.props.currentStep === 5 && (
                  <small className="mt-1 mt-sm-3 mb-1 font-weight-bold">
                    -Didn't receive the code?
                    <span
                      className="mb-6"
                      style={{ cursor: "pointer" }}
                      onClick={this.props.resendcode}
                      onMouseOver={this.hoverEffect}
                      onMouseOut={this.hoverRemove}
                    >
                      {" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "#626ed4",
                        }}
                      >
                        Resend code
                      </span>
                    </span>{" "}
                    or{" "}
                    <span
                      className="mb-6"
                      onClick={this.props.changeNo}
                      style={{ cursor: "pointer" }}
                      onMouseOver={this.hoverEffect}
                      onMouseOut={this.hoverRemove}
                    >
                      {" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "#626ed4",
                        }}
                      >
                        Change No.
                      </span>
                    </span>
                  </small>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="form-group mb-2">
          <span className="card-text custom-checkbox ml-3 mr-4">
            <input
              type="checkbox"
              htmlFor={"allowemails"}
              value={this.props.allowemails}
              checked={this.props.allowemails}
              className="custom-control-input"
              id={"allowemails"}
              name={"allowemails"}
              onChange={(e) => this.props.onChangeCB(e)}
            />

            <label className="custom-control-label" htmlFor={"allowemails"}>
              Email
            </label>
          </span>
        </div>
        <div className="form-group mb-2">
          <span className="card-text custom-checkbox ml-3 mr-4">
            <input
              type="checkbox"
              htmlFor={"allowphonecall"}
              value={this.props.allowphonecall}
              checked={this.props.allowphonecall}
              className="custom-control-input"
              id={"allowphonecall"}
              name={"allowphonecall"}
              onChange={(e) => this.props.onChangeCB(e)}
            />

            <label className="custom-control-label" htmlFor={"allowphonecall"}>
              Phone Call
            </label>
          </span>
        </div>

        <div className="form-group mb-2">
          <span className="card-text custom-checkbox ml-3 mr-4">
            <input
              type="checkbox"
              htmlFor={"allowpostalmail"}
              value={this.props.allowpostalmail}
              checked={this.props.allowpostalmail}
              className="custom-control-input"
              id={"allowpostalmail"}
              name={"allowpostalmail"}
              onChange={(e) => this.props.onChangeCB(e)}
            />

            <label className="custom-control-label" htmlFor={"allowpostalmail"}>
              Mailing Address
            </label>
          </span>
        </div>
        <div className="form-group m-t-10 mb-4 row">
          <div className="col-12 m-t-20 mx-auto text-center">
            <button
              className="btn btn-primary btn-block w-md waves-effect waves-light"
              type="submit"
              disabled={this.validateCompleteBtn()}
            >
              Complete Registration
            </button>
          </div>
        </div>
      </>
    );
  }
}
