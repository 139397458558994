import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
export default {
  TeamScopes: async (teamId) => {
    let url =
      teamId === null
        ? "/api/Permission/Scopes"
        : `/api/Permission/Scopes?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  Team: async (teamId) => {
    let url =
      teamId === null
        ? "/api/Permission/Team"
        : `/api/Permission/Team?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  TeamPosition: async (teamId) => {
    let url = `/api/Permission/TeamPosition?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  TeamPositions: async (teamId) => {
    let url =
      teamId === null
        ? "/api/Permission/Positions"
        : `/api/Permission/Positions?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  TeamPositionTypes: async () => {
    let url = "/api/Permission/TeamPositionTypes";
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  DeleteChild: async (deleteParentChildId) => {
    let url = `api/Permission/DeleteChild?parentChildID=${deleteParentChildId}`;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  DeletePosition: async (teamPositionID, isHistory) => {
    let url = `api/Permission/DeletePosition?teamPositionID=${teamPositionID}&isHistory=${isHistory}`;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  DeleteLinkedPosition: async (teamLinkedPositionID) => {
    let url = `api/Permission/DeleteLinkedPosition?teamLinkedPositionID=${teamLinkedPositionID}`;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteMemberPosition: async (teamPositionMemberID) => {
    let url = `api/Permission/DeleteMemberPosition?teamPositionMemberID=${teamPositionMemberID}`;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  MyTeam: async () => {
    let url = `api/Permission/MyTeams`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  ViewTeam: async (MemberId, TeamPositionId) => {
    let url = `api/Permission/ViewTeams?memberId=${MemberId}&teamPositionId=${TeamPositionId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  FacilityMembers: async (FacilityId) => {
    let url = `api/Permission/FacilityMembers?facilityId=${FacilityId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  MyPositions: async (MemberID) => {
    let url = `api/Permission/MyPositions?memberId=${MemberID}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  TeamsByTeam: async (teamId) => {
    let url = `api/Permission/TeamsByTeam?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  DeleteTeams: async (teamAssignChild) => {
    let url = `/api/Permission/DeleteTeams`;
    var { data } = await axios.post(url, teamAssignChild);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  AssignTeam: async (assignChildTeam) => {
    let url = `api/Permission/AssignTeam`;
    var { data } = await axios.post(url, assignChildTeam);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  SwapPositionOrderId: async (SourcePosition, TargetPosition) => {
    let url = `api/Permission/SwapPositionOrderId`;
    var { data } = await axios.post(url, [SourcePosition, TargetPosition]);
    if (data.statusCode && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  TeamMemberExist: async () => {
    let url = `api/Permission/TeamMemberExist`;
    var { data } = await axios.get(url);
    if (data && data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LinkedPositionTeams: async (teampositionId) => {
    let url = `/api/Permission/LinkedPositions?teampositionId=${teampositionId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  LinkedTeams: async (teamId) => {
    let url =
      teamId === null
        ? "/api/Permission/LinkedTeams"
        : `/api/Permission/LinkedTeams?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  ChildTeams: async (teamId) => {
    let url =
      teamId === null
        ? "/api/Permission/TeamChildren"
        : `/api/Permission/TeamChildren?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  TeamPermissions: async (teamId) => {
    let url =
      teamId === null
        ? "/api/Permission/TeamPermission"
        : `/api/Permission/TeamPermission?teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DefaultPermissions: async () => {
    let url = "/api/Permission/DefaultPermissions";

    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  UserOwnPermissions: async () => {
    let url = "/api/Permission/UserOwnPermissions";

    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  ReviewPermissions: async (MemberId) => {
    let url = `/api/Permission/ReviewPermission?memberId=${MemberId}`;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  ReviewPermissionsDetail: async (MemberId, PermissionFeatureId, PermType) => {
    let url = `/api/Permission/ReviewPermissionDetail?memberId=${MemberId}&permissionFeatureId=${PermissionFeatureId}&permType=${PermType}`;
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  PositionPermissions: async (teamPositionId, teamId) => {
    let url = `/api/Permission/PositionPermission?teamPositionId=${teamPositionId}&teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  PositionScope: async (teamPositionId, teamId) => {
    let url = `/api/Permission/PositionScope?teamPositionId=${teamPositionId}&teamId=${teamId}`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadMembers: async (searchTerms, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/Permission/GetMembers?searchTerms=` +
          searchTerms +
          `&offset=0&fetch=10`
        : `api/Permission/GetMembers?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadOfficerMembers: async (searchTerms, offset, fetch /*, facilityid*/) => {
    let url =
      offset === null || fetch === null
        ? `api/Permission/OfficerMembers?searchTerms=` +
          searchTerms +
          `&offset=0&fetch=10`
        : `api/Permission/OfficerMembers?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    //+
    //    `&facilityid=` +
    //    facilityid;
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadMembersByScope: async (searchTerms, offset, fetch, facilityId) => {
    debugger;
    let url =
      offset === null || fetch === null
        ? `api/Permission/GetMembersByScope?searchTerms=` +
          searchTerms +
          `&offset=0&fetch=10&facilityId=` +
          (typeof facilityId !== "undefined" ? facilityId : 0)
        : `api/Permission/GetMembersByScope?searchTerms=` +
          searchTerms +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch +
          `&facilityId=` +
          (typeof facilityId !== "undefined" ? facilityId : 0);

    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadMoreLogs: async (moduletype, id, offset, fetch) => {
    let url =
      offset === null || fetch === null
        ? `api/ActivityLogging?moduletype=${moduletype}&id=${id}&offset=0&fetch=10`
        : `api/ActivityLogging?moduletype=${moduletype}&id=${id}&offset=${offset}&fetch=${fetch}`;
    var { data } = await axios.get(url);
    return data;
  },
  LoadLinkedTeams: async (teamScope) => {
    let url = `/api/Permission/LinkedPositionTeams`;
    var { data } = await axios.post(url, teamScope);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadScopeTeams: async (teamScope) => {
    let url = `/api/Permission/ScopeTeams`;
    var { data } = await axios.post(url, teamScope);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  SaveLinkedPosition: async (teamLinkedPosition) => {
    let url = `/api/Permission/LinkedPosition`;
    var { data } = await axios.post(url, teamLinkedPosition);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  SaveTeam: async (team) => {
    let url = `/api/Permission/Team`;
    var { data } = await axios.post(url, team);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EditTeam: async (editTeam) => {
    let url = `/api/Permission/EditTeam`;
    var { data } = await axios.post(url, editTeam);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EditTeamPermission: async (editTeamPermissionModel) => {
    let url = `/api/Permission/EditTeamPermission`;
    var { data } = await axios.post(url, editTeamPermissionModel);
    if (data && data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EditSpecialTeamPermission: async (editSpecialTeamPermissionsModel) => {
    let url = `/api/Permission/EditSpecialTeamPermission`;
    var { data } = await axios.post(url, editSpecialTeamPermissionsModel);
    if (data && data.statusCode == 200) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EditDefaultPermission: async (editDefaultPermissionModel) => {
    let url = `/api/Permission/EditDefaultPermission`;
    var { data } = await axios.post(url, editDefaultPermissionModel);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EditUserOwnPermission: async (EditUserOwnPermissionModel) => {
    let url = `/api/Permission/EditUserOwnPermission`;
    var { data } = await axios.post(url, EditUserOwnPermissionModel);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EditPosition: async (editPositionModel) => {
    let url = `/api/Permission/EditPosition`;
    var { data } = await axios.post(url, editPositionModel);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EditPositionMember: async (editPositionModel) => {
    let url = `/api/Permission/EditPositionMember`;
    var { data } = await axios.post(url, editPositionModel);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  EditTeamPositionMemberDates: async (editTeamPositionMemberModel) => {
    let url = `/api/Permission/EditTeamPositionMemberDates`;
    var { data } = await axios.post(url, editTeamPositionMemberModel);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  EditPositionPermissionScope: async (editPositionModel) => {
    let url = `/api/Permission/EditPositionPermissionScope`;
    var { data } = await axios.post(url, editPositionModel);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetTeams: async () => {
    let url = "/api/Permission/Teams";
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetTeamPermissionChangeStatus: async (teamid) => {
    if (teamid == null) {
      return null;
    }
    let url = `/api/Permission/TeamPermissionChangeStatus?TeamId=${teamid}`;
    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      return data;
    } else {
      return null;
    }
  },
  SetTeamPermissionChangeStatusSeen: async (id) => {
    if (id == null) {
      return null;
    }
    let url = `/api/Permission/TeamPermissionChangeStatusSeen?Id=${id}`;
    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      return data;
    } else {
      return null;
    }
  },
  GetArchivedTeams: async () => {
    let url = "/api/Permission/ArchivedTeams";
    var { data } = await axios.get(url);
    if (data.statusCode) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
};
