import moment from "moment";

export const maxDateNormalize = (value, previousValue, values) => {
  const momentMinDate = moment(values.ToDate, "MM/DD/YYYY", true);
  const momentMaxDate = moment(value, "MM/DD/YYYY", true);
  if (!momentMinDate.isValid() || !momentMaxDate.isValid()) {
    return value;
  }
  if (!momentMaxDate.isAfter(momentMinDate)) {
    return momentMinDate.add(1, "d").format("MM/DD/YYYY");
  }
  return value;
};

/**
 * Force before max date
 */
export const minDateNormalize = (value, previousValue, values) => {
  const momentMaxDate = moment(values.FromDate, "MM/DD/YYYY", true);
  const momentMinDate = moment(value, "MM/DD/YYYY", true);
  if (!momentMinDate.isValid() || !momentMaxDate.isValid()) {
    return value;
  }
  if (!momentMinDate.isBefore(momentMaxDate)) {
    return momentMaxDate.subtract(1, "d").format("MM/DD/YYYY");
  }
  return value;
};

export const formatDateForInput = (pickedDate) => {
  // the returned value will be available as `input.value`
  if (pickedDate != null && pickedDate != "Invalid date") {
    return moment(pickedDate).format("MM/DD/YYYY");
  } else {
    return null;
  }
};

export const parseDateForStore = (storedDate) => {
  // the returned value will be stored in the redux store
  if (storedDate != null && storedDate != "Invalid date") {
    return moment(storedDate).format("MM/DD/YYYY");
  } else {
    return null;
  }
};
