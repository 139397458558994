import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import FE_Tabs_Type from "../../modules/F&E/FE_Tabs";
import _ from "lodash";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";
import { connect } from "react-redux";
import FE_EquipmentModal from "./Inventory/FE_EquipmentModal";
import FETrackerAction from "../../store/feTracker/action";

import "../../assets/css/PermissionHeader.css";
import FE_RequestModal from "./F&ERequests/FE_RequestModal";
import FE_AdvanceSearch_Modal from "./AdvanceFilter/FE_AdvanceSearch_Modal";
class FEHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveFeTab: -1,
      page: "",
      subPage: "",
      secondSubPage: "",
      openEquipmentModal: false,
    };
  }
  async componentDidMount() {
    let addresses = window.location.pathname.split("/");
    let page = addresses.length > 1 ? addresses[1] : "";
    let subPage = addresses.length > 2 ? addresses[2] : "";
    let secondSubPage = addresses.length > 3 ? addresses[3] : "";
    this.setState({
      page: page,
      subPage: subPage,
      secondSubPage: secondSubPage,
    });
    if (page == "FETracker" && subPage == "inventory") {
      this.setState({ ActiveFeTab: 1 });
      this.props.setHeaderActiveTab(1);
    } else if (page == "FETracker" && subPage == "myRequests") {
      this.setState({ ActiveFeTab: 2 });
      this.props.setHeaderActiveTab(2);
    } else if (page == "FETracker" && subPage == "reviewRequest") {
      this.setState({ ActiveFeTab: 3 });
      this.props.setHeaderActiveTab(3);
      if (
        this.props.festate.showReviewRequest == "" &&
        !this.props.festate.checkingApproverPerson
      ) {
        //console.log("checkIsBudgetApprover from FE Header");
        const result = await this.props.checkIsBudgetApprover();
      }
    }
    // console.log("componentDidMount from FE Header");
  }
  routeLinkPage = (activePage) => {
    let page = "inventory";
    if (activePage == 2) {
      page = "myRequests";
    } else if (activePage == 3) {
      page = "reviewRequest";
    }

    this.props.setHeaderActiveTab(activePage);
    this.setState({ ActiveFeTab: activePage }, () => {
      this.props.history.push(`/FETracker/${page}`);
    });
  };

  openEquipmentModal = () => {
    //this.setState({ openEquipmentModal: true });
    this.props.openEquipmentModal();
  };
  closeEquipmentModal = () => {
    //this.setState({ openEquipmentModal: false });
    this.props.closeEquipmentModal();
  };

  openRequestModal = () => {
    this.props.openMyRequestModal();
  };
  closeRequestModal = () => {
    var isReviewRequestOpen = this.props.festate.isReviewRequestOpen;
    if (isReviewRequestOpen) {
      this.props.closeReviewRequestModal();
    } else {
      this.props.closeMyRequestModal();
    }
  };
  openFEAdvanceFilter = () => {
    this.props.toggleFEAdvanceFilter(true);
  };
  render() {
    let { page, subPage } = this.state;
    const showCogIcon =
      AuthService.canAdd(PermissionFeature.FEAdmin) &&
      this.props.festate.activeSubHeader == FE_Tabs_Type.Inventory;
    return (
      <>
        <nav className="navbar navbar-expand-sm equipmentHeader-navbar">
          <Fragment>
            {AuthService.canView(PermissionFeature.FEAdmin) && (
              <a
                className={
                  "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                  (this.state.ActiveFeTab === 1
                    ? " equipmentHeader-navbar-brand-active"
                    : "")
                }
                data-tab="MyEquipments"
                onClick={() => this.routeLinkPage(1)}
              >
                <i class="fas fa-warehouse"></i> Equipment
              </a>
            )}
            {AuthService.canView(PermissionFeature.FETrackerRequest) && (
              <a
                className={
                  "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                  (this.state.ActiveFeTab === 2
                    ? " equipmentHeader-navbar-brand-active"
                    : "")
                }
                data-tab="MyRequests"
                onClick={() => this.routeLinkPage(2)}
              >
                <i className="fas fa-hand-holding-usd"></i> My Requests
              </a>
            )}
            {//AuthService.canView(PermissionFeature.FEApprover)
            this.props.festate.showReviewRequest && (
              <a
                className={
                  "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                  (this.state.ActiveFeTab === 3
                    ? " equipmentHeader-navbar-brand-active"
                    : "")
                }
                data-tab="ReviewRequests"
                onClick={() => this.routeLinkPage(3)}
              >
                <i className="fas fa-history"></i> Review Requests
              </a>
            )}
            {/* {(AuthService.canView(PermissionFeature.FEAdmin) ||
              //AuthService.canView(PermissionFeature.FEApprover)
              this.props.festate.isBudgetApproverPerson ||
              AuthService.canView(PermissionFeature.FETrackerRequest)) &&
              page == "FETracker" &&
              subPage !== "BudgetApprovers" && (
                <a
                  className="navbar-brand feHeader-tabs equipmentHeader-navbar-brand"
                  id="clickAdvancedFilter"
                  data-toggle="tooltip"
                  title="Advance Filters"
                  onClick={() => this.openFEAdvanceFilter()}
                >
                  <i className="fas fa-filter"></i>Eqpt. Filter
                </a>
              )} */}
          </Fragment>
          {showCogIcon && (
            <UncontrolledDropdown className="dropdown-menu-toggle feHeaderCogIcon equipmentHeader-navbar-brand">
              <DropdownToggle tag="a" className="nav-link nav-link-font-size">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-body">
                {page == "FETracker" &&
                  subPage == "inventory" &&
                  AuthService.canAdd(PermissionFeature.FEAdmin) && (
                    <DropdownItem
                      className="dropdown-item-custom"
                      onClick={() => {
                        this.openEquipmentModal();
                      }}
                    >
                      {" "}
                      <i className="fas pr-2 fa-plus-circle"></i> Add New
                      Equipment{" "}
                    </DropdownItem>
                  )}
                {/* {page == "FETracker" &&
                  subPage == "myRequests" &&
                  AuthService.canAdd(PermissionFeature.FETrackerRequest) && (
                    <DropdownItem
                      className="dropdown-item-custom"
                      onClick={() => {
                        this.openRequestModal();
                      }}
                    >
                      <i className="fas pr-2 fa-plus-circle fetracker"></i>{" "}
                      Create New Request{" "}
                    </DropdownItem>
                  )} */}
                {/* {page == "FETracker" && subPage !== "BudgetApprovers" && (
                  <DropdownItem
                    className="dropdown-item-custom"
                    onClick={() => {
                      this.props.history.push("/FETracker/BudgetApprovers");
                    }}
                  >
                    <i className="fas pr-2 fa-users"></i> Budget Approvers
                  </DropdownItem>
                )} */}

                {/* {page == "FETracker" && subPage == "BudgetApprovers" && (
                  <DropdownItem
                    className="dropdown-item-custom"
                    onClick={() => {
                      this.props.toggleBudgetLineModal();
                    }}
                  >
                    <i className="fas pr-2 fa-plus-circle"></i> Add Budget Line
                  </DropdownItem>
                )} */}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          {page == "FETracker" &&
            subPage == "myRequests" &&
            AuthService.canAdd(PermissionFeature.FETrackerRequest) && (
              <div className="c-pointer navbar-brand equipmentHeader-navbar-brand">
                <button
                  className="feHeader-tabs cnrbtn btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                  onClick={() => {
                    this.openRequestModal();
                  }}
                  type="button"
                >
                  Create New Request
                </button>
              </div>
            )}
        </nav>

        <FE_EquipmentModal
          showModal={this.props.festate.openEquipmentModal}
          onClose={this.closeEquipmentModal}
        />

        <FE_RequestModal
          showModal={this.props.festate.openMyRequestModal}
          onClose={this.closeRequestModal}
        />

        <FE_AdvanceSearch_Modal
          showModal={this.props.festate.openFEAdvanceFilterModal}
          onClose={this.closeFEAdvanceFilterModal}
        />
      </>
    );
  }
}
export default withRouter(
  connect((state) => ({ festate: state.fetracker }), {
    ...FETrackerAction,
  })(FEHeader)
);
