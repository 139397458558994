const actions = {
  ADD_MULTIPLE_AUTH_SENDERS: "ADD_MULTIPLE_AUTH_SENDERS",
  SELECT_AUTH_SENDER_MEMBER: "SELECT_AUTH_SENDER_MEMBER",
  DESELECT_SELECTED_POPUP_ENTITY: "DESELECT_SELECTED_POPUP_ENTITY",
  DESELECT_ALL_SELECTED_POPUP_ENTITY: "DESELECT_ALL_SELECTED_POPUP_ENTITY",

  selectMultipleAuthSender: (authSenderType) => (dispatch) => {
    dispatch({
      type: actions.ADD_MULTIPLE_AUTH_SENDERS,
      payload: { type: authSenderType },
    });
  },

  selectMember: (id) => (dispatch) => {
    dispatch({
      type: actions.SELECT_AUTH_SENDER_MEMBER,
      payload: id,
    });
  },

  deSelectMember: (id) => (dispatch) => {
    dispatch({
      type: actions.DESELECT_SELECTED_POPUP_ENTITY,
      payload: id,
    });
  },

  deSelectAllMember: () => (dispatch) => {
    dispatch({
      type: actions.DESELECT_ALL_SELECTED_POPUP_ENTITY,
    });
  },
};

export default actions;
