import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AUX from "../../hoc/Aux_";
import $ from "jquery";
import axios from "../Shared/auth-header";
import { toast } from "react-toastify";
import PermissionFeature from "../Permissions/PermissionFeature";
import AuthService from "../Auth/AuthService";
import { connect } from "react-redux";
import mailGunEmailActions from "../../store/mailgunEmails/action";
import registrationsAction from "../../store/registrations/action";
import { withRouter } from "react-router-dom";
import MailGunConfigurationTabs from "../Email/AuthorizedSenders/MailGunConfigurationTabs";
import MailGunEmailTypes from "../Email/Enums/MailGunEmailTypes";
// import MG_DeleteAllAuthSenders from "../Email/ModalPopups/MG_DeleteAllAuthSenders";
import EmailConfigTabs from "../Email/Enums/EmailConfigTabs";
import EmailService from "../Email/EmailListService";
import MG_DeleteEmailList from "../Email/ModalPopups/MG_DeleteEmailList";
import * as Constants from "../../modules/AdvanceSearch/SavedSearches/Constants";
import { Spinner } from "reactstrap";
import _ from "lodash";
import * as Permissions from "../Permissions/PermissionValues";
import RegistrationsService from "../Registrations/RegistrationsService";
import EmailListService from "../Email/EmailListService";
import PermissionService from "../Permissions/PermissionService";
import actionActivity from "../../store/activitylogging/action";
import AuditModuleTypes from "../Shared/AuditModuleTypes";

// Configure Email Modal, opend from FRT ******Facility/Team/Regis*******
// Not for Email list =>ConfigureMG_Email => MainPage
// Not for Save Search Email list
class MailGunMembersListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteEmailListModal: false,
      key: "subscribers",
      showModal: props.showModal,
      mgListAddress: props.mgListAddress,
      mailingListName: "",
      subscribers: null,
      unsubscribers: null,
      pageSize: 25,
      columns: [
        { text: "Name", dataField: "name", sort: false },
        { text: "Email", dataField: "address", sort: false },
      ],
      paginationSettings: {
        withFirstAndLast: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        paginationSize: 1,
      },
      emailPriviliges: [],
      selectedPrivilige: "readonly",
      reply_preference: "list",
      name: "",
      emailAlias: "",
      emailAliasOld: "",
      isConfirmModalOpen: false,
      userFirstName: "",
      userLirstName: "",
      deleteAuthSenderId: 0,
      showEmailAliasModal: false,
      listPrivilige: "",
      replyPreference: "",
      selectedPrivilege: "",
      currentEmailConfigTab: "",
      domain: "",
      emailListId: 0,
      emailListExists: false,
      // postDomain: "",
      isCalledAPI: null,
    };

    this.confirmEmailList = () => {
      this.confirmed = async () => {
        let { emailListId, emailEntityType } = this.props.melstate;
        let deleteResponse = null;
        if (emailEntityType == MailGunEmailTypes.Member) {
          await EmailService.DeleteSSEmailList(emailListId).then(
            ({ data: response }) => {
              $(".loading").hide();
              if (response.status === "success") {
                toast.success("Email list removed successfully!");
                deleteResponse = 1;
                $(".loading").hide();
              } else {
                toast.error(response.message);
                deleteResponse = 0;
                $(".loading").hide();
              }
            }
          );
        } else if (emailEntityType !== MailGunEmailTypes.Member) {
          await EmailService.DeleteFRTEmailList(emailListId).then(
            ({ data: response }) => {
              $(".loading").hide();
              if (response.status === "success") {
                toast.success("Email list removed successfully!");
                deleteResponse = 1;
                $(".loading").hide();
              } else {
                toast.error(response.message);
                deleteResponse = 0;
                $(".loading").hide();
              }
            }
          );
        }
        this.toggleDeleteEmailListModal();
        if (deleteResponse == 1) {
          this.props.onModalClose();
          this.props.onDeleteEmailList();
        }
      };

      return this;
    };
    this.mailgunConfigurationTabComp = React.createRef();
  }

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleClose = () => {
    this.props.onModalClose();
  };

  canView = () => {
    return AuthService.canView(PermissionFeature.EmailListAdmin);
  };

  canAdd = () => {
    return AuthService.canAdd(PermissionFeature.EmailListAdmin);
  };

  canUpdate = () => {
    return AuthService.canUpdate(PermissionFeature.EmailListAdmin);
  };

  // updateData = () => {
  //   this.updateSubscriberList();
  //   this.updateUnSubscriberList();
  // };
  getEmailDetails = async (entityId) => {
    if (entityId > 0) {
      // let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${entityId}&entityType=${this.props.MailGunEmailType}`;
      // // $(".loading").show();
      // await axios.get(url).then(async ({ data }) => {
      //   //  $(".loading").hide();
      //   if (data.exists != undefined) {
      //     await this.setState({
      //       emailListExists: data.exists,
      //       mgListAddress: data.mgListAddress,
      //       emailListId: data.emailListId,
      //       mailingListName: data.mailingListName || "",
      //       replyPreference: data.reply_preference,
      //       selectedPrivilege: data.selected_email_privilege_id,
      //       listPrivilige: data.selectedPrivilege,
      //     });
      //     await this.props.setMgListFullAddress(data.mgListAddress);
      //   }
      // });
      const { MailGunEmailType } = this.props;
      const data = await EmailListService.getFRTEmailDetails(
        entityId,
        MailGunEmailType
      );
      if (data != null) {
        this.setState({
          emailListExists: data.exists,
          mgListAddress: data.mgListAddress,
          emailListId: data.emailListId,
          mailingListName: data.mailingListName || "",
          replyPreference: data.reply_preference,
          selectedPrivilege: data.selected_email_privilege_id,
          listPrivilige: data.selectedPrivilege,
        });
        await this.props.setMgListFullAddress(data.mgListAddress);
        this.props.isEmailListExists(true);
      } else {
        this.setState({ emailListExists: false });
        this.props.isEmailListExists(false);
        this.setState({ emailListId: 0 });
      }
      this.setState({ isCalledAPI: true });
    }
  };
  canDeleteEmailList = () => {
    return AuthService.canDelete(PermissionFeature.EmailListAdmin);
  };
  resetState = () => {
    this.setState({
      emailListExists: false,
      mgListAddress: "",
      emailListId: 0,
      mailingListName: "",
      isCalledAPI: null,
    });
  };

  onHide = () => {
    this.props.resetEmailConfigDetails();
    this.resetState();
    this.props.onModalClose();
    //console.log("onHide");
  };
  onShow = async () => {
    let entityId = 0;
    if (this.props.isTeam) {
      entityId = this.props.TeamId;
    } else if (this.props.isFacility) {
      entityId = this.props.FacilityId;
    }
    //Registration Module Work
    else if (this.props.isRegistration) {
      entityId = this.props.ChildTemplateId;
    }
    await this.getEmailDetails(entityId);
    let {
      emailListExists,
      mgListAddress,
      emailListId,
      mailingListName,
      listPrivilige,
      replyPreference,
    } = this.state;
    if (emailListExists) {
      this.setState({
        emailAlias: mgListAddress ? mgListAddress.split("@")[0] : "",
        emailAliasOld: mgListAddress ? mgListAddress.split("@")[0] : "",
      });
      let formData = {
        emailListId: emailListId,
        emailEntityType: this.props.MailGunEmailType,
        entityKey: entityId,
        mailingListName: mailingListName || "",
        listPrivilige,
        replyPreference,
      };
      this.props.resetAuthSenderMembers();
      this.props.resetAddlMembers();
      if (listPrivilige == "readonly") {
        this.props.getAuthSenders(formData);
      }
      await this.props.setEmailListDetails(formData);
      this.props.reloadAdditionalSubsribersTables(); //Check this API.
      this.props.setMgListFullAddress(this.state.mgListAddress);
      this.props.reloadDefaultSubsribersTable();
      this.setState({
        currentEmailConfigTab: EmailConfigTabs.Subscribers,
      });

      this.fetchLogsCardClick(emailListId);
    } else {
      ///Means No Email List Exists
      this.props.resetEmailConfigDetails();
      this.setState({
        currentEmailConfigTab: EmailConfigTabs.Settings,
      });
      if (this.props.isRegistration) {
        await this.props.setMgListAlias(
          (this.props.childTemplateAbbr || "").toLowerCase()
        );
        await this.props.setEmailListName(this.props.childTemplateAbbr || "");
      }
    }
    ///////// Work of Email Enhancements/////////
    const configTab = this.mailgunConfigurationTabComp.current;
    let { currentEmailConfigTab } = this.state;
    configTab && (await configTab.setKey(currentEmailConfigTab));
    ////////////////////////////////////////////
    await EmailService.GetDomainName()
      .then(async ({ data: response }) => {
        if (response.status === "success") {
          await this.setState({
            domain: response.domain,
            //postDomain: response.postDomain,
          });
          //console.log(this.state.domain);
          let domainName = response.domain;
          domainName = domainName.replace("@", "");
          await this.props.setMgListDomain(domainName);
          // await this.props.setMgPostListDomain(response.postDomain);
        }
      })
      .catch((ex) => {
        console.log(ex);
        toast.error("Something went wrong!");
      });
  };
  fetchLogsCardClick = (emailListId) => {
    /// ActivityLogging
    //var myself = this;
    // var { Id, isChild, parentId } = useParams;
    let { MailGunEmailType } = this.props;
    const Id = `${emailListId},${MailGunEmailType}`;
    //const { emailLoggingVisible = false } = this.props.activityLoggingState;
    //var show = this.props.activityLoggingState && emailLoggingVisible;
    //console.log("show", show);
    //const Id = this.props.paramId && this.props.paramId.Id;
    if (!_.isEmpty(Id)) {
      // this.props.toggleEmailListLogAccordion(!emailLoggingVisible);
      //this.setState({ logRecordId: Id });
      //!show &&
      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.MailGunList,
        Id,
        0,
        10,
        "mail_list_logs"
      );
    }
  };
  //##############################################################################
  ////Save email List, called from "Team Detail" and "Facility Detail Page"//
  ///******Edit Mode************ Scenario
  onSave = () => {
    this.showSpinner();
    let id = 0;
    if (this.props.isTeam) {
      id = this.props.TeamId;
    } else if (this.props.isFacility) {
      id = this.props.FacilityId;
    } else if (this.props.isRegistration) {
      id = this.props.ChildTemplateId;
    }

    let {
      isAliasChanged,
      mgListAddressAlias,
      mgListDomain,
      selectedPrivilige,
      mailingListName,
      replyPreference,
      additionalRecipientsMembers,
      additionalRecipientsTeams,
      additionalRecipientsNonNatca,
      authorizedSendersMembers,
      authorizedSendersTeams,
      sendCopyToSender = false,
    } = this.props.melstate;
    let { MailGunEmailType } = this.props;
    let emailAuthorizedSenders = [
      ...authorizedSendersMembers,
      ...authorizedSendersTeams,
    ];

    let emailListAdditionalRecpts = [
      ...additionalRecipientsMembers,
      ...additionalRecipientsTeams,
      ...additionalRecipientsNonNatca,
    ];

    let emailConfigData = {
      emailAuthorizedSenders,
      emailListAdditionalRecpts,
      additionalRecipientsTeams, // Work for maintaing all Additional Team Recipients
    };
    let url = "";
    if (!isAliasChanged) {
      url = `/api/FacilityRegionEmail/UpdateEmailPrivilege?id=${id}&accessLevel=${selectedPrivilige}&replyPreference=${replyPreference}&name=${mailingListName}&entityType=${MailGunEmailType}&copyToSender=${sendCopyToSender}`;
    } else {
      let newEmailAlias = mgListAddressAlias;
      url = `/api/FacilityRegionEmail/UpdateEmailPrivilege?id=${id}&accessLevel=${selectedPrivilige}&replyPreference=${replyPreference}&name=${mailingListName}&newEmailAliasAddress=${newEmailAlias}@${mgListDomain}&newEmailAlias=${newEmailAlias}&entityType=${MailGunEmailType}&copyToSender=${sendCopyToSender}`;
    }

    $(".loading").show();
    axios
      .post(url, emailConfigData)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.hideSpinner();
          if (selectedPrivilige == "members") {
            this.props.getAuthSenders({
              emailEntityType: this.props.isTeam
                ? MailGunEmailTypes.Team
                : MailGunEmailTypes.Facility,
              emailListId: this.props.emailListId,
            });
          }
          toast.success(data.message);
        } else {
          this.hideSpinner();
          toast.error(data.message);
        }
        $(".loading").hide();
        this.resetState();
        this.props.onModalClose();
      })
      .catch((error) => {
        console.log("error block", error);

        $(".loading").hide();
      });
  };

  ///*****Add Mode****** Scenario
  addMailingList = async () => {
    const {
      MailGunEmailType,
      isRegistration = false,
      isTeam = false,
      isFacility = false,
      ChildTemplateId = 0,
      FacilityId = 0,
      TeamId = 0,
    } = this.props;
    let entityId = 0;
    if (isTeam) {
      entityId = TeamId;
    } else if (isFacility) {
      entityId = FacilityId;
    } else if (isRegistration) {
      entityId = ChildTemplateId;
    }
    let {
      mgListAddressAlias,
      selectedPrivilige,
      mailingListName,
      replyPreference,
      authorizedSendersMembers,
      authorizedSendersTeams,
    } = this.props.melstate;
    let emailAuthorizedSenders = [
      ...authorizedSendersMembers,
      ...authorizedSendersTeams,
    ];
    try {
      const result = await RegistrationsService.CreateFRTEmail(
        mgListAddressAlias,
        entityId,
        MailGunEmailType,
        mailingListName,
        selectedPrivilige,
        replyPreference,
        emailAuthorizedSenders
      );
      //debugger
      if (result != null) {
        if (isRegistration) {
          const {
            location: { pathname },
          } = this.props.history;
          debugger;
          if (pathname == "/admin/requests") {
            await this.props.getAdminRegisRecords();
          } else {
            await this.props.getPendingApprovalRegistrations();
          }
        }
        this.props.onModalClose();
        this.props.closeReviewRequestModal();
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  toggleDeleteEmailListModal = () => {
    this.setState({
      showDeleteEmailListModal: !this.state.showDeleteEmailListModal,
    });
  };
  render() {
    let { emailListExists, emailListId } = this.state;
    let { MailGunEmailType } = this.props;
    return (
      <AUX>
        <Modal
          size="xl"
          backdrop="static"
          centered
          show={this.props.showModal}
          //onHide={this.handleClose}
          onShow={this.onShow}
          onHide={this.onHide}
          //dialogClassName="modal-95w"
          className={
            MailGunEmailType == MailGunEmailTypes.Registrants
              ? "nested-cs-modal"
              : ""
          }
          //className="location(FRT/SavedSearches)CEL"
          // className="scroll-body mg-email-list locationFRTSavedSearchesCEL"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {emailListExists && (
                <h5 className="modal-title mt-0">{`MEMBER LIST FOR ${this.props
                  .mgListAddress || ""}`}</h5>
              )}
              {!emailListExists && (
                <h5 className="modal-title mt-0">{`CREATE NEW MEMBER LIST`}</h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(this.state.mgListAddress || !emailListExists) && (
              ///this.canView() == true && this.props.TeamCheck && /// This Modal is for multiple Entites
              <MailGunConfigurationTabs
                mgListAddress={this.state.mgListAddress}
                isCalledAPI={this.state.isCalledAPI}
                ssUpdateEmailListMode={emailListExists ? true : false} //Show all Tabs, and Default will be Subscribers Tab
                isModalPopUpOpended={true}
                currentEmailConfigTab={this.state.currentEmailConfigTab}
                emailListTitle={this.state.mailingListName || ""}
                emailListExists={this.state.emailListExists}
                ref={this.mailgunConfigurationTabComp}
                entityKey={
                  this.props.isTeam == true
                    ? this.props.TeamId
                    : this.props.isRegistration
                    ? this.props.ChildTemplateId
                    : this.props.FacilityId
                }
                hideActionIcons={EmailService.shouldHideActionIcons()}
                showTwoTabs={true}
                // emailEntityType ??????????
                emailEntityType={this.props.MailGunEmailType}
                emailListId={this.state.emailListId}
              />
            )}
            {_.isEmpty(this.state.mgListAddress) &&
              !this.props.isRegistration &&
              !_.isEmpty(this.props.emailListId) && (
                <div className="d-flex justify-content-center p-4">
                  <Spinner />
                </div>
              )}
            <br />
            <br />
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <div className="row">
              <div className="col-md-4 p-0">
                {EmailService.shouldHideActionIcons() &&
                (this.canAdd() == true || this.canUpdate() == true) ? (
                  <button
                    className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                    type="submit"
                    onClick={() => {
                      if (this.state.emailListExists) {
                        this.onSave(); //UpdaTe Mode
                      } else {
                        this.addMailingList(); // Add Mode
                      }
                    }}
                  >
                    {this.state.emailListExists
                      ? "Save Changes"
                      : "Create List"}
                  </button>
                ) : (
                  <button
                    className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light invisible"
                    type="submit"
                  >
                    Save Changes
                  </button>
                )}
              </div>
              <div className="col-md-2 pr-0 pl-0">
                <button
                  className=" btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light"
                  onClick={() => {
                    this.resetState();
                    this.props.onModalClose();
                  }}
                >
                  Cancel
                </button>
              </div>
              {this.state.emailListId !== 0 &&
              this.canDeleteEmailList() === true ? (
                <div className="col-md-6 pl-1 pr-1 d-flex justify-content-end">
                  <button
                    className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                    type="button"
                    onClick={this.toggleDeleteEmailListModal}
                  >
                    Delete Email List
                  </button>
                </div>
              ) : (
                <div className="col-md-6 pl-1 pr-1 d-flex justify-content-end invisible">
                  <button
                    className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                    type="button"
                  >
                    Delete Email List invisible
                  </button>
                </div>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <MG_DeleteEmailList
          modal={this.state.showDeleteEmailListModal}
          confirm={this.confirmEmailList}
          toggle={this.toggleDeleteEmailListModal}
        />
      </AUX>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      melstate: state.mailGunEmail,
    }),
    {
      ...mailGunEmailActions,
      ...registrationsAction,
      ...actionActivity,
    }
  )(MailGunMembersListModal)
);
