import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../PermissionFeature";
import Scope from "../Scope/Scope";
import _ from "lodash";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import RolesModal from "../../Shared/RolesModal";
import { Link } from "react-router-dom";
import Services from "../PermissionService";
import Permissions from "../General/Permissions";
import InfiniteScroll from "react-infinite-scroll-component";
import { isValid } from "redux-form";
import LinkedPositionContent from "../General/LinkedPositionContent";
import ConfirmLinkedPositionModal from "../General/ConfirmLinkedPositionModal";
import TeamPositionMembers from "../Positions/TeamPositionMembers";
import PermissionAlgo from "../General/PermissionAlgo";
import moment from "moment";
import DatePicker from "react-datepicker";
const ButtonToNavigate = ({ title, history, color, classes }) => (
  <Button
    type="button"
    onClick={() => history.push({ pathname: "/admin/positions" })}
    color={color}
    className={classes}
  >
    {title}
  </Button>
);

const modalHeadings = {
  Orignal: "Create New Team",
  Permissions: "Configure Permissions",
  Member: "Assign Member",
};
class EditTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.type,

      isParentNational: this.props.isParentNational,
      permissions: [],
      position: [],
      check: true,
      scopeData: _.cloneDeep(this.props.scopes),
      positionScopeData: [],
      parentTeamName: "",
      uid: this.props.uid || 0,
      name: "",
      modalheading: "Create New Team",
      currentSection: 1,
      submissionObject: {
        teamName: "",
        isNational: false,
        isSpecial: false,
        Scopes: [],
        Permissions: [],
        Positions: [],
      },
      positionTypes: [],
      teamName: "",
      linkedTeamData: null,
      CLPermissions: [],
      CLPositionName: "",
      CLTeamName: "",
      CLPermission: true,
      CLNewId: -1,
      positionConfIndex: -1,
      // Teampositionmembers.js column width
      columnWidth: 0,
      columnSecWidth: 0,
      columnFirWidth: 0,
    };
    if (this.props.type === "childteam") {
      this.state.submissionObject.parentTeamId = this.props.parentTeamId;
      this.state.isParentNational = this.props.isParentNational;
      this.state.parentScopeData = this.filterAllCheckedScope(
        _.cloneDeep(this.props.scopes)
      );
      this.state.scopeData = this.changeToFalse(
        _.cloneDeep(this.state.parentScopeData)
      );
    }

    this.confirmLinkedPosition = this.confirmLinkedPosition.bind(this);
    this.cloneTeamPermission = this.cloneTeamPermission.bind(this);
    this.getPositionTypes = this.getPositionTypes.bind(this);
    this.teamDetail = this.teamDetail.bind(this);
    this.sectionLoader = this.sectionLoader.bind(this);
    this.subSectionLoader = this.subSectionLoader.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.createTeamButton = this.createTeamButton.bind(this);
    this.buttonLoader = this.buttonLoader.bind(this);
    this.verifyTeamDetail = this.verifyTeamDetail.bind(this);
    this.verifyPermissions = this.verifyPermissions.bind(this);
    this.permissionPositionDetail = this.permissionPositionDetail.bind(this);
    this.teamPermissions = this.teamPermissions.bind(this);
    this.verifyData = this.verifyData.bind(this);
    this.backwardSection = this.backwardSection.bind(this);
    this.PermissionConfiguration = this.PermissionConfiguration.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.setHeading = this.setHeading.bind(this);
    this.cancelButtonBottom = this.cancelButtonBottom.bind(this);
    this.cancelButtonLoader = this.cancelButtonLoader.bind(this);
    this.savePermissionData = this.savePermissionData.bind(this);
    this.backButton = this.backButton.bind(this);
    this.savePermissionButton = this.savePermissionButton.bind(this);

    this.loadMemberData = this.loadMemberData.bind(this);
    this.positionPermissions = this.positionPermissions.bind(this);
    this.assignMember = this.assignMember.bind(this);
    this.saveMemberButton = this.saveMemberButton.bind(this);
    this.saveMemberData = this.saveMemberData.bind(this);
    this.loadMember = this.loadMember.bind(this);
    this.handleAddRow = this.handleAddRow.bind(this);
    this.handleChangePositionScope = this.handleChangePositionScope.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.handleChangeTeamName = this.handleChangeTeamName.bind(this);
    this.handleChangePositionName = this.handleChangePositionName.bind(this);
    this.handleSaveTeamPermission = this.handleSaveTeamPermission.bind(this);
    this.handleCreateTeam = this.handleCreateTeam.bind(this);
    this.handleCancelTeamPermission = this.handleCancelTeamPermission.bind(
      this
    );
    this.handleManagedLinkedTeam = this.handleManagedLinkedTeam.bind(this);
    this.handleResetTeamPermission = this.handleResetTeamPermission.bind(this);
    this.handleChangeTeamPermission = this.handleChangeTeamPermission.bind(
      this
    );
    this.handleConfigurePermissionSection = this.handleConfigurePermissionSection.bind(
      this
    );
    this.handleSavePositionPermission = this.handleSavePositionPermission.bind(
      this
    );
    this.handleCancelPositionPermission = this.handleCancelPositionPermission.bind(
      this
    );
    this.handleResetPosition = this.handleResetPosition.bind(this);
    this.handleChangePositionPermission = this.handleChangePositionPermission.bind(
      this
    );

    this.handleChangePositionScopeType = this.handleChangePositionScopeType.bind(
      this
    );
    this.resetEverything = this.resetEverything.bind(this);
    this.handleChangeTeamScope = this.handleChangeTeamScope.bind(this);
    this.handleSelectMember = this.handleSelectMember.bind(this);
    this.textValidation = this.textValidation.bind(this);
    this.handleChangStartDate = this.handleChangStartDate.bind(this);
    this.handleChangEndDate = this.handleChangEndDate.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.EditAssignMember = this.EditAssignMember.bind(this);
    this.fetchPositionColumns = this.fetchPositionColumns.bind(this);
  }
  fetchPositionColumns = async () => {
    let columnWidth = (($("#ET-MB").width() - 1) * 20) / 100;
    let columnFirWidth = (($("#ET-MB").width() - 0) * 20) / 100;
    let columnSecWidth = (($("#ET-MB").width() - 3) * 60) / 100;

    if (
      this.state.columnWidth != columnWidth ||
      this.state.columnSecWidth != columnSecWidth ||
      this.state.columnFirWidth != columnFirWidth
    ) {
      this.state.columnWidth = columnWidth;
      this.state.columnSecWidth = columnSecWidth;
      this.state.columnFirWidth = columnFirWidth;
      await this.setState({
        columnWidth: this.state.columnWidth,
        columnSecWidth: this.state.columnSecWidth,
        columnFirWidth: this.state.columnFirWidth,
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.type === "childteam" &&
      (this.state.isParentNational != nextProps.isParentNational ||
        this.state.submissionObject.parentTeamId != nextProps.parentTeamId)
    ) {
      this.state.submissionObject.parentTeamId = nextProps.parentTeamId;
      this.state.isParentNational = nextProps.isParentNational;
      this.state.parentScopeData = this.filterAllCheckedScope(
        _.cloneDeep(nextProps.scopes)
      );
      this.getPositionTypes();
      this.state.scopeData = this.changeToFalse(
        _.cloneDeep(this.state.parentScopeData)
      );
    }
  }
  ConvertPermissionToNewScheme(permissions) {
    let originalPermissions = [];
    const createPermissions = (permissions) => {
      permissions.map((item) => {
        originalPermissions.push(Object.assign({}, item));
        if (item.childs) {
          createPermissions(item.childs);
        }
      });
    };
    createPermissions(permissions);
    return originalPermissions;
  }

  cloneTeamPermission = (originalPermissions) =>
    originalPermissions.map((item) =>
      (({ isView, isAdd, isUpdate, isDelete, id }) => {
        return {
          permissionFeatureId: item.id,
          isView,
          isAdd,
          isUpdate,
          isDelete,
        };
      })(item)
    );

  createPositionPermissions() {
    if (this.state.teamPermissions) {
      const clone = (originalPermissions) =>
        originalPermissions.map((item) =>
          (({ isView, isAdd, isUpdate, isDelete }) => ({
            permissionFeatureId: item.permissionFeatureId,
            isView,
            isAdd,
            isUpdate,
            isDelete,
          }))(item)
        ); //Array.isArray(item) ? clone(item) : item);
      return clone(this.state.teamPermissions);
    }
    return [];
  }

  createPositionScope() {
    if (this.state.submissionObject.isNational) {
      this.state.positionScopeData = this.state.scopeData;
      this.setState({ positionScopeData: _.cloneDeep(this.state.scopeData) });
    } else {
      this.state.positionScopeData = this.filterAllCheckedScope(
        this.state.scopeData
      );

      this.setState({ positionScopeData: this.state.positionScopeData });
    }
  }

  handleCancelTeamPermission(e) {
    //e.preventdefault();
    this.state.submissionObject.Permissions = _.cloneDeep(
      this.state.teamPermissions
    );
    this.setState({ submissionObject: this.state.submissionObject });
  }

  handleChangeTeamScope(e) {
    let Result = this.handleChangeScopes(
      e,
      this.state.submissionObject,
      this.state.scopeData
    );
    this.state.scopeData = Result.Data;
    this.state.submissionObject = Result.gameChanger;
    this.setState({
      submissionObject: this.state.submissionObject,
      scopeData: this.state.scopeData,
    }); //************************
  }

  handleSaveTeamPermission(e) {
    //e.preventdefault();
    this.state.teamPermissions = _.cloneDeep(
      this.state.submissionObject.Permissions
    );
    this.setState({ teamPermissions: this.state.teamPermissions });
  }

  handleResetTeamPermission(e) {
    //e.preventdefault();
    this.state.teamPermissions = [];
    this.setState({ teamPermissions: this.state.teamPermissions });
  }
  handleCancelPositionPermission(e) {
    //e.preventdefault();
    let positionIndex = this.state.positionConfIndex;
    this.state.submissionObject.Positions[positionIndex] = _.cloneDeep([
      this.state.position[positionIndex],
    ])[0];
    this.setState({ submissionObject: this.state.submissionObject });
  }
  handleSavePositionPermission(e) {
    //e.preventdefault();
    let positionIndex = this.state.positionConfIndex;
    this.state.position[positionIndex] = _.cloneDeep([
      this.state.submissionObject.Positions[positionIndex],
    ])[0];
    this.setState({ position: this.state.position });
  }

  changeToFalse(data) {
    return data.map((item) => {
      item.isChecked = false;
      if (item.facility) {
        this.changeToFalse(item.facility);
      }
      return item;
    });
  }

  handleResetPosition(e) {
    //e.preventdefault();
    this.createPositionScope();
    this.state.position = [
      {
        PositionName: "",
        Permissions: this.createPositionPermissions(),
        IsOwner: true,
        isNational: false,
        PositionTypeId: null,
        Scopes: [],
        ScopeConfig: this.changeToFalse(
          _.cloneDeep(this.state.positionScopeData)
        ),
        PositionScopeType: 1,
        MemberObj: {
          SearchTerms: "",
          Offset: 0,
          Fetch: 10,
          Data: [],
          IsMore: true,
          FetchedFirst: true,
        },
        Members: [],
        //Member: {
        //    ID: null,
        //    MemberId: null,
        //    StartDate: null,
        //    EndDate: null
        //},
        LinkedPositionTeams: [],
        MemberName: null,
      },
    ];
    this.state.submissionObject.Positions = _.cloneDeep(this.state.position);
    this.setState({
      position: this.state.position,
      linkedTeamData: null,
      submissionObject: this.state.submissionObject,
    });
  }
  handleChangeTeamPermission(e) {
    var permissions = this.state.submissionObject.Permissions;
    this.state.submissionObject.Permissions = PermissionAlgo.changeChild(
      permissions,
      e
    );
    this.setState({ submissionObject: this.state.submissionObject });
  }

  handleChangePositionPermission(e) {
    let PositionIndex = this.state.positionConfIndex;
    var permissions = this.state.submissionObject.Positions[PositionIndex]
      .Permissions;
    this.state.submissionObject.Positions[
      PositionIndex
    ].Permissions = PermissionAlgo.changeChild(permissions, e);
    this.state.submissionObject.Positions[
      PositionIndex
    ].LinkedPositionTeams = [];
    this.setState({ submissionObject: this.state.submissionObject });
  }

  handleChangePositionScope(e) {
    //e.preventdefault();
    let PositionIndex = this.state.positionConfIndex;
    let Result = this.handleChangeScopes(
      e,
      this.state.submissionObject.Positions[PositionIndex],
      this.state.submissionObject.Positions[PositionIndex].ScopeConfig
    );
    this.state.submissionObject.Positions[PositionIndex].ScopeConfig =
      Result.Data;
    this.state.submissionObject.Positions[PositionIndex] = Result.gameChanger;
    this.setState({
      submissionObject: this.state.submissionObject,
    }); //************************
  }

  handleChangeScopes(e, gameChanger, Data) {
    let obj = e.target.dataset.obj;
    const checkAll = (scopeData, value) => {
      return scopeData.map((item) => {
        item.isChecked = value;
        if (Array.isArray(item.facility)) {
          checkAll(item.facility, value);
        }
        return item;
      });
    };

    let index = -1;
    switch (obj) {
      case "National":
        gameChanger.isNational = e.target.checked;
        gameChanger.Scopes = e.target.checked && [];
        if (Data) {
          Data = checkAll(Data, e.target.checked);
        }
        break;

      case "Region":
        index = Data.findIndex((el) => el.id == e.target.dataset.id);
        Data[index].isChecked = e.target.checked;
        gameChanger.isNational = false;
        if (Array.isArray(Data[index].facility)) {
          Data[index].facility = checkAll(
            Data[index].facility,
            e.target.checked
          );
        }
        gameChanger.Scopes = [];
        gameChanger.Scopes = this.createSubmissionScopeObject(Data);

        break;

      case "Facility":
        gameChanger.isNational = false;
        let regionIndex = Data.findIndex((el) => el.id == e.target.dataset.rid);
        let f = Data[regionIndex].facility;
        index = Data[regionIndex].facility.findIndex(
          (el) => el.id == e.target.dataset.id
        );
        Data[regionIndex].isChecked = !e.target.checked && false;
        Data[regionIndex].facility[index].isChecked = e.target.checked;

        gameChanger.Scopes = [];
        gameChanger.Scopes = this.createSubmissionScopeObject(Data);
        break;

      default:
    }
    return { gameChanger: gameChanger, Data: Data };
  }

  createSubmissionScopeObject(scopeData) {
    let filterScopes = this.filterAllCheckedScope(scopeData);
    let scopesArray = [];
    filterScopes.map((item) => {
      if (item.isChecked) {
        scopesArray.push({ RegionId: item.id, FacilityId: null });
      } else {
        item.facility.map((itemf) => {
          if (itemf.isChecked) {
            scopesArray.push({
              RegionId: itemf.regionId,
              FacilityId: itemf.id,
            });
          }
        });
      }
    });
    return scopesArray;
  }

  filterAllCheckedScope(scopeData) {
    var result = _.filter(_.cloneDeep(scopeData), {
      facility: [{ isChecked: true }],
    });
    result = _.map(result, (item) => {
      item.facility = item.facility.filter((x) => x.isChecked === true);
      return item;
    });
    return result;
  }
  handleChangePositionTypeDropDown = (e) => {
    const idx = e.target.dataset.id;

    this.state.submissionObject.Positions[idx].PositionTypeId = e.target.value;
    this.state.position[idx] = _.cloneDeep(
      this.state.submissionObject.Positions[idx]
    );
    this.setState({
      submissionObject: this.state.submissionObject,
      position: this.state.position,
    });
  };

  handleChangePositionName = (e) => {
    const idx = e.target.dataset.id;

    this.state.submissionObject.Positions[idx].PositionName = e.target.value;
    let error = this.notNullValidation(e.target.value);
    if (!error) {
      error = this.textValidation(e.target.value);
    }
    this.state.submissionObject.Positions[idx].PositionName_err = error;
    this.state.position[idx] = _.cloneDeep(
      this.state.submissionObject.Positions[idx]
    );
    this.setState({
      submissionObject: this.state.submissionObject,
      position: this.state.position,
    });
  };
  handleChangePositionIsOwner = (e) => {
    const idx = e.target.dataset.id;
    for (var i = 0; i < this.state.submissionObject.Positions.length; i++) {
      this.state.submissionObject.Positions[i].IsOwner = false;
    }
    this.state.submissionObject.Positions[idx].IsOwner = e.target.checked;
    this.state.position[idx] = _.cloneDeep(
      this.state.submissionObject.Positions[idx]
    );

    this.setState({
      submissionObject: this.state.submissionObject,
      position: this.state.position,
    });
  };
  handleChangePositionScopeType = (e) => {
    let positionIndex = this.state.positionConfIndex;
    this.state.submissionObject.Positions[positionIndex].PositionScopeType =
      e.target.value;
    this.setState({ submissionObject: this.state.submissionObject });
  };

  handleAddRow = () => {
    let obj = {
      PositionName: "",
      Permissions: this.createPositionPermissions(),
      IsOwner: false,
      PositionTypeId: null,
      isNational: false,
      Scopes: [],
      ScopeConfig: this.changeToFalse(
        _.cloneDeep(this.state.positionScopeData)
      ),
      PositionScopeType: 1,
      MemberObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: true,
        FetchedFirst: true,
      },
      Members: [],
      //Member : {
      //    ID: null,
      //    MemberId: null,
      //    StartDate: null,
      //    EndDate:null
      //},
      LinkedPositionTeams: [],
      MemberName: null,
    };
    this.state.position.push(_.cloneDeep([obj])[0]);
    this.state.submissionObject.Positions.push(_.cloneDeep([obj])[0]);
    this.setState({
      position: this.state.position,
      submissionObject: this.state.submissionObject,
    });
  };

  handleSelectMember = (e) => {
    let index = e.target.dataset.index;
    index = !index ? e.target.parentElement.dataset.index : index;
    let positionIndex = this.state.positionConfIndex;
    let memberIndex = this.state.memberConfIndex;
    let id = this.state.submissionObject.Positions[positionIndex].Members[
      memberIndex
    ].MemberId;
    let newId = this.state.submissionObject.Positions[positionIndex].MemberObj
      .Data[index].id;
    let position = this.state.position[positionIndex];
    if (
      !(
        !_.isEmpty(position.Members) &&
        position.Members.findIndex((x) => x.MemberId == newId) != -1
      )
    ) {
      this.state.submissionObject.Positions[positionIndex].Members[
        memberIndex
      ].ID = null;
      this.state.submissionObject.Positions[positionIndex].Members[
        memberIndex
      ].MemberId = newId; //id                ? id == newId                    ? null                    : newId                : newId;
      this.state.submissionObject.Positions[positionIndex].Members[
        memberIndex
      ].MemberName = this.state.submissionObject.Positions[positionIndex]
        .Members[memberIndex].MemberId
        ? this.state.submissionObject.Positions[positionIndex].MemberObj.Data[
            index
          ].name
        : null;

      this.setState({ submissionObject: this.state.submissionObject });
    }
  };
  handleChangStartDate = (date) => {
    let positionIndex = this.state.positionConfIndex;
    let memberIndex = this.state.memberConfIndex;
    let Member = this.state.submissionObject.Positions[positionIndex].Members[
      memberIndex
    ];
    Member.StartDate = date;
    this.setState({ submissionObject: this.state.submissionObject });
  };
  handleChangEndDate = (date) => {
    let positionIndex = this.state.positionConfIndex;
    let memberIndex = this.state.memberConfIndex;
    let Member = this.state.submissionObject.Positions[positionIndex].Members[
      memberIndex
    ];
    Member.EndDate = date;
    this.setState({ submissionObject: this.state.submissionObject });
  };
  handleManagedLinkedTeam = async (e, operation) => {
    let newId = e.target.dataset.id;
    let CLTeamName = e.target.dataset.name;
    let isowner = e.target.dataset.isowner;
    let positionIndex = this.state.positionConfIndex;
    let ids = this.state.submissionObject.Positions[positionIndex]
      .LinkedPositionTeams;
    if (newId) {
      let idIndex = ids.findIndex((x) => x.teamId == newId);
      switch (operation) {
        case "select":
          if (idIndex == -1) {
            $(".loading").show();
            let data = await Services.TeamPermissions(newId);
            if (data.statusCode == 200) {
              let teamperm = this.ConvertPermissionToNewScheme(data.result);
              let posperm = this.state.submissionObject.Positions[positionIndex]
                .Permissions;
              let exceedpermissions = [];

              posperm.map((item, index) => {
                if (item.isView) {
                  if (!teamperm[index].isView) {
                    exceedpermissions.push(
                      teamperm[index].featureName + " for view."
                    );
                  }
                }
                if (item.isAdd) {
                  if (!teamperm[index].isAdd) {
                    exceedpermissions.push(
                      teamperm[index].featureName + " for add."
                    );
                  }
                }
                if (item.isUpdate) {
                  if (!teamperm[index].isUpdate) {
                    exceedpermissions.push(
                      teamperm[index].featureName + " for update."
                    );
                  }
                }
                if (item.isDelete) {
                  if (!teamperm[index].isDelete) {
                    exceedpermissions.push(
                      teamperm[index].featureName + " for delete."
                    );
                  }
                }
              });
              if (exceedpermissions.length > 0) {
                this.setState({
                  CLPermissions: exceedpermissions,
                  CLPositionName: this.state.submissionObject.Positions[
                    positionIndex
                  ].PositionName,
                  CLTeamName: CLTeamName,
                  CLNewId: { teamId: newId, isOwner: isowner == "true" },
                });
                window.$("#ConfirmLinkedPositionModal").modal({
                  backdrop: "static",
                  keyboard: true,
                  show: true,
                });
                window
                  .$("#ConfirmLinkedPositionModal")
                  .on("hidden.bs.modal", function() {
                    if (!$("body").hasClass("modal-open")) {
                      $("body").addClass("modal-open");
                    }
                  });
              } else {
                this.state.submissionObject.Positions[
                  positionIndex
                ].LinkedPositionTeams.push({
                  teamId: newId,
                  isOwner: isowner == "true",
                });
              }
            }

            $(".loading").hide();
          }
          break;
        case "delete":
          if (idIndex != -1) {
            this.state.submissionObject.Positions[
              positionIndex
            ].LinkedPositionTeams.splice(idIndex, 1);
          }
          break;
        case "owner":
          if (idIndex != -1) {
            this.state.submissionObject.Positions[
              positionIndex
            ].LinkedPositionTeams[idIndex].isOwner = isowner == "true";
          }
          break;
        default:
      }

      this.setState({ submissionObject: this.state.submissionObject });
    }
  };
  confirmLinkedPosition() {
    if (this.state.CLNewId != null && this.state.CLNewId.teamId) {
      this.state.submissionObject.Positions[
        this.state.positionConfIndex
      ].LinkedPositionTeams.push(this.state.CLNewId);
      this.setState({
        submissionObject: this.state.submissionObject,
        CLNewId: null,
      });
    }
    window.$("#ConfirmLinkedPositionModal").modal("hide");
  }
  handleRemoveRow = async (e) => {
    let idx = e.target.dataset.idx;
    if (!this.state.position[idx].isOwner) {
      this.state.position.splice(idx, 1);
      this.state.submissionObject.Positions.splice(idx, 1);
      this.setState({
        position: this.state.position,
        submissionObject: this.state.submissionObject,
      });
    }
  };

  handleCreateTeam = async (e) => {
    $(".loading").show();
    if (this.validatePositions()) {
      if (this.validateTeamPermissions) {
        if (this.validateTeamDetail) {
          let data = await Services.SaveTeam(this.state.submissionObject);
          if (!_.isEmpty(data)) {
            if (data.statusCode == 200) {
              toast.success("Team created successfully!");
              this.resetEverything();
              this.props.setReloader();
              window.$("#CreateTeamModal").modal("hide");
            }
          }
        } else {
          this.setState({ currentSection: 1 });
        }
      } else {
        this.setState({ currentSection: 2 });
      }
    }

    $(".loading").hide();
  };
  async getPositionTypes() {
    $(".loading").show();
    let data = await Services.TeamPositionTypes();
    if (data != null) {
      await this.setState({ positionTypes: data.result });
    }
    $(".loading").hide();
  }
  performSlide(parentid) {
    let element = $(".slide-collapsers[data-slide-Id='slide" + parentid + "']");
    let expand = element.attr("data-slide-expand");

    const collapseChild = (parentid, expand) => {
      let element = $(".slide-collapsers[data-slide-Id='" + parentid + "']");

      if (expand) {
        $(".slide-collapsers[data-slide-parentId='" + parentid + "']").addClass(
          "perm-row-hide"
        );
      } else {
        let expandChild = element.attr("data-slide-expand");

        if (expandChild == "true") {
          $(
            ".slide-collapsers[data-slide-parentId='" + parentid + "']"
          ).removeClass("perm-row-hide");
        }
        //in case false
        else {
          expand = true;
        }
      }
      $(".slide-collapsers[data-slide-parentId='" + parentid + "']").each(
        function(i) {
          let parentid2 = $(this).attr("data-slide-Id");
          if (
            $(".slide-collapsers[data-slide-parentId='" + parentid2 + "']")
              .length > 0
          ) {
            collapseChild(parentid2, expand);
          }
        }
      );
    };

    if (expand == "true") {
      $(
        ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
      ).addClass("perm-row-hide");
      element.attr("data-slide-expand", false);
    } else {
      $(
        ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
      ).removeClass("perm-row-hide");
      element.attr("data-slide-expand", true);
    }
    collapseChild("slide" + parentid, expand == "true" ? true : false);
  }

  loadMember = () => {
    let positionIndex = this.state.positionConfIndex;
    let memberIndex = this.state.memberConfIndex;
    if (
      this.state.submissionObject.Positions[positionIndex].MemberObj
        .FetchedFirst
    ) {
      this.state.submissionObject.Positions[
        positionIndex
      ].MemberObj.FetchedFirst = false;
      this.loadMemberData();
    }
    let position = this.state.position[positionIndex];
    const MemberData = this.state.submissionObject.Positions[positionIndex]
      .MemberObj.Data;
    const Member = this.state.submissionObject.Positions[positionIndex].Members[
      memberIndex
    ];
    return (
      <Fragment>
        <p className="text-black-50">
          <b>Hint:</b> <i>Name [Email] Region/Facility</i>
        </p>
        <div id="MemberlistScroll">
          <InfiniteScroll
            dataLength={MemberData.length}
            next={this.loadMemberData}
            hasMore={
              this.state.submissionObject.Positions[positionIndex].MemberObj
                .IsMore
            }
            loader={<h4>Loading...</h4>}
            scrollableTarget="MemberlistScroll"
          >
            {MemberData.map((item, index) => {
              return (
                <div
                  className={
                    "c-pointer px-4 border py-2 card-text w-100" +
                    (item.id === Member.MemberId
                      ? " active"
                      : !_.isEmpty(position.Members) &&
                        position.Members.findIndex(
                          (x) => x.MemberId == item.id
                        ) != -1
                      ? " opacity-half"
                      : "")
                  }
                  onClick={this.handleSelectMember}
                  data-index={index}
                >
                  {item.name} <b>[{item.email}]</b>
                  {" - "}
                  {item.region + "/" + item.facility}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </Fragment>
    );
  };

  loadMemberData = async () => {
    let positionIndex = this.state.positionConfIndex;

    let MemberObj = this.state.submissionObject.Positions[positionIndex]
      .MemberObj;
    let data = await Services.LoadMembers(
      MemberObj.SearchTerms,
      MemberObj.Offset,
      MemberObj.Fetch
    );
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        console.log(data.result.length, "result length:");
        console.log(
          this.state.submissionObject.Positions[positionIndex].MemberObj.Data
            .length + 10,
          "data length"
        );
        this.state.submissionObject.Positions[positionIndex].MemberObj.IsMore =
          data.result.length ==
          this.state.submissionObject.Positions[positionIndex].MemberObj.Fetch;
        this.state.submissionObject.Positions[positionIndex].MemberObj.Data =
          data.result;
        this.state.submissionObject.Positions[positionIndex].MemberObj.Fetch =
          data.result.length ==
          this.state.submissionObject.Positions[positionIndex].MemberObj.Fetch
            ? MemberObj.Fetch + 10
            : MemberObj.Fetch;

        await this.setState({ submissionObject: this.state.submissionObject });
      }
    }
  };

  sectionLoader = () => {
    this.setHeading();
    switch (this.state.currentSection) {
      case 1:
        return this.teamDetail();
      case 2:
      case 3:
        return this.permissionPositionDetail();
      case 4:
        return this.teamPermissions();
      case 5:
        return this.positionPermissions();
      case 6:
        return this.assignMember();
      case 7:
        return this.linkedPosition();
      default:
        return <span></span>;
    }
  };
  subSectionLoader = () => {
    switch (this.state.currentSection) {
      case 2:
        return this.PermissionConfiguration();
      case 3:
        return this.positionConfiguration();
      default:
        return <span></span>;
    }
  };
  resetEverything() {
    if (this.props.type === "childteam") {
      this.state.parentScopeData = this.filterAllCheckedScope(
        _.cloneDeep(this.props.scopes)
      );
      this.state.scopeData = this.changeToFalse(
        _.cloneDeep(this.state.parentScopeData)
      );
      this.setState({ parentScopeData: this.state.parentScopeData });
    } else {
      this.state.scopeData = _.cloneDeep(this.props.scopes);
    }

    this.setState({
      scopeData: this.state.scopeData,
      positionScopeData: [],
      parentTeamName: "",
      uid: this.props.uid || 0,
      teamPermissions: [],
      currentSection: 1,
      submissionObject: {
        teamName: "",
        isNational: false,
        Scopes: [],
        Permissions: [],
        Positions: [],
      },
    });
  }
  cancelModal = (e) => {
    e.preventDefault();
    switch (this.state.currentSection) {
      case 1:
      case 2:
      case 3:
        this.resetEverything();
        break;
      case 4:
        this.handleCancelTeamPermission();
        this.setState({ currentSection: 2 });
        break;
      case 5:
      case 6:
      case 7:
        this.handleCancelPositionPermission();
        this.setState({ currentSection: 3 });
        break;
      default:
        this.setState({ currentSection: 1 });
        break;
    }
  };
  verifyData = (e) => {
    e.preventDefault();
    switch (this.state.currentSection) {
      case 1:
        this.verifyTeamDetail();
        break;
      case 2:
        this.verifyPermissions();
        break;
      case 3:
        this.handleCreateTeam();

        break;
      default:
        this.setState({ currentSection: 2 });
        break;
    }
  };
  buttonLoader = () => {
    switch (this.state.currentSection) {
      case 1:
      case 2:
        return this.nextButton();
      case 3:
        return this.createTeamButton();

      case 4:
      case 5:
      case 7:
        return this.savePermissionButton();
      case 6:
        return this.saveMemberButton();
      default:
        return <span></span>;
    }
  };

  setHeading = () => {
    switch (this.state.currentSection) {
      case 3:
        if (this.state.modalheading != modalHeadings.Orignal) {
          this.setState({ modalheading: modalHeadings.Orignal });
        }

        break;
      case 4:
      case 5:
        if (this.state.modalheading != modalHeadings.Permissions) {
          this.setState({ modalheading: modalHeadings.Permissions });
        }
        break;
      case 6:
        if (this.state.modalheading != modalHeadings.Member) {
          this.setState({ modalheading: modalHeadings.Member });
        }
        break;
      default:
        if (this.state.modalheading != modalHeadings.Orignal) {
          this.setState({ modalheading: modalHeadings.Orignal });
        }
        break;
    }
  };
  cancelButtonLoader = () => {
    switch (this.state.currentSection) {
      case 4:
      case 5:
      case 6:
      case 7:
        return (
          <button
            type="button"
            class="close text-white"
            onClick={this.cancelModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        );
        break;
      default:
        return (
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            onClick={this.cancelModal}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        );
    }
  };

  teamDetail = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            To create a new assign it a and assign it a scope.
          </div>
          {this.state.type == "childteam" && (
            <div className="col-12 d-lg-flex my-2">
              <div className="h4 mt-lg-1 text-primary mr-2 font-weight-bold">
                New Linked Team For:
              </div>
              <div className="h3 font-weight-bold">
                {this.props.parentTeamName}
              </div>
            </div>
          )}

          <div className="col-sm-10 col-12">
            <div className="form-group">
              <h4 className="text-primary font-weight-bold">Team Name</h4>
              <MDBInput
                value={this.state.submissionObject.teamName}
                name="teamName"
                onChange={this.handleChangeTeamName}
                className="bold-input"
                containerClass="mt-0 mb-2"
                group
                type="text"
                hint="Please Provide Team Name"
                required
              >
                <div className="invalid-feedback">Name is required.</div>
              </MDBInput>
              <p className="text-danger">
                {this.state.submissionObject.teamName_err}
              </p>
            </div>
          </div>
          <div className="d-none col-sm-2">
            <div className="card-text text-dark px-4 py-1 w-100 custom-checkbox">
              <input
                id={"isSpecial"}
                name={"isSpecial"}
                type="checkbox"
                data-obj="National"
                checked={this.state.submissionObject.isSpecial}
                onChange={(e) => {
                  this.state.submissionObject.isSpecial = e.target.checked;
                  this.setState({
                    submissionObject: this.state.submissionObject,
                  });
                }}
                className="custom-control-input"
              />
              <label className="custom-control-label" for={"isSpecial"}>
                isSpecial
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h4 class="text-primary font-weight-bold text-left">Team Scope</h4>
          </div>
        </div>
        {this.state.type === "childteam" ? (
          <Scope
            originalScopes={this.state.parentScopeData}
            scopes={this.state.scopeData}
            isTeamNational={this.state.isParentNational}
            hasParent={true}
            handleChangeScope={this.handleChangeTeamScope}
          />
        ) : (
          <Scope
            scopes={this.state.scopeData}
            national={this.state.submissionObject.isNational}
            handleChangeScope={this.handleChangeTeamScope}
          />
        )}
      </Fragment>
    );
  };
  permissionPositionDetail = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            Add one or more positions to this team. Each position determines a
            set of permissions that the assigned user will inherit. When a user
            is removed from a position the associated permissions will be
            removed.
          </div>

          <div className="col-12 mb-2">
            <h5 className="text-primary font-italic font-weight-bold">
              Creating...
            </h5>

            <h3 className="font-style-italic">{this.state.teamName}</h3>
          </div>
        </div>
        {this.subSectionLoader()}
      </Fragment>
    );
  };
  handleConfigurePermissionSection = async (e) => {
    if (this.state.originalPermissions) {
      if (this.state.teamPermissions.length == 0) {
        this.state.teamPermissions = this.cloneTeamPermission(
          this.state.originalPermissions
        );
        this.state.submissionObject.Permissions = this.cloneTeamPermission(
          this.state.originalPermissions
        );
        this.setState({
          submissionObject: this.state.submissionObject,
          teamPermissions: this.state.teamPermissions,
        });
      }
      this.setState({ currentSection: 4 });
    } else {
      $(".perm-btn.lds-ripple").show();
      let data =
        this.state.type === "childteam"
          ? await Services.TeamPermissions(this.props.parentTeamId)
          : await Services.TeamPermissions(null);
      !_.isEmpty(data) &&
        (await this.setState({
          originalPermissions: this.ConvertPermissionToNewScheme(data.result),
        }));
      this.state.submissionObject.Permissions = this.cloneTeamPermission(
        this.state.originalPermissions
      );
      this.state.teamPermissions = this.cloneTeamPermission(
        this.state.originalPermissions
      );
      this.setState({
        currentSection: 4,
        submissionObject: this.state.submissionObject,
        teamPermissions: this.state.teamPermissions,
      });
      $(".perm-btn.lds-ripple").hide();
    }
  };

  PermissionConfiguration = () => {
    return (
      <div className="row">
        <div className="col-12">
          <button
            onClick={this.handleConfigurePermissionSection}
            className="btn btn-success rounded-pills"
          >
            Configure Permission
            <div class="ml-2 perm-btn lds-ripple" style={{ display: "none" }}>
              <div></div>
              <div></div>
            </div>
          </button>
        </div>
      </div>
    );
  };

  teamPermissions = () => {
    return (
      <Permissions
        prefix={"NTP-"}
        permissionFeature={this.state.originalPermissions}
        permissions={this.state.submissionObject.Permissions}
        originalPermissions={this.state.originalPermissions}
        uncheckClass=" uncheck-light"
        handleChangePermission={this.handleChangeTeamPermission}
        hasParent={this.state.type === "childteam"}
        listId={
          this.props.type === "childteam"
            ? "childTeamPermission"
            : "createTeamPermission"
        }
      />
    );
  };
  positionConfiguration = () => {
    let { positionTypes } = this.state;
    return (
      <Fragment>
        {this.state.submissionObject.Positions.map((item, idx) => {
          return (
            <Fragment>
              <div className="row">
                <div className="col-12 text-danger text-left">
                  {this.state.submissionObject.IsOwner_err}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-10 col-lg-11 row pt-4 mx-0 px-0"
                  data-id={idx}
                >
                  {item.Members.length > 1 && (
                    <div className="col-lg-1 text-center">
                      <h5 className="mt-1">
                        <span
                          data-toggle="collapse"
                          data-target={"#PM" + idx}
                          aria-expanded="false"
                          aria-controls={"PM" + idx}
                          onClick={(e) => {
                            if ($(e.target).attr("aria-expanded") == "false") {
                              $(e.target).removeClass("fa-chevron-up");
                              $(e.target).addClass("fa-chevron-down");
                            } else {
                              $(e.target).removeClass("fa-chevron-down");
                              $(e.target).addClass("fa-chevron-up");
                            }
                          }}
                          className="fas fa-chevron-down"
                        ></span>
                      </h5>
                    </div>
                  )}

                  <div className="col-lg-3 pr-lg-2 mt-lg-n2">
                    <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                      Position Name:
                    </span>
                    {/*<MDBInput
                      containerClass="mt-2 mb-2"
                      className="py-0 mb-0"
                      data-id={idx}
                      name="PositionName"
                      
                      value={item.PositionName}
                      onChange={this.handleChangePositionName}
                      hint="position Name"
                      type="text"
                              />*/}
                    <input
                      type="text"
                      id="keyword"
                      className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                      placeholder="Position Name"
                      data-id={idx}
                      onChange={this.handleChangePositionName}
                      value={item.positionName}
                    />
                    <span className="text-danger">{item.PositionName_err}</span>
                  </div>
                  <div className="col-lg-2 pl-lg-2 mt-lg-n2">
                    <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                      Position Type:
                    </span>
                    <select
                      //className="browser-default custom-select"
                      onChange={this.changeHandlePositionTypeDropDown}
                      name="positiontype"
                      data-id={idx}
                      className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                    >
                      <option value="">Select Value</option>

                      {!_.isEmpty(positionTypes) &&
                        positionTypes.map((item, index) => {
                          return (
                            <option
                              value={item.id}
                              selected={
                                this.state.position.PositionTypeId == item.id
                              }
                            >
                              {item.positiontype}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div
                    className={
                      "col-lg-1 col-2 c-pointer text-center" +
                      (item.LinkedPositionTeams.length > 0
                        ? " text-success"
                        : " text-secondary")
                    }
                  >
                    <h4 className="mt-2">
                      <span
                        onClick={() => {
                          this.loadLinkedPosition(idx);
                        }}
                        className="fas fa-clipboard"
                      ></span>
                    </h4>
                  </div>

                  <div
                    className={
                      "col-lg-1 pb-lg-0 col-2 c-pointer text-center" +
                      (item.Members.length > 0
                        ? " pb-3 text-success"
                        : " text-secondary")
                    }
                  >
                    <h4 className="mt-2">
                      <span
                        onClick={() => {
                          if (
                            this.state.submissionObject.Positions[idx]
                              .IsOwner &&
                            this.state.submissionObject.Positions[idx].Members
                              .length == 0
                          ) {
                            this.state.submissionObject.Positions[
                              idx
                            ].Members.push({
                              ID: null,
                              MemberId: null,
                              MemberName: null,
                              StartDate: null,
                              EndDate: null,
                            });
                          } else {
                            if (
                              !this.state.submissionObject.Positions[idx]
                                .IsOwner
                            ) {
                              this.state.submissionObject.Positions[
                                idx
                              ].Members.push({
                                ID: null,
                                MemberId: null,
                                StartDate: null,
                                EndDate: null,
                              });
                            }
                          }
                          this.setState({
                            positionConfIndex: idx,
                            memberConfIndex:
                              this.state.submissionObject.Positions[idx].Members
                                .length - 1,
                            currentSection: 6,
                          });
                        }}
                        className="fas fa-user"
                        data-toggle="popover"
                      ></span>
                    </h4>
                    {item.Members.length == 1 && item.Members[0].MemberId && (
                      <div className="position-absolute assign-member text-nowrap mt-n4">
                        <div class="text-black-50 font-italic px-lg-0">
                          {item.Members[0].MemberName}
                        </div>
                      </div>
                    )}
                  </div>
                  {item.Members.length == 1 && (
                    <div className="col-lg-1 pb-lg-0 col-2 c-pointer text-center">
                      <h5 className="mt-2 text-danger">
                        <span
                          onClick={() => {
                            this.deleteMember({ Id: idx }, { Id: 0 });
                          }}
                          className="fas fa-user-times"
                        ></span>
                      </h5>
                    </div>
                  )}
                  {item.Members.length <= 1 && (
                    <div className="col-lg-1 col-2 px-lg-2 pt-lg-0 pt-2 text-left text-lg-center">
                      <span className="card-subtitle d-lg-block mr-lg-0 mr-2 mb-1 pr-1 text-secondary">
                        Owner
                      </span>
                      <span className="pb-lg-2 align-text-top">
                        <input
                          type="radio"
                          checked={item.IsOwner}
                          data-id={idx}
                          onChange={this.handleChangePositionIsOwner}
                          className="owner"
                        />
                      </span>
                    </div>
                  )}
                  <div className="col-lg-3 pr-lg-0 py-2 py-lg-0">
                    <button
                      onClick={() => {
                        this.setState({
                          positionConfIndex: idx,
                        });
                        this.setState({ currentSection: 5 });
                      }}
                      className="btn btn-success rounded-pills"
                    >
                      Configure Permission
                    </button>
                  </div>

                  <div className="clearfix"></div>
                </div>
                <div className="col-2 col-lg-1 pl-lg-3 p-2 pt-sm-3 pb-sm-1 align-self-center">
                  {!item.IsOwner && (
                    <div data-idx={idx}>
                      <button
                        type="button"
                        data-idx={idx}
                        onClick={this.handleRemoveRow}
                        className="btn btn-danger btn-sm"
                      >
                        <i data-idx={idx} className="fas fa-trash-alt" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="mb-3 col-12">
                  {item.PositionScope_err && (
                    <span className="text-danger d-block">
                      {item.PositionScope_err}
                    </span>
                  )}
                  {item.PositionPermission_err && (
                    <span className="text-danger d-block">
                      {item.PositionPermission_err}
                    </span>
                  )}
                </div>
                {item.Members.length > 1 && (
                  <div
                    className="mb-3 col-12 border-right pr-0 collapse"
                    id={"PM" + idx}
                  >
                    <TeamPositionMembers
                      position={(() => {
                        let position = _.cloneDeep(item);
                        position.Id = idx;
                        position.members = position.Members.map(
                          (item2, idxMem) => {
                            let member = {};
                            member.Id = idxMem;
                            member.memberName = item2.MemberName;
                            member.memberID = item2.MemberId;
                            return member;
                          }
                        );
                        return position;
                      })()}
                      deleteMember={this.deleteMember}
                      columnWidth={this.state.columnWidth}
                      columnFirWidth={this.state.columnFirWidth}
                      columnSecWidth={this.state.columnSecWidth}
                      setAssignPositionMember={this.EditAssignMember}
                    />
                  </div>
                )}
              </div>
            </Fragment>
          );
        })}

        <div className="row">
          <div className="col-12 border-top mt-2 pt-2 text-right">
            <button
              onClick={this.handleAddRow}
              className="btn btn-success rounded-pills"
            >
              Add Position
            </button>
          </div>
        </div>
      </Fragment>
    );
  };
  deleteMember(position, member) {
    this.state.submissionObject.Positions[position.Id].Members.splice(
      [member.Id],
      1
    );
    this.setState({
      submissionObject: this.state.submissionObject,
      position: this.state.submissionObject.Positions,
    });
  }
  EditAssignMember(position, member) {
    this.setState({
      positionConfIndex: position.Id,
      memberConfIndex: member.Id,
      currentSection: 6,
    });
  }

  positionPermissions = () => {
    let positionIndex = this.state.positionConfIndex;
    return (
      <Fragment>
        <Permissions
          prefix={"NPP" + positionIndex + "-"}
          permissionFeature={this.state.originalPermissions}
          permissions={
            this.state.submissionObject.Positions[positionIndex].Permissions
          }
          originalPermissions={this.state.teamPermissions}
          uncheckClass=" uncheck-danger"
          handleChangePermission={this.handleChangePositionPermission}
          hasParent={true}
          listId={"PositionPermission" + positionIndex}
        />

        <div className="row">
          <div className="col-12 pl-lg-4">
            <h4 className="pb-3 text-primary font-weight-bold">
              Position Scope
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 pt-2 pl-4">
            <div className="">
              <input
                type="radio"
                name="scope"
                onChange={this.handleChangePositionScopeType}
                value="1"
                checked={
                  this.state.submissionObject.Positions[positionIndex]
                    .PositionScopeType == "1"
                }
              />{" "}
              <label>Same as team</label>
            </div>

            <div className="">
              <input
                type="radio"
                name="scope"
                onChange={this.handleChangePositionScopeType}
                value="2"
                checked={
                  this.state.submissionObject.Positions[positionIndex]
                    .PositionScopeType == "2"
                }
              />{" "}
              <label>This Team</label>
            </div>
            <div className="">
              <input
                type="radio"
                name="scope"
                onChange={this.handleChangePositionScopeType}
                value="3"
                checked={
                  this.state.submissionObject.Positions[positionIndex]
                    .PositionScopeType == "3"
                }
              />{" "}
              <label>Custom</label>
            </div>
          </div>
          <div
            id="permissionScopeList"
            className={
              "col-lg-8" +
              (this.state.submissionObject.Positions[positionIndex]
                .PositionScopeType == "3"
                ? ""
                : " d-none")
            }
          >
            <Scope
              originalScopes={this.state.positionScopeData}
              scopes={
                this.state.submissionObject.Positions[positionIndex].ScopeConfig
              }
              national={
                this.state.submissionObject.Positions[positionIndex].isNational
              }
              isTeamNational={this.state.submissionObject.isNational}
              hasParent={true}
              handleChangeScope={this.handleChangePositionScope}
            />
          </div>
        </div>
      </Fragment>
    );
  };
  async loadLinkedPosition(idx) {
    if (!this.state.linkedTeamData) {
      const reqObj = (item) =>
        (({ isNational, Scopes }) => ({
          teamID: null,
          isNational, //,
          //Scopes,
        }))(item);

      let data = await Services.LoadLinkedTeams(
        reqObj(this.state.submissionObject)
      );
      if (data.statusCode == 200) {
        this.setState({ linkedTeamData: data.result });
      }
    }

    this.setState({ positionConfIndex: idx });
    this.setState({ currentSection: 7 });
  }
  linkedPosition() {
    let positionIndex = this.state.positionConfIndex;

    return (
      <LinkedPositionContent
        teamData={this.state.linkedTeamData}
        linkedPosition={
          this.state.submissionObject.Positions[positionIndex]
            .LinkedPositionTeams
        }
        positionMemberCount={
          this.state.submissionObject.Positions[positionIndex].Members.length
        }
        handleManagedLinkedTeam={this.handleManagedLinkedTeam}
        positionName={
          this.state.submissionObject.Positions[positionIndex].PositionName
        }
      />
    );
  }

  assignMember = () => {
    let positionIndex = this.state.positionConfIndex;
    let memberIndex = this.state.memberConfIndex;
    let Member = this.state.submissionObject.Positions[positionIndex].Members[
      memberIndex
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <h4 className="text-primary font-weight-bold">Choose Member</h4>
          </div>
          <div className="col-lg-8 text-center">
            <form role="search" className="app-search mb-3">
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control rounded border mb-1 py-2 w-100"
                  placeholder="Member Search.."
                  onChange={(e) => {
                    let positionIndex = this.state.positionConfIndex;
                    let MemberDataObj = this.state.submissionObject.Positions[
                      positionIndex
                    ].MemberObj;
                    MemberDataObj.Offset = 0;
                    MemberDataObj.Fetch = 10;
                    MemberDataObj.SearchTerms = e.target.value;
                    MemberDataObj.IsMore = true;
                    MemberDataObj.FetchedFirst = true;
                    this.state.submissionObject.Positions[
                      positionIndex
                    ].MemberObj = MemberDataObj;
                    this.setState({
                      submissionObject: this.state.submissionObject,
                    });
                  }}
                />
                <button
                  type="submit"
                  className="c-pointer text-secondary bg-transparent border-0"
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div id="memberList" className="col-lg-10">
          {this.loadMember()}
        </div>
        <div className="col-lg-10 row mx-0 mt-4">
          <div class="md-form col form-group mt-0 mb-2">
            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
              Start Date:
            </span>
            <DatePicker
              dateForm="MM/DD/YYYY"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              onChange={this.handleChangStartDate}
              selected={
                Member.StartDate ? moment(Member.StartDate).toDate() : null
              }
              placeholderText={"MM/DD/YYYY"}
            />
          </div>
          <div class="md-form col form-group mt-0 mb-2">
            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
              End Date:
            </span>
            <DatePicker
              dateForm="MM/DD/YYYY"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              onChange={this.handleChangEndDate}
              selected={Member.EndDate ? moment(Member.EndDate).toDate() : null}
              placeholderText={"MM/DD/YYYY"}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  nextButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-4">{this.backButton()}</div>
          <div className="col-8">
            <div className="text-right">
              {this.cancelButtonBottom()}
              <button
                onClick={this.verifyData}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  cancelButtonBottom = () => {
    switch (this.state.currentSection) {
      case 4:
      case 5:
      case 6:
      case 7:
        return (
          <button
            className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
            type="button"
            onClick={this.cancelModal}
          >
            Cancel
          </button>
        );
      default:
        return (
          <button
            className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.cancelModal}
          >
            Cancel
          </button>
        );
    }
  };
  createTeamButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-sm-4 col-3">{this.backButton()}</div>
          <div className="col-sm-8 col-9">
            <div className="text-right">
              {this.cancelButtonBottom()}
              <button
                onClick={this.verifyData}
                className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                type="submit"
              >
                Create Team
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  backButton = () => {
    return (
      this.state.currentSection > 1 && (
        <button
          onClick={this.backwardSection}
          className="btn btn-primary btn-sm btn-x-sm w-md waves-effect waves-light"
          type="submit"
        >
          Back
        </button>
      )
    );
  };
  savePermissionButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-4"></div>
          <div className="col-8">
            <div className="text-right">
              {this.cancelButtonBottom()}
              <button
                onClick={this.savePermissionData}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  saveMemberButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-4"></div>
          <div className="col-8">
            <div className="text-right">
              {this.cancelButtonBottom()}
              <button
                onClick={this.saveMemberData}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  verifyTeamDetail = (e) => {
    /*Verify Team Detail Code*/
    /*End Of Verify Team Detail Code*/
    if (this.validateTeamDetail()) {
      // this.state.submissionObject.Permissions = [];
      this.setState({
        currentSection: 2,
        /* teamPermissions: [],*/ submissionObject: this.state.submissionObject,
      });
    }
  };
  validateTeamDetail() {
    if (!this.notNullValidation(this.state.submissionObject.teamName)) {
      if (
        this.state.submissionObject.Scopes.length > 0 ||
        this.state.submissionObject.isNational
      ) {
        return true;
      } else {
        toast.error("Please select team scope.");
        return false;
      }
    } else {
      toast.error("Please provide appropriate team name.");
      return false;
    }
  }
  validatePositions() {
    let valid = true;
    let isOwner = false;
    let obj = this.state.submissionObject.Positions;
    if (!_.isEmpty(obj)) {
      obj.map((item, index) => {
        if (
          !this.notNullValidation(item.PositionName) &&
          !this.textValidation(item.PositionName)
        ) {
          this.state.submissionObject.Positions[index].PositionName_err = "";
        } else {
          this.state.submissionObject.Positions[index].PositionName_err =
            "Please provide appropriate position name.";
          valid = false;
        }

        if (
          item.PositionScopeType == 3 &&
          (item.Scopes.length > 0 || item.isNational)
        ) {
          this.state.submissionObject.Positions[index].PositionScope_err = "";
        } else {
          if (item.PositionScopeType == 1 || item.PositionScopeType == 2) {
            this.state.submissionObject.Positions[index].PositionScope_err = "";
          } else {
            this.state.submissionObject.Positions[index].PositionScope_err =
              "Please provide appropriate position scope.";
            valid = false;
          }
        }
        let ValidData = item.Permissions.findIndex(
          (el) =>
            el.isAdd == true ||
            el.isDelete == true ||
            el.isUpdate == true ||
            el.isView == true
        );
        if (ValidData > -1) {
          this.state.submissionObject.Positions[index].PositionPermission_err =
            "";
        } else {
          this.state.submissionObject.Positions[index].PositionPermission_err =
            "Please provide appropriate position permissions.";
          valid = false;
        }
        if (item.IsOwner) {
          isOwner = true;
        }
      });
      this.state.position = _.cloneDeep(this.state.submissionObject.Positions);
      this.setState({
        submissionObject: this.state.submissionObject,
        position: this.state.position,
      });
      if (!isOwner) {
        toast.error("Please, select at least one position as a owner");
      }
    } else {
      valid = false;
      toast.error("Please, provide at least one position.");
    }
    return valid;
  }

  verifyPermissions = (e) => {
    if (this.validateTeamPermissions()) {
      this.handleResetPosition();
      this.fetchPositionColumns();
      this.setState({ currentSection: 3 });
    }
  };
  validateTeamPermissions() {
    let teamPermissions = this.state.submissionObject.Permissions;
    let ValidData = teamPermissions.findIndex(
      (el) =>
        el.isAdd == true ||
        el.isDelete == true ||
        el.isUpdate == true ||
        el.isView == true
    );
    if (ValidData > -1) {
      return true;
    } else {
      toast.error("Please set appropriate permission.");
      return false;
    }
  }

  backwardSection = (e) => {
    e.preventDefault();
    switch (this.state.currentSection) {
      case 2:
        //   this.handleResetTeamPermission();
        this.setState({ currentSection: 1 });
        break;
      case 3:
        this.setState({ currentSection: 2 });
        break;
      default:
        break;
    }
  };
  savePermissionData = (e) => {
    e.preventDefault();
    /*Permission Save Data Code*/
    switch (this.state.currentSection) {
      case 4:
        this.handleSaveTeamPermission();
        this.setState({ currentSection: 2 });
        break;
      case 5:
      case 7:
        this.handleSavePositionPermission();
        this.setState({ currentSection: 3 });
        break;

      default:
        this.setState({ currentSection: 2 });
        break;
    }
  };
  saveMemberData = (e) => {
    e.preventDefault();
    this.handleSavePositionPermission();
    this.setState({ currentSection: 3 });
  };

  componentWillUnmount() {}
  componentDidMount() {
    $(window).resize(this.fetchPositionColumns);
    this.getPositionTypes();
  }

  handleChangeTeamName = (evt) => {
    this.state.submissionObject[
      evt.target.name + "_err"
    ] = this.notNullValidation(evt.target.value);
    this.state.submissionObject[evt.target.name] = evt.target.value;
    // if (!this.state.submissionObject[evt.target.name + "_err"]) {
    //     this.state.submissionObject[
    //         evt.target.name + "_err"
    //     ] = this.textValidation(evt.target.value);
    // }
    this.setState({ submissionObject: this.state.submissionObject });
  };
  scopeChange = (e) => {
    const type = e.target.selectedOptions[0].text.toUpperCase();
    this.state.scope = {
      Code: e.target.selectedOptions[0].dataset.code,
      Description: e.target.selectedOptions[0].text,
      Id: e.target.value,
      positionScopeId: 0,
      positionScopeName: "",
    };
    this.setState({
      scope: this.state.scope,
    });
    if (type != "NATIONAL1") {
      this.setState({
        ScopeType: type,
      });
    }
  };

  textValidation = (text) => {
    let textReg = /^[a-zA-z0-9\- ]*$/;
    const text_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Please provide only text.",
      },
    ];
    if (textReg.test(text)) {
      return text_States.find(({ state }) => state == "Valid").error;
    } else {
      return text_States.find(({ state }) => state == "Not_Match_To_Regex")
        .error;
    }
  };
  notNullValidation(text) {
    if (text == null || text == "") {
      return "Please provide the above value";
    }
    return null;
  }

  render() {
    let ModalId = "CreateTeamModal";
    return (
      <AUX>
        <div
          class="modal fade team-style"
          id={ModalId}
          tabindex="-1"
          role="dialog"
          aria-labelledby={ModalId + "Title"}
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5
                  class="modal-title text-uppercase text-white mt-0"
                  id={ModalId + "Title"}
                >
                  {this.state.modalheading}
                </h5>
                {this.cancelButtonLoader()}
              </div>

              <div className="modal-body" id="ET-MB">
                <div>
                  {this.sectionLoader()}

                  {this.buttonLoader()}
                </div>
                {/* <RolesModal rolesMethods={this.rolesMethods} />*/}
              </div>
            </div>
          </div>
        </div>
        <ConfirmLinkedPositionModal
          PositionName={this.state.CLPositionName}
          TeamName={this.state.CLTeamName}
          Permissions={this.state.CLPermissions}
          confirmLinkedPosition={this.confirmLinkedPosition}
          permission={this.state.CLPermission}
        />
      </AUX>
    );
  }
}

export default EditTeam;
