import React, { Fragment, Component } from "react";
import AUX from "../../../hoc/Aux_";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
import _ from "lodash";
import AuthService from "../../Auth/AuthService";
const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
  //  onSelect: this.handleOnSelect,
  //  onSelectAll: this.handleOnSelectAll
};
class Scope extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      page: 1,
      sizePerPage: 10,
      //For Send Scopes to API
      Scope: {},
      totalCount: "",
      expanded: [0, 1],
      national: this.props.national,
      delId: 0,
      searchTerm: "",
    };
    this.handleFormSearch = this.handleFormSearch.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeNational = this.handleChangeNational.bind(this);
    this.handleOnExpand = this.handleOnExpand.bind(this);

    this.columns = [
      {
        dataField: "regionCode",
        //headerClasses: 'bg-dark text-white px-3 py-2',
        classes: "px-3 py-2",
        sort: false,
      },
      //{
      //  text: "Scope",
      //  dataField: "scopeType",
      //  sort: true
      //},
    ];
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (e.target.className.indexOf("del") < 0) {
          if (this.state.perms.canEdit) {
            //this.props.history.push({
            //  pathname: "/admin/positions/edit-position",
            //  type: "edit",
            //  uid: row.id,
            //  keyword: $("#keyword").val()
            //});
          }
        }
      },
    };

    this.listScope = this.listScope.bind(this);
  }
  handleChangeNational(e) {
    //  e.preventDefault();
    this.state.national = e.target.checked;
    this.setState({ national: this.state.national });
  }
  handleChange(e) {
    //e.preventDefault();
    let obj = e.target.dataset.obj;
    let index = -1;
    switch (obj) {
      case "National":
        this.state.national = e.target.checked;
        for (var i = 0; i < this.state.scopes.length; i++) {
          this.state.scopes[i].isChecked = e.target.checked;
          if (this.state.scopes[i].facility) {
            for (var f = 0; f < this.state.scopes[i].facility.length; f++) {
              this.state.scopes[i].facility[f].isChecked = e.target.checked;
            }
          }
        }

        break;

      case "Region":
        index = e.target.dataset.index;
        this.state.scopes[index].isChecked = e.target.checked;
        if (this.state.scopes[index].facility) {
          for (var i = 0; i < this.state.scopes[index].facility.length; i++) {
            this.state.scopes[index].facility[i].isChecked = e.target.checked;
          }
        }
        this.state.Scope = this.state.scopes.find(
          (el) => el.isChecked === true
        );
        break;

      case "Facility":
        index = e.target.dataset.index;
        alert(index);
        break;

      default:
    }
    this.setState({ data: this.state.scopes, national: this.state.national });
  }

  nationalLi(handleChangeScope) {
    return (
      <li>
        <div className="card-text bg-primary text-white px-4 py-1 w-100 custom-checkbox">
          <input
            id={"nationalScope"}
            name={"nationalScope"}
            type="checkbox"
            data-obj="National"
            checked={this.props.national}
            onChange={handleChangeScope}
            className="custom-control-input"
          />
          <label className="custom-control-label" for={"nationalScope"}>
            National
          </label>
        </div>
      </li>
    );
  }

  regionInput(item, index, handleChangeScope) {
    return (
      <Fragment>
        <input
          id={"region" + index}
          name={"region" + index}
          type="checkbox"
          data-obj="Region"
          data-id={item.id}
          checked={item.isChecked}
          onChange={handleChangeScope}
          className="custom-control-input pr-2"
        />

        <label className="custom-control-label" for={"region" + index}>
          {item.code}
        </label>
        <div
          onClick={(e) => this.performSlideS(item.id)}
          data-slide-expand={false}
          data-slide-Id={"slideS" + item.id}
          className=" scope-colaps"
        ></div>
      </Fragment>
    );
  }
  performSlideS(parentid) {
    $("#slideS" + parentid).slideToggle();
    // debugger;
    // let element = $(
    //   ".slide-collapsers[data-slide-Id='slideS" + parentid + "']"
    // );
    // let expand = element.attr("data-slide-expand");
    // if (expand == "true") {
    //   $(
    //     ".slide-collapsers[data-slide-parentId='slideS" + parentid + "']"
    //   ).addClass("perm-row-hide");
    //   element.attr("data-slide-expand", false);
    // } else {
    //   $(
    //     ".slide-collapsers[data-slide-parentId='slideS" + parentid + "']"
    //   ).removeClass("perm-row-hide");
    //   element.attr("data-slide-expand", true);
    // }
  }
  listScope = (handleChangeScope) => {
    return (
      <div className="scopelist">
        <ul className="">
          {AuthService.canNational() &&
            _.isEmpty(this.state.searchTerm) &&
            (!this.props.hasParent
              ? this.nationalLi(handleChangeScope)
              : this.props.isTeamNational &&
                this.nationalLi(handleChangeScope))}

          {!_.isEmpty(this.props.scopes) &&
            this.props.scopes
              .filter(
                (itemQ) =>
                  _.toLower(itemQ.code).includes(
                    _.toLower(this.state.searchTerm || "")
                  ) ||
                  itemQ.facility.find(
                    (itemF) =>
                      _.toLower(itemF.code).includes(
                        _.toLower(this.state.searchTerm || "")
                      ) ||
                      _.toLower(itemF.description).includes(
                        _.toLower(this.state.searchTerm || "")
                      )
                  )
              )
              .map((item, index) => {
                return (
                  <li className="" key={"reg-" + index}>
                    <div className="slide-collapsers perm-row text-white px-4 py-1 bg-dark card-text w-100 custom-checkbox">
                      {!this.props.hasParent ? (
                        this.regionInput(item, index, handleChangeScope)
                      ) : this.props.originalScopes[index].isChecked ? (
                        this.regionInput(item, index, handleChangeScope)
                      ) : (
                        <Fragment>
                          <input
                            id={"region"}
                            name={"region"}
                            type="checkbox"
                            disabled
                            className="custom-control-input pr-2"
                          />

                          <label
                            className="custom-control-label"
                            for={"region"}
                          >
                            {item.code}
                          </label>
                        </Fragment>
                      )}
                    </div>
                    {item.facility.length > 0 && (
                      <ul id={"slideS" + item.id} className="perm-row-hide">
                        {item.facility
                          .filter(
                            (itemQ) =>
                              _.toLower(itemQ.code).includes(
                                _.toLower(this.state.searchTerm || "")
                              ) ||
                              _.toLower(itemQ.description).includes(
                                _.toLower(this.state.searchTerm || "")
                              )
                          )
                          .map((item2, index2) => {
                            return (
                              <li>
                                <div className="card-text px-4 py-1 w-100 custom-checkbox">
                                  <input
                                    id={"facility_" + index + "" + index2}
                                    name={"facility_" + index + "" + index2}
                                    type="checkbox"
                                    data-obj="Facility"
                                    data-id={item2.id}
                                    data-rid={item2.regionId}
                                    onChange={handleChangeScope}
                                    checked={item2.isChecked}
                                    className="custom-control-input"
                                  />
                                  <label
                                    className="custom-control-label"
                                    for={"facility_" + index + "" + index2}
                                  >
                                    {item2.code} - {item2.description}
                                  </label>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </li>
                );
              })}
        </ul>
      </div>
    );
  };
  handleBtnClick = () => {
    if (!this.state.expanded.includes(2)) {
      this.setState(() => ({
        expanded: [...this.state.expanded, 2],
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter((x) => x !== 2),
      }));
    }
  };

  handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      this.setState(() => ({
        expanded: [...this.state.expanded, row.id],
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter((x) => x !== row.id),
      }));
    }
  };

  componentDidMount() {
    //if (this.props.location.keyword) {
    //    $("#keyword").val(this.props.location.keyword);
    //}
    //this.getPositions();
  }
  handleFormSearch(e) {
    e.preventDefault();
    $(".loading").show();
    let url = `/api/Position/GetPositions`;
    const formData = {
      Keyword: $("#keyword").val(),
      Name: "",
      PageNo: 1,
      PageSize: 10,
      SortOrder: "NameAsc",
      ScopeType: "",
      Facility: "",
      Region: "",
      Area: "",
      Role: "",
      OperatorType: "AND",
    };

    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          page: 1,
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }

  render() {
    const { data, sizePerPage, page, totalCount } = this.state;
    const handleChangeScope = this.props.handleChangeScope;
    return (
      <AUX>
        <div className="row align-items-center">
          <div className="col-12 text-center">
            <form role="search" className="app-search mb-3">
              <div className="form-group mb-0">
                <input
                  type="text"
                  id="keyword"
                  className="form-control rounded border mb-1 py-2 w-100"
                  placeholder="Scope Search.."
                  autoComplete="off"
                  value={this.state.searchTerm || ""}
                  onChange={(event) =>
                    this.setState({ searchTerm: event.target.value })
                  }
                />
                <button
                  type="submit"
                  className="c-pointer text-secondary bg-transparent border-0"
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">{this.listScope(handleChangeScope)}</div>
        </div>
      </AUX>
    );
  }
}

export default Scope;
