import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Payfile/payfilestyle.css";
import { connect } from "react-redux";
import FinanceAction from "../../../store/finance/action";
import { makeStyles } from "@material-ui/core/styles";
import Services from "../../Payfile/PayfileService";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { debounce } from "throttle-debounce";
import TextField from "@material-ui/core/TextField";
import AuthService from "../../Auth/AuthService";
import FinanceService from "../FinanceService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import TransactionTable from "./TransactionTable";
import { MDBInput } from "mdbreact";
import { toast } from "react-toastify";
import $ from "jquery";
let formate_textbox_amount = (n) => {
  var num_parts = n
    .toString()
    .replaceAll(",", "")
    .split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
  //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};
let formObject = {
  Id: null,
  FinanceId: null,
  Amount: null,
  Refund: false,
  DuesPostedTypeId: 2,
  FinanceCheckNumber: null,
  TransactionDate: null,
};

class TransactionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TI: -1,
      AddForm: true,
      financeObj: null,
      openModal: false,
      updateData: [],
      SubmissionObj: _.clone(formObject),
      BackUp_SubmissionObj: {},
      DuesPostedTypes: [],
      FinanceLoader: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.LoadUpdatesData = this.LoadUpdatesData.bind(this);
    this.changeHandlerDropdown = this.changeHandlerDropdown.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.handleChangeRefund = this.handleChangeRefund.bind(this);
    this.loadDropdowns = this.loadDropdowns.bind(this);
    this.amountValidation = this.amountValidation.bind(this);
    this.ResetObject = this.ResetObject.bind(this);
    this.dateValidate = this.dateValidate.bind(this);
    //        this.SetFacilityObj = this.SetFacilityObj.bind(this);
  }
  dateValidate(date, name, state) {
    let error = "Please provide " + name + ".";
    if (moment(date).isValid()) {
      error = "";
    }
    this.state.SubmissionObj[state] = error;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
    return error;
  }
  async ResetObject() {
    let obj = _.clone(formObject);
    //in case objec already reset then not update the state for componentdidupdate method
    if (JSON.stringify(obj) != JSON.stringify(this.state.SubmissionObj)) {
      await this.setState({
        AddForm: true,
        TI: -1,
        SubmissionObj: obj,
        BackUp_SubmissionObj: _.cloneDeep(obj),
      });
    }
  }

  componentDidMount() {
    this.loadDropdowns();
  }
  async loadDropdowns() {
    let data = await FinanceService.FinanceDropdowns();
    await this.setState({
      FinanceLoader: true,
    });

    if (data != null) {
      this.state.DuesPostedTypes = data.result.duesPostedTypeDD;
    }
    await this.setState({
      DuesPostedTypes: this.state.DuesPostedTypes,
      FinanceLoader: false,
    });
  }
  async LoadUpdatesData(transactionFormData = null) {
    let obj = _.cloneDeep(this.state.SubmissionObj);
    if (transactionFormData != null) {
      obj.Id = this.state.TI;
      obj.DuesPostedTypeId = transactionFormData.duesPostedTypeId;
      obj.Amount = transactionFormData.amount;
      obj.TransactionDate = transactionFormData.transactionDate;
      obj.Refund = transactionFormData.refund;
      obj.FinanceId = transactionFormData.FinanceId;
      obj.FinanceCheckNumber = transactionFormData.financeCheckNumber;
    } else {
      await this.ResetObject();
    }
    await this.setState({
      SubmissionObj: obj,
      BackUp_SubmissionObj: _.cloneDeep(obj),
      FinanceLoader: false,
    });
  }
  handleChangeRefund = (e) => {
    this.state.SubmissionObj.Refund = e.target.checked;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  amountValidation(amount, fieldName) {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    let error = "";
    if (!amount || amount == null || amount == "" || amount == undefined) {
      error = "Invalid Amount!";
    } else if (amountReg.test(amount)) {
      error = "";
    } else {
      error = "Invalid amount";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  handleAmountChange = (evt) => {
    if (evt.target.name == "ExpectedAmount" || evt.target.name == "Amount") {
      this.amountValidation(
        evt.target.value.replaceAll(",", ""),
        evt.target.name
      );
      this.state.SubmissionObj[evt.target.name] = evt.target.value.replaceAll(
        ",",
        ""
      );
    } else {
      this.state.SubmissionObj[evt.target.name] = evt.target.value.replaceAll(
        ",",
        ""
      );
    }

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };

  changeHandlerDropdown = (e) => {
    this.state.SubmissionObj[e.target.name] = e.target.value;

    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    this.DropDownValidate(e.target.value, e.target.name);
  };

  changeHandler_amount = (e) => {
    this.state.SubmissionObj[e.target.name] = e.target.value;
    this.state.SubmissionObj[e.target.name + "_err"] = this.amountValidation(
      e.target.value
    ).error;

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };

  DropDownValidate(val, fieldName) {
    let error = "";
    if (!val || val == null || val == "" || val < 1) {
      error = "Please, select appropriate value";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }

  async submitForm() {
    let obj = this.state.SubmissionObj;
    if (
      this.DropDownValidate(obj.DuesPostedTypeId, "DuesPostedTypeId") == "" &&
      this.amountValidation(obj.Amount, "Amount") == "" &&
      (!obj.TransactionDate ||
        this.dateValidate(obj.TransactionDate, "TransactionDate") == "")
    ) {
      $(".loading").show();
      obj.FinanceId = this.state.financeObj.id;
      var data = await FinanceService.FinanceTransactionCU(obj);
      $(".loading").hide();
      if (data && data.statusCode == 200) {
        this.props.refreshFinanceTable();
        this.props.refreshTransactionTable();
      }
    }
  }

  async componentDidUpdate(prevprops) {
    let { transactionFinanceObj } = this.props.Finance;
    if (
      this.props.Finance.openTransactionModal &&
      transactionFinanceObj != null
    ) {
      let { financeObj } = this.state;
      if (this.props.Finance.transactionFormData !== null) {
        let { transactionFormData } = this.props.Finance;
        if (transactionFormData.id != this.state.TI) {
          await this.setState({
            financeObj: transactionFinanceObj,
            TI: transactionFormData.id,
            FinanceLoader: true,
            AddForm: false,
            SubmissionObj: _.cloneDeep(formObject),
          });
          this.LoadUpdatesData(transactionFormData);
        }
      } else {
        if (
          JSON.stringify(financeObj) != JSON.stringify(transactionFinanceObj)
        ) {
          await this.setState({ financeObj: transactionFinanceObj });
        }
        await this.ResetObject();
      }

      this.openModal();
    }
  }

  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeTransactionModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
  }
  cancelUpdate() {
    this.setState({
      SubmissionObj: this.state.BackUp_SubmissionObj,
      FormCode: -1,
      AddForm: true,

      TI: -1,
    });
    this.closeModal();
  }

  render() {
    let { financeObj } = this.state;

    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          fontSize: 18,
        },
      },
    });
    let { DuesPostedTypes } = this.state;
    let obj = this.state.SubmissionObj;
    let header = "";

    if (financeObj != null) {
      header =
        " - " +
        (financeObj.firstName || "").toUpperCase() +
        " " +
        (financeObj.lastName || "").toUpperCase() +
        " (" +
        (!_.isEmpty(financeObj.regionCode) ? financeObj.regionCode : "--") +
        "/" +
        (!_.isEmpty(financeObj.facilityCode) ? financeObj.facilityCode : "--") +
        ")";

      return (
        <>
          <Modal
            centered={true}
            autoFocus={true}
            isOpen={this.state.openModal}
            size="lg"
            className="styled-modal"
            backdrop="static"
          >
            <ModalHeader
              className="modal-header bg-secondary py-2"
              toggle={this.cancelUpdate}
            >
              TRANSACTION FORM{header}
            </ModalHeader>
            <ModalBody>
              {((this.state.AddForm &&
                AuthService.canSPAdd(
                  PermissionFeature.FinanceTransaction,
                  financeObj.regionId,
                  financeObj.facilityId,
                  financeObj.memberID
                )) ||
                (!this.state.AddForm &&
                  AuthService.canSPUpdate(
                    PermissionFeature.FinanceTransaction,
                    financeObj.regionId,
                    financeObj.facilityId,
                    financeObj.memberID
                  ))) && (
                <>
                  {this.state.FinanceLoader ? (
                    <div
                      class="m-3 position-relative d-block mx-auto spinner-border text-info"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    !this.state.FinanceLoader &&
                    obj != null && (
                      <>
                        <div className="row mx-0 mt-2 mb-2">
                          <div className="col-md-4">
                            <label>Amount</label>

                            <div className="">
                              {this.state.AddForm ||
                              (!this.state.AddForm &&
                                AuthService.canSPUpdate(
                                  PermissionFeature.FinanceTransactionAmount,
                                  financeObj.regionId,
                                  financeObj.facilityId,
                                  financeObj.memberID
                                )) ? (
                                <>
                                  <MDBInput
                                    containerClass="mt-2 mb-2 small-prefix"
                                    className="py-0 mb-0 ml-3"
                                    name="Amount"
                                    hint="$$"
                                    icon="dollar-sign"
                                    value={
                                      obj.Amount != null
                                        ? formate_textbox_amount(obj.Amount)
                                        : ""
                                    }
                                    onChange={this.handleAmountChange}
                                    disabled={
                                      !AuthService.canMemberUpdate(
                                        PermissionFeature.FinanceTransactionAmount
                                      )
                                    }
                                    group
                                    type="text"
                                  />
                                  <span className="d-block text-danger">
                                    {obj.Amount_Err}
                                  </span>
                                </>
                              ) : (
                                <div>{obj.Amount}</div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <label>Dues Posted Type</label>
                            {this.state.AddForm ||
                            (!this.state.AddForm &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceTransactionCheckNumber,
                                financeObj.regionId,
                                financeObj.facilityId,
                                financeObj.memberID
                              )) ? (
                              <>
                                <select
                                  //className="browser-default custom-select"
                                  onChange={this.changeHandlerDropdown}
                                  name="DuesPostedTypeId"
                                  className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                >
                                  <option value="">Select Value</option>

                                  {DuesPostedTypes.map((item, index) => {
                                    return (
                                      <option
                                        value={item.id}
                                        selected={
                                          obj.DuesPostedTypeId == item.id
                                        }
                                      >
                                        {item.type}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label className="text-danger">
                                  {obj.DuesPostedTypeId_Err}
                                </label>
                              </>
                            ) : (
                              <div>
                                {(!_.isEmpty(DuesPostedTypes) &&
                                  DuesPostedTypes.find(
                                    (x) => x.id == obj.DuesPostedTypes
                                  )) ||
                                  null}
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            {this.state.AddForm ||
                            (!this.state.AddForm &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceTransactionRefund,
                                financeObj.regionId,
                                financeObj.facilityId,
                                financeObj.memberID
                              )) ? (
                              <div className="pt-4 custom-checkbox">
                                <>
                                  <input
                                    id={"Refund"}
                                    name={"Refund"}
                                    type="checkbox"
                                    checked={obj.Refund}
                                    onChange={this.handleChangeRefund}
                                    className="custom-control-input pr-2"
                                  />
                                  <label
                                    className="h6 custom-control-label"
                                    for={"Refund"}
                                    style={{ paddingTop: 2 }}
                                  >
                                    Refund
                                  </label>
                                </>
                              </div>
                            ) : (
                              <div>
                                <label>Refund: </label>
                                {obj.Refund && obj.Refund == true
                                  ? "Yes"
                                  : "No"}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row mx-0 mt-2 mb-2">
                          <div className="col-md-4">
                            <label className="ml-0">Transaction Date</label>
                            {this.state.AddForm ||
                            (!this.state.AddForm &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceTransactionCheckNumber,
                                financeObj.regionId,
                                financeObj.facilityId,
                                financeObj.memberID
                              )) ? (
                              <>
                                <DatePicker
                                  dateForm="MM/DD/YYYY"
                                  peekNextMonth
                                  className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                  showMonthDropdown
                                  showYearDropdown
                                  onChange={(date) => {
                                    if (date == null) {
                                      obj.TransactionDate = null;
                                    } else if (moment(date).isValid()) {
                                      obj.TransactionDate = moment(date).format(
                                        "YYYY-MM-DD"
                                      );
                                    }
                                    this.setState({ SubmissionObj: obj });
                                  }}
                                  selected={
                                    obj.TransactionDate
                                      ? moment(obj.TransactionDate).toDate()
                                      : null
                                  }
                                  placeholderText={"MM/DD/YYYY"}
                                />
                                <span className="text-danger" />
                              </>
                            ) : (
                              <div>
                                {obj.TransactionDate &&
                                  moment(obj.TransactionDate).toDate()}
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label>Check Number</label>

                            {this.state.AddForm ||
                            (!this.state.AddForm &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceTransactionCheckNumber,
                                financeObj.regionId,
                                financeObj.facilityId,
                                financeObj.memberID
                              )) ? (
                              <>
                                <input
                                  type="text"
                                  id="keyword"
                                  className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                  placeholder="check number"
                                  name="FinanceCheckNumber"
                                  onChange={(e) => {
                                    this.state.SubmissionObj[e.target.name] =
                                      e.target.value;

                                    this.setState({
                                      SubmissionObj: this.state.SubmissionObj,
                                    });
                                  }}
                                  disabled={
                                    !AuthService.canMemberUpdate(
                                      PermissionFeature.FinanceTransactionCheckNumber
                                    )
                                  }
                                  value={
                                    obj.FinanceCheckNumber
                                      ? obj.FinanceCheckNumber
                                      : ""
                                  }
                                />
                              </>
                            ) : (
                              <div>{obj.CheckNumber}</div>
                            )}
                          </div>
                        </div>
                      </>
                    )
                  )}

                  <div class="text-right pt-2 col-12">
                    <button
                      className="btn btn-dark btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
                      onClick={this.ResetObject}
                    >
                      Clear
                    </button>
                    <button
                      className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
                      onClick={this.cancelUpdate}
                    >
                      Cancel
                    </button>
                    {((this.state.AddForm &&
                      AuthService.canSPAdd(
                        PermissionFeature.FinanceTransaction,
                        financeObj.regionId,
                        financeObj.facilityId,
                        financeObj.memberID
                      )) ||
                      (!this.state.AddForm &&
                        AuthService.canSPUpdate(
                          PermissionFeature.FinanceTransaction,
                          financeObj.regionId,
                          financeObj.facilityId,
                          financeObj.memberID
                        ))) && (
                      <button
                        class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
                        type="submit"
                        onClick={this.submitForm}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </>
              )}
              {AuthService.canSPView(
                PermissionFeature.FinanceTransaction,
                financeObj.regionId,
                financeObj.facilityId,
                financeObj.memberID
              ) && (
                <div className="pt-3">
                  <TransactionTable />
                </div>
              )}
            </ModalBody>
          </Modal>
        </>
      );
    } else {
      return <></>;
    }
  }
}
export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(TransactionModal);
