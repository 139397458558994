import actions from "./action";

const initialState = {
    openImportDetailModal: false,
    openJobHistoryFormModal:false
};

export default function payfileTwo(state = initialState, action) {
    switch (action.type) {
        case actions.OPEN_IMPORT_DETAIL_MODAL:
            return {
                ...state,
                openImportDetailModal: true
            };

        case actions.CLOSE_IMPORT_DETAIL_MODAL: {
            return {
                ...state,
                openImportDetailModal: false
            };
        }
        case actions.OPEN_JOB_HISTORY_FORM_MODAL:
            return {
                ...state,
                openJobHistoryFormModal: true
            };

        case actions.CLOSE_JOB_HISTORY_FORM_MODAL: {
            return {
                ...state,
                openJobHistoryFormModal: false
            };
        }
        default:
            return state;
    }
}
