import AUX from "../../hoc/Aux_";
import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import axios from "./auth-header";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";
import * as Constants from "../Admin/Users/EditUser/PrimaryEnum";
import InputMask from "react-input-mask";

class EmailModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      id: "",
      editEmail: {},
      operationMode: "",
    };
  }
  componentWillReceiveProps(nextProps) { }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showEmailModal: false,
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  validateSaveEmail = () => {
    var canAdd =
      this.state.operationMode === "ADD" &&
      !(
        AuthService.canOwnAdd(
          PermissionFeature.ContactEmailAddresses,
          this.props.memberid
        ) || AuthService.canMemberAdd(PermissionFeature.ContactEmailAddresses)
      );

    var canEdit =
      this.state.operationMode === "Edit" &&
      !(
        AuthService.canOwnUpdate(
          PermissionFeature.ContactEmailAddresses,
          this.props.memberid
        ) ||
        AuthService.canMemberUpdate(PermissionFeature.ContactEmailAddresses)
      );

    return canAdd || canEdit;
  };
  onShow = () => {
    var currentEmail = this.props.currentEmail;
    if (!_.isEmpty(currentEmail)) {
      this.setState({ email: currentEmail.email });
      this.setState({ id: currentEmail.id });
      this.setState({ editEmail: currentEmail });
      this.setState({ operationMode: "Edit" });
    } else {
      this.setState({ operationMode: "ADD" });
      this.setState({ email: "" });
      this.setState({ id: 0 });
      this.setState({ editEmail: {} });
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
    // this.state.profile[e.target.dataset.obj][
    //   idx
    // ].email_err = this.validateEmail(e.target.value).error;
  };
  validateEmail = (email) => {
    let EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const Email_States = [
      {
        state: "Valid",
        error: "",
      },
      {
        state: "Null",
        error: "Please provide your email.",
      },
      {
        state: "Not_Match_To_Regex",
        error: "Please provide valid email.",
      },
    ];
    if (email == "") {
      return Email_States.find(({ state }) => state == "Null");
    } else if (EmailReg.test(email)) {
      return Email_States.find(({ state }) => state == "Valid");
    } else {
      return Email_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.target.className += " was-validated";
    if (e.target.checkValidity() === false) {
      return false;
    }
    if (this.state.id == 0) {
      const body = {
        email: this.state.email.trim(),
      };
      let Url = "/api/Member/CreateEmail?memberid=" + this.props.memberid;
      this.showSpinner();
      axios.post(Url, body).then(({ data }) => {
        this.hideSpinner();
        if (data.status) {
          if (data.status.toLowerCase() === "success") {
            toast.success(data.message);
            this.props.refreshData(this.props.memberid);
            this.handleClose();
            this.setState({ email: "" });
          } else {
            this.hideSpinner();
            toast.error(data.message);
            this.handleClose();
          }
        } else {
          toast.error("Could not complete the opertaion.");
        }
      });
    } else {
      ////edit email//////
      $(".loading").show();
      const data = { User_Id: 0, EmailAddresses: [] };
      var modifiedEmail = {
        id: this.state.editEmail.id,
        email: this.state.email.trim(),
        isprimary: this.state.editEmail.isprimary,
      };
      data.EmailAddresses.push(modifiedEmail);
      data.User_Id = this.props.memberid;
      axios
        .post("/api/Member/UpdateMemberEmailAddresses", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Email Addresses have been updated.");
            $(".loading").hide();
            this.props.refreshData(this.props.memberid);
            this.handleClose();
            this.setState({ email: "" });
          } else {
            $(".loading").hide();
            toast.error(data.message);
            this.handleClose();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    /////////////////////////
  };

  render() {
    const { phoneTypes } = this.props;
    return (
      <AUX>
        <Modal
          className="EmailMOdal latest"
          show={this.props.showModal}
          onHide={this.handleClose}
          centered
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title  text-white mt-0">
                {" "}
                {this.state.operationMode} EMAIL{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              className="form-horizontal"
              onSubmit={this.handleFormSubmit}
              noValidate
            >
              {" "}
              <div className="row">
                <div className="col-12">
                  {
                    <div>
                      <div className="row mb-4">
                        <div className="col-11 row">
                          <div className="col-sm-12">
                            {" "}
                            <div>
                              <MDBInput
                                id="email"
                                name="email"
                                containerClass="mb-0 w-100"
                                value={this.state.email}
                                onChange={this.handleChangeEmail}
                                placeHolder="Enter Email"
                                // disabled={
                                //   !AuthService.canAdd(
                                //     PermissionFeature.ContactEmailAddresses
                                //   )
                                // }
                                required
                                type="email"
                              >
                                <div className="invalid-feedback">
                                  Enter valid Email
                                </div>
                              </MDBInput>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div className="col-12 text-centered d-flex justify-content-center">
                    <button
                      className="btn btn-sm btn-danger w-md waves-effect waves-light"
                      type="button"
                      id="emailPopupCloseBtn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      // onClick={this.handleFormSubmit}
                      className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                      type="submit"
                      disabled={this.validateSaveEmail}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default EmailModal;
