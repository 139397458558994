import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import registrationsAction from "../../../store/registrations/action";
import { withRouter } from "react-router-dom";
import { RegistrationTableTypes } from "../Helpers/LookUpValues";

import { Card, CardBody, Collapse } from "reactstrap";
import RegistrationsListTable from "./ListTables/RegistrationsListTable";
export class MemberProfileRegistrations extends Component {
  state = {};
  fetchRegistrationsForMember = () => {
    /// ActivityLogging
    var show =
      this.props.registrationsState &&
      this.props.registrationsState.memberRegistrationsVisible;
    const { id } = this.props.match.params; //mmeber id

    if (!_.isEmpty(id)) {
      this.props.toggleMemberRegistrationsAccordion(
        !this.props.registrationsState.memberRegistrationsVisible
      );
      !show && this.props.getMemberProfileRegistrations(id);
    }
  };

  componentDidMount = () => {
    if (
      this.props.registrationsState &&
      _.isEmpty(this.props.registrationsState.memberProfileRegistrations)) {
      const { id } = this.props.match.params;
      this.props.getMemberProfileRegistrations(id)
    };
  }

  render() {
    var {
      isLoadingProfileRegistrations,
      memberProfileRegistrations,
      isLoadingProfileRegistrations,
    } = this.props.registrationsState && this.props.registrationsState;

    return (
      <>
        <Card>
          {/* Card Header */}
          {!isLoadingProfileRegistrations && (
            <>
              <h5
                className="card-header cursorPointer"
                onClick={this.fetchRegistrationsForMember}
              >
                <i className="fa fa-id-card-o" aria-hidden="true"></i> Member
                Registrations
                <i
                  className={`pull-right float-right ${this.props.registrationsState.memberRegistrationsVisible
                    ? "fas arrow-icon fa-chevron-down"
                    : "fas arrow-icon fa-chevron-up"
                    }`}
                ></i>
              </h5>
            </>
          )}
          {isLoadingProfileRegistrations && (
            <>
              <h5 className="card-header cursorPointer">
                <i class="fa fa-history" aria-hidden="true"></i> Loading....
                <i
                  className={`pull-right float-right ${this.props.registrationsState.memberRegistrationsVisible
                    ? "fas arrow-icon fa-chevron-down"
                    : "fas arrow-icon fa-chevron-up"
                    }`}
                ></i>
              </h5>
            </>
          )}
          <Collapse
            className="memberCardCollapse"
            // style={{ transition: "height 2s ease" }}
            isOpen={this.props.registrationsState.memberRegistrationsVisible}
          >
            <CardBody className="pl-1 pr-2">
              <RegistrationsListTable
                listData={memberProfileRegistrations}
                isloading={isLoadingProfileRegistrations}
                regTableType={RegistrationTableTypes.MemberProfileRegistrations}
              />
            </CardBody>
          </Collapse>
        </Card>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(MemberProfileRegistrations)
);
