import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import $ from "jquery";

class ConfirmDeleteLinkedPositionModal extends Component {
    constructor(props) {
        super(props);
    }


    componentWillReceiveProps(nextprops) {
    }
    render() {
        let deleteId =  "DeleteModalLinkedPosition";
        return (
            <AUX>
                <div className="modal fade delete-modal p-0" role="dialog" id={deleteId} aria-labelledby="" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header text-dark">
                                <h5 className="modal-title mt-0">Confirm Delete</h5>

                            </div>
                            <div className="modal-body">
                                <div className="col-12 pb-2 text-danger text-center">
                                    <span className="h1 fa fa-trash-alt"></span>

                                </div>
                                <div className="col-12 mb-4">
                                        <p className="h5 text-center">Are you sure you want to delete linked position "{this.props.positionName}"?</p>
                                </div>
                                <div className="col-12 text-center mb-3 border-top pt-4">
                                    <button
                                        className="btn btn-outline-secondary w-md waves-effect cancelModal waves-light mr-2"
                                        onClick={() => { window.$("#" + deleteId).modal("hide"); }}
                                    >
                                        Cancel
                                </button>
                                    <button
                                        className="btn btn-outline-danger w-md waves-effect waves-light"
                                        onClick={this.props.confirm}>
                                        Delete
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}


export default ConfirmDeleteLinkedPositionModal