import React from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  RegistrationTableTypes,
  RegistrationStatuses,
  RegisWizardCloseActions,
  RegisWizardSteps,
} from "../../Helpers/LookUpValues";

export default function CloseRegistrationWizardModal(props) {
  const {
    modal,
    toggle,
    confirm,
    justCloseRegModal,
    regTableType,
    registrationRow,
    submitFormData,
    isFormValid,
    currentStep,
    setFormErrors,
  } = props;

  const handleModalClose = (btnVal) => {
    if (btnVal == RegisWizardCloseActions.JustClose) {
      justCloseRegModal();
    } else if (btnVal == RegisWizardCloseActions.SaveAndClose) {
      //setFormErrors({});
      confirm().confirmed(submitFormData, btnVal, setFormErrors);
    } else if (btnVal == RegisWizardCloseActions.CancelRegis) {
      //Cancel My Registration
      props.cancelRegistration(registrationRow.id);
    }
  };
  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        Confirm You want to leave this page?
      </ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          {/* <h5>Are you sure you want to Close the Registration Wizard?</h5> */}
          <h6>Are you want to Save the Information?</h6>
        </div>
      </ModalBody>
      <ModalFooter>
        <div class="btn-group">
          <button
            type="button"
            onClick={() => handleModalClose(RegisWizardCloseActions.JustClose)}
            class="btn btn-success"
            title="Click 'No' to not Save Anything."
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-success dropdown-toggle dropdown-toggle-split"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            {/* isFormValid && */}
            {currentStep == RegisWizardSteps.SecondStep && (
              <a
                class="dropdown-item"
                href="#"
                onClick={() =>
                  handleModalClose(RegisWizardCloseActions.SaveAndClose)
                }
              >
                Save And Close
              </a>
            )}
            {regTableType == RegistrationTableTypes.MyRegistrations &&
              registrationRow &&
              registrationRow.statusId == RegistrationStatuses.InProgress && (
                <a
                  class="dropdown-item"
                  href="#"
                  onClick={() =>
                    handleModalClose(RegisWizardCloseActions.CancelRegis)
                  }
                >
                  Cancel Registration
                </a>
              )}
          </div>
        </div>

        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
