import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import SideNav from "./SideNav";
import Scrollbars from "react-custom-scrollbars";
import $ from "jquery";
import "./Leftsidebar.css";
import PayfileServices from "../../modules/Payfile/PayfileService";
import AuthService from "../../modules/Auth/AuthService";
import PropTypes from "prop-types";
import PayfileAction from "../../../src/store/payfile/action";
import { isMobile } from "../../modules/Shared/Helpers";
import { ID } from "webpack/lib/ModuleFilenameHelpers";
import { connect } from "react-redux";
import moment from "moment";
class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payfileIndicatorData: null,
      payfileStatusDate: null,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {};
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.resize = this.resize.bind(this);
    this.loadPayfileLastUpdateDate = this.loadPayfileLastUpdateDate.bind(this);
  }

  resize = () => {
    if (window.innerWidth <= 1024) {
      this.props.removeSidebarOpenedCallback();
    } else if (window.innerWidth > 1025) {
      this.props.addSidebarOpenedCallback();
    }
    this.props.SetSideBarHeight();
  };

  toggleSidebar = () => {
    this.props.toggleSidebarCallback();
  };
  componentDidUpdate() {
    if (this.props.payfile.reloadLastUpdate) {
      this.props.finishedReloadPayfileIndidator();
      this.loadPayfileLastUpdateDate();
    }
    this.props.SetSideBarHeight();
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  loadPayfileLastUpdateDate = async () => {
    let data = await PayfileServices.LastStatusUpdateDate();
    if (data != null) {
      try {
        this.state.payfileStatusDate = moment(data.updateDate).format(
          "MM/DD/YYYY"
        );
        await this.setState({
          payfileStatusDate: this.state.payfileStatusDate,
          payfileIndicatorData: data,
        });
        this.props.finishedReloadPayfileIndidator();
      } catch (e) {
        console.error("Error parsing Last Change Date:", e);
      }
    }
  };
  /**
   * Alert if clicked on outside of element
   */
  componentWillReceiveProps(nextProps) {
    console.log(
      "NewProps PreviousProps",
      nextProps.isEnlarged + " " + this.props.isEnlarged
    );
  }
  handleClickOutside(event) {
    if (window.innerWidth <= 1024) {
      try {
        if (
          event.target.className.indexOf("button-menu-mobile") < 0 &&
          event.target.className.indexOf("mdi-menu") < 0
        ) {
          if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.props.closeSidebarCallback();
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  componentDidMount() {
    window.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", this.resize);
    this.loadPayfileLastUpdateDate();
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.resize);
  }

  render() {
    return (
      <AUX>
        <div
          className="left side-menu"
          id="sidemenu-container"
          ref={this.setWrapperRef}
        >
          {this.props.children}
          {this.props.isEnlarged ? (
            <SideNav toggleSidebarCallback={this.props.toggleSidebarCallback} />
          ) : (
            <Scrollbars id="sideNav-scroll">
              <SideNav
                toggleSidebarCallback={this.props.toggleSidebarCallback}
              />
            </Scrollbars>
          )}
          <NavLink
            className="row position-absolute bottom-0 left-0 mx-0 mt-2 align-items-center text-white"
            id="PayfileIndicatorStatus"
            to="/admin/uploads"
          >
            <span className="col-auto">
              <i class="fas fa-calendar mb-0 h6 pr-1"></i>
            </span>
            <span
              id="PayfileIndicator"
              onClick={() => {}}
              className={
                "col pl-0 text-white vertical-align-middle h6 " +
                (this.state.Tab === "PayfileIndicator"
                  ? "waves-effect mlink"
                  : "waves-effect mlink")
              }
            >
              <div>
                <small className="text-secondary text-italic">
                  Last Update Date:
                </small>
                <br />

                <span>
                  {this.state.payfileStatusDate
                    ? this.state.payfileStatusDate
                    : "none"}
                </span>
              </div>
              <div>
                <small className="text-secondary text-italic">PP/Status:</small>
                <br />
                <span>
                  {this.state.payfileIndicatorData &&
                  this.state.payfileIndicatorData.year &&
                  this.state.payfileIndicatorData.payperiod
                    ? this.state.payfileIndicatorData.payperiod +
                      "-" +
                      this.state.payfileIndicatorData.year
                    : "none"}
                </span>
                /
                <span>
                  {this.state.payfileIndicatorData &&
                  this.state.payfileIndicatorData.status
                    ? this.state.payfileIndicatorData.status
                    : "none"}
                </span>
              </div>
            </span>
          </NavLink>
        </div>
      </AUX>
    );
  }
}
sidebar.propTypes = {
  children: PropTypes.element.isRequired,
};
export default withRouter(
  connect(
    (state) => ({
      payfile: state.payfile,
    }),
    {
      ...PayfileAction,
    }
  )(sidebar)
);
