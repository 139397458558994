import React, { Component } from "react";
import Users from "../../../Admin/Users/Users";
import { withRouter } from "react-router-dom";

export class FilterMembersTab extends Component {
  render() {
    return (
      <Users
        his={this.props.his}
        isFacility={false}
        isPAC={false}
        facility={null}
        FacilityDashboard={false}
        FacilityMembers={false}
        facilityId={null}
        isEditFilterModal={true}
        refreshMembersFilterUsersTable={true}
        filterMembersTab={true} // Main Prop for Listing Members in Edit Filter Modal
        history={this.props.history}
      />
    );
  }
}
export default withRouter(FilterMembersTab);
