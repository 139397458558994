import React, { Component } from "react";
import { render } from "react-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import $ from "jquery";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

const SortableItem = SortableElement(
  ({ value, dataKey, showDeleteIcon, onItemDelete }) => (
    <li
      data-key={dataKey}
      className="sort-item"
      title={`Drag ${value} to your required order.`}
    >
      <div>
        <span>{value}</span>
        {showDeleteIcon === true && (
          <i
            className="fas fa-trash-alt"
            title={`Remove ${value}.`}
            onClick={() => {
              onItemDelete(dataKey);
            }}
          ></i>
        )}
      </div>
    </li>
  )
);

const SortableList = SortableContainer(
  ({ items, textField, dataKeyField, showDeleteIcon, onItemDelete }) => {
    return (
      <ul className="sortable-item-list">
        {items.map((obj, index) => {
          let value = obj;
          if (textField) {
            value = obj[textField];
          }
          let dataKey = -1;
          if (dataKeyField) {
            dataKey = obj[dataKeyField];
          }
          if (
            obj.permission_feature_id &&
            obj.permission_feature_id > 0 &&
              AuthService.canView(obj.permission_feature_id
              )
          ) {
              if (obj.permission_feature_id == PermissionFeature.ContactAddresses || obj.permission_feature_id == PermissionFeature.ContactEmailAddresses || obj.permission_feature_id == PermissionFeature.ContactPhones) {
                  if (!AuthService.canView(PermissionFeature.MembersContact)) {
                      return null;
                  }
              }
            return (
              <SortableItem
                key={`item-${value}`}
                index={index}
                value={value}
                dataKey={dataKey}
                showDeleteIcon={showDeleteIcon}
                onItemDelete={onItemDelete}
              />
            );
          }
        })}
      </ul>
    );
  }
);

class SortableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [...this.props.items],
      dataKeyField: this.props.dataKeyField,
      textField: this.props.textField,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    $(".sortable-item-list")
      .find("li.active-sort-item")
      .removeClass("active-sort-item");
    $(".sortable-item-list").removeClass("sorting");
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    this.props.onSortEnd(oldIndex, newIndex);
  };

  onSortStart = ({ node, index, collection, isKeySorting }, event) => {
    //console.log(node);
    $(".sortable-item-list").addClass("sorting");
    $(node).addClass("active-sort-item");
  };

  onSortOver = ({ index, oldIndex, newIndex, collection, isKeySorting }, e) => {
    //debugger;
  };

  render() {
    return (
      <SortableList
        items={[...this.props.items]}
        textField={this.state.textField}
        dataKeyField={this.state.dataKeyField}
        onSortEnd={this.onSortEnd}
        onSortStart={this.onSortStart}
        //lockToContainerEdges={true}
        helperClass="sortable-helper"
        onSortOver={this.onSortOver}
        lockAxis="y"
        showDeleteIcon={this.props.showDeleteIcon}
        onItemDelete={this.props.onItemDelete}
        pressDelay={150}
      />
    );
  }
}

export default SortableComponent;
