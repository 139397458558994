import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import jsPDF from "jspdf";
import RegService from "../../RegistrationsService";
// import FERequestsType from "../FERequestsType";
import { OverlayTrigger, Popover } from "react-bootstrap";
import overlayFactory from "react-bootstrap-table2-overlay";
import { QuestionTypes } from "../../Helpers/LookUpValues";

const QuestionActions = (cell, row, viewBl, deleteBl) => {
  return (
    <div>
      <div className="actionIcons">
        <i
          className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
          title="View Budget Line"
          onClick={() => viewBl(row)}
        ></i>
      </div>
      <div className="actionIcons">
        <i
          className="fas fa-trash fa-md text-danger pl-1 pt-2 cursorPointer"
          title="Delete Budget Line"
          onClick={() => deleteBl(row)}
        ></i>
      </div>
    </div>
  );
};

const MyExportCSV = (props) => {
  const handleClick = async () => {
    const formData = {
      recordtype: props.requestType, // Active/InAccountReview || My/Review Requests
      isExportTable: true,
      PageNo: 1,
      PageSize: 1,
      SortOrder: "id",
      activeTab: props.activeSubHeader, // Inventory Or Requests
      isAdvanceSearchApplied: props.isAdvanceSearchApplied,
      searchCriteria: props.isAdvanceSearchApplied ? props.searchCriteria : {},
      showClosed: props.showClosed,
    };
    var response = await RegService.getDataForFEExport(formData);
    if (!_.isEmpty(response)) {
      props.onExport(response.data);
    }
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
const columns = (viewQuestion, deleteQuestion) => [
  {
    text: "Label",
    dataField: "label",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "Question Type",
    dataField: "questionTypeName",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
    formatter: (cellContent, row) => {
      return row.questionType == QuestionTypes.DropDown ? (
        <div>
          {cellContent} <i className="fa fa-caret-square-o-down"></i>
        </div>
      ) : row.questionType == QuestionTypes.Switch ? (
        <div>
          {cellContent}{" "}
          <i
            class="fa fa-toggle-off font-weight-bold text-dark"
            aria-hidden="true"
          ></i>
        </div>
      ) : row.questionType == QuestionTypes.Text ? (
        <div>
          {cellContent}{" "}
          <i class="fa fa-file-text-o text-dark" aria-hidden="true"></i>
        </div>
      ) : row.questionType == QuestionTypes.Checkboxes ? (
        <div>
          {cellContent} <i class="fa fa-check-square-o" aria-hidden="true"></i>
        </div>
      ) : row.questionType == QuestionTypes.Radiobuttons ? (
        <div>
          {cellContent} <i class="fa fa-dot-circle-o" aria-hidden="true"></i>
        </div>
      ) : (
        ""
      );
    },
  },
  {
    text: "Is Fee Assosicated",
    dataField: "isFeeAssociated",
    sort: false,
    headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
    formatter: (cellContent, row) => {
      return row.isFeeAssociated ? (
        <i className="fa fa-check" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-times" aria-hidden="true"></i>
      );
    },
  },
  {
    text: "Is Required",
    dataField: "isRequired",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
    formatter: (cellContent, row) => {
      return row.isRequired ? (
        <i className="fa fa-check" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-times" aria-hidden="true"></i>
      );
    },
  },

  {
    text: "",
    dataField: "actions",
    isDummyField: true,
    headerClasses: "text-center text-nowrap bg-dark text-white px-sm-1 py-2",
    headerAttrs: {
      width: "60px",
    },
    formatter: (cell, row) => {
      return QuestionActions(cell, row, viewQuestion, deleteQuestion);
    },
    csvExport: false,
  },
];

const getExportFileName = (tblType, filetype) => {
  //   if (tblType === FERequestsType.MyRequests) {
  //     return `${Date.now()}_MyRequestsList.${filetype}`;
  //   }
  //   if (tblType > FERequestsType.MyRequests) {
  //     return `${Date.now()}_ReviewRequests.${filetype}`;
  //   }
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.requestType, "pdf");
  const formData = {
    recordtype: allprops.requestType, // Active/InAccountReview || My/Review Requests
    isExportTable: true,
    PageNo: 1,
    PageSize: 10,
    SortOrder: "id",
    activeTab: allprops.activeSubHeader, // Inventory Or Requests
    isAdvanceSearchApplied: allprops.isAdvanceSearchApplied,
    searchCriteria: allprops.isAdvanceSearchApplied
      ? allprops.searchCriteria
      : {},
    showClosed: allprops.showClosed,
  };
  var response = await RegService.getDataForFEExport(formData);
  if (!_.isEmpty(response)) {
    var tableBody = response.data.map((val) => {
      var listRow = [];
      listRow.push(val.userName);
      listRow.push(val.description);
      listRow.push(val.requesterName);
      listRow.push(val.QuestionName);
      listRow.push(val.categoryName);
      listRow.push(val.typeName);
      listRow.push(val.statusName);
      return listRow;
    });
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "USER",
          "DESCRIPTION",
          "REQUESTER",
          "BUDGET",
          "CATEGORY",
          "TYPE",
          "STAUS",
        ],
      ],
      body: tableBody,
      tableWidth: "auto",
      styles: { cellPadding: 0.7, fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },

        // etc
      },
    });
    doc.save(filename);
  }
};
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="QuestionsKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder="Question Search.."
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};

const RemotePagination = (data, viewQuestion, deleteQuestion, isloading) => (
  <ToolkitProvider
    keyField="id"
    data={data}
    columns={columns(viewQuestion, deleteQuestion)}
    search
  >
    {(props) => (
      <div>
        <MySearch {...props.searchProps} />
        <BootstrapTable
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: (base) => ({
                ...base,
                background: "rgba(40, 45, 42, 0.19)",
              }),
            },
          })}
          noDataIndication={() => {
            return isloading === false ? <NoDataIndication /> : <Empty />;
          }}
          loading={isloading}
          {...props.baseProps}
        />

        <br />
      </div>
    )}
  </ToolkitProvider>
);

const QuestionsListTable = (props) => {
  const { listData, viewQuestion, deleteQuestion, isloading } = props;
  return (
    <>{RemotePagination(listData, viewQuestion, deleteQuestion, isloading)}</>
  );
};

QuestionsListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

QuestionsListTable.defaultProps = {
  listData: [],
};

export default QuestionsListTable;
