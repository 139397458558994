import { toast } from "react-toastify";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import _ from "lodash";

export default {
  GetAreas: async () => {
    $(".loading").show();
    let url = "/api/Areas";
    var { data } = await axios.get(url);

    if (data.status && data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  CreateAreaTeam: async (areaId) => {
    if (areaId == null) {
      return null;
    }
    $(".loading").show();
    let url = `/api/Areas/CreateAreaTeam?areaId=${areaId}`;
    var { data } = await axios.post(url);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },

  GetAreasByFacility: async (id) => {
    $(".loading").show();
    let url = `/api/Areas/GetAreasByFacility/${id}`;
    var { data } = await axios.get(url);

    if (data.status && data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },

  GetAreasByFacilityCode: async (code) => {
    $(".loading").show();
    let url = `/api/Areas/GetAreasByFacilityCode/${code}`;
    var { data } = await axios.get(url);

    if (data.status && data.status == "Success") {
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },

  SaveArea: async (formdata) => {
    $(".loading").show();
    let url = "/api/Areas/SaveArea";
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      toast.success("Record saved successfully!");
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },

  DeleteArea: async (id) => {
    $(".loading").show();
    let url = `/api/Areas/${id}`;
    var { data } = await axios.delete(url).catch((ex) => {
      $(".loading").hide();
    });
    if (data.status && data.status == "Success") {
      $(".loading").hide();
      toast.success(data.message);
      return data;
    } else {
      toast.error("Invalid Request");
      $(".loading").hide();
      return null;
    }
  },

  AssignMembers: async (formdata) => {
    $(".loading").show();
    let url = "/api/Areas/AssignMembers";
    var { data } = await axios.post(url, formdata);

    if (data.status && data.status == "Success") {
      toast.success(data.message);
      $(".loading").hide();
      return data;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
};
