import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import NCEPTFileColumn from "./NCEPTFileColumn";
export default function NCEPTColumnsModal() {
  return (
    <AUX>
      <div
        className="modal fade confirm-modal p-0"
        role="dialog"
        id="NCEPTColumnsModal"
        aria-labelledby=""
        aria-hidden="true"
      >
        <div className={"modal-dialog modal-dialog-centered modal-lg"}>
          <div className="modal-content shadow">
            <div className="modal-header py-2 bg-white">
              <h5 className="modal-title text-secondary mt-0">NCEPT Columns</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-12 mb-4">
                <p className="h6 text-secondary mb-3 font-weight-normal">
                  Following are the columns that is used for this file:
                </p>
                <ol>
                  {NCEPTFileColumn.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
}
