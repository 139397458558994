//These values are used in Member Advance filter
const FE_AdvanceFilter_NewTabs = Object.freeze({
  MEM_AF_INV_STATUS: "45",
  MEM_AF_INV_BUDGETLINES: "46",
  MEM_AF_INV_BOOKVAL: "47",
  MEM_AF_INV_TYPE: "48",
  MEM_AF_INV_DESC: "49",

  // REQUEST_BOOKVAL: 49,

  // REQUEST_STATUS: 45,
  // REQUEST_BUDGETLINES: 46,
  // REQUEST_TYPES: 47,
  // REQUEST_DESCRIPTION: 48,

  // MEM_AF_INV_ACTUALCOST: 51,

  // MEM_AF_INV_YEAR: 53,

  // MEM_AF_INV_PURHCASEDATE: 55,
  // MEM_AF_INV_STICKERNO: 56,
});
export default FE_AdvanceFilter_NewTabs;
