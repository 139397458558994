import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import FETrackerAction from "../../../store/feTracker/action";
import $ from "jquery";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import { isInvalid } from "redux-form";
import { Row, Col } from "reactstrap";
import FE_AF_Nav_Items from "./FE_AF_Nav_Items";
import SelectedFilter from "./SelectedFilter";
import TagsActionButtons from "./TagsButtons";
import Tags from "./Tags";
import { toast } from "react-toastify";
import axios from "../../Shared/auth-header";
import MobileSideBar from "./Mobile/MobileSideBar";
import DesktopSideBar from "./Desktop/DesktopSideBar";
import DesktopTabContents from "./Desktop/DeskTopTabContents";
import FE_Tabs_Type from "../../../modules/F&E/FE_Tabs";
import FE_API_Actions from "../FE_API_Actions";
import FE_Tabs from "../../../modules/F&E/FE_Tabs";
import { change } from "redux-form";
import { store } from "../../../../src/index";
export const fe_request_statuses = [
  { id: 1, status: "Requested", checked: false },
  { id: 2, status: "Pending EVP Approval", checked: false },
  { id: 3, status: "Approved", checked: false },
  { id: 4, status: "Denied", checked: false },
  { id: 5, status: "Closed", checked: false },
];
export const fetypes = [
  { id: 1, type: "Computer", checked: false },
  { id: 2, type: "Furniture", checked: false },
  { id: 3, type: "A/V Equipment", checked: false },
  { id: 4, type: "Software", checked: false },
  { id: 5, type: "Other", checked: false },
];
export const fe_inventory_types = [
  { id: 1, type: "Computer", checked: false },
  { id: 2, type: "Furniture", checked: false },
  { id: 3, type: "A/V Equipment", checked: false },
  { id: 4, type: "Software", checked: false },
  { id: 5, type: "Other", checked: false },
];
export const feequipmentstatuses = [
  //{ id: 1, status: "In Accounting Review", checked: false },
  { id: 2, status: "Active", checked: true },
  { id: 3, status: "Disposed", checked: false },
];
class FE_AdvanceSearch_Modal extends Component {
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener(
      "orientationchange",
      this.orientationchange.bind(this)
    );
    this.resize();
    // $("#clickAdvancedFilter").on("click", this.onModalClick);
  }
  orientationchange = () => {
    this.resize();
  };

  onShow = async () => {
    ///Default CheckBox selection of Active Status
    let searchApplied = this.props.festate.filterData.searchApplied.inventories;

    let preloadedDataFound =
      Object.keys(this.props.festate.filterData).length <= 2;

    if (preloadedDataFound) {
      $(".loading").show();
      await axios
        .get("/api/Equipment/AdvanceFilterPreLoadData")
        .then(async ({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            var options = data.data;
            options = {
              ...options,
              ...this.props.festate.filterData,
            };

            let result = _.merge({}, options, {
              fe_equipment_teams: options.feteams,
              fe_request_teams: options.feteams,

              fe_equipment_regions: options.feregions,
              fe_request_regions: options.feregions,

              // fe_equipment_facilities: options.fefacilities,
              // fe_request_facilities: options.fefacilities,

              fe_equipment_naoffices: options.fenaoffices, // New filter

              fe_request_budgetlines: options.febudgetlines,
              fe_equipments_budgetlines: [
                ...options.febudgetlines,
                { id: -1, budgetline: "Select All", budgetlineNo: -1 },
              ],
              fe_request_statuses: fe_request_statuses,
              fe_equipment_statuses: feequipmentstatuses,
            });

            this.props.setFilterData(result);
            if (
              this.props.festate.activeSubHeader == FE_Tabs_Type.Inventory &&
              ((searchApplied && searchApplied.length == 0) ||
                _.isEmpty(searchApplied))
            ) {
              await this.props.setFilterStatusActive(); //1st Time
            }
            //this.props.getMemberTotalCount(this.props.festate.filterData);

            $(".loading").hide();
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        });
    }
    if (
      this.props.festate.activeSubHeader == FE_Tabs_Type.Inventory &&
      ((searchApplied && searchApplied.length == 0) ||
        (searchApplied.length == 1 &&
          searchApplied[0].searchType == FE_AF_Nav_Items.Status &&
          searchApplied[0].selectedItems.length == 0) ||
        (searchApplied.length == 2 &&
          searchApplied.some((s) => s.searchType == FE_AF_Nav_Items.Year) &&
          searchApplied.find((f) => f.searchType == FE_AF_Nav_Items.Year)
            .selectedItems.length == 1) ||
        (searchApplied.length == 3 &&
          searchApplied.some((s) => s.searchType == FE_AF_Nav_Items.Budget) &&
          searchApplied.find((f) => f.searchType == FE_AF_Nav_Items.Budget)
            .selectedItems.length == 1))
    ) {
      await this.props.setFilterStatusActive(); // 2nd Time
    }
    ///************************NEW SEARCH FILTERS WORK************************************** */

    ///Show Default Filters of DropDown Selected Filters
    const {
      filterData: { years = [] },
    } = this.props.festate;
    store.dispatch(change("FE_INV_YearsForm", "inventoryYear", years));
    this.props.setAFTabFormsById(FE_AF_Nav_Items.Year);
    this.applyDropDownListFilters();
    //////////////////////***************************////////////////
    this.props.setCurrentAFActiveTab(FE_AF_Nav_Items.UserInventory);
    this.props.setCollapseTab(2);
    this.props.handleTagsVisibility();
    this.props.getMemberTotalCount(this.props.festate.filterData);

    this.resize();
    $("body").removeClass("modal-open");
    $("html").addClass("removeYScroll");
    this.stopBodyScrolling(true);
  };

  getSelectedOptions = (currentTabKey) => {
    let fdata = this.props.festate.filterData;

    let selectedVals = Object.keys(fdata)
      .filter((it) => it === this.getCurrentTabIdByName(currentTabKey))
      .reduce((obj, key) => {
        ///This condition is for checkbox controls
        if (
          +fdata[key] !== 0 &&
          fdata[key] &&
          fdata[key].constructor === Array
        ) {
          if (
            fdata[key] &&
            +fdata[key] !== 0 &&
            fdata[key].some(
              (s) => typeof s !== "undefined" && s.constructor === Object
            )
          ) {
            obj[key] = fdata[key]
              .filter((it) => it.checked)
              .map((m) => Object.values(m).find((t) => typeof t === "string"));

            return Object.values(obj);
          } else if (fdata[key].some((s) => typeof s === "string")) {
            return Object.values(fdata[key]);
          }
        }
      }, {});

    selectedVals =
      selectedVals && _.size(selectedVals)
        ? selectedVals.filter((f) => typeof f !== "undefined")
        : [];
    let result = [].concat.apply([], selectedVals);

    result = result.map((m) => {
      return m;
    });

    return result;
  };
  applyDropDownListFilters() {
    const dropDownFilters = [
      FE_AF_Nav_Items.Budget,
      FE_AF_Nav_Items.Status,
      FE_AF_Nav_Items.Year,
    ];

    for (let index = 0; index < dropDownFilters.length; index++) {
      const currentTab = dropDownFilters[index];

      if (currentTab !== 0) {
        var apply = {
          searchType: +currentTab,
          isApplied: true,
          isSearched: true,
        };
        let filterItems = this.getSelectedOptions(currentTab);
        if (!_.isEmpty(filterItems)) {
          apply.selectedItems = filterItems;

          if (_.isEmpty(this.props.getSearchAppliedList())) {
            this.props.setSearchAppliedList([]);
          }

          var applyIndex = _.findIndex(
            this.props.getSearchAppliedList(),
            (o) => {
              return o.searchType === apply.searchType;
            }
          );
          if (applyIndex === -1) {
            this.props.getSearchAppliedList().push(apply);
          } else {
            this.props.getSearchAppliedList()[applyIndex] = apply;
          }
          this.props.setFilterData({
            ...this.props.festate.filterData,
          });
          this.props.festate.filterData = Object.assign(
            {},
            this.props.festate.filterData,
            {
              activeTab: currentTab,
            }
          );
        }
      }
    }
  }
  getCurrentTabIdByName = (id) => {
    id = parseInt(id);
    let statusesObj = "";
    let budgetsObj = "";
    statusesObj = "fe_equipment_statuses";
    budgetsObj = "fe_equipments_budgetlines";

    let tabName = "";
    switch (id) {
      case FE_AF_Nav_Items.Status:
        tabName = statusesObj;
        break;
      case FE_AF_Nav_Items.Budget:
        tabName = budgetsObj;
        break;
      case FE_AF_Nav_Items.Year: //Years
        tabName = "years";
        break;
      default:
        tabName = "NaN";
        break;
    }

    return tabName;
  };
  ///************************NEW SEARCH FILTERS END************************************** */

  resize() {
    let currentHideNav = window.innerWidth >= 897; /// isDesktop
    if (currentHideNav !== this.props.festate.showWebSideBar) {
      this.props.showWebSideBar(currentHideNav);
      this.props.showMobileSideBar(false);
    } else if (window.innerWidth <= 897) {
      this.props.showMobileSideBar(true);
      this.props.showWebSideBar(false);
    }
    let vh = 0;
    let tagsScroll = 0;
    if (window.innerHeight > window.innerWidth) {
      ///Potrait Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.018}px`
      );
      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01 - 2}px`
      );
    } else if (window.innerHeight < window.innerWidth) {
      ///Landscape Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01}px`
      );
    }
    let bodyHeight = $(".modal-body").height() * 0.01;
    document.documentElement.style.setProperty(
      "--bodyHeight",
      `${bodyHeight}px`
    );
    if (this.DEBUG) {
      // console.log("resized called");
      //console.log(`set vh ${vh}px`);
      //  console.log(`set body ${bodyHeight}px`);
      //console.log(window.innerWidth);
    }
  }
  //InFE module Work
  resetALLFilters = async () => {
    await this.props.resetALLFilters();
    //this.onReturnNo();
    await this.props.setYearsFilterData([]);

    this.props.handleTagsVisibility();
  };
  onCancel() {
    this.props.toggleFEAdvanceFilter(false);
    $("html").removeClass("removeYScroll");
    this.stopBodyScrolling(false);
  }
  onReturnNo() {
    /// API
    if (!_.isEmpty(this.props.getSearchAppliedList())) {
      this.props.getMemberTotalCount(this.props.festate.filterData);
    } else {
      this.props.setReturnResultNo(0);
    }
  }
  isAnyFormInvalid(formName) {
    if (isInvalid(formName)(this.props.currentState)) {
      return true;
    } else {
      return false;
    }
  }
  onSearch(e) {
    //this.props.ResetlocalPaging();
    /// API
    const Forms = {
      Description: "fe_req_descriptionForm",
      BookValues: "fe_req_bookValsForm",
      ActualCosts: "fe_actualValsForm",
      Years: "FE_INV_YearsForm",
      PurchaseDates: "purchaseDateForm",
      StickerNo: "FE_INV_StickerNoForm",
    };

    let result = false;
    _.forEach(Forms, (value) => {
      if (this.isAnyFormInvalid(value)) {
        result = true;
      }
    });

    if (result) {
      toast.warn("Please correct the invalid Filter Type(s) fields.");
      return false;
    }

    if (
      // !_.isEmpty(this.props.getSearchAppliedList()) &&
      this.props.festate.returnResultNo !== 0
    ) {
      _.forEach(this.props.getSearchAppliedList(), (value) => {
        this.props.setAFTabFormsById(value.searchType);
      });

      const formData = {
        ...this.props.festate.filterData,
        recordsTypeList: [],
        PageNo: 1,
        PageSize: 25,
        SortOrder: "id",
        EventType: FE_API_Actions.AdvanceSearch,
      };

      this.props.getResultRowsForAdvanceFilter(formData);
      if (this.props.festate.activeSubHeader == FE_Tabs_Type.Inventory) {
        this.props.resetPagination(FE_Tabs.Inventory);
      } else if (
        this.props.festate.activeSubHeader == FE_Tabs_Type.MyRequests
      ) {
        this.props.resetPagination(FE_Tabs.MyRequests);
      } else if (
        this.props.festate.activeSubHeader == FE_Tabs_Type.ReviewRequests
      ) {
        this.props.resetPagination(FE_Tabs.ReviewRequests);
      }
    } else {
      toast.warn("There are no records against this filter criteria.");
    }
  }

  mobileHeaderBtns() {
    return (
      <div
        className="modal-header customMobileHeader pt-2 pl-0 pb-0 pr-0"
        style={{ background: "#759fbc" }}
      >
        <div className="mobileModalHeader col-5 pl-0 pr-0 pb-0">
          <h6
            className="modal-title mt-0 headerMobileColor pl-2"
            style={{ display: "block ruby", position: "fixed" }}
          >
            ADVANCED FILTER
          </h6>
        </div>
        <div className="mobileHeaderBtns" style={{ marginTop: "-0.1rem" }}>
          <TagsActionButtons isMobile={true} />
        </div>
      </div>
    );
  }
  webModalHeader() {
    return (
      <div className="modal-header pt-0 pb-0" style={{ display: "block" }}>
        <div className="row header-back">
          <Col xs="3" sm="3" md="3" xl="3" lg="3" className="webHeader">
            <h5 className="modal-title mt-0">ADVANCED FILTER</h5>
          </Col>
          <Col xs="9" sm="9" md="9" xl="9" lg="9" />
        </div>
      </div>
    );
  }
  onReturnNo = () => {
    /// API
    if (!_.isEmpty(this.props.getSearchAppliedList())) {
      this.props.getMemberTotalCount(this.props.festate.filterData);
    } else {
      this.props.setReturnResultNo(0);
    }
  };
  onAccordionClick = (id) => {
    const currentActiveAccordion = this.props.festate.activeTab;
    if (id !== currentActiveAccordion) {
      this.props.setCurrentAFActiveTab(id);
    } else {
      this.props.setCurrentAFActiveTab("0");
    }
  };
  onChildAccordionClick = (id) => {
    const currentChildActiveAccordion = this.props.festate.childAccordion;
    if (id !== currentChildActiveAccordion) {
      this.props.activeFacilityChildAccordion(id);
    } else {
      this.props.activeFacilityChildAccordion("0");
    }
  };

  onDesktopAccordionClick = (id) => {
    const currentActiveAccordion = this.props.festate.collaps;
    if (id !== currentActiveAccordion) {
      if (id === "1") {
        this.props.setCurrentAFActiveTab("9");
      }
      this.props.setCollapseTab(id);
    } else {
      this.props.setCollapseTab("0");
      this.props.setCurrentAFActiveTab("0");
    }
  };
  onParentAccordionClick = (id) => {
    const currentActiveAccordion = this.props.festate.collaps;
    if (id !== currentActiveAccordion) {
      this.props.setCollapseTab(id);
    } else {
      this.props.setCollapseTab("0");
      this.props.setCurrentAFActiveTab("0");
    }

    this.resize();
  };
  stopBodyScrolling = (bool) => {
    if (bool === true) {
      document.body.addEventListener("touchmove", this.freezeVp, false);
    } else {
      document.body.removeEventListener("touchmove", this.freezeVp, false);
    }
  };
  freezeVp = function(e) {
    e.preventDefault();
  };
  render() {
    const showWebSideBar = this.props.festate.showWebSideBar;
    const smallBtns = window.innerWidth <= 897 ? "btn-filter-sm" : "";
    const loadingBtn = (
      <button class="btn btn-success m-1 m-sm-1" type="button">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </button>
    );

    return (
      <AUX>
        <Modal
          className="modal fade AdvanceFilterModal"
          id="AdvanceFilterModal"
          // size="xl"
          dialogClassName="modal-85w"
          backdrop="static"
          show={this.props.showModal}
          centered={false}
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          {window.innerWidth <= 897
            ? this.mobileHeaderBtns()
            : this.webModalHeader()}
          <Modal.Body
            className={`modal-body pt-0 ${window.innerWidth <= 897 &&
              "pb-1 modal-body-custom"}`}
          >
            <div />
            <Row>
              {showWebSideBar ? (
                <DesktopSideBar
                  onAccordionClick={this.onDesktopAccordionClick}
                  onChildAccordionClick={this.onChildAccordionClick}
                />
              ) : (
                <MobileSideBar
                  onAccordionClick={this.onAccordionClick}
                  onParentAccordionClick={this.onParentAccordionClick}
                />
              )}

              {showWebSideBar ? <DesktopTabContents /> : null}
            </Row>

            {/* Mobile tags works start  */}

            {/* Tags for advance filter */}
            {window.innerWidth <= 897 && (
              <>
                <Row className="mobFilterSpan">
                  <Col className="p-0 smallFont">
                    <SelectedFilter isMobile={true} />
                  </Col>
                </Row>
                <div className="mobileUIFilter">
                  <Row className="bottomBorderMobile">
                    <Col className="mb-2 mt-2 mr-2 ml-2 p-0 tagsScroll smallFont">
                      <Tags />
                    </Col>
                  </Row>
                </div>

                <div className="footerBtns">
                  <div
                    className={
                      window.innerWidth <= 897
                        ? "col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12 text-center"
                        : "col-12 text-right p-4"
                    }
                  >
                    <button
                      type="button"
                      className="cancelBtn"
                      data-dismiss="modal"
                      aria-hidden="true"
                      style={{ display: "none" }}
                    />
                    <button
                      className={`btn btn-danger cancelModal ${window.innerWidth >=
                        897 &&
                        "w-md waves-effectwaves-light"}  m-1 m-sm-1 ${smallBtns}`}
                      data-dismiss="modal"
                      aria-hidden="true"
                      onClick={() => this.onCancel()}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => this.resetALLFilters()}
                      className={`btn btn-primary btn-success ${window.innerWidth >=
                        897 &&
                        "w-md waves-effect waves-light"} m-1 m-sm-1 ${smallBtns}`}
                      type="button"
                    >
                      Reset All
                    </button>

                    {!this.props.festate.loadingAdvanceFilter &&
                      window.innerWidth > 897 && (
                        <button
                          onClick={() => this.onSearch()}
                          className={`btn btn-success ${window.innerWidth >=
                            897 &&
                            "w-md waves-effect waves-light"}  m-1 m-sm-1 ${smallBtns}`}
                          type="button"
                        >
                          Search
                        </button>
                      )}

                    {window.innerWidth <= 897 && (
                      <button
                        onClick={() => this.onSearch()}
                        className={`btn btn-success ${window.innerWidth >=
                          897 &&
                          "w-md waves-effect waves-light"}  m-1 m-sm-1 ${smallBtns}`}
                        type="button"
                      >
                        Search
                      </button>
                    )}
                    {this.props.festate.loadingAdvanceFilter &&
                      window.innerWidth > 897 &&
                      loadingBtn}
                  </div>
                  <Row>
                    <div className="col-12 text-center">
                      <span className="MobiletotalCountMmeber">
                        {this.props.festate.activeSubHeader ==
                        FE_Tabs_Type.Inventory
                          ? `${this.props.festate.returnResultNo.inventories} Records will be returned`
                          : `${this.props.festate.returnResultNo.requests} Records will be returned`}
                      </span>
                    </div>
                  </Row>
                </div>
              </>
            )}
            {/* END Mobile tags works */}

            {window.innerWidth > 897 && (
              <Row>
                <div
                  className={
                    window.innerWidth <= 760
                      ? "col-md-12 col-sm-12 col-xs-12 col-lg-12 col-xl-12 text-center"
                      : "col-12 text-right p-4"
                  }
                >
                  {window.innerWidth >= 760 && (
                    <span className="totalCountMmeber">
                      {this.props.festate.activeSubHeader ==
                      FE_Tabs_Type.Inventory
                        ? `${this.props.festate.returnResultNo.inventories} Records will be returned`
                        : `${this.props.festate.returnResultNo.requests} Records will be returned`}
                    </span>
                  )}

                  <button
                    type="button"
                    className="cancelBtn"
                    data-dismiss="modal"
                    aria-hidden="true"
                    style={{ display: "none" }}
                  />
                  <button
                    className={`btn btn-danger w-md waves-effect cancelModal waves-light m-1 m-sm-1 ${smallBtns}`}
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={() => this.onCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => this.resetALLFilters()}
                    className={`btn btn-primary btn-success w-md waves-effect waves-light m-1 m-sm-1 ${smallBtns}`}
                    type="button"
                  >
                    Reset All
                  </button>

                  {!this.props.festate.loadingAdvanceFilter && (
                    <button
                      onClick={() => this.onSearch()}
                      className={`btn btn-success w-md waves-effect waves-light m-1 m-sm-1 ${smallBtns}`}
                      type="button"
                    >
                      Search
                    </button>
                  )}
                  {this.props.festate.loadingAdvanceFilter && loadingBtn}
                </div>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default withRouter(
  connect((state) => ({ festate: state.fetracker, formState: state.form }), {
    ...FETrackerAction,
  })(FE_AdvanceSearch_Modal)
);
