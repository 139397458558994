import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import chistory from "../../Shared/history";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import { Spinner } from "reactstrap";
import MailGunEmailTypes from "../Enums/MailGunEmailTypes";
import { useLocation } from "react-router-dom";
import PermissionFeature from "../../Permissions/PermissionFeature";
import AuthService from "../../Auth/AuthService";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import * as SavedSearchTypes from "../../AdvanceSearch/SavedSearches/SavedSearchTypes";

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const showListType = (cell) => {
  if (cell == 1) {
    return "Area";
  } else if (cell == 2) {
    return "Facility";
  }
  if (cell == 3) {
    return "Region";
  }
  if (cell == 4) {
    return "Team";
  }
  if (cell == 5) {
    return "Saved Search";
  }
  if (cell == 6) {
    return "Registration";
  }
};
const showListAccessLevel = (cell) => {
  if (cell == 1) {
    return "ReadOnly";
  } else if (cell == 2) {
    return "Members";
  }
  if (cell == 3) {
    return "Anyone";
  }
};
////Start of the Component
const MailingListTable = (props) => {
  const {
    listData,
    loading,
    myUserId,
    openConfirmResubscribeModal,
    openConfirmUnsubscribeModal,
    isAllListsTable,
  } = props;
  const location = useLocation();
  const manageList = (row) => {
    const { email_List_Type_Id, entity_Key } = row;

    if (email_List_Type_Id == MailGunEmailTypes.Facility) {
      if (AuthService.canView(PermissionFeature.Facility)) {
        chistory.push(
          `/directory/facility/view/${entity_Key}?defaultAccordion=emailList`,
          {
            emailListDetails: { ...row },
          }
        );
      } else {
        toast.error("You don't have Permission!!");
      }

      //**Saved Searches**
    } else if (email_List_Type_Id == MailGunEmailTypes.Member) {
      // let isExistsInAllFilters = props.savedSearchState.allSystemFilters.some(
      //   (f) => f.saved_search_id == entity_Key
      // );
      let isExistsInMyFilters = props.savedSearchState.loggedInUserFilters.some(
        (f) => {
          if (
            f.search_type_id == SavedSearchTypes.OWNERSHIP_ITEM ||
            f.search_type_id == SavedSearchTypes.BUCKET_ITEM
          ) {
            return f.id == entity_Key;
          } else {
            return f.saved_search_id == entity_Key; // SavedSearchTypes.SHARED_ITEM
          }
        }
      );
      //If click action happened from "All Email-Lists" then route to "All Filters"
      if (location.pathname == "/emailLists/my" || isExistsInMyFilters) {
        chistory.push(
          `/directory/myfilters?showEmailConfigModal=${entity_Key}`,
          {
            emailListDetails: { ...row },
          }
        );
      } else if (location.pathname == "/emailLists/all") {
        if (isExistsInMyFilters) {
          chistory.push(
            `/directory/myfilters?showEmailConfigModal=${entity_Key}`,
            {
              emailListDetails: { ...row },
            }
          );
        }
        chistory.push(`/admin/allfilters?showEmailConfigModal=${entity_Key}`, {
          emailListDetails: { ...row },
        });
      }
    } else if (email_List_Type_Id == MailGunEmailTypes.Team) {
      if (AuthService.canView(PermissionFeature.Teams)) {
        chistory.push(
          `/permissions/teams/teamDetail/${entity_Key}?showEmailConfigModal=true`,
          {
            emailListDetails: { ...row },
          }
        );
      } else {
        toast.error("You don't have Permission!!");
      }
    } else if (email_List_Type_Id == MailGunEmailTypes.Registrants) {
      if (
        AuthService.canView(PermissionFeature.REGSuperAdmin) ||
        AuthService.canView(PermissionFeature.REGBasicAdmin)
      ) {
        chistory.push(`/admin/requests?showEmailConfigModal=${entity_Key}`, {
          emailListDetails: { ...row },
        });
      } else {
        toast.error("You don't have Permission!!");
      }
    }
  };
  const firtColumnDisplay = (content, row) => {
    ///console.log("my row is " + JSON.stringify(row));
    if (row && row.ownerId == null) {
      row.ownerId = "";
    }
    const { ownerId = "" } = row;
    return (
      <>
        <span>{content}</span>
        <span>
          {/* Manager only, Not a Member */}
          {ownerId.includes(myUserId) && !row.isListMember && (
            <i
              className="fa fa-pencil-square-o c-pointer pl-1 pt-2 cursorPointer"
              title="Edit"
              onClick={() => manageList(row)}
            ></i>
          )}
        </span>
        {/* Both Manager and Member */}
        {ownerId.includes(myUserId) && row.isListMember && (
          <i
            className="fa fa-pencil-square-o c-pointer pl-1 pt-2 cursorPointer"
            title="Edit"
            onClick={() => manageList(row)}
          ></i>
        )}
      </>
    );
  };
  const subUnsubIcons = (row) => {
    return (
      <div className="actionIcons">
        {row && row.subscribed && (
          <button
            type="button"
            disabled={row.blocked}
            className="btn btn-danger unsubscrActionBtn mr-1"
            onClick={() => {
              openConfirmUnsubscribeModal(row);
            }}
          >
            Unsubscribe
            <i
              className="fa fa-bell-slash-o cursorPointer"
              aria-hidden="true"
              title="UnSubscribe"
            ></i>
          </button>
        )}
        {row && !row.subscribed && (
          <button
            type="button"
            disabled={row.blocked}
            className="btn btn-success unsubscrActionBtn mr-1"
            onClick={() => {
              openConfirmResubscribeModal(row);
            }}
          >
            Resubscribe
            <i
              className="fa fa-bell-o cursorPointer"
              aria-hidden="true"
              title="ReSubscribe"
            ></i>
          </button>
        )}
      </div>
    );
  };
  const Actions = (cell, row) => {
    if (row && row.ownerId == null) {
      row.ownerId = "";
    }
    const { ownerId = "" } = row;
    return (
      <>
        {!isAllListsTable && (
          <>
            {/* Not a Manager, Member Only */}
            {!ownerId.includes(myUserId) &&
              row.isListMember &&
              subUnsubIcons(row)}
            {/* Both Manager and Member */}
            {ownerId.includes(myUserId) && row.isListMember && (
              <>{subUnsubIcons(row)}</>
            )}
            {/* Not a Manager neither Member */}
            {!ownerId.includes(myUserId) && !row.isListMember && "N/A"}
          </>
        )}

        {isAllListsTable && (
          <>
            <i
              className="fa fa-pencil-square-o c-pointer pl-1 pt-2 cursorPointer"
              title="Edit"
              onClick={() => manageList(row)}
            ></i>
          </>
        )}
      </>
    );
  };
  const columns = () => [
    {
      dataField: "email_Title",
      text: "LIST TITLE",

      headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
      classes: "px-3 py-2 c-pointer", //console.log("click rowEvents disabled in prod.");
      //classes: "px-3 py-2",
      formatter: (cellContent, row) => firtColumnDisplay(cellContent, row),
      sort: true,
    },
    {
      text: "EMAIL ADDRESS",
      headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
      classes: "px-3 py-2 c-pointer", //console.log("click rowEvents disabled in prod.");
      //classes: "px-3 py-2",
      sort: true,
      dataField: "email_Address",
    },
    {
      text: "EMAIL LIST TYPE",
      dataField: "email_List_Type_Id",
      headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
      formatter: (cellContent, row) => showListType(cellContent),
      hidden: true,
    },
    {
      text: "Access Level",
      dataField: "email_privilege",
      headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
      formatter: (cellContent, row) => showListAccessLevel(cellContent),
      hidden: true,
    },
    {
      text: "EMAIL ENTITY TYPE",
      dataField: "entity_key",
      hidden: true,
    },
    //Primary key of
    //saved_search_email_list
    //facility_region_email_list
    {
      text: "EMAIL LIST ID",
      dataField: "id",
      hidden: true,
    },
    {
      text: "MAILING LIST NAME",
      dataField: "mailingListName",
      hidden: true,
    },
    {
      text: "",
      dataField: "actions",
      isDummyField: true,
      headerClasses: "bg-dark text-white px-sm-1 py-2",
      headerAttrs: {
        width: "15%",
      },
      formatter: (cell, row) => {
        return Actions(cell, row);
      },
      csvExport: false,
    },
  ];
  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     const { email_List_Type_Id, entity_Key } = row;
  //     if (email_List_Type_Id == MailGunEmailTypes.Facility) {
  //       chistory.push(`/directory/facility/view/${entity_Key}`, {
  //         emailListDetails: { ...row },
  //       });
  //       //**Saved Searches**
  //     } else if (email_List_Type_Id == MailGunEmailTypes.Member) {
  //       chistory.push(`/directory/myfilters`, {
  //         emailListDetails: { ...row },
  //       });
  //     } else if (email_List_Type_Id == MailGunEmailTypes.Team) {
  //       chistory.push(`/permissions/teams/teamDetail/${entity_Key}`, {
  //         emailListDetails: { ...row },
  //       });
  //     } else if (email_List_Type_Id == MailGunEmailTypes.Registrants) {
  //       chistory.push(`/admin/requests`, {
  //         emailListDetails: { ...row },
  //       });
  //     }
  //     // chistory.push(`/admin/email-list/view/${row.id}`, {
  //     //   emailListDetails: { ...row },
  //     // });
  //     //console.log("click rowEvents disabled in prod.");
  //   },
  // };
  const RemotePagination = (data, loading) => (
    <div>
      <BootstrapTable
        keyField="rowNumber"
        data={data}
        columns={columns()}
        loading={loading}
        pagination={paginationFactory({
          paginationTotalRenderer: TableCustomTotal,
        })}
        //rowEvents={rowEvents}
        noDataIndication={() => {
          return loading === false ? (
            <NoDataIndication />
          ) : (
            <div className="d-flex justify-content-center p-4">
              <Spinner />
            </div>
          );
        }}
      />
    </div>
  );

  return <>{RemotePagination(listData, loading)}</>;
};

MailingListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

MailingListTable.defaultProps = {
  listData: [],
};

export default connect((state) => ({
  savedSearchState: state.saveSearch,
}))(MailingListTable);
