import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
const actions = {
  //ActionNameConsts
  SET_FACILITY_ACTIVITY_DATA: "SET_FACILITY_ACTIVITY_DATA",
  SET_MEMBER_ACTIVITY_DATA: "SET_MEMBER_ACTIVITY_DATA",
  IS_ACCORDION_LOADING: "IS_ACCORDION_LOADING",
  SET_REQUEST_FETCH: "SET_REQUEST_FETCH",
  SET_LOG_MODULE_TYPE: "SET_LOG_MODULE_TYPE",
  TOGGLE_MEMBER_LOG_CARD: "TOGGLE_MEMBER_LOG_CARD",
  SHOW_ACTIVITYLOG_CHANGE: "SHOW_ACTIVITYLOG_CHANGE",
  RESET_LOGS: "RESET_LOGS",
  TOGGLE_TEAM_LOG_CARD: "TOGGLE_TEAM_LOG_CARD",
  TOGGLE_EMAIL_LOG_CARD: "TOGGLE_EMAIL_LOG_CARD",

  //ActionNameConsts END

  ///Action Methods

  resetLogs: () => (dispatch) => {
    dispatch({
      type: actions.RESET_LOGS,
    });
  },

  showActivityLogChange: (id, self) => (dispatch) => {
    dispatch({
      type: actions.SHOW_ACTIVITYLOG_CHANGE,
      payload: id,
    });
    self.toggleModal();
  },

  setAccordionLoading: (val) => (dispatch) => {
    dispatch({
      type: actions.IS_ACCORDION_LOADING,
      payload: val,
    });
  },

  toggleMemberLogAccordion: (val) => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_MEMBER_LOG_CARD,
      payload: val,
    });
  },

  toggleTeamLogAccordion: (val) => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_TEAM_LOG_CARD,
      payload: val,
    });
  },
  toggleEmailListLogAccordion: (val) => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_EMAIL_LOG_CARD,
      payload: val,
    });
  },
  getActicityLoggingByModuleId: (moduletype, id, offset, fetch, table) => (
    dispatch
  ) => {
    dispatch({
      type: actions.SET_LOG_MODULE_TYPE,
      payload: moduletype,
    });
    dispatch({
      type: actions.IS_ACCORDION_LOADING,
      payload: true,
    });
    let url =
      offset === null || fetch === null
        ? `api/ActivityLogging?moduletype=${moduletype}&id=${id}&offset=0&fetch=10&table=${table}`
        : `api/ActivityLogging?moduletype=${moduletype}&id=${id}&offset=${offset}&fetch=${fetch}&table=${table}`;
    //$(".loading").show();
    axios.get(url).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        dispatch({
          type: actions.SET_FACILITY_ACTIVITY_DATA,
          payload: data.responseData,
        });
        dispatch({
          type: actions.IS_ACCORDION_LOADING,
          payload: false,
        });
        dispatch({
          type: actions.SET_REQUEST_FETCH,
          payload: fetch,
        });
        //$(".loading").hide();
      } else {
        toast.error(data.message);
        ///$(".loading").hide();
      }
    });
  },
};

export default actions;
