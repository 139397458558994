import actions from "./action";

const initialState = {
  toggleAddQuestionModal: false,
  selectedOpenRegRow: {},
  selectedOpenRegQuestions: [],
  isLoadingProfileRegistrations: false,
  memberRegistrationsVisible: true,
  memberProfileRegistrations: [],
  openRegistrations: [],
  adminChildTemplates: [],
  openAddTemplateModal: false,
  openAddDiscountModal: false,
  templateObj: {},
  discountObj: {},
  paymentStatus: false,
  pendingApprovalRegistrations: [],
  adminRegisRecords: [],
  adminInstrRecords: [],
  isLoadingPendingApprovalList: false,
  selectedAddtlRegistrantMembers: [],
  additionalRegistrantMembers: [],
  templateAttachmentFiles: [],
  IsTemplateAttachmentsLoading: false,
};

export default function registrations(state = initialState, action) {
  switch (action.type) {
    case actions.SET_USER_HEADER_ACTIVE_TAB: {
      return {
        ...state,
        activeSubHeader: action.payload,
      };
    }

    case actions.SET_ADMIN_HEADER_ACTIVE_TAB: {
      return {
        ...state,
        activeSubHeader: action.payload,
      };
    }
    case actions.OPEN_ADD_TEMPLATE_MODAL: {
      return {
        ...state,
        openAddTemplateModal: true,
        templateObj: action.payload,
      };
    }
    case actions.CLOSE_ADD_TEMPLATE_MODAL: {
      return {
        ...state,
        openAddTemplateModal: false,
      };
    }
    case actions.OPEN_ADD_DISCOUNT_MODAL: {
      return {
        ...state,
        openAddDiscountModal: true,
        discountObj: action.payload,
      };
    }
    case actions.CLOSE_ADD_DISCOUNT_MODAL: {
      return {
        ...state,
        openAddDiscountModal: false,
      };
    }
    case actions.TOGGLE_ADD_QUESTION_MODAL: {
      return {
        ...state,
        toggleAddQuestionModal: !state.toggleAddQuestionModal,
      };
    }
    case actions.SET_OPEN_REG_ROW: {
      return {
        ...state,
        selectedOpenRegRow: action.payload,
      };
    }
    case actions.SET_REG_QUESTIONS: {
      return {
        ...state,
        selectedOpenRegQuestions: action.payload,
      };
    }
    case actions.SET_PROFILE_REGIS_LOADING: {
      return {
        ...state,
        isLoadingProfileRegistrations: action.payload,
      };
    }

    case actions.TOGGLE_MEM_REG_ACCORDION: {
      return {
        ...state,
        memberRegistrationsVisible: action.payload,
      };
    }
    case actions.SET_OPEN_REGISTRATIONS: {
      return {
        ...state,
        openRegistrations: action.payload,
      };
    }
    case actions.SET_ADMIN_CHILD_TEMPLATES: {
      return {
        ...state,
        adminChildTemplates: action.payload,
      };
    }
    case actions.GET_MEMBER_PROFILE_REGISTRATIONS: {
      return {
        ...state,
        memberProfileRegistrations: action.payload,
      };
    }
    case actions.SET_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatus: action.payload,
      };
    }
    case actions.SET_PENDING_APVL_REGIS_LIST: {
      return {
        ...state,
        pendingApprovalRegistrations: action.payload,
      };
    }
    case actions.SET_ADMIN_REGIS_LIST: {
      return {
        ...state,
        adminRegisRecords: action.payload,
      };
    }
    case actions.SET_ADMIN_REGIS_INSTR_LIST: {
      return {
        ...state,
        adminInstrRecords: action.payload,
      };
    }
    case actions.SELECT_ADDTL_REGISTRANT_MEMBER:
      return {
        ...state,
        selectedAddtlRegistrantMembers: [
          ...state.selectedAddtlRegistrantMembers,
          action.payload,
        ],
      };
    case actions.DE_SELECT_ADDTL_REGISTRANT_MEMBER:
      return {
        ...state,
        selectedAddtlRegistrantMembers: state.selectedAddtlRegistrantMembers.filter(
          (f) => f.memberId !== action.payload
        ),
      };
    case actions.REMOVE_STAND_BY_ADDTL_REGIS_MEMS: {
      return {
        ...state,
        selectedAddtlRegistrantMembers: state.selectedAddtlRegistrantMembers.filter(
          (f) => f.id != 0
        ),
      };
    }
    case actions.ADD_MULTIPLE_REGISTRANT_MEMBERS:
      var newMembers = action.payload;
      let allNewMembers = [...state.additionalRegistrantMembers, ...newMembers];
      return {
        ...state,
        additionalRegistrantMembers: allNewMembers,
      };
    case actions.SET_ADDTL_STD:
      return {
        ...state,
        additionalRegistrantMembers: action.payload,
      };
    case actions.SET_TEMPLATE_ATTACHMENTS_LOADING:
      //    debugger
      return {
        ...state,
        IsTemplateAttachmentsLoading: action.payload,
      };
    case actions.SET_TEMPLATE_ATTACHMENTS_FILES:
      //    debugger
      return {
        ...state,
        templateAttachmentFiles: action.payload,
      };

    case actions.SLICE_TEMPLATE_ATTACHMENTS_FILES:
      return {
        ...state,
        templateAttachmentFiles: {
          data: state.templateAttachmentFiles.data.filter((el, index) => {
            return !el.includes(action.payload);
          }),
        },
      };
    default:
      return state;
  }
}
