import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../../Shared/auth-header";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import $ from "jquery";
import ConfirmDeleteModal from "../../Shared/DeleteModalTeam";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MDBInput } from "mdbreact";
import _ from "lodash";
import Services from "../PermissionService";
import history from "../../Shared/history";

const { SearchBar } = Search;

const selectRow = {
  mode: "checkbox",
  clickToSelect: true,
  //  onSelect: this.handleOnSelect,
  //  onSelectAll: this.handleOnSelectAll
};
class DeleteTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childTeams: _.cloneDeep(this.props.childTeams),
      deleteTeamList: [],
      parentChecked: false,
      redirectToTeam: false,
    };
    this.confirmDeleteTeams = this.confirmDeleteTeams.bind(this);
    this.listTeam = this.listTeam.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeParentChecked = this.handleChangeParentChecked.bind(this);
    this.confirmedDeleteTeams = this.confirmedDeleteTeams.bind(this);
  }
  handleChangeParentChecked(e) {
    //  e.preventDefault();

    this.state.parentChecked = e.target.checked;
    this.setState({ parentChecked: this.state.parentChecked });
  }

  handleChange(e) {
    let id = e.target.dataset.parentchildid;
    let checked = e.target.checked;
    //e.preventDefault();
    const filterChild = (childTeams) => {
      return childTeams.map((item) => {
        let haveChilds = false;
        if (item.childs.length > 0) {
          item.childs = filterChild(item.childs);
        }
        if (item.parentChildID == id) {
          item.checked = checked;
        }
        return item;
      });
    };

    this.setState({ childTeams: filterChild(this.state.childTeams) });
  }
  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.childTeams) != this.state.childTeams) {
      this.setState({
        childTeams: _.cloneDeep(nextProps.childTeams),
      });
    }
  }
  componentDidUpdate() {
    if (this.state.redirectToTeam) {
      this.redirectorToTeamPage();
    }
  }
  listTeam = () => {
    const createHierarchy = (arr, showVal) => {
      let show = /*(this.state.searchChildTeamTerm == '') ? showVal : */ true;
      return arr.map((item, idx) => {
        //if (idx > 0 && this.state.searchChildTeamTerm == '') {
        //    show = false;
        //}
        return (
          <div>
            <div className="pl-2">
              <div className="card-text row mx-0 border px-3 py-1 w-100">
                <div className="px-0 mt-n1 custom-checkbox">
                  <input
                    id={"checkbox" + idx + item.parentChildID}
                    name={"checkbox" + idx + item.parentChildID}
                    type="checkbox"
                    checked={item.checked}
                    onChange={this.handleChange}
                    data-parentchildid={item.parentChildID}
                    className="custom-control-input"
                  />
                  <label
                    class="custom-control-label"
                    for={"checkbox" + idx + item.parentChildID}
                  ></label>
                </div>
                <div
                  data-toggle="collapse"
                  href={"#delCollapse" + idx + item.id}
                  aria-expanded={show}
                  id={"heading" + idx + item.id}
                  aria-controls={"delCollapse" + idx + item.id}
                  className="mb-0 col-11 px-0 c-pointer "
                >
                  {item.teamName}
                  <span className="float-right font-italic px-2">
                    {item.ownerName}
                  </span>
                </div>
              </div>
              <div
                id={"delCollapse" + idx + item.id}
                className={"collapse" + (show ? " show" : "")}
              >
                {item.childs.length > 0 && createHierarchy(item.childs, false)}
              </div>
            </div>
          </div>
        );
      });
    };
    const filterChild = (search) => {
      return search
        .map((item) => {
          let haveChilds = false;
          if (item.childs.length > 0) {
            item.childs = filterChild(item.childs);
            if (item.childs.length > 0) {
              haveChilds = true;
            }
          }
          if (
            _.toLower(item.teamName).includes(
              _.toLower(this.state.searchChildTeamTerm || "")
            ) ||
            _.toLower(item.ownerName).includes(
              _.toLower(this.state.searchChildTeamTerm || "")
            ) ||
            haveChilds
          ) {
            return item;
          }
        })
        .filter(function(el) {
          return el;
        });
    };
    let searchChildTeam = filterChild(_.cloneDeep(this.state.childTeams));
    return createHierarchy(searchChildTeam, true);
  };
  confirmDeleteTeams() {
    let childChecked = false;
    if (Array.isArray(this.state.childTeams)) {
      let filterCheckedChild = (childTeams) =>
        childTeams.map((item) => {
          if (!childChecked) {
            if (item.childs.length > 0) {
              filterCheckedChild(item.childs);
            }
            if (item.checked) {
              childChecked = true;
            }
          }
        });
      filterCheckedChild(this.state.childTeams);
    }

    if (this.state.parentChecked || childChecked) {
      window.$("#DeleteModalTeams").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    } else {
      toast.error("Please, select at least one team for delete!");
    }
  }
  confirmedDeleteTeams = async () => {
    $(".loading").show();
    let obj = { fatherTeam: 0, teamID: null, childTeamIDs: [] };
    var mySelf = this;
    const fatherTeam = mySelf.props.fatherTeam;
    const isChild = mySelf.props.isChild;
    if (isChild) {
      obj.fatherTeam = fatherTeam;
    } else {
      obj.fatherTeam = this.props.parentTeamId;
    }

    if (this.state.parentChecked) {
      obj.teamID = this.props.parentTeamId;
    }

    if (Array.isArray(this.state.childTeams)) {
      let deleteChilds = [];
      let filterDeleteChild = (childTeams) =>
        childTeams.map((item) => {
          if (item.childs.length > 0) {
            item.childs = filterDeleteChild(item.childs);
          }
          if (item.checked) {
            deleteChilds.push(item.parentChildID);
          }
        });

      filterDeleteChild(this.state.childTeams);
      obj.childTeamIDs = deleteChilds;
    }
    let data = await Services.DeleteTeams(obj);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
          toast.success("Teams deleted successfully!");
          let url = `/api/FacilityRegionEmail/SyncRemoveTeams`;
          await axios.post(url,obj).then(({ data }) => {
              if (data.status && data.status.toLowerCase() == "success") {
              } else {
              }
          });
        if (this.state.parentChecked) {
          window.$("#DeleteModalTeams").on("hidden.bs.modal", function() {
            history.push("/permissions/teams");
          });
          window.$("#DeleteTeamModal").modal("hide");
          window.$("#DeleteModalTeams").modal("hide");
        } else {
          this.props.setReloader();
        }
        this.cancelModal();
      }
    }
    $(".loading").hide();
  };
  componentDidMount() {}
  redirectorToTeamPage() {
    history.push("/permissions/teams");
  }
  cancelModal = () => {
    this.setState({
      childTeams: _.cloneDeep(this.props.childTeams),
      parentChecked: false,
    });
    window.$("#DeleteTeamModal").modal("hide");
    window.$("#DeleteModalTeams").modal("hide");
  };
  render() {
    return (
      <AUX>
        <div
          class="modal fade"
          id="DeleteTeamModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="DeleteTeamModal"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5
                  class="modal-title text-uppercase text-white mt-0"
                  id="DeleteTeamModalTitle"
                >
                  DELETE TEAM
                </h5>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  onClick={this.cancelModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-12">
                    <p>Select the team that you want to delete.</p>

                    <div className="card-text row mx-0 border px-3 py-1 w-100">
                      <div className="px-0 mt-n1 custom-checkbox">
                        <input
                          id={"checkbox" + this.props.parentTeamId}
                          name={"checkbox" + this.props.parentTeamId}
                          type="checkbox"
                          checked={this.state.parentChecked}
                          onChange={this.handleChangeParentChecked}
                          data-id={this.props.parentTeamId}
                          className="custom-control-input"
                        />
                        <label
                          class="custom-control-label mt-5"
                          for={"checkbox" + this.props.parentTeamId}
                        ></label>
                      </div>
                      <div className="mb-0 h4 px-0 c-pointer ">
                        {this.props.parentTeamName}
                        <span className="float-right font-italic px-2"></span>
                      </div>
                    </div>
                    <div id="deleteChildTeamList">{this.listTeam()}</div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-4"></div>
                  <div className="col-8">
                    <div className="text-right">
                      <button
                        className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light"
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.cancelModal}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={this.confirmDeleteTeams}
                        className="btn btn-danger btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                        type="submit"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDeleteModal
          deleteChild={false}
          parentTeamName={this.props.parentTeamName}
          parentChecked={this.state.parentChecked}
          TeamsData={this.state.childTeams}
          confirm={this.confirmedDeleteTeams}
        />
      </AUX>
    );
  }
}

export default DeleteTeam;
