import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { bindActionCreators } from "redux";
import payfileActions from "../../store/payfile/action";
import { connect } from "react-redux";
import ImportSummaryTableList from "./ImportSummaryTableList";
import _ from "lodash";

export class MainImportSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: 0,
      code: "",
      region: "",
      description: "",
      rvpemail: "",
      rvpphone: "",
      valid: true,
      emailError: "",

      rows: [],
      totalCount: "",
      delId: 0,
    };
  }

  handleDataChange = ({ dataSize }) => {
    this.props.actions.setTotalDataCountSummary(dataSize);
  };
  componentWillMount() {
    const formData = {
      PageNo: 1,
      PageSize: 10,
      SortBy: "file_id",
      SortOrder: "desc",
    };
    this.props.actions.getSummaryList(formData);
    this.props.actions.resetReviewScreenData();
  }
  onSearch = (text) => {
    const formData = {
      PageNo: 1,
      PageSize: 10,
      SortBy: "year_period",
      SortOrder: "asc",
      Keyword: text,
    };

    this.props.actions.getSummaryList(formData);
  };
  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage, filters }
  ) => {
    console.log("table change" + type);
    if (type === "sort") {
    }
    if (type === "search") {
      return false;
    }
    if (type == "pagination" && _.isEmpty(sortOrder) && _.isEmpty(sortField)) {
      sortField = "file_id";
      sortOrder = "desc";
    }
    //const { filterVal, filterType, comparator } = filters["year_period"];

    console.log("sortField + sortOrder" + sortField + sortOrder);
    this.props.actions.setSummaryTablePaginationInfo(page, sizePerPage);
    const formData = {
      PageNo: page,
      PageSize: sizePerPage,
      SortBy: sortField,
      SortOrder: sortOrder,
    };

    this.props.actions.getSummaryList(formData);
  };

  render() {
    return (
      <AUX>
        <div className="container-fluid">
          <br />
          <div className="row align-items-center">
            <div className="col-sm-6">
              <h4 className="page-title-search">Import Summary</h4>
            </div>
          </div>
          <ImportSummaryTableList
            listMembers={this.props.payFileState.summaryRows}
            totalSize={this.props.payFileState.totalSummaryCount}
            sizePerPage={this.props.payFileState.summarySizePerPage}
            onTableChange={this.handleTableChange}
            page={this.props.payFileState.summaryPageNo}
            loading={this.props.payFileState.isLoadingSummary}

            //onSearch={this.onSearch}
            //dataSizeChange={this.handleDataChange}
          />
        </div>
      </AUX>
    );
  }
}

///export default MainImportSummary;

function mapStateToProps(state, ownProps) {
  return {
    payFileState: state.payfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(payfileActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainImportSummary);
