import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class ShirtSize extends Component {
  onChange(item) {
    var shirtSizeIndex = _.findIndex(
      this.props.filterState.filterData.shirtSize,
      (o) => {
        return o.shirtSize === item.shirtSize;
      }
    );
    item.checked = !this.props.filterState.filterData.shirtSize[shirtSizeIndex]
      .checked;
    this.props.filterState.filterData.shirtSize[shirtSizeIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item) {
    return (
      <li className="chechbox-list_item" key={"sliitem-" + item.shirtSize}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"szsitem-" + item.shirtSize}
        >
          <input
            type="checkbox"
            htmlFor={"szitem-" + item.shirtSize}
            value={item.shirtSize}
            checked={item.checked}
            className="custom-control-input"
            id={"szitem-" + item.shirtSize}
            name={"szitem-" + item.shirtSize}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"szitem-" + item.shirtSize}
          >
            {item.shirtSize}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Shirt Size</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="filter-fields-scroll">
          <ul className="chechbox-list">
            {this.props.filterState.filterData.shirtSize &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.shirtSize.map((item) =>
                this.chechboxes(item)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(ShirtSize);
