import React, { Component } from "react";
import InputRange from "react-input-range";
import { change } from "redux-form";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
import { store } from "../../../../index";

const onFromToInputChange = (event, newValue, previousValue, name) => {
  if (name === "FromVal") {
    store.dispatch(
      change("fe_actualValsForm", "ActualCost[min]", Number(newValue))
    );
  } else if (name === "ToVal") {
    store.dispatch(
      change("fe_actualValsForm", "ActualCost[max]", Number(newValue))
    );
  }
};

const onSliderInputChange = (event, newValue, previousValue, name) => {
  const { min, max } = newValue;

  store.dispatch(change("fe_actualValsForm", "FromVal", min));
  store.dispatch(change("fe_actualValsForm", "ToVal", max));
};
export class FE_ActualVals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { min: 1, max: 50000 },
    };
  }
  renderInputText = ({
    input,
    label,
    classForm,
    type,
    meta,
    name,
    placeholder,
  }) => {
    //console.log(meta)
    //console.log(label);
    return (
      <div className="form-group">
        {label !== "" && !this.props.isMobile ? (
          <label htmlFor={name}>{label} </label>
        ) : placeholder == "Value" ? (
          ""
        ) : (
          <label />
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          style={
            placeholder === "Value" ? { width: "auto" } : null
            //, { color: this.props.isMobile ? "white" : null }
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <div class="mb-3">
            <label>Min </label>
            <label style={{ float: "right" }}>Max</label>
          </div>
        ) : (
          <label> </label>
        )}
        <div class="range-slider">
          <InputRange
            formatLabel={(value) => `$${value}`}
            maxValue={50000}
            minValue={1}
            value={input.value}
            onChange={input.onChange}
          />
        </div>
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    // console.log(formValues);
  };
  validateNumber = (value) => {
    if (value && isNaN(Number(value))) {
      return "Please enter numeric characters only.";
    } else {
      return undefined;
    }
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Actual Cost</h4>
            <hr />
            <br />
          </>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Row>
            <Col className="m-2 m-sm-2 ad-range-box">
              <Field
                name="ActualCost"
                id="ActualCost"
                classForm="form-control"
                component={this.renderInput}
                labelFor="ActualCost"
                onChange={onSliderInputChange}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }  `}
            >
              <Field
                name="FromVal"
                validate={[this.validateNumber]}
                id="FromVal"
                classForm="form-control"
                placeholder="From"
                label="From"
                component={this.renderInputText}
                labelFor="FromVal"
                onChange={onFromToInputChange}
              />
            </Col>
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }  `}
            >
              <Field
                name="ToVal"
                id="ToVal"
                validate={[this.validateNumber]}
                classForm="form-control"
                placeholder="To"
                label="To"
                component={this.renderInputText}
                labelFor="ToVal"
                onChange={onFromToInputChange}
              />
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "fe_actualValsForm",
  destroyOnUnmount: false,
  initialValues: {
    ActualCost: { min: 1, max: 50000 },
  },
})(FE_ActualVals);

const mapStateToProps = (state) => ({
  errorMessage: {},
  formValues: state.form,
  currentState: state,
});

export default connect(
  mapStateToProps,
  {}
)(formWrapped);
