import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import AuthService from "../../../../modules/Auth/AuthService";
import PermissionFeature from "../../../../modules/Permissions/PermissionFeature";
import FE_AF_Nav_Items from "../FE_AF_Nav_Items";
import { bindActionCreators } from "redux";
import advanceFilterActions from "../../../../store/advancedfilter/action";
import fetrackerActions from "../../../../store/feTracker/action";
import { Nav, NavItem, NavLink, Col, Collapse, CardBody } from "reactstrap";
import FE_Tabs from "../../FE_Tabs";

export class DeskTopSideBar extends Component {
  constructor(props) {
    super(props);
    this.DEBUG = process.env.NODE_ENV === "development";
  }
  memberDetailsSection() {
    return (
      <>
        <Collapse isOpen={this.props.festate.collaps == 2}>
          <Nav tabs vertical pills>
            {/* ########################################################################## */}
            {/*  ####################Request AdvanceFilter Tabs ########################## */}
            {/* ########################################################################## */}
            {(this.props.festate.activeSubHeader == FE_Tabs.MyRequests ||
              this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests) && (
              <>
                {AuthService.canView(PermissionFeature.FERequestStatus) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.Status,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.Status
                        );
                      }}
                    >
                      STATUS
                    </NavLink>
                  </NavItem>
                )}
                {/*########### REQ-1 Requests F/R/T Checkboxes##############*/}
                {AuthService.canView(PermissionFeature.FERequestType) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab === FE_AF_Nav_Items.Type,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.Type
                        );
                      }}
                    >
                      TYPE
                    </NavLink>
                  </NavItem>
                )}
                {/* {AuthService.canView(
                  PermissionFeature.FEAccountingStickerNumber
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.StickerNo,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.StickerNo
                        );
                      }}
                    >
                      STICKER NUMBER
                    </NavLink>
                  </NavItem>
                )}
                {AuthService.canView(
                  PermissionFeature.FEAccountingPurchaseDate
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.PurchaseDate,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.PurchaseDate
                        );
                      }}
                    >
                      PURCHASE DATE
                    </NavLink>
                  </NavItem>
                )} */}
                {AuthService.canView(PermissionFeature.FERequestUser) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.UserRequest,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.UserRequest
                        );
                      }}
                    >
                      USER
                      {/* Request User */}
                    </NavLink>
                  </NavItem>
                )}

                {AuthService.canView(
                  PermissionFeature.FERequestDescription
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.Description,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.Description
                        );
                      }}
                    >
                      DESCRIPTION
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
            {/* ########################################################################## */}
            {/* ####################Active Reporting on Equipment######################### */}
            {/* ########################################################################## */}
            {this.props.festate.activeSubHeader == FE_Tabs.Inventory && (
              <>
                {AuthService.canView(PermissionFeature.FEAccountingUser) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.UserInventory,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.UserInventory
                        );
                      }}
                    >
                      USER
                      {/* User for searching Equipment/Inentory */}
                    </NavLink>
                  </NavItem>
                )}
                {/* Remove Staus Filter for Equipment */}
                {/* {AuthService.canView(PermissionFeature.FEAccountingStatus) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.Status,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.Status
                        );
                      }}
                    >
                      STATUS
                    </NavLink>
                  </NavItem>
                )} */}
                {AuthService.canView(
                  PermissionFeature.FEAccountingStickerNumber
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.StickerNo,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.StickerNo
                        );
                      }}
                    >
                      STICKER NUMBER
                    </NavLink>
                  </NavItem>
                )}

                {AuthService.canView(
                  PermissionFeature.FEAccountingPurchaseDate
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.PurchaseDate,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.PurchaseDate
                        );
                      }}
                    >
                      PURCHASE DATE
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
          </Nav>
        </Collapse>
      </>
    );
  }

  render() {
    const myProps = this.props;

    return (
      <Col
        className="spac-remove  modalScroll fe-sidebar-adv borderNone"
        xs="3"
        sm="3"
        md="3"
        xl="3"
        lg="3"
      >
        {/*First Parent Accordion */}
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.fetrackerActions.setCollapseTab(2)}
        >
          <a>
            {this.props.festate.activeSubHeader > FE_Tabs.Inventory
              ? "REQUEST DETAILS"
              : "EQUIPMENT DETAILS"}
          </a>
        </div>

        {/* Old Accordion from initial requirements */}
        {AuthService.canView(PermissionFeature.Directory) &&
        AuthService.canView(PermissionFeature.Member)
          ? this.memberDetailsSection()
          : null}

        {/*New First Parent Accordion */}
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.fetrackerActions.setCollapseTab(1)}
        >
          <a>OWNER</a>
        </div>
        <Collapse isOpen={this.props.festate.collaps == 1}>
          <Nav tabs vertical pills>
            {/*  ####################Request AdvanceFilter Tabs for Owner Accordion ########################## */}
            {(this.props.festate.activeSubHeader == FE_Tabs.MyRequests ||
              this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests) && (
              <>
                {AuthService.canView(PermissionFeature.FERequestBudgetID) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.Budget,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.Budget
                        );
                      }}
                    >
                      BUDGET LINE
                    </NavLink>
                  </NavItem>
                )}

                {AuthService.canView(PermissionFeature.FERequestCategory) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.RegionRequest,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.RegionRequest
                        );
                      }}
                    >
                      REGION
                    </NavLink>
                  </NavItem>
                )}
                {/*########### REQ-1 Requests F/R/T Checkboxes##############*/}
                {/* {AuthService.canView(PermissionFeature.FERequestCategory) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.FacilityRequest,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.FacilityRequest
                        );
                      }}
                    >
                      FACILITY
                    </NavLink>
                  </NavItem>
                )} */}
                {AuthService.canView(PermissionFeature.FERequestCategory) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.TeamRequest,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.TeamRequest
                        );
                      }}
                    >
                      TEAM
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
            {/*  ####################Inventory AdvanceFilter Tabs for Owner Accordion ########################## */}
            {this.props.festate.activeSubHeader == FE_Tabs.Inventory && (
              <>
                {AuthService.canView(
                  PermissionFeature.FEAccountingBudgetID
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.Budget,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.Budget
                        );
                      }}
                    >
                      BUDGET
                    </NavLink>
                  </NavItem>
                )}
                {/*##############REQ-1 Inventory F/R/T Filters###########*/}
                {AuthService.canView(
                  PermissionFeature.FEAccountingCategory
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.RegionInventory,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.RegionInventory
                        );
                      }}
                    >
                      REGION
                    </NavLink>
                  </NavItem>
                )}
                {/* CARD 873 */}
                {/* {AuthService.canView(
                  PermissionFeature.FEAccountingCategory
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.FacilityInventory,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.FacilityInventory
                        );
                      }}
                    >
                      FACILITY
                    </NavLink>
                  </NavItem>
                )} */}
                {AuthService.canView(
                  PermissionFeature.FEAccountingCategory
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.TeamInventory,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.TeamInventory
                        );
                      }}
                    >
                      TEAM
                    </NavLink>
                  </NavItem>
                )}
                {/* /////New Filter of Inventory (NA Office)///////// */}
                {AuthService.canView(
                  PermissionFeature.FEAccountingCategory
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.NAOffice,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.NAOffice
                        );
                      }}
                    >
                      NATIONAL OFFICE
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
          </Nav>
        </Collapse>

        {/* New Accordion with respect to card#672 */}
        <div
          className="collapsBtnFirst"
          onClick={() => this.props.fetrackerActions.setCollapseTab(3)}
        >
          <a>VALUES</a>
        </div>
        <Collapse isOpen={this.props.festate.collaps == 3}>
          <Nav tabs vertical pills>
            {/* ########################################################################## */}
            {/* ####################Active Reporting on Equipment######################### */}
            {/* ########################################################################## */}
            {this.props.festate.activeSubHeader == FE_Tabs.Inventory && (
              <>
                {AuthService.canView(PermissionFeature.FEAccountingBookVal) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.BookValueInventory,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.BookValueInventory
                        );
                      }}
                    >
                      BOOK VALUE
                    </NavLink>
                  </NavItem>
                )}

                {AuthService.canView(PermissionFeature.FEAccountingCost) ? (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.ActualCost,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.ActualCost
                        );
                      }}
                    >
                      ACTUAL COST
                    </NavLink>
                  </NavItem>
                ) : null}
                {AuthService.canView(
                  PermissionFeature.FEAccountingUsefulLife
                ) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab === FE_AF_Nav_Items.Year,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.Year
                        );
                      }}
                    >
                      YEAR
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
            {(this.props.festate.activeSubHeader == FE_Tabs.MyRequests ||
              this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests) && (
              <>
                {AuthService.canView(PermissionFeature.FERequestBookValue) && (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.BookValueRequest,
                      })}
                      onClick={() => {
                        this.props.fetrackerActions.setCurrentAFActiveTab(
                          FE_AF_Nav_Items.BookValueRequest
                        );
                      }}
                    >
                      BOOK VALUE
                    </NavLink>
                  </NavItem>
                )}
              </>
            )}
          </Nav>
        </Collapse>
      </Col>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    festate: state.fetracker,
    filterState: state.advancedFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetrackerActions: bindActionCreators(fetrackerActions, dispatch),
    advanceFilterActions: bindActionCreators(advanceFilterActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeskTopSideBar);

// export default connect((state) => ({ festate: state.fetracker }), {
//   ...actionFilter,
// })(DeskTopSideBar);
