import React, { Component } from "react";
import { Route } from "react-router-dom";
import AuthService from "../../modules/Auth/AuthService";
import PermissionFeature from "../../modules/Permissions/PermissionFeature";
import { connect } from "react-redux";
import FETrackerAction from "../../store/feTracker/action";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { CardBody, Spinner } from "reactstrap";
import FEHeader from "./FEHeader";

class ProtectFERoute extends Component {
  componentDidMount = async () => {
    //console.log("componentDidMount from ProtectFERoute");
    if (
      this.props.festate.showReviewRequest == "" &&
      !this.props.festate.checkingApproverPerson
    ) {
      //console.log("checkIsBudgetApprover from ProtectFERoute");
      const result = await this.props.checkIsBudgetApprover();
    }
  };
  render() {
    const { component: Component, ...props } = this.props;
    const { url } = this.props.computedMatch;
    let shouldShow = false;
    if (url == "/FETracker/reviewRequest") {
      /// Review Request Hit
      shouldShow =
        ///AuthService.canView(PermissionFeature.FEApprover) ||
        this.props.festate.showReviewRequest;
    } else if (url == "/FETracker/myRequests") {
      shouldShow = AuthService.canView(PermissionFeature.FETrackerRequest);
    } else if (url == "/FETracker/inventory") {
      shouldShow = AuthService.canView(PermissionFeature.FEAdmin);
    } else if (url == "/FETracker/BudgetApprovers") {
      shouldShow = AuthService.canView(PermissionFeature.FETracker); //this.props.festate.isBudgetApproverPerson;
    }
    return (
      <Route
        {...props}
        render={(props) =>
          shouldShow ? (
            <Component {...props} />
          ) : !this.props.festate.checkingApproverPerson ? (
            <>
              <FEHeader />
              <br />

              <div class="h-100 fet-container container-fluid">
                <div className="row NoDataFoundTable">
                  <h4>We are Sorry...</h4>
                </div>
                <div class="row NoDataFoundTable">
                  <h5>
                    You are not authorized to view this content. Please contact
                    administrator.
                  </h5>
                </div>
              </div>
            </>
          ) : (
            <CardBody style={{ padding: "4rem" }}>
              <Spinner className="spinnerCard" />
            </CardBody>
          )
        }
      />
    );
  }
}
// export default ProtectFERoute;

export default withRouter(
  connect((state) => ({ festate: state.fetracker }), {
    ...FETrackerAction,
  })(ProtectFERoute)
);
