import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Progress } from "reactstrap";
import AuthService from "../Auth/AuthService";
import { toast } from "react-toastify";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: AuthService.getProfile,
            upLoadStatus: 0,
            progress: false,
            img: "",
            validSize: true
        };
        this.user = AuthService.getProfile().memberData;
        if (this.user) {
            this.address = this.user.addresses.find(ad => ad.isprimary);
            this.facAddress = this.user.facility.address;
        }
        // this.uploadHandler = this.uploadHandler.bind(this);
        // this.fileChangedHandler = this.fileChangedHandler.bind(this);
    }
    // // componentDidMount() {
    // //     if (this.user.imagepath) {
    // //         this.setState({ img: "/assets/" + this.user.imagepath.replace("\\ ", "/") });
    // //     }
    // // }
    // fileChangedHandler = event => {
    //     const file = event.target.files[0];
    //     var fr = new FileReader();
    //     if (!/\.(gif|jpe?g|png)$/i.test(file.name)) {
    //         toast.error("Unsupported File Type.");
    //         return false;
    //     }
    //     fr.onload = () => {
    //         var img = new Image();

    //         img.onload = () => {
    //             if (img.width > 200 || img.height > 150) {
    //                 toast.error("Unsupported File Size. Please use 200x150 Image.");
    //                 return false;
    //             } else {
    //                 this.uploadHandler(file);
    //             }
    //         };

    //         img.src = fr.result;
    //     };

    //     fr.readAsDataURL(event.target.files[0]);
    // };
    // uploadHandler = file => {
    //     const formData = new FormData();
    //     formData.append("File", file);
    //     formData.append("id", this.user.id);

    //     axios
    //         .post("/api/member/UploadImage/", formData, {
    //             headers: { "Content-Type": "multipart/form-data" },
    //             onUploadProgress: progressEvent => {
    //                 this.setState({ upLoadStatus: (progressEvent.loaded / progressEvent.total) * 100 });
    //             }
    //         })
    //         .then(
    //             function(res) {
    //                 if (res.data.status && res.data.status.toLowerCase() == "success") {
    //                     toast.success("Profile Image Updated.");
    //                     this.setState({ img: "/assets/" + res.data.data.replace("\\", "/") });
    //                 }
    //             }.bind(this)
    //         );
    // };
    redenProfile() {
        if (this.user) {
            return (
                <div className="card-body">
                    <h5 className="text-primary font-18 mt-0 mb-1">
                        {/* {this.user.firstname + " " + this.user.middlename + " " + this.user.lastname} */}
                        {
                            this.user && (
                                this.user.firstname + " " + this.user.nickname !== null && (this.user.nickname) + " " + this.user.middlename !== null && (this.user.middlename) + this.user.lastname !== null && this.user.lastname
                            )
                        }
                    </h5>
                    <p className="font-12 mb-2">{this.user.positiontitleopm}</p>
                    <p className="mb-4">
                        {this.user.emailAddresses.length > 0 && this.user.emailAddresses.filter(list => list.isprimary)[0].email}
                    </p>
                    <div className="clearfix" />
                    <div className="row">
                        <div className="col">
                            <h6 className="card-subtitle mb-2 text-muted">Member Number</h6>
                            <p className="card-text">{this.user.membernumber}</p>
                            <h6 className="card-subtitle mb-2 text-muted">Mailing Address</h6>
                            <p className="card-text">
                                {this.address && (
                                    <span>
                                        {this.address.address1}
                                        {this.address.address2 && (
                                            <span>
                                                <br />
                                                {this.address.address2}
                                            </span>
                                        )}
                                        <br />
                                        {this.address.city}, {this.address.state} {this.address.zipcode}
                                    </span>
                                )}
                            </p>
                            <h6 className="card-subtitle mb-2 text-muted">Phone</h6>
                            <p className="card-text">
                                {this.user.phones.map(function (ph, i) {
                                    return (
                                        <span key={i}>
                                            {" "}
                                            {ph.number}
                                            <br />
                                        </span>
                                    );
                                })}
                            </p>
                        </div>
                        <div className="col">
                            <div className="row mb-2">
                                <div className="col">
                                    <h6 className="card-subtitle mb-2 text-muted">Facility</h6>
                                    <p className="card-text">{this.user.facility.code}</p>
                                </div>
                                <div className="col">
                                    <h6 className="card-subtitle mb-2 text-muted">Region</h6>
                                    <p className="card-text">NSO</p>
                                </div>
                                <div className="col">
                                    <h6 className="card-subtitle mb-2 text-muted">Unit</h6>
                                    <p className="card-text">{this.user.unit}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h6 className="card-subtitle mb-2 text-muted">Senators</h6>
                                    <p className="card-text">
                                        Bill Nelson - D<br />
                                        Marco Rubio -R
                                        <br />
                                    </p>
                                    <h6 className="card-subtitle mb-2 text-muted">Representative</h6>
                                    <p className="card-text">Ted Yoho - R</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h1 className="page-title">Profile</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card directory-card">
                                {this.redenProfile()}
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default Profile;
