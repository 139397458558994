import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../store/action";
import { Link } from "react-router-dom";
import { MDBInput } from "mdbreact";
import AuthService from "./AuthService";
import axios from "../Shared/auth-header";
import RegisterModal from "../Shared/RegisterModal";
import $ from "jquery";
class AccountRecovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      message: "",
      status: false,
      success: "alert-danger",
      memberNumber: "",
      showModal: false,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMemberNumber = this.handleChangeMemberNumber.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.Auth = AuthService;
  }

  handleChangeMemberNumber(e) {
    this.setState({ memberNumber: e.target.value });
  }

  handleChangeEmail(e) {
    this.setState({ username: e.target.value });
    let EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value == "") {
      this.setState({ username_err: "Required Field" });
      this.setState({ valid: false });
    } else if (EmailReg.test(e.target.value)) {
      this.setState({ username_err: "" });
      this.setState({ valid: true });
    } else {
      this.setState({ username_err: "Enter Valid Email" });
      this.setState({ valid: false });
    }
  }
  handleFormSubmit(e) {
    e.preventDefault();
    if (this.state.username === "") {
      this.setState({ username_err: "Required Field" });
      this.setState({ valid: false });
      return false;
    }

    if (this.state.valid) {
      $(".loading").show();
      axios
        .post("/api/Account/ForgotUser", {
          EmailAddress: this.state.username,
          MemberNumber: this.state.memberNumber,
        })
        .then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            this.setState({ success: "alert-success" });
            this.setState({ message: data.message });
            this.setState({ status: true });
            this.setState({ username: "" });
          } else {
            this.setState({ status: true });
            this.setState({ message: data.message });
          }
          $(".loading").hide();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  handleOnBlur(e) {
    const body = {
      memberNumber: this.state.memberNumber,
    };
    axios
      .post(
        `/api/Account/CheckMemberStatus?memberNumber=${this.state.memberNumber}`
      )
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
        } else {
          this.setState({ showModal: true });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  componentDidMount() {
    if (this.props.loginpage === false) {
      this.props.UpdateLogin();
    }

    window.onpopstate = (e) => {
      this.props.UpdateLoginAgain();
    };
  }
  componentWillMount() {
    if (this.Auth.loggedIn()) {
      this.props.UpdateLoginAgain();
      this.props.history.replace("/");
    }
  }
  render() {
    return (
      <div className="wrapper-page">
        <div className="card overflow-hidden account-card mx-3">
          <div className="bg-primary ptb-login text-white text-center position-relative">
            {/*<h4 className="font-20 m-b-5">Account Recovery !</h4>*/}
            <span className="logo logo-admin">
              <img src="assets/images/natca-logo.png" height="142" alt="logo" />
            </span>
          </div>
          <div className="account-card-content m-t-40">
            {this.state.status && (
              <div className={`alert ${this.state.success}`} role="alert">
                {this.state.message}
              </div>
            )}
            <div className="col-16 m-t-20 text-left font-weight-bold">
              {<h5>Reset Password</h5> /* <span>accountRecovery</span> */}
              <small>
                Enter your Member Number and an email address associated with
                your account to receive a password reset email.
              </small>
            </div>
            <form
              className="form-horizontal mt-2"
              onSubmit={this.handleFormSubmit}
            >
              <div className="form-group">
                <MDBInput
                  name="memberNumber"
                  value={this.state.memberNumber}
                  onChange={this.handleChangeMemberNumber}
                  label="Enter Member Number"
                  group
                  type="text"
                  id="memberNumber"
                  onBlur={this.handleOnBlur}
                ></MDBInput>
                <MDBInput
                  name="username"
                  value={this.state.username}
                  onChange={this.handleChangeEmail}
                  label="Enter Email"
                  group
                  type="text"
                  id="username"
                >
                  <span className="text-danger">{this.state.username_err}</span>
                </MDBInput>
              </div>

              <div className="form-group m-t-10 mb-4 row">
                <div className="col-8 m-t-20 mx-auto text-center">
                  <button
                    disabled={!this.state.valid}
                    className="btn btn-primary btn-block w-md waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>

                <div className="col-12 mt-3 text-left font-weight-lighter">
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="font-500 text-primary text-right"
                  >
                    {" "}
                    Login{" "}
                  </Link>{" "}
                </div>
                <div className="col-12 mt-1">
                  Need an account?{" "}
                  <Link to="register" className="font-500 text-primary">
                    {" "}
                    Register{" "}
                  </Link>{" "}
                </div>
                <div className="col-12 text-left font-weight-bold">
                  By registering for an account with NATCA you agree to our
                  <Link
                    to="https://www.natca.org/privacy-policy"
                    target="_blank"
                    className="font-500 text-primary text-right"
                  >
                    {" "}
                    Terms & Conditions.{" "}
                  </Link>{" "}
                </div>

                <div className="col-12 m-t-5 text-left font-weight-bold">
                  Need help?
                </div>
                <div className="col-12 m-t-5 text-left font-weight-lighter">
                  Forgot your Member Number?{" "}
                  <Link to="/MemberNumberLookup"> Member Number Lookup</Link>
                </div>
                <div className="col-12 m-t-5 text-left font-weight-lighter">
                  Unable to complete account registration?{" "}
                  <a target="_blank" href={`mailto:support@natca.org`}>
                    Email Us
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        {
          <RegisterModal
            showModal={this.state.showModal}
            onClose={this.handleClose}
          />
        }

        <div className="m-t-40 text-center">
          <p>© {new Date().getFullYear()} NATCA</p>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(AccountRecovery);
