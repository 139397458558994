import React, { Component } from "react";
import { Row, Col, Form } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import _ from "lodash";

export class FE_Description extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // Initialize the form with your initial values here
    const { savedSearchCriteria } = this.props.savedSearchState;

    if (
      this.props.formId == "fe_mem_descriptionForm" &&
      savedSearchCriteria &&
      savedSearchCriteria.savedSearchCriteriaDetail &&
      !_.isEmpty(savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria)
    ) {
      const { fe_inventory_desc = null } =
        savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
      if (fe_inventory_desc != null) {
        this.props.dispatch(
          change("fe_mem_descriptionForm", "Description", fe_inventory_desc)
        );
      } else {
        this.props.dispatch(
          change("fe_mem_descriptionForm", "Description", null)
        );
      }
    }
  }
  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && !this.props.isMobile == true ? (
          <label>{label} </label>
        ) : (
          <label></label>
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          autoComplete="off"
          placeholder={!this.props.isMobile == true ? "" : placeholder}
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Description</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}

        <Form {...this.props} onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2">
              <Field
                name="Description"
                id="Description"
                classForm="form-control"
                placeholder="Description"
                label="Description"
                component={this.renderInput}
                labelFor="Description"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  destroyOnUnmount: false,
})(FE_Description);

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.formId,
  filterState: state.advancedFilter,
  savedSearchState: state.saveSearch,
});

export default connect(mapStateToProps, {})(formWrapped);
