import React, { Component } from "react";
import ActivityLoggingComponent from "../Shared/ActivityLogging";
import AuditModuleTypes from "../Shared/AuditModuleTypes";

import _ from "lodash";
import { Card, CardBody, Collapse } from "reactstrap";

export class EmailActivityLogging extends Component {
  state = {
    logRecordId: 0,
  };
  constructor(props) {
    super(props);
    //console.log("teams logs constructor");
    this.fetchLogs();
  }

  fetchLogsCardClick = () => {
    /// ActivityLogging
    var myself = this;
    // var { Id, isChild, parentId } = useParams;
    const Id = myself.props.paramId;
    //const { emailLoggingVisible = false } = this.props.auditState;
    //var show = this.props.auditState && emailLoggingVisible;
    ///console.log("show", show);
    //const Id = this.props.paramId && this.props.paramId.Id;
    if (!_.isEmpty(Id) && Id.split(",")[0] !== "0") {
      //this.props.toggleEmailListLogAccordion(!emailLoggingVisible);
      this.setState({ logRecordId: Id });
      //!show &&
      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.MailGunList,
        Id,
        0,
        10,
        "mail_list_logs"
      );
    }
  };

  fetchLogs = () => {
    /// ActivityLogging
    var myself = this;
    // var { Id, isChild, parentId } = useParams;
    const Id = myself.props.paramId;

    //const Id = this.props.paramId && this.props.paramId.Id;
    if (!_.isEmpty(Id) && Id.split(",")[0] !== "0") {
      //this.props.toggleEmailListLogAccordion(true);
      this.setState({
        logRecordId: Id,
      });

      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.MailGunList,
        Id,
        0,
        10,
        "mail_list_logs"
      );
    }
  };

  refreshLogs = (Id) => {
    if (!_.isEmpty(Id)) {
      this.props.toggleEmailListLogAccordion(true);
      this.setState({ logRecordId: Id });
      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.MailGunList,
        Id,
        0,
        10,
        "mail_list_logs"
      );
    }
  };

  render() {
    var show =
      this.props.auditState && this.props.auditState.emailLoggingVisible;
    var IsLoading = this.props.auditState && this.props.auditState.IsLoading;
    return (
      <>
        {/* ActivityLogging Work */}
        <Card>
          {/* Card Header */}
          {/* {!IsLoading && (
            <>
              <h5
                className="card-header cursorPointer"
                onClick={this.fetchLogsCardClick}
              >
                <i class="fa fa-history" aria-hidden="true"></i> Activity
                Logging
                <i
                  className={`pull-right float-right ${
                    show
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }`}
                ></i>
              </h5>
            </>
          )} */}

          {/* <Collapse
            className="memberCardCollapse"
            // style={{ transition: "height 2s ease" }}
            isOpen={show}
          > */}
          <div className="row">
            <div className="col-10">
              {IsLoading && (
                <>
                  <h5 className="card-header cursorPointer">
                    <i class="fa fa-history" aria-hidden="true"></i> Loading....
                  </h5>
                </>
              )}
            </div>
            <div className="col-2">
              <button
                className="btn btn-light pr-3 pt-2 mr-2 float-right"
                onClick={this.fetchLogsCardClick}
              >
                <i
                  className="fa fa-refresh c-pointer"
                  title="Refresh"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
          <CardBody className="pl-1 pr-2 pt-0">
            <ActivityLoggingComponent
              recordId={this.props.paramId}
              targetDiv={"EmailLogginglistScroll"}
            />
          </CardBody>
          {/* </Collapse> */}
        </Card>
        {/* ActivityLogging Work */}
      </>
    );
  }
}

export default EmailActivityLogging;
