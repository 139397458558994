
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function GrievanceArticleVoilatedModal(props) {
    
    const {
        modal,
        toggle
    } = props;

    return (
        <Modal size="xl" centered={true} autoFocus={true} isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader>{props.header}</ModalHeader>
            <ModalBody>
                <div className="col-12 mb-4">
                    <div dangerouslySetInnerHTML={{ __html: props.html }} />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}