import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
export default {
  FacilityTypes: async () => {
    let url = `/api/Facilities/GetFacilityTypes`;
    var { data } = await axios.get(url);
    if (data && data.status == "Success") {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  EmployerList: async () => {
    let url = `/api/Member/GetEmployerList`;
    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },
};
