import React, { useState } from "react";
import Moment from "react-moment";
import { Tooltip } from "reactstrap";
import moment from "moment";
import _ from "lodash";
const getUTCDate = () => {
  var dtnow = new Date();
  var utcDate = new Date(
    dtnow.getUTCFullYear(),
    dtnow.getUTCMonth(),
    dtnow.getUTCDate(),
    dtnow.getUTCHours(),
    dtnow.getUTCMinutes(),
    dtnow.getUTCSeconds()
  );
  return utcDate;
};
const getMomentLogUTCDate = (dateToFormat) => {
  return <Moment>{dateToFormat}</Moment>;
};
const getLogUTCDate = (date) => {
  var dtnow = new Date(date);
  var utcDate = new Date(
    dtnow.getUTCFullYear(),
    dtnow.getUTCMonth(),
    dtnow.getUTCDate(),
    dtnow.getUTCHours(),
    dtnow.getUTCMinutes(),
    dtnow.getUTCSeconds()
  );
  return utcDate;
};
const timeDifference = (current, previous) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;
  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return "approximately " + Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
  }
};
const ActivityLogsList = (props) => {
  // debugger;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { onToggleModal, isApiResponded } = props;

  const logs =
    props &&
    props.logsData &&
    props.logsData.map((item, idx) => {
      return (
        <div key={idx}>
          <div className="list-group mt-1" key={idx + 1}>
            <div key={idx + 2}>
              <span className="list-group-item list-group-item-action list-group-item-light pt-2 pb-1 pr-1">
                <i
                  class={`fas fa-circle pr-2 ${(item.actionType.trim() ==
                    "Deletion" &&
                    "text-danger") ||
                    (item.actionType.trim() == "Addition" && "text-success") ||
                    (item.actionType.trim() == "Modification" &&
                      "text-warning")}`}
                ></i>

                <span
                  className="messageText"
                  dangerouslySetInnerHTML={{ __html: item.actionMessage }}
                ></span>
                {item.actionType == "Modification" && !_.isEmpty(item.changes) && (
                  <>
                    <i
                      style={{ color: "blue" }}
                      href="#"
                      id="TooltipExample"
                      onClick={() => onToggleModal(item.id)}
                      class={`fa fa-info-circle pl-2 text-dark cursorPointer`}
                    ></i>

                    <Tooltip
                      placement="right"
                      isOpen={tooltipOpen}
                      target="TooltipExample"
                      toggle={toggle}
                    >
                      Click to view Details
                    </Tooltip>
                  </>
                )}
                {/* <Moment datem={item.createDate} />
                {`date here::${new Date(item.createDate)}`} */}
                <div className="small pl-4">
                  {moment(new Date(item.createDate + "Z")).format(
                    "MM/DD/YY hh:mm:ss"
                  )}
                </div>
              </span>
            </div>
          </div>
        </div>
      );
    });
  return (
    <div>
      {logs}
      {logs === undefined ||
        (logs.length === 0 && !isApiResponded && (
          <div className="container h-100">
            <div className="row spinnerFacilityCategory">
              <h5>No Logs Found!</h5>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ActivityLogsList;
