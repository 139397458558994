import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import axios from "../../Shared/auth-header";
import { Route } from "react-router-dom";
import { toast } from "react-toastify";
import TypeModal from "../../Shared/TypeModal";
import $ from "jquery";

const ButtonToNavigate = ({ title, history, color, classes }) => (
  <Button
    type="button"
    onClick={() => history.push("/directory/members")}
    color={color}
    className={classes}
  >
    {title}
  </Button>
);

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      DateofBirth: "",
      Password: "",
      Gender: "M",
      membertype: "",
      membertypeid: 0,
      isActive: false,
      dob_err: "",
      valid: true,
      email_err: "",
      Password_err: "",
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
    this.handleChangeDOB = this.handleChangeDOB.bind(this);
    this.handleChangeP = this.handleChangeP.bind(this);
    this.updateType = (args) => {
      this.setState({
        membertype: args.description,
        membertypeid: args.id,
      });
      $(".cancelModal").trigger("click");
    };
  }
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeDOB(e) {
    this.setState({ DateofBirth: e.target.value });
    let dateRegex = new RegExp(
      "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$"
    );
    if (!dateRegex.test(e.target.value)) {
      $(".dob_err").show();
      this.setState({
        valid: false,
        dob_err: "Please enter DOB in correct format mm/dd/yyyy",
      });
    } else {
      $(".dob_err").hide();
      this.setState({ valid: true, dob_err: "" });
    }
  }
  handleChangeEmail(e) {
    this.setState({ Email: e.target.value });
    let EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (EmailReg.test(e.target.value)) {
      $(".email_err").hide();
      this.setState({ valid: true, email_err: "" });
    } else {
      $(".email_err").show();
      this.setState({ valid: false, email_err: "Enter valid email address" });
    }
  }
  handleChangePwd(e) {
    this.setState({ Password: e.target.value });
    let passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (!passRegex.test(e.target.value)) {
      this.setState({
        Password_err:
          "Password must be 8 characters long and must contain 1 uppercase, 1 lower case and 1 number.",
      });
      this.setState({ valid: false });
    } else {
      this.setState({ Password_err: "" });
      this.setState({ valid: true });
    }
  }
  handleChangeP(e) {
    this.setState({
      isActive: e.target.checked,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    if (e.target.checkValidity() === false || !this.state.valid) {
      return false;
    }
    if (this.state.dob_err != "" || this.state.email_err != "") {
      return false;
    }
    $(".loading").show();
    const data = {
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      MiddleName: this.state.MiddleName,
      Password: this.state.Password,
      Email: this.state.Email,
      Gender: this.state.Gender,
      DateofBirth: this.state.DateofBirth,
      membertypeid: this.state.membertypeid,
      isActive: this.state.isActive,
      DateofBirth: this.state.DateofBirth,
    };
    axios
      .post("/api/Member/AddMember", data)
      .then(({ data }) => {
        if (data.status && data.status == "Success") {
          toast.success("New Member Saved.");
          this.props.history.push("/directory/members");
          $(".loading").hide();
        } else {
          $(".loading").hide();
          toast.error(data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  render() {
    return (
      <AUX>
        <div className="container-fluid">
          <form
            className="form-horizontal m-t-40"
            onSubmit={this.handleFormSubmit}
            noValidate
          >
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Add Member</h4>
                </div>
                <div className="col-sm-6 float-right text-right">
                  <Route
                    path="/"
                    render={(props) => (
                      <ButtonToNavigate
                        {...props}
                        color="primary"
                        title="Back to Members"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <MDBInput
                        name="FirstName"
                        value={this.state.FirstName}
                        onChange={this.changeHandler}
                        label="First Name"
                        group
                        required
                        type="text"
                      >
                        <div className="invalid-feedback">
                          First Name is required.
                        </div>
                      </MDBInput>
                    </div>
                    <div className="form-group">
                      <MDBInput
                        name="MiddleName"
                        value={this.state.MiddleName}
                        onChange={this.changeHandler}
                        label="Middle Name"
                        group
                        type="text"
                      />
                    </div>
                    <div className="form-group">
                      <MDBInput
                        name="LastName"
                        value={this.state.LastName}
                        onChange={this.changeHandler}
                        label="Last Name"
                        group
                        required
                        type="text"
                      >
                        <div className="invalid-feedback">
                          Last Name is required.
                        </div>
                      </MDBInput>
                    </div>
                    <div className="form-group">
                      <MDBInput
                        name="DateofBirth"
                        value={this.state.DateofBirth}
                        onChange={this.handleChangeDOB}
                        label="Date of Birth"
                        group
                        required
                        type="text"
                      >
                        <div className="invalid-feedback">DOB is required.</div>
                        <div className="dis-none invalid-feedback dob_err">
                          {this.state.dob_err}
                        </div>
                      </MDBInput>
                    </div>

                    <div className="form-group mt-2 align-self-center">
                      <span>Gender : </span>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          //value="Male"
                          onChange={this.changeHandler}
                          type="radio"
                          id="Sex1"
                          name="Gender"
                          value="M"
                          checked={this.state.Gender == "M"}
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="Sex1">
                          Male
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          //value="Female"
                          onChange={this.changeHandler}
                          type="radio"
                          id="Sex2"
                          value="F"
                          name="Gender"
                          checked={this.state.Gender == "F"}
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="Sex2">
                          Female
                        </label>
                      </div>
                      {/* Backlog 1135 */}
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          ///value="Female"
                          onChange={this.changeHandler}
                          type="radio"
                          id="Sex3"
                          value="X"
                          name="Gender"
                          checked={this.state.Gender == "X"}
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="Sex3">
                          Nonbinary and/or Intersex
                        </label>
                      </div>
                      <div className="custom-control custom-radio custom-control-inline">
                        <input
                          //value="Female"
                          onChange={this.changeHandler}
                          type="radio"
                          id="Sex4"
                          value="N"
                          name="Gender"
                          checked={this.state.Gender == "N"}
                          className="custom-control-input"
                        />
                        <label className="custom-control-label" htmlFor="Sex4">
                          Prefer not to answer
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <MDBInput
                        name="Email"
                        value={this.state.Email}
                        onChange={this.handleChangeEmail}
                        label="Email"
                        group
                        required
                        type="text"
                      >
                        <div className="invalid-feedback">
                          Email is required.
                        </div>
                        <div className="dis-none invalid-feedback email_err">
                          {this.state.email_err}
                        </div>
                      </MDBInput>
                    </div>
                    <div className="form-group">
                      <MDBInput
                        name="Password"
                        value={this.state.Password}
                        onChange={this.handleChangePwd}
                        label="Password"
                        group
                        type="password"
                      >
                        <span className="invalid-feedback ">
                          {this.state.Password_err}
                        </span>
                      </MDBInput>
                    </div>
                    <div className="form-group">
                      <span className="d-block mb-2">Enabled</span>
                      <input
                        type="checkbox"
                        id="isEnabled"
                        name="isenabled"
                        switch="bool"
                        onChange={this.handleChangeP}
                      />
                      <label
                        htmlFor="isEnabled"
                        data-on-label="Yes"
                        data-off-label="No"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="region">Member Type</label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          name="memberType"
                          className="form-control disabled"
                          value={this.state.membertype}
                          required
                          onKeyPress={(e) => {
                            e.preventDefault();
                            e.target.value = "";
                          }}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            data-toggle="modal"
                            data-target=".type-modal"
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                        <div className="invalid-feedback">
                          Member Type is required.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col float-right text-right">
                <Route
                  path="/"
                  render={(props) => (
                    <ButtonToNavigate
                      {...props}
                      color="danger"
                      title="Cancel"
                      classes="mr-2"
                    />
                  )}
                />
                <button
                  disabled={!this.state.valid}
                  className="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
        <TypeModal
          typeMethods={this.updateType}
          type="MemberType"
          label="Member Type"
        />
      </AUX>
    );
  }
}

export default AddUser;
