export default [
  "Service Area",

  "District",

  "Level",

  "Facility ID",

  "Facility Name",

  "CPC Target",

  "Current # of CPC On-Board (SWB)",

  "CPC on Temp (SWB)",

  "CPC on Long Term Hold (SWB)",

  "Current # of CPC On-Board minus Temps minus LTH (Calculation)",

  "Current % CPC to Target (Calculation)",

  "Training Time Years (NTD)",

  "ATCS in Training (SWB)",

  "ATCS in Training on Long Term Hold (SWB)",

  "ATCS in Training minus LTH (Calculation)",

  "Training Success Rate (NTD)",

  "Current ATCS in Training Expected to Cert (Calculation)",

  "Committed ATCS Inbound (SWB)",

  "Placement List Inbounds",

  "Temps Inbound (SWB)",

  "Inbounds Expected to Cert + Temps Inbound (Calculation)",

  "Committed ATCS Outbound (SWB)",

  "Placement List Outbounds (ERR, Hardship)",

  "Temps Outbound (SWB)",

  "Projected Retirements and Other Losses (Finance)",

  "Projected % to Target (Calculation)",

  "Possible Gains to National Average (Calculation)",

  "Possible Gains to Target (Calculation)",

  "ERR Category",

  "Current % CPC to Trainees",

  "Possible Losses",

  "HRRegion",
];
