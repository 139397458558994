import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/feTracker/action";
import $ from "jquery";
import InventoryStatus from "../InventoryStatus";
import FE_Tabs_Type from "../../../modules/F&E/FE_Tabs";
import FE_API_Actions from "../FE_API_Actions";
import FE_AF_Nav_Items from "./FE_AF_Nav_Items";
export class Tags extends Component {
  ///called from tag cross icon
  removeFilter = (removeId, optionText) => {
    var removeIndex = _.findIndex(this.props.getSearchAppliedList(), (o) => {
      return o.searchType === +removeId;
    });
    if (removeIndex !== -1) {
      let currentSearchAplied = this.props.getSearchAppliedList()[removeIndex];

      if (
        currentSearchAplied.hasOwnProperty("selectedItems") &&
        currentSearchAplied.selectedItems.length === 1
      ) {
        this.props.getSearchAppliedList()[
          removeIndex
        ].selectedItems = this.props
          .getSearchAppliedList()
          [removeIndex].selectedItems.filter((item) => item !== optionText);

        _.remove(this.props.getSearchAppliedList(), {
          searchType: +removeId,
        });
      } else if (
        currentSearchAplied.hasOwnProperty("selectedItems") &&
        currentSearchAplied.selectedItems.length > 1
      ) {
        this.props.getSearchAppliedList()[
          removeIndex
        ].selectedItems = this.props
          .getSearchAppliedList()
          [removeIndex].selectedItems.filter((item) => item !== optionText);
      }
    }
    this.props.resetAdvanceFilterTabStateById(removeId, optionText);
    this.getRefrehDataAndCount();
    this.props.handleTagsVisibility();
  };

  getRefrehDataAndCount = () => {
    this.props.setFilterData({
      ...this.props.festate.filterData,
    });
    this.getAFRows();
  };
  getAFRows() {
    /// API
    if (this.props.RefreshResult) {
      var activeSections = [];
      activeSections.push(InventoryStatus.NonAccReview);
      activeSections.push(InventoryStatus.InAccReview);
      const formData = {
        ...this.props.festate.filterData,
        recordsTypeList: activeSections,
        PageNo: 1,
        PageSize: 25,
        SortOrder: "id",
        refreshCR: true,
        EventType: FE_API_Actions.AdvanceSearch,
      };
      this.props.getResultRowsForAdvanceFilter(formData);
    } else {
      this.props.getMemberTotalCount(this.props.festate.filterData);
    }
  }
  async tagSelection(searchType) {
    if (
      +this.props.festate.activeTab !== +searchType &&
      searchType !== FE_AF_Nav_Items.Status
    ) {
      await this.props.setCurrentAFActiveTab(searchType);
      var $s = $(".fe-sidebar-adv");
      if ($(".fe-sidebar-adv .active").length) {
        var optionTop = $(".fe-sidebar-adv .active").offset().top;
        var selectTop = $s.offset().top;
        $(".fe-sidebar-adv").animate(
          {
            scrollTop: $s.scrollTop() + (optionTop - selectTop),
          },
          800
        );
      } else {
        console.log("fe-sidebar-adv .active is undefined");
      }
    }
  }
  applySelection(item) {
    const selectedTag = item.selectedItems.map((optionText, idx) => {
      return (
        <a
          key={idx + 1}
          class="filter-selected"
          onClick={() => {
            this.props.RefreshResult !== true &&
              this.tagSelection(item.searchType);
          }}
        >
          {optionText}

          <span key={"filtered-" + item.searchType}>
            <a onClick={() => this.removeFilter(item.searchType, optionText)}>
              <i class="fas fa-times" />
            </a>
          </span>
        </a>
      );
    });

    return (
      <>
        {selectedTag.map((item, idx) => {
          return <div key={idx}>{item}</div>;
        })}
      </>
    );
  }

  render() {
    let selectedTags =
      !_.isEmpty(this.props.festate.filterData) &&
      !_.isEmpty(this.props.festate.filterData.searchApplied)
        ? this.props.festate.activeSubHeader == FE_Tabs_Type.Inventory
          ? this.props.RefreshResult
            ? this.props.festate.filterData.searchApplied["inventories"].filter(
                (f) =>
                  f.searchType != FE_AF_Nav_Items.Year &&
                  f.searchType != FE_AF_Nav_Items.Budget &&
                  f.searchType != FE_AF_Nav_Items.Status
              )
            : this.props.festate.filterData.searchApplied["inventories"]
          : this.props.festate.activeSubHeader > FE_Tabs_Type.Inventory
          ? this.props.festate.filterData.searchApplied["requests"]
          : []
        : [];
    return (
      <div className="row">
        {selectedTags.map((item) => {
          if (item.isApplied) {
            return this.applySelection(item);
          }
        })}
      </div>
    );
  }
}
export default connect(
  (state) => ({
    festate: state.fetracker,
    formValues: state.form,
  }),
  {
    ...actionFilter,
  }
)(Tags);
