import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../PermissionFeature";
import * as Permissions from "../../Permissions/PermissionValues";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";

import "../../../assets/css/PermissionHeader.css";

class PermissionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: /*props.location.tab || */ null,
      tabName: /*props.location.name ||*/ "",
      reload: Math.random(),
    };
  }
  componentDidMount() {
    let addresses = window.location.pathname.split("/");
    let page = addresses.length > 1 ? addresses[1] : "";
    let subPage = addresses.length > 2 ? addresses[2] : "";
    let secondSubPage = addresses.length > 3 ? addresses[3] : "";
    this.setState({
      Page: page,
      SubPage: subPage,
      SecondSubPage: secondSubPage,
    });
    if (secondSubPage == "teamDetail" || secondSubPage == "archived") {
      this.props.setPermissionActiveTab(0);
      this.setState({
        toLocation: "/permissions/teams",
      });
    } else if (
      page == "permissions" &&
      subPage == "teams" &&
      secondSubPage != "teamDetail" &&
      this.props.permissionState.permissionActiveTab == 0
    ) {
      if (AuthService.canNational()) {
        this.props.setPermissionActiveTab(1);
      } else if (AuthService.canRegion()) {
        this.props.setPermissionActiveTab(2);
      } else if (AuthService.canFacility()) {
        this.props.setPermissionActiveTab(3);
      } else {
        this.props.setPermissionActiveTab(0);
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.permissionState.permissionActiveTab != 0 &&
      this.state.toLocation
    ) {
      this.props.history.push({
        pathname: this.state.toLocation,
      });
    }
  }
  showMGCreateMailingList = () => {
    this.props.toggleModal();
  };

  canCreatTeamsEmailList = () => {
    var result = AuthService.canAdd(PermissionFeature.EmailListAdmin);
    return result;
  };
  canUpdateViewTeamsEmailList = () => {
    var result =
      AuthService.canUpdate(PermissionFeature.EmailListAdmin) ||
      AuthService.canView(PermissionFeature.EmailListAdmin);
    return result;
  };
  canCreateorUpdateEmailList = () => {
    if (this.props.exists === false) {
      /// Add Mode
      return this.canCreatTeamsEmailList();
    } else {
      //// Edit Mode
      return this.canUpdateViewTeamsEmailList();
    }
  };
  render() {
    return (
      <>
        <nav className="navbar navbar-expand-sm permission-nav">
          {this.state.SubPage == "teams" && (
            <Fragment>
              {AuthService.canNational() && (
                <a
                  className={
                    "p-tabs c-pointer" +
                    (this.props.permissionState.permissionActiveTab === 1
                      ? " active"
                      : "")
                  }
                  data-tab="National"
                  onClick={() => {
                    this.props.setPermissionActiveTab(1);
                  }}
                >
                  <i className="fas fa-globe" /> National
                </a>
              )}
              {AuthService.canRegion() && (
                <a
                  className={
                    "p-tabs c-pointer" +
                    (this.props.permissionState.permissionActiveTab === 2
                      ? " active"
                      : "")
                  }
                  data-tab="Region"
                  onClick={() => {
                    this.props.setPermissionActiveTab(2);
                  }}
                >
                  <i className="fas fa-server" /> Region
                </a>
              )}
              {AuthService.canFacility() && (
                <a
                  className={
                    "p-tabs c-pointer" +
                    (this.props.permissionState.permissionActiveTab === 3
                      ? " active"
                      : "")
                  }
                  data-tab="Facility"
                  onClick={() => this.props.setPermissionActiveTab(3)}
                >
                  <i className="fas fa-university" /> Facility
                </a>
              )}
            </Fragment>
          )}
          <UncontrolledDropdown
            setActiveFromChild
            className="dropdown-menu-toggle directory-navbar-brand"
          >
            {this.state.SecondSubPage != "archived" && (
              <DropdownToggle tag="a" className="nav-link nav-link-font-size">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
            )}
            <DropdownMenu className="dropdown-menu-body">
              {this.state.SubPage == "positions" && (
                <DropdownItem
                  className="dropdown-item-custom"
                  data-toggle="modal"
                  data-target="#EditPositionModal"
                  data-backdrop="static"
                >
                  {" "}
                  <i className="fas fa-plus-circle"></i> Add Position{" "}
                </DropdownItem>
              )}
              {this.state.SubPage == "teams" &&
                this.state.SecondSubPage != "teamDetail" &&
                this.state.SecondSubPage != "archived" && (
                  <div className="team-menu">
                    {AuthService.canAdd(PermissionFeature.TeamsTeams) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => this.props.beginEditTeam()}
                      >
                        {" "}
                        <i className="fas fa-plus-circle"></i> Create New Team{" "}
                      </DropdownItem>
                    )}
                    {AuthService.canView(
                      PermissionFeature.DefaultPermission
                    ) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() =>
                          this.props.handleDefaultPermissionModal()
                        }
                      >
                        {" "}
                        <i className="fas fa-user-tag"></i> Default Permission{" "}
                      </DropdownItem>
                    )}
                    {AuthService.canView(
                      PermissionFeature.TeamsViewDeleted
                    ) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/permissions/teams/archived",
                          });
                        }}
                      >
                        {" "}
                        <i className="fas fa-trash"></i> View Archived Team{" "}
                      </DropdownItem>
                    )}
                  </div>
                )}
              {this.state.SubPage == "teams" &&
                this.state.SecondSubPage == "teamDetail" && (
                  <div className="team-menu">
                    {AuthService.canAdd(PermissionFeature.TeamsTeams) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => this.props.BeginChildTeamCreation()}
                      >
                        {" "}
                        <i className="fas fa-paperclip"></i> Create Child Team{" "}
                      </DropdownItem>
                    )}
                    {AuthService.canAdd(PermissionFeature.TeamsPositions) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => this.props.EditPositionModal()}
                      >
                        {" "}
                        <i className="fa fa-plus-circle"></i> Add Position
                      </DropdownItem>
                    )}
                    {AuthService.canAdd(PermissionFeature.TeamsTeams) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => this.props.AssignTeamData()}
                      >
                        {" "}
                        <i className="fas fa-paperclip"></i> Link Existing Team{" "}
                      </DropdownItem>
                    )}
                    {AuthService.canView(
                      PermissionFeature.TeamsPermissions
                    ) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => this.props.BeginTeamPermission()}
                      >
                        {" "}
                        <i className="fas fa-lock"></i> Team Permissions
                      </DropdownItem>
                    )}
                    {AuthService.canDelete(PermissionFeature.TeamsTeams) && (
                      <DropdownItem
                        className="dropdown-item-custom"
                        data-toggle="modal"
                        data-target="#DeleteTeamModal"
                        data-backdrop="static"
                      >
                        {" "}
                        <i className="fas fa-trash"></i> Delete Team
                      </DropdownItem>
                    )}

                    {this.props.exists === false &&
                      this.canCreateorUpdateEmailList() && (
                        <DropdownItem
                          className="dropdown-item-custom"
                          // data-toggle="modal"
                          // data-target="#FacilityTeamEmailListNameModal"
                          // data-backdrop="static"
                          onClick={() => this.showMGCreateMailingList()}
                        >
                          {" "}
                          <i className="fas fa-plus"></i> Create Email List{" "}
                        </DropdownItem>
                      )}
                    {this.props.exists === true &&
                      this.canCreateorUpdateEmailList() && (
                        <DropdownItem
                          className="dropdown-item-custom"
                          onClick={this.props.configureTeamsEmail}
                        >
                          {" "}
                          <i className="fas fa-cog"></i> Configure Email{" "}
                        </DropdownItem>
                      )}
                    {AuthService.canDelete(PermissionFeature.EmailListsLists) &&
                      this.props.exists === true && (
                        <DropdownItem
                          className="dropdown-item-custom"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you want to delete email list?`
                              )
                            ) {
                              this.props.onDeleteEmailList();
                            }
                          }}
                        >
                          {" "}
                          <i className="fas fa-trash"></i> Delete Email List{" "}
                        </DropdownItem>
                      )}
                  </div>
                )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </nav>
      </>
    );
  }
}
export default withRouter(
  connect((state) => ({ permissionState: state.permission }), {
    ...PermissionAction,
  })(PermissionHeader)
);
