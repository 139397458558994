import React, { Component } from "react";
import FinanceTable from "../../Finance/General/FinanceTable";
import FinanceForm from "../../Finance/General/FinanceFormModal";
import { connect } from "react-redux";
import FinanceAction from "../../../store/finance/action";

class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="col-12 py-4">
          <FinanceTable Mode="Facility" FacilityId={this.props.facilityId} />
        </div>
        <FinanceForm />
      </>
    );
  }
}

export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(Finance);
