import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";

const ButtonToNavigate = ({ title, history, color, classes }) => (
    <Button type="button" onClick={() => history.push({ pathname: "/admin/positions" })} color={color} className={classes}>
        {title}
    </Button>
);

class LinkedPositionContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,

            check: true,
            uid: this.props.uid || 0,
            mockup_position_scope: 1,
            name: "",
            currentSection: 1,
            showLinkPositions: false,
            selectIsOwner: false,
            selectTeamId: 0,
            currentScope: 0,
            searchTerm: "",
            selectTeamName:''

        };


        this.teamDetail = this.teamDetail.bind(this);



        this.loadTeam = this.loadTeam.bind(this);
        this.resetStates = this.resetStates.bind(this);

    }
    resetStates() {
        this.setState({
            showLinkPositions: false,
            selectIsOwner: false,
            selectTeamId: 0
        });
    }
    loadTeam = () => {
        let handleManagedLinkedTeam = this.props.handleManagedLinkedTeam;
        let linkedPosition = this.props.linkedPosition;
        let TeamData = [];
        switch (this.state.currentScope) {
            case 1:
                TeamData = (!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.national)) ? this.props.teamData.national : [];
                break;
            case 2:
                
                TeamData = (!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.region)) ? this.props.teamData.region : [];
                break;
            case 3:
                TeamData = (!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.facility)) ? this.props.teamData.facility: [];
                break;
            default:
        }
        return (
            <Fragment>
                <div id="TeamList">

                    <ul className="w-100">

                        {
                            TeamData.filter(
                                (itemQ) =>
                                    _.toLower(itemQ.teamName).includes(
                                        _.toLower(this.state.searchTerm || "")
                                    )
                            ).map((item, index) => {
                                let linkedIndex = linkedPosition.findIndex(x => x.teamId == item.id);
                                let setActive = (linkedIndex != -1) ? " active" : "";
                                let isowner = (linkedIndex != -1) ? linkedPosition[linkedIndex].isOwner : false;
                                return (
                                    <li data-id={item.id} data-isowner={isowner} data-name={item.teamName} className={"c-pointer px-4 border py-2 card-text w-100" + setActive}
                                        onClick={(e) => {
                                            if (this.state.selectTeamId == e.target.dataset.id) {
                                                this.setState({ selectTeamId: 0, selectIsOwner:false, selectTeamName:'', showLinkPositions: false });
                                            }
                                            else {
                                                this.setState({ selectTeamName: e.target.dataset.name, selectIsOwner: (e.target.dataset.isowner == "true"), selectTeamId: e.target.dataset.id, showLinkPositions: true });
                                            }

                                            handleManagedLinkedTeam(e, "select");
                                        }}>

                                        {item.teamName}


                                    </li>);
                            }
                            )
                        }

                    </ul>
                </div>
            </Fragment>
        );

    }
    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.linkedPosition) !== JSON.stringify(nextProps.linkedPosition) || this.props.positionName != nextProps.positionName) {
            this.resetStates();
        }
        if (nextProps.teamData) {
            if (JSON.stringify(this.props.teamData) !== JSON.stringify(nextProps.teamData)) {


                if (!_.isEmpty(nextProps.teamData) && !_.isEmpty(nextProps.teamData.national)) {
                    this.state.currentScope = 1;
                }
                else if (!_.isEmpty(nextProps.teamData) && !_.isEmpty(nextProps.teamData.region)) {
                    this.state.currentScope = 2;
                }
                else if (!_.isEmpty(nextProps.teamData) && !_.isEmpty(nextProps.teamData.facility)) {
                    this.state.currentScope = 3;
                }
                this.setState({ currentScope: this.state.currentScope });
            }
        }
    }
    componentDidMount() {
        if (this.props.teamData) {
            if (!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.national)) {
                this.state.currentScope = 1;
            }
            else if (!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.region)) {
                this.state.currentScope = 2;
            }
            else if (!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.facility)) {
                this.state.currentScope = 3;
            }
            this.setState({ currentScope: this.state.currentScope });
        }

    }
    teamDetail = () => {
        let { teamData } = this.props;
        return (
            <Fragment>

                <div className="row align-items-center">
                    <div className="col-lg-7 text-center">

                        <form
                            role="search"
                            className="app-search mb-3"
                        >
                            <div className="form-group mb-0">
                                <input
                                    type="text"
                                    id="keyword"
                                    className="form-control rounded border mb-1 py-2 w-100"
                                    placeholder="Team Search.."
                                    onChange={(e) => {

                                        this.setState({ searchTerm: e.target.value });
                                    }}
                                />
                                <button onClick={(e) => {
                                    this.setState({ searchTerm: $("#keyword").val() });
                                }} className="c-pointer text-secondary bg-transparent border-0">
                                    <i className="fa fa-search" />

                                </button>

                            </div>
                        </form>

                    </div>
                </div>
                <div className="row">

                    <div id="teamList" className="col-lg-7">
                        {this.loadTeam()}
                    </div>
                    <div id="linkedPosition" className="mt-3 mt-lg-0 col-lg-5">
                        {this.linkedPositionWidget()}
                    </div>

                </div>
            </Fragment>
        );
    }
    linkedPositionWidget() {
        let show = (this.props.linkedPosition.findIndex(x => x.teamId == this.state.selectTeamId) != -1) ? true : false;
        return (
            <Fragment>
                {(this.state.showLinkPositions == true && show) && (
                    <div className="card directory-card border-rounded shadow">

                        <h5 className="card-header mt-0 px-2">
                            Linked Position
                            </h5>
                        <div class="card-body px-2 py-0">
                            <div class="row mx-0">
                                <div class="col-10 py-1 row mx-0 px-0">
                                    <p className="mb-0">{(this.props.positionName != null && this.props.positionName != "") ? this.props.positionName : "Name Not Assigned"}</p>
                                    <div className="w-100 position-relative pl-4">
                                        {(this.props.positionMemberCount<=1) ? (<>
                                        <input type="checkbox" style={{ bottom: '5px', left: '27px' }} data-id={this.state.selectTeamId} data-name={this.state.selectTeamName} checked={this.state.selectIsOwner}
                                            onChange={async (e) => {
                                                e.target.dataset.isowner = !this.state.selectIsOwner;
                                                let event = _.cloneDeep(e);
                                                await this.setState({ selectIsOwner: !this.state.selectIsOwner });
                                                await this.props.handleManagedLinkedTeam(event, "owner");
                                            }} data-isowner={this.state.selectIsOwner} className="form-check-input" id="lp-Owner" />
                                            <label className="form-check-label font-italic text-secondary" for="lp-Owner">As Owner</label></>
                                        ) : (<>
                                                <input type="checkbox" style={{ left: '27px' }}
                                                    className="form-check-input" id="lp-Owner" disabled={true} />
                                                <label className="form-check-label font-italic text-secondary" for="lp-Owner">Owner is disabled because position have more than one member.</label>
                                               </> 
                                                )}
                                    </div>
                                </div>
                                <div class="col-2 px-0 pb-sm-1 align-self-center">
                                    <div>
                                        <button onClick={(e) => {

                                            this.props.handleManagedLinkedTeam(e, "delete");
                                            let linkedIndex = this.props.linkedPosition.findIndex(x => x.teamId == this.state.selectTeamId);
                                            if (linkedIndex == -1) {
                                                this.setState({ showLinkPositions: false, selectTeamId: 0, selectTeamName: '' });
                                            }

                                        }} type="button" data-id={this.state.selectTeamId} data-isowner={this.state.selectIsOwner} data-name={this.state.selectTeamName} class="btn font-weight-bold text-danger btn-sm"><i data-id={this.state.selectTeamId} data-name={this.state.selectTeamName} class="fas fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </Fragment>
        );
    }


    render() {
        return (
            <AUX>

                <div>
                    <div className="row">
                        <div className="col-12">select an Existing team to add this position to. Modification to the position will be reflected on all linked team.</div>
                        <div className="col-12 mb-2">
                            <h5 className="text-primary font-italic font-weight-bold">Currently Linking...</h5>
                            <h3 className="font-style-italic">{this.props.positionName}</h3>

                        </div>

                    </div>
                    {(this.props.teamData) && (
                        <div className="row">
                            <div className="col-12">

                                {(!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.national)) && (
                                    <button
                                        className={"btn btn-md text-white mr-2 rounded-pill" + ((this.state.currentScope == 1) ? " btn-success" : " btn-info")}
                                        onClick={(e) => { this.setState({ currentScope: 1 }) }}
                                    >

                                        <i
                                            className="mr-2 fas fa-globe"
                                        />  National
                            </button>
                                )}
                                {(!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.region)) && (
                                    <button
                                        className={"btn btn-md text-white mr-2 rounded-pill" + ((this.state.currentScope == 2) ? " btn-success" : " btn-info")}
                                        onClick={(e) => { this.setState({ currentScope: 2 }) }}
                                    >
                                        <i
                                            className="mr-2 fas fa-server"
                                        />
                                        Region
                            </button>)}
                                {(!_.isEmpty(this.props.teamData) && !_.isEmpty(this.props.teamData.facility)) && (
                                    <button
                                        className={"btn btn-md text-white mr-2 rounded-pill" + ((this.state.currentScope == 3) ? " btn-success" : " btn-info")}
                                        onClick={(e) => { this.setState({ currentScope: 3 }) }}
                                    ><i
                                            className="mr-2 fas fa-university"
                                        />

                                        Facility
                            </button>
                                )}
                            </div>
                            <div className="col-lg-8"></div>
                        </div>
                    )}
                    {this.teamDetail()}


                </div>


            </AUX>
        );
    }
}

export default LinkedPositionContent;
