import React, { Component } from "react";
import InputRange from "react-input-range";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
import _ from "lodash";
export class DRF extends Component {
  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <div class="mb-3">
            <label>Min </label>
            <label style={{ float: "right" }}>Max</label>
          </div>
        ) : (
          <label> </label>
        )}
        <div class="range-slider">
          <InputRange
            formatLabel={(value) => `$${value}`}
            maxValue={this.props.filterData.drfMax}
            minValue={0}
            value={input.value}
            onChange={input.onChange}
          />
        </div>
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  errorMessage() {
    if (this.props.errorMessage) {
      return (
        <div className="text-danger">
          {/* {this.props.errorMessage} */}
          {/* {this.props.errorMessage.map(home => <div>{home.name}</div>)} */}
          {/* {console.log(this.props.errorMessage)}; */}
        </div>
      );
    }
  }
  onSubmit = (formValues) => {
    console.log(formValues);
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>DRF</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2 ad-range-box">
              <Field
                name="DRF"
                id="DRF"
                classForm="form-control"
                component={this.renderInput}
                labelFor="DRF"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "drfForm",
  destroyOnUnmount: false,
  enableReinitialize: true,
})(DRF);

const mapStateToProps = (state, props) => ({
  errorMessage: {},
  initialValues: {
    DRF: {
      min: 0,
      max: props.filterData.drfMax,
    },
  },
});

export default connect(mapStateToProps, {})(formWrapped);
