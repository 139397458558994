import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import "../../../assets/css/PacStyle.css";

class CandidatePreviousRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myRequestData: this.props.CandidateRequestData,
      ModalHeading: this.props.ModalHeading,
      myRequestViewer: [],
      positionsOptions: [],

      searchTerm: "",
      sizePerPage: 10,
    };

    this.rowClasses = (row, rowIndex) => {
      let classes = null;

      if (row.processstatus == "Draft") {
        classes = "bg-light-blue";
      }

      return classes;
    };
    this.setReloader = this.setReloader.bind(this);
    this.formate_amount = this.formate_amount.bind(this);
    this.filterMyRequest = this.filterMyRequest.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.getColumn = this.getColumn.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(id) {
    window.$("#" + this.state.ModalHeading).modal("hide");
    this.props.history.push("directory/userAction/" + id);
  }
  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.CandidateRequestData) !=
      JSON.stringify(this.state.myRequestData)
    ) {
      this.setState({ myRequestData: nextProps.CandidateRequestData });
    }
  }
  formate_amount = (n) => {
    var num_parts = n.toString().split(".");
    num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };
  getColumn() {
    return [
      {
        text: "Natca Member",
        dataField: "createuser",
        sort: true,
        headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row) => {
          return (
            <div
            // onClick={() => {
            //   this.closeModal(row.member.id);
            // }}
            >
              {/* <Link
                
                to={"/directory/userAction/" + row.member.id}
              > */}
              {row.member.firstname + " " + row.member.lastname}
              {/* </Link> */}
            </div>
          );
        },
      },

      {
        text: "Event Date",
        dataField: "eventdate",
        headerClasses: "bg-dark text-white px-3 py-2",
        classes: "px-3 py-2",
        sort: true,
        formatter: (cellContent, row) => {
          return <div>{row.eventdate && row.eventdate.split(" ")[0]}</div>;
        },
      },
      {
        text: "Amount",
        dataField: "requestedamount",
        sort: true,
        headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row) => {
          return (
            <div>
              {!row.approvedamount && this.formate_amount(row.requestedamount)}
              {row.approvedamount && this.formate_amount(row.approvedamount)}
            </div>
          );
        },
      },
    ];
  }

  filterMyRequest() {
    if (!_.isEmpty(this.state.myRequestData)) {
      return this.state.myRequestData.filter(
        (x) =>
          _.toLower(x.member.firstname).includes(
            _.toLower(this.state.searchTerm || "")
          ) ||
          _.toLower(x.member.lastname).includes(
            _.toLower(this.state.searchTerm || "")
          ) ||
          _.toLower(x.requestedamount).includes(
            _.toLower(this.state.searchTerm || "")
          )
      );
    } else {
      return [];
    }
  }

  componentDidMount() {}
  setReloader() {
    this.setState({ reloadEverything: true });
  }
  componentDidUpdate() {}
  componentWillUnmount() {}
  SizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    rowClasses
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
    };
    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowClasses={rowClasses}
        />
      </div>
    );
  }
  render() {
    const columns = this.getColumn();
    const { myRequestData } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <div className="row pt-1 align-items-center">
          <div className="col-lg-6 pb-2">
            <form role="search" className="app-search mt-0">
              <div className="form-group mb-0">
                <input
                  type="text"
                  id="keyword"
                  className="form-control rounded border mb-1 w-100"
                  placeholder="Search NATCA Name and Amount.."
                  onChange={(event) =>
                    this.setState({ searchTerm: event.target.value })
                  }
                />
                <button
                  type="button"
                  className="c-pointer text-secondary bg-transparent border-0"
                  onClick={(event) =>
                    this.setState({ searchTerm: $("#keyword").val() })
                  }
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {!_.isEmpty(this.filterMyRequest()) &&
              this.RemotePagination(
                columns,
                "id",
                this.filterMyRequest(),
                this.SizePerPageChange,
                this.state.sizePerPage,
                this.rowClasses
              )}
          </div>
        </div>
      </AUX>
    );
  }
}

export default CandidatePreviousRequest;
