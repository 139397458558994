import React, { Component, useEffect } from "react";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import RegistrationsService from "../../RegistrationsService";
import registrationsAction from "../../../../store/registrations/action";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import StepOne from "../RegDetails/Wizard/stepOne";
import StepThree from "../RegDetails/Wizard/stepThree";
import StepTwo from "../RegDetails/Wizard/stepTwo";
import "../RegDetails/Wizard/steps-prog.css";
import CloseRegistrationWizardModal from "./CloseRegistrationWizardModal";
import { Formik, Form } from "formik";
import { initForm, makeuid } from "../../Helpers/QuestionsFormUtils";
import SucessfulPayment from "../../../Payments/SucessfulPayment";
import Portal from "@material-ui/core/Portal";
import { toast } from "react-toastify";
import {
  RegistrationTableTypes,
  RegistrationStatuses,
  RegisWizardCloseActions,
  PaymentStatuses,
  SessionStaus,
  RegisWizardSteps,
} from "../../Helpers/LookUpValues";
import _ from "lodash";
import PaymentService from "../../../Payments/PaymentService";

const initialState = {
  showConfirmCloseRegModal: false,
  currentStep: 0, // Default is Step 0
  isExecutePaymentFlow: false,
  isFinishedPaymentFlow: false,
  isFailedPaymentFlow: false,
  anyRequiredFeeQuestions: false,
  anyFeeQuestions: false,
  steps: [
    {
      key: RegisWizardSteps.FirstStep,
      label: "Details",
      isDone: false,
    },
    {
      key: RegisWizardSteps.SecondStep,
      label: "Questions",
      isDone: false,
    },
    {
      key: RegisWizardSteps.ThirdStep,
      label: "Payment",
      isDone: false,
    },
    {
      key: RegisWizardSteps.FourthStep,
      label: "Success",
      isDone: false,
    },
  ],
  paymentSteps: [
    {
      key: RegisWizardSteps.ThirdStep,
      label: "Payment",
      isDone: false,
    },
    {
      key: RegisWizardSteps.FourthStep,
      label: "Success",
      isDone: false,
    },
  ],
  successStep: {
    key: RegisWizardSteps.FourthStep,
    label: "Success",
    isDone: false,
  },

  activeStep: {
    key: RegisWizardSteps.FirstStep,
    label: "Registration Details",
    isDone: false,
  },
  initialValues: [],
  validationSchema: {},
  pauseRegistration: false,
  wizardBtnCloseAction: 0,
  prevValues: [],
  rawQuestionData: [],
  questionsFee: 0,
};
class RegisterMemberModal extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.portalRef = React.createRef();
    this.state = {
      ...initialState,
    };

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.confirmCancelRegModal = () => {
      this.confirmed = async (callSubmitForm, closeBtnAct, setFormErrors) => {
        this.setState(
          { pauseRegistration: true, wizardBtnCloseAction: closeBtnAct },
          () => {
            //setFormErrors({});
            callSubmitForm();
          }
        );
      };

      return this;
    };
  }
  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    ///console.log("submitForm", this.submitForm);
    //this.submitForm.click();
    let { currentStep, steps, activeStep } = this.state;
    const index = steps.findIndex((x) => x.key === activeStep.key);
    let maxLength = steps.length - 1;

    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= maxLength ? maxLength : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
    let item = { ...steps[index] };

    item.isDone = true;
    steps[index] = item;

    this.setState({ activeStep: steps[index + 1] });

    this.setState({ steps });
  }

  _prev() {
    let { currentStep, steps, activeStep } = this.state;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    let firstStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.FirstStep
    );
    if (currentStep <= firstStepIdx) {
      return;
    }
    currentStep = currentStep <= firstStepIdx ? firstStepIdx : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
    const index = steps.findIndex((x) => x.key === activeStep.key);

    let item = { ...steps[currentStep] };

    item.isDone = false;
    steps[currentStep] = item;

    this.setState({ activeStep: steps[index - 1] });

    this.setState({ steps });
  }
  showPaymentSuccess = () => {
    let { steps } = this.state;

    let modifiedSteps = steps
      .map((m) => {
        if (m.key != RegisWizardSteps.FourthStep) return { ...m, isDone: true };
        else {
          return { ...m, isDone: false };
        }
      })
      .filter((s) => s.key !== RegisWizardSteps.ThirdStep); // exclude Payment Step
    let fourthStepIdx = modifiedSteps.findIndex(
      (f) => f.key == RegisWizardSteps.FourthStep
    );
    if (fourthStepIdx != -1) {
      this.setState({ steps: modifiedSteps }, () => {
        this.setState({ activeStep: modifiedSteps[fourthStepIdx] });
        this.setState({ currentStep: fourthStepIdx });
      });
    }
  };
  // The "next" and "previous" button functions
  get previousButton() {
    let { currentStep, steps } = this.state;
    let firstStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.FirstStep
    );
    // If the current step is not 0, then render the "previous" button
    if (currentStep !== firstStepIdx) {
      return (
        <button
          className="btn btn-info mr-1"
          type="button"
          onClick={this._prev}
        >
          <i class="fa fa-chevron-circle-left" aria-hidden="true">
            Previous
          </i>
        </button>
      );
    }
    return null;
  }

  get nextButton() {
    let { currentStep, steps } = this.state;
    // If the current step is not last step, then render the "next" button
    let lastStepIdx = steps.length - 1;
    if (currentStep < lastStepIdx) {
      return (
        <button className="btn btn-success" type="button" onClick={this._next}>
          <i class="fa fa-arrow-circle-right" aria-hidden="true">
            Next
          </i>
        </button>
      );
    }
    // ...else render nothing
    return null;
  }

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleClose = () => {
    const { registrationRow, regisExists } = this.props;
    const { steps } = this.state;
    let secondStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.SecondStep
    );
    const isDisabled =
      registrationRow &&
      registrationRow.regisStatusId != RegistrationStatuses.InProgress &&
      registrationRow.regisStatusId !=
        RegistrationStatuses.PendingRegistration &&
      regisExists;
    if (
      (registrationRow.regisStatusId == RegistrationStatuses.InProgress ||
        registrationRow.regisStatusId ==
          RegistrationStatuses.PendingRegistration ||
        _.isEmpty(registrationRow.regisStatusId)) &&
      !isDisabled &&
      this.state.currentStep == secondStepIdx
    ) {
      this.toggleShowConfirmCancelRegModal();
    } else {
      this.justCloseRegModal();
    }
  };

  toggleShowConfirmCancelRegModal = () => {
    this.setState({
      showConfirmCloseRegModal: !this.state.showConfirmCloseRegModal,
    });
  };
  onShow = async () => {
    const { props } = this;
    const {
      occasionId,
      registrationId,
      paymentStatusId,
      showSuccessPage,
      registrationRow,
      regisExists,
    } = props;
    //debugger;
    if (occasionId) {
      this.showSpinner();
      this.setState(initialState);
      let relatedFunction;

      if (
        this.props.regTableType !== RegistrationTableTypes.MyRegistrations &&
        !registrationId
      ) {
        // No Registration-Id means no Questions have been Answered yet
        ///// When Opened from Opened Registrations Tab....
        relatedFunction = RegistrationsService.GetOccasionRegistrationQuestions;
        ///Right now only event id avaialble, Registration Id can be Generated only after
        ///Registration Wizard's Step 2......
      } else {
        //It would be the edit mode of MyRegistration's view mode..
        relatedFunction =
          RegistrationsService.GetOccasionRegQuestionsWithAnswers;
      }
      await relatedFunction(occasionId, registrationId)
        .then(async (data) => {
          if (data.statusCode == 200) {
            let questionsResponse = data.result;
            const anyFeeQuestions = questionsResponse.some((question) => {
              return question.isFeeAssociated;
            });
            const anyRequiredFeeQuestions = questionsResponse.some(
              (question) => {
                return question.isFeeAssociated && question.isRequired;
              }
            );
            this.setState({
              anyRequiredFeeQuestions: anyRequiredFeeQuestions,
              anyFeeQuestions: anyFeeQuestions,
            });
            console.log("paymentStatusId", paymentStatusId);
            if (paymentStatusId == PaymentStatuses.Success) {
              this.setState({
                isFinishedPaymentFlow: true,
              });
              var steps = this.state.steps.filter(
                (s) => s.key !== RegisWizardSteps.ThirdStep //Removed Payment Step
              );
              this.setState({
                steps: steps,
              });
            }
            if (
              anyFeeQuestions &&
              anyRequiredFeeQuestions &&
              registrationRow &&
              registrationRow.regisStatusId !=
                RegistrationStatuses.InProgress &&
              registrationRow.regisStatusId !=
                RegistrationStatuses.PendingRegistration &&
              regisExists
            ) {
              if (registrationId && paymentStatusId == PaymentStatuses.UnPaid) {
                //Check from Remote stripe API that, is the Payment really happended? or Our App is just Unsynced
                var response = await PaymentService.GetStripeSession(
                  registrationId
                );
                console.log("checking online status of payment");

                if (!_.isEmpty(response)) {
                  if (response.sessionStatus == SessionStaus.complete) {
                    this.setState({
                      isFinishedPaymentFlow: true,
                    });
                    this.showPaymentSuccess();
                    //Manually fullfill the Order
                    var response = await PaymentService.HandleCheckoutSuccess(
                      registrationId,
                      occasionId
                    );
                  }
                }
              } else if (
                registrationId &&
                paymentStatusId == PaymentStatuses.Success
              ) {
                this.setState({
                  isFinishedPaymentFlow: true,
                });
              }
              if (paymentStatusId == PaymentStatuses.Failed) {
                this.setState({
                  isFailedPaymentFlow: true,
                });
              }
              // if (
              //    paymentStatusId == 0 ||
              //    (paymentStatusId == null && registrationRow.IsSessionExists)
              //  ) {
              //  }
            }

            //this.setState({ questionsFee: totalFee }); //Amount for total Fee, used in Step3 Payment

            questionsResponse = questionsResponse.map((question) => ({
              ...question,
              uid: makeuid(5),
            }));
            this.setState({ rawQuestionData: questionsResponse });

            const questionsJson = questionsResponse.reduce(
              (obj, cur) => ({
                ...obj,
                [cur.uid]: cur,
              }),
              {}
            );
            this.props.setRegistrationQuestions(questionsJson);
            if (showSuccessPage) {
              this.showPaymentSuccess();
            }
            this.hideSpinner();
          } else {
            this.hideSpinner();
          }
        })
        .catch((error) => {
          this.hideSpinner();
        });
    }

    let { selectedOpenRegQuestions } = this.props.registrationsState;
    let { _formData, _validationSchema } = initForm(selectedOpenRegQuestions);
    this.setState({ initialValues: _formData });
    this.setState({ validationSchema: _validationSchema });
    this.setState({ prevValues: _formData });
  };
  onSubmit = async (fields, helpers, details) => {
    console.log("details", details);
    let mySelf = this;
    const { currentStep, isExecutePaymentFlow, steps } = this.state;

    let secondStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.SecondStep
    );
    const {
      selectedOpenRegQuestions,
      //paymentStatus,
    } = this.props.registrationsState;
    let questionsWithAnswers = Object.entries(selectedOpenRegQuestions).map(
      (f) => ({
        value: fields[f[1].uid],
        id: f[1].id,
        questionType: f[1].questionType,
        isFeeAssociated: f[1].isFeeAssociated,
        fee: f[1].fee,
        answerMappingId: f[1].answerMappingId,
      })
    );
    let formData = {
      Questions: questionsWithAnswers,
      ChildTemplateId: this.props.occasionId,
      Registration_id: this.props.registrationId || 0,
      //PaymentStatus: paymentStatus,
      CloseBtnAction: this.state.wizardBtnCloseAction,
      CurrentStep: this.state.currentStep,
    };
    if (
      currentStep < 3 &&
      isExecutePaymentFlow &&
      !this.state.pauseRegistration
    ) {
      if (currentStep == secondStepIdx) {
        // Going forward from (Step(2)) of Answering Questions to Next Payment Screen(Step3)
        formData.CloseBtnAction = RegisWizardCloseActions.JustSaveAnswers; //Just Save the Question's Answers
        if (this.props.registrationId) {
          this._next();
        } else {
          await RegistrationsService.AddUpdateQuestionAnswers(formData).then(
            async (data) => {
              if (data && data.status && data.status == "Success") {
                await mySelf.props
                  .updateRegistrationId(data.registrationId)
                  .then(() => {
                    this._next();
                  });
              }
            }
          );
        }
      } else {
        this._next();
      }
    } else {
      //NO Payment Flow
      //It could be
      //1)Save&Close OR
      //2)Last Step Submit Action
      //3)PayNow Button
      //console.log("In onSubmit Method", JSON.stringify(formData));
      if (this.state.pauseRegistration) {
        formData.CloseBtnAction = this.state.wizardBtnCloseAction; //Should be Save&Close action
      } else {
        formData.CloseBtnAction = RegisWizardCloseActions.Submit; //Submit the Registration
      }
      await RegistrationsService.AddUpdateQuestionAnswers(formData).then(
        (data) => {
          if (data && data.status && data.status == "Success") {
            toast.success(data.message);
            if (this.state.pauseRegistration) {
              this.toggleShowConfirmCancelRegModal();
            }
            this.props.onClose();
          } else {
            toast.error("Something went wrong.");
          }
        }
      );

      ///this.props.reloadQuestions();
    }
  };
  justCloseRegModal = () => {
    this.props.onClose(); //No API hit
  };
  // validate = (values, props) => {
  //   let errors = { questions: [] };
  //   const { currentStep } = this.state;
  //   if (currentStep == 2) {
  //     const { questions } = values;
  //     questions.forEach((question, idx, allQuestions) => {
  //       if (
  //         (question.isRequired &&
  //           question.questionType == QuestionTypes.Text &&
  //           typeof question[question.uid] == "undefined") ||
  //         question[question.uid] == ""
  //       ) {
  //         errors.questions.push({ [question.uid]: "Required" });
  //       }
  //     });
  //   } else {
  //     errors = {};
  //   }
  //   console.log("In validate Method", JSON.stringify(errors));
  //   debugger;
  //   return errors;
  // };
  setPrevValues = (values) => {
    this.setState({ prevValues: values });
  };
  render() {
    const { selectedOpenRegRow, selectedOpenRegQuestions } =
      this.props.registrationsState || {};
    const { prevValues, steps, currentStep } = this.state;
    const {
      registrationRow,
      occasionId,
      registrationId,
      regisExists,
      paymentStatusId,
    } = this.props;
    let fourthStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.FourthStep
    );
    let thirdStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.ThirdStep
    );
    let firstStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.FirstStep
    );
    let secondStepIdx = steps.findIndex(
      (i) => i.key == RegisWizardSteps.SecondStep
    );
    const showNextButton =
      currentStep == firstStepIdx ||
      (registrationRow &&
        registrationRow.regisStatusId !== RegistrationStatuses.InProgress &&
        registrationRow.regisStatusId !==
          RegistrationStatuses.PendingRegistration &&
        (this.state.isExecutePaymentFlow || this.state.isFinishedPaymentFlow) &&
        regisExists);
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.handleClose}
        size="xl"
        centered
        animation={true}
        backdrop="static"
        onShow={this.onShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="row">
              <div className="col-xs-12 col-sm-12">
                <h5 className="modal-title mt-0">
                  Registration
                  {`${selectedOpenRegRow &&
                    ` for "${selectedOpenRegRow.title}"
                `} ${
                    selectedOpenRegRow &&
                    !_.isEmpty(selectedOpenRegRow.regisStatus)
                      ? "(" + (selectedOpenRegRow.regisStatus || "") + ")"
                      : ""
                  }`}
                </h5>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        {this.state.initialValues && (
          <Modal.Body className="modal-body">
            <>
              <Formik
                enableReinitialize
                initialValues={this.state.initialValues}
                validationSchema={
                  this.state.pauseRegistration
                    ? null
                    : this.state.validationSchema
                }
                onSubmit={this.onSubmit}
                //validate={this.validate}
                //validate={(values) => {
                // Validate the form using the Yup schema
                // try {
                //   this.state.validationSchema.validateSync(values, {
                //     abortEarly: false,
                //   });
                // } catch (error) {
                //   // Set the errors object using the information from the Yup error
                //   const errors = {};
                //   error.inner.forEach((err) => {
                //     errors[err.path] = err.message;
                //   });
                //   return {};
                // }
                //return {};
                //}}
              >
                {({
                  errors,
                  values,
                  touched,
                  setValues,
                  isValid,
                  handleSubmit,
                  setErrors,
                }) => {
                  useEffect(() => {
                    const {
                      occasionId,
                      registrationId,
                      paymentStatusId,
                      showSuccessPage,
                      registrationRow,
                      regisExists,
                    } = this.props;

                    if (JSON.stringify(prevValues) !== JSON.stringify(values)) {
                      console.log("form field changed", values);
                      this.setPrevValues(values);
                      let {
                        isFinishedPaymentFlow,
                        successStep,
                        steps,
                        paymentSteps,
                        rawQuestionData,
                      } = this.state;
                      if (
                        (registrationRow &&
                          (registrationRow.regisStatusId ==
                            RegistrationStatuses.InProgress ||
                            registrationRow.regisStatusId ==
                              RegistrationStatuses.PendingRegistration)) ||
                        registrationRow.regisStatusId == 0 // For Inprogress Or new Registraiton from Opened List Page
                      ) {
                        ///console.log("form if executed");

                        let allFeeAssocQues = Object.keys(
                          selectedOpenRegQuestions
                        ).filter(
                          (key, i, arr) =>
                            selectedOpenRegQuestions[key].isFeeAssociated
                        );

                        let anyFeeQuestionAnswered = Object.keys(values)
                          .filter((f) => allFeeAssocQues.includes(f))
                          .some((key) => values[key]);
                        if (anyFeeQuestionAnswered) {
                          const feeAnswersKeys = Object.keys(values).filter(
                            (f) => allFeeAssocQues.includes(f) && values[f]
                          );
                          let totalFee = rawQuestionData
                            .filter((f) => feeAnswersKeys.includes(f.uid))
                            .reduce((accumulator, object) => {
                              return accumulator + object.fee;
                            }, 0);
                          this.setState({ questionsFee: totalFee }); //Amount for total Fee, used in Step3 Payment

                          if (
                            steps.findIndex(
                              (n) => n.key == RegisWizardSteps.ThirdStep
                            ) == -1 &&
                            steps.findIndex(
                              (n) => n.key == RegisWizardSteps.FourthStep
                            ) == -1
                          ) {
                            if (isFinishedPaymentFlow) {
                              this.setState(
                                {
                                  steps: [...steps, successStep], //show success payment step as fee question is answered
                                },
                                () => {
                                  // console.log(
                                  //   "add steps",
                                  //   JSON.stringify(steps)
                                  // );
                                }
                              );
                            } else {
                              this.setState(
                                {
                                  steps: [...steps, ...paymentSteps], //show payment step as fee question is answered
                                  isExecutePaymentFlow: true,
                                },
                                () => {
                                  // console.log(
                                  //   "add steps",
                                  //   JSON.stringify(steps)
                                  // );
                                }
                              );
                            }
                          } else {
                            // Payment steps are already there
                            this.setState({
                              isExecutePaymentFlow: true,
                            });
                          }
                        } else {
                          ///Remove Payment Step as fee question hasn't answered
                          var filteredSteps = this.state.steps.filter(
                            (s) =>
                              s.key !== RegisWizardSteps.ThirdStep &&
                              s.key != RegisWizardSteps.FourthStep
                          );
                          this.setState({
                            steps: filteredSteps,
                            isExecutePaymentFlow: false,
                          });
                          //console.log("Remove steps", JSON.stringify(steps));
                        }
                        //this.setState({ steps });
                      }
                    }
                  }, [values]);
                  return (
                    <>
                      <Form>
                        <React.Fragment>
                          {/* Step {this.state.currentStep} */}
                          <div className="box">
                            <div className="steps">
                              <ul className="nav">
                                {this.state.steps.map((step, i) => {
                                  return (
                                    <li
                                      key={i}
                                      className={`${
                                        this.state.activeStep.key === step.key
                                          ? "active"
                                          : ""
                                      } ${step.isDone ? "done" : ""}`}
                                    >
                                      <div className="liChildDiv">
                                        <span className="stepNumber">
                                          Step {i + 1}
                                        </span>
                                        <br />
                                        <span className="stepDetail">
                                          {step.label}
                                        </span>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>

                            {/* // Render the form steps and pass in the required props */}
                            <StepOne currentStep={currentStep} steps={steps} />

                            {/* Step Two has Dynamic Questions */}
                            <StepTwo
                              currentStep={this.state.currentStep}
                              erros={errors}
                              setValues={setValues}
                              values={values}
                              steps={steps}
                              isDisabled={(() => {
                                return (
                                  registrationRow &&
                                  registrationRow.regisStatusId !=
                                    RegistrationStatuses.InProgress &&
                                  registrationRow.regisStatusId !=
                                    RegistrationStatuses.PendingRegistration &&
                                  regisExists
                                );
                              })()}
                            />
                            {/* Step Three has Payment Screen */}

                            <div ref={this.portalRef} />
                            <SucessfulPayment
                              currentStep={currentStep}
                              isFinishedPaymentFlow={
                                this.state.isFinishedPaymentFlow
                              }
                              steps={steps}
                            />
                          </div>
                        </React.Fragment>
                        <div className="card-footer text-center border-top-0">
                          {/* <button className="btn btn-secondary mr-1" type="reset">
                  Reset
                </button> */}
                          {registrationRow &&
                            this.props.regTableType ==
                              RegistrationTableTypes.MyRegistrations &&
                            (registrationRow.regisStatusId ==
                              RegistrationStatuses.InProgress ||
                              registrationRow.regisStatusId ==
                                RegistrationStatuses.PendingRegistration) && (
                              <button
                                type="button"
                                className="btn btn-primary mr-1 cancelModal bg-danger"
                                onClick={() =>
                                  RegistrationsService.CancelRegistration(
                                    registrationRow.id
                                  )
                                }
                              >
                                Cancel Reg.
                              </button>
                            )}
                          <button
                            type="button"
                            className="btn btn-primary mr-1 cancelModal"
                            onClick={this.handleClose}
                          >
                            Close
                          </button>
                          {/* <button
                          type="button"
                          className="btn btn-danger mr-1 cancelModal"
                          onClick={this.handleClose}
                        >
                          Cancel
                        </button> */}
                          {/* // Showed Above Previous Button// */}
                          {(currentStep == firstStepIdx ||
                            currentStep == secondStepIdx) &&
                            ((registrationRow &&
                              (registrationRow.regisStatusId ==
                                RegistrationStatuses.InProgress ||
                                registrationRow.regisStatusId ==
                                  RegistrationStatuses.PendingRegistration)) ||
                              !regisExists) && (
                              <button
                                type="submit"
                                disabled={!isValid}
                                className={`btn btn-success mr-1 wizardSubmit ${
                                  currentStep == secondStepIdx ? "" : "hidden"
                                }`}
                              >
                                {this.state.isExecutePaymentFlow ? (
                                  <i
                                    class="fa fa-arrow-circle-right"
                                    aria-hidden="true"
                                  >
                                    Next
                                  </i>
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            )}
                          {//registrationRow &&
                          // registrationRow.regisStatusId ==
                          //RegistrationStatuses.InProgress &&
                          thirdStepIdx != -1
                            ? currentStep <= thirdStepIdx && this.previousButton
                            : this.previousButton}

                          {/* //Next Button// */}
                          {showNextButton && this.nextButton}
                          {/*  //For View Mode //*/}
                          {/* // This next button is just for navigation no api hit // */}
                        </div>
                      </Form>
                      <CloseRegistrationWizardModal
                        modal={this.state.showConfirmCloseRegModal}
                        confirm={this.confirmCancelRegModal}
                        toggle={this.toggleShowConfirmCancelRegModal}
                        justCloseRegModal={this.justCloseRegModal}
                        regTableType={this.props.regTableType}
                        registrationRow={registrationRow}
                        cancelRegistration={
                          RegistrationsService.CancelRegistration
                        }
                        submitFormData={handleSubmit}
                        isFormValid={isValid}
                        setFormErrors={setErrors}
                        currentStep={this.state.currentStep}
                      />
                    </>
                  );
                }}
              </Formik>
              {this.state.isExecutePaymentFlow && (
                <Portal container={this.portalRef.current}>
                  <StepThree
                    currentStep={this.state.currentStep}
                    nextStep={this._next}
                    steps={steps}
                    closeRegistrationModal={this.props.onClose}
                    occasionId={occasionId}
                    registrationId={registrationId}
                    isFinishedPaymentFlow={this.state.isFinishedPaymentFlow}
                    questionsFee={this.state.questionsFee}
                  />
                </Portal>
              )}
            </>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(RegisterMemberModal)
);
