import actions from "./action";

const initialState = {
  selectedMembers: [],
};

export default function areas(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_MULTIPLE_AUTH_SENDERS: {
      return {
        ...state,
        authorizedSendersMembers: [
          ...state.selectedMembers.filter((f) => f.id == 0),
        ],
      };
    }
    case actions.SELECT_AUTH_SENDER_MEMBER: {
      return {
        ...state,
        selectedMembers: [...state.selectedMembers, action.payload],
      };
    }
    case actions.DESELECT_SELECTED_POPUP_ENTITY: {
      return {
        ...state,
        selectedMembers: state.selectedMembers.filter(
          (f) => f !== action.payload
        ),
      };
    }
    case actions.DESELECT_ALL_SELECTED_POPUP_ENTITY: {
      return {
        ...state,
        selectedMembers: [],
      };
    }
    default:
      return state;
  }
}
