import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import jsPDF from "jspdf";
import RegService from "../../RegistrationsService";
import overlayFactory from "react-bootstrap-table2-overlay";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";

import {
  ChildTemplateStatuses,
  RegistrationStatuses,
  RegistrationTableTypes,
} from "../../Helpers/LookUpValues";
import chistory from "../../../Shared/history";
const isSuperAdmin = AuthService.canView(PermissionFeature.REGSuperAdmin);
const isBasicAdmin = AuthService.canView(PermissionFeature.REGBasicAdmin);
const RegistrationsActions = (
  cell,
  row,
  handleRegistrationViewClick,
  deleteReg,
  regTableType,
  // viewEditReg,
  openRegistrationForApproval,
  openAttendanceModal,
  cancelMyRegistration,
  handleChildTemplateViewClick,
  handleChildTemplateCancelClick,
  handleChildTemplateCopyClick
) => {
  return (
    <div>
      <div className="actionIcons">
        {/* <span className="">
          <i
            className="fa fa-pencil-square-o c-pointer"
            title="Edit"
            aria-hidden="true"
          ></i>
          Testing the action col visibility....
        </span> */}

        {regTableType == RegistrationTableTypes.OpenRegistrations && (
          <i
            className="fa fa fa-id-card fa-md pl-1 pt-2 cursorPointer"
            title="Click to Register"
            onClick={() => handleRegistrationViewClick(row)}
          ></i>
        )}
        {/***** Action Icons for "My-Registrations" *****/}
        {regTableType == RegistrationTableTypes.MyRegistrations && row && (
          //row.regisStatusId == RegistrationStatuses.InProgress && (
          <>
            <>
              <i
                className="fa fa-pencil-square-o c-pointer"
                title="Edit"
                aria-hidden="true"
                onClick={
                  () => handleRegistrationViewClick(row) //viewEditReg(row) //Method called from My Registrations tab
                }
              ></i>

              <i
                className="text-danger fas fa-trash c-pointer pl-1 pr-1"
                title="Delete"
                onClick={() => deleteReg(row.id)}
              ></i>
              {(row.regisStatusId == RegistrationStatuses.InProgress ||
                row.regisStatusId ==
                  RegistrationStatuses.PendingRegistration) && (
                <i
                  className="text-danger fas fa-times-circle c-pointer pl-1 pr-1"
                  title="Cancel"
                  onClick={() => cancelMyRegistration(row.id)}
                ></i>
              )}
            </>
          </>
        )}
        {regTableType == RegistrationTableTypes.ReviewRequests && (
          <>
            {row.isPreStartDate && (
              <i
                className="fa fa-life-ring fa-md pl-1 pt-2 cursorPointer"
                title="Review Request"
                onClick={() => openRegistrationForApproval(row)}
              ></i>
            )}
            {row.isPostStart && (
              <i
                className="fa fa-calendar-check-o fa-md pl-1 pt-2 cursorPointer"
                title="Mark Attendance"
                onClick={() => openAttendanceModal(row)}
              ></i>
            )}
          </>
        )}
        {regTableType == RegistrationTableTypes.AdminRegistrations && (
          <>
            {/* // Show RegisterNow icon for those records for which logged-in user haven't already registered, or inprogres state//  */}
            {(row.showRegisterNow || !row.isAdmin) &&
              row.childTemplateStatusId != ChildTemplateStatuses.Cancelled && (
                //If this record is displaying in the list for open-registrations page  && the current user isn't
                //the creator then it must be for Register-Now and the record colud be inprogress state.
                <i
                  className="fa fa fa-id-card fa-md pl-1 pt-2 cursorPointer"
                  title="Click to Register"
                  onClick={() => handleRegistrationViewClick(row)}
                ></i>
              )}
            {((isBasicAdmin && row.thisCreator) || isSuperAdmin) && (
              <div className="float-right">
                <i
                  className="fa fa-pencil-square-o fa-md pl-1 pt-2 cursorPointer"
                  title="View/Edit Event"
                  onClick={() => handleChildTemplateViewClick(row)}
                ></i>
                <i
                  className="text-danger fas fa-times-circle c-pointer pl-1 pr-1"
                  title="Cancel"
                  onClick={() => handleChildTemplateCancelClick(row.id)}
                ></i>
                <i
                  className="fa fa-clone c-pointer pl-1 pr-1"
                  aria-hidden="true"
                  title="Copy"
                  onClick={() => handleChildTemplateCopyClick(row)}
                ></i>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;

const getExportFileName = (tblType, filetype) => {
  //   if (tblType === FERequestsType.MyRequests) {
  //     return `${Date.now()}_MyRequestsList.${filetype}`;
  //   }
  //   if (tblType > FERequestsType.MyRequests) {
  //     return `${Date.now()}_ReviewRequests.${filetype}`;
  //   }
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.requestType, "pdf");
  const formData = {
    recordtype: allprops.requestType, // Active/InAccountReview || My/Review Requests
    isExportTable: true,
    PageNo: 1,
    PageSize: 10,
    SortOrder: "id",
    activeTab: allprops.activeSubHeader, // Inventory Or Requests
    isAdvanceSearchApplied: allprops.isAdvanceSearchApplied,
    searchCriteria: allprops.isAdvanceSearchApplied
      ? allprops.searchCriteria
      : {},
    showClosed: allprops.showClosed,
  };
  var response = await RegService.getDataForFEExport(formData);
  if (!_.isEmpty(response)) {
    var tableBody = response.data.map((val) => {
      var listRow = [];
      listRow.push(val.userName);
      listRow.push(val.description);
      listRow.push(val.requesterName);
      listRow.push(val.RegistrationName);
      listRow.push(val.categoryName);
      listRow.push(val.typeName);
      listRow.push(val.statusName);
      return listRow;
    });
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "USER",
          "DESCRIPTION",
          "REQUESTER",
          "BUDGET",
          "CATEGORY",
          "TYPE",
          "STAUS",
        ],
      ],
      body: tableBody,
      tableWidth: "auto",
      styles: { cellPadding: 0.7, fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },

        // etc
      },
    });
    doc.save(filename);
  }
};
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="RegistrationsKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder="Registration Search.."
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};

// ************************Start of the Component************************//
const RegistrationsListTable = (props) => {
  const {
    listData,
    handleRegistrationViewClick,
    deleteRegistration,
    isloading,
    regTableType,
    openRegistrationForApproval,
    openAttendanceModal,
    cancelMyRegistration,
    ///Starting props for child tempaltes///
    handleChildTemplateViewClick,
    handleChildTemplateCancelClick,
    handleChildTemplateCopyClick,
    /////*********/////
  } = props;

  const columns = [
    {
      text: "TITLE",
      dataField: "title",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "DATES",
      dataField: "dates",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "REGISTRATION WINDOW",
      dataField: "registrationWindow",
      sort: true,
      hidden: regTableType == RegistrationTableTypes.MemberProfileRegistrations,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "LOCATION / REGION",
      dataField: "location",
      sort: true,
      hidden: regTableType == RegistrationTableTypes.MemberProfileRegistrations,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "CATEGORY",
      dataField: "category",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
      hidden: regTableType == RegistrationTableTypes.MemberProfileRegistrations,
    },
    {
      text: "STATUS",
      dataField:
        regTableType == RegistrationTableTypes.AdminRegistrations ||
        regTableType == RegistrationTableTypes.ReviewRequests ||
        regTableType == RegistrationTableTypes.OpenRegistrations
          ? "childTemplateStatus"
          : "regisStatus",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
      hidden:
        regTableType !== RegistrationTableTypes.MemberProfileRegistrations &&
        regTableType !== RegistrationTableTypes.MyRegistrations &&
        regTableType !== RegistrationTableTypes.OpenRegistrations &&
        regTableType !== RegistrationTableTypes.AdminRegistrations &&
        regTableType !== RegistrationTableTypes.ReviewRequests,
    },
    {
      text: "",
      dataField: "actions",
      isDummyField: true,
      hidden: !(
        regTableType != RegistrationTableTypes.MemberProfileRegistrations ||
        regTableType == RegistrationTableTypes.ReviewRequests
      ),
      headerClasses: "text-center text-nowrap text-white px-sm-1 py-2",
      headerAttrs: {
        width: "95px",
      },
      formatter: (cell, row) => {
        return RegistrationsActions(
          cell,
          row,
          handleRegistrationViewClick,
          deleteRegistration,
          regTableType,
          openRegistrationForApproval,
          openAttendanceModal,
          cancelMyRegistration,
          //Pasing of ChildTemplate Event Handlers
          handleChildTemplateViewClick,
          handleChildTemplateCancelClick,
          handleChildTemplateCopyClick
        );
      },
      csvExport: false,
    },
  ];
  const RemotePagination = (data, isloading, regTableType, rowEvents) => (
    <ToolkitProvider keyField="id" data={data} columns={columns} search>
      {(props) => (
        <div>
          {regTableType !==
            RegistrationTableTypes.MemberProfileRegistrations && (
            <MySearch {...props.searchProps} />
          )}
          <BootstrapTable
            overlay={overlayFactory({
              spinner: true,
              styles: {
                overlay: (base) => ({
                  ...base,
                  background: "rgba(40, 45, 42, 0.19)",
                }),
              },
            })}
            noDataIndication={() => {
              return isloading === false ? <NoDataIndication /> : <Empty />;
            }}
            loading={isloading}
            rowEvents={rowEvents}
            rowClasses={`${
              regTableType == RegistrationTableTypes.MemberProfileRegistrations
                ? "c-pointer"
                : ""
            }`}
            {...props.baseProps}
          />
          <br />
        </div>
      )}
    </ToolkitProvider>
  );
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      //alert(`You clicked row ${rowIndex}`);
      // if (window.location.pathname.includes("/dashboard")) {
      //   chistory.push(`/registrations`);
      // } else if (window.location.pathname.includes("registrations/requests")) {
      //   openRegistrationForApproval(row);
      // }
    },
  };
  return <>{RemotePagination(listData, isloading, regTableType, rowEvents)}</>;
};

RegistrationsListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

RegistrationsListTable.defaultProps = {
  listData: [],
};

export default RegistrationsListTable;
