import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import { Link } from "react-router-dom";
import RemoveActionType from "../Enums/RemoveActionType";
import overlayFactory from "react-bootstrap-table2-overlay";
import { Spinner } from "reactstrap";
import EmailConfigTables from "../Enums/EmailConfigTables";
import { Table } from "react-bootstrap";

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const Empty = () => <div />;
const CaptionElement = (props) => (
  <h6 className="sub_tbl_caption">{props.header}</h6>
);

const actions = (
  cell,
  row,
  setCurrentTeamInfo,
  confirmDeleteTeam,
  keyIdfr,
  hideActionColumn,
  showReSubscribeModal
) => {
  return (
    <div className="mobileActions">
      <div className="actionIcons">
        {hideActionColumn &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
        row.id != 0 &&
        row.isactive ? (
          <button
            type="button"
            className="btn btn-danger unsubscrActionBtn mr-1"
            onClick={() => {
              confirmDeleteTeam(
                row.id,
                row.teamId,
                keyIdfr,
                RemoveActionType.UnSubscribe
              );
              setCurrentTeamInfo(
                row.teamName,
                row.id,
                RemoveActionType.UnSubscribe
              );
            }}
          >
            UnSubscribe
            <i
              title="UnSubscribe"
              className="fa fa-bell-slash-o cursorPointer"
              //style={{ marginLeft: "10%" }}
            />
          </button>
        ) : hideActionColumn &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
          row.id != 0 &&
          !row.isactive ? (
          <button
            type="button"
            className="btn btn-success unsubscrActionBtn mr-1"
            disabled={row.isblocked}
            onClick={() => {
              showReSubscribeModal(row, keyIdfr, RemoveActionType.ReSubscribe);
            }}
          >
            ReSubscribe
            <i
              title="ReSubscribe"
              className="fa fa-bell-slash-o cursorPointer"
              ///style={{ marginLeft: "10%" }}
            />
          </button>
        ) : (
          ""
        )}

        {hideActionColumn &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
        row.id != 0 &&
        !row.isblocked ? (
          <button
            type="button"
            className="btn btn-danger unsubscrActionBtn mr-3"
            onClick={() => {
              confirmDeleteTeam(
                row.id,
                row.teamId,
                keyIdfr,
                RemoveActionType.Block
              );
              setCurrentTeamInfo(row.teamName, row.id, RemoveActionType.Block);
            }}
          >
            Block
            <i
              title="Block"
              className="fa fa-bell-slash-o cursorPointer"
              //style={{ marginLeft: "10%" }}
            />
          </button>
        ) : hideActionColumn &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
          row.id != 0 &&
          row.isblocked ? (
          <button
            type="button"
            className="btn btn-success unsubscrActionBtn mr-1"
            onClick={() => {
              showReSubscribeModal(row, keyIdfr, RemoveActionType.UnBlock);
            }}
          >
            UnBlock
            <i
              title="UnBlock"
              className="fa fa-bell-slash-o cursorPointer"
              ///style={{ marginLeft: "10%" }}
            />
          </button>
        ) : (
          ""
        )}

        <Link
          to={{
            pathname: "/permissions/teams/teamDetail/" + row.teamId,
          }}
          target="_blank"
        >
          <i
            className="fas fa-eye fa-md pl-1 pt-2 mr-1 cursorPointer"
            title="View Member"
          />
        </Link>
        {hideActionColumn && (
          <i
            title="Remove"
            className="fas fa-trash-alt mr-2 cursorPointer text-danger"
            //style={{ marginLeft: "10%" }}
            onClick={() => {
              confirmDeleteTeam(
                row.id,
                row.teamId,
                keyIdfr,
                RemoveActionType.Delete
              );
              setCurrentTeamInfo(row.teamName, row.id);
            }}
          />
        )}
      </div>
    </div>
  );
};
const columns = (
  setCurrentTeamInfo,
  toggleConfirm,
  keyIdfr,
  hideActionColumn,
  showReSubscribeModal
) => [
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
    // headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
  },

  {
    dataField: "id",
    sort: true,
    hidden: true,
    csvExport: false,
    //headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
  },
  {
    dataField: "teamId",
    sort: true,
    hidden: true,
    csvExport: false,
  },
  {
    text: "",
    dataField: "teamId",
    isDummyField: true,
    headerClasses: "text-nowrap px-sm-3 px-2 py-2",
    classes: "pl-3 pr-2 py-2",
    // headerAttrs: {
    //   width: "100px",
    // },
    // headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
    formatter: (cell, row) => {
      return actions(
        cell,
        row,
        setCurrentTeamInfo,
        toggleConfirm,
        keyIdfr,
        hideActionColumn,
        showReSubscribeModal
      );
    },
    csvExport: false,
  },
];
const expandRow = {
  parentClassName: "parent-expand",
  onlyOneExpanding: true,
  renderer: (row) => (
    <>
      {row.id ? (
        <div className="expandTbl">
          {row && !_.isEmpty(row.members) && (
            <>
              <div class="strong d-flex justify-content-center text-primary font-weight-bold ">
                <h6>Team Members</h6>
                <span class="hidden">
                  Total Members {row.members && row.members.length}
                </span>
              </div>
              <div class="d-flex justify-content-center equipExpandableView">
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.members.map((member) => (
                      <tr>
                        <td>{member.name}</td>
                        <td>
                          {member.address}{" "}
                          {member.isDuplicate == true
                            ? showDuplicateIcon()
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}

          {row && _.isEmpty(row.members) && (
            <div class="container">
              <div class="row noADData">
                <span>No Data to display!</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  ),
};
const showDuplicateIcon = () => {
  return (
    <i className="fa fa-files-o" aria-hidden="true" title="Duplicate Email" />
  );
};
const RemotePagination = (
  data,
  setCurrentTeamInfo,
  confirmDeleteTeam,
  loading,
  keyIdfr,
  caption,
  hideActionColumn,
  showReSubscribeModal,
  shrinkActionCol
) => (
  <div
    className={`Sub_Teams_${keyIdfr}_Tbl`}
    id={`Sub_Teams_${keyIdfr}_Records`}
    key={`Sub_Teams_${keyIdfr}_Records_Parent`}
  >
    <ToolkitProvider
      keyField="teamId"
      data={data}
      columns={columns(
        setCurrentTeamInfo,
        confirmDeleteTeam,
        keyIdfr,
        hideActionColumn,
        showReSubscribeModal,
        shrinkActionCol
      )}
    >
      {(props) => (
        <>
          <BootstrapTable
            keyField="teamId"
            loading={!_.isEmpty(data) && loading}
            overlay={overlayFactory({
              spinner: true,
              styles: {
                overlay: (base) => ({
                  ...base,
                  background: "rgba(40, 45, 42, 0.19)",
                }),
              },
            })}
            noDataIndication={() => {
              return loading === false ? (
                <NoDataIndication />
              ) : (
                <div className="d-flex justify-content-center p-4">
                  <Spinner />
                </div>
              );
            }}
            expandRow={
              keyIdfr == EmailConfigTables.AuthorizedSendersTeam
                ? {}
                : expandRow
            }
            caption={<CaptionElement header={caption} />}
            {...props.baseProps}
          />
        </>
      )}
    </ToolkitProvider>
  </div>
);

const EmailConfigTeamsTable = (props) => {
  const {
    listData,
    setCurrentTeamInfo,
    confirmDeleteTeam,
    loading,
    keyIdfr,
    caption,
    hideActionColumn, // Hides the Delete and UnSubscribe action icons for memebres having on view(readonly) permissions
    showReSubscribeModal,
    shrinkActionCol,
  } = props;
  return (
    <>
      {RemotePagination(
        listData,
        setCurrentTeamInfo,
        confirmDeleteTeam,
        loading,
        keyIdfr,
        caption,
        hideActionColumn,
        showReSubscribeModal,
        shrinkActionCol
      )}
    </>
  );
};

EmailConfigTeamsTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

EmailConfigTeamsTable.defaultProps = {
  listData: [],
};

export default EmailConfigTeamsTable;
