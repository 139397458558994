import React, { Component } from "react";
import { connect } from "react-redux";
import registrationsAction from "../../../../store/registrations/action";
import classnames from "classnames";
import "react-image-lightbox/style.css";
import Gallery from "react-grid-gallery";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";
import DeleteModal from "../../../Dashboard/FacilityDashboard/Modals/DeleteModal";
import OshaFilesList from "../../../Dashboard/FacilityDashboard/OshaFilesList";

export class TemplateAttachmentsModal extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      currentImage: -1,
      isConfirmModalOpen: false,
      NonImageFile: "/",
      currentView: "",
    };
    this.deleteImage = this.deleteImage.bind(this);

    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.gallaryComponent = React.createRef();
    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.currentImage === -1 && this.state.NonImageFile === "/") {
          return false;
        }

        const {
          data,
        } = this.props.registrationsState.templateAttachmentFiles;

        if (this.state.activeTab === "1") {
          var delImg = data
            .filter((item) => item.match(/\.(jpeg|jpg|gif|png)$/) != null)
            .find((el, index) => {
              return index === this.state.currentImage;
            });

          delImg &&
            this.props.deleteTemplateFile(this, delImg.split("assets/").pop());
        } else if (this.state.activeTab === "2") {
          var delImg = data.find((el, index) => {
            return el === this.state.NonImageFile;
          });

          delImg &&
            this.props.deleteTemplateFile(this, delImg.split("assets/").pop());
        }
      };
      return this;
    };
  }
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };
  deleteImage() {
    this.toggleConfirmModal();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onClickThumbnailHandler = (e) => {
    console.log(e);
  };
  onCurrentImageChange(index) {
    this.setState({ currentImage: index });
  }

  componentDidMount() {
    this.toggle("1");
  }

  deleteFileAttach = (path) => {
    this.setState({ NonImageFile: path });
    this.toggleConfirmModal();
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const { data } = this.props.registrationsState.templateAttachmentFiles;
    const self = this;

    return (
      <div>
        <Modal
          centered={true}
          className="modal-lg"
          role="dialog"
          autoFocus={true}
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          backdrop="static"
        >
          <ModalHeader toggle={this.props.toggle}>
            Template Attachments
          </ModalHeader>
          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Images
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Files
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Gallery
                      ref="GallaryLightBox"
                      customControls={[
                        <button
                          key="deleteImage"
                          className="customDelete"
                          onClick={this.deleteImage}
                        >
                          {" "}
                          <FaTrashAlt size={16} />
                        </button>,
                      ]}
                      currentImageWillChange={this.onCurrentImageChange}
                      enableImageSelection={false}
                      images={
                        typeof data !== "undefined"
                          ? data
                              .filter(
                                (item) =>
                                  item.match(/\.(jpeg|jpg|gif|png)$/) !== null
                              )
                              .map((path) => ({
                                src: path,
                                thumbnail: path,
                                thumbnailWidth: 320,
                                thumbnailHeight: 212,
                                // customOverlay: (
                                //     <div style={captionStyle}>
                                //         <i
                                //             className="myOverlayIcon" id="wwe"

                                //         >
                                //             {' '}
                                //             I hover: {this.state.hoverDel ? 'yes' : 'no'}
                                //         </i>
                                //     </div>)
                              }))
                          : []
                      }
                    />

                    {data === undefined ||
                      (data.filter(
                        (item) => item.match(/\.(jpeg|jpg|gif|png)$/) !== null
                      ).length === 0 && (
                        <div class="container h-100">
                          <div class="row spinnerFacilityCategory">
                            <h5>No Images Attached!</h5>
                          </div>
                        </div>
                      ))}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Col>
                  <OshaFilesList
                    deleteFileAttach={this.deleteFileAttach}
                    files={
                      data &&
                      data
                        .filter(
                          (item) => item.match(/\.(jpeg|jpg|gif|png)$/) === null
                        )
                        .map((path) => ({
                          src: path,
                        }))
                    }
                  />
                </Col>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </div>
    );
  }
}
export default connect(
  (state) => ({
    registrationsState: state.registrations,
  }),
  {
    ...registrationsAction,
  }
)(TemplateAttachmentsModal);
