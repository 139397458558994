import React, { Component } from "react";
import FEHeader from "../FEHeader";
import InventoryListTable from "./InventoryListTable";
import FETrackerActions from "../../../store/feTracker/action";
import InventoryStatus from "../InventoryStatus";
import _ from "lodash";
import { Collapse, Card } from "reactstrap";
import { connect } from "react-redux";
import FE_Files from "./FE_Files";
import FE_EquipmentDisposeModal from "./FE_EquipmentDisposeModal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { Tags } from "../AdvanceFilter/Tags";
import FE_List_Types from "../FE_List_Types";
import FE_API_Actions from "../FE_API_Actions";
import FEServices from "../F&ERequests/FEService";
import DeleteEquipmentModal from "./DeleteEquipmentModal";
import ListFilters from "../../Shared/ListFilters";
import FE_AF_Nav_Items from "../AdvanceFilter/FE_AF_Nav_Items";
import FE_Tabs from "../FE_Tabs";
import $ from "jquery";
import AllExpandedInventories from "./AllExpandedInventories";
import AddRoleWrap from "../../Admin/AddRole/AddRoleWrap";
import FEService from "../F&ERequests/FEService";

export class Inventory extends Component {
  constructor(props) {
    super(props);
    this.allExpandedInventories = React.createRef();
  }
  state = {
    isallequipmentslistopen: true,
    isaccreviewequipmentslistopen: true,
    includeClosedTitle: "Show History",
    includeClosed: false,
    currentRequestId: 0,
    isNotificationCall: false,
    showListFilters: true,
    activeTabKey: "equipment",
    totalExportRowsCount: 0,
    exportData: [],
  };
  callListTables = () => {
    if (this.props.festate.isFEInventoryAdvanceSearchApplied) {
      if (Object.keys(this.props.festate.filterData).length > 2) {
        ///Advance Search has been applied
        ///Advance Data will be load from the listfilter conponent did mount function.
        var activeSections = [];

        activeSections.push(InventoryStatus.NonAccReview);
        activeSections.push(InventoryStatus.InAccReview);
        const formData = {
          ...this.props.festate.filterData,
          recordsTypeList: activeSections,
          PageNo: 1,
          PageSize: 25,
          SortOrder: "id", //"firstnameAsc",
          showHistory: false,
          EventType: FE_API_Actions.AdvanceSearch,
        };

        this.props.searchFeInventoryRecords(formData);
      }
    } else {
      ///Advance Search has not been applied
      var activeSections = [];
      //********Default view for Equipment Listing*********/
      ///activeSections.push(InventoryStatus.InAccReview);
      //Only Equipment Tab Will have records with **Active-Status** and **Current-Year**
      activeSections.push(InventoryStatus.NonAccReview);
      activeSections.push(InventoryStatus.InAccReview);
      const formData = {
        recordsTypeList: activeSections,
        PageNo: 1,
        PageSize: 25,
        SortOrder: "id", //"firstnameAsc",
        showHistory: false,
      };

      this.props.searchFeInventoryRecords(formData);
    }
  };
  componentDidMount() {
    this.callListTables();
    ///Incase notificaiton is clicked
    const { id } = this.props.match.params;

    if (!_.isEmpty(id)) {
      var notificationCall = this.props.location.state.notificationCall;
      if (notificationCall) {
        this.setState({ isNotificationCall: true });
        this.setState({ currentRequestId: id });
        this.showMyRequest(id);
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    if (this.state.isNotificationCall && this.state.currentRequestId != id) {
      this.showMyRequest(this.state.currentRequestId);
      this.setState({ currentRequestId: id });
    }
  }
  showMyRequest = async (id) => {
    let response = await FEServices.FetchRequestById(id);
    if (!_.isEmpty(response)) {
      let requestRow = response.data;
      this.props.openEquipmentRelatedRequest(requestRow);
    }
  };
  handleEquipmentTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    // console.log("table change called " + type);
    if (type === "sort") {
    }
    //console.log("sortField + sortOrder" + sortField + sortOrder);
    this.props.setInventoryTablePaginationInfo(
      page,
      sizePerPage,
      InventoryStatus.NonAccReview
    );
    var activeSections = [];
    //Check if AF applied
    if (this.props.festate.isFEInventoryAdvanceSearchApplied) {
      activeSections.push(InventoryStatus.NonAccReview);

      const formData = {
        ...this.props.festate.filterData,
        recordsTypeList: activeSections,
        PageNo: page,
        PageSize: sizePerPage,
        SortOrder: sortOrder,
        SortBy: sortField,
        showHistory: this.props.festate.showHisActiveInv,
        EventType: FE_API_Actions.TableChange,
      };

      this.props.getResultRowsForAdvanceFilter(formData);
    } else {
      /// Simple Table change happen
      activeSections.push(InventoryStatus.NonAccReview);

      if (
        type == "pagination" &&
        _.isEmpty(sortOrder) &&
        _.isEmpty(sortField)
      ) {
        sortField = "id";
        sortOrder = "asc";
      }

      const formData = {
        PageNo: page,
        PageSize: sizePerPage,
        SortBy: sortField,
        SortOrder: sortOrder,
        showHistory: this.props.festate.showHisActiveInv,
        recordsTypeList: activeSections,
        EventType: FE_API_Actions.TableChange,
      };

      this.props.searchFeInventoryRecords(formData);
    }
  };
  handleAccReviewTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage, filters }
  ) => {
    // console.log("table change" + type);

    var activeSections = [];
    this.props.setInventoryTablePaginationInfo(
      page,
      sizePerPage,
      InventoryStatus.InAccReview
    );
    if (this.props.festate.isFEInventoryAdvanceSearchApplied) {
      activeSections.push(InventoryStatus.InAccReview);

      const formData = {
        ...this.props.festate.filterData,
        recordsTypeList: activeSections,
        PageNo: page,
        PageSize: sizePerPage,
        SortOrder: sortOrder,
        showHistory: this.props.festate.showHisActiveInv,
        EventType: FE_API_Actions.TableChange,
      };

      this.props.getResultRowsForAdvanceFilter(formData);
    } else {
      var activeSections = [];

      activeSections.push(InventoryStatus.InAccReview);
      if (
        type == "pagination" &&
        _.isEmpty(sortOrder) &&
        _.isEmpty(sortField)
      ) {
        sortField = "id";
        sortOrder = "asc";
      }

      const formData = {
        // fileId: this.props.festate.fileId,
        PageNo: page,
        PageSize: sizePerPage,
        SortBy: sortField,
        SortOrder: sortOrder,
        showHistory: this.props.festate.showHisActiveInv,
        recordsTypeList: activeSections,
        EventType: FE_API_Actions.TableChange,
      };

      this.props.searchFeInventoryRecords(formData);
    }
  };
  toggleClosedRequests = () => {
    var title = this.state.includeClosedTitle;
    if (this.state.includeClosed) {
      title = "Show History";
      this.state.includeClosed = false;
      this.props.setFEShowHistory(false, FE_List_Types.Active);
    } else {
      title = "Hide History";
      this.state.includeClosed = true;
      this.props.setFEShowHistory(true, FE_List_Types.Active);
    }
    this.setState({
      includeClosed: this.state.includeClosed,
      includeClosedTitle: title,
    });

    var activeSections = [];

    activeSections.push(InventoryStatus.NonAccReview);
    const formData = {
      recordsTypeList: activeSections,
      PageNo: 1,
      PageSize: 25,
      showHistory: this.state.includeClosed,
      SortOrder: "id",
    };

    this.props.searchFeInventoryRecords(formData);
  };

  resetShowHistory = () => {
    if (this.state.includeClosed) {
      this.setState({
        includeClosedTitle: "Show History",
        includeClosed: false,
      });
      this.props.setFEShowHistory(false, FE_List_Types.Active);
    }
  };
  handleDataChangeInAccReviewEquip = ({ dataSize }) => {
    this.props.setInAccEquipSize(dataSize);
  };
  handleDataChangeActiveEquip = ({ dataSize }) => {
    this.props.setActiveEquipSize(dataSize);
  };
  getSelectedFilterYear = () => {
    const { festate } = this.props;
    let { inventories } = festate.filterData.searchApplied;
    let yearSearchFilter = inventories.find(
      (inv) => inv.searchType === FE_AF_Nav_Items.Year
    );

    let yearSearchApplied =
      inventories &&
      inventories
        .filter((inv) => inv.searchType == FE_AF_Nav_Items.Year)
        .every((e) => e.isSearched);
    let year;
    if (
      festate.isFEInventoryAdvanceSearchApplied &&
      yearSearchFilter &&
      yearSearchApplied
    ) {
      let yearSearchFilter = inventories.find(
        (inv) => inv.searchType === FE_AF_Nav_Items.Year
      );
      if (!_.isEmpty(yearSearchFilter)) {
        year = yearSearchFilter.selectedItems;
      }
    } else {
      year = [];
    }
    return year;
  };
  //getSelectedFilterCount = () => {
  //  const { festate } = this.props;
  //  let { inventories } = festate.filterData.searchApplied;
  //  let yearFilterCount = inventories.filter((inv) => inv.searchType === 6);
  //  return yearFilterCount.length;
  //};
  toggleListFilters = () => {
    this.setState({
      showListFilters: !this.state.showListFilters,
    });
  };
  onClickCSVExport = () => {
    let id = "";
    const { activeTabKey } = this.state;
    if (activeTabKey == "equipment") {
      id = "exportEqs";
    } else {
      id = "inaccExportEqs";
    }

    let csvIcon = document.getElementById(id);
    csvIcon.click();
  };

  ///New Click Handler for PDF Export
  onClickPDFExport = async () => {
    //first get all records data
    const { activeTabKey } = this.state;
    let elementId = "";

    if (activeTabKey == "equipment") {
      await this.getAllRecordsData(InventoryStatus.NonAccReview); //Active+Disposed
      elementId = "Exp_Equipments_list_4_Records";
    } else {
      await this.getAllRecordsData(InventoryStatus.InAccReview); ///Only In Acc Review
      elementId = "Exp_Equipments_list_1_Records";
    }
    let { current } = this.allExpandedInventories;
    await current.showAllExpandableRows();
    // $(
    //   "#Exp_Equipments_list_4_Records > div.row.react-bootstrap-table-pagination"
    // ).remove();

    var target = document.getElementById(elementId);
    //  var wrap = document.createElement("div");
    //  wrap.appendChild(target.cloneNode(true));
    if (!_.isEmpty(target)) {
      var html = target.innerHTML.replace("tfoot", "span");
      $(".loading").show();

      const response = await FEService.generatePDFFileFromHTML(html);

      //console.log("file:", response);
      this.resolveAndDownloadBlob(response);
      $(".loading").hide();
    } else {
      console.log("Error:", "Element is null");
    }
  };

  getAllRecordsData = async (equipmentType) => {
    const formData = {
      recordtype: equipmentType,
      isExportTable: true,
      PageNo: 1,
      SortOrder: "id",
      activeTab: this.props.festate.activeSubHeader, // Inventory Or Requests
      isAdvanceSearchApplied: this.props.festate
        .isFEInventoryAdvanceSearchApplied,
      searchCriteria: this.props.festate.isFEInventoryAdvanceSearchApplied
        ? this.props.festate.filterData
        : {},
    };
    let response = await FEService.getDataForFEExport(formData);
    if (!_.isEmpty(response.data)) {
      let data = response.data;
      let totalCount = response.totalCount;
      this.setState({ exportData: data });
      this.setState({ totalExportRowsCount: totalCount });
    }
  };
  resolveAndDownloadBlob = (response) => {
    let filename = `${Date.now()}_EquipmentsList.pdf`;
    filename = decodeURI(filename);
    const url = window.URL.createObjectURL(
      new Blob([response], {
        type: "application/pdf",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  };
  setActiveTab = (k) => {
    this.setState({ activeTabKey: k });
  };
  render() {
    let afSelectedYears = this.props.festate.filterData;
    if (!_.isEmpty(afSelectedYears)) {
      //let searchType=
    }
    let showExclamationMark =
      this.props.festate.accountingReviewRows &&
      this.props.festate.accountingReviewRows.length > 0;
    return (
      <>
        <FEHeader />
        <div className="fet-container container-fluid equipmentTbls">
          <React.Fragment>
            <br />
            <div className="row feequip-tags feTagsScroll">
              {this.props.festate.isFEInventoryAdvanceSearchApplied && (
                <Tags
                  isInventory={true}
                  RefreshResult={true}
                  {...this.props}
                  // onChangeTable={this.onAdvancedChangeTable.bind(this)}
                  // onResetTable={this.onResetTable.bind(this)}
                />
              )}
            </div>
            <div className="align-items-center d-flex">
              <div className="col-xs-8 col-sm-8">
                {(AuthService.canView(PermissionFeature.FEAccounting) ||
                  AuthService.canView(PermissionFeature.FEAdmin)) && (
                  <h5 className="page-title-search feheaderTitle">
                    Equipment List{" "}
                    {/* <i
                      className="fab fa-searchengin pl-2 cursorPointer"
                      onClick={() => this.openFEAdvanceFilter()}
                    ></i> */}
                    <button
                      type="button"
                      className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2"
                      onClick={() => {
                        this.props.toggleFEAdvanceFilter(true);
                      }}
                    >
                      <i class="cursorPointer pr-1 fab fa-searchengin" />
                      Filter
                    </button>
                  </h5>
                )}
              </div>
              <div className="col-xs-4 col-sm-4">
                <div className="cursorPointer text-right font-weight-bold small mt-2 textlink">
                  {/* {(AuthService.canView(PermissionFeature.FEAccounting) ||
                    AuthService.canView(PermissionFeature.FEAdmin)) && (
                    <a onClick={this.toggleClosedRequests}>
                      {this.state.includeClosedTitle}
                    </a>
                  )} */}
                  {/* Show History */}
                  <div className="row">
                    <div className="col-12">
                      <i
                        title="Export to csv"
                        className="fas fa-file-csv pull-right float-right ml-1 fa-2x"
                        onClick={this.onClickCSVExport}
                      />
                      <i
                        title="Export to pdf"
                        className="fas fa-file-pdf pl-1 pull-right float-right fa-2x"
                        onClick={this.onClickPDFExport}
                      />

                      <i
                        className="filterIconSize pull-right float-right fa fa-filter fa-2x ml-1"
                        onClick={this.toggleListFilters}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row align-items-center">
              {(AuthService.canView(PermissionFeature.FEAccounting) ||
                AuthService.canView(
                  PermissionFeature.FETrackerEquipmentLists
                )) && (
                <div className="col-sm-4">
                  <h4 className="page-title-search">Equipments List</h4>
                </div>
              )}
            </div> */}
            {this.state.showListFilters && <ListFilters />}
            <Tabs
              defaultActiveKey="equipment"
              id="uncontrolled-tab-example"
              className="mb-3"
              activeKey={this.state.activeTabKey}
              onSelect={(k) => this.setActiveTab(k)}
            >
              <Tab
                eventKey="equipment"
                title={
                  <>
                    <span className="font-weight-bold"> Equipment</span>
                  </>
                }
              >
                {/* Non Acc review Equipment Lists */}
                {AuthService.canView(PermissionFeature.FEAdmin) && (
                  <div className="col-12 equipmentTable">
                    <Card className="mb-1">
                      <h6 className="card-header bg-info text-white mb-3 mt-0 cursorPointer">
                        <i
                          className="fas fa-dolly-flatbed pr-1"
                          aria-hidden="true"
                        />
                        {/* Equipment{" "} */}
                        <i
                          className="pull-right float-right fa fa-refresh c-pointer"
                          aria-hidden="true"
                          title="Refresh Filters"
                          onClick={() => {
                            // this.setState(
                            //   {
                            //     isallequipmentslistopen: !this.state
                            //       .isallequipmentslistopen,
                            //   },
                            (async () => {
                              // //Incase advance filter was applied, reload both tables
                              let formData = {};

                              var activeSections = [];
                              let getInventories; //function call for getting Equipments
                              if (
                                this.props.festate
                                  .isFEInventoryAdvanceSearchApplied
                              ) {
                                //If advanced filter applied then get data for both lists Table

                                formData = {
                                  EventType: FE_API_Actions.AdvanceSearch,
                                  PageNo: 1,
                                  PageSize: 25,
                                  SortingOrder: "desc",
                                  SortOrder: "id",
                                  recordsTypeList: [],
                                };
                                getInventories = this.props
                                  .getResultRowsForAdvanceFilter;
                              }
                              // No advanced filter applied
                              else {
                                activeSections.push(
                                  InventoryStatus.NonAccReview
                                );
                                formData = {
                                  recordsTypeList: activeSections,
                                  PageNo: 1,
                                  PageSize: 25,
                                  SortingOrder: "desc",
                                  SortOrder: "id",
                                  showHistory: false,
                                };
                                getInventories = this.props
                                  .searchFeInventoryRecords;
                              }

                              this.props.festate
                                .isFEInventoryAdvanceSearchApplied &&
                                this.state.isallequipmentslistopen &&
                                (await this.props.resetALLFilters());

                              if (this.state.isallequipmentslistopen) {
                                formData = {
                                  ...formData,
                                  ...this.props.festate.filterData,
                                };
                                getInventories(formData);
                              }

                              // this.state.isallequipmentslistopen &&
                              //   this.resetShowHistory();
                            })();
                            //);
                          }}
                        />
                      </h6>

                      <Collapse isOpen={this.state.isallequipmentslistopen}>
                        <div className="col-12">
                          <InventoryListTable
                            key={InventoryStatus.NonAccReview}
                            listInventories={this.props.festate.equipmentRows}
                            page={this.props.festate.equipmentPageNo}
                            sizePerPage={
                              this.props.festate.equipmentSizePerPage
                            }
                            handleTableChange={this.handleEquipmentTableChange}
                            totalSize={this.props.festate.totalEquipmentCount}
                            loading={this.props.festate.isLoadingEquipments}
                            equipmentType={InventoryStatus.NonAccReview}
                            equipmentId={this.props.festate.equipmentId}
                            viewEquipment={this.props.openEquipmentModal}
                            viewFiles={this.props.openFilesModal}
                            disposeEquipment={
                              this.props.openEquipmentDisposeModal
                            }
                            afSelectedYears={afSelectedYears}
                            isAdvanceSearchApplied={
                              this.props.festate
                                .isFEInventoryAdvanceSearchApplied
                            }
                            searchCriteria={this.props.festate.filterData}
                            activeSubHeader={this.props.festate.activeSubHeader}
                            showHistory={this.state.includeClosed}
                            deleteEquipment={
                              this.props.openEquipmentDeleteModal
                            }
                            onDataSizeChange={this.handleDataChangeActiveEquip}
                            selectedFilterYear={this.getSelectedFilterYear()}
                            //searchFilterCount={this.getSelectedFilterCount()}
                          />
                        </div>
                      </Collapse>
                      {/* Component for getting HTML of Export */}
                      <AllExpandedInventories
                        key={InventoryStatus.NonAccReview}
                        listInventories={this.state.exportData}
                        rowKeys={this.state.exportData.map(
                          (m) => m.equipmentid
                        )}
                        page={1}
                        sizePerPage={this.state.totalExportRowsCount}
                        handleTableChange={this.handleEquipmentTableChange}
                        totalSize={this.state.totalExportRowsCount}
                        loading={this.props.festate.isLoadingEquipments}
                        equipmentType={
                          this.state.activeTabKey == "equipment"
                            ? InventoryStatus.NonAccReview
                            : InventoryStatus.InAccReview
                        }
                        equipmentId={this.props.festate.equipmentId}
                        //viewEquipment={this.props.openEquipmentModal}
                        //viewFiles={this.props.openFilesModal}
                        disposeEquipment={this.props.openEquipmentDisposeModal}
                        afSelectedYears={afSelectedYears}
                        isAdvanceSearchApplied={
                          this.props.festate.isFEInventoryAdvanceSearchApplied
                        }
                        searchCriteria={this.props.festate.filterData}
                        activeSubHeader={this.props.festate.activeSubHeader}
                        showHistory={this.state.includeClosed}
                        //deleteEquipment={this.props.openEquipmentDeleteModal}
                        onDataSizeChange={this.handleDataChangeActiveEquip}
                        selectedFilterYear={this.getSelectedFilterYear()}
                        //searchFilterCount={this.getSelectedFilterCount()}
                        ref={this.allExpandedInventories}
                      />
                    </Card>
                  </div>
                )}
              </Tab>
              <Tab
                eventKey="accountingReview"
                title={
                  <>
                    <span className="font-weight-bold">Accounting Review </span>{" "}
                    {showExclamationMark && (
                      <i class="fas fa-exclamation-triangle fa-sm  reviewIcon" />
                    )}
                  </>
                }
              >
                {/* <div className="row reviewParentDiv"> */}
                {AuthService.canView(PermissionFeature.FEAccounting) && (
                  <div className="col-12">
                    <Card className="mb-1">
                      <h6 className="card-header bg-primary text-white mb-3 mt-0 cursorPointer">
                        <i
                          className="fa fa-clock-o pr-1 reviewIconSize"
                          aria-hidden="true"
                        />

                        {/*Accounting Review{" "}*/}

                        <i
                          className="pull-right float-right fa fa-refresh c-pointer"
                          aria-hidden="true"
                          title="Refresh Filters"
                          onClick={() => {
                            // this.setState(
                            //   {
                            //     isaccreviewequipmentslistopen: !this.state
                            //       .isaccreviewequipmentslistopen,
                            //   },
                            (async () => {
                              // //Incase advance filter was applied, reload both tables
                              let formData = {};
                              let getInventories; //function call for getting Equipments
                              var activeSections = [];
                              if (
                                this.props.festate
                                  .isFEInventoryAdvanceSearchApplied
                              ) {
                                formData = {
                                  EventType: FE_API_Actions.AdvanceSearch,
                                  recordsTypeList: [],
                                  PageNo: 1,
                                  PageSize: 25,
                                  SortOrder: "id",
                                  SortingOrder: "desc",
                                  showHistory: false,
                                };
                                getInventories = this.props
                                  .getResultRowsForAdvanceFilter;
                              } else {
                                activeSections.push(
                                  InventoryStatus.InAccReview
                                );

                                formData = {
                                  recordsTypeList: activeSections,
                                  PageNo: 1,
                                  PageSize: 25,
                                  SortOrder: "id",
                                  SortingOrder: "desc",
                                  showHistory: false,
                                };
                                getInventories = this.props
                                  .getResultRowsForAdvanceFilter;
                              }

                              /// Old Code
                              this.props.festate
                                .isFEInventoryAdvanceSearchApplied &&
                                this.state.isaccreviewequipmentslistopen &&
                                (await this.props.resetALLFilters());
                              if (this.state.isaccreviewequipmentslistopen) {
                                formData = {
                                  ...formData,
                                  ...this.props.festate.filterData,
                                };
                                getInventories(formData);
                              }
                              //  this.resetShowHistory();
                            })();
                            //  );
                          }}
                        />
                      </h6>

                      <Collapse
                        isOpen={this.state.isaccreviewequipmentslistopen}
                      >
                        <div className="col-12">
                          <InventoryListTable
                            listInventories={
                              this.props.festate.accountingReviewRows
                            }
                            page={this.props.festate.accountingReviewPageNo}
                            sizePerPage={
                              this.props.festate.accountingReviewSizePerPage
                            }
                            handleTableChange={this.handleAccReviewTableChange}
                            //expandRow={this.expandRowErrors}
                            totalSize={
                              this.props.festate.totalAccountingReviewCount
                            }
                            loading={
                              this.props.festate.isLoadingAccountingReviewList
                            }
                            key={InventoryStatus.InAccReview}
                            equipmentType={InventoryStatus.InAccReview}
                            equipmentId={this.props.festate.equipmentId}
                            viewEquipment={this.props.openEquipmentModal}
                            viewFiles={this.props.openFilesModal}
                            disposeEquipment={
                              this.props.openEquipmentDisposeModal
                            }
                            isAdvanceSearchApplied={
                              this.props.festate
                                .isFEInventoryAdvanceSearchApplied
                            }
                            searchCriteria={this.props.festate.filterData}
                            activeSubHeader={this.props.festate.activeSubHeader}
                            showHistory={this.state.includeClosed}
                            deleteEquipment={
                              this.props.openEquipmentDeleteModal
                            }
                            onDataSizeChange={
                              this.handleDataChangeInAccReviewEquip
                            }
                            selectedFilterYear={this.getSelectedFilterYear()}
                            //searchFilterCount ={this.getSelectedFilterCount()}
                          />
                        </div>
                      </Collapse>
                    </Card>
                  </div>
                )}
                {/* </div> */}
              </Tab>
            </Tabs>
          </React.Fragment>
        </div>
        {!AuthService.canView(PermissionFeature.FEAccounting) &&
          !AuthService.canView(PermissionFeature.FEAdmin) && (
            <>
              <div className="container h-100">
                <div className="row NoDataFoundTable">
                  <h4>We are Sorry...</h4>
                </div>
                <div className="row NoDataFoundTable">
                  <h5>
                    You are not authorized to view this content. Please contact
                    administrator.
                  </h5>
                </div>
              </div>
            </>
          )}
        <FE_Files
          // facilityId={this.props.facilityId}
          modal={this.props.festate.openEquipmentFilesModal}
          // activeTabView={this.activeTabView}
          data={this.props.festate.currentEquipmentFiles}
          toggle={this.props.closeFilesModal}
          deleteFile={this.props.deleteFile}
          currentEqUserAttachments={this.props.festate.currentEqUserAttachments}
        />
        <FE_EquipmentDisposeModal
          showModal={this.props.festate.openEquipmentDisposeModal}
          onClose={this.props.closeEquipmentDisposeModal}
          disposeEqId={this.props.festate.disposeEquipmentId}
          refreshInvList={this.props.searchFeInventoryRecords}
        />

        <DeleteEquipmentModal
          id={"DeleteEquipment"}
          content={"Equipment"}
          closeModal={this.props.closeEquipmentDeleteModal}
          openModal={this.props.festate.openEquipmentDeleteModal}
          equipmentDelId={this.props.festate.deleteEquipmentId}
          refreshInvList={this.props.searchFeInventoryRecords}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    festate: state.fetracker,
  }),
  {
    ...FETrackerActions,
  }
)(Inventory);
