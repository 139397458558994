import React, { Component } from "react";
import Header from "../../AdminHeader";
import { RegistrationTableTypes } from "../../Helpers/LookUpValues";
import RegistrationsListTable from "../../User/ListTables/RegistrationsListTable";
import RegistrationsService from "../../RegistrationsService";
import _ from "lodash";
import ReviewRegistrationRequestsModal from "./ModalPopups/ReviewRegistrationRequestsModal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RegistrationsAction from "../../../../store/registrations/action";
import MailGunEmailActions from "../../../../store/mailgunEmails/action";
import EventAttendanceModal from "./ModalPopups/EventAttendanceModal";

export class Registration_RequestsMainPage extends Component {
  state = {
    registrations: [],
    isLoading: false,
    clickedChildTemplateRow: {},
    showReviewRequestsModal: false,
    showAttendanceModal: false,
  };
  async componentDidMount() {
    const { adminRegisRecords } = this.props.registrationsState;
    if (_.isEmpty(adminRegisRecords)) {
      await this.reloadRegistrations();
    }
    this.showEmailConfigModal();
  }
  showEmailConfigModal = () => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const filterId = searchParams.get("showEmailConfigModal");
    // Check if the query parameter showEmailConfig is true
    if (filterId) {
      let { adminRegisRecords = [] } = this.props.registrationsState;

      let row = adminRegisRecords.find((f) => f.id == filterId);
      if (row) {
        this.openRegistrationForApproval(row);
      }
    }
  };
  reloadRegistrations = async () => {
    await this.props.getAdminRegisRecords();
  };
  ///To Open Review Request Modal
  openRegistrationForApproval = (registrationRow) => {
    this.toggleReg_Review_RequestsModal(registrationRow);
  };
  openAttendanceModal = (registrationRow) => {
    this.toggleAttendance_Modal(registrationRow);
  };
  toggleReg_Review_RequestsModal = (registrationRow) => {
    this.setState({
      showReviewRequestsModal: !this.state.showReviewRequestsModal,
      clickedChildTemplateId: registrationRow.id,
      clickedChildTemplateRow: registrationRow,
    });
  };
  toggleAttendance_Modal = (registrationRow) => {
    this.setState({
      showAttendanceModal: !this.state.showAttendanceModal,
      clickedChildTemplateId: registrationRow.id,
      clickedChildTemplateRow: registrationRow,
    });
  };
  onClose_RR = () => {
    this.setState({
      showReviewRequestsModal: false,
    });
  };
  onClose_AM = () => {
    this.setState({
      showAttendanceModal: false,
    });
  };
  render() {
    return (
      <div>
        <Header />

        <div className="fet-container container-fluid">
          <br />
          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">Review Requests</h5>
            <div className="card-body pb-2 px-0 pt-0">
              <RegistrationsListTable
                listData={this.props.registrationsState.adminRegisRecords || []}
                reloadRegistrations={this.reloadRegistrations}
                isloading={this.state.isloading}
                regTableType={RegistrationTableTypes.ReviewRequests}
                openRegistrationForApproval={this.openRegistrationForApproval}
                openAttendanceModal={this.openAttendanceModal}
              />
            </div>
          </div>
        </div>

        {/* //////////// Modal Popups//////////// */}
        <ReviewRegistrationRequestsModal
          showModal={this.state.showReviewRequestsModal}
          onClose={this.onClose_RR}
          toggleModal={this.props.toggleReg_Review_RequestsModal}
          //toggleConfirmModal={this.toggleConfirmModal}
          clickedChildTemplateId={this.state.clickedChildTemplateId}
          clickedChildTemplateRow={this.state.clickedChildTemplateRow}
        />
        <EventAttendanceModal
          showModal={this.state.showAttendanceModal}
          onClose={this.onClose_AM}
          toggleModal={this.props.toggleAttendance_Modal}
          //toggleConfirmModal={this.toggleConfirmModal}
          clickedChildTemplateId={this.state.clickedChildTemplateId}
          clickedChildTemplateRow={this.state.clickedChildTemplateRow}
        />
      </div>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
      melstate: state.mailGunEmail,
    }),
    {
      ...RegistrationsAction,
      ...MailGunEmailActions,
    }
  )(Registration_RequestsMainPage)
);
