import React, { Component, Fragment } from "react";
import AuthorizedSendersModal from "../ModalPopups/AuthorizedSendersModal";
import MG_ConfirmationModal from "../ModalPopups/MG_ConfirmationModal";
import MG_EditEmailAliasModal from "../ModalPopups/MG_EditEmailAliasModal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import EmailListMemType from "../Enums/EmailListMemType";
import MailGunMembersList from "../../Shared/MailGunMembersList";
import mailGunEmailActions from "../../../store/mailgunEmails/action";
import savedSearchAction from "../../../store/saveSearch/action";

import { connect } from "react-redux";

import { Card as BootStrapCard } from "react-bootstrap";
import { Card, CardBody, Collapse } from "reactstrap";

import _ from "lodash";

import { Form } from "react-bootstrap";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import EmailConfigTeamsTable from "../TableData/EmailConfigTeamsTable";
import EmailConfigMemberTable from "../TableData/EmailConfigMemberTable";
import ConfigDataType from "../Enums/ConfigDataType";

import EmailService from "../EmailListService";
import MG_DeleteAllAuthSenders from "../ModalPopups/MG_DeleteAllAuthSenders";
import EmailConfigTables from "../Enums/EmailConfigTables";
import EmailConfigTabs from "../Enums/EmailConfigTabs";
import AddRecptType from "../Enums/AddRecptType";
import MG_Add_NonNatca from "../ModalPopups/MG_Add_NonNatca";
import MailGunEmailTypes from "../Enums/MailGunEmailTypes";
import MG_DeleteEmailList from "../ModalPopups/MG_DeleteEmailList";
import PermissionFeature from "../../Permissions/PermissionFeature";
import AuthService from "../../Auth/AuthService";
import RemoveActionType from "../Enums/RemoveActionType";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import chistory from "../../Shared/auth-header";
import EmailActivityLogging from "../EmailActivityLogging";
import actionActivity from "../../../store/activitylogging/action";
import AuditModuleTypes from "../../Shared/AuditModuleTypes";
import MG_ReSubscriberModal from "../ModalPopups/MG_ReSubscriberModal";
import ManageSubscribersModal from "../../AdvanceSearch/SavedSearches/Listing/ManageSubscribersModal";
import { Modal } from "react-bootstrap";
import { Spinner } from "reactstrap";
import FilterEmailConfigTabs from "../Enums/FilterEmailConfigTabs";

export class MailGunConfigurationTabs extends Component {
  constructor(props) {
    super(props);
    //this.unsubscribersListRef = React.createRef();
    this.nonNatcaUnsubscribersListRef = React.createRef();
    this.additionalTeamUnsubscribersListRef = React.createRef();
    this.subscribersListRef = React.createRef();
    this.state = {
      isOpenAddNonNatcaModal: false,
      key:
        this.props.showTwoTabs && this.props.emailListExists
          ? EmailConfigTabs.Subscribers
          : EmailConfigTabs.Settings,
      showEmailAliasModal: false,
      showEmailDeleteAllModal: false,
      showDeleteEmailListModal: false,
      userFirstName: "",
      userLastName: "",
      fullName: "",
      isConfirmModalOpen: false,

      emailPriviliges: [],
      selectedPrivilige: "readonly",
      reply_preference: "list",
      deleteEmailConfigRecordId: 0,

      teamModalOpended: false,
      memberModalOpended: false,
      deleteAuthSenderMemberId: 0,
      deleteAuthSenderTeamId: 0,
      removeActionType: 0,
      confirmModelType: 0,
      totalSyncMembers: 0,
      totalDupSyncMembers: 0,
      showAddtlMembers: true,

      //Resubscribe Work
      resubscribeEmail: "",
      showReSubscribeModal: false,
      unSubTeam: "",
      unSubTeamId: 0,
      teamMembers: [],
      teamMapId: 0,
      currentPage: 1,
      cctosender: false,
      emailPriviligesCalled: false, //Used for Add mode
      nestedTabKey: "emailDetails",
      isAdminAccessLevelChanged: false,
    };
    this.props.setCurrentEmailConfigTab(
      this.props.showTwoTabs && this.props.emailListExists
        ? EmailConfigTabs.Subscribers
        : EmailConfigTabs.Settings
    );
    // For Unsubscribe or Remove  Member
    this.confirmDeleteMember = () => {
      this.confirmed = async () => {
        ///Primary key of table is not 0
        let response = null;
        const { deleteEmailConfigType, deleteEmailConfigRecordId } = this.state;
        const { currentEmailConfigTab } = this.props.melstate;
        if (deleteEmailConfigRecordId !== 0) {
          if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
            // Means here we are dealing with *************Additional********** Subscribers => Members + Non Nacta
            if (deleteEmailConfigType == ConfigDataType.NonNatca) {
              let { removeActionType } = this.state;
              let {
                mgListAddress,
                additionalRecipientsNonNatca,
                emailEntityType,
              } = this.props.melstate;
              let currentNonNatcaMember = additionalRecipientsNonNatca.find(
                (f) => f.address == this.state.nonNatcaMemberAddress
              );
              let apiRequestBody = {
                recipientType: AddRecptType.NonNatca,
                memberEmailAddress: currentNonNatcaMember.address,
                entityId: null,
                mailingListFullAddress: mgListAddress,
                teamMembers: null,
                removeActionType,
                emailEntityType,
                memberName: currentNonNatcaMember.name,
              };
              response = await EmailService.RemoveAddlRecipient(apiRequestBody);
              if (
                removeActionType == RemoveActionType.UnSubscribe &&
                response != null
              ) {
                // const nonNatcaUnsubscribersList = this
                //   .nonNatcaUnsubscribersListRef.current;
                // nonNatcaUnsubscribersList.resetData();
                // nonNatcaUnsubscribersList.reload();
                this.props.updateRecipientSubBlockFlag(
                  currentNonNatcaMember.address,
                  EmailConfigTables.NonNatcaMembers,
                  false,
                  null, //Block
                  false //IsBlockUnBlock
                );
              } else if (
                removeActionType == RemoveActionType.Block &&
                response != null
              ) {
                this.props.updateRecipientSubBlockFlag(
                  currentNonNatcaMember.address,
                  EmailConfigTables.NonNatcaMembers,
                  null,
                  true, //Block
                  true //IsBlockUnBlock
                );
              } else if (
                removeActionType == RemoveActionType.Delete &&
                response != null
              ) {
                this.props.removeSelectedNonNatcaMember(
                  currentNonNatcaMember.address
                );
              }
            }
            //NewChanges *********************Default Members***********************
            else if (deleteEmailConfigType == ConfigDataType.DefaultMember) {
              let {
                mgListAddress,
                defaultRecipientMembers,
                additionalRecipientsMembers,
                emailEntityType,
              } = this.props.melstate;
              let { removeActionType } = this.state;

              let currentMember = defaultRecipientMembers.find(
                (f) => f.memberId == deleteEmailConfigRecordId
              );
              let apiRequestBody = {
                recipientType: AddRecptType.DefaultMember,
                //isDefaultMember: true, should do this after branch 949 Merging
                memberEmailAddress: currentMember.address, /// member email address
                entityId: deleteEmailConfigRecordId, /// Member id to be deleted
                mailingListFullAddress: mgListAddress,
                teamMembers: null,
                removeActionType,
                emailEntityType,
                memberName: currentMember.name,
              };
              response = await EmailService.RemoveAddlRecipient(apiRequestBody);
              if (
                (removeActionType == RemoveActionType.UnSubscribe ||
                  removeActionType == RemoveActionType.Delete ||
                  removeActionType == RemoveActionType.Block) &&
                response != null
              ) {
                // const unsubscribersList = this.unsubscribersListRef.current;
                // unsubscribersList.resetData();
                // unsubscribersList.reload();
                // const subscribersList = this.subscribersListRef.current;
                // subscribersList.resetData();
                // subscribersList.reload();
                if (removeActionType == RemoveActionType.Delete) {
                  this.props.deleteDefaultRecipientMember(
                    currentMember.address
                  );
                } else {
                  this.props.updateRecipientSubBlockFlag(
                    currentMember.address,
                    EmailConfigTables.DefaultMembers,
                    false,
                    true, //Block
                    removeActionType == RemoveActionType.Block //IsBlockUnBlock Call
                  );
                }
              }
              //NewChanges END*********************Default Members***********************
            } else {
              // Means Additional Members + Non Natca
              let {
                mgListAddress,
                additionalRecipientsMembers,
              } = this.props.melstate;
              let { removeActionType } = this.state;

              let currentMember = additionalRecipientsMembers.find(
                (f) => f.memberId == this.state.deleteEmailConfigRecordId
              );
              let { emailEntityType } = this.props.melstate;
              let apiRequestBody = {
                recipientType: AddRecptType.Member,
                memberEmailAddress: currentMember.address, /// member email address
                entityId: this.state.deleteEmailConfigRecordId, /// Member id to be deleted
                mailingListFullAddress: mgListAddress,
                memberName: currentMember.name, //Memner Name
                teamMembers: null,
                removeActionType,
                emailEntityType,
              };
              response = await EmailService.RemoveAddlRecipient(apiRequestBody);
              // Not null means success
              if (response != null) {
                // const unsubscribersList = this.unsubscribersListRef.current;
                // unsubscribersList.resetData();
                // unsubscribersList.reload();
                const { confirmModelType } = this.state;
                if (
                  confirmModelType ==
                  EmailConfigTables.AdditionalRecipientsMember
                ) {
                  if (removeActionType == RemoveActionType.UnSubscribe) {
                    this.props.updateRecipientSubBlockFlag(
                      currentMember.address,
                      EmailConfigTables.AdditionalRecipientsMember,
                      false,
                      null, //Block
                      false //IsBlockUnBlock
                    );
                  }
                  if (removeActionType == RemoveActionType.Block) {
                    this.props.updateRecipientSubBlockFlag(
                      currentMember.address,
                      EmailConfigTables.AdditionalRecipientsMember,
                      false,
                      true, //Block
                      true //IsBlockUnBlock
                    );
                  }
                  if (removeActionType == RemoveActionType.Delete) {
                    this.props.removeSelectedEntity(
                      apiRequestBody.entityId,
                      ConfigDataType.Member ///Additional Member
                    );
                    this.deSelectMember(apiRequestBody.entityId);
                  }
                }
              }
            }
            if (response != null) {
              this.fetchLogsCardClick();
            }
          }
          //*******************************Subscribers Tab Ends**************************//
          else if (
            this.props.melstate.currentEmailConfigTab ==
            EmailConfigTabs.Unsubscribed
          ) {
            // if (this.state.deleteEmailConfigType == ConfigDataType.NonNatca) {
            //   const nonNatcaUnsubscribersListRef = this
            //     .nonNatcaUnsubscribersListRef.current;
            //   let { removeActionType, nonNatcaMemberAddress } = this.state;
            //   let currentNonNatcaMember = nonNatcaUnsubscribersListRef.state.nonNatcaUnSubscribers.find(
            //     (f) => f.address == nonNatcaMemberAddress
            //   );
            //   let apiRequestBody = {
            //     recipientType: AddRecptType.NonNatca,
            //     memberEmailAddress: currentNonNatcaMember.address,
            //     entityId: null,
            //     mailingListFullAddress: this.props.melstate.mgListAddress,
            //     teamMembers: null,
            //     removeActionType,
            //   };
            //   response = await EmailService.RemoveAddlRecipient(apiRequestBody);
            //   if (
            //     removeActionType == RemoveActionType.UnSubscribe &&
            //     response != null
            //   ) {
            //     nonNatcaUnsubscribersListRef.resetData();
            //     nonNatcaUnsubscribersListRef.reload();
            //   }
            // }
          } else if (
            this.props.melstate.currentEmailConfigTab ==
            EmailConfigTabs.Settings
            //Incase of Edit Filter modal the TAB will be "Email-List" but Redux state's Email config modal will have Settings value
          ) {
            let { emailEntityType } = this.props.melstate;
            response = await EmailService.RemoveAuthSender(
              this.state.deleteEmailConfigRecordId,
              emailEntityType
            );
            if (response != null) {
              this.props.removeSelectedEntity(
                this.state.deleteAuthSenderMemberId,
                ConfigDataType.Member
              );
              this.fetchLogsCardClick();
            }
          }
        }

        /////////########END of Logic for DB Generated Removal Record###########/////////
        if (response != null) {
          // if (
          //   this.props.melstate.currentEmailConfigTab ==
          //     EmailConfigTabs.Subscribers &&
          //   this.state.deleteEmailConfigType == ConfigDataType.NonNatca
          // ) {
          //   this.props.removeSelectedNonNatcaMember(
          //     this.state.nonNatcaMemberAddress
          //   );
          // } else if (
          //   this.props.melstate.currentEmailConfigTab ==
          //     EmailConfigTabs.Unsubscribed &&
          //   this.state.deleteEmailConfigType == ConfigDataType.NonNatca
          // ) {
          //   let { nonNatcaMemberAddress } = this.state;
          //   const nonNatcaUnsubscribersListRef = this
          //     .nonNatcaUnsubscribersListRef.current;
          //   let afterRemoval = nonNatcaUnsubscribersListRef.state.nonNatcaUnSubscribers.filter(
          //     (f) => f.address != nonNatcaMemberAddress
          //   );
          //   nonNatcaUnsubscribersListRef.setState({
          //     nonNatcaUnSubscribers: afterRemoval,
          //   });
        } else {
          //For Removng Additional Members in Subscribers Tab
          // this.deSelectMember(this.state.deleteAuthSenderMemberId); /// entity key id
          // this.props.removeSelectedEntity(
          //   this.state.deleteAuthSenderMemberId,
          //   ConfigDataType.Member
          // );
        }
        //}
        ///########################Removed Record from UI(Client State)#############
        this.toggleConfirmDeleteModal();
      };
      return this;
    };

    this.confirmDeleteTeam = () => {
      this.confirmed = async () => {
        const { deleteEmailConfigRecordId } = this.state;
        let response = null;
        if (deleteEmailConfigRecordId !== 0) {
          if (
            this.props.melstate.currentEmailConfigTab ==
            EmailConfigTabs.Subscribers
          ) {
            //Remove Additional *Team* Subscriber from Mailing List
            let {
              mgListAddress,
              additionalRecipientsTeams,
            } = this.props.melstate;
            let { removeActionType } = this.state;
            let currentTeam = additionalRecipientsTeams.find(
              (f) => f.id == deleteEmailConfigRecordId
            );
            let { emailEntityType } = this.props.melstate;
            let apiRequestBody = {
              recipientType: AddRecptType.Team,
              memberEmailAddress: null,
              entityId: deleteEmailConfigRecordId, /// Can be Team id, now 'mail_gun_addtl_subs_teams's PrimaryKey.
              mailingListFullAddress: mgListAddress,
              teamMembers: currentTeam.members,
              removeActionType,
              emailEntityType,
              teamName: currentTeam.teamName || "",
            };
            response = await EmailService.RemoveAddlRecipient(apiRequestBody);
            if (
              removeActionType == RemoveActionType.UnSubscribe &&
              response != null
            ) {
              // const additionalTeamUnsubscribersListRef = this
              //   .additionalTeamUnsubscribersListRef.current;
              // additionalTeamUnsubscribersListRef.resetData();
              // additionalTeamUnsubscribersListRef.reload();
              const { confirmModelType } = this.state;
              if (
                confirmModelType == EmailConfigTables.AdditionalRecipientsTeam
              ) {
                this.props.reloadAdditionalSubsriberTeams();
              }
            }
          }
          //#################  END of Delete Work for (Team Additional **Subscribers***) Tab data ###################
          else if (
            this.props.melstate.currentEmailConfigTab ==
            EmailConfigTabs.Settings
          ) {
            let { emailEntityType } = this.props.melstate;
            response = await EmailService.RemoveAuthSender(
              deleteEmailConfigRecordId,
              emailEntityType
            );
          }

          //Removing Additional Unsubscribed Team Members
          else if (
            this.props.melstate.currentEmailConfigTab ==
            EmailConfigTabs.Unsubscribed
          ) {
            // const additionalTeamUnsubscribersListRef = this
            //   .additionalTeamUnsubscribersListRef.current;
            // let { removeActionType } = this.state;
            // let currentTeam = additionalTeamUnsubscribersListRef.state.additionalTeamUnSubscribers.find(
            //   (f) => f.id == deleteEmailConfigRecordId
            // );
            // let { emailEntityType } = this.props.melstate;
            // let apiRequestBody = {
            //   recipientType: AddRecptType.Team,
            //   memberEmailAddress: null,
            //   entityId: deleteEmailConfigRecordId, /// Can be Team id, now 'mail_gun_addtl_subs_teams's PrimaryKey.
            //   /// Member id to be deleted
            //   mailingListFullAddress: this.props.melstate.mgListAddress,
            //   teamMembers: currentTeam.members,
            //   removeActionType,
            //   emailEntityType,
            // };
            // response = await EmailService.RemoveAddlRecipient(apiRequestBody);
            // if (response != null) {
            //   additionalTeamUnsubscribersListRef.resetData();
            //   additionalTeamUnsubscribersListRef.reload();
            // }
          }
        } // Means new Team, delete it from client side??????
        if (
          response != null &&
          (this.props.melstate.currentEmailConfigTab ==
            EmailConfigTabs.Settings ||
            this.props.melstate.currentEmailConfigTab ==
              EmailConfigTabs.Subscribers)
        ) {
          //Could be Subscriber's Tab Team or Settings's Tab Authorized Team
          this.deSelectTeam(this.state.deleteAuthSenderTeamId);
          this.props.removeSelectedEntity(
            this.state.deleteAuthSenderTeamId,
            ConfigDataType.Team
          );
        }
        if (
          response != null &&
          this.props.melstate.currentEmailConfigTab ==
            EmailConfigTabs.Subscribers
        ) {
          //Subscribers Tab Team
          this.props.reloadAdditionalSubsriberTeams();
        }
        if (response != null) {
          this.fetchLogsCardClick();
        }
        ///########################Removed Record from UI(Client State)#############

        this.toggleConfirmDeleteModal();
      };
      return this;
    };
    this.confirmDeleteAll = () => {
      this.confirmed = () => {
        const { currentEmailConfigTab } = this.props.melstate;
        if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          let {
            additionalRecipientsMembers,
            additionalRecipientsTeams,
            additionalRecipientsNonNatca,
            emailListId,
            emailEntityType,
          } = this.props.melstate;
          let emailListAdditionalRecpts = [
            ...additionalRecipientsMembers,
            ...additionalRecipientsTeams,
            ...additionalRecipientsNonNatca,
          ];
          this.props.deleteAllAddlListClick(
            emailListId,
            emailEntityType,
            emailListAdditionalRecpts,
            this.fetchLogsCardClick
          );
        } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
          const { isAdminAccessLevelChanged, selectedPrivilige } = this.state;
          let {
            authorizedSendersMembers,
            authorizedSendersTeams,
            emailListId,
            emailEntityType,
          } = this.props.melstate;
          let emailAuthorizedSenders = [
            ...authorizedSendersMembers,
            ...authorizedSendersTeams,
          ];
          let privilege = selectedPrivilige == "members" ? "Members" : "Anyone";
          this.props.deleteAllAuthorizedListClick(
            emailListId,
            emailEntityType,
            privilege,
            isAdminAccessLevelChanged,
            emailAuthorizedSenders,
            this.fetchLogsCardClick
          );
        }
        this.toggleDeleteAllModal();
      };

      return this;
    };
    this.confirmEmailList = () => {
      this.confirmed = async () => {
        let { emailListId, emailEntityType } = this.props.melstate;
        let deleteResponse = null;
        if (emailEntityType == MailGunEmailTypes.Member) {
          await EmailService.DeleteSSEmailList(emailListId).then(
            ({ data: response }) => {
              $(".loading").hide();
              if (response.status === "success") {
                toast.success("Email list removed successfully!");
                deleteResponse = 1;
                $(".loading").hide();
              } else {
                toast.error(response.message);
                deleteResponse = 0;
                $(".loading").hide();
              }
            }
          );
        } else if (emailEntityType !== MailGunEmailTypes.Member) {
          await EmailService.DeleteFRTEmailList(emailListId).then(
            ({ data: response }) => {
              $(".loading").hide();
              if (response.status === "success") {
                toast.success("Email list removed successfully!");
                deleteResponse = 1;
                $(".loading").hide();
              } else {
                toast.error(response.message);
                deleteResponse = 0;
                $(".loading").hide();
              }
            }
          );
        }
        this.toggleDeleteEmailListModal();
        if (deleteResponse == 1) {
          chistory.push("/admin/email-list");
        }
      };

      return this;
    };
    ///Resubscribe+UnBlock Work for Teams(Only Additional)
    this.confirmResubscribersModalForTeams = () => {
      this.confirmed = async () => {
        this.showSpinner();
        const {
          removeActionType,
          confirmModelType,
          unSubTeam,
          unSubTeamId,
          teamMapId,
          teamMembers,
        } = this.state;
        let apiParams = {
          mailingListAddress: this.props.mgListAddress,
          TeamId: unSubTeamId,
          teamMembers,
          teamMapId,
          removeActionType,
        };
        /// Additional Change for Activity Logs////

        const { deleteEmailConfigRecordId } = this.state;
        let { mgListAddress, emailEntityType } = this.props.melstate;
        if (deleteEmailConfigRecordId !== 0) {
          apiParams["DeleteData"] = {
            recipientType: AddRecptType.Team,
            mailingListFullAddress: mgListAddress,
            teamName: unSubTeam,
            removeActionType,
            emailEntityType,
          };
        }
        let response;
        try {
          response = await EmailService.ResubscribeUnBlockTeamRecipient(
            apiParams
          );
        } catch (ex) {
          console.log(ex);
        }

        /////// Activity Logs Worked///////
        if (response != null && response.status.toLowerCase() == "success") {
          // if (confirmModelType == EmailConfigTables.AdditionalRecipientsTeam) {
          //   this.props.reloadAdditionalSubsriberTeams();
          // }
          if (confirmModelType == EmailConfigTables.AdditionalRecipientsTeam) {
            if (removeActionType == RemoveActionType.ReSubscribe) {
              this.props.updateRecipientSubBlockFlag(
                unSubTeamId,
                EmailConfigTables.AdditionalRecipientsTeam,
                true, // <== ReSubscribe
                null, // Block
                false
              );
            } else if (removeActionType == RemoveActionType.UnBlock) {
              this.props.updateRecipientSubBlockFlag(
                unSubTeamId,
                EmailConfigTables.AdditionalRecipientsTeam,
                false,
                false, //UnBlock
                true //IsBlockUnBlock Call
              );
            }
          }
          this.toggleResubscribersModal();
          this.fetchLogsCardClick();
        }
      };

      return this;
    };

    ///The method used for **Members** UnBlock+Resubscribe
    this.confirmResubscribersModal = () => {
      this.confirmed = async () => {
        /// Additional Change for Activity Logs////
        let apiRequestBody = {};
        const {
          confirmModelType,
          deleteEmailConfigRecordId,
          removeActionType,
          resubscribeEmail,
        } = this.state;
        let {
          currentEmailConfigTab,
          defaultRecipientMembers,
          mgListAddress,
          additionalRecipientsNonNatca,
          emailEntityType,
        } = this.props.melstate;
        if (deleteEmailConfigRecordId !== 0) {
          if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
            // Means here we are dealing with *************Additional********** Subscribers =>  Non Nacta +Default+ Additional
            if (confirmModelType == EmailConfigTables.NonNatcaMembers) {
              let currentNonNatcaMember = additionalRecipientsNonNatca.find(
                (f) => f.address == this.state.nonNatcaMemberAddress
              );
              apiRequestBody = {
                recipientType: AddRecptType.NonNatca,
                memberEmailAddress: currentNonNatcaMember.address,
                mailingListFullAddress: mgListAddress,
                removeActionType,
                emailEntityType,
                entityId: null,
                memberName: currentNonNatcaMember.name,
              };
            }
            //NewChanges *********************Default Members***********************
            else if (confirmModelType == EmailConfigTables.DefaultMembers) {
              let currentMember = defaultRecipientMembers.find(
                (f) => f.memberId == deleteEmailConfigRecordId
              );
              apiRequestBody = {
                recipientType: AddRecptType.DefaultMember,
                //isDefaultMember: true, should do this after branch 949 Merging
                memberEmailAddress: currentMember.address, /// member email address
                mailingListFullAddress: mgListAddress,
                teamMembers: null,
                entityId: deleteEmailConfigRecordId,
                removeActionType,
                emailEntityType,
                memberName: currentMember.name,
              };
            } else {
              // Means Additional Members
              let {
                mgListAddress,
                additionalRecipientsMembers,
              } = this.props.melstate;

              let currentMember = additionalRecipientsMembers.find(
                (f) => f.memberId == this.state.deleteEmailConfigRecordId
              );
              let { emailEntityType } = this.props.melstate;
              apiRequestBody = {
                recipientType: AddRecptType.Member,
                memberEmailAddress: currentMember.address, /// member email address
                entityId: this.state.deleteEmailConfigRecordId, /// Member id to be deleted
                mailingListFullAddress: mgListAddress,
                teamMembers: null,
                memberName: currentMember.name,
                removeActionType,
                emailEntityType,
              };
            }
          }
          //*******************************Subscribers Tab Ends**************************//
        }
        /////// Activity Logs Worked///////

        const response = await EmailService.ResubscribeUnBlockRecipient(
          removeActionType,
          resubscribeEmail,
          mgListAddress,
          apiRequestBody
        );
        if (response && response.status.toLowerCase() == "success") {
          const { resubscribeEmail, removeActionType } = this.state;
          const { confirmModelType } = this.state;
          if (confirmModelType == EmailConfigTables.DefaultMembers) {
            if (removeActionType == RemoveActionType.ReSubscribe) {
              this.props.updateRecipientSubBlockFlag(
                resubscribeEmail,
                EmailConfigTables.DefaultMembers,
                true, // <== ReSubscribe
                null, //UnBlock
                false //IsBlockUnBlock Call
              );
            }
            if (removeActionType == RemoveActionType.UnBlock) {
              this.props.updateRecipientSubBlockFlag(
                resubscribeEmail,
                EmailConfigTables.DefaultMembers,
                false,
                false, //UnBlock
                true //IsBlockUnBlock Call
              );
            }
          }
          if (
            confirmModelType == EmailConfigTables.AdditionalRecipientsMember
          ) {
            if (removeActionType == RemoveActionType.ReSubscribe) {
              this.props.updateRecipientSubBlockFlag(
                resubscribeEmail,
                EmailConfigTables.AdditionalRecipientsMember,
                true, // <== ReSubscribe
                null, // Block
                false
              );
            } else if (removeActionType == RemoveActionType.UnBlock) {
              this.props.updateRecipientSubBlockFlag(
                resubscribeEmail,
                EmailConfigTables.AdditionalRecipientsMember,
                false,
                false, //UnBlock
                true //IsBlockUnBlock Call
              );
            }
          }

          if (confirmModelType == EmailConfigTables.NonNatcaMembers) {
            if (removeActionType == RemoveActionType.UnBlock) {
              this.props.updateRecipientSubBlockFlag(
                resubscribeEmail,
                EmailConfigTables.NonNatcaMembers,
                false,
                false, //UnBlock
                true //IsBlockUnBlock Call
              );
            }
            if (removeActionType == RemoveActionType.ReSubscribe) {
              this.props.updateRecipientSubBlockFlag(
                resubscribeEmail,
                EmailConfigTables.NonNatcaMembers,
                true, // <== ReSubscribe
                null,
                false
              );
            }
          } ////***************Actions for NonNatca Members End Here*****************
          this.toggleResubscribersModal();
          this.fetchLogsCardClick();
        } //end of success response block
      };

      return this;
    };
  }
  componentDidUpdate = (prevProps) => {
    const {
      emailListExists,
      emailListId,
      showTwoTabs,
      savedSearchId,
    } = this.props;
    const { emailPriviliges, emailPriviligesCalled } = this.state;
    const { currentFilterConfigTab } = this.props.melstate;
    const canAddListPermission =
      AuthService.canAdd(PermissionFeature.EmailListsLists) ||
      AuthService.canView(PermissionFeature.EmailListAdmin);
    const editCondition =
      emailListId !== 0 &&
      (emailListId !== prevProps.emailListId || _.isEmpty(emailPriviliges)) &&
      (currentFilterConfigTab == FilterEmailConfigTabs.EmailList ||
        showTwoTabs) &&
      emailListExists &&
      !emailPriviligesCalled;
    const addCondition =
      emailListId == 0 &&
      canAddListPermission &&
      !prevProps.emailListId &&
      (currentFilterConfigTab == FilterEmailConfigTabs.EmailList ||
        showTwoTabs) &&
      !emailPriviligesCalled &&
      _.isEmpty(emailPriviliges) &&
      this.props.isCalledAPI != null;
    if (editCondition || addCondition) {
      //if (addCondition) {
      this.setState({ emailPriviligesCalled: true });
      //}

      (async () => {
        await EmailService.GetEmailPriviliges()
          .then(async ({ data: response }) => {
            this.setState(
              {
                emailPriviliges: response,
              },
              () => {
                //debugger;
                //console.log(emailListExists, "check VVVV");
                emailListExists
                  ? this.getSavedAccessLevel()
                  : this.props.setPrivilege("members");
              }
            );
          })
          .catch((ex) => {
            console.log(ex);
            toast.error("Something went wrong!");
          });
      })();

      var address = this.props.mgListAddress;
      if (address && emailListExists) {
        EmailService.GetMailingListMemberCount(address)
          .then(({ data: response }) => {
            if (response.status === "success") {
              this.setState({
                totalSyncMembers: response.data.totalCount,
                totalDupSyncMembers: response.data.dupCount,
              });
            }
          })
          .catch((ex) => {
            console.log(ex);
            toast.error("Something went wrong!");
          });
      }
    }
    //if (emailListExists) {
    //  console.log("HGGG");
    //}
  };
  componentDidMount = async () => {
    const { emailListExists, isCalledAPI } = this.props;
    // if (
    //   (emailListExists ||
    //     AuthService.canAdd(PermissionFeature.EmailListsLists)) &&
    //   isCalledAPI
    // ) {
    //   await EmailService.GetEmailPriviliges()
    //     .then(async ({ data: response }) => {
    //       this.setState({
    //         emailPriviliges: response,
    //       });
    //     })
    //     .catch((ex) => {
    //       console.log(ex);
    //       toast.error("Something went wrong!");
    //     });
    // }
    // let activeTabKey = !this.props.ssUpdateEmailListMode
    //   ? "settings"
    //   : "subscribers";
    // this.setState({
    //   key:
    //     this.props.showTwoTabs && emailListExists ? "subscribers" : "settings",
    // });
    // var address = this.props.mgListAddress;
    // if (address && emailListExists) {
    //   EmailService.GetMailingListMemberCount(address)
    //     .then(({ data: response }) => {
    //       if (response.status === "success") {
    //         this.setState({
    //           totalSyncMembers: response.data.totalCount,
    //           totalDupSyncMembers: response.data.dupCount,
    //         });
    //       }
    //     })
    //     .catch((ex) => {
    //       console.log(ex);
    //       toast.error("Something went wrong!");
    //     });
    // }
  };
  getSavedAccessLevel = async () => {
    //entity key passed from ConfigureMG_Email
    //debugger;
    let { emailEntityType, entityKey } = this.props;
    //debugger;
    if (entityKey)
      if (emailEntityType !== MailGunEmailTypes.Member) {
        if (emailEntityType) {
          let url = `/api/FacilityRegionEmail/GetEmailPrivilege?entityKey=${entityKey}&entityType=${emailEntityType}`;
          await axios.get(url).then(({ data: response }) => {
            if (response.status === "success") {
              this.setState({
                selectedPrivilige: response.access_Level,
                reply_preference: response.reply_preference,
                email_alias: response.email_alias, //`facility_region_email_list`.`email_alias`
                name: response.name, //`facility_region_email_list`.`email_list_title`
              });
              this.props.setReplyPref(response.reply_preference);
              this.props.setCopyToSender(response.copyToSender);
              this.props.setPrivilege(response.access_Level);
              this.props.setEmailListName(response.name);
            }
          });
        }
      } else if (emailEntityType == MailGunEmailTypes.Member) {
        /// Saved Search Email List
        await EmailService.GetSavedSearchDetail(entityKey)
          .then(({ data: response }) => {
            if (response.status === "success") {
              this.setState({
                entityKey: response.searchDetail.saved_search_id,
                mailingListAddress: response.searchDetail.email_address,
                email_list_title: response.searchDetail.email_list_title,
                emailListId: response.searchDetail.id,
                emailEntityType: MailGunEmailTypes.Member,
                reply_preference: response.searchDetail.reply_preference,
                selectedPrivilige: response.selectedPrivilege,
              });
              this.props.setReplyPref(response.searchDetail.reply_preference);
              this.props.setCopyToSender(response.searchDetail.copy_to_sender);
              this.props.setPrivilege(response.selectedPrivilege);
              this.props.setEmailListName(
                response.searchDetail.email_list_title
              );
              /// console.log(this.state.mailingListAddress);
            }
          })
          .catch((ex) => {
            console.log(ex);
            toast.error("Something went wrong!");
          });
      }
  };
  // fetchPrivileges = () => {
  //   axios
  //     .get("/api/SavedSearchEmailList/GetEmailPriviliges")
  //     .then(({ data: res }) => {
  //       this.hideSpinner();
  //       this.setState({
  //         emailPriviliges: res,
  //       });
  //     });
  // };

  onPrivilegeChange = (e) => {
    let { selectedPrivilige } = this.props.melstate;
    let currentValue = e.currentTarget.value;
    this.setState({
      selectedPrivilige: currentValue,
    });
    this.props.setPrivilege(currentValue);
    this.setState({ isAdminAccessLevelChanged: false });
    let alreadyAuthSender =
      this.props.melstate.authorizedSendersRows &&
      this.props.melstate.authorizedSendersRows.length > 0;
    if (alreadyAuthSender && selectedPrivilige == "readonly") {
      this.setState({ isAdminAccessLevelChanged: true });
      this.toggleDeleteAllModal();
    }
  };
  handleChange = (evt) => {
    this.setState({
      [evt.target.id]: evt.target.value,
    });
    if (evt.target.id == "reply_preference") {
      this.props.setReplyPref(evt.target.value);
    }
    if (evt.target.id == "cctosender") {
      this.setState({
        [evt.target.id]: evt.target.checked,
      });
      this.props.setCopyToSender(evt.target.checked);
    }
    if (evt.target.id == "emailAlias") {
      let val = evt.target.value.replace(/[^\w\s-]/gi, "");
      this.props.setMgListAlias(val);
    }
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  setKey = async (k) => {
    this.setState({ key: k });
    await this.props.setCurrentEmailConfigTab(k);
  };
  renderEmailPriviliges = (isDisabled) => {
    const { emailPriviliges } = this.state;
    if (!emailPriviliges) return;
    return (
      <div className="row">
        {emailPriviliges.map((privilege, idx) => {
          return (
            <div class="col-md-3" key={idx}>
              <Form.Check
                custom
                type="radio"
                label={privilege.privilage_name}
                checked={
                  privilege.api_value === this.props.melstate.selectedPrivilige
                }
                id={privilege.api_value}
                name="emailPrivilige[]"
                value={privilege.api_value}
                disabled={!isDisabled}
                onChange={this.onPrivilegeChange}
              />
            </div>
          );
        })}
      </div>
    );
  };
  ///Invoked before Opening "Confirm Delete Member" Modal
  handleConfirmDeleteMember = (id, memberid, type, removeActionType) => {
    if (id !== 0) {
      this.setState(
        {
          isConfirmModalOpen: !this.state.isConfirmModalOpen,
        },
        () => {
          if (type == EmailConfigTables.NonNatcaMembers) {
            this.setState({
              deleteEmailConfigRecordId: memberid,
              nonNatcaMemberAddress: memberid, ///this will be the string address of member
              deleteEmailConfigType: ConfigDataType.NonNatca,
              confirmModelType: type,
              removeActionType,
            });
          } else if (type == EmailConfigTables.DefaultMembers) {
            this.setState({
              deleteEmailConfigRecordId: id, // row.id can be zero in case of newly added record on client
              deleteAuthSenderMemberId: memberid, /// int id of the member
              deleteEmailConfigType: ConfigDataType.DefaultMember,
              confirmModelType: type,
              removeActionType,
            });
          } else {
            this.setState({
              deleteEmailConfigRecordId: id, // row.id can be zero
              deleteAuthSenderMemberId: memberid, /// int id of the member
              deleteEmailConfigType: ConfigDataType.Member,
              confirmModelType: type,
              removeActionType,
            });
          }
        }
      );
    }
    //Just Remove Records from JS not API
    else {
      if (type == EmailConfigTables.NonNatcaMembers) {
        this.props.removeSelectedNonNatcaMember(memberid);
      } else if (type == EmailConfigTables.DefaultMembers) {
        //No applicable to Default Members because we don't add them form FrontEnd
      } else {
        this.props.removeSelectedEntity(memberid, ConfigDataType.Member);
        this.deSelectMember(memberid);
      }
    }
  };
  // Non Natca Member
  // handleConfirmDeleteNonNatcaMember = (id, address) => {
  //   if (id !== 0) {
  //     this.setState(
  //       {
  //         isConfirmModalOpen: !this.state.isConfirmModalOpen,
  //       },
  //       () => {
  //         this.setState({
  //           deleteEmailConfigRecordId: address,
  //           nonNatcaMemberAddress: address,
  //           deleteEmailConfigType: ConfigDataType.NonNatca,
  //         });
  //       }
  //     );
  //   } else {
  //     this.props.removeSelectedNonNatcaMember(address);
  //     //this.deSelectMember(address);
  //   }
  // };
  handleConfirmDeleteTeam = (id, teamid, type, removeActionType) => {
    if (id) {
      this.setState(
        {
          isConfirmModalOpen: !this.state.isConfirmModalOpen,
        },
        () => {
          this.setState({
            deleteEmailConfigRecordId: id,
            deleteAuthSenderTeamId: teamid,
            deleteEmailConfigType: ConfigDataType.Team,
            confirmModelType: type,
            removeActionType,
          });
        }
      );
    } else {
      this.props.removeSelectedEntity(teamid, ConfigDataType.Team);
      this.deSelectTeam(teamid);
    }
  };

  toggleConfirmDeleteModal = (id) => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  setCurrentTeamInfo = (teamName, teamid, removeActionType) => {
    this.setState({
      team: teamName,
      teamId: teamid,
      teamModalOpended: true,
      memberModalOpended: false,
      removeActionType,
    });
  };
  setCurrentSenderInfo = (
    firstname,
    lastname,
    memberid,
    removeActionType,
    name
  ) => {
    this.setState({
      userFirstName: firstname,
      userLastName: lastname,
      memberId: memberid,
      memberModalOpended: true,
      teamModalOpended: false,
      removeActionType,
      fullName: name,
    });
  };
  toggleEmailAliasModal = () => {
    this.setState({
      showEmailAliasModal: !this.state.showEmailAliasModal,
    });
  };
  toggleDeleteAllModal = () => {
    this.setState({
      showEmailDeleteAllModal: !this.state.showEmailDeleteAllModal,
    });
  };
  onCancelClick = () => {
    this.toggleDeleteAllModal();
    this.setState({ selectedPrivilige: "readonly" });
    this.props.setPrivilege("readonly");
  };
  toggleDeleteEmailListModal = () => {
    this.setState({
      showDeleteEmailListModal: !this.state.showDeleteEmailListModal,
    });
  };
  deSelectMember = (id) => {
    this.props.deSelectMember(id);
  };
  selectMember = (selectedMem) => {
    this.props.selectMember(selectedMem);
  };
  deSelectTeam = (id) => {
    this.props.deSelectTeam(id);
  };
  selectTeam = (selectedTeam) => {
    this.props.selectTeam(selectedTeam);
  };
  addAuthTeams = () => {
    this.props.selectMultipleAuthSender(
      this.state.selectedTeams,
      ConfigDataType.Team
    );
    this.handleClose();
  };
  addAuthMembers = () => {
    this.props.selectMultipleAuthSender(ConfigDataType.Member);
    this.handleClose();
  };
  changeHandlerListName = (evt) => {
    this.props.setEmailListName(evt.target.value);
  };
  toggleAddNonNatcaModal = () => {
    this.setState({
      isOpenAddNonNatcaModal: !this.state.isOpenAddNonNatcaModal,
    });
  };
  emailConfigFacilityHeader = (title, entityKey) => {
    return (
      <>
        <Link
          className="emailConfigHeader font-italic pr-1"
          to={{
            pathname: "/directory/facility/view/" + entityKey,
          }}
        >
          {title}
        </Link>
      </>
    );
  };
  emailConfigTeamHeader = (title, entityKey) => {
    return (
      <>
        <Link
          to={`/permissions/teams/teamDetail/${entityKey}`}
          class="emailConfigHeader font-italic pr-1"
        >
          {title}
        </Link>
      </>
    );
  };
  emailConfigHeaderSaveSearch = (title, entityKey) => {
    return (
      <>
        <span class="emailConfigHeader emailConfigHeaderHightLight font-italic pr-1">
          {title}
        </span>
      </>
    );
  };
  emailConfigHeaderArea = (title, entityKey) => {
    return (
      <>
        <span class="emailConfigHeader emailConfigHeaderHightLight font-italic pr-1 ">
          {title}
        </span>
      </>
    );
  };
  emailConfigHeaderRegion = (title, entityKey) => {
    return (
      <>
        <span class="emailConfigHeader emailConfigHeaderHightLight font-italic pr-1">
          {title}
        </span>
      </>
    );
  };
  emailConfigHeaderRegis = (title) => {
    return (
      <>
        <Link
          className="emailConfigHeader font-italic pr-1"
          to={{
            pathname: "/Registrations",
          }}
        >
          {title}
        </Link>
      </>
    );
  };
  fetchLogsCardClick = () => {
    /// ActivityLogging
    //var myself = this;
    // var { Id, isChild, parentId } = useParams;
    const Id = `${this.props.emailListId},${this.props.emailEntityType}`;
    //const { emailLoggingVisible = false } = this.props.activityLoggingState;
    //var show = this.props.activityLoggingState && emailLoggingVisible;
    //console.log("show", show);
    //const Id = this.props.paramId && this.props.paramId.Id;
    if (!_.isEmpty(Id)) {
      ///this.props.toggleEmailListLogAccordion(!emailLoggingVisible);
      //this.setState({ logRecordId: Id });
      ///!show &&
      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.MailGunList,
        Id,
        0,
        10,
        "mail_list_logs"
      );
    }
    //console.log("fetchLogsCardClick");
  };
  ////////// Resubscribe Recipient Work(invoked on Resubscribe Btn Click) //////
  reSubscribe = (row, keyIdfr, removeActionType) => {
    if (keyIdfr == EmailConfigTables.NonNatcaMembers) {
      //Default Member
      this.setState({
        resubscribeEmail: row.address,
        unSubTeam: "",
        nonNatcaMemberAddress: row.address, //Non Natca doesn't have Member Id
        showReSubscribeModal: true,
        confirmModelType: keyIdfr,
        removeActionType,
      });
    }
    if (keyIdfr == EmailConfigTables.DefaultMembers) {
      //Default Member
      this.setState({
        resubscribeEmail: row.address,
        unSubTeam: "",
        deleteEmailConfigRecordId: row.memberId,
        showReSubscribeModal: true,
        confirmModelType: keyIdfr,
        removeActionType,
      });
    }
    if (keyIdfr !== EmailConfigTables.AdditionalRecipientsTeam) {
      /// Additional Member
      this.setState({
        unSubTeam: "",
        resubscribeEmail: row.address,
        showReSubscribeModal: true,
        deleteEmailConfigRecordId: row.memberId,
        confirmModelType: keyIdfr,
        removeActionType,
      });
    } else {
      /// Additional Team
      this.setState({
        unSubTeam: row.teamName,
        unSubTeamId: row.teamId,
        teamMembers: row.members,
        showReSubscribeModal: true,
        teamMapId: row.id,
        confirmModelType: keyIdfr,
        removeActionType,
      });
    }
  };
  toggleResubscribersModal = () => {
    this.setState({
      showReSubscribeModal: !this.state.showReSubscribeModal,
    });
  };
  ///////////////////// New Work///////////////////////
  openManageSubscribersModal = () => {
    const { filterDetails } = this.props;
    this.props.toggleMangSubscribersModal(filterDetails, true);
  };

  //Modal for Manage Subscribers
  SubscribersTab = () => {
    const {
      currentEmailConfigTab,
      additionalRecipientsRows,
      authorizedSendersRows,
      emailEntityType,
      entityKey,
    } = this.props.melstate;
    let deletBtnText = "";
    let header;
    if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
      deletBtnText = "Delete All Additional Subscribers";
    } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
      deletBtnText = "Delete All Authorized Senders";
    }
    let showDeleteBtn =
      currentEmailConfigTab == EmailConfigTabs.Subscribers ||
      currentEmailConfigTab == EmailConfigTabs.Settings;
    let anyDataInTbls = false;

    if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
      anyDataInTbls = additionalRecipientsRows.length > 0;
    } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
      anyDataInTbls = authorizedSendersRows.length > 0;
    }
    if (emailEntityType == MailGunEmailTypes.Team) {
      header = this.emailConfigTeamHeader("Teams", entityKey);
    } else if (emailEntityType == MailGunEmailTypes.Member) {
      header = this.emailConfigHeaderSaveSearch("Saved Search");
    } else if (emailEntityType == MailGunEmailTypes.Area) {
      header = this.emailConfigHeaderArea("Area");
    } else if (emailEntityType == MailGunEmailTypes.Region) {
      header = this.emailConfigHeaderRegion("Region");
    } else if (emailEntityType == MailGunEmailTypes.Facility) {
      header = this.emailConfigFacilityHeader("Facility", entityKey);
    } else if (emailEntityType == MailGunEmailTypes.Registrants) {
      header = this.emailConfigHeaderRegis("Registrations");
    }

    return (
      <>
        {/* Default Subscribers */}
        <MailGunMembersList
          mgListAddress={this.props.mgListAddress}
          emailListMemType={EmailListMemType.Subscribers}
          ref={this.subscribersListRef}
          subscribersListRef={this.subscribersListRef}
          hideActionColumn={this.props.hideActionIcons}
          confirmDeleteMember={this.handleConfirmDeleteMember}
          //toggleConfirm={this.toggleConfirmDeleteModal}
          setCurrentSenderInfo={this.setCurrentSenderInfo}
          keyIdfr={EmailConfigTables.DefaultMembers}
          showReSubscribeModal={this.reSubscribe}
          listData={this.props.melstate.defaultRecipientMembers}
          shrinkActionCol={this.props.showTwoTabs}
          isModalPopUpOpended={this.props.isModalPopUpOpended}
          emailEntityType={this.props.emailEntityType}
        />

        {/*################# Additional Recipients Work in Subscribers Tab################# */}
        <Card>
          {AuthService.canView(PermissionFeature.EmailAddlSubscribersList) && (
            <>
              <h4
                className="card-header text-center"
                onClick={() => {
                  this.setState({
                    showAddtlMembers: !this.state.showAddtlMembers,
                  });
                }}
              >
                Additional Recipients{" "}
                <i
                  className="fa fa-refresh c-pointer"
                  aria-hidden="true"
                  title="Refresh"
                  onClick={async (event) => {
                    event.stopPropagation();

                    await this.props.setAddtlSubsLoading(true);
                    await this.props.reloadAdditionalSubsribersTables();
                    await this.props.setAddtlSubsLoading(false);
                  }}
                />
              </h4>
              <Collapse
                className="AddtlRecptCardCollapse"
                isOpen={this.state.showAddtlMembers}
              >
                <CardBody>
                  <div className="row pl-2 pr-2">
                    <div className="col-12">
                      {this.props.hideActionIcons && (
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            {!this.props.melstate
                              .isLoadingAdditionalSubscriber &&
                              this.subscribersListRef &&
                              this.subscribersListRef.current &&
                              !this.subscribersListRef.current.state
                                .loadingSubs && (
                                <h5 className="cursorPointer">
                                  {/* Additional Recipients{" "} */}
                                  Add Members/Teams{" "}
                                  <i
                                    className="fa fa-plus-circle text-success"
                                    // ref={(el) => {
                                    //   if (el) {
                                    //     this.props.melstate
                                    //       .isLoadingAdditionalSubscriber &&
                                    //       el.style.setProperty(
                                    //         "cursor",
                                    //         "not-allowed",
                                    //         "important"
                                    //       );

                                    //     !this.props.melstate
                                    //       .isLoadingAdditionalSubscriber &&
                                    //       el.style.setProperty(
                                    //         "cursor",
                                    //         "default",
                                    //         "important"
                                    //       );
                                    //   }
                                    // }}
                                    onClick={() =>
                                      !this.props.melstate
                                        .isLoadingAdditionalSubscriber &&
                                      this.props.toggleSelectAddRecptModal()
                                    }
                                  />
                                </h5>
                              )}
                          </div>
                          {showDeleteBtn && anyDataInTbls && (
                            <div className="col-md-6 col-sm-12 align-self-center">
                              <div className="float-right">
                                <button
                                  type="button"
                                  onClick={this.toggleDeleteAllModal}
                                  class="btn btn-outline-danger"
                                >
                                  {deletBtnText}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {/* <h5 className="mb-1">Additional MEMBERS</h5> */}
                      <EmailConfigMemberTable
                        listData={
                          this.props.melstate.additionalRecipientsMembers
                        }
                        loading={
                          this.props.melstate.isLoadingAdditionalSubscriber
                        }
                        confirmDeleteMember={this.handleConfirmDeleteMember}
                        toggleConfirm={this.toggleConfirmDeleteModal}
                        setCurrentSenderInfo={this.setCurrentSenderInfo}
                        keyIdfr={EmailConfigTables.AdditionalRecipientsMember}
                        caption={"MEMBERS"}
                        hideActionColumn={this.props.hideActionIcons}
                        showReSubscribeModal={this.reSubscribe}
                        shrinkActionCol={!this.props.isModalPopUpOpended}
                        emailEntityType={this.props.emailEntityType}
                      />
                      {/* <h5 className="mb-1">Additional TEAMS</h5> */}
                      <div className="pt-2">
                        <EmailConfigTeamsTable
                          listData={
                            this.props.melstate.additionalRecipientsTeams
                          }
                          loading={
                            this.props.melstate.isLoadingAdditionalSubscriber
                          }
                          confirmDeleteTeam={this.handleConfirmDeleteTeam}
                          toggleConfirm={this.toggleConfirmDeleteModal}
                          setCurrentTeamInfo={this.setCurrentTeamInfo}
                          keyIdfr={EmailConfigTables.AdditionalRecipientsTeam}
                          caption={"TEAMS"}
                          hideActionColumn={this.props.hideActionIcons}
                          showReSubscribeModal={this.reSubscribe}
                          shrinkActionCol={this.props.showTwoTabs}
                        />
                      </div>
                      <div className="pt-2">
                        {this.props.hideActionIcons && (
                          <h5 className="cursorPointer">
                            Add Non-NATCA{" "}
                            <i
                              className="fa fa-plus-circle text-success"
                              onClick={() => this.toggleAddNonNatcaModal()}
                            />
                          </h5>
                        )}
                        {/* <h5 className="mb-1">Additional NonNatca Members</h5> */}
                        <EmailConfigMemberTable
                          listData={
                            this.props.melstate.additionalRecipientsNonNatca
                          }
                          loading={
                            this.props.melstate.isLoadingAdditionalSubscriber
                          }
                          confirmDeleteMember={this.handleConfirmDeleteMember}
                          toggleConfirm={this.toggleConfirmDeleteModal}
                          setCurrentSenderInfo={this.setCurrentSenderInfo}
                          keyIdfr={EmailConfigTables.NonNatcaMembers}
                          caption={"NON-NATCA"}
                          hideActionColumn={this.props.hideActionIcons}
                          showReSubscribeModal={this.reSubscribe}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Collapse>
            </>
          )}
        </Card>
      </>
    );
  };
  EmailSettingsTab = () => {
    const { isEmailListExists } = this.props.melstate;
    const {
      editFilterRow: { search_name = "" },
    } = this.props.savedSearchState;
    const {
      currentEmailConfigTab,
      additionalRecipientsRows,
      authorizedSendersRows,
      sendCopyToSender,
    } = this.props.melstate;
    let showDeleteBtn =
      currentEmailConfigTab == EmailConfigTabs.Subscribers ||
      currentEmailConfigTab == EmailConfigTabs.Settings;
    let deletBtnText = "";
    if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
      deletBtnText = "Delete All Additional Subscribers";
    } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
      deletBtnText = "Delete All Authorized Senders";
    }
    let anyDataInTbls = false;
    if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
      anyDataInTbls = additionalRecipientsRows.length > 0;
    } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
      anyDataInTbls = authorizedSendersRows.length > 0;
    }
    return (
      <>
        {!isEmailListExists &&
          AuthService.canAdd(PermissionFeature.EmailListsLists) && (
            <div className="row">
              <div className="col">
                <h6 className="mt-0 text-center">Create New Email List</h6>
              </div>
            </div>
          )}
        {isEmailListExists &&
          AuthService.canUpdate(PermissionFeature.EmailListsLists) && (
            <div className="row">
              <div className="col">
                <h6 className="mt-0 text-center">Update Email List Details</h6>
              </div>
            </div>
          )}
        {!isEmailListExists &&
          !AuthService.canAdd(PermissionFeature.EmailListsLists) &&
          !AuthService.canView(PermissionFeature.EmailListAdmin) && (
            <>
              <span>
                No Email list exists for this Saved Filter. To request an email
                list click the button below and a member of the ITC will reach
                out to help you create the list. For additional questions
                contact
              </span>
              <a href="mailto:support@natca.org"> support@natca.org</a>

              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={() =>
                      this.props.sendSupportEmailListRequest({
                        filterName: search_name,
                      })
                    }
                    className={`btn btn-primary mt-1 mb-1 float-left c-pointer`}
                  >
                    Request Email List
                  </button>
                </div>
              </div>
            </>
          )}

        <div className="row">
          {isEmailListExists && (
            <>
              {/* <div className="col-lg-3 col-sm-12">
                        <h6>Email List Details</h6>
                      </div> */}
              {!this.props.showTwoTabs && (
                <div className="col-lg-9 col-sm-12">
                  <span
                    id="manageSubscribe"
                    onClick={this.openManageSubscribersModal}
                    className="active d-flex align-items-center"
                  >
                    <h6>Manage Subscribers </h6>
                    <i
                      className="fa fa-arrow-right ml-2 small"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        {((AuthService.canUpdate(PermissionFeature.EmailListsLists) &&
          isEmailListExists) ||
          (AuthService.canAdd(PermissionFeature.EmailListsLists) &&
            !isEmailListExists) ||
          AuthService.canView(PermissionFeature.EmailListAdmin)) && (
          <Fragment>
            {/* <div className="row">
                      <div className="col">
                        <h4>Email List Details</h4>
                      </div>
                    </div> */}
            <div className="row">
              <div className="col-sm-6 col-md-3 font-weight-bold">
                <h6>List Address</h6>
              </div>
              <div className="col-sm-6 col-md-9 pt-2">
                <span class="font-weight-bold">
                  {this.props.melstate.mgListAddressAlias}
                </span>
                <span>@{this.props.melstate.mgListDomain}</span>{" "}
                <i
                  className="fas fa-edit cursorPointer"
                  onClick={
                    !this.props.hideActionIcons
                      ? null
                      : this.toggleEmailAliasModal
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="card-text px-4 py-1 custom-checkbox emailConfChkBox">
                <input
                  name={"cctosender"}
                  id={"cctosender"}
                  type="checkbox"
                  onChange={this.handleChange}
                  checked={sendCopyToSender}
                  className="custom-control-input"
                  disabled={!this.props.hideActionIcons}
                />
                <label className="custom-control-label" for={"cctosender"}>
                  Send Copy to Sender
                </label>
              </div>
            </div>
            {/* {AuthService.canView(
                      PermissionFeature.EmailAuthSendersList
                    ) && (
                      <div className="row">
                        <div className="col-sm-6 col-md-3 font-weight-bold">
                          <h6>Post Address</h6>
                        </div>
                        <div className="col-sm-6 col-md-9 pt-2">
                          <span class="font-weight-bold">
                            {this.props.melstate.mgListAddressAlias}
                          </span>
                          <span>{this.props.melstate.postListDomain}</span>{" "}
                        </div>
                      </div>
                    )} */}

            <p className="mt-3">Who can send to list?</p>
            <div className="row">
              <div className="col-md-12">
                {this.renderEmailPriviliges(this.props.hideActionIcons)}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p className="mt-3">Reply Preference?</p>
                <select
                  className="browser-default custom-select"
                  id="reply_preference"
                  onChange={this.handleChange}
                  defaultValue={this.props.melstate.replyPreference || "list"}
                  disabled={!this.props.hideActionIcons}
                  value={this.props.melstate.replyPreference}
                >
                  <option
                    value="list"
                    // selected={
                    //   this.props.melstate.replyPreference === "list"
                    // }
                  >
                    List
                  </option>
                  <option
                    value="sender"
                    // selected={
                    //   this.props.melstate.replyPreference === "sender"
                    // }
                  >
                    Sender
                  </option>
                </select>
              </div>
            </div>

            {AuthService.canView(PermissionFeature.EmailAuthSendersList) &&
              this.props.melstate.selectedPrivilige == "readonly" && (
                <div className="row mt-3">
                  <div className="col-12">
                    {/* //Action buttons div// */}
                    {this.props.hideActionIcons && (
                      <div className="row">
                        <div className="col-md-6 col-sm-12">
                          {((!this.props.melstate
                            .isLoadingAdditionalSubscriber &&
                            this.subscribersListRef &&
                            !this.props.melstate.isLoadingAuthorizedSenders &&
                            this.subscribersListRef.current &&
                            !this.subscribersListRef.current.state
                              .loadingSubs) ||
                            (!this.props.showTwoTabs &&
                              !this.props.melstate
                                .isLoadingAdditionalSubscriber) ||
                            !this.props.emailListExists) && (
                            <h5 className="cursorPointer">
                              Customize Authorized Senders{" "}
                              <i
                                className="fa fa-plus-circle text-success"
                                onClick={() => {
                                  this.props.setCurrentEmailConfigTab(
                                    EmailConfigTabs.Settings
                                  );
                                  this.props.toggleSelectAuthSendersModal();
                                }}
                              />
                            </h5>
                          )}
                        </div>
                        {showDeleteBtn && anyDataInTbls && (
                          <div className="col-md-6 col-sm-12 align-self-center">
                            <div className="float-right">
                              <button
                                type="button"
                                onClick={this.toggleDeleteAllModal}
                                class="btn btn-outline-danger"
                              >
                                {deletBtnText}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/*########################### Authorized Senders Tables############ */}
                    {/* <h5 className="mb-1">Members</h5> */}
                    <EmailConfigMemberTable
                      listData={this.props.melstate.authorizedSendersMembers}
                      loading={this.props.melstate.isLoadingAuthorizedSenders}
                      confirmDeleteMember={this.handleConfirmDeleteMember}
                      toggleConfirm={this.toggleConfirmDeleteModal}
                      setCurrentSenderInfo={this.setCurrentSenderInfo}
                      keyIdfr={EmailConfigTables.AuthorizedSendersMember}
                      caption={"MEMBERS"}
                      hideActionColumn={this.props.hideActionIcons}
                    />
                    {/* <h5 className="mb-1">Teams</h5> */}
                    <EmailConfigTeamsTable
                      listData={this.props.melstate.authorizedSendersTeams}
                      loading={this.props.melstate.isLoadingAuthorizedSenders}
                      confirmDeleteTeam={this.handleConfirmDeleteTeam}
                      toggleConfirm={this.toggleConfirmDeleteModal}
                      setCurrentTeamInfo={this.setCurrentTeamInfo}
                      keyIdfr={EmailConfigTables.AuthorizedSendersTeam}
                      caption={"TEAMS"}
                      hideActionColumn={this.props.hideActionIcons}
                    />
                  </div>
                </div>
              )}
            {this.props.hideActionIcons && (
              <div className="row hidden delBtnTeams">
                <div className="col-12 pt-3">
                  <button
                    type="button"
                    onClick={async () => {
                      await this.props.setAddtlSubsLoading(true);
                      await this.props.deleteAllAddlTeamListClick(
                        this.props.melstate.emailListId,
                        this.props.melstate.emailEntityType
                      );
                      await this.props.setAddtlSubsLoading(false);
                    }}
                    class="btn btn-danger"
                  >
                    Delete All Team Recipients{" "}
                    <i
                      className="fas fa-trash-alt cursorPointer"
                      aria-hidden="true"
                      title="Delete All Teams"
                    />
                  </button>
                </div>
              </div>
            )}
            {(this.props.melstate.openAssignAuthorizedSendersModal ||
              this.props.melstate.openAssignAdditionalRecipientsModal) && (
              <AuthorizedSendersModal
                showModal={
                  this.props.melstate.openAssignAuthorizedSendersModal ||
                  this.props.melstate.openAssignAdditionalRecipientsModal
                }
                emailEntityType={this.props.emailEntityType}
                emailListId={this.props.emailListId}
              />
            )}
            <MG_EditEmailAliasModal
              show={this.state.showEmailAliasModal}
              setMgListAlias={this.props.setMgListAlias}
              handleEmailAliasChange={this.handleChange}
              handleClose={this.toggleEmailAliasModal}
              emailAlias={this.props.melstate.mgListAddressAlias}
              domain={this.props.melstate.mgListDomain}
              mailingListName={this.props.melstate.mailingListName}
              changeHandlerListName={this.changeHandlerListName}
            />
          </Fragment>
        )}
      </>
    );
  };
  onCancelManageSubsModal = () => {
    this.props.toggleMangSubscribersModal({}, false);
  };
  onSaveChangesSubsribersModal = async () => {
    await this.props.saveEmailConfigData();
    this.fetchLogsCardClick(); ///Reload Logs after Subscribers update

    this.props.toggleMangSubscribersModal({}, false); /// Close the Modal to give time for saving of List Additional Members
  };
  setNestedTabKeys = (tabId) => {
    this.setState({ nestedTabKey: tabId });
  };
  render() {
    const Id = `${this.props.emailListId},${this.props.emailEntityType}`;
    //console.log(Id);
    const {
      currentEmailConfigTab,
      emailEntityType,
      entityKey,
      sendCopyToSender,
      additionalRecipientsRows,
      authorizedSendersRows,
    } = this.props.melstate;
    let deletBtnText = "";
    let header;
    if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
      deletBtnText = "Delete All Additional Subscribers";
    } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
      deletBtnText = "Delete All Authorized Senders";
    }
    let showDeleteBtn =
      currentEmailConfigTab == EmailConfigTabs.Subscribers ||
      currentEmailConfigTab == EmailConfigTabs.Settings;

    let anyDataInTbls = false;
    if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
      anyDataInTbls = additionalRecipientsRows.length > 0;
    } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
      anyDataInTbls = authorizedSendersRows.length > 0;
    }
    if (emailEntityType == MailGunEmailTypes.Team) {
      header = this.emailConfigTeamHeader("Teams", entityKey);
    } else if (emailEntityType == MailGunEmailTypes.Member) {
      header = this.emailConfigHeaderSaveSearch("Saved Search");
    } else if (emailEntityType == MailGunEmailTypes.Area) {
      header = this.emailConfigHeaderArea("Area");
    } else if (emailEntityType == MailGunEmailTypes.Region) {
      header = this.emailConfigHeaderRegion("Region");
    } else if (emailEntityType == MailGunEmailTypes.Facility) {
      header = this.emailConfigFacilityHeader("Facility", entityKey);
    } else if (emailEntityType == MailGunEmailTypes.Registrants) {
      header = this.emailConfigHeaderRegis("Registrations");
    }
    const { isEmailListExists, mgListAddress } = this.props.melstate;
    const {
      editFilterRow: { search_name = "", id = 0 },
    } = this.props.savedSearchState;
    const { showTwoTabs } = this.props;

    const addressStyle = {
      /* Apply your desired styling to the mgListAddress here */
      fontWeight: "bold", // For example, make it bold
      // Add more styles as needed
      textTransform: "lowercase",
    };
    const { showManageSubscribersModal } = this.props.savedSearchState;
    let settingsTabProps = {
      eventKey: "settings",
      tabClassName: "",
    };
    if (!this.props.editFilterModalCall) {
      settingsTabProps.title = (
        <>
          <i className="fas fa-users-cog">
            <span className="font-weight-bold">
              {showTwoTabs ? " Settings" : " Email List"}
            </span>
          </i>
        </>
      );
    }

    return (
      <>
        {!this.props.isModalPopUpOpended && (
          <>
            {/* <h3>{`${this.props.emailListTitle}`}</h3> */}
            <span className="hidden">Total:{this.state.totalSyncMembers}</span>
            <span className="hidden">
              Duplicates:{this.state.totalDupSyncMembers}
            </span>
            {/* {
              <div className="mainHeaderLine">
                <h6>{header}</h6>
                <span>|</span>

                {this.props.emailListTitle ? (
                  <h6 className="emailConfigHeader emailConfigHeaderHightLight font-italic pl-1 pr-1">
                    {this.props.emailListTitle.replace("Email List", "")}
                  </h6>
                ) : (
                  ""
                )}

                {this.props.emailListTitle ? <span>|</span> : ""}
                <h6 className="emailConfigHeader font-italic pl-1">
                  Configure Email List
                </h6>
              </div>
            } */}
          </>
        )}
        <Modal
          className="modal fade team-style"
          id="ManageSubscribers"
          size="lg"
          backdrop="static"
          dialogClassName="subscribersModalCustomWidth"
          show={showManageSubscribersModal}
          centered
          onShow={async () => {
            $(".loading").show();
            const {
              editFilterRow: { id = 0 },
            } = this.props.savedSearchState;
            await this.props.setDefaultSubscribers([]);
            await this.props.setAddtlSubsLoading(true);
            if (id) {
              this.props.resetAddlMembers();
              await this.props.reloadAdditionalSubsribersTables();
              await this.props.setAddtlSubsLoading(false);
              this.props.reloadDefaultSubsribersTable();
              //updating component key to Subscriber will hide it in the Edit Filter Modal
              //await this.setKey(EmailConfigTabs.Subscribers);
              await this.props.setCurrentEmailConfigTab(
                EmailConfigTabs.Subscribers
              );
              $(".loading").hide();
            }
          }}
          onHide={async () => {
            this.props.toggleMangSubscribersModal({}, false);
            await this.props.setCurrentEmailConfigTab(EmailConfigTabs.Settings);
          }}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-white mt-0">
                {search_name}'s Email List (
                <span style={addressStyle}>{mgListAddress}</span>)
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <MailingListMembersTab /> */}
            {this.SubscribersTab()}

            <div className="col-12 m-t-20 mx-auto text-center">
              {
                <>
                  <button
                    className="btn btn-danger w-md waves-effect cancelModal waves-light mc"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={this.onCancelManageSubsModal}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn btn-success ml-sm-2 ml-1 w-md waves-effect waves-light"
                    type="submit"
                    onClick={this.onSaveChangesSubsribersModal}
                  >
                    Save Changes
                  </button>
                </>
              }
            </div>
          </Modal.Body>
        </Modal>

        <Tabs
          id="emailConfigTabs"
          activeKey={this.state.key}
          onSelect={(k) => this.setKey(k)}
          className="mb-1"
        >
          {/* ************ Add Mode ************** */}
          {/* Email List does not Exists */}
          {/* {this.props.emailListExists && ( */}
          {!this.props.editFilterModalCall && this.props.showTwoTabs && (
            <Tab
              eventKey="subscribers"
              tabClassName={!this.props.ssUpdateEmailListMode ? "d-none" : ""}
              title={
                <>
                  {
                    <i class="fas fa-users">
                      {" "}
                      <span className="font-weight-bold"> Members</span>
                    </i>
                  }
                </>
              }
            >
              {this.props.emailListExists && this.SubscribersTab()}

              {/* ########################### END Additional Subscribers Tables############################# */}
            </Tab>
          )}
          {/* {!this.props.showMembersTabOnly && (
            <Tab
              tabClassName={!this.props.ssUpdateEmailListMode ? "d-none" : ""}
              eventKey="unsubscribed"
              title={
                <>
                  <i className="fas fa-user-slash">
                    <span className="font-weight-bold"> Unsubscribed</span>
                  </i>
                </>
              }
            >
              <MailGunMembersList
                mgListAddress={this.props.mgListAddress}
                emailListMemType={EmailListMemType.UnSubscribers}
                ref={this.unsubscribersListRef}
                unSubscribersListRef={this.unsubscribersListRef}
                nonNatcaUnsubscribersListRef={this.nonNatcaUnsubscribersListRef}
                subscribersListRef={this.subscribersListRef}
                additionalTeamUnsubscribersListRef={
                  this.additionalTeamUnsubscribersListRef
                }
                hideActionColumn={this.props.hideActionIcons}
                keyIdfr={EmailConfigTables.DefaultMembers}
              />

              <MailGunMembersList
                mgListAddress={this.props.mgListAddress}
                emailListMemType={EmailListMemType.NonNatcaUnSubscribers}
                ref={this.nonNatcaUnsubscribersListRef}
                subscribersListRef={this.subscribersListRef}
                unSubscribersListRef={this.unsubscribersListRef}
                nonNatcaUnsubscribersListRef={this.nonNatcaUnsubscribersListRef}
                additionalTeamUnsubscribersListRef={
                  this.additionalTeamUnsubscribersListRef
                }
                setCurrentSenderInfo={this.setCurrentSenderInfo}
                confirmDeleteMember={this.handleConfirmDeleteMember}
                keyIdfr={EmailConfigTables.NonNatcaMembers}
                hideActionColumn={this.props.hideActionIcons}
              />
              //New Table for Team Unsubscribers
              <MailGunMembersList
                mgListAddress={this.props.mgListAddress}
                emailListMemType={EmailListMemType.AdditionalTeamUnSubscribers}
                ref={this.additionalTeamUnsubscribersListRef}
                subscribersListRef={this.subscribersListRef}
                unSubscribersListRef={this.unsubscribersListRef}
                nonNatcaUnsubscribersListRef={this.nonNatcaUnsubscribersListRef}
                additionalTeamUnsubscribersListRef={
                  this.additionalTeamUnsubscribersListRef
                }
                isTeamsTable={true}
                //Deletion from Additional Team Unsubscribers
                confirmDeleteTeam={this.handleConfirmDeleteTeam}
                toggleConfirm={this.toggleConfirmDeleteModal}
                setCurrentTeamInfo={this.setCurrentTeamInfo}
                keyIdfr={EmailConfigTables.AdditionalRecipientsTeam}
                hideActionColumn={this.props.hideActionIcons}
              />
            </Tab>
          )} */}
          {/* )} */}
          {/*####################### Main Configuration (Settings)Tab with Authorized Senders Work########################## */}
          {/*////If showMembersTabOnly props is true then don't Render Settings Tab////*/}
          <Tab {...settingsTabProps}>
            <BootStrapCard>
              <BootStrapCard.Body className="pt-0">
                {/* // In case of Edit Filter Modal "showTwoTabs" will be false becuase we will not show `Subscribers` tab, 
                we will just show `Setttings` tab */}
                {this.props.editFilterModalCall && !this.props.showTwoTabs && (
                  <Tabs
                    id="emailPlusActivityLogs"
                    activeKey={this.state.nestedTabKey}
                    onSelect={(k) => this.setNestedTabKeys(k)}
                    className="mb-1"
                  >
                    <Tab
                      eventKey="emailDetails"
                      title={
                        <>
                          <i class="fa fa-info-circle">
                            {" "}
                            <span className="font-weight-bold">Details</span>
                          </i>
                        </>
                      }
                    >
                      {this.EmailSettingsTab()}
                    </Tab>
                    <Tab
                      eventKey="logs"
                      title={
                        <>
                          <i class="fa fa-history">
                            {" "}
                            <span
                              className="font-weight-bold"
                              //onClick={this.fetchLogsCardClick}
                            >
                              {" "}
                              Activity Logs
                            </span>
                          </i>
                        </>
                      }
                    >
                      <EmailActivityLogging
                        getActicityLoggingByModuleId={
                          this.props.getActicityLoggingByModuleId
                        }
                        //ref={this.activityLogs}
                        toggleEmailListLogAccordion={
                          this.props.toggleEmailListLogAccordion
                        }
                        auditState={this.props.activityLoggingState}
                        paramId={Id}
                      />
                    </Tab>
                  </Tabs>
                )}
                {/* /// For Showing in screens other than Filter Modal /// */}
                {!this.props.editFilterModalCall &&
                  this.props.showTwoTabs &&
                  this.EmailSettingsTab()}
              </BootStrapCard.Body>
            </BootStrapCard>
          </Tab>
          {/*#######################  Settings Tab END Here########################## */}
          {/* ************************************New Tab Activity Logging************************************ */}
          {!this.props.editFilterModalCall && this.props.showTwoTabs && (
            <Tab
              eventKey="activityLogs"
              title={
                <>
                  <i class="fa fa-history">
                    {" "}
                    <span
                      className="font-weight-bold"
                      ////onClick={this.fetchLogsCardClick}
                    >
                      {" "}
                      Activity Logs
                    </span>
                  </i>
                </>
              }
            >
              <EmailActivityLogging
                getActicityLoggingByModuleId={
                  this.props.getActicityLoggingByModuleId
                }
                //ref={this.activityLogs}
                toggleEmailListLogAccordion={
                  this.props.toggleEmailListLogAccordion
                }
                auditState={this.props.activityLoggingState}
                paramId={Id}
              />
            </Tab>
          )}

          {/* ************************************END Tab Activity Logging************************************ */}
        </Tabs>

        {/******************Modal Popup components****************/}
        <MG_DeleteAllAuthSenders
          modal={this.state.showEmailDeleteAllModal}
          confirm={this.confirmDeleteAll}
          toggle={this.toggleDeleteAllModal}
          currentEmailConfigTab={currentEmailConfigTab}
          onCancelClick={this.onCancelClick}
        />
        <MG_DeleteEmailList
          modal={this.state.showDeleteEmailListModal}
          confirm={this.confirmEmailList}
          toggle={this.toggleDeleteEmailListModal}
        />
        <MG_ConfirmationModal
          confirm={
            this.state.teamModalOpended
              ? this.confirmDeleteTeam
              : this.confirmDeleteMember
          }
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmDeleteModal}
          userFirstName={this.state.userFirstName}
          userLastName={this.state.userLastName}
          teamName={this.state.team}
          removeActionType={this.state.removeActionType}
          teamModalOpended={this.state.teamModalOpended}
          fullName={this.state.fullName}
          memberModalOpended={this.state.memberModalOpended}
          confirmModelType={this.state.confirmModelType}
          classId="ManageSubscribers"
        />
        <MG_Add_NonNatca
          handleClose={this.toggleAddNonNatcaModal}
          showModal={this.state.isOpenAddNonNatcaModal}
        />
        <MG_ReSubscriberModal
          email={this.state.resubscribeEmail}
          teamName={this.state.unSubTeam}
          modal={this.state.showReSubscribeModal}
          confirm={
            this.state.unSubTeam
              ? this.confirmResubscribersModalForTeams
              : this.confirmResubscribersModal
          }
          toggle={this.toggleResubscribersModal}
          removeActionType={this.state.removeActionType}
          confirmModelType={this.state.confirmModelType}
        />
        {/* ////Modal That displays Members Not Just Subscibers/Unsubsribers ////// */}
        {/* {showManageSubscribersModal && (
          <ManageSubscribersModal
            showModal={showManageSubscribersModal}
            savedSearchTitle={search_name}
            savedSearchId={id}
            hideActionIcons={EmailService.shouldHideActionIcons()}
          />
        )} */}
      </>
    );
  }
}

export default connect(
  (state) => ({
    melstate: state.mailGunEmail,
    savedSearchState: state.saveSearch,
    activityLoggingState: state.activityLogging,
  }),
  {
    ...mailGunEmailActions,
    ...savedSearchAction,
    ...actionActivity,
  },
  null,
  { forwardRef: true }
)(MailGunConfigurationTabs);
