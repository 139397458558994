import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AUX from "../../../hoc/Aux_";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import PermissionFeature from "../../Permissions/PermissionFeature";
import AuthService from "../../Auth/AuthService";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import _ from "lodash";
import AreasListTable from "./AreasListTable";
import DeleteModalGeneric from "../../Shared/DeleteModalGeneric";
import AreaForm from "./AreaForm";
import AreasService from "./AreasService";
import AssignMembersModal from "./AssignMembersModal";

class ManageAreasModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [],
      areaState: {
        areaId: -1,
        areaCode: "",
        areaDescription: "",
      },
      deleteId: -1,
      areaIdForMemberAssign: -1,
      isConfirmModalOpen: false,
      isLoading: false,
      showDeleteModal: false,
      showModal: props.showModal,
      pageSize: 25,
      columns: [
        { text: "Name", dataField: "name", sort: false },
        { text: "Email", dataField: "address", sort: false },
      ],
      paginationSettings: {
        withFirstAndLast: true,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        paginationSize: 1,
      },
      name: "",
      isConfirmModalOpen: false,
      isAssignMembersModalOpen: false,
    };
  }

  openEditAreaForm = (id) => {
    const { areas } = this.state;
    const area = areas.find((item) => item.id === id);

    if (area) {
      this.setState((prevState) => ({
        areaState: {
          ...prevState.areaState,
          areaId: area.id,
          areaCode: area.code,
          areaDescription: area.description,
        },
      }));
    }
  };

  openAssignMembers = (id) => {
    this.setState({
      isAssignMembersModalOpen: !this.state.isAssignMembersModalOpen,
      areaIdForMemberAssign: id,
    });
  };

  async deleteAreaCall() {
    let data = await AreasService.DeleteArea(this.state.deleteId);
    if (!_.isEmpty(data)) {
      this.getAreas();
    }
  }

  deleteArea = (id) => {
    this.setState({
      deleteId: id,
    });
    this.toggleConfirmModal();
  };

  confirm = () => {
    this.confirmed = () => {
      if (this.state.deleteId == 0) {
        return false;
      }

      this.deleteAreaCall();

      this.setState({ deleteId: -1 });
      this.toggleConfirmModal();
    };
    return this;
  };

  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  areaForm_valueChangeHandler = (evt) => {
    const { name, value } = evt.target;

    this.setState((prevState) => ({
      areaState: {
        ...prevState.areaState,
        [name]: value,
      },
    }));
  };

  areaForm_onClear = () => {
    this.setState((prevState) => ({
      areaState: {
        ...prevState.areaState,
        areaId: -1,
        areaCode: "",
        areaDescription: "",
      },
    }));
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleClose = () => {
    this.props.onModalClose();
  };

  canAdd = () => {
    return AuthService.canAdd(PermissionFeature.Areas);
  };

  canUpdate = () => {
    return AuthService.canUpdate(PermissionFeature.Areas);
  };

  canAssignAreas = () => {
    return AuthService.canUpdate(PermissionFeature.AssignAreas);
  };

  canDelete = () => {
    return AuthService.canDelete(PermissionFeature.Areas);
  };

  resetState = () => {
    this.setState({
      emailListExists: false,
      mgListAddress: "",
      emailListId: 0,
      mailingListName: "",
    });
  };

  getAreas = async () => {
    var data = await AreasService.GetAreasByFacility(this.props.FacilityId);
    this.setState({
      areas: data.data,
    });
  };

  onHide = () => {
    this.resetState();
    this.props.onModalClose();
  };

  onShow = async () => {
    this.getAreas();
  };

  toggleDeleteEmailListModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  closeAssignMembersModal = () => {
    this.setState({
      isAssignMembersModalOpen: false,
    });
  };

  render() {
    return (
      <AUX>
        <Modal
          size="xl"
          //backdrop="static"
          centered
          show={this.props.showModal}
          onShow={this.onShow}
          onHide={this.onHide}
        // className={
        //   MailGunEmailType == MailGunEmailTypes.Registrants
        //     ? "nested-cs-modal"
        //     : ""
        // }
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title mt-0">{`MANAGE AREAS`}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Spinner /> */}
            <AreasListTable
              listData={this.state.areas}
              deleteArea={this.deleteArea}
              editArea={this.openEditAreaForm}
              assignMembers={this.openAssignMembers}
              isloading={this.state.isLoading}
              canUpdate={this.canUpdate()}
              canDelete={this.canDelete()}
              canAssignAreas={this.canAssignAreas()}
            />
            {(this.canAdd() || this.canUpdate()) && (
              <AreaForm
                areasList={this.state.areas}
                FacilityId={this.props.FacilityId}
                areaState={this.state.areaState}
                valueChangeHandler={this.areaForm_valueChangeHandler}
                reloadListing={this.getAreas}
                onClear={this.areaForm_onClear}
              ></AreaForm>
            )}

            {this.canAssignAreas() && (
              <AssignMembersModal
                showModal={this.state.isAssignMembersModalOpen}
                closeModal={this.closeAssignMembersModal}
                areaId={this.state.areaIdForMemberAssign}
                facilityId={this.props.FacilityId}
              />
            )}

            <br />
            <br />
          </Modal.Body>
        </Modal>
        <DeleteModalGeneric
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
          message={
            "Deleting the area will remove all references to this area from the system. Are you sure?"
          }
        />
      </AUX>
    );
  }
}

// export default connect(
//   (state) => ({
//     melstate: state.mailGunEmail,
//   }),
//   {
//     ...mailGunEmailActions,
//     ...registrationsAction,
//   }
// )(ManageAreasModal);
export default ManageAreasModal;
