import AUX from "../../../hoc/Aux_";
import React, { Component } from "react";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import MembersModalAll from "./MemberModalAll";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import $ from "jquery";
import _ from "lodash";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import AuthService from "../../Auth/AuthService";

// const SortableItem = sortableElement(({ value }) => <li>{value}</li>);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const DragHandle = sortableHandle(() => <span className="DragHandle"></span>);
const DeleteTrash = sortableHandle(() => (
  <span className="fa-li TrashIcon">
    <i className="fas fa-trash-alt"></i>
  </span>
));

const SortableItem = sortableElement(({ value }) => (
  <li className="SortBorder">
    <DragHandle />
    {value}
    <DeleteTrash />
  </li>
));

let array = [];

class UpdateEmailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      memberSearchText: "",
      memberSearchText_err: "",
      valid: false,
      redirectToNewPage: false,
      addMember: {
        MemberType: "",
        AssignmentTypeId: 1,
        MemberId: 0,
        PositionId: 0,
        facility: "",
        firstName: "",
        lastName: "",
      },
      memberMethods: this.props.memberMethods,

      membersMethods: this.props.membersMethods,
      memberName: this.props.memberName,
      isLookup: this.props.isLookup || false,
      page: 1,
      data: [],
      sizePerPage: 25,
      totalCount: 0,
      keyword: "",
      SortOrder: "seniorityasc",
      isAdvanceSearch: false,
      delId: 0,
      filters: [],
      showActive: true,
      memberId: AuthService.getProfile().memberData.id,
      items: [],
      resultData: [],
      initialItems: [],
      itemIndexes: [],
      itemColumnIds: [],
      sharedArray: [],
      _dataArray: [],
      isOpen: false,
    };

    this.DeleteRow = this.DeleteRow.bind(this);
    this.removeMembers = this.removeMembers.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.onSaveSearch = this.onSaveSearch.bind(this);

    this.membersMethods = () => {
      this.setMember = (args) => {
        if (array.find((x) => x.id != args.id) || array.length === 0) {
          array.push(args);
          this.setState({ _dataArray: array });
        }
        console.log(array);

        $(".cancel-Modal").trigger("click");
      };

      return this;
    };

    this.changeState = () => {
      this.setState({ isOpen: false });
    };

    this.getDefaultColumns();
  }

  getDefaultColumns() {
    //debugger;
    const url = `/api/SavedSearch/GetColumns/`;

    const body = {
      SearchId: 19,
      MemberId: this.state.memberId,
      SearchType: 1,
    };
    axios.post(url, body).then(({ data }) => {
      var tempArr = [];
      this.setState({ resultData: data.searchColumns });

      for (let i = 0; i < data.searchColumns.length; i++)
        tempArr.push(data.searchColumns[i].column_name);
      this.setState({ items: tempArr, initialItems: tempArr });
    });
  }

  onSaveSearch() {
    var indexes = [];
    var columnids = [];
    for (let i = 0; i < this.state.items.length; i++) {
      indexes.push(
        this.state.items.indexOf(this.state.resultData[i].column_name)
      );
      var check = this.state.resultData.find(
        (item) => item.column_name === this.state.initialItems[i]
      );
      columnids.push(check.id);
    }
    this.setState({ itemIndexes: indexes, itemColumnIds: columnids });

    const memberIds = [];

    for (let x = 0; x < this.state._dataArray.length; x++) {
      memberIds.push(this.state._dataArray[x].id);
    }

    const url = `/api/SavedSearch/SaveMemberSearchColumns/`;

    const body = {
      column_id: columnids,
      column_order: indexes,
      saved_search_id: 13,
      members: memberIds,
    };
    axios.post(url, body).then(({ data }) => {
      if (data.status.toLowerCase() === "success") {
        toast.success(data.message);
        $(".cancelModal").trigger("click");
      } else {
        toast.error(data.message);
      }
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
    console.log(this.state.items);
  };

  DeleteRow(id) {
    alert(id);
  }

  searchTextChangeHandler = (event) => {
    console.log({ [event.target.name]: event.target.value });

    this.setState({ [event.target.name]: event.target.value });
    console.log(this.state.memberSearchText);
    var inputValue = event.target.value.trim();
    if (inputValue !== "") {
      if (
        inputValue.indexOf(" ") > 0 &&
        !(
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[0]) &&
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[1])
        )
      ) {
        $(".MemberSearchField").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Name should contain alphabets only.",
        });
        this.setState({ valid: false });
      } else if (
        !(/^[a-zA-Z ]*$/.test(inputValue) || /^[0-9]*$/.test(inputValue))
      ) {
        /// alphanumeric or special characers with one word
        $(".MemberSearchField").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Alphanumeric is not allowed.",
        });
        this.setState({ valid: false });
      } else {
        $(".MemberSearchField").removeClass("inputBorderRed");
        this.setState({ [event.target.name + "_err"]: "" });
        this.setState({ valid: true });
      }
    } else {
      this.setState({ valid: false });
      this.setState({ [event.target.name + "_err"]: "" });
      $(".MemberSearchField").removeClass("inputBorderRed");
    }
  };

  handleEvent() {
    this.getMembers(this.state.memberSearchText, "");
  }

  getMembers(key, facility) {
    //debugger;
    const url = `/api/member/SearchMembers`;
    const formData = {
      Keyword: key,
      Facility: facility,
      PageNo: 1,
      PageSize: 10,
      ShowOnlyActive: true,
      // PageNo: this.props.filterState.currentFilterResultPage,
      // PageSize: this.state.sizePerPage,
      // ShowOnlyActive: this.state.showActive
    };
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          isOpen: true,
        });

        if (!_.isEmpty(key)) {
          $("#keyword").val(key);
        }

        $(".loading").hide();
        //this.props.setFacilityMembersLoading(false)
      } else {
        $(".loading").hide();
        // this.props.setFacilityMembersLoading(false)
        this.setState({
          isOpen: false,
        });
        toast.error(data.message);
      }
    });
  }

  removeMembers(index) {
    if (index !== -1) {
      let values = this.state._dataArray.filter((e, i) => i !== index);
      this.setState({ _dataArray: values });
    }
  }

  render() {
    const { items, memberSearchText, membersMethod } = this.state;
    return (
      <AUX>
        <div
          className="modal fade UpdateEmailListModal "
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Add/Edit Postion"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">UPDATE SAVED SEARCH </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="md-form form-group">
                        <MDBInput
                          name=""
                          id="MailingListName"
                          onChange={this.handleChange}
                          label="Mailing List Name"
                          group
                          type="text"
                          required
                          width="50%"
                        >
                          <div className="invalid-feedback">
                            Mailing List is required.
                          </div>
                        </MDBInput>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="md-form form-group">
                        <MDBInput
                          name=""
                          id="EmailAddress"
                          onChange={this.handleChange}
                          label="Email Address"
                          group
                          type="text"
                          required
                          width="50%"
                        >
                          <div className="invalid-feedback">
                            Email Address is required.
                          </div>
                        </MDBInput>
                      </div>
                    </div>
                    <div className="col-2">
                      <div
                        className="md-form form-group"
                        style={{ marginTop: "44%" }}
                      >
                        <span>@list.natca.org</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4 className="card-subtitle mb-2 text-muted">
                      Who can send to list?{" "}
                    </h4>
                    {/* Radio Buttons */}
                  </div>

                  <div>
                    {
                      <div className="input-group width100 mt-4">
                        <input
                          name="memberSearchText"
                          type="text"
                          id="memberSearchkeyword"
                          value={this.state.memberSearchText}
                          onChange={this.searchTextChangeHandler}
                          className="form-control MemberSearchField"
                          placeholder="Member Search .."
                        />
                        {
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              data-toggle="modal"
                              data-target=".members-modal-all"
                              onClick={this.handleEvent}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        }
                        {/*<button className="btn btn-success w-md waves-effect waves-light mr-2 ml-2" onClick={() => this.ShareSearch()}>Share</button>*/}

                        <small className="text-danger w-100">
                          {this.state.memberSearchText_err}
                        </small>
                      </div>
                    }

                    <br />
                    <div>
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <td>First Name</td>
                            <td>Last Name</td>
                            <td>Facility</td>
                            <td>Action</td>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state._dataArray &&
                            this.state._dataArray.map((user, index) => (
                              <tr key={user.id}>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.facility}</td>
                                <td>
                                  <span className="fa-li TrashIcon">
                                    <i
                                      className="fas fa-trash-alt"
                                      onClick={() => this.removeMembers(index)}
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <br />

                    <div className="col-12 text-center mb-50 mt-50 pt-4">
                      <button
                        className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        Cancel
                      </button>

                      <button
                        className="btn btn-success w-md mc"
                        onClick={() => this.onSaveSearch()}
                      >
                        Save
                      </button>

                      <button
                        className="btn btn-primary ml-2 w-md mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        Save A Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.isOpen == true ? (
          <MembersModalAll
            data={this.state.data}
            triggerState={this.changeState}
            membersMethods={this.membersMethods}
          />
        ) : null}
      </AUX>
    );
  }
}

export default UpdateEmailList;
