import AUX from "../../hoc/Aux_";
import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

class FileEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileEditModal: false,
      originalfilename: "",
      filename: "",
      reference: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showFileEditModal: false,
      originalfilename: "",
      filename: "",
      reference: {},
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onShow = () => {
    this.setState({
      originalfilename: this.props.originalfilename,
      filename: this.props.filename,
      reference: this.props.reference,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRef = (value) => {
    this.setState({
      reference: value,
    });
  };

  onSubmit() {
    this.props.updateFileData({
      originalfilename: this.state.originalfilename,
      filename: this.state.filename,
      reference: this.state.reference,
    });
    $("#cancelBtn").trigger("click");
  }

  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          marginRight: 10,
          fontSize: 18,
        },
      },
    });
    return (
      <AUX>
        <Modal
          className="modal fade"
          id="GrievanceFileEditModal"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                Edit File Details{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="row m-0 my-3">
                <div className="col-md-12 mb-4">
                  <MDBInput
                    name="originalfilename"
                    containerClass="my-0"
                    value={this.state.originalfilename}
                    label="File Name:"
                    type="text"
                    maxlength="200"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  <span className="text-danger">{}</span>
                </div>
                <div className="col-md-12 mb-2">
                  <Autocomplete
                    name="reference"
                    options={this.props.referencesData}
                    classes={{
                      option: classes.option,
                    }}
                    autoComplete={true}
                    autoHighlight={true}
                    onChange={(e, value) => {
                      this.handleChangeRef(value);
                    }}
                    value={this.state.reference}
                    getOptionLabel={(option) => {
                      if (option != undefined) {
                        return option.name;
                      } else {
                        return "";
                      }
                    }}
                    renderOption={(option) => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reference"
                        InputLabelProps={{
                          shrink: true,
                          classes: { shrink: "mt-n1" },
                        }}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "disabled", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <span className="text-danger">{}</span>
                </div>
              </div>
              <div className="form-group mb-4 row">
                <div className="col-12 m-t-20 mx-auto text-center">
                  {
                    <Fragment>
                      <button
                        id="cancelBtn"
                        className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>

                      {
                        <button
                          className="btn btn-success w-md mc"
                          type="button"
                          onClick={this.onSubmit}
                        >
                          Save Changes
                        </button>
                      }
                    </Fragment>
                  }
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default FileEditModal;
