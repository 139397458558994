/** @format */

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import reducer from "./store/reducers/reducer";
import advancedFilterReducer from "./store/advancedfilter/reducer";
import EditUserReducer from "./store/EditUser/reducer";
import facilityDashboardReducer from "./store/facility/reducer";
import permissionsReducer from "./store/permissions/reducer";
import permissionReducer from "./store/permission/reducer";
import activityLogging from "./store/activitylogging/reducer";
import pactrackerReducer from "./store/pacTracker/reducer";
import fetrackerReducer from "./store/feTracker/reducer";
import notiReducer from "./store/userNotification/reducer";
import mailGunEmailReducer from "./store/mailgunEmails/reducer";
import payfileReducer from "./store/payfile/reducer";
import payfileTwoReducer from "./store/payfileTwo/reducer";
import saveSearch from "./store/saveSearch/reducer";
import grievancesReducer from "./store/grievance/reducer";
import financeReducer from "./store/finance/reducer";
import registrationsReducer from "./store/registrations/reducer";
import areasReducer from "./store/areas/reducer";

import { Provider } from "react-redux";
import "./assets/css/mdb.css";
import { AuthProvider } from "./Auth/Auth";
import "./App.css";
import history from "../src/modules/Shared/history";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import SignalRMiddleware from "./store/middleware/signalr-middleware";
// import ErrorBoundary from "./modules/Shared/ErrorBoundry";
// const logger = (storeAPI) => (next) => (action) => {
//   console.log("dispatching", action);
//   let result = next(action);
//   console.log("next state", storeAPI.getState());
//   return result;
// };
const middlewares = [thunk, SignalRMiddleware];

const rootReducer = combineReducers({
  ui_red: reducer,
  form: formReducer,
  advancedFilter: advancedFilterReducer,
  facilityDashboard: facilityDashboardReducer,
  appPermissions: permissionsReducer,
  permission: permissionReducer,
  activityLogging: activityLogging,
  saveSearch: saveSearch,
  editUser: EditUserReducer,
  payfile: payfileReducer,
  payfileTwo: payfileTwoReducer,
  pacTracker: pactrackerReducer,
  fetracker: fetrackerReducer,
  notification: notiReducer,
  mailGunEmail: mailGunEmailReducer,
  grievances: grievancesReducer,
  registrations: registrationsReducer,
  finance: financeReducer,
  areas: areasReducer,
});
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
const app = (
  // <ErrorBoundary>
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
  // </ErrorBoundary>
);
// Define a function to conditionally import React DevTools
// const enableReactDevTools = () => {
//   if (process.env.NODE_ENV === "development") {
//     import("react-devtools").then((module) => {
//       const devTools = module.default;
//       devTools.connect(); // Optionally connect to the devtools backend
//     });
//   }
// };
//enableReactDevTools();
//if (process.env.NODE_ENV === "development") {
//  ReactDOM.render(
//    <React.StrictMode>{app}</React.StrictMode>,
//    document.getElementById("root")
//  );
//} else {
ReactDOM.render(app, document.getElementById("root"));
/*}*/
serviceWorker.unregister();
export { store };
