export const QuestionTypes = Object.freeze({
  Text: 1,
  DropDown: 2,
  Switch: 3,
  Checkboxes: 4,
  Radiobuttons: 5,
  Email: 6,
});

export const RegistrationTableTypes = Object.freeze({
  MyRegistrations: 1,
  OpenRegistrations: 2,
  AdminRegistrations: 3, // Chid Templates
  MemberProfileRegistrations: 4,
  ReviewRequests: 5,
});

export const RegistrationStatuses = Object.freeze({
  ///Pre Start
  InProgress: 1,
  PendingApproval: 2,
  NotAccepted: 3,
  Registered: 4,
  Cancelled: 5,
  ///Post Start Date
  Attended: 6,
  NoShow: 7,
  Deleted: 8,
  //PaymentFailed: 9,
  PendingRegistration: 10,
});
export const ReviewRequestActions = Object.freeze({
  Approve: 1,
  Deny: 2,
  ApproveAll: 3,
  DenyAll: 4,
  ApproveAndDenyOthers: 5,
  DenyAndApproveOthers: 6,
});
export const CompositionModalMode = Object.freeze({
  Email: 1,
  SMS: 2,
});
export const PaymentStatuses = Object.freeze({
  Success: 1,
  UnPaid: 2,
  Processing: 4,
  RequiresPaymentMethod: 5,
  Failed: 5,
  RequiresAction: 6, //Customer did not complete the checkout
  NotApplicable: 7, // Means registrations with non required fee questions has been submitted by not answering fee questions.
});
export const RegisWizardCloseActions = Object.freeze({
  CancelRegis: 1,
  SaveAndClose: 2,
  JustClose: 3,
  JustSaveAnswers: 4,
  Submit: 5,
});
export const RegisWizardSteps = Object.freeze({
  FirstStep: "firstStep",
  SecondStep: "secondStep",
  ThirdStep: "thirdStep",
  FourthStep: "fourthStep",
});
export const AcademyClassRoles = Object.freeze({
  LeadInstructor: 1,
  Instructor: 2,
  Guest: 3,
  Student: 4,
  EventAdmin: 5,
});

///DropDown Lists used in Review-Request screen
export const RegistrationStatusesOptions = [
  { status: "Pending Approval", id: 2, checked: false },
  { status: "Not Accepted", id: 3, checked: false },
  { status: "Registered", id: 4, checked: false },
  { status: "Attended", id: 6, checked: false },
  { status: "No Show", id: 7, checked: false },
];
export const RegisTableType = Object.freeze({
  ReviewRequest: 1,
  MarkAttendance: 2,
  AdditionalRegistrants: 3,
});
export const SessionPaymentStatus = Object.freeze({
  no_payment_required: 1,
  paid: 2,
  unpaid: 3,
});
export const SessionStaus = Object.freeze({
  open: 1,
  complete: 2,
  expired: 3,
});
export const ChildTemplateStatuses = Object.freeze({
  Active: 1,
  Completed: 2,
  Cancelled: 3,
  InProgress: 4,
});

export const DiscountTypes = Object.freeze({
  1: 'Percentage',
  2: 'Fixed',
});
