import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import AssignMemberList from "./AssignMemberList";
import Services from "../PermissionService";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
class AssignMemberModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberId: this.props.MemberId,
      data: [],
      openModal: false,
      ModalHeading: "AssignMemberModal",
      position: null,
    };
    this.cancelModal = this.cancelModal.bind(this);
    this.saveModal = this.saveModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.setMember = this.setMember.bind(this);
    this.setMemberObj = this.setMemberObj.bind(this);
    this.setAssignMember = this.setAssignMember.bind(this);
    this.createMemberObj = this.createMemberObj.bind(this);
  }
  componentWillUnmount() {}
  setMemberObj(Obj) {
    this.state.position.MemberObj = Obj;
    this.setState({ position: this.state.position });
  }
  setMember(Obj) {
    this.state.position.Member = Obj;
    this.setState({ position: this.state.position });
  }

  setAssignMember(Member, Index) {
    this.state.position.MemberName = Member.MemberId
      ? this.state.position.MemberObj.Data[Index].name
      : null;
    this.state.position.Member = Member;
    this.setState({ position: this.state.position });
  }
  async saveModal() {
    $(".loading").show();
    if (this.state.position.Member.MemberId == null) {
      $(".loading").hide();
      toast.error("Didn't select any member to assign!");
      return;
    }

    let data = await Services.EditPositionMember({
      teamID: this.props.teamId,
      positions: [this.state.position],
    });
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        toast.success("position edited successfully!");

        let teamID = this.props.teamId;
        let url = `/api/FacilityRegionEmail/SyncTeamMembers?teamId=${teamID}`;

        this.props.setReloader();
        this.cancelModal();

        axios.post(url).then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
          } else {
          }
        });
      }
    }
  }
  createMemberObj() {
    let { position, member } = this.props;

    let MemberObj = {
      SearchTerms: "",
      Offset: 0,
      Fetch: 10,
      Data: [],
      IsMore: true,
      FetchedFirst: true,
    };

    let Member = {
      ID: null,
      MemberId: null,
      StartDate: null,
      EndDate: null,
    };
    if (member != null) {
      Member = {
        ID: member.id,
        MemberId: member.memberID,
        StartDate: member.startDate,
        EndDate: member.endDate,
      };
    }
    let obj = {
      id: position.id,
      PositionName: position.positionName,
      MemberObj: MemberObj,
      Member: Member,
      MemberName: member != null ? member.memberName : null,
    };
    this.setState({ position: obj });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.permissionState.openAssignMemberModal &&
      !_.isEmpty(this.props.position)
    ) {
      this.createMemberObj();
      this.setState({
        openModal: this.props.permissionState.openAssignMemberModal,
      });
      this.openModal();
    }
  }
  componentDidMount() {}

  cancelModal() {
    this.setState({ openModal: false });
  }
  openModal() {
    this.props.closeAssignMemberModal();
  }
  render() {
    return (
      <AUX>
        <Modal
          id={this.state.ModalHeading}
          className="styled-modal"
          isOpen={this.state.openModal}
          size={!this.props.onlyDates ? "lg" : "md"}
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader
            toggle={this.cancelModal}
            className="modal-header text-uppercase bg-secondary py-2"
          >
            {!this.props.onlyDates ? "assign member" : "update Dates"}
          </ModalHeader>
          <ModalBody className="modal-body py-2">
            <AssignMemberList
              saveModal={this.saveModal}
              cancelModal={this.cancelModal}
              position={this.state.position}
              teamPosition={this.props.position}
              setAssignMember={this.setAssignMember}
              setMemberObj={this.setMemberObj}
              setMember={this.setMember}
              onlyDates={this.props.onlyDates}
            />
          </ModalBody>
        </Modal>
      </AUX>
    );
  }
}

export default connect((state) => ({ permissionState: state.permission }), {
  ...PermissionAction,
})(AssignMemberModal);
