import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Payfile/payfilestyle.css";
import { connect } from "react-redux";
import FinanceAction from "../../../store/finance/action";
import { makeStyles } from "@material-ui/core/styles";
import Services from "../../Payfile/PayfileService";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { debounce } from "throttle-debounce";
import TextField from "@material-ui/core/TextField";
import AuthService from "../../Auth/AuthService";
import FinanceService from "../FinanceService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { MDBInput } from "mdbreact";
import { toast } from "react-toastify";
import $ from "jquery";
import status from "../../Shared/MemberStatuses";
let formate_textbox_amount = (n) => {
  var num_parts = n
    .toString()
    .replaceAll(",", "")
    .split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
  //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};
let duesAmountObj = {
  MemberNumber: null,
  MemberID: null,
  DuesPostedTypeId: 2,
  ExpectedAmount: null,
};
let formObject = {
  Id: null,
  FacilityId: null,
  EmployerId: null,
  DuesList: [],

  DuesCollectionTypeId: null,

  FinanceTypeId: null,
  RegionId: null,
  Period: null,
  Year: null,
};
//Dues ka object server se bn kr aega
//Us me humey sirf dues posted type ki drop down or expected amount poochnee hai
//
class FinanceFacEmpFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Step2: false,
      FacilityObj: null,
      openModal: false,
      updateData: [],
      Members: [],
      FacilityCode: null,
      Employer: null,
      FormCode: -1,
      SubmissionObj: _.clone(formObject),
      BackUp_SubmissionObj: {},
      DuesCollectionTypes: [],
      DuesPostedTypes: [],
      FinanceTypes: [],
      Facilities: [],
      FinanceLoader: true,
    };
    this.closeModal = this.closeModal.bind(this);

    this.changeHandlerDropdown = this.changeHandlerDropdown.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.submitForm = this.submitForm.bind(this);
    this.step2Form = this.step2Form.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.loadDropdowns = this.loadDropdowns.bind(this);
    this.handlePeriodYearChange = this.handlePeriodYearChange.bind(this);
    this.PeriodYearValidation = this.PeriodYearValidation.bind(this);
    this.amountValidation = this.amountValidation.bind(this);
    this.amountDuesListValidation = this.amountDuesListValidation.bind(this);
    this.handleDuesListAmountChange = this.handleDuesListAmountChange.bind(
      this
    );
    this.changeHandlerDuesListDropdown = this.changeHandlerDuesListDropdown.bind(
      this
    );
    this.DropDownDuesListValidate = this.DropDownDuesListValidate.bind(this);

    //        this.SetFacilityObj = this.SetFacilityObj.bind(this);
  }
  ///**
  // * We have to set facility in an objec instead or putting value directly in autocomplete. Because autocomplete set only state values*/
  //async SetFacilityObj() {
  //    let { Facilities } = this.state;
  //    let facilityObj = !_.isEmpty(Facilities) && Facilities.find(x => x.id == this.state.SubmissionObj.FacilityId);
  //    if (JSON.stringify(facilityObj) != JSON.stringify(this.state.FacilityObj)) {

  //        await this.setState({FacilityObj: facilityObj});
  //    }
  //}
  async ResetObject() {
    let obj = _.clone(formObject);
    //in case objec already reset then not update the state for componentdidupdate method
    if (JSON.stringify(obj) != JSON.stringify(this.state.SubmissionObj)) {
      await this.setState({
        MemberId: -1,
        //FinanceID
        FI: -1,
        SubmissionObj: obj,
        BackUp_SubmissionObj: _.cloneDeep(obj),
      });
    }
  }
  MemberNumberSearchHandler = (SearchTerms) => {
    this.setState({ searchTerm: SearchTerms }, () => {
      this.autocompleteSearchDebounced();
    });
  };
  loadMemberData = async (SearchTerms) => {
    let data = await Services.LoadFinanceMembers(SearchTerms, 0, 5);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.setState({ Members: data.result });
      }
    }
  };

  componentDidMount() {
    this.loadMemberData("");
    this.loadDropdowns();
  }
  async loadDropdowns() {
    let data = await FinanceService.FinanceDropdowns();
    await this.setState({
      FinanceLoader: true,
    });

    if (data != null) {
      this.state.DuesCollectionTypes = data.result.duesCollectionTypeDD;
      this.state.DuesPostedTypes = data.result.duesPostedTypeDD;
      this.state.FinanceTypes = data.result.financeTypeDD;
      this.state.Facilities = data.result.facilityDD;
    }
    await this.setState({
      DuesCollectionTypes: this.state.DuesCollectionTypes,
      DuesPostedTypes: this.state.DuesPostedTypes,
      Facilities: this.state.Facilities,
      FinanceTypes: this.state.FinanceTypes,
      FinanceLoader: false,
    });
  }

  amountDuesListValidation(amount, index, fieldName) {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    let error = "";
    if (!amount || amount == null || amount == "" || amount == undefined) {
      error = "Invalid Amount!";
    } else if (amountReg.test(amount)) {
      error = "";
    } else {
      error = "Invalid amount";
    }
    this.state.SubmissionObj.DuesList[index][fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  amountValidation(amount, fieldName) {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    let error = "";
    if (!amount || amount == null || amount == "" || amount == undefined) {
      error = "Invalid Amount!";
    } else if (amountReg.test(amount)) {
      error = "";
    } else {
      error = "Invalid amount";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  handlePeriodYearChange = (evt) => {
    this.PeriodYearValidation(evt.target.value, evt.target.name);
    this.state.SubmissionObj[evt.target.name] = evt.target.value;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  PeriodYearValidation(val, fieldName) {
    let error = "";
    if (!val || val == null || val == "" || val < 1) {
      error = "Invalid Value!";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  handleAmountChange = (evt) => {
    this.amountValidation(
      evt.target.value.replaceAll(",", ""),
      evt.target.name
    );
    this.state.SubmissionObj[evt.target.name] = evt.target.value.replaceAll(
      ",",
      ""
    );

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  handleDuesListAmountChange = (evt) => {
    let index = evt.target.dataset.key;
    this.amountDuesListValidation(
      evt.target.value.replaceAll(",", ""),
      index,
      evt.target.name
    );
    this.state.SubmissionObj.DuesList[index][
      evt.target.name
    ] = evt.target.value.replaceAll(",", "");

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };

  changeHandlerDropdown = (e) => {
    this.state.SubmissionObj[e.target.name] = e.target.value;

    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    this.DropDownValidate(e.target.value, e.target.name);
  };
  changeHandlerDuesListDropdown = (e) => {
    let index = e.target.dataset.key;
    this.state.SubmissionObj.DuesList[index][e.target.name] = e.target.value;

    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    this.DropDownDuesListValidate(e.target.value, index, e.target.name);
  };

  changeHandler_amount = (e) => {
    this.state.SubmissionObj[e.target.name] = e.target.value;
    this.state.SubmissionObj[e.target.name + "_err"] = this.amountValidation(
      e.target.value
    ).error;

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };

  async handleChangeMemberNumber(value) {
    if (this.state.SubmissionObj != null) {
      if (value != null && value != undefined && value != "") {
        this.state.SubmissionObj.MemberNumber = value.memberNumber;
        this.state.SubmissionObj.MemberID = value.id;
        this.state.SubmissionObj.FacilityId = value.facilityID;
        this.state.FacilityCode = value.facility;
        this.state.SubmissionObj.RegionId = value.regionID;
      } else {
        this.state.SubmissionObj.MemberNumber = null;
        this.state.SubmissionObj.MemberID = null;
        this.state.SubmissionObj.FacilityId = null;
        this.state.FacilityCode = null;
        this.state.SubmissionObj.RegionId = null;
      }

      this.MemberIdValidate(this.state.SubmissionObj.MemberID);
      this.setState({
        SubmissionObj: this.state.SubmissionObj,
        FacilityCode: this.state.FacilityCode,
      });
    }
  }
  MemberIdValidate(val) {
    let error = "";
    if (val == null || val == undefined || val == "") {
      error = "Please, provide appropriate Member Number.";
    }
    this.state.SubmissionObj.MemberNumber_err = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  DropDownDuesListValidate(val, index, fieldName) {
    let error = "";
    if (!val || val == null || val == "" || val < 1) {
      error = "Please, select appropriate value";
    }
    this.state.SubmissionObj.DuesList[index][fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  DropDownValidate(val, fieldName) {
    let error = "";
    if (!val || val == null || val == "" || val < 1) {
      error = "Please, select appropriate value";
    }
    this.state.SubmissionObj[fieldName + "_Err"] = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }

  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.loadMemberData(this.state.searchTerm);
  };
  async step2Form() {
    let obj = this.state.SubmissionObj;
    if (
      this.DropDownValidate(obj.FacilityId, "FacilityId") != "" &&
      this.DropDownValidate(obj.EmployerId, "EmployerId") != ""
    ) {
      toast.error("Invalid Data!");
      this.cancelUpdate();
    }

    if (
      this.DropDownValidate(obj.DuesCollectionTypeId, "DuesCollectionTypeId") ==
        "" &&
      this.DropDownValidate(obj.FinanceTypeId, "FinanceTypeId") == "" &&
      this.PeriodYearValidation(obj.Period, "Period") == "" &&
      this.PeriodYearValidation(obj.Year, "Year") == ""
    ) {
      $(".loading").show();
      var data = await FinanceService.GetFinanceDuesMember(
        obj.FacilityId,
        obj.employerId
      );
      if (data && !_.isEmpty(data.result)) {
        obj.DuesList = data.result
          .map((x, idx) => {
            if (
              !AuthService.canSPView(
                PermissionFeature.FinanceMember,
                x.regionId,
                x.facilityId,
                x.memberID
              ) ||
              !AuthService.canSPAdd(
                PermissionFeature.FinancesRecords,
                x.regionId,
                x.facilityId,
                x.memberID
              ) ||
              !(
                (AuthService.canSPView(
                  PermissionFeature.PersonalInfoFirstName,
                  x.regionId,
                  x.facilityId,
                  x.memberID
                ) &&
                  AuthService.canSPView(
                    PermissionFeature.PersonalInfoLastName,
                    x.regionId,
                    x.facilityId,
                    x.memberID
                  )) ||
                AuthService.canSPView(
                  PermissionFeature.PersonalInfoMemberNumber,
                  x.regionId,
                  x.facilityId,
                  x.memberID
                )
              )
            ) {
              return undefined;
            }
            return x;
          })
          .filter((x) => x);
        await this.setState({ SubmissionObj: obj, Step2: true });
      } else {
        toast.error("No member exist on the selected filter!");
      }
      $(".loading").hide();
    }
  }
  async submitForm() {
    let obj = this.state.SubmissionObj;
    let isvalid = false;
    if (!_.isEmpty(obj.DuesList)) {
      isvalid = true;
    }
    $(".loading").show();
    obj.DuesList.forEach((x, idx) => {
      if (
        this.DropDownDuesListValidate(
          x.duesPostedTypeId,
          idx,
          "duesPostedTypeId"
        ) == "" &&
        this.amountDuesListValidation(
          x.expectedAmount,
          idx,
          "expectedAmount"
        ) == ""
      ) {
      } else {
        isvalid = false;
      }
    });
    if (isvalid) {
      var data = await FinanceService.CreateFinanceFacEmp(
        this.state.SubmissionObj
      );

      if (data && data.statusCode == 200) {
        this.props.refreshFinanceTable();
        this.closeModal();
      }
    }
    $(".loading").hide();
  }

  async componentDidUpdate(prevprops) {
    if (this.props.Finance.openFinanceFacEmpModal) {
      if (
        !AuthService.canView(PermissionFeature.FinanceMember) ||
        !AuthService.canView(PermissionFeature.FinanceExpAmount) ||
        !AuthService.canAdd(PermissionFeature.FinancesRecords) ||
        !AuthService.canView(PermissionFeature.FinanceDuesPostedType)
      ) {
        toast.error("You don't have enough permission to create the finance.");
        this.cancelUpdate();
      } else {
        await this.ResetObject();
        this.state.SubmissionObj.FacilityId = this.props.Finance.facilityId;
        this.state.SubmissionObj.RegionId = this.props.Finance.regionId;
        this.state.SubmissionObj.EmployerId = this.props.Finance.employerId;
        this.state.FacilityCode = this.props.Finance.facilityCode;
        this.state.Employer = this.props.Finance.employer;
        await this.setState({
          SubmissionObj: this.state.SubmissionObj,
          FacilityCode: this.state.FacilityCode,
          Employer: this.state.Employer,
        });

        this.openModal();
      }
    }
  }

  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeFinanceFacEmpModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
    // to clear the header
  }
  cancelUpdate() {
    this.setState({
      SubmissionObj: this.state.BackUp_SubmissionObj,
      FormCode: -1,
      Step2: false,
    });
    this.closeModal();
  }

  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          fontSize: 18,
        },
      },
    });
    let {
      DuesCollectionTypes,
      Facilities,
      Members,
      DuesPostedTypes,
      FinanceTypes,
    } = this.state;
    let obj = this.state.SubmissionObj;
    let { MemberHeaderData } = this.props.Finance;
    let header = "";

    if (MemberHeaderData != null) {
      header =
        " - " +
        (MemberHeaderData.firstName || "").toUpperCase() +
        " " +
        (MemberHeaderData.lastName || "").toUpperCase() +
        " (" +
        (!_.isEmpty(MemberHeaderData.regionCode)
          ? MemberHeaderData.regionCode
          : "--") +
        "/" +
        (!_.isEmpty(MemberHeaderData.facilityCode)
          ? MemberHeaderData.facilityCode
          : "--") +
        ")";
    }
    return (
      <>
        <Modal
          centered={true}
          autoFocus={true}
          isOpen={this.state.openModal}
          className="errorignoremodel styled-modal"
          backdrop="static"
        >
          <ModalHeader
            className="modal-header bg-secondary py-2"
            toggle={this.cancelUpdate}
          >
            FINANCE FORM{header}
          </ModalHeader>
          <ModalBody>
            {!this.state.Step2 ? (
              <div className="row">
                {this.state.FinanceLoader ? (
                  <div
                    class="m-3 position-relative d-block mx-auto spinner-border text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  !this.state.FinanceLoader &&
                  obj != null && (
                    <>
                      <div class="col-12">
                        This Finance will assign to{" "}
                        {this.state.FacilityCode && (
                          <>
                            the Facility (<b>{this.state.FacilityCode}</b>)
                          </>
                        )}
                        {this.state.FacilityCode && this.state.Employer && (
                          <> and </>
                        )}
                        {this.state.Employer && (
                          <>
                            the Employer (<b>{this.state.Employer}</b>)
                          </>
                        )}{" "}
                        Members.
                      </div>

                      <div className="col-12">
                        <div className="">
                          <div className="row mt-2 mb-2">
                            <div className="col-sm-4">
                              <label className="ml-0">Period</label>
                              <input
                                type="text"
                                id="keyword"
                                name="Period"
                                value={obj.Period}
                                className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                placeholder="PayPeriod"
                                onChange={this.handlePeriodYearChange}
                              />
                              <label className="text-danger">
                                {obj.Period_Err}
                              </label>
                            </div>
                            <div className="col-sm-4">
                              <label className="ml-0">Year</label>
                              <input
                                type="text"
                                id="keyword"
                                value={obj.Year}
                                name="Year"
                                className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                placeholder="Year"
                                onChange={this.handlePeriodYearChange}
                              />
                              <label className="text-danger">
                                {obj.Year_Err}
                              </label>
                            </div>

                            <div className="col-sm-4">
                              <label>Finance Type</label>
                              <select
                                //className="browser-default custom-select"
                                onChange={this.changeHandlerDropdown}
                                name="FinanceTypeId"
                                className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                              >
                                <option value="">Select Value</option>

                                {FinanceTypes.map((item, index) => {
                                  return (
                                    <option
                                      value={item.id}
                                      selected={obj.FinanceTypeId == item.id}
                                    >
                                      {item.type}
                                    </option>
                                  );
                                })}
                              </select>
                              <label className="text-danger">
                                {obj.FinanceTypeId_Err}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12" id="FinanceDetail">
                        <div className="">
                          <div className="row mt-2 mb-2">
                            <div className="col-sm-4">
                              <label>Dues Collection Type</label>
                              <select
                                //className="browser-default custom-select"
                                onChange={this.changeHandlerDropdown}
                                name="DuesCollectionTypeId"
                                className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                              >
                                <option value="">Select Value</option>

                                {DuesCollectionTypes.map((item, index) => {
                                  return (
                                    <option
                                      value={item.id}
                                      selected={
                                        obj.DuesCollectionTypeId == item.id
                                      }
                                    >
                                      {item.type}
                                    </option>
                                  );
                                })}
                              </select>
                              <label className="text-danger">
                                {obj.DuesCollectionTypeId_Err}
                              </label>
                            </div>
                            <div className="clearfix" />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
            ) : (
              this.state.Step2 && (
                <div>
                  <>
                    <div className="col-12">
                      <div className="">
                        <div className="row mt-2 mb-2">
                          <div className="col-3">Name</div>

                          <div className="col-sm-3">Amount</div>
                          <div className="col-sm-4">Dues Posted Type</div>

                          <div className="col-sm-2">Action</div>
                        </div>
                        {obj.DuesList.map((x, idx) => {
                          return (
                            <div className="row mt-2 mb-2" data-key={idx}>
                              <div className="col-3">
                                {AuthService.canSPView(
                                  PermissionFeature.FinanceMember,
                                  x.regionId,
                                  x.facilityId,
                                  x.memberID
                                ) && (
                                  <>
                                    {AuthService.canSPView(
                                      PermissionFeature.PersonalInfoFirstName,
                                      x.regionId,
                                      x.facilityId,
                                      x.memberID
                                    ) && x.firstName + " "}
                                    {AuthService.canSPView(
                                      PermissionFeature.PersonalInfoNickname,
                                      x.regionId,
                                      x.facilityId,
                                      x.memberID
                                    ) &&
                                      x.nickName &&
                                      `"${x.nickName} "`}

                                    {AuthService.canSPView(
                                      PermissionFeature.PersonalInfoLastName,
                                      x.regionId,
                                      x.facilityId,
                                      x.memberID
                                    ) && x.lastName + " "}
                                    {AuthService.canSPView(
                                      PermissionFeature.PersonalInfoMemberNumber,
                                      x.regionId,
                                      x.facilityId,
                                      x.memberID
                                    ) && <b>({x.memberNumber})</b>}
                                  </>
                                )}
                              </div>
                              <div className="col-3">
                                {AuthService.canMemberUpdate(
                                  PermissionFeature.FinanceExpAmount
                                ) && (
                                  <div className="">
                                    <MDBInput
                                      containerClass="mt-2 mb-2 small-prefix"
                                      className="py-0 mb-0 ml-3"
                                      name="expectedAmount"
                                      data-key={idx}
                                      onChange={this.handleDuesListAmountChange}
                                      hint="$$"
                                      icon="dollar-sign"
                                      value={
                                        x.expectedAmount != null
                                          ? formate_textbox_amount(
                                              x.expectedAmount
                                            )
                                          : ""
                                      }
                                      group
                                      type="text"
                                    />
                                    <span className="d-block text-danger">
                                      {x.expectedAmount_Err}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-4">
                                <select
                                  //className="browser-default custom-select"
                                  onChange={this.changeHandlerDuesListDropdown}
                                  name="duesPostedTypeId"
                                  data-key={idx}
                                  className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
                                >
                                  <option value="">Select Value</option>

                                  {DuesPostedTypes.map((item, index) => {
                                    return (
                                      <option
                                        value={item.id}
                                        selected={x.duesPostedTypeId == item.id}
                                      >
                                        {item.type}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label className="text-danger">
                                  {x.duesPostedTypeId_Err}
                                </label>
                              </div>
                              <div className="col-2 text-center text-danger">
                                <i
                                  className="c-pointer fa fa-times h4"
                                  onClick={(e) => {
                                    obj.DuesList = obj.DuesList.filter(
                                      (a) => a.memberID !== x.memberID
                                    );
                                    this.setState({ SubmissionObj: obj });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                </div>
              )
            )}
          </ModalBody>
          <ModalFooter>
            <div class="row w-100 mx-0">
              <div className="col-4 text-left">
                {this.state.Step2 && (
                  <button
                    class="btn btn-success btn-x-sm btn-sm waves-effect waves-light w-md"
                    onClick={() => {
                      this.setState({ Step2: false });
                    }}
                  >
                    Back
                  </button>
                )}
              </div>
              <div className="text-right col-8">
                <Button
                  className="btn btn-danger btn-sm w-md waves-effect waves-light mr-2"
                  onClick={this.cancelUpdate}
                >
                  Cancel
                </Button>
                {!this.state.Step2 ? (
                  <button
                    class="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                    onClick={this.step2Form}
                  >
                    Next
                  </button>
                ) : (
                  this.state.Step2 && (
                    <button
                      class="btn btn-success btn-x-sm btn-sm float-right ml-2 w-md waves-effect waves-light plr-2"
                      onClick={this.submitForm}
                    >
                      Submit
                    </button>
                  )
                )}
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(FinanceFacEmpFormModal);
