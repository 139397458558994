import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../payfilestyle.css";
import { connect } from "react-redux";
import PayfileTwoAction from "../../../store/payfileTwo/action";
import _ from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import AuthService from "../../Auth/AuthService";

const supervisor = [
  {
    text: "NO",
    value: "NO",
  },
  {
    text: "YES",
    value: "YES",
  },
  {
    text: "TEMP",
    value: "TEMP",
  },
];

class JobHistoryFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      Supervisor: "",
      JobDetail: null,
    };
    this.closeModal = this.closeModal.bind(this);
    this.changeHandlerIsSupervisor = this.changeHandlerIsSupervisor.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  UpdateDataSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageUpdateData: sizePerPage });
  };
  changeHandlerIsSupervisor = (e) => {
    this.state.JobDetail.isEdited = true;
    switch (e.target.value) {
      case "YES":
        this.state.JobDetail.isSupervisor = true;
        break;
      case "TEMP":
        this.state.JobDetail.isTempSupervisor = true;
        break;
      default:
        this.state.JobDetail.isSupervisor = false;
        this.state.JobDetail.isTempSupervisor = false;
        break;
    }
    this.setState({
      JobDetail: this.state.JobDetail,
      Supervisor_Val: e.target.val,
    });
  };
  requiredValidation(val, name, state) {
    let error = "";
    if (val == null || val == "") {
      error = "Please provide " + name + ".";
    }
    this.state.JobDetail[state] = error;
    this.setState({ JobDetail: this.state.JobDetail });
    return error;
  }
  dateValidate(date, name, state) {
    let error = "Please provide " + name + ".";
    if (moment(date).isValid()) {
      error = "";
    }
    this.state.JobDetail[state] = error;
    this.setState({ JobDetail: this.state.JobDetail });
    return error;
  }

  submitForm() {
    let obj = this.state.JobDetail;
    if (
      ((!obj.isSupervisor &&
        !obj.isTempSupervisor &&
        this.requiredValidation(
          obj.regionCode,
          "Region Code",
          "regionCode_Err"
        ) == "" &&
        this.requiredValidation(
          obj.facilityCode,
          "Facility Code",
          "facilityCode_Err"
        ) == "") ||
        obj.isSupervisor ||
        obj.isTempSupervisor) &&
      //this.requiredValidation(
      //  obj.organizationCode,
      //  "Organization Code",
      //  "organizationCode_Err"
      //) == "" &&
      //this.requiredValidation(obj.busTo, "Bus Code", "busTo_Err") == "" &&
      this.dateValidate(obj.startDate, "Start Date", "startDate_Err") == ""
    ) {
      this.props.jobUpdate(obj);
      this.closeModal();
    }
  }

  componentDidUpdate(prevprops) {
    if (this.props.payfileTwo.openJobHistoryFormModal) {
      this.openModal();

      if (this.props.JobDetail.isSupervisor) {
        this.state.isSupervisor = "YES";
      } else if (this.props.JobDetail.isTempSupervisor) {
        this.state.isSupervisor = "TEMP";
      } else {
        this.state.isSupervisor = "NO";
      }
      this.setState({
        JobDetail: this.props.JobDetail,
        Supervisor: this.state.Supervisor,
      });
    }
  }
  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeJobHistoryFormModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
  }
  render() {
    let { JobDetail } = this.state;
    return (
      <Modal
        centered={true}
        autoFocus={true}
        isOpen={this.state.openModal}
        className="errorignoremodel styled-modal"
        backdrop="static"
      >
        <ModalHeader
          className="modal-header bg-secondary py-2"
          toggle={this.closeModal}
        >
          Update Job Detail
        </ModalHeader>
        <ModalBody>
          {this.state.JobDetail && (
            <div className="row">
              <div class="col-12"></div>

              <div className="col-12">
                <div className="modalinput">
                  <div className="row mt-2 mb-2">
                    <div className="col-3">
                      <label>Region</label>
                      <input
                        placeholder="Region Code"
                        value={JobDetail.regionCode}
                        className="form-control"
                        onChange={(e) => {
                          this.state.JobDetail.isEdited = true;
                          this.state.JobDetail.regionCode = e.target.value;
                          if (
                            !this.state.JobDetail.isSupervisor &&
                            !this.state.JobDetail.isTempSupervisor
                          ) {
                            this.requiredValidation(
                              e.target.value,
                              "Region Code",
                              "regionCode_Err"
                            );
                          } else {
                            this.state.JobDetail.regionCode_Err = "";
                          }
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.regionCode_Err}
                      </span>
                    </div>
                    <div className="col-3">
                      <label>Facility</label>
                      <input
                        placeholder="Facility Code"
                        className="form-control"
                        value={JobDetail.facilityCode}
                        onChange={(e) => {
                          this.state.JobDetail.facilityCode = e.target.value;
                          this.state.JobDetail.isEdited = true;
                          if (
                            !this.state.JobDetail.isSupervisor &&
                            !this.state.JobDetail.isTempSupervisor
                          ) {
                            this.requiredValidation(
                              e.target.value,
                              "Facility Code",
                              "facilityCode_Err"
                            );
                          } else {
                            this.state.JobDetail.facilityCode_Err = "";
                          }
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.facilityCode_Err}
                      </span>
                    </div>
                    <div className="col-3">
                      <label>Org Code</label>
                      <input
                        placeholder="Organization Code"
                        value={JobDetail.organizationCode}
                        className="form-control"
                        onChange={(e) => {
                          this.state.JobDetail.organizationCode =
                            e.target.value;
                          this.state.JobDetail.isEdited = true;
                          //this.requiredValidation(
                          //  e.target.value,
                          //  "Organization Code",
                          //  "organizationCode_Err"
                          //);
                          this.setState({
                            JobDetail: this.state.JobDetail,
                          });
                        }}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.organizationCode_Err}
                      </span>
                    </div>
                    <div className="col-3">
                      <label>Bus Code</label>
                      <input
                        placeholder="Bus To Code"
                        value={JobDetail.busTo}
                        className="form-control"
                        onChange={(e) => {
                          this.state.JobDetail.isEdited = true;
                          this.state.JobDetail.busTo = e.target.value;
                          //this.requiredValidation(
                          //  e.target.value,
                          //  "Bus Code",
                          //  "busTo_Err"
                          //);
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.busTo_Err}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="modalinput">
                  <div className="row mt-2 mb-2">
                    <div className="col-3">
                      <label>Supervisor</label>
                      <select
                        //className="browser-default custom-select"
                        onChange={this.changeHandlerIsSupervisor}
                        name="status"
                        className="form-control"
                      >
                        {supervisor.map((item, index) => {
                          return (
                            <option
                              value={item.value}
                              selected={this.state.Supervisor == item.value}
                            >
                              {item.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-3">
                      <label>Start Date</label>
                      <DatePicker
                        dateForm="MM/DD/YYYY"
                        peekNextMonth
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(date) => {
                          this.state.JobDetail.isEdited = true;
                          this.dateValidate(
                            date,
                            "Start Date",
                            "startDate_Err"
                          );
                          if (moment(date).isValid()) {
                            this.state.JobDetail.startDate = moment(
                              date
                            ).format("YYYY-MM-DD");
                          } else if (date == null) {
                            this.state.JobDetail.startDate = null;
                          }

                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        selected={
                          JobDetail.startDate
                            ? moment(JobDetail.startDate).toDate()
                            : null
                        }
                        placeholderText={"MM/DD/YYYY"}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.startDate_Err}
                      </span>
                    </div>

                    <div className="col-3">
                      <label>End Date</label>
                      <DatePicker
                        dateForm="MM/DD/YYYY"
                        peekNextMonth
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(date) => {
                          this.state.JobDetail.isEdited = true;

                          if (date == null) {
                            this.state.JobDetail.endDate = null;
                          } else if (moment(date).isValid()) {
                            this.state.JobDetail.endDate = moment(date).format(
                              "YYYY-MM-DD"
                            );
                          }
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        selected={
                          JobDetail.endDate
                            ? moment(JobDetail.endDate).toDate()
                            : null
                        }
                        placeholderText={"MM/DD/YYYY"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div class="text-right">
            <Button
              className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
              onClick={this.closeModal}
            >
              Cancel
            </Button>

            <button
              class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
              type="submit"
              onClick={this.submitForm}
            >
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
export default connect((state) => ({ payfileTwo: state.payfileTwo }), {
  ...PayfileTwoAction,
})(JobHistoryFormModal);
