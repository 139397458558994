import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Modal } from "react-bootstrap";
import FETrackerAction from "../../../store/feTracker/action";
import $ from "jquery";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse, Card, CardBody } from "reactstrap";
import AUX from "../../../hoc/Aux_";
import { MDBInput } from "mdbreact";

import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import Services from "../../Permissions/PermissionService";
import FEServices from "../F&ERequests/FEService";
import { debounce } from "throttle-debounce";
import FERequestsType from "../FERequestsType";
import InventoryRequestStatus from "../InventoryRequestStatus";
import PermissionFeature from "../../Permissions/PermissionFeature";
import AuthService from "../../Auth/AuthService";

import { toast } from "react-toastify";
import FE_Tabs from "../FE_Tabs";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import AddMultiBudgetlines from "../AddMultiBudgetlines";
import FEService from "../F&ERequests/FEService";
const filter = createFilterOptions();
const initialState = {
  isEquipmentDetailsOpen: true,
  isEquipmentAccountingOpen: true,

  searchTermMembers: "",
  searchTermBudgetLines: "",
  inputValueEquipmentType: "",

  equipmentCategoryOptions: [
    { key: 1, val: "Team" },
    { key: 2, val: "Region" },
    // { key: 3, val: "Facility" },
    { key: 4, val: "National Office" },
  ],
  requestdate: null, //moment(new Date()).format("MM/DD/YYYY"),

  //Screen Dropdowns
  equipmentUser: {},
  equipmentBudgetline: {},

  //Multi selection of BudgetLines
  selectedEquipmentBudgetlines: [
    {
      showAuthorizedPerson: false,
      equipmentBudgetline: {},
      allocatedpct: "",
      budgetid: 0,
      id: 0,
      selectedOption: "",
    },
  ],
  budgetAuthorizer: [{}],
  budgetAuthorizerId: [{}],

  showAuthorizedPerson: [{}],

  savedBudgetlinesList: [],

  /////////////////////////
  equipmentType: null,
  equipmentCategory: null,
  equipmentFRT: null,
  date_err: "",
  requestid: 0,

  category: null,
  teamid: null,
  regionid: null,
  facilityid: null,
  nationalofficecategoryid: null,
  nationalofficecategoryname: "",

  verified: true,
  issubmit: false,
  description: "",
  currentyear: new Date().getFullYear() - 1,

  requestedAmount: "",
  approvedAmount: "",
  comments: "",
  status: -1,
  statusName: "",

  isConfirmModalOpen: false,

  approvedAmount_err: "",
  requestedAmount_err: "",
  allocatedpct_err: "",
};

class FE_RequestModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      isEquipmentDetailsOpen: true,
      isEquipmentAccountingOpen: true,
      isEquipmentHistoryOpen: true,

      usersOptions: [],
      budgetLinesOptions: [],
      equipmentFRTOptions: [],
      equipmentTypesOptions: [],
      regionsOptions: [],
      teamsOptions: [],
      facilitiesOptions: [],
      history: [],
      naOfficeSubCatsOptions: [],
    };
    this.autocompleteSearchDebouncedUsers = debounce(
      500,
      this.autocompleteSearchUsers
    );
    this.autocompleteSearchDebouncedBudgetLines = debounce(
      500,
      this.autocompleteSearchBudgetLines
    );
  }
  autocompleteSearchBudgetLines = () => {
    this._fetchBudgetLines();
  };
  // users dropdown events
  usersSearchHandler = (SearchTerms) => {
    this.setState({ searchTermMembers: SearchTerms }, () => {
      this.autocompleteSearchDebouncedUsers();
    });
  };
  autocompleteSearchUsers = () => {
    this._fetchUsersMembers();
  };
  _fetchUsersMembers = async () => {
    await this.loadUsersData(this.state.searchTermMembers);
  };
  _fetchBudgetLines = async () => {
    await this.loadBudgetLinesData();
  };

  // budget lines dropdown events
  budgetLinesSearchHandler = (SearchTerms) => {
    this.setState({ searchTermBudgetLines: SearchTerms }, () => {
      this.autocompleteSearchDebouncedBudgetLines();
    });
  };

  loadEquipmentDropDownOptions = async () => {
    let response = await FEServices.LoadEquipmentDropDownOptions();
    if (!_.isEmpty(response)) {
      var equipmentTypes = response.data[0];
      this.setState({ equipmentTypesOptions: equipmentTypes });

      var regions = response.data[1];
      this.setState({ regionsOptions: regions });

      var teams = response.data[2];
      this.setState({ teamsOptions: teams });

      var facilities = response.data[3];
      this.setState({ facilitiesOptions: facilities });

      var naOfficeSubCats = response.data[4];
      this.setState({ naOfficeSubCatsOptions: naOfficeSubCats });
    }
  };
  handleClose = () => {
    this.props.onClose();
    this.setState(initialState);
  };
  handleApproveRequest = async (e) => {
    e.preventDefault();
    this.props.onClose();
    const { id } = this.props.festate.currentMyRequestRow;
    const requestStatus = e.currentTarget.value;

    const formData = {
      id,
      requestStatus,
      comments: this.state.comments,
      approvedamount: this.state.approvedAmount,
      requestedamount: this.state.requestedAmount,
    };
    FEServices.showSpinner();
    let response = await FEServices.SubmitReview(formData);
    if (!_.isEmpty(response)) {
      toast.success("Request updated successfully");
    }
    FEServices.hideSpinner();

    this.reloadRequestTable();
    this.setState(initialState);
  };
  handleCloseRequest = async (e) => {
    e.preventDefault();
    this.props.onClose();
    const { id } = this.props.festate.currentMyRequestRow;
    //let comments = this.state.comments;
    FEServices.showSpinner();
    let response = await FEServices.RemoveRequest(id);
    if (!_.isEmpty(response)) {
      toast.success("Request updated successfully");
    }
    FEServices.hideSpinner();
    this.reloadRequestTable();
    this.setState(initialState);
  };

  componentDidMount() {
    $("#addEquipmentRequestForm").on("keyup keypress", function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        return false;
      }
    });
  }

  onShow = async () => {
    if (!_.isEmpty(this.props.festate.currentMyRequestRow)) {
      await this.loadEquipmentDropDownOptions();
      let savedRow = this.props.festate.currentMyRequestRow;
      var memberData = JSON.parse(localStorage.getItem("memberData"));
      if (savedRow.authorizer && savedRow.authorizer !== memberData.id) {
        ///this.setState({ showAuthorizedPerson: true });
      } else {
        ///this.setState({ showAuthorizedPerson: false });
      }
      await this.setState(
        {
          authorizer: savedRow.authorizer,
          budgetAuthorizer: savedRow.budgetAuthorizer,
          status: savedRow.status,
          statusName: savedRow.statusName,
          category: savedRow.category,
          requestid: savedRow.id,
          selectedEquipmentBudgetlines: savedRow.febudgetlines,
          equipmentFRTOptions:
            "Team" == savedRow.categoryName
              ? this.state.teamsOptions
              : "Region" == savedRow.categoryName
              ? this.state.regionsOptions
              : "Facility" == savedRow.categoryName
              ? this.state.facilitiesOptions
              : "National Office" == savedRow.categoryName
              ? this.state.naOfficeSubCatsOptions
              : [],
        },
        async () => {
          if (!_.isEmpty(savedRow.userName)) {
            var userNameList = savedRow.userName.split(" ");
            let userFLName = "";
            if (userNameList && userNameList.length == 1) {
              // incase there is no last name of the member, then send first name
              userFLName = `${userNameList[0].charAt(
                0
              )}${userNameList[0].charAt(1)}`;
            } else if (userNameList && userNameList.length > 1) {
              /// else there are(first and last name) so send "Ja Do" to the Api.
              userFLName = `${userNameList[0].charAt(
                0
              )}${userNameList[0].charAt(1)} ${userNameList[1].charAt(
                0
              )}${userNameList[1].charAt(1)}`;
            }
            ///Prefill the options list with selected Value
            await this.loadUsersData(userFLName);
          }
          //############## We wouldn't use it bcz of multiBudgetselection##############
          // if (!_.isEmpty(savedRow.budgetLineName)) {
          //   var budgetLineNameList = savedRow.budgetLineName.split(" ");
          //   let blFLName = "";
          //   if (budgetLineNameList && budgetLineNameList.length == 1) {
          //     // incase there is no last name of the bl, then send first name
          //     blFLName = `${budgetLineNameList[0].charAt(
          //       0
          //     )}${budgetLineNameList[0].charAt(1)}`;
          //   } else if (budgetLineNameList && budgetLineNameList.length > 1) {
          //     /// else there are(first and last name) so send "Ja Do" to the Api.
          //     blFLName = `${budgetLineNameList[0].charAt(
          //       0
          //     )}${budgetLineNameList[0].charAt(
          //       1
          //     )} ${budgetLineNameList[1].charAt(
          //       0
          //     )}${budgetLineNameList[1].charAt(1)}`;
          //   }
          //   await this.loadBudgetLinesData(blFLName);
          // }
          //############## We wouldn't use it bcz of multiBudgetselection##############s

          ///Load ALL budget lines

          await this.loadBudgetLinesData();

          await this.setState({
            equipmentUser:
              this.state.usersOptions.find((el) => el.id == savedRow.user) ||
              null,
            equipmentBudgetline:
              this.state.budgetLinesOptions.find(
                (el) => el.id == savedRow.budgetid
              ) || null,

            ///Teams, Region or Faility
            equipmentCategory:
              this.state.equipmentCategoryOptions.find(
                (el) => el.key == savedRow.category
              ) || null,

            //Team, Region Name
            equipmentFRT:
              this.state.equipmentFRTOptions.find(
                (el) => el.key == savedRow.frt
              ) || null,
            equipmentType:
              this.state.equipmentTypesOptions.find(
                (el) => el.key == savedRow.type
              ) || null,
          });
        }
      );

      await this.setState({
        teamid: savedRow.category == 1 ? savedRow.frt : null,
        regionid: savedRow.category == 2 ? savedRow.frt : null,
        facilityid: savedRow.category == 3 ? savedRow.frt : null,
        nationalofficecategoryid: savedRow.category == 4 ? savedRow.frt : null,

        description: savedRow.description,

        requestdate: moment(savedRow.requestdate).format("MM/DD/YYYY"),
        ///Accounting Details
        ///comments: savedRow.comments,
        requestedAmount: savedRow.requestedamount,
        approvedAmount: savedRow.approvedamount,
        history: savedRow.history,
        comments: "",
      });
    } else {
      await this.loadUsersData("");
      await this.loadEquipmentDropDownOptions();
      await this.loadBudgetLinesData();
      if (AuthService.canView(PermissionFeature.FERequestRequestDate)) {
        this.setState({
          requestdate: moment(new Date()).format("MM/DD/YYYY"),
        });
      }
    }
  };

  onSubmit = (evt) => {
    var keyCode = evt.keyCode || evt.which;
    if (keyCode === 13) {
      evt.preventDefault();
      return false;
    }

    evt.preventDefault();

    this.setState({ issubmit: true });

    if (_.isEmpty(this.state.requestdate)) {
      this.setState({ date_err: "Required" });
    }

    let isBudgetLineFormCorrupt = this.state.selectedEquipmentBudgetlines.some(
      (s) =>
        s.allocatedpct == 0 ||
        s.allocatedpct == undefined ||
        s.allocatedpct == "" ||
        s.budgetid == 0 ||
        s.budgetid == undefined
    );

    evt.target.className += " was-validated";
    if (
      evt.target.checkValidity() === false ||
      !_.isEmpty(this.state.date_err)
    ) {
      this.setState({ approvedAmount_err: "" });
      this.setState({ requestedAmount_err: "" });
      return false;
    }
    if (isBudgetLineFormCorrupt) {
      $("#addBudgetLineBtn").trigger("click");
      toast.warn("Please fill all the budget lines information.");
      return false;
    }

    let isValidPct = FEService.validateBudgetPercentages(this.state);
    if (!isValidPct) {
      toast.warn("Total Budgetline percentage should be 100.");
      return false;
    }
    var memberData = JSON.parse(localStorage.getItem("memberData"));
    const loggedInUserId = memberData.id;
    let anyAuthorizedRequesterInBudgetliens = this.state.selectedEquipmentBudgetlines.some(
      (s) => !s.showAuthorizedPerson
    );
    const formdata = {
      ///////Equipment Details//////
      id: this.state.requestid,

      description: this.state.description,
      user: _.isEmpty(this.state.equipmentUser)
        ? null
        : this.state.equipmentUser.id,
      type: _.isEmpty(this.state.equipmentType)
        ? null
        : this.state.equipmentType.key,

      requestdate: this.state.requestdate,
      // requester: this.state.requester, Logged in user
      budgetid: _.isEmpty(this.state.equipmentBudgetline)
        ? null
        : this.state.equipmentBudgetline.id,
      category: _.isEmpty(this.state.equipmentCategory)
        ? null
        : this.state.equipmentCategory.key,

      teamid: this.state.teamid,
      facilityid: this.state.facilityid,
      regionid: this.state.regionid,
      nationalofficecategoryid: this.state.nationalofficecategoryid,
      nationalofficecategoryname: this.state.nationalofficecategoryname,
      ////////Accounting details////////
      // authorizer:
      //   this.state.requestid == 0 && !this.state.showAuthorizedPerson
      //     ? loggedInUserId
      //     : this.state.showAuthorizedPerson && this.state.requestid == 0
      //     ? this.state.budgetAuthorizerId
      //     : this.state.authorizer,
      comments: this.state.comments,
      requestedamount: this.state.requestedAmount,
      approvedamount: this.state.requestedAmount,
      status:
        this.state.requestid == 0 && anyAuthorizedRequesterInBudgetliens // (Add new form and non-auth person logged-in)
          ? InventoryRequestStatus.PendingEVP
          : this.state.showAuthorizedPerson && this.state.requestid == 0 // (Add new form and auth person logged-in)
          ? InventoryRequestStatus.Requested
          : this.state.status,

      FERequestBudgetLines: this.state.selectedEquipmentBudgetlines,
    };
    //	If the Currently logged in user is the Approver for the selected Budget
    // ID the status will automatically be set to Pending EVP Approval
    this.addUpdateFeRequest(formdata);
  };
  addUpdateFeRequest = async (formdata) => {
    FEServices.showSpinner();
    let response = await FEServices.SaveFeRequest(formdata);
    if (!_.isEmpty(response)) {
      toast.success("Request saved successfully");
      this.reloadRequestTable();
      this.handleClose();
    }
    FEServices.hideSpinner();
  };
  reloadRequestTable = () => {
    const reloadAllReviewAccordions = [
      FERequestsType.ApprovedReviewRequests,
      FERequestsType.SubmittedReviewRequests,
      FERequestsType.PendingEVPReviewRequests,
    ];
    var pageNum = 1;
    var pageSize = 10;
    if (this.props.festate.activeSubHeader === FE_Tabs.MyRequests) {
      pageNum = this.props.festate.erPageNo || 1;
      pageSize = this.props.festate.erSizePerPage || 10;
    }
    //else in case of "Review-Requests" there are 3 three Tables
    else {
      this.props.setRequestsTablePaginationInfo(
        1,
        10,
        FERequestsType.ApprovedReviewRequests
      );
      this.props.setRequestsTablePaginationInfo(
        1,
        10,
        FERequestsType.SubmittedReviewRequests
      );
      this.props.setRequestsTablePaginationInfo(
        1,
        10,
        FERequestsType.PendingEVPReviewRequests
      );
    }
    let formData = {
      Keyword: "",
      isMyRequests:
        this.props.festate.activeSubHeader === FE_Tabs.MyRequests
          ? true
          : false,
      // feRequestsType:
      //   this.props.festate.activeSubHeader === FE_Tabs.MyRequests
      //     ? FERequestsType.MyRequests
      //     : requestType,
      feRequestsTypeList:
        this.props.festate.activeSubHeader === FE_Tabs.MyRequests
          ? [FERequestsType.MyRequests]
          : [...reloadAllReviewAccordions],

      showClosed: false,
      PageNo: pageNum, // My Requests List Current PageNo or 1 for Review-Requests
      PageSize: pageSize, // My Requests List PageSize or 10 for Review-Requests
      OperatorType: "AND",
      SortOrder: "id", //"firstnameAsc",
      isAdvanceSearch: this.props.festate.isFERequestAdvanceSearchApplied,
    };
    if (this.props.festate.isFERequestAdvanceSearchApplied) {
      formData = { ...formData, ...this.props.festate.filterData };
    }
    this.props.searchFeRequestsRecords(formData);
  };
  loadUsersData = async (SearchTerms) => {
    let data = await Services.LoadMembers(SearchTerms, 0, 100);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.usersOptions = data.result != null ? data.result : [];
        await this.setState({
          usersOptions: this.state.usersOptions,
        });
      }
    }
  };

  loadBudgetLinesData = async () => {
    let data = await FEServices.LoadAllBudgetLines();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.budgetLinesOptions = data.result != null ? data.result : [];
        await this.setState({
          budgetLinesOptions: this.state.budgetLinesOptions,
        });
      }
    }
  };
  // addRowClick = () => {
  //   for (var key of Object.keys(this.refs)) {
  //     // console.log(key + ": " + this.refs[key]);
  //     this.refs[key].className += " was-validated";
  //     if (this.refs[key].checkValidity() === false) {
  //       this.setState({ isValid: false });
  //       return false;
  //     }
  //   }
  //   this.setState({
  //     selectedEquipmentBudgetlines: [
  //       ...this.state.selectedEquipmentBudgetlines,
  //       {
  //         equipmentBudgetline: 0,
  //         budgetlinePercentage: 0,
  //       },
  //     ],
  //   });
  // };
  // removeRow = (budgetid, idx) => {
  //   if (budgetid > 0) {
  //     this.setState({ delId: budgetid });
  //     this.setState({ dataIdx: idx });
  //   }

  //   this.setState({
  //     selectedEquipmentBudgetlines: this.state.selectedEquipmentBudgetlines.filter(
  //       (el, index) => {
  //         return index !== idx;
  //       }
  //     ),
  //   });
  // };
  // showSelectedBudgetline = (idx) => {
  //   let savedBudgetId =
  //     this.state.selectedEquipmentBudgetlines &&
  //     this.state.selectedEquipmentBudgetlines[idx].budgetid;
  //   let selectedOption =
  //     this.state.budgetLinesOptions.find((el) => el.id == savedBudgetId) ||
  //     null;
  //   return selectedOption;
  // };
  setBudgetLineFormInvalid = () => {
    this.setState({ invalidBudgetForm: true });
  };
  render() {
    const isBothModalsOpen =
      this.props.festate.openEquipmentModal &&
      this.props.festate.openMyRequestModal;
    const isRequested = this.state.status >= InventoryRequestStatus.Requested;
    const isApproved = this.state.status === InventoryRequestStatus.Approved;

    const isCancelAble =
      this.state.status !== InventoryRequestStatus.Denied &&
      this.state.status !== InventoryRequestStatus.Closed &&
      this.state.requestid != 0;
    ///console.log("isCancelAble", isCancelAble);
    const ShowCancelBtn =
      this.props.festate.isReviewRequestOpen &&
      this.state.status >= InventoryRequestStatus.PendingEVP &&
      this.props.festate.isBudgetlineApprover &&
      !this.props.festate.isEVPApprover;

    const ShowPendingEVPBtn =
      ((this.props.festate.isReviewRequestOpen &&
        this.state.status === InventoryRequestStatus.Requested) ||
        (this.props.festate.isBudgetlineApprover &&
          this.state.status === InventoryRequestStatus.Requested &&
          this.props.festate.activeSubHeader !== FE_Tabs.MyRequests)) &&
      !this.props.festate.isEVPApprover; //Don't show Pending EVP actions for Submitted Requests in Review Screen for EVP Persons
    //show cancel request btn incase request is in submitted status
    //i.e it will be shown for Requested, and PendingEVP status
    // console.log(
    //   "FERequestComments",
    //   AuthService.canUpdate(PermissionFeature.FERequestComments)
    // );
    ///console.log("ShowCancelBtn", ShowCancelBtn);
    const allDisabled = FEServices.isAllDisabled(
      PermissionFeature.FERequestUser,
      PermissionFeature.FERequestDescription,
      PermissionFeature.FERequestType,
      PermissionFeature.FERequestRequester,
      PermissionFeature.FERequestRequestedAmount,
      PermissionFeature.FERequestApprovedAmount,
      PermissionFeature.FERequestRequestDate,
      PermissionFeature.FERequestComments,
      PermissionFeature.FERequestBudgetID,
      PermissionFeature.FERequestCategory
    );
    const allHidden = FEServices.isAllHidden(
      PermissionFeature.FERequestUser,
      PermissionFeature.FERequestDescription,
      PermissionFeature.FERequestType,
      PermissionFeature.FERequestRequester,
      PermissionFeature.FERequestRequestedAmount,
      PermissionFeature.FERequestApprovedAmount,
      PermissionFeature.FERequestRequestDate,
      PermissionFeature.FERequestComments,
      PermissionFeature.FERequestBudgetID,
      PermissionFeature.FERequestCategory
    );
    const ShoweEuipmentDetailsSection = !FEServices.isAllHidden(
      PermissionFeature.FERequestUser,
      PermissionFeature.FERequestDescription,
      PermissionFeature.FERequestType,
      PermissionFeature.FERequestRequestDate,
      PermissionFeature.FERequestBudgetID
    );
    const ShowAccDetailsSection = !FEServices.isAllHidden(
      PermissionFeature.FERequestRequestedAmount,
      PermissionFeature.FERequestApprovedAmount,
      PermissionFeature.FERequestComments
    );

    const ShouldDisableApprovedAmountCtrl = () => {
      let canUpdateApprovedAmount = AuthService.canUpdate(
        PermissionFeature.FERequestApprovedAmount
      );
      let result = !(
        canUpdateApprovedAmount &&
        this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
        !ShowCancelBtn
      );
      return result;
    };

    const ShouldDisableRequestedAmount = () => {
      let firstOp =
        isRequested &&
        this.props.festate.isBudgetlineApprover &&
        this.props.festate.activeSubHeader == FE_Tabs.MyRequests;

      let secondOp =
        this.props.festate.isEVPApprover &&
        this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests;

      let thirdOp =
        isRequested && this.props.festate.activeSubHeader == FE_Tabs.MyRequests;

      let result = firstOp || secondOp || thirdOp || ShowCancelBtn;

      return result;
    };
    const GetRequestedAmountClasses = () => {
      let firstOp =
        isRequested &&
        this.props.festate.isBudgetlineApprover &&
        this.props.festate.activeSubHeader == FE_Tabs.MyRequests;
      let secondOp =
        this.props.festate.isEVPApprover &&
        this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests;

      let thirdOp =
        isRequested && this.props.festate.activeSubHeader == FE_Tabs.MyRequests;

      let result = `equipment-control pl-1 ${
        firstOp || secondOp || thirdOp ? "grayoutText" : ""
      }`;
      return result;
    };

    const shouldDisableComments = () => {
      let op1 =
        AuthService.canUpdate(PermissionFeature.FERequestComments) &&
        this.props.festate.activeSubHeader == FE_Tabs.MyRequests &&
        !ShowCancelBtn &&
        this.state.requestid == 0;
      // Below is the handling for Review Requests
      let op2 =
        (this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests &&
          this.state.requestid > 0 &&
          this.state.status > InventoryRequestStatus.PendingEVP) ||
        this.state.status == InventoryRequestStatus.Denied ||
        this.state.status == InventoryRequestStatus.Closed;

      let result =
        !(
          op1 ||
          !/// Below is the handling for Review Requests
          op2
        ) ||
        ShowCancelBtn ||
        (isCancelAble &&
          this.props.festate.activeSubHeader == FE_Tabs.MyRequests); // cancel request is available ony on My Requests Page

      return result;
    };
    const canAddNewSubCategory = () => {
      return AuthService.canAdd(PermissionFeature.FESubCategory);
    };

    ///Testing
    // var commentsPerm = !(
    //   (AuthService.canUpdate(PermissionFeature.FERequestComments) &&
    //     this.props.festate.activeSubHeader == FE_Tabs.MyRequests &&
    //     !ShowCancelBtn &&
    //     this.state.requestid == 0) ||
    //   !(
    //     /// Below is the handling for Review Requests
    //     (
    //       (this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests &&
    //         this.state.requestid > 0 &&
    //         this.state.status > InventoryRequestStatus.PendingEVP) ||
    //       this.state.status == InventoryRequestStatus.Denied
    //     )
    //   )
    // );
    //console.log("FECommnetsPermissions", commentsPerm);
    return (
      <AUX>
        <Modal
          //className="modal fade team-style"
          id="addRequestModal"
          size="xl"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
          className={isBothModalsOpen && `nestedmodal`}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title className=" w-100 modal-title font-italic">
              <div className="feModalHeader align-items-center text-uppercase d-flex">
                <div className="col-sm-6 mt-2">
                  <h5 className="text-white mt-0">
                    {allHidden ? <span>We are Sorry...</span> : ""}

                    {!allHidden && this.state.requestid == 0
                      ? "Add New Request"
                      : ""}

                    {!allHidden && this.state.requestid > 0
                      ? `${this.state.description} ${
                          !_.isEmpty(this.state.equipmentUser)
                            ? "-" + this.state.equipmentUser.name
                            : ""
                        }`
                      : ""}
                  </h5>
                </div>
                <div className="col-sm-6 float-right text-right w-25 text-white mt-0">
                  <h5>
                    {this.state.statusName == "PendingEVP"
                      ? "Pending EVP Approval"
                      : this.state.statusName}
                  </h5>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {!allHidden && (
                <form
                  className="needs-validation"
                  id="addEquipmentRequestForm"
                  onSubmit={this.onSubmit}
                  noValidate
                >
                  {ShoweEuipmentDetailsSection && (
                    // First Card
                    <Card className="mb-1">
                      <h6
                        className="card-header mb-3 mt-0 cursorPointer"
                        onClick={() => {
                          this.setState(
                            {
                              isEquipmentDetailsOpen: !this.state
                                .isEquipmentDetailsOpen,
                            },
                            () => {}
                          );
                        }}
                      >
                        <i class="fas fa-industry pr-1" />
                        EQUIPMENT DETAILS{" "}
                      </h6>

                      <Collapse isOpen={this.state.isEquipmentDetailsOpen}>
                        {/* First Row */}
                        <div className="row mt-1">
                          {AuthService.canView(
                            PermissionFeature.FERequestUser
                          ) && (
                            <div className=" col-lg-4 mt-md-3 mt-xs-0">
                              <Autocomplete
                                id="equipmentUser"
                                options={this.state.usersOptions}
                                filterOptions={(options) => options}
                                autoHighlight={true}
                                getOptionLabel={(option) => {
                                  if (!_.isEmpty(option)) {
                                    return `${option.name} - ${
                                      option.memberNumber
                                    } ${
                                      option.region
                                        ? option.region + "/" + option.facility
                                        : ""
                                    }`;
                                  } else {
                                    return "";
                                  }
                                }}
                                ChipProps={{ classes: { root: "py-2" } }}
                                onInputChange={(e, value, reason) => {
                                  if (reason == "input") {
                                    this.usersSearchHandler(value);
                                  }
                                }}
                                disabled={isRequested}
                                value={this.state.equipmentUser}
                                onChange={(e, value, reason) => {
                                  FEServices.changeHanlderServerSiderDropDown(
                                    e,
                                    value,
                                    reason,
                                    "equipmentUser",
                                    this
                                  );
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        className:
                                          "pb-1" +
                                          (params.inputProps &&
                                            " " + params.inputProps.className),
                                      }}
                                      variant="standard"
                                      label="User"
                                      value={this.state.equipmentUser}
                                      InputLabelProps={{
                                        shrink: true,
                                        classes: { shrink: "mt-n1" },
                                      }}
                                      error={
                                        this.state.issubmit &&
                                        _.isEmpty(this.state.equipmentUser)
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        this.state.issubmit &&
                                        _.isEmpty(this.state.equipmentUser)
                                          ? "Required"
                                          : ""
                                      }
                                      required={true}
                                      placeholder="Select User"
                                    />
                                  );
                                }}
                              />
                            </div>
                          )}
                          {AuthService.canView(
                            PermissionFeature.FERequestDescription
                          ) && (
                            <div className=" col-lg-4 mt-3 text_ctrl">
                              <div className="form-group">
                                <MDBInput
                                  id="equipment_desc"
                                  value={this.state.description}
                                  name="description"
                                  onChange={(e) =>
                                    FEServices.handleChange(e, this)
                                  }
                                  label="Description"
                                  className={`mt-5 ${
                                    isRequested ? "grayoutText" : ""
                                  }`}
                                  hint="Enter the equipment description"
                                  group
                                  type="text"
                                  disabled={isRequested}
                                  required
                                >
                                  <div className="invalid-feedback">
                                    Required
                                  </div>
                                </MDBInput>
                              </div>
                            </div>
                          )}
                          {AuthService.canView(
                            PermissionFeature.FERequestType
                          ) && (
                            <div className=" col-lg-4 mt-lg-3 mt-xs-0">
                              <Autocomplete
                                id="equipmentType"
                                options={this.state.equipmentTypesOptions}
                                getOptionLabel={(option) => {
                                  if (typeof option === "object") {
                                    return option.val;
                                  } else {
                                    return "";
                                  }
                                }}
                                onChange={(e, newValue, reason) =>
                                  FEServices.changeValueHandler(
                                    e,
                                    newValue,
                                    reason,
                                    "equipmentType",
                                    this
                                  )
                                }
                                disabled={isRequested}
                                value={this.state.equipmentType}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Type"
                                    variant="standard"
                                    value={this.state.equipmentType}
                                    placeholder="Select Equipment Type"
                                    InputLabelProps={{
                                      shrink: true,
                                      classes: { shrink: "mt-n1" },
                                    }}
                                    error={
                                      this.state.issubmit &&
                                      _.isEmpty(this.state.equipmentType)
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.issubmit &&
                                      _.isEmpty(this.state.equipmentType)
                                        ? "Required"
                                        : ""
                                    }
                                    required={true}
                                  />
                                )}
                              />
                            </div>
                          )}
                        </div>
                        {/* Second Row */}
                        <div className="row mt-0">
                          {AuthService.canView(
                            PermissionFeature.FERequestRequestDate
                          ) && (
                            <div className="col-lg-4 mt-4 mt-md-3 pl-2.1">
                              <div className="dateCtrl">
                                <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                  <small>Request Date:</small>
                                </span>
                                <DatePickerInput
                                  readOnly="readonly"
                                  name="requestdate"
                                  disabled={isRequested}
                                  onChange={(val) =>
                                    FEServices.onDateChange(
                                      val,
                                      this,
                                      "requestdate"
                                    )
                                  }
                                  value={this.state.requestdate}
                                />
                                {this.state.issubmit && (
                                  <span className="text-danger">
                                    {this.state.date_err}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          {/* Add Feature */}
                          {AuthService.canView(
                            PermissionFeature.FERequestCategory
                          ) && (
                            <div className=" col-lg-4 mt-2 mt-md-3">
                              <Autocomplete
                                id="equipmentCategory"
                                options={this.state.equipmentCategoryOptions}
                                getOptionLabel={(option) => {
                                  if (!_.isEmpty(option)) {
                                    return option.val;
                                  } else {
                                    return "";
                                  }
                                }}
                                onChange={(e, val, reason) =>
                                  FEServices.changeValueHandler(
                                    e,
                                    val,
                                    reason,
                                    "equipmentCategory",
                                    this
                                  )
                                }
                                disabled={isRequested}
                                // disabled={
                                //   !AuthService.canUpdate(PermissionFeature.FacilityType)
                                // }

                                value={this.state.equipmentCategory}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Category"
                                    value={this.state.equipmentCategory}
                                    //validators={["required"]}
                                    //errorMessages={["Equipment Category is required"]}
                                    //variant="standard"
                                    placeholder="Select Equipment Category"
                                    InputLabelProps={{
                                      shrink: true,
                                      classes: { shrink: "mt-n1" },
                                    }}
                                    required={true}
                                    error={
                                      this.state.issubmit &&
                                      _.isEmpty(this.state.equipmentCategory)
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.issubmit &&
                                      _.isEmpty(this.state.equipmentCategory)
                                        ? "Required"
                                        : ""
                                    }
                                  />
                                )}
                              />
                            </div>
                          )}
                          {/* Add Feature */}
                          {AuthService.canView(
                            PermissionFeature.FERequestCategory
                          ) && (
                            <div className=" col-lg-4 mt-4 mt-md-3">
                              <Autocomplete
                                id="equipmentFRT"
                                freeSolo={
                                  this.state.category == 4 &&
                                  canAddNewSubCategory()
                                }
                                autoSelect={
                                  this.state.category == 4 &&
                                  canAddNewSubCategory()
                                    ? true
                                    : false
                                }
                                clearOnBlur={
                                  this.state.category == 4 &&
                                  canAddNewSubCategory()
                                    ? false
                                    : true
                                }
                                selectOnFocus
                                options={this.state.equipmentFRTOptions}
                                getOptionLabel={(option) => {
                                  // Value selected with enter, right from the input
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  // Add "xxx" option created dynamically
                                  if (option.inputValue) {
                                    return option.val + " New Option";
                                  }
                                  // if (!_.isEmpty(option)) {
                                  //   return option.val;
                                  // } else {
                                  //   return "";
                                  // }
                                  return option.val;
                                }}
                                onChange={(e, val, reason) =>
                                  FEServices.changeValueHandler(
                                    e,
                                    val,
                                    reason,
                                    "equipmentFRT",
                                    this
                                  )
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  let canAddNewCat = canAddNewSubCategory();
                                  let categoryId = this.state.category;
                                  let inputExistsInOptions = filtered.findIndex(
                                    (x) =>
                                      x.val.toUpperCase() ==
                                      params.inputValue.toUpperCase()
                                  );
                                  // Suggest the creation of a new value
                                  if (
                                    params.inputValue !== "" &&
                                    categoryId == 4 &&
                                    canAddNewCat &&
                                    (_.isEmpty(filtered) ||
                                      inputExistsInOptions == -1)
                                  ) {
                                    filtered.push({
                                      inputValue: `${params.inputValue}`,
                                      key: 0,
                                      val: `Add "${params.inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                value={this.state.equipmentFRT}
                                disabled={isRequested}
                                // disabled={
                                //   !AuthService.canUpdate(PermissionFeature.FacilityType)
                                // }
                                // renderOption={(option) => (
                                //   <React.Fragment>
                                //     {option.val}
                                //     <span class="filter-selected"></span>
                                //   </React.Fragment>
                                // )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Sub-Category"
                                    placeholder="Equipment used for"
                                    //variant="standard"
                                    InputLabelProps={{
                                      shrink: true,
                                      classes: { shrink: "mt-n1" },
                                    }}
                                    required={true}
                                    error={
                                      this.state.issubmit &&
                                      _.isEmpty(this.state.equipmentFRT)
                                        ? true
                                        : false
                                    }
                                    helperText={
                                      this.state.issubmit &&
                                      _.isEmpty(this.state.equipmentFRT)
                                        ? "Required"
                                        : ""
                                    }
                                  />
                                )}
                              />
                            </div>
                          )}
                          {/* Testing Controls */}

                          <input type="text" style={{ display: "none" }} />
                        </div>
                        <AddMultiBudgetlines
                          isEquipment={false}
                          isRequested={isRequested}
                          budgetLinesSearchHandler={
                            this.budgetLinesSearchHandler
                          }
                          shouldDisableControl={false}
                          state={this.state}
                          myself={this}
                          entitykey={this.state.requestid}
                          loadBudgetLinesData={this.loadBudgetLinesData}
                          setBudgetLineFormInvalid={
                            this.setBudgetLineFormInvalid
                          }
                        />
                      </Collapse>
                    </Card>
                  )}
                  {/* Second Card */}
                  {ShowAccDetailsSection && (
                    <Card className="mb-1">
                      <h6
                        className="card-header mb-3 mt-0 cursorPointer"
                        onClick={() => {
                          this.setState(
                            {
                              isEquipmentAccountingOpen: !this.state
                                .isEquipmentAccountingOpen,
                            },
                            () => {}
                          );
                        }}
                      >
                        <i class="fas fa-file-invoice-dollar pr-1" />
                        ACCOUNTING DETAILS{" "}
                      </h6>

                      <Collapse isOpen={this.state.isEquipmentAccountingOpen}>
                        {/* First Row */}
                        <div className="row mt-2">
                          {AuthService.canView(
                            PermissionFeature.FERequestRequestedAmount
                          ) && (
                            <div className="col-lg-6 pl-1 fedollaramt">
                              <MDBInput
                                containerClass="mb-0"
                                className={GetRequestedAmountClasses()}
                                name="cost"
                                size="sm"
                                label="Requested Amount"
                                hint="Provide the requested amount"
                                icon="dollar-sign"
                                iconClass="position-absolute"
                                value={this.state.requestedAmount}
                                maxLength="16"
                                onChange={(e) =>
                                  FEServices.handleChangeAmount(
                                    e,
                                    "requestedAmount",
                                    this
                                  )
                                }
                                disabled={ShouldDisableRequestedAmount()}
                                group
                                type="text"
                                required
                              >
                                <small className="invalid-feedback pl-4 font-italic">
                                  Please enter requested amount.
                                </small>
                              </MDBInput>
                              <small className="text-danger pl-3 font-italic">
                                {this.state.requestedAmount_err}
                              </small>
                            </div>
                          )}

                          {/* When review request, then display  */}

                          {AuthService.canView(
                            PermissionFeature.FERequestApprovedAmount
                          ) &&
                            this.props.festate.isEVPApprover &&
                            this.props.festate.isReviewRequestOpen && (
                              <div className="col-md-4">
                                <MDBInput
                                  containerClass="mb-0"
                                  className={`equipment-control pl-1 ${
                                    !(
                                      AuthService.canUpdate(
                                        PermissionFeature.FERequestApprovedAmount
                                      ) &&
                                      this.props.festate.activeSubHeader !==
                                        FE_Tabs.MyRequests &&
                                      !ShowCancelBtn
                                    )
                                      ? "grayoutText"
                                      : ""
                                  }`}
                                  name="cost"
                                  size="sm"
                                  label="Approved Amount"
                                  hint="Provide the approved amount"
                                  icon="dollar-sign"
                                  iconClass="position-absolute"
                                  value={this.state.approvedAmount}
                                  maxLength="16"
                                  onChange={(e) =>
                                    FEServices.handleChangeAmount(
                                      e,
                                      "approvedAmount",
                                      this
                                    )
                                  }
                                  disabled={ShouldDisableApprovedAmountCtrl()}
                                  //required
                                  group
                                  type="text"
                                >
                                  <small className="invalid-feedback pl-4 font-italic">
                                    Please enter approved amount.
                                  </small>
                                </MDBInput>
                                <small className="text-danger pl-3 font-italic">
                                  {this.state.approvedAmount_err}
                                </small>
                              </div>
                            )}
                        </div>
                      </Collapse>
                    </Card>
                  )}
                  {/* Separate Row  outside card*/}
                  {AuthService.canView(PermissionFeature.FERequestComments) && (
                    <div className="row">
                      <div className="col-md-12 text_ctrl">
                        <div className="form-group commmentStyle">
                          <MDBInput
                            value={this.state.comments}
                            name="comments"
                            onChange={(e) => FEServices.handleChange(e, this)}
                            label="Comments"
                            className="mt-5"
                            group
                            type="textarea"
                            //false Editable
                            //true  Disabled
                            disabled={
                              // !(
                              //   (AuthService.canUpdate(
                              //     PermissionFeature.FERequestComments
                              //   ) &&
                              //     this.props.festate.activeSubHeader ==
                              //       FE_Tabs.MyRequests &&
                              //     !ShowCancelBtn &&
                              //     this.state.requestid == 0) ||
                              //   !(
                              //     /// Below is the handling for Review Requests
                              //     (
                              //       (this.props.festate.activeSubHeader ==
                              //         FE_Tabs.ReviewRequests &&
                              //         this.state.requestid > 0 &&
                              //         this.state.status >
                              //           InventoryRequestStatus.PendingEVP) ||
                              //       this.state.status ==
                              //         InventoryRequestStatus.Denied
                              //     )
                              //   )
                              // )
                              shouldDisableComments()
                            }
                            // required
                          >
                            {/* <div className="invalid-feedback">
                              Comments are required.
                            </div> */}
                          </MDBInput>
                        </div>
                      </div>
                    </div>
                  )}
                  {AuthService.canView(
                    PermissionFeature.FERequestActivityLog
                  ) &&
                    this.state.requestid > 0 &&
                    this.state.history &&
                    this.state.history.length > 0 && (
                      <Card className="mb-1">
                        <h6
                          className="card-header mb-3 mt-0 cursorPointer"
                          onClick={() => {
                            this.setState(
                              {
                                isEquipmentHistoryOpen: !this.state
                                  .isEquipmentHistoryOpen,
                              },
                              () => {}
                            );
                          }}
                        >
                          <i class="fas fa-history pr-1" />
                          HISTORY
                        </h6>

                        <Collapse isOpen={this.state.isEquipmentHistoryOpen}>
                          <div className="event-detail pb-3">
                            <div className="history">
                              {!_.isEmpty(this.state.history) &&
                                this.state.history.map((item, idx) => (
                                  <div className="row py-1 mx-0" key={idx}>
                                    <div className="px-3">
                                      {moment(item.actionDate).isValid()
                                        ? moment(
                                            new Date(item.actionDate + "Z")
                                          ).format("MM/DD/YYYY @ HHMM")
                                        : ""}
                                    </div>
                                    <div className="px-3">
                                      <div>
                                        {item.actionMessage} by{" "}
                                        <strong>{item.memberName}</strong>
                                      </div>
                                      {!_.isEmpty(item.comments) && (
                                        <div>
                                          <b>Comments:</b> {item.comments}
                                        </div>
                                      )}
                                      {item.reqamtchg && (
                                        <div>
                                          <b>Amount:</b> {item.reqamtchgmsg}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </Collapse>
                      </Card>
                    )}
                  {!this.props.festate.isReviewRequestOpen && (
                    <div className="form-group mb-4 row">
                      <div className="col-12 m-t-20 mx-auto text-center">
                        {
                          <>
                            {this.state.status === -1 && (
                              <button
                                className="btn btn-success w-md mc mr-2"
                                disabled={!this.state.verified}
                                type="submit"
                              >
                                Submit
                              </button>
                            )}
                            {
                              <button
                                className="btn btn-primary w-md waves-effect cancelModal waves-light mr-2  mc"
                                data-dismiss="modal"
                                aria-hidden="true"
                                type="reset"
                                onClick={this.handleClose}
                              >
                                Close
                              </button>
                            }
                            {isCancelAble && (
                              <button
                                className="btn btn-danger w-md waves-effect cancelModal waves-light mc"
                                data-dismiss="modal"
                                aria-hidden="true"
                                type="reset"
                                value={4}
                                onClick={this.handleCloseRequest}
                              >
                                Cancel Request
                              </button>
                            )}
                          </>
                        }
                      </div>
                    </div>
                  )}
                  {/* Input contrls end here */}
                  {/* Showing actions for budget line approver */}
                  {ShowPendingEVPBtn && (
                    <div className="form-group mb-4 row">
                      <div className="col-md-12 m-t-20 mx-auto text-center">
                        <button
                          className="btn btn-dark w-md waves-effect cancelModal waves-light mr-2 mc"
                          data-dismiss="modal"
                          aria-hidden="true"
                          type="reset"
                          onClick={this.handleClose}
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-success w-md mc mr-2"
                          data-dismiss="modal"
                          aria-hidden="true"
                          value={InventoryRequestStatus.PendingEVP}
                          type="reset"
                          onClick={this.handleApproveRequest}
                        >
                          Submit to EVP
                        </button>
                        <button
                          className="btn btn-danger w-md mc"
                          data-dismiss="modal"
                          value={InventoryRequestStatus.Denied}
                          type="reset"
                          onClick={this.handleApproveRequest}
                        >
                          Deny
                        </button>
                      </div>
                    </div>
                  )}
                  {ShowCancelBtn && (
                    <div className="form-group mb-4 row">
                      <div className="col-md-12 m-t-20 mx-auto text-center">
                        <button
                          className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                          data-dismiss="modal"
                          aria-hidden="true"
                          type="reset"
                          onClick={this.handleClose}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                  {!ShowCancelBtn &&
                    this.props.festate.activeSubHeader ==
                      FE_Tabs.ReviewRequests &&
                    this.props.festate.isEVPApprover &&
                    (this.state.status == InventoryRequestStatus.PendingEVP ||
                      this.state.status ==
                        InventoryRequestStatus.Requested) && (
                      <div className="form-group mb-4 row">
                        <div className="col-md-12 m-t-20 mx-auto text-center">
                          {
                            <>
                              <button
                                className="btn btn-success w-md mc"
                                data-dismiss="modal"
                                aria-hidden="true"
                                value={InventoryRequestStatus.Approved}
                                type="reset"
                                onClick={this.handleApproveRequest}
                              >
                                Approve
                              </button>

                              <button
                                className="btn btn-danger w-md mc ml-2"
                                data-dismiss="modal"
                                value={InventoryRequestStatus.Denied}
                                type="reset"
                                onClick={this.handleApproveRequest}
                              >
                                Deny
                              </button>
                              <button
                                className="btn btn-dark w-md waves-effect cancelModal waves-light ml-2 mc"
                                data-dismiss="modal"
                                aria-hidden="true"
                                type="reset"
                                onClick={this.handleClose}
                              >
                                Cancel
                              </button>
                            </>
                          }
                        </div>
                      </div>
                    )}
                </form>
              )}
              {allHidden && (
                <>
                  <div class="container h-100">
                    <div class="row NoDataFoundTable">
                      <h5>
                        You are not authorized to view this content. Please
                        contact administrator.
                      </h5>
                    </div>
                  </div>
                  <div className="actionBtns form-group mb-4 row">
                    <div className="col-12 m-t-20 mx-auto text-center">
                      <button
                        className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                        type="reset"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              )}
            </>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({ festate: state.fetracker }),
    {
      ...FETrackerAction,
    }
  )(FE_RequestModal)
);
{
  /* <div className="col-lg-6">
                                <Autocomplete
                                  id="equipmentBudgetline"
                                  options={this.state.budgetLinesOptions}
                                  filterOptions={(options) => options}
                                  autoHighlight={true}
                                  getOptionLabel={(option) => {
                                    if (
                                      !_.isEmpty(option) &&
                                      !_.isEmpty(option.approverMember) &&
                                      !this.state.showAuthorizedPerson
                                    ) {
                                      return `${option.name}-${option.approverMember.firstname} ${option.approverMember.lastname}`;
                                    } else if (
                                      !_.isEmpty(option) &&
                                      !_.isEmpty(option.approverMember) &&
                                      this.state.showAuthorizedPerson
                                    ) {
                                      return `${option.name}`;
                                    } else {
                                      return "";
                                    }
                                  }}
                                  disabled={isRequested}
                                  ChipProps={{ classes: { root: "py-2 " } }}
                                  onInputChange={(e, value, reason) => {
                                    if (reason == "input") {
                                      this.budgetLinesSearchHandler(value);
                                    }
                                  }}
                                  value={this.state.equipmentBudgetline}
                                  onChange={(e, value, reason) => {
                                    if (reason !== "clear") {
                                      FEServices.changeHanlderServerSiderDropDown(
                                        e,
                                        value,
                                        reason,
                                        "equipmentBudgetline",
                                        this
                                      );
                                    } else {
                                      this.setState({
                                        showAuthorizedPerson: "",
                                      });
                                      this.setState({
                                        budgetAuthorizer: false,
                                      });
                                      this.loadBudgetLinesData("");
                                    }
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          className:
                                            "pb-1" +
                                            (params.inputProps &&
                                              " " +
                                                params.inputProps.className),
                                        }}
                                        variant="standard"
                                        label="Budget Line"
                                        InputLabelProps={{
                                          shrink: true,
                                          classes: { shrink: "mt-n1" },
                                        }}
                                        placeholder="Select Budget Line"
                                        required={true}
                                        error={
                                          this.state.issubmit &&
                                          _.isEmpty(
                                            this.state.equipmentBudgetline
                                          )
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.issubmit &&
                                          _.isEmpty(
                                            this.state.equipmentBudgetline
                                          )
                                            ? "Required"
                                            : ""
                                        }
                                      />
                                    );
                                  }}
                                />
                              </div> */
}
