import React from "react";

export const countries = [
  { key: "af", value: "af", flag: "af", text: "Afghanistan" },
  { key: "ax", value: "ax", flag: "ax", text: "Aland Islands" },
];

export const oshaTierOptions = [
  { key: "1", value: "1", text: "1" },
  { key: "2", value: "2", text: "2" },
  { key: "3", value: "3", text: "3" },
];

export const facilityLevelOptions = [
  { key: "FPL-4 (D)", value: "FPL-4 (D)", text: "FPL-4 (D)" },
  { key: "FPL-5 (E)", value: "FPL-5 (E)", text: "FPL-5 (E)" },
  { key: "FPL-6 (F)", value: "FPL-6 (F)", text: "FPL-6 (F)" },
  { key: "FPL-7 (G)", value: "FPL-7 (G)", text: "FPL-7 (G)" },
  { key: "FPL-8 (H)", value: "FPL-8 (H)", text: "FPL-8 (H)" },
  { key: "FPL-9 (I)", value: "FPL-9 (I)", text: "FPL-9 (I)" },
  { key: "FPL-10 (J)", value: "FPL-10 (J)", text: "FPL-10 (J)" },
  { key: "FPL-11 (K)", value: "FPL-11 (K)", text: "FPL-11 (K)" },
  { key: "FPL-12 (L)", value: "FPL-12 (L)", text: "FPL-12 (L)" },
];

export const facilityStatusOptions = [
  { key: "Represented", value: "Represented", text: "Represented" },
  { key: "Non-represented", value: "Non-represented", text: "Non-represented" },
  { key: "Organizing", value: "Organizing", text: "Organizing" },
  { key: "Closed", value: "Closed", text: "Closed" },
  { key: "Consolidated", value: "Consolidated", text: "Consolidated" },
];
export const facilityTypeOptions = [
  { key: "Tower–No Radar", value: 1, text: "Tower–No Radar" },
  { key: "Tower–Radar", value: 2, text: "Tower–Radar" },
  { key: "TRACON", value: 3, text: "TRACON" },
  { key: "Combined TRACON", value: 4, text: "Combined TRACON" },
  { key: "Combined Tower/TRACON", value: 5, text: "Combined Tower/TRACON" },

  {
    key: "Non-Radar Tower/Approach",
    value: 6,
    text: "Non-Radar Tower/Approach",
  },
  {
    key: "Combined Control Facility",
    value: 7,
    text: "Combined Control Facility",
  },
  { key: "ARTCC", value: 8, text: "ARTCC" },
  { key: "Region X", value: 9, text: "Region X" },
  { key: "Flight Service", value: 10, text: "Flight Service" },
  { key: "Command Center", value: 11, text: "Command Center" },
  { key: "National Office", value: 12, text: "National Office" },
];

export const facilityRegionsOptions = [
  { key: "Master", value: 1, text: "Master" },
  { key: "NSO", value: 2, text: "NSO" },
  { key: "NGL", value: 3, text: "NGL" },
  { key: "NEA", value: 4, text: "NEA" },
  { key: "NCE", value: 5, text: "NCE" },

  { key: "NNM", value: 6, text: "NNM" },
  { key: "NWP", value: 7, text: "NWP" },
  { key: "NSW", value: 8, text: "NSW" },
  { key: "NNE", value: 9, text: "NNE" },
  { key: "NRX", value: 10, text: "NRX" },
  { key: "NAL", value: 11, text: "NAL" },
  { key: "NAT", value: 12, text: "NAT" },
  { key: "test1", value: 13, text: "test1" },
];

export const oshaOwnerOptions = [
  {
    key: "Federal Aviation Administration",
    value: "Federal Aviation Administration",
    text: "Federal Aviation Administration",
  },
  {
    key: "Department of Defense",
    value: "Department of Defense",
    text: "Department of Defense",
  },
  {
    key: "Federal Contract Tower",
    value: "Federal Contract Tower",
    flag: "Federal Contract Tower",
    text: "Federal Contract Tower",
  },
  {
    key: "Midwest ATC Service",
    value: "Midwest ATC Service",
    text: "Midwest ATC Service",
  },
  { key: "Serco Inc", value: "Serco Inc", text: "Serco Inc" },
];

export const SRF12Numbers = [
  { key: "Full", value: "Full", text: "Full" },
  { key: "Half", value: "Half", text: "Half" },
  { key: "None", value: "None", text: "None" },
];
