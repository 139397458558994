import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import $ from "jquery";
import _ from "lodash";
export default function ConfirmLinkedPositionModal(props) {
    let ModalId = (_.isEmpty(props.Heading)) ? "ConfirmLinkedPositionModal" : props.Heading;
    return (
        <AUX>
            <div className="modal fade confirm-modal p-0" style={{ "zIndex": '1100' }} role="dialog" id={ModalId} aria-labelledby="" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered" + ((props.permission) ? (" modal-lg") : (" modal-md"))}>
                    <div className="modal-content shadow">
                        <div className="modal-header py-2 bg-white">
                            <h5 className="modal-title text-secondary mt-0">Confirm Position</h5>

                        </div>
                        <div className="modal-body">

                            <div className="col-lg-10 offset-lg-1 col-12 mb-4">
                                {(props.permission) ? (
                                    <Fragment>
                                        <div className="col-12 pb-2 text-center text-success" >
                                            <span className="h1 fas fa-user-lock"></span>

                                        </div>
                                        <p className="h5 text-center">You are linking {props.PositionName} to {props.TeamName} and {props.TeamName} does not have the following permissions:</p>
                                        <ol>
                                            {props.Permissions.map(item =>
                                                <li>{item}</li>
                                            )
                                            }
                                        </ol></Fragment>) : (
                                        <Fragment>
                                            <div className="col-12 pb-2 text-center text-danger" >
                                                <span className="h1 fa fa-trash-alt"></span>

                                            </div>
                                            <p className="text-center">Are you sure you want to unlink <b>{props.PositionName}</b> from team <b>"{props.TeamName}"</b>?</p>
                                           </Fragment>
                                    )}
                            </div>
                            <div className="col-12 text-center border-top pt-4">
                                <button
                                    className="btn btn-outline-dark w-md waves-effect cancelModal waves-light mr-2"
                                    onClick={() => {

                                        window.$("#" + ModalId).modal("hide");
                                        if (!$("body").hasClass("modal-open")) {
                                            $("body").addClass("modal-open");
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={"btn w-md waves-effect waves-light" + ((props.permission) ? (" btn-outline-success") : (" btn-outline-danger"))}
                                    onClick={() => {
                                        props.confirmLinkedPosition();
                                    }}>
                                    Confirmed
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AUX>
    );
}


