import React, { Component } from "react";
import { Row, Col, Form } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import _ from "lodash";
import AuthService from "../../../modules/Auth/AuthService";
import PermissionFeature from "../../../modules/Permissions/PermissionFeature";

export class Name extends Component {
  constructor(props) {
    super(props);
  }

  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && !this.props.isMobile == true ? (
          <label>{label} </label>
        ) : (
          <label></label>
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          autoComplete="off"
          placeholder={!this.props.isMobile == true ? "" : placeholder}
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Name</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}

        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }`}
            >
              {AuthService.canView(PermissionFeature.PersonalInfoFirstName) ? (
                <Field
                  name="firstName"
                  type="text"
                  classForm="form-control"
                  label="First Name"
                  placeholder="First Name"
                  component={this.renderInput}
                />
              ) : null}

              {AuthService.canView(
                PermissionFeature.PersonalInfoPreviousLastName
              ) ? (
                <Field
                  name="previouslastname"
                  type="text"
                  classForm="form-control"
                  placeholder="Previous Last Name"
                  label="Previous Last Name"
                  component={this.renderInput}
                />
              ) : null}
            </Col>
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }`}
            >
              {AuthService.canView(PermissionFeature.PersonalInfoLastName) ? (
                <Field
                  name="lastName"
                  type="text"
                  classForm="form-control"
                  label="Last Name"
                  placeholder="Last Name"
                  component={this.renderInput}
                />
              ) : null}
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!/^[a-zA-Z']*$/.test(formValues.firstName)) {
    errors.firstName = "Please enter alpha characters only.";
  }
  if (!/^[a-zA-Z']*$/.test(formValues.middleName)) {
    errors.middleName = "Please enter alpha characters only";
  }
  if (!/^[a-zA-Z']*$/.test(formValues.lastName)) {
    errors.lastName = "Please enter alpha characters only";
  }

  if (!/^[a-zA-Z']*$/.test(formValues.nickName)) {
    errors.nickName = "Please enter alpha characters only";
  }
  return errors;
};

const formWrapped = reduxForm({
  form: "nameForm",
  destroyOnUnmount: false,
  validate,
})(Name);
// function mapDispatchToProps(dispatch) {
//   return {
//     fetrackerActions: bindActionCreators(fetrackerActions, dispatch),
//   };
// }
const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
