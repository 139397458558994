import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { MDBInput } from "mdbreact";
import FacilityModal from "../../Shared/FacilityModal";

class Filter extends Component {
    constructor(props) {
        super(props);

        this.filterHandlers = this.props.methods();
        this.filterType = this.filterType.bind(this);
    }

    filterType(item, idx) {
        const typeObj = {
            MemberType: [
                { value: "AcademyGraduate", label: "Academy Graduate" },
                { value: "AssociateMember", label: "Associate Member" },
                { value: "Company", label: "Company" },
                { value: "CorporateMember", label: "Corporate Member" },
                { value: "CTIStudent", label: "CTI Student" },
                { value: "CurrentMember", label: "Current Member" },
                { value: "MemberOfCongress", label: "Member Of Congress" },
                { value: "NATCAEmployee", label: "NATCA Employee" },
                { value: "Non-Member", label: "Non-Member" }
            ],
            careerLevel: [
                { value: "AcademyGraduates", label: "Academy Graduate" },
                { value: "CertifiedProfessionalController", label: "Certified Professional Controller" },
                { value: "CertifiedProfessionalControllerIn-Training", label: "Certified Professional Controller In-Training" },
                { value: "DevelopmentalStage 1", label: "Developmental Stage 1" },
                { value: "DevelopmentalStage 2", label: "Developmental Stage 2" },
                { value: "DevelopmentalStage 3", label: "Developmental Stage 3" },
                { value: "TrafficManagementController", label: "Traffic Management Controller" },
                { value: "ManagementPosition", label: "Management Position" },
                { value: "NewEmployee", label: "New Employee" },
                { value: "NewHireSpecial", label: "New Hire Special" },
                { value: "NoLongerBargainingUnitEmployee", label: "No Longer Bargaining Unit Employee" }
            ],
            CommMethods: [
                { value: "AllowPostalMail", label: "Postal Mail" },
                { value: "AllowMobile", label: "Call" },
                { value: "AllowText", label: "Text" },
                { value: "AllowEmail", label: "Email" }
            ]
        };
        const filterType = item.type.filterType || item.type.name;
        switch (filterType) {
            case "faaid":
            case "emailAddress":
            case "shirtsize":
            case "Unit":
            case "OccupationSeries":
            case "PayGrade":
            case "Region":
            case "FacilityRoutingSymbol":
            case "DutyStation": {
                const val = item.type[item.type.name];
                return (
                    <div className="form-group">
                        <div className="row">
                            <div className="col">
                                <MDBInput
                                    name={item.type.name}
                                    value={val}
                                    onChange={this.filterHandlers.changeText.bind(this, this.props.idx)}
                                    label={item.type.label}
                                    group
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            case "Facility": {
                return (
                    <div className="form-group">
                        <h6 className="mr-2">Facility</h6>
                        <div class="input-group mb-3">
                            <input type="text" name={item.type.name} class="form-control" value={item[item.type.name]} onChange={this.filterHandlers.changeText.bind(this, this.props.idx)}  />
                            <div class="input-group-append">
                                <button
                                    class="btn btn-outline-secondary"
                                    type="button"
                                    data-toggle="modal"
                                    data-target=".facility-modal">
                                    Select
                                </button>
                            </div>
                        </div>
                        <FacilityModal arg={this.props.idx} getFacilities={true} facilityMethods={this.filterHandlers.facilityMethods} />
                       
                    </div>
                );
            }
            case "TEXT": {
                const val = item.type[item.type.name];
                return (
                    <div className="form-group">
                        <div className="row">
                            <div className="col">
                                <MDBInput
                                    name={item.type.name}
                                    value={val}
                                    onChange={this.filterHandlers.changeText.bind(this, this.props.idx)}
                                    label={item.type.label}
                                    group
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            case "names": {
                return (
                    <div className="form-group">
                        <div className="row">
                            <div className="col">
                                <MDBInput
                                    name="firstname"
                                    value={item.firstname}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="First Name"
                                    group
                                    type="text"
                                />
                            </div>
                            <div className="col">
                                <MDBInput
                                    name="middlename"
                                    value={item.middlename}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="Middle Name"
                                    group
                                    type="text"
                                />
                            </div>
                            <div className="col">
                                <MDBInput
                                    name="lastname"
                                    value={item.lastname}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="Last Name"
                                    group
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            case "TeamMemberNames": {
                return (
                    <div className="form-group">
                        <div className="row">
                            <div className="col">
                                <MDBInput
                                    name="MemberFirstName"
                                    value={item.firstname}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="First Name"
                                    group
                                    type="text"
                                />
                            </div>
                            <div className="col">
                                <MDBInput
                                    name="MemberMiddleName"
                                    value={item.middlename}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="Middle Name"
                                    group
                                    type="text"
                                />
                            </div>
                            <div className="col">
                                <MDBInput
                                    name="MemberLastName"
                                    value={item.lastname}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="Last Name"
                                    group
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            case "address": {
                return (
                    <div className="form-group">
                        <div className="row">
                            <div className="col">
                                <MDBInput
                                    name="Street"
                                    value={item.address1}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="Street"
                                    group
                                    type="text"
                                />
                            </div>
                            
                            <div className="col">
                                <MDBInput
                                    name="city"
                                    value={item.city}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="City"
                                    group
                                    type="text"
                                />
                            </div>
                            <div className="col">
                                <MDBInput
                                    name="state"
                                    value={item.state}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="State"
                                    group
                                    type="text"
                                />
                            </div>
                            <div className="col">
                                <MDBInput
                                    name="zipcode"
                                    value={item.zipcode}
                                    onChange={this.filterHandlers.changeText.bind(this, idx)}
                                    label="Zipcode"
                                    group
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                );
            }

            case "careerLevel":
            case "MemberType": {
                const objList = typeObj[item.type.name];
                let label = "";
                if (item.type.name == "MemberType") {
                    label = "Member Type: ";
                } else {
                    label = "Career Level: ";
                }
                return (
                    <div className="form-group">
                        <div className="row">
                            <div className="col-2  mr-2">{label}</div>
                            <div className="col">
                                <div className="row">
                                    {objList.map((el, idx) => (
                                        <div className="col-4">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    onChange={this.filterHandlers.changeTypes.bind(this, this.props.idx)}
                                                    name={item.type.name}
                                                    value={el.label}
                                                    className="custom-control-input"
                                                    id={el.value}
                                                />
                                                <label className="custom-control-label" for={el.value}>
                                                    {" "}
                                                    {el.label}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            case "CommMethods": {
                const objList = typeObj[item.type.name];
                let label = "Communication Methods: ";

                return (
                    <div className="form-group">
                        <div className="row">
                            <div className="col-2  mt-2">{label}</div>
                            <div className="col">
                                <div className="row">
                                    {objList.map((el, idx) => (
                                        <div className="col-4">
                                            <div class="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    onChange={this.filterHandlers.changeComm.bind(this, this.props.idx)}
                                                    name={el.value}
                                                    value={el.label}
                                                    className="custom-control-input"
                                                    id={el.value}
                                                />
                                                <label className="custom-control-label" for={el.value}>
                                                    {" "}
                                                    {el.label}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            case "Active": {
                return (
                    <div className="form-group md-form">
                        <div className="row">
                            <div className="col-2">Member Active: </div>
                            <div className="col-3">
                                <select
                                    className="browser-default custom-select"
                                    onChange={this.filterHandlers.changeActive.bind(this, this.props.idx)}>
                                    <option name="isactive" value="isactive">
                                        Active
                                    </option>
                                    <option name="isinactive" value="isinactive">
                                        Not Active
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                );
            }
            case "PlannedRetirement":
            case "Retirement":
            case "dateofBirth":
            case "SCD":
            case "ExpectedDues":
            case "NatcaBargainingUnitDate": {
                return (
                    <div className="form-group md-form">
                        <div className="row">
                            <div className="col-12 mb-2">{item.type.label}</div>
                            <div className="col">
                                <MDBInput
                                    name={`${item.type.name}from`}
                                    value={item.type[`${item.type.name}from`]}
                                    onChange={this.filterHandlers.changeText.bind(this, this.props.idx)}
                                    label="From"
                                    group
                                    type="text"
                                />
                            </div>

                            <div className="col">
                                <MDBInput
                                    name={`${item.type.name}to`}
                                    value={item.type[`${item.type.name}to`]}
                                    onChange={this.filterHandlers.changeText.bind(this, this.props.idx)}
                                    label="To"
                                    group
                                    type="text"
                                />
                            </div>
                        </div>
                    </div>
                );
            }
            case "FLSACategory": {
                return (
                    <div className="form-group md-form">
                        <div className="row">
                            <div className="col-2">FLSA Category: </div>
                            <div className="col-3">
                                <select
                                    name="FLSACategory"
                                    className="browser-default custom-select"
                                    onChange={this.filterHandlers.changeSelect.bind(this, this.props.idx)}>
                                    <option value="exepmt">Exempt</option>
                                    <option value="nonexempt">Non Exempt</option>
                                </select>
                            </div>
                        </div>
                    </div>
                );
            }
            case "Status": {
                return (
                    <div className="form-group md-form">
                        <div className="row">
                            <div className="col-2">Member Status: </div>
                            <div className="col-3">
                                <select
                                    name="Status"
                                    className="browser-default custom-select"
                                    onChange={this.filterHandlers.changeSelect.bind(this, this.props.idx)}>
                                    <option value="Active">Active</option>
                                    <option value="Deceased">Deceased</option>
                                    <option value="Retired">Retired</option>
                                    <option value="Terminated">Terminated</option>
                                </select>
                            </div>
                        </div>
                    </div>
                );
            }
            case "VetStatus": {
                return (
                    <div className="form-group md-form">
                        <div className="row">
                            <div className="col-2">Vet Status: </div>
                            <div className="col-3">
                                <select
                                    name="VetStatus"
                                    className="browser-default custom-select"
                                    onChange={this.filterHandlers.changeSelect.bind(this, this.props.idx)}>
                                    <option value="B - Vet Before Vietnam">B - Vet Before Vietnam</option>
                                    <option value="N - Not a Vietnam Vet">N - Not a Vietnam Vet</option>
                                    <option value="P - Post Vietnam Vet">P - Post Vietnam Vet</option>
                                    <option value="V - Vietnam Vet">V - Vietnam Vet</option>
                                    <option value="X - Not a Vet">X - Not a Vet</option>
                                </select>
                            </div>
                        </div>
                    </div>
                );
            }
            default: {
                return "";
            }
        }
    }
    render() {
        return <AUX>{this.filterType(this.props.row, this.props.idx)}</AUX>;
    }
}

export default Filter;
