import React, { useState, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import InventoryStatus from "../InventoryStatus";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import jsPDF from "jspdf";
import { Row, Table } from "react-bootstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import $ from "jquery";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import FEService from "../F&ERequests/FEService";
import { faker } from "@faker-js/faker";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";

const showBugdetlines = (cell, row) => {
  let isMultiBglines =
    (row.febudgetlines && row.febudgetlines.length > 1) || false;
  let firstBgLine = cell.split("<br>").shift() || "";
  let secondBgLine = (isMultiBglines && cell.split("<br>")[1]) || "";
  let cutStrFirst = FEService.getStrCutLength(firstBgLine);
  let cutStrSec = FEService.getStrCutLength(secondBgLine);

  return !isMultiBglines
    ? firstBgLine
    : isMultiBglines && (
        <div className="progress-wrapper inventories">
          <OverlayTrigger
            overlay={
              <Popover id="popover-basic inventories">
                <Popover.Title as="h6">All Budget Lines</Popover.Title>
                <Popover.Content>
                  <small dangerouslySetInnerHTML={{ __html: cell }} />
                </Popover.Content>
              </Popover>
            }
          >
            <i href="#" id="inventories">
              {firstBgLine.substring(0, firstBgLine.length - cutStrFirst)
                ? firstBgLine.substring(0, firstBgLine.length - cutStrFirst) +
                  "... , "
                : ""}

              {secondBgLine.substring(0, secondBgLine.length - cutStrSec)
                ? secondBgLine.substring(0, secondBgLine.length - cutStrSec) +
                  "..."
                : ""}
            </i>
          </OverlayTrigger>
        </div>
      );
};
const showCategory = (cell, row) => {
  return (
    <div className="progress-wrapper inventories">
      <OverlayTrigger
        overlay={
          <Popover id="popover-basic inventories">
            <Popover.Title as="h6">Category</Popover.Title>
            <Popover.Content>
              <small dangerouslySetInnerHTML={{ __html: cell }} />
            </Popover.Content>
          </Popover>
        }
      >
        <i href="#" id="inventories">
          <span className="text-truncate categoryFN d-inline-block">
            {row.categoryFullName}
          </span>
        </i>
      </OverlayTrigger>
    </div>
  );
};

const actionIcons = (
  row,
  viewEquipment,
  viewFiles,
  disposeEquipment,
  removeEquipment
) => {
  return (
    <div className="d-inline-block text-truncate categoryFN">
      <div className="actionIcons">
        {AuthService.canView(PermissionFeature.FEAdmin) && (
          <i
            className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
            title="View Equipment"
            onClick={() => viewEquipment(row)}
          />
        )}
        {AuthService.canView(PermissionFeature.FEAccountingFiles) && (
          <i
            className="fas fa-images del cursorPointer pl-1"
            title="View Files"
            onClick={() => viewFiles(row)}
          />
        )}

        {AuthService.canDelete(PermissionFeature.FEAccountingRecycle) &&
          row.status !== InventoryStatus.Disposed && (
            <i
              className="fa fa-recycle fa-md c-pointer pl-1"
              title="Recycle"
              onClick={() => disposeEquipment(row)}
            />
          )}
        {AuthService.canDelete(PermissionFeature.FEAdmin) && (
          <i
            className="text-danger fas fa-trash c-pointer pl-1 pr-1"
            title="Delete"
            onClick={() => removeEquipment(row)}
          />
        )}
      </div>
    </div>
  );
};
const currencyFormatter = (cell, row) => {
  return (
    <span>
      {cell
        ? `$${cell &&
            cell.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
        : "$0"}
    </span>
  );
};
const MyExportCSV = (props) => {
  const handleClick = async () => {
    const formData = {
      recordtype: props.equipmentType, // Active/InAccountReview || My/Review Requests
      isExportTable: true,
      PageNo: 1,
      PageSize: 1,
      SortOrder: "id",
      activeTab: props.activeSubHeader, // Inventory Or Requests
      isAdvanceSearchApplied: props.isAdvanceSearchApplied,
      searchCriteria: props.isAdvanceSearchApplied ? props.searchCriteria : {},
      showHistory: props.showHistory,
    };
    var response = await FEService.getDataForFEExport(formData);
    if (!_.isEmpty(response)) {
      let responseData = response.data.map((m) => {
        Object.keys(m.yearlyDepreciations).map((key) => {
          m[key] = m.yearlyDepreciations[key];
        });
        return m;
      });

      props.onExport(responseData);
    }
  };
  return (
    <div>
      <i
        title="Export to csv"
        id="exportEqs"
        className="csvIconSize fas fa-file-csv d-none"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Equipment Found!</h5>
    </div>
  </div>
);

const Empty = (cell, row) => <div />;
const showDeprSum = (row) => {
  const { equipmentdepreciations } = row;
  const deprSum = equipmentdepreciations
    .filter((f) => f.year < new Date().getFullYear())
    .reduce((acc, curr) => {
      return acc + curr.deprval;
    }, 0);

  return (
    <span>
      {deprSum
        ? `$${deprSum &&
            deprSum.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
        : "$0"}
    </span>
  );
};
// const showYearlyDep = (row) => {
//   const { equipmentdepreciations } = row;
//   const nth = (
//     equipmentdepreciations.find((f) => f.year == new Date().getFullYear()) || {
//       deprval: 0,
//     }
//   ).deprval;

//   const nthMinusOne = (
//     equipmentdepreciations.find(
//       (f) => f.year == new Date().getFullYear() - 1
//     ) || { deprval: 0 }
//   ).deprval;

//   const currentYearDepr = nth - nthMinusOne;

//   return (
//     <span>
//       {currentYearDepr
//         ? `$${currentYearDepr &&
//             currentYearDepr.toLocaleString(navigator.language, {
//               minimumFractionDigits: 2,
//               maximumFractionDigits: 2,
//             })}`
//         : "$0"}
//     </span>
//   );
// };
const getAccDeprLabel = () => {
  return `${new Date().getFullYear()} Dep.`;
};
const getADLabel = () => {
  return `${new Date().getFullYear()} A/D`;
};
// const priceFormatter = (column, colIndex, { text }) => {
//   return (
//     <h5>
//       <strong> {column.text} $$</strong>
//     </h5>
//   );
// };

const getExportFileName = (tblType, filetype) => {
  if (tblType === InventoryStatus.NonAccReview) {
    return `${Date.now()}_ActiveEquipmentsList.${filetype}`;
  }
  if (tblType === InventoryStatus.InAccReview) {
    return `${Date.now()}_InAccountingReviewEquipments.${filetype}`;
  }
};
const createAutoTable = (filename, tableBody) => {
  const doc = new jsPDF();
  function bodyRows(rowCount) {
    rowCount = rowCount || 10;
    var body = [];
    for (var j = 1; j <= rowCount; j++) {
      body.push({
        id: j,
        name: faker.name.findName(),
        email: faker.internet.email(),
        city: faker.address.city(),
        expenses: faker.finance.amount(),
      });
    }
    return body;
  }

  doc.autoTable({
    //html: "#table",
    head: [
      [
        "",
        "USER",
        "DESCRIPTION",
        "CATEGORY",
        "TYPE",
        "COST",
        "A/D",
        "BOOK",
        "STAUS",
      ],
    ],
    body: tableBody,
    //css
    tableWidth: "auto",
    // styles: {
    //   cellPadding: 0.7,
    //   fontSize: 8,
    // },
    columnStyles: {
      0: { cellWidth: 50 },
      1: { cellWidth: 25 },
      2: { cellWidth: 25 },
      // etc
    },
    theme: "grid",
    startY: 20,

    // bodyStyles: {
    //   minCellHeight: 30,
    // },
    didDrawCell: function(data) {
      console.log(data);
      data.column.index === 0 &&
        data.cell.section === "body" &&
        doc.autoTable({
          // head: [["One", "Two", "Three", "Four"]],
          // body: [
          //   ["1", "2", "3", "4"],
          //   ["1", "2", "3", "4"],
          //   ["1", "2", "3", "4"],
          //   ["1", "2", "3", "4"],
          // ],
          columns: [
            { dataKey: "id", header: "ID" },
            { dataKey: "name", header: "Name" },
            { dataKey: "expenses", header: "Sum" },
          ],
          body: bodyRows(),
          startY: data.cell.y + 2,
          margin: { left: data.cell.x + data.cell.padding("left") },
          tableWidth: "wrap",
          theme: "grid",

          styles: {
            fontSize: 7,
            cellPadding: 1,
            maxCellHeight: 4,
          },
        });
    },
  });
  doc.save(filename);
};
/**
 * Count total rows of nested table for calculating the height of primary table (assumption 1 row height cells)
 */
const getTotalRows = (table) => {
  return table.querySelectorAll("tr").length;
};
/**
 * if there is a nested table inside a cell then clear the content and set the height of the cell
 */
const prepareCellForNestedTable = (cell, nestedTable) => {
  cell.styles.minCellHeight = getTotalRows(nestedTable) * 11; // calc how many rows are needed?
  cell.text = [];
};
Document.prototype.createElementFromString = function(str) {
  const element = new DOMParser().parseFromString(str, "text/html");
  const child = element.documentElement.querySelector("body").firstChild;
  return child;
};
const renderPdfTableFromHTML = () => {
  //   const doc = new jsPDF();
  //   const htlmlEle = document.createElementFromString(W3School);
  //   autoTable(doc, {
  //     // html: "Equipments_list_4_Tbl table",
  //     html:
  //       "#Equipments_list_4_Records > div._loading_overlay_wrapper.css-79elbk > div > table",
  //     includeHiddenHtml: true,
  //     useCss: true,
  //   });
  //   doc.save("table.pdf");
  //   console.log(htlmlEle);
};
const canvasPrint = () => {
  // const input = document.querySelector(
  //   "#Equipments_list_4_Records > div._loading_overlay_wrapper.css-79elbk > div > table"
  // );
  const input = document.getElementsByClassName("equipmentTable")[0];
  // var w = document.getElementById("pdfdiv").offsetWidth;
  // var h = document.getElementById("pdfdiv").offsetHeight;
  html2canvas(input).then((canvas) => {
    var imgData = canvas.toDataURL("image/png");
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("p", "mm", "a4");
    var position = 0;

    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save("file.pdf");

    // var img = canvas.toDataURL("image/png");
    // var imgWidth = 210;
    // var pageHeight = 295;
    // var imgHeight = (canvas.height * imgWidth) / canvas.width;
    // var heightLeft = imgHeight;
    // var doc = new jsPDF("p", "mm");
    // var position = 0;

    // doc.addImage(img, "PNG", 0, position, w, h);
    // heightLeft -= pageHeight;

    // while (heightLeft >= 0) {
    //   position = heightLeft - imgHeight;
    //   doc.addPage();
    //   doc.addImage(img, "PNG", 0, position, w, h);
    //   heightLeft -= pageHeight;
    // }
    // doc.save("Exported-Result.pdf");
  });
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.equipmentType, "pdf");

  ///Server side Export/////
  const formData = {
    recordtype: allprops.equipmentType,
    isExportTable: true,
    PageNo: 1,
    SortOrder: "id",
    activeTab: allprops.activeSubHeader, // Inventory Or Requests
    isAdvanceSearchApplied: allprops.isAdvanceSearchApplied,
    searchCriteria: allprops.isAdvanceSearchApplied
      ? allprops.searchCriteria
      : {},
  };
  let response = await FEService.getDataForFEExport(formData);
  if (!_.isEmpty(response)) {
    let responseData = response.data.map((m) => {
      Object.keys(m.yearlyDepreciations).map((key) => {
        m[key] = m.yearlyDepreciations[key];
      });
      return m;
    });
    var nestedTableCell = {
      content: "",
      styles: { valign: "middle", halign: "center" },
      colSpan: 5,

      // Dynamic height of nested tables are not supported right now
      // so we need to define height of the parent cell
      styles: { minCellHeight: 50 },
    };
    var tableBody = responseData.map((val) => {
      var listRow = [];
      //listRow.push(nestedTableCell); //
      listRow.push(val.userName);
      listRow.push(val.description);
      listRow.push(val.categoryName);
      listRow.push(val.typeName);
      listRow.push(val.cost);
      listRow.push(val.accumulatedDepr);
      listRow.push(val.bookVal);
      listRow.push(val.statusName);
      return listRow;
    });
    // const doc = new jsPDF();
    // doc.autoTable({
    //   head: [
    //     [
    //       "USER",
    //       "DESCRIPTION",
    //       "CATEGORY",
    //       "TYPE",
    //       "COST",
    //       "A/D",
    //       "BOOK",
    //       "STAUS",
    //     ],
    //   ],
    //   body: tableBody,
    //   tableWidth: "auto",
    //   styles: { cellPadding: 0.7, fontSize: 8 },
    //   columnStyles: {
    //     0: { cellWidth: 25 },
    //     1: { cellWidth: 25 },

    //     // etc
    //   },
    // });
    // doc.save(filename);

    ///Test
    //createAutoTable(filename, tableBody);
    //renderPdfTableFromHTML();
    canvasPrint();
  }
};
export const YearlyDepreciationTable = (props) => {
  const { usefulLife, cost } = props;
  let costUseful = parseFloat((cost / usefulLife).toFixed(2)) || 0;

  if (!isFinite(costUseful)) {
    costUseful = 0;
  }
  if (!isFinite(cost)) {
    cost = 0;
  }

  let resultRow = (
    <>
      <tr>
        {Array.from({
          length: usefulLife + 3,
        }).map((_, index, arr) => (
          <td
            key={index}
            className={`${
              index == usefulLife + 2 ? "d-none" : index == 0 ? "depLable" : ""
            }`}
          >
            {`${
              index == 0 // if first cell
                ? `ANNUAL DEPRECIATION` // then show "YEARLY DEPRECIATION" lable in it
                : index < arr.length - 1 // else index is not zero ,other than first cell
                ? index == 1 // if index ==1 , second cell then
                  ? `$${(
                      parseFloat((costUseful / 2).toFixed(2)) || 0
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` // => show costUseful/2
                  : index === arr.length - 2 // if second last cell then
                  ? `$${(
                      parseFloat((costUseful / 2).toFixed(2)) || 0
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` //=> costUseful / 2 // => show costUseful/2
                  : `$${costUseful.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` // between first and last cells
                : 0 //last cell
            } `}{" "}
          </td>
        ))}
      </tr>
      {/* </tbody> */}
    </>
  );
  return <>{resultRow}</>;
};

export const YearlyAcccumulatedDepr = (props) => {
  const { usefulLife, cost } = props;
  let costUseful = parseFloat((cost / usefulLife).toFixed(2)) || 0;

  if (!isFinite(costUseful)) {
    costUseful = 0;
  }
  if (!isFinite(cost)) {
    cost = 0;
  }

  let resultRow = (
    <>
      <tr>
        {Array.from({
          length: usefulLife + 3,
        }).map((_, index, arr) => (
          <td
            key={index}
            className={`${
              index == usefulLife + 2 ? "d-none" : index == 0 ? "depLable" : ""
            }`}
          >
            {`${
              index == 0 // if first cell
                ? `YEARLY DEPRECIATION` // then show "YEARLY DEPRECIATION" lable in it
                : index < arr.length - 1 // else index is not zero ,other than first cell
                ? index == 1 // if index ==1 , second cell then
                  ? `$${(
                      parseFloat((costUseful / 2).toFixed(2)) || 0
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` // => show costUseful/2
                  : index === arr.length - 2 // if second last cell then
                  ? `$${(
                      parseFloat((costUseful / 2).toFixed(2)) || 0
                    ).toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` //=> costUseful / 2 // => show costUseful/2
                  : `$${costUseful.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` // between first and last cells
                : 0 //last cell
            } `}{" "}
          </td>
        ))}
      </tr>
      {/* </tbody> */}
    </>
  );
  return <>{resultRow}</>;
};

//*****************************************************Component Starting Point*********************************************
const AllExpandedInventories = forwardRef((props, ref) => {
  ///First Method
  const [expanded, setExpanded] = useState([...props.rowKeys]);
  const handleOnExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      let so = [...expanded, row.equipmentid];
      setExpanded(so);
    } else {
      let po = expanded.filter((x) => x !== row.equipmentid);
      setExpanded(po);
    }
  };
  //   // Similar to componentDidMount and componentDidUpdate:
  //   useEffect(() => {
  //     //setExpanded([...props.rowKeys]);
  //     showAllExpandableRows();
  //   });
  // Expandable Row
  const expandRow = {
    parentClassName: "parent-expand",
    //onlyOneExpanding: true,
    //showExpandColumn: true,
    expanded: expanded,
    onExpand: handleOnExpand,
    renderer: (row) => (
      <div className="expandTbl">
        {!_.isEmpty(row.equipmentdepreciations) && (
          <>
            <div class="strong d-flex justify-content-center text-primary font-weight-bold ">
              <h6>Yearly Depreciation and Book Values</h6>
            </div>
            {/* <div class="d-flex justify-content-center">
            <Table responsive striped bordered hover size="xs">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Accumulated Depreciation</th>
                  <th>Book Value</th>
                  <th className="d-none"> Id</th>
                </tr>
              </thead>
              <tbody>
                {row.equipmentdepreciations.map((item) => (
                  <tr key={item.id}>
                    {Object.values(item).map((val, index) => (
                      <td
                        className={
                          item.id === val || item.equipmentid === val
                            ? `d-none`
                            : "deprId"
                        }
                      >
                        {index == 1 || index == 2
                          ? `${val.toLocaleString(navigator.language, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              style: "currency",
                              currency: "USD",
                            })}`
                          : val}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div> */}

            <div className="equipExpandableView">
              <Table responsive striped bordered hover size="xs">
                <thead>
                  <tr className=" ">
                    <th />
                    {Array.from({
                      length: row.equipmentdepreciations.length,
                    }).map((_, index, arr) => (
                      <th key={index}>
                        {`${row.equipmentdepreciations[index].year}`}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Book Values</th>
                    {Array.from({
                      length: row.equipmentdepreciations.length,
                    }).map((_, index, arr) => (
                      <td key={index}>
                        {`${
                          Math.sign(
                            row.equipmentdepreciations[index].bookval
                          ) !== -1
                            ? row.equipmentdepreciations[
                                index
                              ].bookval.toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                style: "currency",
                                currency: "USD",
                              })
                            : 0
                        }`}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th>Accumulated Depreciation</th>
                    {Array.from({
                      length: row.equipmentdepreciations.length,
                    }).map((_, index, arr) => (
                      <td key={index} className="deprId">
                        {`${row.equipmentdepreciations[
                          index
                        ].deprval.toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          style: "currency",
                          currency: "USD",
                        })}`}
                      </td>
                    ))}
                  </tr>
                  <YearlyDepreciationTable
                    usefulLife={parseFloat(row.usefullife ? row.usefullife : 0)}
                    cost={parseFloat(+row.cost) || 0}
                    year={new Date().getFullYear()}
                  />
                </tbody>
              </Table>
            </div>
          </>
        )}
        {_.isEmpty(row.equipmentdepreciations) && (
          <div class="container">
            <div class="row noADData">
              <span>No Data to display!</span>
            </div>
          </div>
        )}
        {row.status === InventoryStatus.Disposed && (
          <div>
            <div class="strong d-flex justify-content-center text-primary font-weight-bold ">
              <h6>Disposal Information</h6>
            </div>

            <div className="row">
              <div className="col">
                <span className="font-weight-bold">Dispose Reason: </span>
                <span>
                  {_.isEmpty(row.disposedreason) ? "none" : row.disposedreason}
                </span>
              </div>
              <div className="col">
                <span className="font-weight-bold">Dispose Amount: </span>
                <span>
                  {_.isEmpty(row.disposedamount)
                    ? "none"
                    : `$${row.disposedamount}`}
                </span>
              </div>
              <div className="col">
                <span className="font-weight-bold">Dispose Date: </span>
                <span>
                  {" "}
                  {_.isEmpty(row.disposeddate)
                    ? "none"
                    : moment(row.disposeddate).format("MM/DD/YYYY")}
                </span>
              </div>
            </div>
          </div>
        )}
        {/* {showDeprSum(row)} */}
      </div>
    ),
  };
  const RemotePagination = (data) => (
    <div
      className={`Exp_Equipments_list_${equipmentType}_Tbl d-none`}
      id={`Exp_Equipments_list_${equipmentType}_Records`}
      key={`Exp_Equipments_list_${equipmentType}_Records_Parent`}
    >
      <ToolkitProvider
        keyField="equipmentid"
        data={data}
        columns={columns()}
        exportCSV={{
          fileName: getExportFileName(equipmentType, "csv"),
          exportAll: false,
          onlyExportFiltered: false,
        }}
      >
        {(props) => (
          <>
            <span className="d-flex justify-content-end">
              {!_.isEmpty(data) && (
                <>
                  <MyExportCSV
                    equipmentType={equipmentType}
                    isAdvanceSearchApplied={isAdvanceSearchApplied}
                    searchCriteria={searchCriteria}
                    activeSubHeader={activeSubHeader}
                    showHistory={showHistory}
                    onClickCSVBtn={onClickCSVBtn}
                    {...props.csvProps}
                  />
                  {/* <MyExportPDF
                    currentTable={`EquipmentsList_${equipmentType}_Records`}
                    equipmentType={equipmentType}
                    isAdvanceSearchApplied={isAdvanceSearchApplied}
                    searchCriteria={searchCriteria}
                    activeSubHeader={activeSubHeader}
                    showHistory={showHistory}
                    selectedFilterYear={selectedFilterYear}
                    onClickPdfBtn={onClickPdfBtn}
                  /> */}
                </>
              )}
            </span>
            <BootstrapTable
              hover
              remote
              loading={loading}
              keyField="equipmentid"
              //   pagination={paginationFactory({
              //     paginationTotalRenderer: TableCustomTotal,
              //     page,
              //     sizePerPage,
              //     totalSize,
              //     showTotal,
              //     sizePerPageList: [
              //       {
              //         text: "10",
              //         value: 10,
              //       },
              //       {
              //         text: "25",
              //         value: 25,
              //       },
              //       {
              //         text: "30",
              //         value: 30,
              //       },
              //       {
              //         text: "50",
              //         value: 50,
              //       },
              //       {
              //         text: "All",
              //         value: totalSize,
              //       },
              //     ],
              //   })}
              //onDataSizeChange={onDataSizeChange}
              expandRow={expandRow}
              onTableChange={onTableChange}
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(40, 45, 42, 0.19)",
                  }),
                },
              })}
              noDataIndication={() => {
                return loading === false ? <NoDataIndication /> : <Empty />;
              }}
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  );
  const showTotal = true;
  //*********************** Component Main Props *************************

  const {
    listInventories,
    page,
    sizePerPage,
    handleTableChange: onTableChange,
    totalSize,
    loading,
    equipmentType,
    equipmentId,
    viewEquipment,
    viewFiles,
    disposeEquipment,
    isAdvanceSearchApplied,
    searchCriteria,
    activeSubHeader,
    showHistory,
    deleteEquipment,
    onDataSizeChange,
    selectedFilterYear,
    searchFilterCount,
    onClickCSVBtn,
    onClickPdfBtn,
    rowKeys,
  } = props;
  const showAllExpandableRows = () => {
    let rowkeys = listInventories.map((m) => m.equipmentid);
    setExpanded(rowkeys);
  };

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    showAllExpandableRows,
  }));

  let inventoryCols = [
    {
      text: "User",
      dataField: "userName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FEAccountingUser),
      headerStyle: (colum, colIndex) => {
        return {
          width: "7%",
        };
      },
      formatter: (cellContent, row) => {
        return (
          <div>
            {/* <Link
              to={{
                pathname: "/directory/userAction/" + row.user,
              }}
            >
              {cellContent}
            </Link> */}
            {cellContent}
          </div>
        );
      },
      footer: equipmentType == InventoryStatus.NonAccReview && "",
      footerClasses: equipmentType != InventoryStatus.NonAccReview && "d-none",
    },
    {
      text: "descr.",
      dataField: "description",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FEAccountingDescription),
      headerStyle: (colum, colIndex) => {
        return {
          width: "9%",
        };
      },
      formatter: (cell, row) => {
        return <div>{cell}</div>;
      },
      footer: equipmentType == InventoryStatus.NonAccReview && "",
      footerClasses: equipmentType != InventoryStatus.NonAccReview && "d-none",
    },
    {
      text: "Budget",
      dataField: "budgetLineFullName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FEAccountingBudgetID),
      headerStyle: (colum, colIndex) => {
        return {
          width: "13%",
        };
      },
      formatter: (cell, row) => {
        return <div>{showBugdetlines(cell, row)}</div>;
        // if (cell == "PendingEVP") {
        //   return `Pending EVP Approval ${showExclamationSign(cell, row)}`;
        // } else return `${cell}` + showExclamationSign(cell, row);
      },
      footer: equipmentType == InventoryStatus.NonAccReview && "",
      footerClasses: equipmentType != InventoryStatus.NonAccReview && "d-none",
    },
    {
      text: "Category",
      dataField: "categoryFullName",
      hidden: !AuthService.canView(PermissionFeature.FEAccountingCategory),
      sort: true,

      headerStyle: (colum, colIndex) => {
        return {
          width: "6%",
        };
      },
      formatter: (cell, row) => {
        return row.category !== 4 ? (
          <div className="d-inline-block text-truncate categoryFN">{cell}</div>
        ) : (
          showCategory(cell, row)
        );
      },
      footer: equipmentType == InventoryStatus.NonAccReview && "",
      footerClasses: equipmentType != InventoryStatus.NonAccReview && "d-none",
    },
    {
      text: "Type",
      dataField: "typeName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FEAccountingType),
      headerStyle: (colum, colIndex) => {
        return {
          width: "6%",
        };
      },
      formatter: (cell, row) => {
        return <div>{cell}</div>;
      },
      footer: equipmentType == InventoryStatus.NonAccReview && "",
      footerClasses: equipmentType != InventoryStatus.NonAccReview && "d-none",
    },
    {
      text: "Cost",
      dataField: "cost",
      hidden: !AuthService.canView(PermissionFeature.FEAccountingCost),
      sort: true,
      formatter: currencyFormatter,
      headerStyle: (colum, colIndex) => {
        return {
          width: "4%",
        };
      },
      footer:
        !loading &&
        equipmentType == InventoryStatus.NonAccReview &&
        ((columnData) =>
          currencyFormatter(columnData.reduce((acc, item) => acc + item, 0))),
      footerClasses:
        equipmentType == InventoryStatus.NonAccReview
          ? "equipmentFooter"
          : "d-none",
    },
    {
      text: "A/D", //"2022 A/D."
      dataField: "accumulatedDepr",
      hidden: !AuthService.canView(PermissionFeature.FEAccountingAD),
      //csvExport: false,
      csvText: getADLabel(),
      sort: true,
      formatter: currencyFormatter,
      headerStyle: (colum, colIndex) => {
        return {
          width: "8%",
        };
      },
      footer:
        !loading &&
        equipmentType == InventoryStatus.NonAccReview &&
        ((columnData) =>
          currencyFormatter(columnData.reduce((acc, item) => acc + item, 0))),
      footerClasses:
        equipmentType == InventoryStatus.NonAccReview
          ? "equipmentFooter"
          : "d-none",
    },
    // {
    //   //text: getAccDeprLabel(), //"2022 DEP." //Need to get 2022 Year's Depriciation Value
    //   text: selectedFilterYear || "Default Year", //"2022 DEP."
    //   dataField: "yearlyDepr",
    //   hidden: !AuthService.canView(PermissionFeature.FEAccountingAD),
    //   //csvExport: false,
    //   sort: true,
    //   formatter: currencyFormatter,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "8%" };
    //   },
    //   footer:
    //     !loading &&
    //     equipmentType == InventoryStatus.NonAccReview &&
    //     ((columnData) =>
    //       currencyFormatter(columnData.reduce((acc, item) => acc + item, 0))),
    //   footerClasses:
    //     equipmentType == InventoryStatus.NonAccReview
    //       ? "equipmentFooter"
    //       : "d-none",
    // },
    {
      text: "Book",
      dataField: "bookVal",
      hidden: !AuthService.canView(PermissionFeature.FEAccountingBookVal),
      sort: true,
      formatter: currencyFormatter,
      headerStyle: (colum, colIndex) => {
        return {
          width: "4%",
        };
      },
      footer:
        !loading &&
        equipmentType == InventoryStatus.NonAccReview &&
        ((columnData) =>
          currencyFormatter(columnData.reduce((acc, item) => acc + item, 0))),
      footerClasses:
        equipmentType == InventoryStatus.NonAccReview
          ? "equipmentFooter"
          : "d-none",
    },

    {
      dataField: "equipmentid",
      sort: true,
      hidden: true,
      csvExport: false,
    },
    {
      dataField: "userMemberNo",
      hidden: true,
      csvExport: false,
    },
    // {
    //   text: "Summary",
    //   dataField: "summary",
    //   headerClasses: "text-nowrap px-sm-3 px-2 py-2",
    //   classes: "pl-3 pr-2 py-2",
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "6%" };
    //   },
    //   formatter: currencyFormatter,
    // },
    {
      text: "Depr. Sum",
      dataField: "deprSum",
      headerClasses: "text-nowrap px-sm-3 px-2 py-2",
      classes: "pl-3 pr-2 py-2",
      csvExport: false,
      formatter: (cell, row) => {
        return showDeprSum(row);
      },
      headerStyle: (colum, colIndex) => {
        return {
          width: "6%",
        };
      },
    },
    {
      text: "Status",
      dataField: "statusName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FEAccountingStatus),
      headerStyle: (colum, colIndex) => {
        return {
          width: "7%",
        };
      },
      formatter: (cell, row) => {
        return <div>{cell}</div>;
      },
    },
    {
      text: "",
      dataField: "inv_actions",
      isDummyField: true,
      hidden: true,
      headerClasses: "text-nowrap px-sm-3 px-2 py-2",
      classes: "pl-3 pr-2 py-2",

      // headerAttrs: {
      //   width:
      //     equipmentType == InventoryStatus.NonAccReview && showHistory && !loading
      //       ? "55px"
      //       : "72px",
      // },

      headerStyle: (colum, colIndex) => {
        return {
          width:
            equipmentType == InventoryStatus.NonAccReview ? "4.9%" : "4.4%",
        };
      },
      csvExport: false,
      formatter: (cell, row) => {
        return actionIcons(
          row,
          viewEquipment,
          viewFiles,
          disposeEquipment,
          deleteEquipment
        );
      },
    },
  ];

  //Prepare data for Inventory Table
  let data = listInventories.map((m) => {
    Object.keys(m.yearlyDepreciations).map((key) => {
      m[key] = m.yearlyDepreciations[key];
    });
    return m;
  });

  const columns = () => {
    if (data && data.length > 0) {
      if (selectedFilterYear.length == 0) {
        inventoryCols.splice(7, 0, {
          text: `${new Date().getFullYear()} Dep.`, //"2022 DEP."
          dataField: `Dep${new Date().getFullYear()}`,
          hidden: !AuthService.canView(PermissionFeature.FEAccountingAD),
          csvExport: true,
          csvFormatter: (cell, row, rowIndex) => `$ ${cell}`,

          sort: true,
          formatter: currencyFormatter,
          headerStyle: (colum, colIndex) => {
            return {
              width: "5%",
            };
          },
          footer:
            !loading &&
            equipmentType == InventoryStatus.NonAccReview &&
            ((columnData) =>
              currencyFormatter(
                columnData.reduce((acc, item) => acc + item, 0)
              )),
          footerClasses:
            equipmentType == InventoryStatus.NonAccReview
              ? "equipmentFooter"
              : "d-none",
        });
      } else {
        let spliceIdx = 7;
        for (let i = 0; i < selectedFilterYear.length; i++) {
          inventoryCols.splice(++spliceIdx, 0, {
            text: `${selectedFilterYear[i]} Dep.`, //"2022 DEP."
            dataField: `Dep${selectedFilterYear[i]}`, //Dep2022,Dep2023,Dep2024
            hidden: !AuthService.canView(PermissionFeature.FEAccountingAD),
            csvExport: true,
            csvFormatter: (cell, row, rowIndex) => `$ ${cell}`,

            sort: true,
            formatter: currencyFormatter,
            headerStyle: (colum, colIndex) => {
              return {
                width: "5%",
              };
            },
            footer:
              !loading &&
              equipmentType == InventoryStatus.NonAccReview &&
              ((columnData) =>
                currencyFormatter(
                  columnData.reduce((acc, item) => acc + item, 0)
                )),
            footerClasses:
              equipmentType == InventoryStatus.NonAccReview
                ? "equipmentFooter"
                : "d-none",
          });
        }
      }
    }

    return inventoryCols;
  };
  return (
    <>
      {/* <button className="btn btn-success" onClick={showAllExpandableRows}>
        Expand All
      </button> */}

      {RemotePagination(data)}
    </>
  );
});

AllExpandedInventories.propTypes = {
  listInventories: PropTypes.array.isRequired,
};

AllExpandedInventories.defaultProps = {
  listInventories: [],
};

export default AllExpandedInventories;
