import React, { Component, Fragment } from "react";
import { MDBInput } from "mdbreact";

import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";

class EmailPopup extends Component {
  constructor(props) {
    super(props);
  }
  componentWillReceiveProps(nextProps) {}

  render() {
    const {
      emailAddresses,
      handleChangeEmail,
      handleChangePEmail,
      handleRemoveEmail,
      handleFormSubmitEmail,
      handleResetProfileEmailAddress,
      handleAddEmail,
      member,
    } = this.props;
    return (
      <div
        class="modal fade"
        id="EditEmailModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="EditAddressModalTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-max-width modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content border-0">
            <div class="modal-header py-2 bg-secondary">
              <h5 class="modal-title mt-0 text-white" id="EditRegionModalTitle">
                {" "}
                Edit Email Address{" "}
              </h5>
              <button
                type="button"
                onClick={handleResetProfileEmailAddress}
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {emailAddresses.map((item, idx) => (
                <div key={idx} data-repeater-list="group-a">
                  <div data-repeater-item className="row mb-4">
                    <div className="col-11 row">
                      <div className="col-sm-6">
                        <div data-id={idx}>
                          <MDBInput
                            name="email"
                            containerClass="mb-0 w-100"
                            value={item.email}
                            onChange={handleChangeEmail}
                            label={`Email ${idx + 1}`}
                            data-obj="emailAddresses"
                            disabled={
                              !AuthService.canOwnUpdate(
                                PermissionFeature.ContactEmailAddresses,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberUpdate(
                                PermissionFeature.ContactEmailAddresses
                              )
                            }
                            group
                            type="text"
                          >
                            <span className="text-danger">
                              {item.email_err}
                            </span>
                          </MDBInput>
                        </div>
                      </div>
                      <div className="col-sm-6 pt-sm-4">
                        <div data-id={idx} className="pt-2">
                          <label>Primary?</label>
                          <input
                            type="checkbox"
                            id={`EmailPrimary-${idx}`}
                            name="isprimary"
                            switch="bool"
                            data-obj="emailAddresses"
                            data-name="Email"
                            disabled={
                              !AuthService.canOwnUpdate(
                                PermissionFeature.ContactEmailAddresses,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberUpdate(
                                PermissionFeature.ContactEmailAddresses
                              )
                            }
                            checked={item.isprimary}
                            onChange={handleChangePEmail}
                          />
                          <label
                            className="mb-n2 ml-4"
                            htmlFor={`EmailPrimary-${idx}`}
                            data-on-label="Yes"
                            data-off-label="No"
                          />

                          <span className="d-block text-danger">
                            {item.isprimary_err}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-1 p-2 pt-sm-3 pb-sm-1 align-self-center">
                      <div data-id={idx}>
                        {(AuthService.canOwnDelete(
                          PermissionFeature.ContactEmailAddresses,
                          this.props.memberid
                        ) ||
                          AuthService.canDelete(
                            PermissionFeature.ContactEmailAddresses
                          )) && (
                          <button
                            type="button"
                            data-id={idx}
                            onClick={handleRemoveEmail}
                            className="btn btn-danger btn-sm"
                          >
                            <i data-id={idx} className="fas fa-trash-alt" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <hr className="my-2" />
              <div className="row">
                <div className="col-8">
                  <div className="float-left text-left">
                    <button
                      onClick={handleFormSubmitEmail}
                      className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-sm btn-danger w-md waves-effect waves-light"
                      type="button"
                      id="emailPopupCloseBtn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleResetProfileEmailAddress}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="col-4 align-self-center">
                  {(AuthService.canOwnAdd(
                    PermissionFeature.ContactEmailAddresses,
                    this.props.memberid
                  ) ||
                    AuthService.canAdd(
                      PermissionFeature.ContactEmailAddresses
                    )) && (
                    <a
                      onClick={handleAddEmail}
                      className="text-success c-pointer float-right mo-mt-2"
                    >
                      <i className="fas fa-plus"></i> Add Email
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EmailPopup;
