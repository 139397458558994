/// <reference path="general/financeformmodal.js" />
import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import AuthService from "../Auth/AuthService";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import FinanceAction from "../../store/finance/action";
import TextField from "@material-ui/core/TextField";
import FinanceTable from "./General/FinanceTable";
import FinanceForm from "./General/FinanceFormModal";
import FinanceFacEmpForm from "./General/FinanceFacEmpFormModal";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import Services from "./FinanceService";
import TableCustomTotal from "../Shared/TableCustomTotal";
import PermissionFeature from "../Permissions/PermissionFeature";
const filter = createFilterOptions();
class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
    };
  }
  componentDidMount() {
    //TO CLEAR ALL THE MODE ON LOAD
    this.props.setFinanceFormMode();
    this.props.setFinanceFacEmpFormMode();
    this.props.setFinanceFacEmpFormFacility();
    this.props.setFinanceFacEmpFormEmployer();
  }

  componentWillUnmount() {}
  render() {
    // New Work

    return (
      <AUX>
        <div className="container-fluid">
          <div class="page-title-box">
            <div className="row align-items-center">
              <div class="col-lg-12">
                <h4 class="text-uppercase page-title-search">
                  Finances
                  <span className="pl-2 h6 font-weight-normal">
                    {AuthService.canAdd(PermissionFeature.FinancesRecords) && (
                      <span
                        className="text-primary c-pointer pr-1"
                        onClick={() => {
                          this.props.openFinanceModal(null);
                        }}
                      >
                        <i class="fas fa-plus" />
                      </span>
                    )}

                    {AuthService.canAdd(PermissionFeature.FinancesRecords) &&
                      this.props.Finance.FacEmpFormMode && (
                        <span className="col-3 mt-lg-0 mt-3">
                          <span
                            className="text-primary ml-2 c-pointer"
                            onClick={() => {
                              this.props.openFinanceFacEmpModal();
                            }}
                          >
                            Add Facility/Employer Members Dues
                          </span>
                        </span>
                      )}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <FinanceFacEmpForm />
          <FinanceTable />
          <FinanceForm />
        </div>
      </AUX>
    );
  }
}

export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(Finance);
