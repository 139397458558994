import AUX from "../../../hoc/Aux_";
import React, { Component } from "react";
import { MDBInput } from "mdbreact";
import $ from "jquery";
import _ from "lodash";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import { Modal } from "react-bootstrap";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic/build/ckeditor";
import EmailListService from "../../Email/EmailListService";
import SendWithoutSubject from "../../Email/ModalPopups/SendWithoutSubject";
import MailGunEmailTypes from "../../Email/Enums/MailGunEmailTypes";
import CKEditorTextEditor from "./CKEditorTextEditor";
import { CompositionModalMode } from "../../Registrations/Helpers/LookUpValues";

class CompositionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: this.props.showModal,
      keyword: "",
      recepientText: "",
      recepientText_err: "",
      subjectText: "",
      valid: false,
      redirectToNewPage: false,

      membersMethods: this.props.membersMethods,
      memberName: this.props.memberName,
      isLookup: this.props.isLookup || false,
      page: 1,
      facility: this.props.facility ? this.props.facility : "",
      data: [],
      sizePerPage: 25,
      totalCount: 0,
      keyword: "",
      SortOrder: "seniorityasc",
      isAdvanceSearch: false,
      delId: 0,
      filters: [],
      showActive: true,
      memberId: AuthService.getProfile().memberData.id,
      ckeditorText: "",
      smsText: "",
      chars_Count: 0,
      chars_exceed: false,
      emailAddress: "",
      numbers: "",
      mgListAddress: "",
      selectedPrivilege: "",
      emailListId: 0,
      replyPreference: "",
      showSendWtSubModal: false,
      sendEmailUrl: "",
      sendEmailBody: {},
      postListAddress: "",
    };
    this.confirmSendSendWtSubModal = () => {
      this.confirmed = () => {
        this.callSendEmailApi(
          this.state.sendEmailUrl,
          this.state.sendEmailBody
        );
        this.toggleShowSendWtSubModal();
      };
      return this;
    };
  }
  toggleShowSendWtSubModal = () => {
    this.setState({ showSendWtSubModal: !this.state.showSendWtSubModal });
  };
  resetState = () => {
    this.setState({ recepientText: "", ckeditorText: "", subjectText: "" });
  };

  smsTextChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({
      chars_Count: event.target.value.length,
      chars_exceed: event.target.value.length > 160 ? true : false,
    });
  };

  RecepientChangeHandler = (event) => {
    console.log({ [event.target.name]: event.target.value });

    this.setState({ [event.target.name]: event.target.value });

    var inputValue = event.target.value.trim();
    if (inputValue !== "") {
      if (
        inputValue.indexOf(" ") > 0 &&
        !(
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[0]) &&
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[1])
        )
      ) {
        $(".MemberSearchField").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Name should contain alphabets only.",
        });
        this.setState({ valid: false });
      } else if (
        !(/^[a-zA-Z ]*$/.test(inputValue) || /^[0-9]*$/.test(inputValue))
      ) {
        /// alphanumeric or special characers with one word
        $(".MemberSearchField").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Alphanumeric is not allowed.",
        });
        this.setState({ valid: false });
      } else {
        $(".MemberSearchField").removeClass("inputBorderRed");
        this.setState({ [event.target.name + "_err"]: "" });
        this.setState({ valid: true });
      }
    } else {
      this.setState({ valid: false });
      this.setState({ [event.target.name + "_err"]: "" });
      $(".MemberSearchField").removeClass("inputBorderRed");
    }
  };

  SubjectChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  SendEmail = () => {
    let {
      mgListAddress,
      selectedPrivilege,
      replyPreference,
      subjectText,
      ckeditorText,
      emailListId,
      postListAddress, /// Post List Address would have zero subscribers
    } = this.state;
    var memberData = JSON.parse(localStorage.getItem("memberData")) || {
      id: "0",
    };

    let url = "";
    let body = {};
    let { listType, entityId = 0 } = this.props;

    if (
      (listType == MailGunEmailTypes.Member &&
        !_.isEmpty(JSON.parse(localStorage.getItem("BucketSearch")))) ||
      entityId == 0 ///For Basic Search
    ) {
      url = "/api/Mailgun/SendEmailToBucketMembers";
      body = {
        UserID: memberData.id,
        recepient: document.getElementById("Email").value,
        subject: subjectText,
        text: ckeditorText,
        isBucketHasMembers: localStorage.getItem("BucketSearch") != null,
        emailListId: emailListId,
      };
    } else {
      url = "/api/Mailgun/SendSimpleEmailToMailingList";
      body = {
        UserID: memberData.id,
        recepient: mgListAddress, // List Addrese would have all the subscribers
        subject: subjectText,
        text: ckeditorText,
        listType: this.props.listType,
        entityId: this.props.entityId,
        emailListId: emailListId,
        access_level: selectedPrivilege,
        natcaRecipientMembers: document.getElementById("Email").value,
        reply_preference: replyPreference,
        isBucketHasMembers: localStorage.getItem("BucketSearch") != null,
      };
    }
    this.setState({ sendEmailUrl: url });
    this.setState({ sendEmailBody: body });
    if (!_.isEmpty(body.recepient)) {
      if (_.isEmpty(body.subject)) {
        this.setState({ showSendWtSubModal: true });
      } else {
        this.callSendEmailApi(url, body);
      }
    } else {
      toast.warn(
        "Email List not Found, or Bucket Member doesn't have any Emails!"
      );
    }
  };
  callSendEmailApi = (url, body) => {
    this.showSpinner();
    axios.post(url, body).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.hideSpinner();
        toast.success(data.message);
        this.resetState();
        this.handleClose();
      } else {
        this.hideSpinner();
        toast.error(data.message);
      }
    });
  };
  SendSMS = () => {
    const url = "/api/Nexcom/SendSMS";
    var memberData = JSON.parse(localStorage.getItem("memberData"));
    let body = {
      recepient: document.getElementById("Email").value,
      message: this.state.smsText,
    };

    axios.post(url, body).then(({ data }) => {
      this.showSpinner();

      if (data.status && data.status.toLowerCase() == "success") {
        this.hideSpinner();
        toast.success(data.message);
        this.handleClose();
        localStorage.removeItem("BucketSearch");
      } else {
        this.hideSpinner();
        toast.error(data.message);
        this.handleClose();
      }
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showModal: false,
    });
  };

  canCreateOrUpdatEmailList = () => {
    return true;
  };
  onShow = async () => {
    //console.log("CS onShow Called");
    if (this.props.check == CompositionModalMode.Email) {
      let { entityId, listType } = this.props;

      //It could be Facility,Team or Registration Email List
      if (
        entityId > 0 &&
        (listType == MailGunEmailTypes.Facility ||
          listType == MailGunEmailTypes.Team ||
          listType == MailGunEmailTypes.Area ||
          listType == MailGunEmailTypes.Region ||
          listType == MailGunEmailTypes.Registrants)
      ) {
        let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${entityId}&entityType=${listType}`;

        await axios.get(url).then(async ({ data }) => {
          if (data.exists != undefined) {
            this.setState({
              emailListExists: data.exists,
              mgListAddress: data.mgListAddress,
              emailListId: data.emailListId,
              selectedPrivilege: data.selectedPrivilege,
              replyPreference: data.reply_preference,
              postListAddress: data.postListAddress,
            });
            // Mailing list exists & Bucket is Empty So Fetch Additional Mailing Lists Members Also.
            data.exists && this.setState({ mg_Mailing_List_Found: true });
            if (
              (data.exists &&
                listType == MailGunEmailTypes.Facility &&
                _.isEmpty(JSON.parse(localStorage.getItem("BucketSearch")))) ||
              (data.exists && listType == MailGunEmailTypes.Team) ||
              (data.exists && listType == MailGunEmailTypes.Region) ||
              (data.exists && listType == MailGunEmailTypes.Area) //||
              //(data.exists && listType == MailGunEmailTypes.Registrants)
            ) {
              await this.props.getAdditionalSubsribersEmailAddresses(
                data.mgListAddress
              );
            } else if (
              _.isEmpty(JSON.parse(localStorage.getItem("BucketSearch"))) &&
              !data.exists &&
              listType == MailGunEmailTypes.Facility
            ) {
              toast.warn("Email List/Bucket not Exist.");
            } else if (
              !data.exists &&
              listType !== MailGunEmailTypes.Facility
            ) {
              toast.warn("Email List not Exist.");
            }
          }
        });
      } // Saved Search Mailing List
      else if (entityId > 0 && listType == MailGunEmailTypes.Member) {
        await EmailListService.GetSavedSearchDetail(entityId)
          .then(async ({ data: response }) => {
            //Saved Search Mailing List Found
            if (response.status === "success") {
              this.setState({
                mgListAddress: response.searchDetail.email_address,
                emailListId: response.searchDetail.id,
                selectedPrivilege: response.selectedPrivilege,
                replyPreference: response.searchDetail.reply_preference,
                postListAddress: response.postListAddress,
              });
              // Bucket Empty && Mailing List Exists
              let isBucketEmpty = _.isEmpty(
                JSON.parse(localStorage.getItem("BucketSearch"))
              );
              if (isBucketEmpty) {
                /// There should be other cases where we should avoid this
                await this.props.getAdditionalSubsribersEmailAddresses(
                  response.searchDetail.email_address
                );
              } else {
                //Bucket is not empty
                console.log("bucket not empty");
              }

              this.setState({
                mg_Mailing_List_Found: true,
              });
            }
            //Saved Search Mailing List NOT Found
            else {
              if (_.isEmpty(JSON.parse(localStorage.getItem("BucketSearch")))) {
                toast.warn(
                  "Saved search Email List not Found and Bucket is also Empty."
                );
              }
              var recipientsEmails =
                this.props.addresses &&
                this.props.addresses
                  .filter((ele) => ele != "" && ele != undefined && ele != null)
                  .join(";");
              if (
                _.isEmpty(recipientsEmails) &&
                !_.isEmpty(JSON.parse(localStorage.getItem("BucketSearch")))
              ) {
                toast.warn("Bucket Members doesn't have any Emails.");
              }
            }
          })
          .catch((ex) => {
            console.log(ex);
            toast.error("Something went wrong!");
          });
      }
    }
    // const { refreshAddresses } = this.props;
    // if (refreshAddresses) {
    //   refreshAddresses();
    // }
    //console.log("CS onShow End");
  };
  onChangeEmailBody = (data) => {
    this.setState({ ckeditorText: data });
  };
  render() {
    const { listType } = this.props;
    return (
      <AUX>
        <Modal
          show={this.props.showModal}
          onHide={this.handleClose}
          size="xl"
          centered
          animation={true}
          backdrop="static"
          onShow={this.onShow}
          className={
            listType == MailGunEmailTypes.Registrants ? "nested-cs-modal" : ""
          }
        >
          <Modal.Header
            closeButton
            className={
              listType == MailGunEmailTypes.Registrants
                ? "bg-secondary text-white"
                : ""
            }
          >
            <Modal.Title>
              <h5 className="modal-title mt-0">
                Composition Modal
                {this.props.check === CompositionModalMode.Email &&
                  !this.props.isBucketFilled &&
                  (this.props.isFRTCS || // Bcz only FRT or Saved Search colud have Mailing lists, for Other like Basic or Advance Search there will no Email List
                    this.props.listType == MailGunEmailTypes.Member) &&
                  this.state.mg_Mailing_List_Found &&
                  this.state.mgListAddress && (
                    <span className="modal-title mt-1 float-right small">
                      &nbsp;(for Mailing List Address{" "}
                      {`"${this.state.mgListAddress}"`})
                    </span>
                  )}
                {this.props.check === CompositionModalMode.Email &&
                  this.props.isSaveSearchFacilityCS &&
                  this.props.isBucketFilled && (
                    <span className="small">&nbsp;(for Bucket Members)</span>
                  )}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="some-class">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="md-form form-group">
                    <MDBInput
                      name="recepientText"
                      id="Email"
                      onChange={this.RecepientChangeHandler}
                      label={
                        this.props.check === CompositionModalMode.Email
                          ? "Emails"
                          : "Numbers"
                      }
                      group
                      type="text"
                      required
                      value={
                        this.props.check === CompositionModalMode.Email
                          ? this.props.addresses &&
                            this.props.addresses
                              .filter(
                                (ele) =>
                                  ele != "" && ele != undefined && ele != null
                              )
                              .join(";")
                          : this.props.addresses.length > 1
                          ? this.props.addresses
                              .filter(
                                (ele) =>
                                  ele != "" && ele != undefined && ele != null
                              )
                              .join(";")
                          : this.props.addresses
                      }
                    >
                      <div className="invalid-feedback">
                        {this.state.recepientText_err}
                      </div>
                    </MDBInput>
                  </div>
                  {this.props.check === CompositionModalMode.Email && (
                    <div className="md-form form-group">
                      <MDBInput
                        name="subjectText"
                        id="subject"
                        onChange={this.SubjectChangeHandler}
                        label="Subject"
                        group
                        type="text"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {this.props.check === CompositionModalMode.Email && (
                  <CKEditorTextEditor
                    onChangeInput={(data) => this.onChangeEmailBody(data)}
                  />
                  // <CKEditor
                  //   //editor={ClassicEditor}
                  //   onInit={(editor) => {
                  //     // You can store the "editor" and use when it is needed.
                  //     console.log("Editor is ready to use!", editor);
                  //     // You can store the "editor" and use when it is needed.
                  //     // console.log("Editor is ready to use!", editor);

                  //     //editor.plugins = [CKFinder, ...editor.plugins];
                  //     editor.editing.view.change((writer) => {
                  //       writer.setStyle(
                  //         "height",
                  //         "125px",
                  //         editor.editing.view.document.getRoot()
                  //       );
                  //     });

                  //     //editor.on("fileUploadRequest", function(evt) {
                  //     //  var xhr = evt.data.fileLoader.xhr;
                  //     //  console.log(this.file);
                  //     //  alert("fileUploadRequest");
                  //     //  // xhr.setRequestHeader("Cache-Control", "no-cache");
                  //     //  // xhr.setRequestHeader("X-File-Name", this.fileName);
                  //     //  // xhr.setRequestHeader("X-File-Size", this.total);
                  //     //  // xhr.send(this.file);

                  //     //  // Prevented the default behavior.
                  //     //  //evt.stop();
                  //     //});
                  //     //editor.on("dialogDefinition", function(ev) {
                  //     //  var dialogName = ev.data.name;
                  //     //  var dialogDefinition = ev.data.definition;
                  //     //  alert("dialogDefinition");
                  //     //  if (dialogName == "image") {
                  //     //    dialogDefinition.removeContents("Upload");
                  //     //    dialogDefinition.addContents({
                  //     //      title: "Upload",
                  //     //      id: "upload",
                  //     //      label: "Upload",
                  //     //      elements: [
                  //     //        {
                  //     //          type: "html",
                  //     //          html:
                  //     //            '<form><input id="imageupload" type="file" name="files[]" />{%csrf_token%}</form>',
                  //     //        },
                  //     //      ],
                  //     //    });
                  //     //  }
                  //     //});
                  //   }}
                  //   onChange={(event, editor) => {
                  //     const data = editor.getData();
                  //     this.setState({ ckeditorText: data });
                  //   }}
                  //   onBlur={(event, editor) => {
                  //     console.log("Blur.", editor);
                  //   }}
                  //   onFocus={(event, editor) => {
                  //     console.log("Focus.", editor);
                  //   }}
                  //   AutoParagraph={false}
                  //   //config={{
                  //   //  ckfinder: {
                  //   //    // Upload the images to the server using the CKFinder QuickUpload command
                  //   //    // You have to change this address to your server that has the ckfinder php connector
                  //   //    uploadUrl:
                  //   //      "https://example.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Images&responseType=json",
                  //   //  },
                  //   //}}
                  // />
                )}
                {this.props.check === CompositionModalMode.SMS && (
                  <>
                    <textarea
                      class="form-control z-depth-1"
                      id="exampleFormControlTextarea6"
                      name="smsText"
                      rows="3"
                      placeholder="Write something here..."
                      onChange={this.smsTextChangeHandler}
                      maxLength="160"
                    />
                    <div style={{ textAlign: "center" }}>
                      <span style={{ float: "right" }}>
                        {this.state.chars_Count}/160
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div>
                <br />
                <div className="col-12 text-center mb-10 mt-10 pt-4">
                  <button
                    className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-success w-md mc"
                    onClick={() =>
                      this.props.check === CompositionModalMode.Email
                        ? this.SendEmail()
                        : this.SendSMS()
                    }
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <SendWithoutSubject
          modal={this.state.showSendWtSubModal}
          toggle={this.toggleShowSendWtSubModal}
          confirm={this.confirmSendSendWtSubModal}
        />
      </AUX>
    );
  }
}

export default CompositionModal;
