import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import PACHeader from "../General/PACHeader";
import Services from "../PACService";
import RequestDetailModal from "./RequestDetailModal";
import "../../../assets/css/PacStyle.css";
import { connect } from "react-redux";
import PACTrackerAction from "../../../store/pacTracker/action";
import CandidateDelailModal from "../Candidate/CandidateDetailModal";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class ReviewRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includeClosed: false,
      includeClosedTitle: "Show History",
      myRequestData: [],
      myRequestViewer: [],
      positionsOptions: [],
      selected: [0, 1],
      selectAll: false,
      searchTerm: "",
      sizePerPage: 10,
      requestType: this.props.pacState.ReviewRequestType.Reviewer,
      requestStatuses: [
        { id: "2", name: "Submitted" },
        { id: "3", name: "Supported" },
        { id: "4", name: "Returned" },
        { id: "5", name: "Approved" },
        { id: "6", name: "Withdrawn" },
        { id: "7", name: "Denied" },
        { id: "8", name: "Closed" },
        { id: "9", name: "CheckIssued" },
      ],
      selectedStatuses: [],
      isNotificationCall: false,
      currentRequestId: 0,
      identificationNum: Math.random(),
    };

    this.rowClasses = (row, rowIndex) => {
      let classes = null;

      if (row.processstatus == "Draft") {
        classes = "bg-light-blue";
      }

      return classes;
    };
    this.setReloader = this.setReloader.bind(this);
    this.formate_amount = this.formate_amount.bind(this);
    this.filterMyRequest = this.filterMyRequest.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.handleOnSelectIndividual = this.handleOnSelectIndividual.bind(this);
    this.getColumn = this.getColumn.bind(this);
    this.batchProcess = this.batchProcess.bind(this);
    this.getReviewRequests = this.getReviewRequests.bind(this);
    this.getReviewRequests();
    this.viewAllLink = this.viewAllLink.bind(this);
    this.generatingPDF = this.generatingPDF.bind(this);
    this.selectStatus = this.selectStatus.bind(this);
  }
  selectStatus = (value) => {
    this.state.selectedStatuses = value;
    this.setState({
      selectedStatuses: this.state.selectedStatuses,
    });

    this.getReviewRequests();
  };
  viewAllLink = (data) => {
    this.setState({ sizePerPage: data.length });
  };
  formate_amount = (n) => {
    var num_parts = n.toString().split(".");
    num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };
  getColumn() {
    return [
      {
        dataField: "checked",
        headerClasses: "text-nowrap",
        headerFormatter: (cellContent, row) => {
          return (
            <div className="custom-checkbox pl-4">
              <input
                className="custom-control-input custom-control-input-override"
                type="checkbox"
                name={"checkboxHeader"}
                id={"checkboxHeader"}
                checked={this.state.selectAll}
                onClick={this.handleOnSelectAll}
              />
              <label
                className="custom-control-label custom-control-label-override"
                for={"checkboxHeader"}
              />
            </div>
          );
        },
        formatter: (cellContent, row, rowIndex /*, extraData*/) => {
          return (
            <div className="card-text pl-4 custom-checkbox">
              <input
                className="custom-control-input custom-control-input-override"
                type="checkbox"
                name={"checkbox" + rowIndex}
                id={"checkbox" + rowIndex}
                checked={row.checked}
                // onChange={(e) => {
                //   row.checked = e.target.checked;

                //   this.setState({
                //     myRequestData: this.state.myRequestData,
                //     checkAll: false,
                //   });
                // }}
              />

              <label
                className="custom-control-label custom-control-label-override"
                for={"checkbox" + rowIndex}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  this.handleOnSelectIndividual(e, row.id);
                }}
              />
            </div>
          );
        },
      },
      {
        dataField: "candidateName",
        text: "Candidate Name",
        sort: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row, rowIndex /*, extraData*/) => {
          return (
            <div>
              {AuthService.canView(PermissionFeature.Candidates) ? (
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.props.openCandidateDetailModal({
                      candidateId: row.candidateId,
                      candidateModalType: 2,
                    });
                  }}
                >
                  {row.candidateName}
                </a>
              ) : (
                row.candidateName
              )}
            </div>
          );
        },
      },
      {
        text: "Event Date",
        dataField: "eventDate",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return <div>{row.eventDate && row.eventDate.split("T")[0]}</div>;
        },
      },
      {
        text: "State",
        dataField: "state",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
      },
      {
        text: "Event Name",
        dataField: "eventName",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
      },
      {
        text: "Amount",
        dataField: "requestedamount",
        sort: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row) => {
          return (
            <div>
              {!row.approvedamount && this.formate_amount(row.requestedamount)}
              {row.approvedamount && this.formate_amount(row.approvedamount)}
            </div>
          );
        },
      },
      {
        text: "Natca Member",
        dataField: "submitterName",
        sort: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row) => {
          return (
            <div>
              <Link to={"/directory/userAction/" + row.submitterId}>
                {row.submitterName}
              </Link>
            </div>
          );
        },
      },
      {
        text: "Status",
        dataField: "status",
        sort: true,
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          if (row.status == "CheckIssued") return "Check Issued";
          else return row.status;
        },
      },
    ];
  }

  async getReviewRequests() {
    $(".loading").show();

    var type = 0;
    if (
      AuthService.canView(PermissionFeature.ReviewRequests) &&
      AuthService.canView(PermissionFeature.PABReview)
    ) {
      type = 3;
    } else if (AuthService.canView(PermissionFeature.ReviewRequests)) {
      type = 1;
    } else if (AuthService.canView(PermissionFeature.PABReview)) {
      type = 2;
    }

    var statusIds = this.state.selectedStatuses.map((val) => val.id).join(",");
    let data = await Services.GetReviewRequests(type, statusIds);
    if (!_.isEmpty(data)) {
      this.setState({
        selectAll: false,
        myRequestData: data.data,
      });
    }
    $(".loading").hide();
    $("#tableRow").removeClass("d-none");
  }

  async batchProcess(processType) {
    $(".loading").show();
    let filtered = this.filterMyRequest();
    if (!_.isEmpty(filtered)) {
      let checkedItems = filtered.filter((x) => x.checked);
      if (_.isEmpty(checkedItems)) {
        $(".loading").hide();
        toast.info("Please select rows first.");
      } else {
        let checkedIds = checkedItems.map((y) => y.id);
        let data = await Services.BatchProcessStatusUpdate(
          processType,
          checkedIds.join()
        );
        if (!_.isEmpty(data)) {
          $(".loading").hide();
          toast.success(data.message);
          this.getReviewRequests();
        }
      }
    }
    $(".loading").hide();
  }
  async generatingPDF() {
    $(".loading").show();
    let filtered = this.filterMyRequest();
    if (!_.isEmpty(filtered)) {
      let checkedItems = filtered.filter((x) => x.checked);
      if (_.isEmpty(checkedItems)) {
        $(".loading").hide();
        toast.info("Please select rows first.");
      } else {
        let checkedIds = checkedItems.map((y) => y.id);
        let data = await Services.GeneratePDF(checkedIds);
        if (!_.isEmpty(data)) {
          $(".loading").hide();
        }
      }
    }
    $(".loading").hide();
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      e.stopPropagation();
      this.props.openRequestDetailModal({
        reviewRequestId: row.id,
        type: this.state.requestType,
      });
    },
  };
  handleOnSelectIndividual = (e, id) => {
    let checked = this.filterMyRequest();
    checked = checked.map((item) => {
      if (item.id == id) {
        item.checked = !item.checked;
        return item;
      }
    });
    this.setState({
      selectAll: false,
      myRequestData: this.state.myRequestData,
    });
  };
  handleOnSelectAll = (e) => {
    this.state.selectAll = e.target.checked;

    let checked = this.filterMyRequest();
    checked = checked.map((item) => {
      item.checked = e.target.checked;
      return item;
    });
    this.setState({ myRequestData: this.state.myRequestData });
  };
  filterMyRequest() {
    if (this.state.myRequestData != undefined) {
      return this.state.myRequestData.filter(
        (x) =>
          _.toLower(x.candidateName).includes(
            _.toLower(this.state.searchTerm || "")
          ) ||
          _.toLower(x.submitterName).includes(
            _.toLower(this.state.searchTerm || "")
          ) ||
          _.toLower(x.requestedamount).includes(
            _.toLower(this.state.searchTerm || "")
          )
      );
    } else {
      return null;
    }
  }

  componentDidMount() {
    //Incase notificaiton is clicked
    const id = this.props.location.state ? this.props.location.state.reqID : "";
    if (
      !this.state.isNotificationCall &&
      !_.isEmpty(id) &&
      this.state.currentRequestId != id
    ) {
      var notificationCall = this.props.location.state
        ? this.props.location.state.notificationCall
        : false;
      const identificationNum = this.props.location.state
        ? this.props.location.state.identificationNum
        : Math.random();

      if (notificationCall) {
        this.setState({
          isNotificationCall: true,
          currentRequestId: id,
          identificationNum: identificationNum,
        });
        this.showMyRequest(id);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const id = this.props.location.state ? this.props.location.state.reqID : "";
    const identificationNum = this.props.location.state
      ? this.props.location.state.identificationNum
      : Math.random();

    if (!_.isEmpty(id) && this.state.identificationNum != identificationNum) {
      var notificationCall = this.props.location.state
        ? this.props.location.state.notificationCall
        : false;
      if (notificationCall) {
        this.setState({
          isNotificationCall: true,
          currentRequestId: id,
          identificationNum: identificationNum,
        });
        this.showMyRequest(id);
      }
    }
  }

  showMyRequest = async (id) => {
    $(".loading").show();
    // let data = await Services.GetReviewRequestDetail(id);
    // let status = !_.isEmpty(data.data) ? data.data.status : "";
    $(".loading").hide();
    this.props.openRequestDetailModal({
      reviewRequestId: id,
      type: this.state.requestType,
    });
  };
  setReloader() {
    this.setState({ reloadEverything: true });
  }
  SizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    rowClasses,
    rowEvents
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
    };
    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowClasses={rowClasses}
          rowEvents={rowEvents}
        />
        {data.length > sizePerPage && (
          <a
            href="#"
            className="pl-1"
            onClick={(e) => {
              e.preventDefault();
              this.viewAllLink(data);
            }}
          >
            View All
          </a>
        )}
      </div>
    );
  }
  render() {
    const columns = this.getColumn();
    const { myRequestData } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <PACHeader />
        <div className="pac-container container-fluid">
          <div className="page-title-box">
            <div className="row pt-3 align-items-center">
              <div className="col-12">
                <h4 className="text-uppercase mt-0 mb-3 page-title-search">
                  Review Request
                </h4>
              </div>
              <div className="col-lg-6">
                <form role="search" className="app-search mt-0">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      id="keyword"
                      className="form-control rounded border mb-1 w-100"
                      placeholder="Search Event Date, Candidate Name, Submitter Name and Amount.."
                      onChange={(event) =>
                        this.setState({ searchTerm: event.target.value })
                      }
                    />
                    <button
                      type="button"
                      className="c-pointer text-secondary bg-transparent border-0"
                      onClick={(event) =>
                        this.setState({ searchTerm: $("#keyword").val() })
                      }
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
              <div class="dropdown px-lg-0 px-3 ml-lg-2 mt-lg-0 mt-2">
                <a
                  class="btn btn-outline-success px-3 py-1 rounded-pills dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Batch Process
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  {AuthService.canView(PermissionFeature.ReviewRequests) && (
                    <a
                      class="dropdown-item cursorPointer"
                      onClick={() => {
                        this.batchProcess(1);
                      }}
                    >
                      Support
                    </a>
                  )}

                  {AuthService.canView(PermissionFeature.PABReview) && (
                    <a
                      class="dropdown-item cursorPointer"
                      onClick={() => {
                        this.batchProcess(2);
                      }}
                    >
                      Approve
                    </a>
                  )}

                  <a
                    class="dropdown-item cursorPointer"
                    onClick={() => {
                      this.batchProcess(3);
                    }}
                  >
                    Deny
                  </a>
                  <a
                    class="dropdown-item cursorPointer"
                    onClick={() => {
                      this.batchProcess(4);
                    }}
                  >
                    Withdraw
                  </a>
                  <a
                    class="dropdown-item cursorPointer"
                    onClick={() => {
                      this.batchProcess(5);
                    }}
                  >
                    Close
                  </a>
                </div>
              </div>
              <div className="px-lg-0 px-3 ml-lg-2 mt-lg-0 mt-2">
                {!_.isEmpty(this.filterMyRequest()) && (
                  <button
                    type="button"
                    className="c-pointer mb-0 px-3 py-1 btn btn-sm btn-outline-success"
                    onClick={() => {
                      this.generatingPDF();
                    }}
                  >
                    <i class="cursorPointer pr-1 fas fa-file-pdf" />
                    Export PDF
                  </button>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Autocomplete
                  multiple
                  id="requestStatusesField"
                  options={this.state.requestStatuses}
                  size="small"
                  disableCloseOnSelect
                  filterOptions={(options, state) => options}
                  autoHighlight={true}
                  getOptionLabel={(option) => {
                    if (option != undefined) {
                      return option.name;
                    } else {
                      return "";
                    }
                  }}
                  onChange={(e, value, reason) => {
                    this.selectStatus(value);
                  }}
                  value={this.state.selectedStatuses}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Filter Statuses"
                      placeholder="Select Status"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {!_.isEmpty(this.filterMyRequest()) ? (
                this.RemotePagination(
                  columns,
                  "id",
                  this.filterMyRequest(),
                  this.SizePerPageChange,
                  this.state.sizePerPage,
                  this.rowClasses,
                  this.rowEvents
                )
              ) : (
                <p className="mt-4 text-center">No Request Are Available</p>
              )}
            </div>
          </div>
        </div>
        <RequestDetailModal getMyRequests={this.getReviewRequests} />
        <CandidateDelailModal />
      </AUX>
    );
  }
}

export default connect(
  (state) => ({ pacState: state.pacTracker }),
  {
    ...PACTrackerAction,
  }
)(ReviewRequest);
