import React from "react";
import {
  FaFile,
  FaFolder,
  FaFileUpload,
  FaFileDownload,
  FaFolderOpen,
  FaEye,
  FaChevronDown,
  FaChevronRight,
  FaTrash,
} from "react-icons/fa";
import styled from "styled-components";
import last from "lodash/last";
import PropTypes from "prop-types";
import _ from "lodash";

import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";

const getPaddingLeft = (level, type) => {
  let paddingLeft = level * 20;
  if (type === "file") paddingLeft += 20;
  return paddingLeft;
};

const StyledTreeNode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  padding-left: ${(props) => getPaddingLeft(props.level, props.type)}px;

  &:hover {
    background: lightgray;
  }
`;

const NodeIcon = styled.div`
  font-size: 12px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 5)}px;
`;

const getNodeLabel = (node) => last(node.path.split(" > "));

const TreeNode = (props) => {
  let fileInput = null;
  const {
    node,
    getChildNodes,
    level,
    onToggle,
    fileChangedHandler,
    deletNode,
    handleViewClick,
    handleUploadBtnClick,
    handleDownloadClick,
    isDeleteable,
    isAddablee,
    checkViewIconAccess,
  } = props;

  return (
    <React.Fragment>
      {node !== undefined && (
        <>
          <input
            className="d-none"
            type="file"
            accept=".pdf,.png,.gif,.jpg"
            data-id={node.id}
            onChange={(e) => fileChangedHandler(e)}
            ref={(input) => (fileInput = input)}
          />

          <StyledTreeNode level={level} type={node.type}>
            {node.parentid !== null && (
              <>
                <NodeIcon onClick={() => onToggle(node)}>
                  {(node.type === "folder" || node.type === "heading") &&
                    node.children &&
                    (node.children.length > 1 ||
                      (node.children.length > 0 && node.parentid === 15)) &&
                    (node.isOpen ? (
                      <FaChevronDown size="1.5em" />
                    ) : (
                      <FaChevronRight size="1.5em" />
                    ))}
                  {/* {(node.isOpen ? <FaChevronDown /> : <FaChevronRight />)} */}
                </NodeIcon>
                {node.iscurrentfile === false && (
                  <NodeIcon marginRight={4}>
                    {node.type === "folder" &&
                      node.children.length <= 1 &&
                      node.parentid !== 15 && <FaFile size="1.5em" />}
                    {node.type === "file" && <FaFile size="1.5em" />}
                    {(node.type === "folder" || node.type === "heading") &&
                      node.children &&
                      (node.children.length > 1 ||
                        (node.children.length > 0 && node.parentid === 15)) &&
                      node.isOpen === true && <FaFolderOpen size="1.5em" />}
                    {(node.type === "folder" || node.type === "heading") &&
                      node.children &&
                      (node.children.length > 1 || node.parentid === 15) &&
                      !node.isOpen && <FaFolder size="1.5em" />}
                  </NodeIcon>
                )}

                {/* <span role="button" onClick={() => onNodeSelect(node)}> */}

                {/* the Label of fileName Span */}

                {node.iscurrentfile === false && (
                  <span role="button">
                    {node.type === "folder" && (
                      <h6 className="h6tag">{getNodeLabel(node)}</h6>
                    )}
                    {node.type === "heading" && (
                      <h6 className="h6tag">{getNodeLabel(node)}</h6>
                    )}
                    {node.type === "file" &&
                      (isNaN(Date.parse(getNodeLabel(node)))
                        ? getNodeLabel(node)
                        : getNodeLabel(node))}
                  </span>
                )}

                {node.type !== "heading" && node.iscurrentfile === false && (
                  <>
                    {/* View Icon Click */}

                    {((node.children && node.children.length >= 1) ||
                      node.type === "file") &&
                      node.parentid !== 15 &&
                      checkViewIconAccess(node) && (
                        <a
                          target="_new"
                          href={
                            node.filepath &&
                            `/assets${node.filepath.split("assets")[1]}`
                          }
                          className="ml-1"
                          data-parentid={node.parentid}
                          data-id={node.id}
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty(
                                "color",
                                "#5b626b",
                                "important"
                              );
                            }
                          }}
                          onClick={(e) => handleViewClick(e)}
                          style={{ cursor: "pointer" }}
                        >
                          <FaEye size="1.5em" id="viewEye"></FaEye>
                        </a>
                      )}

                    {/* Download Icon Click */}

                    {((node.children && node.children.length >= 1) ||
                      node.type === "file") &&
                      node.parentid !== 15 && (
                        <a
                          href={
                            node.filepath &&
                            `/assets${node.filepath.split("assets")[1]}`
                          }
                          download={
                            node.filepath &&
                            node.filepath.split("FacilityFiles")[1]
                          }
                          className={`ml-1 ${node.parentid} ${node.type} ${node.id}`}
                          data-parentid={node.parentid}
                          data-id={node.id}
                          ref={(el) => {
                            if (el) {
                              el.style.setProperty(
                                "color",
                                "#5b626b",
                                "important"
                              );
                            }
                          }}
                          onClick={(e) => handleDownloadClick(e)}
                          style={{ cursor: "pointer" }}
                        >
                          <FaFileDownload
                            data-id={node.parentid}
                            size="1.5em"
                          ></FaFileDownload>
                        </a>
                      )}

                    {/* Upload Icon Click */}

                    {isAddablee && node.type === "folder" && (
                      <a
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              "color",
                              "#5b626b",
                              "important"
                            );
                          }
                        }}
                        style={{ color: "#5b626b" }}
                        className="ml-1"
                        data-id={node.id}
                        onClick={(e) => handleUploadBtnClick(e)}
                        style={{ cursor: "pointer" }}
                      >
                        <FaFileUpload size="1.5em"></FaFileUpload>
                      </a>
                    )}

                    {/* delete Icon Click */}
                    {isDeleteable && node.type === "file" && (
                      <a
                        ref={(el) => {
                          if (el) {
                            el.style.setProperty(
                              "color",
                              "#5b626b",
                              "important"
                            );
                          }
                        }}
                        style={{ color: "#5b626b" }}
                        data-id={node.id}
                        className="ml-1"
                        onClick={() => deletNode(node.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <FaTrash size="1.5em"></FaTrash>
                      </a>
                    )}
                  </>
                )}
              </>
            )}
          </StyledTreeNode>

          {node.isOpen &&
            getChildNodes(node).map((childNode, index) => {
              // console.log("childNode" + index, childNode);
              if (
                //!_.isEmpty(childNode.path) &&
                (AuthService.canFacilityView(
                  PermissionFeature.FilesConstitution
                ) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("Constitution")) ||
                (AuthService.canFacilityView(
                  PermissionFeature.FilesMeetingMinutes
                ) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("Meeting Minutes")) ||
                (AuthService.canFacilityView(PermissionFeature.FilesMous) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("MOUs")) ||
                (AuthService.canFacilityView(
                  PermissionFeature.FilesDuesRebate
                ) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("Dues Rebate")) ||
                (AuthService.canFacilityView(PermissionFeature.FilesIrs) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("IRS 990")) ||
                (AuthService.canFacilityView(PermissionFeature.FilesOsha) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("OSHA 301")) ||
                (AuthService.canFacilityView(PermissionFeature.FilesLmNumber) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("LM Form")) ||
                (AuthService.canFacilityView(
                  PermissionFeature.FilesFinancialAudit
                ) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("Financial Audit")) ||
                (AuthService.canFacilityView(
                  PermissionFeature.FilesVirtualAudit
                ) &&
                  !_.isEmpty(childNode) &&
                  !_.isEmpty(childNode.path) &&
                  childNode.path.includes("Virtual Audit"))
              ) {
                let isDeleteable =
                  (AuthService.canFacilityDelete(
                    PermissionFeature.FilesConstitution
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Constitution")) ||
                  (AuthService.canFacilityDelete(
                    PermissionFeature.FilesMeetingMinutes
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Meeting Minutes")) ||
                  (AuthService.canFacilityDelete(PermissionFeature.FilesMous) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("MOUs")) ||
                  (AuthService.canFacilityDelete(
                    PermissionFeature.FilesDuesRebate
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Dues Rebate")) ||
                  (AuthService.canFacilityDelete(PermissionFeature.FilesIrs) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("IRS 990")) ||
                  (AuthService.canFacilityDelete(PermissionFeature.FilesOsha) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("OSHA 301")) ||
                  (AuthService.canFacilityDelete(
                    PermissionFeature.FilesLmNumber
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("LM Form")) ||
                  (AuthService.canFacilityDelete(
                    PermissionFeature.FilesFinancialAudit
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Financial Audit")) ||
                  (AuthService.canFacilityDelete(
                    PermissionFeature.FilesVirtualAudit
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Virtual Audit"));

                let isAddablee =
                  (AuthService.canFacilityAdd(
                    PermissionFeature.FilesConstitution
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Constitution")) ||
                  (AuthService.canFacilityAdd(
                    PermissionFeature.FilesMeetingMinutes
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Meeting Minutes")) ||
                  (AuthService.canFacilityAdd(PermissionFeature.FilesMous) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("MOUs")) ||
                  (AuthService.canFacilityAdd(
                    PermissionFeature.FilesDuesRebate
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Dues Rebate")) ||
                  (AuthService.canFacilityAdd(PermissionFeature.FilesIrs) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("IRS 990")) ||
                  (AuthService.canFacilityAdd(PermissionFeature.FilesOsha) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("OSHA 301")) ||
                  (AuthService.canFacilityAdd(
                    PermissionFeature.FilesLmNumber
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("LM Form")) ||
                  (AuthService.canFacilityAdd(
                    PermissionFeature.FilesFinancialAudit
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Financial Audit")) ||
                  (AuthService.canFacilityAdd(
                    PermissionFeature.FilesVirtualAudit
                  ) &&
                    !_.isEmpty(childNode) &&
                    !_.isEmpty(childNode.path) &&
                    childNode.path.includes("Virtual Audit"));

                return (
                  <TreeNode
                    {...props}
                    key={index + 1}
                    node={childNode}
                    level={level + 1}
                    isDeleteable={isDeleteable}
                    isAddablee={isAddablee}
                  />
                );
              }
            })}
        </>
      )}
    </React.Fragment>
  );
};

TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  getChildNodes: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
  //onNodeSelect: PropTypes.func.isRequired,
  fileChangedHandler: PropTypes.func.isRequired,
};

TreeNode.defaultProps = {
  level: 0,
};

export default TreeNode;
