import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import $ from "jquery";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Region from "./Region";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import authAxios from "../../Shared/auth-header";
import axios from "../../Shared/auth-header";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import AuthService from "../../Auth/AuthService";
import Services from "../RFTeamsService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import TableCustomTotal from "../../Shared/TableCustomTotal";

const { SearchBar } = Search;

class Regions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: 0,
      code: "",
      region: "",
      description: "",
      valid: true,
      bulkProgress: true,
      rows: [],
      totalCount: "",
      delId: 0,
    };
    this.createRegionTeam = this.createRegionTeam.bind(this);
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (e.target.className.indexOf("del") < 0) {
          if (AuthService.canView(PermissionFeature.Region)) {
            this.props.history.push({
              pathname: `/directory/region/view/${row.id}`,
              type: "edit",
              uid: row.id,
            });
          }
        }
      },
    };

    this.columns = this.columns.bind(this);
  }
  preventDefaultCustom = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  async createRegionTeam(e, id) {
    $(".loading").show();
    this.preventDefaultCustom(e);
    let data = await Services.CreateRegionTeam(id);
    if (data) {
      this.getRegions();
    }
    $(".loading").hide();
  }
  handleChange = (evt) => {
    this.setState({
      [evt.target.name]:
        [evt.target.name] == "description"
          ? evt.target.value
          : evt.target.value.trim(),
    });
  };

  handleSubmit = (evt) => {
    this.preventDefaultCustom(evt);
    if (!this.state.valid) {
      return false;
    }
    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      return false;
    }

    const data = {
      Id: this.state.uid,
      code: this.state.code,
      description: this.state.description.trim(),
    };
    $(".loading").show();
    axios
      .post("/api/Region/SaveRegion", data)
      .then(({ data }) => {
        if (data.status && data.status == "Success") {
          toast.success(data.message);
          this.getRegions();
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
        window.$("#AddRegionModal").modal("hide");
      })
      .catch(function(error) {
        window.$("#AddRegionModal").modal("hide");
        $(".loading").hide();
        console.log(error);
      });
  };
  columns = () => {
    return [
      {
        text: "Region ID",
        dataField: "code",
        sort: true,
        formatExtraData: this.state.bulkProgress,
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.RegionCode)) {
            return (
              <>
                {cell}{" "}
                {AuthService.canView(PermissionFeature.TeamsTeams) &&
                row.teamId != null &&
                row.teamId != -1 ? (
                  <a
                    alt="show Teams"
                    onClick={(e) => {
                      this.preventDefaultCustom(e);
                    }}
                    href={"/permissions/teams/teamDetail/" + row.teamId}
                   
                  >
                    <span className="fas fa-sm fa-users"></span>
                  </a>
                ) : (
                  AuthService.canAdd(PermissionFeature.TeamsTeams) &&
                  !this.state.bulkProgress && (
                    <span
                      alt="Create Team"
                      onClick={(e) => {
                        this.createRegionTeam(e, row.id);
                      }}
                      className="text-primary fas fa-sm fa-plus-circle"
                    ></span>
                  )
                )}
              </>
            );
          } else return "";
        },
      },
      {
        text: "Region Name",
        dataField: "description",
        sort: true,
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.RegionName)) return cell;
          else return "";
        },
      },
      {
        text: "RVP Email",
        dataField: "rvpEmail",
        sort: true,
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
            return (
              <a
                target="_blank"
                href={"mailto:" + cell}
                onClick={this.preventDefaultCustom}
              >
                {cell}
              </a>
            );
          } else {
            return "";
          }
        },
      },
      {
        text: "RVP Phone",
        dataField: "rvpPhone",
        sort: true,
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
            return (
              <a
                target="_blank"
                href={"tel:" + cell}
                onClick={this.preventDefaultCustom}
              >
                {cell}
              </a>
            );
          } else {
            return "";
          }
        },
      },
    ];
  };
  getRegions() {
    $(".loading").show();

    authAxios.get("/api/Region").then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          rows: data.data,
          totalCount: data.totalCount,
        });

        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }
  async componentDidUpdate(prevProps) {
    if (this.props.refreshRF) {
      this.getRegions();
      this.props.setFalseRefreshRF();
    }
    if (this.props.bulkProgress != prevProps.bulkProgress) {
      await this.setState({ bulkProgress: this.props.bulkProgress });
      this.getRegions();
    }
  }
  componentWillMount() {
    this.setState({ bulkProgress: this.props.bulkProgress });
    this.getRegions();
  }
  render() {
    return (
      <AUX>
        <div className="w-100 mx-auto">
          <br />
          <div className="row mx-0 align-items-center">
            <div className="col-sm-6">
              <h4 className="page-title-search">Regions</h4>
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <BootstrapTable
                    hover
                    keyField="id"
                    data={this.state.rows}
                    columns={this.columns()}
                    search
                    // pagination={paginationFactory({ paginationTotalRenderer: TableCustomTotal })}
                    rowEvents={this.rowEvents}
                    id="regionsListing"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add region modal */}
        <div
          className="modal fade"
          id="AddRegionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AddRegionModalTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AddRegionModalTitle">
                  {" "}
                  Add Region{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="needs-validation">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {AuthService.canView(PermissionFeature.RegionCode) && (
                          <div className="col-6">
                            <div className="form-group">
                              <MDBInput
                                value={this.state.code}
                                name="code"
                                id="code"
                                onChange={this.handleChange}
                                label="Region Code"
                                group
                                type="text"
                                //disabled={this.canEdit("code")}
                                required
                              >
                                <div className="invalid-feedback">
                                  Code is required.
                                </div>
                              </MDBInput>
                            </div>
                          </div>
                        )}
                        {AuthService.canView(PermissionFeature.RegionName) && (
                          <div className="col-6">
                            <div className="form-group">
                              <MDBInput
                                value={this.state.description}
                                name="description"
                                id="description"
                                onChange={this.handleChange}
                                label="Name"
                                //disabled={this.canEdit("name")}
                                group
                                type="text"
                                required
                              >
                                <div className="invalid-feedback">
                                  Name is required.
                                </div>
                              </MDBInput>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <Button color="primary" onClick={this.handleSubmit}>
                    Save Changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Regions;
