import React from "react";

const TextField = ({ label, name, placeholder, value, ...rest }) => {
  return (
    <>
      <label htmlFor={name} className="font-weight-bold">
        {label}
      </label>
      <br />
      <input
        type="text"
        name={name}
        className="form-control"
        placeholder={placeholder}
        value={value}
        {...rest}
      />
    </>
  );
};

const TextFieldInpGrp = ({
  label,
  name,
  placeholder,
  value,
  inpGrpText,
  btnApplyDiscountOnClick,
  btnRemoveDiscountOnClick,
  editMode,
  disabled,
  ...rest
}) => {
  return (
    <>
      <label htmlFor={name} className="font-weight-bold small">
        {label}
      </label>
      <br />
      <div className="input-group">
        <input
          type="text"
          name={name}
          className="form-control grpInput"
          placeholder={placeholder}
          value={value}
          disabled={editMode}
          {...rest}
        />
        {editMode && (
          <span
            className="input-group-text c-pointer"
            id="basic-addon2"
            disabled={disabled}
            onClick={btnRemoveDiscountOnClick}
          >
            Remove
          </span>
        )}
        {!editMode && (
          <span
            className="input-group-text c-pointer"
            id="basic-addon2"
            disabled={disabled}
            onClick={btnApplyDiscountOnClick}
          >
            {inpGrpText}
          </span>
        )}
      </div>
    </>
  );
};
const Select = ({ label, name, placeholder, options }) => {
  return (
    <>
      <label htmlFor={name} className="font-weight-bold">
        {label}
      </label>
      <select name={name} placeholder={placeholder}>
        {options.map((optn) => (
          <option key={optn.id} value={optn.id}>
            {optn.option}
          </option>
        ))}
      </select>
    </>
  );
};

const CheckBoxes = ({ label, name, options, value }) => {
  return (
    <div>
      <label htmlFor={name} className="font-weight-bold">
        {label}
      </label>
      {options.map((opt) => (
        <div className="form-check">
          <input
            type="checkbox"
            id={opt.option}
            name={name}
            value={opt.id}
            className="form-check-input"
            checked={value && value.some((val) => val == opt.id)}
            disabled
          />
          <label className="form-check-label" htmlFor={opt.id}>
            {opt.option}
          </label>
        </div>
      ))}
    </div>
  );
};

const RadioButtons = ({ label, name, options, value }) => {
  return (
    <div>
      <label htmlFor={name} className="font-weight-bold">
        {label}
      </label>
      {options.map((opt) => (
        <div className="form-check">
          <input
            type="radio"
            id={opt.option}
            name={name}
            value={opt.id}
            className="form-check-input"
            checked={value == opt.id}
            disabled
          />
          <label className="form-check-label" htmlFor={opt.id}>
            {opt.option}
          </label>
        </div>
      ))}
    </div>
  );
};

const Switch = ({ label, name }) => {
  return (
    <>
      <br />
      <div class="custom-control custom-switch">
        <label htmlFor={name} className="custom-control-label font-weight-bold">
          {label}
        </label>

        <input
          type="checkbox"
          class="custom-control-input"
          disabled
          id="customSwitch2"
        />
      </div>
    </>
  );
};

export { TextField, Select, CheckBoxes, RadioButtons, Switch, TextFieldInpGrp };
