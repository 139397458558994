import React from "react";
import EmailConfigTabs from "../Enums/EmailConfigTabs";
import FilterEmailConfigTabs from "../Enums/FilterEmailConfigTabs";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function MG_DeleteAllAuthSenders(props) {
  const {
    modal,
    toggle,
    confirm,
    currentEmailConfigTab,
    currentFilterConfigTab,
    onCancelClick,
  } = props;
  let message = "";
  if (
    currentFilterConfigTab == FilterEmailConfigTabs.EmailList ||
    currentEmailConfigTab == EmailConfigTabs.Settings
  ) {
    message =
      " Are you sure you want to remove all Authorized Senders from this list?";
  } else if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
    message =
      " Are you sure you want to remove all Subscribers in Additional Members from this list?";
  }
  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Confirm Remove</ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          <h5>{message}</h5>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary w-md waves-effect waves-light"
          onClick={() => {
            confirm().confirmed();
          }}
        >
          Confirm
        </Button>{" "}
        <Button color="secondary" onClick={onCancelClick}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
