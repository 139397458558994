import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import MembersModal from "../../Shared/MembersModal";
import TeamsModal from "../../Shared/TeamsModal";
import PositionsModal from "../../Shared/PositionsModal";

class AddMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            id: this.props.id,
            MemberId: 0,
            MemberName: "",
            TeamId: this.props.id,
            PositionId: 0,
            PositionName: "",
            AssignmentTypeId: 1,
            IsTeam: this.props.IsTeam
        };
        this.membersMethods = () => {
            this.setMember = args => {
                this.setState({ MemberId: args.id, MemberName: args.firstName + " " + args.lastName });
                $(".cancelModal").trigger("click");
            };

            return this;
        };
        this.teamsMethods = () => {
            this.setTeam = args => {
                this.setState({ MemberId: args.id, MemberName: args.name });
                $(".cancelModal").trigger("click");
            };

            return this;
        };
        this.positionMethods = () => {
            this.setPostion = args => {
                this.setState({ PositionId: args.id, PositionName: args.name });
                $(".cancelModal").trigger("click");
            };
            return this;
        };
    }
    setAssType = e => {
        this.setState({
            AssignmentTypeId: e.target.value
        });
    };
    handleChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleSubmit = evt => {
        evt.preventDefault();
        evt.target.className += " was-validated";
        if (evt.target.checkValidity() === false) {
            return false;
        }
        $(".loading").show();
        const data = {
            MemberId: this.state.MemberId,
            TeamId: this.state.id,
            PositionId: this.state.PositionId,
            AssignmentTypeId: this.state.AssignmentTypeId,
            IsTeam: this.state.IsTeam
        };
        axios
            .post("/api/TeamMember/AddTeamMemberDetails", data)
            .then(({ data }) => {
                if (data.status && data.status == "Success") {
                    toast.success("Team Member Saved.");
                    this.props.history.push({ pathname: "/teams/edit-team", type: "edit", uid: this.state.id });
                    $(".loading").hide();
                } else {
                    $(".loading").hide();
                    toast.error(data.message);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    componentDidMount() {
        $(".loading").show();
        if (!this.state.type) {
            //  this.props.history.push("/admin/teams");
        }
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                        <div className="page-title-box">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h4 className="page-title"> Add/Edit Member</h4>
                                </div>
                                <div className="col-sm-6 float-right text-right">
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            this.props.history.push({ pathname: "/teams/edit-team", type: "edit", uid: this.state.id })
                                        }
                                        className="primary">
                                        Back to Team
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            {this.state.IsTeam && (
                                                <div className="col-4">
                                                    <label>Select Team</label>
                                                    <div class="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            class="form-control disabled"
                                                            required
                                                            onKeyPress={e => {
                                                                e.preventDefault();
                                                                e.target.value = "";
                                                            }}
                                                            value={this.state.MemberName}
                                                        />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary"
                                                                type="button"
                                                                data-toggle="modal"
                                                                data-target=".teams-modal">
                                                                <i class="fa fa-search" />
                                                            </button>
                                                        </div>
                                                        <div className="invalid-feedback">Team is required.</div>
                                                    </div>
                                                </div>
                                            )}
                                            {!this.state.IsTeam && (
                                                <div className="col-4">
                                                    <label>Select Member</label>
                                                    <div class="input-group mb-3">
                                                        <input
                                                            type="text"
                                                            class="form-control disabled"
                                                            required
                                                            onKeyPress={e => {
                                                                e.preventDefault();
                                                                e.target.value = "";
                                                            }}
                                                            value={this.state.MemberName}
                                                        />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary"
                                                                type="button"
                                                                data-toggle="modal"
                                                                data-target=".members-modal">
                                                                <i class="fa fa-search" />
                                                            </button>
                                                        </div>
                                                        <div className="invalid-feedback">Member is required.</div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-4">
                                                <label>Select Position</label>
                                                <div class="input-group mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control disabled"
                                                        required
                                                        onKeyPress={e => {
                                                            e.preventDefault();
                                                            e.target.value = "";
                                                        }}
                                                        value={this.state.PositionName}
                                                    />
                                                    <div class="input-group-append">
                                                        <button
                                                            class="btn btn-outline-secondary"
                                                            type="button"
                                                            data-toggle="modal"
                                                            data-target=".position-modal">
                                                            <i class="fa fa-search" />
                                                        </button>
                                                    </div>
                                                    <div className="invalid-feedback">Position is required.</div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <label>Assignment Type: </label>
                                                <div className="form-group">
                                                    <select
                                                        className="browser-default custom-select"
                                                        id="assType"
                                                        onChange={this.setAssType}>
                                                        <option value="1">Appointed</option>
                                                        <option value="2">Elected</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col float-right text-right">
                                <Button
                                    type="button"
                                    onClick={() =>
                                        this.props.history.push({ pathname: "/teams/edit-team", type: "edit", uid: this.state.id })
                                    }
                                    className="mr-2"
                                    color="danger">
                                    Cancel
                                </Button>
                                <Button to="/roles" color="primary" type="submit">
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
                <MembersModal membersMethods={this.membersMethods} />
                <TeamsModal teamsMethods={this.teamsMethods} />
                <PositionsModal positionMethods={this.positionMethods} />
            </AUX>
        );
    }
}

export default AddMember;
