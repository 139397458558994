import React, { useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import AuthService from "../../../Auth/AuthService";
import TableCustomTotal from "../../../Shared/TableCustomTotal";
import paginationFactory from "react-bootstrap-table2-paginator";
import FilterTablesType from "../../../Email/Enums/FilterTablesType";
import { Spinner } from "reactstrap";
import FiltersService from "./FiltersService";
import { toast } from "react-toastify";
import $ from "jquery";

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;

const getExportFileName = (tblType, filetype) => {
  //   if (tblType === FERequestsType.MyRequests) {
  //     return `${Date.now()}_MyRequestsList.${filetype}`;
  //   }
  //   if (tblType > FERequestsType.MyRequests) {
  //     return `${Date.now()}_ReviewRequests.${filetype}`;
  //   }
};
const showFilterType = (cell, row) => {
  if (cell == 1) {
    return "Saved Filter";
  } else if (cell == 2) {
    return "Bucket Filter";
  } else {
    // In case a newly Assigned Manager View his MyFilters listing
    let { real_search_type_id = 0 } = row;
    if (real_search_type_id == 1) {
      return "Saved Filter";
    } else {
      return "Bucket Filter";
    }
  }
};
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  const placeHolder =
    props.tableType == FilterTablesType.MyFilters
      ? "Search Filters by Name"
      : "Search Filters by Name/Owner..";
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="FiltersKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder={placeHolder}
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};
const showSpinner = () => {
  $(".loading").show();
};

const hideSpinner = () => {
  $(".loading").hide();
};

// ************************Start of the Component************************//
const FiltersTable = (props) => {
  const {
    listData,
    isloading,
    viewFilter,
    deleteFilter,
    tableType,
    reloadFiltersTable,
  } = props;
  const togglePin = async (searchId, searchTypeId) => {
    const memberId = AuthService.getProfile().memberData.id;
    showSpinner();
    const data = await FiltersService.togglePin(
      searchId,
      searchTypeId,
      memberId
    );
    if (data.status) {
      if (data.status.toLowerCase() === "success") {
        toast.success(data.message);
        reloadFiltersTable();
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Search cannot be updated.");
    }
    hideSpinner();
  };
  const renderPinnedIcon = (item) => {
    if (item.is_pinned === 1) {
      return (
        <i
          className="fas fa-star cursorPointer fa-md pl-1 pt-2"
          title={`Unpin ${item.search_name}.`}
          onClick={() => {
            togglePin(item.id, item.search_type_id);
            $("body").trigger("click");
          }}
        ></i>
      );
    } else {
      return (
        <i
          className="far fa-star cursorPointer fa-md pl-1 pt-2"
          title={`Pin ${item.search_name}.`}
          onClick={() => {
            togglePin(item.id, item.search_type_id);
          }}
        ></i>
      );
    }
  };
  const Actions = (cell, row) => {
    return (
      <div>
        <div className="actionIcons">
          <i
            className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
            title="Edit Filter"
            onClick={() => viewFilter(row)}
          ></i>

          <i
            className="fas fa-trash fa-md text-danger pl-1 pt-2 cursorPointer"
            title="Delete Filter"
            onClick={() => deleteFilter(row)}
          ></i>
          {renderPinnedIcon(row)}
        </div>
      </div>
    );
  };
  const columns = [
    {
      text: "Name",
      dataField: "search_name",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "Type",
      dataField: "search_type_id",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
      formatter: (cell, row) => {
        return showFilterType(cell, row);
      },
      searchable: false,
    },
    {
      text: "Owner",
      dataField: "owner",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
      hidden: tableType == FilterTablesType.MyFilters,
    },
    {
      text: "",
      dataField: "actions",
      isDummyField: true,

      headerClasses: "text-center text-nowrap text-white px-sm-1 py-2",
      headerAttrs: {
        width: "85px",
      },
      formatter: (cell, row) => {
        return Actions(cell, row);
      },
      csvExport: false,
    },
  ];
  const [sizePerPage, setsizePerPage] = useState(10);

  const sizePerPageChange = (sizePerPage) => {
    setsizePerPage(sizePerPage);
  };
  const RemotePagination = () => {
    let paginationOptions = {
      totalSize: listData && listData.length,
      paginationTotalRenderer: TableCustomTotal,
      showTotal: true,
      onSizePerPageChange: sizePerPageChange,
      sizePerPage,
    };
    return (
      <ToolkitProvider keyField="id" data={listData} columns={columns} search>
        {(props) => (
          <div>
            <MySearch {...props.searchProps} tableType={tableType} />
            <BootstrapTable
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(40, 45, 42, 0.19)",
                  }),
                },
              })}
              noDataIndication={() => {
                return isloading === false ? (
                  <NoDataIndication />
                ) : (
                  <div className="d-flex justify-content-center p-4">
                    <Spinner />
                  </div>
                );
              }}
              loading={isloading}
              ///rowEvents={rowEvents}
              ///rowClasses="c-pointer"
              // pagination={
              //   listData.length > sizePerPage
              //     ? paginationFactory({ ...paginationOptions })
              //     : null
              // }
              pagination={paginationFactory({ ...paginationOptions })}
              {...props.baseProps}
            />
            <br />
          </div>
        )}
      </ToolkitProvider>
    );
  };
  return RemotePagination();
};

FiltersTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

FiltersTable.defaultProps = {
  listData: [],
};

export default FiltersTable;
