import actions from "./action";
import React, { Component } from "react";

const initialState = {
  notificationList: [],
  // notificationList: [
  //   {
  //     id: 1,
  //     message: "Your F&E Request has been submitted succesfully.",
  //     detailPage: "/events",
  //     receivedTime: "12h ago",
  //   },
  //   {
  //     id: 2,
  //     message: (
  //       <p>
  //         <strong>Rich Santa </strong> changed the F&E
  //         <span className="noti_Request_link"> Request's </span>status to
  //         <strong> Approved</strong>.
  //       </p>
  //     ),
  //     detailPage: `${process.env.REACT_APP_API_BASE_URL}/FETracker/12`,
  //     receivedTime: "12h ago",
  //   },
  //   {
  //     id: 3,
  //     message: (
  //       <p>
  //         <strong>Json Doss</strong> has submitted a F&E Request with your
  //         <strong> BudgetLine</strong>.
  //       </p>
  //     ),
  //     detailPage: "/events",
  //     receivedTime: "12h ago",
  //   },
  //   {
  //     id: 4,
  //     message: (
  //       <p>
  //         <strong>Zakir M</strong> has submitted a <strong>F&E Request</strong>.
  //       </p>
  //     ),
  //     detailPage: "/events",
  //     receivedTime: "12h ago",
  //   },
  //   {
  //     id: 5,
  //     message: (
  //       <p>
  //         <strong>Ehsaan M</strong> has entered a new Equipment with status
  //         In-Accounting Review.
  //       </p>
  //     ),
  //     detailPage: "/events",
  //     receivedTime: "12h ago",
  //   },
  //   {
  //     id: 6,
  //     message: (
  //       <p>
  //         amet S had shared a <span style={{ color: "#7ac2fa" }}>feedback</span>{" "}
  //         with you.
  //       </p>
  //     ),
  //     detailPage: "/events",
  //     receivedTime: "12h ago",
  //   },
  //   {
  //     id: 7,
  //     message: (
  //       <p>
  //         dolor sit had shared a{" "}
  //         <span style={{ color: "#7ac2fa" }}>feedback</span> with you.
  //       </p>
  //     ),
  //     detailPage: "/events",
  //     receivedTime: "12h ago",
  //   },
  //   {
  //     id: 8,
  //     message: (
  //       <p>
  //         Lorem ipsum S had shared a{" "}
  //         <span style={{ color: "#7ac2fa" }}>feedback</span> with you.
  //       </p>
  //     ),
  //     detailPage: "/events",
  //     receivedTime: "12h ago",
  //   },
  // ],
};

export default function userNotification(state = initialState, action) {
  switch (action.type) {
    case actions.SET_NOTIFICATIONS:
      return {
        ...state,
        notificationList: action.payload,
      };
    case actions.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notificationList: [
          action.payload.notification,
          ...state.notificationList,
        ],
      };

    default:
      return state;
  }
}
