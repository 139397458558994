import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import { DiscountTypes } from "../../Helpers/LookUpValues";

const DiscountActions = (cell, row, deleteDiscount, editDiscount) => {
  return (
    <div>
      <div className="actionIcons">
        <>
          <i
            className="fa fa-pencil-square-o c-pointer"
            title="Edit Discount Code"
            aria-hidden="true"
            onClick={
              () => editDiscount(row.id) 
            } 
          ></i>
          <i
            className="text-danger fas fa-trash c-pointer pl-1 pr-1"
            title="Delete"
            onClick={() => deleteDiscount(row.id)}
          ></i>
        </>
      </div>
    </div>
  );
};

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
const columns = (deleteDiscount, editDiscount) => [
  {
    text: "Code",
    dataField: "code",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "Type",
    dataField: "type",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
    formatter: (cell, row) => {
      return DiscountTypes[cell] || cell;
    },
  },
  {
    text: "Amount",
    dataField: "amount",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "",
    dataField: "actions",
    isDummyField: true,
    headerClasses: "text-center text-nowrap bg-dark text-white px-sm-1 py-2",
    headerAttrs: {
      width: "80px",
    },
    formatter: (cell, row) => {
      return DiscountActions(cell, row, deleteDiscount, editDiscount);
    },
    csvExport: false,
  },
];

const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="DiscountsKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder="Discount code Search.."
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};

const RemotePagination = (data, deleteDiscount, editDiscount, isloading) => (
  <ToolkitProvider
    keyField="id"
    data={data}
    columns={columns(deleteDiscount, editDiscount)}
    search
  >
    {(props) => (
      <div>
        <MySearch {...props.searchProps} />
        <BootstrapTable
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: (base) => ({
                ...base,
                background: "rgba(40, 45, 42, 0.19)",
              }),
            },
          })}
          noDataIndication={() => {
            return isloading === false ? <NoDataIndication /> : <Empty />;
          }}
          loading={isloading}
          {...props.baseProps}
        />

        <br />
      </div>
    )}
  </ToolkitProvider>
);

const DiscountsListTable = (props) => {
  const { listData, deleteDiscount, editDiscount, isloading } = props;
  return <>{RemotePagination(listData, deleteDiscount, editDiscount, isloading)}</>;
};

DiscountsListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

DiscountsListTable.defaultProps = {
  listData: [],
};

export default DiscountsListTable;
