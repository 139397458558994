import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "../../Shared/auth-header";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import $ from "jquery";
import ConfirmDeleteModal from "../../Shared/Modals";
import AuthService from "../../Auth/AuthService";
import _ from "lodash";
import PermissionFeature from "../PermissionFeature";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MDBInput } from "mdbreact";
import EditPosition from "./EditPosition";
import PermissionHeader from "../General/PermissionHeader";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import AssignMemberModal from "../General/AssignMemberModal";
const { SearchBar } = Search;

class TeamPositionMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPageMembers: 25,
      data: [],
      totalCount: "",
      members: [],
      member: null,
      columnWidth: 0,
      columnSecWidth: 0,
      columnSortWidth: 0,
      delId: 0,
    };
    this.membersSizePerPageChange = this.membersSizePerPageChange.bind(this);
    this.sizePerPageRenderer = this.sizePerPageRenderer.bind(this);
    this.columns = this.columns.bind(this);
  }
  sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
    <span className="dropdown">
      <button
        className="btn btn-secondary rounded-pill dropdown-toggle"
        //href="#"
        role="button"
        id="pageDropDown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {currSizePerPage}
      </button>

      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        {options.map((option) => (
          <a
            key={option.text}
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onSizePerPageChange(option.page);
            }}
            className="dropdown-item"
          >
            {option.text}
          </a>
        ))}
      </div>
    </span>
  );
  columns = () => {
    return [
      {
        headerAttrs: {
          hidden: true,
        },
        classes: "border-left-0 border-right-0",
        formatExtraData:
          this.state.columnWidth + "|" + this.state.columnSecWidth,
        formatter: (cellContent, row) => {
          return (
            <div className="">
              <div
                className="align-middle d-inline-block pr-2 pl-1"
                style={{ width: this.state.columnSecWidth + 2 + "px" }}
              >
                <Link
                  to={{
                    pathname: "/directory/userAction/" + row.memberID,
                  }}
                >
                  {row.memberName}
                </Link>
              </div>
              <div
                className="d-inline-block align-middle pl-2 pr-0 text-center border-left"
                style={{ width: this.state.columnWidth + 1 + "px" }}
              >
                {AuthService.canUpdate(PermissionFeature.TeamsPositions) && (
                  <i
                    onClick={() => {
                      this.setState({ member: row });
                      this.props.setAssignPositionMember(
                        this.props.position,
                        row,
                        true
                      );
                    }}
                    className="fas mr-1 c-pointer fa-pencil-alt"
                  ></i>
                )}
                {AuthService.canDelete(PermissionFeature.TeamsPositions) && (
                  <i
                    onClick={() => {
                      this.props.deleteMember(this.props.position, row);
                    }}
                    className="mr-1 c-pointer text-danger mx-1 fas fa-user-times"
                  ></i>
                )}
              </div>
            </div>
          );
        },
      },
    ];
  };

  componentDidUpdate(prevProps) {
    if (
      !_.isEmpty(this.props.position) &&
      JSON.stringify(this.props.position.members) !=
        JSON.stringify(this.state.members)
    ) {
      this.setState({
        members: this.props.position.members,
      });
    }
    if (
      this.state.columnSecWidth != this.props.columnSecWidth ||
      this.state.columnWidth != this.props.columnWidth
    ) {
      this.setState({
        columnWidth: this.props.columnWidth,
        columnSecWidth: this.props.columnSecWidth,
      });
    }
  }
  membersSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageMembers: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    //totalSize,
    rowEvents
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
      sizePerPage,
      custom: true,
      sizePerPageRenderer: this.sizePerPageRenderer,
    };
    if (data.length > sizePerPage) {
      return (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                keyField={keyFields}
                data={data}
                columns={columns}
                classes="border-0"
                {...paginationTableProps}
              />
              <div className="row mx-0 react-bootstrap-table-pagination members-pagination">
                <div className="col-6 pr-0 pl-2">
                  <PaginationTotalStandalone {...paginationProps} />
                  <SizePerPageDropdownStandalone {...paginationProps} />
                </div>
                <div className="col-6 pl-0 pr-2">
                  <PaginationListStandalone {...paginationProps} />
                </div>
              </div>
            </div>
          )}
        </PaginationProvider>
      );
    } else {
      return (
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          classes="border-0 mb-0"
          pagination={false}
        />
      );
    }
  }

  componentDidMount() {
    this.setState({
      members: this.props.position.members,
    });
  }

  render() {
    const { data, sizePerPage, page, totalCount } = this.state;
    const showTotal = true;
    return (
      <div className="position-relative w-100">
        <div className="mx-0 d-flex">
          <div
            className="d-inline-block"
            style={{
              minWidth:
                this.props.columnFirWidth +
                (this.props.columnSortWidth
                  ? this.props.columnSortWidth + 1
                  : 0) +
                "px",
            }}
          ></div>
          <div className="d-inline-block border-left">
            {this.RemotePagination(
              this.columns(),
              "id",
              this.state.members,
              this.membersSizePerPageChange,
              this.state.sizePerPageMembers
            )}
            <ConfirmDeleteModal confirm={this.confirm} />
          </div>
        </div>
      </div>
    );
  }
}

export default TeamPositionMembers;
