import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class OccupationSeries extends Component {
  onChange(item) {
    var occupationSeriesIndex = _.findIndex(
      this.props.filterState.filterData.occupationSeries,
      (o) => {
        return o.occupation === item.occupation;
      }
    );
    item.checked = !this.props.filterState.filterData.occupationSeries[
      occupationSeriesIndex
    ].checked;
    this.props.filterState.filterData.occupationSeries[
      occupationSeriesIndex
    ] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item, index) {
    return (
      <li class="chechbox-list_item pt-1" key={"gliitem-" + index}>
        <input
          type="checkbox"
          htmlFor={"gitem-" + index}
          value={index}
          checked={item.checked}
          className="custom-control-input"
          id={"gitem-" + index}
          name={"gitem-" + index}
          onChange={() => this.onChange(item)}
        />
        <span className="card-text custom-checkbox" key={"gitem-" + index}>
          <label
            className="custom-control-label reduceLetterSpace"
            style={{
              color: this.props.isMobile ? "white" : null,
            }}
            htmlFor={"gitem-" + index}
          >
            {item.occupation === item.title
              ? item.occupation
              : item.occupation + "-" + item.title}

            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Occupation Series</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div class="filter-fields-scroll">
          <ul class="chechbox-list">
            {this.props.filterState.filterData.occupationSeries &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.occupationSeries.map(
                (item, index) => this.chechboxes(item, index)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(OccupationSeries);
