import actions from "./action";

const initialState = {
  permissionActiveTab: 1,
  openDefaultPermissionModal: false,
  showDefaultPermissionModal: false,
  openSpecialTeamPermissionModal: false,
  loadDataDefaultPermission: false,
  loadDataOwnPermission: false,
  openAssignMemberModal: false,
  openPositionMembersModal: false,
  openAssignMultiMemberModal: false,
  openEditPositionModal: false,
  EditPositionNew: false,
  openPositionPSModal: false,
  userOwnRecordData: [],
  defaultPermissionData: [],
};

export default function permission(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PERMISSION_ACTIVE_TAB:
      return {
        ...state,
        permissionActiveTab: action.payload,
      };
    case actions.SET_DEFAULT_PERMISSION_DATA:
      return {
        ...state,
        defaultPermissionData: action.payload,
      };
    case actions.SET_USER_OWN_RECORD_DATA:
      return {
        ...state,
        userOwnRecordData: action.payload,
      };

    case actions.OPEN_SPECIAL_TEAM_PERMISSION_MODAL:
      return {
        ...state,
        openSpecialTeamPermissionModal: true,
      };
    case actions.CLOSE_SPECIAL_TEAM_PERMISSION_MODAL:
      return {
        ...state,
        openSpecialTeamPermissionModal: false,
      };
    case actions.SET_PERMISSION_ACTIVE_TAB:
      return {
        ...state,
        permissionActiveTab: action.payload,
      };
    case actions.OPEN_ASSIGN_MEMBER_MODAL:
      return {
        ...state,
        openAssignMemberModal: true,
      };
    case actions.CLOSE_ASSIGN_MEMBER_MODAL:
      return {
        ...state,
        openAssignMemberModal: false,
      };
    case actions.OPEN_ASSIGN_MULTI_MEMBER_MODAL:
      return {
        ...state,
        openAssignMultiMemberModal: true,
      };
    case actions.CLOSE_ASSIGN_MULTI_MEMBER_MODAL:
      return {
        ...state,
        openAssignMultiMemberModal: false,
      };
    case actions.OPEN_POSITION_MEMBERS_MODAL:
      return {
        ...state,
        openPositionMembersModal: true,
      };
    case actions.CLOSE_POSITION_MEMBERS_MODAL:
      return {
        ...state,
        openPositionMembersModal: false,
      };
    case actions.OPEN_EDIT_POSITION_MODAL:
      return {
        ...state,
        openEditPositionModal: true,
        editPositionNew: action.payload,
      };
    case actions.CLOSE_EDIT_POSITION_MODAL:
      return {
        ...state,
        openEditPositionModal: false,
      };
    case actions.OPEN_POSITION_PERMISSION_SCOPE_MODAL:
      return {
        ...state,
        openPositionPSModal: true,
      };
    case actions.CLOSE_POSITION_PERMISSION_SCOPE_MODAL:
      return {
        ...state,
        openPositionPSModal: false,
      };
    case actions.OPEN_DEFAULT_PERMISSION_MODAL:
      return {
        ...state,
        openDefaultPermissionModal: true,
        loadDataOwnPermission: true,

        loadDataDefaultPermission: true,
      };
    case actions.CLOSE_DEFAULT_PERMISSION_MODAL:
      return {
        ...state,
        openDefaultPermissionModal: false,
      };
    case actions.COMPLETE_LOAD_DATA_DEFAULT_PERMISSION:
      return {
        ...state,
        loadDataDefaultPermission: false,
      };
    case actions.COMPLETE_LOAD_DATA_OWN_PERMISSION:
      return {
        ...state,
        loadDataOwnPermission: false,
      };
    case actions.SHOW_DEFAULT_PERMISSION_MODAL:
      return {
        ...state,
        showDefaultPermissionModal: true,
      };
    case actions.HIDE_DEFAULT_PERMISSION_MODAL:
      return {
        ...state,
        showDefaultPermissionModal: false,
      };
    default:
      return state;
  }
}
