import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import $ from "jquery";
import _ from "lodash";
import Scope from "../Scope/Scope";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import Services from "../PermissionService";
import PermissionsST from "./PermissionsST";
import PermissionAlgo from "../General/PermissionAlgo";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import AuthService from "../../Auth/AuthService";
const modalHeadings = {
  Orignal: "SPECIAL TEAM POSITION TYPE PERMISSIONS",
  Permissions: "CONFIGURE PERMISSIONS",
};
class SpecialTeamPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teamName: "",
      modalheading: "",
      openModal: false,
      type: this.props.type,
      parentTeamId: this.props.parentTeamId,
      permissionTeamId: this.props.permissionTeamId,
      applyChild: false,
      step: 1,
      teams: [],
      positionTypes: [],
      userPermissions: [],
      submissionObject: {
        isNational: false,
        positionTypeIds: [],
        teams: [],
        applyChild: false,
        permissions: [],
      },
    };
    this.getTeamsAndPositionTypes = this.getTeamsAndPositionTypes.bind(this);
    //   this.cloneTeamPermission = this.cloneTeamPermission.bind(this);
    this.buttonLoader = this.buttonLoader.bind(this);

    this.teamPermissions = this.teamPermissions.bind(this);
    this.verifyData = this.verifyData.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.cancelButtonBottom = this.cancelButtonBottom.bind(this);
    this.cancelButtonLoader = this.cancelButtonLoader.bind(this);
    this.savePermissionData = this.savePermissionData.bind(this);
    this.backwardSection = this.backwardSection.bind(this);
    this.verifyPositionTypeTeam = this.verifyPositionTypeTeam.bind(this);
    this.verifyData = this.verifyData.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.validatePositionTypeTeam = this.validatePositionTypeTeam.bind(this);

    this.handleChangeTeamPermission = this.handleChangeTeamPermission.bind(
      this
    );

    this.handleConfigurePermissionSection = this.handleConfigurePermissionSection.bind(
      this
    );
    this.teamsPositionType = this.teamsPositionType.bind(this);
    this.sectionLoader = this.sectionLoader.bind(this);
    this.setHeading = this.setHeading.bind(this);
    this.createSubmissionTeamObject = this.createSubmissionTeamObject.bind(
      this
    );
    this.filterAllCheckedTeam = this.filterAllCheckedTeam.bind(this);
    this.handleChangeTeams = this.handleChangeTeams.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleChangeApplyChild = this.handleChangeApplyChild.bind(this);
  }
  createSubmissionTeamObject(teamData) {
    let filterTeams = this.filterAllCheckedTeam(teamData);
    let teamsArray = [];
    filterTeams.map((item) => {
      if (item.isChecked) {
        teamsArray.push({ RegionId: item.id, FacilityId: null });
      } else {
        item.facility.map((itemf) => {
          if (itemf.isChecked) {
            teamsArray.push({
              RegionId: itemf.regionId,
              FacilityId: itemf.id,
            });
          }
        });
      }
    });
    return teamsArray;
  }
  handleConfigurePermissionSection() {
    this.setState({
      userPermissions: JSON.parse(
        localStorage.getItem("memberpermissionswithscope")
      ),
    });
  }
  filterAllCheckedTeam(teamData) {
    var result = _.filter(_.cloneDeep(teamData), {
      facility: [{ isChecked: true }],
    });
    result = _.map(result, (item) => {
      item.facility = item.facility.filter((x) => x.isChecked === true);
      return item;
    });
    return result;
  }
  // Function to handle checkbox change
  handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const id = parseInt(value);
    this.setState((prevState) => {
      if (checked) {
        return {
          submissionObject: {
            ...prevState.submissionObject,
            positionTypeIds: [
              ...prevState.submissionObject.positionTypeIds,
              id,
            ],
          },
        };
      } else {
        return {
          submissionObject: {
            ...prevState.submissionObject,
            positionTypeIds: prevState.submissionObject.positionTypeIds.filter(
              (itemId) => itemId !== id
            ),
          },
        };
      }
    });
  };
  setHeading = () => {
    switch (this.state.step) {
      case 1:
      default:
        if (this.state.modalheading != modalHeadings.Orignal) {
          this.setState({ modalheading: modalHeadings.Orignal });
        }

        break;
      case 2:
        if (this.state.modalheading != modalHeadings.Permissions) {
          this.setState({ modalheading: modalHeadings.Permissions });
        }
        break;
    }
  };
  teamsPositionType = () => {
    let { positionTypes } = this.state;
    let { positionTypeIds } = this.state.submissionObject;
    return (
      <Fragment>
        {!_.isEmpty(positionTypes) && (
          <>
            <h4>Position Types:</h4>
            {positionTypes.map((item) => (
              <div key={item.id} className={"w-100 pl-3 custom-checkbox"}>
                <input
                  id={"positionType" + item.id}
                  name={"positionType" + item.id}
                  checked={positionTypeIds.includes(item.id)}
                  onChange={this.handleCheckboxChange}
                  type="checkbox"
                  value={item.id}
                  className="custom-control-input pr-2"
                />

                <label
                  className="custom-control-label"
                  for={"positionType" + item.id}
                >
                  {item.positiontype}
                </label>
              </div>
            ))}
          </>
        )}
        {!_.isEmpty(this.state.teams) && (
          <>
            <h4>Teams:</h4>
            <Scope
              scopes={this.state.teams}
              national={this.state.submissionObject.isNational}
              handleChangeScope={this.handleChangeTeam}
            />
          </>
        )}
      </Fragment>
    );
  };
  handleChangeTeams(e, submissionObject, Data) {
    let obj = e.target.dataset.obj;
    const checkAll = (teamData, value) => {
      return teamData.map((item) => {
        item.isChecked = value;
        if (Array.isArray(item.facility)) {
          checkAll(item.facility, value);
        }
        return item;
      });
    };

    let index = -1;
    switch (obj) {
      case "National":
        submissionObject.isNational = e.target.checked;
        submissionObject.teams = e.target.checked && [];
        if (Data) {
          Data = checkAll(Data, e.target.checked);
        }
        break;

      case "Region":
        index = Data.findIndex((el) => el.id == e.target.dataset.id);
        Data[index].isChecked = e.target.checked;
        submissionObject.isNational = false;
        if (Array.isArray(Data[index].facility)) {
          Data[index].facility = checkAll(
            Data[index].facility,
            e.target.checked
          );
        }
        submissionObject.teams = [];
        submissionObject.teams = this.createSubmissionTeamObject(Data);

        break;

      case "Facility":
        submissionObject.isNational = false;
        let regionIndex = Data.findIndex((el) => el.id == e.target.dataset.rid);
        let f = Data[regionIndex].facility;
        index = Data[regionIndex].facility.findIndex(
          (el) => el.id == e.target.dataset.id
        );
        Data[regionIndex].isChecked = !e.target.checked && false;
        Data[regionIndex].facility[index].isChecked = e.target.checked;

        submissionObject.teams = [];
        submissionObject.teams = this.createSubmissionTeamObject(Data);
        break;

      default:
    }
    return { gameChanger: submissionObject, Data: Data };
  }
  handleChangeTeam(e) {
    let Result = this.handleChangeTeams(
      e,
      this.state.submissionObject,
      this.state.teams
    );
    this.state.teamData = Result.Data;
    this.state.submissionObject = Result.gameChanger;
    this.setState({
      submissionObject: this.state.submissionObject,
      teams: this.state.teams,
    }); //************************
  }
  sectionLoader = () => {
    this.setHeading();
    switch (this.state.step) {
      case 1:
      default:
        return this.teamsPositionType();
        break;
      case 2:
        return this.teamPermissions();
        break;
    }
  };
  async getTeamsAndPositionTypes() {
    $(".loading").show();
    let data = await Services.TeamPositionTypes();

    if (data != null) {
      await this.setState({ positionTypes: data.result });
    }
    data = await Services.TeamScopes(null);
    !_.isEmpty(data) && (await this.setState({ teams: data.result }));

    $(".loading").hide();
  }

  handleChangeTeamPermission(permissions) {
    this.state.submissionObject.permissions = _.cloneDeep(permissions).filter(
      (item) =>
        item.isView > -1 ||
        item.isAdd > -1 ||
        item.isUpdate > -1 ||
        item.isDelete > -1
    );
    this.setState({
      submissionObject: this.state.submissionObject,
    });
  }
  handleChangeApplyChild(applyChild) {
    this.state.submissionObject.applyChild = applyChild;
    this.setState({
      submissionObject: this.state.submissionObject,
    });
  }

  performSlide(parentid) {
    let element = $(".slide-collapsers[data-slide-Id='slide" + parentid + "']");
    let expand = element.attr("data-slide-expand");

    const collapseChild = (parentid, expand) => {
      let element = $(".slide-collapsers[data-slide-Id='" + parentid + "']");

      if (expand) {
        $(".slide-collapsers[data-slide-parentId='" + parentid + "']").addClass(
          "perm-row-hide"
        );
      } else {
        let expandChild = element.attr("data-slide-expand");

        if (expandChild == "true") {
          $(
            ".slide-collapsers[data-slide-parentId='" + parentid + "']"
          ).removeClass("perm-row-hide");
        }
        //in case false
        else {
          expand = true;
        }
      }
      $(".slide-collapsers[data-slide-parentId='" + parentid + "']").each(
        function(i) {
          let parentid2 = $(this).attr("data-slide-Id");
          if (
            $(".slide-collapsers[data-slide-parentId='" + parentid2 + "']")
              .length > 0
          ) {
            collapseChild(parentid2, expand);
          }
        }
      );
    };

    if (expand == "true") {
      $(
        ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
      ).addClass("perm-row-hide");
      element.attr("data-slide-expand", false);
    } else {
      $(
        ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
      ).removeClass("perm-row-hide");
      element.attr("data-slide-expand", true);
    }
    collapseChild("slide" + parentid, expand == "true" ? true : false);
  }

  handleSaveTeamPermission = async (e) => {
    $(".loading").show();
    if (this.validateTeamPermissions()) {
      let data = await Services.EditSpecialTeamPermission(
        this.state.submissionObject
      );
      if (!_.isEmpty(data)) {
        if (data.statusCode == 200) {
          toast.success(data.message);
          //this.props.BulkProcessStart();
          this.cancelModal();
        }
      }
    }

    $(".loading").hide();
  };
  verifyPositionTypeTeam = (e) => {
    /*Verify Team Detail Code*/
    /*End Of Verify Team Detail Code*/
    if (this.validatePositionTypeTeam()) {
      // this.state.submissionObject.Permissions = [];
      this.setState({
        step: 2,
        /* teamPermissions: [],*/ submissionObject: this.state.submissionObject,
      });
    }
  };
  verifyData = (e) => {
    e.preventDefault();
    switch (this.state.step) {
      case 1:
        this.verifyPositionTypeTeam();
        break;
      case 2:
        this.verifyPermissions();
        break;
      default:
        this.setState({ step: 1 });
        break;
    }
  };
  backwardSection = (e) => {
    e.preventDefault();
    switch (this.state.step) {
      case 2:
      default:
        //   this.handleResetTeamPermission();
        this.setState({ step: 1 });
        break;
    }
  };
  backButton = () => {
    return (
      this.state.step > 1 && (
        <button
          onClick={this.backwardSection}
          className="btn btn-primary btn-sm btn-x-sm w-md waves-effect waves-light"
          type="submit"
        >
          Back
        </button>
      )
    );
  };
  nextButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row w-100 pt-3">
          <div className="col-4">{this.backButton()}</div>
          <div className="col-8">
            <div className="text-right">
              {this.cancelButtonBottom()}
              <button
                onClick={this.verifyData}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  validatePositionTypeTeam() {
    if (this.state.submissionObject.positionTypeIds.length > 0) {
      if (
        this.state.submissionObject.teams.length > 0 ||
        this.state.submissionObject.isNational
      ) {
        return true;
      } else {
        toast.error("Please Select Team.");
        return false;
      }
    } else {
      toast.error("Please provide Select Position Type.");
      return false;
    }
  }

  cancelModal = (e) => {
    this.setState({
      openModal: false,
      step: 1,
      teams: [],
      positionTypes: [],
      userPermissions: [],
      submissionObject: {
        isNational: false,
        positionTypeIds: [],
        teams: [],
        applyChild: false,
        permissions: [],
      },
    });
  };

  buttonLoader = () => {
    switch (this.state.step) {
      case 1:
      default:
        return this.nextButton();
        break;
      case 2:
        return this.savePermissionButton();
        break;
    }
  };

  cancelButtonLoader = () => {
    return (
      <button type="button" class="close text-white" onClick={this.cancelModal}>
        <span aria-hidden="true">&times;</span>
      </button>
    );
  };

  teamPermissions = () => {
    return (
      <>
        <h6 className="mt-0 font-weight-normal font-italic">
          <small>
            All the permissions that change to Turn OFF will also Turn OFF for
            the positions, child teams and child teams positions. However, If
            you change the permissions to Turn ON will not Turn ON for the child
            teams and child teams positions until and unless{" "}
            <b className="font-weight-bold">Apply to Child Teams</b> checkbox is
            not checked
          </small>
          <br />
          <small>
            The permissions toggles are designed as three-way switches, with
            colors representing specific meanings:
          </small>
          <br />
          <small className="pt-1">
            <label className="ST-check pr-1 check-small check-success"></label>{" "}
            Activates permission for the selected Position-types'.
          </small>
          <br />
          <small className="pt-1">
            <label className="ST-check pr-1 check-small check-danger"></label>{" "}
            Deactivates permission for the selected Position-types'.
          </small>
          <br />
          <small className="pt-1">
            <label className="ST-check pr-1 check-small check-default"></label>{" "}
            Deactivates the permission, yet Position-types' retain Default
            Permissions.
          </small>
          <br />
          <small className="pt-1">
            <label className="ST-check pr-1 check-small check-light"></label>{" "}
            Leaves permissions unchanged for Position-types'.
          </small>
        </h6>
        {AuthService.canSPAdd}
        <PermissionsST
          prefix={"TP-"}
          permissions={this.state.userPermissions}
          uncheckClass=" uncheck-light"
          handleChangePermission={this.handleChangeTeamPermission}
          handleChangeApplyChild={this.handleChangeApplyChild}
          listId="specialTeamPermission"
        />
      </>
    );
  };

  cancelButtonBottom = () => {
    return (
      <button
        className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
        type="button"
        onClick={this.cancelModal}
      >
        Cancel
      </button>
    );
  };

  savePermissionButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row w-100 pt-3">
          <div className="col-4">{this.backButton()}</div>
          <div className="col-8">
            <div className="text-right">
              {this.cancelButtonBottom()}
              <button
                onClick={this.savePermissionData}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  validateTeamPermissions() {
    let teamPermissions = this.state.submissionObject.permissions;
    let ValidData = teamPermissions.length;

    if (ValidData > 0) {
      return true;
    } else {
      toast.error("Please set appropriate permission.");
      return false;
    }
  }

  savePermissionData = (e) => {
    this.handleSaveTeamPermission();
  };

  async componentDidUpdate(prevprops) {
    if (this.props.permissionState.openSpecialTeamPermissionModal) {
      this.getTeamsAndPositionTypes();
      this.handleConfigurePermissionSection();

      this.openModal();
    }
  }

  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeSpecialTeamPermissionModal();
  }

  componentDidMount() {
    // this.GetPermissions();

    window.onunload = function() {
      $('[data-toggle="tooltipApplyChild"]').tooltip();
    };
  }

  render() {
    return (
      <AUX>
        <Modal
          centered={true}
          autoFocus={true}
          size="lg"
          isOpen={this.state.openModal}
          className="errorignoremodel styled-modal"
          backdrop="static"
          id="SpecialTeamPermission"
        >
          <ModalHeader
            className="modal-header bg-secondary py-2"
            toggle={this.cancelModal}
          >
            {this.state.modalheading}
          </ModalHeader>
          <ModalBody>{this.sectionLoader()}</ModalBody>
          <ModalFooter>{this.buttonLoader()}</ModalFooter>
        </Modal>
      </AUX>
    );
  }
}

export default connect((state) => ({ permissionState: state.permission }), {
  ...PermissionAction,
})(SpecialTeamPermission);
