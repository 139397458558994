import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function MG_DeleteEmailList(props) {
  const { modal, toggle, confirm, emailListExists, isFilterModal } = props;
  let message = "Are you sure you want to Delete the Email List.";
  if (isFilterModal) {
    if (emailListExists) {
      message = "Are you sure you want to Delete the Email List and Filter.";
    } else {
      message = "Are you sure you want to Delete Filter.";
    }
  }

  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          <h5>{message}</h5>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary w-md waves-effect waves-light"
          onClick={() => {
            confirm().confirmed();
          }}
        >
          Confirm
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
