import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";

const actions = {
  SET_FILTER_DATA: "SET_FILTER_DATA",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_COLLAPSE_TAB: "SET_COLLAPSE_TAB",
  SET_OPEN_TAB: "SET_OPEN_TAB",
  SHOW_WEB_SIDE_BAR: "SHOW_WEB_SIDE_BAR",
  SHOW_MOBILE_SIDE_BAR: "SHOW_MOBILE_SIDE_BAR",
  SET_FILTER_RESULT: "SET_FILTER_RESULT",
  SET_ADVANCED_FILTER: "SET_ADVANCED_FILTER",
  SET_TEMP_FILTER_DATA: "SET_TEMP_FILTER_DATA",
  SET_RETURN_RESULTSNO: "SET_RETURN_RESULTSNO",
  SET_BASIC_SEARCH_KEYWORD: "SET_BASIC_SEARCH_KEYWORD",
  SET_IS_BASIC_SEARCH_SELECTED: "SET_IS_BASIC_SEARCH_SELECTED",
  SET_FILTER_RESULT_PAGE_NO: "SET_FILTER_RESULT_PAGE_NO",
  SET_FILTER_RESULT_PAGE_SIZE: "SET_FILTER_RESULT_PAGE_SIZE",
  SET_FILTER_RESULT_TOTAL_COUNT: "SET_FILTER_RESULT_TOTAL_COUNT",
  SET_ADVANCE_FILTER_LOADNG: "SET_ADVANCE_FILTER_LOADNG",
  SET_NEW_FILTERED_DATA: "SET_NEW_FILTERED_DATA",
  SET_SIDEBAR_STATE: "SET_SIDEBAR_STATE",
  SET_DIRECTORY_MENU_STATE: "SET_DIRECTORY_MENU_STATE",
  SET_CURRENT_ACTIVE_TAB: "SET_CURRENT_ACTIVE_TAB",
  SET_RESET_ADVANCE_FILTER: "SET_RESET_ADVANCE_FILTER",
  SET_FACILITY_CHILD_ACCORDION: "SET_FACILITY_CHILD_ACCORDION",
  SET_LOCAL_PAGING: "SET_LOCAL_PAGING",
  RESET_LOCAL_PAGING: "RESET_LOCAL_PAGING",
  SET_MEMBERS_ACCORDIAN_TYPE: "SET_MEMBERS_ACCORDIAN_TYPE",
  SET_RESET_ALL: "SET_RESET_ALL",
  activeFacilityChildAccordion: (Id) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILITY_CHILD_ACCORDION,
      payload: Id,
    });
  },

  setFilterData: (FilterData) => (dispatch) => {
    dispatch({
      type: actions.SET_FILTER_DATA,
      payload: FilterData,
    });
  },
  setFilterResult: (Result) => (dispatch) => {
    dispatch({
      type: actions.SET_FILTER_RESULT,
      payload: Result,
    });
  },
  setTempFilterData: (Result) => (dispatch) => {
    dispatch({
      type: actions.SET_TEMP_FILTER_DATA,
      payload: Result,
    });
  },
  setReturnResultNo: (Result) => (dispatch) => {
    dispatch({
      type: actions.SET_RETURN_RESULTSNO,
      payload: Result,
    });
  },
  setActiveTab: (name) => (dispatch) => {
    dispatch({
      type: actions.SET_ACTIVE_TAB,
      payload: name,
    });
  },

  setCollapseTab: (name) => (dispatch) => {
    dispatch({
      type: actions.SET_COLLAPSE_TAB,
      payload: name,
    });
  },

  setOpenCard: (name) => (dispatch) => {
    dispatch({
      type: actions.SET_OPEN_TAB,
      payload: name,
    });
  },

  showWebSideBar: (val) => (dispatch) => {
    dispatch({
      type: actions.SHOW_WEB_SIDE_BAR,
      payload: val,
    });
  },

  showMobileSideBar: (val) => (dispatch) => {
    dispatch({
      type: actions.SHOW_MOBILE_SIDE_BAR,
      payload: val,
    });
  },

  setResetAdvanceFilter: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_RESET_ADVANCE_FILTER,
      payload: val,
    });
  },

  setAdvancedSearch: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_ADVANCED_FILTER,
      payload: val,
    });
  },
  getMemberTotalCount: (body) => (dispatch) => {
    axios
      .post("/api/Member/AdvancedFilter?isTotalCount=true", body)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          //console.log("return " + data.totalCount);

          dispatch({
            type: actions.SET_RETURN_RESULTSNO,
            payload: data.totalCount,
          });
        } else {
          dispatch({
            type: actions.SET_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        }
      });
  },

  setBasicSearchKeyWord: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_BASIC_SEARCH_KEYWORD,
      payload: val,
    });
  },

  setIsBasicSearchSelected: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_IS_BASIC_SEARCH_SELECTED,
      payload: val,
    });
  },
  setFilterResultPageNo: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FILTER_RESULT_PAGE_NO,
      payload: val,
    });
  },
  setCurrentActiveTab: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_CURRENT_ACTIVE_TAB,
      payload: val,
    });
  },
  getNewFilteredData: (body, isRefresh) => (dispatch) => {
    window.innerWidth <= 897 && $(".loading").show();
    axios
      .post(
        `/api/Member/AdvancedFilteredData?getRefreshData=${isRefresh}`,
        body
      )
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          window.innerWidth <= 897 && $(".loading").hide();

          dispatch({
            type: actions.SET_NEW_FILTERED_DATA,
            payload: data,
          });
        } else {
          toast.error(data.message);
          window.innerWidth <= 897 && $(".loading").hide();
          dispatch({
            type: actions.SET_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        }
      });
  },

  setAdvanceFilterLoader: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_ADVANCE_FILTER_LOADNG,
      payload: val,
    });
  },

  setSidebarState: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_SIDEBAR_STATE,
      payload: val,
    });
  },

  setDirectoryMenuState: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_DIRECTORY_MENU_STATE,
      payload: val,
    });
  },

  SetlocalPaging: () => (dispatch) => {
    dispatch({
      type: actions.SET_LOCAL_PAGING,
      payload: true,
    });
  },

  ResetlocalPaging: () => (dispatch) => {
    dispatch({
      type: actions.RESET_LOCAL_PAGING,
      payload: false,
    });
  },
  setMemberAccordianType: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_MEMBERS_ACCORDIAN_TYPE,
      payload: val,
    });
  },
  setFilterResultPageSize: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FILTER_RESULT_PAGE_SIZE,
      payload: val,
    });
  },
  setFilterResultTotalCount: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FILTER_RESULT_TOTAL_COUNT,
      payload: val,
    });
  },
  setResetAll: () => (dispatch) => {
    dispatch({
      type: actions.SET_RESET_ALL,
    });
  },
};

export default actions;
