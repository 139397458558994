import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../AdminHeader";
import RegistrationsListTable from "../User/ListTables/RegistrationsListTable";
import RegistrationsService from "../RegistrationsService";
import registrationsAction from "../../../store/registrations/action";
import AddTemplateModal from "./Modals/AddTemplateModal";
import { RegistrationTableTypes } from "../Helpers/LookUpValues";
import _ from "lodash";

export class RegistrationsListing extends Component {
  state = {
    registrations: [],
    isLoading: false,
    occasionId: 0,
  };

  async componentDidMount() {
    await this.reloadRegistrations();
  }

  reloadRegistrations = async () => {
    this.setState({ isloading: true });
    let data = await RegistrationsService.GetAdminChildTemplates();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.registrations = data.result != null ? data.result : [];
        await this.setState({
          registrations: this.state.registrations,
          isloading: false,
        });
      }
    }
  };

  handleRegistration = (occasionId) => {
    this.setState({
      occasionId: occasionId,
    });
  };

  // openAddTemplateModal = () => {
  //   this.setState({ editTemplate: {} });
  //   this.props.toggleAddTemplateModal();
  // };

  // onAddTemplateClose = async () => {
  //   this.props.toggleAddTemplateModal();
  //   this.setState({ editTemplate: {} });
  // };

  render() {
    return (
      <>
        <Header />
        <div className="fet-container container-fluid">
          <br />
          <button
            type="button"
            className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2 mt-4"
            onClick={() => {
              this.props.openAddTemplateModal({
                formType: "New",
                templateCategory: "2",
              });
            }}
          >
            <i class="cursorPointer pr-1 fas fa-plus" />
            Add Child Template
          </button>

          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">Child Templates </h5>
            <div className="card-body pb-2 px-0 pt-0">
              <RegistrationsListTable
                handleRegistrationViewClick={(occasionId) =>
                  this.handleRegistration(occasionId)
                }
                listData={this.state.registrations}
                reloadRegistrations={this.reloadRegistrations}
                isloading={this.state.isloading}
                regTableType={RegistrationTableTypes.AdminRegistrations}
              />
            </div>
          </div>
        </div>

        <AddTemplateModal
          showModal={this.props.registrationsState.openAddTemplateModal}
          reloadRegistrations={this.reloadRegistrations}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(RegistrationsListing)
);
