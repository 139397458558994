import React, { Component, Fragment } from "react";
import AUX from "../../../../hoc/Aux_";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import registrationAction from "../../../../store/registrations/action";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "moment/locale/en-ca";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import { debounce } from "throttle-debounce";
import AuthService from "../../../Auth/AuthService";
import RegistrationsService from "../../RegistrationsService";
import AddQuestionModal from "../Questions/ModalPopUps/AddQuestionModal";
import { Modal, Card, Table, Accordion, Button } from "react-bootstrap";
import { FilePond, registerPlugin, setOptions, File } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import EmailListService from "../../../Email/EmailListService";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);

const initialState = {
  searchTerm: "",
  masterTemplateId: -1,
  childTemplateId: -1,
  templateType: "",
  templateName: "",
  emailAlias: "",
  abbreviation: "",
  description: "",
  duration: "",
  duration_err: "",
  templateState: "",
  templateCity: "",
  regisStartDate: null,
  regisStartDate_err: "",
  regisEndDate: null,
  regisEndDate_err: "",
  startDate: null,
  startDate_err: "",
  endDate: null,
  endDate_err: "",
  visibility: "",
  fee: 0,
  fee_err: "",
  formateFee: "",
  approvalRequired: false,
  preRequisite: "",
  MemberOptions: [],
  selectedLeadsIds: [],
  selectedLeadsObj: [],
  selectedInstructorsIds: [],
  selectedInstructorsObj: [],
  selectedAdminsIds: [],
  selectedAdminsObj: [],
  availableQuestions: [],
  selectedQuestions: [],
  isConfirmModalOpen: false,
  acceptedFileTypes: [
    // "image/png",
    // "image/jpeg",
    // "application/pdf",
    // "image/gif",
  ],
  files: [],
};

class AddTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      domain: "",
      formTitle: "Add Template",
      isChild: false,
      templateTypesOptions: [
        { key: 1, val: "Academy Class" },
        { key: 2, val: "Meeting" },
        { key: 3, val: "Event" },
      ],
      visibilityOptions: [
        { key: 1, val: "Public" },
        { key: 2, val: "Members Only" },
        { key: 3, val: "Invite Only" },
      ],
      preRequisiteOptions: [],
    };
    this.user = AuthService.getProfile().memberData;
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.onShow = this.onShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.customValidation = this.customValidation.bind(this);
    this.loadMemberData = this.loadMemberData.bind(this);
    this.loadQuestionsData = this.loadQuestionsData.bind(this);
    this.changeLeads = this.changeLeads.bind(this);
    this.changeInstructors = this.changeInstructors.bind(this);
    this.changeAdmins = this.changeAdmins.bind(this);
    this.MemberSearchHandler = this.MemberSearchHandler.bind(this);
    this.onRSDateChange = this.onRSDateChange.bind(this);
    this.onREDateChange = this.onREDateChange.bind(this);
    this.onSDateChange = this.onSDateChange.bind(this);
    this.onEDateChange = this.onEDateChange.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
  }

  handleInitFileUpload() {
    //console.log("handleInitFileUpload instance has initialised", this.pond2);
  }

  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.loadMemberData(this.state.searchTerm);
  };

  componentWillUnmount() { }

  async componentDidMount() {
    //this.loadPrerequisites();
    this.loadDomain();
    //this.loadQuestionsData();
  }
  loadDomain = async () => {
    await EmailListService.GetDomainName()
      .then(({ data: response }) => {
        if (response.status === "success") {
          this.setState({
            domain: response.domain,
          });
          console.log(this.state.domain);
          let domainName = response.domain;
          this.setState({ domain: domainName });
        }
        $(".loading").hide();
      })
      .catch((ex) => {
        console.log(ex);
        $(".loading").hide();
        toast.error("Something went wrong!");
      });
  };
  onRSDateChange = (jsDate, dateString) => {
    this.setState({
      regisStartDate: jsDate,
      regisStartDate_err: "",
    });
  };

  onREDateChange = (jsDate, dateString) => {
    this.setState({
      regisEndDate: jsDate,
      regisEndDate_err: "",
    });
  };

  onSDateChange = (jsDate, dateString) => {
    this.setState({
      startDate: jsDate,
      startDate_err: "",
    });
  };

  onEDateChange = (jsDate, dateString) => {
    this.setState({
      endDate: jsDate,
      endDate_err: "",
    });
  };

  loadPrerequisites = async () => {
    let data = await RegistrationsService.GetPreRequisites();

    if (!_.isEmpty(data)) {
      let list = this.props.registrationState.templateObj.masterTemplateId
        ? data.filter(
          (pr) =>
            !(
              pr.id ===
              this.props.registrationState.templateObj.masterTemplateId
            )
        )
        : data;
      await this.setState({
        preRequisiteOptions: list,
      });
    }
  };

  loadChildData = async (id) => {
    let data = await RegistrationsService.GetChildTemplateById(id);

    this.loadStateProperties(data.result);
  };

  loadMasterData = async (id) => {
    let data = await RegistrationsService.GetMasterTemplateById(id);

    this.loadStateProperties(data.result);
  };

  loadPreviousData = async (templateType) => {
    let data = await RegistrationsService.GetLastTemplateRecord(templateType);

    if (!_.isEmpty(data))
      this.loadStateProperties(data.result);
  };

  loadStateProperties = async (data) => {
    if (data != null) {
      var num_parts = data.fee
        .toString()
        .split(",")
        .join("")
        .split(".");

      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      let visibility = "";
      let templateType = "";
      let preRequisite = "";
      let isCopied = this.props.registrationState.templateObj.isCopied
        ? this.props.registrationState.templateObj.isCopied
        : false;

      if (_.isEmpty(data.visibility) && data.visibility != 0) {
        visibility = this.state.visibilityOptions.find((el, index) => {
          if (el.key == data.visibility) return el;
        });
      }
      if (_.isEmpty(data.template_type) && data.template_type != 0) {
        templateType = this.state.templateTypesOptions.find((el, index) => {
          if (el.key == data.template_type) return el;
        });
      }
      if (_.isEmpty(data.pre_requisite) && data.pre_requisite != 0) {
        preRequisite = this.state.preRequisiteOptions.find((el, index) => {
          if (el.id == data.pre_requisite) return el;
        });
      }

      await this.setState({
        masterTemplateId: this.props.registrationState.templateObj
          .masterTemplateId
          ? this.props.registrationState.templateObj.masterTemplateId
          : -1,
        childTemplateId:
          this.props.registrationState.templateObj.childTemplateId && !isCopied
            ? this.props.registrationState.templateObj.childTemplateId
            : -1,
        templateType: templateType,
        templateName: data.name,
        abbreviation: data.abbreviation,
        description: data.description,
        duration: data.duration,
        templateState: data.state,
        templateCity: data.city,
        fee: data.fee,
        formateFee:
          num_parts.length > 2
            ? num_parts[0] + "." + num_parts[1]
            : num_parts.join("."),
        approvalRequired: data.approval_required,
        visibility: visibility,
        preRequisite: preRequisite,
        emailAlias: data.email_alias ? data.email_alias : "",
        selectedInstructorsIds: data.instructors ? data.instructors : [],
        selectedInstructorsObj: data.instructors_objects
          ? data.instructors_objects
          : [],
        selectedLeadsIds: data.lead_instructors ? data.lead_instructors : [],
        selectedLeadsObj: data.lead_instructors_objects
          ? data.lead_instructors_objects
          : [],
        selectedAdminsIds: data.admins ? data.admins : [],
        selectedAdminsObj: data.admins_objects ? data.admins_objects : [],
        selectedQuestions: data.questions ? data.questions : [],
        availableQuestions: data.questions
          ? this.state.availableQuestions.filter(
            (aq) => !data.questions.find((sq) => sq.id === aq.id)
          )
          : this.state.availableQuestions,
        regisStartDate: data.registration_start_date
          ? data.registration_start_date
          : null,
        regisEndDate: data.registration_end_date
          ? data.registration_end_date
          : null,
        startDate: data.start_date ? data.start_date : null,
        endDate: data.end_date ? data.end_date : null,
      });
    } else {
      var type = this.state.templateType;
      var ques = this.state.availableQuestions;
      this.setState(initialState);
      this.setState({
        templateType: type,
        availableQuestions: ques,
      });
    }
  };

  componentDidUpdate(prevProps) { }

  MemberSearchHandler = (SearchTerms) => {
    this.setState({ searchTerm: SearchTerms }, () => {
      this.autocompleteSearchDebounced();
    });
  };

  loadMemberData = async (SearchTerms) => {
    let data = await RegistrationsService.LoadMembers(SearchTerms, 0, 5);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        await this.setState({
          MemberOptions: data.result,
        });
      }
    }
  };

  loadQuestionsData = async () => {
    let data = await RegistrationsService.GetSystemQuestions();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        await this.setState({
          availableQuestions: data.result,
        });
      }
    }
  };

  changeLeads = (value) => {
    this.setState({
      selectedLeadsIds: value.map((val) => val.id),
      selectedLeadsObj: value,
    });
  };

  changeInstructors = (value) => {
    this.setState({
      selectedInstructorsIds: value.map((val) => val.id),
      selectedInstructorsObj: value,
    });
  };

  changeAdmins = (value) => {
    this.setState({
      selectedAdminsIds: value.map((val) => val.id),
      selectedAdminsObj: value,
    });
  };

  valueChangeHandler = (evt) => {
    if (evt.target.name == "approvalRequired") {
      this.setState({
        [evt.target.name]: evt.target.checked,
      });
    } else if (evt.target.name == "emailAlias") {
      let validity = this.checkEmailAliasValidity(evt.target.value);
      this.setState({
        [evt.target.name]: evt.target.value,
        [evt.target.name + "_err"]: validity ? "" : "Email alias is not valid.",
      });
    } else {
      this.setState({
        [evt.target.name]: evt.target.value,
        [evt.target.name + "_err"]: "",
      });
    }
  };

  checkEmailAliasValidity = async (alias) => {
    let data = await RegistrationsService.CheckEmailAliasValidity(alias);
    if (!_.isEmpty(data)) {
      return true;
    }
    return false;
  };

  dropdownChangeHandler = (evt, value, field) => {
    this.setState({
      [field]: value,
    });

    if (field == "templateType" && !_.isEmpty(value)) {
      this.loadPreviousData(value.key);
    }
  };

  feeChangeHandler = (evt) => {
    if (evt.target.value != "") {
      var expression = /^[\d.,]+$/;
      if (!expression.test(evt.target.value)) {
        this.state.fee_err =
          "Invalid amount entered. Only numbers are allowed.";
      } else {
        this.state.fee_err = "";
      }
    } else {
      this.state.fee_err = "";
    }
    var num_parts = evt.target.value
      .toString()
      .split(",")
      .join("")
      .split(".");
    var amount = num_parts.join(".");
    if (num_parts[0].length > 10) {
      this.setState({
        fee_err: this.state.fee_err,
      });
      return false;
    }
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({
      formateFee:
        num_parts.length > 2
          ? num_parts[0] + "." + num_parts[1]
          : num_parts.join("."),
      fee: amount,
      fee_err: this.state.fee_err,
    });
  };

  durationChangeHandler = (evt) => {
    if (evt.target.value != "") {
      var expression = /^[\d.,]+$/;
      if (!expression.test(evt.target.value)) {
        this.state.duration_err =
          "Invalid value entered. Only numbers are allowed.";
      } else {
        this.state.duration_err = "";
      }
    } else {
      this.state.duration_err = "";
    }
    var num_parts = evt.target.value
      .toString()
      .split(",")
      .join("")
      .split(".");

    if (num_parts[0].length > 2) {
      this.setState({
        duration_err: this.state.duration_err,
      });
      return false;
    }

    this.setState({
      duration: evt.target.value,
      duration_err: this.state.duration_err,
    });
  };

  onFileUpload(files) {
    this.state.files = files
      .filter((el) => {
        return el.status !== 8;
      })
      .map((fileItem) => fileItem.file);
    this.setState({ files: this.state.files });
  }

  renderAvailableQuestions = (availableQuestions) => {
    if (availableQuestions) {
      return (
        <Accordion className="available-fields-container">
          {availableQuestions.map((question, idx) => {
            return (
              <Card data-header-id={question.id} key={idx}>
                <Card.Header className="category-header">
                  <div
                    className={"available-field"}
                    key={question.id}
                    data-field-id={question.id}
                  >
                    {question.label}
                    <i
                      class="fas fa-arrow-right"
                      style={{ float: "right" }}
                      data-field-id={question.id}
                      title={`Move to selected.`}
                      onClick={() => {
                        this.moveToSelected(question.id);
                      }}
                      key={question.id + 1}
                    ></i>
                  </div>
                </Card.Header>
              </Card>
            );
          })}
        </Accordion>
      );
    }
  };

  moveToSelected = (questionId) => {
    let available = [...this.state.availableQuestions];
    let selectedQuestion = available.filter((qld) => {
      return qld.id === questionId;
    });
    let selected = [...this.state.selectedQuestions];
    selected.push(selectedQuestion[0]);
    available = available.filter((qld) => {
      return qld.id !== questionId;
    });

    this.setState({
      selectedQuestions: selected,
      availableQuestions: available,
    });
  };

  renderSelectedQuestions = (selectedQuestions) => {
    if (selectedQuestions) {
      return (
        <Accordion className="available-fields-container">
          {selectedQuestions.map((question, idx) => {
            return (
              <Card data-header-id={question.id} key={idx}>
                <Card.Header className="category-header">
                  <div
                    className={"available-field"}
                    key={question.id}
                    data-field-id={question.id}
                  >
                    {question.label}
                    <i
                      class="fa fa-trash"
                      style={{ float: "right" }}
                      data-field-id={question.id}
                      title={`Remove from selected.`}
                      onClick={() => {
                        this.removeFromSelected(question.id);
                      }}
                      key={question.id + 1}
                    ></i>
                  </div>
                </Card.Header>
              </Card>
            );
          })}
        </Accordion>
      );
    }
  };

  removeFromSelected = (questionId) => {
    let selected = [...this.state.selectedQuestions];
    let removedQuestion = selected.filter((qld) => {
      return qld.id === questionId;
    });
    let available = [...this.state.availableQuestions];

    available.push(removedQuestion[0]);
    selected = selected.filter((qld) => {
      return qld.id !== questionId;
    });

    this.setState({
      selectedQuestions: selected,
      availableQuestions: available,
    });
  };

  customValidation() {
    var isValid = true;

    // if (_.isEmpty(this.state.candidateName)) {
    //   isValid = false;
    //   this.state.candidateName_err = "Please select candidate.";
    // }

    // if (!isValid) {
    //   this.setState({ submissionObj: property });
    // }

    if (
      _.isEmpty(this.state.templateType.toString()) ||
      _.isEmpty(this.state.templateName)
    ) {
      isValid = false;
    }
    if (this.state.isChild) {
      if (!_.isEmpty(this.state.emailAlias_err)) {
        isValid = false;
      }
      if (
        !(
          this.state.regisStartDate ||
          this.state.regisStartDate ||
          this.state.regisStartDate ||
          this.state.regisStartDate
        )
      ) {
        isValid = false;
      }
    }
    return isValid;
  }

  refreshSelectedQuestions = async () => { };

  toggleConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };

  confirm = () => {
    this.confirmed = async () => {
      this.toggleConfirmModal();
    };
    return this;
  };

  openAddQuestionModal = () => {
    this.props.toggleAddQuestionModal();
  };

  onCloseAddQuestion = async (data) => {
    this.props.toggleAddQuestionModal();
    if (data) {
      let merged = [...this.state.selectedQuestions, ...data];
      this.setState({
        selectedQuestions: merged,
      });
    }
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    if (this.customValidation()) {
      var formdata = new FormData();
      if (!this.state.isChild) {
        const data = {
          master_template_id: this.state.masterTemplateId,
          child_template_id: this.state.childTemplateId,
          questions: this.state.selectedQuestions,
          template_category: this.props.registrationState.templateObj
            .templateCategory,
          template_type: this.state.templateType.key,
          name: this.state.templateName,
          abbreviation: this.state.abbreviation,
          description: this.state.description,
          duration: this.state.duration,
          city: this.state.templateCity,
          state: this.state.templateState,
          approval_required: this.state.approvalRequired,
          visibility: this.state.visibility ? this.state.visibility.key : 0,
          fee: this.state.fee,
          pre_requisite: this.state.preRequisite
            ? this.state.preRequisite.id
            : 0,
          lead_instructors: this.state.selectedLeadsIds,
          instructors: this.state.selectedInstructorsIds,
          admins: this.state.selectedAdminsIds,
        };

        for (var i = 0; i < this.state.files.length; i++) {
          formdata.append("File" + i, this.state.files[i]);
        }

        formdata.append("data", JSON.stringify(data));

        this.saveMasterTemplateObject(formdata);
      } else {
        const data = {
          master_template_id: this.state.masterTemplateId,
          child_template_id: this.state.childTemplateId,
          questions: this.state.selectedQuestions,
          name: this.state.templateName,
          template_category: this.props.registrationState.templateObj
            .templateCategory,
          template_type: this.state.templateType.key,
          email_alias: this.state.emailAlias,
          registration_start_date: this.state.regisStartDate,
          registration_end_date: this.state.regisEndDate,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
          abbreviation: this.state.abbreviation,
          description: this.state.description,
          duration: this.state.duration,
          city: this.state.templateCity,
          state: this.state.templateState,
          approval_required: this.state.approvalRequired,
          visibility: this.state.visibility ? this.state.visibility.key : 0,
          fee: this.state.fee,
          lead_instructors: this.state.selectedLeadsIds,
          instructors: this.state.selectedInstructorsIds,
          admins: this.state.selectedAdminsIds,
          pre_requisite: this.state.preRequisite
            ? this.state.preRequisite.id
            : 0,
        };

        for (var i = 0; i < this.state.files.length; i++) {
          formdata.append("File" + i, this.state.files[i]);
        }

        formdata.append("data", JSON.stringify(data));

        this.saveChildTemplateObject(formdata);
      }
    } else {
      toast.error("Please fill mandatory(*) fields.");
    }
  };

  async saveMasterTemplateObject(formdata) {
    let response = await RegistrationsService.SaveMasterTemplate(formdata);
    if (!_.isEmpty(response)) {
      //this.props.getMyRequests();
      toast.success("Record saved successfully");
      //await this.loadPrerequisites();
      await this.props.reloadRegistrations();
      this.handleClose();
    }
  }

  async saveChildTemplateObject(formdata) {
    let response = await RegistrationsService.SaveChildTemplate(formdata);
    if (!_.isEmpty(response)) {
      //this.props.getMyRequests();
      toast.success("Record saved successfully");
      //await this.loadPrerequisites();
      await this.props.reloadRegistrations();
      this.handleClose();
    }
  }

  onShow() {
    this.loadQuestionsData();
    this.loadPrerequisites();
    this.setState({
      formTitle: this.props.registrationState.templateObj.formTitle,
      isChild: this.props.registrationState.templateObj.templateCategory == 2, /// 1 for master, 2 for child
    });

    if (this.props.registrationState.templateObj.childTemplateId)
      this.loadChildData(
        this.props.registrationState.templateObj.childTemplateId
      );
    else if (this.props.registrationState.templateObj.masterTemplateId)
      this.loadMasterData(
        this.props.registrationState.templateObj.masterTemplateId
      );
  }

  handleClose() {
    this.props.closeAddTemplateModal();
    this.setState(initialState);
  }

  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          marginRight: 10,
          fontSize: 18,
        },
      },
    });

    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="addRequestModal"
          size="xl"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title className=" w-100 modal-title font-italic">
              <div className="feModalHeader align-items-center text-uppercase d-flex">
                <div className="col-sm-6 mt-2">
                  <h5 className="text-white mt-0">{this.state.formTitle}</h5>
                </div>
                <div className="col-sm-6 float-right text-right w-25 text-white mt-0">
                  <h5></h5>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <form
                className="needs-validation"
                id="addTemplateForm"
                onSubmit={this.onSubmit}
                noValidate
              >
                <div className="row mt-1">
                  {!this.state.isChild && (
                    <div className=" col-lg-4 mt-lg-3 text_ctrl">
                      <Autocomplete
                        id="templateType"
                        options={this.state.templateTypesOptions}
                        getOptionLabel={(option) => {
                          if (typeof option === "object") {
                            return option.val;
                          } else {
                            return "";
                          }
                        }}
                        onChange={(e, newValue, reason) =>
                          this.dropdownChangeHandler(
                            e,
                            newValue,
                            "templateType"
                          )
                        }
                        disabled={false}
                        value={this.state.templateType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type"
                            variant="standard"
                            value={this.state.templateType}
                            placeholder="Select Template Type"
                            InputLabelProps={{
                              shrink: true,
                              classes: { shrink: "mt-n1" },
                            }}
                            error={
                              this.state.issubmit &&
                                _.isEmpty(this.state.templateType)
                                ? true
                                : false
                            }
                            helperText={
                              this.state.issubmit &&
                                _.isEmpty(this.state.templateType)
                                ? "Required"
                                : ""
                            }
                            required={true}
                          />
                        )}
                      />
                    </div>
                  )}

                  <div className="col-lg-4 mt-3 text_ctrl">
                    <div className="form-group">
                      <MDBInput
                        id="templateName"
                        value={this.state.templateName}
                        name="templateName"
                        onChange={this.valueChangeHandler}
                        label="Template Name *"
                        hint="Enter Name"
                        group
                        type="text"
                        disabled={false} //{this.state.isChild}
                        required
                      >
                        <div className="invalid-feedback">Required</div>
                      </MDBInput>
                    </div>
                  </div>
                  {this.state.isChild && (
                    <div className="col-lg-4 mt-3 text_ctrl">
                      <div className="ct-alias input-group mt-2">
                        <input
                          id="emailAlias"
                          name="emailAlias"
                          type="text"
                          class="form-control h-auto"
                          placeholder="Enter Alias"
                          aria-label="Email Alias"
                          aria-describedby="basic-addon2"
                          value={this.state.emailAlias}
                          disabled={!this.state.isChild}
                          onChange={this.valueChangeHandler}
                        />
                        <span className="input-group-text" id="basic-addon2">
                          {this.state.domain}
                        </span>
                      </div>
                      <span className="text-danger">
                        {this.state.emailAlias_err}
                      </span>
                    </div>
                  )}

                  <div className=" col-lg-4 mt-3 text_ctrl">
                    <Autocomplete
                      id="preRequisite"
                      options={this.state.preRequisiteOptions}
                      getOptionLabel={(option) => {
                        if (typeof option === "object") {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      onChange={(e, newValue, reason) =>
                        this.dropdownChangeHandler(e, newValue, "preRequisite")
                      }
                      disabled={false} //{this.state.isChild}
                      value={this.state.preRequisite}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Pre-Requisite"
                          variant="standard"
                          value={this.state.preRequisite}
                          placeholder="Select Pre-Requisite"
                          InputLabelProps={{
                            shrink: true,
                            classes: { shrink: "mt-n1" },
                          }}
                          error={
                            this.state.issubmit &&
                              _.isEmpty(this.state.preRequisite)
                              ? true
                              : false
                          }
                          helperText={
                            this.state.issubmit &&
                              _.isEmpty(this.state.preRequisite)
                              ? "Required"
                              : ""
                          }
                          required={false}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg text_ctrl">
                    <div class="form-group">
                      <label
                        style={{ color: "#979797 !important" }}
                        for="description"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        value={this.state.description}
                        name="description"
                        class="form-control rounded-0"
                        rows="3"
                        onChange={this.valueChangeHandler}
                        maxLength="1000"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-4 mt-3 text_ctrl">
                    <div className="form-group">
                      <MDBInput
                        id="description"
                        value={this.state.description}
                        name="description"
                        onChange={this.valueChangeHandler}
                        label="Description"
                        hint="Enter Description"
                        group
                        type="text"
                        disabled={false}
                        required
                      >
                        <div className="invalid-feedback">Required</div>
                      </MDBInput>
                    </div>
                  </div> */}

                  {/* <div className="col-lg-8 mt-3">
                    <div className="row">
                      
                    </div>
                  </div> */}

                  <div className="col-lg-4 text_ctrl">
                    <div className="form-group">
                      <MDBInput
                        id="abbreviation"
                        value={this.state.abbreviation}
                        name="abbreviation"
                        onChange={this.valueChangeHandler}
                        label="Abbreviation"
                        hint="Enter Abbreviation"
                        group
                        type="text"
                        disabled={false}
                        required
                      >
                        <div className="invalid-feedback">Required</div>
                      </MDBInput>
                    </div>
                  </div>
                  <div className="col-lg-4 text_ctrl">
                    <div className="form-group">
                      <MDBInput
                        id="templateState"
                        value={this.state.templateState}
                        name="templateState"
                        onChange={this.valueChangeHandler}
                        label="State"
                        hint="Enter State"
                        group
                        type="text"
                        disabled={false}
                        required
                      >
                        <div className="invalid-feedback">Required</div>
                      </MDBInput>
                    </div>
                  </div>
                  <div className="col-lg-4 text_ctrl">
                    <div className="form-group">
                      <MDBInput
                        id="templateCity"
                        value={this.state.templateCity}
                        name="templateCity"
                        onChange={this.valueChangeHandler}
                        label="City"
                        hint="Enter City"
                        group
                        type="text"
                        disabled={false}
                        required
                      >
                        <div className="invalid-feedback">Required</div>
                      </MDBInput>
                    </div>
                  </div>
                </div>
                {this.state.isChild && (
                  <div className="row">
                    <div className="col-lg-3 mt-3 text_ctrl">
                      <div className="form-group">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>Registration Start Date: *</small>
                        </span>
                        <DatePickerInput
                          readonly="readonly"
                          onChange={this.onRSDateChange}
                          value={this.state.regisStartDate}
                        />
                        <span className="text-danger">
                          {this.state.regisStartDate_err}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 text_ctrl">
                      <div className="form-group">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>Registration End Date: *</small>
                        </span>
                        <DatePickerInput
                          readonly="readonly"
                          onChange={this.onREDateChange}
                          value={this.state.regisEndDate}
                        />
                        <span className="text-danger">
                          {this.state.regisEndDate_err}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 text_ctrl">
                      <div className="form-group">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>Start Date: *</small>
                        </span>
                        <DatePickerInput
                          readonly="readonly"
                          onChange={this.onSDateChange}
                          value={this.state.startDate}
                        />
                        <span className="text-danger">
                          {this.state.startDate_err}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 mt-3 text_ctrl">
                      <div className="form-group">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>End Date: *</small>
                        </span>
                        <DatePickerInput
                          readonly="readonly"
                          onChange={this.onEDateChange}
                          value={this.state.endDate}
                        />
                        <span className="text-danger">
                          {this.state.endDate_err}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-4 mt-3 text_ctrl">
                    <div className="form-group">
                      <Autocomplete
                        id="visibility"
                        options={this.state.visibilityOptions}
                        getOptionLabel={(option) => {
                          if (typeof option === "object") {
                            return option.val;
                          } else {
                            return "";
                          }
                        }}
                        onChange={(e, newValue, reason) =>
                          this.dropdownChangeHandler(e, newValue, "visibility")
                        }
                        disabled={false}
                        value={this.state.visibility}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Visibility"
                            variant="standard"
                            value={this.state.visibility}
                            placeholder="Select Visibility"
                            InputLabelProps={{
                              shrink: true,
                              classes: { shrink: "mt-n1" },
                            }}
                            error={
                              this.state.issubmit &&
                                _.isEmpty(this.state.visibility)
                                ? true
                                : false
                            }
                            helperText={
                              this.state.issubmit &&
                                _.isEmpty(this.state.visibility)
                                ? "Required"
                                : ""
                            }
                            required={false}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 mt-3">
                    <div className="row">
                      <div className="col-lg-4 text_ctrl">
                        <div className="form-group">
                          <MDBInput
                            id="duration"
                            value={this.state.duration}
                            name="duration"
                            onChange={this.durationChangeHandler}
                            label="Duration (in days)"
                            hint="Enter Duration in Days"
                            group
                            type="text"
                            disabled={false}
                            required
                          />
                          <span className="text-danger">
                            {this.state.duration_err}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 text_ctrl d-none">
                        <div className="form-group">
                          <MDBInput
                            id="fee"
                            value={this.state.formateFee}
                            name="fee"
                            onChange={this.feeChangeHandler}
                            label="Fee ($)"
                            hint="Enter Fee in $"
                            maxlength="16"
                            group
                            type="text"
                            disabled={false}
                            required
                          />
                          <span className="text-danger">
                            {this.state.fee_err}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-4 text_ctrl">
                        <div className="form-group">
                          <div className="card-text  px-4 py-1 custom-checkbox">
                            <input
                              name={"approvalRequired"}
                              id={"approvalRequired"}
                              type="checkbox"
                              onChange={this.valueChangeHandler}
                              checked={this.state.approvalRequired}
                              className="custom-control-input"
                            />
                            <label
                              className="custom-control-label"
                              for={"approvalRequired"}
                            >
                              Approval Required
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-4 mt-3 text_ctrl">
                    <Autocomplete
                      multiple
                      id="Leads"
                      options={this.state.MemberOptions}
                      filterOptions={(options, state) => options}
                      autoHighlight={true}
                      getOptionLabel={(option) => {
                        if (option != undefined) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      ChipProps={{ classes: { root: "py-2 " } }}
                      onInputChange={(e, value, reason) => {
                        this.MemberSearchHandler(value);
                      }}
                      value={this.state.selectedLeadsObj}
                      onChange={(e, value, reason) => {
                        this.changeLeads(value);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              className:
                                "pb-1" +
                                (params.inputProps &&
                                  " " + params.inputProps.className),
                            }}
                            variant="standard"
                            label="Lead Instructors:"
                            InputLabelProps={{
                              shrink: true,
                              classes: { shrink: "mt-n1" },
                            }}
                            placeholder="Lead Instructors"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className=" col-lg-4 mt-3 text_ctrl">
                    <Autocomplete
                      multiple
                      id="Instructors"
                      options={this.state.MemberOptions}
                      filterOptions={(options, state) => options}
                      autoHighlight={true}
                      getOptionLabel={(option) => {
                        if (option != undefined) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      ChipProps={{ classes: { root: "py-2 " } }}
                      onInputChange={(e, value, reason) => {
                        this.MemberSearchHandler(value);
                      }}
                      value={this.state.selectedInstructorsObj}
                      onChange={(e, value, reason) => {
                        this.changeInstructors(value);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              className:
                                "pb-1" +
                                (params.inputProps &&
                                  " " + params.inputProps.className),
                            }}
                            variant="standard"
                            label="Instructors:"
                            InputLabelProps={{
                              shrink: true,
                              classes: { shrink: "mt-n1" },
                            }}
                            placeholder="Instructors"
                          />
                        );
                      }}
                    />
                  </div>
                  <div className=" col-lg-4 mt-3 text_ctrl">
                    <Autocomplete
                      multiple
                      id="Admins"
                      options={this.state.MemberOptions}
                      filterOptions={(options, state) => options}
                      autoHighlight={true}
                      getOptionLabel={(option) => {
                        if (option != undefined) {
                          return option.name;
                        } else {
                          return "";
                        }
                      }}
                      ChipProps={{ classes: { root: "py-2 " } }}
                      onInputChange={(e, value, reason) => {
                        this.MemberSearchHandler(value);
                      }}
                      value={this.state.selectedAdminsObj}
                      onChange={(e, value, reason) => {
                        this.changeAdmins(value);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              className:
                                "pb-1" +
                                (params.inputProps &&
                                  " " + params.inputProps.className),
                            }}
                            variant="standard"
                            label="Admins:"
                            InputLabelProps={{
                              shrink: true,
                              classes: { shrink: "mt-n1" },
                            }}
                            placeholder="Admins"
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-12 mt-3 text_ctrl">
                    <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                      <small>Documents:</small>
                    </span>
                    <FilePond
                      ref={(ref) => (this.pond2 = ref)}
                      files={this.state.files}
                      allowMultiple={true}
                      maxFiles={10}
                      allowFileTypeValidation={true}
                      allowImageExifOrientation={true}
                      allowImagePreview={true}
                      allowImageCrop={true}
                      allowFileEncode={true}
                      // acceptedFileTypes={
                      //   this.state.acceptedFileTypes
                      // }
                      fileValidateTypeDetectType={(source, type) =>
                        new Promise((resolve, reject) => {
                          let rejectedExtensions = [
                            "ade",
                            "adp",
                            "apk",
                            "bat",
                            "chm",
                            "cmd",
                            "com",
                            "cpl",
                            "dll",
                            "dmg",
                            "exe",
                            "hta",
                            "ins",
                            "isp",
                            "jar",
                            "js",
                            "jse",
                            "lib",
                            "lnk",
                            "mde",
                            "msc",
                            "msi",
                            "msp",
                            "mst",
                            "nsh",
                            "pif",
                            "scr",
                            "sct",
                            "shb",
                            "sys",
                            "vb",
                            "vbe",
                            "vbs",
                            "vxd",
                            "wsc",
                            "wsf",
                            "wsh",
                            "cab",
                          ];
                          // If the file extension is not in our rejected list.
                          if (
                            !rejectedExtensions.some((ext) =>
                              source.name.endsWith("." + ext)
                            )
                          ) {
                            resolve(type);
                          } else {
                            // Otherwise it is rejected.
                            toast.error("File type is unsupported!");
                            //reject(new Error('Files contains unsupported format!'));
                            reject(type);
                          }
                        })
                      }
                      oninit={() => this.handleInitFileUpload()}
                      onupdatefiles={(fileItems) => {
                        // Set currently active file objects to this.state
                        this.onFileUpload(fileItems);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-4 mt-3 text_ctrl">
                    <button
                      type="button"
                      className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2"
                      onClick={this.openAddQuestionModal}
                    >
                      <i class="cursorPointer pr-1 fas fa-plus" />
                      Create New Questions
                    </button>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <Card>
                      <Card.Header className="text-center">
                        Available Question(s)
                      </Card.Header>
                      <Card.Body className="available-fields">
                        {this.renderAvailableQuestions(
                          this.state.availableQuestions
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <Card>
                      <Card.Header className="text-center">
                        Selected Question(s)
                      </Card.Header>
                      <Card.Body className="available-fields">
                        {this.renderSelectedQuestions(
                          this.state.selectedQuestions
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                <div className="form-group mb-4 row">
                  <div className="col-12 m-t-20 mx-auto text-center">
                    {
                      <>
                        <button
                          className="btn btn-success w-md mc mr-2"
                          disabled={""}
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-primary w-md waves-effect cancelModal waves-light mr-2  mc"
                          data-dismiss="modal"
                          aria-hidden="true"
                          type="reset"
                          onClick={this.handleClose}
                        >
                          Close
                        </button>
                      </>
                    }
                  </div>
                </div>
              </form>
            </>
          </Modal.Body>
        </Modal>
        <AddQuestionModal
          showModal={this.props.registrationState.toggleAddQuestionModal}
          onClose={this.onCloseAddQuestion}
          editQuestion={{}}
          toggleModal={this.props.toggleQuestionModal}
          isConfirmModalOpen={this.state.isConfirmModalOpen}
          toggleConfirmModal={this.toggleConfirmModal}
          confirmDeleteAction={this.confirm}
          isTemplateCall={true}
        />
      </AUX>
    );
  }
}
export default withRouter(
  connect((state) => ({ registrationState: state.registrations }), {
    ...registrationAction,
  })(AddTemplateModal)
);
