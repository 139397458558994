import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";

const AreasActions = (
  cell,
  row,
  deleteArea,
  editArea,
  assignMembers,
  canUpdate,
  canDelete,
  canAssignAreas
) => {
  return (
    <div>
      <div className="actionIcons">
        <>
          {canAssignAreas && (
            <i
              className="text-success fas fa-light fa-sitemap c-pointer pl-1"
              title="Assign Members"
              onClick={() => assignMembers(row.id)}
            ></i>
          )}
          {canUpdate && (
            <i
              className="fa fa-pencil-square-o c-pointer pl-1"
              title="Edit Area"
              aria-hidden="true"
              onClick={() => editArea(row.id)}
            ></i>
          )}
          {canDelete && (
            <i
              className="text-danger fas fa-trash c-pointer pl-1"
              title="Delete"
              onClick={() => deleteArea(row.id)}
            ></i>
          )}
        </>
      </div>
    </div>
  );
};

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Areas Configured!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
const columns = (
  deleteArea,
  editArea,
  assignMembers,
  canUpdate,
  canDelete,
  canAssignAreas
) => [
  {
    text: "AREA ID",
    dataField: "code",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "AREA NAME",
    dataField: "description",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "",
    dataField: "actions",
    isDummyField: true,
    headerClasses: "text-center text-nowrap bg-dark text-white px-sm-1 py-2",
    headerAttrs: {
      width: "100px",
    },
    formatter: (cell, row) => {
      return AreasActions(
        cell,
        row,
        deleteArea,
        editArea,
        assignMembers,
        canUpdate,
        canDelete,
        canAssignAreas
      );
    },
    csvExport: false,
  },
];

const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="AreasKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder="Area Search.."
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};

const RemotePagination = (
  data,
  deleteArea,
  editArea,
  assignMembers,
  isloading,
  canUpdate,
  canDelete,
  canAssignAreas
) => (
  <ToolkitProvider
    keyField="id"
    data={data}
    columns={columns(
      deleteArea,
      editArea,
      assignMembers,
      canUpdate,
      canDelete,
      canAssignAreas
    )}
    search
  >
    {(props) => (
      <div>
        <MySearch {...props.searchProps} />
        <BootstrapTable
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: (base) => ({
                ...base,
                background: "rgba(40, 45, 42, 0.19)",
              }),
            },
          })}
          noDataIndication={() => {
            return isloading === false ? <NoDataIndication /> : <Empty />;
          }}
          loading={isloading}
          {...props.baseProps}
        />

        <br />
      </div>
    )}
  </ToolkitProvider>
);

const AreasListTable = (props) => {
  const {
    listData,
    deleteArea,
    editArea,
    assignMembers,
    isloading,
    canUpdate,
    canDelete,
    canAssignAreas,
  } = props;
  return (
    <>
      {RemotePagination(
        listData,
        deleteArea,
        editArea,
        assignMembers,
        isloading,
        canUpdate,
        canDelete,
        canAssignAreas
      )}
    </>
  );
};

AreasListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

AreasListTable.defaultProps = {
  listData: [],
};

export default AreasListTable;
