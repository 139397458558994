import React, { Component } from "react";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";
import BootstrapTable from "react-bootstrap-table-next";
import Papa from "papaparse";
import NCEPTFileColumn from "./NCEPTFileColumn";
import NCEPTColumnsModal from "./NCEPTColumnsModal";
import { Collapse, Card, CardBody, Spinner, Row, Col } from "reactstrap";
import _ from "lodash";
class NCEPTUpload extends Component {
  constructor(props) {
    super(props);
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(20), (val, index) => year - index);
    this.state = {
      upLoadStatus: 0,
      progress: false,
      nceptfile: "",
      nceptMonth: "1",
      nceptYear: year.toString(),
      nceptdata: [],
      nceptguid: "",
      missingColumn: false,
      missingColumnData: [],
      nceptcardopen: true,
    };
    this.nceptuploadHandler = this.nceptuploadHandler.bind(this);
    this.nceptfileChangedHandler = this.nceptfileChangedHandler.bind(this);
    this.changeFile = this.changeFile.bind(this);
    this.nceptfile = {};
    this.nceptcolumns = [
      {
        text: "Error",
        dataField: "errorMessage",
        sort: true,
      },
      {
        text: "Field",
        dataField: "fieldName",
        sort: true,
      },
      {
        text: "Line Number",
        dataField: "lineNumber",
        sort: true,
      },
    ];
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.id]: evt.target.value,
    });
  };

  nceptuploadHandler = () => {
    // this.createUUID();
    $(".loading").show();
    $(".errorGridNcept").hide();
    const formData = new FormData();
    formData.append("File", this.nceptfile);
    formData.append("month", this.state.nceptMonth);
    formData.append("year", this.state.nceptYear);
    // formData.append("guid", this.state. nceptguid);

    axios
      .post("/api/dataimport/NCEPTDataFileUpload/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.status && res.data.status.toLowerCase() == "success") {
          toast.success("NCEPT-PPT imported successfully.");
          $(".loading").hide();
        } else {
          //$(".errorGridNcept").show();
          toast.error(res.data.message);
          //this.setState({ nceptdata: res.data.data });
          $(".loading").hide();
        }
      });
  };

  nceptfileChangedHandler = (event) => {
    //
    this.state.missingColumn = false;
    const file = event.target.files[0];
    if (file != undefined) {
      this.nceptfile = file;
      var fr = new FileReader();
      if (!/\.(csv)$/i.test(file.name)) {
        toast.error("Unsupported File Type.");
        this.setState({
          missingColumn: this.state.missingColumn,
          missingColumnData: [],
        });
        return false;
      }

      Papa.parse(file, {
        header: true,
        complete: this.changeFile,
      });
      this.setState({
        nceptfile: file.name,
        missingColumn: this.state.missingColumn,
        missingColumnData: this.state.missingColumnData,
      });
      fr.readAsDataURL(event.target.files[0]);
    }
  };
  changeFile(results) {
    let cColumns = results.meta.fields;
    this.state.missingColumn = true;
    this.state.missingColumnData = [];
    if (_.isArray(cColumns)) {
      NCEPTFileColumn.map((item) => {
        let idx = cColumns.findIndex(
          (x) => x.split(" ").join("") == item.split(" ").join("")
        );
        if (idx == -1) {
          this.state.missingColumnData.push(item);
        }
      });
    }

    console.log("Finalized", results);
    this.setState({
      missingColumn: this.state.missingColumn,
      missingColumnData: this.state.missingColumnData,
    });
  }

  render() {
    return (
      <>
        {/* <div className="page-title-box">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <h4 className="page-title">NCEPT-PPT</h4>
            </div>
          </div>
        </div> */}
        <Card>
          <h5
            className="card-header cursorPointer"
            onClick={() =>
              this.setState({ nceptcardopen: !this.state.nceptcardopen })
            }
          >
            <i className="fas fa-file" aria-hidden="true"></i> NCEPT-PPT
            <i
              className={`pull-right float-right ${
                this.state.nceptcardopen
                  ? "fas arrow-icon fa-chevron-down"
                  : "fas arrow-icon fa-chevron-up"
              }`}
            ></i>
          </h5>
          <Collapse isOpen={this.state.nceptcardopen}>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <span className="p-2">{this.state.nceptfile}</span>
                    <br />
                    <div className="row">
                      <div className="form-group col-xl-2 col-lg-3 col-md-4 pr-sm-2 pr-md-0 mr-md-0">
                        <input
                          className="d-none"
                          ref={(fileinput) => (this.fileinput = fileinput)}
                          type="file"
                          onChange={this.nceptfileChangedHandler}
                          name="nceptfile"
                          data-ref="0"
                        />

                        <button
                          className="w-100 mt-2 mb-2 btn btn-primary btn-md"
                          onClick={() => this.fileinput.click()}
                          style={{ fontSize: "90%" }}
                        >
                          Choose NCEPT File
                        </button>
                      </div>
                      <div className="form-group col-lg-2 col-md-3">
                        <select
                          className="browser-default custom-select"
                          id="nceptMonth"
                          onChange={this.handleChange}
                        >
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-2 col-md-3">
                        <select
                          className="browser-default custom-select"
                          id="nceptYear"
                          onChange={this.handleChange}
                        >
                          {this.years.map((year, index) => {
                            return (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group col-lg-2 col-md-2">
                        <button
                          className="w-100 btn btn-primary"
                          onClick={this.nceptuploadHandler}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                    <div className="col px-0">
                      <a
                        data-toggle="modal"
                        href=""
                        data-target="#NCEPTColumnsModal"
                      >
                        View NCEPT Columns
                      </a>
                    </div>
                    <div className="row mt-3 errorGridNcept dispNone">
                      <div className="col-12">
                        <h5 className="mt-3">Upload Result:</h5>
                        <p className="error">
                          File upload was not successful. Please fix the below
                          errors and upload the file again.
                        </p>
                      </div>
                      <div className="col">
                        <BootstrapTable
                          striped
                          hover
                          keyField="id"
                          data={this.state.nceptdata}
                          columns={this.nceptcolumns}
                        />
                      </div>
                    </div>
                    {this.state.missingColumn &&
                      !_.isEmpty(this.state.missingColumnData) && (
                        <div className="pt-3 px-0 col-12 mb-4">
                          <p className="h6 text-secondary font-weight-normal">
                            Following are the missing columns in this file:
                          </p>
                          <ol>
                            {this.state.missingColumnData.map((item) => (
                              <li>{item}</li>
                            ))}
                          </ol>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </Card>

        <NCEPTColumnsModal />
      </>
    );
  }
}
export default NCEPTUpload;
