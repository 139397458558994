import React, { Component } from "react";
import ActivityLoggingComponent from "../../../Shared/ActivityLogging";
import actionActivity from "../../../../store/activitylogging/action";
import _ from "lodash";
import { connect } from "react-redux";
import AuditModuleTypes from "../../../Shared/AuditModuleTypes";

import { Card, CardBody, Collapse } from "reactstrap";
export class UserActivityLogging extends Component {
  state = {
    logRecordId: 0,
  };
  fetchLogsForMember = () => {
    /// ActivityLogging
    var show =
      this.props.activityLoggingState &&
      this.props.activityLoggingState.memberLoggingVisible;
    const { id } = this.props.paramId;
    if (!_.isEmpty(id)) {
      this.props.toggleMemberLogAccordion(
        !this.props.activityLoggingState.memberLoggingVisible
      );
      this.setState({ logRecordId: id });
      !show &&
        this.props.getActicityLoggingByModuleId(
          AuditModuleTypes.Member,
          id,
          0,
          10
        );
    }
  };
  render() {
    var IsLoading =
      this.props.activityLoggingState &&
      this.props.activityLoggingState.IsLoading;

    return (
      <>
        {/* ActivityLogging Work */}
        <Card>
          {/* Card Header */}
          {!IsLoading && (
            <>
              <h5
                className="card-header cursorPointer"
                onClick={this.fetchLogsForMember}
              >
                <i class="fa fa-history" aria-hidden="true"></i> Activity
                Logging
                <i
                  className={`pull-right float-right ${
                    this.props.activityLoggingState.memberLoggingVisible
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }`}
                ></i>
              </h5>
            </>
          )}
          {IsLoading && (
            <>
              <h5 className="card-header cursorPointer">
                <i class="fa fa-history" aria-hidden="true"></i> Loading....
                <i
                  className={`pull-right float-right ${
                    this.props.activityLoggingState.memberLoggingVisible
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }`}
                ></i>
              </h5>
            </>
          )}
          <Collapse
            className="memberCardCollapse"
            // style={{ transition: "height 2s ease" }}
            isOpen={this.props.activityLoggingState.memberLoggingVisible}
          >
            <CardBody className="pl-1 pr-2">
              <ActivityLoggingComponent recordId={this.state.logRecordId} />
            </CardBody>
          </Collapse>
        </Card>
        {/* ActivityLogging Work */}
      </>
    );
  }
}

//export default EditUser;
export default connect(
  (state) => ({
    activityLoggingState: state.activityLogging,
  }),
  { ...actionActivity }
)(UserActivityLogging);
