import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import TableCustomTotal from '../Shared/TableCustomTotal';
const { SearchBar } = Search;


class PositionsLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalCount: "",
            page: 1,
            sizePerPage: 10,
            positionMethods: this.props.positionMethods
        };
        this.columns = [
            {
                text: "Position Name",
                dataField: "name",
                sort: true
            },
            {
                text: "Scope",
                dataField: "scopeType",
                sort: true
            }
        ];
        this.handleFormSearch = this.handleFormSearch.bind(this);
        this.rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.state.positionMethods().setPostion(row);
            }
        };
        this.RemotePagination = (data, page, sizePerPage, onTableChange, totalSize, showTotal, rowEvents) => (
            <div>
                <BootstrapTable
                    remote
                    keyField="id"
                    data={data}
                    columns={this.columns}
                    pagination={paginationFactory({ paginationTotalRenderer: TableCustomTotal, page, sizePerPage, totalSize, showTotal })}
                    onTableChange={onTableChange}
                    rowEvents={rowEvents}
                />
            </div>
        );
    }
    handleTableChange = (type, { sortField, sortOrder, data, page, sizePerPage }) => {
        let url = `/api/Position/GetPositions`;
        if (type === "sort") {
            this.state.SortOrder = sortField + sortOrder;
            this.setState({ SortOrder: this.state.SortOrder });
        }
        const formData = {
            Name: $(".positions #keyword").val(),
            PageNo: page,
            PageSize: sizePerPage,
            SortOrder: this.state.SortOrder,
            ScopeType: "",
            Facility: "",
            Region: "",
            Area: "",
            Role: "",
            OperatorType: "AND"
        };

        $(".loading").show();

        axios.post(url, formData).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({
                    data: data.data,
                    totalCount: data.totalCount,
                    page,
                    sizePerPage
                });
                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    };
    handleFormSearch(e) {
        e.preventDefault();
        $(".loading").show();
        let url = `/api/Position/GetPositions`;
        const formData = {
            Name: $(".positions #keyword").val(),
            PageNo: 1,
            PageSize: 10,
            SortOrder: "NameAsc",
            ScopeType: "",
            Facility: "",
            Region: "",
            Area: "",
            Role: "",
            OperatorType: "AND"
        };

        axios.post(url, formData).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({
                    data: data.data,
                    totalCount: data.totalCount,
                    page: 1
                });
                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    }
    componentWillMount() {
        $(".loading").show();
        const url = `/api/Position/GetPositions`;
        const formData = {
            PageNo: 1,
            PageSize: 10,
            Name: $(".positions #keyword").val(),
            ScopeType: "",
            Facility: "",
            Region: "",
            Area: "",
            Role: "",
            OperatorType: "AND",
            SortOrder: "NameAsc"
        };
        axios.post(url, formData).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({ data: data.data, totalCount: data.totalCount });
                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    }
    render() {
        const { data, sizePerPage, page, totalCount } = this.state;
        const showTotal = true;
        return (
            <AUX>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">Positions</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <ToolkitProvider keyField="id" data={this.state.rows} columns={this.columns} >
                                {props => (
                                    <Fragment>
                                        <div className="d-flex justify-content-center mb-2">
                                            <div className="col-6 text-center app-search">
                                                <form role="search" className="app-search mt-0 positions" onSubmit={this.handleFormSearch}>
                                                    <div className="form-group mb-0">
                                                        <input
                                                            type="text"
                                                            id="keyword"
                                                            className="form-control mb-1 w-100"
                                                            placeholder="Search.."
                                                        />
                                                        <button type="submit">
                                                            <i className="fa fa-search" />
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {this.RemotePagination(
                                                    data,
                                                    page,
                                                    sizePerPage,
                                                    this.handleTableChange,
                                                    totalCount,
                                                    showTotal,
                                                    this.rowEvents
                                                )}
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default PositionsLookup;
