import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import advanceFilterActions from "../../../../store/advancedfilter/action";
import fetrackerActions from "../../../../store/feTracker/action";
import { bindActionCreators } from "redux";

class FE_Budgets extends Component {
  onChange(item) {
    const actions = this.props.isMemberAF
      ? this.props.advanceFilterActions
      : this.props.fetrackerActions;

    const reduxState = this.props.isMemberAF
      ? this.props.filterState
      : this.props.festate;

    if (this.props.isMemberAF && this.props.isInventoryTab) {
      var budgetlines = reduxState.filterData.fe_inventory_budgetlines; // this is for members advance filter
    } else if (!this.props.isMemberAF && this.props.isInventoryTab) {
      // FE Module's Equipment's Tab
      var budgetlines = reduxState.filterData.fe_equipments_budgetlines;
    } else {
      // FE Module's Request's Tab
      var budgetlines = reduxState.filterData.fe_request_budgetlines;
    }
    if (reduxState) {
      var index = _.findIndex(budgetlines, (o) => {
        return o.budgetline === item.budgetline;
      });
      item.checked = !budgetlines[index].checked;
      budgetlines[index] = item;
      actions.setFilterData({
        ...reduxState.filterData,
      });
    }
  }
  chechboxesV2(item, mySelf) {
    return (
      <div className="form-group checkboxSp">
        <div className="custom-control custom-checkbox ">
          <input
            className="custom-control-input"
            type="checkbox"
            id={"febliitem-" + item.budgetline}
            name={"febliitem-" + item.budgetline}
            htmlFor={"febliitem-" + item.budgetline}
            value={item.id}
            checked={item.checked}
            onChange={() => this.onChange(item)}
            aria-label="..."
          />
          <label
            className="custom-control-label"
            htmlFor={"febliitem-" + item.budgetline}
            style={{ color: this.props.isMobile ? "white" : null }}
          >
            {item.budgetline}

            {mySelf.props.isMemberAF && (
              <small className="totalCount">{`(${item.total})`}</small>
            )}
          </label>
        </div>
      </div>
    );
  }
  chechboxes(item, mySelf) {
    return (
      <li class="chechbox-list_item" key={"msliitem-" + item.budgetline}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"febliitem-" + item.budgetline}
        >
          <input
            type="checkbox"
            htmlFor={"febliitem-" + item.budgetline}
            value={item.budgetline}
            checked={item.checked}
            className="custom-control-input"
            id={"febliitem-" + item.budgetline}
            name={"febliitem-" + item.budgetline}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            htmlFor={"febliitem-" + item.budgetline}
            style={{ color: this.props.isMobile ? "white" : null }}
          >
            {item.budgetline}

            {mySelf.props.isMemberAF && (
              <small className="totalCount">{`(${item.total})`}</small>
            )}
          </label>
        </span>
      </li>
    );
  }

  render() {
    const reduxState = this.props.isMemberAF
      ? this.props.filterState
      : this.props.festate;

    if (this.props.isMemberAF && this.props.isInventoryTab) {
      var budgetlines = reduxState.filterData.fe_inventory_budgetlines;
    } else if (!this.props.isMemberAF && this.props.isInventoryTab) {
      // FE Module's Equipment's fe_equipments_budgetlines
      var budgetlines = reduxState.filterData.fe_equipments_budgetlines;
    } else {
      // FE Module's Request's Tab
      var budgetlines = reduxState.filterData.fe_request_budgetlines;
    }
    const scrollClass = this.props.isMobile
      ? "fe-filter--scroll-mobile"
      : "fe-filter--scroll";
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Budget Lines</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className={scrollClass}>
          {budgetlines &&
            !_.isEmpty(reduxState) &&
            budgetlines.map((item) => this.chechboxesV2(item, this))}
          {_.isEmpty(budgetlines) && "No filters available"}
        </div>

        <div className="height-scroll" />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    festate: state.fetracker,
    filterState: state.advancedFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetrackerActions: bindActionCreators(fetrackerActions, dispatch),
    advanceFilterActions: bindActionCreators(advanceFilterActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FE_Budgets);
