import TypesLookup from "./TypeLookup";
import AUX from "../../hoc/Aux_";
import React, { Component } from "react";

class TypeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeMethods: this.props.typeMethods
        };
    }
    render() {
        return (
            <AUX>
                <div className="modal fade type-modal z-index9999" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Select Type</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                                    ×
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 mb-4">
                                    <TypesLookup label={this.props.label} typeMethods={this.state.typeMethods} type={this.props.type} />
                                </div>
                                <div className="col-12 text-center mb-50 mt-50 pt-4">
                                    <button
                                        className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2"
                                        data-dismiss="modal"
                                        aria-hidden="true">
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default TypeModal;
