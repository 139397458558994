import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";

const actions = {
  SET_FACILITY_ACTIVE_TAB: "SET_FACILITY_ACTIVE_TAB",
  SET_FACILITY_FILE_TAB: "SET_FACILITY_FILE_TAB",

  SET_FACILITY_CARDS_VISIBILITY: "SET_FACILITY_CARDS_VISIBILITY",
  SET_FACILITY_MEMBERS_LOADING: "SET_FACILITY_MEMBERS_LOADING",
  ADD_FACILITY_PHY_ADDRESS: "ADD_FACILITY_PHY_ADDRESS",
  REMOVE_FACILITY_PHY_ADDRESS: "REMOVE_FACILITY_PHY_ADDRESS",
  ADD_FACILITY_MAIL_ADDRESS: "ADD_FACILITY_MAIL_ADDRESS",
  REMOVE_FACILITY_MAIL_ADDRESS: "REMOVE_FACILITY_MAIL_ADDRESS",
  ADD_FACILITY_PHONE_NO: "ADD_FACILITY_PHONE_NO",
  REMOVE_FACILITY_PHONE_NO: "REMOVE_FACILITY_PHONE_NO",
  SET_FACILTY_PHY_ADDRESS: "SET_FACILTY_PHY_ADDRESS",
  SET_FACILTY_MAILING_ADDRESS: "SET_FACILTY_MAILING_ADDRESS",

  SAVE_FACILTY_PHY_ADDRESS: "SAVE_FACILTY_PHY_ADDRESS",
  SAVED_SUCCESSFULLY: "SAVED_SUCCESSFULLY",
  DELETED_SUCCESSFULLY: "DELETED_SUCCESSFULLY",

  SET_FACILITY_INFO_CARD: "SET_FACILITY_INFO_CARD",
  SET_FACILITY_PHONE_NUMBERS: "SET_FACILITY_PHONE_NUMBERS",

  RESET_FACILITY_PHONE_NUMBERS: "RESET_FACILITY_PHONE_NUMBERS",
  RESET_FACILTY_PHY_ADDRESS: "RESET_FACILTY_PHY_ADDRESS",
  RESET_FACILTY_MAILING_ADDRESS: "RESET_FACILTY_MAILING_ADDRESS",

  SET_FACILITY_TYPE_OPTIONS: "SET_FACILITY_TYPE_OPTIONS",
  SET_FACILITY_REGIONS_OPTIONS: "SET_FACILITY_REGIONS_OPTIONS",

  SET_FACILITY_OPTIONS: "SET_FACILITY_OPTIONS",
  SET_FACILITY_FILES_LOADING: "SET_FACILITY_FILES_LOADING",
  SET_FACILITY_FILES_TREE_DATA: "SET_FACILITY_FILES_TREE_DATA",

  SET_VFR_MAP_KEY: "SET_VFR_MAP_KEY",
  SET_FACILITY_FILE_UPLOADING: "SET_FACILITY_FILE_UPLOADING",
  SET_STARTUP_FACILITY_ACTIVE: "SET_STARTUP_FACILITY_ACTIVE",

  DELETE_OSHA_FILE: "DELETE_OSHA_FILE",

  SET_OSHA_ATTACHMENTS_LOADING: "SET_OSHA_ATTACHMENTS_LOADING",

  SET_OSHA_ATTACHMENTS_FILES: "SET_OSHA_ATTACHMENTS_FILES",

  SLICE_OSHA_ATTACHMENTS_FILES: "SLICE_OSHA_ATTACHMENTS_FILES",

  SET_FACILITY_SELECTED_MAPS: "SET_FACILITY_SELECTED_MAPS",

  ADD_SELECTED_MAPS: "ADD_SELECTED_MAPS",
  REMOVE_SELECTED_MAPS: "REMOVE_SELECTED_MAPS",

  IS_ACCORDION_LOADING: "IS_ACCORDION_LOADING",
  SHOW_OFFICER_MODAL: "SHOW_OFFICER_MODAL",
  SHOW_PENDING_REQUEST_ALERT: "SHOW_PENDING_REQUEST_ALERT",

  setFacilityActiveTab: (name) => async (dispatch) => {
    await dispatch({
      type: actions.SET_FACILITY_ACTIVE_TAB,
      payload: name,
    });
  },
  showOfficerModal: (val) => (dispatch) => {
    dispatch({
      type: actions.SHOW_OFFICER_MODAL,
      payload: val,
    });
  },
  showPendingRequestAlert: (val) => (dispatch) => {
    dispatch({
      type: actions.SHOW_PENDING_REQUEST_ALERT,
      payload: val,
    });
  },

  setFacilityActiveFileTab: (name) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILITY_FILE_TAB,
      payload: name,
    });
  },

  setFacilityCardsVisibility: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILITY_CARDS_VISIBILITY,
      payload: val,
    });
  },
  setStartupFacilityActive: () => (dispatch) => {
    dispatch({
      type: actions.SET_STARTUP_FACILITY_ACTIVE,
    });
  },
  setFacilityMembersLoading: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILITY_MEMBERS_LOADING,
      payload: val,
    });
  },
  addFacilityPhyAddress: () => (dispatch) => {
    dispatch({
      type: actions.ADD_FACILITY_PHY_ADDRESS,
    });
  },
  removeFacilityPhyAddress: (id) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_FACILITY_PHY_ADDRESS,
      payload: id,
    });
  },
  addFacilityMailAddress: () => (dispatch) => {
    dispatch({
      type: actions.ADD_FACILITY_MAIL_ADDRESS,
    });
  },
  removeFacilityMailAddress: (id) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_FACILITY_MAIL_ADDRESS,
      payload: id,
    });
  },
  addFacilityPhoneNo: () => (dispatch) => {
    dispatch({
      type: actions.ADD_FACILITY_PHONE_NO,
    });
  },
  removeFacilityPhoneNo: (id) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_FACILITY_PHONE_NO,
      payload: id,
    });
  },
  setFacilityPhyAddress: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILTY_PHY_ADDRESS,
      payload: val,
    });
  },

  setFacilityMailingAddress: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILTY_MAILING_ADDRESS,
      payload: val,
    });
  },
  saveFacilityAddress: (body) => (dispatch) => {
    //debugger
    $(".loading").show();
    axios.post("/api/Facilities/AddAddresses", body).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        toast.success(" Updated Successfuly.");
        dispatch({
          type: actions.SAVED_SUCCESSFULLY,
        });

        let isPhysical = data.result.filter((el) => {
          return el.addresstypeid == 4;
        });

        if (isPhysical.length > 0) {
          dispatch({
            type: actions.SET_FACILTY_PHY_ADDRESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: actions.SET_FACILTY_MAILING_ADDRESS,
            payload: data.result,
          });
        }

        $(".loading").hide();
      } else {
        // console.log(data);
        $(".loading").hide();
      }
    });
  },
  deleteFacilityFile: (formData) => (dispatch) => {
    //debugger
    dispatch({
      type: actions.SET_FACILITY_FILES_LOADING,
      payload: true,
    });
    axios
      .post("/api/FacilityFiles/RemoveFacilityFile", formData)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          dispatch({
            type: actions.SET_FACILITY_FILES_TREE_DATA,
            payload: data.treeResult.value,
          });

          dispatch({
            type: actions.SET_FACILITY_FILES_LOADING,
            payload: false,
          });
          toast.success(" Deleted Successfuly.");
        } else {
          toast.error(data.message);
          dispatch({
            type: actions.SET_FACILITY_FILES_LOADING,
            payload: false,
          });
        }
      });
  },
  setFacilityPhoneNumbers: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILITY_PHONE_NUMBERS,
      payload: val,
    });
  },

  saveFacilityPhone: (body) => (dispatch) => {
    // debugger
    $(".loading").show();
    axios.post("/api/Facilities/AddPhoneNumners", body).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        toast.success(" Updated Successfuly.");
        dispatch({
          type: actions.SAVED_SUCCESSFULLY,
        });

        dispatch({
          type: actions.SET_FACILITY_PHONE_NUMBERS,
          payload: data.result,
        });

        $(".loading").hide();
      } else {
        // console.log(data);
        $(".loading").hide();
      }
    });
  },

  resetPhoneNumbers: () => (dispatch) => {
    dispatch({
      type: actions.RESET_FACILITY_PHONE_NUMBERS,
    });
  },
  resetPhysicalAddresses: () => (dispatch) => {
    dispatch({
      type: actions.RESET_FACILTY_PHY_ADDRESS,
    });
  },
  resetMailingAddresses: () => (dispatch) => {
    dispatch({
      type: actions.RESET_FACILTY_MAILING_ADDRESS,
    });
  },

  setVFRMapKey: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_VFR_MAP_KEY,
      payload: val,
    });
  },

  getFacilityTypeOptions: () => (dispatch) => {
    $(".loading").show();
    axios.get("/api/Systemdata/FacilityType").then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        dispatch({
          type: actions.SET_FACILITY_TYPE_OPTIONS,
          payload: data.data,
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  },
  getFacilityRegionsOptions: () => (dispatch) => {
    $(".loading").show();

    axios.get("/api/Region/FetchRegionsDropDownList").then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        dispatch({
          type: actions.SET_FACILITY_REGIONS_OPTIONS,
          payload: data.data,
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  },
  getFacilityOptions: () => (dispatch) => {
    axios.get("/api/Facilities?isTable=true").then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        dispatch({
          type: actions.SET_FACILITY_OPTIONS,
          payload: data.data,
        });
      } else {
        toast.error(data.message);
      }
    });
  },
  getFacilityFiles: (facilityid) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILITY_FILES_LOADING,
      payload: true,
    });

    axios
      .get(`/api/FacilityFiles/FetchFilesTree?facilityid=${facilityid}`)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          //  debugger

          dispatch({
            type: actions.SET_FACILITY_FILES_TREE_DATA,
            payload: data,
          });
          // console.log('receive the data for facility files');
        } else {
          dispatch({
            type: actions.SET_FACILITY_FILES_LOADING,
            payload: false,
          });
          toast.error(data.message);
        }
      });
  },

  uploadfacilityfile: (formdata) => (dispatch) => {
    dispatch({
      type: actions.SET_FACILITY_FILE_UPLOADING,
      payload: true,
    });
    $(".loading").show();

    axios
      .post("/api/FacilityFiles/UploadFacilityFile", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        dispatch({
          type: actions.SET_FACILITY_FILE_UPLOADING,
          payload: false,
        });
        //  debugger
        let facilityid = JSON.parse(formdata.get("data")).facilityid;
        var action = actions.getFacilityFiles(facilityid);
        dispatch(action);
        $(".loading").hide();
      })
      .catch(function(error) {
        $(".loading").hide();
        console.log(error);
      });
  },

  getOSHAAttachments: (self, folderId) => (dispatch) => {
    dispatch({
      type: actions.SET_OSHA_ATTACHMENTS_LOADING,
      payload: true,
    });

    axios
      .get(`/api/OSHA/FetchOSHAAttachments/${folderId}`, {})
      .then(({ data }) => {
        debugger;
        if (data.status && data.status.toLowerCase() == "success") {
          dispatch({
            type: actions.SET_OSHA_ATTACHMENTS_LOADING,
            payload: false,
          });

          dispatch({
            type: actions.SET_OSHA_ATTACHMENTS_FILES,
            payload: data,
          });

          self.toggleModal();
        } else {
          dispatch({
            type: actions.SET_OSHA_ATTACHMENTS_LOADING,
            payload: false,
          });
          dispatch({
            type: actions.SET_OSHA_ATTACHMENTS_FILES,
            payload: data,
          });
          self.toggleModal();
          toast.error(data.message);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },

  sliceImageFromAttachment: (id) => (dispatch) => {
    dispatch({
      type: actions.SLICE_OSHA_ATTACHMENTS_FILES,
      payload: id,
    });
  },

  deleteOSHAFile: (mySelf, filepath) => (dispatch) => {
    //debugger
    dispatch({
      type: actions.DELETE_OSHA_FILE,
      payload: true,
    });
    let currentFacility = mySelf.props.facilityId;
    axios
      .post(
        `/api/OSHA/RemoveFileFromDirectory?filePath=${filepath}&facilityId=${currentFacility}`
      )
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          mySelf.setState({ currentImage: -1 });
          mySelf.setState({ NonImageFile: "/" });
          mySelf.toggleConfirmModal();

          const allrefs = mySelf.refs;
          const lightBox = allrefs.GallaryLightBox;

          lightBox.closeLightbox();

          var action = actions.sliceImageFromAttachment(filepath);
          dispatch(action);

          toast.success(" Deleted Successfuly.");
        } else {
          toast.error(data.message);
        }
      });
  },

  forcelyCloseModal: (modalId) => (dispatch) => {
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    $(`${modalId}`).click();
  },

  getSelectedMapsForFacility: (id) => (dispatch) => {
    dispatch({
      type: actions.IS_ACCORDION_LOADING,
      payload: true,
    });

    axios.get(`/api/Facilities/GetSelectedMapIds/${id}`).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        dispatch({
          type: actions.SET_FACILITY_SELECTED_MAPS,
          payload: data.result,
        });

        dispatch({
          type: actions.IS_ACCORDION_LOADING,
          payload: false,
        });
      } else {
        dispatch({
          type: actions.SET_FACILITY_SELECTED_MAPS,
          payload: [],
        });
        dispatch({
          type: actions.IS_ACCORDION_LOADING,
          payload: false,
        });
        toast.error(data.message);
      }
    });
  },

  saveSelectedMapsForFacility: (ids, facilityId) => (dispatch) => {
    let body = {
      selectedIds: ids,
      facilityId,
    };

    axios.post(`/api/Facilities/SaveSelectedMapIds`, body).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        dispatch({
          type: actions.SET_FACILITY_SELECTED_MAPS,
          payload: data.result,
        });
      } else {
        toast.error(data.message);
      }
    });
  },

  addSelectedMapfacilities: (values) => (dispatch) => {
    dispatch({
      type: actions.ADD_SELECTED_MAPS,
      payload: values,
    });
  },

  removeSelectedMapfacilities: (idx, mapid, facilityid) => (dispatch) => {
    //debugger;
    dispatch({
      type: actions.REMOVE_SELECTED_MAPS,
      payload: idx,
    });

    axios
      .get(`/api/Facilities/RemoveFacilityMapRow/${mapid}/${facilityid}`)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
        } else {
          toast.error(data.message);
        }
      });
  },

  setAccordionLoading: (val) => (dispatch) => {
    dispatch({
      type: actions.IS_ACCORDION_LOADING,
      payload: val,
    });
  },
  removeFacilityAddress: (body) => (dispatch) => {
    //debugger
    $(".loading").show();
    axios.post("/api/Facilities/RemoveAddresses", body).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        toast.success(" Deleted Successfuly.");
        $(".cancelModal").trigger("click");

        dispatch({
          type: actions.DELETED_SUCCESSFULLY,
        });
        $(".loading").hide();
      } else {
        toast.error(data.message);
        $(".loading").hide();
      }
    });
  },

  deleteFacilityPhoneNo: (body) => (dispatch) => {
    //  debugger
    $(".loading").show();
    axios.post("/api/Facilities/RemovePhoneNumners", body).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        toast.success(" Deleted Successfuly.");
        $(".cancelModal").trigger("click");

        dispatch({
          type: actions.DELETED_SUCCESSFULLY,
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  },
};

export default actions;
