//These are used in Advance filter of FE Screens

const FE_AF_Nav_Items = Object.freeze({
  Status: 1,
  Budget: 2,
  BookValueRequest: 3,
  Type: 4,
  Description: 5,
  Year: 6,
  ActualCost: 7,
  PurchaseDate: 8,
  StickerNo: 9,
  BookValueInventory: 10,

  FacilityInventory: 11,
  RegionInventory: 12,
  TeamInventory: 13,
  UserInventory: 14,

  FacilityRequest: 15,
  RegionRequest: 16,
  TeamRequest: 17,
  UserRequest: 18,
  NAOffice: 19,
});
export default FE_AF_Nav_Items;
