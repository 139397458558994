import React, { Component } from "react";
import InputRange from "react-input-range";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { store } from "../../../../../src/index";
import _ from "lodash";
const onFromToInputChange = (event, newValue, previousValue, name, props) => {
  let formName = "";
  if (props.isMemberAF) {
    formName = "fe_mem_bookValsForm";
  } else if (props.isFERequests) {
    formName = "fe_req_bookValsForm";
  } else if (props.isFEInventoryTab) {
    formName = "fe_Inv_bookValsForm";
  }
  if (name === "FromVal") {
    store.dispatch(change(formName, "BookValue[min]", Number(newValue)));
  } else if (name === "ToVal") {
    store.dispatch(change(formName, "BookValue[max]", Number(newValue)));
  }
};

const onSliderInputChange = (event, newValue, previousValue, name, props) => {
  const { min, max } = newValue;
  let formName = "";
  if (props.isMemberAF) {
    formName = "fe_mem_bookValsForm";
  } else if (props.isFERequests) {
    formName = "fe_req_bookValsForm";
  } else if (props.isFEInventoryTab) {
    formName = "fe_Inv_bookValsForm";
  }
  store.dispatch(change(formName, "FromVal", min));
  store.dispatch(change(formName, "ToVal", max));
};
export class FE_BookVals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { min: 1, max: 50000 },
    };
  }
  componentDidMount() {
    // Initialize the form with your initial values here
    const { savedSearchCriteria } = this.props.savedSearchState;

    if (
      this.props.formId == "fe_mem_bookValsForm" &&
      savedSearchCriteria &&
      savedSearchCriteria.savedSearchCriteriaDetail &&
      !_.isEmpty(savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria)
    ) {
      const { FromBookValue = null, ToBookValue = null } =
        savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
          .fe_inv_book_vals || {};
      if (FromBookValue != null) {
        this.props.dispatch(
          change("fe_mem_bookValsForm", "FromBookValue", FromBookValue)
        );
      } else {
        this.props.dispatch(
          change("fe_mem_bookValsForm", "FromBookValue", null)
        );
      }
      if (ToBookValue != null) {
        this.props.dispatch(
          change("fe_mem_bookValsForm", "ToBookValue", ToBookValue)
        );
      } else {
        this.props.dispatch(change("fe_mem_bookValsForm", "ToBookValue", null));
      }
    }
  }
  renderInputText = ({
    input,
    label,
    classForm,
    type,
    meta,
    name,
    placeholder,
  }) => {
    //console.log(meta)
    // console.log(label);
    return (
      <div className="form-group">
        {label !== "" && !this.props.isMobile ? (
          <label htmlFor={name}>{label} </label>
        ) : placeholder == "Value" ? (
          ""
        ) : (
          <label></label>
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          // style={
          //   (placeholder === "Value" ? { width: "auto" } : null,
          //   { color: this.props.isMobile ? "white" : null })
          // }
          style={placeholder === "Value" ? { width: "auto" } : null}
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <div class="mb-3">
            <label>Min </label>
            <label style={{ float: "right" }}>Max</label>
          </div>
        ) : (
          <label> </label>
        )}
        <div class="range-slider equipmentRange">
          <InputRange
            formatLabel={(value) => `$${value}`}
            maxValue={50000}
            minValue={1}
            value={input.value}
            onChange={input.onChange}
          />
        </div>
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    // console.log(formValues);
  };
  validateNumber = (value) => {
    if (value && isNaN(Number(value))) {
      return "Please enter numeric characters only.";
    } else {
      return undefined;
    }
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Book Value</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}
        <form {...this.props} onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2 ad-range-box">
              <Field
                name="BookValue"
                id="BookValue"
                classForm="form-control"
                component={this.renderInput}
                labelFor="BookValue"
                onChange={(e, n, p, na) =>
                  onSliderInputChange(e, n, p, na, this.props)
                }
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }  `}
            >
              <Field
                name="FromVal"
                validate={[this.validateNumber]}
                id="FromVal"
                classForm="form-control"
                placeholder="From"
                label="From"
                component={this.renderInputText}
                labelFor="FromVal"
                onChange={(e, n, p, na) =>
                  onFromToInputChange(e, n, p, na, this.props)
                }
              />
            </Col>
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }  `}
            >
              <Field
                name="ToVal"
                id="ToVal"
                validate={[this.validateNumber]}
                classForm="form-control"
                placeholder="To"
                label="To"
                component={this.renderInputText}
                labelFor="ToVal"
                onChange={(e, n, p, na) =>
                  onFromToInputChange(e, n, p, na, this.props)
                }
              />
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  // form: "fe_req_bookValsForm",
  destroyOnUnmount: false,
  initialValues: {
    BookValue: { min: 1, max: 50000 },
  },
})(FE_BookVals);

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.formId,
  formValues: state.form,
  currentState: state,
  filterState: state.advancedFilter,
  savedSearchState: state.saveSearch,
});

export default connect(mapStateToProps, {})(formWrapped);
