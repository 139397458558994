import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/feTracker/action";
import { reset, getFormValues, isInvalid } from "redux-form";
import { toast } from "react-toastify";
import FE_Tabs from "../FE_Tabs";
import FE_AF_Nav_Items from "./FE_AF_Nav_Items";
import $ from "jquery";
//Here is the code snippet from
// src\modules\F&E\AdvanceFilter\TagsButtons.js:
//Contains buttons of Apply filter and Reset Mini
export class TagsButtons extends Component {
  applyFilter() {
    const currentTab = this.props.festate.activeTab;
    if (currentTab !== 0 && this.checkValidApply(currentTab)) {
      // this.setFormValues(currentTab);
      this.props.setAFTabFormsById(currentTab);
      this.props.setFilterData({
        ...this.props.festate.filterData,
      });
      var apply = {
        searchType: +this.props.festate.activeTab,
        isApplied: true,
        isSearched: false,
      };
      let filterItems = this.getSelectedOptions(currentTab);

      apply.selectedItems = filterItems;

      if (_.isEmpty(this.props.getSearchAppliedList())) {
        this.props.setSearchAppliedList([]);
      }

      var applyIndex = _.findIndex(this.props.getSearchAppliedList(), (o) => {
        return o.searchType === apply.searchType;
      });
      if (applyIndex === -1) {
        this.props.getSearchAppliedList().push(apply);
      } else {
        this.props.getSearchAppliedList()[applyIndex] = apply;
      }
      this.props.setFilterData({
        ...this.props.festate.filterData,
      });
      this.props.festate.filterData = Object.assign(
        {},
        this.props.festate.filterData,
        {
          activeTab: currentTab,
        }
      );
      //console.log(this.props.festate.filterData);
      this.props.setCurrentAFActiveTab(currentTab);

      this.onReturnNo();
    }
    this.props.handleTagsVisibility();
    if (window.innerWidth <= 897) {
      this.resize();
    }
  }
  resize() {
    let currentHideNav = window.innerWidth >= 897; /// isDesktop
    if (currentHideNav !== this.props.festate.showWebSideBar) {
      this.props.showWebSideBar(currentHideNav);
      this.props.showMobileSideBar(false);
    } else if (window.innerWidth <= 897) {
      this.props.showMobileSideBar(true);
      this.props.showWebSideBar(false);
    }
    let vh = 0;
    let tagsScroll = 0;
    if (window.innerHeight > window.innerWidth) {
      ///Potrait Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.018}px`
      );
      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01 - 2}px`
      );
    } else if (window.innerHeight < window.innerWidth) {
      ///Landscape Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01}px`
      );
    }
    let bodyHeight = $(".modal-body").height() * 0.01;
    document.documentElement.style.setProperty(
      "--bodyHeight",
      `${bodyHeight}px`
    );
  }
  isEmpty = (obj) => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };
  getSelectedOptions = (currentTabKey) => {
    let fdata = this.props.festate.filterData;

    let selectedVals = Object.keys(fdata)
      .filter((it) => it === this.getCurrentTabIdByName(currentTabKey))
      .reduce((obj, key) => {
        ///This condition is for Checkbox and Array controls
        if (+fdata[key] !== 0 && fdata[key].constructor === Array) {
          if (
            fdata[key] &&
            +fdata[key] !== 0 &&
            fdata[key].some(
              (s) => typeof s !== "undefined" && s.constructor === Object
            )
          ) {
            obj[key] = fdata[key]
              .filter((it) => it.checked)
              .map((m) => Object.values(m).find((t) => typeof t === "string"));

            return Object.values(obj);
          } else if (fdata[key].some((s) => typeof s === "string")) {
            return Object.values(fdata[key]); // Handles Array Redux for Years Form
          }
        } else if (
          +fdata[key] !== 0 &&
          fdata[key].constructor === Object &&
          typeof fdata[key] !== "string"
        ) {
          obj = fdata[key];

          let selectedObjects = Object.keys(obj).map((item) => {
            if (obj[item] instanceof Date) {
              let dateObj = {};

              dateObj[`${item}`] = obj[item].toLocaleDateString();

              return dateObj;
            } else if (
              obj[item] &&
              +obj[item] !== 0 &&
              obj[item].constructor === Array &&
              obj[item].some((it) => it.checked)
            ) {
              return obj[item]
                .filter((it) => it.checked)
                .map((m) =>
                  Object.values(m).find((t) => typeof t === "string")
                );
            } else if (
              obj[item] &&
              +obj[item] !== 0 &&
              obj[item].constructor === Object
            ) {
              return Object.keys(obj).map((key) => `${key} : ${obj[key]}`);
            } else if (
              obj[item] &&
              +obj[item] !== 0 &&
              typeof obj[item] === "string"
            ) {
              let constainer = {};

              constainer[`${item}`] = obj[item];

              return Object.keys(constainer).map(
                (key) => `${key} : ${obj[key]}`
              );
            }
            // else if (obj[item] && +obj[item] !== 0) {

            //   return Object.keys(obj).map((key) => `${key} : ${obj[key]}`);
            // }
          });
          selectedObjects = selectedObjects.filter(
            (f) => typeof f !== "undefined"
          );

          if (
            selectedObjects.length > 0 &&
            selectedObjects.some(
              (f) => f.constructor === Object || f.constructor === Array
            )
          ) {
            let result = selectedObjects.map((item) => {
              return (item = Object.keys(item).map((key) => {
                if (!isNaN(key)) {
                  return item[key];
                } else {
                  return `${key} : ${item[key]}`;
                }
              }));
            });

            return result;
          } else {
            return Object.keys(obj).map((key) => `${key} : ${obj[key]}`);
          }
        }
        ///This condition is for textfield controls like name, description
        else if (typeof fdata[key] === "string") {
          let constainer = {};

          constainer[`${key}`] = fdata[key];
          obj = constainer;

          return Object.keys(obj).map((key) => `${obj[key]}`);
        }
      }, {});

    selectedVals =
      selectedVals && _.size(selectedVals)
        ? selectedVals.filter((f) => typeof f !== "undefined")
        : [];
    let result = [].concat.apply([], selectedVals);
    if (selectedVals.some((s) => s.includes("From") || s.includes("To"))) {
      // Range Fields
      if (
        selectedVals.some((s) => s.includes("FromAge") || s.includes("ToAge"))
      ) {
        result = selectedVals
          .toString()
          .match(/\d+/g)
          .map(Number)
          .join(">")
          .split();
      } else {
        result = selectedVals
          .toString()
          .match(/\d+/g)
          .map(Number)
          .join("-")
          .split();
      }
    } else {
      result = result.map((m) => {
        if (m.includes(":")) {
          return m.split(": ")[1];
        } else {
          return m;
        }
      });
    }
    return result;
  };
  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };
  getCurrentTabIdByName = (id) => {
    id = parseInt(id);
    let statusesObj = "";
    let budgetsObj = "";
    let fefacilityinv = "";
    let feregioninv = "";
    let feteaminv = "";
    let fenaofficeinv = "";

    let fefacilityreq = "";
    let feregionreq = "";
    let feteamreq = "";

    if (
      this.props.festate.activeSubHeader === FE_Tabs.MyRequests ||
      this.props.festate.activeSubHeader === FE_Tabs.ReviewRequests
    ) {
      statusesObj = "fe_request_statuses";
      budgetsObj = "fe_request_budgetlines";
      fefacilityreq = "fe_request_facilities";
      feregionreq = "fe_request_regions";
      feteamreq = "fe_request_teams";
    } else if (this.props.festate.activeSubHeader === FE_Tabs.Inventory) {
      statusesObj = "fe_equipment_statuses";
      budgetsObj = "fe_equipments_budgetlines";
      fefacilityinv = "fe_equipment_facilities";
      feregioninv = "fe_equipment_regions";
      feteaminv = "fe_equipment_teams";
      fenaofficeinv = "fe_equipment_naoffices";
    }

    let tabName = "";
    switch (id) {
      case FE_AF_Nav_Items.Status:
        tabName = statusesObj;
        break;
      case FE_AF_Nav_Items.Budget:
        tabName = budgetsObj;
        break;
      case FE_AF_Nav_Items.BookValueRequest:
        tabName = "ferequests_bookValues";
        break;
      case FE_AF_Nav_Items.BookValueInventory:
        tabName = "feinv_bookValues";
        break;
      case FE_AF_Nav_Items.Type:
        tabName = "fetypes";
        break;
      case FE_AF_Nav_Items.Description:
        tabName = "description";
        break;

      case FE_AF_Nav_Items.ActualCost: //Actual Costs
        tabName = "actualCosts";
        break;
      case FE_AF_Nav_Items.Year: //Years
        tabName = "years";
        break;
      case FE_AF_Nav_Items.PurchaseDate: //PurchaseDate
        tabName = "purchaseDates";
        break;
      case FE_AF_Nav_Items.StickerNo: //Sticker No
        tabName = "stickerNo";
        break;

      // REQ-1 Tabs Inventory
      // case FE_AF_Nav_Items.FacilityInventory:
      //   tabName = fefacilityinv;
      //   break;
      case FE_AF_Nav_Items.RegionInventory:
        tabName = feregioninv;
        break;
      case FE_AF_Nav_Items.TeamInventory:
        tabName = feteaminv;
        break;
      case FE_AF_Nav_Items.NAOffice:
        tabName = fenaofficeinv;
        break;
      // REQ-1 Tabs Request
      case FE_AF_Nav_Items.FacilityRequest:
        tabName = fefacilityreq;
        break;
      case FE_AF_Nav_Items.RegionRequest:
        tabName = feregionreq;
        break;
      case FE_AF_Nav_Items.TeamRequest:
        tabName = feteamreq;
        break;
      case FE_AF_Nav_Items.UserInventory:
        tabName = "InventoryUserName";
        break;
      case FE_AF_Nav_Items.UserRequest:
        tabName = "RequestUserName";
        break;
      default:
        tabName = "NaN";
        break;
    }

    return tabName;
  };

  //In FE advance Search for equipemnt/requests total count
  //Called on apply filter

  onReturnNo = () => {
    /// API

    this.props.getMemberTotalCount(this.props.festate.filterData);
  };

  ///Reset Mini Button Event Handler
  resetFilter(activeTab) {
    this.props.resetAdvanceFilterTabStateById(activeTab);
    ///this.props.resetALLFilters(activeTab);
    this.props.setCurrentAFActiveTab(activeTab);
    this.props.handleTagsVisibility();
  }

  isAnyFormInvalid(formName) {
    if (isInvalid(formName)(this.props.currentState)) {
      return true;
    } else {
      return false;
    }
  }

  checkValidApply = (currentTab) => {
    const Forms = {
      Description: "fe_req_descriptionForm",
      ReqsBookVals: "fe_req_bookValsForm",
      InvBookVals: "fe_Inv_bookValsForm",
      ActualCost: "fe_actualValsForm",
      Year: "FE_INV_YearsForm",
      PurchaseDate: "purchaseDateForm",
      StickerNo: "FE_INV_StickerNoForm",
      UserName: "fe_inv_NameForm",
    };
    if (currentTab === FE_AF_Nav_Items.Description) {
      // Description tab
      const formValues = getFormValues(Forms.Description)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Enter Description.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.StickerNo) {
      // StickerNo tab
      const formValues = getFormValues(Forms.StickerNo)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Enter Sticker No.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.ActualCost) {
      // ActualCost tab
      const formValues = getFormValues(Forms.ActualCost)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Select Actual Cost.");
        return false;
      }
      if (formValues) {
        if (formValues.FromVal && formValues.ToVal) {
          if (
            Number.parseInt(formValues.FromVal) >
            Number.parseInt(formValues.ToVal)
          ) {
            toast.warn("Min Value should be less than Max Value.");
            return false;
          }
        }
      }
    }
    if (currentTab === FE_AF_Nav_Items.PurchaseDate) {
      const formValues = getFormValues(Forms.PurchaseDate)(
        this.props.currentState
      );
      if (_.isEmpty(formValues)) {
        toast.warn("Please enter Purhcase Date.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.Year) {
      const YearsformValues = getFormValues(Forms.Year)(
        this.props.currentState
      );

      if (
        (YearsformValues && _.isEmpty(YearsformValues.inventoryYear[0])) ||
        !YearsformValues.inventoryYear.every((s) => typeof s === "string")
      ) {
        toast.warn("Please enter Year.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.UserInventory) {
      // User Name tab
      const formValues = getFormValues(Forms.UserName)(this.props.currentState);
      if (_.isEmpty(formValues)) {
        toast.warn("Enter Name.");
        return false;
      }
    }

    ///#####################Start of ****DropDown***** Controls#################
    if (currentTab === FE_AF_Nav_Items.Status) {
      ///  Status

      if (
        this.props.festate.activeSubHeader > FE_Tabs.Inventory &&
        _.isEmpty(
          _.filter(this.props.festate.filterData.fe_request_statuses, "checked")
        )
      ) {
        toast.warn("Select Status.");
        return false;
      }

      if (
        this.props.festate.activeSubHeader === FE_Tabs.Inventory &&
        _.isEmpty(
          _.filter(
            this.props.festate.filterData.fe_equipment_statuses,
            "checked"
          )
        )
      ) {
        toast.warn("Select Status.");
        return false;
      }
    }

    if (currentTab === FE_AF_Nav_Items.Type) {
      ///  Type
      if (
        _.isEmpty(_.filter(this.props.festate.filterData.fetypes, "checked"))
      ) {
        toast.warn("Select Type.");
        return false;
      }
    }

    if (currentTab === FE_AF_Nav_Items.Budget) {
      ///  BudgetLines

      if (
        this.props.festate.activeSubHeader > FE_Tabs.Inventory &&
        _.isEmpty(
          _.filter(
            this.props.festate.filterData.fe_request_budgetlines,
            "checked"
          )
        )
      ) {
        toast.warn("Select Budget.");
        return false;
      }
      if (
        this.props.festate.activeSubHeader == FE_Tabs.Inventory &&
        _.isEmpty(
          _.filter(
            this.props.festate.filterData.fe_equipments_budgetlines,
            "checked"
          )
        )
      ) {
        toast.warn("Select Budget.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.TeamInventory) {
      ///  Equipment Teams
      if (
        _.isEmpty(
          _.filter(this.props.festate.filterData.fe_equipment_teams, "checked")
        )
      ) {
        toast.warn("Select Team.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.TeamRequest) {
      ///  Request Teams
      if (
        _.isEmpty(
          _.filter(this.props.festate.filterData.fe_request_teams, "checked")
        )
      ) {
        toast.warn("Select Team.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.RegionInventory) {
      ///  Equipment Regions
      if (
        _.isEmpty(
          _.filter(
            this.props.festate.filterData.fe_equipment_regions,
            "checked"
          )
        )
      ) {
        toast.warn("Select Region.");
        return false;
      }
    }
    if (currentTab === FE_AF_Nav_Items.RegionRequest) {
      ///  Request Regions
      if (
        _.isEmpty(
          _.filter(this.props.festate.filterData.fe_request_regions, "checked")
        )
      ) {
        toast.warn("Select Region.");
        return false;
      }
    }
    //Only for Equipment
    if (currentTab === FE_AF_Nav_Items.NAOffice) {
      if (
        _.isEmpty(
          _.filter(
            this.props.festate.filterData.fe_equipment_naoffices,
            "checked"
          )
        )
      ) {
        toast.warn("Select National Office.");
        return false;
      }
    }
    const requestBookValues = getFormValues(Forms.ReqsBookVals)(
      this.props.currentState
    );
    if (requestBookValues) {
      if (requestBookValues.FromVal && requestBookValues.ToVal) {
        if (
          Number.parseInt(requestBookValues.FromVal) >
          Number.parseInt(requestBookValues.ToVal)
        ) {
          toast.warn("Min Book Value should be less than Max Book Value.");
          return false;
        }
      }
    }
    const invBookValues = getFormValues(Forms.InvBookVals)(
      this.props.currentState
    );
    if (invBookValues) {
      if (invBookValues.FromVal && invBookValues.ToVal) {
        if (
          Number.parseInt(invBookValues.FromVal) >
          Number.parseInt(invBookValues.ToVal)
        ) {
          toast.warn("Min Book Value should be less than Max Book Value.");
          return false;
        }
      }
    }
    let result = false;
    _.forEach(Forms, (value) => {
      if (this.isAnyFormInvalid(value)) {
        result = true;
      }
    });

    if (result) {
      return false;
    }

    return true;
  };

  render() {
    const loadingBtn = (
      <button class="btn btn-success mt-15 mr-2 applyfilter" type="button">
        <span
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </button>
    );
    const isloadingAdvanceFilter = !this.props.festate.loadingAdvanceFilter;
    return (
      <>
        {isloadingAdvanceFilter && window.innerWidth > 897 && (
          <button
            type="button"
            onClick={() => this.applyFilter()}
            className={`btn btn-success mt-15 mr-2 applyfilter ${this.props
              .isMobile && "btn-filter-sm mobileHeadBtns"}`}
          >
            Apply Filter
          </button>
        )}

        {window.innerWidth <= 897 && (
          <button
            type="button"
            onClick={() => this.applyFilter()}
            className={`btn btn-success mt-15 mr-2 applyfilter ${this.props
              .isMobile && "btn-filter-sm mobileHeadBtns"}`}
          >
            Apply Filter
          </button>
        )}
        {this.props.festate.loadingAdvanceFilter &&
          window.innerWidth > 897 &&
          loadingBtn}
        <button
          type="button"
          onClick={() => this.resetFilter(this.props.festate.activeTab)}
          className={`btn btn-primary mr-2 mb-1 float-right ${this.props
            .isMobile && "btn-filter-sm mobileHeadBtns"}`}
        >
          Reset
        </button>
      </>
    );
  }
}

export default connect(
  (state) => ({
    festate: state.fetracker,
    formValues: state.form,
    currentState: state,
  }),
  {
    ...actionFilter,
  }
)(TagsButtons);
