import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function CancelChildTemplateConfirmModal(props) {
  const { modal, toggle } = props;

  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Confirm Cancel</ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          <h5>Are you sure you want to Cancel Occasion?</h5>
        </div>{" "}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary w-md waves-effect waves-light"
          onClick={() => {
            props.confirm().confirmed();
          }}
        >
          Yes
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
