import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import Services from "../PermissionService";
import $ from "jquery";
class MemberPermissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberId: this.props.MemberId,
      PermissionFeatureId: this.props.PermissionFeatureId,
      PermType: this.props.PermType,
      data: [],
      ModalHeading: this.props.ModalHeading,
    };
    this.listScope = this.listScope.bind(this);
    this.loadDetail = this.loadDetail.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {
    //this.handleConfigurePermissionDetail();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.openPermissionDetailModal) {
      if (
        this.props.MemberId != nextProps.MemberId ||
        this.props.PermissionFeatureId != nextProps.PermissionFeatureId ||
        this.props.PermType != nextProps.PermType
      ) {
        this.state.MemberId = nextProps.MemberId;
        this.state.PermissionFeatureId = nextProps.PermissionFeatureId;
        await this.setState({
          MemberId: this.state.MemberId,
          PermType: nextProps.PermType,
          ModalHeading: nextProps.ModalHeading,
          PermissionFeatureId: nextProps.PermissionFeatureId,
        });
        this.handleConfigurePermissionDetail();
        this.openModal();
      } else {
        this.openModal();
      }
    }
  }
  handleConfigurePermissionDetail = async (e) => {
    $(".loading").show();
    let data = await Services.ReviewPermissionsDetail(
      this.state.MemberId,
      this.state.PermissionFeatureId,
      this.state.PermType
    );
    !_.isEmpty(data) &&
      (await this.setState({
        data: data.result,
      }));
    $(".loading").hide();
  };
  loadDetail = (data) => {
    return (
      <Fragment>
        {data.map((item) => {
          return (
            <Fragment>
              <div className="mx-2 my-3 py-3 shadow-sm border row">
                {item.defaultPermission ? (
                  <>
                    <div className="col-12">
                      <div className="mb-1 text-center d-block text-secondary">
                        <div className="text-center py-1">
                          <h4>Allowed by default permission.</h4>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12">
                      {item.linkedPositionPermission && (
                        <div className="mb-1 text-left d-block text-secondary">
                          <div className="mb-1 text-left d-block text-secondary">
                            <div className="text-left py-1">
                              <h5>Linked Position</h5>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="text-left py-1">
                        <span className="mb-2 text-muted">
                          <strong>Position Name: </strong>
                        </span>
                        <span className="font-weight-light">
                          {item.positionName == null ||
                          item.positionName == "" ? (
                            "none"
                          ) : item.isOwner ? (
                            <span>
                              {item.positionName} <b>OWNER</b>
                            </span>
                          ) : (
                            item.positionName
                          )}
                        </span>
                      </div>

                      <div className="mb-1 text-left d-block text-secondary">
                        <div className="text-left py-1">
                          <span className="mb-2 text-muted">
                            <strong>Team Name: </strong>
                          </span>
                          <span className="font-weight-light">
                            {item.teamName == null || item.teamName == ""
                              ? "none"
                              : item.teamName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-1 text-left d-block text-secondary">
                        <div className="text-left py-1">
                          <span className="mb-2 text-muted">
                            <strong>Scope: </strong>
                          </span>
                          {item.positionScopeType != null &&
                            item.positionScopeType != "" &&
                            (item.positionScopeType != 3 &&
                            item.positionScopeType != 1 ? (
                              <span className="font-weight-light">
                                {item.teamName == null ||
                                item.teamName == "" ? (
                                  "none"
                                ) : (
                                  <span>This Team ({item.teamName})</span>
                                )}
                              </span>
                            ) : (
                              <Fragment>
                                {item.positionScopeType == 1 && (
                                  <span className="font-weight-light">
                                    Same As Team
                                  </span>
                                )}
                                {item.isNational ? (
                                  <span className="card-text pl-2 text-italic">
                                    (National)
                                  </span>
                                ) : (
                                  this.listScope(item.scopes)
                                )}
                              </Fragment>
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Fragment>
          );
        })}
      </Fragment>
    );
  };

  listScope = (scopes) => {
    return (
      <div className="scopelist border mt-3">
        <ul className="">
          {!_.isEmpty(scopes) &&
            scopes.map((item, index) => {
              return (
                <li className="" key={"reg-" + index}>
                  <div
                    className={
                      "text-white px-4 py-1 bg-dark card-text w-100" +
                      (item.isChecked ? "" : " opacity-quater")
                    }
                  >
                    {
                      <Fragment>
                        <label className="mb-0" for={"region"}>
                          {item.code}
                        </label>
                      </Fragment>
                    }
                  </div>

                  {item.facility.length > 0 && (
                    <ul>
                      {item.facility.map((item2, index2) => {
                        return (
                          <li>
                            <div
                              className={
                                "card-text px-4 py-1 w-100" +
                                (item2.isChecked ? "" : " opacity-half")
                              }
                            >
                              <label
                                className="mb-0"
                                for={"facility_" + index + "" + index2}
                              >
                                {item2.code} - {item2.description}
                              </label>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    );
  };
  cancelModal() {
    // window.$("#" + this.state.ModalHeading).hide();
    // window.$("#" + this.state.ModalHeading).removeClass("show").attr("aria-hidden","true").removeAttr("aria-modal");
    window.$("#" + this.state.ModalHeading).modal("hide");

    //window.$(".modal-backdrop.fade.show")[1].remove();
  }
  openModal() {
    window.$("#" + this.state.ModalHeading).modal("show");
    window.$("#" + this.state.ModalHeading).on("hidden.bs.modal", function() {
      window.$("body").addClass("modal-open");
    });
    this.props.closePermissionDetailModal();
  }
  render() {
    return (
      <AUX>
        <div
          class="modal fade"
          id={this.state.ModalHeading}
          tabindex="-1"
          role="dialog"
          aria-labelledby={this.state.ModalHeading + "Title"}
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5 class="modal-title text-uppercase text-white mt-0">
                  PERMISSION DETAIL
                </h5>

                <button
                  type="button"
                  class="close text-white"
                  onClick={this.cancelModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body py-2 review-permission">
                {!_.isEmpty(this.state.data) ? (
                  this.loadDetail(this.state.data)
                ) : (
                  <h5 className="text-center text-secondary">
                    Detail is Unavailable!
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default MemberPermissionModal;
