import React, { Component } from "react";
import ActivityLoggingComponent from "../../Shared/ActivityLogging";
import AuditModuleTypes from "../../Shared/AuditModuleTypes";
import _ from "lodash";
import { Card, CardBody, Collapse } from "reactstrap";
import "../grievances.css";

export class GrievanceActivityLogging extends Component {
  state = {
    logRecordId: 0,
  };
  componentDidUpdate() {
    if (this.props.reloadLogs) {
      this.props.resetReloadLogs();
      this.fetchLogs();
    }
  }
  componentDidMount() {
    this.fetchLogs();
  }
  toggleCard = () => {
    this.props.loggingfn(!this.props.grievanceState.grievanceLoggingVisible);
    this.fetchLogs();
  };
  fetchLogs = () => {
    const { id } = this.props;
    if (id) {
      this.setState({ logRecordId: id }, () => {
        this.props.grievanceState.grievanceLoggingVisible &&
          this.props.getActivityLoggingByModuleId(
            AuditModuleTypes.Grievance,
            id,
            0,
            10
          );
      });
    }
  };

  refreshLogs = (Id) => {
    if (Id) {
      this.props.loggingfn(true);
      this.setState({ logRecordId: Id });
      this.props.getActivityLoggingByModuleId(
        AuditModuleTypes.Grievance,
        Id,
        0,
        10
      );
    }
  };
  render() {
    return (
      <div className="col-lg-12">
        {/* ActivityLogging Work */}
        <Card className="">
          {/* Card Header */}

          <h6
            className="card-header cursorPointer "
            style={{ background: "#686e82", color: "white", marginTop: "0px" }}
            onClick={this.toggleCard}
          >
            <i class="fa fa-history" aria-hidden="true" /> ACTIVITY LOGGING
            <i
              className={`pull-right float-right ${
                this.props.grievanceState.grievanceLoggingVisible
                  ? "fas arrow-icon fa-chevron-down"
                  : "fas arrow-icon fa-chevron-up"
              }`}
            />
          </h6>

          <Collapse
            className="memberCardCollapse"
            // style={{ transition: "height 2s ease" }}
            isOpen={this.props.grievanceState.grievanceLoggingVisible}
          >
            <CardBody className="px-2">
              <ActivityLoggingComponent recordId={this.state.logRecordId} />
            </CardBody>
          </Collapse>
        </Card>
        {/* ActivityLogging Work */}
      </div>
    );
  }
}
export default GrievanceActivityLogging;
