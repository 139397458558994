import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import { MDBInput } from "mdbreact";
import Services from "../PermissionService";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import PermissionAlgo from "./PermissionAlgo";
let positionObj = {
  id: 0,
  PositionName: "",
  Permissions: [],
  PositionTypeId: null,
  IsOwner: false,
  IsNational: false,
  PositionScopeType: 1,
};
class EditPositionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      openModal: false,
      positionTypes: [],
      ModalHeading: "EditPositionModal",
      position: null,
    };
    this.cancelModal = this.cancelModal.bind(this);
    this.saveModal = this.saveModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.positionConfiguration = this.positionConfiguration.bind(this);
    this.createPositionObj = this.createPositionObj.bind(this);
    this.getPositionTypes = this.getPositionTypes.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.changeHandlePositionTypeDropDown = this.changeHandlePositionTypeDropDown.bind(
      this
    );
  }
  changeHandlePositionTypeDropDown = (e) => {
    this.state.position.positionTypeId = e.target.value;
    this.setState({ position: this.state.position });
  };
  async resetModal() {
    this.setState({ position: _.clone(positionObj) });
  }
  componentWillUnmount() {}
  async saveModal() {
    $(".loading").show();

    let data = await Services.EditPosition({
      teamID: this.props.teamId,
      positions: [this.state.position],
    });
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        toast.success("position edited successfully!");

        let teamID = this.props.teamId;
        // let url = `/api/FacilityRegionEmail/SyncTeamMembers?teamId=${teamID}`;

        this.props.setReloader();
        this.cancelModal();

        //axios.post(url).then(({ data }) => {
        //    if (data.status && data.status.toLowerCase() == "success") {
        //    } else {
        //    }
        //});
      }
    }

    $(".loading").hide();
  }
  createPositionObj() {
    if (!this.props.permissionState.editPositionNew) {
      let { position } = this.props;

      let obj = {
        id: position.id,
        PositionName: position.positionName,
        PositionTypeId: position.positionTypeId,
        Permissions: [],
        IsOwner: position.isOwner,
        IsNational: position.isNational,
        //Scopes: null,
        //ScopeConfig: null,
        PositionScopeType: 1,
      };
      this.setState({ position: obj });
    } else {
      this.setState({ position: _.clone(positionObj) });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.permissionState.openEditPositionModal &&
      (!_.isEmpty(this.props.position) ||
        this.props.permissionState.editPositionNew)
    ) {
      this.createPositionObj();

      this.setState({
        openModal: this.props.permissionState.openEditPositionModal,
      });
      this.openModal();
    }
  }
  componentDidMount() {
    this.getPositionTypes();
  }
  async getPositionTypes() {
    $(".loading").show();
    let data = await Services.TeamPositionTypes();
    if (data != null) {
      await this.setState({ positionTypes: data.result });
    }
    $(".loading").hide();
  }

  cancelModal() {
    this.resetModal();
    this.setState({ openModal: false });
  }
  openModal() {
    this.props.closeEditPositionModal();
  }
  saveCancelButton = () => {
    return (
      <Fragment>
        <div className="row pt-2">
          <div className="col-12">
            <div className="text-right">
              <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                onClick={this.cancelModal}
              >
                Cancel
              </button>
              <button
                onClick={this.saveModal}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  handleChangePositionName = (e) => {
    this.state.position.PositionName = e.target.value;
    let error = PermissionAlgo.notNullValidation(e.target.value);
    if (!error) {
      error = PermissionAlgo.textValidation(e.target.value);
    }
    this.state.PositionName_err = error;

    this.setState({
      position: this.state.position,
    });
  };
  positionConfiguration = () => {
    let { positionTypes } = this.state;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
              Position Name:
            </span>
            <MDBInput
              containerClass="mt-2 mb-2"
              className="py-0 mb-0"
              name="PositionName"
              value={this.state.position.PositionName}
              onChange={this.handleChangePositionName}
              hint="provide your position Name"
              type="text"
            />
            <span className="text-danger">{this.state.PositionName_err}</span>

            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
          <div className="col-12 mt-2">
            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
              Position Type:
            </span>
            <select
              //className="browser-default custom-select"
              onChange={this.changeHandlePositionTypeDropDown}
              name="positiontype"
              className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
            >
              <option value="">Select Value</option>

              {!_.isEmpty(positionTypes) &&
                positionTypes.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      selected={this.state.position.PositionTypeId == item.id}
                    >
                      {item.positiontype}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </Fragment>
    );
  };
  render() {
    return (
      <AUX>
        <Modal
          id={this.state.ModalHeading}
          className="styled-modal"
          isOpen={this.state.openModal}
          size="sm"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader
            toggle={this.cancelModal}
            className="modal-header text-uppercase bg-secondary py-2"
          >
            Edit Position
          </ModalHeader>
          <ModalBody className="modal-body py-3">
            {!_.isEmpty(this.state.position) && (
              <>
                {this.positionConfiguration()}
                {this.saveCancelButton()}
              </>
            )}
          </ModalBody>
        </Modal>
      </AUX>
    );
  }
}

export default connect((state) => ({ permissionState: state.permission }), {
  ...PermissionAction,
})(EditPositionModal);
