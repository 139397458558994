import React, { Component } from "react";
import AddRoleFirstLevel from "./AddRolesFirstLevel";

class AddRoleWrap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            features: this.props.features
        };
    }
    render() {
        return (
            <div id="permissionsCont" className="md-accordion">
                {this.props.features.map(list => (
                    <div className="card mb-1">
                        <div className="card-header" id={`heading${list.name}`}>
                            <a href={`#${list.name.toLowerCase()}`} data-toggle="collapse" aria-expanded="true" aria-controls={list.name}>
                                <h6 className="m-0 font-14">{list.name}</h6>
                            </a>
                        </div>
                        <div id={list.name.toLowerCase()} className="collapse show" aria-labelledby={`heading${list.name}`}>
                            <AddRoleFirstLevel updateMethods={this.props.updateMethods} feature={list} />
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default AddRoleWrap;
