import { DatePickerInput } from "rc-datepicker";
import "../../../assets/css/equipmentstyles.css";
import { Tooltip } from "reactstrap";
import React, { Component } from "react";
import $ from "jquery";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import _ from "lodash";
import FETrackerAction from "../../../store/feTracker/action";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Collapse, Card, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { Modal, Table } from "react-bootstrap";
import AUX from "../../../hoc/Aux_";
import { debounce } from "throttle-debounce";
import { MDBInput } from "mdbreact";
import Services from "../../Permissions/PermissionService";
import FEServices from "../F&ERequests/FEService";
import moment from "moment";
//  FilesPond Import
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import InventoryStatus from "../InventoryStatus";
import InventoryRequestStatus from "../InventoryRequestStatus";
import FERequestsType from "../FERequestsType";
import FE_ActivityLogging from "../F&ERequests/FE_ActivityLogging";

import ActivityActions from "../../../store/activitylogging/action";
import FE_Tabs from "../FE_Tabs";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import AddMultiBudgetlines from "../AddMultiBudgetlines";

const filter = createFilterOptions();

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);
const ExclamationLabel = (name, state, value) => {
  let isRequest = state.isRequestOpened && state.id !== 0;
  const JsxElement = (
    <>
      <span> {name} </span>
      {_.isEmpty(value) && isRequest && (
        <i
          className={`fas fa-exclamation-triangle fa-sm  reviewIcon ${
            !state.showExclamation ? "invisible" : "visible"
          }  `}
        />
      )}
    </>
  );
  return JsxElement;
};
export const DepreciationTable = (props) => {
  const { usefulLife, cost, year, setDepreciationValues, purchaseDate } = props;
  let firstYear = new Date(purchaseDate).getFullYear();
  let bookValue = 0;
  var accumulatedDepr = 0;
  let costUseful = parseFloat((cost / usefulLife).toFixed(2)) || 0;

  if (!isFinite(costUseful)) {
    costUseful = 0;
  }
  if (!isFinite(cost)) {
    cost = 0;
  }

  //$(this).text() = > Years Header

  $("#depreciationTbl  th") &&
    $("#depreciationTbl  th").filter(function() {
      if (
        !_.isEmpty($(this).data()) &&
        !isNaN($(this).text()) &&
        $(this).text() < year
      ) {
        accumulatedDepr =
          accumulatedDepr +
            parseFloat(
              $(this)
                .attr("data-depreciation")
                .replace(/,/g, "")
            ) || 0;
      }
    });
  bookValue = parseFloat((cost - accumulatedDepr).toFixed(2)) || 0;
  if (!isFinite(bookValue)) {
    bookValue = 0;
  }

  setDepreciationValues({
    bookval: bookValue,
    accdep: accumulatedDepr.toFixed(2),
  });

  let resultRow = (
    <>
      <thead>
        <tr className="d-none">
          {Array.from({
            length: usefulLife + 3,
          }).map((_, index, arr) => (
            <th key={index}>
              {`${
                index == 0 ? "" : index < arr.length - 1 ? "Depreciation" : ""
              }`}
            </th>
          ))}
        </tr>
        <tr>
          {Array.from({
            length: usefulLife + 3,
          }).map((_, index, arr) => (
            <th
              key={index}
              data-depreciation={`${
                index == 0 // if first cell
                  ? null // then show cost in it
                  : index < arr.length - 1 // else index is not zero ,other than first cell
                  ? index == 1 // if index ==1 , second cell then
                    ? parseFloat((costUseful / 2).toFixed(2)) || 0 // => show costUseful/2
                    : index === arr.length - 2 // if second last cell then
                    ? parseFloat((costUseful / 2).toFixed(2)) || 0 //=> costUseful / 2 // => show costUseful/2
                    : costUseful // between first and last cells
                  : null //last cell
              }`}
            >
              {`${
                index == 0
                  ? "COST" ///first cell
                  : index < arr.length - 1
                  ? firstYear++ // b/w 1st and last excluded
                  : "Book Value"
              }`}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        <tr>
          {Array.from({
            length: usefulLife + 3,
          }).map((_, index, arr) => (
            <td key={index}>
              {`${
                index == 0 // if first cell
                  ? `$${cost.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` // then show cost in it
                  : index < arr.length - 1 // else index is not zero ,other than first cell
                  ? index == 1 // if index ==1 , second cell then
                    ? `$${(
                        parseFloat((costUseful / 2).toFixed(2)) || 0
                      ).toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}` // => show costUseful/2
                    : index === arr.length - 2 // if second last cell then
                    ? `$${(
                        parseFloat((costUseful / 2).toFixed(2)) || 0
                      ).toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}` //=> costUseful / 2 // => show costUseful/2
                    : `$${costUseful.toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}` // between first and last cells
                  : `$${bookValue.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}` //last cell
              } `}{" "}
            </td>
          ))}
        </tr>
      </tbody>
    </>
  );
  return <>{resultRow}</>;
};

const GetAccDeprBookVal = (equipDet) => {
  const { usefullife, cost, year } = equipDet;

  let bookValue = 0;
  var accumulatedDepr = 0;
  let costUseful = parseFloat((cost / usefullife).toFixed(2)) || 0;

  if (!isFinite(costUseful)) {
    costUseful = 0;
  }
  if (!isFinite(cost)) {
    cost = 0;
  }
  $("#depreciationTbl  th") &&
    $("#depreciationTbl  th").filter(function() {
      if (!_.isEmpty($(this).data()) && $(this).text() <= year) {
        accumulatedDepr =
          accumulatedDepr +
            parseFloat(
              $(this)
                .attr("data-depreciation")
                .replace(/,/g, "")
            ) || 0;
      }
    });

  accumulatedDepr = Number(parseFloat(accumulatedDepr).toFixed(2));
  bookValue = parseFloat((cost - accumulatedDepr).toFixed(2)) || 0;
  if (!isFinite(bookValue)) {
    bookValue = 0;
  }

  return {
    year: year,
    bookval: bookValue,
    deprval: Math.max(0, accumulatedDepr),
  };
};

const initialState = {
  isEquipmentDetailsOpen: true,
  isEquipmentAccountingOpen: true,
  isEquipmentDepreciationOpen: true,
  submittedVal: 1,
  //Dropdowns
  equipmentUser: {},
  equipmentPurchaser: {},
  equipmentBudgetline: {},
  equipmentType: null,
  equipmentFRT: null,
  equipmentCategory: null,
  usefullLife: null,

  searchTermMembers: "",
  searchTermPurchasers: "",
  searchTermBudgetLines: "",
  inputValueEquipmentType: "",
  isUnAuthorized: false,
  usefullLifeOptions: [
    { key: "1", val: "1" },
    { key: "2", val: "2" },
    { key: "3", val: "3" },
    { key: "4", val: "4" },
    { key: "5", val: "5" },
    { key: "6", val: "6" },
    { key: "7", val: "7" },
    { key: "8", val: "8" },
    { key: "9", val: "9" },
    { key: "10", val: "10" },
  ],
  equipmentCategoryOptions: [
    { key: 1, val: "Team" },
    { key: 2, val: "Region" },
    // { key: 3, val: "Facility" },
    { key: 4, val: "National Office" },
  ],
  purchasedate: moment(new Date()).format("MM/DD/YYYY"),
  date_err: "",
  equipmentid: 0,
  requestid: null,
  statusName: "",
  files: [],
  acceptedFileTypes: ["image/png", "image/jpeg", "application/pdf"],
  accdep: 0,
  bookval: 0,
  cost: "",
  budgetLine: 0,
  category: null,
  teamid: null,
  regionid: null,
  facilityid: null,
  user: null,
  purchaser: null,
  nationalofficecategoryid: null,
  nationalofficecategoryname: "",

  issubmit: false,
  description: "",
  currentyear: new Date().getFullYear() - 1,
  comments: "",
  serialnumber: "",
  stickernumber: "",
  year: "",
  warrantyinformation: "",
  isConfirmModalOpen: false,
  id: 0,
  status: -1,
  isManualEntry: false,
  isRequestOpened: false,
  requestdate: null,
  requestedAmount: "",
  approvedAmount: "",
  tooltipOpen: false,
  showTooltip: false,
  loadingPurchaser: false,
  loadingUsers: false,
  pageLoaded: false,
  hasActiveEquipment: false,
  currEquipmentStatus: "",
  showExclamation: false,
  cost_err: "",
  //Multi selection of BudgetLines
  selectedEquipmentBudgetlines: [
    {
      showAuthorizedPerson: false,
      equipmentBudgetline: null,
      allocatedpct: "",
      budgetid: 0,
      id: 0,
      selectedOption: "",
    },
  ],
  budgetAuthorizer: [{}],
  budgetAuthorizerId: [{}],

  showAuthorizedPerson: [{}],

  savedBudgetlinesList: [],
  showAuthorizedPerson: false,
  invalidBudgetForm: false,
  /////////////////////////
};

//Component Start
class FE_EquipmentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      usersOptions: [],
      purchaserOptions: [],
      budgetLinesOptions: [],
      equipmentFRTOptions: [],
      equipmentTypesOptions: [],
      regionsOptions: [],
      teamsOptions: [],
      facilitiesOptions: [],
      requestHistory: [],
      naOfficeSubCatsOptions: [],
    };

    this.autocompleteSearchDebouncedUsers = debounce(
      500,
      this.autocompleteSearchUsers
    );
    this.autocompleteSearchDebouncedBudgetLines = debounce(
      500,
      this.autocompleteSearchBudgetLines
    );
    this.autocompleteSearchDebouncedPurchasers = debounce(
      500,
      this.autocompleteSearchPurchasers
    );
    this.onSubmit = this.onSubmit.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.activityLogs = React.createRef();
  }
  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };
  autocompleteSearchUsers = () => {
    this._fetchUsersMembers();
  };
  autocompleteSearchPurchasers = () => {
    this._fetchPurchaserMembers();
  };
  autocompleteSearchBudgetLines = () => {
    this._fetchBudgetLines();
  };
  _fetchPurchaserMembers = async () => {
    await this.loadPurchasersData(this.state.searchTermPurchasers);
  };
  _fetchUsersMembers = async () => {
    await this.loadUsersData(this.state.searchTermMembers);
  };
  _fetchBudgetLines = async () => {
    await this.loadBudgetLinesData();
  };
  deleteImage() {
    this.toggleConfirmModal();
  }
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  componentDidUpdate(pp, ps, ss) {
    var { usefullLife } = ps;

    var previousStateUF = usefullLife != null ? usefullLife.val : null;
    var currentStateUF =
      this.state.usefullLife != null ? this.state.usefullLife.val : null;
    if (previousStateUF !== currentStateUF) {
      var currentCals = {
        bookval: this.state.accdep,
        accdep: this.state.bookval,
      };
      this.setDepreciationValues(currentCals);
    }
  }
  handleUpdate = () => {
    this.setState({ isUnAuthorized: this.state.isUnAuthorized });
  };
  handleClose = () => {
    this.props.onClose();
    this.setState(initialState);
  };
  onShow = async () => {
    // debugger;
    ///Edit Mode
    const { activeSubHeader } = this.props.festate;
    if (!_.isEmpty(this.props.festate.currentEquipmentRow)) {
      await this.loadEquipmentDropDownOptions();

      let savedRow = this.props.festate.currentEquipmentRow;

      this.setState(
        {
          year: savedRow.year,
          selectedEquipmentBudgetlines: savedRow.febudgetlines,
          showExclamation: savedRow.showExclamation || false,
          currEquipmentStatus:
            activeSubHeader === FE_Tabs.MyRequests &&
            savedRow.hasActiveEquipment
              ? savedRow.equipmentStatus
              : "N/A",
          hasActiveEquipment:
            activeSubHeader === FE_Tabs.MyRequests
              ? savedRow.hasActiveEquipment
              : false,
          isManualEntry: savedRow.isManualEntry || false,
          cost:
            savedRow.cost !== "" &&
            savedRow.cost !== null &&
            savedRow.cost !== undefined
              ? String(savedRow.cost)
              : "",
          statusName: savedRow.statusName,
          category: savedRow.category,
          isRequestOpened: activeSubHeader === FE_Tabs.MyRequests,
          //Incase it is equipment is self both id and equipment id will be the same
          id: savedRow.id, //incase it is approved request it will be request's id itself
          equipmentid:
            activeSubHeader !== FE_Tabs.Inventory
              ? savedRow.equipmentid
              : savedRow.id, ///incase it is approved request it will be request's equipment id
          requestid:
            activeSubHeader == FE_Tabs.Inventory
              ? savedRow.requestid
              : savedRow.id, /// after new work we got the request id column value
          equipmentFRTOptions:
            "Team" == savedRow.categoryName
              ? this.state.teamsOptions
              : "Region" == savedRow.categoryName
              ? this.state.regionsOptions
              : "Facility" == savedRow.categoryName
              ? this.state.facilitiesOptions
              : "National Office" == savedRow.categoryName
              ? this.state.naOfficeSubCatsOptions
              : [],
          requestHistory:
            activeSubHeader === FE_Tabs.MyRequests && savedRow.history
              ? savedRow.history
              : [],
          requestdate:
            activeSubHeader === FE_Tabs.MyRequests
              ? moment(savedRow.requestdate).format("MM/DD/YYYY")
              : null,
          requestedAmount:
            activeSubHeader === FE_Tabs.MyRequests
              ? savedRow.requestedamount
              : null,
          approvedAmount:
            activeSubHeader === FE_Tabs.MyRequests
              ? savedRow.approvedamount
              : null,
        },
        async () => {
          if (!_.isEmpty(savedRow.userName)) {
            var userNameList = savedRow.userName.split(" ");
            let userFLName = "";
            if (userNameList && userNameList.length == 1) {
              // incase there is no last name of the member, then send first name
              userFLName = `${userNameList[0].charAt(
                0
              )}${userNameList[0].charAt(1)}`;
            } else if (userNameList && userNameList.length > 1) {
              /// else there are(first and last name) so send "Ja Do" to the Api.
              userFLName = `${userNameList[0].charAt(
                0
              )}${userNameList[0].charAt(1)} ${userNameList[1].charAt(
                0
              )}${userNameList[1].charAt(1)}`;
            }
            ///Prefill the options list with selected Value
            await this.loadUsersData(userFLName);
          }
          //############## We wouldn't use it bcz of multiBudgetselection##############
          //if (!_.isEmpty(savedRow.budgetLineName)) {
          //   var budgetLineNameList = savedRow.budgetLineName.split(
          //     " "
          //   );
          //   let blFLName = "";
          //   if (
          //     budgetLineNameList &&
          //     budgetLineNameList.length == 1
          //   ) {
          //     // incase there is no last name of the bl, then send first name
          //     blFLName = `${budgetLineNameList[0].charAt(
          //       0
          //     )}${budgetLineNameList[0].charAt(1)}`;
          //   } else if (
          //     budgetLineNameList &&
          //     budgetLineNameList.length > 1
          //   ) {
          //     /// else there are(first and last name) so send "Ja Do" to the Api.
          //     blFLName = `${budgetLineNameList[0].charAt(
          //       0
          //     )}${budgetLineNameList[0].charAt(
          //       1
          //     )} ${budgetLineNameList[1].charAt(
          //       0
          //     )}${budgetLineNameList[1].charAt(1)}`;
          //   }
          //   await this.loadBudgetLinesData(blFLName);
          // }
          //############## We wouldn't use it bcz of multiBudgetselection##############s
          ///Load ALL budget lines

          await this.loadBudgetLinesData();
          if (!_.isEmpty(savedRow.purchaserName)) {
            var purchaserNameList = savedRow.purchaserName.split(" ");
            let purFLName = "";
            if (purchaserNameList && purchaserNameList.length == 1) {
              // incase there is no last name of the member, then send first name
              purFLName = `${purchaserNameList[0].charAt(
                0
              )}${purchaserNameList[0].charAt(1)}`;
            } else if (purchaserNameList && purchaserNameList.length > 1) {
              /// else there are(first and last name) so send "Ja Do" to the Api.
              purFLName = `${purchaserNameList[0].charAt(
                0
              )}${purchaserNameList[0].charAt(1)} ${purchaserNameList[1].charAt(
                0
              )}${purchaserNameList[1].charAt(1)}`;
            }
            await this.loadPurchasersData(purFLName);
          } else {
            await this.loadPurchasersData("");
          }

          await this.setState(
            {
              equipmentUser:
                this.state.usersOptions.find((el) => el.id == savedRow.user) ||
                null,
              equipmentType:
                this.state.equipmentTypesOptions.find(
                  (el) => el.key == savedRow.type
                ) || null,

              equipmentPurchaser:
                this.state.purchaserOptions.find(
                  (el) => el.id == savedRow.purchaser
                ) || null,
              equipmentBudgetline:
                this.state.budgetLinesOptions.find(
                  (el) => el.id == savedRow.budgetid
                ) || null,

              ///Teams, Region or Faility
              equipmentCategory:
                this.state.equipmentCategoryOptions.find(
                  (el) => el.key == savedRow.category
                ) || null,

              //Team, Region Name
              equipmentFRT:
                this.state.equipmentFRTOptions.find(
                  (el) => el.key == savedRow.frt
                ) || null,
            },
            () => {
              /////Pre Selecting useful life/////
              /////Not previously selected then select it with Type
              if (savedRow.usefullife == null) {
                const equipmentType = this.state.equipmentTypesOptions.find(
                  (el) => el.key == savedRow.type
                );
                if (equipmentType.key !== 5)
                  if (!_.isEmpty(this.state.usefullLifeOptions)) {
                    /// if Type is not "Other"
                    var usefulLifeList = this.state.usefullLifeOptions;
                    var selectedUsefulLife = usefulLifeList.find(
                      (el) => el.val == equipmentType.usefulLife
                    );
                    this.setState({
                      usefullLife: selectedUsefulLife,
                    });
                  }
              } else {
                ///if selected then show that value
                this.setState({
                  usefullLife:
                    this.state.usefullLifeOptions.find((el) => {
                      return el.val == savedRow.usefullife;
                    }) || null,
                });
              }
              ///////////////////////////////////
            }
          );

          if (activeSubHeader === FE_Tabs.MyRequests) {
            if (savedRow.requestedamount != savedRow.approvedamount) {
              this.setState({ showTooltip: true });
            }
          }
        }
      );

      await this.setState({
        teamid: savedRow.category == 1 ? savedRow.frt : null,
        regionid: savedRow.category == 2 ? savedRow.frt : null,
        facilityid: savedRow.category == 3 ? savedRow.frt : null,
        nationalofficecategoryid: savedRow.category == 4 ? savedRow.frt : null,

        files: [],
        accdep: savedRow.accumulatedDepr,
        bookval: savedRow.bookVal,

        description: savedRow.description,
        // comments:
        //   this.state.isRequestOpened ||
        //   (!this.state.isRequestOpened &&
        //     this.state.status == InventoryStatus.InAccReview) // if inventory is opened it shouldn't be InAccReview status
        //     ? ""
        //     : savedRow.comments, //comments shouldn't be displayed for requests and equipments of InAcc Review
        serialnumber: !_.isEmpty(savedRow.serialnumber)
          ? savedRow.serialnumber
          : "",
        stickernumber: !_.isEmpty(savedRow.stickernumber)
          ? savedRow.stickernumber
          : "",
        warrantyinformation: !_.isEmpty(savedRow.warrantyinformation)
          ? savedRow.warrantyinformation
          : "",
        status: savedRow.status,
        purchasedate:
          savedRow.purchasedate !== "0001-01-01T00:00:00"
            ? moment(savedRow.purchasedate).format("MM/DD/YYYY")
            : moment(new Date()).format("MM/DD/YYYY"),
      });
      let isReq = this.state.isRequestOpened;
      let reqStatus = this.state.status;

      if (!isReq && reqStatus == InventoryStatus.InAccReview) {
        this.setState({ comments: "" });
      } else if (isReq) {
        this.setState({ comments: "" });
      } else {
        this.setState({ comments: savedRow.comments });
      }
      this.props.activityLoggingState &&
        this.activityLogs &&
        this.activityLogs.current &&
        this.activityLogs.current.refreshLogs(savedRow.equipmentid);
      if (this.props.activityLoggingState.totalCount > 0) {
        this.props.toggleEquipmentLogAccordion(false);
      }
      ///console.log("onShow ran complete");
    } else {
      //Add Mode
      $(".loading").show();
      await this.loadUsersData("");
      await this.loadPurchasersData("");
      await this.loadBudgetLinesData();
      await this.loadEquipmentDropDownOptions();
      if (AuthService.canView(PermissionFeature.FEAccountingPurchaseDate)) {
        this.setState({
          purchasedate: moment(new Date()).format("MM/DD/YYYY"),
        });
      }
      $(".loading").hide();
    }
  };

  handleInitFileUpload() {
    //console.log("handleInitFileUpload instance has initialised", this.pond2);
  }

  uploadFileObject = (fileItems) => {
    var files = fileItems
      .filter((el) => {
        return el.status !== 8;
      })
      .map((fileItem) => fileItem.file);
    // property.flyerJustification_err = "";
    this.setState({
      files: files,
    });
  };

  loadPurchasersData = async (SearchTerms) => {
    // $(".loading").show();
    if (_.isEmpty(this.state.purchaserOptions)) {
      this.setState({ loadingPurchaser: true });
    }

    let data = await Services.LoadMembers(SearchTerms, 0, 100);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.purchaserOptions = data.result != null ? data.result : [];

        await this.setState(
          {
            purchaserOptions: this.state.purchaserOptions,
          },
          () => {}
        );
      }
    }
    this.setState({ pageLoaded: true });
    this.setState({ loadingPurchaser: false });
    // $(".loading").hide();
  };
  loadUsersData = async (SearchTerms) => {
    //$(".loading").show();
    if (_.isEmpty(this.state.usersOptions)) {
      this.setState({ loadingUsers: true });
    }

    let data = await Services.LoadMembers(SearchTerms, 0, 100);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.usersOptions = data.result != null ? data.result : [];
        await this.setState({
          usersOptions: this.state.usersOptions,
        });
        this.setState({ loadingUsers: false });
      }
    }
    //$(".loading").hide();
  };

  loadBudgetLinesData = async () => {
    let data = await FEServices.LoadAllBudgetLines();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.budgetLinesOptions = data.result != null ? data.result : [];
        await this.setState({
          budgetLinesOptions: this.state.budgetLinesOptions,
        });
      }
    }
  };
  setBudgetLineFormInvalid = () => {
    this.setState({ invalidBudgetForm: true });
  };

  loadEquipmentDropDownOptions = async () => {
    let response = await FEServices.LoadEquipmentDropDownOptions();
    if (!_.isEmpty(response)) {
      var equipmentTypes = response.data[0];
      this.setState({ equipmentTypesOptions: equipmentTypes });

      var regions = response.data[1];
      this.setState({ regionsOptions: regions });

      var teams = response.data[2];
      this.setState({ teamsOptions: teams });

      var facilities = response.data[3];
      this.setState({ facilitiesOptions: facilities });

      var naOfficeSubCats = response.data[4];
      this.setState({ naOfficeSubCatsOptions: naOfficeSubCats });
    }
  };

  onSubmit = async (evt) => {
    evt.preventDefault();
    evt.persist();
    await FEServices.setStateAsync({ issubmit: true }, this);
    ///await this.setState({ issubmit: true });
    if (this.state.submittedVal == 3) {
      $(".EQ-Purchaser").removeAttr("required");
      $(".EQ-Cost").removeAttr("required");

      $(".EQ-Purchaser").prop("required", false);
      $(".EQ-Cost").prop("required", false);
    } else {
      $(".EQ-Purchaser").attr("required", "true");
      $(".EQ-Cost").attr("required", "true");
      $(".EQ-Purchaser").prop("required", true);
      $(".EQ-Cost").prop("required", true);
    }

    let isBudgetLineFormCorrupt = this.state.selectedEquipmentBudgetlines.some(
      (s) =>
        s.allocatedpct == 0 ||
        s.allocatedpct == undefined ||
        s.allocatedpct == "" ||
        s.budgetid == 0 ||
        s.budgetid == undefined
    );
    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      this.setState({ cost_err: "" });
      this.setState({ approvedAmount_err: "" });

      return false;
      //#####Form Validation false ho gyae ha########//
    }

    if (isBudgetLineFormCorrupt) {
      $("#addBudgetLineBtn").trigger("click");
      toast.warn("Please fill all the budget lines information.");
      return false;
    }
    let isValidPct = FEServices.validateBudgetPercentages(this.state);
    if (!isValidPct) {
      toast.warn("Total Budget Line percentage should be 100.");
      return false;
    }
    var formdata = new FormData();
    evt.preventDefault();
    let equipmentStatus = 0;

    /// This modal opened from "My REquests" tab, it should be "Approved Request" view scenario.
    let isRequest = this.state.isRequestOpened && this.state.id !== 0;
    let equipmentid = 0;
    let shouldCloseRequest = true;
    let requestid = 0;
    if (
      isRequest &&
      (this.state.status === InventoryRequestStatus.Approved ||
        this.state.status === InventoryRequestStatus.Closed)
    ) {
      ///When Request Opened in Equipment Modal, There are two Action Buttons available
      //1)Just Save => 3
      //2)Save and Close => 1

      equipmentid = this.state.equipmentid; //some id //bcz Approved/Closed Request will always has a Equipment Associated but with Pending/In Acc Review Status

      requestid = this.state.id; ///Request Id

      //User have clicked on "Just Save" button
      if (this.state.submittedVal == 3) {
        equipmentStatus = InventoryStatus.Pending;
        shouldCloseRequest = false; //Means don't close the Request and Equipment should stay the same as Pending Status Equipment
      } else if (this.state.submittedVal == 1) {
        ///"Save and Close"
        equipmentStatus = InventoryStatus.InAccReview; // Mark the Equipment As In Accounting Review
        shouldCloseRequest = true; //Close the Request
      }
    } else if (!isRequest) {
      equipmentid = this.state.equipmentid; //will not be null here
      requestid = this.state.requestid; // incase of manual entry of equipment it would be null, other wise it will be the request id of related equipment
      ////Save
      if (this.state.submittedVal == 1) {
        equipmentStatus =
          this.state.equipmentid == 0
            ? InventoryStatus.Active //2
            : this.state.status;
      } ////Save and Activate
      else if (this.state.submittedVal == 2) {
        equipmentStatus = InventoryStatus.Active; //2
      }
    }
    const data = {
      //Equipment Details
      isRequest: isRequest, /// <==
      requeststatus: equipmentStatus, /// <==
      id: equipmentid, /// <==
      requestid: requestid,
      user: _.isEmpty(this.state.equipmentUser)
        ? null
        : this.state.equipmentUser.id,
      type: _.isEmpty(this.state.equipmentType)
        ? null
        : this.state.equipmentType.key,

      usefullife: _.isEmpty(this.state.usefullLife)
        ? null
        : this.state.usefullLife.key,

      purchaser: _.isEmpty(this.state.equipmentPurchaser)
        ? null
        : this.state.equipmentPurchaser.id,

      budgetid: _.isEmpty(this.state.equipmentBudgetline)
        ? null
        : this.state.equipmentBudgetline.id,
      category: _.isEmpty(this.state.equipmentCategory)
        ? null
        : this.state.equipmentCategory.key,

      description: this.state.description,
      comments: this.state.comments,
      serialnumber: this.state.serialnumber,
      stickernumber: this.state.stickernumber,
      warrantyinformation: this.state.warrantyinformation,
      teamid: this.state.teamid,
      facilityid: this.state.facilityid,
      regionid: this.state.regionid,

      nationalofficecategoryid: this.state.nationalofficecategoryid,
      nationalofficecategoryname: this.state.nationalofficecategoryname,

      //Accounting details
      cost: this.state.cost,
      purchasedate: this.state.purchasedate,

      //Depreciation

      status: equipmentStatus,

      comments: this.state.comments,
      shouldCloseRequest: shouldCloseRequest,
      FEEquipmentBudgetLines: this.state.selectedEquipmentBudgetlines,
      year: this.state.year,
    };

    //FilePond Files
    for (var i = 0; i < this.state.files.length; i++) {
      formdata.append("File" + i, this.state.files[i]);
    }

    //FilePond End

    //Depreciation Schedule Values
    let EquipmentDepreciations = [];
    let myself = this;
    $("#depreciationTbl th").filter((i, el) => {
      if (!_.isEmpty(el.dataset) && el.dataset.depreciation != "null") {
        // console.log(el.innerText);
        // console.log(el.dataset.depreciation);
        let year = parseInt(el.innerText);
        let cost = parseFloat(+myself.state.cost.replace(/,/g, "")) || 0;
        let usefullife = parseFloat(+myself.state.usefullLife.val);
        let equipmentdepreciation = GetAccDeprBookVal({
          year,
          cost,
          usefullife,
        });

        EquipmentDepreciations.push(equipmentdepreciation);
        return el;
      }
    });

    //this.UpdateRequestObject(formdata);
    data["EquipmentDepreciations"] = EquipmentDepreciations;
    formdata.append("data", JSON.stringify(data));
    //console.log("EquipmentDepreciations", EquipmentDepreciations);
    this.addUpdateEquipment(formdata);
  };

  addUpdateEquipment = async (formdata) => {
    FEServices.showSpinner();
    let response = await FEServices.SaveEquipment(formdata);
    if (!_.isEmpty(response)) {
      toast.success("Equipment saved successfully");
    }
    this.reloadEquipments();
    FEServices.hideSpinner();
    this.handleClose();
  };

  reloadEquipments = () => {
    var activeSections = [];
    let isRequest = this.state.isRequestOpened && this.state.id !== 0;
    let showClosedMyRequests = this.props.festate.showHisMyRequests;
    if (isRequest) {
      // This would be the approved/closed request, opend in equipment's model
      const formData = {
        Keyword: "",
        isMyRequests: true,
        feRequestsTypeList: [FERequestsType.MyRequests],
        showClosed: showClosedMyRequests,
        PageNo: 1,
        PageSize: 10,
        SortOrder: "id", //"firstnameAsc",
      };

      this.props.searchFeRequestsRecords(formData);
    } else {
      //Reset the Equipment Listing Table
      this.props.setInventoryTablePaginationInfo(
        1,
        10,
        InventoryStatus.NonAccReview
      );
      this.props.setInventoryTablePaginationInfo(
        1,
        10,
        InventoryStatus.InAccReview
      );
      activeSections.push(InventoryStatus.InAccReview);
      activeSections.push(InventoryStatus.NonAccReview);
      const formData = {
        recordsTypeList: activeSections,
        PageNo: 1,
        PageSize: 25,
        SortOrder: "id", //"firstnameAsc",
      };

      this.props.searchFeInventoryRecords(formData);
    }
  };
  // users dropdown events
  usersSearchHandler = (SearchTerms) => {
    this.setState({ searchTermMembers: SearchTerms }, () => {
      this.autocompleteSearchDebouncedUsers();
    });
  };
  purchaserSearchHandler = (SearchTerms) => {
    this.setState({ searchTermPurchasers: SearchTerms }, () => {
      this.autocompleteSearchDebouncedPurchasers();
    });
  };

  // budget lines dropdown events
  budgetLinesSearchHandler = (SearchTerms) => {
    this.setState({ searchTermBudgetLines: SearchTerms }, () => {
      this.autocompleteSearchDebouncedBudgetLines();
    });
  };

  valueChangeHandler = (evt) => {
    this.state.submissionObj[evt.target.name] = evt.target.value;
    this.state.submissionObj[evt.target.name + "_err"] = "";
    this.setState({
      submissionObj: this.state.submissionObj,
    });
  };

  setDepreciationValues = (deprObj) => {
    if (
      deprObj.accdep != this.state.accdep ||
      deprObj.bookval != this.state.bookval
    ) {
      this.setState({ accdep: deprObj.accdep });
      this.setState({ bookval: deprObj.bookval });
    }
  };

  setSaveStatus = () => {
    this.setState({ submittedVal: 1 }); ///Save and Close
  };

  setSaveAndActiveStatus = () => {
    this.setState({ submittedVal: 2 }); //Save and Active
  };
  setJustSaveStatus = () => {
    this.setState({ submittedVal: 3 }); // Just Save
  };
  viewRelatedRequest = async (equipmentid) => {
    let response = await FEServices.getRequestById(equipmentid);
    if (!_.isEmpty(response)) {
      let requestRow = response.data;
      this.props.openEquipmentRelatedRequest(requestRow);
    }
  };
  handleCancelRequest = async () => {
    const { id } = this.state;
    FEServices.showSpinner();
    let response = await FEServices.RemoveRequest(id);
    if (!_.isEmpty(response)) {
      toast.success("Request updated successfully");
    }
    FEServices.hideSpinner();
    this.reloadEquipments();
    this.handleClose();
  };
  render() {
    ///Equipment Modal is shown, filled with Request details,
    //from my requests lists section,
    //against an approved request.
    let isRequest = this.state.isRequestOpened && this.state.id !== 0;
    const isActiveEquipment =
      this.state.status === InventoryStatus.Active && !isRequest;
    const isAccountingPerson = AuthService.canView(
      PermissionFeature.FEAccounting
    );
    const { activeSubHeader } = this.props.festate;
    const shouldShowSaveBtn = () => {
      let op1 =
        !(this.state.status === InventoryStatus.Disposed) &&
        isActiveEquipment &&
        isAccountingPerson;
      let op2 =
        this.state.status === InventoryStatus.InAccReview && isAccountingPerson;
      let op3 =
        this.state.equipmentid !== this.state.id ||
        isRequest ||
        this.state.status === -1;

      let result =
        (op1 || op2 || op3) &&
        !(
          isRequest &&
          this.state.status == InventoryRequestStatus.Closed &&
          this.state.hasActiveEquipment
        );
      return result;
    };
    const shouldDisableControl =
      this.state.status === InventoryStatus.Disposed ||
      (!(
        (isActiveEquipment && isAccountingPerson) ||
        this.state.equipmentid == 0
      ) &&
        this.state.status !== InventoryStatus.InAccReview);

    // shouldDisableControl method end here
    const shouldDisableComments = () => {
      let firstOp =
        this.state.status === InventoryStatus.Disposed &&
        !this.state.isRequestOpened;
      let secondP1 = !(
        (isActiveEquipment && !isAccountingPerson) ||
        this.state.equipmentid == 0
      );

      let secondP2 =
        ///Inventory status comparison would be only for Equipment not for Request
        this.state.status !== InventoryStatus.InAccReview &&
        this.state.isRequestOpened;
      ///Incase request is opened in Equipment Modal(Approved/Closed Status)
      let last =
        !this.state.isRequestOpened &&
        this.state.status === InventoryRequestStatus.Approved &&
        shouldShowSaveBtn();

      let final =
        this.state.isRequestOpened &&
        this.state.status === InventoryRequestStatus.Closed &&
        this.state.hasActiveEquipment;

      let result = firstOp || (secondP1 && secondP2 && last) || final;

      return result;
    };

    const shouldShowSaveAndActiveBtn =
      this.state.status == InventoryStatus.InAccReview;
    const allDisabled =
      FEServices.isAllDisabled(
        PermissionFeature.FEAccountingUser,
        PermissionFeature.FEAccountingDescription,
        PermissionFeature.FEAccountingType,
        PermissionFeature.FEAccountingPurchaser,
        PermissionFeature.FEAccountingCost,
        PermissionFeature.FEAccountingPurchaseDate,
        PermissionFeature.FEAccountingComments,
        PermissionFeature.FEAccountingSerialNumber,
        PermissionFeature.FEAccountingStickerNumber,
        PermissionFeature.FEAccountingWarrantyInformaion,
        PermissionFeature.FEAccountingBudgetID,
        PermissionFeature.FEAccountingFiles,
        PermissionFeature.FEAccountingUsefulLife,
        PermissionFeature.FEAccountingCategory
      ) && !isRequest;
    ///All Hidden scenario would be true only when we are not in Approved Request flow
    const allHidden = () => {
      let isAllHiddenForEquipment = FEServices.isAllHidden(
        PermissionFeature.FEAccountingUser,
        PermissionFeature.FEAccountingType,
        PermissionFeature.FEAccountingUsefulLife,
        PermissionFeature.FEAccountingPurchaser,
        PermissionFeature.FEAccountingBudgetID,
        PermissionFeature.FEAccountingDescription,
        PermissionFeature.FEAccountingCategory,

        PermissionFeature.FEAccountingSerialNumber,
        PermissionFeature.FEAccountingStickerNumber,
        PermissionFeature.FEAccountingWarrantyInformaion,

        PermissionFeature.FEAccountingCost,
        PermissionFeature.FEAccountingPurchaseDate,
        PermissionFeature.FEAccountingFiles,
        PermissionFeature.FEAccountingDepreciationValues,
        PermissionFeature.FEAccountingBookVal,
        PermissionFeature.FEAccountingAD,
        PermissionFeature.FEAccountingComments,
        PermissionFeature.FEAccountingActivityLog
      );

      let isAllHiddenForRequest = FEServices.isAllHidden(
        PermissionFeature.FERequestUser,
        PermissionFeature.FERequestDescription,
        PermissionFeature.FERequestType,
        PermissionFeature.FERequestRequester,
        PermissionFeature.FERequestRequestedAmount,
        PermissionFeature.FERequestApprovedAmount,
        PermissionFeature.FERequestRequestDate,
        PermissionFeature.FERequestComments,
        PermissionFeature.FERequestBudgetID,
        PermissionFeature.FERequestCategory
      );

      if (isRequest) {
        return isAllHiddenForRequest;
      } else {
        return isAllHiddenForEquipment && !isRequest;
      }
    };

    const ShowAccountingSection =
      !FEServices.isAllHidden(
        PermissionFeature.FEAccountingPurchaseDate,
        PermissionFeature.FEAccountingFiles,
        PermissionFeature.FEAccountingCost
      ) || isRequest;
    const ShowEquipmentDetailsSection =
      !FEServices.isAllHidden(
        PermissionFeature.FEAccountingUser,
        PermissionFeature.FEAccountingType,
        PermissionFeature.FEAccountingUsefulLife,
        PermissionFeature.FEAccountingPurchaser,
        PermissionFeature.FEAccountingBudgetID,
        PermissionFeature.FEAccountingDescription,
        PermissionFeature.FEAccountingCategory,
        PermissionFeature.FEAccountingSerialNumber,
        PermissionFeature.FEAccountingStickerNumber,
        PermissionFeature.FEAccountingWarrantyInformaion
      ) || isRequest;

    const showStatusLabel = () => {
      if (
        this.props.festate.activeSubHeader === FE_Tabs.MyRequests &&
        AuthService.canView(PermissionFeature.FERequestStatus)
      ) {
        return true;
      } else if (
        this.props.festate.activeSubHeader === FE_Tabs.Inventory &&
        AuthService.canView(PermissionFeature.FEAccountingStatus)
      ) {
        return true;
      } else {
        return false;
      }
    };

    const commmentsPermission = () => {
      //console.log("shouldDisableComments", shouldDisableComments());
      //console.log("isAccountingPerson", isAccountingPerson);
      let canUpdate = AuthService.canUpdate(
        PermissionFeature.FEAccountingComments
      );
      let equipmentId = this.state.equipmentid;
      var result =
        (!canUpdate && equipmentId > 0 && !isRequest) ||
        (shouldDisableComments() && equipmentId !== 0) ||
        (!isRequest && equipmentId !== 0 && shouldDisableComments()) ||
        (!isAccountingPerson && equipmentId > 0 && !isRequest) ||
        (!this.state.isRequestOpened && !shouldShowSaveBtn());
      ///console.log("commmentsPermissionResult", result);
      return result;
    };
    const DescriptionCtrlPermission = () => {
      let res =
        !AuthService.canUpdate(PermissionFeature.FEAccountingDescription) ||
        shouldDisableControl;
      return res;
    };
    const getTypeControl = () => {
      return (
        <>
          <div className="col-lg-4 mt-4 mt-sm-4 mt-lg-0">
            <Autocomplete
              id="equipmentType"
              options={this.state.equipmentTypesOptions}
              getOptionLabel={(option) => {
                if (typeof option === "object") {
                  return option.val;
                } else {
                  return "";
                }
              }}
              onChange={(e, newValue, reason) =>
                FEServices.changeValueHandler(
                  e,
                  newValue,
                  reason,
                  "equipmentType",
                  this
                )
              }
              disabled={
                !AuthService.canUpdate(PermissionFeature.FEAccountingType) ||
                shouldDisableControl
              }
              value={this.state.equipmentType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type"
                  variant="standard"
                  value={this.state.equipmentType}
                  placeholder="Select Equipment Type"
                  InputLabelProps={{
                    shrink: true,
                    classes: { shrink: "mt-n1" },
                  }}
                  error={
                    this.state.issubmit &&
                    !(
                      !AuthService.canUpdate(
                        PermissionFeature.FEAccountingType
                      ) || shouldDisableControl
                    ) &&
                    _.isEmpty(this.state.equipmentType)
                      ? true
                      : false
                  }
                  helperText={
                    this.state.issubmit &&
                    !(
                      !AuthService.canUpdate(
                        PermissionFeature.FEAccountingType
                      ) || shouldDisableControl
                    ) &&
                    _.isEmpty(this.state.equipmentType)
                      ? "Required"
                      : ""
                  }
                  required={true}
                />
              )}
            />
          </div>
        </>
      );
    };

    const getUsefulLifeControl = () => {
      return (
        <div className="col-lg-4 mt-4 mt-sm-4 mt-lg-0">
          <Autocomplete
            id="equipmentusefullLife"
            options={this.state.usefullLifeOptions}
            getOptionLabel={(option) => option.val}
            onChange={(e, newValue, reason) =>
              FEServices.changeValueHandler(
                e,
                newValue,
                reason,
                "usefullLife",
                this
              )
            }
            //true disabled
            //false editable
            disabled={
              !AuthService.canUpdate(
                PermissionFeature.FEAccountingUsefulLife
              ) ||
              (shouldDisableControl && !isRequest) ||
              isRequest
            }
            value={this.state.usefullLife}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Useful Life"
                value={this.state.usefullLife}
                // validators={["required"]}
                // errorMessages={["Useful Life is required"]}
                placeholder="Select Equipment Useful Life"
                InputLabelProps={{
                  shrink: true,
                  classes: { shrink: "mt-n1" },
                }}
                required={true}
                error={
                  this.state.issubmit &&
                  !(
                    !AuthService.canUpdate(
                      PermissionFeature.FEAccountingType
                    ) || shouldDisableControl
                  ) &&
                  _.isEmpty(this.state.usefullLife)
                    ? true
                    : false
                }
                helperText={
                  this.state.issubmit &&
                  !(
                    !AuthService.canUpdate(
                      PermissionFeature.FEAccountingType
                    ) || shouldDisableControl
                  ) &&
                  _.isEmpty(this.state.usefullLife)
                    ? "Required"
                    : ""
                }
                variant="standard"
              />
            )}
          />
        </div>
      );
    };
    const getPurchaserControl = () => {
      const equipmentClass = "col-lg-6 mt-4 mt-sm-4 mt-lg-0";
      const requestClass = "col-lg-12 mt-4 mt-sm-4 mt-lg-3";
      let actualClass = "";
      if (this.props.festate.activeSubHeader == FE_Tabs.MyRequests) {
        actualClass = requestClass;
      } else {
        actualClass = equipmentClass;
      }
      return (
        <div className={actualClass}>
          <Autocomplete
            id="equipmentPurchaser"
            options={this.state.purchaserOptions}
            filterOptions={(options) => options}
            autoHighlight={true}
            getOptionLabel={(option) => {
              if (!_.isEmpty(option)) {
                return `${option.name}-${option.memberNumber} ${option.region}/${option.facility}`;
              } else {
                return "";
              }
            }}
            ChipProps={{ classes: { root: "py-2 " } }}
            onInputChange={(e, value, reason) => {
              if (reason == "input") {
                this.purchaserSearchHandler(value);
              }
            }}
            value={this.state.equipmentPurchaser}
            onChange={(e, value, reason) => {
              FEServices.changeHanlderServerSiderDropDown(
                e,
                value,
                reason,
                "equipmentPurchaser",
                this
              );
            }}
            disabled={
              (!AuthService.canUpdate(
                PermissionFeature.FEAccountingPurchaser
              ) &&
                !isRequest) ||
              (shouldDisableControl && !isRequest) ||
              (this.state.status === InventoryRequestStatus.Closed &&
                isRequest &&
                this.state.hasActiveEquipment) //Disable it for Closed Request having Active Equipment
            }
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    className:
                      "EQ-Purchaser pb-1" +
                      (params.inputProps && " " + params.inputProps.className),
                  }}
                  variant="standard"
                  label={ExclamationLabel(
                    "Purchaser",
                    this.state,
                    this.state.purchaser
                  )}
                  InputLabelProps={{
                    shrink: true,
                    classes: { shrink: "mt-n1" },
                  }}
                  required={this.state.submittedVal !== 3}
                  error={showErrorForPurchaserControl()}
                  helperText={showPurchaserCtrlHelpText()}
                  placeholder="Select Purchaser"
                />
              );
            }}
          />
        </div>
      );
    };
    const getPurchaseDateControl = () => {
      const equipmentClass = "col-lg-6 mt-3 mt-sm-4 mt-lg-0";
      const requestClass = "col-lg-6 mt-3 mt-sm-4 mt-md-2";
      let actualClass = "";
      if (this.props.festate.activeSubHeader == FE_Tabs.MyRequests) {
        actualClass = requestClass;
      } else {
        actualClass = equipmentClass;
      }
      return (
        <div className={actualClass}>
          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
            {/* <small>Purchase Date:</small> */}
            <small>
              {ExclamationLabel(
                "Purchase Date:",
                this.state,
                this.state.purchasedate
              )}
            </small>
          </span>
          <DatePickerInput
            readOnly="readonly"
            name="purchasedate"
            onChange={(val) =>
              FEServices.onDateChange(val, this, "purchasedate")
            }
            disabled={
              (!AuthService.canUpdate(
                PermissionFeature.FEAccountingPurchaseDate
              ) &&
                this.state.equipmentid > 0 &&
                !isRequest) ||
              (shouldDisableControl && !isRequest) ||
              (this.state.status === InventoryRequestStatus.Closed &&
                isRequest &&
                this.state.hasActiveEquipment)
            }
            value={this.state.purchasedate}
          />
        </div>
      );
    };
    const getDescriptionControl = () => {
      return (
        <div className="col-lg-4 text_ctrl mt-4 mt-sm-4 mt-lg-0">
          <div className="form-group eq-desc">
            <MDBInput
              id="equipment_desc"
              value={this.state.description}
              name="description"
              onChange={(e) => FEServices.handleChange(e, this)}
              label="Description"
              className={`mt-5 ${
                DescriptionCtrlPermission() == true ? "grayoutText" : " xcxd"
              }`}
              hint="Enter the equipment description"
              group
              type="text"
              disabled={DescriptionCtrlPermission()}
              required
            >
              <div className="invalid-feedback">Required</div>
            </MDBInput>
          </div>
        </div>
      );
    };

    const getCategoryControl = () => {
      return (
        <>
          <div className="col-lg-4 mt-2 mt-sm-2 mt-lg-0">
            <Autocomplete
              id="equipmentCategory"
              options={this.state.equipmentCategoryOptions}
              getOptionLabel={(option) => option.val}
              onChange={(e, val, reason) =>
                FEServices.changeValueHandler(
                  e,
                  val,
                  reason,
                  "equipmentCategory",
                  this
                )
              }
              disabled={
                !AuthService.canUpdate(
                  PermissionFeature.FEAccountingCategory
                ) || shouldDisableControl
              }
              value={this.state.equipmentCategory}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  value={this.state.equipmentCategory}
                  //validators={["required"]}
                  //errorMessages={["Equipment Category is required"]}
                  //variant="standard"
                  placeholder="Select Equipment Category"
                  InputLabelProps={{
                    shrink: true,
                    classes: { shrink: "mt-n1" },
                  }}
                  variant="standard"
                  required={true}
                  error={
                    this.state.issubmit &&
                    !(
                      !AuthService.canUpdate(
                        PermissionFeature.FEAccountingCategory
                      ) || shouldDisableControl
                    ) &&
                    _.isEmpty(this.state.equipmentCategory)
                      ? true
                      : false
                  }
                  helperText={
                    this.state.issubmit &&
                    !(
                      !AuthService.canUpdate(
                        PermissionFeature.FEAccountingCategory
                      ) || shouldDisableControl
                    ) &&
                    _.isEmpty(this.state.equipmentCategory)
                      ? "Required"
                      : ""
                  }
                />
              )}
            />
          </div>
        </>
      );
    };

    const getStickerNoControl = (isRequest) => {
      let margin = "";
      let colClass = "col-lg-4";
      if (isRequest) {
        margin = "mt-2";
        colClass = "col-lg-4";
      } else if (
        this.state.equipmentType &&
        this.state.equipmentType.key == 1
      ) {
        colClass = "col-lg-3";
      }

      return (
        <div className={`${colClass} text_ctrl ${margin}`}>
          <div className="form-group ">
            <MDBInput
              variant="standard"
              value={this.state.stickernumber}
              name="stickernumber"
              onChange={(e) => FEServices.handleChange(e, this)}
              // label="Sticker #"
              label={ExclamationLabel(
                "Sticker #",
                this.state,
                this.state.stickernumber
              )}
              // className="mt-3 mt-sm-0"
              className={`mt-3 mt-sm-0 ${
                shouldDisableStickerNoCtrl() == true ? "grayoutText" : ""
              }`}
              group
              type="text"
              disabled={shouldDisableStickerNoCtrl()}
            />
          </div>
        </div>
      );
    };

    // Showed for Requests Page
    const getYearControl = (isRequest) => {
      let margin = "";
      let colClass = "col-lg-4";
      if (isRequest) {
        margin = "mt-2";
        colClass = "col-lg-4";
      } else if (
        this.state.equipmentType &&
        this.state.equipmentType.key == 1
      ) {
        colClass = "col-lg-3";
      }
      return (
        <div className={`${colClass} text_ctrl ${margin}`}>
          <div className="form-group ">
            <TextField
              size="medium"
              /// label="Year #"
              label={ExclamationLabel("Year", this.state, this.state.year)}
              name="year"
              value={this.state.year}
              //variant="standard"
              onChange={(e) => FEServices.handleChange(e, this)}
              placeholder="Enter Year"
              InputLabelProps={{
                shrink: true,
                classes: { shrink: "mt-n1" },
              }}
              disabled={
                (!AuthService.canUpdate(
                  PermissionFeature.FEAccountingStickerNumber
                ) &&
                  this.state.equipmentid > 0 &&
                  !isRequest) ||
                (shouldDisableControl && !isRequest) ||
                (this.state.status === InventoryRequestStatus.Closed &&
                  isRequest &&
                  this.state.hasActiveEquipment) ||
                (!isAccountingPerson &&
                  this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
                  this.state.equipmentid > 0)
              }
              fullWidth
              variant="standard"
              required={
                this.state.issubmit &&
                this.state.submittedVal == 1 &&
                _.isEmpty(this.state.serialnumber) &&
                !_.isEmpty(this.state.equipmentType) &&
                this.state.equipmentType.key == 1
                  ? true
                  : false
              }
              error={
                this.state.issubmit &&
                this.state.submittedVal == 1 &&
                !(
                  (!AuthService.canUpdate(
                    PermissionFeature.FEAccountingSerialNumber
                  ) &&
                    this.state.equipmentid > 0) ||
                  (shouldDisableControl && !isRequest) ||
                  (this.state.status === InventoryRequestStatus.Closed &&
                    isRequest &&
                    this.state.hasActiveEquipment)
                ) &&
                _.isEmpty(this.state.serialnumber) &&
                !_.isEmpty(this.state.equipmentType) &&
                this.state.equipmentType.key == 1
                  ? true
                  : false
              }
              helperText={
                this.state.issubmit &&
                this.state.submittedVal == 1 &&
                !(
                  (!AuthService.canUpdate(
                    PermissionFeature.FEAccountingSerialNumber
                  ) &&
                    this.state.equipmentid > 0) ||
                  (shouldDisableControl && !isRequest) ||
                  (this.state.status === InventoryRequestStatus.Closed &&
                    isRequest &&
                    this.state.hasActiveEquipment)
                ) &&
                _.isEmpty(this.state.serialnumber) &&
                !_.isEmpty(this.state.equipmentType) &&
                this.state.equipmentType.key == 1
                  ? "Required"
                  : ""
              }
            />
          </div>
        </div>
      );
    };
    const getYearMDBInputControl = (isRequest) => {
      let margin = "";
      let colClass = "col-lg-4";
      if (isRequest) {
        margin = "mt-2";
        colClass = "col-lg-4";
      } else if (
        this.state.equipmentType &&
        this.state.equipmentType.key == 1
      ) {
        colClass = "col-lg-3";
      }
      return (
        <div className={`${colClass} text_ctrl ${margin}`}>
          <div className="form-group ">
            <MDBInput
              variant="standard"
              value={this.state.year}
              name="year"
              onChange={(e) => FEServices.handleChange(e, this)}
              // label="Sticker #"
              label={ExclamationLabel("Year", this.state, this.state.year)}
              // className="mt-3 mt-sm-0"
              className={`mt-3 mt-sm-0 ${
                shouldDisableStickerNoCtrl() == true ? "grayoutText" : ""
              }`}
              group
              type="text"
              fullWidth
              disabled={shouldDisableStickerNoCtrl()}
              required={
                this.state.submittedVal == 1 &&
                this.state.issubmit &&
                _.isEmpty(this.state.serialnumber) &&
                !_.isEmpty(this.state.equipmentType) &&
                this.state.equipmentType.key == 1
                  ? true
                  : false
              }
            />
          </div>
        </div>
      );
    };
    const shouldDisableStickerNoCtrl = () => {
      let r =
        (!AuthService.canUpdate(PermissionFeature.FEAccountingStickerNumber) &&
          this.state.equipmentid > 0 &&
          !isRequest) ||
        (shouldDisableControl && !isRequest) ||
        (this.state.status === InventoryRequestStatus.Closed &&
          isRequest &&
          this.state.hasActiveEquipment) ||
        (!isAccountingPerson &&
          this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
          this.state.equipmentid > 0);
      return r;
    };
    const getWarrantyInfoControl = (isRequest) => {
      let margin = "";
      let colClass = "col-lg-4";
      if (isRequest) {
        margin = "mt-2";
        colClass = "col-lg-4";
      } else if (
        this.state.equipmentType &&
        this.state.equipmentType.key == 1
      ) {
        colClass = "col-lg-3";
      }
      return (
        <div className={`${colClass} text_ctrl ${margin}`}>
          <div className="form-group ">
            <MDBInput
              value={this.state.warrantyinformation}
              name="warrantyinformation"
              onChange={(e) => FEServices.handleChange(e, this)}
              ///label="Warranty"
              /// className="mt-2 mt-sm-0"
              label={ExclamationLabel(
                "Warranty",
                this.state,
                this.state.warrantyinformation
              )}
              className={`mt-2 mt-sm-0 ${
                shouldDisableWarrantyInformationCtrl() == true
                  ? "grayoutText"
                  : ""
              }`}
              group
              type="text"
              disabled={shouldDisableWarrantyInformationCtrl()}
            />
          </div>
        </div>
      );
    };

    const shouldDisableWarrantyInformationCtrl = () => {
      var result =
        (!AuthService.canUpdate(
          PermissionFeature.FEAccountingWarrantyInformaion
        ) &&
          this.state.equipmentid > 0 &&
          !isRequest) ||
        (shouldDisableControl && !isRequest) ||
        (this.state.status === InventoryRequestStatus.Closed &&
          isRequest &&
          this.state.hasActiveEquipment) ||
        (!isAccountingPerson &&
          this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
          this.state.equipmentid > 0);
      return result;
    };
    const getSerialNoControl = (isRequest) => {
      let classes = "";
      if (isRequest) {
        classes = "col-lg-4 text_ctrl mt-3 mt-sm-3 mt-lg-2";
      } else if (
        this.state.equipmentType &&
        this.state.equipmentType.key == 1
      ) {
        classes = "col-lg-3 text_ctrl mt-3 mt-sm-3 mt-lg-2";
      }
      return (
        <div className={classes}>
          <TextField
            // size="medium"
            /// label="Serial #"
            label={ExclamationLabel(
              "Serial #",
              this.state,
              this.state.serialnumber
            )}
            name="serialnumber"
            value={this.state.serialnumber}
            //variant="standard"
            onChange={(e) => FEServices.handleChange(e, this)}
            placeholder="Enter serial"
            InputLabelProps={{
              shrink: true,
              classes: { shrink: "mt-n1" },
            }}
            disabled={
              (!AuthService.canUpdate(
                PermissionFeature.FEAccountingStickerNumber
              ) &&
                this.state.equipmentid > 0 &&
                !isRequest) ||
              (shouldDisableControl && !isRequest) ||
              (this.state.status === InventoryRequestStatus.Closed &&
                isRequest &&
                this.state.hasActiveEquipment) ||
              (!isAccountingPerson &&
                this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
                this.state.equipmentid > 0)
            }
            variant="standard"
            required={
              this.state.issubmit &&
              this.state.submittedVal == 1 &&
              _.isEmpty(this.state.serialnumber) &&
              !_.isEmpty(this.state.equipmentType) &&
              this.state.equipmentType.key == 1
                ? true
                : false
            }
            error={
              this.state.issubmit &&
              this.state.submittedVal == 1 &&
              !(
                (!AuthService.canUpdate(
                  PermissionFeature.FEAccountingSerialNumber
                ) &&
                  this.state.equipmentid > 0) ||
                (shouldDisableControl && !isRequest) ||
                (this.state.status === InventoryRequestStatus.Closed &&
                  isRequest &&
                  this.state.hasActiveEquipment)
              ) &&
              _.isEmpty(this.state.serialnumber) &&
              !_.isEmpty(this.state.equipmentType) &&
              this.state.equipmentType.key == 1
                ? true
                : false
            }
            helperText={
              this.state.issubmit &&
              this.state.submittedVal == 1 &&
              !(
                (!AuthService.canUpdate(
                  PermissionFeature.FEAccountingSerialNumber
                ) &&
                  this.state.equipmentid > 0) ||
                (shouldDisableControl && !isRequest) ||
                (this.state.status === InventoryRequestStatus.Closed &&
                  isRequest &&
                  this.state.hasActiveEquipment)
              ) &&
              _.isEmpty(this.state.serialnumber) &&
              !_.isEmpty(this.state.equipmentType) &&
              this.state.equipmentType.key == 1
                ? "Required"
                : ""
            }
            fullWidth
          />
        </div>
      );
    };
    const getSerialNoMDBInputControl = (isRequest) => {
      let margin = "";
      let colClass = "col-lg-4";
      if (isRequest) {
        margin = "mt-2";
        colClass = "col-lg-4";
      } else if (
        this.state.equipmentType &&
        this.state.equipmentType.key == 1
      ) {
        colClass = "col-lg-3";
      }
      return (
        <div className={`${colClass} text_ctrl ${margin}`}>
          <div className="form-group ">
            <MDBInput
              value={this.state.serialnumber}
              name="serialnumber"
              onChange={(e) => FEServices.handleChange(e, this)}
              ///label="Serial #"
              className="mt-2 mt-sm-0"
              label={ExclamationLabel(
                "Serial #",
                this.state,
                this.state.serialnumber
              )}
              group
              type="text"
              disabled={
                (!AuthService.canUpdate(
                  PermissionFeature.FEAccountingStickerNumber
                ) &&
                  this.state.equipmentid > 0 &&
                  !isRequest) ||
                (shouldDisableControl && !isRequest) ||
                (this.state.status === InventoryRequestStatus.Closed &&
                  isRequest &&
                  this.state.hasActiveEquipment) ||
                (!isAccountingPerson &&
                  this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
                  this.state.equipmentid > 0)
              }
            />
          </div>
        </div>
      );
    };
    const getCommentsControl = () => {
      const equipmentClass = "col-12 text_ctrl";
      const requestClass = "col-12 text_ctrl mt-3 mt-md-0";
      let actualClass = "";
      if (this.props.festate.activeSubHeader == FE_Tabs.MyRequests) {
        actualClass = requestClass;
      } else {
        actualClass = equipmentClass;
      }
      return (
        <div className="row">
          <div className={actualClass}>
            <div className="form-group commmentStyle">
              <MDBInput
                value={this.state.comments}
                name="comments"
                onChange={(e) => FEServices.handleChange(e, this)}
                label="Comments"
                className="mt-5"
                group
                type="textarea"
                //false Editable
                //true  Disabled
                disabled={commmentsPermission()}
                // required
              >
                {/* <div className="invalid-feedback">Comments are required.</div> */}
              </MDBInput>
            </div>
          </div>
        </div>
      );
    };
    const getCostControl = () => {
      const equipmentClass = "col-lg-6 pl-1 fedollaramt";
      const requestClass = "col-lg-6 pl-1 fedollaramt mt-2";
      let actualClass = "";
      if (this.props.festate.activeSubHeader == FE_Tabs.MyRequests) {
        actualClass = requestClass;
      } else {
        actualClass = equipmentClass;
      }
      return (
        <div className={actualClass}>
          <MDBInput
            containerClass="mb-0"
            // className="EQ-Cost equipment-control pl-1"
            className={`EQ-Cost equipment-control pl-1${
              (!AuthService.canUpdate(PermissionFeature.FEAccountingCost) &&
                this.state.equipmentid > 0 &&
                !isRequest) ||
              (shouldDisableControl && !isRequest) ||
              (this.state.status === InventoryRequestStatus.Closed &&
                isRequest &&
                this.state.hasActiveEquipment) ||
              (!isAccountingPerson &&
                this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
                this.state.equipmentid > 0)
                ? " grayoutText"
                : ""
            }`}
            name="cost"
            size="sm"
            // label="Cost"
            label={ExclamationLabel("Cost", this.state, this.state.cost)}
            hint="Provide the equipment cost"
            icon="dollar-sign"
            iconClass="position-absolute"
            value={this.state.cost}
            maxLength="16"
            onChange={(e) => FEServices.handleChangeAmount(e, "cost", this)}
            disabled={
              (!AuthService.canUpdate(PermissionFeature.FEAccountingCost) &&
                this.state.equipmentid > 0 &&
                !isRequest) ||
              (shouldDisableControl && !isRequest) ||
              (this.state.status === InventoryRequestStatus.Closed &&
                isRequest &&
                this.state.hasActiveEquipment) ||
              (!isAccountingPerson &&
                this.props.festate.activeSubHeader !== FE_Tabs.MyRequests &&
                this.state.equipmentid > 0)
            }
            group
            type="text"
            required
          >
            <small className="invalid-feedback text-center font-italic">
              Please enter equipment Cost.
            </small>
          </MDBInput>
          <small className="text-danger pl-3 font-italic">
            {this.state.cost_err}
          </small>
        </div>
      );
    };

    const showErrorForPurchaserControl = () => {
      var canUpdatePurchaser =
        AuthService.canUpdate(PermissionFeature.FEAccountingPurchaser) ||
        isRequest;
      var firstOperand = !(
        !canUpdatePurchaser ||
        (shouldDisableControl && !isRequest) ||
        (this.state.status === InventoryRequestStatus.Closed &&
          isRequest &&
          this.state.hasActiveEquipment)
      );

      let showError =
        this.state.issubmit &&
        firstOperand &&
        _.isEmpty(this.state.equipmentPurchaser) &&
        this.state.submittedVal !== 3
          ? true
          : false;

      return showError;
    };

    const showPurchaserCtrlHelpText = () => {
      var canUpdatePurchaser =
        AuthService.canUpdate(PermissionFeature.FEAccountingPurchaser) ||
        isRequest;
      var firstOperand = !(
        !canUpdatePurchaser ||
        (shouldDisableControl && !isRequest) ||
        (this.state.status === InventoryRequestStatus.Closed &&
          isRequest &&
          this.state.hasActiveEquipment)
      );

      let result =
        this.state.issubmit &&
        firstOperand &&
        _.isEmpty(this.state.equipmentPurchaser) &&
        this.state.submittedVal !== 3
          ? "Required"
          : "";

      return result;
    };

    let isDoubleClosed = false;
    let markClose = 0;
    if (_.isEmpty(this.state.requestHistory)) {
      let closedMessage = this.state.requestHistory.filter(
        (f) => f.actionMessage == "Closed"
      );
      if (closedMessage.length > 1) {
        isDoubleClosed = true;
      }
    }
    const markClosed = () => {
      markClose = ++markClose;
    };
    const saveButtonLabel = () => {
      let saveBtnLableText = "Save";
      if (
        this.state.isRequestOpened &&
        this.state.status == InventoryRequestStatus.Approved
      ) {
        saveBtnLableText = "Save and Close";
      }
      return saveBtnLableText;
    };

    /// Condition to show 'Request Details' of Approved Status in Equipment Modal
    const justShowSaveBtn = () => {
      if (
        this.state.isRequestOpened &&
        this.state.status == InventoryRequestStatus.Approved
      ) {
        return true;
      } else {
        return false;
      }
    };
    const loadingEquipmentModal = () => {
      return (
        this.state.pageLoaded &&
        !(this.state.loadingPurchaser || this.state.loadingUsers)
      );
    };
    const canAddNewSubCategory = () => {
      return AuthService.canAdd(PermissionFeature.FESubCategory);
    };

    /// console.log("shouldDisableControl", shouldDisableControl);
    return (
      <AUX>
        {
          <Modal
            className="modal fade team-style"
            id="addEquipmentModal"
            size="xl"
            backdrop="static"
            show={this.props.showModal}
            centered
            onShow={this.onShow}
            onHide={this.handleClose}
          >
            <Modal.Header
              className="modal-header bg-secondary py-2"
              closeButton
            >
              <Modal.Title className="w-100 modal-title font-italic">
                <div className="feModalHeader align-items-center d-flex text-uppercase">
                  <div className="col-sm-6 mt-2">
                    <h5 className="text-white mt-0 feheaderTitle">
                      {allHidden() &&
                      this.state.pageLoaded &&
                      this.state.loadingPurchaser &&
                      this.state.loadingUsers ? (
                        <span>We are Sorry...</span>
                      ) : (
                        ""
                      )}
                      {!loadingEquipmentModal() && (
                        <span className="pr-3">Loading...</span>
                      )}
                      {!allHidden() &&
                      loadingEquipmentModal() &&
                      this.state.equipmentid == 0
                        ? "Add New Equipment"
                        : ""}
                      {!allHidden() &&
                      this.state.equipmentid !== 0 &&
                      loadingEquipmentModal()
                        ? `${this.state.description} ${
                            !_.isEmpty(this.state.equipmentUser)
                              ? "-" + this.state.equipmentUser.name
                              : ""
                          }`
                        : ""}
                    </h5>
                  </div>
                  <div className="col-sm-6 float-right text-right w-25 text-white mt-0 eqStatusLable">
                    {showStatusLabel() && !allHidden() && (
                      <h5>{this.state.statusName}</h5>
                    )}
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                {loadingEquipmentModal() && !allHidden() && (
                  <form
                    className="needs-validation"
                    id="addEquipmentForm"
                    onSubmit={this.onSubmit}
                    noValidate
                  >
                    {ShowEquipmentDetailsSection && (
                      <Card className="mb-1">
                        <h6
                          className="card-header mb-3 mt-0 cursorPointer"
                          onClick={() => {
                            this.setState(
                              {
                                isEquipmentDetailsOpen: !this.state
                                  .isEquipmentDetailsOpen,
                              },
                              () => {}
                            );
                          }}
                        >
                          <i class="fas fa-industry pr-1" />
                          {this.state.isRequestOpened && "REQUEST DETAILS"}
                          {!this.state.isRequestOpened && "EQUIPMENT DETAILS"}

                          <span className="d-none pk">
                            pkEquipment = {this.state.equipmentid}
                            noIdColumn = {this.state.id}
                            currentEquipStatus ={this.state.currEquipmentStatus}
                            hasEquipment={this.state.hasActiveEquipment}
                          </span>
                          {this.state.isManualEntry && this.state.id !== 0 && (
                            <div className="cursorPointer text-primary hover text-right font-weight-bold small mt-2 float-right textlink">
                              <a
                                onClick={() =>
                                  this.viewRelatedRequest(
                                    this.state.equipmentid
                                  )
                                }
                              >
                                View Request
                              </a>
                            </div>
                          )}
                        </h6>
                        {/* First Card/Accordion Controls start below */}
                        <Collapse isOpen={this.state.isEquipmentDetailsOpen}>
                          {/* First Row of first card */}
                          <div className="row">
                            {(AuthService.canView(
                              PermissionFeature.FEAccountingUser
                            ) ||
                              this.state.isRequestOpened) && (
                              <div className="col-lg-4">
                                <Autocomplete
                                  id="equipmentUser"
                                  options={this.state.usersOptions}
                                  filterOptions={(options) => options}
                                  autoHighlight={true}
                                  getOptionLabel={(option) => {
                                    if (!_.isEmpty(option)) {
                                      return `${option.name} - ${
                                        option.memberNumber
                                      } ${
                                        option.region
                                          ? option.region +
                                            "/" +
                                            option.facility
                                          : ""
                                      }`;
                                    } else {
                                      return "";
                                    }
                                  }}
                                  ChipProps={{ classes: { root: "py-2" } }}
                                  onInputChange={(e, value, reason) => {
                                    if (reason == "input") {
                                      this.usersSearchHandler(value);
                                    }
                                  }}
                                  disabled={
                                    !AuthService.canUpdate(
                                      PermissionFeature.FEAccountingUser
                                    ) || shouldDisableControl
                                  }
                                  value={this.state.equipmentUser}
                                  onChange={(e, value, reason) => {
                                    FEServices.changeHanlderServerSiderDropDown(
                                      e,
                                      value,
                                      reason,
                                      "equipmentUser",
                                      this
                                    );
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          className:
                                            "pb-1" +
                                            (params.inputProps &&
                                              " " +
                                                params.inputProps.className),
                                        }}
                                        variant="standard"
                                        label="User"
                                        value={this.state.equipmentUser}
                                        InputLabelProps={{
                                          shrink: true,
                                          classes: { shrink: "mt-n1" },
                                        }}
                                        // error={
                                        //   this.state.issubmit &&
                                        //   !(
                                        //     !AuthService.canUpdate(
                                        //       PermissionFeature.FEAccountingUser
                                        //     ) || shouldDisableControl
                                        //   ) &&
                                        //   _.isEmpty(this.state.equipmentUser)
                                        //     ? true
                                        //     : false
                                        // }
                                        // helperText={
                                        //   this.state.issubmit &&
                                        //   !(
                                        //     !AuthService.canUpdate(
                                        //       PermissionFeature.FEAccountingUser
                                        //     ) || shouldDisableControl
                                        //   ) &&
                                        //   _.isEmpty(this.state.equipmentUser)
                                        //     ? "Required"
                                        //     : ""
                                        // }
                                        // required={true}
                                        placeholder="Select User"
                                      />
                                    );
                                  }}
                                />
                              </div>
                            )}
                            {this.state.isRequestOpened &&
                              getDescriptionControl()}
                            {(AuthService.canView(
                              PermissionFeature.FEAccountingType
                            ) ||
                              isRequest) &&
                              getTypeControl()}

                            {!this.state.isRequestOpened &&
                              AuthService.canView(
                                PermissionFeature.FEAccountingUsefulLife
                              ) &&
                              getUsefulLifeControl()}
                          </div>

                          {/* Second Row of first accordion */}
                          <div
                            className={`${
                              this.state.isRequestOpened
                                ? "row mt-1 mt-md-1"
                                : "row mt-1 mt-md-3"
                            }`}
                          >
                            {this.state.isRequestOpened && (
                              <div className="col-lg-4 mt-2 mt-md-1 pl-2.1 pb-2">
                                <div className="dateCtrl">
                                  <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                    <small>Requested Date:</small>
                                  </span>
                                  <DatePickerInput
                                    readOnly="readonly"
                                    name="requestdate"
                                    disabled={true}
                                    onChange={(val) =>
                                      FEServices.onDateChange(
                                        val,
                                        this,
                                        "requestdate"
                                      )
                                    }
                                    value={this.state.requestdate}
                                  />
                                  {this.state.issubmit && (
                                    <span className="text-danger">
                                      {this.state.date_err}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                            {this.state.isRequestOpened && (
                              // AuthService.canView(
                              //     PermissionFeature.FERequestApprovedAmount
                              // ) && (
                              <div className="col-md-4 mt-md-0 mt-lg-0 approvedAmtMt">
                                {this.state.showTooltip && (
                                  <div className="tooltipAmt">
                                    <i
                                      style={{ color: "blue" }}
                                      href="#"
                                      id="TooltipAprAmt"
                                      class={`fa fa-info-circle pl-2 text-dark cursorPointer float-right`}
                                    />
                                    <Tooltip
                                      placement="left"
                                      isOpen={this.state.tooltipOpen}
                                      target="TooltipAprAmt"
                                      toggle={this.toggle}
                                      className="ttOnModal"
                                    >
                                      <small>
                                        Requested Amount :
                                        {this.state.requestedAmount.toLocaleString(
                                          navigator.language,
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: "currency",
                                            currency: "USD",
                                          }
                                        )}
                                      </small>
                                    </Tooltip>
                                  </div>
                                )}
                                <MDBInput
                                  containerClass="mb-0"
                                  className="equipment-control grayoutText"
                                  name="cost"
                                  size="sm"
                                  label="Approved Amount"
                                  hint="Provide the approved amount"
                                  icon="dollar-sign"
                                  iconClass="position-absolute"
                                  value={this.state.approvedAmount}
                                  maxLength="16"
                                  onChange={(e) =>
                                    FEServices.handleChangeAmount(
                                      e,
                                      "approvedAmount",
                                      this
                                    )
                                  }
                                  disabled={true}
                                  //required
                                  group
                                  type="text"
                                  required
                                >
                                  <div className="invalid-feedback">
                                    Please enter approved amount.
                                  </div>
                                </MDBInput>
                                <span className="text-danger">
                                  {this.state.approvedAmount_err}
                                </span>
                              </div>
                            )}
                            {/* Common in both Equipement and Request */}
                            {/* If Approved Request is opened then canView should 
                            be ignored */}
                            {/* {(AuthService.canView(
                              PermissionFeature.FEAccountingBudgetID
                            ) ||
                              this.state.isRequestOpened) && (
                              <div
                                className={`${
                                  this.state.isRequestOpened
                                    ? "col-lg-4 mt-4 mt-sm-4 mt-lg-0"
                                    : "col-lg-6 mt-4 mt-sm-4 mt-lg-0"
                                } `}
                              >
                                <Autocomplete
                                  id="equipmentBudgetline"
                                  options={this.state.budgetLinesOptions}
                                  filterOptions={(options) => options}
                                  autoHighlight={true}
                                  getOptionLabel={(option) => {
                                    if (
                                      !_.isEmpty(option) &&
                                      !_.isEmpty(option.approverMember)
                                    ) {
                                      return `${option.name}-${option.approverMember.firstname} ${option.approverMember.lastname}`;
                                    } else {
                                      return "";
                                    }
                                  }}
                                  ChipProps={{ classes: { root: "py-2 " } }}
                                  onInputChange={(e, value, reason) => {
                                    if (reason == "input") {
                                      this.budgetLinesSearchHandler(value);
                                    }
                                  }}
                                  value={this.state.equipmentBudgetline}
                                  onChange={(e, value, reason) => {
                                    FEServices.changeHanlderServerSiderDropDown(
                                      e,
                                      value,
                                      reason,
                                      "equipmentBudgetline",
                                      this
                                    );
                                  }}
                                  disabled={
                                    !AuthService.canUpdate(
                                      PermissionFeature.FEAccountingBudgetID
                                    ) || shouldDisableControl
                                  }
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          className:
                                            "pb-1" +
                                            (params.inputProps &&
                                              " " +
                                                params.inputProps.className),
                                        }}
                                        variant="standard"
                                        label="Budget Line"
                                        InputLabelProps={{
                                          shrink: true,
                                          classes: { shrink: "mt-n1" },
                                        }}
                                        placeholder="Select Budget Line"
                                        required={true}
                                        error={
                                          this.state.issubmit &&
                                          !(
                                            !AuthService.canUpdate(
                                              PermissionFeature.FEAccountingBudgetID
                                            ) || shouldDisableControl
                                          ) &&
                                          _.isEmpty(
                                            this.state.equipmentBudgetline
                                          )
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          this.state.issubmit &&
                                          !(
                                            !AuthService.canUpdate(
                                              PermissionFeature.FEAccountingBudgetID
                                            ) || shouldDisableControl
                                          ) &&
                                          _.isEmpty(
                                            this.state.equipmentBudgetline
                                          )
                                            ? "Required"
                                            : ""
                                        }
                                      />
                                    );
                                  }}
                                />
                              </div>
                            )} */}
                            {/* Will be displayed in Equipment View Only */}
                            {/* {!this.state.isRequestOpened &&
                              AuthService.canView(
                                PermissionFeature.FEAccountingDescription
                              ) &&
                              getDescriptionControl()} */}
                          </div>

                          {/* ####Third Row of First Accordion##### */}
                          {!this.state.isRequestOpened && (
                            <div className="row mt-3">
                              {!this.state.isRequestOpened &&
                                AuthService.canView(
                                  PermissionFeature.FEAccountingDescription
                                ) &&
                                getDescriptionControl()}
                              {!this.state.isRequestOpened &&
                                AuthService.canView(
                                  PermissionFeature.FEAccountingCategory
                                ) &&
                                getCategoryControl()}

                              {/* Should be only displayed in Equipment View PopUp */}
                              {!this.state.isRequestOpened && (
                                <>
                                  {AuthService.canView(
                                    PermissionFeature.FEAccountingCategory
                                  ) && (
                                    // Dropdown for selecting Facility/Region/Teams Option
                                    <div className="col-lg-4 mt-4 mt-sm-4 mt-lg-0">
                                      <Autocomplete
                                        id="equipmentFRT"
                                        freeSolo={
                                          this.state.category == 4 &&
                                          canAddNewSubCategory()
                                        }
                                        autoSelect={
                                          this.state.category == 4 &&
                                          canAddNewSubCategory()
                                            ? true
                                            : false
                                        }
                                        clearOnBlur={
                                          this.state.category == 4 &&
                                          canAddNewSubCategory()
                                            ? false
                                            : true
                                        }
                                        options={this.state.equipmentFRTOptions}
                                        // getOptionLabel={(option) => option.val}
                                        getOptionLabel={(option) => {
                                          // Value selected with enter, right from the input
                                          if (typeof option === "string") {
                                            return option;
                                          }
                                          // Add "xxx" option created dynamically
                                          if (option.inputValue) {
                                            return option.val + " New Option";
                                          }
                                          // if (!_.isEmpty(option)) {
                                          //   return option.val;
                                          // } else {
                                          //   return "";
                                          // }
                                          return option.val;
                                        }}
                                        onChange={(e, val, reason) =>
                                          FEServices.changeValueHandler(
                                            e,
                                            val,
                                            reason,
                                            "equipmentFRT",
                                            this
                                          )
                                        }
                                        filterOptions={(options, params) => {
                                          const filtered = filter(
                                            options,
                                            params
                                          );
                                          let canAddNewCat = canAddNewSubCategory();
                                          let categoryId = this.state.category;
                                          let inputExistsInOptions = filtered.findIndex(
                                            (x) =>
                                              x.val.toUpperCase() ==
                                              params.inputValue.toUpperCase()
                                          );
                                          // Suggest the creation of a new value
                                          if (
                                            params.inputValue !== "" &&
                                            categoryId == 4 &&
                                            canAddNewCat &&
                                            (_.isEmpty(filtered) ||
                                              inputExistsInOptions == -1)
                                          ) {
                                            filtered.push({
                                              inputValue: `${params.inputValue}`,
                                              key: 0,
                                              val: `Add "${params.inputValue}"`,
                                            });
                                          }

                                          return filtered;
                                        }}
                                        disabled={
                                          (!AuthService.canUpdate(
                                            PermissionFeature.FEAccountingCategory
                                          ) &&
                                            this.state.equipmentid > 0) ||
                                          shouldDisableControl
                                        }
                                        value={this.state.equipmentFRT}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Sub-Category"
                                            value={this.state.equipmentFRT}
                                            //validators={["required"]}
                                            // errorMessages={["F/R/T Category is required"]}
                                            //variant="standard"
                                            placeholder="Equipment used for"
                                            InputLabelProps={{
                                              shrink: true,
                                              classes: { shrink: "mt-n1" },
                                            }}
                                            variant="standard"
                                            required={true}
                                            error={
                                              this.state.issubmit &&
                                              !(
                                                (!AuthService.canUpdate(
                                                  PermissionFeature.FEAccountingCategory
                                                ) &&
                                                  this.state.equipmentid > 0) ||
                                                shouldDisableControl
                                              ) &&
                                              _.isEmpty(this.state.equipmentFRT)
                                                ? true
                                                : false
                                            }
                                            helperText={
                                              this.state.issubmit &&
                                              !(
                                                (!AuthService.canUpdate(
                                                  PermissionFeature.FEAccountingCategory
                                                ) &&
                                                  this.state.equipmentid > 0) ||
                                                shouldDisableControl
                                              ) &&
                                              _.isEmpty(this.state.equipmentFRT)
                                                ? "Required"
                                                : ""
                                            }
                                          />
                                        )}
                                      />
                                    </div>
                                  )}

                                  {AuthService.canView(
                                    PermissionFeature.FEAccountingStickerNumber
                                  ) && getStickerNoControl()}
                                  {AuthService.canView(
                                    PermissionFeature.FEAccountingWarrantyInformaion
                                  ) && getWarrantyInfoControl()}

                                  {AuthService.canView(
                                    PermissionFeature.FEAccountingSerialNumber
                                  ) && getSerialNoMDBInputControl()}
                                  {this.state.equipmentType &&
                                    this.state.equipmentType.key == 1 &&
                                    getYearMDBInputControl()}
                                </>
                              )}
                            </div>
                          )}

                          {/*### Will be displayed in Equipment### */}
                          <div className="row">
                            {!this.state.isRequestOpened &&
                              AuthService.canView(
                                PermissionFeature.FEAccountingPurchaser
                              ) &&
                              getPurchaserControl()}
                          </div>
                          <AddMultiBudgetlines
                            requestOpenedInEquipment={
                              this.state.isRequestOpened &&
                              this.state.id !== 0 &&
                              (this.state.status ===
                                InventoryRequestStatus.Approved ||
                                this.state.status ===
                                  InventoryRequestStatus.Closed)
                            }
                            isEquipment={true}
                            isRequested={this.state.isRequestOpened}
                            budgetLinesSearchHandler={
                              this.budgetLinesSearchHandler
                            }
                            shouldDisableControl={shouldDisableControl}
                            state={this.state}
                            myself={this}
                            entitykey={this.state.equipmentid}
                            loadBudgetLinesData={this.loadBudgetLinesData}
                            setBudgetLineFormInvalid={
                              this.setBudgetLineFormInvalid
                            }
                          />
                        </Collapse>
                      </Card>
                    )}
                    {/* Second Card or Accordion*/}
                    {ShowAccountingSection && (
                      <Card className="mb-1 mt-3">
                        <h6
                          className="card-header mb-3 mt-0 cursorPointer"
                          onClick={() => {
                            this.setState(
                              {
                                isEquipmentAccountingOpen: !this.state
                                  .isEquipmentAccountingOpen,
                              },
                              () => {}
                            );
                          }}
                        >
                          <i class="fas fa-file-invoice-dollar pr-1" />
                          {this.state.isRequestOpened && "PURCHASE DETAILS"}
                          {!this.state.isRequestOpened && "ACCOUNTING DETAILS"}
                        </h6>

                        <Collapse isOpen={this.state.isEquipmentAccountingOpen}>
                          {/* First Row of second Card */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                {/* Should be only displayed in Approved Request */}
                                {this.state.isRequestOpened &&
                                  // AuthService.canView(
                                  //   PermissionFeature.FEAccountingPurchaser
                                  // ) &&
                                  getPurchaserControl()}

                                {/* Common in both Equipement and Request */}
                                {/* If Approved Request is opened then canView could 
                                  be ignored */}

                                {(AuthService.canView(
                                  PermissionFeature.FEAccountingCost
                                ) ||
                                  this.state.isRequestOpened) &&
                                  getCostControl()}

                                {(AuthService.canView(
                                  PermissionFeature.FEAccountingPurchaseDate
                                ) ||
                                  this.state.isRequestOpened) &&
                                  getPurchaseDateControl()}

                                {/* {this.state.isRequestOpened &&
                                  AuthService.canView(
                                    PermissionFeature.FEAccountingComments
                                  ) &&
                                  getCommentsControl()} */}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                {(AuthService.canView(
                                  PermissionFeature.FEAccountingFiles
                                ) ||
                                  this.state.isRequestOpened) && (
                                  <div
                                    className="pl-3 col-lg-12 mb-3 mt-3 mt-sm-4 mt-lg-0"
                                    id="files-uploader"
                                  >
                                    <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                                      <small>Files:</small>
                                    </span>
                                    <FilePond
                                      disabled={
                                        (!AuthService.canUpdate(
                                          PermissionFeature.FEAccountingFiles
                                        ) &&
                                          this.state.equipmentid > 0 &&
                                          !isRequest) ||
                                        (shouldDisableControl && !isRequest) ||
                                        (this.state.status ===
                                          InventoryRequestStatus.Closed &&
                                          isRequest &&
                                          this.state.hasActiveEquipment)
                                      }
                                      ref={(ref) => (this.pond2 = ref)}
                                      files={this.state.files}
                                      allowMultiple={true}
                                      allowFileTypeValidation={true}
                                      allowImageExifOrientation={true}
                                      allowImagePreview={true}
                                      allowImageCrop={true}
                                      allowFileEncode={true}
                                      acceptedFileTypes={
                                        this.state.acceptedFileTypes
                                      }
                                      fileValidateTypeDetectType={(
                                        source,
                                        type
                                      ) =>
                                        new Promise((resolve, reject) => {
                                          let rejectedExtensions = [
                                            "ade",
                                            "adp",
                                            "apk",
                                            "bat",
                                            "chm",
                                            "cmd",
                                            "com",
                                            "cpl",
                                            "dll",
                                            "dmg",
                                            "exe",
                                            "hta",
                                            "ins",
                                            "isp",
                                            "jar",
                                            "js",
                                            "jse",
                                            "lib",
                                            "lnk",
                                            "mde",
                                            "msc",
                                            "msi",
                                            "msp",
                                            "mst",
                                            "nsh",
                                            "pif",
                                            "scr",
                                            "sct",
                                            "shb",
                                            "sys",
                                            "vb",
                                            "vbe",
                                            "vbs",
                                            "vxd",
                                            "wsc",
                                            "wsf",
                                            "wsh",
                                            "cab",
                                            "doc",
                                            "docx",
                                            "docm",
                                            "dot",
                                            "dotx",
                                            "sql",
                                            "xlsx",
                                            "csv",
                                            "xls",
                                            "xls",
                                          ];
                                          // If the file extension is not in our rejected list.
                                          if (
                                            !rejectedExtensions.some((ext) =>
                                              source.name.endsWith("." + ext)
                                            )
                                          ) {
                                            resolve(type);
                                          } else {
                                            // Otherwise it is rejected.
                                            toast.error(
                                              "File type is unsupported!"
                                            );
                                            //reject(new Error('Files contains unsupported format!'));
                                            reject(type);
                                          }
                                        })
                                      }
                                      oninit={() => this.handleInitFileUpload()}
                                      onupdatefiles={(fileItems) => {
                                        this.uploadFileObject(fileItems);
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {this.state.isRequestOpened &&
                              // AuthService.canView(
                              //   PermissionFeature.FEAccountingWarrantyInformaion
                              // ) &&
                              getWarrantyInfoControl(true)}
                            {this.state.isRequestOpened &&
                              // AuthService.canView(
                              //   PermissionFeature.FEAccountingSerialNumber
                              // ) &&
                              getSerialNoControl(true)}
                            {/* if the type is computer then show Year field */}
                            {this.state.isRequestOpened &&
                              this.state.equipmentType &&
                              this.state.equipmentType.key == 1 &&
                              // AuthService.canView(
                              //   PermissionFeature.FEAccountingStickerNumber
                              // ) &&
                              getYearControl(true)}
                          </div>
                          {/* Second Row */}
                          <div class="mt-1 mt-md-3">
                            {this.state.isRequestOpened &&
                              // AuthService.canView(
                              //   PermissionFeature.FEAccountingComments
                              // ) &&
                              getCommentsControl()}
                          </div>
                        </Collapse>
                      </Card>
                    )}
                    {/* Second Card END*/}
                    {/* Third Card */}
                    {/* Remove the Depreciation section, it should only be displayed
                    for Equipment, not Requests */}
                    <div
                      className={
                        this.state.isRequestOpened
                          ? "DepreciationsSection hidden"
                          : "DepreciationsSection"
                      }
                    >
                      {AuthService.canView(
                        PermissionFeature.FEAccountingDepreciationValues
                      ) &&
                        AuthService.canView(
                          PermissionFeature.FEAccountingUsefulLife
                        ) && (
                          <Card className="mb-1">
                            <h6
                              className="card-header mb-3 mt-0 cursorPointer"
                              onClick={() => {
                                this.setState(
                                  {
                                    isEquipmentDepreciationOpen: !this.state
                                      .isEquipmentDepreciationOpen,
                                  },
                                  () => {}
                                );
                              }}
                            >
                              <i class="fas fa-funnel-dollar pr-1" />
                              DEPRECIATION
                            </h6>

                            <Collapse
                              isOpen={this.state.isEquipmentDepreciationOpen}
                            >
                              {/* First Row */}

                              <>
                                <div className="row ">
                                  {AuthService.canView(
                                    PermissionFeature.FEAccountingAD
                                  ) && (
                                    <div className="col-lg-4 pl-1 fedollaramt">
                                      <MDBInput
                                        containerClass="mb-0"
                                        className="equipment-control pl-1"
                                        name="accdep"
                                        size="sm"
                                        label="Accumulated Depreciation"
                                        icon="dollar-sign"
                                        iconClass="position-absolute"
                                        value={this.state.accdep}
                                        maxLength="16"
                                        disabled={true}
                                        onChange={(e) =>
                                          FEServices.handleChangeAmount(
                                            e,
                                            "accdep",
                                            this
                                          )
                                        }
                                        group
                                        type="text"
                                      />
                                    </div>
                                  )}
                                  {AuthService.canView(
                                    PermissionFeature.FEAccountingBookVal
                                  ) && (
                                    <div className="col-lg-4 pl-1 fedollaramt">
                                      <MDBInput
                                        containerClass="mb-0"
                                        className="equipment-control pl-1"
                                        name="bookval"
                                        size="sm"
                                        label="Book Value"
                                        icon="dollar-sign"
                                        iconClass="position-absolute"
                                        value={this.state.bookval}
                                        disabled={true}
                                        maxLength="16"
                                        onChange={(e) =>
                                          FEServices.handleChangeAmount(
                                            e,
                                            "bookval",
                                            this
                                          )
                                        }
                                        group
                                        type="text"
                                        required
                                      >
                                        <div className="invalid-feedback">
                                          Book value.
                                        </div>
                                      </MDBInput>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="row mt-3"
                                  style={{ minHeight: "5vh" }}
                                >
                                  {this.state.usefullLife &&
                                    this.state.cost &&
                                    this.state.purchasedate && (
                                      <>
                                        <div className="col">
                                          <h6>Depreciation Schedule</h6>
                                        </div>
                                        <div className="col-12 mainTblDepr">
                                          <Table
                                            id="depreciationTbl"
                                            hover
                                            bordered={true}
                                            responsive
                                            size="sm"
                                          >
                                            <DepreciationTable
                                              usefulLife={parseFloat(
                                                this.state.usefullLife &&
                                                  +this.state.usefullLife.val
                                              )}
                                              cost={
                                                parseFloat(
                                                  +this.state.cost.replace(
                                                    /,/g,
                                                    ""
                                                  )
                                                ) || 0
                                              }
                                              year={new Date().getFullYear()}
                                              setDepreciationValues={(arg) =>
                                                this.setDepreciationValues(arg)
                                              }
                                              purchaseDate={
                                                this.state.purchasedate
                                              }
                                            />
                                          </Table>
                                        </div>
                                      </>
                                    )}
                                </div>
                              </>

                              {/* Third Row */}
                            </Collapse>
                          </Card>
                        )}
                    </div>
                    {/* Third Card END*/}
                    {/* for Equipment, not Requests   */}
                    {!this.state.isRequestOpened &&
                      AuthService.canView(
                        PermissionFeature.FEAccountingComments
                      ) &&
                      getCommentsControl()}
                    <div className="row">
                      <div className="col">
                        {AuthService.canView(
                          PermissionFeature.FEAccountingActivityLog
                        ) &&
                          !this.state.isRequestOpened &&
                          this.state.equipmentid > 0 && (
                            <FE_ActivityLogging
                              ref={this.activityLogs}
                              id={this.state.equipmentid}
                              getActicityLoggingByModuleId={
                                this.props.getActicityLoggingByModuleId
                              }
                              festate={this.props.festate}
                              toggleEquipmentLogAccordion={
                                this.props.toggleEquipmentLogAccordion
                              }
                            />
                          )}
                      </div>
                    </div>
                    {/* Incase we open request in Equipment Modal, show request history */}
                    {AuthService.canView(
                      PermissionFeature.FERequestActivityLog
                    ) &&
                      this.state.id > 0 &&
                      this.state.isRequestOpened &&
                      this.state.requestHistory &&
                      this.state.requestHistory.length > 0 && (
                        <Card className="mb-1">
                          <h6
                            className="card-header mb-3 mt-0 cursorPointer"
                            onClick={() => {
                              this.setState(
                                {
                                  isEquipmentHistoryOpen: !this.state
                                    .isEquipmentHistoryOpen,
                                },
                                () => {}
                              );
                            }}
                          >
                            <i class="fas fa-history pr-1" />
                            HISTORY
                          </h6>

                          <Collapse isOpen={this.state.isEquipmentHistoryOpen}>
                            <div className="event-detail pb-3">
                              <div className="history">
                                {!_.isEmpty(this.state.requestHistory) &&
                                  this.state.requestHistory.map((item, idx) => (
                                    <div className="row py-1 mx-0" key={idx}>
                                      {(markClose > 0 && isDoubleClosed) ||
                                        ((!_.isEmpty(item.comments) ||
                                          (_.isEmpty(item.comments) &&
                                            markClose == 0 &&
                                            !isDoubleClosed)) && (
                                          <div className="px-3">
                                            {moment(item.actionDate).isValid()
                                              ? moment(
                                                  new Date(
                                                    item.actionDate + "Z"
                                                  )
                                                ).format("MM/DD/YYYY @ HHMM")
                                              : ""}
                                          </div>
                                        ))}

                                      <div className="px-3">
                                        <div>
                                          {markClose == 0 && !isDoubleClosed && (
                                            <>
                                              {item.actionMessage} by{" "}
                                              <strong>{item.memberName}</strong>
                                            </>
                                          )}

                                          {item.actionMessage == "Closed" &&
                                            markClosed()}
                                        </div>
                                        {!_.isEmpty(item.comments) && (
                                          <div>
                                            <b>Comments:</b> {item.comments}
                                          </div>
                                        )}
                                        {item.reqamtchg && (
                                          <div>
                                            <b>Amount:</b> {item.reqamtchgmsg}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </Collapse>
                        </Card>
                      )}
                    <div className="actionBtns form-group mb-4 row">
                      <div className="col-12 m-t-20 mx-auto text-center">
                        {
                          <>
                            <button
                              className="btn btn-primary w-md waves-effect cancelModal waves-light mr-2 mc"
                              data-dismiss="modal"
                              aria-hidden="true"
                              type="reset"
                              onClick={this.handleClose}
                            >
                              Close
                            </button>
                            {shouldShowSaveBtn() && (
                              <button
                                className="btn btn-success w-md mr-2 mc"
                                disabled={allDisabled}
                                type="submit"
                                onClick={this.setSaveStatus}
                              >
                                {saveButtonLabel()}
                              </button>
                            )}
                            {/* ///Show Btn for not closing the Request/// */}
                            {/* In Approved Status */}

                            {justShowSaveBtn() && (
                              <>
                                <button
                                  className="btn btn-success w-md mr-2 mc"
                                  disabled={allDisabled}
                                  type="submit"
                                  onClick={this.setJustSaveStatus}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-danger w-md waves-effect cancelModal waves-light mc"
                                  // data-dismiss="modal"
                                  // aria-hidden="true"
                                  type="reset"
                                  onClick={this.handleCancelRequest}
                                >
                                  Cancel Request
                                </button>
                              </>
                            )}
                            {shouldShowSaveAndActiveBtn && (
                              <button
                                className="btn bg-primary text-white w-md mc"
                                disabled={allDisabled}
                                type="submit"
                                onClick={this.setSaveAndActiveStatus}
                              >
                                Save And Activate
                              </button>
                            )}
                          </>
                        }
                      </div>
                    </div>
                  </form>
                )}
                {allHidden() &&
                  this.state.pageLoaded &&
                  !this.state.loadingPurchaser &&
                  !this.state.loadingUsers && (
                    <>
                      <div class="container h-100">
                        <div class="row NoDataFoundTable">
                          <h5>
                            You are not authorized to view this content. Please
                            contact administrator.
                          </h5>
                        </div>
                      </div>
                      <div className="actionBtns form-group mb-4 row">
                        <div className="col-12 m-t-20 mx-auto text-center">
                          <button
                            className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                            data-dismiss="modal"
                            aria-hidden="true"
                            type="reset"
                            onClick={this.handleClose}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                {!loadingEquipmentModal() && (
                  <p style={{ padding: "4rem" }}>
                    <Spinner className="spinnerCard" />
                  </p>
                )}
              </>
            </Modal.Body>
          </Modal>
        }
      </AUX>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      festate: state.fetracker,
      activityLoggingState: state.activityLogging,
    }),
    {
      ...FETrackerAction,
      ...ActivityActions,
    }
  )(FE_EquipmentModal)
);
