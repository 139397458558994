import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
import _ from "lodash";

export class BUDate extends Component {
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <label>{label} </label>
        ) : (
          <label> </label>
        )}
        <DatePicker
          {...input}
          dateForm="MM/DD/YYYY"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          onChangeRaw={this.handleDateChangeRaw}
          className={classForm}
          selected={input.value ? moment(input.value).toDate() : null}
          placeholderText={this.props.isMobile === true ? label : ""}
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };

  validateNumber = (value) => {
    // value && isNaN(Number(value))
    // ? "Please enter numeric characters only."
    // : undefined;
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>BU Date</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }  `}
            >
              <Field
                name="FromDate"
                validate={[this.validateNumber]}
                id="FromDate"
                classForm="form-control"
                placeholder="From Date"
                label="From Date"
                component={this.renderInput}
                labelFor="FromDate"
              />
            </Col>
            <Col
              className={`m-2 m-sm-2  ${
                this.props.isMobile === true ? "pr-0 pl-0" : ""
              }  `}
            >
              <Field
                name="ToDate"
                id="ToDate"
                validate={[this.validateNumber]}
                classForm="form-control"
                placeholder="To Date"
                label="To Date"
                component={this.renderInput}
                labelFor="ToDate"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "bUDateForm",
  destroyOnUnmount: false,
})(BUDate);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
