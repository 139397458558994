import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import Services from "../PermissionService";
import { connect } from "react-redux";
import PermissionAction from "../../../store/permission/action";

import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../PermissionFeature";
import Permissions from "../General/Permissions";
import PermissionAlgo from "../General/PermissionAlgo";
class EditDefaultPermission extends Component {
    constructor(props) {
        super(props);

        this.state = {
            teamName: "",
            type: this.props.type,
            parentTeamId: this.props.parentTeamId,
            permissionTeamId: this.props.permissionTeamId,
            originalPermissions: [],
            permissions: [],
        };

        this.clonePermission = this.clonePermission.bind(this);
        this.buttonLoader = this.buttonLoader.bind(this);
        this.loadData = this.loadData.bind(this);
        this.permissions = this.permissions.bind(this);
        this.verifyData = this.verifyData.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
        this.cancelButtonBottom = this.cancelButtonBottom.bind(this);
        this.cancelButtonLoader = this.cancelButtonLoader.bind(this);
        this.savePermissionData = this.savePermissionData.bind(this);
        this.handleSavePermission = this.handleSavePermission.bind(this);
        this.handleResetPermission = this.handleResetPermission.bind(this);
        this.handleChangePermission = this.handleChangePermission.bind(this);
        this.handleConfigurePermissionSection = this.handleConfigurePermissionSection.bind(
            this
        );
    }

    ConvertPermissionToNewScheme(permissions) {
        let originalPermissions = [];
        const createPermissions = (permissions) => {
            permissions.map((item) => {
                originalPermissions.push(Object.assign({}, item));
                if (item.childs) {
                    createPermissions(item.childs);
                }
            });
        };
        createPermissions(permissions);
        return originalPermissions;
    }

    clonePermission = (originalPermissions) =>
        originalPermissions.map((item) =>
            (({ isView, isAdd, isUpdate, isDelete, id }) => {
                return {
                    id: item.defaultPermissionID,
                    permissionFeatureId: item.id,
                    isView,
                    isAdd,
                    isUpdate,
                    isDelete,
                };
            })(item)
        );

    handleCancelPermission(e) {
        //e.preventdefault();
        this.handleResetPermission();
        //window.$("#DPModal").modal("hide");
        this.props.closeDefaultPermissionModal();
    }

    handleResetPermission(e) {
        //e.preventdefault();

        this.state.permissions = this.clonePermission(
            this.state.originalPermissions
        );

        this.setState({
            permissions: this.state.permissions,
        });
    }

    handleChangePermission(e) {

        var permissions = this.state.permissions;
        this.setState({ permissions: PermissionAlgo.changeChild(permissions, e) });
    }

    performSlide(parentid) {
        let element = $(".slide-collapsers[data-slide-Id='slide" + parentid + "']");
        let expand = element.attr("data-slide-expand");

        const collapseChild = (parentid, expand) => {
            let element = $(".slide-collapsers[data-slide-Id='" + parentid + "']");

            if (expand) {
                $(".slide-collapsers[data-slide-parentId='" + parentid + "']").addClass(
                    "perm-row-hide"
                );
            } else {
                let expandChild = element.attr("data-slide-expand");

                if (expandChild == "true") {
                    $(
                        ".slide-collapsers[data-slide-parentId='" + parentid + "']"
                    ).removeClass("perm-row-hide");
                }
                //in case false
                else {
                    expand = true;
                }
            }
            $(".slide-collapsers[data-slide-parentId='" + parentid + "']").each(
                function (i) {
                    let parentid2 = $(this).attr("data-slide-Id");
                    if (
                        $(".slide-collapsers[data-slide-parentId='" + parentid2 + "']")
                            .length > 0
                    ) {
                        collapseChild(parentid2, expand);
                    }
                }
            );
        };

        if (expand == "true") {
            $(
                ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
            ).addClass("perm-row-hide");
            element.attr("data-slide-expand", false);
        } else {
            $(
                ".slide-collapsers[data-slide-parentId='slide" + parentid + "']"
            ).removeClass("perm-row-hide");
            element.attr("data-slide-expand", true);
        }
        collapseChild("slide" + parentid, expand == "true" ? true : false);
    }

    handleSavePermission = async (e) => {
        $(".loading").show();
        let data = await Services.EditDefaultPermission(this.state.permissions);
        if (!_.isEmpty(data)) {
            if (data.statusCode == 200) {
                toast.success("Default permissions edited successfully!");
                //this.props.setReloadPosition();
                this.handleConfigurePermissionSection();
                this.props.closeDefaultPermissionModal();
                //window.$("#DPModal").modal("hide");
            }
        }

        $(".loading").hide();
    };



    sectionLoader = () => {
        if (!_.isEmpty(this.state.permissions)) {
            return this.permissions();
        }
    };

    cancelModal = (e) => {
        this.handleCancelPermission();
    };
    verifyData = (e) => {
        this.verifyPermissions();
    };
    buttonLoader = () => {
        return this.savePermissionButton();
    };

    cancelButtonLoader = () => {
        return (
            <button type="button" class="close text-white" onClick={this.cancelModal}>
                <span aria-hidden="true">&times;</span>
            </button>
        );
    };

    handleConfigurePermissionSection = async (e) => {
        $(".loading").show();
        this.props.hideDefaultPermissionModal();
        let data = await Services.DefaultPermissions();

        if (!_.isEmpty(data)) {
            this.state.originalPermissions = this.ConvertPermissionToNewScheme(
                data.result
            );
            await this.setState({
                originalPermissions: this.state.originalPermissions,
            });
            this.props.setDefaultPermissionData(this.state.originalPermissions);
        }

        this.handleResetPermission();

        $(".loading").hide();
        this.props.showDefaultPermissionModal();
    };

    permissions = () => {

        return (
            <Permissions prefix={'DP-'} fromDefaultPermission={true} permissionFeature={this.state.originalPermissions} permissions={this.state.permissions} originalPermissions={this.state.originalPermissions} uncheckClass=" uncheck-light" handleChangePermission={this.handleChangePermission} hasParent={(this.state.type === "childteam")} listId="defaultPermissionList" />
        );
    };

    cancelButtonBottom = () => {
        return (
            <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                onClick={this.cancelModal}
            >
                Cancel
      </button>
        );
    };

    savePermissionButton = () => {
        return (
            <Fragment>
                <hr />
                <div className="row pt-3">
                    <div className="col-4"></div>
                    <div className="col-8">
                        <div className="text-right">
                            {this.cancelButtonBottom()}
                            {AuthService.canUpdate(PermissionFeature.DefaultPermission) && (
                                <button
                                    onClick={this.savePermissionData}
                                    className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                                    type="submit"
                                >
                                    Save
                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    savePermissionData = (e) => {
        this.handleSavePermission();
    };
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {
        // this.handleConfigurePermissionSection();
        // this.GetPermissions();
    }
    loadData() {

        if (this.props.permissionState.loadDataDefaultPermission) {
            if (!_.isEmpty(this.props.permissionState.defaultPermissionData)) {
                this.state.originalPermissions = this.props.permissionState.defaultPermissionData;
                this.setState({
                    originalPermissions: this.state.originalPermissions
                });
                this.handleResetPermission();
            } else {
                this.handleConfigurePermissionSection();
            }
            this.props.completeLoadDataDefaultPermissiion();
        }
    }
    render() {
        this.loadData();
        return (
            <AUX>
                {this.sectionLoader()}

                {this.buttonLoader()}
            </AUX>
        );
    }
}

export default connect((state) => ({ permissionState: state.permission }), {
    ...PermissionAction,
})(EditDefaultPermission);
