import React, { Component } from "react";

import { Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import { toast } from "react-toastify";
import _ from "lodash";

export default class MG_EditEmailAliasModal extends Component {
  state = {
    myAlias: this.props.emailAlias,
  };

  ///Called on Save Button Click
  closeModal = () => {
    let { emailAlias } = this.props;
    if (_.isEmpty(emailAlias)) {
      toast.error("Email Alias is Empty.");
      return;
    }
    this.props.handleClose();
  };
  //Called on Cross button click
  onHide = () => {
    const { myAlias } = this.state;
    let { emailAlias } = this.props;

    this.props.setMgListAlias(myAlias);
    // if (_.isEmpty(emailAlias)) {
    //   toast.error("Email Alias is Empty.");
    //   return;
    // }
    this.props.handleClose();
  };
  //Called on Cancel Button
  onCancel = () => {
    const { myAlias } = this.state;
    let { emailAlias } = this.props;

    this.props.setMgListAlias(myAlias);
    // if (_.isEmpty(emailAlias)) {
    //   toast.error("Email Alias is Empty.");
    //   return;
    // }
    this.props.handleClose();
  };
  onShowModal = () => {
    const { emailAlias } = this.props;
    this.setState({ myAlias: emailAlias });
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        onShow={this.onShowModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Email List Alias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div class="input-group mb-3">
                <input
                  id="emailAlias"
                  type="text"
                  class="form-control"
                  placeholder="Email Alias"
                  aria-label="Email Alias"
                  aria-describedby="basic-addon2"
                  value={this.props.emailAlias}
                  onChange={this.props.handleEmailAliasChange}
                />
                <span class="input-group-text" id="basic-addon2">
                  {this.props.domain}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <MDBInput
                  value={this.props.mailingListName}
                  name="mailingListName"
                  label="Mailing List Name (optional)"
                  group
                  type="text"
                  onChange={this.props.changeHandlerListName}
                ></MDBInput>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.onCancel}>
            Cancel
          </Button>
          <button className="btn btn-success w-md mc" onClick={this.closeModal}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
