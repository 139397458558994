import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import "react-datepicker/dist/react-datepicker.css";
import Filter from "../../Admin/Users/Filters";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";


class TeamSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: this.props.perms,
      page: this.props.args.page,
      filters: [],
      sizePerPage: this.props.args.sizePerPage,
      totalCount: this.props.args.totalCount,
      keyword: this.props.keyvalue,
      selectedOption: null,
      advSearch: this.props.args.advSearch
    };
    this.handleFormSearch = this.handleFormSearch.bind(this);
    //  this.handleAdvSubmit = this.handleAdvSubmit.bind(this);
    this.propMethods = this.props.propMethods();
    this.addFilter = this.addFilter.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.filterHandlers = () => {
      this.changeText = (idx, e) => {
        this.state.filters[idx][e.target.name] = e.target.value;
        this.setState({ filters: this.state.filters });
      };

      return this;
    };
  }

  addFilter() {
    const select = document.getElementById("filterType");
    const filterType = select.value;
    const filterLabel = select.options[select.selectedIndex].label;
    const filterTypeName =
      select.options[select.selectedIndex].dataset.filtertype;
    const filter = {
      type: {
        label: filterLabel,
        name: filterType,
        filterType: filterTypeName
      }
    };
    this.state.filters.push(filter);
    this.setState({ filters: this.state.filters });
  }
  handleRemove = e => {
    const idx = parseInt(e.target.dataset.id);
    this.state.filters.splice(idx, 1);
    this.setState({
      filters: this.state.filters
    });
  };
  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };
  handleAdvSubmit(e) {
    e.preventDefault();
    $(".loading").show();
    const url = `/api/Team/GetTeams`;
    const formData = {
      Keyword: $("#keyword").val(),
      PageNo: 1,
      PageSize: this.state.sizePerPage,
      OperatorType: $("#operator").val()
    };
    let adSearch = Object.assign(
      {},
      ...this.state.filters.map(el => {
        let obj = {};
        for (let key in el) {
          if (key != "type") {
            obj[key] = el[key];
          }
        }

        return obj;
      })
    );

    Object.assign(formData, adSearch);
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          page: 1
        });
        this.props.updateTable({
          page: 1,
          data: data.data,
          totalCount: data.totalCount,
          keyword: $("#keyword").val()
        });
        $(".loading").hide();
        $(".advance-search").toggleClass("d-none");
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }
  handleFormSearch(e) {
    e.preventDefault();
    $(".loading").show();
    const url = `../api/Team/GetTeams`;
    const formData = {
      Keyword: $("#keyword").val(),
      PageNo: 1,
      PageSize: this.state.sizePerPage,
      Name: "",
      MemberName: "",
      PositionName: "",
      OperatorType: "AND",
      SortOrder: "NameAsc"
    };
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          page: 1
        });

        this.props.propMethods().updateTable({
          page: 1,
          data: data.data,
          totalCount: data.totalCount,
          keyword: $("#keyword").val()
        });
        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }
  componentDidMount() {
    if (this.props.keyvalue) {
      $("#keyword").val(this.props.keyvalue);
    }
  }

  render() {
    return (
      <AUX>
        <div className="page-title-box">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h4 className="text-uppercase page-title-search">Teams</h4>
            
              <form
                role="search"
                className="app-search mt-0"
                onSubmit={this.handleFormSearch}
              >
                <div className="form-group mb-0">
                  <input
                    type="text"
                    id="keyword"
                    className="form-control rounded border mb-1 w-100"
                    placeholder="Team Search.."
                  />
                  <button type="submit" className="c-pointer text-secondary bg-transparent border-0">
                    <i className="fa fa-search" />
                    
                  </button>
                  {/* 
                  <a
                    className="as-link"
                    href="#"
                    onClick={() => $(".advance-search").toggleClass("d-none")}
                  >
                    <i className="fas fa-caret-down" /> ADVANCED SEARCH
                  </a> */}
                </div>
              </form>
            </div>

          </div>
        </div>


      </AUX >
    );
  }
}

export default TeamSearch;
