import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/advancedfilter/action";

class DutyStation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: false
    };
  }
  onChange(item) {
    var dutyStationIndex = _.findIndex(
      this.props.filterState.filterData.dutyStation,
      o => {
        return o.dutyStation === item.dutyStation;
      }
    );
    item.checked = !this.props.filterState.filterData.dutyStation[
      dutyStationIndex
    ].checked;
    this.props.filterState.filterData.dutyStation[dutyStationIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData
    });
  }
  chechboxes(item) {
    return (
      <li class="chechbox-list_item" key={"dtliitem-" + item.dutyStation}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"dtitem-" + item.dutyStation}
        >
          <input
            type="checkbox"
            htmlFor={"dtitem-" + item.dutyStation}
            value={item.dutyStation}
            checked={item.checked}
            className="custom-control-input"
            id={"dtitem-" + item.dutyStation}
            name={"dtitem-" + item.dutyStation}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"dtitem-" + item.dutyStation}
          >
            {item.dutyStation}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Duty Station</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="search-ad">
          <input
            class="form-control"
            type="text"
            autoComplete="off"
            placeholder="Search"
            value={this.state.query || ""}
            onChange={event => this.setState({ query: event.target.value })}
          />
        </div>
        <div class="filter-fields-scroll">
          <ul class="chechbox-list longTextchbx">
            {this.props.filterState.filterData.dutyStation &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.dutyStation
                .filter(itemQ =>
                  _.toLower(itemQ.dutyStation).includes(
                    _.toLower(this.state.query || "")
                  )
                )
                .map(item => this.chechboxes(item))}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect(state => ({ filterState: state.advancedFilter }), {
  ...actionFilter
})(DutyStation);
