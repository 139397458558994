const MailingListMembersTables = Object.freeze({
  DefaultSubscribers: 1,
  DefaultUnSubscribers: 2,
  AdditionalMemberRecipients: 3,
  AdditionalTeamRecipients: 4,
  AdditionalNonNatcaRecipients: 5,
  NonNatcaUnSubscribers: 6,
  AdditionalTeamUnSubscribers: 7,
});
export default MailingListMembersTables;
//     public enum SubscribersTabTables
//     {
//         DefaultSubscribers = 1,
//         DefaultUnSubscribers,
//         AdditionalMemberRecipients,
//         AdditionalTeamRecipients,
//         AdditionalNonNatcaRecipients,
//         NonNatcaUnSubscribers,
//         AdditionalTeamUnSubscribers
//     }
