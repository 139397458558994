import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import advanceFilterActions from "../../../../store/advancedfilter/action";
import fetrackerActions from "../../../../store/feTracker/action";
import { bindActionCreators } from "redux";

//console.log("My react version is:", React.version);
class FE_Statuses extends Component {
  onChange(item) {
    const actions = this.props.isMemberAF
      ? this.props.advanceFilterActions
      : this.props.fetrackerActions;

    const reduxState = this.props.isMemberAF
      ? this.props.filterState
      : this.props.festate;

    //From Member's Advance Filter
    if (this.props.isMemberAF && this.props.isInventoryTab) {
      var statuses = reduxState.filterData.fe_request_statuses;
    } ///From FE's Equipments Advance Filter
    else if (!this.props.isMemberAF && this.props.isInventoryTab) {
      var statuses = reduxState.filterData.fe_equipment_statuses;
    } ///From FE's Requests Advance Filter
    else {
      var statuses = reduxState.filterData.fe_request_statuses;
    }
    var index = _.findIndex(statuses, (o) => {
      return o.status === item.status;
    });
    item.checked = !statuses[index].checked;
    statuses[index] = item;
    actions.setFilterData({
      ...reduxState.filterData,
    });

    // if (this.props.isMemberAF && this.props.isInventoryTab) {
    //   //Get all checked items
    //   const checkedOptions = statuses.filter((f) => f.checked);
    //   const totalChecked = checkedOptions.length;

    //   if (checkedOptions.length == 2 && item.checked) {
    //     checkedOptions.forEach((element, index) => {
    //       if (index < totalChecked - 1) {
    //         let array1 = checkedOptions[index].memberids.split(",");
    //         let array2 = checkedOptions[++index].memberids.split(",");
    //         var filteredArray = array1.filter(function(n) {
    //           return array2.indexOf(n) !== -1;
    //         });

    //         if (filteredArray.length > 0) {
    //           /// common/duplicate members found
    //           let oneoptionid = checkedOptions[index - 1].id;
    //           var index = _.findIndex(statuses, (o) => {
    //             return o.id === oneoptionid;
    //           });
    //           let oldmembers = statuses[index].memberids.split(",");
    //           let filterMembers = oldmembers.filter(
    //             (f) => !filteredArray.includes(f)
    //           );
    //           statuses[index].oldmembers = oldmembers;
    //           statuses[index].memberids = filterMembers.join(",");
    //           actions.setFilterData({
    //             ...reduxState.filterData,
    //           });
    //         } else if (filteredArray.length == 0) {
    //           if (_.size(item.memberids) < _.size(item.oldmembers)) {
    //             var index = _.findIndex(statuses, (o) => {
    //               return o.id === item.id;
    //             });
    //             statuses[index].memberids = item.oldmembers.join(",");
    //             actions.setFilterData({
    //               ...reduxState.filterData,
    //             });
    //           }
    //         }
    //       }
    //     });
    //   } else if (checkedOptions.length > 2) {
    //     checkedOptions
    //       .filter((f) => f.id != item.id)
    //       .forEach((element, index, arr) => {
    //         if (item.checked && index < totalChecked - 1) {
    //           let array1 = arr[index].memberids.split(",");
    //           let array2 = item.memberids.split(",");
    //           var filteredArray = array1.filter(function(n) {
    //             return array2.indexOf(n) !== -1;
    //           });

    //           if (filteredArray.length > 0) {
    //             /// common/duplicate members found
    //             let oneoptionid = arr[index].id;
    //             var index = _.findIndex(statuses, (o) => {
    //               return o.id === oneoptionid;
    //             });
    //             let oldmembers = statuses[index].memberids.split(",");
    //             let filterMembers = oldmembers.filter(
    //               (f) => !filteredArray.includes(f)
    //             );
    //             statuses[index].oldmembers = oldmembers;
    //             statuses[index].memberids = filterMembers.join(",");
    //             actions.setFilterData({
    //               ...reduxState.filterData,
    //             });
    //           }
    //         }
    //       });
    //   } else if (!item.checked) {
    //     checkedOptions
    //       .filter((f) => f.id != item.id)
    //       .forEach((element, index, arr) => {
    //         if (index < totalChecked - 1 && !_.isEmpty(arr[index].oldmembers)) {
    //           let array1 = arr[index].oldmembers;
    //           let array2 = item.memberids.split(",");
    //           var filteredArray = array1.filter(function(n) {
    //             return array2.indexOf(n) !== -1;
    //           });

    //           if (filteredArray.length > 0) {
    //             /// common/duplicate members found
    //             let oneoptionid = arr[index].id;
    //             var index = _.findIndex(statuses, (o) => {
    //               return o.id === oneoptionid;
    //             });
    //             let currentmemberids = statuses[index].memberids.split(",");
    //             currentmemberids = [...currentmemberids, ...filteredArray];
    //             // let filterMembers = oldmembers.filter(
    //             //   (f) => !filteredArray.includes(f)
    //             // );
    //             //statuses[index].oldmembers = oldmembers;
    //             statuses[index].memberids = currentmemberids.join(",");
    //             actions.setFilterData({
    //               ...reduxState.filterData,
    //             });
    //           }
    //         }
    //       });
    //   }
    //   console.log("total checked onChange", checkedOptions.length);
    // }
  }
  chechboxes(item, statuses, mySelf) {
    // const dynamicCount = item.memberids ? item.memberids.split(",").length : 0;
    // const checkedOptions = statuses.filter((f) => f.checked);
    // console.log("total checked from chechboxes", checkedOptions.length);
    return (
      <li class="chechbox-list_item" key={"fesliitem-" + item.status}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"fesitem-" + item.status}
        >
          <input
            type="checkbox"
            htmlFor={"fesitem-" + item.status}
            value={item.status}
            checked={item.checked}
            className="custom-control-input"
            id={"fesitem-" + item.status}
            name={"fesitem-" + item.status}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            htmlFor={"fesitem-" + item.status}
            style={{ color: this.props.isMobile ? "white" : null }}
          >
            {item.status}
            {/* {mySelf.props.isMemberAF &&
              item.checked &&
              checkedOptions.length > 1 && (
                <small className="totalCount">{`(${dynamicCount})`}</small>
              )}
            {mySelf.props.isMemberAF &&
              (checkedOptions.length == 1 || !item.checked) && (
                <small className="totalCount">{`(${item.total})`}</small>
              )} */}
            {mySelf.props.isMemberAF && (
              <small className="totalCount">{`(${item.total})`}</small>
            )}
          </label>
        </span>
      </li>
    );
  }

  render() {
    const reduxState = this.props.isMemberAF
      ? this.props.filterState
      : this.props.festate;

    if (this.props.isMemberAF && this.props.isInventoryTab) {
      var statuses = reduxState.filterData.fe_request_statuses;
      ///Inventory Tab => Equipment Module
    } else if (!this.props.isMemberAF && this.props.isInventoryTab) {
        var statuses = reduxState.filterData.fe_equipment_statuses;
    } else if (!this.props.isMemberAF && !this.props.isInventoryTab) {
      var statuses = reduxState.filterData.fe_request_statuses;
    }
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4> Status</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="filter-fields-scroll">
          <ul className="chechbox-list">
            {statuses &&
              !_.isEmpty(reduxState) &&
              statuses.map((item) => this.chechboxes(item, statuses, this))}
          </ul>
        </div>
        <div className="height-scroll" />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    festate: state.fetracker,
    filterState: state.advancedFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetrackerActions: bindActionCreators(fetrackerActions, dispatch),
    advanceFilterActions: bindActionCreators(advanceFilterActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FE_Statuses);
