import React from "react";
import { createUseStyles } from "react-jss";

// How to? https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

const useStyles = createUseStyles({
  /* Customize the label (the container) */
  container: {
    display: "flex",
    position: "relative",
    paddingLeft: "35px",
    marginBottom: "12px",
    cursor: "pointer",
    fontSize: "22px",
    userSelect: "none",
    "& input": {
      /*  Hide the browser's default checkbox  */
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0,
      /* Show the checkmark when checked */
      "&:checked": {
        "& ~ $checkmark": {
          /* When the checkbox is checked, add a blue background */
          backgroundColor: "#2196F3",
          "&:after": {
            display: "block",
          },
        },
      },
    },

    "& $checkmark": {
      "&:after": {
        left: "9px",
        top: "5px",
        width: "5px",
        height: "10px",
        border: "solid white",
        borderWidth: "0 3px 3px 0",
        transform: "rotate(45deg)",
      },
    },

    /* On mouse-over, add a grey background color */
    "&:hover": {
      "& input": {
        "& ~ $checkmark": {
          backgroundColor: "#ccc",
        },
      },
    },
  },

  checkmark: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "25px",
    width: "25px",
    backgroundColor: "#eee",
    "&:after": {
      content: '""',
      position: "absolute",
      display: "none",
    },
  },
});

export default function TestCheckBoxes() {
  const classes = useStyles();
  return (
    <div className="App">
      <h1>How to customize checkbox with JSS</h1>

      <label className={classes.container}>
        One
        <input type="checkbox" checked={true} />
        <span className={classes.checkmark} />
      </label>
      <label className={classes.container}>
        two
        <input type="checkbox" checked={false} />
        <span className={classes.checkmark} />
      </label>
      <label className={classes.container}>
        three
        <input type="checkbox" />
        <span className={classes.checkmark} />
      </label>
    </div>
  );
}
