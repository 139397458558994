import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import Services from "./EditUserService";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import { MDBInput } from "mdbreact";

class FacebookIdModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      FacebookId: "",
      FacebookEdit: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.requiredValidation = this.requiredValidation.bind(this);
  }

  requiredValidation(val) {
    let error = "";
    if (!val || val == null || (val && val.trim() == "")) {
      error = "Please provide FaceBook Id.";
    }
    return error;
  }

  async submitForm() {
    let error = this.requiredValidation(this.state.FacebookId);

    if (error == "") {
      $(".loading").show();
      let data = await Services.SaveFacebookId(
        this.props.memberId,
        this.state.FacebookId
      );
      if (data != null) {
        this.closeModal();

        await this.props.LoadData();
      }

      $(".loading").hide();
    }
    await this.setState({ FacebookId_err: error });
  }
  componentDidMount() {}
  componentDidUpdate(prevprops) {
    if (this.props.showModal) {
      this.openModal();

      this.setState({
        FacebookId: this.props.FacebookId,
        FacebookEdit: this.props.FacebookEdit,
        FacebookId_err: "",
      });
    }
  }
  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeFacebookIdFormModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
    this.props.resetFacebookSubscription();
  }
  handleChangeFacebookId = (e) => {
    let error = this.requiredValidation(e.target.value);
    this.setState({
      FacebookId: e.target.value,
      FacebookId_err: error,
    });
  };

  render() {
    return (
      <Modal
        centered={true}
        autoFocus={true}
        isOpen={this.state.openModal}
        className="styled-modal"
        backdrop="static"
        size="md"
        id="FacebookIdForm"
      >
        <ModalHeader
          className="modal-header bg-secondary py-2"
          toggle={this.closeModal}
        >
          {this.state.FacebookEdit ? "Edit Facebook ID" : "Add Facebook ID"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 my-2">
              <MDBInput
                containerClass="my-0"
                className="py-0 mb-0"
                name="FacebookId"
                label="Facebook ID"
                value={this.state.FacebookId}
                onChange={this.handleChangeFacebookId}
                hint="provide your Facebook Id"
                type="text"
              />
              <span className="text-danger">{this.state.FacebookId_err}</span>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div class="text-right">
            <Button
              className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
              onClick={this.closeModal}
            >
              Cancel
            </Button>

            <button
              class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
              type="submit"
              onClick={this.submitForm}
            >
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
export default FacebookIdModal;
