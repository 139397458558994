import React, { Component, Fragment } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Services from "./EditUserService";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import TableCustomTotal from "../../../Shared/TableCustomTotal";
import _ from "lodash";
import $ from "jquery";
import jsPDF from "jspdf";
import { Link } from "react-router-dom";
import { Collapse, Card } from "reactstrap";
import FE_Equipment_Card_Types from "../../../F&E/FE_Equipment_Card_Types";
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Equipment Found!</h5>
    </div>
  </div>
);

const MyExportCSV = (props) => {
  const handleClick = async () => {
    const formData = {
      recordId: props.recordId,
      recordtype: props.TableType,
      searchCriteria: {
        Keyword: props.keyword,
        PageNo: 1,
        PageSize: 10,
        SortOrder: "id",
      },
    };
    showSpinner();
    let data = await Services.MemberEquipments(formData);

    if (!_.isEmpty(data)) {
      props.onExport(data);
    } else {
      console.log("error block");
    }
    hideSpinner();
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.TableType, "pdf");
  // /////Server side Export/////
  const formData = {
    recordId: allprops.recordId,
    recordtype: allprops.TableType,
    searchCriteria: {
      Keyword: allprops.keyword,
      PageNo: 1,
      PageSize: 10,
      SortOrder: "id",
    },
  };
  showSpinner();
  let data = await Services.MemberEquipments(formData);
  if (!_.isEmpty(data)) {
    var tableBody = data.map((row) => {
      var listRow = [];
      let userName = `${row.userFirstName}-${row.userLastName}`;
      listRow.push(userName);
      listRow.push(row.description);
      listRow.push(row.typeName);
      listRow.push(row.category);
      listRow.push(row.budget);
      listRow.push(row.cost);
      listRow.push(row.adVal);
      listRow.push(row.bookVal);
      return listRow;
      // let team = row.teamName;
      // let facility = row.facilityCode;
      // let region = row.regionCode;
      // let cellcontent = "";
      // if (!_.isEmpty(team)) {
      //   cellcontent = cellcontent.concat(team);
      // }
      // if (!_.isEmpty(facility)) {
      //   cellcontent = cellcontent.concat(
      //     `${!_.isEmpty(team) ? +"-" + facility : facility}`
      //   );
      // }
      // if (!_.isEmpty(region)) {
      //   cellcontent = cellcontent.concat(
      //     `${!_.isEmpty(facility) ? +"-" + region : region}`
      //   );
      // }
    });
    const doc = new jsPDF();

    doc.autoTable({
      head: [
        [
          "User",
          "Description",
          "Type",
          "Category",
          "BudgetLine",
          "Cost",
          "A/D",
          "Book Value",
        ],
      ],
      body: tableBody,
      tableWidth: "auto",
      styles: { cellPadding: 0.7, fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },
      },
    });
    doc.save(filename);
  }

  hideSpinner();

  ///////////////////////////////
};
const showSpinner = () => {
  $(".loading").show();
};
const currencyFormatter = (cell, row) => {
  return (
    <span>
      {cell
        ? `$${cell &&
            cell.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
        : "$0"}
    </span>
  );
};
const hideSpinner = () => {
  $(".loading").hide();
};
const getExportFileName = (tblType, filetype) => {
  if (tblType === FE_Equipment_Card_Types.Member) {
    return `${Date.now()}_MemberEquipments.${filetype}`;
  }
  if (tblType === FE_Equipment_Card_Types.Team) {
    return `${Date.now()}_TeamEquipments.${filetype}`;
  }
  if (tblType === FE_Equipment_Card_Types.Facility) {
    return `${Date.now()}_FacilityEquipments.${filetype}`;
  }
  if (tblType === FE_Equipment_Card_Types.Region) {
    return `${Date.now()}_RegionEquipments.${filetype}`;
  }
};

class EquipmentsCardList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Equipments: [],
      sizePerPageEquipments: 10,

      ///Equpment Search Box
      eqMemberSearchTxt: "",
      eqMemberSearchTxt_err: "",
      valid: true,
      isEquipmentListOpen: true,
    };

    this.EquipmentsSizePerPageChange = this.EquipmentsSizePerPageChange.bind(
      this
    );

    this.getEquipments = this.getEquipments.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
  }

  EquipmentsSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageEquipments: sizePerPage });
  };
  EquipmentsColumns = (tableType) => [
    {
      text: "User",
      dataField: "userFirstName",
      sort: true,
      classes: "c-pointer text-nowrap",
      headerClasses: "text-nowrap",
      csvExport: true,
      hidden: !(
        AuthService.canView(PermissionFeature.FEAccountingUser) &&
        tableType != FE_Equipment_Card_Types.Member
      ),

      //should hide on Member Details Page, and show on other Pages
      formatter: (cell, row) => {
        if (AuthService.canView(PermissionFeature.FEAccountingUser)) {
          return (
            <div>
              <Link
                to={{
                  pathname: "/directory/userAction/" + row.userId,
                }}
              >
                {`${cell}-${
                  _.isEmpty(row.userLastName) ? "" : row.userLastName
                }`}
              </Link>
            </div>
          );
        } else {
          return "";
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FEAccountingDescription),
      classes: "c-pointer",
      // headerClasses: "text-nowrap",
      // headerAttrs: {
      //   width: 200,
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "17%" };
      },
      searchable: false,
    },
    {
      text: "Type",
      dataField: "typeName",
      hidden: !AuthService.canView(PermissionFeature.FEAccountingType),
      sort: true,
      classes: "c-pointer",
      // headerClasses: "text-nowrap",
      // headerAttrs: {
      //   width: 200,
      // },
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      searchable: false,
    },

    {
      text: "Region",
      dataField: "regionCode",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.RegionCode),
      classes: "c-pointer",
      headerClasses: "text-nowrap",
      hidden: true,
      csvExport: false,
      searchable: false,
    },
    {
      text: "Facility",
      dataField: "facilityCode",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.FacilityCode),
      classes: "c-pointer",
      headerClasses: "text-nowrap",
      hidden: true,
      csvExport: false,
      searchable: false,
    },
    {
      text: "Team",
      dataField: "teamName",
      sort: true,
      hidden: !AuthService.canView(PermissionFeature.TeamsTeams),
      classes: "c-pointer text-nowrap",
      headerClasses: "text-nowrap",
      hidden: true,
      csvExport: false,
      searchable: false,
    },
    {
      text: "Category",
      dataField: "category",
      sort: true,
      classes: "c-pointer",
      // headerClasses: "text-nowrap",
      csvExport: true,
      searchable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: "Budget",
      dataField: "budget",
      sort: true,
      classes: "c-pointer",
      // headerClasses: "text-nowrap",
      csvExport: true,
      searchable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
    },
    {
      text: "Cost",
      dataField: "cost",
      sort: true,
      classes: "c-pointer text-nowrap",
      headerClasses: "text-nowrap",
      csvExport: true,
      formatter: currencyFormatter,
      searchable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: "A/D",
      dataField: "adVal",
      sort: true,
      classes: "c-pointer",
      // headerClasses: "text-nowrap",
      csvExport: true,
      formatter: currencyFormatter,
      searchable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },
    {
      text: "Book",
      dataField: "bookVal",
      sort: true,
      classes: "c-pointer",
      // headerClasses: "text-nowrap",
      csvExport: true,
      formatter: currencyFormatter,
      searchable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
    },

    // {
    //   text: "Category",
    //   dataField: "equipmentCat",
    //   sort: true,
    //   hidden: !AuthService.canView(PermissionFeature.TeamsTeams),
    //   csvExport: false,
    //   classes: "c-pointer text-nowrap",
    //   headerClasses: "text-nowrap",
    //   headerAttrs: {
    //     width: 175,
    //   },
    //   formatter: (cell, row) => {
    //     let team = row.teamName;
    //     let facility = row.facilityCode;
    //     let region = row.regionCode;
    //     let cellcontent = "";
    //     if (!_.isEmpty(team)) {
    //       cellcontent = cellcontent.concat(team);
    //     }
    //     if (!_.isEmpty(facility)) {
    //       cellcontent = cellcontent.concat(
    //         `${!_.isEmpty(team) ? +"-" + facility : facility}`
    //       );
    //     }
    //     if (!_.isEmpty(region)) {
    //       cellcontent = cellcontent.concat(
    //         `${!_.isEmpty(facility) ? +"-" + region : region}`
    //       );
    //     }
    //     return cellcontent;
    //   },
    // },
  ];

  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    TableType,
    recordId,
    keyword
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      paginationTotalRenderer: TableCustomTotal,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      sizePerPage,
    };
    return (
      <div>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          exportCSV={{
            fileName: getExportFileName(TableType, "csv"),
            exportAll: false,
            onlyExportFiltered: false,
          }}
        >
          {(props) => (
            <div
              className={`equipCardsTblMainDiv pt-2 mt-3 ${
                TableType == FE_Equipment_Card_Types.Member
                  ? "memberEqsTable"
                  : ""
              } `}
            >
              <div className="row">
                <div className="col d-flex">
                  <MyExportCSV
                    TableType={TableType}
                    recordId={recordId}
                    keyword={keyword}
                    {...props.csvProps}
                  />
                  <MyExportPDF
                    currentTable={`equipments_${TableType}_Records`}
                    data={data}
                    recordId={recordId}
                    TableType={TableType}
                    keyword={keyword}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <BootstrapTable
                    keyField={keyFields}
                    data={data}
                    columns={columns}
                    className="table"
                    pagination={
                      data.length > sizePerPage
                        ? paginationFactory({ ...paginationOptions })
                        : null
                    }
                    {...props.baseProps}
                  />
                </div>
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
  getEquipments = async (recordId, tableType) => {
    $(".loading").show();
    const formData = {
      recordId,
      recordtype: tableType,
      searchCriteria: {
        Keyword: this.state.eqMemberSearchTxt,
        PageNo: 1,
        PageSize: 10,
        SortOrder: "id",
      },
    };

    let data = await Services.MemberEquipments(formData);

    if (!_.isEmpty(data) && !_.isNull(data)) {
      this.state.Equipments = data;
      this.setState({
        Equipments: this.state.Equipments,
      });
    } else {
      this.setState({
        Equipments: [],
      });
    }

    $(".loading").hide();
  };
  componentDidMount() {
    this.getEquipments(this.props.recordId, this.props.tableType);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recordId != this.props.recordId) {
      this.getEquipments(this.props.recordId, this.props.tableType);
    }
  }
  ///Equipment related search event handlers
  searchTextChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    var inputValue = event.target.value.trim();
    if (inputValue !== "") {
      if (
        inputValue.indexOf(" ") > 0 &&
        !(
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[0]) &&
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[1])
        )
      ) {
        /// Contains 2 words &  One of them contains character other than alphabets -- Invalid
        //$("#" + event.target.id).addClass("inputBorderRed");
        $(".eqMemberSrchkeyword").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Name should contain alphabets only.",
        });
        this.setState({ valid: false });
      } else if (
        !(/^[a-zA-Z ]*$/.test(inputValue) || /^[0-9]*$/.test(inputValue))
      ) {
        /// alphanumeric or special characers with one word
        $(".eqMemberSrchkeyword").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Alphanumeric is not allowed.",
        });
        this.setState({ valid: false });
      } else {
        $(".eqMemberSrchkeyword").removeClass("inputBorderRed");
        this.setState({ [event.target.name + "_err"]: "" });
        this.setState({ valid: true });
      }
    } else {
      /// searching with empty field
      // this.setState({
      //     [event.target.name + "_err"]: "No search criteria entered."
      // });
      this.setState({ valid: true });
      this.setState({ [event.target.name + "_err"]: "" });
      $(".eqMemberSrchkeyword").removeClass("inputBorderRed");
    }
  };

  handleEqSearch = (e) => {
    e.preventDefault();
    if (this.state.valid) {
      // this.getPACMembers(this.state.pacMemberSearchTxt, this.props.facility);
    } else {
      if (this.state.eqMemberSearchTxt.trim() === "") {
        // this.getPACMembers("", this.props.facility);
      }
      return false;
    }
  };
  searchTextKeyupHandler = (event) => {
    if (event.keyCode == 13) {
      this.handleSearchClick(event);
    }
  };

  handleSearchClick = (e) => {
    e.preventDefault();
    if (this.state.valid) {
      this.getEquipments(this.props.recordId, this.props.tableType);
    }
    // else {
    //   if (this.state.eqMemberSearchTxt.trim() === "") {
    //     this.setState({
    //       eqMemberSearchTxt_err: "No search criteria entered.",
    //     });
    //     $("#eqMemberSearchkeyword").addClass("inputBorderRed");
    //   }
    //   return false;
    // }
  };
  render() {
    const equipmentsList = !_.isEmpty(this.state.Equipments)
      ? this.state.Equipments
      : [];

    let adVal = equipmentsList.reduce((pv, cv) => pv + (cv.adVal || 0), 0);
    let bookVal = equipmentsList.reduce((pv, cv) => pv + (cv.bookVal || 0), 0);
    let cost = equipmentsList.reduce((pv, cv) => pv + (cv.cost || 0), 0);
    let totalEqs = equipmentsList.length;
    let keyword = this.state.eqMemberSearchTxt;
    let anyEquipments = !_.isEmpty(this.state.Equipments);
    const { tableType, recordId } = this.props;
    return (
      <Fragment>
        <div className="card directory-card border-rounded shadow">
          <>
            {/* Equipments Cards Header */}
            {(this.props.tableType === FE_Equipment_Card_Types.Member ||
              this.props.tableType === FE_Equipment_Card_Types.Team ||
              this.props.tableType === FE_Equipment_Card_Types.Region) && (
              <>
                <h5
                  className="card-header cursorPointer mt-0"
                  onClick={() => {
                    this.setState(
                      {
                        isEquipmentListOpen: !this.state.isEquipmentListOpen,
                      },
                      () => {}
                    );
                  }}
                >
                  <i className="fas fa-laptop mr-2" />
                  Equipment
                  <i
                    className={
                      this.state.isEquipmentListOpen
                        ? "float-right fas arrow-icon fa-chevron-up"
                        : "float-right fas arrow-icon fa-chevron-down"
                    }
                  ></i>
                </h5>
              </>
            )}
            <Collapse isOpen={this.state.isEquipmentListOpen}>
              {/* Search Box Start*/}
              {tableType !== FE_Equipment_Card_Types.Member && anyEquipments && (
                <div className="paddinglt marginAdjust mt-3 mb-3">
                  <div className="d-flex justify-content-center">
                    <form
                      role="search"
                      className="col-sm-9 form-inline"
                      onSubmit={(e) => this.handleSearchClick(e)}
                    >
                      <div className="col-sm-12 pr-0 pl-0">
                        <div className="input-group width100">
                          <input
                            name="eqMemberSearchTxt"
                            type="text"
                            id="eqMemberSearchkeyword"
                            value={this.state.eqMemberSearchTxt}
                            onChange={this.searchTextChangeHandler}
                            onKeyUp={this.searchTextKeyupHandler}
                            className="form-control pacMemberSrchkeyword"
                            placeholder="Member Search .."
                          />
                          <div className="input-group-append">
                            <button
                              type="submit"
                              className="pacTopBarSearchBtn"
                            >
                              <i className="fa fa-search" /> Search
                            </button>
                          </div>
                          <small className="text-danger w-100">
                            {this.state.eqMemberSearchTxt_err}
                          </small>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {/* Search Box END*/}
              {!anyEquipments && <NoDataIndication />}
              <>
                {/* Calculated values Start */}
                <div className="d-flex justify-content-center mt-4">
                  {anyEquipments && (
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="d-flex justify-content-center col-sm-6 paddLeftLabel">
                          <span className="font-weight-bold">
                            Equipment Cost:
                          </span>
                          {`$${cost.toLocaleString(navigator.language, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                        </div>

                        <div className="col-sm-6">
                          <span className="font-weight-bold">
                            {new Date().getFullYear()} Accumulated Depreciation:
                          </span>
                          {`$${adVal.toLocaleString(navigator.language, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex justify-content-center col-sm-6 pl-0">
                          <span className="font-weight-bold">Book Value:</span>
                          {`$${bookVal.toLocaleString(navigator.language, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`}
                        </div>
                        <div className="col-sm-6 totalEquipmentsLable">
                          <span className="font-weight-bold"># Equipment:</span>
                          {totalEqs}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* Calculated values END */}
                <div className="card-body pb-3 pt-1">
                  <div className={` ${anyEquipments ? "" : "invisible"}`}>
                    {this.RemotePagination(
                      this.EquipmentsColumns(tableType),
                      "id",
                      this.state.Equipments,
                      this.EquipmentsSizePerPageChange,
                      this.state.sizePerPageEquipments,
                      tableType,
                      recordId,
                      keyword
                    )}
                  </div>
                </div>
              </>
            </Collapse>
          </>
        </div>
      </Fragment>
    );
  }
}

export default EquipmentsCardList;
