const FacilityDashboardAccordions = Object.freeze({
  Files: 1,
  Members: 2,
  Grievances: 3,
  PAC: 4,
  NCEPT: 5,

  TrafficCount: 6,
  OSHA: 7,
  OWCP: 8,
  Legislative: 9,
  Maps: 10,

  TrainedActivists: 12,
  ActivityLogging: 13,
  EmailList: 14,
  Finance: 15,
});
export default FacilityDashboardAccordions;
