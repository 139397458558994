import React, { Component, Fragment } from "react";
import axios from "../../Shared/auth-header";
import AUX from "../../../hoc/Aux_";
import { debounce } from "throttle-debounce";
import AuthService from "../../Auth/AuthService";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import FinanceAction from "../../../store/finance/action";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import Services from "../FinanceService";
import DeleteModal from "./DeleteModal";
import TransactionsModal from "./TransactionsModal";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { toast } from "react-toastify";
const filter = createFilterOptions();
class FinanceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      page: 1,
      mode: null,
      sizePerPage: 10,
      keyword: "",
      data: [],
      searchTerms: "",
      facilityId: "",
      employerId: "",
      payPeriod: "",
      duesPostedType: "",
      memberId: "",
      quarter: "",

      errorFilter: "",
      year: "",
      deleteId: null,
      deleteModal: false,
      FinanceLoader: false,
      sortOrder: null,
      sortField: null,
      Facilities: [],
      Employers: [],
      DuesPostedTypes: [],
      totalCount: 0,
      duesRebate: [],
      totalAmount: null,
      totalExpectedAmount: null,
    };
    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.handleRemoveFinances = this.handleRemoveFinances.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.handleChangeErrorFilter = this.handleChangeErrorFilter.bind(this);
    this.changeHandleDuesPostedDropDown = this.changeHandleDuesPostedDropDown.bind(
      this
    );
    this.columns = this.columns.bind(this);
  }
  formate_amount(n) {
    try {
      var num_parts = n.toString().split(".");
      num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    } catch (e) {}
    //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }

  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.getFinances();
  };
  handleRemoveFinances = async () => {
    $(".loading").show();

    if (
      this.state.deleteId != null &&
      this.state.deleteId != "" &&
      this.state.deleteId != undefined
    ) {
      var data = await Services.DeleteFinance(this.state.deleteId);
      if (data != null) {
        // await this.LoadData();
        this.props.refreshFinanceTable();
        await this.setState({
          deleteModal: false,
          deleteStatus: "",
        });
      } else {
        await this.setState({ deleteModal: false });
      }
    } else {
      toast.error("Invalid state, Try again after few moments.");
      await this.setState({ deleteModal: false });
    }
    $(".loading").hide();
  };
  componentDidUpdate(prevProps) {
    //Props Details {Mode,MemberId,FacilityId}
    let { Mode, MemberId, FacilityId } = this.props;
    let { mode, memberId, facilityId } = this.state;
    let change = false;
    if (Mode != mode) {
      this.setState({ mode: Mode });
      change = true;
    }
    if (Mode == "Facility" && FacilityId != facilityId) {
      this.setState({ facilityId: FacilityId });
      change = true;
    } else if (Mode == "Member" && MemberId != memberId) {
      this.setState({ memberId: MemberId });
      change = true;
    } else if (Mode != "Member" && this.state.memberId != "") {
      this.setState({ memberId: "" });
      change = true;
    }
    if (this.props.Finance.refreshFinanceTable == true) {
      this.props.refreshFinanceTable(false);
      this.getFinances();
    } else if (change) {
      this.getFinances();
    }
  }
  handleChangeErrorFilter = (e) => {
    this.state.errorFilter = e.target.checked;

    this.setState({ errorFilter: this.state.errorFilter });
  };
  driveOffset(page = null, sizePerPage = null) {
    if (page != null && sizePerPage != null) {
      return (page - 1) * sizePerPage;
    }
    return (this.state.page - 1) * this.state.sizePerPage;
  }

  handleTableChange = async (
    type,
    { sortField, sortOrder, page, sizePerPage }
  ) => {
    if (
      type == "sort" &&
      (this.state.sortField != sortField || this.state.sortOrder != sortOrder)
    ) {
      await this.setState({
        sortField,

        sortOrder,
      });
      await this.getFinances(true);
    }
    if (type == "pagination") {
      await this.setState(() => ({
        page,

        sizePerPage,
      }));
      await this.getFinances(true, true);
    }
  };
  columns = () => {
    return [
      {
        text: "Name",
        dataField: "firstName",
        headerStyle: {
          minWidth: "81px",
        },
        hidden:
          this.state.mode == "Member" ||
          !AuthService.canView(PermissionFeature.FinanceMember),
        headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
        classes: "text-nowrap px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceMember,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && (
                <>
                  {AuthService.canSPView(
                    PermissionFeature.PersonalInfoFirstName,
                    row.regionId,
                    row.facilityId,
                    row.memberID
                  ) && row.firstName}{" "}
                  {AuthService.canSPView(
                    PermissionFeature.PersonalInfoNickname,
                    row.regionId,
                    row.facilityId,
                    row.memberID
                  ) &&
                    row.nickName &&
                    `"${row.nickName}"`}{" "}
                  {AuthService.canSPView(
                    PermissionFeature.PersonalInfoLastName,
                    row.regionId,
                    row.facilityId,
                    row.memberID
                  ) && row.lastName}
                </>
              )}
            </div>
          );
        },
      },

      {
        text: "Bill. Type",
        dataField: "billingType",

        sort: true,
        hidden: !AuthService.canView(PermissionFeature.FinancialBillingType),
        headerClasses: "text-nowrap bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinancialBillingType,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && row.billingType}
            </div>
          );
        },
      },
      {
        headerStyle: {
          minWidth: "89px",
        },
        hidden:
          this.state.mode == "Facility" ||
          !AuthService.canView(PermissionFeature.FinanceFacility),
        text: "Reg/Fac",
        dataField: "regionCode",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceFacility,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && (
                <>
                  {AuthService.canSPView(
                    PermissionFeature.RegionCode,
                    row.regionId,
                    row.facilityId,
                    row.memberID
                  )
                    ? row.regionCode
                    : "-"}
                  /
                  {AuthService.canSPView(
                    PermissionFeature.FacilityCode,
                    row.regionId,
                    row.facilityId,
                    row.memberID
                  )
                    ? row.facilityCode
                    : "-"}
                </>
              )}
            </div>
          );
        },
      },

      {
        text: "Type",
        headerStyle: {
          minWidth: "70px",
        },
        dataField: "financeType",
        hidden: !AuthService.canView(PermissionFeature.FinanceType),
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceType,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && row.financeType}
            </div>
          );
        },
      },
      {
        headerStyle: {
          minWidth: "120px",
        },
        text: "Exp Amount",
        dataField: "expectedAmount",
        hidden: !AuthService.canView(PermissionFeature.FinanceExpAmount),
        sort: true,
        headerClasses: "bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceExpAmount,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && row.expectedAmount}
            </div>
          );
        },
      },
      {
        headerStyle: {
          minWidth: "91px",
        },
        text: "Amount",
        dataField: "amount",
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.FinanceAmount),
        headerClasses: "bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceAmount,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && row.amount}
            </div>
          );
        },
      },
      {
        text: "Posted Type",
        dataField: "duesPostedType",
        hidden: !AuthService.canView(PermissionFeature.FinanceDuesPostedType),
        sort: true,
        headerClasses: "text-nowrap bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceDuesPostedType,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && row.duesPostedType}
            </div>
          );
        },
      },
      {
        text: "Collection Type",
        dataField: "duesCollectionType",
        hidden: !AuthService.canView(
          PermissionFeature.FinanceDuesCollectionType
        ),
        sort: true,
        headerClasses: "text-nowrap bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceDuesCollectionType,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && row.duesCollectionType}
            </div>
          );
        },
      },
      {
        text: "Quarter",
        dataField: "quarter",
        hidden:
          this.state.mode != "Facility" ||
          !AuthService.canView(PermissionFeature.FinanceQuarter),
        sort: true,
        headerClasses: "text-nowrap bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceQuarter,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && row.quarter}
            </div>
          );
        },
      },
      {
        text: "Period/Year",
        hidden:
          !AuthService.canView(PermissionFeature.FinancePayPeriod) &&
          !AuthService.canView(PermissionFeature.FinanceYear),
        dataField: "year",
        headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinancePayPeriod,
                row.regionId,
                row.facilityId,
                row.memberID
              )
                ? row.period
                : "-"}
              /
              {AuthService.canSPView(
                PermissionFeature.FinanceYear,
                row.regionId,
                row.facilityId,
                row.memberID
              )
                ? row.year
                : "-"}
            </div>
          );
        },
      },
      {
        text: "",

        headerClasses: "bg-dark text-center text-white px-sm-3 px-2 py-2",
        classes: "text-nowrap px-3 py-2 c-pointer",
        sort: false,
        formatter: (cellContent, row) => {
          return (
            <div>
              {AuthService.canSPUpdate(
                PermissionFeature.FinancesRecords,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && (
                <i
                  className="fas pr-1 text-primary fa-pen c-pointer"
                  onClick={() => {
                    this.props.setFinanceFormMemberHeader(
                      row.firstName,
                      row.lastName,
                      row.facilityCode,
                      row.regionCode
                    );
                    this.props.openFinanceModal(row);
                  }}
                />
              )}
              {AuthService.canSPDelete(
                PermissionFeature.FinancesRecords,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && (
                <i
                  onClick={(e) => {
                    this.state.deleteId = row.id;
                    this.setState({
                      deleteId: this.state.deleteId,
                      deleteModal: true,
                    });
                  }}
                  className="fas fa-trash text-danger c-pointer"
                />
              )}
              {AuthService.canSPView(
                PermissionFeature.FinanceTransaction,
                row.regionId,
                row.facilityId,
                row.memberID
              ) && (
                <i
                  title="transactions"
                  onClick={(e) => {
                    this.props.setTransactionFormFinanceObj(row);
                    this.props.openTransactionModal();
                  }}
                  className="fas fa-wallet ml-2 text-primary c-pointer"
                />
              )}
            </div>
          );
        },
      },
    ];
  };

  SizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  RemotePagination = (data, sizePerPage, SizePerPageChange, rowEvents) => {
    const sizePerPageList = [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "All",
        value: this.state.totalCount,
      },
    ];
    let paginationOptions = {
      totalSize: this.state.totalCount,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
      page: this.state.page,
      sizePerPage,
      sizePerPageList,
      //hidePageListOnlyOnePage: true
    };
    let defaultsort = [
      {
        dataField: "year",
        order: "desc",
      },
    ];
    return (
      <div>
        <BootstrapTable
          remote
          keyField="id"
          id="membersListing"
          data={data}
          defaultSortDirection="asc"
          defaultSorted={defaultsort}
          columns={this.columns()}
          className="table"
          pagination={
            this.state.totalCount > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          onTableChange={this.handleTableChange}
        />
      </div>
    );
  };
  changeHandleDuesPostedDropDown = (e) => {
    this.state.duesPostedType = e.target.value;
    this.setState({ duesPostedType: this.state.duesPostedType });
  };
  async getFinances(hideLoader = false, overlayLoader = false) {
    if (!hideLoader) {
      await this.setState({ FinanceLoader: true });
    }
    if (overlayLoader) {
      $(".loading").show();
    }

    let {
      searchTerms,
      facilityId,
      employerId,
      payPeriod,
      duesPostedType,
      errorFilter,
      year,
      memberId,
      sortField,
      sortOrder,
      quarter,
      mode,
    } = this.state;
    let offset = this.driveOffset(),
      fetch = this.state.sizePerPage;
    let data = await Services.LoadFinanceData(
      searchTerms,
      facilityId,
      employerId,
      payPeriod,
      duesPostedType,
      errorFilter,
      year,
      memberId,
      quarter,
      mode,
      sortField + sortOrder,
      offset,
      fetch
    );
    let dataDD = await Services.FinanceFiltersDropdowns();
    if (data != null) {
      await this.setState({
        data: data.item1,
        totalCount: data.item2.count,
        totalAmount: data.item2.amount,
        totalExpected: data.item2.expectedAmount,
      });
      if (mode == "Facility") {
        await this.setState({
          duesRebate: data.item3,
        });
      }
    }

    if (dataDD != null) {
      await this.setState({
        Facilities: dataDD.facilityDD,
        Employers: dataDD.employerDD,
        DuesPostedTypes: dataDD.duesPostedTypeDD,
      });
    }
    if (!hideLoader) {
      await this.setState({ FinanceLoader: false });
    }
    if (overlayLoader) {
      $(".loading").hide();
    }
  }
  componentDidMount() {
    this.getFinances();
  }

  render() {
    // New Work
    const { data, sizePerPage, page, totalCount } = this.state;
    let {
      searchTerms,
      duesRebate,
      quarter,
      facilityId,
      employerId,
      payPeriod,
      duesPostedType,
      DuesPostedTypes,
      Facilities,
      Employers,
      errorFilter,
      mode,
      sortField,
      sortOrder,
      year,
      memberId,
    } = this.state;
    const showTotal = true;
    return (
      <AUX>
        {/*Filters Section*/}
        <div className="row mb-3 align-items-center">
          {this.state.mode !== "Member" && (
            <div className="app-search col-lg-6 mt-0">
              <div className="form-group mb-0">
                <input
                  type="text"
                  id="keyword"
                  className="form-control rounded border mb-1 w-100"
                  placeholder="Finance Member Name Search.."
                  onChange={async (event) => {
                    await this.setState({ searchTerms: event.target.value });

                    this.autocompleteSearchDebounced();
                  }}
                />
              </div>
            </div>
          )}
          <div className="col-lg-3 mt-lg-0 mt-3">
            <span
              className={
                "text-primary c-pointer" +
                (this.state.mode !== "Member" ? " ml-2" : "")
              }
              data-toggle="collapse"
              data-target="#Filters"
              aria-expanded="true"
              aria-controls="Filters"
            >
              {this.state.mode !== "Member" ? "More filters" : "Filters"}
            </span>
          </div>
          <div className="clearfix" />
        </div>

        <div className="row mb-3 collapse" id="Filters">
          <div className="mb-lg-0 mb-3 col-lg-3">
            <label>Dues Posted Type</label>
            <select
              //className="browser-default custom-select"
              onChange={this.changeHandleDuesPostedDropDown}
              name="duesPostedType"
              className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
            >
              <option value="">Select Value</option>

              {!_.isEmpty(DuesPostedTypes) &&
                DuesPostedTypes.map((item, index) => {
                  return (
                    <option
                      value={item.id}
                      selected={duesPostedType == item.id}
                    >
                      {item.type}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="mb-lg-0 mb-3 col-lg-3">
            <label>Facilities</label>
            <Autocomplete
              //  style={{ width: 300 }}
              options={
                !_.isEmpty(Facilities)
                  ? Facilities.map((o) => {
                      return o;
                    })
                  : []
              }
              //classes={{option: classes.option}}

              autoComplete={true}
              //autoSelect={true}
              autoHighlight={true}
              selectOnFocus
              clearOnBlur
              value={
                !_.isEmpty(Facilities) &&
                Facilities.find((x) => x.id == facilityId)
              }
              onChange={(e, value) => {
                if (value && value.id) {
                  this.state.facilityId = value.id;

                  this.props.setFinanceFacEmpFormMode(true);
                  this.props.setFinanceFacEmpFormFacility(
                    value.id,
                    value.type,
                    value.regionid
                  );
                } else {
                  this.state.facilityId = "";
                  if (this.props.Finance.FacEmpFormMode) {
                    //will set null automatically mode, facilityid, and facilitycode
                    if (!this.props.Finance.employerId) {
                      this.props.setFinanceFacEmpFormMode();
                    }

                    this.props.setFinanceFacEmpFormFacility();
                  }
                }

                this.setState({ facilityId: this.state.facilityId });
              }}
              getOptionLabel={(option) => {
                if (option && option.type) {
                  return option.type;
                }
                if (option != undefined) {
                  return option;
                }

                // Regular option
                return "";
              }}
              renderOption={(option) => {
                if (option && option.type) {
                  return option.type;
                }
                if (option != undefined) {
                  return option;
                }
                // Regular option
                return "";
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Facility"
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,

                    autoComplete: "disabled", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
          <div className="mb-lg-0 mb-3 col-lg-3">
            <label>Employers</label>
            <Autocomplete
              //  style={{ width: 300 }}
              options={
                !_.isEmpty(Employers)
                  ? Employers.map((o) => {
                      return o;
                    })
                  : []
              }
              //classes={{option: classes.option}}

              autoComplete={true}
              //autoSelect={true}
              autoHighlight={true}
              selectOnFocus
              clearOnBlur
              value={
                !_.isEmpty(Employers) &&
                Employers.find((x) => x.id == employerId)
              }
              onChange={(e, value) => {
                if (value && value.id) {
                  this.state.employerId = value.id;
                  this.props.setFinanceFacEmpFormMode(true);
                  this.props.setFinanceFacEmpFormEmployer(value.id, value.type);
                } else {
                  this.state.employerId = "";

                  if (this.props.Finance.FacEmpFormMode) {
                    //will set null automatically mode, facilityid, and facilitycode
                    if (!this.props.Finance.facilityId) {
                      this.props.setFinanceFacEmpFormMode();
                    }
                    this.props.setFinanceFacEmpFormEmployer();
                  }
                }

                this.setState({ employerId: this.state.employerId });
              }}
              getOptionLabel={(option) => {
                if (option && option.type) {
                  return option.type;
                }
                if (option != undefined) {
                  return option;
                }

                // Regular option
                return "";
              }}
              renderOption={(option) => {
                if (option && option.type) {
                  return option.type;
                }
                if (option != undefined) {
                  return option;
                }
                // Regular option
                return "";
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Employer"
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,

                    autoComplete: "disabled", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>

          <div className="col-lg-3 mb-lg-0 mb-3 pl-4 pt-lg-4">
            <div className="pt-lg-3 custom-checkbox">
              <input
                id={"errorfilter"}
                name={"errorfilter"}
                type="checkbox"
                checked={errorFilter}
                onChange={this.handleChangeErrorFilter}
                className="custom-control-input pr-2"
              />
              <label
                className="h6 custom-control-label"
                for={"errorfilter"}
                style={{ paddingTop: 2 }}
              >
                Dues != Posted Amount
              </label>
            </div>
          </div>
          <div className="clearfix" />
          <div className="mb-lg-0 mb-3 col-lg-3">
            <label>Period</label>
            <input
              type="text"
              id="keyword"
              className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
              placeholder="PayPeriod Search.."
              onChange={async (event) => {
                await this.setState({ payPeriod: event.target.value });
              }}
            />
          </div>
          <div className="mb-lg-0 mb-3 col-lg-3">
            <label>Year</label>
            <input
              type="text"
              id="keyword"
              className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
              placeholder="year Search.."
              onChange={async (event) => {
                await this.setState({ year: event.target.value });
              }}
            />
          </div>
          {mode == "Facility" && (
            <div className="mb-lg-0 mb-3 col-lg-3">
              <label>Quarter</label>
              <select
                //className="browser-default custom-select"
                onChange={(e) => {
                  this.state.quarter = e.target.value;
                  this.setState({ quarter: this.state.quarter });
                }}
                name="Quarters"
                className="bg-transparent pl-0 rounded-0 border-left-0 border-top-0 border-right-0 form-control"
              >
                <option value="">Select Value</option>

                {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <option value={item} selected={quarter == item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="pt-4 col-lg-3">
            <button
              type="button"
              className="btn mt-1 btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light"
              onClick={(event) => {
                this.getFinances();
              }}
            >
              <i className="fa fa-search pr-2" />
              Search
            </button>
          </div>
        </div>
        {/*End Of Filters Section*/}

        {mode == "Facility" && (
          <>
            {duesRebate &&
              !_.isEmpty(duesRebate) &&
              duesRebate.map((item, index) => (
                <div className="mb-2" id="Rebat">
                  <span className="pl-2 font-weight-light h6">
                    <b>Year/Quarter:</b>
                    <i className="pr-2">
                      {item.year + "/" + item.quarter}
                    </i>{" "}
                    <b>Amount:</b>
                    <i className="pr-2">
                      {this.formate_amount(item.amount)}
                    </i>{" "}
                    <b>FacilityAmount(10%):</b>
                    <i>{this.formate_amount(item.facilityAmount)}</i>
                  </span>
                </div>
              ))}
          </>
        )}
        {data && !_.isEmpty(data) && (
          <div className="row">
            <div className="mb-3 col-10" id="Detail">
              <span className="pl-2 font-weight-light h6">
                (<b>Total Amount:</b>
                <i className="pr-2">
                  {this.formate_amount(this.state.totalAmount)}
                </i>{" "}
                <b>Total Expected Amount:</b>
                <i>{this.formate_amount(this.state.totalExpected)})</i>
              </span>
            </div>

            <div className="text-right h6 col-2 icons-file">
              <span
                className="text-dark pr-2 c-pointer"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  $(".loading").show();

                  let data = await Services.GenerateExcel(
                    searchTerms,
                    facilityId,
                    employerId,
                    payPeriod,
                    duesPostedType,
                    errorFilter,
                    year,
                    memberId,
                    quarter,
                    mode,
                    sortField + sortOrder
                  );

                  $(".loading").hide();
                }}
              >
                <i class="fas fa-file-csv" />
              </span>
              <span
                className="text-dark c-pointer"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  $(".loading").show();
                  if (totalCount >= 10000) {
                    toast.warn("PDF will contains only 10,000 rows.");
                  }
                  let data = await Services.GeneratePDF(
                    searchTerms,
                    facilityId,
                    employerId,
                    payPeriod,
                    duesPostedType,
                    errorFilter,
                    year,
                    memberId,
                    quarter,
                    mode,
                    sortField + sortOrder
                  );

                  $(".loading").hide();
                }}
              >
                <i class="fas fa-file-pdf" />
              </span>
            </div>
          </div>
        )}
        {/*Table Section*/}
        <div className="row">
          <div className="col-12">
            {this.state.FinanceLoader ? (
              <div
                class="m-3 position-relative d-block mx-auto spinner-border text-info"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            ) : !_.isEmpty(data) ? (
              this.RemotePagination(
                data,

                this.state.sizePerPage,

                this.SizePerPageChange,
                this.rowEvents
              )
            ) : (
              <p className="text-center">No data found!</p>
            )}
          </div>
        </div>
        {/*End Of table Section*/}
        {AuthService.canDelete(PermissionFeature.FinancesRecords) && (
          <DeleteModal
            content={"the Selected Finance"}
            confirm={() => {
              this.handleRemoveFinances();
            }}
            closeModal={() => {
              this.setState({ deleteModal: false });
            }}
            openModal={this.state.deleteModal}
          />
        )}
        {AuthService.canView(PermissionFeature.FinanceTransaction) && (
          <TransactionsModal />
        )}
      </AUX>
    );
  }
}
export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(FinanceTable);
