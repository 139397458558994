import AUX from "../../../hoc/Aux_";
import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
// import axios from "./auth-header";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import AuthService from "../../../modules/Auth/AuthService";

import PermissionFeature from "../../Permissions/PermissionFeature";
import { connect } from "react-redux";
import mailGunEmailActions from "../../../store/mailgunEmails/action";

class MG_Add_NonNatca extends Component {
  constructor(props) {
    super(props);
  }

  handleClose = () => {
    this.props.handleClose();
    // this.setState({
    //   showEmailModal: false,
    // });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onShow = () => {
    this.props.resetNonNatcaUI();
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.target.className += " was-validated";
    if (e.target.checkValidity() === false) {
      return false;
    }
    this.handleClose();

    let allSelected = this.props.melstate.selectedAddtlRecipientsNonNatca;
    allSelected.forEach((element) => {
      element.name = `${element.firstName} ${element.lastName}`;
      element.id = 0;
      element.isNonNatca = true;
      element.subscribed = true;
    });

    this.props.addSelectedNonNatcaMembers(allSelected);
  };
  changeHandler = (e, idx) => {
    const { name, value } = e.target;

    const list = [...this.props.melstate.selectedAddtlRecipientsNonNatca];
    list[idx][name] = value;

    this.props.handleNonNatcaChange(list);
  };
  addRowClick = () => {
    const list = [...this.props.melstate.selectedAddtlRecipientsNonNatca];
    list.push({ name: "", address: "" });

    this.props.handleNonNatcaChange(list);
  };
  removeNonNatca = (idx) => {
    const list = [...this.props.melstate.selectedAddtlRecipientsNonNatca];
    list.splice(idx, 1);
    this.props.handleNonNatcaChange(list);
  };
  render() {
    const { selectedAddtlRecipientsNonNatca } = this.props.melstate;

    return (
      <AUX>
        <Modal
          className="EmailMOdal latest"
          show={this.props.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title  text-white mt-0">
                Add New Non Natca Member
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <form
                id="addNonNatcaPerson"
                className="needs-validation form-horizontal"
                onSubmit={this.handleFormSubmit}
                noValidate
              >
                {selectedAddtlRecipientsNonNatca &&
                  selectedAddtlRecipientsNonNatca.map((item, idx) => (
                    <div className="row" key={idx + 1}>
                      <div className="col-sm-5">
                        <MDBInput
                          id="firstName"
                          name="firstName"
                          containerClass="mb-0 w-100"
                          value={item.firstName || ""}
                          onChange={(e) => this.changeHandler(e, idx)}
                          placeHolder="Enter First Name"
                          required
                          type="text"
                        >
                          <div className="invalid-feedback">
                            Enter First Name
                          </div>
                        </MDBInput>
                      </div>
                      <div className="col-sm-5">
                        <MDBInput
                          id="lastName"
                          name="lastName"
                          containerClass="mb-0 w-100"
                          value={item.lastName || ""}
                          onChange={(e) => this.changeHandler(e, idx)}
                          placeHolder="Enter Last Name"
                          required
                          type="text"
                        >
                          <div className="invalid-feedback">
                            Enter Last Name
                          </div>
                        </MDBInput>
                      </div>
                      {selectedAddtlRecipientsNonNatca.length - 1 === idx && (
                        <div className="col-sm-1 align-self-center">
                          <button
                            type="button"
                            data-id={idx}
                            onClick={() => this.addRowClick()}
                            className="btn btn-success btn-sm"
                          >
                            <i class="fas fa-user-plus"></i>
                          </button>
                        </div>
                      )}

                      {selectedAddtlRecipientsNonNatca.length !== 1 && (
                        <div className="col-sm-1 align-self-center">
                          <button
                            type="button"
                            data-id={idx}
                            onClick={() => this.removeNonNatca(idx)}
                            className="btn btn-danger btn-sm"
                          >
                            <i className="fas fa-trash-alt" />
                          </button>
                        </div>
                      )}

                      <div className="col-sm-6">
                        <MDBInput
                          id="email"
                          name="address"
                          containerClass="mb-0 w-100"
                          value={item.address || ""}
                          onChange={(e) => this.changeHandler(e, idx)}
                          placeHolder="Enter Email"
                          required
                          type="email"
                        >
                          <div className="invalid-feedback">
                            Enter valid Email
                          </div>
                        </MDBInput>
                      </div>
                    </div>
                  ))}
              </form>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-centered d-flex justify-content-center">
                <button
                  className="btn btn-sm btn-danger w-md waves-effect waves-light"
                  type="button"
                  id="emailPopupCloseBtn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.handleClose}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                  type="submit"
                  form="addNonNatcaPerson"
                >
                  Done Adding Subscribers
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

//export default MG_Add_NonNatca;
export default connect(
  (state) => ({
    melstate: state.mailGunEmail,
  }),
  {
    ...mailGunEmailActions,
  }
)(MG_Add_NonNatca);
