import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import FacilityModal from "../../Shared/FacilityModal";
import RegionModal from "../../Shared/RegionModal";
import AreaModal from "../../Shared/AreaModal";

function ScopeSelector(props) {
    switch (props.type) {
        case "REGION": {
            return (
                <AUX>
                    <div className="col-3">
                        <label htmlFor="region">Region</label>
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                id="region"
                                name="region"
                                class="form-control disabled"
                                value={props.scopeName}
                                required
                                onKeyPress={e => {
                                    e.preventDefault();
                                    e.target.value = "";
                                }}
                            />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" data-toggle="modal" data-target=".region-modal">
                                    <i class="fa fa-search" />
                                </button>
                            </div>
                            <div className="invalid-feedback">Region is required.</div>
                        </div>
                    </div>
                    <RegionModal regionMethods={props.scopeMethods().regionMethods} />
                </AUX>
            );
        }
        case "FACILITY": {
            return (
                <AUX>
                    <div className="col-3">
                        <label htmlFor="region">Facility</label>
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                id="region"
                                name="region"
                                class="form-control disabled"
                                value={props.scopeName}
                                required
                                onKeyPress={e => {
                                    e.preventDefault();
                                    e.target.value = "";
                                }}
                            />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" data-toggle="modal" data-target=".facility-modal">
                                    <i class="fa fa-search" />
                                </button>
                            </div>
                            <div className="invalid-feedback">Facility is required.</div>
                        </div>
                    </div>
                    <FacilityModal getFacilities={true} facilityMethods={props.scopeMethods().facilityMethods} />
                </AUX>
            );
        }
        case "AREA": {
            return (
                <AUX>
                    <div className="col-3">
                        <label htmlFor="region">Area</label>
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                id="region"
                                name="region"
                                class="form-control disabled"
                                value={props.scopeName}
                                required
                                onKeyPress={e => {
                                    e.preventDefault();
                                    e.target.value = "";
                                }}
                            />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" data-toggle="modal" data-target=".area-modal">
                                    <i class="fa fa-search" />
                                </button>
                            </div>
                            <div className="invalid-feedback">Area is required.</div>
                        </div>
                    </div>
                    <AreaModal areaMethods={props.scopeMethods().areaMethods} />
                </AUX>
            );
        }
        default: {
            return "";
        }
    }
}

export default ScopeSelector;
