import React, { Component, Fragment } from "react";
import { Button, Row, Col } from "reactstrap";
import { MDBInput } from "mdbreact";
import { FilePond, registerPlugin, setOptions, File } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "moment/locale/en-ca";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import actionFilter from "../../../store/facility/action";

import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);

export class OSHAReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedFileTypes: [
        "image/png",
        "image/jpeg",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
    };
  }

  handleInit() {
    // console.log("FilePond instance has initialised", this.pond);
  }

  canEdit = (name) => {
    // if (this.facilitycontrolpermissions) return this.facilitycontrolpermissions.find(el => el.name == name).permissions.canEdit;
    return true;
  };

  canView = (name) => {
    // if (this.facilitycontrolpermissions) return this.facilitycontrolpermissions.find(el => el.name == name).permissions.canView;
    return true;
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    //debugger

    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      return false;
    }
    this.saveOSHAObject();
    // this.pond.props.fileValidateTypeDetectType(this.pond.props.files[0], this.pond.props.files[0].type)
    //     .then((data) => {
    //         this.saveOSHAObject();
    //     })
    //     .catch((err) => {
    //         toast.error(err.message)
    //         console.log("catch here" + console.error(err))
    //         return false;
    //     })
  };

  saveOSHAObject = () => {
    $(".loading").show();
    // debugger
    var formData = new FormData();
    const data = {
      id: this.props.oshaState.id,
      Description: this.props.oshaState.description,
      AdditionalInfo: this.props.oshaState.addInfo,
      DateIdentified: new Date(this.props.oshaState.dateIdentifiedString),
      ManagementNotified: this.props.oshaState.isNotified,
      Status: this.props.oshaState.status,
      facilityId: this.props.facilityId,
    };
    for (var i = 0; i < this.props.oshaState.files.length; i++) {
      formData.append("File" + i, this.props.oshaState.files[i]);
    }
    formData.append("data", JSON.stringify(data));
    console.log(data);
    axios
      .post("/api/OSHA/SaveOSHA", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data.status && data.status == "Success") {
          toast.success("OSHA Saved.");
          //  $('#addOSHACloseBtn').click();
          this.props.getOSHA();
        } else {
          // $('#addOSHACloseBtn').click();
          toast.error(data.message);
        }
        $("#addOSHAForm").removeClass("was-validated");
        //  $('.modal-backdrop').hide();
        this.props.forcelyCloseModal("#addOSHACloseBtn");
        $(".loading").hide();
      })
      .catch(function(error) {
        console.log(error);
        $("#addOSHAForm").removeClass("was-validated");
        // $('#addOSHACloseBtn').click();
        // $('.modal-backdrop').hide();
        this.props.forcelyCloseModal("#addOSHACloseBtn");
        $(".loading").hide();
      });
  };

  render() {
    return (
      <div
        className="modal fade"
        id="AddOSHAModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="AddOSHAModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="AddOSHAModalTitle">
                {" "}
                {this.props.oshaState.modalHeader}{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form
              className="needs-validation"
              id="addOSHAForm"
              onSubmit={this.handleSubmit}
              noValidate
            >
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <Fragment>
                      <div className="row">
                        {this.canView("description") && (
                          <div className="col-8">
                            <div className="form-group">
                              <MDBInput
                                className="PR-Zero"
                                value={this.props.oshaState.description}
                                id="description"
                                name="description"
                                onChange={this.props.handleChangeParent}
                                maxLength="100"
                                label="Description of the Issue"
                                group
                                type="textarea"
                                rows="3"
                                //disabled={this.canEdit("description")}
                                required
                              >
                                <div className="invalid-feedback">
                                  Description is required.
                                </div>
                              </MDBInput>
                            </div>
                          </div>
                        )}
                        {this.canView("addInfo") && (
                          <div className="col-4">
                            <div className="form-group">
                              <MDBInput
                                className="PR-Zero"
                                value={this.props.oshaState.addInfo}
                                id="addInfo"
                                name="addInfo"
                                onChange={this.props.handleChangeParent}
                                maxLength="100"
                                pattern="[A-Za-z0-9]+"
                                label="Additional Information"
                                group
                                type="textarea"
                                rows="3"
                                //disabled={this.canEdit("description")}
                              >
                                <div className="invalid-feedback">
                                  Additional Information is invalid.
                                </div>
                              </MDBInput>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        {this.canView("dateIdentified") && (
                          <div className="col-4">
                            <div className="form-group">
                              <span className="d-block mb-2">
                                Date Identified
                              </span>
                              <DatePickerInput
                                onChange={this.props.onDateChangeParent}
                                value={this.props.oshaState.dateIdentified}
                              />
                            </div>
                          </div>
                        )}
                        {this.canView("status") && (
                          <div className="col-4">
                            <div className="form-group">
                              <span className="d-block mb-2">Status</span>
                              <select
                                className="browser-default custom-select"
                                onChange={this.props.handleChangeParent}
                                name="status"
                                id="status"
                                disabled={
                                  this.props.oshaState.currentState === "Add"
                                }
                              >
                                <option
                                  value="Pending"
                                  selected={
                                    this.props.oshaState.status === "Pending"
                                  }
                                >
                                  Pending
                                </option>
                                <option
                                  value="In Progress"
                                  selected={
                                    this.props.oshaState.status ===
                                    "In Progress"
                                  }
                                >
                                  In Progress
                                </option>
                                <option
                                  value="Completed"
                                  selected={
                                    this.props.oshaState.status === "Completed"
                                  }
                                >
                                  Completed
                                </option>
                              </select>
                            </div>
                          </div>
                        )}
                        {this.canView("notified") && (
                          <div className="col-4">
                            <div className="form-group">
                              <span className="d-block mb-2">
                                Management Notified?
                              </span>
                              <div class="input-group mb-3">
                                <input
                                  type="checkbox"
                                  id="isNotified"
                                  name="isNotified"
                                  switch="bool"
                                  onChange={this.props.handleChangeParent}
                                  checked={this.props.oshaState.isNotified}
                                />
                                <label
                                  htmlFor="isNotified"
                                  data-on-label="Yes"
                                  data-off-label="No"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <Row>
                        {this.canView("dateIdentified") && (
                          <Col>
                            <div className="form-group">
                              <FilePond
                                ref={(ref) => (this.pond = ref)}
                                files={this.props.oshaState.files}
                                allowMultiple={true}
                                allowFileTypeValidation={true}
                                allowImageExifOrientation={true}
                                allowImagePreview={true}
                                allowImageCrop={true}
                                allowFileEncode={true}
                                acceptedFileTypes={this.state.acceptedFileTypes}
                                fileValidateTypeDetectType={(source, type) =>
                                  new Promise((resolve, reject) => {
                                    //  debugger
                                    let rejectedExtensions = [
                                      "ade",
                                      "adp",
                                      "apk",
                                      "bat",
                                      "chm",
                                      "cmd",
                                      "com",
                                      "cpl",
                                      "dll",
                                      "dmg",
                                      "exe",
                                      "hta",
                                      "ins",
                                      "isp",
                                      "jar",
                                      "js",
                                      "jse",
                                      "lib",
                                      "lnk",
                                      "mde",
                                      "msc",
                                      "msi",
                                      "msp",
                                      "mst",
                                      "nsh",
                                      "pif",
                                      "scr",
                                      "sct",
                                      "shb",
                                      "sys",
                                      "vb",
                                      "vbe",
                                      "vbs",
                                      "vxd",
                                      "wsc",
                                      "wsf",
                                      "wsh",
                                      "cab",
                                    ];
                                    // If the file extension is not in our rejected list.
                                    if (
                                      !rejectedExtensions.some((ext) =>
                                        source.name.endsWith("." + ext)
                                      )
                                    ) {
                                      resolve(type);
                                    } else {
                                      // Otherwise it is rejected.
                                      toast.error("File type is unsupported!");
                                      //reject(new Error('Files contains unsupported format!'));
                                      reject(type);
                                    }
                                  })
                                }
                                oninit={() => this.handleInit()}
                                onupdatefiles={(fileItems) => {
                                  // debugger
                                  // Set currently active file objects to this.state
                                  this.props.updateFilesParent(fileItems);
                                  // console.log(this.props.oshaState.files);
                                }}
                              ></FilePond>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Fragment>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => this.props.onModalClose()}
                  id="addOSHACloseBtn"
                >
                  Close
                </button>
                <Button to="/roles" color="primary" type="submit">
                  Save Changes
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

//export default OSHAReportModal;

export default connect(
  (state) => ({ facilityDashboardState: state.facilityDashboard }),
  {
    ...actionFilter,
  }
)(OSHAReportModal);
