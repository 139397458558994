

const actions = {

    OPEN_IMPORT_DETAIL_MODAL: "OPEN_IMPORT_DETAIL_MODAL",
    CLOSE_IMPORT_DETAIL_MODAL: "CLOSE_IMPORT_DETAIL_MODAL",
    OPEN_JOB_HISTORY_FORM_MODAL: "OPEN_JOB_HISTORY_FORM_MODAL",
    CLOSE_JOB_HISTORY_FORM_MODAL: "CLOSE_JOB_HISTORY_FORM_MODAL",
    openImportDetailModal: () => (dispatch) => {
        dispatch({
            type: actions.OPEN_IMPORT_DETAIL_MODAL,
        });
    },
    closeImportDetailModal: () => (dispatch) => {
        dispatch({
            type: actions.CLOSE_IMPORT_DETAIL_MODAL,
        });
    },
    openJobHistoryFormModal: () => (dispatch) => {
        dispatch({
            type: actions.OPEN_JOB_HISTORY_FORM_MODAL,
        });
    },
    closeJobHistoryFormModal: () => (dispatch) => {
        dispatch({
            type: actions.CLOSE_JOB_HISTORY_FORM_MODAL,
        });
    },

};

export default actions;
