import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/feTracker/action";
import $ from "jquery";
export class SelectedFilter extends Component {
  state = {
    toggle: false,
  };
  componentDidMount() {}
  toggleTagsDisplay = () => {
    $(".setFETagsPadding").toggle("fast");
    this.setState({ toggle: !this.state.toggle });
  };
  resize() {
    let currentHideNav = window.innerWidth >= 897; /// isDesktop
    if (currentHideNav !== this.props.festate.showWebSideBar) {
      this.props.showWebSideBar(currentHideNav);
      this.props.showMobileSideBar(false);
    } else if (window.innerWidth <= 897) {
      this.props.showMobileSideBar(true);
      this.props.showWebSideBar(false);
    }
    let vh = 0;
    let tagsScroll = 0;
    if (window.innerHeight > window.innerWidth) {
      ///Potrait Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.018}px`
      );
      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01 - 2}px`
      );
    } else if (window.innerHeight < window.innerWidth) {
      ///Landscape Mode
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
      document.documentElement.style.setProperty(
        "--tagsScroll",
        `${window.innerHeight * 0.01}px`
      );
    }
    let bodyHeight = $(".modal-body").height() * 0.01;
    document.documentElement.style.setProperty(
      "--bodyHeight",
      `${bodyHeight}px`
    );
  }
  toggleMobileTagsDisplay = () => {
    $(".bottomBorderMobile").toggle("fast");
    this.setState({ toggle: !this.state.toggle });
    this.resize();
  };
  getTotalFitlerCount = () => {
    let count = 0;
    const searchApplied = this.props.getSearchAppliedList();
    searchApplied.forEach(function(val, index) {
      count += val.selectedItems.length;
    });
    return count;
  };
  render() {
    const showSelectedFilters =
      !_.isEmpty(this.props.festate.filterData) &&
      !_.isEmpty(this.props.festate.filterData.searchApplied);
    return (
      <>
        <div
          onClick={
            this.props.isMobile === true
              ? this.toggleMobileTagsDisplay
              : this.toggleTagsDisplay
          }
          className="filterSelected border-right-0 border-bottom-0"
          style={
            this.props.isMobile == true
              ? { marginBottom: "1vh", borderTop: 0 }
              : { justifyContent: "center" }
          }
        >
          <span className="mt-2 pr-1" style={{ marginLeft: "inherit" }}>
            {" "}
            {showSelectedFilters ? `(${this.getTotalFitlerCount()})` : 0}{" "}
            Filter(s) Selected&nbsp;
            {!this.props.isMobile && (
              <i
                class={` ${
                  this.state.toggle
                    ? "fa fa-angle-double-down"
                    : "fa fa-angle-double-up"
                } `}
                style={{ fontSize: "small" }}
              />
            )}
            {this.props.isMobile && (
              <i
                class={` ${
                  this.state.toggle
                    ? "fa fa-angle-double-down"
                    : "fa fa-angle-double-up"
                } `}
                style={{ fontSize: "smaller" }}
              />
            )}
          </span>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({ festate: state.fetracker }),
  {
    ...actionFilter,
  }
)(SelectedFilter);
