import React from "react";
import RemoveActionType from "../Enums/RemoveActionType";
import EmailConfigTables from "../Enums/EmailConfigTables";
import _ from "lodash";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function MG_ConfirmationModal(props) {
  const {
    modal,
    toggle,
    userFirstName,
    userLastName,
    teamName,
    memberModalOpended,
    teamModalOpended,
    removeActionType,
    confirmModelType,
    fullName,
  } = props;
  let isUnsubscribeAction = false;
  let confirmText = "";
  let messageLastPart = "";
  if (removeActionType == RemoveActionType.UnSubscribe) {
    confirmText = "UnSubscribe";
    isUnsubscribeAction = true;
  } else if (removeActionType == RemoveActionType.Delete) {
    confirmText = "Delete";
  } else if (removeActionType == RemoveActionType.Block) {
    confirmText = "Block";
  }
  if (
    confirmModelType == EmailConfigTables.AuthorizedSendersTeam ||
    confirmModelType == EmailConfigTables.AuthorizedSendersMember
  ) {
    messageLastPart =
      " will no longer be able to send email to mailing list. Are you sure?";
  } else if (
    confirmModelType == EmailConfigTables.AdditionalRecipientsTeam ||
    confirmModelType == EmailConfigTables.AdditionalRecipientsMember ||
    confirmModelType == EmailConfigTables.NonNatcaMembers ||
    confirmModelType == EmailConfigTables.DefaultMembers
  ) {
    messageLastPart =
      " will no longer be able to receive email from mailing list. Are you sure?";
  }
  let unsubscribeMemberMessage = () => {
    let name = "";
    if (_.isEmpty(userFirstName) || _.isEmpty(userLastName)) {
      name = "'" + fullName + "'";
    } else {
      name =
        !_.isEmpty(userFirstName) && !_.isEmpty(userLastName)
          ? "'" + userFirstName + "-" + userLastName + "'"
          : "";
    }

    return `${name} will no longer receive emails from mailing list. Are you sure you want to Unsubscribe?`;
  };

  let unsubscribeTeamMessage = `${
    !_.isEmpty(teamName)
      ? "'" +
        "'" +
        teamName +
        "'" +
        " will no longer receive emails from mailing list. Are you sure you want to Unsubscribe?"
      : ""
  } `;

  let blockMemberMessage = () => {
    let name = "";
    if (_.isEmpty(userFirstName) || _.isEmpty(userLastName)) {
      name = " for Member " + "'" + fullName + "'";
    } else {
      name =
        !_.isEmpty(userFirstName) &&
        !_.isEmpty(userLastName) &&
        " for Member " + "'" + userFirstName + "-" + userLastName + "'";
    }
    return name;
  };

  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
      className={props.classId}
    >
      <ModalHeader toggle={toggle}>Confirm {confirmText}</ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          <h5>
            {isUnsubscribeAction ? (
              <>
                {memberModalOpended
                  ? unsubscribeMemberMessage()
                  : unsubscribeTeamMessage}
              </>
            ) : (
              <>
                This will revoke access
                {memberModalOpended && blockMemberMessage()}
                {teamModalOpended && " for Team " + "'" + teamName + "'"},
                {messageLastPart}
              </>
            )}
          </h5>
        </div>{" "}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary w-md waves-effect waves-light"
          onClick={() => {
            props.confirm().confirmed();
          }}
        >
          {confirmText}
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
