import React, { Component } from "react";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { Row, Col, Form } from "reactstrap";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class Region extends Component {
  onChange(item) {
    var regionIndex = _.findIndex(
      this.props.filterState.filterData.region,
      o => {
        return o.id === item.id;
      }
    );
    item.checked = !this.props.filterState.filterData.region[regionIndex]
      .checked;
    this.props.filterState.filterData.region[regionIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData
    });
  }
  chechboxes(item) {
    return (
      <li className="chechbox-list_item" key={"rliitem-" + item.id}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"ritem-" + item.id}
        >
          <input
            type="checkbox"
            htmlFor={"ritem-" + item.id}
            value={item.id}
            checked={item.checked}
            className="custom-control-input"
            id={"ritem-" + item.id}
            name={"ritem-" + item.id}
            onChange={() => this.onChange(item)}
          />

          <label className="custom-control-label" style={{ color: this.props.isMobile ? 'white' : null }} htmlFor={"ritem-" + item.id}>
            {item.code}
            <small className="totalCount">

              {`(${item.total})`}
            </small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Region</h4>
            <hr />
          </>
        ) : (
            ""
          )}
        <div className="filter-fields-scroll">
          <ul className="chechbox-list">
            {this.props.filterState.filterData.region &&
            !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.region.map(item =>
                this.chechboxes(item)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect(state => ({ filterState: state.advancedFilter }), {
  ...actionFilter
})(Region);
