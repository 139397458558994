import React, { Component } from "react";
import AddRoleBody from "./AddRoleBody";

class AddRoleSub extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <tr>
                <td colSpan="5" className="p-0">
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0">
                            <table className="table no-t-border sub-table table-hover">
                                <AddRoleBody
                                    data={this.props.data}
                                    featureId={this.props.featureId}
                                    updateMethods={this.props.updateMethods}
                                />
                            </table>
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}
export default AddRoleSub;
