import React, { Component } from "react";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { Row, Col, Form } from "reactstrap";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class Communication extends Component {
  onChange(item) {
    var communicationIndex = _.findIndex(
      this.props.filterState.filterData.communication,
      (o) => {
        return o.communication === item.communication;
      }
    );
    item.checked = !this.props.filterState.filterData.communication[
      communicationIndex
    ].checked;
    this.props.filterState.filterData.communication[communicationIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item, idx) {
    if (
      !(
        item.communication.includes("News") ||
        item.communication.includes("Legislative")
      )
    ) {
      return (
        <li
          class="chechbox-list_item"
          key={"cmliitem-" + item.communication + idx}
        >
          <span
            className="card-text custom-checkbox ml-3 mr-4"
            key={"comitem-" + item.communication}
          >
            <input
              type="checkbox"
              htmlFor={"comitem-" + item.communication}
              value={item.communication}
              checked={item.checked}
              className="custom-control-input"
              id={"comitem-" + item.communication}
              name={"comitem-" + item.communication}
              onChange={() => this.onChange(item)}
            />

            <label
              className="custom-control-label"
              style={{ color: this.props.isMobile ? "white" : null }}
              htmlFor={"comitem-" + item.communication}
            >
              {item.communication}
              <small className="totalCount">{`(${item.total})`}</small>
            </label>
          </span>
        </li>
      );
    } else {
      return (
        <div className="childTextFields">
          <li class="chechbox-list_item" key={"cmliitem-" + item.communication}>
            <span
              className="card-text custom-checkbox ml-3 mr-4"
              key={"comitem-" + item.communication}
            >
              <input
                type="checkbox"
                htmlFor={"comitem-" + item.communication}
                value={item.communication}
                checked={item.checked}
                className="custom-control-input"
                id={"comitem-" + item.communication}
                name={"comitem-" + item.communication}
                onChange={() => this.onChange(item)}
              />

              <label
                className="custom-control-label"
                style={{ color: this.props.isMobile ? "white" : null }}
                htmlFor={"comitem-" + item.communication}
              >
                {item.communication}
                <small className="totalCount">{`(${item.total})`}</small>
              </label>
            </span>
          </li>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Communication</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div class="filter-fields-scroll minHeightComms">
          <ul class="chechbox-list">
            {this.props.filterState.filterData.communication &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.communication.map((item, idx) =>
                this.chechboxes(item, idx)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(Communication);
