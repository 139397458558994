import React, { Component } from "react";
import axios from "../../../Shared/auth-header";
import AuthService from "../../../Auth/AuthService";
import { Link, withRouter } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";
import actionFilter from "../../../../store/advancedfilter/action";
import savedSearchAction from "../../../../store/saveSearch/action";
import mailGunEmailActions from "../../../../store/mailgunEmails/action";
import { connect } from "react-redux";
import FiltersTable from "./FiltersTable";
import EditFilterModal from "./EditFilterModal";
import EmailListService from "../../../Email/EmailListService";
import { toast } from "react-toastify";
import { Card, CardBody, Collapse } from "reactstrap";
import FiltersService from "../Listing/FiltersService";
import FilterTablesType from "../../../Email/Enums/FilterTablesType";
import * as SavedSearchTypes from "../../../AdvanceSearch/SavedSearches/SavedSearchTypes";

const OWNERSHIP_ITEM = 1;
const BUCKET_ITEM = 2;
const SHARED_ITEM = 3;

export class SavedSearchFiltersList extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    memberId: AuthService.getProfile().memberData.id,
    searchId: 0,
    searchName: "",
    showListing: true,
  };

  // rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     this.props.toggleEditFilterModal(row, true);
  //     this.logicSaveSearch(row);
  //   },
  // };
  //Onclick event handler from Filters listing screen
  viewEditFilter = (row) => {
    const {
      location: { pathname, search },
    } = this.props.history;
    const searchParams = new URLSearchParams(search);
    if (pathname == "/directory/myfilters") {
      // My Filters
      row = { ...row, owner_id: row.member_id };
    }
    //row = {...row, row.mem}
    if (searchParams) {
      const newUrl = pathname.split("?")[0];
      this.props.history.replace(newUrl);
    }
    this.props.toggleEditFilterModal(row, true);
    this.logicSaveSearch(row);
  };
  deleteFilter = (row) => {
    let loggedInMember = this.state.memberId;
    let {
      search_name,
      id,
      search_type_id,
      real_search_type_id,
      owner_id, // incase of AllFilters screen
      member_id, //incase of MyFilter screen
      saved_search_id, //incase of shared filter
    } = row;
    let memberId = 0;
    let filterId = 0;
    let type = 0;
    if (real_search_type_id) {
      filterId = saved_search_id;
      memberId = member_id;
      type = real_search_type_id; //most probably Bucket or Ownership
      // 1 for Ownership, 2 for Bucket and 3 for Shared
    } else {
      filterId = id;
      type = search_type_id;
      if (owner_id && owner_id !== loggedInMember) {
        // Check if logged in member is not owner of filter
        memberId = owner_id;
      } else {
        memberId = loggedInMember; // If logged in member is owner of filter
      }
    }
    this.deleteSearch(search_name, filterId, type, memberId);
  };

  async deleteSearch(searchName, searchId, searchType, memberId) {
    if (
      window.confirm(
        `This will delete the saved search ${searchName} permanently.\r\nIf this search is shared with other users they will lose access.\r\nAre you sure?`
      )
    ) {
      let deleteUrl =
        "/api/SavedSearch/DeleteSearch?savedSearchId=" +
        searchId +
        "&searchType=" +
        searchType +
        "&memberId=" +
        memberId;
      this.showSpinner();
      try {
        const { data } = await axios.delete(deleteUrl);
        this.hideSpinner();
        if (data.status) {
          if (data.status.toLowerCase() === "success") {
            toast.success(data.message);
            const {
              location: { pathname },
            } = this.props.history;
            if (pathname == "/directory/myfilters") {
              // ****My Filters****
              this.refreshState();
            } else {
              // ****All Filters***
              const { allSystemFilters } = this.props.savedSearchState; //current filter opened
              //Remove the filter from the Redux List
              const currentFilters = allSystemFilters.filter(
                (f) => f.id !== searchId
              );
              //Will Reload New Filters Listing Screen and DropDown of Saved Searches
              this.props.setAllSystemFilters(currentFilters);
            }

            this.props.resetSavedSearch();
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("Search cannot be deleted.");
        }
      } catch (ex) {
        console.log(ex);
        toast.error("Something went wrong!");
        this.hideSpinner();
      }
    }
  }
  logicSaveSearch = (item) => {
    if (
      item.search_type_id == BUCKET_ITEM ||
      (item.search_type_id == SHARED_ITEM &&
        item.real_search_type_id == BUCKET_ITEM)
    ) {
      //this.fetchBucketSearchCriteria(item);
      this.fetchSearchCriteria(item);
    } else {
      this.fetchSearchCriteria(item);
    }
  };
  fetchSearchCriteria = async (item) => {
    //debugger;
    let searchId = item.id, ///confirm is this the right id
      //searchTitle = item.search_name,
      searchTypeId = item.search_type_id,
      owner_member = item.owner_id;

    if (searchId && searchTypeId) {
      this.props.ResetlocalPaging();
      this.props.isSavedSearchSelected(true);
      this.props.setAdvancedSearch(false);
      //this.props.redirectToMembers();
      this.props.setSavedSearchCriteria(null);
      this.props.setSavedSearchDetail(null);
      this.setState({
        savedSearchId: searchId,
        savedSearchTypeId: searchTypeId,
        activeSearchId: searchId,
      });
      this.setState({ showDropdown: false });
      let body = {
        SearchId: searchId,
        MemberId: owner_member, //this.state.memberId,
        SearchType: searchTypeId,
      };
      if (item.real_search_type_id) {
        body.MemberId = this.state.memberId;
      }

      let shared_type_id = 0;
      let shared_search_id = 0;
      if (
        item.search_type_id == SHARED_ITEM &&
        item.real_search_type_id == BUCKET_ITEM
      ) {
        shared_type_id = item.real_search_type_id;
        shared_search_id = item.saved_search_id;
      }
      this.props.setSavedSearchDDLObject({
        ...body,
        savedSearchPerformed: true,
      });
      this.showSpinner();
      var _this = this;
      let response = await FiltersService.GetSavedSearchFilter(body);
      if (response) {
        this.props.setSavedSearchCriteria({
          savedSearchCriteriaDetail: response,
          searchTypeId,
          searchId,
          sharedSaveSearchTypeId: shared_type_id,
          sharedBucketSearchId: shared_search_id,
        });
        //delete item.search_criteria;
        this.props.setSavedSearchDetail(item);
        if (searchTypeId == BUCKET_ITEM) {
          this.props.isSavedBucket(true);
          this.props.setSavedBucketId(searchId);
        } else {
          this.props.isSavedBucket(false);
          this.props.setSavedBucketId(0);
        }
        $("body").trigger("click");
      }
      this.hideSpinner();
      // axios
      //   .post("/api/SavedSearch/GetSavedSearchFilter", body)
      //   .then(({ data: response }) => {
      //     this.hideSpinner();
      //     if (response.status) {
      //       if (response.status.toLowerCase() === "success") {
      //         this.props.setSavedSearchCriteria({
      //           savedSearchCriteriaDetail: response,
      //           searchTypeId,
      //           searchId,
      //           sharedSaveSearchTypeId: shared_type_id,
      //           sharedBucketSearchId: shared_search_id,
      //         });
      //         //delete item.search_criteria;
      //         this.props.setSavedSearchDetail(item);

      //         if (searchTypeId == BUCKET_ITEM) {
      //           this.props.isSavedBucket(true);
      //           this.props.setSavedBucketId(searchId);
      //         } else {
      //           this.props.isSavedBucket(false);
      //           this.props.setSavedBucketId(0);
      //         }

      //         $("body").trigger("click");
      //       } else {
      //         toast.error(response.message);
      //       }
      //     } else {
      //       toast.error("Could not retrieve saved search detail.");
      //     }
      //   });
    }
  };
  fetchBucketSearchCriteria(item) {
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    if (items !== null && items.length > 0) {
      this.setState({ confirmBucketSearch: item });
      window.$("#CLBSModal").modal("show");
    } else {
      this.fetchSearchCriteria(item);
    }
  }
  handleClose = () => {
    this.props.toggleEditFilterModal({}, false);
  };
  refreshState = async () => {
    this.showSpinner();
    let data = await FiltersService.getMemberSearches(this.state.memberId);
    const { ownershipItems, bucketSearchItems, sharedSearchItems } = data;
    const {
      SavedSearchDetail,
      RefreshState,
      RefreshSelectId,
    } = this.props.savedSearchState;
    const selectId = RefreshSelectId;
    const searchType = SavedSearchDetail
      ? SavedSearchDetail.search_type_id
      : null;

    // const findSearchCriteria = (searchItems) => {
    //   const currentObj = searchItems.find((x) => x.id === selectId);
    //   if (currentObj) {
    //     this.fetchSearchCriteria(currentObj);
    //   }
    // };

    let selectedSearchItem = null;

    if (Array.isArray(ownershipItems) && searchType === OWNERSHIP_ITEM) {
      selectedSearchItem = ownershipItems.find(
        (x) => x.id === SavedSearchDetail.id
      );
    } else if (Array.isArray(bucketSearchItems) && searchType === BUCKET_ITEM) {
      selectedSearchItem = bucketSearchItems.find(
        (x) => x.id === SavedSearchDetail.id
      );
    } else if (Array.isArray(sharedSearchItems) && searchType === SHARED_ITEM) {
      selectedSearchItem = sharedSearchItems.find(
        (x) => x.id === SavedSearchDetail.id
      );
    }

    if (selectedSearchItem) {
      //delete selectedSearchItem.search_criteria;
      this.props.setSavedSearchDetail(selectedSearchItem);
    }

    if (RefreshState) {
      let data2 = ownershipItems.find((x) => x.id === selectId);
      if (!data2) {
        data2 = bucketSearchItems.find((x) => x.id === selectId);
      }
      if (!data2) {
        data2 = sharedSearchItems.find((x) => x.id === selectId);
      }
      if (data2) {
        this.fetchSearchCriteria(data2);
      }
    }

    const allFilters = [
      ...ownershipItems,
      ...bucketSearchItems,
      ...sharedSearchItems,
    ];
    let truncatedList = allFilters.map((obj) => {
      // Create a copy of the object without the "search_criteria" property
      const { search_criteria, ...rest } = obj;
      return rest;
    });
    this.props.setMemberFilters(truncatedList);

    //const pinnedOnly = allFilters.filter((item) => item.is_pinned === 1);

    this.setState({
      ownershipItems,
      bucketSearchItems,
      sharedSearchItems,
      //allFilters: pinnedOnly,
    });

    this.hideSpinner();
  };
  reloadFiltersTable = async () => {
    const { showListing, memberId } = this.state;
    const { loadAllFilters } = this.props;
    if (showListing && !loadAllFilters) {
      this.refreshState(memberId);
    } else if (loadAllFilters) {
      await this.props.setAllFilterLoading(true);
      let data = await FiltersService.getAllSystemFilters();
      this.props.setAllSystemFilters(data);
      await this.props.setAllFilterLoading(false);
    }
  };
  render() {
    const { loadAllFilters } = this.props;
    let filterType = FilterTablesType.MyFilters;
    let filterId = 0; // Can be Saved Search or Shared Saved Search Id
    const {
      isOpenFilterModal,
      editFilterRow: {
        search_name = "",
        id = 0,
        real_search_type_id = 0,
        saved_search_id = 0,
      },
      isLoadingAllFilters,
    } = this.props.savedSearchState;
    if (real_search_type_id) {
      filterId = saved_search_id;
    } else {
      filterId = id;
    }
    let allFilters = [];
    if (loadAllFilters) {
      allFilters = this.props.savedSearchState.allSystemFilters;
      filterType = FilterTablesType.AllSystemFilters;
    } else {
      allFilters = this.props.savedSearchState.loggedInUserFilters;
      filterType = FilterTablesType.MyFilters;
    }

    return (
      <>
        <div className="w-100 mx-auto">
          <br />
          <Card>
            <div className="row mx-0 align-items-center">
              <div className="col-sm-12">
                <h4
                  className="card-header cursorPointer page-title-search"
                  onClick={() => {
                    this.setState(
                      {
                        showListing: !this.state.showListing,
                      },
                      async () => {
                        this.reloadFiltersTable();
                      }
                    );
                  }}
                >
                  Saved Filters List
                </h4>
              </div>
            </div>
            <CardBody className="pt-0">
              <Collapse
                className="filtersCardCollapse"
                isOpen={this.state.showListing}
              >
                <div className="row mx-0">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <FiltersTable
                          listData={allFilters}
                          reloadFilters={this.reloadFilters}
                          isloading={isLoadingAllFilters}
                          viewFilter={this.viewEditFilter}
                          deleteFilter={this.deleteFilter}
                          tableType={filterType}
                          reloadFiltersTable={this.reloadFiltersTable}
                          ///rowEvents={this.rowEvents}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </CardBody>
          </Card>
        </div>
        <EditFilterModal
          showModal={isOpenFilterModal}
          handleClose={this.handleClose}
          savedSearchTitle={search_name}
          savedSearchId={filterId}
          hideActionIcons={EmailListService.shouldHideActionIcons()}
          reloadMemberFilters={this.refreshState}
        />

        {/* <input
          id="toggle-blockUnblock"
          type="checkbox"
          checked
          data-toggle="toggle"
          data-on="<i class='fa fa-unlock-alt'><i/> UnBlock"
          data-off="<i class='fa fa-ban'><i/> Block"
          data-onstyle="success"
          data-offstyle="danger"
          data-style="android"
          data-width="130"
        ></input>
        <input
          id="toggle-subUnsub"
          type="checkbox"
          checked
          data-toggle="toggle"
          data-on="<i class='fa fa-bell-o'><i/> Subscribe"
          data-off="<i class='fa fa-bell-slash-o'></i> UnSubscribe"
          data-onstyle="success"
          data-offstyle="danger"
          data-style="android"
          data-width="140"
          data-height="20"
        ></input> */}
      </>
      // <div>
      //   <div className="card directory-card border-rounded shadow">
      //     <h5 className="card-header mt-0  px-3 py-1">Saved Filters List</h5>
      //     <div className="card-body pb-2 px-0 pt-0">
      //       <FiltersTable
      //         handleRegistrationViewClick={(occasionId) =>
      //           this.handleRegistration(occasionId)
      //         }
      //         listData={allFilters}
      //         reloadFilters={this.reloadFilters}
      //         isloading={this.state.isloading}
      //       />
      //     </div>
      //   </div>
      // </div>
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterId != this.state.filterId) {
      this.showEmailConfigModal();
    }
  }

  componentDidMount = async () => {
    // if (this.props && this.props.location) {
    //   let savedSearchId = this.props.SavedSearch.SaveSearchId; //this.props.location;
    //   if (
    //     this.props.filterState.isAdvancedFilterSelected === true &&
    //     savedSearchId &&
    //     savedSearchId > 0
    //   ) {
    //     this.fetchSearchCriteria(this.props.SavedSearch.SavedSearchDetail);
    //   }
    // }
    ///this.props.history.push("/directory/members");
    this.props.setfilterActionClick(false);
    const {
      allSystemFilters,
      isLoadingAllFilters,
    } = this.props.savedSearchState;
    const { memberId } = this.state;
    const { loadAllFilters } = this.props;
    if (loadAllFilters && _.isEmpty(allSystemFilters) && !isLoadingAllFilters) {
      await this.props.setAllFilterLoading(true);
      let data = await FiltersService.getAllSystemFilters();
      this.props.setAllSystemFilters(data);
      await this.props.setAllFilterLoading(false);
      //console.log("mount for list");
    }
    this.showEmailConfigModal();
    const { location } = this.props.history;
    if (
      !loadAllFilters &&
      location &&
      location.state &&
      location.state.reloadMyFilters
    ) {
      this.refreshState(memberId); //Loads Members' Filters
    }
  };
  showEmailConfigModal = () => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const filterId = searchParams.get("showEmailConfigModal");
    const permissionFailed = location.state
      ? location.state.permissionFailed
      : false;
    if (permissionFailed) {
      toast.warn("Permission to access this route was denied.");
    }
    const { loadAllFilters } = this.props;
    // Check if the query parameter showEmailConfig is true
    if (filterId) {
      this.setState({ filterId });
      let allFilters = [];
      if (loadAllFilters) {
        allFilters = this.props.savedSearchState.allSystemFilters;
      } else {
        allFilters = this.props.savedSearchState.loggedInUserFilters;
      }
      let row = allFilters.find((f) => {
        if (
          f.search_type_id == SavedSearchTypes.OWNERSHIP_ITEM ||
          f.search_type_id == SavedSearchTypes.BUCKET_ITEM
        ) {
          return f.id == filterId;
        } else {
          return f.saved_search_id == filterId; // SavedSearchTypes.SHARED_ITEM
        }
      });
      if (row) {
        this.viewEditFilter(row);
      }
    }
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
}

export default withRouter(
  connect(
    (state) => ({
      filterState: state.advancedFilter,
      savedSearchState: state.saveSearch,
      melstate: state.mailGunEmail,
      facilityDashboardState: state.facilityDashboard,
    }),
    {
      ...actionFilter,
      ...savedSearchAction,
      ...mailGunEmailActions,
    }
  )(SavedSearchFiltersList)
);
