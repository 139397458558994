import StepOne from "./stepOne";
import StepThree from "./stepThree";
import StepTwo from "./stepTwo";
import React, { Component } from "react";
import "./steps-prog.css";
class MasterForm extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.state = {
      currentStep: 1, // Default is Step 1
      email: "",
      username: "",
      password: "",
      steps: [
        {
          key: "firstStep",
          label: "Details",
          isDone: false,
        },
        {
          key: "secondStep",
          label: "Questions",
          isDone: false,
        },
        {
          key: "thirdStep",
          label: "Payment",
          isDone: false,
        },
      ],
      activeStep: {
        key: "firstStep",
        label: "Registration Details",
        isDone: false,
      },
    };

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
  }

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  // Trigger an alert on form submission
  handleSubmit = (event) => {
    event.preventDefault();
    const { email, username, password } = this.state;
    alert(`Your registration detail: \n 
    Email: ${email} \n 
    Username: ${username} \n
    Password: ${password}`);
  };
  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;
    let { activeStep, steps } = this.state;
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (currentStep == 3) {
      return;
    }
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
    let item = { ...steps[index] };

    item.isDone = true;
    steps[index] = item;

    this.setState({ activeStep: steps[index + 1] });

    this.setState({ steps });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click

    if (currentStep <= 1) {
      return;
    }
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
    });
    let { activeStep, steps } = this.state;
    const index = steps.findIndex((x) => x.key === activeStep.key);

    let item = { ...steps[currentStep] };

    item.isDone = false;
    steps[currentStep] = item;

    this.setState({ activeStep: steps[index - 1] });

    this.setState({ steps });
  }
  // The "next" and "previous" button functions
  get previousButton() {
    return (
      <button className="btn btn-info mr-1" type="button" onClick={this._prev}>
        <i class="fa fa-chevron-circle-left" aria-hidden="true">
          Previous
        </i>
      </button>
    );
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button

    if (currentStep < 2) {
      return (
        <button className="btn btn-success" type="button" onClick={this._next}>
          <i class="fa fa-arrow-circle-right" aria-hidden="true">
            Next
          </i>
        </button>
      );
    }
    // ...else render nothing
    return null;
  }

  render() {
    return (
      <React.Fragment>
        {/* Step {this.state.currentStep} */}
        <div className="box">
          <div className="steps">
            <ul className="nav">
              {this.state.steps.map((step, i) => {
                return (
                  <li
                    key={i}
                    className={`${
                      this.state.activeStep.key === step.key ? "active" : ""
                    } ${step.isDone ? "done" : ""}`}
                  >
                    <div>
                      Step {i + 1}
                      <br />
                      <span>{step.label}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* // Render the form steps and pass in the required props */}
          <StepOne currentStep={this.state.currentStep} />
          <StepTwo
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            username={this.state.username}
          />
          <StepThree
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            password={this.state.password}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default MasterForm;
