import AUX from "../../../../hoc/Aux_";
import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Card } from "react-bootstrap";
import EmailConfigTabs from "../../../Email/Enums/EmailConfigTabs";
import mailGunEmailActions from "../../../../store/mailgunEmails/action";
import savedSearchAction from "../../../../store/saveSearch/action";
import { connect } from "react-redux";
import EmailListService from "../../../Email/EmailListService";
import EmailService from "../../../Email/EmailListService";
import { toast } from "react-toastify";
import MailGunEmailTypes from "../../../Email/Enums/MailGunEmailTypes";
import AdvancedFilterTab from "./AdvancedFilterTab";
import FilterMembersTab from "./FilterMembersTab";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import SettingsTab from "./SettingsTab";
import * as SavedSearchTypes from "../../../AdvanceSearch/SavedSearches/SavedSearchTypes";
import FilterEmailConfigTabs from "../../../Email/Enums/FilterEmailConfigTabs";
import MailGunConfigurationTabs from "../../../Email/AuthorizedSenders/MailGunConfigurationTabs";
import MG_DeleteEmailList from "../../../Email/ModalPopups/MG_DeleteEmailList";
import { change } from "redux-form";
import moment from "moment";
import { store } from "../../../../../src/index";
import EmailActivityLogging from "../../../Email/EmailActivityLogging";
import actionActivity from "../../../../store/activitylogging/action";
import AuditModuleTypes from "../../../Shared/AuditModuleTypes";

class EditFilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "member",
      currentEmailConfigTab: "",
      emailPriviliges: [],
      filterDetails: {},
      loadEmailListTab: false,
      entityKey: 0,
      emailListExists: false,
      mailingListAddress: "",
      email_list_title: "",
      emailListId: 0, //Settings ******Mailing list Id******
      emailEntityType: MailGunEmailTypes.Member,
      replyPreference: "",
      listPrivilige: "",
      showDeleteEmailListModal: false,
      isCalledAPI: null,
      isSettingsTabClick: null,
      triggerMapFieldsWithSearchMethod: false,
    };
    this.mailgunConfigurationTabComp = React.createRef();
    this.settingsTabComp = React.createRef();

    // this.confirmDeleteMember = () => {
    //   this.confirmed = async () => {
    //     ////Primary key of table is not 0
    //     let response = null;
    //     if (this.state.deleteEmailConfigRecordId !== 0) {
    //       if (
    //         this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Subscribers
    //       ) {
    //         // Means here we are dealing with *************Additional********** Subscribers => Members + Non Nacta
    //         if (this.state.deleteEmailConfigType == ConfigDataType.NonNatca) {
    //           let {
    //             mgListAddress,
    //             additionalRecipientsNonNatca,
    //           } = this.props.melstate;
    //           let { removeActionType } = this.state;
    //           let currentNonNatcaMember = additionalRecipientsNonNatca.find(
    //             (f) => f.address == this.state.nonNatcaMemberAddress
    //           );
    //           let apiRequestBody = {
    //             recipientType: AddRecptType.NonNatca,
    //             memberEmailAddress: currentNonNatcaMember.address,
    //             entityId: null,
    //             mailingListFullAddress: mgListAddress,
    //             teamMembers: null,
    //             removeActionType,
    //           };
    //           response = await EmailService.RemoveAddlRecipient(apiRequestBody);
    //           if (
    //             removeActionType == RemoveActionType.UnSubscribe &&
    //             response != null
    //           ) {
    //             const nonNatcaUnsubscribersList = this
    //               .nonNatcaUnsubscribersListRef.current;
    //             nonNatcaUnsubscribersList.resetData();
    //             nonNatcaUnsubscribersList.reload();
    //           }
    //         } else {
    //           // Means Additional Members
    //           let {
    //             mgListAddress,
    //             additionalRecipientsMembers,
    //           } = this.props.melstate;
    //           let { removeActionType } = this.state;

    //           let currentMember = additionalRecipientsMembers.find(
    //             (f) => f.memberId == this.state.deleteEmailConfigRecordId
    //           );
    //           let apiRequestBody = {
    //             recipientType: AddRecptType.Member,
    //             memberEmailAddress: currentMember.address, /// member email address
    //             entityId: this.state.deleteEmailConfigRecordId, /// Member id to be deleted
    //             mailingListFullAddress: mgListAddress,
    //             teamMembers: null,
    //             removeActionType,
    //           };
    //           response = await EmailService.RemoveAddlRecipient(apiRequestBody);
    //           if (
    //             removeActionType == RemoveActionType.UnSubscribe &&
    //             response != null
    //           ) {
    //             // const unsubscribersList = this.subscribersListRef.current;
    //             // unsubscribersList.resetData();
    //             // unsubscribersList.reload();
    //           }
    //         }
    //       }
    //       ////Subscribers Tab Ends
    //       else if (
    //         this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Unsubscribed
    //       ) {
    //         if (this.state.deleteEmailConfigType == ConfigDataType.NonNatca) {
    //           const nonNatcaUnsubscribersListRef = this
    //             .nonNatcaUnsubscribersListRef.current;

    //           let { removeActionType, nonNatcaMemberAddress } = this.state;
    //           let currentNonNatcaMember = nonNatcaUnsubscribersListRef.state.nonNatcaUnSubscribers.find(
    //             (f) => f.address == nonNatcaMemberAddress
    //           );
    //           let apiRequestBody = {
    //             recipientType: AddRecptType.NonNatca,
    //             memberEmailAddress: currentNonNatcaMember.address,
    //             entityId: null,
    //             mailingListFullAddress: this.props.melstate.mgListAddress,
    //             teamMembers: null,
    //             removeActionType,
    //           };
    //           response = await EmailService.RemoveAddlRecipient(apiRequestBody);
    //           if (
    //             removeActionType == RemoveActionType.UnSubscribe &&
    //             response != null
    //           ) {
    //             nonNatcaUnsubscribersListRef.resetData();
    //             nonNatcaUnsubscribersListRef.reload();
    //           }
    //         }
    //       } else if (
    //         this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Settings
    //       ) {
    //         response = EmailService.RemoveAuthSender(
    //           this.state.deleteEmailConfigRecordId
    //         );
    //       }
    //     }

    //     ///////########END of Logic for DB Generated Removal Record###########/////////
    //     if (response != null) {
    //       if (
    //         this.props.melstate.currentEmailConfigTab ==
    //           EmailConfigTabs.Subscribers &&
    //         this.state.deleteEmailConfigType == ConfigDataType.NonNatca
    //       ) {
    //         this.props.removeSelectedNonNatcaMember(
    //           this.state.nonNatcaMemberAddress
    //         );
    //       } else if (
    //         this.props.melstate.currentEmailConfigTab ==
    //           EmailConfigTabs.Unsubscribed &&
    //         this.state.deleteEmailConfigType == ConfigDataType.NonNatca
    //       ) {
    //         let { nonNatcaMemberAddress } = this.state;

    //         const nonNatcaUnsubscribersListRef = this
    //           .nonNatcaUnsubscribersListRef.current;
    //         let afterRemoval = nonNatcaUnsubscribersListRef.state.nonNatcaUnSubscribers.filter(
    //           (f) => f.address != nonNatcaMemberAddress
    //         );

    //         nonNatcaUnsubscribersListRef.setState({
    //           nonNatcaUnSubscribers: afterRemoval,
    //         });
    //       } else {
    //         //For Removng Additional Members in Subscribers Tab
    //         this.deSelectMember(this.state.deleteAuthSenderMemberId); /// entity key id
    //         this.props.removeSelectedEntity(
    //           this.state.deleteAuthSenderMemberId,
    //           ConfigDataType.Member
    //         );
    //       }
    //     }
    //     ///########################Removed Record from UI(Client State)#############
    //     this.toggleConfirmDeleteModal();
    //   };
    //   return this;
    // };

    // this.confirmDeleteTeam = () => {
    //   this.confirmed = async () => {
    //     const { deleteEmailConfigRecordId } = this.state;
    //     let response = null;
    //     if (deleteEmailConfigRecordId !== 0) {
    //       if (
    //         this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Subscribers
    //       ) {
    //         //Remove Additional *Team* Subscriber from Mailing List
    //         let {
    //           mgListAddress,
    //           additionalRecipientsTeams,
    //         } = this.props.melstate;
    //         let { removeActionType } = this.state;
    //         let currentTeam = additionalRecipientsTeams.find(
    //           (f) => f.id == deleteEmailConfigRecordId
    //         );
    //         let apiRequestBody = {
    //           recipientType: AddRecptType.Team,
    //           memberEmailAddress: null,
    //           entityId: deleteEmailConfigRecordId, /// Can be Team id, now 'mail_gun_addtl_subs_teams's PrimaryKey.
    //           /// Member id to be deleted
    //           mailingListFullAddress: mgListAddress,
    //           teamMembers: currentTeam.members,
    //           removeActionType,
    //         };
    //         response = await EmailService.RemoveAddlRecipient(apiRequestBody);
    //         if (
    //           removeActionType == RemoveActionType.UnSubscribe &&
    //           response != null
    //         ) {
    //           const additionalTeamUnsubscribersListRef = this
    //             .additionalTeamUnsubscribersListRef.current;
    //           additionalTeamUnsubscribersListRef.resetData();
    //           additionalTeamUnsubscribersListRef.reload();
    //         }
    //       }
    //       //#################  END of Delete Work for (Team Additional **Subscribers***) Tab data ###################
    //       else if (
    //         this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Settings
    //       ) {
    //         response = EmailService.RemoveAuthSender(deleteEmailConfigRecordId);
    //       }

    //       //Removing Additional Unsubscribed Team Members
    //       else if (
    //         this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Unsubscribed
    //       ) {
    //         const additionalTeamUnsubscribersListRef = this
    //           .additionalTeamUnsubscribersListRef.current;

    //         let { removeActionType } = this.state;
    //         let currentTeam = additionalTeamUnsubscribersListRef.state.additionalTeamUnSubscribers.find(
    //           (f) => f.id == deleteEmailConfigRecordId
    //         );
    //         let apiRequestBody = {
    //           recipientType: AddRecptType.Team,
    //           memberEmailAddress: null,
    //           entityId: deleteEmailConfigRecordId, /// Can be Team id, now 'mail_gun_addtl_subs_teams's PrimaryKey.
    //           /// Member id to be deleted
    //           mailingListFullAddress: this.props.melstate.mgListAddress,
    //           teamMembers: currentTeam.members,
    //           removeActionType,
    //         };
    //         response = await EmailService.RemoveAddlRecipient(apiRequestBody);
    //         if (response != null) {
    //           additionalTeamUnsubscribersListRef.resetData();
    //           additionalTeamUnsubscribersListRef.reload();
    //         }
    //       }
    //     } // Means new Team, delete it from client side??????
    //     if (
    //       response != null &&
    //       (this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Settings ||
    //         this.props.melstate.currentEmailConfigTab ==
    //           EmailConfigTabs.Subscribers)
    //     ) {
    //       //Could be Subscriber's Tab Team or Settings's Tab Authorized Team
    //       this.deSelectTeam(this.state.deleteAuthSenderTeamId);
    //       this.props.removeSelectedEntity(
    //         this.state.deleteAuthSenderTeamId,
    //         ConfigDataType.Team
    //       );
    //     }
    //     if (
    //       response != null &&
    //       this.props.melstate.currentEmailConfigTab ==
    //         EmailConfigTabs.Subscribers
    //     ) {
    //       //Subscribers Tab Team
    //       this.props.reloadAdditionalSubsriberTeams();
    //     }
    //     ///########################Removed Record from UI(Client State)#############

    //     this.toggleConfirmDeleteModal();
    //   };
    //   return this;
    // };
    // this.confirmDeleteAll = () => {
    //   this.confirmed = () => {
    //     const { currentEmailConfigTab } = this.props.melstate;
    //     if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
    //       this.props.deleteAllAddlListClick(
    //         this.props.melstate.emailListId,
    //         this.props.melstate.emailEntityType
    //       );
    //     } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
    //       this.props.deleteAllAuthorizedListClick(
    //         this.props.melstate.emailListId,
    //         this.props.melstate.emailEntityType
    //       );
    //     }
    //     this.toggleDeleteAllModal();
    //   };

    //   return this;
    // };
    this.confirmEmailList = () => {
      this.confirmed = async () => {
        let { emailListId } = this.props.melstate;
        let deleteResponse = null;
        await EmailService.DeleteSSEmailList(emailListId).then(
          ({ data: response }) => {
            $(".loading").hide();
            if (response.status === "success") {
              toast.success("Email list removed successfully!");
              deleteResponse = 1;
              $(".loading").hide();
            } else {
              toast.error(response.message);
              deleteResponse = 0;
              $(".loading").hide();
            }
          }
        );
        this.toggleDeleteEmailListModal();
        if (deleteResponse == 1) {
          ///Email list doesn't exist against Saved Filter
          this.props.isEmailListExists(false);
          this.resetState();
        }
      };

      return this;
    };
  }

  // componentWillReceiveProps(nextProps) {}
  componentDidMount() {}

  resetState = () => {
    this.setState({
      emailListId: 0,
      emailListExists: false,
      entityKey: 0,
      mailingListAddress: "",
      email_list_title: "",
      emailEntityType: MailGunEmailTypes.Member,
      replyPreference: "list",
      listPrivilige: "member",
      filterDetails: {},
      isCalledAPI: null,
      isSettingsTabClick: null,
    });
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  handleClose = () => {
    ///////////////////Reset the Email Settings Details/////////////////////////
    this.props.resetEmailListDetails();
    this.props.handleClose();
    this.props.resetSavedSearch();
    this.props.setIsAdvancedFilterOpenedInTab(false);
    this.resetState();
  };
  // renderEmailPriviliges = (isDisabled) => {
  //   const { emailPriviliges } = this.state;
  //   if (!emailPriviliges) return;
  //   return (
  //     <div className="row">
  //       {emailPriviliges.map((privilege, idx) => {
  //         return (
  //           <div class="col-md-3" key={idx}>
  //             <Form.Check
  //               custom
  //               type="radio"
  //               label={privilege.privilage_name}
  //               checked={
  //                 privilege.api_value === this.props.melstate.selectedPrivilige
  //               }
  //               id={privilege.api_value}
  //               name="emailPrivilige[]"
  //               value={privilege.api_value}
  //               disabled={!isDisabled}
  //               onChange={this.onPrivilegeChange}
  //             />
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };
  toggleConfirmDeleteModal = (id) => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  onPrivilegeChange = (e) => {
    let { selectedPrivilige } = this.props.melstate;
    this.setState({
      selectedPrivilige: e.currentTarget.value,
    });
    this.props.setPrivilege(e.currentTarget.value);
    let alreadyAuthSender =
      this.props.melstate.authorizedSendersRows &&
      this.props.melstate.authorizedSendersRows.length > 0;
    if (alreadyAuthSender && selectedPrivilige == "readonly") {
      this.toggleDeleteAllModal();
    }
  };
  // componentDidUpdate(prevProps, prevState) {
  //   const { currentFilterConfigTab } = this.props.melstate;
  //   if (prevState.key !== currentFilterConfigTab) {
  //   }
  //   console.log("componentDidUpdate edit filter modal");
  // }
  getEmailListDetails = async () => {
    await EmailListService.GetSavedSearchDetail(this.props.savedSearchId)
      .then(async ({ data: response }) => {
        if (response.status === "success") {
          this.props.isEmailListExists(true);

          this.setState({
            emailListExists: true,
            entityKey: response.searchDetail.saved_search_id,
            mailingListAddress: response.searchDetail.email_address,
            email_list_title: response.searchDetail.email_list_title,
            emailListId: response.searchDetail.id, //Settings ******Mailing list Id******
            emailEntityType: MailGunEmailTypes.Member,
            replyPreference: response.searchDetail.reply_preference,
            listPrivilige: response.selectedPrivilege,
            filterDetails: response.searchDetail,
          });
          let {
            email_list_title,
            emailListId,
            entityKey,
            emailEntityType,
            mailingListAddress,
            replyPreference,
            listPrivilige,
          } = this.state;
          let formData = {
            emailListId: emailListId,
            emailEntityType: emailEntityType,
            entityKey: entityKey,
            mailingListAddress,
            replyPreference,
            listPrivilige,
          };
          await this.props.setEmailListName(
            response.searchDetail.email_list_title
          );
          await this.props.setEmailListDetails(formData); // Could be setting name
          let fullAliasPlusDomain = mailingListAddress;
          //  console.log("fullAliasPlusDomain", fullAliasPlusDomain);
          this.props.setMgListFullAddress(fullAliasPlusDomain);
          this.props.setEmailListName(email_list_title); // ***Definitely be setting name***
          this.setState({ loadEmailListTab: true });
          //console.log(this.state.mailingListAddress);
        } else {
          ///Email list doesn't exist against Saved Filter
          this.props.isEmailListExists(false);
          this.setState({
            emailListExists: false,
          });
          this.setEmailAddMode();
          this.resetState();
        }
      })
      .catch((ex) => {
        console.log(ex);
        toast.error("Something went wrong!");
      });
  };
  setEmailAddMode = async () => {
    await this.props.resetEmailConfigDetails();
    const { savedSearchTitle, savedSearchId } = this.props;
    let mailingListAddressPart = savedSearchTitle
      .replace(/\s+/g, "-")
      .toLowerCase();
    //The regular expression /\s+/g is used to match one or more whitespace characters.
    //The g flag at the end of the regular expression is used to perform a global search,
    //which means it will replace all occurrences of the matched pattern in the string
    let fullAliasPlusDomain = mailingListAddressPart + this.state.domain;
    //console.log("fullAliasPlusDomain", fullAliasPlusDomain);
    await this.props.setMgListFullAddress(fullAliasPlusDomain);
    this.setState({
      mailingListAddress: fullAliasPlusDomain,
    });
    // const {
    //   editFilterRow: { search_name = "", id = 0 },
    // } = this.props.savedSearchState;
    let {
      email_list_title,
      emailListId,
      entityKey,
      emailEntityType = MailGunEmailTypes.Member,
      mailingListAddress,
      replyPreference,
      listPrivilige,
    } = this.state;

    if (entityKey === 0) {
      entityKey = savedSearchId;
    }
    let formData = {
      emailListId: emailListId,
      emailEntityType: emailEntityType,
      entityKey: entityKey,
      mailingListAddress,
      // mailingListName: mailingListName, ///// Name
      replyPreference,
      listPrivilige,
    };

    await this.props.setEmailListDetails(formData); // Could be setting name
    if (savedSearchTitle) {
      await this.props.setEmailListName(savedSearchTitle);
    } else {
      await this.props.setEmailListName("Saved Filter");
    }
    this.props.setPrivilege("members");

    await this.props.setAddtlSubsLoading(false);
  };
  setKey = async (k) => {
    this.setState({ key: k });
    await this.props.setCurrentFilterConfigTab(k); // Set for Filter Tab

    // const {
    //   currentEmailConfigTab,
    //   currentFilterConfigTab,
    //   entityKey,
    // } = this.props.melstate;
    const { loadEmailListTab, isCalledAPI, isSettingsTabClick } = this.state;
    const { savedSearchId } = this.props;
    ///Setting the Data for Email Lis Tab Mostly for Old Settings Tab's Authorized Members/Teams
    //debugger;
    const { filterDetails } = this.state;

    if (
      k == FilterEmailConfigTabs.EmailList &&
      ((!_.isEmpty(filterDetails) &&
        filterDetails.saved_search_id !== savedSearchId) ||
        _.isEmpty(filterDetails) ||
        loadEmailListTab) &&
      !isCalledAPI
    ) {
      // if (
      //   (!_.isEmpty(filterDetails) &&
      //     filterDetails.saved_search_id !== this.props.savedSearchId) ||
      //   _.isEmpty(filterDetails)
      // )
      // {

      if (isSettingsTabClick != true) {
        await this.getEmailListDetails();
      }

      this.setState({ isCalledAPI: true });
      if (this.props.savedSearchId) {
        // await EmailListService.GetEmailPriviliges()
        //   .then(async ({ data: response }) => {
        //     await this.setState({
        //       emailPriviliges: response,
        //     });
        //   })
        //   .catch((ex) => {
        //     console.log(ex);
        //     toast.error("Something went wrong!");
        //   });

        await EmailListService.GetDomainName()
          .then(async ({ data: response }) => {
            if (response.status === "success") {
              await this.setState({
                domain: response.domain,
                //postDomain: response.postDomain,
              });
              //console.log(this.state.domain);
              let domainName = response.domain;
              domainName = domainName.replace("@", "");
              await this.props.setMgListDomain(domainName);
              //await this.props.setMgPostListDomain(response.postDomain);
            }
          })
          .catch((ex) => {
            console.log(ex);
            toast.error("Something went wrong!");
          });
      }
      //this.refreshData();
      // this.getSubscribedMembers();
      // this.getUnsubscribeMembers();
      await this.props.setCurrentEmailConfigTab(EmailConfigTabs.Settings); // Updating the old Email Config Tab to Settings tab because it make the necessary state configuraiton
      // Authorized Senders Modal actions

      this.setState({ loadEmailListTab: false });
      $(".loading").show();
      ///////// Work of Email Enhancements/////////
      await this.props.setAddtlSubsLoading(true);

      //debugger;
      let {
        email_list_title,
        emailListId,
        entityKey,
        emailEntityType,
        mailingListAddress,
        replyPreference,
        listPrivilige,
      } = this.state;
      //Edit Mode
      if (emailListId) {
        let formData = {
          emailListId: emailListId,
          emailEntityType: emailEntityType,
          entityKey: entityKey,
          mailingListAddress,
          replyPreference,
          listPrivilige,
        };
        await this.props.setMgListFullAddress(mailingListAddress);
        if (
          formData.emailListId &&
          formData.emailEntityType &&
          formData.entityKey &&
          formData.mailingListAddress
        ) {
          this.props.resetAuthSenderMembers();
          //this.props.resetAddlMembers();
          if (listPrivilige == "readonly") {
            this.props.getAuthSenders(formData);
          }
          this.fetchLogsCardClick(emailListId, emailEntityType);

          //await this.props.reloadAdditionalSubsribersTables();
        } else {
          console.log("form data key values are invalid");
        }
      }
      /// Add mode
      else {
        this.setEmailAddMode();
      }
      await this.props.setAddtlSubsLoading(false);
    }

    //if (emailListId == 0) {
    // await this.props.setCurrentFilterConfigTab(EmailConfigTabs.Settings);
    // await this.setState({
    //   currentEmailConfigTab: EmailConfigTabs.Settings,
    // });
    //}
    //debugger;
    // let { currentEmailConfigTab } = this.state;
    // configTab && (await configTab.setKey(currentEmailConfigTab));
    $(".loading").hide();
    //}
    //// *********** When Filter Tab is clicked**************///
    if (k == FilterEmailConfigTabs.Filter) {
      this.setReduxFormValues(); //Used in edit Filter to show already saved redux form filter edit values
    }
    if (k == FilterEmailConfigTabs.Settings) {
      if (!isCalledAPI && isSettingsTabClick == null) {
        await this.getEmailListDetails();
        this.setState({ isSettingsTabClick: true });
      }
    }
  };
  setReduxFormValues = () => {
    const { savedSearchCriteria } = this.props.savedSearchState;
    if (!_.isEmpty(savedSearchCriteria)) {
      const { fromDate = null, toDate = null } =
        savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
          .mandatoryRetirement || {};
      const { hourlySalary = null } =
        savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};

      if (moment(fromDate).isValid()) {
        store.dispatch(
          change(
            "mandatoryRetirementForm",
            "FromDate",
            moment(fromDate).toDate()
          )
        );
      }
      if (moment(toDate).isValid()) {
        store.dispatch(
          change("mandatoryRetirementForm", "ToDate", moment(toDate).toDate())
        );
      }
      if (
        hourlySalary != null &&
        hourlySalary.fromHourlySalary != null &&
        hourlySalary.toHourlySalary != null
      ) {
        const initialValues = {
          HourlySalary: {
            min: hourlySalary.fromHourlySalary,
            max: hourlySalary.toHourlySalary,
          },
        };
        store.dispatch(
          change("hourlySalaryForm", "HourlySalary", initialValues.HourlySalary)
        );
      }
      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
            .activeTeamPositionDate || {};

        if (moment(fromDate).isValid()) {
          store.dispatch(
            change(
              "activeDatePositionForm",
              "FromDate",
              moment(fromDate).toDate()
            )
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("activeDatePositionForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria.buDate ||
          {};
        if (moment(fromDate).isValid()) {
          store.dispatch(
            change("bUDateForm", "FromDate", moment(fromDate).toDate())
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("bUDateForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
      {
        const { age = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        if (age != null && age.fromAge != null && age.toAge != null) {
          const initialValues = {
            Age: {
              min: age.fromAge,
              max: age.toAge,
            },
          };
          store.dispatch(change("ageForm", "Age", initialValues.Age));
        }
      }
      {
        const { drf = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        if (drf != null && drf.fromDRF != null && drf.toDRF != null) {
          const initialValues = {
            DRF: {
              min: drf.fromDRF,
              max: drf.toDRF,
            },
          };
          store.dispatch(change("drfForm", "DRF", initialValues.DRF));
        }
      }
      {
        const { memberECI = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        if (!_.isEmpty(memberECI)) {
          const initialValues = {
            memberECI: memberECI.map((m) => m.toString()), // Example initial value for firstName
          };
          store.dispatch(
            change("memberECIForm", "memberECI", initialValues.memberECI)
          );
        }
      }
      const { email = null, nullPrimaryEmail = null } =
        savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
      if (email != null) {
        store.dispatch(change("emailForm", "Email", email));
      }
      if (nullPrimaryEmail != null) {
        store.dispatch(
          change("emailForm", "NullPrimaryEmail", nullPrimaryEmail)
        );
      }

      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
            .eodDate || {};
        if (moment(fromDate).isValid()) {
          store.dispatch(
            change("eODDateForm", "FromDate", moment(fromDate).toDate())
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("eODDateForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
      {
        const { facebook = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        const initialValues = {
          Facebook: facebook,
        };
        if (!_.isEmpty(initialValues.facebook)) {
          store.dispatch(
            change("facebookForm", "Facebook", initialValues.Facebook)
          );
        }
      }
      {
        const { facilityRoutingSymbol = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        if (!_.isEmpty(facilityRoutingSymbol)) {
          const initialValues = {
            FacilityRoutingSymbol: facilityRoutingSymbol, // Example initial value for firstName
          };
          store.dispatch(
            change(
              "facilityRoutingSymbolForm",
              "FacilityRoutingSymbol",
              initialValues.FacilityRoutingSymbol
            )
          );
        }
      }
      {
        const { firstName = null, lastName = null, previousLastName = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria.name ||
          {};
        const initialValues = {
          firstName: firstName, // Example initial value for firstName
          // Add other fields and their initial values as needed
          lastName: lastName,
          previousLastName: previousLastName,
        };
        if (!_.isEmpty(initialValues.firstName)) {
          store.dispatch(
            change("nameForm", "firstName", initialValues.firstName)
          );
        }
        if (!_.isEmpty(initialValues.lastName)) {
          store.dispatch(
            change("nameForm", "lastName", initialValues.lastName)
          );
        }
        if (!_.isEmpty(initialValues.previousLastName)) {
          store.dispatch(
            change(
              "nameForm",
              "previousLastName",
              initialValues.previousLastName
            )
          );
        }
      }
      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
            .natcabuDate || {};
        if (moment(fromDate).isValid()) {
          store.dispatch(
            change("nATCABUDateForm", "FromDate", moment(fromDate).toDate())
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("nATCABUDateForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
      {
        const { memberNumber = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        if (!_.isEmpty(memberNumber)) {
          const initialValues = {
            memberNumbers: memberNumber.map((m) => m.toString()), // Example initial value for firstName
          };
          store.dispatch(
            change("memberNoForm", "memberNumbers", initialValues.memberNumbers)
          );
        }
      }
      {
        const { orgCode = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        if (!_.isEmpty(orgCode)) {
          store.dispatch(change("orgCodeForm", "OrgCode", orgCode));
        }
      }
      {
        const { pac = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
        if (pac != null && pac.fromPAC != null && pac.toPAC != null) {
          const initialValues = {
            PAC: {
              min: pac.fromPAC,
              max: pac.toPAC,
            },
          };
          store.dispatch(change("pacForm", "PAC", initialValues.PAC));
        }
      }
      const { phone = null, nullPhoneTotal = null } =
        savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria || {};
      if (phone != null) {
        store.dispatch(change("phoneForm", "Phone", phone));
      }
      if (nullPhoneTotal != null) {
        store.dispatch(change("phoneForm", "NullPrimaryPhone", nullPhoneTotal));
      }

      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
            .plannedRetirement || {};
        if (moment(fromDate).isValid()) {
          store.dispatch(
            change(
              "plannedRetirementForm",
              "FromDate",
              moment(fromDate).toDate()
            )
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("plannedRetirementForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
            .retirement || {};
        if (moment(fromDate).isValid()) {
          store.dispatch(
            change("retirementForm", "FromDate", moment(fromDate).toDate())
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("retirementForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria.scd ||
          {};

        if (moment(fromDate).isValid()) {
          store.dispatch(
            change("sCDForm", "FromDate", moment(fromDate).toDate())
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(change("sCDForm", "ToDate", moment(toDate).toDate()));
        }
      }

      const { positionName = null, teamName = null } =
        savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
          .teamPosition || {};
      if (!_.isEmpty(positionName)) {
        store.dispatch(
          change("teamPositionForm", "positionName", positionName)
        );
      }
      if (!_.isEmpty(teamName)) {
        store.dispatch(change("teamPositionForm", "teamName", teamName));
      }

      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
            .unionJoinDate || {};

        if (moment(fromDate).isValid()) {
          store.dispatch(
            change("unionJoinDateForm", "FromDate", moment(fromDate).toDate())
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("unionJoinDateForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
      {
        const { fromDate = null, toDate = null } =
          savedSearchCriteria.savedSearchCriteriaDetail.searchCriteria
            .unionStartDate || {};
        if (moment(fromDate).isValid()) {
          store.dispatch(
            change("unionStartDateForm", "FromDate", moment(fromDate).toDate())
          );
        }
        if (moment(toDate).isValid()) {
          store.dispatch(
            change("unionStartDateForm", "ToDate", moment(toDate).toDate())
          );
        }
      }
    } //END IF
  };

  //Onshow For New Edit Filter Tabs
  onShow = async () => {
    //debugger;
    //console.log("onshow calledddddd");
    ////Now According to New UI Settings tab will be in the Email List Tab, and Autohrized Senders work should be performed from here!!!!
    await this.props.setCurrentFilterConfigTab(FilterEmailConfigTabs.Members);
    this.setState({
      currentEmailConfigTab: FilterEmailConfigTabs.Members,
      key: FilterEmailConfigTabs.Members,
    });
  };
  // toggleEmailAliasModal = () => {
  //   this.setState({
  //     showEmailAliasModal: !this.state.showEmailAliasModal,
  //   });
  // };
  // changeHandlerListName = (evt) => {
  //   this.props.setEmailListName(evt.target.value);
  // };
  // handleChange = (evt) => {
  //   this.setState({
  //     [evt.target.id]: evt.target.value,
  //   });
  //   if (evt.target.id == "reply_preference") {
  //     this.props.setReplyPref(evt.target.value);
  //   }

  //   if (evt.target.id == "emailAlias") {
  //     let val = evt.target.value.replace(/[^\w\s-]/gi, "");
  //     this.props.setMgListAlias(val);
  //   }
  // };
  ///Invoked before Opening "Confirm Delete Member" Modal
  // handleConfirmDeleteMember = (id, memberid, type, removeActionType) => {
  //   if (id !== 0) {
  //     this.setState(
  //       {
  //         isConfirmModalOpen: !this.state.isConfirmModalOpen,
  //       },
  //       () => {
  //         if (type == EmailConfigTables.NonNatcaMembers) {
  //           this.setState({
  //             deleteEmailConfigRecordId: memberid,
  //             nonNatcaMemberAddress: memberid, ///this will be the string address of member
  //             deleteEmailConfigType: ConfigDataType.NonNatca,
  //             confirmModelType: type,
  //             removeActionType,
  //           });
  //         } else {
  //           this.setState({
  //             deleteEmailConfigRecordId: id, // row.id can be zero
  //             deleteAuthSenderMemberId: memberid, /// int id of the member
  //             deleteEmailConfigType: ConfigDataType.Member,
  //             confirmModelType: type,
  //             removeActionType,
  //           });
  //         }
  //       }
  //     );
  //   } else {
  //     if (type == EmailConfigTables.NonNatcaMembers) {
  //       this.props.removeSelectedNonNatcaMember(memberid);
  //     } else {
  //       this.props.removeSelectedEntity(memberid, ConfigDataType.Member);
  //       this.deSelectMember(memberid);
  //     }
  //   }
  // };
  // handleConfirmDeleteTeam = (id, teamid, type, removeActionType) => {
  //   if (id) {
  //     this.setState(
  //       {
  //         isConfirmModalOpen: !this.state.isConfirmModalOpen,
  //       },
  //       () => {
  //         this.setState({
  //           deleteEmailConfigRecordId: id,
  //           deleteAuthSenderTeamId: teamid,
  //           deleteEmailConfigType: ConfigDataType.Team,
  //           confirmModelType: type,
  //           removeActionType,
  //         });
  //       }
  //     );
  //   } else {
  //     this.props.removeSelectedEntity(teamid, ConfigDataType.Team);
  //     this.deSelectTeam(teamid);
  //   }
  // };
  toggleDeleteEmailListModal = () => {
    this.setState({
      showDeleteEmailListModal: !this.state.showDeleteEmailListModal,
    });
  };
  // deSelectMember = (id) => {
  //   this.props.deSelectMember(id);
  // };
  // selectMember = (selectedMem) => {
  //   this.props.selectMember(selectedMem);
  // };
  // deSelectTeam = (id) => {
  //   this.props.deSelectTeam(id);
  // };
  // selectTeam = (selectedTeam) => {
  //   this.props.selectTeam(selectedTeam);
  // };
  // addAuthTeams = () => {
  //   this.props.selectMultipleAuthSender(
  //     this.state.selectedTeams,
  //     ConfigDataType.Team
  //   );
  //   this.handleClose();
  // };
  // addAuthMembers = () => {
  //   this.props.selectMultipleAuthSender(ConfigDataType.Member);
  //   this.handleClose();
  // };
  // changeHandlerListName = (evt) => {
  //   this.props.setEmailListName(evt.target.value);
  // };
  // toggleAddNonNatcaModal = () => {
  //   this.setState({
  //     isOpenAddNonNatcaModal: !this.state.isOpenAddNonNatcaModal,
  //   });
  // };
  // toggleConfirmDeleteModal = (id) => {
  //   this.setState({
  //     isConfirmModalOpen: !this.state.isConfirmModalOpen,
  //   });
  // };
  // setCurrentTeamInfo = (teamName, teamid, removeActionType) => {
  //   this.setState({
  //     team: teamName,
  //     teamId: teamid,
  //     teamModalOpended: true,
  //     memberModalOpended: false,
  //     removeActionType,
  //   });
  // };
  // setCurrentSenderInfo = (firstname, lastname, memberid, removeActionType) => {
  //   this.setState({
  //     userFirstName: firstname,
  //     userLastName: lastname,
  //     memberId: memberid,
  //     memberModalOpended: true,
  //     teamModalOpended: false,
  //     removeActionType,
  //   });
  // };
  // toggleEmailAliasModal = () => {
  //   this.setState({
  //     showEmailAliasModal: !this.state.showEmailAliasModal,
  //   });
  // };
  // toggleDeleteAllModal = () => {
  //   this.setState({
  //     showEmailDeleteAllModal: !this.state.showEmailDeleteAllModal,
  //   });
  // };
  onCancelClick = () => {
    this.toggleDeleteAllModal();
    this.setState({ selectedPrivilige: "readonly" });
    this.props.setPrivilege("readonly");
  };
  fetchLogsCardClick = (emailListId, emailEntityType) => {
    /// ActivityLogging
    const Id = `${emailListId},${emailEntityType}`;
    if (!_.isEmpty(Id)) {
      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.MailGunList,
        Id,
        0,
        10,
        "mail_list_logs"
      );
    }
  };
  ///Called only for Email List Tab i.e List Address/Authorized Senders/Reply Pref..... add/update
  handleSubmit = async () => {
    await this.props.saveEmailConfigData();
    let { emailListId, emailEntityType } = this.state;
    this.fetchLogsCardClick(emailListId, emailEntityType);
    // if (data != null) {
    //   this.setState({ emailListExists: true });
    // } else {
    //   console.log("error in creation record.");
    // }
    this.resetState();
    this.handleClose();
  };
  render() {
    //const Id = `${this.props.emailListId},${this.props.emailEntityType}`;

    const {
      currentEmailConfigTab,
      currentFilterConfigTab,
    } = this.props.melstate;
    const {
      editFilterRow: {
        id = 0,
        search_name = "",
        search_type_id = 0,
        real_search_type_id,
      },
    } = this.props.savedSearchState;
    const {
      mgListAddress,
      mailingListName: mgListTitle = "",
    } = this.props.melstate;
    const { emailListExists } = this.state;
    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="EditSaveFilter"
          size="xl"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-white mt-0">
                Edit "{search_name}" Filter
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Tabs
                id="filterEmailConfigTabs"
                activeKey={currentFilterConfigTab}
                onSelect={(k) => this.setKey(k)}
                className="mb-3"
              >
                {/* ************ Add Mode ************** */}
                {/* Email List does not Exists */}
                {/* {this.props.emailListExists && ( */}

                <Tab
                  eventKey="member"
                  // tabClassName={
                  //   !this.props.ssUpdateEmailListMode ? "d-none" : ""
                  // }
                  title={
                    <>
                      <i class="fas fa-users">
                        {" "}
                        <span className="font-weight-bold"> Members</span>
                      </i>
                    </>
                  }
                >
                  <FilterMembersTab />
                </Tab>

                {/* '1', 'Regular Search' 
                    '2', 'Bucket Search' */}
                {/* No Filter Tab for **Bucket Search** */}
                {(search_type_id == SavedSearchTypes.OWNERSHIP_ITEM ||
                  real_search_type_id == SavedSearchTypes.OWNERSHIP_ITEM) && (
                  <Tab
                    // tabClassName={
                    //   currentFilterConfigTab != FilterEmailConfigTabs.Filter
                    //     ? "d-none"
                    //     : ""
                    // }
                    eventKey="filter"
                    title={
                      <>
                        <i className="fab fa-searchengin">
                          <span className="font-weight-bold"> Filter</span>
                        </i>
                      </>
                    }
                  >
                    {/*currentFilterConfigTab == FilterEmailConfigTabs.Filter &&*/
                    this.state.currentEmailConfigTab != "" && (
                      <AdvancedFilterTab />
                    )}
                  </Tab>
                )}

                {/* )} */}
                {/*#######################Email List########################## */}
                <Tab
                  eventKey="emailList"
                  title={
                    <>
                      <i className="fas fa-users-cog">
                        <span className="font-weight-bold"> Email List</span>
                      </i>
                    </>
                  }
                  tabClassName=""
                >
                  <Card>
                    <Card.Body className="pt-0">
                      <>
                        {/* <EmailListTab
                          //  BusinessProps
                          filterDetails={this.state.filterDetails}
                          //*****************State************
                          isOpenAddNonNatcaModal={
                            this.state.isOpenAddNonNatcaModal
                          }
                          teamModalOpended={this.state.teamModalOpended}
                          isConfirmModalOpen={this.state.isConfirmModalOpen}
                          userFirstName={this.state.userFirstName}
                          team={this.state.team}
                          removeActionType={this.state.removeActionType}
                          memberModalOpended={this.state.memberModalOpended}
                          confirmModelType={this.state.confirmModelType}
                          showEmailAliasModal={this.state.showEmailAliasModal}
                          showEmailDeleteAllModal={
                            this.state.showEmailDeleteAllModal
                          }
                          showDeleteEmailListModal={
                            this.state.showDeleteEmailListModal
                          }
                          userLastName={this.state.userLastName}
                          currentEmailConfigTab={currentEmailConfigTab}
                          currentFilterConfigTab={currentFilterConfigTab}
                          //*****************Funcitons************
                          toggleDeleteEmailListModal={
                            this.toggleDeleteEmailListModal
                          }
                          confirmEmailList={this.confirmEmailList}
                          onCancelClick={this.onCancelClick}
                          toggleDeleteAllModal={this.toggleDeleteAllModal}
                          confirmDeleteAll={this.confirmDeleteAll}
                          changeHandlerListName={this.changeHandlerListName}
                          toggleEmailAliasModal={this.toggleEmailAliasModal}
                          handleChange={this.handleChange}
                          confirmDeleteMember={this.confirmDeleteMember}
                          confirmDeleteTeam={this.confirmDeleteTeam}
                          toggleConfirmDeleteMember={
                            this.handleConfirmDeleteMember
                          }
                          toggleConfirmDeleteTeam={this.handleConfirmDeleteTeam}
                          //Authorized Senders
                          handleToggleConfirm={this.toggleConfirmDeleteModal}
                          setCurrentSenderInfo={this.setCurrentSenderInfo}
                          setCurrentTeamInfo={this.setCurrentTeamInfo}
                          renderEmailPriviliges={this.renderEmailPriviliges}
                          
                          hideActionIcons={this.props.hideActionIcons}
                        /> */}
                        {/* {!emailListExists &&
                          AuthService.canAdd(
                            PermissionFeature.EmailListsLists
                          ) && (
                            <div className="row">
                              <div className="col">
                                <h6 className="mt-0 text-center">
                                  Create New Email List
                                </h6>
                              </div>
                            </div>
                          )}
                        {emailListExists &&
                          AuthService.canUpdate(
                            PermissionFeature.EmailListsLists
                          ) && (
                            <div className="row">
                              <div className="col">
                                <h6 className="mt-0 text-center">
                                  Update Email List Details
                                </h6>
                              </div>
                            </div>
                          )} */}
                        <MailGunConfigurationTabs
                          ///////  BusinessProps/////////
                          isCalledAPI={this.state.isCalledAPI}
                          filterDetails={this.state.filterDetails}
                          ////////////////////////////////////////
                          mgListAddress={mgListAddress}
                          emailListTitle={mgListTitle}
                          ssUpdateEmailListMode={true} //will always be edit mode
                          isModalPopUpOpended={true}
                          emailEntityType={MailGunEmailTypes.Member}
                          ref={this.mailgunConfigurationTabComp}
                          entityKey={id}
                          emailListExists={this.state.emailListId != 0}
                          emailListId={this.state.emailListId}
                          currentEmailConfigTab={
                            this.state.currentEmailConfigTab
                          }
                          hideActionIcons={EmailListService.shouldHideActionIcons()}
                          editFilterModalCall={true}
                          showTwoTabs={false} //only show Settings Tab
                          savedSearchTitle={this.props.savedSearchTitle} // Passed from Filters List component.
                          savedSearchId={this.props.savedSearchId} // Passed from Filters List component.
                        />
                      </>
                    </Card.Body>
                  </Card>
                </Tab>
                <Tab
                  eventKey="settings"
                  // tabClassName={
                  //   !this.props.ssUpdateEmailListMode ? "d-none" : ""
                  // }
                  title={
                    <>
                      <i class="fas fa-users">
                        {" "}
                        <span className="font-weight-bold"> Settings</span>
                      </i>
                    </>
                  }
                >
                  <Card>
                    <Card.Body className="pt-0">
                      <SettingsTab
                        savedSearchId={this.props.savedSearchId} // Passed from Filters List component.
                        currentTab={currentFilterConfigTab}
                        reloadMemberFilters={this.props.reloadMemberFilters}
                        ref={this.settingsTabComp}
                        triggerMapFieldsWithSearchMethod={
                          this.state.triggerMapFieldsWithSearchMethod
                        }
                        resetEditFilterModalState={this.resetState}
                      />
                    </Card.Body>
                  </Card>
                </Tab>
                {/*#######################  Settings Tab END Here########################## */}

                {/* ************************************New Tab Activity Logging************************************ */}
                {/* <Tab
                  eventKey="activityLogs"
                  title={
                    <>
                      <i class="fa fa-history">
                        {" "}
                        <span
                          className="font-weight-bold"
                          onClick={this.fetchLogsCardClick}
                        >
                          {" "}
                          Activity Logs
                        </span>
                      </i>
                    </>
                  }
                >
                  <EmailActivityLogging
                    getActicityLoggingByModuleId={
                      this.props.getActicityLoggingByModuleId
                    }
                    //ref={this.activityLogs}
                    toggleTeamLogAccordion={this.props.toggleTeamLogAccordion}
                    auditState={this.props.activityLoggingState}
                    paramId={Id}
                  />
                </Tab> */}
                {/* ************************************END Tab Activity Logging************************************ */}
              </Tabs>

              <div
                // className={`form-group mb-4 row ${
                //   this.state.key == "filter" ? "invisible" : ""
                // }`}
                className={`form-group mb-4 row`}
              >
                <div className="col-12 m-t-20 mx-auto text-center">
                  {
                    <Fragment>
                      <button
                        className="btn btn-primary w-md waves-effect cancelModal waves-light mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                      {((!emailListExists &&
                        AuthService.canAdd(
                          PermissionFeature.EmailListsLists
                        )) ||
                        (emailListExists &&
                          AuthService.canUpdate(
                            PermissionFeature.EmailListsLists
                          )) ||
                        AuthService.canView(
                          PermissionFeature.EmailListAdmin
                        )) &&
                        (currentFilterConfigTab ==
                          FilterEmailConfigTabs.EmailList ||
                          currentFilterConfigTab ==
                            FilterEmailConfigTabs.Settings) && (
                          <button
                            className="btn btn-success ml-sm-2 ml-1 w-md waves-effect waves-light"
                            type="submit"
                            onClick={() => {
                              if (
                                this.state.key ==
                                FilterEmailConfigTabs.EmailList
                              ) {
                                this.handleSubmit();
                              }
                              /// Setting Tab's Operations like Assign Members/Filter Columns Order
                              else if (
                                this.state.key == FilterEmailConfigTabs.Settings
                              ) {
                                //this.handleClose();
                                // let ref = this.settingsTabComp.current;
                                // if (_.isEmpty(ref)) {
                                //   console.log(ref);
                                // }
                                //ref.mapFieldsWithSearch();
                                this.setState({
                                  triggerMapFieldsWithSearchMethod: !this.state
                                    .triggerMapFieldsWithSearchMethod,
                                });
                              }
                            }}
                          >
                            {currentFilterConfigTab ==
                            FilterEmailConfigTabs.Settings
                              ? "Save Changes"
                              : this.state.emailListExists &&
                                currentFilterConfigTab ==
                                  FilterEmailConfigTabs.EmailList
                              ? "Update List"
                              : "Create List"}
                          </button>
                        )}
                      {this.state.emailListId &&
                      currentFilterConfigTab ==
                        FilterEmailConfigTabs.EmailList &&
                      (EmailService.shouldHideActionIcons() ||
                        this.canDeleteEmailList() === true) ? (
                        <button
                          className="btn btn-danger ml-sm-2 ml-1 w-md waves-effect waves-light delbtnel"
                          type="button"
                          onClick={this.toggleDeleteEmailListModal}
                        >
                          Delete Email List
                        </button>
                      ) : null}
                      <MG_DeleteEmailList
                        modal={this.state.showDeleteEmailListModal}
                        confirm={this.confirmEmailList}
                        toggle={this.toggleDeleteEmailListModal}
                      />
                    </Fragment>
                  }
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
export default connect(
  (state) => ({
    melstate: state.mailGunEmail,
    activityLoggingState: state.activityLogging,
    savedSearchState: state.saveSearch,
  }),
  {
    ...mailGunEmailActions,
    ...savedSearchAction,
    ...actionActivity,
  },
  null,
  { forwardRef: true }
)(EditFilterModal);
