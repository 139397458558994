import React, { Component } from "react";
import { Row, Col, Card } from "reactstrap";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "../../Shared/auth-header";
import NCEPTChartsModal from "./NCEPTChartsModal";

export default class NCEPT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFound: false,
      isModalOpenChart: false,
      isYearActive: false,
      modalTitle: "",
      chartName: "",
      activeTab: "0",
      nceptData: {
        cpctarget: null,
        cpctargetstatus: null,
        cpctargetdifference: null,
        staffingworkbook: null,
        cpcontemp: null,
        cpconlongtermhold: null,
        currentcpconboard: null,
        currentcpconboardstatus: null,
        currentcpconboarddifference: null,
        currentpercenttocpctarget: null,
        currentpercenttocpctargetstatus: null,
        currentpercenttocpctargetdifference: null,
        trainingtime: null,
        trainingtimestatus: null,
        trainingtimedifference: null,
        atcsintrainingminuslth: null,
        trainingsuccessrate: null,
        trainingexpectedtocert: null,
        committedatcsinbound: null,
        placementlistinbound: null,
        tempsinbound: null,
        inboundexpectedtocertify: null,
        committedatcsoutbound: null,
        placementlistoutbound: null,
        tempsoutbound: null,
        projectedretirements: null,
        totaloutbound: null,
        projectedpercenttotarget: null,
        possiblegainstonationalaverage: null,
        possiblegainstotarget: null,
        errcategory: null,
        currentcpctotraineespercent: null,
        possiblelosses: null,
        nationalcpctarget: null,
        nationalcpconboard: null,
        nationalcpctotarget: null,
        nationalprojectedcpctotarget: null,
        createdate: null,
      },
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
              formatter: null,
            },
          },
        },
        legend: {
          data: [""],
        },
        toolbox: {
          feature: {
            saveAsImage: {
              title: "Download",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            name: null,
            type: "value",
            boundaryGap: false,
            min: null,
            max: null,
            data: null,
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            areaStyle: {},
            data: [],
          },
        ],
      },
    };
    this.chartStatic = {
      cpcYaxisMin: function(value) {
        return value.min - value.min * 0.05;
      },
      cpcYaxisMax: function(value) {
        return value.max + value.max * 0.05;
      },
      percentYaxisMin: function(value) {
        if (value.min >= 60) {
          return 60;
        } else {
          return value.min;
        }
      },
      percentYaxisMax: function(value) {
        if (value.max <= 100) {
          return 100;
        } else {
          return value.max;
        }
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: {
            backgroundColor: "#6a7985",
            formatter: null,
          },
        },
      },
      errCategoryData: ["None", "Category 2", "Category 1"],
    };
    this.toggleModalChart = this.toggleModalChart.bind(this);
    this.openChartModal = this.openChartModal.bind(this);
    this.getNCEPT = () => {
      let url = "";
      url = "/api/ncept/" + this.props.facilityId;
      $(".loading").show();

      axios.get(url).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          //console.log(data.data);
          this.setState({
            nceptData: data.data,
            dataFound: true,
          });
        } else {
          //toast.error(data.message);
          this.setState({
            dataFound: false,
          });
        }
        $(".loading").hide();
      });
    };
    this.updateChartConfiguration = (config) => {
      var property = { ...this.state.option };
      if (property.yAxis.length > 1) {
        property.yAxis.pop();
      }
      property.legend = config.legend;
      property.xAxis = config.xAxis;
      property.series = config.series;
      property.yAxis[0].name = config.yAxis[0].name;
      property.yAxis[0].type = config.yAxis[0].type;
      property.yAxis[0].boundaryGap = config.yAxis[0].boundaryGap;
      property.yAxis[0].data = null;
      property.yAxis[0].min = null;
      property.yAxis[0].max = null;
      property.tooltip = this.chartStatic.tooltip;
      property.tooltip.axisPointer.label.formatter = config.tooltipFormatter;

      if (this.state.chartName.toLowerCase() === "cpcs") {
        property.yAxis[0].min = this.chartStatic.cpcYaxisMin;
        property.yAxis[0].max = this.chartStatic.cpcYaxisMax;
      } else if (
        this.state.chartName.toLowerCase() === "current % to cpc target" ||
        this.state.chartName.toLowerCase() === "currentpercenttocpctarget" ||
        this.state.chartName.toLowerCase() === "projected % to cpc target" ||
        this.state.chartName.toLowerCase() === "cpc %"
      ) {
        property.yAxis[0].min = this.chartStatic.percentYaxisMin;
        property.yAxis[0].max = this.chartStatic.percentYaxisMax;
      } else if (
        this.state.chartName.toLowerCase() === "errcategory" ||
        this.state.chartName.toLowerCase() === "err category"
      ) {
        property.tooltip = null;
        property.yAxis[0].data = this.chartStatic.errCategoryData;
      }

      if (
        this.state.chartName.toLowerCase() === "cpconboard" ||
        this.state.chartName.toLowerCase() === "cpc on board"
      ) {
        property.yAxis.push(config.yAxis);
      }

      this.setState({
        option: property,
      });
    };
    this.GetChartData = (invoked, type) => {
      const formData = new FormData();
      formData.append("chartType", type);
      formData.append("chartName", invoked);
      formData.append("facilityId", this.props.facilityId);

      axios.post("/api/ncept/GetChartData/", formData, null).then((res) => {
        if (res.data.status && res.data.status.toLowerCase() == "success") {
          this.updateChartConfiguration(res.data.data);
          this.setState({
            isModalOpenChart: true,
          });
          $(".loading").hide();
        } else {
          toast.error(res.data.message);
          this.setState({
            isModalOpenChart: false,
          });
          $(".loading").hide();
        }
      });
    };
    this.GetChartDataHandler = (
      modalTitle,
      chartName,
      yearorall,
      activeTab
    ) => {
      this.setState({
        modalTitle: modalTitle,
        isYearActive: yearorall,
        chartName: chartName,
        activeTab: activeTab,
      });
      if (yearorall) this.GetChartData(chartName, "alltime");
      else this.GetChartData(chartName, "year");
    };
  }

  toggleModalChart() {
    if (this.state.isModalOpenChart) {
      this.setState({
        isYearActive: false,
        activeTab: "0",
      });
    }
    this.setState({ isModalOpenChart: !this.state.isModalOpenChart });
  }

  openChartModal = (evt, chartName) => {
    this.setState({
      modalTitle: evt.currentTarget.getAttribute("name"),
      chartName: chartName,
    });
    this.GetChartData(chartName, "year");
  };

  componentDidMount() {
    this.getNCEPT();
  }

  render() {
    if (this.state.dataFound) {
      return (
        <>
          <Row className="mt-3">
            <Col lg={1}></Col>
            <Col lg={2} className="">
              <Card
                body
                inverse
                color="success"
                className={`cardHover cardShadow text-center p-0 ${this.state
                  .nceptData.cpctargetstatus &&
                  this.state.nceptData.cpctargetstatus}`}
                onClick={() =>
                  this.GetChartDataHandler(
                    "CPC Target",
                    "cpctarget",
                    false,
                    "0"
                  )
                }
              >
                <h5 className="h5tag">CPC Target</h5>
                <h4 className="h4tag">
                  {this.state.nceptData.cpctarget &&
                    this.state.nceptData.cpctarget}
                  {this.state.nceptData.cpctargetdifference &&
                    this.state.nceptData.cpctargetstatus == "dropped" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &darr; {this.state.nceptData.cpctargetdifference}{" "}
                      </small>
                    )}
                  {this.state.nceptData.cpctargetdifference &&
                    this.state.nceptData.cpctargetstatus == "raised" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &uarr; {this.state.nceptData.cpctargetdifference}{" "}
                      </small>
                    )}
                </h4>
              </Card>
            </Col>
            <Col lg={2} className="">
              <Card
                body
                inverse
                color="success"
                className={`cardHover cardShadow text-center p-0 ${this.state
                  .nceptData.currentcpconboardstatus &&
                  this.state.nceptData.currentcpconboardstatus}`}
                onClick={() =>
                  this.GetChartDataHandler(
                    "Current CPC On Board",
                    "cpconboard",
                    false,
                    "0"
                  )
                }
              >
                <h5 className="h5tag">CPC On-Board</h5>
                <h4 className="h4tag">
                  {this.state.nceptData.currentcpconboard &&
                    this.state.nceptData.currentcpconboard}
                  {this.state.nceptData.currentcpconboarddifference &&
                    this.state.nceptData.currentcpconboardstatus ==
                      "dropped" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &darr;{" "}
                        {this.state.nceptData.currentcpconboarddifference}{" "}
                      </small>
                    )}
                  {this.state.nceptData.currentcpconboarddifference &&
                    this.state.nceptData.currentcpconboardstatus ==
                      "raised" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &uarr;{" "}
                        {this.state.nceptData.currentcpconboarddifference}{" "}
                      </small>
                    )}
                </h4>
              </Card>
            </Col>
            <Col lg={2} className="">
              <Card
                body
                inverse
                color="success"
                className={`cardHover cardShadow text-center p-0 ${this.state
                  .nceptData.currentpercenttocpctargetstatus &&
                  this.state.nceptData.currentpercenttocpctargetstatus}`}
                onClick={() =>
                  this.GetChartDataHandler(
                    "Current CPC On Board",
                    "currentpercenttocpctarget",
                    false,
                    "1"
                  )
                }
              >
                <h5 className="h5tag">CPC to Target</h5>
                <h4 className="h4tag">
                  {this.state.nceptData.currentpercenttocpctarget &&
                    this.state.nceptData.currentpercenttocpctarget}
                  {this.state.nceptData.currentpercenttocpctargetdifference &&
                    this.state.nceptData.currentpercenttocpctargetstatus ==
                      "dropped" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &darr;{" "}
                        {
                          this.state.nceptData
                            .currentpercenttocpctargetdifference
                        }{" "}
                      </small>
                    )}
                  {this.state.nceptData.currentpercenttocpctargetdifference &&
                    this.state.nceptData.currentpercenttocpctargetstatus ==
                      "raised" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &uarr;{" "}
                        {
                          this.state.nceptData
                            .currentpercenttocpctargetdifference
                        }{" "}
                      </small>
                    )}
                </h4>
              </Card>
            </Col>
            <Col lg={2} className="">
              <Card
                body
                inverse
                color="success"
                className={`cardHover cardShadow text-center p-0 ${this.state
                  .nceptData.trainingtimestatus &&
                  this.state.nceptData.trainingtimestatus}`}
                onClick={() =>
                  this.GetChartDataHandler(
                    "Training",
                    "trainingtime",
                    false,
                    "0"
                  )
                }
              >
                <h5 className="h5tag">Training Time</h5>
                <h4 className="h4tag">
                  {this.state.nceptData.trainingtime &&
                    this.state.nceptData.trainingtime}
                  {this.state.nceptData.trainingtimedifference &&
                    this.state.nceptData.trainingtimestatus == "dropped" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &darr; {
                          this.state.nceptData.trainingtimedifference
                        }{" "}
                      </small>
                    )}
                  {this.state.nceptData.trainingtimedifference &&
                    this.state.nceptData.trainingtimestatus == "raised" && (
                      <small className="smallNceptLabel">
                        {" "}
                        &uarr; {
                          this.state.nceptData.trainingtimedifference
                        }{" "}
                      </small>
                    )}
                </h4>
              </Card>
            </Col>
            <Col lg={2} className="">
              <Card
                body
                inverse
                color="success"
                className="text-center cardShadow p-0 grayBackground cardHover"
                onClick={() =>
                  this.GetChartDataHandler(
                    "Other Information",
                    "errcategory",
                    false,
                    "0"
                  )
                }
              >
                <h5 className="h5tag">ERR Category</h5>
                <h4 className="h4tag">
                  {this.state.nceptData.errcategory &&
                    this.state.nceptData.errcategory}
                </h4>
              </Card>
            </Col>
            <Col lg={1}></Col>
          </Row>

          <Row className="ml-2 mr-2">
            <Col lg={6}>
              <Row>
                <Col lg={6} md={8}>
                  <h6 className="font-weight-bold"> CPC Target </h6>
                </Col>
                <Col lg={2} md={4}>
                  <h6 className="font-weight-bold">
                    {this.state.nceptData.cpctarget &&
                      this.state.nceptData.cpctarget}{" "}
                    <i
                      className="fas fa-info-circle  chartIconhover info-icon chartIconsGreen"
                      onClick={() =>
                        this.GetChartDataHandler(
                          "CPC Target",
                          "cpctarget",
                          false,
                          "0"
                        )
                      }
                    ></i>
                  </h6>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-3 ml-2 mr-2">
            <Col lg={6}>
              {/* Current CPC on Board */}
              <>
                <Row>
                  <Col lg={6} md={8}>
                    <h4 className="h4tag text-primary-card">
                      {" "}
                      Current CPC On Board{" "}
                      <i
                        className="fas fa-info-circle  chartIconhover chartIconsGreen"
                        onClick={() =>
                          this.GetChartDataHandler(
                            "Current CPC On Board",
                            "cpconboard",
                            false,
                            "0"
                          )
                        }
                      ></i>
                    </h4>
                  </Col>
                  <Col lg={2} md={4}></Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Staffing Workbook{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.staffingworkbook &&
                        this.state.nceptData.staffingworkbook}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> CPC on Temp </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.cpcontemp &&
                        this.state.nceptData.cpcontemp}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      CPC on Long Term Hold{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.cpconlongtermhold &&
                        this.state.nceptData.cpconlongtermhold}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="font-weight-bold">
                      {" "}
                      Current CPC on-Board (Adj.){" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="font-weight-bold">
                      {this.state.nceptData.currentcpconboard &&
                        this.state.nceptData.currentcpconboard}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="font-weight-bold">
                      {" "}
                      Current % to CPC Target{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="font-weight-bold">
                      {this.state.nceptData.currentpercenttocpctarget &&
                        this.state.nceptData.currentpercenttocpctarget}
                    </h6>
                  </Col>
                </Row>
              </>
              {/* Projections */}
              <>
                <Row>
                  <Col lg={6} md={8}>
                    <h4 className="h4tag text-primary-card">
                      {" "}
                      Projections{" "}
                      <i
                        className="fas fa-info-circle  chartIconhover chartIconsGreen"
                        onClick={() =>
                          this.GetChartDataHandler(
                            "Projections",
                            "possiblegains",
                            false,
                            "0"
                          )
                        }
                      ></i>
                    </h4>
                  </Col>
                  <Col lg={2} md={4}></Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Projected % to Target{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.projectedpercenttotarget &&
                        this.state.nceptData.projectedpercenttotarget}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Possible Gains to National Average{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.possiblegainstonationalaverage &&
                        this.state.nceptData.possiblegainstonationalaverage}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Possible Gains to Target{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.possiblegainstotarget &&
                        this.state.nceptData.possiblegainstotarget}
                    </h6>
                  </Col>
                </Row>
              </>
              {/* Training */}
              <>
                <Row>
                  <Col lg={6} md={8}>
                    <h4 className="h4tag text-primary-card">
                      {" "}
                      Training{" "}
                      <i
                        className="fas fa-info-circle  chartIconhover chartIconsGreen"
                        onClick={() =>
                          this.GetChartDataHandler(
                            "Training",
                            "trainingtime",
                            false,
                            "0"
                          )
                        }
                      ></i>
                    </h4>
                  </Col>
                  <Col lg={2} md={4}></Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Time </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.trainingtime &&
                        this.state.nceptData.trainingtime}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Success Rate </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.trainingsuccessrate &&
                        this.state.nceptData.trainingsuccessrate}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      ATCS in Training (minus LTH){" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.atcsintrainingminuslth &&
                        this.state.nceptData.atcsintrainingminuslth}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="font-weight-bold">
                      {" "}
                      Trainees Expected to Certify{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="font-weight-bold">
                      {this.state.nceptData.trainingexpectedtocert &&
                        this.state.nceptData.trainingexpectedtocert}
                    </h6>
                  </Col>
                </Row>
              </>
            </Col>
            <Col lg={6}>
              {/* Inbounds */}
              <>
                <Row>
                  <Col lg={10} md={8}>
                    <h4 className="h4tag text-primary-card">
                      {" "}
                      Inbounds{" "}
                      <i
                        className="fas fa-info-circle  chartIconhover chartIconsGreen"
                        onClick={() =>
                          this.GetChartDataHandler(
                            "Inbounds/Outbounds",
                            "inbounds/outbounds",
                            false,
                            "0"
                          )
                        }
                      ></i>
                    </h4>
                  </Col>
                  <Col lg={2} md={4}></Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Committed ATCS </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.committedatcsinbound &&
                        this.state.nceptData.committedatcsinbound}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Placement List </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.placementlistinbound &&
                        this.state.nceptData.placementlistinbound}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Temps </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.tempsinbound &&
                        this.state.nceptData.tempsinbound}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="font-weight-bold">
                      {" "}
                      Inbound Expected to Certify{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="font-weight-bold">
                      {this.state.nceptData.inboundexpectedtocertify &&
                        this.state.nceptData.inboundexpectedtocertify}
                    </h6>
                  </Col>
                </Row>
              </>
              {/* Outbounds */}
              <>
                <Row>
                  <Col lg={10} md={8}>
                    <h4 className="h4tag text-primary-card">
                      {" "}
                      Outbounds{" "}
                      <i
                        className="fas fa-info-circle  chartIconhover chartIconsGreen"
                        onClick={() =>
                          this.GetChartDataHandler(
                            "Inbounds/Outbounds",
                            "inbounds/outbounds",
                            false,
                            "0"
                          )
                        }
                      ></i>
                    </h4>
                  </Col>
                  <Col lg={2} md={4}></Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Committed ATCS </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.committedatcsoutbound &&
                        this.state.nceptData.committedatcsoutbound}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Placement List (ERR, Hardship){" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.placementlistoutbound &&
                        this.state.nceptData.placementlistoutbound}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Temps </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.tempsoutbound &&
                        this.state.nceptData.tempsoutbound}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Projected Retirements/Other{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.projectedretirements &&
                        this.state.nceptData.projectedretirements}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="font-weight-bold"> Total Outbounds </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="font-weight-bold">
                      {this.state.nceptData.totaloutbound &&
                        this.state.nceptData.totaloutbound}
                    </h6>
                  </Col>
                </Row>
              </>
              {/* Other Information */}
              <>
                <Row>
                  <Col lg={10} md={8}>
                    <h4 className="h4tag text-primary-card">
                      {" "}
                      Other Information{" "}
                      <i
                        className="fas fa-info-circle  chartIconhover chartIconsGreen"
                        onClick={() =>
                          this.GetChartDataHandler(
                            "Other Information",
                            "errcategory",
                            false,
                            "0"
                          )
                        }
                      ></i>
                    </h4>
                  </Col>
                  <Col lg={2} md={4}></Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> ERR Category </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.errcategory &&
                        this.state.nceptData.errcategory}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Current CPC to Trainees %{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.currentcpctotraineespercent &&
                        this.state.nceptData.currentcpctotraineespercent}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> Possible Losses </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.possiblelosses &&
                        this.state.nceptData.possiblelosses}
                    </h6>
                  </Col>
                </Row>
              </>
              {/* National Data */}
              <>
                <Row>
                  <Col lg={10} md={8}>
                    <h4 className="h4tag text-primary-card">
                      {" "}
                      National Data{" "}
                      <i
                        className="fas fa-info-circle  chartIconhover chartIconsGreen"
                        onClick={() =>
                          this.GetChartDataHandler(
                            "National Data",
                            "cpcs",
                            false,
                            "0"
                          )
                        }
                      ></i>
                    </h4>
                  </Col>
                  <Col lg={2} md={4}></Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> CPC Target </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.nationalcpctarget &&
                        this.state.nceptData.nationalcpctarget}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Current CPC On-Board{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.nationalcpconboard &&
                        this.state.nceptData.nationalcpconboard}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500"> CPC to Target % </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.nationalcpctotarget &&
                        this.state.nceptData.nationalcpctotarget}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={8}>
                    <h6 className="h6tag font-weight-500">
                      {" "}
                      Projected CPC to Target %{" "}
                    </h6>
                  </Col>
                  <Col lg={2} md={4}>
                    <h6 className="h6tag font-weight-500">
                      {this.state.nceptData.nationalprojectedcpctotarget &&
                        this.state.nceptData.nationalprojectedcpctotarget}
                    </h6>
                  </Col>
                </Row>
              </>
            </Col>
            <Col>
              <h6 className="float-right">
                {" "}
                Last NCEPT Update: {this.state.nceptData.createdate}{" "}
              </h6>
            </Col>
          </Row>

          <NCEPTChartsModal
            //facilityId={this.props.facilityId}
            isModalOpen={this.state.isModalOpenChart}
            toggleModal={this.toggleModalChart}
            //invokedBy={this.state.invokedBy}
            isYearActive={this.state.isYearActive}
            modalTitle={this.state.modalTitle}
            chartName={this.state.chartName}
            activeTab={this.state.activeTab}
            options={this.state.option}
            GetChartDataHandler={this.GetChartDataHandler}
          />
        </>
      );
    } else {
      return (
        <div class="container h-100">
          <div class="row spinnerFacilityCategory">
            <h5>No Record Found!</h5>
          </div>
        </div>
      );
    }
  }
}
