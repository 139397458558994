import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import AddRoleWrap from "../AddRole/AddRoleWrap";
import { toast } from "react-toastify";
import $ from "jquery";
import axios from "../../Shared/auth-header";

const ButtonToNavigate = ({ title, history, color, classes }) => (
    <Button type="button" onClick={() => history.push("/admin/roles")} color={color} className={classes}>
        {title}
    </Button>
);

class AddRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.location.type,
            uid: this.props.location.uid,
            roleName: "",
            roleDesc: "",
            features: []
        };
        this.updateMethods = () => {
            this.updateFeatures = args => {
                this.state.features.map(el => {
                    if (el.id == args.id) {
                        el = args;
                    }
                });
                this.setState({ features: this.state.features });
            };
            this.updateSubFeatures = (featureId, args) => {
                this.state.features.map(el => {
                    if (el.featuretaskid == featureId) {
                        el.subFeatures = args;
                    }
                });
                this.setState({ features: this.state.features });
            };
            return this;
        };
    }
    handleChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleSubmit = evt => {
        evt.preventDefault();
        evt.target.className += " was-validated";
        if (evt.target.checkValidity() === false) {
            return false;
        }
        const data = {
            Id: this.state.uid,
            name: this.state.roleName,
            description: this.state.roleDesc,
            Features: this.state.features
        };
        axios
            .post("/api/Role/SaveRole", data)
            .then(({ data }) => {
                if (data.status && data.status == "Success") {
                    toast.success("Role Saved.");
                    this.props.history.push("/admin/roles");
                } else {
                    toast.error(data.message);
                }
                $(".loading").hide();
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    componentDidMount() {
        $(".loading").show();
        let url = "/api/Role/" + this.state.uid;
        if (!this.state.type) {
            this.props.history.push("/admin/roles");
        }
        if (this.state.type == "add") {
            url = "/api/Role/GetRoleModel";
        }
        axios.get(url).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({
                    roleDesc: data.data.description,
                    roleName: data.data.name,
                    features: data.data.features
                });
                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });

    }

    componentWillUnmount() {
        if (this.props.history.action === "POP") {

            this.props.history.location.keyword = this.props.location.keyword;

        }
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <form className="needs-validation" onSubmit={this.handleSubmit} noValidate>
                        <div className="page-title-box">
                            <div className="row align-items-center">
                                <div className="col-sm-6">
                                    <h4 className="page-title"> Add/Edit Roles</h4>
                                </div>
                                <div className="col-sm-6 float-right text-right">
                                    <Button
                                        type="button"
                                        onClick={() =>
                                            this.props.history.push({
                                                pathname: "/admin/roles"

                                            })
                                        }
                                        color="primary">
                                        Back to Roles
                                </Button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <MDBInput
                                                        value={this.state.roleName}
                                                        name="roleName"
                                                        onChange={this.handleChange}
                                                        label="Role Name"
                                                        group
                                                        type="text"
                                                        required>
                                                        <div className="invalid-feedback">Role Name is required.</div>
                                                    </MDBInput>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <MDBInput
                                                        value={this.state.roleDesc}
                                                        onChange={this.handleChange}
                                                        label="Role Description"
                                                        name="roleDesc"
                                                        group
                                                        type="text"
                                                        required>
                                                        <div className="invalid-feedback">Role Description is required.</div>
                                                    </MDBInput>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                        <h4 className="mt-0 mb-2 header-title">Permissions</h4>
                                        <AddRoleWrap features={this.state.features} updateMethods={this.updateMethods} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col float-right text-right">
                                <Button
                                    type="button"
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: "/admin/roles"

                                        })
                                    }
                                    color="danger"
                                    className="mr-2">
                                    Cancel
                                </Button>
                                <Button to="/roles" color="primary" type="submit">
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </AUX>
        );
    }
}

export default AddRoles;
