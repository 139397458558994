import React, { Component, Fragment } from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import TableCustomTotal from "../../../Shared/TableCustomTotal";
import ReCalculateSeniority from "./ReCalculateSeciorityModal";
import moment from "moment";
import Services from "./EditUserService";
import _ from "lodash";
import DatePicker from "react-datepicker";
import $ from "jquery";
import JobHistoryFormModal from "./JobHistoryFormModal";
import ConfirmDeleteModal from "../../../Payfile/Two/General/DeleteModal";

class JobHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      editDate: false,
      seniorityHistory: [],
      sizePerPageJobs: 10,
      deleteModal: false,
      jobsBackup: [],
      jobError: "",
      deleteJobId: -1,
      openReCalculateSeniorityModal: false,
      user: {},
    };
    this.jobsColumns = this.jobsColumns.bind(this);

    this.jobSizePerPageChange = this.jobSizePerPageChange.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.closeReCalculateSeniorityModal = this.closeReCalculateSeniorityModal.bind(
      this
    );
    this.openReCalculateSeniorityModal = this.openReCalculateSeniorityModal.bind(
      this
    );
    this.getJobsSeniorityHistory = this.getJobsSeniorityHistory.bind(this);
    this.closeJobHistoryFormModal = this.closeJobHistoryFormModal.bind(this);
    this.openJobHistoryFormModal = this.openJobHistoryFormModal.bind(this);
    this.handleRemoveJob = this.handleRemoveJob.bind(this);
    this.DateAdjuster = this.DateAdjuster.bind(this);
    this.dateValidate = this.dateValidate.bind(this);
  }
  SortJobs(jobs) {
    return jobs.sort(function(a, b) {
      return (
        moment(b.startdate)
          .format("YYYY-MM-DD")
          .localeCompare(moment(a.startdate).format("YYYY-MM-DD")) ||
        (a.enddate && b.enddate
          ? moment(b.enddate)
              .format("YYYY-MM-DD")
              .localeCompare(moment(a.enddate).format("YYYY-MM-DD"))
          : !a.enddate
          ? -1
          : 1)
      );
    });
  }
  DateAdjuster(id) {
    if (!_.isEmpty(this.state.jobs)) {
      let c_jobs = _.cloneDeep(this.state.jobs); //_.orderBy(, ["enddate"], ["asc"]);
      c_jobs = this.SortJobs(c_jobs);
      let result = [];
      if (c_jobs.length > 0) {
        // we are using this loop in descending order and we will work in it ascending order, because if we sort data in asc order then it makes big problem
        for (var i = c_jobs.length - 1; i >= 0; i--) {
          // compare start date with end date of previous one
          // if not greater or null then change the previous end date at -1 or at start date
          let PreviousEdit = false;
          if (c_jobs[i].enddate && c_jobs[i].startdate > c_jobs[i].enddate) {
            c_jobs[i].enddate = moment(c_jobs[i].startdate)
              .add(1, "days")
              .format("YYYY-MM-DD");
          }

          // If it's not a first index
          if (i != c_jobs.length - 1) {
            // match the previous entry have appropriate end date and (previous job have end date is not current edited job)
            if (!c_jobs[i + 1].enddate) {
              console.log(i + " " + c_jobs[i + 1].enddate);
            }
            if (
              (c_jobs[i + 1].enddate &&
                c_jobs[i].startdate != c_jobs[i + 1].enddate &&
                c_jobs[i + 1].id != id) ||
              !c_jobs[i + 1].enddate
            ) {
              // Assigning End date to previous entry
              c_jobs[i + 1].enddate = c_jobs[i].startdate;
              PreviousEdit = true;
            }
            //in case previous job was current eddited job then for taking users value important so we will edit other values.
            else if (
              c_jobs[i + 1].enddate &&
              c_jobs[i].startdate != c_jobs[i + 1].enddate &&
              c_jobs[i + 1].id == id
            ) {
              // Assigning appropriate start date to current entry
              c_jobs[i].startdate = c_jobs[i + 1].enddate;
            }
            // in case previous job edit and end date is lesser than todays date or start date is in future.
            if (
              PreviousEdit &&
              ((c_jobs[i + 1].enddate &&
                c_jobs[i + 1].enddate < moment().toDate()) ||
                (c_jobs[i + 1].startdate &&
                  c_jobs[i + 1].startdate > moment().toDate()))
            ) {
              c_jobs[i + 1].jobstatus = "InActive";
            }
            // in case previous job edit and end date is greate than todays date and start date is in past.
            else if (
              PreviousEdit &&
              (!c_jobs[i + 1].enddate ||
                (c_jobs[i + 1].enddate &&
                  c_jobs[i + 1].enddate > moment().toDate())) &&
              c_jobs[i + 1].startdate &&
              c_jobs[i + 1].startdate < moment().toDate()
            ) {
              c_jobs[i + 1].jobstatus = "Active";
            }
          }
          // in case current job end date is lesser than todays date or start date is in future.
          if (
            (c_jobs[i].enddate && c_jobs[i].enddate < moment().toDate()) ||
            (c_jobs[i].startdate && c_jobs[i].startdate > moment().toDate())
          ) {
            c_jobs[i].jobstatus = "InActive";
          }
          // in case current job end date is greate than todays date and start date is in past.
          else if (
            (!c_jobs[i].enddate ||
              (c_jobs[i].enddate && c_jobs[i].enddate > moment().toDate())) &&
            c_jobs[i].startdate &&
            c_jobs[i].startdate < moment().toDate()
          ) {
            c_jobs[i].jobstatus = "Active";
          }
        }
        //c_jobs = _.orderBy(c_jobs, ["startdate"], ["desc"]);
        //let lowestSD = c_jobs[0];
        //sort in connection
      }
      //_.orderBy(c_jobs, ["startdate", "enddate"], ["desc", "desc"])
      this.setState({
        jobs: c_jobs,
      });
    }
  }
  jobsColumns = () => {
    return [
      {
        text: "Facility",
        dataField: "facility[code]",
        //sort: true,

        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "c-pointer";
          if (row.isbreak) {
            borderClass = " bg-light";
          }
          return borderClass;
        },
        hidden:
          !AuthService.canOwnView(
            PermissionFeature.FacilityCode,
            this.props.memberid
          ) && !AuthService.canMemberView(PermissionFeature.FacilityCode),
        formatter: (cellContent, row) => {
          return (
            <>
              {row.facility && row.facility.code ? (
                <>{row.facility.code}</>
              ) : (
                <>-</>
              )}
              {row.isSupervisor ? (
                <span
                  title="Supervisor"
                  className="fas fa-ban text-danger pl-2"
                ></span>
              ) : row.isTempSupervisor ? (
                <span
                  title="Temp Supervisor"
                  className="fas fa-ban text-warning pl-2"
                ></span>
              ) : (
                <></>
              )}
            </>
          );
        },
      },
      {
        text: "Unit",
        dataField: "busto",
        //sort: true,
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "c-pointer";
          if (row.isbreak) {
            borderClass = " bg-light";
          }
          return borderClass;
        },
        hidden:
          !AuthService.canOwnView(
            PermissionFeature.FinancialUnit,
            this.props.memberid
          ) && !AuthService.canMemberView(PermissionFeature.FinancialUnit),
        formatter: (cellContent, row) => (cellContent ? cellContent : "-"),
      },
      {
        text: "Org. Code",
        dataField: "organizationcode",
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "c-pointer";
          if (row.isbreak) {
            borderClass = " bg-light";
          }
          return borderClass;
        },
        formatter: (cellContent, row) => (cellContent ? cellContent : "-"),
        //sort: true,
      },
      {
        text: "Route",
        dataField: "facilityroutingsymbolto",
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "c-pointer";
          if (row.isbreak) {
            borderClass = " bg-light";
          }
          return borderClass;
        },
        formatter: (cellContent, row) => (cellContent ? cellContent : "-"),
        //sort: true,
      },
      {
        text: "Start Date",
        dataField: "startdate",
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "c-pointer";
          if (row.isbreak) {
            borderClass = " bg-light";
          }
          return borderClass;
        },
        //sort: true,
        formatExtraData: this.state.editDate && JSON.stringify(this.state.jobs),
        formatter: (cellContent, row, rowIndex) => {
          if (this.state.editDate) {
            return (
              <DatePicker
                dateForm="MM/DD/YYYY"
                peekNextMonth
                className="text-center jobhistory-datepicker border-top-0 p-0 m-0 border-left-0 border-right-0"
                style={{ maxWidth: "90px!important" }}
                showMonthDropdown
                showYearDropdown
                onChange={(date) => {
                  this.dateValidate(date);
                  if (date == null) {
                    row.startdate = null;
                  } else if (moment(date).isValid()) {
                    row.startdate = moment(date).format("YYYY-MM-DD");
                  }
                  this.state.jobs[rowIndex].startdate = row.startdate;
                  this.DateAdjuster(row.id);
                  this.dateValidate();
                }}
                selected={row.startdate ? moment(row.startdate).toDate() : null}
                placeholderText={"MM/DD/YYYY"}
              />
            );
          } else {
            return moment(row.startdate).isValid()
              ? moment(row.startdate).format("MM/DD/YYYY")
              : "-";
          }
        },
      },
      {
        text: "End Date",
        dataField: "enddate",

        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "c-pointer";
          if (row.isbreak) {
            borderClass = " bg-light";
          }
          return borderClass;
        },
        formatExtraData: this.state.editDate && JSON.stringify(this.state.jobs),
        //sort: true,
        formatter: (cellContent, row, rowIndex) => {
          if (this.state.editDate) {
            return (
              <DatePicker
                dateForm="MM/DD/YYYY"
                peekNextMonth
                className="w-100 jobhistory-datepicker text-center border-top-0 border-left-0 border-right-0"
                showMonthDropdown
                showYearDropdown
                onChange={(date) => {
                  if (date == null) {
                    row.enddate = null;
                  } else if (moment(date).isValid()) {
                    row.enddate = moment(date).format("YYYY-MM-DD");
                  }
                  this.state.jobs[rowIndex].enddate = row.enddate;
                  this.DateAdjuster(row.id);
                  this.dateValidate();
                }}
                selected={row.enddate ? moment(row.enddate).toDate() : null}
                placeholderText={"MM/DD/YYYY"}
              />
            );
          } else {
            return moment(row.enddate).isValid()
              ? moment(row.enddate).format("MM/DD/YYYY")
              : "-";
          }
        },
      },
      {
        text: "",
        //hidden:!(
        //    AuthService.canOwnUpdate(
        //        PermissionFeature.MembersJobs,
        //        this.props.memberid
        //    ) ||
        //    AuthService.canMemberUpdate(PermissionFeature.MembersJobs) ||
        //    AuthService.canOwnDelete(
        //        PermissionFeature.MembersJobs,
        //        this.props.memberid
        //    ) ||
        //    AuthService.canMemberDelete(PermissionFeature.MembersJobs)
        //),
        hidden: this.state.editDate,
        //toggle: this.state.editDate,
        formatExtraData: this.state.editDate,
        classes: (cell, row, rowIndex, colIndex) => {
          let borderClass = "c-pointer";
          if (row.isbreak) {
            borderClass = " bg-light";
          }
          return borderClass;
        },
        //sort: true,
        formatter: (cellContent, row) => {
          return (
            <>
              {(AuthService.canOwnUpdate(
                PermissionFeature.MembersJobs,
                this.props.memberid
              ) ||
                AuthService.canMemberUpdate(PermissionFeature.MembersJobs)) &&
                (!this.state.editDate ? (
                  <span
                    className="text-success c-pointer pr-1"
                    id={"edit"}
                    onClick={() => {
                      this.state.FormCode++;
                      this.state.JobDetail = {
                        FormCode: this.state.FormCode,
                        id: row.id,

                        regionCode:
                          row.region && row.region ? row.region.code : "",
                        facilityCode:
                          row.facility && row.facility.code
                            ? row.facility.code
                            : "",
                        busTo: row.busto,
                        isBreak: row.isbreak,
                        organizationCode: row.organizationcode,
                        routingSymbolTo: row.facilityroutingsymbolto,
                        dutyStationCode: row.dutystationcode,
                        dutyStationDescription: row.dutystationdescription,
                        isSuperVisor: row.isSupervisor,
                        isTempSuperVisor: row.isTempSupervisor,
                        startDate: moment(row.startdate).isValid()
                          ? moment(row.startdate).format("MM/DD/YYYY")
                          : row.startdate,
                        endDate:
                          row.enddate != null &&
                          row.enddate != "" &&
                          moment(row.enddate).isValid()
                            ? moment(row.enddate).format("MM/DD/YYYY")
                            : "",
                      };
                      this.setState({
                        jobFormHeading: "Update",
                        JobDetail: this.state.JobDetail,
                      });
                      this.openJobHistoryFormModal();
                    }}
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                ) : (
                  <span className="text-secondary opacity-half c-pointer pr-1">
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                ))}
              {(AuthService.canOwnDelete(
                PermissionFeature.MembersJobs,
                this.props.memberid
              ) ||
                AuthService.canMemberDelete(PermissionFeature.MembersJobs)) &&
                (!this.state.editDate ? (
                  <span
                    className="text-danger c-pointer pr-1"
                    onClick={(e) => {
                      this.setState({
                        deleteJobId: row.id,
                        deleteModal: true,
                      });
                    }}
                  >
                    <i class="fas fa-trash"></i>
                  </span>
                ) : (
                  <span className="text-secondary opacity-half c-pointer pr-1">
                    <i class="fas fa-trash"></i>
                  </span>
                ))}
            </>
          );
        },
      },
    ];
  };
  closeJobHistoryFormModal() {
    this.setState({ openJobHistoryFormModal: false });
  }
  openJobHistoryFormModal() {
    this.setState({ openJobHistoryFormModal: true });
  }

  closeReCalculateSeniorityModal() {
    this.setState({ openReCalculateSeniorityModal: false });
  }
  openReCalculateSeniorityModal() {
    this.setState({ openReCalculateSeniorityModal: true });
  }

  componentDidMount() {
    //   this.getJobsSeniorityHistory();
    var userData = AuthService.getProfile().memberData;
    this.setState({ jobs: this.SortJobs(this.props.jobs), user: userData });
  }
  handleRemoveJob = async () => {
    this.setState({ deleteModal: false });
    $(".loading").show();
    if (this.state.deleteJobId != -1) {
      let data = await Services.JobDelete(
        this.state.deleteJobId,
        this.props.memberid
      );
      if (data != null && data.statusCode == 200) {
        this.props.loadData();
        this.props.getJobs();
      }
    }
    $(".loading").hide();
  };
  dateValidate() {
    let error = "";
    this.state.jobs.some((x, i) => {
      error = "Please provide appropriate dates at row no." + (i + 1);
      if (
        x.startdate &&
        moment(x.startdate).isValid() &&
        (((x.isbreak || (!x.isbreak && i > 0)) &&
          x.enddate &&
          moment(x.enddate).isValid()) ||
          (!x.isbreak && i <= 0))
      ) {
        error = "";
      }

      return error != "";
    });
    this.state.jobError = error;
    this.setState({ jobError: this.state.jobError });
    return error;
  }
  async getJobsSeniorityHistory() {
    let data = await Services.MemberSeniorityHistory(this.props.memberid);
    if (!_.isEmpty(data)) {
      await this.setState({ seniorityHistory: data });
    } else {
      await this.setState({ seniorityHistory: [] });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.jobs) != JSON.stringify(prevProps.jobs) ||
      prevProps.memberid != this.props.memberid
    ) {
      //this.getJobsSeniorityHistory();
      this.setState({ jobs: this.SortJobs(this.props.jobs) });
    }
  }
  jobSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageJobs: sizePerPage });
  };
  RemotePagination(columns, keyFields, data, SizePerPageChange, sizePerPage) {
    let paginationOptions = {
      totalSize: data && data.length,
      paginationTotalRenderer: TableCustomTotal,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      sizePerPage: 10,
    };

    return (
      <>
        <div className="mb-3 mt-1">
          {!_.isEmpty(data) && (
            <BootstrapTable
              keyField={keyFields}
              data={data}
              columns={columns}
              className="table"
              classes="mb-0"
              headerClasses="text-nowrap"
              sort={{ dataField: "startdate", order: "desc" }}
              pagination={
                data.length > sizePerPage
                  ? paginationFactory({ ...paginationOptions })
                  : false
              }
            />
          )}
        </div>
      </>
    );
  }

  render() {
    return (
      <Fragment>
        <>
          {(this.state.user.membertypeid == 6 ||
            this.state.user.membertypeid == 8 ||
            this.state.user.membertypeid == 10) && (
            <div className="card directory-card border-rounded shadow">
              <h5 className="card-header mt-0">
                <i className="mdi mdi-view-list mr-2" />
                Job History
                <span className="float-right font-weight-normal">
                  {(AuthService.canOwnAdd(
                    PermissionFeature.MembersJobs,
                    this.props.memberid
                  ) ||
                    AuthService.canMemberAdd(
                      PermissionFeature.MembersJobs
                    )) && (
                    <span
                      className="text-primary c-pointer pr-1"
                      onClick={() => {
                        this.state.FormCode++;
                        this.state.JobDetail = {
                          FormCode: this.state.FormCode,
                          id: 0,
                          regionCode: "",
                          facilityCode: "",
                          busTo: "",
                          organizationCode: "",
                          routingSymbolTo: "",
                          isBreak: false,
                          dutyStationCode: "",
                          dutyStationDescription: "",
                          isSuperVisor: false,
                          isTempSuperVisor: false,
                          isEdited: true,
                          startDate: moment(new Date()).format("MM/DD/YYYY"),
                          endDate: "",
                        };
                        this.setState({
                          jobFormHeading: "Add",
                          JobDetail: this.state.JobDetail,
                        });
                        this.openJobHistoryFormModal();
                      }}
                    >
                      <i class="fas fa-plus"></i>
                    </span>
                  )}
                </span>
              </h5>
              <div className="card-body pb-3 pt-1">
                {!_.isEmpty(this.state.jobs) ? (
                  <>
                    <div class="col-12 pt-2 pb-1 text-right px-0">
                      <span className="float-left text-left text-danger">
                        {this.state.jobError}
                      </span>
                      {(AuthService.canOwnUpdate(
                        PermissionFeature.MembersJobs,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberUpdate(
                          PermissionFeature.MembersJobs
                        )) &&
                        !this.state.editDate && (
                          <span
                            className="font-italic c-pointer"
                            onClick={() => {
                              this.setState({
                                editDate: true,
                                jobsBackup: _.cloneDeep(this.state.jobs),
                              });
                              if (
                                this.state.jobs.length &&
                                this.state.jobs.length > 0
                              ) {
                                this.DateAdjuster(this.state.jobs[0].id);
                                this.dateValidate();
                              }
                            }}
                          >
                            Edit Dates{" "}
                            <i class="fas fa-pencil-alt edit-pencil-icon"></i>
                          </span>
                        )}
                      {(AuthService.canOwnUpdate(
                        PermissionFeature.MembersJobs,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberUpdate(
                          PermissionFeature.MembersJobs
                        )) &&
                        this.state.editDate && (
                          <>
                            <i
                              onClick={() => {
                                this.setState({
                                  editDate: false,
                                  jobError: "",
                                  jobs: _.cloneDeep(this.state.jobsBackup),
                                });
                              }}
                              class="fas fa-times fa-lg pr-2 c-pointer"
                            ></i>
                            <i
                              onClick={async () => {
                                this.dateValidate();
                                if (this.state.jobError == "") {
                                  $(".loading").show();
                                  let data = await Services.UpdateJobDates(
                                    this.state.jobs
                                  );
                                  if (data) {
                                    await this.setState({
                                      editDate: false,
                                      jobError: "",
                                      jobsBackup: _.cloneDeep(this.state.jobs),
                                    });
                                    await this.props.loadData();
                                  }
                                  $(".loading").hide();
                                }
                              }}
                              className="fas fa-check fa-lg c-pointer"
                            ></i>
                          </>
                        )}
                    </div>

                    {this.RemotePagination(
                      this.jobsColumns(),
                      "id",
                      this.state.jobs,
                      this.jobSizePerPageChange,
                      this.state.sizePerPageJobs
                    )}
                    {!this.state.editDate && (
                      <div className="pb-2 text-right">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={this.openReCalculateSeniorityModal}
                        >
                          Recalculate Seniority
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="text-center py-3">
                    No Jobs for this member to show.
                  </div>
                )}
              </div>
            </div>
          )}
          <ReCalculateSeniority
            openReCalculateSeniorityModal={
              this.state.openReCalculateSeniorityModal
            }
            closeReCalculateSeniorityModal={this.closeReCalculateSeniorityModal}
            memberId={this.props.memberid}
            getJobs={this.props.getJobs}
            jobsHistory={this.state.jobs}
          />
          <JobHistoryFormModal
            openJobHistoryFormModal={this.state.openJobHistoryFormModal}
            closeJobHistoryFormModal={this.closeJobHistoryFormModal}
            JobDetail={this.state.JobDetail}
            memberId={this.props.memberid}
            heading={this.state.jobFormHeading}
            getJobs={this.props.getJobs}
            loadData={this.props.loadData}
          />{" "}
          <ConfirmDeleteModal
            id={"DeleteJob"}
            content={"Job"}
            confirm={() => {
              this.handleRemoveJob();
            }}
            closeModal={() => {
              this.setState({ deleteModal: false });
            }}
            openModal={this.state.deleteModal}
          />
        </>
      </Fragment>
    );
  }
}

export default JobHistory;
