import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import { toast } from "react-toastify";
import $ from "jquery";
import "moment/locale/en-ca";
import { DatePickerInput } from "rc-datepicker";

export default class PositionDatesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        centered={true}
        size="md"
        role="dialog"
        isOpen={this.props.isModalOpen}
        toggle={this.props.toggleModal}
      >
        <ModalHeader
          className="py-2 bg-secondary modal-title mt-0 text-white"
          toggle={this.props.toggleModal}
        >
          {"Edit Position Dates"}
        </ModalHeader>

        <ModalBody className="modalshadow">
          <Row>
            <Col>
              <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                <small>Start Date:</small>
              </span>
              <DatePickerInput
                readonly="readonly"
                onChange={this.props.onStartDateChange}
                value={this.props.positionStartDate}
              />
              <span className="text-danger">{}</span>
            </Col>
            <Col>
              <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                <small>End Date:</small>
              </span>
              <DatePickerInput
                readonly="readonly"
                onChange={this.props.onEndDateChange}
                value={this.props.positionEndDate}
              />
              <span className="text-danger">{}</span>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <button
                className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                onClick={this.props.savePositionDates}
                type="submit"
              >
                Save
              </button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}
