import React, { Component } from "react";
import PermissionFeature from "../Permissions/PermissionFeature";
import AuthService from "../Auth/AuthService";
import FEServices from "../F&E/F&ERequests/FEService";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import FEService from "../F&E/F&ERequests/FEService";
import DeleteModal from "../Dashboard/FacilityDashboard/Modals/DeleteModal";
const filter = createFilterOptions();

export default class AddMultiBudgetlines extends Component {
  state = {
    showValidationErrors: false,
    isConfirmModalOpen: false,
  };
  confirm = () => {
    this.confirmed = () => {
      if (this.state.delId == undefined) {
        return false;
      }
      if (this.state.delId > 0) {
        this.props.myself.setState({
          selectedEquipmentBudgetlines: this.props.myself.state.selectedEquipmentBudgetlines.filter(
            (el, index) => {
              return el.id !== this.state.delId;
            }
          ),
        });
      } else {
        this.props.myself.setState({
          selectedEquipmentBudgetlines: this.props.myself.state.selectedEquipmentBudgetlines.filter(
            (el, index) => {
              return index !== this.state.delIdx;
            }
          ),
        });
      }

      if (this.props.isEquipment && this.state.delId != 0) {
        FEService.deleteEquipmentBudgetline(this.state.delId, this);
      } else {
        this.toggleConfirmModal();
      }
    };
    return this;
  };
  addRowClick = (myself) => {
    for (var key of Object.keys(this.refs)) {
      // console.log(key + ": " + this.refs[key]);
      this.setState({ showValidationErrors: false });
      this.refs[key].className += " was-validated";
      if (this.refs[key].checkValidity() === false) {
        this.setState({ showValidationErrors: true });
        this.props.setBudgetLineFormInvalid();
        return false;
      }
    }
    myself.setState({
      selectedEquipmentBudgetlines: [
        ...myself.state.selectedEquipmentBudgetlines,
        {
          equipmentBudgetline: null,
          allocatedpct: "",
        },
      ],
    });
  };
  showSelectedBudgetline = (idx, state, myself) => {
    let savedBudgetId =
      state.selectedEquipmentBudgetlines &&
      state.selectedEquipmentBudgetlines[idx].budgetid;
    let selectedOption =
      state.budgetLinesOptions.find((el) => el.id == savedBudgetId) || null;

    // state.selectedEquipmentBudgetlines[idx][
    //   "equipmentBudgetline"
    // ] = selectedOption;

    // myself.setState({
    //   selectedEquipmentBudgetlines: state.selectedEquipmentBudgetlines,
    // });

    return selectedOption;
  };
  toggleConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  removeRow = (budgetid, idx, state, myself) => {
    if (budgetid > 0) {
      this.setState({ delId: budgetid });
      this.setState({ dataIdx: idx });
    } else {
      this.setState({ delId: 0 });
      this.setState({ delIdx: idx });
    }

    this.setState({ isConfirmModalOpen: true });
  };

  render() {
    const {
      myself,
      state,
      entitykey,
      isRequested,
      isEquipment,
      shouldDisableControl,
      requestOpenedInEquipment,
    } = this.props;

    const disablePermissionforBudgetline = isEquipment
      ? !AuthService.canUpdate(PermissionFeature.FEAccountingBudgetID) ||
        shouldDisableControl
      : isRequested;

    return (
      <>
        <div
          className={`col-12 pl-4 pr-4 pb-4 ${
            isEquipment && !requestOpenedInEquipment ? "pt-4" : ""
          } `}
        >
          {/* ######################Third Row Budgetlines UI######################*/}

          <div className="row mt-3 modalshadow">
            {AuthService.canView(PermissionFeature.FERequestBudgetID) && (
              <>
                <h6 className="col-12 card-header position-relative mt-0">
                  <i class="far fa-id-badge"></i> Budget Lines
                </h6>
                <div className="col-lg-12 pt-4 pl-4 pr-4 multiBudgetLinesScroll">
                  {state.selectedEquipmentBudgetlines &&
                    state.selectedEquipmentBudgetlines.map((item, idx) => (
                      <div className="container-fluid" key={idx + 1}>
                        <form
                          ref={`${idx}-addBudgetLineForm-${
                            this.props.isEquipment ? "Equipment" : "Request"
                          }`}
                        >
                          <div key={idx} data-repeater-list="group-a">
                            <div data-repeater-item>
                              <div data-id={idx} className="row mb-2">
                                <div className="col-sm-7">
                                  <Autocomplete
                                    data-obj="selectedEquipmentBudgetlines"
                                    name="equipmentBudgetline"
                                    id="equipmentBudgetline"
                                    getOptionDisabled={(option) => {
                                      let selectedOption = state.selectedEquipmentBudgetlines.some(
                                        (item) =>
                                          item.selectedOption &&
                                          item.selectedOption.includes(
                                            option.name
                                          ) &&
                                          state.selectedEquipmentBudgetlines
                                            .length > 1
                                      );
                                      if (selectedOption) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }}
                                    options={state.budgetLinesOptions}
                                    //filterOptions={(options) => options}
                                    autoHighlight={true}
                                    getOptionLabel={(option) => {
                                      if (
                                        !_.isEmpty(option) &&
                                        !_.isEmpty(option.approverMember) &&
                                        !state.selectedEquipmentBudgetlines[idx]
                                          .showAuthorizedPerson
                                      ) {
                                        return `${option.name}-${option.approverMember.firstname} ${option.approverMember.lastname}`;
                                      } else if (
                                        !_.isEmpty(option) &&
                                        !_.isEmpty(option.approverMember) &&
                                        state.selectedEquipmentBudgetlines[idx]
                                          .showAuthorizedPerson
                                      ) {
                                        return `${option.name}`;
                                      } else {
                                        return "";
                                      }
                                    }}
                                    disabled={disablePermissionforBudgetline}
                                    ChipProps={{
                                      classes: {
                                        root: "py-2 ",
                                      },
                                    }}
                                    // onInputChange={(e, value, reason) => {
                                    //   if (reason == "input") {
                                    //     this.props.budgetLinesSearchHandler(
                                    //       value
                                    //     );
                                    //   }
                                    // }}
                                    // For searching options inside the client's array
                                    filterOptions={(options, params) => {
                                      const inputValue = params.inputValue;
                                      const paramsOptionLabel = params.getOptionLabel(
                                        params.inputValue
                                      );
                                      if (inputValue !== "") {
                                        if (
                                          inputValue.indexOf(" ") > 0 &&
                                          /^[a-zA-Z-]*$/.test(
                                            inputValue.split(" ")[0]
                                          ) &&
                                          /^[a-zA-Z-]*$/.test(
                                            inputValue.split(" ")[1]
                                          )
                                        ) {
                                          let firstName = inputValue.split(
                                            " "
                                          )[0];
                                          let lastName = inputValue.split(
                                            " "
                                          )[1];

                                          let result = options.filter(
                                            (val, ind, arr) => {
                                              return (
                                                val.approverMember.firstname
                                                  .toLowerCase()
                                                  .includes(
                                                    firstName.toLowerCase()
                                                  ) &&
                                                val.approverMember.lastname
                                                  .toLowerCase()
                                                  .includes(
                                                    lastName.toLowerCase()
                                                  )
                                              );
                                            }
                                          );
                                          return result;
                                        } else if (
                                          inputValue.indexOf(" ") < 0
                                        ) {
                                          if (/^[a-zA-Z-]*$/.test(inputValue)) {
                                            let result = options.filter(
                                              (val, ind, arr) => {
                                                return (
                                                  val.name
                                                    .toLowerCase()
                                                    .includes(
                                                      inputValue.toLowerCase()
                                                    ) ||
                                                  val.approverMember.lastname
                                                    .toLowerCase()
                                                    .includes(
                                                      inputValue.toLowerCase()
                                                    )
                                                );
                                              }
                                            );
                                            return result;
                                          } else if (
                                            typeof inputValue === "number" &&
                                            isFinite(inputValue)
                                          ) {
                                            let result = options.filter(
                                              (val, ind, arr) => {
                                                return (
                                                  val.budgetline == inputValue
                                                );
                                              }
                                            );
                                            return result;
                                          }
                                        }
                                      }
                                      const filtered = filter(options, params);

                                      return filtered;
                                    }}
                                    value={
                                      /// Add Mode
                                      entitykey == 0
                                        ? state.selectedEquipmentBudgetlines[
                                            idx
                                          ]["equipmentBudgetline"]
                                        : //Edit Mode
                                          this.showSelectedBudgetline(
                                            idx,
                                            state,
                                            myself
                                          )
                                    }
                                    onChange={(e, value, reason) => {
                                      if (reason !== "clear") {
                                        FEServices.changeHanlderServerSiderDropDown(
                                          e,
                                          value,
                                          reason,
                                          "equipmentBudgetline",
                                          myself,
                                          idx,
                                          "selectedEquipmentBudgetlines"
                                        );
                                      } else {
                                        let selectedEquipmentBudgetlines = [
                                          ...state.selectedEquipmentBudgetlines,
                                        ];
                                        let currentSelectedEquipmentBudgetline = {
                                          ...selectedEquipmentBudgetlines[idx],
                                        };
                                        currentSelectedEquipmentBudgetline.showAuthorizedPerson = false;
                                        currentSelectedEquipmentBudgetline.equipmentBudgetline = null;
                                        currentSelectedEquipmentBudgetline.budgetid = null;
                                        currentSelectedEquipmentBudgetline.selectedOption = null;
                                        selectedEquipmentBudgetlines[
                                          idx
                                        ] = currentSelectedEquipmentBudgetline;

                                        myself.setState({
                                          selectedEquipmentBudgetlines,
                                        });

                                        this.props.loadBudgetLinesData();
                                      }
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          {...params}
                                          name={`txtFieldBL-${idx}`}
                                          inputProps={{
                                            ...params.inputProps,
                                            className:
                                              "pb-1" +
                                              (params.inputProps &&
                                                " " +
                                                  params.inputProps.className),
                                          }}
                                          variant="standard"
                                          label="Budget Line"
                                          InputLabelProps={{
                                            shrink: true,
                                            classes: {
                                              shrink: "mt-n1",
                                            },
                                          }}
                                          placeholder="Select Budget Line"
                                          required={true}
                                          error={
                                            this.state.showValidationErrors &&
                                            _.isEmpty(
                                              state
                                                .selectedEquipmentBudgetlines[
                                                idx
                                              ]["equipmentBudgetline"]
                                            )
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            this.state.showValidationErrors &&
                                            _.isEmpty(
                                              state
                                                .selectedEquipmentBudgetlines[
                                                idx
                                              ]["equipmentBudgetline"]
                                            )
                                              ? "Required"
                                              : ""
                                          }
                                        />
                                      );
                                    }}
                                  />
                                </div>

                                <div className="col-sm-2 pr-0 pl-0 align-self-center">
                                  {state.selectedEquipmentBudgetlines[idx]
                                    .showAuthorizedPerson &&
                                    state.selectedEquipmentBudgetlines[idx]
                                      .budgetauthorizer && (
                                      <>
                                        <span>
                                          <small className="font-weight-bold text-secondary d-block pl-2">
                                            Authorizer:
                                          </small>
                                        </span>

                                        <span className="h6">
                                          <span class="badge px-3 py-2 m-1 badge-pill text-info bg-transparent border border-info">
                                            {state.selectedEquipmentBudgetlines[
                                              idx
                                            ] &&
                                              !_.isEmpty(
                                                state
                                                  .selectedEquipmentBudgetlines[
                                                  idx
                                                ]
                                              ) &&
                                              state
                                                .selectedEquipmentBudgetlines[
                                                idx
                                              ].budgetauthorizer}
                                          </span>
                                        </span>
                                      </>
                                    )}
                                </div>
                                <div className="col-sm-2 mt-3 pl-0 pr-0 align-self-center">
                                  <div class="input-group">
                                    <input
                                      value={
                                        state.selectedEquipmentBudgetlines[idx][
                                          "allocatedpct"
                                        ]
                                      }
                                      type="text"
                                      id="equipmentBGPercent"
                                      className={`form-control ${
                                        disablePermissionforBudgetline
                                          ? "grayoutText bgclr"
                                          : ""
                                      }`}
                                      // class="form-control"
                                      placeholder="Allocated"
                                      pattern="\d*"
                                      maxLength="3"
                                      max="100"
                                      min="1"
                                      disabled={disablePermissionforBudgetline}
                                      onChange={(e) =>
                                        FEServices.handleAllocatedPct(
                                          e,
                                          myself,
                                          idx,
                                          "selectedEquipmentBudgetlines",
                                          "allocatedpct"
                                        )
                                      }
                                      required
                                    />
                                    <small className="text-danger pl-3 font-italic d-none">
                                      {state.allocatedpct_err}
                                    </small>
                                    <span class="input-group-text">
                                      <i class="fas fa-percent fa-xs"></i>
                                    </span>
                                  </div>
                                </div>

                                {(entitykey == 0 || this.props.isEquipment) && (
                                  <div className="col-1 pt-sm-1 align-self-center text-center mt-2">
                                    {
                                      <button
                                        disabled={
                                          disablePermissionforBudgetline
                                        }
                                        type="button"
                                        data-id={idx}
                                        onClick={() =>
                                          this.removeRow(
                                            item.id,
                                            idx,
                                            state,
                                            myself,
                                            isEquipment
                                          )
                                        }
                                        className="btn btn-danger btn-sm"
                                      >
                                        <i className="fas fa-trash-alt" />
                                      </button>
                                    }
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ))}
                  <hr className="mb-3" />
                  <div className="row">
                    <div className="col-8"></div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* Show add Budgetline only in add mode for Requests */}
          {(entitykey == 0 || this.props.isEquipment) &&
            !disablePermissionforBudgetline && (
              <div class="pt-2 pb-2 pr-4">
                {
                  //  this.canAdd("phones") &&
                  <button
                    id="addBudgetLineBtn"
                    type="button"
                    className="btn btn-outline-info float-right mo-mt-2 mb-2"
                    onClick={() => this.addRowClick(myself)}
                  >
                    <i className="fas fa-plus"></i> Add Budget Line
                  </button>
                }
              </div>
            )}
          {/*############ View Mode For BudgetLines########### */}
        </div>
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </>
    );
  }
}
