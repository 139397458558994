import React, { Component } from "react";
import $ from "jquery";
import Users from "../../modules/Admin/Users/Users";
import actionFilter from "../../../src/store/advancedfilter/action";
import actionSaveSearch from "../../../src/store/saveSearch/action";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
import _ from "lodash";
import { debounce } from "throttle-debounce";
import PermissionFeature from "../../modules/Permissions/PermissionFeature";
import AuthService from "../../modules/Auth/AuthService";
export class TopSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      memberSearchText: "",
      memberSearchText_err: "",
      valid: false,
      redirectToNewPage: false,
      members: [],
      offset: 0,
      fetch: 20,
      total: 0,
      searchType: 1,
      isShowSearch: false,
      user: {},
    };
    this.scroll = React.createRef();
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
  }
  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }
  componentDidMount() {
    this.setState({ user: AuthService.getProfile().memberData });
  }
  handleClick = (e) => {
    if (this.searchNode !== undefined && this.searchNode !== null) {
      if (this.searchNode.contains(e.target)) {
        return;
      }
      this.setState({ isShowSearch: false });
    }
  };
  searchTextKeyupHandler = (event) => {
    if (event.keyCode == 13) {
      this.handleSearchClick(event);
    }
  };

  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.SearchMembers(
      this.state.memberSearchText,
      this.state.searchType,
      this.state.offset,
      this.state.fetch
    );
    if (data !== null) {
      this.setState({
        total: data.result.total,
        members: data.result.members,
        isShowSearch: true,
      });
    }
  };
  searchTextChangeHandler = async (event) => {
    if (
      !_.isEmpty(this.state.members) &&
      this.scroll !== null &&
      this.scroll.current !== null
    ) {
      this.scroll.current.scrollTo(0, 0);
    }
    var searchTerms = "";
    var searchType = 1;
    var firstChar = event.target.value.charAt(0);
    if (firstChar <= "9" && firstChar >= "0") {
      searchTerms = event.target.value
        .replace(/[A-Za-z]*$/gi, "")
        .replace(/[^\w\s]/gi, "");
      searchType = 2;
    } else {
      if (this.validateEmail(event.target.value)) {
        searchType = 3;
      } else {
        searchType = 1;
      }
      searchTerms = event.target.value;
    }
    if (!_.isEmpty(searchTerms)) {
      this.setState(
        {
          offset: 0,
          memberSearchText: searchTerms,
          searchType: searchType,
        },
        () => {
          this.autocompleteSearchDebounced();
        }
      );
    } else {
      this.setState({
        memberSearchText: searchTerms,
        total: 0,
        members: [],
        isShowSearch: false,
      });
    }
  };

  handleSearchClick = (e) => {
    e.preventDefault();
    console.log("this.props ad", this.props);
    console.log("this ad", this);
    this.props.setAdvancedSearch(false);

    this.props.isSavedSearchSelected(false);
    this.props.setIsBasicSearchSelected(true);
    this.props.setBasicSearchKeyWord(this.state.memberSearchText);
    this.props.setFilterResultPageNo(1);
    this.props.setFilterResultPageSize(25);
    this.props.setMemberAccordianType(1);
    //  this.props.setFilterData({});
    this.setState({ redirectToNewPage: true, isShowSearch: false });
    if (
      window.location.href.includes("directory") &&
      this.state.redirectToNewPage
    ) {
      $(".d-tabs").removeClass("directory-brand-active");
      this.props.history.push({
        pathname: "/directory/members",
        keyword: this.state.memberSearchText,
        tab: Users,
        name: "Members",
        showActive: true,
      });
    } else if (
      this.state.redirectToNewPage &&
      !window.location.href.includes("directory")
    ) {
      if (this.state.memberSearchText === this.props.basicSearchKeyWord) {
        this.setState({ redirectToNewPage: false });
      }
    }
  };
  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  fatchMoreResult = async () => {
    this.setState({ offset: this.state.offset + 20 });
    var data = await this.SearchMembers(
      this.state.memberSearchText,
      this.state.searchType,
      this.state.offset,
      this.state.fetch
    );
    if (data !== null && !_.isEmpty(data.result.members)) {
      this.setState({
        members: [...this.state.members, ...data.result.members],
      });
    }
  };
  SearchMembers = async (searchTerms, type, offset, fetch) => {
    let url =
      `api/Member/Members?searchTerms=` +
      searchTerms +
      `&type=` +
      type +
      `&offset=` +
      offset +
      `&fetch=` +
      fetch;
    var { data } = await axios.get(url);
    if (data.statusCode !== 500) {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  };
  handleLoadMemberPage = (id) => {
    this.setState({ isShowSearch: false });
    this.props.history.push({
      pathname: "/directory/userAction/" + id,
      type: "edit",
      uid: id,
      keyword: this.state.memberSearchText,
      showActive: true,
    });
  };
  canSPView(memberId, regionId, facilityId) {
    var isRegion = false;
    var isFacility = false;
    var isMemberNumber = false;
    var isFirstName = false;
    var isLastName = false;
    var isEmail = false;
    var isPhone = false;
    var isMemberType = false;
    //const scopepermissions = JSON.parse(
    //  localStorage.getItem("scopepermissions")
    //);
    //if (!_.isEmpty(scopepermissions.scopePermission)) {
    if (
      AuthService.canSPView(
        PermissionFeature.Region,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isRegion = true;
    }
    if (
      AuthService.canSPView(
        PermissionFeature.Facility,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isFacility = true;
    }
    if (
      AuthService.canSPView(
        PermissionFeature.PersonalInfoMemberNumber,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isMemberNumber = true;
    }
    if (
      AuthService.canSPView(
        PermissionFeature.PersonalInfoFirstName,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isFirstName = true;
    }
    if (
      AuthService.canSPView(
        PermissionFeature.PersonalInfoLastName,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isLastName = true;
    }
    if (
      AuthService.canSPView(
        PermissionFeature.ContactPhones,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isPhone = true;
    }
    if (
      AuthService.canSPView(
        PermissionFeature.ContactEmailAddresses,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isEmail = true;
    }
    if (
      AuthService.canSPView(
        PermissionFeature.MembershipMemberType,
        regionId,
        facilityId,
        memberId
      )
    ) {
      isMemberType = true;
    }
    //}
    return {
      isRegion,
      isFacility,
      isMemberNumber,
      isFirstName,
      isLastName,
      isEmail,
      isPhone,
      isMemberType,
    };
  }
  renderSearchArea = () => {
    if (
      this.state.isShowSearch === true &&
      !_.isEmpty(this.state.memberSearchText)
    ) {
      return (
        <div
          className="live-searched"
          ref={(searchNode) => (this.searchNode = searchNode)}
        >
          <div
            className="searched-scroll"
            id="searched-scroll"
            ref={this.scroll}
          >
            <InfiniteScroll
              dataLength={this.state.members.length}
              next={this.fatchMoreResult}
              hasMore={true}
              scrollableTarget="searched-scroll"
            >
              {!_.isEmpty(this.state.members) &&
                this.state.members.map((item, i) => {
                  const {
                    isFirstName,
                    isLastName,
                    isMemberType,
                    isMemberNumber,
                    isEmail,
                    isPhone,
                    isFacility,
                    isRegion,
                  } = this.canSPView(item.id, item.regionID, item.facilityID);
                  return (
                    <div className="searched-item" key={"itemm-" + i}>
                      <a
                        className="row-search-bar"
                        onClick={() => this.handleLoadMemberPage(item.id)}
                      >
                        <div className="value">
                          <div className="user-info">
                            <h4>
                              <i class="fa fa-user" aria-hidden="true"></i>
                              {isFirstName && item.firstName}{" "}
                              {isLastName && item.lastName}
                              <span className="member-no">
                                {isMemberType && item.memberType + " "}
                                {isMemberNumber && item.memberNumber}
                              </span>
                            </h4>
                          </div>
                          <div className="email-phone">
                            {isEmail && !_.isEmpty(item.email) && (
                              <a
                                className="anker  mr-2"
                                href={
                                  !_.isEmpty(item.email)
                                    ? "mailto:" + item.email
                                    : "javascript:void(0"
                                }
                              >
                                <i
                                  class="fa fa-envelope"
                                  aria-hidden="true"
                                ></i>

                                {item.email}
                              </a>
                            )}
                            {isPhone && !_.isEmpty(item.phone) && (
                              <a
                                className="anker"
                                target="_blank"
                                href={
                                  !_.isEmpty(item.phone)
                                    ? "tel:" + item.phone
                                    : "javascript:void(0"
                                }
                              >
                                <i
                                  className={
                                    item.phoneTypeID === 1
                                      ? "fa fa-mobile-alt cell-phone"
                                      : "fa fa-phone"
                                  }
                                  aria-hidden="true"
                                ></i>

                                {item.phone}
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="zdc-arrow">
                          {(isFacility || isRegion) && (
                            <p>
                              {isRegion &&
                                (item.region != null ? item.region : "--")}{" "}
                              |{" "}
                              {isFacility &&
                                (item.facility != null ? item.facility : "--")}
                            </p>
                          )}
                          <span>
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                  );
                })}
            </InfiniteScroll>
            {_.isEmpty(this.state.members) && (
              <div className="search-not-found">
                <h4>Search criteria not found</h4>
              </div>
            )}
          </div>
          <div className="search-view-all">
            {/* <a onClick={() => this.setState({ isShowSearch: false })}>
              <i class="fa fa-times"></i>
            </a> */}
            <p>
              <a onClick={this.state.total !== 0 && this.handleSearchClick}>
                <i class="fa fa-search"></i>
                View all {this.state.total} employee(s)
              </a>
            </p>
          </div>
        </div>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };
  render() {
    let path = window.location.pathname;
    let id = "";
    let classs = "";
    if (path.indexOf("/directory") > -1 || path.indexOf("/dashboard") > -1) {
      id = "TopSearchInput";
      classs = "d-md-inline d-none";
    }
    if (this.state.redirectToNewPage) {
      return (
        <>
          {(this.state.user.membertypeid == 6 ||
            this.state.user.membertypeid == 8 ||
            this.state.user.membertypeid == 10) && (
            <form
              role="search"
              className="form-inline marginRight width100"
              onSubmit={this.handleSearchClick}
            >
              <div className="input-group width100">
                <input
                  name="memberSearchText"
                  type="text"
                  autoComplete="off"
                  id="memberSearchkeyword"
                  value={this.state.memberSearchText}
                  onChange={this.searchTextChangeHandler}
                  onKeyUp={this.searchTextKeyupHandler}
                  className="form-control MemberSearchField"
                  placeholder="Member Search .."
                />
                <div className="input-group-append">
                  <button type="submit" className="topBarSearchBtn">
                    <i className="fa fa-search" /> Search
                  </button>
                </div>
                <small className="text-danger w-100">
                  {this.state.memberSearchText_err}
                </small>
              </div>
              {this.renderSearchArea()}
            </form>
          )}
          <Redirect to={{ pathname: "/directory/members" }} />
        </>
      );
    } else {
      return (
        <>
          {(this.state.user.membertypeid == 6 ||
            this.state.user.membertypeid == 8 ||
            this.state.user.membertypeid == 10) && (
            <form
              role="search"
              className="form-inline marginRight width100"
              onSubmit={this.handleSearchClick}
            >
              <div className={"input-group " + (id == "" ? "w-100" : id)}>
                <input
                  name="memberSearchText"
                  type="text"
                  id="memberSearchkeyword"
                  autoComplete="off"
                  value={this.state.memberSearchText}
                  onChange={this.searchTextChangeHandler}
                  onKeyUp={this.searchTextKeyupHandler}
                  className="form-control MemberSearchField"
                  placeholder="Member Search .."
                />
                <div className="input-group-append">
                  <button type="submit" className="topBarSearchBtn">
                    <i className="fa fa-search" />{" "}
                    <span className={classs}>Search</span>
                  </button>
                </div>
                <small className="text-danger w-100">
                  {this.state.memberSearchText_err}
                </small>
              </div>
              {this.renderSearchArea()}
            </form>
          )}
        </>
      );
    }
  }
}

export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    SavedSearch: state.saveSearch,
  }),
  {
    ...actionFilter,
    ...actionSaveSearch,
  }
)(TopSearchBar);
