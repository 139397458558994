import React, { Component } from "react";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import _ from "lodash";
import $ from "jquery";
import Services from "../GrievanceService";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
class StepMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingDate: new Date(),
      FAANumber: "",
      selectedFiles: [],
      setDatePickerPosition: "bottom",
      randomId: Math.round(Math.random() * (100 - 10) + 10),
    };
    this.handleInit = () => {
      // console.log("FilePond instance has initialised", this.pond);
    };
    //        this.buttonRef = React.createRef();
    this.cancelMeeting = () => {
      this.props.history.push({
        pathname: "/grievances",
        type: "list",
      });
    };
    this.updateChange = () => {};
    this.getRandomArbitrary = (min, max) => {
      return Math.random() * (max - min) + min;
    };
    this.saveMeeting = () => {
      $(".loading").show();
      var data = {
        stepName: this.props.stepsData.stepName,
        meetingDate: this.state.meetingDate,
        isMeetingRequired: true,
        grievanceId: props.match.params.Id,
        stepType: "Meeting",
        isAdverse: this.props.stepsData.isAdverse,
        dateOfVoilation: this.props.stepsData.dateOfVoilation,
        dateSubmitted: this.props.stepsData.dateSubmitted,
        subject: this.props.stepsData.subject,
        fAANumber: this.props.stepsData.fAANumber,
        statementOfFacts: this.props.stepsData.statementOfFacts,
        remedySought: this.props.stepsData.remedySought,
        lrRepresentativeId: this.props.stepsData.lrRepresentativeId,
        grtRepresentativeId: this.props.stepsData.grtRepresentativeId,
        representativeId: this.props.stepsData.representativeId,
        recipientId: this.props.stepsData.recipientId,
        additionalFacts: "",
        statusId: 2,
      };

      let formdata = new FormData();
      for (var i = 0; i < this.state.selectedFiles.length; i++) {
        formdata.append("File" + i, this.state.selectedFiles[i]);
      }
      formdata.append("data", JSON.stringify(data));
      Services.SaveStep(formdata).then((result) => {
        if (!_.isEmpty(result)) {
          toast.success("Request saved successfully");
          this.props.rerenderParentCallback();
          $(".loading").hide();
        }
      });
    };
  }
  componentDidMount() {
    // Calculate remaining space after the component has mounted
    //this.calculateRemainingSpace();
  }

  componentDidUpdate() {
    // Recalculate remaining space after each update
  }
  calculateRemainingSpace() {
    //if (!this.buttonRef.current) return;
    if (document.querySelector("#meetingdatepicker") != null) {
      const buttonRect = document
        .querySelector("#meetingdatepicker")
        .getBoundingClientRect().bottom;
      const remainingSpace = document.body.scrollHeight - buttonRect;
      console.log(remainingSpace);
      if (remainingSpace < 300) {
        this.setState({ setDatePickerPosition: "top" });
      } else {
        this.setState({ setDatePickerPosition: "bottom" });
      }
    }
  }
  handleCollapse = (id) => {
    if ($("#collapse" + id).is(":visible")) {
      $("#collapse" + id).hide(1000);
      $("#label" + id).text("<i class='fas fa-angle-down'></i>");
    } else {
      $("#collapse" + id).show(1000);
      $("#label" + id).text("<i class='fas fa-angle-up'></i>");
    }
  };
  render() {
    return (
      <>
        {(() => {
          if (!this.props.stepCreateMode || !this.props.stepsData.isMeeting) {
            return (
              <li>
                <div class="node green" />
                <div class="stepPara">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <div className="float-left">
                          <h5>{this.props.stepsData.stepName}</h5>
                        </div>
                      </div>
                      <div class="col-md-12">
                        Meeting Completed {this.props.stepsData.meetingDate}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          } else {
            return (
              <li>
                <div class="node meeting" />
                <div class="stepPara">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <div className="float-left">
                          <h5>{this.props.stepsData.stepName}</h5>
                        </div>
                        <div
                          style={{
                            float: "left",
                            marginTop: "13px",
                            marginLeft: "10px",
                          }}
                        >
                          <label
                            onClick={() => {
                              this.handleCollapse(this.state.randomId);
                            }}
                            id={"label" + this.state.randomId}
                          >
                            <i class="fas fa-level-down"> </i>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        {this.props.stepsData.stepDueByDate !== "" && (
                          <p>
                            {this.props.stepsData.stepName ==
                            "Expedited Arbitration Hearing"
                              ? "Hearing Date"
                              : "Due By"}{" "}
                            {this.props.stepsData.stepDueByDate}{" "}
                          </p>
                        )}
                        <div
                          className="col-md-6 mb-2"
                          style={{
                            border: "1px solid darkgrey",
                            padding: "15px",
                          }}
                          id={"collapse" + this.state.randomId}
                        >
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            {this.props.stepsData.stepName &&
                              this.props.stepsData.stepName.indexOf("Step 2") >
                                -1 && (
                                <small>
                                  {" "}
                                  Mutually agreed upon meeting date:
                                </small>
                              )}
                            {this.props.stepsData.stepName &&
                              this.props.stepsData.stepName.indexOf("Step 2") ==
                                -1 && <small>Meeting Date:</small>}
                          </span>
                          {/*
                                                    <DatePickerInput

                                                        id="meetingdatepicker"
                                                        readonly="readonly"
                                                        onChange={(event, value) => {
                                                            this.setState({ meetingDate: value });
                                                        }}
                                                        position={this.state.setDatePickerPosition}
                                                        floating={true}
                                                        value={this.props.stepsData.meetingDate}
                                                    />
                                                    */}
                          <DatePicker
                            dateForm="MM/DD/YYYY"
                            className="form-control bg-light rounded-0 "
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            onChange={(date) => {
                              if (moment(date).isValid()) {
                                this.state.meetingDate = moment(date).format(
                                  "MM/DD/YYYY"
                                );
                              } else {
                                this.state.meetingDate = date;
                              }
                              this.setState({
                                meetingDate: this.state.meetingDate,
                              });
                            }}
                            selected={
                              this.state.meetingDate
                                ? moment(this.state.meetingDate).toDate()
                                : moment(new Date()).toDate()
                            }
                            placeholderText={"MM/DD/YYYY"}
                          />

                          <span className="text-danger">{""}</span>
                          <div
                            style={{
                              marginTop: "15px",
                              textAlign: "center",
                            }}
                          >
                            <button
                              className="btn btn-secondary btn-x-sm"
                              style={{ marginRight: "40px" }}
                              onClick={() => {
                                this.cancelMeeting();
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-success btn-x-sm"
                              onClick={() => {
                                this.saveMeeting();
                              }}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          }
        })()}
      </>
    );
  }
}

export default withRouter(StepMeeting);
