import React, { Component, Fragment } from "react";
import { MDBInput } from "mdbreact";
import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";
import Service from "../Admin/Users/EditUser/EditUserService";
import _ from "lodash";
import { Button, Dropdown } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
const filter = createFilterOptions();
class AddressPopup extends Component {
  constructor(props) {
    super(props);
  }
  componentWillReceiveProps(nextProps) {}

  componentWillReceiveProps(nextProps) {}

  render() {
    const {
      addresses,
      changeHandlerAddress,
      handleChangePAddress,
      handleRemoveRow,
      handleFormSubmitAddresses,
      handleResetProfileAddress,
      changeHandlerState,
      States,
      handleAddRow,
      handleSelectChange,
      addressTypes,
      validateUSPS,
    } = this.props;
    return (
      <div
        className="modal fade latest"
        id="EditAddressModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="EditAddressModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content border-0">
            <div class="modal-header py-2 bg-secondary">
              <h5 class="modal-title mt-0 text-white" id="EditRegionModalTitle">
                {" "}
                Edit Address{" "}
              </h5>
              <button
                type="button"
                class="close text-white"
                onClick={handleResetProfileAddress}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <form
                id="editAddressForm"
                className="form-horizontal addressMemberForm"
                onSubmit={handleFormSubmitAddresses}
                noValidate
              >
                {addresses.map((item, idx) => (
                  <div
                    id={`add${idx}`}
                    key={"addform" + idx}
                    data-repeater-list="group-a"
                  >
                    <div data-repeater-item className="row mb-4">
                      <div className="col-sm-4 align-self-center">
                        {/*<label>Address Type</label>*/}
                        <select
                          className="browser-default custom-select"
                          onChange={handleSelectChange}
                          name="addresstypeid"
                          data-id={idx}
                          data-obj="addresses"
                          required
                          value={item.addresstypeid}
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.ContactAddresses,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.ContactAddresses
                            )
                          }
                        >
                          <div className="invalid-feedback">
                            Address Type is required.
                          </div>
                          {addressTypes.map((item1, idx) => (
                            <option
                              key={idx}
                              value={item1.id}
                              selected={item1.id == item.addresstypeid}
                            >
                              {item1.code}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/*<div className="row col-11">*/}
                      <div data-id={idx} className="col-sm-12">
                        <MDBInput
                          name="address1"
                          containerClass="mb-0"
                          value={item.address1}
                          onChange={changeHandlerAddress}
                          label="Address 1"
                          data-obj="addresses"
                          data-name="Address 1"
                          required
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.ContactAddresses,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.ContactAddresses
                            )
                          }
                          group
                          type="text"
                        >
                          <div className="invalid-feedback">
                            Address1 is required.
                          </div>
                        </MDBInput>
                      </div>

                      <div data-id={idx} className="col-sm-12">
                        <MDBInput
                          name="address2"
                          containerClass="mb-0"
                          value={item.address2}
                          onChange={changeHandlerAddress}
                          label="Address 2"
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.ContactAddresses,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.ContactAddresses
                            )
                          }
                          data-obj="addresses"
                          group
                          type="text"
                        />
                      </div>

                      <div data-id={idx} className="col-sm-4">
                        <MDBInput
                          name="city"
                          containerClass="mb-0"
                          value={item.city}
                          onChange={changeHandlerAddress}
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.ContactAddresses,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.ContactAddresses
                            )
                          }
                          label="City"
                          data-obj="addresses"
                          data-name="City"
                          group
                          required
                          type="text"
                        >
                          <div className="invalid-feedback">
                            City is required.
                          </div>
                        </MDBInput>
                      </div>

                      <div data-id={idx} className="col-sm-4">
                        <Autocomplete
                          options={!_.isEmpty(States) ? States : []}
                          autoComplete={true}
                          autoHighlight={true}
                          selectOnFocus
                          clearOnBlur
                          onChange={(e, value) => {
                            changeHandlerState(e, value, idx);
                          }}
                          getOptionLabel={(option) => {
                            if (option && option.inputValue) {
                              return option.inputValue;
                            }
                            if (option != undefined) {
                              return option;
                            }

                            // Regular option
                            return "";
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            // Suggest the creation of a new value
                            if (
                              params.inputValue !== "" &&
                              (_.isEmpty(filtered) ||
                                filtered.findIndex(
                                  (x) =>
                                    x.toUpperCase() ==
                                    params.inputValue.toUpperCase()
                                ) == -1)
                            ) {
                              filtered.push({
                                state: `Add "${params.inputValue}"`,
                                inputValue: params.inputValue,
                              });
                            }

                            return filtered;
                          }}
                          value={item.state}
                          id="state"
                          renderOption={(option) => {
                            if (option && option.state) {
                              return option.state;
                            }
                            if (option != undefined) {
                              return option;
                            }
                            // Regular option
                            return "";
                          }}
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.ContactAddresses,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.ContactAddresses
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="State"
                              variant="standard"
                              error={this.props.state_error}
                              helperText={
                                this.props.state_error
                                  ? "State is required"
                                  : ""
                              }
                              required
                              label={"State"}
                              value={item.state}
                              className="md-form state-container"
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                id: "state",
                                name: "state",
                                className: "form-control",
                                autoComplete: "disabled", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {/*   <MDBInput
                          name="state"
                          containerClass="mb-0"
                          value={item.state}
                          onChange={changeHandlerAddress}
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.ContactAddresses,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.ContactAddresses
                            )
                          }
                          label="State"
                          data-obj="addresses"
                          data-name="State"
                          group
                          required
                          type="text"
                        >
                          <div className="invalid-feedback">
                            State is required.
                          </div>
                        </MDBInput>*/}
                      </div>
                      <div data-id={idx} className="col-sm-4">
                        <MDBInput
                          name="zipcode"
                          containerClass="mb-0"
                          value={item.zipcode}
                          onChange={changeHandlerAddress}
                          disabled={
                            !AuthService.canOwnUpdate(
                              PermissionFeature.ContactAddresses,
                              this.props.memberid
                            ) &&
                            !AuthService.canMemberUpdate(
                              PermissionFeature.ContactAddresses
                            )
                          }
                          label="Zipcode"
                          data-obj="addresses"
                          data-name="Zipcode"
                          group
                          required
                          type="text"
                        >
                          <div className="invalid-feedback">
                            Zipcode is required.
                          </div>
                        </MDBInput>
                      </div>

                      {/*<div className="offset-sm-1 col-sm-4 pt-sm-4">
                          <label className="mt-n1">Primary?</label>
                          <input
                            type="checkbox"
                            id={`Primary-${idx}`}
                            name="isprimary"
                            disabled={
                              !AuthService.canMemberUpdate(
                                PermissionFeature.ContactAddresses
                              )
                            }
                            switch="bool"
                            data-obj="addresses"
                            checked={item.isprimary}
                            onChange={handleChangePAddress}
                          />
                          <label
                            className="ml-4 mb-n2"
                            htmlFor={`Primary-${idx}`}
                            data-on-label="Yes"
                            data-off-label="No"
                          />

                          <span className="d-block text-danger">
                            {
                              item.isprimary_err
                            }
                          </span>
                          </div>*/}
                      {/*</div>*/}
                      {/*<div className="col-1 align-self-center">
                      {AuthService.canDelete(
                        PermissionFeature.ContactAddresses
                      ) && (
                        <button
                          type="button"
                          data-id={idx}
                          onClick={handleRemoveRow}
                          className="btn btn-danger btn-sm"
                        >
                          <i data-id={idx} className="fas fa-trash-alt" />
                        </button>
                      )}
                      </div>*/}
                    </div>
                  </div>
                ))}
                {/*   <hr className="mb-3" />*/}
                <div className="col-12 text-centered d-flex justify-content-center">
                  <button
                    className="btn btn-sm btn-danger cancelModal w-md waves-effect waves-light"
                    type="button"
                    id="addressPopupCloseBtn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleResetProfileAddress}
                  >
                    Cancel
                  </button>

                  <button
                    //   onClick={handleFormSubmitAddresses}
                    className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                    type="submit"
                  >
                    {validateUSPS ? "Validate USPS" : "Save"}
                  </button>
                </div>
              </form>
            </div>
            {/* 
            <div className="row">
              <div className="col-12 text-centered">
                <div className="col-4 align-self-center">
                  <a
                    onClick={handleAddRow}
                    className="float-right c-pointer text-success mo-mt-2"
                  >
                    <i className="fas fa-plus"></i> Add Address
                  </a>
                
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default AddressPopup;
