import axios from "../Shared/auth-header";
import { toast } from "react-toastify";
import _ from "lodash";
export default {
  FinanceDropdowns: async () => {
    let url = `api/Finance/FinanceDropdown`;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetFinanceSingleObject: async (id) => {
    if (id == null || id == undefined || id == -1 || id == "") {
      toast.error("Invalid Data, please refresh the page!");
      return null;
    }
    let url = `api/Finance/SingleObject?Id=` + id;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteFinance: async (id) => {
    if (id == null || id == undefined || id == -1 || id == "") {
      toast.error("Invalid Data, please refresh the page!");
      return null;
    }
    let url = `api/Finance/Finance?Id=` + id;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      toast.success(data.result);
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  DeleteFinanceTransaction: async (id) => {
    if (id == null || id == undefined || id == -1 || id == "") {
      toast.error("Invalid Data, please refresh the page!");
      return null;
    }
    let url = `api/Finance/FinanceTransaction?Id=` + id;
    var { data } = await axios.delete(url);
    if (data.statusCode == 200) {
      toast.success(data.result);
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },

  CreateFinanceFacEmp: async (Obj) => {
    let url = `/api/Finance/FinanceFacEmp`;
    var { data } = await axios.post(url, Obj);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  FinanceUpdate: async (Obj) => {
    let url = `/api/Finance/FinanceCU`;
    var { data } = await axios.post(url, Obj);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  FinanceTransactionCU: async (Obj) => {
    let url = `/api/Finance/FinanceTransactionCU`;
    var { data } = await axios.post(url, Obj);
    if (data && data.statusCode == 200) {
      toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetFinanceDuesMember: async (FacilityId, EmployerId) => {
    if (!FacilityId && !EmployerId) {
      toast.error("In appropriate data, please refresh the page!");
      return null;
    }
    let url =
      `/api/Finance/DuesMembers?FacilityId=` +
      (FacilityId ? FacilityId : "") +
      "&EmployerId=" +
      (EmployerId ? EmployerId : "");
    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      //       toast.success(data.message);
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  FinanceFiltersDropdowns: async () => {
    let url = `api/Finance/FinanceFiltersDropdowns`;

    var { data } = await axios.get(url);
    if (data && data.statusCode == 200) {
      if (!_.isEmpty(data.result)) {
        return data.result;
      } else {
        return null;
      }
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadFinanceTransactionList: async (financeId) => {
    let url = `api/Finance/TransactionList?financeId=` + financeId;

    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  LoadFinanceData: async (
    searchTerms,
    facilityId = "",
    employerId = "",
    payperiod = "",
    duesPostedType = "",
    errorfilter = "",
    year = "",
    memberId = "",
    quarter = "",
    mode = "",
    sort = "",
    offset,
    fetch
  ) => {
    let url =
      offset === null || fetch === null
        ? `api/Finance/List?searchTerms=` +
          searchTerms +
          `&facilityId=` +
          facilityId +
          `&employerId=` +
          employerId +
          `&payperiod=` +
          payperiod +
          `&duesPostedType=` +
          duesPostedType +
          `&errorfilter=` +
          errorfilter +
          `&year=` +
          year +
          `&memberId=` +
          memberId +
          `&quarter=` +
          quarter +
          `&mode=` +
          mode +
          `&sort=` +
          sort +
          `&offset=0&fetch=20`
        : `api/Finance/List?searchTerms=` +
          searchTerms +
          `&facilityId=` +
          facilityId +
          `&employerId=` +
          employerId +
          `&payperiod=` +
          payperiod +
          `&duesPostedType=` +
          duesPostedType +
          `&errorfilter=` +
          errorfilter +
          `&year=` +
          year +
          `&memberId=` +
          memberId +
          `&quarter=` +
          quarter +
          `&mode=` +
          mode +
          `&sort=` +
          sort +
          `&offset=` +
          offset +
          `&fetch=` +
          fetch;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GeneratePDF: async (
    searchTerms,
    facilityId = "",
    employerId = "",
    payperiod = "",
    duesPostedType = "",
    errorfilter = "",
    year = "",
    memberId = "",
    quarter = "",
    mode = "",
    sort = ""
  ) => {
    let uniqueID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

    let url =
      `/api/Finance/PDF?searchTerms=` +
      searchTerms +
      `&facilityId=` +
      facilityId +
      `&employerId=` +
      employerId +
      `&payperiod=` +
      payperiod +
      `&duesPostedType=` +
      duesPostedType +
      `&errorfilter=` +
      errorfilter +
      `&year=` +
      year +
      `&memberId=` +
      memberId +
      `&quarter=` +
      quarter +
      `&mode=` +
      mode +
      `&sort=` +
      sort +
      "&uniqueID=" +
      uniqueID;
    //var { data } = await axios.post(url, checkedIds, { params: { uniqueID } });
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 500) {
      toast.error(data.message);
      return null;
    } else {
      if (data) {
        console.log(data);
        //const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = axios.defaults.baseURL + url;
        // link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  GenerateExcel: async (
    searchTerms,
    facilityId = "",
    employerId = "",
    payperiod = "",
    duesPostedType = "",
    errorfilter = "",
    year = "",
    memberId = "",
    quarter = "",
    mode = "",
    sort = ""
  ) => {
    let uniqueID = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );

    let url =
      `/api/Finance/Excel?searchTerms=` +
      searchTerms +
      `&facilityId=` +
      facilityId +
      `&employerId=` +
      employerId +
      `&payperiod=` +
      payperiod +
      `&duesPostedType=` +
      duesPostedType +
      `&errorfilter=` +
      errorfilter +
      `&year=` +
      year +
      `&memberId=` +
      memberId +
      `&quarter=` +
      quarter +
      `&mode=` +
      mode +
      `&sort=` +
      sort +
      "&uniqueID=" +
      uniqueID;
    //var { data } = await axios.post(url, checkedIds, { params: { uniqueID } });
    var { data } = await axios.get(url);
    if (data.statusCode && data.statusCode == 500) {
      toast.error(data.message);
      return null;
    } else {
      if (data) {
        console.log(data);
        //const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = axios.defaults.baseURL + url;
        // link.target = "_blank";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
};
