import React, { Component } from "react";
import _ from "lodash";
import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";

export class MemberNumber extends Component {
  renderInput = ({
    input,
    label,
    classForm,
    type,
    meta,
    placeholder,
    name,
    id,
  }) => {
    //console.log(meta)
    //onsole.log(meta);
    return (
      <div className="form-group">
        {label !== "" && !this.props.isMobile ? (
          <label htmlFor={id}>{label} </label>
        ) : null}
        <input
          {...input}
          className={classForm}
          type={type}
          id={id}
          placeholder={placeholder}
          autoComplete="off"
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  validateNumber = (value) =>
    value && isNaN(Number(value))
      ? "Please enter numeric characters only."
      : undefined;

  renderSubFields = (control, index, fields) => {
    const vt = fields.length;
    // console.log("fields lenght:" + vt)
    return (
      <li key={index}>
        <Row
          className="col-sm-12 col-xs-12 col-md-9 col-xl-9 col-lg-9"
          style={{ display: "flex" }}
        >
          <div className="input-group mb-3">
            <div className="input-group-append">
              <button
                type="btn"
                className="btn btn-info faAddBtn"
                onClick={() => fields.push()}
              >
                <i className="fas fa-plus-circle"></i>
              </button>
            </div>
            <Field
              name={control}
              type="text"
              label=""
              classForm="form-control memberNumId col-sm-12 col-xs-12"
              validate={[this.validateNumber]}
              component={this.renderInput}
              placeholder={`Member Number #${index + 1}`}
            />
            {index > 0 ? (
              <button
                className="btn fatrashBtn"
                stitle="Remove Member No"
                onClick={() => fields.remove(index)}
              >
                <i className="fas fa-minus-circle"></i>
              </button>
            ) : null}
          </div>
        </Row>
        {fields.error && <li className="error">{fields.error}</li>}
      </li>
    );
  };

  renderControls = ({ fields, meta: { error } }) => (
    <>
      <div className="field-array-scroll" style={{ minHeight: "5rem" }}>
        <ul>
          <li>
            {fields.length < 1 ? (
              <button
                type="button"
                className="btn btn-info"
                onClick={() => fields.push()}
              >
                Add Member#
              </button>
            ) : null}
          </li>
          {fields.map((control, index) => (
            <div className="row" key={index + 1}>
              <li key={index}>
                <div className="input-group">
                  <div className="input-group-append">
                    <button
                      type="btn"
                      className="btn btn-info faAddBtn"
                      onClick={() => fields.push()}
                    >
                      <i className="fas fa-plus-circle"></i>
                    </button>
                  </div>
                  <Field
                    name={control}
                    type="text"
                    label=""
                    classForm="form-control memberNumId col-sm-12 col-xs-12"
                    validate={[this.validateNumber]}
                    component={this.renderInput}
                    placeholder={`Member Number #${index + 1}`}
                  />
                  {index > 0 ? (
                    <button
                      className="btn fatrashBtn"
                      stitle="Remove Member No"
                      onClick={() => fields.remove(index)}
                    >
                      <i className="fas fa-minus-circle"></i>
                    </button>
                  ) : null}
                </div>
              </li>
            </div>
          ))}
          {error && <li className="error">{error}</li>}
        </ul>
      </div>
    </>
  );

  onSubmit = (formValues) => {
    //  console.log(formValues);
    // this.props.Login(formValues, this.props.history);
    // this.props.fetchUserProfile();
  };
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Member Number</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}

        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col
              className={`mt-4 ${this.props.isMobile &&
                "m-2 pl-0 pr-0"}  m-sm-2`}
            >
              <FieldArray
                name="memberNumbers"
                component={this.renderControls}
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "memberNoForm",
  initialValues: {
    memberNumbers: [""],
  },
  destroyOnUnmount: false,
})(MemberNumber);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
