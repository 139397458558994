import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RegistrationsService from "../RegistrationsService";
import TemplatesListTable from "../User/ListTables/TemplatesListTable";
import registrationsAction from "../../../store/registrations/action";
import AddTemplateModal from "./Modals/AddTemplateModal";
import Header from "../AdminHeader";
import _ from "lodash";
import DeleteModal from "../../Dashboard/FacilityDashboard/Modals/DeleteModal";
import TemplateAttachmentsModal from "./Modals/TemplateAttachmentsModal";

export class TemplatesListing extends Component {
  state = {
    templates: [],
    isLoading: false,
    occasionId: 0,
    isConfirmModalOpen: false,
    deleteId: -1,
    activeTabView: "1",
    isModalOpen: false,
  };

  async componentDidMount() {
    await this.reloadTemplates();
  }

  reloadTemplates = async () => {
    this.setState({ isloading: true });
    let data = await RegistrationsService.GetAdminTemplates();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.templates = data.result != null ? data.result : [];
        await this.setState({
          templates: this.state.templates,
          isloading: false,
        });
      }
    }
  };

  // openAddTemplateModal = () => {
  //   this.setState({ editTemplate: {} });
  //   this.props.toggleAddTemplateModal();
  // };

  openChildTemplateForm = (masterId) => {
    this.props.openAddTemplateModal({ formTitle: "Add Child Template", templateCategory: "2", masterTemplateId: masterId });
  }
  
  openEditTemplateForm = (masterId) => {
    this.props.openAddTemplateModal({ formTitle: "Edit Template", templateCategory: "1", masterTemplateId: masterId });
  }

  // onAddTemplateClose = async () => {
  //   this.props.toggleAddTemplateModal();
  //   this.setState({ editTemplate: {} });
  // };
  
  async deleteTemplateCall() {
    let data = await RegistrationsService.deleteTemplateService(this.state.deleteId);

    if (!_.isEmpty(data)) {
      this.reloadTemplates();
    }
  }

  deleteTemplate = (id) => {
    this.setState({
      deleteId: id,
    });
    this.toggleConfirmModal();
  };

  confirm = () => {
    this.confirmed = () => {
      if (this.state.deleteId == 0) {
        return false;
      }

      this.deleteTemplateCall();

      this.setState({ deleteId: -1 });
      this.toggleConfirmModal();
    };
    return this;
  };

  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  toggleModal = () => {
    this.setState({ activeTabView: 1 });
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleFileListClick = (id) => {
    this.setState({ deleteId: id });

    this.props.getTemplateAttachments(this, id);
  };

  render() {
    return (
      <>
        <Header />
        <div className="fet-container container-fluid">
          <br />
          <button
            type="button"
            className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2 mt-4"
            onClick={() => {
                this.props.openAddTemplateModal({ formTitle: "Add Template", templateCategory: "1" });
              }}
          >
            <i class="cursorPointer pr-1 fas fa-plus" />
            Add Template
          </button>

          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">Templates</h5>
            <div className="card-body pb-2 px-0 pt-0">
              <TemplatesListTable
                listData={this.state.templates}
                deleteTemplate={this.deleteTemplate}
                createChildTemplate={this.openChildTemplateForm}
                editTemplate={this.openEditTemplateForm}
                handleFileListClick={this.handleFileListClick}
                isloading={this.state.isloading}
              />
            </div>
          </div>
        </div>

        <AddTemplateModal
          showModal={this.props.registrationsState.openAddTemplateModal}
          reloadRegistrations={this.reloadTemplates}
        />

        <TemplateAttachmentsModal
          //templateId={this.props.templateId}
          modal={this.state.isModalOpen}
          activeTabView={this.activeTabView}
          toggle={this.toggleModal}
        />

        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(TemplatesListing)
);
