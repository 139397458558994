import React from "react";
import PropTypes from "prop-types";
import "./card.scss";
import moment from "moment";
import Services from "../NotificationService";

const Card = ({
  imagePosition,
  classNamePrefix,
  cardOption,
  renderImage,
  markAsRead,
  data,
  setHeaderActiveTab,
  navigateToDetailPage,
}) => {
  const { image, notibody, createDate, detailpage, isread } = data;

  const classNameGenerator = () => {
    const prefix = classNamePrefix ? `${classNamePrefix}-` : "";
    const classes = {
      card: `${prefix}card-noti`,
      cardRead: `${prefix}card-noti-read`,
      content: `${prefix}content`,
      image: `${prefix}image`,
      options: `${prefix}options`,
      option: `${prefix}option`,
      message: `${prefix}message`,
      text: `${prefix}text`,
      time: `${prefix}time`,
    };
    return classes;
  };

  const classes = classNameGenerator();

  const showDetailPage = (
    routeData,
    setFEHeaderActiveTab,
    navigateToDetailPage
  ) => {
    // Set read status in database
    if (!data.isread) {
      data.isread = true;
      let result = Services.UpdateReadStatus(data.id);
    }

    // Navigate to given link
    if (routeData.indexOf("FETracker") != -1) {
      //it is FE Tracker Item
      var recordId = routeData.split("/", 4).pop();
      var navigationPage = routeData.split("/", 3).pop();
      var page = "inventory";
      var tabId = 1;
      if (navigationPage == "reviewRequest") {
        page = "reviewRequest";
        tabId = 3;
      } else if (navigationPage == "myRequests") {
        page = "myRequests";
        tabId = 2;
      }
      setFEHeaderActiveTab(tabId);
      navigateToDetailPage(`/FETracker/${page}/${recordId}`);
      // this.setState({ ActiveFeTab: tabId }, () => {
      //   this.props.history.push(`/FETracker/${page}/${recordId}`);
      // });
    } else if (routeData.indexOf("PACTracker") != -1) {
      //it is PAC Tracker Item
      if (routeData.indexOf("reviewRequest") != -1) {
        navigateToDetailPage(routeData);
      } else {
        navigateToDetailPage(routeData);
      }
    } else if (routeData.indexOf("directory/facility") != -1) {
      //it is Officer Module Notification

      navigateToDetailPage(routeData);
    }
    console.log(routeData);
  };
  return (
    <div className={isread ? classes.cardRead : classes.card}>
      <div
        onClick={() => {
          //markAsRead(data);
          showDetailPage(detailpage, setHeaderActiveTab, navigateToDetailPage);
        }}
        className="card-link-noti"
      >
        <div
          className={classes.content}
          style={
            imagePosition === "right" ? { flexDirection: "row-reverse" } : {}
          }
        >
          {/* {renderImage && (
              <div className={classes.image}>
                <img src={image} alt="Person " />
              </div>
            )} */}
          <div className={classes.message}>
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: notibody }}
            >
              {/* {notibody} */}
            </div>
            {createDate && (
              <div className={classes.time}>
                {/* {createDate} */}
                {moment(
                  new Date(
                    createDate.indexOf("Z") < 0 ? createDate + "Z" : createDate
                  )
                ).format("MM/DD/YY hh:mm:ss")}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.options}>
        {cardOption && (
          <div className={classes.option} onClick={() => cardOption(data)}>
            &hellip;
          </div>
        )}
        {/* {!isread && (
          <div
            className={classes.option}
            title="Mark as Read"
            onClick={() => markAsRead(data)}
          >
            <i className="fas fa-circle fa-xs noti_isread"></i>
            
            {!isread && <div></div>}
          </div>
        )} */}
      </div>
    </div>
  );
};

Card.defaultProps = {
  renderImage: true,
  imagePosition: "left",
  markAsRead: null,
  data: null,
  classNamePrefix: null,
  cardOption: null,
};

Card.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    message: PropTypes.any,
    createDate: PropTypes.string,
    detailpage: PropTypes.string,
    isread: PropTypes.bool,
  }),
  renderImage: PropTypes.bool,
  markAsRead: PropTypes.func,
  cardOption: PropTypes.func,
  imagePosition: PropTypes.oneOf(["left", "right"]),
  classNamePrefix: PropTypes.string,
};
export default Card;
