import React, { Component } from "react";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { Row, Col, Form } from "reactstrap";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class Facility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: false
    };
  }
  onChange(item) {
    var facilityIndex = _.findIndex(
      this.props.filterState.filterData.facility,
      o => {
        return o.id === item.id;
      }
    );
    item.checked = !this.props.filterState.filterData.facility[facilityIndex]
      .checked;
    this.props.filterState.filterData.facility[facilityIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData
    });
  }
  chechboxes(item) {
    return (
      <li class="chechbox-list_item" key={"fliitem-" + item.id}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"fitem-" + item.id}
        >
          <input
            type="checkbox"
            htmlFor={"fitem-" + item.id}
            value={item.id}
            checked={item.checked}
            className="custom-control-input"
            id={"fitem-" + item.id}
            name={"fitem-" + item.id}
            onChange={() => this.onChange(item)}
          />
          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"fitem-" + item.id}
          >
            {item.code}
            {item.total != 0 && (
              <small className="totalCount">{`(${item.total})`}</small>
            )}
          </label>
        </span>
      </li>
    );
  }

  render() {
    let noData = <>No filters available</>;
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Facility</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="search-ad">
          <input
            class="form-control"
            type="text"
            autoComplete="off"
            placeholder="Search"
            value={this.state.query || ""}
            onChange={event => this.setState({ query: event.target.value })}
          />
        </div>
        <div className="filter-fields-scroll">
          <ul className="chechbox-list">
            {!_.isEmpty(this.props.filterState.filterData) &&
            this.props.filterState.filterData.facility.length === 0
              ? noData
              : !_.isEmpty(this.props.filterState.filterData) &&
                this.props.filterState.filterData.facility
                  .filter(itemQ =>
                    _.toLower(itemQ.code).includes(
                      _.toLower(this.state.query || "")
                    )
                  )
                  .map(item => this.chechboxes(item))}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect(state => ({ filterState: state.advancedFilter }), {
  ...actionFilter
})(Facility);
