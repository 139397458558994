import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import $ from "jquery";

class ConfirmDeleteModalTeam extends Component {
    constructor(props) {
        super(props);
    }

    listTeam = (Teams) => {
        const createHierarchy = (arr) => {

            return (
                arr.map((item, idx) => {
                    if (item.checked) {


                        return (

                            <Fragment>
                                <div className="pl-2">


                                    <div className="card-text position-relative row mx-0 px-3 ml-2 py-1 w-100" >
                                        <span className="bullets"></span>
                                        <div data-toggle="collapse" href={"#delCollapse" + idx + item.id} aria-expanded={true} id={"heading" + idx + item.id} aria-controls={"delCollapse" + idx + item.id} className="mb-0 col-11 px-0 c-pointer ">{item.teamName}</div>

                                    </div>
                                    <div id={"delCollapse" + idx + item.id} className="collapse show">
                                        {(item.childs.length > 0) && (createHierarchy(item.childs))}
                                    </div>
                                </div>
                            </Fragment>

                        );
                    }
                    else {
                        if (item.childs.length > 0) { return createHierarchy(item.childs); }
                    }

                })
            );
        };

        return (Teams) && createHierarchy(Teams);
    };
    componentWillReceiveProps(nextprops) {
    }
    render() {
        let deleteId = (this.props.deleteChild) ? "DeleteModalTeam" : "DeleteModalTeams";
        return (
            <AUX>
                <div className="modal fade delete-modal p-0" role="dialog" id={deleteId} aria-labelledby="" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header text-dark">
                                <h5 className="modal-title mt-0">Confirm Delete</h5>

                            </div>
                            <div className="modal-body">
                                <div className="col-12 pb-2 text-danger text-center">
                                    <span className="h1 fa fa-trash-alt"></span>

                                </div>
                                <div className="col-12 mb-4">
                                    {(this.props.deleteChild) ? (
                                        <p className="h5 text-center">Are you sure you want to delete team "{this.props.teamName}"?</p>
                                    ) : (
                                            <div>
                                                <p className="h5 text-center">Are you sure you want to delete the following teams?</p>
                                                {(this.props.parentChecked) && (<h3 class="pl-3"><small className="pr-2"><span class="text-black-50 fas fa-xs fa-caret-right"></span></small>
                                                    {this.props.parentTeamName}</h3>)}
                                                {this.listTeam(this.props.TeamsData)}
                                            </div>
                                        )}
                                </div>
                                <div className="col-12 text-center mb-3 border-top pt-4">
                                    <button
                                        className="btn btn-outline-secondary w-md waves-effect cancelModal waves-light mr-2"
                                        onClick={() => { window.$("#" + deleteId).modal("hide"); }}
                                    >
                                        Cancel
                                </button>
                                    <button
                                        className="btn btn-outline-danger w-md waves-effect waves-light"
                                        onClick={this.props.confirm}>
                                        Delete
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}


export default ConfirmDeleteModalTeam