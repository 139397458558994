import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import { toast } from "react-toastify";
import authAxios from '../Shared/auth-header';
import TableCustomTotal from '../Shared/TableCustomTotal';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const { SearchBar } = Search;
const columns = [
    {
        text: "Facility Name",
        dataField: "description",
        sort: true
    },
    {
        text: "Facility ID",
        dataField: "code",
        sort: true
    }
];

class FacilitiesLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            facilityid: this.props.facilityid,
            facilityMethods: this.props.facilityMethods,
            arg: this.props.arg
        };
        const id = this.props.arg;
        this.rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.state.facilityMethods().updateFacilityId(row, id);

            }
        };
    }

    componentWillMount() {
        $(".loading").show();
        let data = {
            regionCode: ""
        }
        authAxios.post("/api/Facilities/GetFacilitiesByRegion", data).then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({ rows: data.data });

                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">Facilities</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <ToolkitProvider keyField="id" data={this.state.rows} columns={columns} search>
                                {props => (
                                    <Fragment>
                                        <div className="d-flex justify-content-center mb-2">
                                            <div className="col-6 text-center app-search">
                                                <SearchBar className="form-control mb-1 w-100" {...props.searchProps} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <BootstrapTable
                                                    striped
                                                    hover
                                                    pagination={paginationFactory({
                                                        paginationTotalRenderer: TableCustomTotal
                                                    })}
                                                    {...props.baseProps}
                                                    rowEvents={this.rowEvents}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default FacilitiesLookup;
