import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import AuthService from "../Auth/AuthService";
import { connect } from "react-redux";
import * as actionTypes from "../../store/action";
import $ from "jquery";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: AuthService.getProfile,
      upLoadStatus: 0,
      progress: false,
      img: "",
      validSize: true,
    };
    this.user = AuthService.getProfile().memberData;
    if (this.user) {
      this.address = this.user.addresses.find((ad) => ad.isprimary);
      this.facAddress = this.user.facility.address;
    }
  }

  componentWillMount() {
    if (this.props.loginpage === true) {
      this.props.UpdateLoginAgain();
    }
  }
  renderFacility() {
    if (this.facAddress) {
      return (
        <div className="card-body">
          <h5 className="text-primary font-18 mt-0 mb-4">
            {this.user.facility.description}
          </h5>
          <div className="row mb-2">
            <div className="col">
              <h6 className="card-subtitle mb-2 text-muted">Mailing Address</h6>

              <p className="card-text">
                {this.facAddress.address1}
                {this.facAddress.address2 && (
                  <span>
                    <br />
                    {this.facAddress.address2}
                  </span>
                )}
                <br />
                {this.facAddress.city}, {this.facAddress.state}{" "}
                {this.facAddress.zipcode}
              </p>

              <h6 className="card-subtitle mb-2 text-muted">Phone</h6>
              <p className="card-text">{this.user.facility.phonenumber}</p>
            </div>
            <div className="col">
              <h6 className="card-subtitle mb-2 text-muted">Level</h6>
              <p className="card-text">{this.user.facility.level}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6 className="card-subtitle mb-2 text-muted">Miscellaneous</h6>
              <p className="card-text">{this.user.facility.miscellaneous}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card-body">
          <h5 className="text-primary font-18 mt-0 mb-4">---</h5>
          <div className="row mb-2">
            <div className="col">
              <h6 className="card-subtitle mb-2 text-muted">Mailing Address</h6>
              <p className="card-text">---</p>
              <h6 className="card-subtitle mb-2 text-muted">Phone</h6>
              <p className="card-text">---</p>
            </div>
            <div className="col">
              <h6 className="card-subtitle mb-2 text-muted">Level</h6>
              <p className="card-text">---</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h6 className="card-subtitle mb-2 text-muted">Miscellaneous</h6>
              <p className="card-text">---</p>
            </div>
          </div>
        </div>
      );
    }
  }
  redenProfile() {
      var email =
      this.user &&
      this.user.emailAddresses.length > 0 &&
      this.user.emailAddresses.find((list) => list.isprimary) &&
      this.user.emailAddresses.find((list) => list.isprimary).email;
    if (this.user) {
      return (
        <div className="card-body">
          <h5 className="text-primary font-18 mt-0 mb-1">
            {/* {this.user.nickname} */}
            {/* {this.user.firstname + " " + this.user.middlename + " " + this.user.lastname} */}

            {this.user &&
              ` ${this.user.firstname !== null ? this.user.firstname : ""} ${
                this.user.nickname !== null
                  ? "''" + this.user.nickname + "''"
                  : ""
              } ${this.user.middlename !== null ? this.user.middlename : ""} ${
                this.user.lastname
              }`}
          </h5>
          <p className="font-12 mb-2">{this.user.positiontitleopm}</p>
          <p className="mb-4">{email}</p>
          <div className="clearfix" />
          <div className="row">
            <div className="col">
              <h6 className="card-subtitle mb-2 text-muted">Member Number</h6>
              <p className="card-text">{this.user.membernumber}</p>
              <h6 className="card-subtitle mb-2 text-muted">Mailing Address</h6>
              <p className="card-text">
                {this.address && (
                  <span>
                    {this.address.address1}
                    {this.address.address2 && (
                      <span>
                        <br />
                        {this.address.address2}
                      </span>
                    )}
                    <br />
                    {this.address.city}, {this.address.state}{" "}
                    {this.address.zipcode}
                  </span>
                )}
              </p>
              <h6 className="card-subtitle mb-2 text-muted">Phone</h6>
              <p className="card-text">
                {this.user.phones.map(function(ph, i) {
                  return (
                    <span key={i}>
                      {" "}
                      {ph.number}
                      <br />
                    </span>
                  );
                })}
              </p>
            </div>
            <div className="col">
              <div className="row mb-2">
                <div className="col">
                  <h6 className="card-subtitle mb-2 text-muted">Facility</h6>
                  <p className="card-text">{this.user.facility.code}</p>
                </div>
                <div className="col">
                  <h6 className="card-subtitle mb-2 text-muted">Region</h6>
                  <p className="card-text">NSO</p>
                </div>
                <div className="col">
                  <h6 className="card-subtitle mb-2 text-muted">Unit</h6>
                  <p className="card-text">{this.user.unit}</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h6 className="card-subtitle mb-2 text-muted">Senators</h6>
                  <p className="card-text">
                    Bill Nelson - D<br />
                    Marco Rubio -R
                    <br />
                  </p>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Representative
                  </h6>
                  <p className="card-text">Ted Yoho - R</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <AUX>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6" />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="card directory-card">
                <h5 className="card-header">
                  <i className="fas fa-user mr-2" />
                  Profile
                </h5>
                {this.redenProfile()}
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="card directory-card">
                <h5 className="card-header">
                  <i className="fas fa-building mr-2" />
                  Facility
                </h5>
                {this.renderFacility()}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Dashboard);
