import React, { Component, Fragment } from "react";
import { MDBInput } from "mdbreact";
import $ from "jquery";
import EmailListService from "../Email/EmailListService";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";

export class FacilityTeamEmailListNameModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EmailAlias: "",
      domain: "",
    };
  }

  componentDidMount() {
    this.setState({ EmailAlias: "" });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
        .replace(/[^\w\s\-]/gi, "")
        .replace(/\s+/g, "-"),
    });
  };
  onShow = async () => {
    $(".loading").show();
    await EmailListService.GetDomainName()
      .then(({ data: response }) => {
        if (response.status === "success") {
          this.setState({
            domain: response.domain,
          });
          console.log(this.state.domain);
          let domainName = response.domain;
          this.setState({ domain: domainName });
        }
        $(".loading").hide();
      })
      .catch((ex) => {
        console.log(ex);
        $(".loading").hide();
        toast.error("Something went wrong!");
      });
  };
  onModalExited = () => {
    this.props.toggleModal();
  };
  submitForm = () => {
    if (_.isEmpty(this.state.EmailAlias)) {
      toast.warn("Please Enter Email Alias");
    } else {
      this.onModalExited();
      this.props.createEmail(this.state.EmailAlias);
    }
  };
  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.onModalExited}
        size="lg"
        backdrop="static"
        dialogClassName="modal-20w"
        centered
        onShow={this.onShow}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5
              className="modal-title"
              id="FacilityTeamEmailListNameModalTitle"
            >
              {"Provide Email List Alias "}
            </h5>
          </Modal.Title>
        </Modal.Header>

        <div className="col-sm-12 col-sm-12 mt-4 mb-4">
          <div class="input-group mb-3">
            <input
              id="emailAlias"
              name="EmailAlias"
              type="text"
              class="form-control"
              placeholder="Email Alias"
              aria-label="Email Alias"
              aria-describedby="basic-addon2"
              value={this.state.EmailAlias}
              onChange={this.handleChange}
            />
            <span class="input-group-text" id="basic-addon2">
              {this.state.domain}
            </span>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={this.onModalExited}
            id="FTELNModalClose"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.submitForm}
          >
            Create Email List
          </button>
        </div>
      </Modal>
    );
  }
}

export default FacilityTeamEmailListNameModal;
