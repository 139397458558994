import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import RegistrationsAction from "../../store/registrations/action";

export class RegistrationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveTab: -1,
      page: "",
      subPage: "",
      secondSubPage: "",
    };
  }
  async componentDidMount() {
    let addresses = window.location.pathname.split("/");
    let page = addresses.length > 1 ? addresses[1] : "";
    let subPage = addresses.length > 2 ? addresses[2] : "";
    let secondSubPage = addresses.length > 3 ? addresses[3] : "";
    this.setState({
      page: page,
      subPage: subPage,
      secondSubPage: secondSubPage,
    });
    if (page == "Registrations") {
      this.setState({ ActiveTab: 1 });
      this.props.setUserHeaderActiveTab(1);
    } else if (page == "MyRegistrations") {
      this.setState({ ActiveTab: 2 });
      this.props.setUserHeaderActiveTab(2);
    }
  }
  routeLinkPage = (activePage) => {
    let page = "Registrations";
    if (activePage == 2) {
      page = "MyRegistrations";
    }

    this.props.setUserHeaderActiveTab(activePage);
    this.setState({ ActiveTab: activePage }, () => {
      this.props.history.push(`/${page}`);
    });
  };

  render() {
    let { page, subPage } = this.state;

    return (
      <>
        <nav className="navbar navbar-expand-sm equipmentHeader-navbar">
          <Fragment>
            {/* {AuthService.canView(PermissionFeature.FEAdmin) && ( */}
            <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 1
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="Registrations"
              onClick={() => this.routeLinkPage(1)}
            >
              <i className="fa fa-thumb-tack" aria-hidden="true"></i> Open
              Registrations
            </a>
            {/* )}
            {AuthService.canView(PermissionFeature.FETrackerRequest) && ( */}
            <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 2
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="MyRegistrations"
              onClick={() => this.routeLinkPage(2)}
            >
              <i className="fa fa-id-card" aria-hidden="true"></i> My Registrations
            </a>
            {/* )} */}
          </Fragment>
        </nav>

        {/* <Registrations /> */}

        {/* <MyRegistrations /> */}
      </>
    );
  }
}

export default withRouter(
  connect((state) => ({ registrationstate: state.registrationstate }), {
    ...RegistrationsAction,
  })(RegistrationHeader)
);
