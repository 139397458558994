import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class FLSACategory extends Component {
  onChange(item) {
    console.log(item.flsa);
    var flsaCategoryIndex = _.findIndex(
      this.props.filterState.filterData.flsaCategory,
      o => {
        return o.flsa === item.flsa;
      }
    );
    console.log(flsaCategoryIndex);
    item.checked = !this.props.filterState.filterData.flsaCategory[
      flsaCategoryIndex
    ].checked;
    this.props.filterState.filterData.flsaCategory[flsaCategoryIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData
    });
  }
  chechboxes(item, index) {
    return (
      <li class="chechbox-list_item" key={"fcliitem-" + index}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"fcitem-" + index}
        >
          <input
            type="checkbox"
            htmlFor={"fcitem-" + index}
            value={index}
            checked={item.checked}
            className="custom-control-input"
            id={"fcitem-" + index}
            name={"fcitem-" + index}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"fcitem-" + index}
          >
            {item.flsa}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>FLSA Category</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div class="filter-fields-scroll">
          <ul class="chechbox-list">
            {this.props.filterState.filterData.flsaCategory &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.flsaCategory.map(
                (item, index) => this.chechboxes(item, index)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect(state => ({ filterState: state.advancedFilter }), {
  ...actionFilter
})(FLSACategory);
