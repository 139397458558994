import React, { Component } from "react";
import _ from "lodash";
import FEServices from "../F&ERequests/FEService";
import moment from "moment";
import { DatePickerInput } from "rc-datepicker";
import "../../../assets/css/equipmentstyles.css";
import { Modal, Table } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import AUX from "../../../hoc/Aux_";
import { toast } from "react-toastify";
import PermissionFeature from "../../Permissions/PermissionFeature";
import AuthService from "../../Auth/AuthService";
import InventoryStatus from "../InventoryStatus";
import { MDBInput } from "mdbreact";
const initialState = {
  showAmount: false,
  disposeReason: null,
  issubmit: false,
  disposedAmount: "",
  equipmentid: 0,
  disposedDate: null, // moment(new Date()).format("MM/DD/YYYY"),
};
export default class FE_EquipmentDisposeModal extends Component {
  state = {
    ...initialState,
    disposeReasonOptions: [
      { key: 1, val: "Obsolete" },
      { key: 2, val: "Sold" },
      { key: 3, val: "Donated" },
      { key: 4, val: "Lost" },
    ],
  };
  onShow = async () => {
    let disposeEqId = this.props.disposeEqId;
    await this.setState({ equipmentid: disposeEqId });
    if (AuthService.canView(PermissionFeature.FEAccountingDisposedDate)) {
      this.setState({
        disposedDate: moment(new Date()).format("MM/DD/YYYY"),
      });
    }
  };
  handleClose = () => {
    this.setState(initialState);
    this.props.onClose();
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ issubmit: true });
    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      return false;
    }
    const formdata = {
      //Equipment Details
      id: this.state.equipmentid,
      DisposedReason: _.isEmpty(this.state.disposeReason)
        ? null
        : this.state.disposeReason.val,
      DisposedAmount: this.state.disposedAmount,
      DisposedDate: this.state.disposedDate,
    };

    this.disposeEquipment(formdata);
  };

  disposeEquipment = async (formdata) => {
    FEServices.showSpinner();
    let response = await FEServices.DisposeInventory(formdata);
    if (!_.isEmpty(response)) {
      toast.success("Equipment disposed successfully");
    }
    FEServices.hideSpinner();
    this.handleClose();
    var activeSections = [];
    activeSections.push(InventoryStatus.InAccReview);
    activeSections.push(InventoryStatus.NonAccReview);
    const formData = {
      recordsTypeList: activeSections,
      PageNo: 1,
      PageSize: 10,
      SortOrder: "id", //"firstnameAsc",
    };

    this.props.refreshInvList(formData);
  };
  render() {
    const isAllFieldsHidden = FEServices.isAllHidden(
      PermissionFeature.FEAccountingDisposedReason,
      PermissionFeature.FEAccountingDisposedDate,
      PermissionFeature.FEAccountingDisposedAmount
    );
    const isAllFieldsDisabled = FEServices.isAllDisabled(
      PermissionFeature.FEAccountingDisposedReason,
      PermissionFeature.FEAccountingDisposedDate,
      PermissionFeature.FEAccountingDisposedAmount
    );
    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="DisposeEquipmentModal"
          size="lg"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                Dispose Equipment{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!isAllFieldsHidden && (
              <>
                <form
                  className="needs-validation"
                  id="disposeEquipmentForm"
                  onSubmit={this.onSubmit}
                  noValidate
                >
                  <div className="row">
                    {AuthService.canView(
                      PermissionFeature.FEAccountingDisposedReason
                    ) && (
                      <div className="col-4">
                        <Autocomplete
                          id="equipment-disposeReason"
                          options={this.state.disposeReasonOptions}
                          getOptionLabel={(option) => option.val}
                          onChange={(e, val, reason) =>
                            FEServices.changeValueHandler(
                              e,
                              val,
                              reason,
                              "disposeReason",
                              this
                            )
                          }
                          disabled={
                            !AuthService.canUpdate(
                              PermissionFeature.FEAccountingDisposedReason
                            )
                          }
                          value={this.state.disposeReason}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Disposed Reason"
                              value={this.state.disposeReason}
                              variant="standard"
                              placeholder="Select Dispose Reason"
                              InputLabelProps={{
                                shrink: true,
                                classes: { shrink: "mt-n1" },
                              }}
                              //variant="standard"
                              required={true}
                              error={
                                this.state.issubmit &&
                                _.isEmpty(this.state.disposeReason)
                                  ? true
                                  : false
                              }
                              helperText={
                                this.state.issubmit &&
                                _.isEmpty(this.state.disposeReason)
                                  ? "Required"
                                  : ""
                              }
                            />
                          )}
                        />
                      </div>
                    )}
                    {AuthService.canView(
                      PermissionFeature.FEAccountingDisposedDate
                    ) && (
                      <div className="col-4 ">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>Disposed Date:</small>
                        </span>
                        <DatePickerInput
                          readOnly="readonly"
                          name="disposedDate"
                          disabled={
                            !AuthService.canUpdate(
                              PermissionFeature.FEAccountingDisposedDate
                            )
                          }
                          onChange={(val) =>
                            FEServices.onDateChange(val, this, "disposedDate")
                          }
                          value={this.state.disposedDate}
                        />
                      </div>
                    )}
                    {AuthService.canView(
                      PermissionFeature.FEAccountingDisposedAmount
                    ) &&
                      this.state.showAmount && (
                        <div className="col-4">
                          <MDBInput
                            containerClass="mb-0"
                            className="equipment-control"
                            name="disposedAmount"
                            size="sm"
                            label="Disposed Amount"
                            hint="Provide Disposed Amount"
                            icon="dollar-sign"
                            iconClass="position-absolute"
                            value={this.state.disposedAmount}
                            maxLength="16"
                            onChange={(e) =>
                              FEServices.handleChangeAmount(
                                e,
                                "disposedAmount",
                                this
                              )
                            }
                            disabled={
                              !AuthService.canUpdate(
                                PermissionFeature.FEAccountingDisposedAmount
                              )
                            }
                            group
                            type="text"
                            required
                          >
                            <div className="invalid-feedback text-center">
                              Required
                            </div>
                          </MDBInput>
                          <span className="text-danger">
                            {this.state.amount_err}
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="form-group mb-4 row">
                    <div className="col-12 m-t-20 mx-auto text-center">
                      {
                        <>
                          <button
                            className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                            data-dismiss="modal"
                            aria-hidden="true"
                            type="reset"
                            onClick={this.handleClose}
                          >
                            Cancel
                          </button>

                          {
                            <button
                              className="btn btn-success w-md mc"
                              type="submit"
                              disabled={isAllFieldsDisabled}
                            >
                              Submit
                            </button>
                          }
                        </>
                      }
                    </div>
                  </div>
                </form>
              </>
            )}

            {isAllFieldsHidden && (
              <>
                <div class="container h-100">
                  <div class="row NoDataFoundTable">
                    <h5>
                      You are not authorized to view this content. Please
                      contact administrator.
                    </h5>
                  </div>
                </div>
                <div className="form-group mb-4 row">
                  <div className="col-12 m-t-20 mx-auto text-center">
                    <button
                      className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc"
                      data-dismiss="modal"
                      aria-hidden="true"
                      type="reset"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
