import React, { Component } from "react";
import ActivityLoggingComponent from "../../Shared/ActivityLogging";
import actionActivity from "../../../store/activitylogging/action";
import AuditModuleTypes from "../../Shared/AuditModuleTypes";

import _ from "lodash";
import { Card, CardBody, Collapse } from "reactstrap";

export class TeamActivityLogging extends Component {
  state = {
    logRecordId: 0,
  };
  constructor(props) {
    super(props);
    //console.log("teams logs constructor");
    this.fetchLogs();
  }

  fetchLogsCardClick = () => {
    /// ActivityLogging
    var myself = this;
    // var { Id, isChild, parentId } = useParams;
    const Id = myself.props.paramId;
    var show =
      this.props.auditState && this.props.auditState.teamLoggingVisible;

    //const Id = this.props.paramId && this.props.paramId.Id;
    if (!_.isEmpty(Id)) {
      this.props.toggleTeamLogAccordion(
        !this.props.auditState.teamLoggingVisible
      );
      this.setState({ logRecordId: Id });
      !show &&
        this.props.getActicityLoggingByModuleId(
          AuditModuleTypes.Teams,
          Id,
          0,
          10
        );
    }
  };

  fetchLogs = () => {
    /// ActivityLogging
    var myself = this;
    // var { Id, isChild, parentId } = useParams;
    const Id = myself.props.paramId;

    //const Id = this.props.paramId && this.props.paramId.Id;
    if (!_.isEmpty(Id)) {
      this.props.toggleTeamLogAccordion(true);
      this.setState({ logRecordId: Id });

      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.Teams,
        Id,
        0,
        10
      );
    }
  };

  refreshLogs = (Id) => {
    if (!_.isEmpty(Id)) {
      this.props.toggleTeamLogAccordion(true);
      this.setState({ logRecordId: Id });
      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.Teams,
        Id,
        0,
        10
      );
    }
  };

  render() {
    var show =
      this.props.auditState && this.props.auditState.teamLoggingVisible;
    var IsLoading = this.props.auditState && this.props.auditState.IsLoading;
    return (
      <>
        {/* ActivityLogging Work */}
        <Card>
          {/* Card Header */}
          {!IsLoading && (
            <>
              <h5
                className="card-header cursorPointer"
                onClick={this.fetchLogsCardClick}
              >
                <i class="fa fa-history" aria-hidden="true"></i> Activity
                Logging
                <i
                  className={`pull-right float-right ${
                    show
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }`}
                ></i>
              </h5>
            </>
          )}
          {IsLoading && (
            <>
              <h5 className="card-header cursorPointer">
                <i class="fa fa-history" aria-hidden="true"></i> Loading....
                <i
                  className={`pull-right float-right ${
                    show
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }`}
                ></i>
              </h5>
            </>
          )}
          <Collapse
            className="memberCardCollapse"
            // style={{ transition: "height 2s ease" }}
            isOpen={show}
          >
            <CardBody className="pl-1 pr-2">
              <ActivityLoggingComponent recordId={this.props.paramId} />
            </CardBody>
          </Collapse>
        </Card>
        {/* ActivityLogging Work */}
      </>
    );
  }
}

export default TeamActivityLogging;
