import React, { Component } from "react";
import arrayMove from "array-move";
import $ from "jquery";
import _ from "lodash";
import axios from "./auth-header";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";

const array = [];
class MemberBucket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <Modal
        className="Register"
        show={this.props.showModal}
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title mt-0"> INFORMATION </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12">
            <div className="row">
              <div className="col-9"></div>
              <h5>
                Account not registered for this member number, want to register?
                <Link
                  to="register"
                  className="font-500 text-primary text-right"
                >
                  {" "}
                  Register Now{" "}
                </Link>{" "}
              </h5>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default MemberBucket;
