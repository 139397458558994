import { toast } from "react-toastify";
import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import _ from "lodash";

export default {
  GetPaymentIntent: async (formdata) => {
    let url = "/api/Payments/create-payment-intent";
    const { status, data } = await axios.post(url, formdata);

    if (status && status == 200) {
      return data;
    } else {
      const { error } = data;
      toast.error(error);
      return null;
    }
  },
  GetPaymentIntentV2: async (formdata) => {
    debugger;
    let url = "/api/Payments/create-payment-intent-v2";
    const { status, data } = await axios
      .post(url, formdata)
      .catch((reason) => {
        console.log(reason);
        return reason;
      })
      .finally(() => {});

    if (status && status == 200) {
      return data;
    } else {
      const { error } = data;
      toast.error(error.message);
      return null;
    }
  },
  CreateCheckoutSesssion: async (formdata) => {
    debugger;
    let url = "/api/Payments/create-checkout-session-v2";
    const { status, data } = await axios
      .post(url, formdata)
      .catch((reason) => {
        console.log(reason);
        return reason;
      })
      .finally(() => {});

    if (data && data.statusCode == 200) {
      return data;
    } else {
      const { error = {} } = data || {};
      if (!_.isEmpty(error)) {
        toast.error(error.message);
      }
      return null;
    }
  },
  GetPaymentIntentV3: async (formdata) => {
    debugger;
    let url = "/api/Payments/create-payment-intent-v3";
    const { status, data } = await axios
      .post(url, formdata)
      .catch((reason) => {
        console.log(reason);
        return reason;
      })
      .finally(() => {});

    if (status && status == 200) {
      return data;
    } else {
      const { error } = data;
      toast.error(error.message);
      return null;
    }
  },

  GetStripeSession: async (regisId) => {
    $(".loading").show();
    let url = `/api/Payments/GetSessionStatus?registration_Id=${regisId}`;
    var { data } = await axios.get(url);

    if (data.message == "Success") {
      $(".loading").hide();
      return data.result;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  HandleCheckoutSuccess: async (registration_Id, occasion_Id) => {
    $(".loading").show();
    let url = `/api/Payments/HandleCheckoutSuccess?registration_Id=${registration_Id}&occasion_Id=${occasion_Id}`;
    var { data } = await axios.get(url);

    if (data.message == "Success") {
      $(".loading").hide();
      return data.result;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
  GetAppliedDiscountDetails: async (occasion_Id, registrationId) => {
    $(".loading").show();
    let url = `/api/Payments/GetAppliedDiscountDetails?childTemplateId=${occasion_Id}&regisId=${registrationId}`;
    var { data } = await axios.get(url);
    if (data.message == "Success") {
      $(".loading").hide();
      return data.result;
    } else {
      toast.error(data.message);
      $(".loading").hide();
      return null;
    }
  },
};
