import axios from "../../modules/Shared/auth-header";

const actions = {
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  GetUserNotifications: (Id) => (dispatch) => {
    axios
      .post("/api/Notification/GetUserNotifications/", null, {
        params: {
          userid: Id == null ? 0 : Id,
        },
      })
      .then(({ data }) => {
        if (data && data.status.toLowerCase() == "success") {
          dispatch({
            type: actions.SET_NOTIFICATIONS,
            payload: data.response,
          });
        } else {
          console.log(data.status);
        }
      })
      .catch((error) => {
        console.log("error block", error);
      });
  },
  UpdateNotifications: (notification) => (dispatch) => {
    dispatch({
      type: actions.UPDATE_NOTIFICATIONS,
      payload: notification,
    });
  },
};

export default actions;
