import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
import _ from "lodash";

export default {
  UpdateReadStatus: (id) => {
    let url = "/api/Notification/UpdateReadStatus";
    var { data } = axios.post(url, null, {
      params: {
        notificationId: id == null ? 0 : id,
      },
    });

    return "";
    // if (data.status == "Success") {
    //   return data;
    // } else {
    //   toast.error("Invalid Request");
    //   return null;
    // }
  },
};
