import React, { Component } from "react";
import MailGunConfigurationTabs from "../../Email/AuthorizedSenders/MailGunConfigurationTabs";
import EmailListService from "../../Email/EmailListService";
import MailGunEmailTypes from "../../Email/Enums/MailGunEmailTypes";
import { connect } from "react-redux";
import mailGunEmailActions from "../../../store/mailgunEmails/action";
import { toast } from "react-toastify";
import _ from "lodash";
import MG_DeleteEmailList from "../../Email/ModalPopups/MG_DeleteEmailList";
import $ from "jquery";
import chistory from "../../Shared/history";
import { Spinner } from "reactstrap";
import EmailConfigTabs from "../../Email/Enums/EmailConfigTabs";

class FacilityEmailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderSelf: false,
      emailListExists: null,
    };
    this.confirmEmailList = () => {
      this.confirmed = async () => {
        let { emailListId } = this.state;
        await EmailListService.DeleteFRTEmailList(emailListId).then(
          ({ data: response }) => {
            $(".loading").hide();
            if (response.status === "success") {
              toast.success("Email list removed successfully!");
              this.setState({ emailListExists: false, emailListId: 0 });
              this.props.resetEmailConfigDetails();
              this.props.resetAddlMembers();
              this.props.setCurrentEmailConfigTab(EmailConfigTabs.Settings);

              $(".loading").hide();
            } else {
              toast.error(response.message);
              $(".loading").hide();
            }
          }
        );

        this.toggleDeleteEmailListModal();
      };

      return this;
    };
  }

  componentDidMount = () => {
    this.props.resetEmailConfigDetails();
    this.props.resetAddlMembers();
  };
  componentDidUpdate(prevProps) {
    const { loadFacilityEmail } = this.props;
    if (loadFacilityEmail !== prevProps.loadFacilityEmail) {
      if (loadFacilityEmail) {
        this.setState({ renderSelf: true });
        this.GetEmailListDetails();
      }
    }
  }
  GetEmailListDetails = async () => {
    const { facilityId } = this.props;
    const data = await EmailListService.getFRTEmailDetails(
      facilityId,
      MailGunEmailTypes.Facility
    );
    if (data != null) {
      this.props.isEmailListExists(true);
      this.setState({
        emailListExists: data.exists,
        mgListAddress: data.mgListAddress,
        emailListId: data.emailListId,
        mailingListName: data.mailingListName || "",
        replyPreference: data.reply_preference,
        selectedPrivilege: data.selected_email_privilege_id,
        listPrivilige: data.selectedPrivilege,
      });
      let formData = {
        emailListId: data.emailListId,
        emailEntityType: MailGunEmailTypes.Facility,
        entityKey: facilityId,
        mailingListAddress: data.mgListAddress,
        replyPreference: data.reply_preference,
        listPrivilige: data.reply_preference,
      };
      await this.props.setEmailListName(data.email_list_title);
      await this.props.setEmailListDetails(formData); // Could be setting name
      let fullAliasPlusDomain = data.mgListAddress;
      //  console.log("fullAliasPlusDomain", fullAliasPlusDomain);
      this.props.setMgListFullAddress(fullAliasPlusDomain);
      this.props.setEmailListName(data.email_list_title); // ***Definitely be setting name***
      await this.props.setMgListFullAddress(data.mgListAddress);
      //****Members Tabs****//
      this.props.reloadDefaultSubsribersTable();
      this.props.reloadAdditionalSubsribersTables();
      if (data.selectedPrivilege == "readonly") {
        this.props.getAuthSenders(formData);
      }
    } else {
      //Email list doesn't Exists or
      this.props.isEmailListExists(false);
      this.setState({ emailListId: 0, emailListExists: false });
      await EmailListService.GetDomainName()
        .then(async ({ data: response }) => {
          if (response.status === "success") {
            let domainName = response.domain;
            domainName = domainName.replace("@", "");
            await this.props.setMgListDomain(domainName);
            //await this.props.setMgPostListDomain(response.postDomain);
            const { facilityCode } = this.props;
            let fullAliasPlusDomain = facilityCode + response.domain;
            this.props.setMgListFullAddress(fullAliasPlusDomain);
            let mailingListName = facilityCode.replace(/[^\w\s-]/gi, "");
            await this.props.setEmailListName(mailingListName);
          }
        })
        .catch((ex) => {
          console.log(ex);
          toast.error("Something went wrong!");
        });
    }
    this.props.scrollAccordion();
  };
  createList = async () => {
    const { facilityId } = this.props;
    let {
      mgListAddressAlias,
      selectedPrivilige,
      mailingListName,
      replyPreference,
      authorizedSendersMembers,
      authorizedSendersTeams,
    } = this.props.melstate;
    let emailAuthorizedSenders = [
      ...authorizedSendersMembers,
      ...authorizedSendersTeams,
    ];
    let response = await EmailListService.createFacilityEmail({
      facilityId,
      emailAlias: mgListAddressAlias,
      entityType: MailGunEmailTypes.Facility,
      replyPreference,
      selectedPrivilige,
      mailingListName,
      emailAuthorizedSenders,
    });
    if (response != null) {
      this.setState({
        emailListExists: true,
        mgListAddress: response.mgListAddress,
        emailListId: response.emailListId,
      });
      let formData = {
        emailListId: response.emailListId,
        emailEntityType: MailGunEmailTypes.Facility,
        entityKey: facilityId,
        mailingListName,
        replyPreference,
        listPrivilige: selectedPrivilige,
      };
      ///debugger;
      this.props.setEmailListDetails(formData);
      this.props.reloadDefaultSubsribersTable();
    }
  };
  updateList = () => {
    this.props.saveEmailConfigData();
  };
  toggleDeleteEmailListModal = () => {
    this.setState({
      showDeleteEmailListModal: !this.state.showDeleteEmailListModal,
    });
  };
  goBackToFacilitiesList = () => {
    chistory.push("/directory/facility");
  };
  render() {
    const {
      mgListAddress,
      emailListExists,
      mailingListName,
      renderSelf,
    } = this.state;

    const { facilityId, isRVP = false } = this.props;
    return renderSelf ? (
      <>
        <MailGunConfigurationTabs
          mgListAddress={mgListAddress}
          ssUpdateEmailListMode={emailListExists ? true : false} //Show all Tabs, and Default will be Subscribers Tab
          isModalPopUpOpended={false} //Because it is on Facility Dashboard Accordion
          currentEmailConfigTab={this.state.currentEmailConfigTab}
          emailListTitle={mailingListName || ""}
          emailListExists={emailListExists}
          ref={this.mailgunConfigurationTabComp}
          entityKey={facilityId}
          emailEntityType={MailGunEmailTypes.Facility}
          hideActionIcons={EmailListService.shouldHideActionIcons() || isRVP}
          showTwoTabs={true}
          emailListId={this.state.emailListId}
        />
        <div className="modal-footer justify-content-center">
          <div className="row">
            {EmailListService.shouldHideActionIcons() ? (
              <div className="col-md-4 p-0">
                <button
                  className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                  type="submit"
                  onClick={() => {
                    emailListExists ? this.updateList() : this.createList();
                  }}
                >
                  {!emailListExists ? "Create List" : "Update List"}
                </button>
              </div>
            ) : (
              ///Invisible
              <div className="col-md-4 p-0">
                <button
                  className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light invisible"
                  type="button"
                >
                  Save Changes
                </button>
              </div>
            )}
            <div className="col-md-2 pr-0 pl-0">
              <button
                className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light"
                onClick={this.goBackToFacilitiesList}
              >
                Back
              </button>
            </div>
            {emailListExists &&
            ////EmailListService.shouldHideActionIcons() &&
            EmailListService.canDeleteEmailList() ? (
              <div className="col-md-6 pl-1 pr-1 d-flex justify-content-end">
                <button
                  className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                  type="button"
                  onClick={this.toggleDeleteEmailListModal}
                >
                  Delete Email List
                </button>
              </div>
            ) : (
              ///Invisible
              <div className="col-md-6 pl-1 pr-1 d-flex justify-content-end invisible">
                <button
                  className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                  type="button"
                >
                  Delete Email List Inv
                </button>
              </div>
            )}
          </div>
        </div>
        <MG_DeleteEmailList
          modal={this.state.showDeleteEmailListModal}
          confirm={this.confirmEmailList}
          toggle={this.toggleDeleteEmailListModal}
        />
      </>
    ) : (
      <div className="d-flex justify-content-center p-4">
        <Spinner />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    melstate: state.mailGunEmail,
  }),
  {
    ...mailGunEmailActions,
  }
)(FacilityEmailList);
