import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import $ from "jquery";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../modules/Shared/TableCustomTotal";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import jsPDF from "jspdf";
import DataUploadStatus from "./DataUploadStatus";
import axios from "../../modules/Shared/auth-header";

const MyExportCSV = (props) => {
  const handleClick = () => {
    //debugger;
    var recordType;
    if (props.reviewTableType == "error") {
      recordType = DataUploadStatus.Error;
    } else if (props.reviewTableType == "success") {
      recordType = DataUploadStatus.Update;
    } else if (props.reviewTableType == "skip") {
      recordType = DataUploadStatus.Skip;
    } else if (props.reviewTableType == "removed") {
      recordType = DataUploadStatus.Delete;
    }
    const formData = {
      fileId: props.fileId,
      recordtype: recordType,
      PageNo: 1,
      PageSize: props.totalSize,
      SortBy: "firstname",
      SortingOrder: "asc",
      OperatorType: "AND",
    };
    showSpinner();
    axios
      .post("/api/dataimport/GetPayFileOneReviewRecords/", formData)
      .then(({ data }) => {
        if (data && data.status.toLowerCase() == "success") {
          props.onExport(data.data);
          hideSpinner();
        } else {
          console.log(data.status);
        }
      })
      .catch((error) => {
        console.log("error block", error);
      });
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(
      props.currentTable,
      props.handleToggleSections,
      props.data,
      props
    );
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Members Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
const columns = [
  {
    text: "FIRST NAME",
    dataField: "firstname",
    sort: true,
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionName)) return cell;
    //   else return "";
    // },
  },
  {
    text: "LAST NAME",
    dataField: "lastname",
    sort: true,
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
    //     return cell;
    //   } else {
    //     return "";
    //   }
    // },
  },
  {
    text: "REGION",
    dataField: "regionName",
    sort: true,
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
    //     return cell;
    //   } else {
    //     return "";
    //   }
    // },
  },
  {
    text: "FACILITY",
    dataField: "facilityName",
    sort: true,
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
    //     return cell;
    //   } else {
    //     return "";
    //   }
    // },
  },
  {
    text: "FAA ID",
    dataField: "faaid",
    sort: true,
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
    //     return cell;
    //   } else {
    //     return "";
    //   }
    // },
  },
  {
    text: "MEMBER #",
    dataField: "memberno",
    sort: true,
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
    //     return cell;
    //   } else {
    //     return "";
    //   }
    // },
  },
  {
    text: "BUS Code",
    dataField: "bus",
    hidden: true,
    csvExport: false,
  },
  {
    text: "Org Code",
    dataField: "org_cd",
    hidden: true,
    csvExport: false,
  },
  {
    text: "Org Code Title",
    dataField: "org_title",
    hidden: true,
    csvExport: false,
  },
  {
    text: "facility Routing Symbol",
    dataField: "fac_routing_symbol",
    hidden: true,
    csvExport: false,
  },

  {
    text: "Position Title OPM",
    dataField: "psn_title_opm",
    hidden: true,
    csvExport: false,
  },
  {
    text: "Duty Station Code",
    dataField: "dty_stn_cd",
    hidden: true,
    csvExport: false,
  },
  {
    text: "isdeleted",
    dataField: "isdeleted",
    hidden: true,
    csvExport: false,
  },

  {
    text: "Date of Birth",
    dataField: "date_birth",
    hidden: true,
    //  csvExport: false,
  },

  {
    text: "SCD Date",
    dataField: "scd_date",
    hidden: true,
    //  csvExport: false,
  },
  {
    text: "Hourly Salary",
    dataField: "hourly_salary",
    hidden: true,
    //csvExport: false,
  },
  {
    text: "Yearly Salary",
    dataField: "yearly_salary",
    hidden: true,
    //csvExport: false,
  },
  {
    text: "Status",
    dataField: "member_status",
    hidden: true,
    //csvExport: false,
  },
];

const getExportFileName = (tblType) => {
  if (tblType === "error") {
    return `${Date.now()}_ImportErrors.csv`;
  }
  if (tblType === "skip") {
    return `${Date.now()}_IgnoredUpdates.csv`;
  }
  if (tblType === "success") {
    return `${Date.now()}_SuccessfullUpdates.csv`;
  }
  if (tblType === "removed") {
    return `${Date.now()}_RemovedUpdates.csv`;
  }
};

//(inner) addImage(imageData, format, x, y, width, height, alias, compression, rotation)

const _html2canvas = (
  tblCurrent,
  handleToggleSections,
  tableData,
  allprops
) => {
  let filename = "";
  if (`payfileOneCSV_${tblCurrent}_Records`.includes("error")) {
    filename = `${Date.now()}_errorsList.pdf`;
    ///handleToggleSections("error");
  }
  if (`payfileOneCSV_${tblCurrent}_Records`.includes("skip")) {
    filename = `${Date.now()}_ignoredMembers.pdf`;
    // handleToggleSections("skip");
  }
  if (`payfileOneCSV_${tblCurrent}_Records`.includes("success")) {
    filename = `${Date.now()}_successfullUpdates.pdf`;
    /// handleToggleSections("success");
  }
  if (`payfileOneCSV_${tblCurrent}_Records`.includes("remove")) {
    filename = `${Date.now()}_removedUpdates.pdf`;
    /// handleToggleSections("success");
  }
  /////Server side Export/////

  var recordType;
  if (allprops.reviewTableType == "error") {
    recordType = DataUploadStatus.Error;
  } else if (allprops.reviewTableType == "success") {
    recordType = DataUploadStatus.Update;
  } else if (allprops.reviewTableType == "skip") {
    recordType = DataUploadStatus.Skip;
  } else if (allprops.reviewTableType == "removed") {
    recordType = DataUploadStatus.Delete;
  }
  const formData = {
    fileId: allprops.fileId,
    recordtype: recordType,
    PageNo: 1,
    PageSize: allprops.totalSize,
    SortBy: "firstname",
    SortingOrder: "asc",
    OperatorType: "AND",
  };
  showSpinner();
  axios
    .post("/api/dataimport/GetPayFileOneReviewRecords/", formData)
    .then(({ data }) => {
      if (data && data.status.toLowerCase() == "success") {
        var tableBody = data.data.map((val) => {
          var listRow = [];

          listRow.push(val.firstname);
          listRow.push(val.lastname);
          listRow.push(val.regionName);
          listRow.push(val.facilityName);
          listRow.push(val.faaid);
          listRow.push(val.memberno);

          listRow.push(val.org_cd);
          //  listRow.push(val.org_title);
          listRow.push(val.date_birth);
          // listRow.push(val.scd_date);
          // listRow.push(val.grade);
          //   listRow.push(val.hourly_salary);
          //  listRow.push(val.yearly_salary);
          return listRow;
        });
        const doc = new jsPDF();
        //autoTable(doc, { html: $(`#${tblCurrent} .react-bootstrap-table`).get(0) });
        doc.autoTable({
          head: [
            [
              "FIRST NAME",
              "LAST NAME",
              "REGION",
              "FACILITY",
              "FAA ID",
              "MEMBER #",

              "ORG. CODE",
              //   "ORG. TITLE",
              "BIRTH DATE",

              //"SCD",
              //"GRADE",
              //"HOURLY SALARY",
              //"YEARLY SALARY",
            ],
          ],
          body: tableBody,
          tableWidth: "auto",
          styles: { cellPadding: 0.7, fontSize: 8 },
          columnStyles: {
            0: { cellWidth: 25 },
            1: { cellWidth: 25 },

            // etc
          },
          //styles: { overflow: "ellipsize", cellWidth: "auto" },
          // Override the default above for the text column
          //columnStyles: { text: { cellWidth: "wrap" }, columnWidth: "20" },
          // margin: { right: 5 },
        });
        doc.save(filename);
        hideSpinner();
      } else {
        console.log(data.status);
      }
    })
    .catch((error) => {
      console.log("error block", error);
    });

  ///////////////////////////////
};
const showSpinner = () => {
  $(".loading").show();
};

const hideSpinner = () => {
  $(".loading").hide();
};
const RemotePagination = (
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  showTotal,
  expandRow,
  dataSizeChange,
  loading,
  reviewTableType,
  handleToggleSections,
  fileId
) => (
  <div
    className={`ReviewScreen_${reviewTableType}_Tbl`}
    id={`payfileOneCSV_${reviewTableType}_Records`}
  >
    <ToolkitProvider
      keyField="id"
      data={data}
      columns={columns}
      exportCSV={{
        fileName: getExportFileName(reviewTableType),
        exportAll: false,
        onlyExportFiltered: false,
      }}
    >
      {(props) => (
        <>
          <span className="d-flex">
            {!_.isEmpty(data) && (
              <>
                <MyExportCSV
                  reviewTableType={reviewTableType}
                  fileId={fileId}
                  totalSize={totalSize}
                  {...props.csvProps}
                />
                <MyExportPDF
                  currentTable={`payfileOneCSV_${reviewTableType}_Records`}
                  handleToggleSections={handleToggleSections}
                  data={data}
                  fileId={fileId}
                  totalSize={totalSize}
                  reviewTableType={reviewTableType}
                />
              </>
            )}
          </span>
          <BootstrapTable
            hover
            remote
            loading={loading}
            keyField="id"
            pagination={paginationFactory({
              paginationTotalRenderer: TableCustomTotal,
              page,
              sizePerPage,
              totalSize,
              showTotal,
              sizePerPageList: [
                {
                  text: "10",
                  value: 10,
                },
                {
                  text: "25",
                  value: 25,
                },
                {
                  text: "30",
                  value: 30,
                },
                {
                  text: "50",
                  value: 50,
                },
                {
                  text: "All",
                  value: totalSize,
                },
              ],
            })}
            onDataSizeChange={dataSizeChange}
            expandRow={expandRow}
            onTableChange={onTableChange}
            //defaultSorted={defaultSorted}
            overlay={overlayFactory({
              spinner: true,
              styles: {
                overlay: (base) => ({
                  ...base,
                  background: "rgba(40, 45, 42, 0.19)",
                }),
              },
            })}
            noDataIndication={() => {
              return loading === false ? <NoDataIndication /> : <Empty />;
            }}
            {...props.baseProps}
          />
        </>
      )}
    </ToolkitProvider>
  </div>
);

const PayFilesMembersList = (props) => {
  const {
    onDataSizeChange,
    handleTableChange,
    listMembers,
    expandRow,
    page,
    sizePerPage,
    totalSize,
    loading,
    reviewTableType,
    handleToggleSections,
    fileId,
  } = props;
  // console.log("PayFilesMembersList component called.");
  return (
    <>
      {RemotePagination(
        listMembers,
        page,
        sizePerPage,
        handleTableChange,
        totalSize,
        true,
        expandRow,
        onDataSizeChange,
        loading,
        reviewTableType,
        handleToggleSections,
        fileId
      )}
    </>
  );
};

PayFilesMembersList.propTypes = {
  listMembers: PropTypes.object.isRequired,
};

PayFilesMembersList.defaultProps = {
  listMembers: [],
};

export default PayFilesMembersList;
