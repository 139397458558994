import React, { Component, useState } from "react";
import { connect } from "react-redux";
import actionFilter from "../../../../store/advancedfilter/action";
import _ from "lodash";
import AdvanceFilterModal from "../../../Shared/AdvanceFilterModal";
import * as Permissions from "../../../Permissions/PermissionValues";
import AuthService from "../../../Auth/AuthService";

class AdvancedFilterTab extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}
  onAdvancedChangeTable(data) {}
  onResetTable() {}

  dontAllowEditFilter = () => {
    let result = AuthService.canUpdate(Permissions.Saved_Search);
    return result;
  };
  render() {
    return (
      <AdvanceFilterModal
        {...this.props}
        showOnlyBody={true} // To Display Advanced Filters in Tab
        onChangeTable={this.onAdvancedChangeTable.bind(this)}
        onResetTable={this.onResetTable}
        routeFromSaveSearchDDL={false}
        advancedFilterOpenedInTab={true}
        dontAllowEditFilter={!this.dontAllowEditFilter()}
      />
    );
  }
}

export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    formValues: state.form,
    currentState: state,
    savedSearchState: state.saveSearch,
  }),
  { ...actionFilter }
)(AdvancedFilterTab);
