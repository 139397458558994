import actions from "./action";

const initialState = {
  moduletypeid: 0,
  activityLoggingRecords: [],
  IsLoading: false,
  fetch: 10,
  recordId: 0,
  hasMore: false,
  totalCount: 0,
  memberLoggingVisible: false,
  teamLoggingVisible: false,
  emailLoggingVisible: false,
  logChangeObject: [],
};

export default function activityLogging(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FACILITY_ACTIVITY_DATA:
      return {
        ...state,
        activityLoggingRecords: action.payload.result,
        totalCount: action.payload.totalCount,
        hasMore: action.payload.result.length < action.payload.totalCount,
      };
    case actions.SET_REQUEST_FETCH:
      return {
        ...state,
        fetch: state.fetch + 10,
      };

    case actions.IS_ACCORDION_LOADING:
      return {
        ...state,
        IsLoading: action.payload,
      };

    case actions.SET_LOG_MODULE_TYPE:
      return {
        ...state,
        moduletypeid: action.payload,
      };

    case actions.TOGGLE_MEMBER_LOG_CARD:
      return {
        ...state,
        memberLoggingVisible: action.payload,
      };

    case actions.TOGGLE_TEAM_LOG_CARD:
      return {
        ...state,
        teamLoggingVisible: action.payload,
      };
    case actions.TOGGLE_EMAIL_LOG_CARD:
      return {
        ...state,
        emailLoggingVisible: action.payload,
      };
    case actions.RESET_LOGS:
      return {
        ...state,
        activityLoggingRecords: [],
        memberLoggingVisible: false,
        teamLoggingVisible: false,
        totalCount: 0,
        hasMore: false,
        fetch: 10,
      };

    case actions.SHOW_ACTIVITYLOG_CHANGE:
      return {
        ...state,
        logChangeObject: JSON.parse(
          state.activityLoggingRecords.find((f) => f.id === action.payload)
            .changes
        ),
      };
    default:
      return state;
  }
}
