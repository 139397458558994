import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import registrationsAction from "../../../../store/registrations/action";
import QuestionsListTable from "./QuestionsListTable";
import RegistrationsService from "../../RegistrationsService";
import _ from "lodash";
import AddQuestionModal from "./ModalPopUps/AddQuestionModal";
import DeleteModal from "../../../../modules/Dashboard/FacilityDashboard/Modals/DeleteModal";
import Header from "../../AdminHeader";
export class QuestionsMainPage extends Component {
  state = {
    questions: [],
    editQuestion: {},
    isConfirmModalOpen: false,
    deleteId: 0,
    isloading: false,
  };
  async componentDidMount() {
    await this.reloadQuestions();
  }

  toggleConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  confirm = () => {
    this.confirmed = async () => {
      if (this.state.deleteId == 0) {
        return false;
      }
      var result = await RegistrationsService.DeleteQuestion(
        this.state.deleteId
      );

      if (result !== null) {
        this.setState({ deleteId: 0 });
        this.toggleConfirmModal();
        await this.reloadQuestions();
      }
    };
    return this;
  };

  reloadQuestions = async () => {
    this.setState({ isloading: true });
    let data = await RegistrationsService.GetSystemQuestions();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.questions = data.result != null ? data.result : [];
        await this.setState({
          questions: this.state.questions,
          isloading: false,
        });
      }
    }
  };
  onClose = async () => {
    this.props.toggleAddQuestionModal();
    this.setState({ editQuestion: {} });
  };
  viewQuestion = (row) => {
    let editQues = {
      id: row.id,
      label: row.label,
      questionType: row.questionType,
      isRequired: row.isRequired,
      questionOptions: row.questionOptions,
      hasOptions: row.hasOptions,
      isFeeAssociated: row.isFeeAssociated,
      fee: row.fee || 0,
    };
    this.setState({ editQuestion: editQues });
    this.setState({ isEditMode: true });
    this.props.toggleAddQuestionModal();
  };
  deleteQuestion = (row) => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
    this.setState({ deleteId: row.id });
  };
  openAddQuestionModal = () => {
    this.setState({ editQuestion: {} });
    this.setState({ isEditMode: false });
    this.props.toggleAddQuestionModal();
  };
  render() {
    return (
      <>
        <Header />

        <div className="fet-container container-fluid">
          <br />
          <button
            type="button"
            className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2 mt-4"
            onClick={this.openAddQuestionModal}
          >
            <i class="cursorPointer pr-1 fas fa-plus" />
            Add Question
          </button>
          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">Questions</h5>
            <div className="card-body pb-2 px-0 pt-0">
              <QuestionsListTable
                viewQuestion={this.viewQuestion}
                deleteQuestion={this.deleteQuestion}
                listData={this.state.questions}
                reloadQuestions={this.reloadQuestions}
                isloading={this.state.isloading}
              />
            </div>
          </div>
        </div>
        <AddQuestionModal
          showModal={this.props.registrationsState.toggleAddQuestionModal}
          onClose={this.onClose}
          editQuestion={this.state.editQuestion}
          isEditMode={this.state.isEditMode}
          toggleModal={this.props.toggleQuestionModal}
          refreshQuestions={this.reloadQuestions}
          isConfirmModalOpen={this.state.isConfirmModalOpen}
          toggleConfirmModal={this.toggleConfirmModal}
          confirmDeleteAction={this.confirm}
          reloadQuestions={this.reloadQuestions}
        />
        <DeleteModal
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
          confirm={this.confirm}
        />
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(QuestionsMainPage)
);
