import React, { Component } from "react";
import FEHeader from "../FEHeader";
import RequestsListTable from "./RequestsListTable";
import FETrackerActions from "../../../store/feTracker/action";
import _ from "lodash";
import { Collapse, Card } from "reactstrap";
import { connect } from "react-redux";
import FERequestsType from "../FERequestsType";
import { Tags } from "../AdvanceFilter/Tags";
import FE_List_Types from "../FE_List_Types";
import FEServices from "../F&ERequests/FEService";
import { withRouter } from "react-router-dom";
import FE_API_Actions from "../FE_API_Actions";
import { toast } from "react-toastify";

import { PdfDocument } from "../F&ERequests/ClosedRequestPDF";
import { SampleTest } from "../F&ERequests/SampleTest";
import { PDFViewer } from "@react-pdf/renderer";
import DeleteRequestModal from "./DeleteRequestModal";

import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

// const sampleData = {
//   id: 111,
//   user: 11112,
//   description: "Well",
//   type: 1,
//   requester: 12985,
//   authorizer: null,
//   budgetAuthorizer: "",
//   requestedamount: 25,
//   approvedamount: 24,
//   requestdate: "2021-09-22T00:00:00",
//   comments: "",
//   equipmentid: 48,
//   status: 5,
//   statusName: "Closed",
//   member: null,
//   equipmentTypes: null,
//   equipment: null,
//   firstname: null,
//   lastname: null,
//   middlename: null,
//   nickname: null,
//   previouslastname: null,
//   userName: "Willie Aaron",
//   typeName: "Computer",
//   regionName: "",
//   regionid: 0,
//   facilityName: "",
//   facilityid: 0,
//   teamid: null,
//   teamName: "",
//   budgetLineName: null,
//   budgetLineFullName:
//     "16-NNM Northwest Mountain Region-Jason Doss<br>52-Information Tech-Richard Santa<br>",
//   currentPositionMember: null,
//   category: 4,
//   categoryName: "National Office",
//   categoryFullName: "National Office-Old Stock Room",
//   frt: 6,
//   frtName: "Old Stock Room",
//   history: [
//     {
//       id: 257,
//       actionDate: "2021-09-22T13:13:31",
//       memberName: "Jason Doss",
//       comments: "",
//       actionMessage: "Requested",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//     {
//       id: 258,
//       actionDate: "2021-09-22T13:24:03",
//       memberName: 'Richard "Rich" Santa',
//       comments: "r santa is going to submit it to EVP",
//       actionMessage: "PendingEVP",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//     {
//       id: 259,
//       actionDate: "2021-09-22T13:27:44",
//       memberName: "Admin Natca",
//       comments: "admin is only approving 24 dollars",
//       actionMessage: "Approved",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//     {
//       id: 260,
//       actionDate: "2021-09-22T16:19:49",
//       memberName: "Jason Doss",
//       comments: "",
//       actionMessage: "Closed",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//   ],
//   febudgetlines: [
//     {
//       id: 17,
//       requestid: 111,
//       budgetid: 343,
//       allocatedpct: 50,
//       fullbudgetlinename: "16-NNM Northwest Mountain Region",
//       budgetauthorizer: "Jason Doss",
//       showAuthorizedPerson: false,
//     },
//     {
//       id: 18,
//       requestid: 111,
//       budgetid: 352,
//       allocatedpct: 50,
//       fullbudgetlinename: "52-Information Tech",
//       budgetauthorizer: "Richard Santa",
//       showAuthorizedPerson: true,
//     },
//   ],
//   requesterName: "Jason Doss",
//   isactive: false,
//   purchaser: 11112,
//   purchaserName: "Willie Aaron",
//   serialnumber: "srk566",
//   stickernumber: "",
//   warrantyinformation: "6 yrs",
//   year: 2009,
//   purchasedate: "2021-09-22T00:00:00",
//   cost: 12,
//   hasActiveEquipment: false,
//   equipmentStatus: "InAccountingReview",
//   showExclamation: false,
//   relatedAttachments: [],
// };
// const sampleDataTwo = {
//   id: 105,
//   user: 13264,
//   description: "DXC",
//   type: 2,
//   requester: 12985,
//   authorizer: null,
//   budgetAuthorizer: "",
//   requestedamount: 43,
//   approvedamount: 43,
//   requestdate: "2021-09-02T00:00:00",
//   comments: "",
//   equipmentid: 47,
//   status: 5,
//   statusName: "Closed",
//   member: null,
//   equipmentTypes: null,
//   equipment: null,
//   firstname: null,
//   lastname: null,
//   middlename: null,
//   nickname: null,
//   previouslastname: null,
//   userName: "Erik Aarness",
//   typeName: "Furniture",
//   regionName: "NEA",
//   regionid: 4,
//   facilityName: "",
//   facilityid: 0,
//   teamid: null,
//   teamName: "",
//   budgetLineName: null,
//   budgetLineFullName:
//     "Committee Mentee-racheal khan<br>National Program Management Organization Office PMO Representative-Brad Davidson<br>",
//   currentPositionMember: null,
//   category: 2,
//   categoryName: "Region",
//   categoryFullName: "Region-NEA",
//   frt: 4,
//   frtName: "NEA-Eastern",
//   history: [
//     {
//       id: 228,
//       actionDate: "2021-09-02T17:50:09",
//       memberName: "Jason Doss",
//       comments: "",
//       actionMessage: "Requested",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//     {
//       id: 235,
//       actionDate: "2021-09-14T16:29:32",
//       memberName: "racheal khan",
//       comments: "",
//       actionMessage: "PendingEVP",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//     {
//       id: 255,
//       actionDate: "2021-09-16T14:57:45",
//       memberName: "Admin Natca",
//       comments: "",
//       actionMessage: "Approved",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//     {
//       id: 256,
//       actionDate: "2021-09-20T16:45:23",
//       memberName: "Jason Doss",
//       comments: "",
//       actionMessage: "Closed",
//       reqamtchg: false,
//       reqamtchgmsg: null,
//     },
//   ],
//   febudgetlines: [
//     {
//       id: 5,
//       requestid: 105,
//       budgetid: 178,
//       allocatedpct: 3,
//       fullbudgetlinename: "Committee Mentee",
//       budgetauthorizer: "racheal khan",
//       showAuthorizedPerson: true,
//     },
//     {
//       id: 6,
//       requestid: 105,
//       budgetid: 193,
//       allocatedpct: 97,
//       fullbudgetlinename:
//         "National Program Management Organization Office PMO Representative",
//       budgetauthorizer: "Brad Davidson",
//       showAuthorizedPerson: true,
//     },
//   ],
//   requesterName: "Jason Doss",
//   isactive: false,
//   purchaser: 11112,
//   purchaserName: "Willie Aaron",
//   serialnumber: "",
//   stickernumber: "",
//   warrantyinformation: "",
//   year: null,
//   purchasedate: "2021-09-17T00:00:00",
//   cost: 34,
//   hasActiveEquipment: false,
//   equipmentStatus: "InAccountingReview",
//   showExclamation: false,
//   relatedAttachments: [
//     // "assets/FETrackerFiles\\47\\b4766acd-2d18-41f2-888a-b6c252010278_payment-receiptImages\\img-0.png",
//     // "assets/FETrackerFiles\\47\\a282a43e-1ed6-46fa-99b1-927abe84334e_Generate_Certificate_1627763257666Images\\img-0.png",
//     // "assets/FETrackerFiles\\47\\f1002ae3-0c08-4b59-8c19-9eef3c2e8ba9_D-Wise List of JO in PunjabImages\\img-0.png",
//     // "assets/FETrackerFiles\\47\\f1002ae3-0c08-4b59-8c19-9eef3c2e8ba9_D-Wise List of JO in PunjabImages\\img-1.png",
//     // "assets/FETrackerFiles\\47\\f1002ae3-0c08-4b59-8c19-9eef3c2e8ba9_D-Wise List of JO in PunjabImages\\img-2.png",
//     // "assets/FETrackerFiles\\47\\f1002ae3-0c08-4b59-8c19-9eef3c2e8ba9_D-Wise List of JO in PunjabImages\\img-3.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-0.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-1.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-2.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-3.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-4.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-5.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-6.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-7.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-8.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-9.png",
//     // "assets/FETrackerFiles\\47\\3cb0f253-5b1f-472e-947a-e3583b7be971_showSuccessPageImages\\img-10.png",
//     // "assets/FETrackerFiles\\47\\47851e01-f17c-469f-9ba7-958c8d790bb9_BA077B0FB3F7Images\\img-0.png",
//     // "assets/FETrackerFiles\\47\\47851e01-f17c-469f-9ba7-958c8d790bb9_BA077B0FB3F7Images\\img-1.png",
//     // "assets/FETrackerFiles\\47\\47851e01-f17c-469f-9ba7-958c8d790bb9_BA077B0FB3F7Images\\img-2.png",
//     // "assets/FETrackerFiles\\47\\2235e271-281c-4e45-9bde-8b1413d24f87_IMG_4786.JPG",
//     // "assets/FETrackerFiles\\47\\83f916ae-945f-4fbd-89a6-3d0d219d3e6e_242136212_588965045625706_4866530237199368783_n.jpg",
//   ],
// };
class FE_MyRequests extends Component {
  state = {
    ismyrequestslistopen: true,
    includeClosedTitle: "Show Closed",
    includeClosed: false,
    currentRequestId: 0,
    isNotificationCall: false,
  };

  callListTables = () => {
    // let isAFTagsSelected =
    //   this.props.festate.filterData &&
    //   this.props.festate.filterData.searchApplied &&
    //   this.props.festate.filterData.searchApplied.requests &&
    //   this.props.festate.filterData.searchApplied.requests.length >= 1;
    if (this.props.festate.isFERequestAdvanceSearchApplied) {
      // // We have to show the advance filter result against selected Tags/filters
      // const formData = {
      //   ...this.props.festate.filterData,
      //   recordsTypeList: [],
      //   PageNo: 1,
      //   PageSize: 10,
      //   SortOrder: "id",
      // };
      // this.props.getResultRowsForAdvanceFilter(formData);
    } else {
      const formData = {
        Keyword: "",
        isMyRequests: true,
        feRequestsTypeList: [FERequestsType.MyRequests],
        showClosed: false,
        PageNo: 1,
        PageSize: 10,
        SortOrder: "id", //"firstnameAsc",
      };
      this.props.searchFeRequestsRecords(formData);
    }
  };
  componentDidMount = async () => {
    this.callListTables();

    ///Incase notificaiton is clicked
    const { id } = this.props.match.params;

    if (!_.isEmpty(id)) {
      var notificationCall = this.props.location.state.notificationCall;
      if (notificationCall) {
        this.setState({ isNotificationCall: true });
        this.setState({ currentRequestId: id });
        this.showMyRequest(id);
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;

    if (this.state.isNotificationCall && this.state.currentRequestId != id) {
      this.showMyRequest(this.state.currentRequestId);
      this.setState({ currentRequestId: id });
    }
  }
  showMyRequest = async (id) => {
    let response = await FEServices.FetchRequestById(id);
    if (!_.isEmpty(response)) {
      let requestRow = response.data;
      this.props.openEquipmentRelatedRequest(requestRow);
    }
  };

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      //indexOf => -1 => string not found
      //indexOf =>  0 => string exists
      if (
        typeof e.target.className == "string" &&
        e.target.className.indexOf("fa-trash") < 0 &&
        e.target.className.indexOf("fa-archive") < 0 &&
        e.target.className.indexOf("pdfDoc") < 0
      ) {
        this.props.openMyRequestModal(row);
      }
    },
  };
  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    if (type == "pagination" && _.isEmpty(sortOrder) && _.isEmpty(sortField)) {
      sortField = "id";
      sortOrder = "asc";
    }
    /// Advance search applied
    if (this.props.festate.isFERequestAdvanceSearchApplied) {
      this.props.setRequestsTablePaginationInfo(
        page,
        sizePerPage,
        FERequestsType.MyRequests
      );
      const formData = {
        ...this.props.festate.filterData,
        //recordsTypeList: [],
        feRequestsTypeList: [FERequestsType.MyRequests],
        PageNo: page,
        PageSize: sizePerPage,
        SortBy: sortField,
        SortOrder: sortOrder,
        EventType: FE_API_Actions.TableChange,
      };
      this.props.getResultRowsForAdvanceFilter(formData);
    }
    //Normal Search
    else {
      this.props.setRequestsTablePaginationInfo(
        page,
        sizePerPage,
        FERequestsType.MyRequests
      );

      const formData = {
        isMyRequests: true,
        feRequestsTypeList: [FERequestsType.MyRequests],
        showClosed: this.props.festate.showHisMyRequests,
        PageNo: page,
        PageSize: sizePerPage,
        SortBy: sortField,
        SortOrder: sortOrder,
        EventType: FE_API_Actions.TableChange,
      };

      this.props.searchFeRequestsRecords(formData);
    }
  };

  toggleClosedRequests = () => {
    var title = this.state.includeClosedTitle;
    if (this.state.includeClosed) {
      title = "Show Closed";
      this.state.includeClosed = false;
      this.props.setFEShowHistory(false, FE_List_Types.MyRequests);
    } else {
      title = "Hide Closed";
      this.state.includeClosed = true;
      this.props.setFEShowHistory(true, FE_List_Types.MyRequests);
    }
    this.setState({
      includeClosed: this.state.includeClosed,
      includeClosedTitle: title,
    });

    const formData = {
      Keyword: "",
      isMyRequests: true,
      feRequestsTypeList: [FERequestsType.MyRequests],
      showClosed: this.state.includeClosed,
      PageNo: 1,
      PageSize: 10,

      SortOrder: "id", //"firstnameAsc",
    };

    this.props.searchFeRequestsRecords(formData);
  };
  removeMyRequest = async (row) => {
    const { id } = row;
    this.setState({ deleteRequestId: id, showDeleteRequestModal: true });
  };
  markRequestArchive = async (row) => {
    const { id } = row;
    let response = await FEServices.markRequestArchiveById(id);
    if (!_.isEmpty(response)) {
      toast.success(response.message);
      const formData = {
        Keyword: "",
        isMyRequests: true,
        feRequestsTypeList: [FERequestsType.MyRequests],
        showClosed: this.state.includeClosed,
        PageNo: 1,
        PageSize: 10,

        SortOrder: "id", //"firstnameAsc",
      };

      this.props.searchFeRequestsRecords(formData);
    } else {
      toast.error("Something went wrong");
    }
  };
  closeDeleteRequestModal = () => {
    this.setState({ showDeleteRequestModal: false });
  };

  shouldShowRequestTable = () => {
    var allColsVisible =
      AuthService.canView(PermissionFeature.FERequestUser) &&
      AuthService.canView(PermissionFeature.FERequestDescription) &&
      AuthService.canView(PermissionFeature.FERequestRequester) &&
      AuthService.canView(PermissionFeature.FERequestBudgetID) &&
      AuthService.canView(PermissionFeature.FERequestCategory) &&
      AuthService.canView(PermissionFeature.FERequestType) &&
      AuthService.canView(PermissionFeature.FERequestStatus);
    return allColsVisible;
  };
  render() {
    return (
      <>
        <FEHeader />

        <div className="fet-container container-fluid">
          <React.Fragment>
            <br />

            {/* <div className="row">
              <div className="col-12">
                <PDFViewer
                  style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    overflow: "hidden",
                  }}
                >
                  <PdfDocument data={sampleData} />
                </PDFViewer>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <PDFViewer
                  style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    overflow: "hidden",
                  }}
                >
                  <PdfDocument data={sampleDataTwo} />
                </PDFViewer>
              </div>
            </div> */}
            <div className="row feequip-tags">
              {this.props.festate.isFERequestAdvanceSearchApplied && (
                <Tags isRequest={true} RefreshResult={true} {...this.props} />
              )}
            </div>
            <div className="align-items-center d-flex">
              <div className="col-xs-8 col-sm-8">
                <h4 className="page-title-search feheaderTitle">
                  {/* My Requests List */}

                  <button
                    type="button"
                    className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2"
                    onClick={() => {
                      this.props.toggleFEAdvanceFilter(true);
                    }}
                  >
                    <i class="cursorPointer pr-1 fab fa-searchengin" />
                    Filter
                  </button>
                </h4>
              </div>
              <div className="col-xs-4 col-sm-4">
                <div className="cursorPointer hover text-right font-weight-bold small mt-2 textlink">
                  <a onClick={this.toggleClosedRequests}>
                    {this.state.includeClosedTitle}
                  </a>
                </div>
              </div>
            </div>
            <div className="row reviewParentDiv">
              <div className="col">
                <Card className="mb-1">
                  <h6
                    className="card-header bg-primary text-white mb-3 mt-0 cursorPointer"
                    onClick={() => {
                      this.setState(
                        {
                          ismyrequestslistopen: !this.state
                            .ismyrequestslistopen,
                        },
                        () => {
                          const formData = {
                            Keyword: "MyRequestsReload",
                            isMyRequests: true,
                            feRequestsTypeList: [FERequestsType.MyRequests],
                            showClosed: false,
                            PageNo: 1,
                            PageSize: 10,
                            SortOrder: "id", //"firstnameAsc",
                          };

                          if (
                            this.state.ismyrequestslistopen &&
                            this.state.includeClosedTitle == "Hide Closed"
                          ) {
                            this.setState({
                              includeClosed: false,
                              includeClosedTitle: "Show Closed",
                            });
                          }

                          this.state.ismyrequestslistopen &&
                            this.props.searchFeRequestsRecords(formData);
                        }
                      );
                    }}
                  >
                    {/* <i class="fas fa-layer-group"></i> */}
                    <i class="far fa-user pr-1" />
                    My Requests{" "}
                  </h6>

                  <Collapse isOpen={this.state.ismyrequestslistopen}>
                    <div className="col-12">
                      {this.shouldShowRequestTable() ? (
                        <RequestsListTable
                          listData={this.props.festate.myRequestsRows}
                          page={this.props.festate.erPageNo}
                          sizePerPage={this.props.festate.erSizePerPage}
                          handleTableChange={this.handleTableChange}
                          totalSize={
                            this.props.festate.totalMyEquipmentRequestsCount
                          }
                          loading={this.props.festate.isLoadingMyRequests}
                          key={FERequestsType.MyRequests}
                          requestType={FERequestsType.MyRequests}
                          viewRequest={this.props.openMyRequestModal}
                          isAdvanceSearchApplied={
                            this.props.festate.isFERequestAdvanceSearchApplied
                          }
                          searchCriteria={this.props.festate.filterData}
                          activeSubHeader={this.props.festate.activeSubHeader}
                          showClosed={this.props.festate.showHisMyRequests}
                          rowEvents={this.rowEvents}
                          removeEquipmentRequest={(row) =>
                            this.removeMyRequest(row)
                          }
                          markRequestArchive={this.markRequestArchive}
                        />
                      ) : (
                        "No Visible Columns"
                      )}
                    </div>
                  </Collapse>
                </Card>
                <DeleteRequestModal
                  id={"DeleteRequest"}
                  content={"Request"}
                  closeModal={this.closeDeleteRequestModal}
                  openModal={this.state.showDeleteRequestModal}
                  requestDelId={this.state.deleteRequestId}
                />
              </div>
            </div>
          </React.Fragment>
        </div>
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      festate: state.fetracker,
    }),
    {
      ...FETrackerActions,
    }
  )(FE_MyRequests)
);
