import React, { Component, Fragment } from "react";
import _ from "lodash";
import "../../../assets/css/TeamStyle.css";
import $ from "jquery";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Services from "../PermissionService";
import { debounce } from "throttle-debounce";
import DatePicker from "react-datepicker";
class assignMemberList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
    };
    this.assignMember = this.assignMember.bind(this);
    this.searchMember = this.searchMember.bind(this);
    this.searchDebounceMember = debounce(500, this.searchMember);
    this.saveCancelButton = this.saveCancelButton.bind(this);
  }
  componentWillReceiveProps() {}
  handleSelectMember = (e) => {
    let index = e.target.dataset.index;
    index = !index ? e.target.parentElement.dataset.index : index;
    let { Member, MemberObj } = this.props.position;
    let id = Member.MemberId;
    let newId = MemberObj.Data[index].id;
    let teamPosition = this.props.teamPosition;
    if (
      !(
        !_.isEmpty(teamPosition.members) &&
        teamPosition.members.findIndex((x) => x.memberID == newId) != -1
      )
    ) {
      Member.StartDate = null;
      Member.MemberId = newId; // id ? (id == newId ? null : newId) : newId;
      this.props.setAssignMember(Member, index);
    }
    //Member.ID = null;
  };
  handleChangStartDate = (date) => {
    let { Member } = this.props.position;
    if (moment(date).isValid()) {
      Member.StartDate = moment(date).format("MM/DD/YYYY");
    } else {
      Member.StartDate = date;
    }
    this.props.setMember(Member);
  };
  handleChangEndDate = (date) => {
    let { Member } = this.props.position;
    if (moment(date).isValid()) {
      Member.EndDate = moment(date).format("MM/DD/YYYY");
    } else {
      Member.EndDate = date;
    }

    this.props.setMember(Member);
  };
  searchMember = () => {
    let MemberDataObj = this.props.position.MemberObj;
    MemberDataObj.Offset = 0;
    MemberDataObj.Fetch = 10;
    MemberDataObj.SearchTerms = this.state.searchTerm;
    MemberDataObj.IsMore = true;
    MemberDataObj.FetchedFirst = true;
    this.props.setMemberObj(MemberDataObj);
  };
  assignMember = () => {
    let Member = this.props.position.Member;
    return (
      <Fragment>
        {!this.props.onlyDates && (
          <>
            <div className="row">
              <div className="col-12">
                <h4 className="text-primary font-weight-bold">Choose Member</h4>
              </div>
              <div className="col-lg-8 text-center">
                <form role="search" className="app-search mb-3">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control rounded border mb-1 py-2 w-100"
                      placeholder="Member Search.."
                      onChange={(e) => {
                        this.setState({ searchTerm: e.target.value });
                        this.searchDebounceMember();
                      }}
                    />
                    <button
                      type="submit"
                      className="c-pointer text-secondary bg-transparent border-0"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div id="memberList" className="col-lg-10">
              {this.loadMember()}
            </div>
          </>
        )}
        <div className="col-lg-10 px-0 row mx-0 mt-4">
          <div
            class={
              "md-form form-group mt-0 mb-2" +
              (!this.props.onlyDates ? " col" : " col-6")
            }
          >
            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
              Start Date:
            </span>
            <DatePicker
              dateForm="MM/DD/YYYY"
              className="text-dark"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              onChange={this.handleChangStartDate}
              selected={
                Member.StartDate
                  ? moment(Member.StartDate).toDate()
                  : moment(new Date()).toDate()
              }
              placeholderText={"MM/DD/YYYY"}
            />
          </div>
          <div
            class={
              "md-form form-group mt-0 mb-2" +
              (!this.props.onlyDates ? " col" : " col-6")
            }
          >
            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
              End Date:
            </span>
            <DatePicker
              dateForm="MM/DD/YYYY"
              className="text-dark"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              onChange={this.handleChangEndDate}
              selected={Member.EndDate ? moment(Member.EndDate).toDate() : null}
              placeholderText={"MM/DD/YYYY"}
            />
          </div>
        </div>
      </Fragment>
    );
  };
  loadMember = () => {
    let { MemberObj } = this.props.position;
    if (MemberObj.FetchedFirst) {
      MemberObj.FetchedFirst = false;
      this.loadMemberData();
    }
    const MemberData = MemberObj.Data;
    const teamPosition = this.props.teamPosition;
    const { Member } = this.props.position;
    return (
      <Fragment>
        <p className="text-black-50">
          <b>Hint:</b> <i>Name [Email] Region/Facility</i>
        </p>
        <div id="MemberlistScroll">
          <InfiniteScroll
            dataLength={MemberData.length}
            next={this.loadMemberData}
            hasMore={MemberObj.IsMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="MemberlistScroll"
          >
            {MemberData.map((item, index) => {
              return (
                <div
                  className={
                    "c-pointer px-4 border py-2 card-text w-100" +
                    (item.id === Member.MemberId
                      ? " active"
                      : !_.isEmpty(teamPosition.members) &&
                        teamPosition.members.findIndex(
                          (x) => x.memberID == item.id
                        ) != -1
                      ? " opacity-half"
                      : "")
                  }
                  onClick={this.handleSelectMember}
                  data-index={index}
                >
                  {`${item.lastName ? item.lastName + ", " : ""}${
                    item.firstName
                  } ${
                    item.nickName != null ? '"' + item.nickName + '" ' : ""
                  }`}{" "}
                  {item.email && <b>[{item.email}]</b>}
                  {" - "}
                  {item.region + "/" + item.facility}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </Fragment>
    );
  };

  loadMemberData = async () => {
    let { MemberObj } = this.props.position;
    let data = await Services.LoadMembers(
      MemberObj.SearchTerms,
      MemberObj.Offset,
      MemberObj.Fetch
    );
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        MemberObj.IsMore = data.result.length == MemberObj.Fetch;
        MemberObj.Data = data.result;
        MemberObj.Fetch =
          data.result.length == MemberObj.Fetch
            ? MemberObj.Fetch + 10
            : MemberObj.Fetch;

        this.props.setMemberObj(MemberObj);
      }
    }
  };
  saveCancelButton = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-4"></div>
          <div className="col-8">
            <div className="text-right">
              <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                onClick={this.props.cancelModal}
              >
                Cancel
              </button>
              <button
                onClick={this.props.saveModal}
                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <div>
        {this.assignMember()}
        {this.saveCancelButton()}
      </div>
    );
  }
}

export default assignMemberList;
