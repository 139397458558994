const AuditModuleTypes = Object.freeze({
  Member: 1,
  Facility: 2,
  Teams: 3,
  JobHistory: 4,
  Region: 5,
  FETracker: 6,
  Grievance: 8,
  PACTracker: 9,
  Officer: 10,
  MailGunList: 11,
});
export default AuditModuleTypes;
