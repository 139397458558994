import React from "react";
import clsx from "clsx";

import {
  Formik,
  Form as FormikForm,
  Field,
  ErrorMessage,
  useFormikContext,
  useField,
  useFormik,
} from "formik";
import TextError from "./TextError";
import { Switch as MaterialSwitch } from "@material-ui/core";
import { createUseStyles } from "react-jss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
export function Form(props) {
  return (
    <Formik {...props}>
      <FormikForm className="needs-validation" noValidate="">
        {props.children}
      </FormikForm>
    </Formik>
  );
}

const useStyles = createUseStyles({
  /* Customize the label (the container) */
  container: {
    display: "flex",
    position: "relative",
    paddingLeft: "24px",
    marginBottom: "12px",
    cursor: "pointer",
    fontSize: "14px",
    userSelect: "none",
    "& input": {
      /*  Hide the browser's default checkbox  */
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0,
      /* Show the checkmark when checked */
      "&:checked": {
        "& ~ $checkmark": {
          /* When the checkbox is checked, add a blue background */
          backgroundColor: "#2196F3",
          "&:after": {
            display: "block",
          },
        },
      },
    },
    // /* When the checkbox is checked and disabled, add a grey background */
    // "&:disabled:checked": {
    //   "& ~ $checkmark": {
    //     backgroundColor: "#ccc !important",
    //   },
    // },
    /* When the checkbox is disabled, add a grey background color */
    // "&:disabled": {
    //   "& ~ $checkmark": {
    //     backgroundColor: "#546470",
    //   },
    // },
    "& $checkmark": {
      "&:after": {
        left: "7px",
        top: "4px",
        width: "5px",
        height: "10px",
        border: "solid white",
        borderWidth: "0 3px 3px 0",
        transform: "rotate(45deg)",
      },
    },
    // /* When the checkbox is disabled, make it transparent */
    // "&:disabled": {
    //   "& input": {
    //     "& ~ $checkmark": {
    //       opacity: 0.5,
    //     },
    //   },
    // },
    /* On mouse-over, add a grey background color */
    "&:hover": {
      "& input": {
        "& ~ $checkmark": {
          backgroundColor: "#ccc",
        },
      },
    },
  },

  checkmark: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "19px",
    width: "19px",
    backgroundColor: "#eee",
    "&:after": {
      content: '""',
      position: "absolute",
      display: "none",
    },
  },
  // disabled: {
  //   checkmark: {
  //     backgroundColor: "#ccc !important",
  //   },
  // },
});
export function TextField(props) {
  const { name, label, placeholder, fee, ...rest } = props;
  return (
    <>
      <span className="hidden">{fee || "0"}</span>
      {label && (
        <label htmlFor={name} className="font-weight-bold">
          {label}
        </label>
      )}
      <Field
        className="form-control"
        type="text"
        name={name}
        id={name}
        placeholder={placeholder || ""}
        {...rest}
      />
      {/* <ErrorMessage
        name={name}
        render={(msg) => (
          <div
            style={{
              color: "#dc3545",
              fontSize: "80%",
              width: "100%",
              marginTop: "0.25rem",
            }}
          >
            {msg}
          </div>
        )}
      /> */}
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}
export function ToggleSwitchV2(props) {
  const { label, name, options, handleToggle, toggle, fee, ...rest } = props;
  return (
    <>
      <span className="hidden">{fee || "0"}</span>
      {label && (
        <label htmlFor={name} className="font-weight-bold">
          {label}
        </label>
      )}
      <br />
      <Field name={name}>
        {({ field }) => {
          return (
            <Field
              className="form-control"
              type="checkbox"
              name={name}
              id={name}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
              component={MaterialSwitch}
              {...rest}
            />
          );
        }}
      </Field>

      {/* <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
      />

      <ErrorMessage component={TextError} name={name} /> */}
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}
export function TextFieldV2(props) {
  const { label, name, ...rest } = props;
  delete rest.options;
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <br />
      <Field name={name}>
        {({ field }) => {
          return (
            <div key={name}>
              <input
                className="form-control"
                type="text"
                name={name}
                id={name}
                onBlur={(e) => {
                  props.fieldChanged(e, field);
                }}
                onChange={(e) => {
                  props.fieldChanged(e, field);
                }}
                {...rest}
              />
            </div>
          );
        }}
      </Field>

      {/* <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
      />

      <ErrorMessage component={TextError} name={name} /> */}
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}

export function ToggleSwitch(props) {
  const { label, name, options, ...rest } = props;
  let toggle = false;
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <br />
      <Field
        className="form-control"
        type="checkbox"
        name={name}
        id={name}
        component={MaterialSwitch}
        onChange={(e) => {
          debugger;
          if (toggle) {
            toggle = false;
          } else {
            toggle = true;
          }
          //field.onChange(e);
        }}
        //checked={toggle}
        value={toggle}
        {...rest}
      />
      {/* <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
      /> */}
      <ErrorMessage component={TextError} name={name} />

      {/* <ErrorMessage component={TextError} name={name} /> */}
    </>
  );
}
function ClearIcon(props) {
  return (
    <div className="clear-icon" onClick={props.onClick}>
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>
  );
}

export function SelectField(props) {
  const { name, label, options, disabled, fee, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <span className="hidden">{fee || "0"}</span>
      <div className="selectControl mb-2">
        {label && (
          <label htmlFor={name} className="font-weight-bold">
            {label}
          </label>
        )}
        <br />

        <Field
          as="select"
          id={name}
          className="custom-select"
          name={name}
          disabled={disabled}
          onChange={(e) => {
            if (e.target.value === "") {
              setFieldValue(name, "");
            } else {
              setFieldValue(name, e.target.value);
            }
          }}
        >
          <option value="" selected disabled>
            Choose...
          </option>
          {options.map((optn, index) => (
            <option
              key={optn.id}
              value={optn.id}
              label={optn.option || optn.value}
              disabled={disabled}
              {...rest}
            />
          ))}
        </Field>
        {!disabled && <ClearIcon onClick={() => setFieldValue(name, "")} />}
        {/* <ErrorMessage
          name={name}
          render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
        /> */}
        <ErrorMessage component={TextError} name={name} />
      </div>
    </>
  );
}

export function CheckBoxes(props) {
  const { label, name, options, ...rest } = props;
  return (
    <>
      {label && (
        <label htmlFor={name} className="font-weight-bold">
          {label}
        </label>
      )}
      <br />
      <Field name={name}>
        {(formik) => {
          const { field } = formik;
          return options.map((questionOption) => {
            return (
              <div key={questionOption.id}>
                <input
                  type="checkbox"
                  id={questionOption.option}
                  {...field}
                  {...rest}
                  value={questionOption.id}
                  checked={
                    field.value &&
                    field.value.some((val) => val == questionOption.id)
                  }
                />
                <label>{questionOption.option}</label>
              </div>
            );
          });
        }}
      </Field>
      {/* <ErrorMessage
        name={name}
        render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
      /> */}
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}
export function StyledCheckBoxes(props) {
  const classes = useStyles();

  const { label, name, options, disabled, fee, ...rest } = props;
  return (
    <>
      <span className="hidden">{fee || "0"}</span>

      {label && (
        <label
          htmlFor={name}
          className={clsx("font-weight-bold", { disStyleCheck: disabled })}
        >
          {label}
          <Field name={name}>
            {(formik) => {
              const { field } = formik;
              return options.map((questionOption) => {
                return (
                  <div key={questionOption.id}>
                    <label
                      className={clsx(classes.container, {
                        [classes.disabled]: disabled,
                      })}
                    >
                      {questionOption.option}
                      <input
                        type="checkbox"
                        id={questionOption.option}
                        {...field}
                        {...rest}
                        disabled={disabled}
                        value={questionOption.id}
                        checked={
                          field.value &&
                          field.value.some((val) => val == questionOption.id)
                        }
                      />
                      <span
                        className={clsx(classes.checkmark, {
                          [classes.disabled]: disabled,
                        })}
                      />
                    </label>
                  </div>
                );
              });
            }}
          </Field>
        </label>
      )}

      <ErrorMessage component={TextError} name={name} />
    </>
  );
}
export function RadioButtons(props) {
  const { label, name, options, ...rest } = props;
  return (
    <>
      <label htmlFor={name} className="font-weight-bold">
        {label}
      </label>
      <br />
      <Field name={name}>
        {({ field }) => {
          return options.map((questionOption) => {
            return (
              <div key={questionOption.id}>
                <input
                  type="radio"
                  id={questionOption.id}
                  {...field}
                  {...rest}
                  value={questionOption.id}
                  checked={field.value == questionOption.id}
                />
                <label htmlFor={questionOption.id}>
                  {questionOption.option}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </>
  );
}

export function StyledRadioButtons(props) {
  const { label, name, options, disabled, fee, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <span className="hidden">{fee || "0"}</span>
      <label htmlFor={name} className="font-weight-bold mr-2">
        {label}
      </label>
      <br />
      <Field name={name}>
        {({ field }) => {
          return options.map((questionOption) => {
            return (
              <div className="form-check" key={questionOption.id}>
                <input
                  type="radio"
                  className="form-check-input"
                  id={questionOption.id}
                  {...field}
                  {...rest}
                  value={questionOption.id}
                  checked={field.value == questionOption.id}
                />
                <label className="form-check-label" htmlFor={questionOption.id}>
                  {questionOption.option}
                </label>
              </div>
            );
          });
        }}
      </Field>
      {!disabled && (
        <i
          className="fas fa-times radioBtn-clear-icon"
          onClick={() => setFieldValue(name, "")}
        />
      )}

      <ErrorMessage component={TextError} name={name} />
    </>
  );
}
export function Textarea(props) {
  const { label, name, ...rest } = props;
  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>
      <Field as="textarea" id={name} name={name} {...rest} />
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export function SubmitButton(props) {
  const { title, ...rest } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <button type="submit" {...rest} disabled={isSubmitting}>
      {title}
    </button>
  );
}
export function CurrenyInput(props) {
  const { name, label, type, placeholder, ...rest } = props;
  return (
    <>
      {label && (
        <label htmlFor={name} className="font-weight-bold">
          {label}
        </label>
      )}
      <div className="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text  pt-1 pb-1" id="inputGroup-sizing-sm">
            $
          </span>
        </div>
        <Field
          className="form-control"
          type={type}
          name={name}
          id={name}
          aria-describedby="inputGroup-sizing-sm"
          placeholder={placeholder || ""}
          {...rest}
        />
      </div>
      <ErrorMessage component={TextError} name={name} />

      {/* <ErrorMessage
        name={name}
        render={(msg) => (
          <div
            style={{
              color: "#dc3545",
              fontSize: "80%",
              width: "100%",
              marginTop: "0.25rem",
            }}
          >
            {msg}
          </div>
        )}
      /> */}
    </>
  );
}
