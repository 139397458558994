import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import Services from "./EditUserService";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import Region from "../../../AdvanceSearch/MemberDetails/Region";

const filter = createFilterOptions();
const supervisor = [
  {
        text: "NO",

    value: "NO",
  },
  {
    text: "YES",
    value: "YES",
  },
  {
    text: "TEMP",
    value: "TEMP",
  },
];

class JobHistoryFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      Supervisor: "",
      RegionCode: "",
      FacilityCode: "",
      OrganizationDD: [],
      RegionDD: [],
      FacilityDD: [],
      FacilityFilterDD: [],
      BusToDD: [],
      DutyStationDD: [],
      FacilityRoutingDD: [],
      JobDetail: null,
    };
    this.handleChangeBreak = this.handleChangeBreak.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getJobHistoryDropDown = this.getJobHistoryDropDown.bind(this);
    this.changeHandlerIsSupervisor = this.changeHandlerIsSupervisor.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  UpdateDataSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageUpdateData: sizePerPage });
  };
  getJobHistoryDropDown = async () => {
    let data = await Services.JobHistoryDropdown();
    if (data) {
      this.setState({
        OrganizationDD: data.organizationDD,
        BusToDD: data.busToDD,
        DutyStationDD: data.dutyStationDD,
        FacilityRoutingDD: data.facilityRoutingDD,
        RegionDD: data.regionDD,
        FacilityDD: data.facilityDD,
      });
    }
  };
  changeHandlerIsSupervisor = (e) => {
    this.state.JobDetail.isEdited = true;
    switch (e.target.value) {
      case "YES":
        this.state.JobDetail.IsSupervisor = true;
        this.state.JobDetail.IsTempSupervisor = false;
        break;
      case "TEMP":
        this.state.JobDetail.IsTempSupervisor = true;
        this.state.JobDetail.IsSupervisor = false;
        break;
      default:
        this.state.JobDetail.IsSupervisor = false;
        this.state.JobDetail.IsTempSupervisor = false;
        break;
    }
    this.setState({
      JobDetail: this.state.JobDetail,
      Supervisor_Val: e.target.val,
    });
  };
  requiredValidation(val, name, state) {
    let error = "";
    if (!val || val == null || (val && val.trim() == "")) {
      error = "Please provide " + name + ".";
    }
    this.state.JobDetail[state] = error;
    this.setState({ JobDetail: this.state.JobDetail });
    return error;
  }
  dateValidate(date, name, state) {
    let error = "Please provide " + name + ".";
    if (moment(date).isValid()) {
      error = "";
    }
    this.state.JobDetail[state] = error;
    this.setState({ JobDetail: this.state.JobDetail });
    return error;
  }
  handleChangeBreak = (e) => {
    this.state.JobDetail.isBreak = e.target.checked;
    this.setState({ JobDetail: this.state.JobDetail });
  };
  async submitForm() {
    let obj = this.state.JobDetail;
    if (
      //((!obj.isBreak &&
      //  this.requiredValidation(
      //    obj.regionCode,
      //    "Region Code",
      //    "regionCode_Err"
      //  ) == "" &&
      //  this.requiredValidation(
      //    obj.facilityCode,
      //    "Facility Code",
      //    "facilityCode_Err"
      //  ) == "") ||
      //  obj.isBreak) &&
      //this.requiredValidation(
      //    obj.organizationCode,
      //    "Organization Code",
      //    "organizationCode_Err"
      //) == "" &&
      //this.requiredValidation(obj.busTo, "Bus Code", "busTo_Err") == "" &&
      //this.requiredValidation(
      //    obj.routingSymbolTo,
      //    "Routing Symbol",
      //    "routingSymbolTo_Err"
      //) == "" &&
      //this.requiredValidation(
      //    obj.dutyStationCode,
      //    "Duty Station",
      //    "dutyStationCode_Err"
      //) == "" &&
      this.dateValidate(obj.startDate, "Start Date", "startDate_Err") == "" //&&
      //((obj.isBreak &&
      //  this.requiredValidation(obj.endDate, "End Date", "endDate_Err") ==
      //    "") ||
      //  !obj.isBreak)
    ) {
      $(".loading").show();
      let data = await Services.JobUpdate(obj, this.props.memberId);
      if (data != null && data.statusCode == 200) {
        this.closeModal();

        await this.props.loadData();
        await this.props.getJobs();

        this.getJobHistoryDropDown();
      }
      $(".loading").hide();
    }
  }
  componentDidMount() {
    let thisObj = this;
    setTimeout(function() {
      thisObj.getJobHistoryDropDown();
    }, 1000);
  }
  componentDidUpdate(prevprops) {
    if (this.props.openJobHistoryFormModal) {
      this.openModal();

      if (this.props.JobDetail.isSuperVisor) {
        this.state.Supervisor = "YES";
      } else if (this.props.JobDetail.isTempSuperVisor) {
        this.state.Supervisor = "TEMP";
      } else {
        this.state.Supervisor = "NO";
      }
      if (
        this.props.JobDetail.regionCode != "" &&
        !_.isEmpty(this.state.FacilityDD)
      ) {
        let index = this.state.RegionDD.findIndex(
          (x) => x.code == this.props.JobDetail.regionCode
        );
        this.state.FacilityFilterDD = this.state.FacilityDD.filter(
          (x) => x.regionid == this.state.RegionDD[index].id
        );
        this.setState({ FacilityFilterDD: this.state.FacilityFilterDD });
      }
      this.setState({
        JobDetail: this.props.JobDetail,
        Supervisor: this.state.Supervisor,
      });
    }
  }
  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeJobHistoryFormModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
  }
  render() {
    let { JobDetail } = this.state;
    return (
      <Modal
        centered={true}
        autoFocus={true}
        isOpen={this.state.openModal}
        className="errorignoremodel styled-modal"
        backdrop="static"
        id="JobHistoryForm"
      >
        <ModalHeader
          className="modal-header bg-secondary py-2"
          toggle={this.closeModal}
        >
          {this.props.heading} Job Detail
        </ModalHeader>
        <ModalBody>
          {this.state.JobDetail && (
            <div className="row">
              <div class="col-12 pl-4 ml-1">
                <div className="custom-checkbox">
                  <input
                    id={"IsBreak"}
                    name={"IsBreak"}
                    type="checkbox"
                    checked={this.state.JobDetail.isBreak}
                    onChange={this.handleChangeBreak}
                    className="custom-control-input pr-2"
                  />
                  <label
                    className="h6 custom-control-label"
                    for={"IsBreak"}
                    style={{ paddingTop: 2 }}
                  >
                    Outside NATCA Bargaining Unit
                  </label>
                </div>
              </div>
              <div className="col-12">
                <div className="modalinput">
                  <div className="row mt-2 mb-2">
                    <div className="col-4">
                      <label className="ml-0">Start Date</label>
                      <DatePicker
                        dateForm="MM/DD/YYYY"
                        peekNextMonth
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(date) => {
                          this.state.JobDetail.isEdited = true;
                          this.dateValidate(
                            date,
                            "Start Date",
                            "startDate_Err"
                          );
                          if (date == null) {
                            this.state.JobDetail.startDate = null;
                          } else if (moment(date).isValid()) {
                            this.state.JobDetail.startDate = moment(
                              date
                            ).format("YYYY-MM-DD");
                          }
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        selected={
                          JobDetail.startDate
                            ? moment(JobDetail.startDate).toDate()
                            : null
                        }
                        placeholderText={"MM/DD/YYYY"}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.startDate_Err}
                      </span>
                    </div>

                    <div className="col-4">
                      <label className="ml-0">End Date</label>
                      <DatePicker
                        dateForm="MM/DD/YYYY"
                        peekNextMonth
                        className="form-control"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(date) => {
                          this.state.JobDetail.isEdited = true;

                          if (this.state.JobDetail.isBreak) {
                            this.dateValidate(date, "End Date", "endDate_Err");
                          } else {
                            this.state.JobDetail.endDate_error = "";
                          }

                          if (date == null) {
                            this.state.JobDetail.endDate = null;
                          } else if (moment(date).isValid()) {
                            this.state.JobDetail.endDate = moment(date).format(
                              "YYYY-MM-DD"
                            );
                          }
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        selected={
                          JobDetail.endDate
                            ? moment(JobDetail.endDate).toDate()
                            : null
                        }
                        placeholderText={"MM/DD/YYYY"}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.endDate_Err}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="modalinput">
                  <div className="row mt-2 mb-2">
                    <div className="col-4">
                      <label className="ml-0">Region</label>
                      <select
                        //className="browser-default custom-select"
                        onChange={(e) => {
                          this.state.JobDetail.isEdited = true;
                          this.state.JobDetail.regionCode = e.target.value;
                          this.state.JobDetail.facilityCode = "";
                          let index = this.state.RegionDD.findIndex(
                            (x) => x.code == e.target.value
                          );
                          if (index > -1) {
                            this.state.FacilityFilterDD = this.state.FacilityDD.filter(
                              (x) => x.regionid == this.state.RegionDD[index].id
                            );
                          } else {
                            this.state.FacilityFilterDD = [];
                          }

                          //if (!this.state.JobDetail.isBreak) {
                          //  this.requiredValidation(
                          //    e.target.value,
                          //    "Region Code",
                          //    "regionCode_Err"
                          //  );
                          //} else {
                            this.state.JobDetail.regionCode_error = "";
                          //}

                          this.setState({
                            JobDetail: this.state.JobDetail,
                            FacilityFilterDD: this.state.FacilityFilterDD,
                          });
                        }}
                        name="status"
                        className="form-control"
                      >
                        <option
                          value=""
                          selected={this.state.JobDetail.regionCode == ""}
                        >
                          Select Region Code
                        </option>
                        {this.state.RegionDD.map((item, index) => {
                          return (
                            <option
                              value={item.code}
                              selected={
                                this.state.JobDetail.regionCode == item.code
                              }
                            >
                              {item.code}
                            </option>
                          );
                        })}
                      </select>
                      {/*<TextField
                        placeholder="Region Code"
                        value={JobDetail.regionCode}
                        variant="standard"
                        fullWidth
                        onChange={(e) => {
                          this.state.JobDetail.isEdited = true;
                          this.state.JobDetail.regionCode = e.target.value;
                          this.requiredValidation(
                            e.target.value,
                            "Region Code",
                            "regionCode_Err"
                          );
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                      />*/}
                      <span className="text-danger">
                        {this.state.JobDetail.regionCode_Err}
                      </span>
                    </div>
                    <div className="col-4">
                      <label className="ml-0">Facility</label>
                      <select
                        //className="browser-default custom-select"
                        onChange={(e) => {
                          this.state.JobDetail.facilityCode = e.target.value;
                          this.state.JobDetail.isEdited = true;
                          //if (!this.state.JobDetail.isBreak) {
                          //  this.requiredValidation(
                          //    e.target.value,
                          //    "Facility Code",
                          //    "facilityCode_Err"
                          //  );
                          //} else {
                            this.state.JobDetail.facilityCode_error = "";
                          //}
                          /*set Facility route to*/
                          if (!_.isEmpty(this.state.FacilityRoutingDD)) {
                            let st = this.state;
                            this.state.FacilityRoutingDD.some(function(el) {
                              if (
                                e.target.value == el.facilityroutingsymbolto
                              ) {
                                st.JobDetail.routingSymbolTo =
                                  el.facilityroutingsymbolto;
                                return true;
                              }
                            });
                          }

                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        name="status"
                        className="form-control"
                      >
                        <option
                          value=""
                          selected={this.state.JobDetail.facilityCode == ""}
                        >
                          Select Facility
                        </option>
                        {this.state.FacilityFilterDD.map((item, index) => {
                          return (
                            <option
                              value={item.code}
                              selected={
                                this.state.JobDetail.facilityCode == item.code
                              }
                            >
                              {item.code}
                            </option>
                          );
                        })}
                      </select>

                      <span className="text-danger">
                        {this.state.JobDetail.facilityCode_Err}
                      </span>
                    </div>

                    <div className="col-4">
                      <label className="ml-0">Unit:</label>

                      <Autocomplete
                        options={
                          !_.isEmpty(this.state.BusToDD)
                            ? this.state.BusToDD.map((o) => o.busto)
                            : []
                        }
                        autoComplete={true}
                        autoHighlight={true}
                        selectOnFocus
                        clearOnBlur
                        value={JobDetail.busTo}
                        onChange={(e, value) => {
                          let valueV = "";
                          if (value && value.inputValue) {
                            this.state.JobDetail.busTo = value.inputValue;
                            valueV = value.inputValue;
                          } else {
                            this.state.JobDetail.busTo = value;
                            valueV = value;
                          }
                          //this.requiredValidation(
                          //    valueV,
                          //    "Bus Code",
                          //    "busTo_Err"
                          //);

                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        getOptionLabel={(option) => {
                          if (option && option.inputValue) {
                            return option.inputValue;
                          }
                          if (option != undefined) {
                            return option;
                          }

                          // Regular option
                          return "";
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (
                            params.inputValue !== "" &&
                            (_.isEmpty(filtered) ||
                              filtered.findIndex(
                                (x) =>
                                  x.toUpperCase() ==
                                  params.inputValue.toUpperCase()
                              ) == -1)
                          ) {
                            filtered.push({
                              busTo: `Add "${params.inputValue}"`,
                              inputValue: params.inputValue,
                            });
                          }

                          return filtered;
                        }}
                        renderOption={(option) => {
                          if (option && option.busTo) {
                            return option.busTo;
                          }
                          if (option != undefined) {
                            return option;
                          }
                          // Regular option
                          return "";
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Bargaining Unit"
                            variant="standard"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.busTo_Err}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="modalinput">
                  <div className="row mt-2 mb-2">
                    <div className="col-4">
                      <label className="ml-0">Org Code</label>
                      <Autocomplete
                        //  style={{ width: 300 }}
                        options={
                          !_.isEmpty(this.state.OrganizationDD)
                            ? this.state.OrganizationDD.map((o) => o.code)
                            : []
                        }
                        //classes={{option: classes.option}}

                        autoComplete={true}
                        //autoSelect={true}
                        autoHighlight={true}
                        selectOnFocus
                        clearOnBlur
                        value={JobDetail.organizationCode}
                        onChange={(e, value) => {
                          let valueV = "";
                          if (value && value.inputValue) {
                            this.state.JobDetail.organizationCode =
                              value.inputValue;
                            valueV = value.inputValue;
                          } else {
                            this.state.JobDetail.organizationCode = value;
                            valueV = value;
                          }

                          //this.requiredValidation(
                          //    valueV,
                          //    "Organization Code",
                          //    "organizationCode_Err"
                          //);

                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        getOptionLabel={(option) => {
                          if (option && option.inputValue) {
                            return option.inputValue;
                          }
                          if (option != undefined) {
                            return option;
                          }

                          // Regular option
                          return "";
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (
                            params.inputValue !== "" &&
                            (_.isEmpty(filtered) ||
                              filtered.findIndex(
                                (x) =>
                                  x.toUpperCase() ==
                                  params.inputValue.toUpperCase()
                              ) == -1)
                          ) {
                            filtered.push({
                              organizationCode: `Add "${params.inputValue}"`,
                              inputValue: params.inputValue,
                            });
                          }

                          return filtered;
                        }}
                        renderOption={(option) => {
                          if (option && option.organizationCode) {
                            return option.organizationCode;
                          }
                          if (option != undefined) {
                            return option;
                          }
                          // Regular option
                          return "";
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Organization Code"
                            variant="standard"
                            className="form-control"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,

                              className: "form-control",
                              autoComplete: "disabled", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.organizationCode_Err}
                      </span>
                    </div>
                    <div className="col-4">
                      <label className="ml-0">Routing Symbol</label>

                      <Autocomplete
                        //  style={{ width: 300 }}
                        options={
                          !_.isEmpty(this.state.FacilityRoutingDD)
                            ? this.state.FacilityRoutingDD.map(
                                (o) => o.facilityroutingsymbolto
                              )
                            : []
                        }
                        autoComplete={true}
                        selectOnFocus
                        clearOnBlur
                        autoHighlight={true}
                        value={JobDetail.routingSymbolTo}
                        onChange={(e, value) => {
                          let valueV = "";
                          if (value && value.inputValue) {
                            this.state.JobDetail.routingSymbolTo =
                              value.inputValue;
                            valueV = value.inputValue;
                          } else {
                            this.state.JobDetail.routingSymbolTo = value;
                            valueV = value;
                          }

                          //this.requiredValidation(
                          //    valueV,
                          //    "Routing Symbol",
                          //    "routingSymbolTo_Err"
                          //);
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        getOptionLabel={(option) => {
                          if (option && option.inputValue) {
                            return option.inputValue;
                          }
                          if (option != undefined) {
                            return option;
                          }

                          // Regular option
                          return "";
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (
                            params.inputValue !== "" &&
                            (_.isEmpty(filtered) ||
                              filtered.findIndex(
                                (x) =>
                                  x.toUpperCase() ==
                                  params.inputValue.toUpperCase()
                              ) == -1)
                          ) {
                            filtered.push({
                              routingSymbolTo: `Add "${params.inputValue}"`,
                              inputValue: params.inputValue,
                            });
                          }

                          return filtered;
                        }}
                        renderOption={(option) => {
                          if (option && option.routingSymbolTo) {
                            return option.routingSymbolTo;
                          }
                          if (option != undefined) {
                            return option;
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Routing Symbol"
                            variant="standard"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.routingSymbolTo_Err}
                      </span>
                    </div>
                    <div className="col-4">
                      <label className="ml-0">Duty Station</label>

                      <Autocomplete
                        options={
                          !_.isEmpty(this.state.DutyStationDD)
                            ? this.state.DutyStationDD
                            : []
                        }
                        autoComplete={true}
                        selectOnFocus
                        clearOnBlur
                        autoHighlight={true}
                        onChange={(e, value) => {
                          let valueV = "";
                          if (value && value.inputValue) {
                            valueV = value.dutystationcode;
                            this.state.JobDetail.dutyStationCode =
                              value.inputValue;
                            this.state.JobDetail.dutyStationDescription =
                              value.inputValue2;
                          } else if (value && value.dutystationcode) {
                            valueV = value.dutystationcode;
                            this.state.JobDetail.dutyStationCode =
                              value.dutystationcode;
                            this.state.JobDetail.dutyStationDescription =
                              value.dutystationdescription;
                          } else {
                            valueV = value;
                            this.state.JobDetail.dutyStationCode = "";
                            this.state.JobDetail.dutyStationDescription = "";
                          }
                          //this.requiredValidation(
                          //    valueV,
                          //    "Duty Station",
                          //    "dutyStation_Err"
                          //);
                          this.setState({ JobDetail: this.state.JobDetail });
                        }}
                        value={{
                          dutystationcode: JobDetail.dutyStationCode,
                          dutystationdescription:
                            JobDetail.dutyStationDescription,
                        }}
                        getOptionLabel={(option) => {
                          if (option != undefined) {
                            return option.dutystationcode
                              ? option.dutystationcode +
                                  (option.dutystationdescription
                                    ? " - " + option.dutystationdescription
                                    : "")
                              : "";
                          } else {
                            return "";
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (
                            params.inputValue !== "" &&
                            (_.isEmpty(filtered) ||
                              filtered.findIndex(
                                (x) =>
                                  x.dutystationcode.toUpperCase() ==
                                  params.inputValue.toUpperCase()
                              ) == -1)
                          ) {
                            let vals = params.inputValue.split("-");
                            filtered.push({
                              dutystationcode: `Add "${vals[0].trim()}"`,
                              dutystationdescription:
                                vals.length > 1 ? `"${vals[1].trim()}"` : "",
                              inputValue: vals[0].trim(),
                              inputValue2:
                                vals.length > 1 ? vals[1].trim() : "",
                            });
                          }

                          return filtered;
                        }}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.dutystationcode +
                              " - " +
                              option.dutystationdescription}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Duty Station"
                            variant="standard"
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">
                        {this.state.JobDetail.dutyStation_Err}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="modalinput">
                  <div className="row mt-2 mb-2">
                    <div className="col-4">
                      <label className="ml-0">Supervisor</label>
                      <select
                        //className="browser-default custom-select"
                        onChange={this.changeHandlerIsSupervisor}
                        name="status"
                        className="form-control"
                      >
                        {supervisor.map((item, index) => {
                          return (
                            <option
                              value={item.value}
                              selected={this.state.Supervisor == item.value}
                            >
                              {item.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div class="text-right">
            <Button
              className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
              onClick={this.closeModal}
            >
              Cancel
            </Button>

            <button
              class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
              type="submit"
              onClick={this.submitForm}
            >
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
export default JobHistoryFormModal;
