import React, { Component } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "../../../Shared/auth-header";
import InputMask from "react-input-mask";
import {
  CardBody,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { MDBInput } from "mdbreact";

export default class MemberPhoneModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: "",
      phonetypeid: "",
    };
  }

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleFormSubmit = () => {
    const body = {
      number: this.state.number,
      phonetypeid: this.state.phonetypeid,
    };

    let Url = "/api/Member/CreatePhone?memberid=" + this.props.memberid;
    this.showSpinner();
    axios.post(Url, body).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);
          // this.props.refreshData(this.props.memberid);
          // this.handleClose();
        } else {
          this.hideSpinner();
          toast.error(data.message);
          //   this.handleClose();
        }
      } else {
        toast.error("Could not complete the opertaion.");
      }
    });
  };
  render() {
    ///debugger;
    return (
      <div>
        <Modal isOpen={this.props.isModalOpen} toggle={this.props.toggle}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.modalLabel}
          </ModalHeader>
          <form
            className="form-horizontal m-t-40"
            onSubmit={this.props.handleFormSubmitAddresses}
            noValidate
          >
            <ModalBody>
              {this.props.phones &&
                this.props.phones.map((item, idx) => {
                  return (
                    <div key={"phoneedit-" + idx} data-repeater-list="group-a">
                      <div data-repeater-item className="row mb-4">
                        <div className="col-11 row">
                          <div className="col-sm-8">
                            <div data-id={idx}>
                              <div class="md-form form-group mb-0">
                                <InputMask
                                  value={item.number}
                                  //onChange={handleChangePhone}
                                  name="number"
                                  // disabled={
                                  //   !AuthService.canUpdate(
                                  //     PermissionFeature.ContactPhones
                                  //   )
                                  // }
                                  mask="(999) 999-9999"
                                  data-obj="phones"
                                  className="form-control"
                                  type="text"
                                  id={"phone" + idx}
                                  placeholder=""
                                />
                                <span class="text-danger">
                                  {item.number_err}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div data-id={idx} className="align-self-center">
                              <label>Phone Type</label>
                              <select
                                className="browser-default custom-select"
                                //onChange={handleSelectChangePhoneType}
                                name="phonetypeid"
                                //data-id={idx}
                                data-obj="phones"
                                //   disabled={
                                //     !AuthService.canUpdate(
                                //       PermissionFeature.ContactPhones
                                //     )
                                //   }
                              >
                                {this.props.phoneTypes.map((item1, idx) => (
                                  <option
                                    value={item1.id}
                                    selected={item1.phonetype == item.phonetype}
                                  >
                                    {item1.phonetype}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </ModalBody>
            <ModalFooter>
              <div className="row">
                <div className="col-12">
                  <div className="text-centered">
                    <button
                      className="btn btn-danger w-md waves-effect waves-light mr-2 mc m-1 m-sm-3"
                      type="button"
                      id="phonePopupCloseBtn"
                      data-dismiss="modal"
                      aria-label="Close"
                      //onClick={handleResetProfilePhones}
                    >
                      Cancel
                    </button>

                    <button
                      //onClick={handleFormSubmitPhone}
                      className="btn btn-success w-md mc m-1 m-sm-3 waves-effect waves-light"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
