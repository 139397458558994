import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
import * as SavedSearchTypes from "../../modules/AdvanceSearch/SavedSearches/SavedSearchTypes";
const actions = {
  //Define ActionNameConsts
  SavedSearch: "Saved_Search",
  BucketSearch: "Bucket_Search",
  SharedSearch: "Shared_Search",
  ResetSaveSearchName: "RESET_SAVE_SEARCH_NAME",
  setSavedSearchDetail: "setSavedSearchDetail",
  LoadSavedSearchData: "LoadSavedSearchData",
  setSavedSearchCriteria: "setSavedSearchCriteria",
  stopSavedSearchCriteria: "stopSavedSearchCriteria",
  REST_TABLE: "RESET_TABLE",
  SET_ICON_EVENT: "SET_ICON_EVENT",
  UNSET_ICON_EVENT: "UNSET_ICON_EVENT",
  RESET_SAVED_SEARCH: "RESET_SAVED_SEARCH",
  STOP_RESET_SAVED_SEARCH: "STOP_RESET_SAVED_SEARCH",
  STOP_RESET_SAVED_SEARCH_COMP: "STOP_RESET_SAVED_SEARCH_COMP",
  UPDATE_AND_SELECT: "UPDATE_AND_SELECT",
  UPDATE_AND_REFRESH: "UPDATE_AND_REFRESH",
  IS_SAVED_SEARCH_SELECTED: "IS_SAVED_SEARCH_SELECTED",
  SET_SHARED_SEARCH_TYPE: "SET_SHARED_SEARCH_TYPE",
  SET_SHARED_BUCKET_SEARCH_ID: "SET_SHARED_BUCKET_SEARCH_ID",
  SET_IS_SAVED_BUCKET: "SET_IS_SAVED_BUCKET",
  SET_SAVED_BUCKET_ID: "SET_SAVED_BUCKET_ID",
  SET_CLICK_FILTER_DDL: "SET_CLICK_FILTER_DDL",
  SET_MEMBERS_FILTERS: "SET_MEMBERS_FILTERS",
  SET_ALL_SYS_FILTERS: "SET_ALL_SYS_FILTERS",
  OPEN_EDIT_FILTER_MODAL: "OPEN_EDIT_FILTER_MODAL",
  SHOW_MANAG_SUB_MODAL: "SHOW_MANAG_SUB_MODAL",
  IS_AD_OPENDED_IN_TAB: "IS_AD_OPENDED_IN_TAB",
  LOAD_ALL_FILTERS: "LOAD_ALL_FILTERS",
  SET_FILTER_NAME: "SET_FILTER_NAME",
  SET_IS_FILTERS_LOADED: "SET_IS_FILTERS_LOADED",
  SET_ALLOW_FILTER_MEM_TAB_UPDATE: "SET_ALLOW_FILTER_MEM_TAB_UPDATE",
  //ActionNameConsts END

  ///Define Action Methods
  setAllowFilterUpdateMembersTab: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_ALLOW_FILTER_MEM_TAB_UPDATE,
      payload: val,
    });
  },
  setSharedBucketSearchId: (bucketId) => (dispatch) => {
    dispatch({
      type: actions.SET_SHARED_BUCKET_SEARCH_ID,
      payload: bucketId,
    });
  },
  setSharedSearchType: (typeId) => (dispatch) => {
    dispatch({
      type: actions.SET_SHARED_SEARCH_TYPE,
      payload: typeId,
    });
  },
  isSavedSearchSelected: (isSavedSearchSeleceted) => (dispatch) => {
    dispatch({
      type: actions.IS_SAVED_SEARCH_SELECTED,
      payload: isSavedSearchSeleceted,
    });
  },
  onResetTable: () => (dispatch) => {
    var data = { totalCount: 0, page: 1, sizePerPage: 25 };
    dispatch({
      type: actions.REST_TABLE,
      payload: data,
    });
  },
  resetSavedSearch: () => (dispatch) => {
    dispatch({
      type: actions.RESET_SAVED_SEARCH,
    });
  },
  stopResetSavedSearch: () => (dispatch) => {
    dispatch({
      type: actions.STOP_RESET_SAVED_SEARCH,
    });
  },
  stopResetSavedSearchComp: () => (dispatch) => {
    dispatch({
      type: actions.STOP_RESET_SAVED_SEARCH_COMP,
    });
  },

  updateAndSelect: (id, state) => async (dispatch) => {
    await dispatch({
      type: actions.UPDATE_AND_SELECT,
      payload: { id, state },
    });
  },

  update: (state) => (dispatch) => {
    dispatch({
      type: actions.UPDATE_AND_REFRESH,
      payload: { state },
    });
  },
  ResetSavedSearchName: () => (dispatch) => {
    dispatch({
      type: actions.ResetSaveSearchName,
      payload: "",
    });
  },

  setSavedSearchDetail: (Detail) => (disptach) => {
    disptach({
      type: actions.setSavedSearchDetail,
      payload: Detail,
    });
  },

  setIconEvent: (Event) => (disptach) => {
    disptach({
      type: actions.SET_ICON_EVENT,
      payload: Event,
    });
  },
  unSetIconEvent: () => (disptach) => {
    disptach({
      type: actions.UNSET_ICON_EVENT,
    });
  },

  setSavedSearchCriteria: (obj) => (dispatch) => {
    dispatch({
      type: actions.setSavedSearchCriteria,
      payload: obj,
    });
  },
  stopSavedSearchCriteria: () => (dispatch) => {
    dispatch({
      type: actions.stopSavedSearchCriteria,
    });
  },

  isSavedBucket: (isSavedBucketSeleceted) => (dispatch) => {
    dispatch({
      type: actions.SET_IS_SAVED_BUCKET,
      payload: isSavedBucketSeleceted,
    });
  },

  setSavedBucketId: (id) => (dispatch) => {
    dispatch({
      type: actions.SET_SAVED_BUCKET_ID,
      payload: id,
    });
  },

  // ********************10007 Work Initiated***************
  setMemberFilters: (filtersList) => (dispatch) => {
    dispatch({
      type: actions.SET_MEMBERS_FILTERS,
      payload: filtersList,
    });
  },
  setAllSystemFilters: (filtersList) => (dispatch) => {
    dispatch({
      type: actions.SET_ALL_SYS_FILTERS,
      payload: filtersList,
    });
  },
  setfilterActionClick: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_CLICK_FILTER_DDL,
      payload: val,
    });
  },
  toggleEditFilterModal: (row, toggleModal) => (dispatch) => {
    dispatch({
      type: actions.OPEN_EDIT_FILTER_MODAL,
      payload: { toggleModal, row },
    });
  },
  toggleMangSubscribersModal: (row, toggleModal) => (dispatch) => {
    dispatch({
      type: actions.SHOW_MANAG_SUB_MODAL,
      payload: { toggleModal, row },
    });
  },
  setAllFilterLoading: (val) => async (dispatch) => {
    await dispatch({
      type: actions.LOAD_ALL_FILTERS,
      payload: val,
    });
  },
  setIsAdvancedFilterOpenedInTab: (val) => (dispatch) => {
    dispatch({
      type: actions.IS_AD_OPENDED_IN_TAB,
      payload: val,
    });
  },
  changeFilterName: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FILTER_NAME,
      payload: val,
    });
  },
  setIsFiltersLoaded: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_IS_FILTERS_LOADED,
      payload: val,
    });
  },
};

export default actions;
