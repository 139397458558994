import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/advancedfilter/action";
import $ from "jquery";
export class SelectedFilter extends Component {
  toggleTagsDisplay = () => {
    $(".setTagsPadding").toggle("fast");
  };

  toggleMobileTagsDisplay = () => {
    $(".bottomBorderMobile").toggle("fast");
  };
  getTotalFitlerCount = () => {
    let count = 0;
    const searchApplied = this.props.filterState.filterData.searchApplied;
    searchApplied.forEach(function(val, index) {
      count += val.selectedItems.length;
    });
    return count;
  };
  render() {
    return (
      <>
        <div
          onClick={
            this.props.isMobile === true
              ? this.toggleMobileTagsDisplay
              : this.toggleTagsDisplay
          }
          className={`filterSelected border-right-0 border-bottom-0`}
          style={
            this.props.isMobile == true
              ? { marginBottom: "1vh", borderTop: 0 }
              : { justifyContent: "center" }
          }
        >
          {/* <nav className="navbar navbar-light bg-lignt">
            <span className="navbar-toggler-icon my-toggler"></span>
          </nav> */}
          <span className="mt-2 pr-1" style={{ marginLeft: "inherit" }}>
            {" "}
            {!_.isEmpty(this.props.filterState.filterData) &&
            !_.isEmpty(this.props.filterState.filterData.searchApplied)
              ? `(${this.getTotalFitlerCount()})`
              : 0}{" "}
            Filter(s) Selected&nbsp;
            {!this.props.isMobile && (
              <i
                class="fa fa-angle-double-down"
                style={{ fontSize: "small" }}
              ></i>
            )}
            {this.props.isMobile && (
              <i
                class="fa fa-angle-double-up"
                style={{ fontSize: "small" }}
              ></i>
            )}
          </span>
        </div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(SelectedFilter);
