import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import RegistrationsAction from "../../store/registrations/action";

export class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveTab: -1,
      page: "",
      subPage: "",
      secondSubPage: "",
    };
  }
  async componentDidMount() {
    let addresses = window.location.pathname.split("/");
    let page = addresses.length > 1 ? addresses[1] : "";
    let subPage = addresses.length > 2 ? addresses[2] : "";
    let secondSubPage = addresses.length > 3 ? addresses[3] : "";
    this.setState({
      page: page,
      subPage: subPage,
      secondSubPage: secondSubPage,
    });
    if (subPage == "registrations") {
      this.setState({ ActiveTab: 1 });
      this.props.setAdminHeaderActiveTab(1);
    } else if (subPage == "templates") {
      this.setState({ ActiveTab: 2 });
      this.props.setAdminHeaderActiveTab(2);
    } else if (subPage == "requests") {
      this.setState({ ActiveTab: 3 });
      this.props.setAdminHeaderActiveTab(3);
    } else if (subPage == "attendance") {
      this.setState({ ActiveTab: 4 });
      this.props.setAdminHeaderActiveTab(4);
    } else if (subPage == "questions") {
      this.setState({ ActiveTab: 5 });
      this.props.setAdminHeaderActiveTab(5);
    } else if (subPage == "discounts") {
      this.setState({ ActiveTab: 6 });
      this.props.setAdminHeaderActiveTab(6);
    }
  }
  routeLinkPage = (activePage) => {
    let page = "admin";
    let subPage = "registrations";

    if (activePage == 2) {
      subPage = "templates";
    }
    if (activePage == 3) {
      subPage = "requests";
    }
    if (activePage == 4) {
      subPage = "attendance";
    }
    if (activePage == 5) {
      subPage = "questions";
    }
    if (activePage == 6) {
      subPage = "discounts";
    }
    this.props.setAdminHeaderActiveTab(activePage);
    this.setState({ ActiveTab: activePage }, () => {
      this.props.history.push(`/${page}/${subPage}`);
    });
  };

  render() {
    let { page, subPage } = this.state;

    return (
      <>
        <nav className="navbar navbar-expand-sm equipmentHeader-navbar">
          <Fragment>
            {/* <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 1
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="Registrations"
              onClick={() => this.routeLinkPage(1)}
            >
              <i className="fas fa-hand-holding-usd"></i> Registrations
            </a> */}
            <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 2
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="Templates"
              onClick={() => this.routeLinkPage(2)}
            >
              <i class="fas fa-warehouse"></i> Templates
            </a>
            <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 3
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="Requests"
              onClick={() => this.routeLinkPage(3)}
            >
              <i className="fas fa-hand-holding-usd"></i> Review Requests
            </a>
            {/* <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 4
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="Attendance"
              onClick={() => this.routeLinkPage(4)}
            >
              <i className="fas fa-hand-holding-usd"></i> Attendance
            </a> */}
            <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 5
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="Questions"
              onClick={() => this.routeLinkPage(5)}
            >
              <i className="fas fa-hand-holding-usd"></i> Questions
            </a>
            <a
              className={
                "feHeader-tabs c-pointer navbar-brand equipmentHeader-navbar-brand" +
                (this.state.ActiveTab === 6
                  ? " equipmentHeader-navbar-brand-active"
                  : "")
              }
              data-tab="Discounts"
              onClick={() => this.routeLinkPage(6)}
            >
              <i className="fas fa-hand-holding-usd"></i> Discounts
            </a>
          </Fragment>
        </nav>
      </>
    );
  }
}

export default withRouter(
  connect((state) => ({ registrationstate: state.registrationstate }), {
    ...RegistrationsAction,
  })(AdminHeader)
);
