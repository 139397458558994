import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class PositionTypes extends Component {
  onChange(item) {
    var positionTypesIndex = _.findIndex(
      this.props.filterState.filterData.positionTypes,
      (o) => {
        return o.positionType === item.positionType;
      }
    );
    item.checked = !this.props.filterState.filterData.positionTypes[
      positionTypesIndex
    ].checked;
    this.props.filterState.filterData.positionTypes[positionTypesIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item, index) {
    return (
      <li class="chechbox-list_item" key={"postypitem-" + index}>
        <span
          className="card-text custom-checkbox ml-4"
          key={"ptitem-" + index}
        >
          <input
            type="checkbox"
            htmlFor={"ptitem-" + index}
            value={item.id}
            checked={item.checked}
            className="custom-control-input"
            id={"ptitem-" + index}
            name={"ptitem-" + index}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            htmlFor={"ptitem-" + index}
            style={{ color: this.props.isMobile ? "white" : null }}
          >
            {item.positionType}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Position Types</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="filter-fields-scroll">
          <ul className="chechbox-list">
            {this.props.filterState.filterData.positionTypes &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.positionTypes.map(
                (item, index) => this.chechboxes(item, index)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(PositionTypes);
