import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import {
  ToggleSwitchV2,
  TextField,
  CurrenyInput,
} from "../../../../Shared/CustomizedForms/FormElements";
import styled from "styled-components";
import { QuestionTypes } from "../../../Helpers/LookUpValues";
import RegistrationsService from "../../../RegistrationsService";
import _ from "lodash";

function AddQuestionModal(props) {
  const initialValues = {
    questions: [props.editQuestion || {}],
  };
  const resetFormValues = {
    questions: [],
  };
  const { isEditMode } = props;
  //useEffect(() => {
  //  if (_.isEmpty(props.editQuestion)) {
  //    console.log("empty");
  //  } else {
  //    console.log("filled");
  //  }
  //}, [props.editQuestion]);
  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        label: Yup.string()
          .required("Label is required")
          .max(255),
        questionType: Yup.number().required("Question Type is required"),
        isFeeAssociated: Yup.boolean(),
        fee: Yup.number().when("isFeeAssociated", {
          is: true,
          then: Yup.number().required("Fee is required"),
        }),
        // questionOptions: Yup.array().of(
        //   Yup.object().shape({
        //     option: Yup.string().when("questionType", {
        //       is: QuestionTypes.DropDown,
        //       then: Yup.string().required("Option is required"),
        //     }),
        //     id: Yup.number().default(0),
        //     question_id: Yup.number().default(0),
        //   })
        // ),
      })
    ),
  });
  function onChangeNumberOfQuestions(e, field, values, setValues) {
    // update dynamic form
    const questions = [...values.questions];

    const numberOfQuestions = e.target.value || 0;
    const previousNumber = parseInt(questions.length || "0");
    if (previousNumber < numberOfQuestions) {
      for (let i = previousNumber; i < numberOfQuestions; i++) {
        questions.push({
          label: "",
          isRequired: false,
          questionType: 1,
        });
      }
    } else {
      for (let i = previousNumber; i >= numberOfQuestions; i--) {
        questions.splice(i, 1);
      }
    }
    setValues({ ...values, questions });

    // call formik onChange method
    field.onChange(e);
  }
  function onChangeQuestionType(e, field, values, setValues, index) {
    // update dynamic form
    const questions = [...values.questions];
    if (e.target.value == "Choose...") {
      return;
    }
    const questionType = parseInt(e.target.value || "1");
    questions[index].questionType = questionType;
    questions[index].isRequired = false;

    if (
      questionType == QuestionTypes.DropDown ||
      questionType == QuestionTypes.Radiobuttons ||
      questionType == QuestionTypes.Checkboxes
    ) {
      questions[index].hasOptions = true;
      questions[index].questionOptions = [];
    } else {
      questions[index].hasOptions = false;
      questions[index].questionOptions = []; // clear options
      delete questions[index].questionOptions;
    }
    setValues({ ...values, questions });

    // call formik onChange method
    field.onChange(e);
  }
  function addQuestion(e, values, setValues) {
    var questions = [...values.questions];
    questions.push({
      label: "",
      isRequired: false,
      questionType: 1,
    });
    setValues({ ...values, questions });
  }
  const onSubmit = async (fields) => {
    // display form field values on success
    //alert("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 4));
    console.log("In onSubmit Method", JSON.stringify(fields));

    let response = null;
    await RegistrationsService.AddUpdateQuestions(fields).then((data) => {
      if (data && data.status && data.status == "Success") {
        response = data.data;
        toast.success(data.message);
      } else {
        toast.error("Something went wrong");
      }
    });

    if(props.isTemplateCall)
    {
      props.onClose(response);
    }
    else
    {
      props.onClose();
      props.reloadQuestions();
    }
  };
  function deleteQuestion(i, values, setValues) {
    var questions = [...values.questions];
    questions = questions.filter((el, index) => {
      return index !== i;
    });

    setValues({ ...values, questions });
  }
  const handleClose = () => {
    props.onClose();
  };
  const cancelModal = () => {
    props.onClose();
  };
  const onShow = () => {};
  return (
    <Modal
      className="modal fade team-style"
      id="addQuestionModal"
      size="xl"
      backdrop="static"
      show={props.showModal}
      centered
      onShow={onShow}
      onHide={handleClose}
    >
      <Modal.Header className="modal-header bg-secondary py-2" closeButton>
        <Modal.Title className=" w-100 modal-title font-italic">
          <div className="feModalHeader align-items-center text-uppercase d-flex">
            <div className="col-sm-6 mt-2">
              <h5 className="text-white mt-0">
                {isEditMode ? "Edit Question" : "Create Question"}
              </h5>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values, touched, setValues, isValid }) => (
            <Form>
              <div className="card m-3">
                {!isEditMode && (
                  <div className="card-body border-bottom">
                    <div className="form-row">
                      <div className="form-group">
                        <label>Total Number of Questions</label>
                        <Field name="numberOfQuestion">
                          {({ field }) => (
                            <select
                              {...field}
                              className={
                                "form-control" +
                                (errors.numberOfQuestion &&
                                touched.numberOfQuestion
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={(e) =>
                                onChangeNumberOfQuestions(
                                  e,
                                  field,
                                  values,
                                  setValues
                                )
                              }
                            >
                              {["Choose...", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                (i) => (
                                  <option
                                    key={i}
                                    value={i}
                                    selected={i == "Choose..."}
                                    disabled={i == "Choose..."}
                                  >
                                    {i}
                                  </option>
                                )
                              )}
                            </select>
                          )}
                        </Field>
                        <ErrorMessage
                          name="numberOfQuestion"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm w-md waves-effect waves-light ml-2"
                        onClick={(e) => addQuestion(e, values, setValues)}
                      >
                        + Add Question
                      </button>
                    </div>
                  </div>
                )}
                <FieldArray name="questions">
                  {() =>
                    values.questions.map((question, i) => {
                      const questionErrors =
                        (errors.questions &&
                          errors.questions.length &&
                          errors.questions[i]) ||
                        {};
                      const questionTouched =
                        (touched.questions &&
                          touched.questions.length &&
                          touched.questions[i]) ||
                        {};

                      return (
                        <div key={i} className="list-group list-group-flush">
                          <div className="list-group-item">
                            {!isEditMode && (
                              <button
                                type="button"
                                onClick={() =>
                                  deleteQuestion(i, values, setValues)
                                }
                                className="btn btn-danger btn-sm float-right"
                              >
                                <i className="fas fa-trash-alt " />
                              </button>
                            )}

                            {!isEditMode && (
                              <h5 className="card-title font-weight-bold text-center">
                                Question {i + 1}
                              </h5>
                            )}
                            {/*###############(First Row)##################*/}
                            <div className="form-row">
                              <div className="form-group col-md-3 col-sm-12">
                                <label className=" font-weight-bold">
                                  Select Control Type
                                </label>
                                <Field name={`questions.${i}.questionType`}>
                                  {({ field }) => (
                                    <select
                                      {...field}
                                      className={
                                        "form-control" +
                                        (questionErrors.questionType &&
                                        questionTouched.questionType
                                          ? " is-invalid"
                                          : "")
                                      }
                                      onChange={(e) =>
                                        onChangeQuestionType(
                                          e,
                                          field,
                                          values,
                                          setValues,
                                          i
                                        )
                                      }
                                    >
                                      {[
                                        "Choose...",
                                        ...Object.keys(QuestionTypes),
                                      ].map((i, index) => (
                                        <option
                                          key={i}
                                          value={
                                            i !== "Choose..." ? index : null
                                          }
                                          selected={i == "Choose..."}
                                          disabled={i == "Choose..."}
                                        >
                                          {i}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={`questions.${i}.questionType`}
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="form-group col-md-9 col-sm-12">
                                <TextField
                                  name={`questions.${i}.label`}
                                  type="text"
                                  label="Question Label"
                                  placeholder="Enter Question Label"
                                />
                              </div>
                            </div>
                            {/*##############(Second Row)######################### */}
                            <div className="form-row">
                              <div className="form-group col-md-4 col-sm-6">
                                <ToggleSwitchV2
                                  label="Is Required"
                                  name={`${`questions.${i}.isRequired`}`}
                                />
                              </div>
                              <div className="form-group col-md-4 col-sm-6 ">
                                <ToggleSwitchV2
                                  label="Is Fee Associated"
                                  name={`${`questions.${i}.isFeeAssociated`}`}
                                />
                              </div>
                              {question.isFeeAssociated && (
                                <div className="form-group col-md-4 col-sm-6 ">
                                  <CurrenyInput
                                    name={`questions.${i}.fee`}
                                    type="number"
                                    label="Fee"
                                    placeholder="Enter Amount"
                                  />
                                </div>
                              )}
                            </div>
                            {question.hasOptions && (
                              <FieldArray
                                name={`questions.${i}.questionOptions`}
                                className="form-row"
                              >
                                {(fieldArrayProps) => {
                                  const {
                                    push,
                                    remove,
                                    form,
                                  } = fieldArrayProps;
                                  const { values } = form;
                                  debugger;
                                  const { questionOptions } = values.questions[
                                    i
                                  ]
                                    ? values.questions[i]
                                    : {};
                                  //console.log("fieldArrayProps", fieldArrayProps);
                                  //console.log("Form errors", form.errors);
                                  return (
                                    <>
                                      <span className="font-weight-bold">
                                        Add Options
                                      </span>
                                      {questionOptions &&
                                        questionOptions.map(
                                          (options, index) => (
                                            <div key={index} className="row">
                                              <div className="col-3">
                                                <Field
                                                  id={`questions.${i}.questionOptions[${index}].option`}
                                                  name={`questions.${i}.questionOptions[${index}].option`}
                                                  className="form-control mt-2"
                                                  placeholder={`Option #${index +
                                                    1}`}
                                                />
                                              </div>
                                              {index > 0 && (
                                                <div className="col-3 mt-2">
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm ml-2 w-md waves-effect waves-light"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    -
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          )
                                        )}
                                      <div className="col mt-2 pl-0">
                                        <button
                                          type="button"
                                          className="btn btn-success btn-sm w-md waves-effect waves-light"
                                          onClick={() => push("")}
                                        >
                                          + Add
                                        </button>
                                      </div>
                                    </>
                                  );
                                }}
                              </FieldArray>
                            )}
                          </div>
                        </div>
                      );
                    })
                  }
                </FieldArray>
                <div className="card-footer text-center border-top-0">
                  <button
                    type="submit"
                    disabled={!isValid}
                    className="btn btn-primary mr-1"
                  >
                    Submit
                  </button>
                  {!isEditMode && (
                    <button
                      className="btn btn-secondary mr-1"
                      onClick={() =>
                        setValues({
                          ...resetFormValues,
                        })
                      }
                      type="reset"
                    >
                      Reset
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-danger mr-1 cancelModal"
                    onClick={cancelModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default AddQuestionModal;
const SwitchDiv = styled.div`
  @media (min-width: 576px) {
    padding-left: 2rem;
  }
`;
