import React, { Component, Fragment } from "react";
const customTotal = (from, to, size) => (
    <span
        className="react-bootstrap-table-pagination-total"
        style={{ float: "left" }}
    >
        {from}
        {" - "}
        {to} of {size} {"   "}
        <b style={{ marginLeft: 15 }}> Show: </b>
    </span>
);
export default customTotal;