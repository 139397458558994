import React, { Component, Fragment } from "react";
import AddRoleSub from "./AddRoleSub";
import $ from "jquery";

class AddRoleBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subFeatures: this.props.data,
            updateMethods: this.props.updateMethods,
            featureId: this.props.featureId
        };
    }
    handleChange = (name, idx) => evt => {
        if (name == "canAdd" || name == "canEdit" || name == "canDelete") {
            this.state.subFeatures.map(item => {
                if (idx == item.id) {
                    item.permissions["canView"] = true;
                }
            });
        }
        this.state.subFeatures.map(item => {
            if (idx == item.id) {
                item.permissions[name] = evt.target.checked;
            }
        });
        this.state.updateMethods().updateSubFeatures(this.state.featureId, this.state.subFeatures);
    };
    componentDidMount() {
        $(".Upload").find("label").hide();
        $(".Members").find("label[for=11]").hide()
    }
    render() {
        return (
            <Fragment>
                {this.props.data.map(feature => {
                    return (
                        <Fragment>
                            <tr data-row={feature.permissions.viewId}>
                                <td>{feature.displayName}</td>
                                <td width="70">
                                    <input
                                        type="checkbox"
                                        id={feature.permissions.viewId}
                                        switch="bool"
                                        checked={feature.permissions.canView}
                                        onChange={this.handleChange("canView", feature.id)}
                                    />
                                    <label htmlFor={feature.permissions.viewId} data-on-label="Yes" data-off-label="No" />
                                </td>
                                <td width="70" className={feature.name}>
                                    {feature.permissions.addId && (
                                        <Fragment>
                                            <input
                                                type="checkbox"
                                                id={feature.permissions.addId}
                                                switch="bool"
                                                checked={feature.permissions.canAdd}
                                                onChange={this.handleChange("canAdd", feature.id)}
                                            />
                                            <label htmlFor={feature.permissions.addId} data-on-label="Yes" data-off-label="No" />
                                        </Fragment>
                                    )}
                                </td>
                                <td width="70" className={feature.name}>
                                    {feature.permissions.editId && (
                                        <Fragment>
                                            <input
                                                type="checkbox"
                                                id={feature.permissions.editId}
                                                switch="bool"
                                                checked={feature.permissions.canEdit}
                                                onChange={this.handleChange("canEdit", feature.id)}
                                            />
                                            <label htmlFor={feature.permissions.editId} data-on-label="Yes" data-off-label="No" />
                                        </Fragment>
                                    )}
                                </td>
                                <td width="70" className={feature.name}>
                                    {feature.permissions.deleteId && (
                                        <Fragment>
                                            <input
                                                type="checkbox"
                                                id={feature.permissions.deleteId}
                                                switch="bool"
                                                checked={feature.permissions.canDelete}
                                                onChange={this.handleChange("canDelete", feature.id)}
                                            />
                                            <label htmlFor={feature.permissions.deleteId} data-on-label="Yes" data-off-label="No" />
                                        </Fragment>
                                    )}
                                </td>
                            </tr>
                            {feature.components && feature.components.length > 0 && (
                                <AddRoleSub
                                    data={feature.components}
                                    featureId={feature.featuretaskid}
                                    updateMethods={this.props.updateMethods}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </Fragment>
        );
    }
}

export default AddRoleBody;
