import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { connect } from "react-redux";
import * as actionTypes from "../../store/action";
import axios from "../Shared/auth-header";
import ChangePasswordForm from "./ChangePasswordForm";
import { Link } from "react-router-dom";
import $ from "jquery";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Password: "",
      RePassword: "",
      VerificationCode: "",
      Password_err: "",
      RePassword_err: "",
      valid: false,
      message: "",
      status: false,
      showInstruction: true,
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
    this.handleChangePwdConfirm = this.handleChangePwdConfirm.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    this.state.VerificationCode = urlParams.get("key");
  }

  handleChangePwd(e) {
    this.setState({ Password: e.target.value });
    let passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (e.target.value === "") {
      this.setState({ Password_err: "Required Field" });
      this.setState({ valid: false });
    } else if (!passRegex.test(e.target.value)) {
      this.setState({
        Password_err:
          "Password must be 8 characters long and must contain 1 uppercase, 1 lower case and 1 number.",
      });
      this.setState({ valid: false });
    } else {
      this.setState({ Password_err: "" });
      this.setState({ valid: true });
    }
  }
  handleChangePwdConfirm(e) {
    this.setState({ RePassword: e.target.value });
    if (e.target.value === "") {
      this.setState({ RePassword_err: "Required Field" });
      this.setState({ valid: false });
    } else if (e.target.value != this.state.Password) {
      this.setState({ RePassword_err: "Password should be the same." });
      this.setState({ valid: false });
    } else {
      this.setState({ RePassword_err: "" });
      this.setState({ valid: true });
    }
  }
  handleFormSubmit(e) {
    e.preventDefault();

    if (this.state.Password === "" || this.state.Password_err != "") {
      this.setState({ Password_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.RePassword === "" || this.state.RePassword_err != "") {
      this.setState({ RePassword_err: "Fix the Error" });
      this.setState({ valid: false });
      return false;
    }
    if (this.state.valid) {
      $(".loading").show();
      const data = {
        //model: {
        VerificationCode: this.state.VerificationCode,
        Password: this.state.Password,
        //}
      };
      axios
        .post("/api/Account/UpdatePassword", data)
        .then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            this.setState({ status: true });
            this.setState({
              message:
                "Password is updated successfully.Please login with your new password",
            });

            document.querySelector(".resetForm").style.display = "none";
            if (document.querySelector(".loginc") != null) {
              document.querySelector(".loginc").classList.remove("d-none");
            }
          } else {
            this.setState({ status: true });
            this.setState({ message: data.message });
          }
          $(".loading").hide();
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
  componentDidMount() {
    if (this.props.loginpage === false) {
      const urlParams = new URLSearchParams(window.location.search);
      const keydata = urlParams.get("key");

      if (keydata && keydata != null) {
        this.setState({ VerificationCode: urlParams.get("key") });
      } else {
        this.props.history.push("/login");
      }
      this.props.UpdateLogin();
    }
  }
  confirmChangeEvent = () => {
    this.setState({ showInstruction: false });
  };
  render() {
    return (
      <AUX>
        <div className="wrapper-page">
          <div
            className="card overflow-hidden account-card mx-3"
            style={{ height: "800px" }}
          >
            <div className="bg-primary ptb-login text-white text-center position-relative">
              <span className="logo logo-admin">
                <img
                  src="assets/images/natca-logo.png"
                  height="142"
                  alt="logo"
                />
              </span>
            </div>
            <div className="account-card-content m-t-40">
              <div className="col-16 m-t-20 text-left font-weight-bold">
                <h5>Reset Password</h5>

                {this.state.showInstruction && (
                  <small>
                    Enter your new account password below. Passwords must be at
                    least eight (8) characters utilizing at least one uppercase,
                    lowercase, and number.
                  </small>
                )}
              </div>
              <div></div>
              <ChangePasswordForm
                type="login"
                vcode={this.state.VerificationCode}
                confirmChangeEvent={this.confirmChangeEvent}
              />

              <div className="col-12 m-t-20 text-left font-weight-bold">
                By registering for an account with NATCA you agree to our
                <Link to="login" className="font-500 text-primary text-right">
                  {" "}
                  Terms & Conditions.{" "}
                </Link>{" "}
              </div>

              <div className="col-12 m-t-5 text-left font-weight-bold">
                Need help?
              </div>
              <div className="col-12 m-t-5 text-left font-weight-lighter">
                Other issues?{" "}
                <a target="_blank" href={`mailto:support@natca.org`}>
                  Email Us
                </a>
              </div>
              {this.state.showInstruction && (
                <div className="col-12 text-left font-weight-lighter">
                  <Link
                    to="/login"
                    className="font-500 text-primary text-right"
                  >
                    {" "}
                    Login{" "}
                  </Link>{" "}
                </div>
              )}
            </div>
          </div>

          <div className="m-t-40 text-center">
            <p>© {new Date().getFullYear()} NATCA</p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(ResetPassword);
