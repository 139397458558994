import React from "react";

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isAuth: false };
    }
    setAuthIn = () => {
        this.setState({ isAuth: true });
    };
    setAuthOut = () => {
        this.setState({ isAuth: false });
    };
    render() {
        return (
            <AuthContext.Provider value={{ state: this.state, setAuthIn: this.setAuthIn, setAuthOut: this.setAuthOut }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
