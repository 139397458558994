import React, { Component, Fragment } from "react";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import AUX from "../../../../hoc/Aux_";
import _ from "lodash";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import Services from "./EditUserService";
import { toast } from "react-toastify";
import { Collapse, Card, Accordion } from "react-bootstrap";
class CivicAPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      civicData: null,
      memberid: null,
      noDataMessage: "No Data Found!",
      loader: true,
    };
    this.loadCivicData = this.loadCivicData.bind(this);
  }
  async componentDidMount() {
    await this.setState({ memberid: this.props.memberid });
    this.loadCivicData();
  }
  async componentDidUpdate(prevProps) {
    if (this.state.memberid != this.props.memberid || this.props.reload) {
      await this.props.reloadDone();
      await this.setState({ memberid: this.props.memberid });
      await this.loadCivicData();
    }
  }
  async loadCivicData() {
    this.setState({ loader: true });
    if (this.state.memberid != null) {
      let data = await Services.CivicResults(this.state.memberid);
      if (data != null && data != "No Address Found!") {
        this.setState({ civicData: data });
      } else if (data == "No Address Found!") {
        this.setState({ noDataMessage: data, civicData: null });
      } else {
        this.setState({ noDataMessage: "No Data Found!", civicData: null });
      }
    }
    this.setState({ loader: false });
  }
  renderOfficials = (officials) => {
    return officials.map((officialStr, index) => {
      let official = JSON.parse(officialStr);
      return (
        <div className="rounded shadow mb-3 p-3">
          <div className="py-1 row mx-0">
            <span className="card-subtitle col-2 pt-1 text-muted">
              <strong>Name: </strong>
            </span>
            <span className="card-text col-10">
              {official.name == null || official.name == ""
                ? "none"
                : official.name}
            </span>
          </div>
          {official.address && (
            <div className="py-1  row mx-0">
              <span className="card-subtitle col-2 pt-1 text-muted">
                <strong>Address: </strong>
              </span>
              <span className="card-text col-10">
                {official.address.map((item, index) => (
                  <>
                    {index > 0 && <br />}
                    <a
                      target="_blank"
                      href={
                        "http://maps.google.com/?q=" +
                        item.address1 +
                        ", " +
                        item.address2 +
                        item.address1 +
                        ", " +
                        item.city +
                        ", " +
                        item.state +
                        " " +
                        item.zipcode +
                        " "
                      }
                      onClick={(e) => this.handleAddressesClick(e, item)}
                    >
                      {item.line1} {item.line2} {item.line3} {item.city}{" "}
                      {item.state} {item.zip}{" "}
                    </a>
                  </>
                ))}
              </span>
            </div>
          )}
          <div className="py-1 row mx-0">
            <span className="card-subtitle pt-1 text-muted col-2">
              <strong>Party: </strong>
            </span>
            <span className="card-text col-10">
              {official.party == null || official.party == ""
                ? "none"
                : official.party}
            </span>
          </div>
          <div className="py-1 row mx-0">
            <span className="card-subtitle col-2 pt-1 text-muted">
              <strong>Phones: </strong>
            </span>
            <span className="card-text col-10">
              {official.phones == null || official.phones == ""
                ? "none"
                : official.phones.join(", ")}
            </span>
          </div>
          {official.urls && (
            <div className="py-1 row mx-0">
              <span className="card-subtitle col-2 pt-1 text-muted">
                <strong>Party: </strong>
              </span>
              <span className="card-text col-10">
                {official.urls == null || official.urls == ""
                  ? "none"
                  : official.urls.map((url, index) => (
                      <>
                        {index > 0 && <br />}
                        <a href={url} key={url}>
                          {url}
                        </a>
                      </>
                    ))}
              </span>
            </div>
          )}
          {/* You can similarly display other details like photoUrl, emails, channels, etc. */}
        </div>
      );
    });
  };
  render() {
    let data = this.state.civicData;
    return (
      <AUX>
        <div className="card directory-card border-rounded shadow">
          <h5 className="card-header mt-0">
            <i className="fas fa-id-card-alt mr-2" />
            Civic API
          </h5>
          <div className="card-body pb-3 pt-2 px-1">
            {this.state.loader == false ? (
              <div>
                {data && data != null && !_.isEmpty(data) ? (
                  <Fragment>
                    <div className="row mx-0 pb-1">
                      <div className="col-lg-12">
                        <div className="py-1">
                          <span className="card-subtitle mb-2 text-muted">
                            <strong>Division: </strong>
                          </span>
                          <span className="card-text">
                            {data.division == null || data.division == ""
                              ? "none"
                              : data.division}
                          </span>
                        </div>
                        <Accordion>
                          {Object.keys(data.officials).map((key, index) => (
                            <div key={index}>
                              <Accordion.Toggle
                                as="div"
                                eventKey={index.toString()}
                              >
                                <h6 className="card-header mt-0">{key}</h6>
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey={index.toString()}>
                                <div className="p-2">
                                  {this.renderOfficials(data.officials[key])}
                                </div>
                              </Accordion.Collapse>
                            </div>
                          ))}
                        </Accordion>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <p className="text-center mb-0">{this.state.noDataMessage}</p>
                )}
              </div>
            ) : (
              <div
                class="m-3 position-relative d-block mx-auto spinner-border text-info"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            )}
          </div>
        </div>
      </AUX>
    );
  }
}

export default CivicAPI;
