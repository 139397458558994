import React, { Component } from "react";
import { toast } from "react-toastify";
import { FilePond, registerPlugin, setOptions, File } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Services from "./GrievanceService";
import moment from "moment";
import $ from "jquery";
import _ from "lodash";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);

class GrievanceAttachments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 10,
      attachmentsData: [],
      files: [],
      acceptedFileTypes: [
        "image/png",
        "image/jpeg",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
    };
    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.viewAllLink = this.viewAllLink.bind(this);
    this.getColumn = this.getColumn.bind(this);
  }

  handleInit() {
    // console.log("FilePond instance has initialised", this.pond);
  }
  handleDownload(links, name) {
    var link = document.createElement("a");
    link.href = links;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      e.stopPropagation();
    },
  };

  updateFiles = (item) => {
    alert("s");
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    rowClasses
  ) {
    const customTotal = (from, to, size) => (
      <span
        className="react-bootstrap-table-pagination-total"
        style={{ float: "left", marginTop: "-1px", marginRight: "4px" }}
      >
        {from}
        {" - "}
        {to} of {size} {"   "}
        <b style={{ marginLeft: "4px" }}> Show: </b>
      </span>
    );
    const defaultPaginationConfigs = paginationFactory({
      paginationTotalRenderer: customTotal,
      sizePerPage: this.state.sizePerPage,
      totalSize: data && data.length,
      showTotal: true,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value: data.length,
        },
      ],
    });
    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={defaultPaginationConfigs}
          rowClasses={rowClasses}
          rowEvents={this.rowEvents}
        />
      </div>
    );
  }

  getColumn() {
    return [
      {
        text: "Uploaded",
        dataField: "uploaded",
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row) => {
          return (
            <div>
              {moment(row.uploaded).isValid()
                ? moment(row.uploaded).format("MM/DD/YYYY")
                : ""}
            </div>
          );
        },
      },
      {
        text: "Filename",
        dataField: "originalfilename",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: null,
      },
      {
        text: "Reference",
        dataField: "stepName",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
      },
      {
        isDummyField: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "py-2",
        formatter: (cellContent, row) => {
          return (
            <div className="text-center">
              <a onClick={() => window.open(row.fileDownloadLink, "_blank")}>
                <i className="fas fa-eye"></i>
              </a>
              <a
                onClick={() => {
                  this.props.openFileEditModal(
                    row.originalfilename,
                    row.filename,
                    row.reference,
                    row.stepName
                  );
                }}
              >
                <i class="fas fa-edit ml-2"></i>
              </a>
              <a
                onClick={() => {
                  this.handleDownload(
                    row.fileDownloadLink,
                    row.originalfilename
                  );
                }}
              >
                <i className="fas fa-download mx-2"></i>
              </a>
              <a
                onClick={() => {
                  if (window.confirm(`Are you sure you want to delete?`)) {
                    this.props.deleteFile(row.filename);
                  }
                }}
              >
                <span className="text-danger fas fa-trash"></span>
              </a>
            </div>
          );
        },
      },
    ];
  }

  SizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };

  viewAllLink = (data) => {
    this.setState({ sizePerPage: data.length });
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12" id="GrievanceAttachmentControl">
            <FilePond
              disabled={!this.props.disableAttachments }
              ref={(ref) => {
                this.pond = ref;
              }}
              server={{
                process: (
                  fieldName,
                  file,
                  metadata,
                  load,
                  error,
                  progress,
                  abort,
                  transfer,
                  options
                ) => {
                  if (file.status !== 8) {
                    const formData = new FormData();
                    formData.append(fieldName, file, file.name);
                    var data = Services.SaveAttachments(
                      formData,
                      this.props.requestId
                    ).then((response) => {
                      if (!_.isEmpty(response.data)) {
                        this.props.updateFiles(response.data);
                      }
                    });
                    if (!_.isEmpty(data)) {
                      load(file);
                    } else {
                      error("Upload Failed");
                    }
                  }
                },
              }}
              files={this.state.files}
              allowMultiple={true}
              allowFileTypeValidation={true}
              allowImageExifOrientation={true}
              allowImagePreview={true}
              allowImageCrop={true}
              allowFileEncode={true}
              acceptedFileTypes={this.state.acceptedFileTypes}
              fileValidateTypeDetectType={(source, type) =>
                new Promise((resolve, reject) => {
                  //  debugger
                  let rejectedExtensions = [
                    "ade",
                    "adp",
                    "apk",
                    "bat",
                    "chm",
                    "cmd",
                    "com",
                    "cpl",
                    "dll",
                    "dmg",
                    "exe",
                    "hta",
                    "ins",
                    "isp",
                    "jar",
                    "js",
                    "jse",
                    "lib",
                    "lnk",
                    "mde",
                    "msc",
                    "msi",
                    "msp",
                    "mst",
                    "nsh",
                    "pif",
                    "scr",
                    "sct",
                    "shb",
                    "sys",
                    "vb",
                    "vbe",
                    "vbs",
                    "vxd",
                    "wsc",
                    "wsf",
                    "wsh",
                    "cab",
                  ];
                  // If the file extension is not in our rejected list.
                  if (
                    !rejectedExtensions.some((ext) =>
                      source.name.endsWith("." + ext)
                    )
                  ) {
                    resolve(type);
                  } else {
                    // Otherwise it is rejected.
                    toast.error("File type is unsupported!");
                    //reject(new Error('Files contains unsupported format!'));
                    reject(type);
                  }
                })
              }
              oninit={() => this.handleInit()}
              onupdatefiles={(fileItems) => {
                this.setState({
                  files: fileItems.map((fileItem) => fileItem.file),
                });
              }}
            ></FilePond>
          </div>
        </div>
        <div className="container h-100">
          {this.props.attachedFilesTable &&
          this.props.attachedFilesTable.length < 1 ? (
            <div className="row rowLabelCenter">
              <h5>No Attachments Found!</h5>
            </div>
          ) : (
            <div className="row" id="tableRow">
              <div className="col-12 p-0">
                {this.RemotePagination(
                  this.getColumn(),
                  "id",
                  this.props.attachedFilesTable,
                  this.SizePerPageChange,
                  this.state.sizePerPage,
                  this.rowClasses
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default GrievanceAttachments;
