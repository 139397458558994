import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../payfilestyle.css";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PayfileTwoAction from "../../../store/payfileTwo/action";
import Services from "../PayfileService";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { debounce } from "throttle-debounce";
import TextField from "@material-ui/core/TextField";
import ConfirmDeleteModal from "./General/DeleteModal";
import AuthService from "../../Auth/AuthService";
import FinanceService from "../../Finance/FinanceService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { MDBInput } from "mdbreact";

import { toast } from "react-toastify";
import $ from "jquery";
import status from "../../Shared/MemberStatuses";
let formate_textbox_amount = (n) => {
  var num_parts = n
    .toString()
    .replaceAll(",", "")
    .split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
  //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};
class ImportDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberId: -1,
      //FinanceFileID
      FFI: -1,
      openModal: false,
      updateData: [],
      Members: [],
      FormCode: -1,
      SubmissionObj: {
        ExpectedAmount: null,
        Amount: null,
        Refund: false,
        MemberNumber: null,
        MemberID: null,
        DuesCollectionTypeId: null,
        NatcaBargainingUnitId: null,
        FinanceFileId: null,
        Status: null,
      },
      BackUp_SubmissionObj: {},
      NatcaBargainingUnits: [],
      DuesCollectionTypes: [],
      MemberInfoData: null,
      NBU: -1,
      NBU_Err: "",

      FinanceLoader: true,

      deleteModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.LoadUpdatesData = this.LoadUpdatesData.bind(this);
    this.changeHandlerStatus = this.changeHandlerStatus.bind(this);
    this.changeHandlerDuesCollectionType = this.changeHandlerDuesCollectionType.bind(
      this
    );
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.submitForm = this.submitForm.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.handleChangeRefund = this.handleChangeRefund.bind(this);
    this.financeIgnores = this.financeIgnores.bind(this);
    this.changeHandlerNBU = this.changeHandlerNBU.bind(this);
  }
  async financeIgnores() {
    if (this.state.FFI && this.state.FFI > 0) {
      $(".loading").show();
      var data = await Services.IgnoreFinance(this.state.FFI);
      $(".loading").hide();
      if (data && data.statusCode == 200) {
        this.closeModal();
        this.props.refreshOnIgnore();
      }
    } else {
      toast.error("Invalid state, Tray again after few moments");
      this.closeModal();
    }
  }

  loadMemberData = async (SearchTerms) => {
    let data = await Services.LoadMembers(SearchTerms, 0, 5);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.setState({ Members: data.result });
      }
    }
  };
  async loadBargainingUnit() {
    let data = await Services.LoadBargainingUnit();
    if (data != null) {
      this.setState({ NatcaBargainingUnits: data.result });
    }
  }
  componentDidMount() {
    this.loadMemberData("");
    this.loadBargainingUnit();
  }
  async LoadUpdatesData() {
    let data = await Services.FinanceFileEntry(this.state.FFI);
    let data2 = await FinanceService.FinanceDropdowns();
    await this.setState({
      FinanceLoader: true,
    });

    let obj = _.cloneDeep(this.state.SubmissionObj);
    if (data != null) {
      obj.FinanceFileId = data.result.id;
      obj.MemberID = data.result.memberID;
      obj.Status = data.result.memberStatus;
      obj.ExpectedAmount = data.result.expectedAmount;
      obj.Amount = data.result.amount;
      obj.MemberNumber = data.result.memberNumber;
      obj.Refund = data.result.refund;
      obj.DuesCollectionTypeId = data.result.duesCollectionTypeId;
      obj.NatcaBargainingUnitId = data.result.natcaBargainingUnitId;
    } else {
      obj.FinanceFileId = null;
      obj.MemberID = null;
      obj.Status = null;
      obj.ExpectedAmount = null;
      obj.Amount = null;
      obj.MemberNumber = null;
      obj.Refund = false;
      obj.DuesCollectionTypeId = null;
      obj.NatcaBargainingUnitId = null;
    }

    if (data2 != null) {
      this.state.DuesCollectionTypes = data2.result.duesCollectionTypeDD;
    }
    await this.setState({
      SubmissionObj: obj,
      BackUp_SubmissionObj: _.cloneDeep(obj),
      DuesCollectionTypes: this.state.DuesCollectionTypes,
      FinanceLoader: false,
    });
  }
  handleChangeRefund = (e) => {
    this.state.SubmissionObj.Refund = e.target.checked;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  amountValidation(amount) {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    const amount_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Invalid amount",
      },
    ];
    if (amountReg.test(amount)) {
      return amount_States.find(({ state }) => state == "Valid");
    } else {
      return amount_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  }
  handleAmountChange = (evt) => {
    if (evt.target.name == "expectedamount" || evt.target.name == "amount") {
      this.state.SubmissionObj[
        evt.target.name + "_err"
      ] = this.amountValidation(evt.target.value.replaceAll(",", "")).error;

      this.state.SubmissionObj[evt.target.name] = evt.target.value;
    } else {
      this.state.SubmissionObj[evt.target.name] = evt.target.value;
    }

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  MemberNumberSearchHandler = (SearchTerms) => {
    this.setState({ searchTerm: SearchTerms }, () => {
      this.autocompleteSearchDebounced();
    });
  };
  changeHandlerNBU = (e) => {
    this.state.SubmissionObj.NatcaBargainingUnitId = e.target.value;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  changeHandlerStatus = (e) => {
    this.state.SubmissionObj.Status = e.target.value;
    this.statusValidate(e.target.value);
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  changeHandlerDuesCollectionType = (e) => {
    this.state.SubmissionObj.DuesCollectionTypeId = e.target.value;

    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
  };

  changeHandler_amount = (e) => {
    this.state.SubmissionObj[e.target.name] = e.target.value;
    this.state.SubmissionObj[e.target.name + "_err"] = this.amountValidation(
      e.target.value
    ).error;

    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  amountValidation = (amount) => {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    const amount_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Invalid amount",
      },
    ];
    if (amountReg.test(amount)) {
      return amount_States.find(({ state }) => state == "Valid");
    } else {
      return amount_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };
  async handleChangeMemberNumber(value) {
    if (this.state.SubmissionObj != null) {
      this.state.SubmissionObj.MemberNumber = value;
      this.MemberNumberValidate(value);
      this.setState({
        SubmissionObj: this.state.SubmissionObj,
      });
    }
  }
  MemberNumberValidate(val) {
    let error = "";
    if (_.isEmpty(val)) {
      error = "Please, provide appropriate Member Number.";
    }
    this.state.SubmissionObj.MemberNumber_err = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  statusValidate(val) {
    let error = "";
    if (_.isEmpty(val)) {
      error = "Please, select appropriate status";
    }
    this.state.SubmissionObj.Status_Err = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  duesCollectionTypeValidate(val) {
    let error = "";
    if (!val || val == null || val < 1) {
      error = "Please, select appropriate Type";
    }
    this.state.SubmissionObj.DuesCollectionTypeId_Err = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }

  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.loadMemberData(this.state.searchTerm);
  };
  async submitForm() {
    let obj = this.state.SubmissionObj;
    if (
      (this.state.BackUp_SubmissionObj.MemberID != -1 ||
        this.MemberNumberValidate(obj.MemberNumber) == "") &&
      this.statusValidate(obj.Status) == "" &&
      this.duesCollectionTypeValidate(obj.DuesCollectionTypeId) == ""
    ) {
      $(".loading").show();
      var data = await Services.FinanceUpdate(this.state.SubmissionObj);
      $(".loading").hide();
      if (data && data.statusCode == 200) {
        this.props.refreshOnUpdate();
        this.closeModal();
      }
    }
  }
  async componentDidUpdate(prevprops) {
    if (this.props.openImportDetailModal && this.props.ImportData !== null) {
      if (
        this.props.ImportData.id != this.state.FFI ||
        this.state.MemberId != this.props.ImportData.memberID
      ) {
        await this.setState({
          FFI: this.props.ImportData.id,
          MemberId: this.props.ImportData.memberID,
          FinanceLoader: true,
        });
        this.LoadUpdatesData();
      }

      this.openModal();
    }
  }

  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeImportDetailModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
  }
  cancelUpdate() {
    this.setState({
      SubmissionObj: this.state.BackUp_SubmissionObj,
      FormCode: -1,
    });
    this.closeModal();
  }

  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          fontSize: 18,
        },
      },
    });
    let { NatcaBargainingUnits, DuesCollectionTypes } = this.state;
    let obj = this.state.SubmissionObj;
    let { ImportData } = this.props;
    let header = "";

    if (ImportData != null) {
      header =
        ImportData.firstName.toUpperCase() +
        " " +
        ImportData.lastName.toUpperCase() +
        " (" +
        (!_.isEmpty(ImportData.regionCode) ? ImportData.regionCode : "--") +
        "/" +
        (!_.isEmpty(ImportData.facilityCodeTo)
          ? ImportData.facilityCodeTo
          : "--") +
        ")";
    }

    return (
      <>
        <Modal
          centered={true}
          autoFocus={true}
          isOpen={this.state.openModal}
          className="errorignoremodel styled-modal"
          backdrop="static"
        >
          <ModalHeader
            className="modal-header bg-secondary py-2"
            toggle={this.cancelUpdate}
          >
            FINANCE UPDATE - {header}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div class="col-12">
                To create a new assign it a and assign it a scope.
              </div>

              <div
                className="col-12"
                data-toggle="collapse"
                data-target="#M-Verification"
                aria-expanded="true"
                aria-controls="M-Verification"
              >
                <div className="ignore-gray">
                  <h5>Member Verifications</h5>
                </div>
              </div>
              <div className="col-12 collapse show" id="M-Verification">
                {this.state.FinanceLoader ? (
                  <div
                    class="m-3 position-relative d-block mx-auto spinner-border text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  !this.state.FinanceLoader &&
                  obj != null && (
                    <div className="modalinput">
                      <div className="row mt-2 mb-2">
                        {this.state.BackUp_SubmissionObj.MemberID == -1 && (
                          <div className="col-sm-4">
                            <label className="ml-0">Member Number</label>
                            <Autocomplete
                              //  style={{ width: 300 }}
                              options={
                                !_.isEmpty(this.state.Members)
                                  ? this.state.Members.map(
                                      (x) => x.memberNumber
                                    )
                                  : []
                              }
                              //classes={{option: classes.option}}

                              autoComplete={true}
                              //autoSelect={true}
                              autoHighlight={true}
                              onChange={(e, value) => {
                                this.handleChangeMemberNumber(value);
                              }}
                              onInputChange={(e, value, reason) => {
                                this.MemberNumberSearchHandler(value);
                              }}
                              value={obj.MemberNumber}
                              getOptionLabel={(option) => {
                                if (option != undefined) {
                                  return option;
                                } else {
                                  return "";
                                }
                              }}
                              renderOption={(option) => (
                                <React.Fragment>{option}</React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Provide Member Number"
                                  variant="standard"
                                  fullWidth
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                            <span className="text-danger">
                              {obj.MemberNumber_err}
                            </span>
                          </div>
                        )}
                        <div className="col-sm-4">
                          <label>Status</label>
                          <select
                            //className="browser-default custom-select"
                            onChange={this.changeHandlerStatus}
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Value</option>
                            {status.map((item, index) => {
                              return (
                                <option
                                  value={item.value}
                                  selected={
                                    (obj.Status || "").toUpperCase() ==
                                    item.value.toUpperCase()
                                  }
                                >
                                  {item.text}
                                </option>
                              );
                            })}
                          </select>
                          <label className="text-danger">
                            {obj.Status_Err}
                          </label>
                        </div>
                        <div className="col-sm-4">
                          <label>Natca Bargaining Units</label>
                          <select
                            //className="browser-default custom-select"
                            onChange={this.changeHandlerNBU}
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Value</option>

                            {NatcaBargainingUnits.map((item, index) => {
                              return (
                                <option
                                  value={item.id}
                                  selected={
                                    obj.NatcaBargainingUnitId == item.id
                                  }
                                >
                                  {item.name} - {item.code}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div
                className="col-12"
                data-toggle="collapse"
                data-target="#FinanceDetail"
                aria-expanded="true"
                aria-controls="FinanceDetail"
              >
                <div className="ignore-gray">
                  <h5>Finance Details</h5>
                </div>
              </div>
              <div className="col-12 collapse show" id="FinanceDetail">
                {this.state.FinanceLoader ? (
                  <div
                    class="m-3 position-relative d-block mx-auto spinner-border text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  !this.state.FinanceLoader &&
                  obj != null && (
                    <div className="modalinput">
                      <div className="row mt-2 mb-2">
                        {((this.props.ignoreStatus &&
                          AuthService.canSPView(
                            PermissionFeature.FinanceFileIgnoreExpectedAmount,
                            ImportData.regionId,
                            ImportData.facilityId,
                            ImportData.memberID
                          )) ||
                          (!this.props.ignoreStatus &&
                            AuthService.canSPView(
                              PermissionFeature.FinanceFileErrorsExpectedAmount,
                              ImportData.regionId,
                              ImportData.facilityId,
                              ImportData.memberID
                            ))) && (
                          <div className="col-sm-3">
                            <label>Expected Amount</label>
                            {(this.props.ignoreStatus &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceFileIgnoreExpectedAmount,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ||
                            (!this.props.ignoreStatus &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceFileErrorsExpectedAmount,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ? (
                              <div className="">
                                <MDBInput
                                  containerClass="mt-2 mb-2 small-prefix"
                                  className="py-0 mb-0 ml-3"
                                  name="ExpectedAmount"
                                  hint="$$"
                                  icon="dollar-sign"
                                  value={
                                    obj.ExpectedAmount != null
                                      ? formate_textbox_amount(
                                          obj.ExpectedAmount
                                        )
                                      : ""
                                  }
                                  onChange={this.handleAmountChange}
                                  group
                                  type="text"
                                />
                                <span className="d-block text-danger">
                                  {obj.ExpectedAmount_err}
                                </span>
                              </div>
                            ) : (
                              <div>
                                formate_textbox_amount( obj.ExpectedAmount )
                              </div>
                            )}
                          </div>
                        )}
                        {((this.props.ignoreStatus &&
                          AuthService.canSPView(
                            PermissionFeature.FinanceFileIgnoreAmount,
                            ImportData.regionId,
                            ImportData.facilityId,
                            ImportData.memberID
                          )) ||
                          (!this.props.ignoreStatus &&
                            AuthService.canSPView(
                              PermissionFeature.FinanceFileErrorsAmount,
                              ImportData.regionId,
                              ImportData.facilityId,
                              ImportData.memberID
                            ))) && (
                          <div className="col-sm-3">
                            <label>Amount</label>
                            {(this.props.ignoreStatus &&
                              AuthService.canSPView(
                                PermissionFeature.FinanceFileIgnoreAmount,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ||
                            (!this.props.ignoreStatus &&
                              AuthService.canSPView(
                                PermissionFeature.FinanceFileErrorsAmount,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ? (
                              <div className="">
                                <MDBInput
                                  containerClass="mt-2 mb-2 small-prefix"
                                  className="py-0 mb-0 ml-3"
                                  name="Amount"
                                  hint="$$"
                                  icon="dollar-sign"
                                  value={
                                    obj.Amount != null
                                      ? formate_textbox_amount(obj.Amount)
                                      : ""
                                  }
                                  onChange={this.handleAmountChange}
                                  group
                                  type="text"
                                />
                                <span className="d-block text-danger">
                                  {obj.Amount_err}
                                </span>
                              </div>
                            ) : (
                              <div>{formate_textbox_amount(obj.Amount)}</div>
                            )}
                          </div>
                        )}
                        {((this.props.ignoreStatus &&
                          AuthService.canSPView(
                            PermissionFeature.FinanceFileIgnoreDuesCollectionType,
                            ImportData.regionId,
                            ImportData.facilityId,
                            ImportData.memberID
                          )) ||
                          (!this.props.ignoreStatus &&
                            AuthService.canSPView(
                              PermissionFeature.FinanceFileErrorsDuesCollectionType,
                              ImportData.regionId,
                              ImportData.facilityId,
                              ImportData.memberID
                            ))) && (
                          <div className="col-sm-3">
                            <label>Dues Collection Type</label>
                            {(this.props.ignoreStatus &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceFileIgnoreDuesCollectionType,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ||
                            (!this.props.ignoreStatus &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceFileErrorsDuesCollectionType,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ? (
                              <>
                                <select
                                  //className="browser-default custom-select"
                                  onChange={
                                    this.changeHandlerDuesCollectionType
                                  }
                                  name="status"
                                  className="form-control"
                                >
                                  <option value="">Select Value</option>

                                  {DuesCollectionTypes.map((item, index) => {
                                    return (
                                      <option
                                        value={item.id}
                                        selected={
                                          obj.DuesCollectionTypeId == item.id
                                        }
                                      >
                                        {item.type}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label className="text-danger">
                                  {obj.DuesCollectionTypeId_Err}
                                </label>
                              </>
                            ) : (
                              DuesCollectionTypes.map((item, index) => {
                                if (obj.DuesCollectionTypeId == item.id) {
                                  return <div>{item.type}</div>;
                                }
                              })
                            )}
                          </div>
                        )}
                        {((this.props.ignoreStatus &&
                          AuthService.canSPView(
                            PermissionFeature.FinanceFileIgnoreRefund,
                            ImportData.regionId,
                            ImportData.facilityId,
                            ImportData.memberID
                          )) ||
                          (!this.props.ignoreStatus &&
                            AuthService.canSPView(
                              PermissionFeature.FinanceFileErrorsRefund,
                              ImportData.regionId,
                              ImportData.facilityId,
                              ImportData.memberID
                            ))) && (
                          <div className="col-sm-3">
                            {(this.props.ignoreStatus &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceFileIgnoreRefund,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ||
                            (!this.props.ignoreStatus &&
                              AuthService.canSPUpdate(
                                PermissionFeature.FinanceFileErrorsRefund,
                                ImportData.regionId,
                                ImportData.facilityId,
                                ImportData.memberID
                              )) ? (
                              <div className="pt-4 custom-checkbox">
                                <input
                                  id={"Refund"}
                                  name={"Refund"}
                                  type="checkbox"
                                  checked={obj.Refund}
                                  onChange={this.handleChangeRefund}
                                  className="custom-control-input pr-2"
                                />
                                <label
                                  className="h6 custom-control-label"
                                  for={"Refund"}
                                  style={{ paddingTop: 2 }}
                                >
                                  Refund
                                </label>
                              </div>
                            ) : (
                              <div>
                                {obj.Refund && obj.Refund == true
                                  ? "Yes"
                                  : "No"}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div class="text-right">
              <Button
                className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
                onClick={this.cancelUpdate}
              >
                Cancel
              </Button>
              {!this.props.ignoreStatus && (
                <button
                  class="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.financeIgnores}
                >
                  Ignore Update
                </button>
              )}
              <button
                class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
                type="submit"
                onClick={this.submitForm}
              >
                Process Update
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
export default ImportDetailModal;
