import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import Services from "../PACService";
import { connect } from "react-redux";
import PACTrackerAction from "../../../store/pacTracker/action";
import CandidateRequestTable from "./CandidatePreviousRequest";
import $ from "jquery";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

class CandidateDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CandidateId: this.props.pacState.candidateId,
      candidateData: {},
      ModalHeading: "CandidateDetail",
    };
    this.openModal = this.openModal.bind(this);

    this.cancelModal = this.cancelModal.bind(this);
    //this.resetModal = this.resetModal.bind(this);
    this.handleConfigureCandidateDetail = this.handleConfigureCandidateDetail.bind(
      this
    );
    //   this.getCandidates = this.getCandidates.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {
    if (this.state.CandidateId) {
      this.handleConfigureCandidateDetail();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.pacState.openCandidateModal) {
      if (this.state.CandidateId != nextProps.pacState.candidateId) {
        this.state.CandidateId = nextProps.pacState.candidateId;
        this.setState({ CandidateId: this.state.CandidateId });
        if (nextProps.pacState.candidateModalType == 1) {
          this.setState({ candidateData: nextProps.pacState.candidateData });
        } else {
          this.handleConfigureCandidateDetail();
        }
      }
      this.openModal();
    }
  }
  openModal() {
    window.$("#" + this.state.ModalHeading).modal("show");
    this.props.closeCandidateDetailModal();
  }
  handleConfigureCandidateDetail = async (e) => {
    $(".loading").show();
    let data = await Services.GetCandidates(this.state.CandidateId);
    if (!_.isEmpty(data)) {
      if (!_.isEmpty(data.data)) {
        this.setState({
          candidateData: data.data,
        });
      } else {
        this.setState({
          candidateData: {},
        });
      }
    }
    $(".loading").hide();
    $("#tableRow").removeClass("d-none");
  };

  // resetModal() {
  //   this.setState({
  //     candidateData: {},
  //   });
  // }
  cancelModal() {
    //this.resetModal();
    window.$("#" + this.state.ModalHeading).modal("hide");
  }
  render() {
    let { candidateData } = this.state;
    return (
      <AUX>
        <div
          class="modal fade"
          id={this.state.ModalHeading}
          tabindex="-1"
          role="dialog"
          aria-labelledby={this.state.ModalHeading + "Title"}
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5 class="modal-title text-uppercase text-white mt-0">
                  CANDIDATE DETAIL
                </h5>

                <button
                  type="button"
                  class="close text-white"
                  onClick={this.cancelModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body ">
                <div className="candidate-information pb-3">
                  <h5 className="mt-0 text-uppercase">
                    Candidate Information:
                    <hr className="mt-2" />
                  </h5>
                  <div className="row mx-0 pt-2">
                    {AuthService.canView(PermissionFeature.CandidateName) && (
                      <div className="col-md-6 pl-1 mb-3">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          Name:
                        </span>
                        <b className="h5 text-secondary d-block">
                          {candidateData.name}
                        </b>
                      </div>
                    )}

                    {AuthService.canView(PermissionFeature.CandidateState) && (
                      <div className="col-md-6 pl-1 mb-3">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          State:
                        </span>
                        <b className="h5 text-secondary d-block">
                          {candidateData.state}
                        </b>
                      </div>
                    )}

                    {AuthService.canView(PermissionFeature.CandidateParty) && (
                      <div className="col-md-6 pl-1 mb-3">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          Party:
                        </span>
                        <b className="h5 text-secondary d-block">
                          {candidateData.party}
                        </b>
                      </div>
                    )}

                    {AuthService.canView(
                      PermissionFeature.CandidateDistrict
                    ) && (
                      <div className="col-md-6 pl-1 mb-3">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          District:
                        </span>
                        <b className="h5 text-secondary d-block">
                          {candidateData.district}
                        </b>
                      </div>
                    )}
                  </div>
                </div>

                {AuthService.canView(PermissionFeature.Requests) &&
                  !_.isEmpty(this.state.candidateData.pacrequests) && (
                    <div className="candidate-request">
                      <h5 className="mt-0 text-uppercase">
                        Candidate Request:
                        <hr className="mt-2" />
                      </h5>

                      <CandidateRequestTable
                        CandidateRequestData={
                          this.state.candidateData.hasOwnProperty(
                            "pacrequests"
                          ) && this.state.candidateData.pacrequests
                        }
                        ModalHeading={this.state.ModalHeading}
                        history={this.props.history}
                      />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default connect((state) => ({ pacState: state.pacTracker }), {
  ...PACTrackerAction,
})(CandidateDetailModal);
