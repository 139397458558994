import React, { Component } from "react";
import Layout from "./components/Layout/Layout";
import { Route, Switch /*, useLocation*/ } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AUX from "./hoc/Aux_";
import routes from "./modules/routes";
import Auth from "./modules/Auth/AuthService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import withAuth from "./modules/withAuth";
import $ from "jquery";
// // Define a custom component that gets the current location
// const TrackLocation = (myProps) => {
//   const location = useLocation();
//   let AppLayout = null;

//   // Use the location object to determine the active component based on the URL
//   // You can use any logic you want here to map the URL to the active component
//   let activeComponent;
//   if (location.pathname === "/another") {
//     //activeComponent = AnotherComponent;
//   } else {
//     AppLayout = (

//         <Layout
//           yaar={myProps.yaar}
//           topbar={myProps.topbar}
//           footer={myProps.footer}
//           sidebar={myProps.sidebar}
//           loginpage={myProps.loginpage}
//         >
//           <Switch>
//             <Route key={window.refresh} path="/" component={routes} />
//           </Switch>
//           <ToastContainer />
//         </Layout>

//     );
//       return <AUX>{AppLayout}</AUX>;
//   }
// };
class App extends Component {
  componentDidMount() {
    $(".loading").hide();
    window.refresh = Math.random();
  }

  render() {
    let layout = null;

    layout = (
      <Layout
        yaar={this.props.yaar}
        topbar={this.props.topbar}
        footer={this.props.footer}
        sidebar={this.props.sidebar}
        loginpage={this.props.loginpage}
      >
        <Switch>
          <Route key={window.refresh} path="/" component={routes} />
        </Switch>
        <ToastContainer />
      </Layout>
    );
    return <AUX>{layout}</AUX>;
    //return  <TrackLocation myProps={this.props} />
  }
}
const mapStatetoProps = (state) => {
  return {
    topbar: state.ui_red.topbar,
    footer: state.ui_red.footer,
    sidebar: state.ui_red.sidebar,
    loginpage: state.ui_red.loginpage,
    yaar: state.ui_red.yaar,
  };
};

export default withRouter(connect(mapStatetoProps)(withAuth(App)));
