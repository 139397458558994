import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import jsPDF from "jspdf";
import RegService from "../../RegistrationsService";
import overlayFactory from "react-bootstrap-table2-overlay";
import { RegistrationTableTypes } from "../../Helpers/LookUpValues";

const TemplatesActions = (cell, row, deleteTemplate, createChildTemplate, editTemplate, handleFileListClick) => {
  return (
    <div>
      <div className="actionIcons">
        <>
          <i 
            class="cursorPointer fas fa-plus" 
            title="Create Child Template"
            onClick={() => createChildTemplate(row.id)}
          ></i>
          <i
            className="fa fa-pencil-square-o c-pointer pl-1"
            title="Edit Template"
            aria-hidden="true"
            onClick={
              () => editTemplate(row.id) 
            }
          ></i>          
          <i
            className="fas fa-images del c-pointer pl-1"
            title="Attachments"
            aria-hidden="true"
            onClick={
              () => handleFileListClick(row.id) 
            }
          ></i>
          <i
            className="text-danger fas fa-trash c-pointer pl-1"
            title="Delete"
            onClick={() => deleteTemplate(row.id)}
          ></i>
        </>
      </div>
    </div>
  );
};

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
const columns = (deleteTemplate, createChildTemplate, editTemplate, handleFileListClick) => [
  {
    text: "NAME",
    dataField: "name",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "ABBREVIATION",
    dataField: "abbreviation",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "DESCRIPTION",
    dataField: "description",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "CATEGORY", /// Template type (academy class, event, meeting)
    dataField: "category",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "DURATION",
    dataField: "duration",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "LOCATION",
    dataField: "location",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "",
    dataField: "actions",
    isDummyField: true,
    headerClasses: "text-center text-nowrap bg-dark text-white px-sm-1 py-2",
    headerAttrs: {
      width: "100px",
    },
    formatter: (cell, row) => {
      return TemplatesActions(cell, row, deleteTemplate, createChildTemplate, editTemplate, handleFileListClick);
    },
    csvExport: false,
  },
];

const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="TemplatesKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder="Template Search.."
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};

const RemotePagination = (data, deleteTemplate, createChildTemplate, editTemplate, handleFileListClick, isloading) => (
  <ToolkitProvider
    keyField="id"
    data={data}
    columns={columns(deleteTemplate, createChildTemplate, editTemplate, handleFileListClick)}
    search
  >
    {(props) => (
      <div>
        <MySearch {...props.searchProps} />
        <BootstrapTable
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: (base) => ({
                ...base,
                background: "rgba(40, 45, 42, 0.19)",
              }),
            },
          })}
          noDataIndication={() => {
            return isloading === false ? <NoDataIndication /> : <Empty />;
          }}
          loading={isloading}
          {...props.baseProps}
        />

        <br />
      </div>
    )}
  </ToolkitProvider>
);

const TemplatesListTable = (props) => {
  const { listData, deleteTemplate, createChildTemplate, editTemplate, handleFileListClick, isloading } = props;
  return <>{RemotePagination(listData, deleteTemplate, createChildTemplate, editTemplate, handleFileListClick, isloading)}</>;
};

TemplatesListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

TemplatesListTable.defaultProps = {
  listData: [],
};

export default TemplatesListTable;
