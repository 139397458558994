import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import { Progress } from "reactstrap";
import { Link } from "react-router-dom";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import TableCustomTotal from "../../modules/Shared/TableCustomTotal";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";

import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";

const NoDataIndication = () => (
  <div className="container h-100">
    <div className="row NoDataFoundTable">
      <h5>No Files Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
//This is my custom search component
const columns = [
  {
    text: "File Name",
    dataField: "file_name",
    //  filter: textFilter(),
    sort: true,
  },
  {
    text: "YEAR / PP",
    dataField: "year_period",
    // filter: textFilter(),
    sort: true,
    formatter: (cell, row) => {
      if (true) {
        return (
          <div>
            <span className="yearPP">{cell}</span>
            {row.status &&
              row.status !== "PROCESSING" &&
              row.status !== "IN QUEUE" && (
                <>
                  <i
                    className={`fas fa-exclamation-triangle fa-sm  reviewIcon cursorPointer ${
                      row.status === "COMPLETED" ? "invisible" : "visible"
                    }  `}
                  ></i>
                  {row.file_type === "Pay File 1" &&
                    AuthService.canView(
                      PermissionFeature.Payfile2ReviewSummary
                    ) && (
                      <Link
                        to={`/Uploads/PayFileOneReview/${row.file_id}`}
                        style={{ color: "#6c757d" }}
                      >
                        <i
                          className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
                          title="View Review Screen"
                        ></i>
                      </Link>
                    )}
                  {row.file_type === "Pay File 2" &&
                    AuthService.canView(
                      PermissionFeature.Payfile2ReviewSummary
                    ) && (
                      <Link
                        to={`/payfile/summarytwo/${row.file_id}`}
                        style={{ color: "#6c757d" }}
                      >
                        <i
                          className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
                          title="View Review Screen"
                        ></i>
                      </Link>
                    )}
                  {(row.file_type === "Dues Remittance" ||
                    row.file_type === "PAC Remittance" ||
                    row.file_type === "DRF Remittance") &&
                    AuthService.canView(PermissionFeature.FinanceFile) && (
                      <Link
                        to={`/financefile/${row.file_id}`}
                        style={{ color: "#6c757d" }}
                      >
                        <i
                          className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
                          title="View Review Screen"
                        ></i>
                      </Link>
                    )}
                </>
              )}
          </div>
        );
      } else return "";
    },
  },
  {
    text: "FILE TYPE",
    dataField: "file_type",
    sort: true,
    //filter: textFilter(),
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionName)) return cell;
    //   else return "";
    // },
  },
  {
    text: "STATUS",
    dataField: "status",
    sort: true,
    //filter: textFilter(),
  },
  {
    text: "RECORDS",
    dataField: "records_count",
    sort: true,
    //filter: textFilter(),
    // formatter: (cell, row) => {
    //   if (AuthService.canView(PermissionFeature.RegionRVPEmail)) {
    //     return cell;
    //   } else {
    //     return "";
    //   }
    // },
  },
  {
    text: "Skips",
    dataField: "total_ignored",
    sort: true,
  },
  {
    text: "Errors",
    dataField: "total_errors",
    sort: true,
  },
  {
    text: "Updates",
    dataField: "total_updates",
    sort: true,
  },

  {
    text: "file_id",
    dataField: "file_id",
    hidden: true,
    formatter: (cell) => {
      return cell;
    },
  },
  {
    text: "total_completed",
    dataField: "total_completed",
    hidden: true,
    formatter: (cell) => {
      return cell;
    },
  },
  {
    text: "total_added",
    dataField: "total_added",
    hidden: true,
    formatter: (cell) => {
      return cell;
    },
  },
  {
    text: "total_removed",
    dataField: "total_removed",
    hidden: true,
    formatter: (cell) => {
      return cell;
    },
  },
  ,
];

const expandRow = {
  onlyOneExpanding: true,
  renderer: (row) => (
    <>
      {row.status !== "IN QUEUE" && row.status !== "FAILED" && (
        <>
          <div className="font-italic pt-2 text-center">
            Records: ({row.total_completed}) of {row.records_count}{" "}
            <span className="font-weight-bold">
              ({Math.floor((row.total_completed / row.records_count) * 100)}) %
            </span>
          </div>
          <div className="pb-3 d-flex justify-content-center">
            {/* <p>{`This Expand row is belong to rowKey ${row.file_id}`}</p> */}

            <Progress multi>
              <Progress
                animated
                bar
                color="success"
                max={row.records_count}
                value={row.total_updates}
                className={`${row.total_updates > 0 && "updated_pg"} `}
              >
                {row.total_updates}
              </Progress>

              <Progress
                animated
                bar
                color="warning"
                max={row.records_count}
                value={row.total_ignored}
                className={`${row.total_ignored > 0 && "ignored_pg"} `}
              >
                {row.total_ignored}
              </Progress>
              <Progress
                animated
                bar
                color="danger"
                max={row.records_count}
                value={row.total_errors}
                className={`${row.total_errors > 0 && "error_pg"}`}
              >
                {row.total_errors}
              </Progress>

              <Progress
                animated
                bar
                color="info"
                max={row.records_count}
                value={row.total_removed}
                className={`${row.total_removed > 0 && "removed_pg"}`}
              >
                {row.total_removed}
              </Progress>

              {/* Fill the uncolored space */}
              {row.total_completed == row.records_count &&
                row.records_count !=
                  row.total_removed +
                    row.total_errors +
                    row.total_ignored +
                    row.total_updates && (
                  <Progress
                    animated
                    bar
                    color="secondary"
                    max={
                      row.records_count -
                      (row.total_removed +
                        row.total_errors +
                        row.total_ignored +
                        row.total_updates)
                    }
                    value={
                      row.records_count -
                      (row.total_removed +
                        row.total_errors +
                        row.total_ignored +
                        row.total_updates)
                    }
                    className={`${row.records_count -
                      (row.total_removed +
                        row.total_errors +
                        row.total_ignored +
                        row.total_updates) >
                      0 && "unfilled_pg"}`}
                  >
                    {row.records_count -
                      (row.total_removed +
                        row.total_errors +
                        row.total_ignored +
                        row.total_updates)}
                  </Progress>
                )}
            </Progress>
          </div>
        </>
      )}
    </>
  ),
};
const RemotePagination = (
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  showTotal,
  loading

  // dataSizeChange,
  // onSearch
) => (
  <div className="row">
    <div className="col-12">
      <ToolkitProvider keyField="file_id" data={data} columns={columns}>
        {(props) => (
          <>
            {/* <MySearch
              {...props.searchProps}
              onSearch={(searchText) => onSearch(searchText)}
            /> */}
            {/* <SearchBar {...props.searchProps} />, */}
            <BootstrapTable
              hover
              remote
              filter={filterFactory()}
              // remote={{ search: true }}
              keyField="file_id"
              //onDataSizeChange={dataSizeChange}
              pagination={paginationFactory({
                paginationTotalRenderer: TableCustomTotal,
                page,
                sizePerPage,
                totalSize,
                showTotal,
                sizePerPageList: [
                  {
                    text: "10",
                    value: 10,
                  },
                  {
                    text: "25",
                    value: 25,
                  },
                  {
                    text: "30",
                    value: 30,
                  },
                  {
                    text: "50",
                    value: 50,
                  },
                  {
                    text: "All",
                    value: totalSize,
                  },
                ],
              })}
              expandRow={expandRow}
              loading={loading}
              onTableChange={onTableChange}
              id="payfileSummaryListing"
              overlay={overlayFactory({
                spinner: true,
                styles: {
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(40, 45, 42, 0.19)",
                  }),
                },
              })}
              noDataIndication={() => {
                return loading === false ? <NoDataIndication /> : <Empty />;
              }}
              {...props.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
    </div>
  </div>
);

const ImportSummaryTableList = (props) => {
  const {
    listMembers,
    totalSize,
    sizePerPage,
    onTableChange,
    page,
    loading,

    //onSearch,
    //dataSizeChange,
  } = props;
  return (
    <>
      {RemotePagination(
        listMembers,
        page,
        sizePerPage,
        onTableChange,
        totalSize,
        true,
        loading

        // dataSizeChange,
        //onSearch
      )}
    </>
  );
};

ImportSummaryTableList.propTypes = {
  listMembers: PropTypes.array.isRequired,
};

ImportSummaryTableList.defaultProps = {
  listMembers: [],
};

export default ImportSummaryTableList;
