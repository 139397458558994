import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { MDBInput } from "mdbreact";
import { Route } from "react-router-dom";
import $ from "jquery";
import axios from "../../Shared/auth-header";
import AuthService from "../../Auth/AuthService";
import Scope from "../Scope/Scope";
import { toast } from "react-toastify";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import Services from "../PermissionService";
const ButtonToNavigate = ({ title, history, color, classes }) => (
    <Button
        type="button"
        onClick={() => history.push({ pathname: "/admin/positions" })}
        color={color}
        className={classes}
    >
        {title}
    </Button>
);

class AssignChildTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTeam: [],
            assignTeamData : this.props.assignTeamData,
            teamId: this.props.teamId,
            searchTerm:''
        }

        this.teamDetail = this.teamDetail.bind(this);
        this.cancelButtonBottom = this.cancelButtonBottom.bind(this);
        this.cancelAssignTeam = this.cancelAssignTeam.bind(this);
        this.saveTeamButton = this.saveTeamButton.bind(this);
  //      this.loadParentTeamScope = this.loadParentTeamScope.bind(this);
        this.handleSelectTeam = this.handleSelectTeam.bind(this);
        this.loadTeam = this.loadTeam.bind(this);

    }

  
    loadTeam = () => {
        const TeamData = this.state.assignTeamData;
        return (
            <Fragment>
                <p class="text-black-50">
                </p>
                <div id="TeamList">
                    <ul className="w-100">
                        {TeamData.filter(x => _.toLower(x.teamName).includes(
                            _.toLower(this.state.searchTerm || "")
                        )).map((item, index) => {
                            let className = "";
                            if (this.state.selectedTeam.length>0) {
                                className = (this.state.selectedTeam.findIndex(x => x == item.id) > -1) ? " active" : "";
                            }
                            
                            return (
                                <li
                                    data-id={item.id}
                                    className={"c-pointer d-md-flex px-4 border py-2 card-text w-100" + className}
                                    onClick={this.handleSelectTeam}
                                >
                                    {item.teamName}

                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Fragment>
        );
    };
    
    handleSelectTeam = e => {
        let newId = e.target.dataset.id;
        let index = this.state.selectedTeam.findIndex(x => x == newId);
        if (index == -1) {
            this.state.selectedTeam.push(newId);
        }
        else if (index > -1) {
            this.state.selectedTeam.splice(index, 1);
        }
        this.setState({ selectedTeam: this.state.selectedTeam });
    };

    teamDetail = () => {
        return (
            <Fragment>
                <div className="row align-items-center">
                    <div className="col-lg-7 text-center">
                        <form role="search" className="app-search mb-3">
                            <div className="form-group mb-0">
                                <input
                                    type="text"
                                    id="keyword"
                                    className="form-control rounded border mb-1 py-2 w-100"
                                    placeholder="Team Search.."
                                    onChange={(e) => { this.setState({ searchTerm: e.target.value }); }}
                                />
                                <button
                                    className="c-pointer text-secondary bg-transparent border-0"
                                    onClick={() => { this.setState({ searchTerm: $("#keyword").val()}); }}
                                >
                                    <i className="fa fa-search" />
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div id="teamList" className="col-lg-12">
                        {this.loadTeam()}
                    </div>
                </div>
            </Fragment>
        );
    };

    cancelButtonBottom = () => {
        return (
            <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.cancelAssignTeam}
            >
                Cancel
      </button>
        );
    };
    saveAssignTeamData = async () => {
        if (this.state.selectedTeam.length > 0) {
            $(".loading").show();
            let obj = {
                teamID: this.state.teamId,
                childTeamIDs: this.state.selectedTeam
            };
            let data = await Services.AssignTeam(obj);
            if (!_.isEmpty(data)) {
                if (data.statusCode == 200) {
                    toast.success("Team assigned successfully!");
                    this.props.setReloader(); 
                    this.cancelAssignTeam();
                }
                else {
                    toast.error(data.message);
                }
            }
            $(".loading").hide();
        }
        
    }
    cancelAssignTeam = () => {
        this.setState({ selectedTeam: [] });
        window.$("#AssignChildTeamModal").modal("hide");
    }
    saveTeamButton = () => {
        return (
            <Fragment>
                <hr />
                <div className="row pt-3">
                    <div className="col-4"></div>
                    <div className="col-8">
                        <div className="text-right">
                            {this.cancelButtonBottom()}
                            <button
                                onClick={this.saveAssignTeamData}
                                className="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                                type="submit"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    
    componentWillUnmount() { }
    componentDidMount() {
        //this.getPositions();
        this.setState({ parentTeamScope: this.props.Scope });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.teamId != this.state.teamId) {
            this.setState({ parentTeamScope: nextProps.Scope, teamId: nextProps.teamId, assignTeamData: nextProps.assignTeamData });
        }
        
    }

    render() {
        return (
            <AUX>
                <div
                    class="modal fade"
                    id="AssignChildTeamModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="AssignChildTeamModal"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-header bg-secondary py-2">
                                <h5
                                    class="modal-title text-uppercase text-white mt-0"
                                    id="CreateTeamModalTitle"
                                >
                                    ASSIGN CHILD TEAM
                </h5>
                                <button
                                    type="button"
                                    class="close text-white"
                                    data-dismiss="modal"
                                    onClick={this.cancelAssignTeam}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            Choose the team that you want to assign as a child team
                                            from the list.
                    </div>
                                        <div className="col-12">
                                            <h3 className="font-style-italic">
                                                {this.props.parentTeamName}
                                            </h3>
                                        </div>
                                    </div>
                                 
                                    {this.teamDetail()}

                                    {this.saveTeamButton()}
                                </div>
                                {/* <RolesModal rolesMethods={this.rolesMethods} />*/}
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default AssignChildTeam;
