import "../../../assets/css/TeamStyle.css";
import AUX from "../../../hoc/Aux_";
import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import Services from "../../Permissions/PermissionService";
import { debounce } from "throttle-debounce";
class AssignMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: false,
        FetchedFirst: false,
      },
      MemberId: AuthService.getProfile().memberData.id,
    };

    this.searchMember = this.searchMember.bind(this);
    this.searchDebounceMember = debounce(500, this.searchMember);
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showAssignMemberModal: false,
    });
  };
  searchMember = () => {
    let MemberDataObj = this.state.MemberObj;
    MemberDataObj.Offset = 0;
    MemberDataObj.Fetch = 10;
    MemberDataObj.SearchTerms = this.state.searchTerm;
    MemberDataObj.IsMore = true;
    MemberDataObj.FetchedFirst = true;
    this.setState({ MemberObj: MemberDataObj });
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleSelectMember = (e) => {
    let index = e.target.dataset.index;
    index = !index ? e.target.parentElement.dataset.index : index;
    let positionIndex = this.state.positionConfIndex;
    //let id = this.state.MemberId;
    let id = this.state.MemberObj.Data[index].id;
    let firstname = this.state.MemberObj.Data[index].firstName;
    let lastname = this.state.MemberObj.Data[index].lastName;
    let facility = this.state.MemberObj.Data[index].facility;

    //this.state.submissionObject.Positions[positionIndex].Member.ID = null;
    // this.state.MemberId = id
    //   ? id == newId
    //     ? null
    //     : newId
    //   : newId;

    this.props.membersMethods().setMember(this.state.MemberObj.Data[index]);
    this.handleClose();
  };

  loadMember = () => {
    let positionIndex = this.state.positionConfIndex;
    if (this.state.MemberObj.FetchedFirst) {
      this.state.MemberObj.FetchedFirst = false;
      this.loadMemberData();
    }
    const MemberData = this.state.MemberObj.Data;
    //const Member = this.state.submissionObject.Positions[positionIndex].Member;
    return (
      <Fragment>
        <p className="text-black-50">
          <b>Hint:</b> <i>Name [Email] Region/Facility</i>
        </p>
        <div id="MemberlistScroll">
          <InfiniteScroll
            dataLength={MemberData.length}
            next={this.loadMemberData}
            hasMore={this.state.MemberObj.IsMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="MemberlistScroll"
          >
            {MemberData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    "c-pointer px-4 border py-2 card-text w-100" //+ (item.id === Member.MemberId ? " active" : "")
                  }
                  onClick={this.handleSelectMember}
                  data-index={index}
                >
                  {`${item.lastName ? item.lastName + ", " : ""}${
                    item.firstName
                  } ${
                    item.nickName != null ? '"' + item.nickName + '" ' : ""
                  }`}{" "}
                  {item.email && <b>[{item.email}]</b>}
                  {" - "}
                  {item.region + "/" + item.facility}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </Fragment>
    );
  };

  loadMemberData = async () => {
    let MemberObj = this.state.MemberObj;
    let data = await Services.LoadMembers(
      MemberObj.SearchTerms,
      MemberObj.Offset,
      MemberObj.Fetch
    );
    if (data.result && !_.isEmpty(data) && data.result.length > 0) {
      if (data.statusCode == 200) {
        console.log(data.result.length, "result length:");
        console.log(this.state.MemberObj.Data.length + 10, "data length");
        var xyz = data.result.length == this.state.MemberObj.Fetch;
        this.state.MemberObj.IsMore =
          data.result.length == this.state.MemberObj.Fetch;
        this.state.MemberObj.Data = data.result;
        var abc = data.result.length == this.state.MemberObj.Fetch;
        this.state.MemberObj.Fetch =
          data.result.length == this.state.MemberObj.Fetch
            ? MemberObj.Fetch + 10
            : MemberObj.Fetch;

        await this.setState({ MemberObj: this.state.MemberObj });
      }
    }
  };

  assignMember = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <h4 className="text-primary font-weight-bold changeColor">
              Choose Member
            </h4>
          </div>
          <div className="col-lg-8 text-center">
            <form role="search" className="app-search mb-3">
              <div className="form-group mb-0">
                <input
                  type="text"
                  className="form-control rounded border mb-1 py-2 w-100"
                  placeholder="Member Search..(Type keywords)"
                  onChange={(e) => {
                    this.setState({ searchTerm: e.target.value });
                    this.searchDebounceMember();
                  }}
                />
                <button
                  type="submit"
                  className="c-pointer text-secondary bg-transparent border-0"
                >
                  <i className="fa fa-search" />
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div id="memberList" className="col-lg-10">
            {this.loadMember()}
          </div>
        </div>
      </Fragment>
    );
  };

  buttons = () => {
    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-sm-8 offset-sm-4 col-9">
            <div className="text-right">
              <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                onClick={this.handleClose}
              >
                Cancel
              </button>
              <button
                //onClick={this.verifyData}
                className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                type="submit"
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="AssignMembers"
          show={this.props.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                ASSIGN MEMBER{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.assignMember()}
            {this.buttons()}
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default AssignMember;
