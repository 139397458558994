import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import Services from "../PACService";
import { connect } from "react-redux";
import PACTrackerAction from "../../../store/pacTracker/action";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import InputMask from "react-input-mask";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { toast } from "react-toastify";
import "moment/locale/en-ca";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
// import "filepond/dist/filepond.min.css";
// import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// import FilePondPluginImageCrop from "filepond-plugin-image-crop";
// import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import { debounce } from "throttle-debounce";
import AuthService from "../../Auth/AuthService";

//registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const requestType = {
  New: "New",
  Draft: "Draft",
  Returned: "Returned",
  Submitted: "Submitted",
  Supported: "Supported",
  Approved: "Approved",
  Withdrawn: "Withdrawn",
  Denied: "Denied",
  Closed: "Closed",
};
class MyRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCandidate: {},
      selectedNatcaVist: [],
      defaultNatcaVistSelected: false,
      requestId: -1,
      searchTerm: "",
      rType: requestType.New,
      requestDetail: [],
      modalHeading: "NewRequest",
      acceptedFileTypes: ["image/png", "image/jpeg", "application/pdf"],
      submissionObj: {
        candidateid: 0,
        candidateName: "",
        status: requestType.Submitted,
        eventName: "",
        eventDate: new Date(),
        eventDateString: new Date().toString(),
        files: [],
        natcaTags: [],
        flyerJustification: "",
        holdForPickUp: false,
        newAddressCheck: false,
        newAddress: {
          addressName_err: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipcode: "",

          addressName_err: "",
          address1_err: "",
          city_err: "",
          state_err: "",
          zipcode_err: "",
        },
        poc: "",
        phone: "",
        payableTo: "",
        additionalinfo: "",
        addresses: [],
        selectedAddress: "",
        requestedamount: "",

        candidateName_err: "",
        eventName_err: "",
        eventDate_err: "",
        poc_err: "",
        phone_err: "",
        payableTo_err: "",
        requestedamount_err: "",
      },
      firstExecution: true,
      addressTypes: [],
      formateAmount: null,
      candidates: [],
      candidateid: -1,
      currentUserAddress: "",
      currentUserAddressHref: "",
      tagsOption: [],
      amount: null,
    };
    this.user = AuthService.getProfile().memberData;
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.openModal = this.openModal.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.handleChangeAddressType = this.handleChangeAddressType.bind(this);
    this.handleChangeCandidateName = this.handleChangeCandidateName.bind(this);
    this.getCandidates = this.getCandidates.bind(this);
    this.loadMemberData = this.loadMemberData.bind(this);
    this.getRequestDataModal = this.getRequestDataModal.bind(this);
    this.valueChangeHandler = this.valueChangeHandler.bind(this);
    this.newAddressChangeHandler = this.newAddressChangeHandler.bind(this);
    this.uploadFileObject = this.uploadFileObject.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.changeTags = this.changeTags.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.setDraftStatus = this.setDraftStatus.bind(this);
    this.setSubmitStatus = this.setSubmitStatus.bind(this);
    this.setSupportStatus = this.setSupportStatus.bind(this);
    this.setWithdrawStatus = this.setWithdrawStatus.bind(this);
    this.customValidation = this.customValidation.bind(this);
    this.natcaVistSearchHandler = this.natcaVistSearchHandler.bind(this);
    this.handleChangeAddressSelection = this.handleChangeAddressSelection.bind(
      this
    );
  }

  handleInitFileUpload() {
    //console.log("FilePond instance has initialised", this.pond2);
  }

  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.loadMemberData(this.state.searchTerm);
  };

  componentWillUnmount() { }
  componentDidMount() {
    let firstname =
      this.user && this.user.firstname !== null ? this.user.firstname : "";
    let lastname =
      this.user && this.user.lastname !== null ? this.user.lastname : "";
    let requesterName = firstname + " " + lastname;
    this.state.submissionObj.newAddress.addressName = requesterName;
    //this.getAddressTypes();
    //this.getCandidates();
    //this.getPrimaryAddressOfCurrentUser();
    //this.loadMemberData('');
    //if (this.state.rType == requestType.Returned) {
    //    this.getRequestHistory();
    //}
  }
  async getAddressTypes() {
    $(".loading").show();
    let data = await Services.GetAddressTypes();
    if (!_.isEmpty(data)) {
      this.setState({
        addressTypes: data.data,
      });
    }
    $(".loading").hide();
  }
  async getCandidates() {
    $(".loading").show();
    let data = await Services.GetCandidates();
    if (!_.isEmpty(data)) {
      this.state.candidates = data.data;
      var candidate = null;
      if (this.state.submissionObj.candidateid != 0) {
        candidate = this.state.candidates.filter(
          (x) => x.id == this.state.submissionObj.candidateid
        );
      }
      if (_.isEmpty(candidate)) {
        this.setState({
          candidates: this.state.candidates,
        });
      } else {
        this.setState({
          selectedCandidate: candidate[0],
          candidates: this.state.candidates,
        });
      }
    }
    $(".loading").hide();
  }
  async getRequestHistory() {
    $(".loading").show();
    let data = await Services.GetRequestHistory(this.state.requestId);
    if (!_.isEmpty(data)) {
      this.setState({
        requestHistory: data.data,
      });
    }
    $(".loading").hide();
  }

  async getPrimaryAddressOfCurrentUser() {
    $(".loading").show();
    let data = await Services.GetPrimaryAddressOfCurrentUser();
    if (!_.isEmpty(data)) {
      if (!_.isEmpty(data.data)) {
        let item = data.data;
        this.setState({
          currentUserAddressHref:
            item.address1 +
            ", " +
            item.address2 +
            item.address1 +
            ", " +
            item.city +
            ", " +
            item.state +
            " " +
            item.zipcode +
            " ",
          currentUserAddress:
            item.address1 +
            ", " +
            item.address2 +
            " " +
            item.city +
            ", " +
            item.state +
            " " +
            item.zipcode +
            " ",
        });
      }
    }
    $(".loading").hide();
  }
  componentDidUpdate(prevProps) {
    if (this.props.pacState.openRequestModal) {
      this.props.closeRequestModal();
      // props have request type
      if (
        this.props.pacState.request &&
        this.props.pacState.request.requestType
      ) {
        this.state.rType = this.props.pacState.request.requestType;
        // check first execution
        this.getAddressTypes();
        this.getCandidates();
        this.getPrimaryAddressOfCurrentUser();
        this.loadMemberData("");
        // incase request type is returned
        if (
          this.state.rType == requestType.Returned ||
          this.state.rType == requestType.Draft ||
          this.state.rType == requestType.Submitted ||
          this.state.rType == requestType.Supported
        ) {
          if (this.state.requestId != this.props.pacState.request.requestId) {
            this.state.requestId = this.props.pacState.request.requestId;
            this.setState({ requestId: this.state.requestId });
          }
          this.getRequestDataModal();
          this.openModal();
        } else {
          this.openModal();
        }
      } else {
        this.openModal();
      }
    }
  }

  getRequestDataModal = async () => {
    $(".loading").show();
    let data = await Services.GetReviewRequestDetail(this.state.requestId);
    if (!_.isEmpty(data)) {
      this.setState({
        requestDetail: !_.isEmpty(data.data) ? data.data : [],
      });
      if (!_.isEmpty(data.data)) {
        this.setExistingData();
      }

      this.openModal();
    }
    $(".loading").hide();
  };

  setExistingData = () => {
    var property = { ...this.state.submissionObj };
    property.candidateid = this.state.requestDetail.candidateiId;
    property.candidateName = this.state.requestDetail.candidateName;
    property.eventName = this.state.requestDetail.eventName;
    property.eventDate = this.state.requestDetail.eventDate;
    property.status = this.state.requestDetail.status;
    property.poc = this.state.requestDetail.poc;
    property.phone = this.state.requestDetail.phone;
    property.natcaTags = this.state.requestDetail.attendees.split(",");
    property.holdForPickUp = this.state.requestDetail.hold;
    property.additionalinfo = this.state.requestDetail.additionalinfo;
    property.payableTo = this.state.requestDetail.payableTo;
    property.flyerJustification = this.state.requestDetail.flyerJustification;
    property.selectedAddress = this.state.requestDetail.campaignAddress;
    property.newAddressCheck = false;
    if (!_.isEmpty(this.state.requestDetail.campaignAddress)) {
      property.addresses.push(this.state.requestDetail.campaignAddress);
    }

    property.requestedamount = this.state.requestDetail.requestedamount.toString();
    var num_parts = this.state.requestDetail.requestedamount
      .toString()
      .split(",")
      .join("")
      .split(".");
    var amount = num_parts.join(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.setState({
      formateAmount:
        num_parts.length > 2
          ? num_parts[0] + "." + num_parts[1]
          : num_parts.join("."),
      submissionObj: property,
    });
  };

  natcaVistSearchHandler = (SearchTerms) => {
    this.setState({ searchTerm: SearchTerms }, () => {
      this.autocompleteSearchDebounced();
    });
  };

  loadMemberData = async (SearchTerms) => {
    let data = await Services.LoadMembers(SearchTerms, 0, 5);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.tagsOption = data.result;

        if (this.state.defaultNatcaVistSelected || this.state.requestId < 1) {
          await this.setState({
            tagsOption: this.state.tagsOption,
          });
        } else {
          if (_.isEmpty(this.state.requestDetail.attendees)) {
            this.setState({
              defaultNatcaVistSelected: true,
              selectedNatcaVist: [],
              tagsOption: this.state.tagsOption,
            });
          } else {
            let data2 = await Services.LoadPreviousNatcavist(
              this.state.requestDetail.attendees
            );

            this.setState({
              defaultNatcaVistSelected: true,
              selectedNatcaVist: data2.result,
              tagsOption: this.state.tagsOption,
            });
          }
        }
      }
    }
  };
  handleChangeAddressType(e, type) {
    switch (type) {
      case "holdForPickUp":
        this.state.submissionObj.selectedAddress = "";
        this.state.submissionObj.holdForPickUp = e.target.checked;
        if (
          !e.target.checked &&
          this.state.submissionObj.addresses.length > 0
        ) {
          this.state.submissionObj.selectedAddress = this.state.submissionObj.addresses[0];
        }
        this.state.submissionObj.newAddressCheck = false;
        this.setState({ submissionObj: this.state.submissionObj });
        return;
      case "newAddress":
        this.state.submissionObj.selectedAddress = "";
        this.state.submissionObj.holdForPickUp = false;
        if (
          this.state.submissionObj.newAddressCheck &&
          this.state.submissionObj.addresses.length > 0
        ) {
          this.state.submissionObj.selectedAddress = this.state.submissionObj.addresses[0];
        }
        this.state.submissionObj.newAddressCheck = !this.state.submissionObj
          .newAddressCheck;
        this.setState({ submissionObj: this.state.submissionObj });
        return;
      default:
        return;
    }
  }

  handleChangeAddressSelection(value) {
    this.state.submissionObj.newAddressCheck = false;
    this.state.submissionObj.selectedAddress = value;
    this.setState({ submissionObj: this.state.submissionObj });
  }

  async handleChangeCandidateName(value) {
    if (!_.isEmpty(value)) {
      $(".loading").show();

      let data = await Services.GetPreviousRequestDataByCandidateId(value.id);
      this.state.submissionObj.candidateid = value.id;
      this.state.submissionObj.candidateName = value.name;
      this.state.submissionObj.candidateName_err = "";

      if (!_.isEmpty(data)) {
        if (!_.isEmpty(data.data)) {
          this.state.submissionObj.eventName = data.data.eventName;
          this.state.submissionObj.phone = data.data.pocPhone;
          this.state.submissionObj.poc = data.data.poc;
          this.state.submissionObj.payableTo = data.data.payableTo;
          this.state.submissionObj.addresses = data.data.addresses;
          this.state.submissionObj.selectedAddress = data.data.addresses[0];

          this.state.submissionObj.eventName_err = "";
          this.state.submissionObj.payableTo_err = "";
        }
      }

      $(".loading").hide();
    } else {
      this.state.submissionObj.candidateid = -1;
      this.state.submissionObj.candidateName = "";
      this.state.submissionObj.eventName = "";
      this.state.submissionObj.phone = "";
      this.state.submissionObj.poc = "";
      this.state.submissionObj.payableTo = "";
      this.state.submissionObj.addresses = [];
      this.state.submissionObj.selectedAddress = "";
    }
    this.setState({
      submissionObj: this.state.submissionObj,
      selectedCandidate: value,
    });
  }
  handleChangeAmount(e) {
    if (e.target.value != "") {
      var expression = /^[\d.,]+$/;
      if (!expression.test(e.target.value)) {
        this.state.submissionObj.requestedamount_err =
          "Invalid amount entered. Only numbers are allowed.";
        //return false;
      } else {
        this.state.submissionObj.requestedamount_err = "";
      }
    } else {
      this.state.submissionObj.requestedamount_err = "";
    }
    var num_parts = e.target.value
      .toString()
      .split(",")
      .join("")
      .split(".");
    var amount = num_parts.join(".");
    if (num_parts[0].length > 10) {
      this.setState({
        submissionObj: this.state.submissionObj,
      });
      return false;
    }
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.state.submissionObj.requestedamount = amount;
    this.setState({
      formateAmount:
        num_parts.length > 2
          ? num_parts[0] + "." + num_parts[1]
          : num_parts.join("."),
      submissionObj: this.state.submissionObj,
    });
  }

  uploadFileObject = (fileItems) => {
    var property = { ...this.state.submissionObj };
    property.files = fileItems
      .filter((el) => {
        return el.status !== 8;
      })
      .map((fileItem) => fileItem.file);
    property.flyerJustification_err = "";
    this.setState({
      submissionObj: property,
    });
  };

  changeTags = (value) => {
    var property = { ...this.state.submissionObj };
    property.natcaTags = value.map((val) => val.name);
    this.setState({
      submissionObj: property,
      selectedNatcaVist: value,
    });
  };

  valueChangeHandler = (evt) => {
    this.state.submissionObj[evt.target.name] = evt.target.value;
    this.state.submissionObj[evt.target.name + "_err"] = "";
    this.setState({
      submissionObj: this.state.submissionObj,
    });
  };

  onDateChange = (jsDate, dateString) => {
    var property = { ...this.state.submissionObj };
    property.eventDate = jsDate;
    property.eventDateString = dateString;
    this.setState({
      submissionObj: property,
    });
  };

  newAddressChangeHandler = (evt) => {
    this.state.submissionObj.newAddress[evt.target.name] = evt.target.value;
    this.state.submissionObj.newAddress[evt.target.name + "_err"] = "";
    this.setState({
      submissionObj: this.state.submissionObj,
    });
  };

  resetModal() {
    var property = { ...this.state.submissionObj };
    property.candidateid = 0;
    property.candidateName = "";
    property.status = requestType.Submitted;
    property.eventName = "";
    property.eventDate = new Date();
    property.eventDateString = new Date().toString();
    property.files = [];
    property.natcaTags = [];
    property.flyerJustification = "";
    property.holdForPickUp = false;
    property.newAddressCheck = false;
    property.newAddress.addressName = "";
    property.newAddress.address1 = "";
    property.newAddress.address2 = "";
    property.newAddress.city = "";
    property.newAddress.state = "";
    property.newAddress.zipcode = "";

    property.newAddress.addressName_err = "";
    property.newAddress.address1_err = "";
    property.newAddress.city_err = "";
    property.newAddress.state_err = "";
    property.newAddress.zipcode_err = "";
    property.poc = "";
    property.phone = "";
    property.payableTo = "";
    property.additionalinfo = "";
    property.addresses = [];
    property.selectedAddress = "";
    property.requestedamount = "";

    property.candidateName_err = "";
    property.eventName_err = "";
    property.eventDate_err = "";
    property.poc_err = "";
    property.phone_err = "";
    property.payableTo_err = "";
    property.requestedamount_err = "";

    this.setState({
      submissionObj: property,
      formateAmount: "",
      selectedCandidate: {},
      selectedNatcaVist: [],
      defaultNatcaVistSelected: false,
      requestId: -1,
      addresses: [],
    });
  }

  setDraftStatus() {
    this.state.submissionObj.status = requestType.Draft;
    this.setState({ submissionObj: this.state.submissionObj });
  }

  setSubmitStatus() {
    this.state.submissionObj.status = requestType.Submitted;
    this.setState({ submissionObj: this.state.submissionObj });
  }

  setSupportStatus() {
    this.state.submissionObj.status = requestType.Supported;
    this.setState({ submissionObj: this.state.submissionObj });
  }

  setWithdrawStatus() {
    this.state.submissionObj.status = requestType.Withdrawn;
    this.setState({ submissionObj: this.state.submissionObj });
  }

  customValidation() {
    var isValid = true;
    var property = { ...this.state.submissionObj };

    if (_.isEmpty(property.candidateName)) {
      isValid = false;
      property.candidateName_err = "Please select candidate.";
    }
    if (_.isEmpty(property.eventName)) {
      isValid = false;
      property.eventName_err = "Please enter event name.";
    }
    if (
      _.isEmpty(property.files) &&
      _.isEmpty(property.flyerJustification) &&
      this.state.requestId < 1
    ) {
      isValid = false;
      property.flyerJustification_err =
        "Please provide justification or upload the file.";
    }
    if (_.isEmpty(property.payableTo)) {
      isValid = false;
      property.payableTo_err = "Payable To cannot be empty.";
    }
    if (!_.isEmpty(property.requestedamount_err)) {
      isValid = false;
    } else if (_.isEmpty(property.requestedamount)) {
      isValid = false;
      property.requestedamount_err = "Please enter the requested amount.";
    }

    if (property.newAddressCheck) {
      if (_.isEmpty(property.newAddress.addressName)) {
        isValid = false;
        property.newAddress.addressName_err = "Please enter Name.";
      }
      if (_.isEmpty(property.newAddress.address1)) {
        isValid = false;
        property.newAddress.address1_err = "Please enter address1.";
      }
      if (_.isEmpty(property.newAddress.city)) {
        isValid = false;
        property.newAddress.city_err = "Please enter city.";
      }
      if (_.isEmpty(property.newAddress.state)) {
        isValid = false;
        property.newAddress.state_err = "Please enter state.";
      }
      if (_.isEmpty(property.newAddress.zipcode)) {
        isValid = false;
        property.newAddress.zipcode_err = "Please enter zipcode.";
      }
    }

    if (!isValid) {
      this.setState({ submissionObj: property });
    }

    return isValid;
  }

  createNewAddress = () => {
    var property = { ...this.state.submissionObj.newAddress };
    var addr =
      property.addressName +
      ", " +
      property.address1 +
      ", " +
      (property.address2 != "" ? property.address2 + ", " : "") +
      property.state +
      ", " +
      property.city +
      ", " +
      property.zipcode;
    return addr;
  };

  handleSubmit = (evt) => {
    evt.preventDefault();

    if (this.state.submissionObj.status === requestType.Draft) {
      if (_.isEmpty(this.state.submissionObj.candidateName)) {
        this.state.submissionObj.candidateName_err = "Please select candidate.";
        this.setState({ submissionObj: this.state.submissionObj });
        return false;
      }
    } else if (!this.customValidation()) {
      return false;
    }
    var formdata = new FormData();
    const data = {
      id: this.state.requestId,
      candidateiId: this.state.submissionObj.candidateid,
      candidateName: this.state.submissionObj.candidateName,
      state: null,
      eventName: this.state.submissionObj.eventName,
      eventDate: this.state.submissionObj.eventDate,
      requestedamount: this.state.submissionObj.requestedamount,
      approvedamount: null,
      status: this.state.submissionObj.status,
      natcaMember: "",
      natcaMemberId: -1,
      poc: this.state.submissionObj.poc,
      phone: this.state.submissionObj.phone,
      attendees: this.state.submissionObj.natcaTags.join(","),
      checkDate: new Date(),
      checkNumber: null,
      campaignAddress:
        this.state.submissionObj.newAddressCheck === true
          ? this.createNewAddress()
          : this.state.submissionObj.selectedAddress,
      hold: this.state.submissionObj.holdForPickUp,
      additionalinfo: this.state.submissionObj.additionalinfo,
      payableTo: this.state.submissionObj.payableTo,
      flyerFileName: "",
      flyerFilePath: "",
      isflyer: _.isEmpty(this.state.submissionObj.flyerJustification),
      flyerJustification: this.state.submissionObj.flyerJustification,
      history: null,
    };
    for (var i = 0; i < this.state.submissionObj.files.length; i++) {
      formdata.append("File" + i, this.state.submissionObj.files[i]);
    }
    formdata.append("data", JSON.stringify(data));

    this.saveRequestObject(formdata);
  };

  async saveRequestObject(formdata) {
    $(".loading").show();

    let response = await Services.SaveRequest(formdata);
    if (!_.isEmpty(response)) {
      this.props.getMyRequests();
      toast.success("Request saved successfully");
      this.cancelModal();
    }

    $(".loading").hide();
  }

  openModal() {
    window.$("#" + this.state.modalHeading).modal("show");
  }

  cancelModal() {
    this.resetModal();
    window.$("#" + this.state.modalHeading).modal("hide");
  }

  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          marginRight: 10,
          fontSize: 18,
        },
      },
    });
    let { submissionObj, rType, requestDetail } = this.state;
    let { newAddress } = submissionObj;

    return (
      <AUX>
        <div
          class="modal fade"
          id={this.state.modalHeading}
          tabindex="-1"
          role="dialog"
          aria-labelledby={this.state.modalHeading + "Title"}
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5 class="modal-title text-uppercase text-white mt-0">
                  {rType != requestType.Returned &&
                    rType != requestType.Submitted
                    ? "new request"
                    : requestDetail.candidateName}
                </h5>

                <button
                  type="button"
                  class="close text-white"
                  onClick={this.cancelModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form
                className="needs-validation"
                id="addPacRequestForm"
                onSubmit={this.handleSubmit}
                noValidate
              >
                <div className="modal-body pac-form">
                  <div className="candidate-information pb-3">
                    <h5 className="mt-0 text-uppercase">
                      Candidate Information:
                      <hr className="mt-2" />
                    </h5>
                    <div className="row mx-0">
                      {rType != requestType.Returned &&
                        rType != requestType.Submitted && (
                          <div className="col-md-6 pl-1 mb-3">
                            <Autocomplete
                              //  style={{ width: 300 }}
                              options={this.state.candidates}
                              classes={{
                                option: classes.option,
                              }}
                              autoComplete={true}
                              //autoSelect={true}
                              autoHighlight={true}
                              onChange={(e, value) => {
                                this.handleChangeCandidateName(value);
                              }}
                              value={this.state.selectedCandidate}
                              getOptionLabel={(option) => {
                                if (option != undefined) {
                                  return option.name;
                                } else {
                                  return "";
                                }
                              }}
                              renderOption={(option) => (
                                <React.Fragment>{option.name}</React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Candidate Name"
                                  placeholder="Provide Candidate Name"
                                  InputLabelProps={{
                                    shrink: true,
                                    classes: { shrink: "mt-n1" },
                                  }}
                                  fullWidth
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                            <span className="text-danger">
                              {this.state.submissionObj.candidateName_err}
                            </span>
                          </div>
                        )}
                    </div>
                    <div className="mx-0 row">
                      <div className="col-md-6 pl-1 mb-3">
                        <MDBInput
                          name="poc"
                          containerClass="mb-0"
                          value={submissionObj.poc}
                          label="Campaign POC:"
                          hint="Provide Campaign POC"
                          type="text"
                          maxlength="200"
                          onChange={this.valueChangeHandler}
                        />
                        <span className="text-danger">
                          {submissionObj.poc_err}
                        </span>
                      </div>
                      <div className="col-md-6 pl-1 mb-3">
                        <div class="md-form form-group mb-0">
                          <InputMask
                            value={submissionObj.phone}
                            //onChange={handleChangePhone}
                            name="phone"
                            mask="(999) 999-9999"
                            className="form-control"
                            type="text"
                            //id={"phone" + idx}
                            placeholder="(xxx) xxx-xxxx"
                            onChange={this.valueChangeHandler}
                          />
                          <label
                            class="active"
                            data-error=""
                            data-success=""
                            id=""
                            aria-labelledby=""
                          >
                            Campaign POC Phone:
                          </label>
                          <span class="text-danger">
                            {submissionObj.phone_err}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="event-detail pb-3">
                    <h5 className="mt-0 text-uppercase">
                      Event Details:
                      <hr className="mt-2" />
                    </h5>
                    <div className="row mx-0">
                      <div className="col-md-12 pl-1 mb-3">
                        <MDBInput
                          name="eventName"
                          containerClass="mb-0"
                          value={submissionObj.eventName}
                          label="Event Name/Description:"
                          hint="Provide Event Details"
                          type="text"
                          maxlength="500"
                          onChange={this.valueChangeHandler}
                        />
                        <span className="text-danger">
                          {submissionObj.eventName_err}
                        </span>
                      </div>
                    </div>
                    <div className="mx-0 row">
                      <div className="col-md-6 pl-1 mb-3">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>Event Date:</small>
                        </span>
                        <DatePickerInput
                          readonly="readonly"
                          onChange={this.onDateChange}
                          value={this.state.submissionObj.eventDate}
                        />
                        {/* <span className="float-right mt-n4 fa fa-lg fa-calendar-alt"></span> */}
                        <span className="text-danger">
                          {this.state.submissionObj.eventDate_err}
                        </span>
                      </div>
                      <div className="col-md-6 pl-1 pt-2 mb-3">
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={this.state.tagsOption}
                          filterOptions={(options, state) => options}
                          autoHighlight={true}
                          getOptionLabel={(option) => {
                            if (option != undefined) {
                              return option.name;
                            } else {
                              return "";
                            }
                          }}
                          ChipProps={{ classes: { root: "py-2 " } }}
                          onInputChange={(e, value, reason) => {
                            this.natcaVistSearchHandler(value);
                          }}
                          value={this.state.selectedNatcaVist}
                          onChange={(e, value, reason) => {
                            this.changeTags(value);
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  className:
                                    "pb-1" +
                                    (params.inputProps &&
                                      " " + params.inputProps.className),
                                }}
                                variant="standard"
                                label="NATCAvists Attending:"
                                InputLabelProps={{
                                  shrink: true,
                                  classes: { shrink: "mt-n1" },
                                }}
                                placeholder="Attendees"
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="pl-1 col-sm-5 mb-3" id="flyer-uploader">
                        <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                          <small>Flyer:</small>
                        </span>
                        <FilePond
                          ref={(ref) => (this.pond2 = ref)}
                          files={this.state.submissionObj.files}
                          allowMultiple={true}
                          maxFiles={1}
                          allowFileTypeValidation={true}
                          // allowImageExifOrientation={true}
                          allowImagePreview={false}
                          //allowImageCrop={true}
                          allowFileEncode={true}
                          acceptedFileTypes={this.state.acceptedFileTypes}
                          fileValidateTypeDetectType={(source, type) =>
                            new Promise((resolve, reject) => {
                              let rejectedExtensions = [
                                "ade",
                                "adp",
                                "apk",
                                "bat",
                                "chm",
                                "cmd",
                                "com",
                                "cpl",
                                "dll",
                                "dmg",
                                "exe",
                                "hta",
                                "ins",
                                "isp",
                                "jar",
                                "js",
                                "jse",
                                "lib",
                                "lnk",
                                "mde",
                                "msc",
                                "msi",
                                "msp",
                                "mst",
                                "nsh",
                                "pif",
                                "scr",
                                "sct",
                                "shb",
                                "sys",
                                "vb",
                                "vbe",
                                "vbs",
                                "vxd",
                                "wsc",
                                "wsf",
                                "wsh",
                                "cab",
                              ];
                              // If the file extension is not in our rejected list.
                              if (
                                !rejectedExtensions.some((ext) =>
                                  source.name.endsWith("." + ext)
                                )
                              ) {
                                resolve(type);
                              } else {
                                // Otherwise it is rejected.
                                toast.error("File type is unsupported!");
                                //reject(new Error('Files contains unsupported format!'));
                                reject(type);
                              }
                            })
                          }
                          oninit={() => this.handleInitFileUpload()}
                          onupdatefiles={(fileItems) => {
                            this.uploadFileObject(fileItems);
                          }}
                        />
                      </div>

                      <div className="col-md-1">
                        <h3 className="text-info text-center">OR</h3>
                      </div>
                      <div className="col-md-6 pl-1 mb-3">
                        <MDBInput
                          name="flyerJustification"
                          containerClass="mb-0"
                          value={submissionObj.flyerJustification}
                          label="Justification for Missing Flyer:"
                          hint="Provide Flyer Justification"
                          type="text"
                          maxlength="500"
                          onChange={this.valueChangeHandler}
                        />
                        <span className="text-danger">
                          {submissionObj.flyerJustification_err}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="event-detail pb-3">
                    <h5 className="mt-0 text-uppercase">
                      Check Details:
                      <hr className="mt-2" />
                    </h5>
                    <div className="row mx-0">
                      <div className="col-md-6 pl-1 mb-3">
                        <MDBInput
                          name="payableTo"
                          containerClass="mb-0"
                          value={submissionObj.payableTo}
                          label="Payable To:"
                          hint="Payable To Name"
                          type="text"
                          maxlength="200"
                          onChange={this.valueChangeHandler}
                        />
                        <span className="text-danger">
                          {this.state.submissionObj.payableTo_err}
                        </span>
                      </div>
                      <div className="col-md-6 mb-3">
                        <MDBInput
                          containerClass="mb-0"
                          className="font-weight-bold"
                          name="amountrequested"
                          label="Amount Requested"
                          hint="Provide the amount of this request"
                          icon="dollar-sign"
                          value={this.state.formateAmount}
                          maxlength="16"
                          onChange={this.handleChangeAmount}
                          //disabled={canEdit("pacdonation")}
                          group
                          type="text"
                        />
                        <span className="text-danger">
                          {submissionObj.requestedamount_err}
                        </span>
                      </div>
                    </div>
                    <div className="row mx-0">
                      <div className="row mx-0 pl-1 col-12">
                        <span className="mt-2 mb-0 h6 font-weight-normal font-italic text-secondary d-block">
                          Address:
                        </span>
                        <div className="card-text  px-4 py-1 custom-checkbox">
                          <input
                            name={"holdForPickup_1"}
                            id={"holdForPickup_1"}
                            type="checkbox"
                            onChange={(e) =>
                              this.handleChangeAddressType(e, "holdForPickUp")
                            }
                            checked={submissionObj.holdForPickUp}
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label"
                            for={"holdForPickup_1"}
                          >
                            Hold For Pickup
                          </label>
                        </div>
                      </div>
                      {!submissionObj.holdForPickUp && (
                        <div className="pl-1">
                          {submissionObj.addresses.map((element, index) => {
                            return (
                              <div className="custom-control py-1 custom-radio">
                                <input
                                  type="radio"
                                  id={`addressRadio` + index}
                                  name="addressRadio"
                                  checked={
                                    submissionObj.selectedAddress == element
                                  }
                                  className="position-absolute custom-control-input"
                                  onChange={() =>
                                    this.handleChangeAddressSelection(element)
                                  }
                                />
                                <label
                                  className="custom-control-label font-italic"
                                  for={`addressRadio` + index}
                                >
                                  <a
                                    target="blank"
                                    href={
                                      "http://maps.google.com/?q=" + element
                                    }
                                  >
                                    {element}
                                  </a>
                                </label>
                              </div>
                            );
                          })}

                          <div className="">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                this.handleChangeAddressType(e, "newAddress");
                              }}
                              className="pl-4 py-1 font-italic"
                            >
                              New Address
                            </a>
                          </div>
                          {submissionObj.newAddressCheck && (
                            <div className="row">
                              <div className="col-sm-4">
                                <MDBInput
                                  name="addressName"
                                  containerClass="mb-0"
                                  value={newAddress.addressName}
                                  onChange={this.newAddressChangeHandler}
                                  label="Name"
                                  //disabled={canEdit("addresses")}
                                  group
                                  type="text"
                                />
                                <span className="text-danger">
                                  {newAddress.addressName_err}
                                </span>
                              </div>
                              <div className="col-sm-4">
                                <MDBInput
                                  name="address1"
                                  containerClass="mb-0"
                                  value={newAddress.address1}
                                  onChange={this.newAddressChangeHandler}
                                  label="Address 1"
                                  //disabled={canEdit("addresses")}
                                  group
                                  type="text"
                                />
                                <span className="text-danger">
                                  {newAddress.address1_err}
                                </span>
                              </div>

                              <div className="col-sm-4">
                                <MDBInput
                                  name="address2"
                                  containerClass="mb-0"
                                  value={newAddress.address2}
                                  onChange={this.newAddressChangeHandler}
                                  label="Address 2"
                                  // disabled={canEdit("addresses")}
                                  group
                                  type="text"
                                />
                              </div>

                              <div className="col-sm-4">
                                <MDBInput
                                  name="city"
                                  containerClass="mb-0"
                                  value={newAddress.city}
                                  onChange={this.newAddressChangeHandler}
                                  //disabled={canEdit("addresses")}
                                  label="City"
                                  group
                                  type="text"
                                />

                                <span className="text-danger">
                                  {newAddress.city_err}
                                </span>
                              </div>

                              <div className="col-sm-4">
                                <MDBInput
                                  name="state"
                                  containerClass="mb-0"
                                  value={newAddress.state}
                                  onChange={this.newAddressChangeHandler}
                                  //disabled={canEdit("addresses")}
                                  label="State"
                                  data-name="State"
                                  group
                                  type="text"
                                />

                                <span className="text-danger">
                                  {newAddress.state_err}
                                </span>
                              </div>

                              <div className="col-sm-4">
                                <MDBInput
                                  name="zipcode"
                                  containerClass="mb-0"
                                  value={newAddress.zipcode}
                                  onChange={this.newAddressChangeHandler}
                                  label="Zipcode"
                                  data-name="Zipcode"
                                  group
                                  type="text"
                                />
                                <span className="text-danger">
                                  {newAddress.zipcode_err}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="event-detail pb-3">
                    <h5 className="mt-0 text-uppercase">
                      Additional information:
                      <hr className="mt-2" />
                    </h5>
                    <div className="pl-1">
                      <div class="md-form form-group mb-0">
                        <textarea
                          placeHolder="Add a comment"
                          name="additionalinfo"
                          containerClass="mb-0"
                          className="w-100 form-control p-2 border textareaPlaceholder"
                          value={submissionObj.additionalinfo}
                          rows="5"
                          maxlength="1000"
                          onChange={this.valueChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                  {rType == requestType.Returned &&
                    rType == requestType.Submitted && (
                      <div className="event-detail pb-3">
                        <h5 className="mt-0 text-uppercase">
                          History:
                          <hr className="mt-2" />
                        </h5>
                        <div className="history">
                          {!_.isEmpty(requestDetail.history) &&
                            requestDetail.history.map((item) => (
                              <div className="row py-1 mx-0">
                                <div className="px-3">
                                  {moment(item.actionDate).isValid()
                                    ? moment(item.actionDate).format(
                                      "MMMM DD, YYYY @ HHMM"
                                    )
                                    : ""}
                                </div>
                                <div className="px-3">
                                  <div>
                                    Request {item.actionType} by{" "}
                                    {item.memberName}
                                  </div>
                                  {!_.isEmpty(item.comments) && (
                                    <div>
                                      <b>Comments:</b> {item.comments}
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  <hr />
                  <div className="row pt-3">
                    <div className="col-sm-8 offset-sm-4">
                      <div className="text-right">
                        <button
                          className="btn btn-dark btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                          type="button"
                          onClick={this.cancelModal}
                        >
                          Cancel
                        </button>
                        {(rType == requestType.New ||
                          rType == requestType.Returned ||
                          rType == requestType.Draft) && (
                            <button
                              className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                              onClick={this.setSubmitStatus}
                              type="submit"
                            >
                              Submit
                            </button>
                          )}
                        {rType == requestType.Supported && (
                          <button
                            className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                            onClick={this.setSupportStatus}
                            type="submit"
                          >
                            Save
                          </button>
                        )}
                        {(rType == requestType.New ||
                          rType == requestType.Draft) && (
                            <button
                              className="btn btn-info btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                              onClick={this.setDraftStatus}
                              type="submit"
                            >
                              Save as Draft
                            </button>
                          )}
                        {(rType == requestType.Submitted ||
                          rType == requestType.Supported ||
                          rType == requestType.Returned) && (
                            <button
                              className="btn btn-info btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                              onClick={this.setWithdrawStatus}
                              type="submit"
                            >
                              Withdraw
                            </button>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default connect(
  (state) => ({ pacState: state.pacTracker }),
  {
    ...PACTrackerAction,
  }
)(MyRequestModal);
