import React, { Component } from "react";
import values from "lodash/values";
import PropTypes from "prop-types";
import _ from "lodash";
import TreeNode from "./TreeNode";
import { connect } from "react-redux";
import actionFilter from "../../../../store/facility/action";
import { toast } from "react-toastify";
import last from "lodash/last";

import DeleteModal from "../Modals/DeleteModal";
import FileUploadModal from "../Modals/FileUploadModal";

export class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: {},
      dataIdx: 0,
      delId: 0,
      selectedFile: null,
      isConfirmModalOpen: false,
      treeApiResponse: [],
      isModalOpen: false,
      filesList: [],
      parentId: 0,
      allowedFiles: 1,
      modalHeader: "",
    };

    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);

    this.flatten = this.flatten.bind(this);
    this.toggleModal = this.toggleModal.bind(this);

    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        const formData = {
          fileCategoryId: this.state.delId,
          facilityId: this.props.facilityId,
        };

        this.props.deleteFacilityFile(formData);

        this.setState({ delId: 0 });

        this.toggleConfirmModal();
      };
      return this;
    };

    this.updateModalFiles = (fileItems) => {
      try {
        this.setState({
          filesList: fileItems
            .filter((el) => {
              return el.status !== 8;
            })
            .map((fileItem) => fileItem.file),
        });
      } catch (err) {
        console.log(err);
      }
    };
  }
  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  fileChangedHandler = (event) => {
    //const file = event.target.files[0]
    // this.toggleModal();
    // if (!(/\.(jpe?g|png|gif|pdf)$/i.test(file.name))) {
    //   toast.error("Unsupported File Type.");
    //   return false;
    // }
    // this.setState({ selectedFile: file })
    // const parentid = event.target.dataset.id;
    // console.log('file changed handler', file)
    // const data = {
    //   parentid: parentid,
    //   facilityid: this.props.facilityId
    // };
    // var formData = new FormData();
    // formData.append(
    //   "File",
    //   event.target.files[0]
    // )
    // formData.append("data", JSON.stringify(data))
    // console.log(formData);
    // this.props.uploadfacilityfile(formData);
  };
  getRootNodes = () => {
    const { nodes } = this.state;

    return values(nodes).filter((node) => node.parentid == null);
  };

  getChildNodes = (node) => {
    const { nodes } = this.state;
    if (!node.children) return [];
    return node.children.map((path) => nodes[path]);

    // if (!treeData.children) return [];
    // return treeData.children.map(path => treeData[path]);
  };
  deletNode = (id) => {
    this.setState({ isConfirmModalOpen: true });
    this.setState({ delId: id });
  };

  onToggle = (node) => {
    if (!_.isEmpty(node)) {
      const { nodes } = this.state;
      nodes[node.path].isOpen = !node.isOpen;
      this.setState({ nodes });
    }
  };

  checkViewIconAccess = (node) => {
    var ext = "";
    if (node.parentid === 15) {
      return false;
    } else if (node.type === "file" && node.filepath) {
      ext = last(node.filepath.split("."));
    } else if (node.type === "folder") {
      let latestFileNode = this.state.treeApiResponse.find((el) => {
        return el.parentid == node.id && el.iscurrentfile === true;
      });

      if (!_.isEmpty(latestFileNode) && !_.isEmpty(latestFileNode.filepath)) {
        ext = last(latestFileNode.filepath.split("."));
      }
    }

    return (
      ext !== "xls" &&
      ext !== "xlsx" &&
      ext !== "doc" &&
      ext !== "docs" &&
      ext !== "docx"
    );
  };

  handleViewClick = (event) => {
    event.stopPropagation();
    let target = event.currentTarget;
    var id = parseInt(target.dataset.id);

    let latestFileNode = {};
    if (_.isInteger(id)) {
      latestFileNode = this.state.treeApiResponse.find((el) => {
        return el.parentid == id && el.iscurrentfile === true;
      });

      if (!_.isEmpty(latestFileNode)) {
        let currentfilepath = `/assets${
          latestFileNode.filepath.split("assets")[1]
        }`;
        target.href = currentfilepath;
        target.click();
      }
    }
  };
  handleDownloadClick = (event) => {
    event.stopPropagation();
    let target = event.currentTarget;
    var id = parseInt(target.dataset.id);

    let latestFileNode = {};
    if (_.isInteger(id)) {
      latestFileNode = this.state.treeApiResponse.find((el) => {
        return el.parentid == id && el.iscurrentfile === true;
      });

      if (!_.isEmpty(latestFileNode)) {
        let currentfilepath = `/assets${
          latestFileNode.filepath.split("assets")[1]
        }`;
        target.href = currentfilepath;
        target.download = currentfilepath.replace(/^.*[\\\/]/, "");
      }
    }
  };

  flatten = (arr) => {
    let self = this;
    if (!arr) {
      return [];
    }
    return arr.reduce(function(r, i) {
      // console.log("flatten i " + JSON.stringify(i));
      return i && r.concat([i]).concat(self.flatten(i.children));
    }, []);
  };

  arrayToObject = (array) =>
    array.reduce((obj, item) => {
      if (item && item.path && item.children) {
        obj[item.path] = item;
        obj[item.path].children = item.children.map((m) => {
          if (!(typeof m === "undefined")) {
            return m.path;
          }
        });
      } else {
        //console.log("item.childer is undefined" + JSON.stringify(item))
      }

      return obj;
    }, {});

  componentDidMount() {
    //console.log('componentDidMount Tree');
    this.getlatestFilesFromDb();
  }

  getlatestFilesFromDb = () => {
    let apiResponse = this.props.facilityDashboardState.facilityFilesTreeData
      .treeHiararcy
      ? this.props.facilityDashboardState.facilityFilesTreeData.treeHiararcy
      : [];
    let result = this.flatten(apiResponse);

    //debugger
    //console.log("The files response is:" + result)
    const objectArray = result.length !== 0 ? this.arrayToObject(result) : {};

    if (!_.isEmpty(objectArray)) {
      //this.setState({ nodes: objectArray });

      const childPathsArray = result
        .filter((item) => {
          if (item && item.children) {
            var result =
              item.children.length === 1 &&
              item.parentid !== 15 &&
              item.type !== "file";

            return result;
          }
        })
        .map((f) => {
          return f.children[0];
        });

      const filteredNodesWO1stChild = Object.keys(objectArray)
        .filter((key) => !childPathsArray.includes(key))
        .reduce((obj, key) => {
          obj[key] = objectArray[key];
          return obj;
        }, {});

      this.setState({ nodes: filteredNodesWO1stChild }, function() {
        this.onToggle(this.state.nodes["Categories"]);
      });

      this.setState({ treeApiResponse: result });
    }
  };
  handleUploadBtnClick = (event) => {
    const parentid = event.currentTarget.dataset.id;
    const modalHeader = event.currentTarget.parentElement.innerText;
    this.setState({
      parentId: parentid,
      filesList: [],
      modalHeader: modalHeader,
    });
    if (parentid > 165 && parentid < 183) {
      this.setState({ allowedFiles: 10 });
    } else {
      this.setState({ allowedFiles: 1 });
    }
    this.toggleModal();
  };

  render() {
    const rootNodes = this.getRootNodes();

    return (
      <div>
        {rootNodes.map((node, index) => {
          return (
            <TreeNode
              key={index + 1}
              node={node && node}
              getChildNodes={this.getChildNodes}
              onToggle={this.onToggle}
              //  onNodeSelect={this.onNodeSelect}
              fileChangedHandler={this.fileChangedHandler}
              deletNode={this.deletNode}
              handleViewClick={this.handleViewClick}
              handleUploadBtnClick={this.handleUploadBtnClick}
              handleDownloadClick={this.handleDownloadClick}
              checkViewIconAccess={this.checkViewIconAccess}
            />
          );
        })}
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
        <FileUploadModal
          header={this.state.modalHeader}
          parentId={this.state.parentId}
          facilityId={this.props.facilityId}
          modal={this.state.isModalOpen}
          toggle={this.toggleModal}
          updateFiles={this.updateModalFiles}
          files={this.state.filesList}
          allowedFiles={this.state.allowedFiles}
        />
      </div>
    );
  }
}

Tree.propTypes = {
  //onSelect: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({ facilityDashboardState: state.facilityDashboard }),
  {
    ...actionFilter,
  }
)(Tree);
