import AUX from "../../hoc/Aux_";
import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import axios from "./auth-header";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import AuthService from "../Auth/AuthService";
import Service from "../Admin/Users/EditUser/EditUserService";
import PermissionFeature from "../Permissions/PermissionFeature";
import * as Constants from "../Admin/Users/EditUser/PrimaryEnum";
const filter = createFilterOptions();
class AddressModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validateUSPS: true,
      address1: "",
      address2: "",
      address3: "",
      city: "",
      state: "",
      zipcode: "",
      addresstypeid: 1,
      county: "",

      States: [],
    };
    this.LoadStates = this.LoadStates.bind(this);
  }
  componentWillReceiveProps(nextProps) {}
  componentDidMount() {
    this.LoadStates();
  }
  LoadStates = async () => {
    let States = await Service.States();
    if (!_.isEmpty(States) != null) {
      this.setState({ States: States });
    }
  };
  hideModal = () => {
    this.setState({ showSaveBucketModal: false });
  };

  handleClose = () => {
    this.props.onClose();

    this.setState({
      showModal: false,
      validateUSPS: true,
      address1: "",
      address2: "",
      address3: "",
      city: "",
      state: "",
      state_error: false,
      zipcode: "",
      addresstypeid: 1,
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onShow = () => {};

  changeHandlerAddress = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({
      validateUSPS: true,
    });
    let name = e.target.name;
    if (name != "address2") {
      if (e.target.value == null || e.target.value == "") {
        this.state[e.target.name + "_err"] = "Please Provide " + e.target.name;
      } else {
        this.state[e.target.name + "_err"] = "";
      }
    }
    this.setState({ profile: this.state.profile });
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    var v = false;
    try {
      v = e.target.checkValidity();
    } catch (ex) {
      v = document.getElementById(e.target.id).checkValidity();
    }

    if (
      this.state.state == null ||
      this.state.state == "" ||
      this.state.state == undefined
    ) {
      await this.setState({ state_error: true });
    } else {
      await this.setState({ state_error: false });
    }

    if (!v || this.state.state_error == true) {
      return false;
    }
    const body = {
      address1: this.state.address1,
      address2: this.state.address2,
      address3: this.state.address3,
      city: this.state.city,
      state: this.state.state,
      zipcode: this.state.zipcode,
      addresstypeid: this.state.addresstypeid,
      county: this.state.county,
      isValidAddress: false,
    };
    if (this.state.validateUSPS) {
      this.showSpinner();
      let response = await Service.AddressUSPS(body);
      debugger;
      if (response != null) {
        this.setState({ validateUSPS: false });
        if (
          response.AddressValidateResponse &&
          response.AddressValidateResponse != null &&
          response.AddressValidateResponse.Address &&
          response.AddressValidateResponse.Address != null
        ) {
          if (
            response.AddressValidateResponse.Address.Error &&
            response.AddressValidateResponse.Address.Error != null
          ) {
            toast.error(
              response.AddressValidateResponse.Address.Error.Description
            );
            this.setState({ isValidAddress: false });

            $("#addAddressForm").removeClass(" was-validated");
            $("#addressnotverified").show();
          } else {
            let USPSaddress = response.AddressValidateResponse.Address;
            await this.setState({
              validateUSPS: false,
              address1: USPSaddress.Address2,
              address2: USPSaddress.Address1,
              city: USPSaddress.City,
              state: USPSaddress.State,
              isValidAddress: true,
              zipcode:
                USPSaddress.Zip5 +
                (USPSaddress.Zip4 != undefined &&
                USPSaddress.Zip4 != null &&
                USPSaddress.Zip4 != ""
                  ? "-" + USPSaddress.Zip4
                  : ""),
            });
            $("#addAddressForm").addClass(" was-validated");
            toast.success("USPS validation done successfully!");
            this.setState({ isValidAddress: true });
          }
        } else {
          if (response.Error) {
            await this.setState({
              isValidAddress: false,
            });
            toast.error(response.Error.Description);
            $("#addAddressForm").removeClass(" was-validated");
            debugger;
            $("#addressnotverified").show();
          } else {
            toast.error("An error occured. Please try again.");
          }
        }
      }
      this.hideSpinner();
    } else {
      var totalDisabled = $(
        ".addressMemberForm input:disabled,.addressMemberForm select:disabled"
      );
      var totalDisabledCount = 0;
      if (totalDisabled) {
        totalDisabledCount = totalDisabled.length;
      }
      if (totalDisabledCount == 6) {
        return false;
      }
      const { isValidAddress } = this.state;
      body.isValidAddress = isValidAddress;
      let Url = "/api/Member/CreateAddress?memberid=" + this.props.memberid;
      this.showSpinner();
      axios.post(Url, body).then(({ data }) => {
        this.hideSpinner();
        if (data.status) {
          if (data.status.toLowerCase() === "success") {
            toast.success(data.message);
            this.props.refreshData(this.props.memberid);
            this.handleClose();
            this.LoadStates();
          } else {
            this.hideSpinner();
            toast.error(data.message);
            this.handleClose();
          }
        } else {
          toast.error("Could not complete the opertaion.");
        }
      });
    }
  };

  render() {
    const { addressTypes } = this.props;
    return (
      <AUX>
        <Modal
          className="AddressModal latest"
          show={this.props.showModal}
          onHide={this.handleClose}
          centered
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                ADD ADDRESS{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              id="addAddressForm"
              className="form-horizontal addressMemberForm"
              onSubmit={this.handleFormSubmit}
              noValidate
            >
              <div className="row">
                <div className="col-12">
                  {
                    //addresses.map((item, idx) => (
                    <div
                      //id={`add${idx}`}
                      //key={"addform"}
                      data-repeater-list="group-a"
                    >
                      <div data-repeater-item className="row mb-4">
                        <div className="col-sm-4 align-self-center">
                          {/*<label>Address Type</label>*/}
                          {
                            <select
                              className="browser-default custom-select"
                              onChange={this.handleSelectChange}
                              id="addresstypeid"
                              name="addresstypeid"
                              value={this.state.addresstypeid}
                              required
                              disabled={
                                !AuthService.canOwnAdd(
                                  PermissionFeature.ContactAddresses,
                                  this.props.memberid
                                ) &&
                                !AuthService.canMemberAdd(
                                  PermissionFeature.ContactAddresses
                                )
                              }
                            >
                              <div className="invalid-feedback">
                                Address Type is required.
                              </div>

                              {addressTypes.map((item1, idx) => (
                                <option
                                  value={item1.id}
                                  selected={
                                    item1.id === this.state.addresstypeid
                                  }
                                >
                                  {item1.code}
                                </option>
                              ))}
                            </select>
                          }
                        </div>
                        {/*<div className="row col-11">*/}
                        <div className="col-sm-12">
                          <MDBInput
                            id="address1"
                            name="address1"
                            containerClass="mb-0"
                            value={this.state.address1}
                            onChange={this.changeHandlerAddress}
                            label="Address 1"
                            required
                            disabled={
                              !AuthService.canOwnAdd(
                                PermissionFeature.ContactAddresses,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberAdd(
                                PermissionFeature.ContactAddresses
                              )
                            }
                            type="text"
                          >
                            <div className="invalid-feedback">
                              Address1 is required.
                            </div>
                          </MDBInput>
                        </div>

                        <div className="col-sm-12">
                          <MDBInput
                            id="address2"
                            name="address2"
                            containerClass="mb-0"
                            value={this.state.address2}
                            onChange={this.changeHandlerAddress}
                            label="Address 2"
                            disabled={
                              !AuthService.canOwnAdd(
                                PermissionFeature.ContactAddresses,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberAdd(
                                PermissionFeature.ContactAddresses
                              )
                            }
                            type="text"
                          />
                        </div>

                        <div className="col-sm-4">
                          <MDBInput
                            id="city"
                            name="city"
                            containerClass="mb-0"
                            value={this.state.city}
                            onChange={this.changeHandlerAddress}
                            disabled={
                              !AuthService.canOwnAdd(
                                PermissionFeature.ContactAddresses,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberAdd(
                                PermissionFeature.ContactAddresses
                              )
                            }
                            label="City"
                            required
                            type="text"
                          >
                            <div className="invalid-feedback">
                              City is required.
                            </div>
                          </MDBInput>
                        </div>

                        <div className="col-sm-4">
                          {/*                         <MDBInput
                            id="state"
                            name="state"
                            containerClass="mb-0"
                            value={this.state.state}
                            onChange={this.changeHandlerAddress}
                            disabled={
                              !AuthService.canUpdate(
                                PermissionFeature.ContactAddresses
                              )
                            }
                            label="State"
                            required
                            type="text"
                          >
                            <div className="invalid-feedback">
                              State is required.
                            </div>
                                                </MDBInput>*/}
                          <Autocomplete
                            options={
                              !_.isEmpty(this.state.States)
                                ? this.state.States
                                : []
                            }
                            autoComplete={true}
                            autoHighlight={true}
                            selectOnFocus
                            clearOnBlur
                            onChange={async (e, value) => {
                              let valueV = "";
                              if (value && value.inputValue) {
                                this.state.state = value.inputValue;
                                valueV = value.inputValue;
                              } else {
                                this.state.state = value;
                                valueV = value;
                              }

                              if (
                                this.state.state == null ||
                                this.state.state == "" ||
                                this.state.state == undefined
                              ) {
                                await this.setState({ state_error: true });
                              } else {
                                await this.setState({ state_error: false });
                              }
                              this.setState({
                                validateUSPS: true,
                                state: this.state.state,
                              });
                            }}
                            getOptionLabel={(option) => {
                              if (option && option.inputValue) {
                                return option.inputValue;
                              }
                              if (option != undefined) {
                                return option;
                              }

                              // Regular option
                              return "";
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              // Suggest the creation of a new value
                              if (
                                params.inputValue !== "" &&
                                (_.isEmpty(filtered) ||
                                  filtered.findIndex(
                                    (x) =>
                                      x.toUpperCase() ==
                                      params.inputValue.toUpperCase()
                                  ) == -1)
                              ) {
                                filtered.push({
                                  state: `Add "${params.inputValue}"`,
                                  inputValue: params.inputValue,
                                });
                              }

                              return filtered;
                            }}
                            value={this.state.state}
                            id="state"
                            renderOption={(option) => {
                              if (option && option.state) {
                                return option.state;
                              }
                              if (option != undefined) {
                                return option;
                              }
                              // Regular option
                              return "";
                            }}
                            disabled={
                              !AuthService.canOwnAdd(
                                PermissionFeature.ContactAddresses,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberAdd(
                                PermissionFeature.ContactAddresses
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="State"
                                variant="standard"
                                error={this.state.state_error}
                                helperText={
                                  this.state.state_error
                                    ? "State is required"
                                    : ""
                                }
                                required
                                label={"State"}
                                value={this.state.state}
                                className="md-form state-container"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  id: "state",
                                  name: "state",
                                  className: "form-control",
                                  autoComplete: "disabled", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="col-sm-4">
                          <MDBInput
                            id="zipcode"
                            name="zipcode"
                            containerClass="mb-0"
                            value={this.state.zipcode}
                            onChange={this.changeHandlerAddress}
                            disabled={
                              !AuthService.canOwnAdd(
                                PermissionFeature.ContactAddresses,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberAdd(
                                PermissionFeature.ContactAddresses
                              )
                            }
                            label="Zipcode"
                            //required
                            type="text"
                          >
                            {/* <div className="invalid-feedback">
                              Zipcode is required.
                            </div> */}
                          </MDBInput>
                        </div>
                      </div>
                    </div>
                    //))
                  }
                  {/*   <hr className="mb-3" />*/}
                  <div className="col-12 text-centered d-flex justify-content-center addressValidUSPS">
                    <div className="row">
                      <div
                        style={{ color: "red", display: "none" }}
                        id="addressnotverified"
                      >
                        <span>
                          Address not verified.
                          <i className="fas fa-exclamation-triangle fa-sm"></i>{" "}
                          Save anyway?.
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <button
                        className="btn btn-sm btn-danger w-md cancelModal waves-effect waves-light"
                        type="button"
                        id="addressPopupCloseBtn"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>

                      <button
                        //onClick={this.handleFormSubmit}
                        className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                        type="submit"
                      >
                        {this.state.validateUSPS ? "Validate USPS" : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default AddressModal;
