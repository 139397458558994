import actions from "./action";

const initialState = {
  refreshFinanceTable: true,
  refreshTransactionTable: true,
  openFinanceModal: false,
  openFinanceFacEmpModal: false,
  openTransactionModal: false,
  //Finance form mode
  mode: null,
  facilityId: null,
  regionId: null,
  facilityCode: null,
  employerId: null,
  employer: null,
  FacEmpFormMode: false,
  memberId: null,
  MemberHeaderData: null,
  transactionFinanceObj: null,
  FinanceFormData: null,
  transactionFormData: null,
};

export default function Finance(state = initialState, action) {
  switch (action.type) {
    case actions.REFRESH_FINANCE_TABLE:
      return {
        ...state,
        refreshFinanceTable: action.payload,
      };
    case actions.REFRESH_TRANSACTION_TABLE:
      return {
        ...state,
        refreshTransactionTable: action.payload,
      };
    case actions.SET_TRANSACTION_FORM_FINANCE_OBJ:
      return {
        ...state,
        transactionFinanceObj: action.payload,
      };
    case actions.SET_FINANCE_FORM_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case actions.SET_FINANCE_FORM_MEMBER:
      return {
        ...state,
        memberId: action.payload,
      };
    case actions.SET_FINANCE_FAC_EMP_FORM_FACILITY:
      return {
        ...state,
        facilityId: action.payload.facilityId,
        regionId: action.payload.regionId,
        facilityCode: action.payload.facilityCode,
      };
    case actions.SET_FINANCE_FAC_EMP_FORM_EMPLOYER:
      return {
        ...state,
        employerId: action.payload.id,
        employer: action.payload.employer,
      };
    case actions.SET_FINANCE_FAC_EMP_FORM_MODE:
      return {
        ...state,
        FacEmpFormMode: action.payload,
      };
    case actions.SET_FINANCE_FORM_MEMBER_HEADER_DATA:
      return {
        ...state,
        MemberHeaderData: action.payload,
      };
    case actions.CLEAR_FINANCE_FORM_MEMBER_HEADER_DATA:
      return {
        ...state,
        MemberHeaderData: null,
      };
    case actions.OPEN_FINANCE_FORM:
      return {
        ...state,
        openFinanceModal: true,
        FinanceFormData: action.payload,
      };
    case actions.CLOSE_FINANCE_FORM:
      return {
        ...state,
        openFinanceModal: false,
        FinanceFormData: null,
      };
    case actions.OPEN_TRANSACTION_FORM:
      return {
        ...state,
        openTransactionModal: true,
        transactionFormData: action.payload,
      };
    case actions.CLOSE_TRANSACTION_FORM:
      return {
        ...state,
        openTransactionModal: false,
        transactionFormData: null,
      };
    case actions.OPEN_FINANCE_FAC_EMP_FORM: {
      return {
        ...state,
        openFinanceFacEmpModal: true,
      };
    }
    case actions.CLOSE_FINANCE_FAC_EMP_FORM:
      return {
        ...state,
        openFinanceFacEmpModal: false,
      };
    default:
      return state;
  }
}
