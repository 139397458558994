import React, { Component } from "react";

import axios from "../../Shared/auth-header";
import AUX from "../../../hoc/Aux_";
import AuthService from "../../Auth/AuthService";

import { Modal, Card, Table, Accordion, Button } from "react-bootstrap";
import $ from "jquery";
import _ from "lodash";
import arrayMove from "array-move";
import SortableComponent from "./SortableComponent";
import { toast } from "react-toastify";
import * as Constants from "../SavedSearches/Constants";
import * as Permissions from "../../Permissions/PermissionValues";
import PermissionFeature from "../../Permissions/PermissionFeature";
import * as SavedSearchTypes from "../../AdvanceSearch/SavedSearches/SavedSearchTypes";
import EmailListService from "../../Email/EmailListService";
import { connect } from "react-redux";

class CustomizeSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedSearchId: this.props.savedSearchId,
      showModal: this.props.showModal,
      availableFields: [],
      categoryFields: [],
      selectedFields: [],
      sortItems: [],
      memberId: AuthService.getProfile().memberData.id,
      items: [],
      selectedFieldsCount: 0,
      requiresResultRefresh: false,
    };

    this.showSpinner = this.showSpinner.bind(this);
    this.hideSpinner = this.hideSpinner.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal !== prevProps.showModal) {
      this.setState({
        showModal: this.props.showModal,
      });
    }
    this.updateCounts();
  }

  updateCounts = () => {
    let headers = $("div[data-header-id]");
    $(headers).each(function(index, category) {
      var totalFields = $(category).find(".available-field");
      var hiddenFields = $(category).find(".available-field.hidden");
      var visibleFields = $(totalFields).length - $(hiddenFields).length;
      $(category)
        .find(".card-header")
        .find(".field-summary")
        .html(`${visibleFields}/${$(totalFields).length}`);
    });
  };

  canCustomizeResults = () => {
    return true;
  };

  handleClose = () => {
    this.props.customizeSearchDialogClosed(
      this.state.requiresResultRefresh,
      this.state.selectedFields
    );
    this.setState({
      availableFields: [],
      selectedFields: [],
      categoryFields: [],
      showModal: false,
      requiresResultRefresh: false,
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  showSearchColumns = () => {
    this.setState({
      availableFields: [],
      selectedFields: [],
      categoryFields: [],
    });
    this.showSpinner();
    let savedSearchId = this.props.savedSearchId;
    if (!savedSearchId) {
      savedSearchId = 0;
    }
    let selectedFields = this.props.selectedFields;
    if (savedSearchId > 0) {
      selectedFields = [];
    }
    let url = `/api/SavedSearchColCategory/GetColumnCatgories?savedSearchId=${savedSearchId}&memberId=${this.state.memberId}`;
    axios.post(url, selectedFields).then(({ data: response }) => {
      /* Seniority Dates column */
      let SeniorityColumns = [
        "entryOnDutyDate",
        "serviceComputationDate",
        "bargainingUnitDate",
        "natcaBargainingUnitDate",
      ];
      let obj = response.availableFields.find(
        (obj) => obj.category_name === "Dates"
      );
      if (!AuthService.canView(PermissionFeature.FacilityMemberSeniority)) {
        if (AuthService.canView(PermissionFeature.DatesEntryOnDutyDate)) {
          _.pull(SeniorityColumns, "entryOnDutyDate");
        }
        if (
          AuthService.canView(PermissionFeature.DatesServiceComputationDate)
        ) {
          _.pull(SeniorityColumns, "serviceComputationDate");
        }
        if (AuthService.canView(PermissionFeature.DatesBargainingUnitDate)) {
          _.pull(SeniorityColumns, "bargainingUnitDate");
        }
        if (
          AuthService.canView(PermissionFeature.DatesNatcaBargainingUnitDate)
        ) {
          _.pull(SeniorityColumns, "natcaBargainingUnitDate");
        }

        obj.savedSearchColumns = obj.savedSearchColumns.filter((x) => {
          if (
            SeniorityColumns.findIndex((el) => el == x.backend_column_name) > -1
          ) {
            return false;
          }
          return true;
        });
        response.selectedFields = response.selectedFields.filter((x) => {
          if (
            SeniorityColumns.findIndex((el) => el == x.backend_column_name) > -1
          ) {
            return false;
          }
          return true;
        });
      } else if (
        AuthService.canView(PermissionFeature.FacilityMemberSeniority)
      ) {
        obj.savedSearchColumns = obj.savedSearchColumns.filter((x) => {
          if (
            SeniorityColumns.findIndex((el) => el == x.backend_column_name) > -1
          ) {
            x.permission_feature_id = PermissionFeature.FacilityMemberSeniority;
          }
          return true;
        });
        response.selectedFields = response.selectedFields.filter((x) => {
          if (
            SeniorityColumns.findIndex((el) => el == x.backend_column_name) > -1
          ) {
            x.permission_feature_id = PermissionFeature.FacilityMemberSeniority;
          }
          return true;
        });
      }
      /* End of Seniority Dates column */
      this.setState({
        availableFields: response.availableFields,
        selectedFields: response.selectedFields,
        savedSearchId: this.props.savedSearchId,
        selectedFieldsCount: response.selectedFields.length,
      });

      let fields = [];
      response.availableFields.map((category) => {
        fields = [...fields, ...category.savedSearchColumns];
      });
      this.setState({
        categoryFields: [...fields],
      });

      this.renderAvailableFields();
      this.hideSpinner();
    });
  };

  showHide(fldId) {
    let { selectedFields } = this.state;
    var existing = selectedFields.filter((fld) => {
      return fld.id === fldId;
    });
    if (existing.length > 0) {
      return " hidden";
    } else {
      return "";
    }
  }

  moveToSelected = (fieldId) => {
    let available = [...this.state.categoryFields];
    let filteredItem = available.filter((fld) => {
      return fld.id === fieldId;
    });
    let fieldToMove = filteredItem[0];
    let selectedFields = [...this.state.selectedFields];
    selectedFields.push(fieldToMove);
    this.setState({
      selectedFields: selectedFields,
      selectedFieldsCount: selectedFields.length,
    });
  };

  hasAccessToFields = (headerId) => {
    let permissionId = 0;
    let hasPermission = true;
    switch (headerId) {
      case Constants.PERSONAL_INFO_FIELDS_HEADER: // ACCOUNT:
        permissionId = Permissions.Personal_Info;
        break;

      case Constants.CONTACT_FIELDS_HEADER:
        permissionId = Permissions.Contacts;
        break;

      case Constants.MEMBERSHIP_FIELDS_HEADER:
        permissionId = Permissions.Membership;
        break;

      case Constants.ACCOUNT_FIELDS_HEADER:
        permissionId = Permissions.Account;
        break;

      case Constants.DATE_FIELDS_HEADER:
        permissionId = Permissions.Dates;
        break;

      case Constants.FINANCIAL_FIELDS_HEADER:
        permissionId = Permissions.Financial;
        break;

      case Constants.JOBS_FIELD_HEADER:
        permissionId = Permissions.Jobs;
        break;

      //case Constants.POSITIONS_FIELD_HEADER:
      //  permissionId = Permissions.Positions;
      //  break;

      //case Constants.FILTER_FIELD_HEADER:
      //  permissionId = Permissions.Filter;
      //  break;

      case Constants.COMMUNICATION_FIELD_HEADER:
        permissionId = Permissions.Communication;
        break;
    }
    if (permissionId > 0) {
      hasPermission = AuthService.canView(permissionId);
    }
    return hasPermission;
  };

  renderAvailableFields = (availableFields) => {
    if (availableFields) {
      return (
        <Accordion className="available-fields-container">
          {availableFields.map((header, idx) => {
            if (this.hasAccessToFields(header.id)) {
              return (
                <Card data-header-id={header.id} key={idx}>
                  <Card.Header className="category-header">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={header.id}
                      onClick={this._handleClick}
                    >
                      {header.category_name}
                    </Accordion.Toggle>
                    <span
                      className="field-summary"
                      style={{ float: "right", paddingTop: "6px" }}
                    ></span>
                  </Card.Header>
                  <Accordion.Collapse eventKey={header.id}>
                    <Card.Body>
                      {header.savedSearchColumns.map((fld, index) => {
                        if (AuthService.canView(fld.permission_feature_id)) {
                          return (
                            <div
                              className={
                                "available-field" + this.showHide(fld.id)
                              }
                              key={index}
                              data-field-id={fld.id}
                            >
                              {fld.column_name}
                              <i
                                class="fas fa-arrow-right"
                                style={{ float: "right" }}
                                data-field-id={fld.id}
                                title={`Move ${fld.column_name} to selected field(s).`}
                                onClick={() => {
                                  this.moveToSelected(fld.id);
                                }}
                                key={index + 1}
                              ></i>
                            </div>
                          );
                        }
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            }
          })}
        </Accordion>
      );
    }
  };

  selectedFieldSorted = (oldIndex, newIndex) => {
    let sortedFields = arrayMove(
      [...this.state.selectedFields],
      oldIndex,
      newIndex
    );
    let items = arrayMove([...this.state.items], oldIndex, newIndex);
    //sortedFields = arrayMove([...sortedFields], oldIndex, newIndex);
    this.setState({
      selectedFields: sortedFields,
      items: items,
    });
  };

  removeSelectedField = (fieldId) => {
    let selectedFields = [...this.state.selectedFields];
    let filteredItem = selectedFields.filter((fld) => {
      return fld.id === fieldId;
    });
    let fieldToRemove = filteredItem[0];
    const index = selectedFields.indexOf(fieldToRemove);
    if (index > -1) {
      selectedFields.splice(index, 1);
      if (this.props.FacilityMembers === true) {
        var items = JSON.parse(
          localStorage.getItem("FMembers_" + this.state.memberId)
        );
        if (items !== null) {
          items.splice(index, 1);
          localStorage.setItem(
            "FMembers_" + this.state.memberId,
            JSON.stringify(items)
          );
        }
      } else if (this.props.PACMembers === true) {
        var items = JSON.parse(
          localStorage.getItem("PAC_" + this.state.memberId)
        );
        if (items !== null) {
          items.splice(index, 1);
          localStorage.setItem(
            "PAC_" + this.state.memberId,
            JSON.stringify(items)
          );
        }
      } else {
        var items = JSON.parse(
          localStorage.getItem("Members_" + this.state.memberId)
        );
        if (items !== null) {
          items.splice(index, 1);
          localStorage.setItem(
            "Members_" + this.state.memberId,
            JSON.stringify(items)
          );
        }
      }
    }
    this.setState({
      selectedFields: selectedFields,
      selectedFieldsCount: selectedFields.length,
    });
  };

  canBeSubmitted = () => {
    return this.state.selectedFieldsCount > 0;
  };

  mapFieldsWithSearch = async () => {
    if (!this.state.savedSearchId || this.state.savedSearchId <= 0) {
      this.setState({
        requiresResultRefresh: true,
      });
      if (this.props.PACMembers === true) {
        localStorage.setItem(
          "PAC_" + this.state.memberId,
          JSON.stringify(this.state.selectedFields)
        );
      } else if (this.props.FacilityMembers === true) {
        localStorage.setItem(
          "FMembers_" + this.state.memberId,
          JSON.stringify(this.state.selectedFields)
        );
      } else {
        localStorage.setItem(
          "Members_" + this.state.memberId,
          JSON.stringify(this.state.selectedFields)
        );
      }

      this.state.requiresResultRefresh = true;
      this.props.customizeSearchDialogClosed(
        this.state.requiresResultRefresh,
        this.state.selectedFields
      );

      this.handleClose();
      return;
    }
    this.showSpinner();
    ///const body = this.state.selectedFields;
    let { selectedFields } = this.state;
    //selectedFields = JSON.parse(JSON.stringify(body));
    let {
      editFilterRow: {
        id = 0,
        search_type_id = 0,
        saved_search_id = 0,
        real_search_type_id = 0,
      },
    } = this.props.savedSearchState;
    let filterId = 0;
    if (search_type_id == SavedSearchTypes.SHARED_ITEM) {
      filterId = saved_search_id;
    } else {
      filterId = id;
    }
    let APIParams = {
      searchId: filterId,
      memberId: this.state.memberId, // This member id will be used to fetch Member info
      search_name: "",
      selectedColumns: selectedFields,
      membersList: this.state._dataArray,
    };
    ///const body = this.state.selectedFields;
    let response = await EmailListService.MapColumnsWithSearch(APIParams);
    if (response && response.status === "success") {
      toast.success("Field(s) successfully mapped with search.");
      this.setState({
        requiresResultRefresh: true,
      });
      this.handleClose();
    }
    this.hideSpinner();

    // axios
    //   .post(
    //     `/api/SavedSearch/MapcolumnsWithSearch?savedSearchId=${this.state.savedSearchId}&memberId=${this.state.memberId}`,
    //     body
    //   )
    //   .then(({ data: response }) => {
    //     if (response.status === "success") {
    //       toast.success("Field(s) successfully mapped with search.");
    //       this.setState({
    //         requiresResultRefresh: true,
    //       });
    //       this.handleClose();
    //     } else {
    //       toast.error(response.message);
    //     }
    //     this.hideSpinner();
    //   });
  };

  render() {
    var props = {
      items: [...this.state.selectedFields],
      dataKeyField: "id",
      textField: "column_name",
      showDeleteIcon: true,
      onItemDelete: this.removeSelectedField,
    };
    const isEnabled = this.canBeSubmitted();
    return (
      <AUX>
        <Modal
          id="result-customizer-dialog"
          className="result-customizer-dialog"
          show={this.state.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
          onEntered={this.showSearchColumns}
          onExiting={this.onModalExiting}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              <h5 className="modal-title mt-0">CUSTOMIZE RESULTS</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 col-sm-12 col-xs-12">
                <Card>
                  <Card.Header className="text-center">
                    Available Fields(s)
                  </Card.Header>
                  <Card.Body className="available-fields">
                    {this.renderAvailableFields(this.state.availableFields)}
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <Card>
                  <Card.Header className="text-center">
                    Selected Fields(s)
                  </Card.Header>
                  <Card.Body className="sortable-items">
                    {/* {this.renderSelectedFields(this.state.items)} */}
                    <SortableComponent
                      {...props}
                      onSortEnd={(oldIndex, newIndex) =>
                        this.selectedFieldSorted(oldIndex, newIndex)
                      }
                    ></SortableComponent>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="text-center">
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="success"
              disabled={!isEnabled}
              onClick={this.mapFieldsWithSearch.bind(this)}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </AUX>
    );
  }
}

//export default CustomizeSearchResults;

export default connect((state) => ({
  savedSearchState: state.saveSearch,
}))(CustomizeSearchResults);
