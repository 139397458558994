import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import jsPDF from "jspdf";
// import FEService from "../F&E/F&ERequests/FEService";
// import FERequestsType from "../F&E/FERequestsType";
import { Link } from "react-router-dom";
import EmailConfigTables from "../Enums/EmailConfigTables";
import RemoveActionType from "../Enums/RemoveActionType";
import overlayFactory from "react-bootstrap-table2-overlay";
import { Spinner } from "reactstrap";
import MailGunEmailTypes from "../Enums/MailGunEmailTypes";

const actions = (
  cell,
  row,
  setCurrentSenderInfo,
  toggleConfirm,
  confirmDeleteMember,
  keyIdfr,
  hideActionColumn,
  showReSubscribeModal
) => {
  return (
    <div className="mobileActions">
      <div className="actionIcons">
        {/* ///Authorized Senders are not registered on MailGun/// */}
        {hideActionColumn &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
        row.id != 0 &&
        row.subscribed ? (
          //Show for only Additional Subscribers Tables
          <button
            type="button"
            disabled={row.blocked}
            className="btn btn-danger unsubscrActionBtn mr-1"
            onClick={() => {
              if (keyIdfr == EmailConfigTables.NonNatcaMembers) {
                confirmDeleteMember(
                  row.id,
                  row.address,
                  keyIdfr,
                  RemoveActionType.UnSubscribe
                );
              } else {
                confirmDeleteMember(
                  row.id,
                  row.memberId,
                  keyIdfr,
                  RemoveActionType.UnSubscribe
                );
              }
              setCurrentSenderInfo(
                row.firstName,
                row.lastName,
                row.memberId,
                RemoveActionType.UnSubscribe
              );
            }}
          >
            UnSubscribe
            <i
              title="UnSubscribe"
              className="fa fa-bell-slash-o cursorPointer pl-1"
            ></i>
          </button>
        ) : (
          hideActionColumn &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
          row.id != 0 &&
          !row.subscribed && (
            <button
              type="button"
              className="btn btn-success unsubscrActionBtn mr-1"
              disabled={row.blocked}
              onClick={() => {
                showReSubscribeModal(
                  row,
                  keyIdfr,
                  RemoveActionType.ReSubscribe
                );
              }}
            >
              ReSubscribe
              <i title="UnSubscribe" className="fa fa-bell-o cursorPointer"></i>
            </button>
          )
        )}
        {hideActionColumn &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
        keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
        row.id != 0 &&
        !row.blocked ? (
          //Show for only Additional Subscribers Tables
          <button
            type="button"
            className="btn btn-danger unsubscrActionBtn mr-1"
            onClick={() => {
              if (keyIdfr == EmailConfigTables.NonNatcaMembers) {
                confirmDeleteMember(
                  row.id,
                  row.address,
                  keyIdfr,
                  RemoveActionType.Block
                );
              } else {
                confirmDeleteMember(
                  row.id,
                  row.memberId,
                  keyIdfr,
                  RemoveActionType.Block
                );
              }
              setCurrentSenderInfo(
                row.firstName,
                row.lastName,
                row.memberId,
                RemoveActionType.Block
              );
            }}
          >
            Block
            <i title="Block" className="fa fa-ban ml-1 cursorPointer"></i>
          </button>
        ) : (
          hideActionColumn &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersTeam &&
          keyIdfr !== EmailConfigTables.AuthorizedSendersMember &&
          row.id != 0 &&
          row.blocked && (
            <button
              type="button"
              className="btn btn-success unsubscrActionBtn mr-1"
              onClick={() => {
                showReSubscribeModal(row, keyIdfr, RemoveActionType.UnBlock);
              }}
            >
              UnBlock
              <i
                title="UnBlock"
                className="fa fa-unlock ml-1 cursorPointer"
              ></i>
            </button>
          )
        )}
        {/* <div className="action-icons">
          <div className="action-def-mes"> */}
        {keyIdfr != EmailConfigTables.NonNatcaMembers ? (
          <Link
            to={{
              pathname: "/directory/userAction/" + row.memberId,
            }}
            target="_blank"
          >
            <i
              className="fas fa-eye fa-md pl-1 pt-2 mr-1 cursorPointer"
              title="View Member"
            ></i>
          </Link>
        ) : (
          ///Incase it Non Natca Table Record, NO EYE ICON
          ""
        )}
        {/* </div>
        </div> */}
        {hideActionColumn && (
          <i
            title="Remove"
            className={`fas fa-trash-alt cursorPointer text-danger ${
              keyIdfr == EmailConfigTables.NonNatcaMembers ? "pr-4 pt-2" : ""
            } `}
            onClick={() => {
              if (keyIdfr == EmailConfigTables.NonNatcaMembers) {
                confirmDeleteMember(
                  row.id,
                  row.address,
                  keyIdfr,
                  RemoveActionType.Delete
                );
              } else {
                confirmDeleteMember(
                  row.id,
                  row.memberId,
                  keyIdfr,
                  RemoveActionType.Delete
                );
              }
              setCurrentSenderInfo(
                row.firstName,
                row.lastName,
                row.memberId,
                RemoveActionType.Delete
              );
            }}
          ></i>
        )}
      </div>
    </div>
  );
};

const MyExportCSV = (props) => {
  const handleClick = async () => {
    const formData = {
      recordtype: props.requestType, // Active/InAccountReview || My/Review Requests
      isExportTable: true,
      PageNo: 1,
      PageSize: 1,
      SortOrder: "id",
      activeTab: props.activeSubHeader, // Inventory Or Requests
      isAdvanceSearchApplied: props.isAdvanceSearchApplied,
      searchCriteria: props.isAdvanceSearchApplied ? props.searchCriteria : {},
      showClosed: props.showClosed,
    };
    // var response = await FEService.getDataForFEExport(formData);
    // if (!_.isEmpty(response)) {
    //   props.onExport(response.data);
    // }
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const CaptionElement = (props) => (
  <h6 className="sub_tbl_caption">{props.header}</h6>
);
// const Empty = () => <div></div>;
const columns = (
  setCurrentSenderInfo,
  toggleConfirm,
  confirmDeleteMember,
  keyIdfr,
  hideActionColumn,
  showReSubscribeModal,
  shrinkActionCol,
  emailEntityType
) => [
  {
    text: "FIRST NAME",
    dataField: "firstName",
    sort: true,
    //headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
  },
  {
    text: "LAST NAME",
    dataField: "lastName",
    sort: true,
    //headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
  },
  {
    text: "FACILITY",
    dataField: "facilityRegion",
    sort: true,
    hidden: keyIdfr && keyIdfr == EmailConfigTables.NonNatcaMembers,
    //headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
  },

  {
    dataField: "id",
    sort: true,
    hidden: true,
    csvExport: false,
  },
  {
    text: "Email",
    dataField: "address",
    sort: true,
    hidden:
      keyIdfr &&
      (keyIdfr == EmailConfigTables.AuthorizedSendersMember ||
        keyIdfr == EmailConfigTables.AuthorizedSendersTeam),
    csvExport: false,
    //headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
  },
  {
    text: "",
    dataField: "memberId",
    isDummyField: true,
    //headerClasses: "text-nowrap px-sm-3 px-2 py-2",
    classes: "pl-3 pr-2 py-2",
    headerAttrs: {
      //width: shrinkActionCol ? "25%" : "35%",
      width: (() => {
        if (window.innerWidth < 1460) {
          if (emailEntityType == MailGunEmailTypes.Facility) {
            if (shrinkActionCol) {
              // Facility Dashboard
              return "26%";
            } else {
              return "29%"; // On Edit Email Config Modal
            }
          } else if (emailEntityType == MailGunEmailTypes.Team) {
            return "24%";
          } else if (emailEntityType == MailGunEmailTypes.Member) {
            // Saved Seach
            return "34%";
          }
          return "36%";
        }
        if (window.innerWidth < 1560) {
          if (emailEntityType == MailGunEmailTypes.Facility) {
            if (shrinkActionCol) {
              return "23%"; // On Facility Dashboard
            } else {
              return "26%"; // On Edit Email Config Modal
            }
          } else if (emailEntityType == MailGunEmailTypes.Team) {
            return "25%";
          } else if (emailEntityType == MailGunEmailTypes.Member) {
            // Saved Seach
            return "29%";
          }
          return "35%";
        } else if (window.innerWidth > 1860) {
          if (emailEntityType == MailGunEmailTypes.Facility) {
            if (shrinkActionCol) {
              return "17%"; // On Facility Dashboard
            } else {
              return "25%"; // On Edit Email Config Modal
            }
          } else if (emailEntityType == MailGunEmailTypes.Team) {
            return "24%";
          } else if (emailEntityType == MailGunEmailTypes.Member) {
            // Saved Seach
            return "28%";
          }
          return "35%";
        }
      })(),
    },
    // headerStyle: (colum, colIndex) => {
    //   return { width: "%" };
    // },
    //headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
    formatter: (cell, row) => {
      return actions(
        cell,
        row,
        setCurrentSenderInfo,
        toggleConfirm,
        confirmDeleteMember,
        keyIdfr,
        hideActionColumn,
        showReSubscribeModal
      );
    },
    csvExport: false,
  },
];

const getExportFileName = (tblType, filetype) => {
  // if (tblType === FERequestsType.MyRequests) {
  //   return `${Date.now()}_MyRequestsList.${filetype}`;
  // } else if (tblType == FERequestsType.ApprovedReviewRequests) {
  //   return `${Date.now()}_ApprovedReviewRequests.${filetype}`;
  // } else if (tblType == FERequestsType.SubmittedReviewRequests) {
  //   return `${Date.now()}_SubmittedReviewRequests.${filetype}`;
  // } else if (tblType == FERequestsType.PendingEVPReviewRequests) {
  //   return `${Date.now()}_PendingReviewRequests.${filetype}`;
  // }
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.requestType, "pdf");
  const formData = {
    recordtype: allprops.requestType, // Active/InAccountReview || My/Review Requests
    isExportTable: true,
    PageNo: 1,
    PageSize: 10,
    SortOrder: "id",
    activeTab: allprops.activeSubHeader, // Inventory Or Requests
    isAdvanceSearchApplied: allprops.isAdvanceSearchApplied,
    searchCriteria: allprops.isAdvanceSearchApplied
      ? allprops.searchCriteria
      : {},
    showClosed: allprops.showClosed,
  };
  // var response = await FEService.getDataForFEExport(formData);
  // if (!_.isEmpty(response)) {
  //   var tableBody = response.data.map((val) => {
  //     var listRow = [];
  //     listRow.push(val.userName);
  //     listRow.push(val.description);
  //     listRow.push(val.requesterName);
  //     listRow.push(val.budgetLineName);
  //     listRow.push(val.categoryName);
  //     listRow.push(val.typeName);
  //     listRow.push(val.statusName);
  //     return listRow;
  //   });
  //   const doc = new jsPDF();
  //   doc.autoTable({
  //     head: [
  //       [
  //         "USER",
  //         "DESCRIPTION",
  //         "REQUESTER",
  //         "BUDGET",
  //         "CATEGORY",
  //         "TYPE",
  //         "STAUS",
  //       ],
  //     ],
  //     body: tableBody,
  //     tableWidth: "auto",
  //     styles: { cellPadding: 0.7, fontSize: 8 },
  //     columnStyles: {
  //       0: { cellWidth: 25 },
  //       1: { cellWidth: 25 },

  //       // etc
  //     },
  //   });
  //   doc.save(filename);
  //}
};

const RemotePagination = (
  data,
  setCurrentSenderInfo,
  toggleConfirm,
  loading,
  confirmDeleteMember,
  keyIdfr,
  caption,
  hideActionColumn,
  showReSubscribeModal,
  shrinkActionCol,
  emailEntityType
) => (
  <div
    // className={`Requests${requestType}_Tbl`}
    // id={`Requests_${requestType}_Records`}
    key={`Member_${keyIdfr}_Records_Parent`}
  >
    <ToolkitProvider
      keyField={
        keyIdfr == EmailConfigTables.NonNatcaMembers ? "address" : "memberId"
      }
      data={data}
      columns={columns(
        setCurrentSenderInfo,
        toggleConfirm,
        confirmDeleteMember,
        keyIdfr,
        hideActionColumn,
        showReSubscribeModal,
        shrinkActionCol,
        emailEntityType
      )}
    >
      {(props) => (
        <>
          <BootstrapTable
            keyField={
              keyIdfr == EmailConfigTables.NonNatcaMembers
                ? "address"
                : "memberId"
            }
            loading={!_.isEmpty(data) && loading}
            overlay={overlayFactory({
              spinner: true,
              styles: {
                overlay: (base) => ({
                  ...base,
                  background: "rgba(40, 45, 42, 0.19)",
                }),
              },
            })}
            noDataIndication={() => {
              return loading === false ? (
                <NoDataIndication />
              ) : (
                <div className="d-flex justify-content-center p-4">
                  <Spinner />
                </div>
              );
            }}
            caption={<CaptionElement header={caption} />}
            {...props.baseProps}
          />
        </>
      )}
    </ToolkitProvider>
  </div>
);

//Main Component
const EmailConfigMemberTable = (props) => {
  const {
    listData,
    setCurrentSenderInfo,
    toggleConfirm,
    loading,
    confirmDeleteMember,
    keyIdfr,
    caption,
    hideActionColumn,
    showReSubscribeModal,
    shrinkActionCol,
    emailEntityType,
  } = props;

  return (
    <>
      {RemotePagination(
        listData,
        setCurrentSenderInfo,
        toggleConfirm,
        loading,
        confirmDeleteMember,
        keyIdfr,
        caption,
        hideActionColumn,
        showReSubscribeModal,
        shrinkActionCol,
        emailEntityType
      )}
    </>
  );
};

EmailConfigMemberTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

EmailConfigMemberTable.defaultProps = {
  listData: [],
};

export default EmailConfigMemberTable;
