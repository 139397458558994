import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class MemberStatus extends Component {
  onChange(item) {
    var memberStatusIndex = _.findIndex(
      this.props.filterState.filterData.memberStatus,
      (o) => {
        return o.memberStatus === item.memberStatus;
      }
    );
    item.checked = !this.props.filterState.filterData.memberStatus[
      memberStatusIndex
    ].checked;
    this.props.filterState.filterData.memberStatus[memberStatusIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item) {
    return (
      <li class="chechbox-list_item" key={"msliitem-" + item.memberStatus}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"msitem-" + item.memberStatus}
        >
          <input
            type="checkbox"
            htmlFor={"msitem-" + item.memberStatus}
            value={item.memberStatus}
            checked={item.checked}
            className="custom-control-input"
            id={"msitem-" + item.memberStatus}
            name={"msitem-" + item.memberStatus}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            htmlFor={"msitem-" + item.memberStatus}
            style={{ color: this.props.isMobile ? "white" : null }}
          >
            {item.memberStatus}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Member Status</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="filter-fields-scroll">
          <ul className="chechbox-list">
            {this.props.filterState.filterData.memberStatus &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.memberStatus.map((item) =>
                this.chechboxes(item)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(MemberStatus);
