import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AUX from "../../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import PACHeader from "../General/PACHeader";
import CandidateDelailModal from "./CandidateDetailModal";
import Services from "../PACService";
import "../../../assets/css/PacStyle.css";
import moment from "moment";
import { connect } from "react-redux";
import PACTrackerAction from "../../../store/pacTracker/action";
import AddCandidateModal from "./AddCandidateModal";
import DeleteModal from "../../Dashboard/FacilityDashboard/Modals/DeleteModal";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

const tempData = [];
class Candidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateData: [],
      positionsOptions: [],
      selectAll: false,
      searchTerm: "",
      sizePerPage: 10,
      isConfirmModalOpen: false,
      delId: 0,
    };

    this.rowClasses = (row, rowIndex) => {
      let classes = "c-pointer";

      if (row.processstatus == "Draft") {
        classes += " bg-light-blue";
      }

      return classes;
    };
    this.setReloader = this.setReloader.bind(this);
    this.formate_amount = this.formate_amount.bind(this);
    this.filterMyRequest = this.filterMyRequest.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this);
    this.deleteCandidate = this.deleteCandidate.bind(this);
    this.deleteCandidateCall = this.deleteCandidateCall.bind(this);
    this.editCandidate = this.editCandidate.bind(this);

    this.getColumn = this.getColumn.bind(this);
    this.getCandidates = this.getCandidates.bind(this);
    this.getCandidates();
    this.viewAllLink = this.viewAllLink.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);

    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        this.deleteCandidateCall();

        this.setState({ delId: 0 });
        this.toggleConfirmModal();
      };
      return this;
    };
  }
  viewAllLink = (data) => {
    this.setState({ sizePerPage: data.length });
  };
  formate_amount = (n) => {
    var num_parts = n.toString().split(".");
    num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };
  deleteCandidate = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      delId: id,
    });

    this.toggleConfirmModal();
  };

  async deleteCandidateCall() {
    $(".loading").show();
    let data = await Services.DeleteCandidate(this.state.delId);

    if (!_.isEmpty(data)) {
      $(".loading").hide();
      this.getCandidates();
    }
    $(".loading").hide();
  }

  async getCandidates() {
    $(".loading").show();
    let data = await Services.GetCandidates(null);
    if (!_.isEmpty(data)) {
      if (!_.isEmpty(data.data)) {
        this.setState({
          candidateData: data.data,
        });
      }
    }
    $(".loading").hide();
    $("#tableRow").removeClass("d-none");
  }

  editCandidate = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openAddCandidateModal(row);
  };

  getColumn() {
    return [
      {
        dataField: "name",
        text: "Candidate Name",
        sort: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
      },

      {
        text: "State",
        dataField: "state",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2",
      },
      {
        text: "Party",
        dataField: "party",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2",
      },
      {
        text: "District",
        dataField: "district",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2",
      },
      {
        isDummyField: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "py-2",
        formatter: (cellContent, row) => {
          return (
            <>
              <div className="text-center">
                {AuthService.canUpdate(PermissionFeature.Candidates) && (
                  <span
                    className="text-secondary fas fa-edit mr-2 c-pointer"
                    onClick={(e) => {
                      this.editCandidate(e, row);
                    }}
                  ></span>
                )}

                {AuthService.canDelete(PermissionFeature.Candidates) && (
                  <span
                    className="text-danger fas fa-trash c-pointer"
                    onClick={(e) => {
                      this.deleteCandidate(e, row.id);
                    }}
                  ></span>
                )}
              </div>
            </>
          );
        },
      },
    ];
  }

  handleOnSelectAll = (e) => {
    this.state.selectAll = e.target.checked;

    let checked = this.filterMyRequest();
    checked = checked.map((item) => {
      item.checked = e.target.checked;
      return item;
    });
    this.setState({ candidateData: this.state.candidateData });
  };
  filterMyRequest() {
    return this.state.candidateData.filter((x) =>
      _.toLower(x.name).includes(_.toLower(this.state.searchTerm || ""))
    );
  }
  rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      e.stopPropagation();

      this.props.openCandidateDetailModal({
        candidateId: row.id,
        candidateModalType: 1,
        candidateData: row,
      });
    },
  };
  componentDidMount() {}
  setReloader() {
    this.setState({ reloadEverything: true });
  }
  componentDidUpdate() {}
  componentWillUnmount() {}
  SizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    rowClasses,
    rowEvents
  ) {
    let paginationOptions = {
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
    };

    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowClasses={rowClasses}
          rowEvents={rowEvents}
        />
        {data.length > sizePerPage && (
          <a
            href="#"
            className="pl-1"
            onClick={(e) => {
              e.preventDefault();
              this.viewAllLink(data);
            }}
          >
            View All
          </a>
        )}
      </div>
    );
  }
  render() {
    const columns = this.getColumn();

    const { candidateData } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <PACHeader />
        <div className="pac-container container-fluid">
          <div className="page-title-box">
            <div className="row pt-3 align-items-center">
              <div className="col-12">
                <h4 className="text-uppercase mt-0 mb-3 page-title-search">
                  Candidates
                </h4>
              </div>
              <div className="col-lg-6">
                <form role="search" className="app-search mt-0">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      id="keyword"
                      className="form-control rounded border mb-1 w-100"
                      placeholder="Search By Candidate Name.."
                      onChange={(event) =>
                        this.setState({ searchTerm: event.target.value })
                      }
                    />
                    <button
                      type="button"
                      className="c-pointer text-secondary bg-transparent border-0"
                      onClick={(event) =>
                        this.setState({ searchTerm: $("#keyword").val() })
                      }
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row" id="tableRow">
            <div className="col-12">
              {!_.isEmpty(this.filterMyRequest()) ? (
                this.RemotePagination(
                  columns,
                  "id",
                  this.filterMyRequest(),
                  this.SizePerPageChange,
                  this.state.sizePerPage,
                  this.rowClasses,
                  this.rowEvents
                )
              ) : (
                <p className="mt-4 text-center">No Candidates Are Available</p>
              )}
            </div>
          </div>
        </div>
        <CandidateDelailModal />
        <AddCandidateModal getCandidates={this.getCandidates} />
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </AUX>
    );
  }
}

export default connect((state) => ({ pacState: state.pacTracker }), {
  ...PACTrackerAction,
})(Candidate);
