import AUX from "../../../hoc/Aux_";
import React, { Component } from "react";
import { connect } from "react-redux";
import arrayMove from "array-move";
import $ from "jquery";
import _ from "lodash";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import { Modal } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import SaveBucketSearch from "./SaveBucketSearch";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import savedSearchAction from "../../../store/saveSearch/action";
const array = [];
class MemberBucket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: "",
      memberSearchText: "",
      memberSearchText_err: "",
      valid: false,
      redirectToNewPage: false,
      addMember: {
        MemberType: "",
        AssignmentTypeId: 1,
        MemberId: 0,
        PositionId: 0,
      },
      memberMethods: this.props.memberMethods,

      membersMethods: this.props.membersMethods,
      memberName: this.props.memberName,
      isLookup: this.props.isLookup || false,
      page: 1,
      facility: this.props.facility ? this.props.facility : "",
      data: [],
      sizePerPage: 25,
      totalCount: 0,
      keyword: "",
      SortOrder: "seniorityasc",
      isAdvanceSearch: false,
      delId: 0,
      filters: [],
      showActive: true,
      //memberId: AuthService.getProfile().memberData.id,
      memberId: AuthService.getProfile().memberData.id,
      items: [],
      resultData: [],
      initialItems: [],
      itemIndexes: [],
      itemColumnIds: [],
      replacingMemberId: 0,
      replacingMemberName: "",
      searchId: this.props.searchid,
      searchTypeId: 0,
      showModal: false,
      dataArray: [],
      isOpen: false,
      SearchName: "",
      searchMember: "",
      delId: "",
      showSaveBucketModal: false,
      listPaging: {
        currentPage: 1,
        pageSize: 25,
        orderBy: "name",
        sortOrder: "asc",
      },
      paginationSettings: {
        showTotal: true,
      },
      memberList: [],
      memberList2: [],
    };

    this.handleEvent = this.handleEvent.bind(this);
    this.exportToPDF = this.exportToPDF.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.membersMethods = () => {
      this.setMember = (args) => {
        if (array.find((x) => x.id != args.id) || array.length === 0) {
          array.push(args);
          this.setState({
            dataArray: this.state.dataArray.concat(array),
            showModal: false,
          });
        }
      };
      return this;
    };

    this.columns = [
      {
        text: "First Name",
        dataField: "firstName",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "40%", textAlign: "left" };
        },
      },
      {
        text: "Last Name",
        dataField: "lastName",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "35%", textAlign: "left" };
        },
      },

      {
        text: "Facility",
        dataField: "facility",
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: "25%", textAlign: "left" };
        },
        formatter: (cellContent, row) => {
          return (
            <div className="row">
              <div className="col-sm-3 tblBucket">{cellContent}</div>
              <div className="col-sm-9 text-danger text-right tblBucket">
                <i
                  className="fas pr-2 fa-trash-alt del"
                  //style={{ color: "red", right: "20px", position: "absolute" }}
                  onClick={() => {
                    this.removeSelected(row.id);
                  }}
                />
              </div>
            </div>
          );
        },
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      memberList: nextProps.displayMembers,
      memberList2: nextProps.displayMembers,
    });
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      this.props.handleDelete(row.id);
    },
  };

  removeSelected = (id) => {
    this.props.handleDelete(id);
  };

  iterator = (currentPage, list, paging) => {
    var current = currentPage,
      l = list.length;
    var end = paging;

    let skip = (currentPage - 1) * paging;
    if (skip < 0) {
      skip = 0;
    }
    let take = skip + paging;
    let temp = [...list];

    temp = temp.slice(skip, take);

    var part = temp;
    currentPage = end < l ? end : 0;
    return part;
  };

  updateMemberList = (pagingDetail) => {
    this.showSpinner();
    this.setState({
      memberList: this.iterator(
        pagingDetail.currentPage,
        this.props.displayMembers,
        pagingDetail.pageSize
      ),
    });
    this.hideSpinner();
  };

  EmptyBucket = () => {
    this.setState({ displayMembers: [] });

    this.props.Empty();
    localStorage.removeItem("BucketSearch");

    this.handleClose();
  };

  showSaveBucketModal = () => {
    if (this.props.SavedSearch.BucketSearchId == 0) {
      this.setState({ showSaveBucketModal: true });
    } else {
      this.setState({ showSaveBucketModal: false });
      this.onSaveBucketSearch();
    }
  };

  onSaveBucketSearch = () => {
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    const member_ids = [];
    for (let i = 0; i < items.length; i++) {
      member_ids.push(items[i].id);
    }

    let postBucketSearch = "/api/SavedSearch/SaveBucketResult";
    const body = {
      SearchName: this.state.SaveBucketName,
      SearchType: 2,
      MemberId: this.props.memberId,
      member_ids: member_ids,
      SearchId: this.props.SavedSearch.BucketSearchId,
      search_criteria: this.props.search_criteria,
    };

    this.showSpinner();
    axios.post(postBucketSearch, body).then(({ data }) => {
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          this.hideSpinner();
          this.state.SaveBucketName = "";
          toast.success(data.message);

          localStorage.removeItem("BucketSearch");
          this.handleClose();

          this.props.setSavedSearchCriteria(null);
          this.props.updateAndSelect(data.searchId, true);
          this.UpdateBucketSearch();
        } else {
          this.hideSpinner();
          toast.error(data.message);
        }
      } else {
        this.hideSpinner();
        toast.error("Search cannot be saved.");
      }
    });
  };

  UpdateBucketSearch = () => {
    if (!_.isEmpty(this.props.SavedSearch.SavedSearchDetail)) {
      let postUrl = "/api/SavedSearch/UpdateSavedSearch";

      const body = {
        SearchName: this.props.SavedSearch.SavedSearchDetail.search_name,
        SearchType: this.props.SavedSearch.SavedSearchDetail.search_type_id,
        MemberId: this.state.memberId,
        SearchCriteria: "",
        id: this.props.SavedSearch.BucketSearchId,
        totalrecords: 0,
      };
      this.showSpinner();
      axios.post(postUrl, body).then(({ data }) => {
        this.hideSpinner();
        if (data.status) {
          if (data.status.toLowerCase() === "success") {
            this.props.setSavedSearchCriteria(null);
            this.props.updateAndSelect(
              this.props.SavedSearch.SavedSearchDetail.id,
              true
            );
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("Search cannot be updated.");
        }
      });
    }
  };

  hideModal = () => {
    this.setState({ showSaveBucketModal: false });
  };

  getSelectedMembers = () => {
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    this.setState({ displayMembers: items });
  };

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showBucketModal: false,
    });
  };

  handleEvent() {
    this.getMembers(this.state.memberSearchText, "");
  }
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  getMembers(key, facility) {
    const url = `/api/member/SearchMembers`;
    const formData = {
      Keyword: key,
      Facility: facility,
      PageNo: 1,
      PageSize: 10,
      ShowOnlyActive: true,
    };
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,

          isOpen: true,
        });

        if (!_.isEmpty(key)) {
          $("#keyword").val(key);
        }

        $(".loading").hide();
      } else {
        $(".loading").hide();
        toast.error(data.message);
      }
    });
  }

  getBucketMembers() {
    const url = `/api/SavedSearch/GetBucketSearch/`;

    const body = {
      member_id: this.state.memberid,
      save_search_id: this.state.searchId,
    };
    axios.post(url, body).then(({ data }) => {
      if (data.status.toLowerCase() === "success") {
        toast.success(data.message);
        $(".cancelModal").trigger("click");
      } else {
        toast.error(data.message);
      }
    });
  }

  onShow = () => {
    this.updateMemberList(this.state.listPaging);
  };

  filterMembers = (Name) => {
    console.log(this.state.displayMembers);
    if (Name != "") {
      var names = Name.split(" ");
      if (names.length > 1) {
        // search in first and last name
        this.setState(() => ({
          memberList: this.state.memberList2.filter(
            (el) =>
              el.firstName
                .toLowerCase()
                .startsWith(names[0].trim().toLowerCase()) &&
              el.lastName
                .toLowerCase()
                .startsWith(names[1].trim().toLowerCase())
          ),
        }));
      } else {
        // search in last name
        this.setState(() => ({
          memberList: this.state.memberList2.filter((el) =>
            el.lastName.toLowerCase().startsWith(Name.trim().toLowerCase())
          ),
        }));
      }
    } else {
      this.setState({
        memberList: this.props.displayMembers,
      });
    }
  };

  ViewAll = () => {
    this.props.ViewBucket();
    this.handleClose();

    // this.state.listPaging.pageSize = this.props.displayMembers.length;
    // this.state.listPaging.currentPage = 0;

    // this.setState({
    //   memberList: this.iterator(
    //     0,
    //     this.props.displayMembers,
    //     this.props.displayMembers.length + 1
    //   ),
    // });
  };

  // Function to export data to PDF
  exportToPDF = () => {
    $(".loading").show();
    const { memberList2 } = this.state;
    // Create a new jsPDF instance
    const doc = new jsPDF();
    // Customize PDF styles
    doc.setFont("Roboto");
    doc.setFontSize(12); // Set font size

    // Define the table columns for the PDF
    const pdfColumns = this.columns.map((column) => {
      return { text: column.text, field: column.dataField };
    });
    const columnsKeys = this.columns.map((column) => column.dataField);

    // Define the table data for the PDF
    //const pdfData = memberList.map((row) => Object.values(row));
    var filterObj = memberList2.map((item) =>
      Object.keys(item)
        .filter((key) => columnsKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {})
    );
    const pdfData = filterObj.map((row) => {
      /*based on column get key and then fill array
       */
      let return_arr = [];
      for (var i = 0; i < pdfColumns.length; i++) {
        return_arr.push(row[pdfColumns[i].field]);
      }
      return return_arr;
    });
    let pdfColumns2 = this.columns.map((column) => column.text);
    // Add the table to the PDF
    doc.autoTable({
      head: [pdfColumns2],
      body: pdfData,
      startY: 20, // Set the Y position where the table starts
      theme: "grid", // Use the 'grid' theme
      styles: {
        lineWidth: 0.25, // Set line width
        lineColor: [222, 226, 230], // Set line color (RGB)
        textColor: [0, 0, 0],
        cellPadding: 2, // Adjust cell padding as needed
      },
      headStyles: {
        fontWeight: "bold", // Make the font bold
        textColor: [255, 255, 255], // Set font color to black (RGB)
        lineColor: "#999999", // Set line color (RGB)
        fillColor: "#999999", // Set transparent background for the header (RGBA)
      },
    });

    // Save or display the PDF
    doc.save("bucket.pdf");
    $(".loading").hide();
  };

  // Function to export data to CSV
  exportToCSV = () => {
    const { memberList2 } = this.state;

    // Create CSV data using react-csv
    const csvData = {
      data: memberList2,
      headers: this.columns.map((column) => {
        return { label: column.text, key: column.dataField };
      }),
    };
    return (
      <CSVLink
        data={csvData.data}
        headers={csvData.headers}
        filename={"bucket.csv"}
        className="text-secondary"
      >
        <i class="fas fa-file-csv"></i>
      </CSVLink>
    );
  };
  render() {
    const { memberList, data, sizePerPage, page, totalCount } = this.state;

    const showTotal = true;
    return (
      <AUX>
        <Modal
          className="BucketModal"
          show={this.props.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
          onShow={this.onShow}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title mt-0"> MEMBER BUCKET </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <div className="row">
                <div className="col-9">
                  <form role="search" className="app-search mt-0">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        id="keyword"
                        className="form-control rounded border mb-1 w-100"
                        placeholder="Member Search.."
                        onChange={(event) => {
                          this.setState({ searchMember: event.target.value });
                          this.filterMembers(event.target.value);
                        }}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-3 h5 pt-2 text-secondary text-right">
                  <span>
                    <i
                      class="fas c-pointer pr-2 fa-file-pdf"
                      onClick={this.exportToPDF}
                    ></i>
                    {!_.isEmpty(memberList) && this.exportToCSV()}
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-2 row scroller">
              <div class="col-12 scroller">
                <div className="table-responsive scroller bucketSpecialTbl">
                  <BootstrapTable
                    remote
                    keyField="id"
                    data={memberList}
                    columns={this.columns}
                    onTableChange={(type, newState) => {
                      let { listPaging } = this.state;
                      let paging = { listPaging };
                      paging = {
                        currentPage: newState.page,
                        pageSize: newState.sizePerPage,
                        orderBy: newState.sortField,
                        sortOrder: newState.sortOrder
                          ? newState.sortOrder
                          : "name",
                      };
                      listPaging = paging;

                      this.setState({
                        listPaging: listPaging,
                      });
                      this.updateMemberList(paging);
                    }}
                    pagination={paginationFactory({
                      sizePerPage: this.state.listPaging.pageSize,
                      totalSize: this.props.displayMembers.length,
                      paginationTotalRenderer: TableCustomTotal,
                      ...this.state.paginationSettings,
                    })}
                    //rowEvents={this.rowEvents}
                  />
                </div>

                {/*this.renderMemberList(
                  this.props.displayMembers,
                  this.props.displayMembers.length
                )*/}
              </div>
              {/*<div className="col-12">
                {this.RemotePagination(
                  data,
                  page,
                  sizePerPage,
                  this.handleTableChange,
                  totalCount,
                  showTotal,
                  this.rowEvents
                )}
                </div>*/}

              <br />

              <div className="col-12 text-center mt-30 pt-4">
                <button
                  className="btn btn-primary ml-2 w-md mc m-1 m-sm-3"
                  onClick={() => this.ViewAll()}
                >
                  View List
                </button>

                {
                  <button
                    className="btn btn-success w-md mc m-1 m-sm-3"
                    onClick={() => this.showSaveBucketModal()}
                  >
                    {this.props.SavedSearch.BucketSearchId > 0
                      ? "Update"
                      : "Save"}
                  </button>
                }
                <button
                  className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc m-1 m-sm-3"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={() => this.EmptyBucket()}
                >
                  Empty Bucket
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {
          <SaveBucketSearch
            showModal={this.state.showSaveBucketModal}
            onClose={this.hideModal}
            memberId={this.state.memberId}
            searchId={this.state.searchId}
            search_criteria={this.props.SearchCriteria}
            ParentClose={this.handleClose}
            setSavedSearchCriteria={this.props.setSavedSearchCriteria}
          />
        }
      </AUX>
    );
  }
}

export default connect(
  (state) => ({
    SavedSearch: state.saveSearch,
  }),
  {
    ...savedSearchAction,
  }
)(MemberBucket);
