import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Collapse, Card } from "reactstrap";
import { QuestionTypes } from "../../../Helpers/LookUpValues";
import {
  Switch,
  TextField,
  CheckBoxes,
  RadioButtons,
  Select,
} from "../../../../Shared/CustomizedForms/FormControls";

import registrationsAction from "../../../../../store/registrations/action";

//This modal is just showing the questions answered by User.
export class ReviewRequestQuestions extends Component {
  getFormElement = (idx, elementSchema) => {
    const props = {
      name: elementSchema.uid,
      label: elementSchema.label,
      options: elementSchema.questionOptions,
      value: elementSchema.value,
      disabled: true, /// Disabe the field bcz we are just showing what the user answered
    };
    if (elementSchema.questionType == QuestionTypes.Text) {
      return <TextField {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.DropDown) {
      return <Select {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.Switch) {
      return <Switch {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.Checkboxes) {
      return <CheckBoxes {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.Radiobuttons) {
      return <RadioButtons {...props} />;
    }
  };
  render() {
    let { selectedOpenRegQuestions = [] } = this.props.registrationsState; // Redux state
    let { props } = this;
    return (
      <Modal
        className="modal fade team-style"
        id="reviewRegRequestsModal"
        size="lg"
        backdrop="static"
        show={props.showModal}
        centered
        onShow={this.onShow}
        onHide={props.onClose}
      >
        <Modal.Header className="modal-header bg-secondary py-2" closeButton>
          <Modal.Title className=" w-100 modal-title font-italic">
            <div className="feModalHeader align-items-center text-uppercase d-flex">
              <div className="col-sm-6 mt-2">
                <h6 className="text-white mt-0">Registration Questions</h6>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body m-2">
          <div className="row">
            {selectedOpenRegQuestions &&
              Array.isArray(selectedOpenRegQuestions) &&
              selectedOpenRegQuestions.map((cv, ind) => (
                <div className="col-md-6 com-sm-12">
                  <div name="form-control" key={ind + 1}>
                    {this.getFormElement(ind, cv)}
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            className="btn btn-danger btn-sm w-md waves-effect waves-light"
            type="button"
            onClick={props.onClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(ReviewRequestQuestions)
);
