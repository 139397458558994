import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import Users from "../Admin/Users/Users";

class MembersLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLookup: this.props.isLookup,
            membersMethods: this.props.membersMethods
        };
    }
    componentWillMount() { }
    render() {
        return (
            <AUX>
                <Users isLookup={this.props.isLookup} isFacility={false} membersMethods={this.props.membersMethods} />
            </AUX>
        );
    }
}

export default MembersLookup;
