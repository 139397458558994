import React, { Component } from "react";
import { Row, Col, Form } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";

export class FE_INV_StickerNo extends Component {
  constructor(props) {
    super(props);
  }

  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && !this.props.isMobile == true ? (
          <label>{label} </label>
        ) : (
          <label></label>
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          autoComplete="off"
          placeholder={!this.props.isMobile == true ? "" : placeholder}
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    //console.log(formValues);
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Sticker No</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}

        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2">
              <Field
                name="StickerNo"
                id="StickerNo"
                classForm="form-control"
                placeholder="StickerNo"
                label="Sticker No"
                component={this.renderInput}
                labelFor="StickerNo"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "FE_INV_StickerNoForm",
  destroyOnUnmount: false,
})(FE_INV_StickerNo);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
