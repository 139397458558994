import React, { Component } from "react";
import { MDBInput } from "mdbreact";
import { Link } from "react-router-dom";
import RegisterModal from "../Shared/RegionModal";
import $ from "jquery";
import axios from "../Shared/auth-header";
class MemberNumberLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            dob: Date.now(),
            firstName_err: '',
            lastName_err: '',
            dob_err: '',
            showModal: false,
            message: "",
            status: false,
            success: "alert-danger",

        };
        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeDOB = this.handleChangeDOB.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleChangeFirstName(e) {
        this.setState({ firstName: e.target.value });
    }
    handleChangeLastName(e) {
        this.setState({ lastName: e.target.value });
    }
    handleChangeDOB(e) {
        this.setState({ dob: e.target.value });
    }

    handleFormSubmit(e) {
        debugger;
        e.preventDefault();
        if (this.state.firstName === "") {
            this.setState({ firstName_err: "Required Field" });
            return false;
        }
        if (this.state.lastName === "") {
            this.setState({ lastName_err: "Required Field" });
            return false;
        }
        if (this.state.dob === "") {
            this.setState({ dob_err: "Required Field" });
            return false;
        }

        $(".loading").show();
        axios
            .post("/api/Account/GetMemberNumberInfo", {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                dob: this.state.dob
            })
            .then(({ data }) => {
                debugger;
                if (data.status && data.status.toLowerCase() == "success") {
                    this.setState({ success: "alert-success" });
                    this.setState({ message: data.message });
                    this.setState({ status: true });
                    this.setState({ username: "" });
                } else {
                    this.setState({ status: true });
                    this.setState({ message: data.message });
                }
                $(".loading").hide();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        $('.loading').hide();
    }

    render() {
        return (
            <div className="wrapper-page">
                <div className="card overflow-hidden account-card mx-3">
                    <div className="bg-primary ptb-login text-white text-center position-relative">
                        <span className="logo logo-admin">
                            <img src="assets/images/natca-logo.png" height="142" alt="logo" />
                        </span>
                    </div>
                    <div className="account-card-content m-t-40">
                        {this.state.status && (
                            <div className={`alert ${this.state.success}`} role="alert">
                                {this.state.message}
                            </div>
                        )}
                        <div className="col-16 m-t-20 text-left font-weight-bold">
                            {<h5>Member Number / Username Lookup</h5>}
                            <small>
                                Fill in the fields below and press submit. An email contianing your information will be sent to you.
                            </small>
                        </div>
                        <form
                            className="form-horizontal mt-2"
                            onSubmit={this.handleFormSubmit}
                        >
                            <div className="form-group">
                                <MDBInput
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleChangeFirstName}
                                    label="Enter First Name"
                                    group
                                    type="text"
                                    id="firstName"
                                >
                                    <span className="text-danger">{this.state.firstName_err}</span>
                                </MDBInput>
                                <MDBInput
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.handleChangeLastName}
                                    label="Enter Last Name"
                                    group
                                    type="text"
                                    id="lastName"
                                >
                                    <span className="text-danger">{this.state.lastName_err}</span>
                                </MDBInput>
                                <MDBInput
                                    name="dob"
                                    value={this.state.dob}
                                    onChange={this.handleChangeDOB}
                                    label="Enter Date of Birth"
                                    group
                                    type="date"
                                    id="dob"
                                >
                                    <span className="text-danger">{this.state.dob_err}</span>
                                </MDBInput>
                            </div>

                            <div className="form-group m-t-10 mb-4 row">
                                <div className="col-8 m-t-20 mx-auto text-center">
                                    <button
                                        className="btn btn-primary btn-block w-md waves-effect waves-light"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                                <div className="col-12 text-left font-weight-bold">
                                    By registering for an account with NATCA you agree to our
                                    <Link
                                        to="https://www.natca.org/privacy-policy"
                                        target="_blank"
                                        className="font-500 text-primary text-right"
                                    >
                                        {" "}
                                        Terms & Conditions.{" "}
                                    </Link>{" "}
                                </div>

                                <div className="col-12 m-t-5 text-left font-weight-bold">
                                    Need help?
                                </div>
                                <div className="col-12 m-t-5 text-left font-weight-lighter">
                                    Other Issues?{" "}
                                    <a target="_blank" href={`mailto:support@natca.org`}>
                                        Email Us
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {
                    <RegisterModal
                        showModal={this.state.showModal}
                    />
                }

                <div className="m-t-40 text-center">
                    <p>© {new Date().getFullYear()} NATCA</p>
                </div>
            </div>
        );
    }
}
export default MemberNumberLookup;
