import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../RegistrationHeader";
import RegistrationsListTable from "./ListTables/RegistrationsListTable";
import RegistrationsService from "../RegistrationsService";
import _ from "lodash";
import registrationsAction from "../../../store/registrations/action";
import RegisterMemberModal from "./ModalPopUps/RegisterMemberModal";
import { RegistrationTableTypes } from "../Helpers/LookUpValues";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import CancelChildTemplateConfirmModal from "./ModalPopUps/CancelChildTemplateConfirmModal";
import AddTemplateModal from "../Admin/Modals/AddTemplateModal";
const isSuperAdmin = AuthService.canView(PermissionFeature.REGSuperAdmin);
const isBasicAdmin =
  AuthService.canView(PermissionFeature.REGBasicAdmin);
export class OpenRegistrationsMainPage extends Component {
  state = {
    registrations: [],
    isLoading: false,
    toggleRegisterMemberModal: false,
    occasionId: 0,
    registrationId: 0,
    registrationRow: {},
    paymentStatusId: 0,
    includeClosedTitle: "Show Closed",
    includeClosed: false,
    isCancelConfirmModalOpen: false,
    cancelId: 0,
    regisExists: false,
  };

  async componentDidMount() {
    await this.reloadRegistrations(this.state.includeClosed);
  }
  reloadRegistrations = async (includeClosed) => {
    this.setState({ isloading: true });
    await this.props.reloadOpenRegistrations(
      includeClosed,
      isSuperAdmin,
      isBasicAdmin
    );
    this.setState({ isloading: false });
  };
  handleRegistrationViewClick = async (registrationRow) => {
    var {
      registrationId = 0,
      paymentStatusId = 0,
      regisStatusId = 0,
      regisExists = false,
    } =
      (await RegistrationsService.GetOpenedEventDetails(registrationRow.id)) ||
      {};
    registrationRow.regisStatusId = regisStatusId;
    this.setState({
      toggleRegisterMemberModal: true, ///Open Registratoin Wizard screen
      occasionId: registrationRow.id,
      registrationId: registrationId, ///
      paymentStatusId: paymentStatusId,
      registrationRow: registrationRow,
      regisExists,
    });
    this.props.setSelectedOpenRegRow(registrationRow);
  };
  updateRegistrationId = async (registrationId) => {
    await this.setState({ registrationId });
  };
  onClose = () => {
    this.setState({ toggleRegisterMemberModal: false });
    this.reloadRegistrations(this.state.includeClosed);
  };
  toggleClosed = async () => {
    var title = this.state.includeClosedTitle;
    if (this.state.includeClosed) {
      title = "Show Closed";
      this.setState({ includeClosed: false }, async () => {
        await this.reloadRegistrations(this.state.includeClosed);
      });
    } else {
      title = "Hide Closed";
      this.setState({ includeClosed: true }, async () => {
        await this.reloadRegistrations(this.state.includeClosed);
      });
    }
    this.setState({
      includeClosedTitle: title,
    });
  };
  ////*****************Above that work of Sada User Open Registrations*******************
  confirmCancelReg = () => {
    this.confirmed = async () => {
      const { cancelId } = this.state;
      if (!cancelId) {
        return false;
      }
      if (cancelId > 0) {
        await RegistrationsService.CancelOccasion(cancelId)
          .then((data) => {
            if (!_.isEmpty(data)) {
              if (data.statusCode == 200) {
                this.toggleCancelConfirmModal();
                this.reloadRegistrations(this.state.includeClosed);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    return this;
  };
  toggleCancelConfirmModal = () => {
    this.setState({
      isCancelConfirmModalOpen: !this.state.isCancelConfirmModalOpen,
    });
  };
  handleChildTemplateViewClick = async (registrationRow) => {
    this.props.openAddTemplateModal({ formTitle: "Edit Child Template", templateCategory: "2", masterTemplateId: registrationRow.masterTemplateId , childTemplateId: registrationRow.id});
  };
  handleChildTemplateCancelClick = (cancelId) => {
    this.setState({ cancelId: cancelId }, () => {
      this.toggleCancelConfirmModal();
    });
  };
  handleChildTemplateCopyClick = (registrationRow) => {
    this.props.openAddTemplateModal({ formTitle: "Add Child Template", templateCategory: "2", masterTemplateId: registrationRow.masterTemplateId , childTemplateId: registrationRow.id, isCopied : true});
  };

  //////////////*****ChildTemplate Work end******//////////
  render() {
    let listData =
      isSuperAdmin || isBasicAdmin
        ? this.props.registrationsState.adminChildTemplates
        : this.props.registrationsState.openRegistrations;
    let regTableType =
      isSuperAdmin || isBasicAdmin
        ? RegistrationTableTypes.AdminRegistrations
        : RegistrationTableTypes.OpenRegistrations;
debugger;
    return (
      <>
        <Header />
        <div className="fet-container container-fluid">
          <br />
          {/* <span>This is showing opened/admin's child template registrations</span> */}
          {/* //Basic admin can only see records, that they create.// */}
          {(isSuperAdmin || isBasicAdmin) && (
            <div className="cursorPointer hover text-right font-weight-bold small mt-2 textlink">
              <a onClick={this.toggleClosed}>{this.state.includeClosedTitle}</a>
            </div>
          )}

          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">
              Opened Registrations
            </h5>
            <div className="card-body pb-2 px-0 pt-0">
              <RegistrationsListTable
                handleRegistrationViewClick={(registrationRow) =>
                  this.handleRegistrationViewClick(registrationRow)
                }
                listData={listData}
                reloadRegistrations={this.reloadRegistrations}
                isloading={this.state.isloading}
                regTableType={regTableType}
                ///*******Starting Props for ChildTemplates/Events/Classess*******////
                handleChildTemplateViewClick={(row) =>
                  this.handleChildTemplateViewClick(row)
                }
                handleChildTemplateCancelClick={(id) =>
                  this.handleChildTemplateCancelClick(id)
                }
                handleChildTemplateCopyClick={(row) =>
                  this.handleChildTemplateCopyClick(row)
                }
                //*********************/
              />
            </div>
          </div>
        </div>
        <RegisterMemberModal
          showModal={this.state.toggleRegisterMemberModal}
          occasionId={this.state.occasionId}
          registrationId={this.state.registrationId}
          paymentStatusId={this.state.paymentStatusId}
          onClose={this.onClose}
          regTableType={RegistrationTableTypes.OpenRegistrations}
          registrationRow={this.state.registrationRow}
          regisExists={this.state.regisExists}
          updateRegistrationId={this.updateRegistrationId}
          showSuccessPage={false}
        />
        <CancelChildTemplateConfirmModal
          confirm={this.confirmCancelReg}
          modal={this.state.isCancelConfirmModalOpen}
          toggle={this.toggleCancelConfirmModal}
        />
        <AddTemplateModal
          showModal={this.props.registrationsState.openAddTemplateModal}
          reloadRegistrations={() => this.reloadRegistrations(this.state.includeClosed)}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(OpenRegistrationsMainPage)
);
