import React, { Component, Fragment } from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ViewTeam from "../../../Permissions/Teams/ViewTeam";
import Services from "../../../Permissions/PermissionService";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import TableCustomTotal from "../../../Shared/TableCustomTotal";
import PositionDatesModal from "./PositionDatesModal";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";
import $ from "jquery";

class Position extends Component {
  constructor(props) {
    super(props);
    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (
          //   (window.location.href.indexOf("/dashboard") > -1 &&
          //     AuthService.canOwnView(PermissionFeature.TeamsPositions)) ||
          //   (window.location.href.indexOf("/directory") > -1 &&
          //     AuthService.canView(PermissionFeature.TeamsPositions))
          AuthService.canView(PermissionFeature.TeamsPositions)
        ) {
          // this.state.viewTeamId = row.id;
          // this.setState({ viewTeamId: this.state.viewTeamId, openModal: true });
          window.location.href = "/permissions/teams/teamDetail/" + row.teamId;
        }
      },
    };
    this.state = {
      positionsDisplayer: [],
      positions: [],
      sizePerPagePositions: 10,
      showHistoryAvail: true,
      showHistory: true,
      openModal: false,
      viewTeamId: 0,
      isDatesModalOpen: false,
      positionStartDate: null,
      positionEndDate: null,
      editTeamPositionMemberId: -1,
    };
    this.positionsColumns = [
      {
        text: "Position Name",
        dataField: "positionName",
        sort: true,
        classes: "c-pointer",
      },
      {
        text: "Team Name",
        dataField: "teamName",
        sort: true,
        classes: "c-pointer",
      },
      {
        text: "Start Date",
        dataField: "startDate",
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            cellContent &&
            moment(cellContent.split("T")[0]).format("MM/DD/YYYY")
          );
        },
      },
      {
        text: "End Date",
        dataField: "endDate",
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            cellContent &&
            moment(cellContent.split("T")[0]).format("MM/DD/YYYY")
          );
        },
      },
      {
        isDummyField: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "py-2",
        hidden:
          !AuthService.canMemberUpdate(PermissionFeature.MembersPositions) &&
          !AuthService.canMemberDelete(PermissionFeature.MembersPositions),
        formatter: (cellContent, row) => {
          return (
            <>
              <div className="text-center">
                {AuthService.canMemberUpdate(
                  PermissionFeature.MembersPositions
                ) && (
                  <span
                    className="text-secondary fas fa-edit mr-2 c-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.editPosition(row);
                    }}
                  ></span>
                )}

                {AuthService.canMemberDelete(
                  PermissionFeature.MembersPositions
                ) && (
                  <span
                    className="text-danger fas fa-trash c-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        window.confirm(
                          `Are you sure you want to delete position ${row.positionName}?`
                        )
                      ) {
                        this.deletePosition(row.teamPositionMemberId);
                      }
                    }}
                  ></span>
                )}
              </div>
            </>
          );
        },
      },
    ];
    this.positionsColumnsDashboard = [
      {
        text: "Position Name",
        dataField: "positionName",
        sort: true,
        classes: "c-pointer",
      },
      {
        text: "Team Name",
        dataField: "teamName",
        sort: true,
        classes: "c-pointer",
      },
      {
        text: "Start Date",
        dataField: "startDate",
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            cellContent &&
            moment(cellContent.split("T")[0]).format("MM/DD/YYYY")
          );
        },
      },
      {
        text: "End Date",
        dataField: "endDate",
        classes: "c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            cellContent &&
            moment(cellContent.split("T")[0]).format("MM/DD/YYYY")
          );
        },
      },
    ];
    this.positionSizePerPageChange = this.positionSizePerPageChange.bind(this);
    this.handleShowAllPosition = this.handleShowAllPosition.bind(this);
    this.handleLessAllPosition = this.handleLessAllPosition.bind(this);
    this.getPositions = this.getPositions.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.closeAllModal = this.closeAllModal.bind(this);
    this.toggleDatesModal = this.toggleDatesModal.bind(this);
    this.editPosition = this.editPosition.bind(this);
    this.deletePosition = this.deletePosition.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.savePositionDates = this.savePositionDates.bind(this);
  }

  toggleDatesModal() {
    // if (this.state.isModalOpenChart) {
    //   this.setState({
    //     isYearActive: false,
    //     activeTab: "0",
    //   });
    // }
    this.setState({ isDatesModalOpen: !this.state.isDatesModalOpen });
  }

  onStartDateChange = (jsDate, dateString) => {
    this.setState({
      positionStartDate: moment(new Date(jsDate)).format("MM/DD/YYYY"),
    });
  };

  onEndDateChange = (jsDate, dateString) => {
    this.setState({
      positionEndDate: moment(new Date(jsDate)).format("MM/DD/YYYY"),
    });
  };

  editPosition(row) {
    this.setState({
      editTeamPositionMemberId: row.teamPositionMemberId,
      positionStartDate: row.startDate
        ? moment(new Date(row.startDate)).format("MM/DD/YYYY")
        : null,
      positionEndDate: row.endDate
        ? moment(new Date(row.endDate)).format("MM/DD/YYYY")
        : null,
    });
    this.toggleDatesModal();
  }

  deletePosition = async (id) => {
    $(".loading").show();
    let data = await Services.DeleteMemberPosition(id);
    if (!_.isEmpty(data)) {
      $(".loading").hide();
      toast.success("Member's position deleted successfully!");
      this.getPositions();
    } else {
      toast.error("Operation failed!");
    }
    $(".loading").hide();
  };

  async savePositionDates() {
    $(".loading").show();

    let data = await Services.EditTeamPositionMemberDates({
      ID: this.state.editTeamPositionMemberId,
      MemberId: null,
      StartDate: this.state.positionStartDate,
      EndDate: this.state.positionEndDate,
    });
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        toast.success("Member's Position edited successfully!");
        this.getPositions();
      } else {
        toast.error("Operation failed!");
      }
    }
    this.toggleDatesModal();

    $(".loading").hide();
  }

  positionSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPagePositions: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    //totalSize,
    rowEvents
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      paginationTotalRenderer: TableCustomTotal,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      //sizePerPage: 10,
      //hidePageListOnlyOnePage: true
    };
    return (
      <>
        <PositionDatesModal
          isModalOpen={this.state.isDatesModalOpen}
          toggleModal={this.toggleDatesModal}
          onStartDateChange={this.onStartDateChange}
          onEndDateChange={this.onEndDateChange}
          savePositionDates={this.savePositionDates}
          positionStartDate={this.state.positionStartDate}
          positionEndDate={this.state.positionEndDate}
        />
        <div>
          {!_.isEmpty(data) && (
            <BootstrapTable
              keyField={keyFields}
              data={data}
              columns={columns}
              className="table"
              pagination={
                data.length > sizePerPage
                  ? paginationFactory({ ...paginationOptions })
                  : false
              }
              rowEvents={rowEvents}
            />
          )}
        </div>
      </>
    );
  }
  getPositions = async () => {
    $(".loading").show();
    let data = await Services.MyPositions(this.props.MemberId);
    if (!_.isEmpty(data)) {
      this.state.positions = data.result;
      this.state.positionsDisplayer = _.cloneDeep(
        this.state.positions.filter((x) => x.isActive == true)
      );
      if (
        JSON.stringify(this.state.positions) ==
        JSON.stringify(this.state.positionsDisplayer)
      ) {
        this.state.showHistoryAvail = false;
      } else {
        this.state.showHistoryAvail = true;
      }
      await this.setState({
        showHistoryAvail: this.state.showHistoryAvail,
        positions: this.state.positions,
        positionsDisplayer: this.state.positionsDisplayer,
      });
      if (this.state.showHistoryAvail && !this.state.showHistory) {
        this.handleShowAllPosition();
      }
    }

    $(".loading").hide();
  };
  componentDidMount() {
    this.getPositions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.MemberId != this.props.MemberId) {
      this.getPositions();
    }
  }
  closeAllModal() {
    this.setState({ openModal: false });
  }
  handleShowAllPosition = () => {
    this.setState({
      positionsDisplayer: _.cloneDeep(this.state.positions),
      showHistory: false,
    });
  };
  handleLessAllPosition = () => {
    this.state.positionsDisplayer = this.state.positions.filter(
      (x) => x.isActive === true
    );
    this.setState({
      positionsDisplayer: this.state.positionsDisplayer,
      showHistory: true,
    });
  };
  render() {
    const { handleShowAllPosition, positions, history } = this.props;
    return (
      <Fragment>
        {!_.isEmpty(this.state.positions) && (
          <>
            <div className="card directory-card border-rounded shadow">
              <h5 className="card-header mt-0">
                <i className="mdi mdi-view-list mr-2" />
                Positions
              </h5>
              <div className="card-body pb-3 pt-1">
                <div className="pb-2 text-right">
                  {this.state.showHistoryAvail &&
                    (this.state.showHistory ? (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleShowAllPosition();
                        }}
                      >
                        Show History
                      </a>
                    ) : (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleLessAllPosition();
                        }}
                      >
                        Hide History
                      </a>
                    ))}
                </div>
                {window.location.href.indexOf("/dashboard") < 0
                  ? this.RemotePagination(
                      this.positionsColumns,
                      "id",
                      this.state.positionsDisplayer,
                      this.state.positionSizePerPageChange,
                      this.state.sizePerPagePositions,
                      this.rowEvents
                    )
                  : this.RemotePagination(
                      this.positionsColumnsDashboard,
                      "id",
                      this.state.positionsDisplayer,
                      this.state.positionSizePerPageChange,
                      this.state.sizePerPagePositions,
                      this.rowEvents
                    )}
              </div>
            </div>

            <ViewTeam
              memberId={this.props.MemberId}
              closeAllModal={this.closeAllModal}
              openModal={this.state.openModal}
              viewTeamId={this.state.viewTeamId}
            />
          </>
        )}
      </Fragment>
    );
  }
}

export default Position;
