import actions from "./action";

const initialState = {
    Permissions: [],
    NatcaBargainingUnitDate: "",
    BargainingUnitDate: "",
};

export default function EditUser(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PERMISSIONS:
      return {
        ...state,
          Permissions: action.payload,
          };
      case actions.SET_SENIORITY:
          return {
              ...state,
              NatcaBargainingUnitDate: action.payload.NatcaBargainingUnitDate,
              BargainingUnitDate: action.payload.BargainingUnitDate
          };

    default:
      return state;
  }
}
