import React, { Component, useState } from "react";
import AUX from "../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import InputMask from "react-input-mask";
import { Field } from "redux-form";
import $ from "jquery";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import authAxios from "../../modules/Shared/auth-header";
import { connect } from "react-redux";
import payfileActions from "../../store/payfile/action";
import { Collapse, Card, CardBody, Spinner } from "reactstrap";
import TableCustomTotal from "../../modules/Shared/TableCustomTotal";
import _ from "lodash";
import PayFilesMembersList from "./PayFilesMembersList";
import DataUploadStatus from "./DataUploadStatus";
import { bindActionCreators } from "redux";
import PayfileErrorModal from "./PayfileErrorModal";

import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";
const ShowCurrentMemberValues = (props) => {
  let importCsvProperties = [
    "bus",
    "org_cd",
    "org_title",
    "fac_routing_symbol",
    "date_birth",
    "hourly_salary",
    "yearly_salary",
    "scd_date",
    "dty_stn_desc",
    "dty_stn_cd",
    "grade",
  ];
  let currentMemberProperties = [
    "current_unit",
    "current_organizationcode",
    "current_organizationtitle",
    "current_facilityaddressroutingsymbol",
    "current_dateofbirth",
    "current_hourlysalary",
    "current_yearlysalary",
    "current_servicecomputationdate",
    "current_dutystationdescription",
    "current_dutystationcode",
    "current_paygrade",
  ];
  let fieldLabels = [
    "BARGAINING UNIT",
    "ORG. CODE",
    "ORG. TITLE",
    "FACILITY ROUTING SYMBOL",
    "DATE OF BIRTH",
    "HOURLY SALARY",
    "YEARLY SALARY",
    "SCD",
    "DUTY STN. DESC",
    "DUTY STN. CODE",
    "GRADE",
  ];
  let allCurrentValues = [];
  let modifiedCurrentArr = [];
  let modifiedImportArr = [];
  importCsvProperties.forEach((importValProperty, index) => {
    const currentMemberProperty = currentMemberProperties[index];
    const fieldLabel = fieldLabels[index];
    const rowCurrentVal = props.row[currentMemberProperty];
    const rowImportVal = props.row[importValProperty];
    if (
      !importValProperty.includes("salary") &&
      // !importValProperty.includes("bus") &&
      !_.isEqual(props.row[importValProperty], rowCurrentVal)
    ) {
      modifiedCurrentArr.push({
        fieldName: [fieldLabel],
        fieldValue: _.isEmpty(rowCurrentVal) ? "none" : rowCurrentVal,
      });
      modifiedImportArr.push({
        fieldName: [fieldLabel],
        fieldValue: _.isEmpty(rowImportVal) ? "none" : rowImportVal,
      });
    }
    /// salary columns
    else if (
      importValProperty.includes("salary")
      // &&!importValProperty.includes("bus")
    ) {
      if (
        parseFloat(props.row[importValProperty]) !== parseFloat(rowCurrentVal)
      ) {
        modifiedCurrentArr.push({
          fieldName: [fieldLabel],
          fieldValue: !rowCurrentVal
            ? "none"
            : `$${parseFloat(rowCurrentVal).toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              })}`,
        });
        modifiedImportArr.push({
          fieldName: [fieldLabel],
          fieldValue: !rowImportVal
            ? "none"
            : `$${parseFloat(rowImportVal).toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              })}`,
        });
      }
    }
    // else if (importValProperty.includes("bus")) {
    //   if (parseInt(props.row[importValProperty]) !== parseInt(rowCurrentVal)) {
    //     modifiedCurrentArr.push({
    //       fieldName: [fieldLabel],
    //       fieldValue: rowCurrentVal,
    //     });
    //   }
    // }
  });
  const { row } = props;

  const [readMore, setReadMore] = useState(false);
  const linkName = readMore ? "Show Less << " : "Show All >> ";
  // const modifiedValues =
  //   modifiedCurrentArr.length > 0
  //     ? modifiedCurrentArr.map((cv, i, arr) => cv.fieldValue)
  //     : [];
  // const modifiedValuesExcluded = Object.values(modifiedCurrentArr).filter(
  //   (item) => typeof item == "string" && !modifiedValues.includes(item)
  // );
  // Object.entries($reactTemp1).filter(
  //   (item) =>
  //     typeof item[1] == "string" &&
  //     !modifiedCurrentArr.includes(item[1]) &&
  //     item[0].includes("current")
  // );
  /* {!_.isEmpty(modifiedValuesExcluded) &&
            modifiedValuesExcluded.map((m) => {
              return (
                <>
                  <div className="col-6">
                    <span className="font-weight-bold"> {m.fieldName} :</span>{" "}
                    {m.fieldValue}{" "}
                  </div>
                </>
              );
            })} */

  const extraContentCurrentValues = (
    <>
      <div className="col-6">
        <span className="font-weight-bold">BARGAINING UNIT:</span>{" "}
        {_.isEmpty(row.current_unit) ? "none" : row.current_unit}
        <br />
        <span className="font-weight-bold">FACILITY ROUTING SYMBOL:</span>
        {_.isEmpty(row.current_facilityaddressroutingsymbol)
          ? "none"
          : row.current_facilityaddressroutingsymbol}
      </div>

      <div className="col-6">
        <span className="font-weight-bold">ORG. CODE:</span>
        {_.isEmpty(row.current_organizationcode)
          ? "none"
          : row.current_organizationcode}
        <br />
        <span className="font-weight-bold"> ORG. TITLE:</span>
        {_.isEmpty(row.current_organizationtitle)
          ? "none"
          : row.current_organizationtitle}
      </div>

      <div className="col-6">
        <span className="font-weight-bold">DATE OF BIRTH : </span>
        {_.isEmpty(row.current_dateofbirth)
          ? "none"
          : row.current_dateofbirth}{" "}
        <br />
        <span className="font-weight-bold">SCD : </span>
        {_.isEmpty(row.current_servicecomputationdate)
          ? "none"
          : row.current_servicecomputationdate}
      </div>
      <div className="col-6">
        <span className="font-weight-bold">HOURLY SALARY: </span>
        {!row.current_hourlysalary
          ? "none"
          : `$${row.current_hourlysalary.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            })}`}
        <br />
        <span className="font-weight-bold">YEARLY SALARY: </span>
        {!row.current_yearlysalary
          ? "none"
          : `$${row.current_yearlysalary.toLocaleString(navigator.language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
            })}`}
      </div>
      <div className="col-6">
        <span className="font-weight-bold">DUTY STN. CODE: </span>
        {_.isEmpty(row.current_dutystationcode)
          ? "none"
          : row.current_dutystationcode}

        <br />
        <span className="font-weight-bold">DUTY STN. DESC : </span>
        {_.isEmpty(row.current_dutystationdescription)
          ? "none"
          : row.current_dutystationdescription}
      </div>
      <div className="col-6">
        <span className="font-weight-bold">FACILITY: </span>
        {_.isEmpty(row.current_facility) ? "none" : row.current_facility}
        <br />
        <span className="font-weight-bold">REGION: </span>
        {_.isEmpty(row.current_region) ? "none" : row.current_region}
      </div>
      <div className="col-12">
        <span className="font-weight-bold">GRADE: </span>
        {_.isEmpty(row.current_paygrade) ? "none" : row.current_paygrade}
      </div>
    </>
  );
  const extraContentImportValues = (
    <>
      <div className="col-6">
        <span className="font-weight-bold">BARGAINING UNIT:</span>{" "}
        {_.isEmpty(row.bus) ? "none" : row.bus}
        <br />
        <span className="font-weight-bold">FACILITY ROUTING SYMBOL:</span>{" "}
        {_.isEmpty(row.fac_routing_symbol) ? "none" : row.fac_routing_symbol}
      </div>
      <div className="col-6">
        <span className="font-weight-bold">ORG. CODE:</span>
        {_.isEmpty(row.org_cd) ? "none" : row.org_cd}
        <br />
        <span className="font-weight-bold"> ORG. TITLE:</span>
        {_.isEmpty(row.org_title) ? "none" : row.org_title}
      </div>

      <div className="col-6">
        <span className="font-weight-bold">DATE OF BIRTH: </span>
        {_.isEmpty(row.date_birth) ? "none" : row.date_birth}
        <br />
        <span className="font-weight-bold">SCD: </span>
        {_.isEmpty(row.scd_date) ? "none" : row.scd_date}
      </div>
      <div className="col-6">
        <span className="font-weight-bold">HOURLY SALARY: </span>
        {!row.hourly_salary
          ? "none"
          : `$${parseFloat(row.hourly_salary).toLocaleString(
              navigator.language,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              }
            )}`}
        <br />
        <span className="font-weight-bold">YEARLY SALARY: </span>
        {!row.yearly_salary
          ? "none"
          : `$${parseFloat(row.yearly_salary).toLocaleString(
              navigator.language,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 4,
              }
            )}`}
      </div>

      <div className="col-6">
        <span className="font-weight-bold">DUTY STN. CODE: </span>
        {_.isEmpty(row.dty_stn_cd) ? "none" : row.dty_stn_cd}
        <br />
        <span className="font-weight-bold">DUTY STN. DESC : </span>
        {_.isEmpty(row.dty_stn_desc) ? "none" : row.dty_stn_desc}
      </div>
      <div className="col-6">
        <span className="font-weight-bold">GRADE: </span>
        {_.isEmpty(row.grade) ? "none" : row.grade}
      </div>
    </>
  );
  const totalCurrentDistinct = modifiedCurrentArr.length;
  const totalImportDistinct = modifiedImportArr.length;
  return (
    <>
      <h6 className="text-warning pl-3 pr-3 mt-1">Import Values</h6>
      <div className="row pb-2 m-auto">
        <div className="col-12">
          <div className="row fontSmaller modifiedCurrentValues">
            {!_.isEmpty(modifiedImportArr) &&
              !readMore &&
              modifiedImportArr.map((m, i) => {
                return (
                  <>
                    <div
                      className={`${
                        totalImportDistinct === i + 1 && i % 2 == 0
                          ? "col-12"
                          : " col-6"
                      }`}
                    >
                      <span className="font-weight-bold"> {m.fieldName} :</span>{" "}
                      {m.fieldValue}{" "}
                    </div>
                  </>
                );
              })}
            {_.isEmpty(modifiedImportArr) && !readMore && (
              <span className="text-primary pl-3 pr-3 mb-1 col-12">
                No Distinct Values Found!
              </span>
            )}

            {readMore && extraContentImportValues}
          </div>
        </div>
      </div>

      <h6 className="text-info  pl-3 pr-3 mt-1">Current Values</h6>
      <div className="row pb-2 m-auto">
        <div className="col-12">
          <div className="row fontSmaller modifiedCurrentValues">
            {!_.isEmpty(modifiedCurrentArr) &&
              !readMore &&
              modifiedCurrentArr.map((m, i) => {
                return (
                  <>
                    <div
                      className={`${
                        totalCurrentDistinct === i + 1 && i % 2 == 0
                          ? "col-12"
                          : " col-6"
                      }`}
                    >
                      <span className="font-weight-bold"> {m.fieldName} :</span>{" "}
                      {m.fieldValue}{" "}
                    </div>
                  </>
                );
              })}
            {_.isEmpty(modifiedCurrentArr) && !readMore && (
              <span className="text-primary pl-3 pr-3 mb-1 col-12">
                No Distinct Values Found!
              </span>
            )}

            {readMore && extraContentCurrentValues}

            <a
              className="search-link fontsmaller showCV mt-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              {linkName}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
const getEditIcondisplair = (row) => {
  if (row && row.errors && row.errors["fieldMessages"]) {
    var anyECIError = row.errors["fieldMessages"].some(
      (val) => val.fieldName === "EMPLOYEE_COMMON_ID"
    );
    ////only one error and it is of ECI not found,then don't show edit pencil of error
    if (anyECIError && row.errors["fieldMessages"].length == 1) {
      return false; // don't display edit pencil...
    } else {
      return true;
    }
  } else {
    return true;
  }
};
const getDisplayForRemovedSectionAddBtn = (row) => {
  if (!getEditIcondisplair(row)) {
    return true;
  }
  if (
    _.isEmpty(row.errors) ||
    _.isEmpty(row.errors["fieldMessages"]) ||
    row.errors["fieldMessages"].length === 0
  ) {
    return true;
  }
};

export class ReviewScreenPayFileOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizePerPage: 25,
      page: 1,
      isloadded: false,
      errorRowsData: [],
      ignoredRows: [],
      successRows: [],
      totalErrorCount: "",
      totalIgnoredCount: "",
      totalSuccessCount: "",
      iserrorlistopen: true,
      isignoredlistopen: false,
      issuccesslistopen: false,
      isremovedlistopen: false,
      openErrorModal: false,
      name: "",
      regionFacility: "",

      importfileid: 0,
      payfileoneid: 0,
      review_memberSearchText: "",
      review_memberSearchText_err: "",
      valid: false,
      isRemovedSection: false,
    };

    this.expandRowErrors = {
      parentClassName: "parent-expand",
      onlyOneExpanding: true,
      renderer: (row) => (
        <div className="parentErrorDiv">
          <div className="col d-flex">
            <h6 className="text-danger font-weight-bold">Errors</h6>
            {AuthService.canUpdate(PermissionFeature.Payfile1ReviewSummary) &&
              //_.isEmpty(row.memberno) &&
              getEditIcondisplair(row) && (
                <i
                  className="fas fa-sm fa-pencil-alt mt-3 ml-2 edit-pencil-icon"
                  title="Edit"
                  onClick={() => this.openErrorReviewModal(row)}
                ></i>
              )}
            {AuthService.canDelete(PermissionFeature.Payfile1ReviewSummary) && (
              <i
                class="fas fa-trash c-pointer mt-3 ml-1"
                title="Delete"
                onClick={() => this.onIgnoreError(row)}
              ></i>
            )}
            {!getEditIcondisplair(row) && (
              <>
                <i
                  class="fas fa-user-plus c-pointer mt-3 ml-1"
                  title="Process Update"
                  onClick={() => {
                    var value = document
                      .getElementById("Emno1")
                      .value.replaceAll("_", "");
                    if (value != "" && value != null) {
                      var regex = /^([0-9]){5}$/;
                      if (!regex.test(value)) {
                        toast.error(
                          "Enter valid member no. between 10000 - 99999"
                        );
                      } else {
                        this.processSkipUpdate(row, true, false, false, value);
                        this.setState({ errorMemberNo: "" });
                      }
                    } else {
                      this.processSkipUpdate(row, true, false, false);
                    }
                  }}
                  ///From expandRowErrors
                ></i>
                <div className="md-form form-group mt-2 pt-1 mb-0 ml-2">
                  <InputMask
                    id="Emno1"
                    name="MemberNumber123"
                    data-type="P"
                    mask="99999"
                    className=""
                    type="text"
                    placeholder="Member Number"
                  />
                </div>
              </>
            )}
          </div>
          <div className="row m-auto fontSmaller">
            {!_.isEmpty(row.errors) &&
              row.errors["fieldMessages"] &&
              row.errors["fieldMessages"].map((m) => {
                return (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      {/* {`${m.fieldName}:${m.fieldValue}  ${m.message}`} */}
                      <span className="font-weight-bold">
                        {" "}
                        {m.fieldName} :
                      </span>{" "}
                      {m.fieldValue}{" "}
                      <span className="text-danger"> {`(${m.message})`}</span>
                    </div>
                  </>
                );
              })}
          </div>
          {_.isEmpty(row.memberno) && (
            <h6 className="text-warning  pl-3 pr-3 mt-1">Import Values</h6>
          )}
          {_.isEmpty(row.memberno) && this.GetRowImportvalues(row)}

          {/* //show current and import values spans for old members */}
          {!_.isEmpty(row.memberno) && <ShowCurrentMemberValues row={row} />}
        </div>
      ),
    };
    this.expandRowIgnore = {
      parentClassName: "parent-expand",
      onlyOneExpanding: true,
      renderer: (row) => (
        <div className="p-2">
          <h6 className="text-warning pl-3">Import Values</h6>

          <div className="row  m-auto">
            <div className="col-11 pl-0">{this.GetRowImportvalues(row)}</div>

            <div className="col-1 inlineTable">
              <button
                className="btn-sm btn-success c-pointer mr-2 mc"
                type="button"
                onClick={() => this.processSkipUpdate(row, false, true, false)}
                //from expandRowIgnore
              >
                <i class="fas fa-user-plus" title="Process Update"></i>
              </button>
              {AuthService.canDelete(
                PermissionFeature.Payfile1ReviewSummary
              ) && (
                <button
                  className="btn-sm btn-warning c-pointer mr-2 mc"
                  type="button"
                  onClick={() => this.onDeleteIgnore(row)}
                >
                  <i className="fas fa-trash c-pointer" title="Delete"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      ),
    };
    this.expandRowSuccess = {
      parentClassName: "parent-expand",
      onlyOneExpanding: true,
      renderer: (row) => (
        <div className="p-2">
          {!_.isEmpty(row.updatedFields) &&
            !_.isEmpty(row.updatedFields.fieldsDelta) &&
            row.updatedFields.fieldsDelta.length > 0 && (
              <h6 className="text-success">Updated Fields</h6>
            )}
          {!_.isEmpty(row.updatedFields) &&
            (_.isEmpty(row.updatedFields.fieldsDelta) ||
              row.updatedFields.fieldsDelta == null ||
              (!_.isEmpty(row.updatedFields.fieldsDelta) &&
                row.updatedFields.fieldsDelta.length == 0)) && (
              <h6 className="row m-auto text-success NoUpdatedFieldFound">
                No Updated Fields Found
              </h6>
            )}
          <div className="row fontSmaller m-auto">
            {!_.isEmpty(row.updatedFields) &&
              !_.isEmpty(row.updatedFields["fieldsDelta"]) &&
              row.updatedFields["fieldsDelta"].map((m) => {
                return (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-4">
                      {/* {`${m.fieldName}:${m.fieldValue}  ${m.message}`} */}
                      <span className="font-weight-bold">
                        {" "}
                        {m.fieldName}:
                      </span>{" "}
                      <span className="text-success">
                        {" "}
                        {_.isEmpty(m.valueAfter) || m.valueAfter === "(null)"
                          ? "(none)"
                          : m.valueAfter}{" "}
                      </span>
                      <span className="">
                        {_.isEmpty(m.valueBefore) || m.valueBefore === "(null)"
                          ? " (none)"
                          : ` (${m.valueBefore})`}{" "}
                      </span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      ),
    };
    this.expandRowRemoved = {
      parentClassName: "parent-expand",
      onlyOneExpanding: true,
      renderer: (row) => (
        <div>
          <div className="row  m-auto">
            <div className="col-11 pl-0">
              {_.isEmpty(row.memberno) && (
                <h6 className="text-warning  pl-3 pr-3">Import Values</h6>
              )}
              {_.isEmpty(row.memberno) && this.GetRowImportvalues(row)}
              {!_.isEmpty(row.memberno) && (
                <ShowCurrentMemberValues row={row} />
              )}
            </div>

            {getDisplayForRemovedSectionAddBtn(row) && (
              <div className="col-1 inlineTable justify-content-center align-self-center">
                <button
                  className="btn-sm btn-success c-pointer mr-2 mc"
                  type="button"
                  onClick={() =>
                    this.processSkipUpdate(row, false, false, true)
                  }
                  // from expandRowRemoved
                >
                  <i class="fas fa-user-plus" title="Process Update"></i>
                </button>
              </div>
            )}
          </div>

          {!_.isEmpty(row.errors) &&
            !_.isEmpty(row.errors["fieldMessages"]) &&
            row.errors["fieldMessages"].length > 0 && (
              <div className="col d-flex">
                <h6 className="text-danger">Errors</h6>
                {AuthService.canUpdate(
                  PermissionFeature.Payfile1ReviewSummary
                ) &&
                  getEditIcondisplair(row) && (
                    <i
                      className="fas fa-sm fa-pencil-alt mt-3 ml-2 edit-pencil-icon"
                      title="Edit"
                      onClick={() => this.openErrorReviewModal(row, true)}

                      // Error Modal Opened from Removed Section
                    ></i>
                  )}
              </div>
            )}
          <div className="row fontSmaller pb-2 m-auto">
            {!_.isEmpty(row.errors) &&
              row.errors["fieldMessages"] &&
              row.errors["fieldMessages"].map((m) => {
                return (
                  <>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                      <span className="font-weight-bold"> {m.fieldName} :</span>{" "}
                      {m.fieldValue}{" "}
                      <span className="text-danger"> {`(${m.message})`}</span>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      ),
    };
  }
  GetRowImportvalues = (row) => (
    <div className="row pb-2 m-auto">
      <div className="col-12">
        <div className="row fontSmaller">
          <div className="col-6">
            <span className="font-weight-bold">BARGAINING UNIT:</span> {row.bus}
            <br />
            <span className="font-weight-bold">
              FACILITY ROUTING SYMBOL:
            </span>{" "}
            {row.fac_routing_symbol}
          </div>
          <div className="col-6">
            <span className="font-weight-bold">ORG. CODE:</span>
            {row.org_cd}
            <br />
            <span className="font-weight-bold"> ORG. TITLE:</span>

            {row.org_title}
          </div>

          <div className="col-6">
            <span className="font-weight-bold">DATE OF BIRTH: </span>
            {row.date_birth}
            <br />
            <span className="font-weight-bold">SCD: </span>
            {row.scd_date}
          </div>
          <div className="col-6">
            <span className="font-weight-bold">HOURLY SALARY: </span>
            {`$${
              !row.hourly_salary
                ? "none"
                : parseFloat(row.hourly_salary).toLocaleString(
                    navigator.language,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 4,
                    }
                  )
            }`}

            <br />
            <span className="font-weight-bold">YEARLY SALARY: </span>

            {`$${
              !row.yearly_salary
                ? "none"
                : parseFloat(row.yearly_salary).toLocaleString(
                    navigator.language,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 4,
                    }
                  )
            }`}
          </div>

          <div className="col-6">
            <span className="font-weight-bold">DUTY STN. CODE: </span>
            {row.dty_stn_cd}
            <br />
            <span className="font-weight-bold">DUTY STN. DESC : </span>
            {row.dty_stn_desc}
          </div>
          <div className="col-6">
            <span className="font-weight-bold">GRADE: </span>
            {row.grade}
          </div>
        </div>
      </div>
    </div>
  );

  componentDidMount() {
    const { id } = this.props.match.params;
    var currentFileId = 0;

    if (!_.isEmpty(id)) {
      this.props.actions.setCurrentFileId(id);
      currentFileId = id;
    } else {
      currentFileId = this.props.payFileState.fileId;
    }
    this.setState({ importfileid: currentFileId });

    var activeSections = [];

    activeSections.push(DataUploadStatus.Error);

    const formData = {
      fileId: currentFileId,
      Keyword: "",
      recordsTypeList: activeSections,
      PageNo: 1,
      PageSize: 10,
      OperatorType: "AND",
      SortOrder: "firstnameAsc", //"firstnameAsc",
    };

    this.props.actions.searchPayFileOneReviewRecords(formData);

    //console.log("componentDidMount called");
  }

  componentDidUpdate() {
    //console.log("componentDidUpdate");
  }
  componentWillMount() {
    //console.log("componentWillMount");
  }
  handleErrorDataChange = ({ dataSize }) => {
    this.props.actions.setTotalDataCount(dataSize, DataUploadStatus.Error);
  };
  handleIgnoreDataChange = ({ dataSize }) => {
    this.props.actions.setTotalDataCount(dataSize, DataUploadStatus.Skip);
  };
  handleSuccessDataChange = ({ dataSize }) => {
    this.props.actions.setTotalDataCount(dataSize, DataUploadStatus.Update);
  };
  handleErrorTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    console.log("table change called " + type);
    if (type === "sort") {
    }
    console.log("sortField + sortOrder" + sortField + sortOrder);
    this.props.actions.setTablePaginationInfo(
      page,
      sizePerPage,
      DataUploadStatus.Error
    );
    var activeSections = [];

    activeSections.push(DataUploadStatus.Error);

    if (type == "pagination" && _.isEmpty(sortOrder) && _.isEmpty(sortField)) {
      sortField = "firstname";
      sortOrder = "asc";
    }
    this.props.actions.setSortOrder(
      sortField,
      sortOrder,
      DataUploadStatus.Error
    );
    const formData = {
      fileId: this.props.payFileState.fileId,
      PageNo: page,
      PageSize: sizePerPage,
      SortBy: sortField,
      SortOrder: sortOrder,
      OperatorType: "AND",
      recordsTypeList: activeSections,
    };

    this.props.actions.searchPayFileOneReviewRecords(formData);
  };
  handleIgnoreTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage, filters }
  ) => {
    console.log("table change" + type);

    this.props.actions.setTablePaginationInfo(
      page,
      sizePerPage,
      DataUploadStatus.Skip
    );
    var activeSections = [];

    activeSections.push(DataUploadStatus.Skip);
    if (type == "pagination" && _.isEmpty(sortOrder) && _.isEmpty(sortField)) {
      sortField = "firstname";
      sortOrder = "asc";
    }
    this.props.actions.setSortOrder(
      sortField,
      sortOrder,
      DataUploadStatus.Skip
    );
    const formData = {
      fileId: this.props.payFileState.fileId,
      PageNo: page,
      PageSize: sizePerPage,
      SortBy: sortField,
      SortOrder: sortOrder,
      OperatorType: "AND",
      recordsTypeList: activeSections,
    };

    this.props.actions.searchPayFileOneReviewRecords(formData);
  };
  handleSuccessTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    console.log("table change" + type);
    if (type === "sort") {
    }
    this.props.actions.setTablePaginationInfo(
      page,
      sizePerPage,
      DataUploadStatus.Update
    );
    var activeSections = [];

    activeSections.push(DataUploadStatus.Update);
    if (type == "pagination" && _.isEmpty(sortOrder) && _.isEmpty(sortField)) {
      sortField = "firstname";
      sortOrder = "asc";
    }
    this.props.actions.setSortOrder(
      sortField,
      sortOrder,
      DataUploadStatus.Update
    );
    const formData = {
      fileId: this.props.payFileState.fileId,
      PageNo: page,
      PageSize: sizePerPage,
      SortBy: sortField,
      SortOrder: sortOrder,
      OperatorType: "AND",
      recordsTypeList: activeSections,
    };

    this.props.actions.searchPayFileOneReviewRecords(formData);
  };
  handleRemoveTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    console.log("table change" + type);
    if (type === "sort") {
    }
    this.props.actions.setTablePaginationInfo(
      page,
      sizePerPage,
      DataUploadStatus.Delete
    );
    var activeSections = [];

    activeSections.push(DataUploadStatus.Delete);
    if (type == "pagination" && _.isEmpty(sortOrder) && _.isEmpty(sortField)) {
      sortField = "firstname";
      sortOrder = "asc";
    }
    this.props.actions.setSortOrder(
      sortField,
      sortOrder,
      DataUploadStatus.Delete
    );
    const formData = {
      fileId: this.props.payFileState.fileId,
      PageNo: page,
      PageSize: sizePerPage,
      SortBy: sortField,
      SortOrder: sortOrder,
      OperatorType: "AND",
      recordsTypeList: activeSections,
    };

    this.props.actions.searchPayFileOneReviewRecords(formData);
  };
  handleToggleSections = (currentActiveTbl) => {
    if (currentActiveTbl === "error") {
      this.setState({
        isignoredlistopen: false,
        issuccesslistopen: false,
      });
    }

    if (currentActiveTbl === "skip") {
      this.setState({
        iserrorlistopen: false,
        issuccesslistopen: false,
      });
    }

    if (currentActiveTbl === "success") {
      this.setState({
        isignoredlistopen: false,
        iserrorlistopen: false,
      });
    }
  };
  openErrorReviewModal = (row, isRemovedSection = false) => {
    var name = `${row.firstname} ${row.lastname}`;
    var separater = "/";
    var regionFacility = `${row.regionName &&
      "(" + row.regionName} ${row.regionName && separater} ${row.facilityName &&
      row.facilityName + ")"}`;
    var rowErrors = row.errors && row.errors;
    this.setState({ openErrorModal: true });
    this.setState({ name: name });
    this.setState({ regionFacility: regionFacility });
    this.setState({ rowErrors: rowErrors });
    this.setState({ payfileoneid: row.id });
    this.setState({ isRemovedSection: isRemovedSection });
  };

  ///Called from Add btn of following Tables
  // Error
  // Ignore
  // Remove
  processSkipUpdate = (
    row,
    isError = false,
    isSkipTable = false,
    isRemoveTable = false,
    memberNo = ""
  ) => {
    var isNewMember = false;
    var isDeleted = false;
    var isErrorRecord = false;
    var customMemberNo = null;
    if (_.isEmpty(row.memberno)) {
      isNewMember = true;
      customMemberNo = memberNo != null || memberNo != "" ? memberNo : null;
    }
    if (row.isdeleted == true) {
      isDeleted = true;
    }
    if (isError) {
      isErrorRecord = true;
    }
    const formData = {
      importedfileId: this.state.importfileid,
      payFileId: row.id,
      isNewMember,
      isDeleted,
      isErrorRecord,
      isSkipTable,
      isRemoveTable,
      customMemberNo,
    };
    this.props.actions.updateIgnoreAsSuccess(formData);
  };

  closePayfileErrorModal = () => {
    this.setState({ openErrorModal: false });
  };
  onIgnoreError = (row) => {
    const formData = {
      importedfileId: this.state.importfileid,
      payFileId: row.id,
    };
    this.props.actions.ignoreUpdateError(formData);
  };
  searchTextKeyupHandler = (event) => {
    if (event.keyCode == 13) {
      this.handleSearchClick(event);
    }
  };
  onDeleteIgnore = (row) => {
    const formData = {
      importedfileId: this.state.importfileid,
      payFileId: row.id,
    };
    this.props.actions.DeleteIgnore(formData);
  };
  searchTextKeyupHandler = (event) => {
    if (event.keyCode == 13) {
      this.handleSearchClick(event);
    }
  };
  handleSearchClick = (e) => {
    e.preventDefault();
    var activeSections = [];

    if (this.state.valid) {
      if (this.state.iserrorlistopen) {
        activeSections.push(DataUploadStatus.Error);
      }
      if (this.state.isignoredlistopen) {
        activeSections.push(DataUploadStatus.Skip);
      }
      if (this.state.issuccesslistopen) {
        activeSections.push(DataUploadStatus.Update);
      }
      if (this.state.isremovedlistopen) {
        activeSections.push(DataUploadStatus.Delete);
      }
      const formData = {
        fileId: this.state.importfileid,
        Keyword: this.state.review_memberSearchText,
        recordsTypeList: activeSections,
        PageNo: 1,
        PageSize: 10,
        OperatorType: "AND",
        SortOrder: "firstnameAsc", //"firstnameAsc",
      };

      this.props.actions.searchPayFileOneReviewRecords(formData);
    } else {
      if (this.state.review_memberSearchText.trim() === "") {
        this.setState({
          review_memberSearchText_err: "No search criteria entered.",
        });
        $("#review_memberSearchkeyword").addClass("inputBorderRed");
      }
      return false;
    }
  };
  searchTextChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    var inputValue = event.target.value.trim();
    if (inputValue !== "") {
      if (
        inputValue.indexOf(" ") > 0 &&
        !(
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[0]) &&
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[1])
        )
      ) {
        /// Contains 2 words &  One of them contains character other than alphabets -- Invalid
        //$("#" + event.target.id).addClass("inputBorderRed");
        $(`.${event.target.name}`).addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Name should contain alphabets only.",
        });
        this.setState({ valid: false });
      } else if (
        !(/^[a-zA-Z ]*$/.test(inputValue) || /^[0-9]*$/.test(inputValue))
      ) {
        /// alphanumeric or special characers with one word
        $(`.${event.target.name}`).addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Alphanumeric is not allowed.",
        });
        this.setState({ valid: false });
      } else {
        $(`.${event.target.name}`).removeClass("inputBorderRed");
        this.setState({ [event.target.name + "_err"]: "" });
        this.setState({ valid: true });
      }
    } else {
      /// searching with empty field
      // this.setState({
      //     [event.target.name + "_err"]: "No search criteria entered."
      // });
      this.setState({ valid: false });
      this.setState({ [event.target.name + "_err"]: "" });
      $(`.${event.target.name}`).removeClass("inputBorderRed");
    }
  };
  onExportToCSV = () => {
    const formData = {
      fileId: 5,
      recordtype: DataUploadStatus.Error,
      PageNo: 1,
      PageSize: 56,
      SortBy: "firstname",
      SortingOrder: "asc",
      OperatorType: "AND",
    };
    this.props.actions.fetchPayFileOneReviewRecords(formData);
  };
  render() {
    return (
      <AUX>
        <div className="container-fluid">
          {AuthService.canView(PermissionFeature.Payfile1ReviewSummary) ? (
            <React.Fragment>
              <br />
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <h4 className="page-title-search">Import Review Screen</h4>
                </div>

                <div className="col-sm-8">
                  <form
                    role="search"
                    className="form-inline marginRight width100"
                    onSubmit={this.handleSearchClick}
                  >
                    <div className="input-group width100">
                      <input
                        name="review_memberSearchText"
                        type="text"
                        autoComplete="off"
                        id="review_memberSearchkeyword"
                        value={this.state.review_memberSearchText}
                        onChange={this.searchTextChangeHandler}
                        onKeyUp={this.searchTextKeyupHandler}
                        className="form-control review_memberSearchText"
                        placeholder="Search Review Records .."
                      />
                      <div className="input-group-append">
                        <button type="submit" className="topBarSearchBtn">
                          <i className="fa fa-search" /> Search
                        </button>
                      </div>
                      <small className="text-danger w-100">
                        {this.state.review_memberSearchText_err}
                      </small>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row reviewParentDiv">
                <div className="col">
                  <Card className="mb-1">
                    <h6
                      className="card-header bg-danger text-white mb-3 mt-0 cursorPointer"
                      onClick={() => {
                        this.setState(
                          {
                            iserrorlistopen: !this.state.iserrorlistopen,
                          },
                          () => {
                            var activeSections = [];

                            activeSections.push(DataUploadStatus.Error);

                            const formData = {
                              fileId: this.props.payFileState.fileId,
                              Keyword: "",
                              recordsTypeList: activeSections,
                              PageNo: 1,
                              PageSize: 10,
                              OperatorType: "AND",
                              SortBy: this.props.payFileState.sortErrorBy,
                              SortOrder: this.props.payFileState.orderErrorBy,
                            };

                            if (this.state.iserrorlistopen) {
                              this.props.actions.setTablePaginationInfo(
                                1,
                                10,
                                DataUploadStatus.Error
                              );
                              this.props.actions.searchPayFileOneReviewRecords(
                                formData
                              );
                            }
                          }
                        );
                      }}
                    >
                      <i
                        className="fas fa-exclamation-triangle pr-1"
                        aria-hidden="true"
                      ></i>
                      IMPORT ERRORS
                    </h6>

                    <Collapse isOpen={this.state.iserrorlistopen}>
                      <div className="col-12">
                        <PayFilesMembersList
                          listMembers={this.props.payFileState.errorRows}
                          handleTableChange={this.handleErrorTableChange}
                          expandRow={this.expandRowErrors}
                          totalSize={this.props.payFileState.totalErrorCount}
                          sizePerPage={this.props.payFileState.errorSizePerPage}
                          page={this.props.payFileState.errorPageNo}
                          loading={this.props.payFileState.isLoadingErrors}
                          //onDataSizeChange={this.handleErrorDataChange}
                          reviewTableType="error"
                          handleToggleSections={(ct) =>
                            this.handleToggleSections(ct)
                          }
                          fileId={this.props.payFileState.fileId}
                        />
                      </div>
                    </Collapse>
                  </Card>
                </div>
                {/* <PayFileOneIgnoredMembers /> */}
                <div className="col-12">
                  <Card className="mb-1">
                    <h6
                      className="card-header bg-warning text-white mb-3 mt-0 cursorPointer"
                      onClick={() => {
                        const formData = {
                          fileId: this.props.payFileState.fileId,
                          recordtype: DataUploadStatus.Skip,
                          PageNo: 1,
                          PageSize: 10,
                          SortBy: this.props.payFileState.sortIgnoreBy,
                          SortOrder: this.props.payFileState.orderIgnoreBy,
                          OperatorType: "AND",
                        };

                        this.setState(
                          {
                            isignoredlistopen: !this.state.isignoredlistopen,
                          },
                          () => {
                            var activeSections = [];

                            activeSections.push(DataUploadStatus.Skip);

                            const formData = {
                              fileId: this.props.payFileState.fileId,
                              Keyword: "",
                              recordsTypeList: activeSections,
                              PageNo: 1,
                              PageSize: 10,
                              OperatorType: "AND",
                              SortBy: this.props.payFileState.sortIgnoreBy,
                              SortOrder: this.props.payFileState.orderIgnoreBy, //"firstnameAsc",
                            };

                            if (this.state.isignoredlistopen) {
                              this.props.actions.setTablePaginationInfo(
                                1,
                                10,
                                DataUploadStatus.Skip
                              );
                              this.props.actions.searchPayFileOneReviewRecords(
                                formData
                              );
                            }
                          }
                        );
                      }}
                    >
                      <i
                        className="fa fa-fast-forward pr-1"
                        aria-hidden="true"
                      ></i>
                      IGNORED UPDATES{" "}
                    </h6>

                    <Collapse isOpen={this.state.isignoredlistopen}>
                      <div className="col-12">
                        <PayFilesMembersList
                          listMembers={this.props.payFileState.ignoredRows}
                          handleTableChange={this.handleIgnoreTableChange}
                          expandRow={this.expandRowIgnore}
                          totalSize={this.props.payFileState.totalIgnoredCount}
                          sizePerPage={
                            this.props.payFileState.ignoreSizePerPage
                          }
                          page={this.props.payFileState.ignorePageNo}
                          loading={this.props.payFileState.isLoadingSkips}
                          /// onDataSizeChange={this.handleIgnoreDataChange}
                          reviewTableType="skip"
                          handleToggleSections={(ct) =>
                            this.handleToggleSections(ct)
                          }
                          fileId={this.props.payFileState.fileId}
                        />
                      </div>
                    </Collapse>
                  </Card>
                </div>
                <div className="col-12">
                  <Card className="mb-1">
                    <h6
                      className="card-header bg-success text-white mb-3 mt-0 cursorPointer"
                      onClick={() => {
                        this.setState(
                          {
                            issuccesslistopen: !this.state.issuccesslistopen,
                          },
                          () => {
                            var activeSections = [];

                            activeSections.push(DataUploadStatus.Update);

                            const formData = {
                              fileId: this.props.payFileState.fileId,
                              Keyword: "",
                              recordsTypeList: activeSections,
                              PageNo: 1,
                              PageSize: 10,
                              OperatorType: "AND",
                              SortBy: this.props.payFileState.sortSuccessBy,
                              SortOrder: this.props.payFileState.orderSuccessBy,
                            };
                            if (this.state.issuccesslistopen) {
                              this.props.actions.setTablePaginationInfo(
                                1,
                                10,
                                DataUploadStatus.Update
                              );
                              this.props.actions.searchPayFileOneReviewRecords(
                                formData
                              );
                            }
                          }
                        );
                      }}
                    >
                      <i className="fa fa-check-circle pr-1"></i>
                      SUCCESSFULL UPDATES
                    </h6>

                    <Collapse isOpen={this.state.issuccesslistopen}>
                      <div className="col-12">
                        <PayFilesMembersList
                          listMembers={this.props.payFileState.successRows}
                          handleTableChange={this.handleSuccessTableChange}
                          expandRow={this.expandRowSuccess}
                          totalSize={this.props.payFileState.totalSuccessCount}
                          sizePerPage={
                            this.props.payFileState.successSizePerPage
                          }
                          page={this.props.payFileState.successPageNo}
                          loading={this.props.payFileState.isLoadingSuccess}
                          // onDataSizeChange={this.handleSuccessDataChange}
                          reviewTableType="success"
                          handleToggleSections={(ct) =>
                            this.handleToggleSections(ct)
                          }
                          fileId={this.props.payFileState.fileId}
                        />
                      </div>
                    </Collapse>
                  </Card>
                </div>

                {/* Start of removed section */}
                <div className="col-12">
                  <Card className="mb-1">
                    <h6
                      className="card-header removedSection text-white mb-3 mt-0 cursorPointer"
                      onClick={() => {
                        const formData = {
                          fileId: this.props.payFileState.fileId,
                          recordtype: DataUploadStatus.Delete,
                          PageNo: 1,
                          PageSize: 10,
                          SortBy: this.props.payFileState.sortRemovedBy,
                          SortOrder: this.props.payFileState.orderRemovedBy,
                          OperatorType: "AND",
                        };

                        this.setState(
                          {
                            isremovedlistopen: !this.state.isremovedlistopen,
                          },
                          () => {
                            var activeSections = [];

                            activeSections.push(DataUploadStatus.Delete);

                            const formData = {
                              fileId: this.props.payFileState.fileId,
                              Keyword: "",
                              recordsTypeList: activeSections,
                              PageNo: 1,
                              PageSize: 10,
                              OperatorType: "AND",
                              SortBy: this.props.payFileState.sortRemovedBy,
                              SortOrder: this.props.payFileState.orderRemovedBy,
                            };
                            if (this.state.isremovedlistopen) {
                              this.props.actions.setTablePaginationInfo(
                                1,
                                10,
                                DataUploadStatus.Delete
                              );
                              this.props.actions.searchPayFileOneReviewRecords(
                                formData
                              );
                            }
                          }
                        );
                      }}
                    >
                      <i
                        className="fa fa-fast-forward pr-1"
                        aria-hidden="true"
                      ></i>
                      REMOVED UPDATES{" "}
                    </h6>

                    <Collapse isOpen={this.state.isremovedlistopen}>
                      <div className="col-12">
                        <PayFilesMembersList
                          listMembers={this.props.payFileState.removedRows}
                          handleTableChange={this.handleRemoveTableChange}
                          expandRow={this.expandRowRemoved}
                          totalSize={this.props.payFileState.totalRemovedCount}
                          sizePerPage={
                            this.props.payFileState.removeSizePerpage
                          }
                          page={this.props.payFileState.removePageNo}
                          loading={this.props.payFileState.isLoadingRemove}
                          reviewTableType="removed"
                          handleToggleSections={(ct) =>
                            this.handleToggleSections(ct)
                          }
                          fileId={this.props.payFileState.fileId}
                        />
                      </div>
                    </Collapse>
                  </Card>
                </div>
                <PayfileErrorModal
                  name={this.state.name}
                  regionFacility={this.state.regionFacility}
                  errors={this.state.rowErrors}
                  showModal={this.state.openErrorModal}
                  closePayfileErrorModal={this.closePayfileErrorModal}
                  importfileid={this.state.importfileid}
                  payfileid={this.state.payfileoneid}
                  isRemovedSection={this.state.isRemovedSection}
                />
                {/* <PayFileOneIgnoredMembers /> */}
              </div>
            </React.Fragment>
          ) : (
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-lg-2"></div>
                <div className="col-lg-6 text-center">
                  <h4 className=" text-center text-uppercase page-title-search">
                    You're unauthorized to access the payfiles
                  </h4>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          )}
        </div>
      </AUX>
    );
  }
}

//export default ReviewScreenPayFileOne;

function mapStateToProps(state, ownProps) {
  return {
    errorRows: state.payfile.errorRows,
    payFileState: state.payfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(payfileActions, dispatch),
  };
}
// export default connect((state) => ({ payFileState: state.payfileReducer }), {
//   ...payfileActions,
// })(ReviewScreenPayFileOne);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewScreenPayFileOne);
