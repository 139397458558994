import axios from "../../modules/Shared/auth-header";


import AuthService from "../../modules/Auth/AuthService";


const actions = {

    SET_FEATURES: "SET_FEATURES",

    setPermissions: val => dispatch => {


        dispatch({
            type: actions.SET_FEATURES,
            payload: val
        });
    },


    canViewDirectorySubFeatures: name => (dispatch, getState) => {

        const permissions = JSON.parse(localStorage.getItem("permissions")).features;
        let result = false;
        try {
            result = permissions.find(item => {
                return item.name === "Directory";

            }).subFeatures.find(item => {
                return item.name === name;
            }).permissions.canView;

            return result;
        } catch (err) {
            //   console.log(err + "CAN_VIEW_DIRECTORY_SUB_FEATURE")

        }

    },


    getFacilityCompPermissionByName: (name, action) => (dispatch, getState) => {


        let result = false;
        const permissions = JSON.parse(localStorage.getItem("permissions")).features;
        try {

            if (permissions !== undefined && permissions.length > 0) {


                result = permissions.find(r => {
                    return r.name === "Directory";
                }).subFeatures.find(el => {
                    return el.name === "Facility";
                }).components.find(s => {
                    return s.name === name;
                }).permissions[action];

                return result;
            } else {
                return result;
            }
        } catch (err) {
            //console.log(err + "CAN_VIEW_DIRECTORY_FACILITY_COMPONENT")
        }


    },


    // canViewDirectoryMembersComponents(name) {
    //     let result = false;
    //     let subFeat = {};
    //     let component = {};
    //     if (this.permissions) {
    //       const feat = this.permissions.features.filter(r => {
    //         return r.name === "Directory";
    //       });
    //       subFeat = feat[0].subFeatures.filter(el => {
    //         return el.name === "Members";
    //       });

    //       component = subFeat[0].components.filter(s => {
    //         return s.name === name;
    //       });
    //       result = component[0].permissions.canView;
    //     }

    //     return result;
    //   },


    //   hasAccessSub = name => {
    //     let perms = [];
    //     if (this.permissions) {
    //       const sub = this.permissions.features.filter(r => {
    //         return r.name == "Directory";
    //       });
    //       perms = sub[0].subFeatures.filter(el => {
    //         if (el.hasAccess && el.name == name) return el;
    //       });
    //     }

    //     return perms.length > 0;
    //   },

    //   hasAccess = name => {
    //     let perms = [];
    //     if (this.permissions) {
    //       perms = this.permissions.features.filter(el => {
    //         if (el.hasAccess && el.name == name) return el;
    //       });
    //     }

    //     return perms.length > 0;
    //   }


};

export default actions;
