import actions from "./action";

const initialState = {
  isRepresentative: true,
  LoggingVisible: true,
  grievanceLoggingVisible: true,
  isReloadAttachments: false,
};

export default function grievances(state = initialState, action) {
  switch (action.type) {
    case actions.SET_IS_REPRESENTATIVE:
      return {
        ...state,
        isRepresentative: action.payload,
      };
    case actions.RELOAD_ATTACHMENTS:
      return {
        ...state,
        isReloadAttachments: action.payload,
      };
    case actions.TOGGLE_GRIEVANCE_LOG_CARD:
      return {
        ...state,
        grievanceLoggingVisible: action.payload,
      };
    default:
      return state;
  }
}
