import React, { Component } from "react";

export default class ForgotUsername extends Component {
  render() {
    return (
      <div className="App">
        <div className="container">
          <h1>
            Page
            <br />
            Coming Soon
          </h1>
        </div>
      </div>
    );
  }
}
