import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import GrievanceAction from "../../../store/grievance/action";
import "../../../assets/css/PermissionHeader.css";
import "../../../assets/css/GrievanceStyle.css";
import _ from "lodash";
import Services from "../GrievanceService";

class GrievanceHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      subPage: "",
      secondSubPage: "",
    };
  }
  componentDidMount() {
    Services.GetRepresentativeRole().then((response) => {
      if (!_.isEmpty(response)) this.props.setRepresentative(response.data);
    });
    let addresses = window.location.pathname.split("/");
    let page = addresses.length > 1 ? addresses[1] : "";
    let subPage = addresses.length > 2 ? addresses[2] : "";
    let secondSubPage = addresses.length > 3 ? addresses[3] : "";
    this.setState({
      page: page,
      subPage: subPage,
      secondSubPage: secondSubPage,
    });
    if (page == "Grievances" && !subPage) {
      this.props.changeActiveTab(1);
    } else if (page == "Grievances" && subPage == "ReviewGrievances") {
      this.props.changeActiveTab(2);
    }
  }
  render() {
    let { page, subPage } = this.state;
    return (
      <nav className="navbar navbar-expand-sm permission-nav">
        <Fragment>
          <a
            className={
              "p-tabs c-pointer" + (this.props.ActiveTab === 1 ? " active" : "")
            }
            data-tab="MyGrievances"
            onClick={() => {
              this.props.history.push("/Grievances");
              this.props.changeActiveTab(0);
            }}
          >
            <i class="fas fa-clipboard"></i> My Grievances
          </a>
          {AuthService.canView(PermissionFeature.GRReviewTab) &&
            this.props.grievanceState.isRepresentative && (
              <a
                className={
                  "p-tabs c-pointer" +
                  (this.props.ActiveTab === 2 ? " active" : "")
                }
                data-tab="ReviewGrievances"
                onClick={() => {
                  this.props.history.push("/Grievances/ReviewGrievances");
                  this.props.changeActiveTab(2);
                }}
              >
                <i class="fas fa-history"></i> Grievances
              </a>
            )}
        </Fragment>
        {page.toLowerCase() == "grievances" &&
          subPage == "" && (
            <button
              className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
              onClick={() => {
                this.props.history.push("/Grievances/addGrievance");
                this.props.changeActiveTab(2);
              }}
              type="submit"
            >
              Create New Grievance
            </button>
          )}
      </nav>
    );
  }
}

export default withRouter(
  connect((state) => ({ grievanceState: state.grievances }), {
    ...GrievanceAction,
  })(GrievanceHeader)
);
