export default [
  "NAME_LAST",
  "NAME_FIRST",
  "NAME_MIDDLE",
  "BUS",
  "EMPLOYEE_COMMON_ID",
  "DT_EOD_BUR",
  "FLSA_CAT",
  "AWS_CD",
  "DT_OF_BIRTH",
  "OCC_SER",
  "PB_LVL",
  "PSN_TITLE_OPM",
  "GRD",
  "SUB_BUR",
  "ORG_CD",
  "ORG_TITLE",
  "FACILITY_ADRS_ROUTING_SYMBOL",
  "HOURLY_SALARY",
  "YEARLY_SALARY",
  "SCD",
  "OPM_PAY_STATUS_CODE",
  "DTY_STN_CD",
  "STAT_SPEC",
  "VET_PREF",
  "SUPV_STAT",
  "DUTY_STN_DESC",
  "ANNUITY_IND",
  "ANNUITY_AMT",
  "VET_STATUS",
  "WORK_SCHED",
];
