import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from "@react-pdf/renderer";

Font.register({
    family: "Open Sans",
    fonts: [
        {
            src:
                "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
        },
        {
            src:
                "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
            fontWeight: "light",
        },
    ],
});

const styles = StyleSheet.create({
    GrienvantSecondFooter: {
        marginTop: 10,
        textDecoration: "underline",
        fontSize: 13,
        fontWeight: "500",
    },
    grievanceHeaderLeft: {
        width: "100%",
        float: "left",
    },
    grievanceHeaderRight: {
        width: "50%",
        marginLeft: 330,
        marginTop: -30,
        float: "right",
    },
    headerImageView: {
        marginLeft: 100,
    },
    GrienvantFooter: {
        marginTop: 30,
        marginLeft: 25,
        marginBottom: -10,
    },
    image: {
        width: 80,
        height: 80,
        float: "left",
    },
    floatLeft: {
        float: "left",
    },
    sec: {
        //marginTop: 80
    },
    Dear: {
        marginTop: 20,
        marginBottom: 20,
    },
    afterSalution: {
        marginTop: 10,
    },
    afterDateText: {
        marginTop: 30,
    },
    dateText: {
        marginTop: 50,
    },
    logoImage: {
        width: 80,
        height: 80,
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        fontSize: 12,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: "left",
        marginTop: 20,
        fontFamily: "Oswald",
    },
    author: {
        fontSize: 12,
        textAlign: "center",
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: "Oswald",
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "Times-Roman",
    },

    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

export function PARMultiPdfDocument(props) {
    if (!props.data || props.data.length === 0) {
        return null;
    }
    return (
        <Document>

            <Page style={styles.body}>
                <View style={styles.grievanceHeaderLeft}>
                    <Image
                        style={styles.image}
                        src="https://upload.wikimedia.org/wikipedia/en/6/67/NATCA_logo.png"
                    />
                    <View style={[styles.headerImageView, { marginTop: -60, float: 'right' }]}>
                        <Text>{props.data[0].representativeName}</Text>
                        <Text>{props.data[0].representativePhone}</Text>
                        <Text
                            style={{ width: "200px", display: "flex", flexDirection: "row" }}
                        >
                            {props.data[0].representativeEmail}
                        </Text>
                    </View>
                </View>
                <Text style={styles.dateText}>{props.data[0].submissionDate}</Text>
                <Text>  </Text>
                <Text style={styles.afterDateText}>{props.data[0].recipientName}</Text>
                <Text style={styles.afterSalution}>{props.data[0].memberName}</Text>
                <Text style={styles.afterSalution}>{props.data[0].memberFacilityAddress}</Text>
                <View>
                    <Text style={styles.Dear}>Dear {props.data[0].recipientName},</Text>
                </View>
                <View>
                    <Text>
                        This letter is to request that, in accordance with the applicable Collective Bargaining Agreement, the following grievance(s) be submitted for Pre-Arbitration Review:
                    </Text>
                </View>
                <View>
                    {props.data[0].multipleFAAGrievanceNumbers.map((element, index) => (

                        <Text style={styles.GrienvantFooter}>
                            <Text>    </Text><Text>{index + 1}.</Text>  <Text style={{ width: "200px" }}>{element.grievanceNumber}</Text>    <Text>{element.fAANumber}</Text>
                        </Text>
                    ))}
                </View>
                <Text>  </Text>
                <View>
                    <Text
                        style={[
                            styles.GrienvantSecondFooter,
                            { fontWeight: "100 !mportant" },
                        ]}
                    >
                        Sincerely,
                    </Text>
                </View>

                <View>

                    <View style={{ marginTop: 80, display: "flex", flexDirection: "row" }}>
                        <Text style={{ textDecoration: "underline" }}>
                            {props.data[0].rvpName}
                        </Text>
                        <Text> </Text>
                        <Text style={{ textDecoration: "underline" }}>
                            {props.data[0].rvpTitle}
                        </Text>
                        <Text> </Text>
                        <Text style={{ textDecoration: "underline" }}>
                            {props.data[0].regionName}
                        </Text>
                    </View>
                    <View style={{ marginTop: -15, display: "flex", flexDirection: "row" }}>
                        <Text
                            style={{
                                textDecoration: "underline",
                                fontWeight: "light",
                                width: "180px",
                                marginLeft: 190,
                                textAlign: "right",
                            }}
                        >
                            {props.data[0].submittionDate}
                        </Text>
                        <Text> </Text>
                    </View>
                </View>

                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />
            </Page>
        </Document>
    );
}
