
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function GrievanceCloseReasonModal(props) {
    
    const {
        modal,
        toggle
    } = props;

    return (
        <Modal size="xl" centered={true} autoFocus={true} isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader>Reason For Withdrawing Grievance</ModalHeader>
            <ModalBody>
                <div className="col-12 mb-4">
                    <textarea
                        name=""
                        containerClass="mb-0"
                        className="w-100 border"
                        rows="5"
                        maxlength="500"
                        onChange={props.onChangeReason}>{props.reason}</textarea>
                    <span className="text-danger">
                        {props.reason_err}
                    </span>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.saveWithdrawnResponse}>Save Response</Button>
                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}