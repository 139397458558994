
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function GrievanceDetailsOnlyModal(props) {
    const {
        modal,
        toggle
    } = props;

    return (
        <Modal size="xl" centered={true} autoFocus={true} isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader style={{ textAlign: 'center', display: 'block'}}><h3>Grievance Details</h3></ModalHeader>
            <ModalBody style={{ fontSize: '18px', fontFamily: 'calibri' }}>
                <div className="col-12">
                    {
                        props.data.map(function (d, idx) {
                            return (
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h5>Step Name : <b>{d.stepName}</b></h5>
                                    </div>
                                    {(idx == 0) && 
                                        <div className='col-md-12'>
                                        <div className='col-md-12'>
                                            <div className='col-md-3' style={{ float: 'left' }}>
                                                <p>
                                                    <b>Grievant : </b> {d.grievant}
                                                </p>
                                            </div>
                                            <div className='col-md-3' style={{ float: 'left' }}>
                                                <p>
                                                    <b>FAA Number : </b> {d.fAANumber}
                                                </p>
                                            </div>
                                            <div className='col-md-3' style={{ float: 'left' }}>
                                                <p>
                                                    <b>Voilation : </b> {d.violation}
                                                </p>
                                            </div>
                                            <div className='col-md-3' style={{ float: 'left' }}>
                                                <p>
                                                    <b>Recipient : </b> {d.recipientName}
                                                </p>
                                            </div>
                                            </div>
                                            {(d.withdrawReason != '' || d.withdrawReason != undefined) && 
                                                <div className='col-md-12'>
                                                    <div className='col-md-3'>
                                                        <p>
                                                            <b>WithDraw Reason : </b> {d.withdrawReason}
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                    </div>
                                    }
                                    <div className='col-md-12'>
                                        <div className='col-md-3' style={{ float: 'left' }}>
                                            <p>
                                                <b>Adverse Action : </b> {d.isAdverse}
                                            </p>
                                        </div>
                                        <div className='col-md-3' style={{ float: 'left' }}>
                                            <p>
                                                <b>Meeting Requested : </b> {d.isMeeting}
                                            </p>
                                        </div>
                                        <div className='col-md-3' style={{ float: 'left' }}>
                                            <p>
                                                <b>Statement of Facts : </b> {d.statementOfFact}
                                            </p>
                                        </div>
                                        <div className='col-md-3' style={{ float: 'left' }}>
                                            <p>
                                                <b>Remedy Sought : </b> {d.remedySought}
                                            </p>
                                        </div>
                                        
                                    </div>
                                    {(d.isMeeting &&
                                        <div className='col-md-12'>
                                            
                                        </div>)}
                                    {((d.responseDate != '' || d.isMeeting) &&
                                        <div className='col-md-12'>
                                            {((d.isMeeting && d.meetingDate != '') && <div className='col-md-3' style={{ float: 'left' }}>
                                                <p>
                                                    <b>Meeting Date : </b> {d.meetingDate}
                                                </p>
                                            </div>)}
                                            {(d.isMeeting && <div className='col-md-3' style={{ float: 'left' }}>
                                                <p>
                                                    <b>Response Date : </b> {d.responseDate}
                                                </p>
                                            </div>)}
                                            
                                        </div>
                                    )}
                                    
                                </div>
                                )
                        })
                    }
                    
                </div>
            </ModalBody>
            <ModalFooter>
                {((props.grievanceStatus == 'Withdrawn' || props.grievanceStatus == 'Denied' || props.grievanceStatus == 'Settled') &&
                    <Button type="button" color="primary" onClick={props.reActive(props.grievanceId)}>ReOpen Grievance</Button>
                )}
                <Button type="button" color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}