export default [
  "EMPLOYEE_COMMON_ID",
  "NAME_LAST",
  "NAME_FIRST",
  "NAME_MIDDLE",
  "BUS TO",
  "BUS FROM",
  "NOA_1",
  "NOA_2",
  "DATE_EFFECTIVE",
  "SUB_BUR",
  "SUB_BUR_FROM",
  "ORG",
  "ORG_TITLE",
  "FACILITY_ADRS_ROUTING_SYMBOL",
  "ORG_FROM",
  "ORG_TITLE_1",
  "FACILITY_ADRS_ROUTING_SYMBOL_1",
  "DATE_STATUS_CREATED",
  "SUPV_STAT",
  "SUPV_LVL_CODE",
  "SUPV_LVL_DESC",
];

// export default [
//   "Employee Common ID",
//   "BUS To",
//   "BUS From",
//   "Nature of Action 1",
//   "Nature of Action 2",
//   "Begin Date",
//   "Organization Code",
//   "Facility Routing Symbol From",
//   "Facility Routing Symbol To",
//   "Supervisor Stat",
//   "Supervisor Level Code",
//   "Supervisor Level Description",
// ];
