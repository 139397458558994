import React, { Component } from "react";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { connect } from "react-redux";
import actionFilter from "../../../store/facility/action";
import actionActivity from "../../../store/activitylogging/action";
import { Collapse, Spinner, Col } from "reactstrap";
import AuditModuleTypes from "../../Shared/AuditModuleTypes";
import $ from "jquery";
import Services from "../../Permissions/PermissionService";

import Users from "../../Admin/Users/Users";
import NCEPT from "./NCEPT";
import Finance from "./Finance";
import TrafficCount from "./TrafficCount";
import OSHA from "./OSHA";
import OWCP from "./OWCP";
import Legislative from "./Legislative";
import Maps from "./Maps";
import Assets from "./Assets";
import TrainedActivists from "./TrainedActivists";
import Grievaneces from "./Grievaneces";
import _ from "lodash";
import Tree from "./Tree/Tree";

import ActivityLogging from "../../../../src/modules/Shared/ActivityLogging";
import EquipmentsCardList from "../../Admin/Users/EditUser/EquipmentsCardList";
import FE_Equipment_Card_Types from "../../F&E/FE_Equipment_Card_Types";
import FacilityEmailList from "./FacilityEmailList";
import FacilityDashboardAccordions from "./Enums/FacilityDashboardAccordions";
export class FacilityCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadFacilityEmail: false,
      rvpMems: [],
    };
    this.emailListRef = React.createRef();
    // this.DEBUG = process.env.NODE_ENV === "development";
    // if (this.DEBUG) {
    // }
  }

  componentDidMount() {
    this.getRepresentatives();
  }
  componentDidUpdate(prevProps) {
    const { defaultAccordion } = this.props;
    if (defaultAccordion !== prevProps.defaultAccordion) {
      this.onAccordionClick(FacilityDashboardAccordions.EmailList);
    }
  }
  scrollAccordion = () => {
    setTimeout(() => {
      if (this.emailListRef.current) {
        const emailListElement = this.emailListRef.current;
        emailListElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  };
  onFilesAccordionClick = (fileid) => {
    if (this.props.facilityDashboardState.facilityActiveTab !== 1) {
      this.props.getFacilityFiles(this.props.facilityId);
      this.props.setFacilityActiveTab(fileid);
    } else if (this.props.facilityDashboardState.facilityActiveTab === 1) {
      this.props.setFacilityActiveTab(0);
    }
  };

  onAccordionClick = async (id) => {
    let currentSelectedItem = this.props.facilityDashboardState
      .facilityActiveTab;

    if (currentSelectedItem !== id) {
      if (id === FacilityDashboardAccordions.Maps) {
        /// Maps
        this.props.getSelectedMapsForFacility(this.props.facilityId);
      }

      if (id === FacilityDashboardAccordions.ActivityLogging) {
        /// ActivityLogging

        this.props.resetLogs();
        this.props.getActicityLoggingByModuleId(
          AuditModuleTypes.Facility,
          this.props.facilityId,
          0,
          10
        );
      }
      if (id === FacilityDashboardAccordions.EmailList) {
        this.setState({ loadFacilityEmail: true });
      }
      if (id === FacilityDashboardAccordions.Members) {
        this.setState({ loadMembers: true });
      }
      if (id === FacilityDashboardAccordions.PAC) {
        this.setState({ loadPAC: true });
      }
      await this.props.setFacilityActiveTab(id);
    } else if (currentSelectedItem === id) {
      this.props.setFacilityActiveTab(0);
    }
  };
  async getRepresentatives() {
    if (this.props.facilityId && this.props.facilityId != undefined) {
      $(".loading").show();
      let data = await Services.FacilityMembers(this.props.facilityId);
      if (!_.isEmpty(data)) {
        let facilityMems = _.filter(data.result.members, function(o) {
          return o.nothistory;
        });
        this.setState({
          rvpMems: facilityMems || [],
        });
      }
      $(".loading").hide();
    } else {
      this.setState({
        rvpMems: [],
      });
    }
  }
  render() {
    let loggeduser = AuthService.getProfile();
    const { rvpMems = [] } = this.state;
    let isRVP = false;
    if (!_.isEmpty(loggeduser) && !_.isEmpty(rvpMems)) {
      isRVP = rvpMems.some(
        (m) =>
          m.memberID == loggeduser.memberData.id &&
          m.positionName == "Facility President" &&
          m.nothistory
      );
      //console.log("isRVP", isRVP);
    }

    let currentActiveTab = this.props.facilityDashboardState.facilityActiveTab;
    //debugger;
    return (
      <>
        {/* <button
          className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
          type="button"
          onClick={() => {
            if (this.emailListRef.current) {
              const emailListElement = this.emailListRef.current;
              emailListElement.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          Test
        </button> */}
        <Col className="facilityCategoriesList">
          {AuthService.canFacilityView(PermissionFeature.FacilityFiles) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.Files
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onFilesAccordionClick(FacilityDashboardAccordions.Files)
                }
              >
                <a className="font-italic">Files</a>

                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.Files
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab === FacilityDashboardAccordions.Files}
              >
                {!this.props.facilityDashboardState.IsFilesTreeLoading && (
                  <Tree facilityId={this.props.facilityId} />
                )}

                {this.props.facilityDashboardState.IsFilesTreeLoading && (
                  <div className="row spinnerFacilityCategory">
                    <Spinner />
                  </div>
                )}
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(PermissionFeature.FacilityMembers) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.Members
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.Members)
                }
              >
                <a className="font-italic">Members</a>

                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.Members
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab == FacilityDashboardAccordions.Members}
              >
                {this.props.facility &&
                  currentActiveTab === FacilityDashboardAccordions.Members && (
                    <Users
                      his={this.props.his}
                      isFacility={true}
                      isPAC={false}
                      facility={this.props.facility}
                      FacilityDashboard={true}
                      FacilityMembers={true}
                      facilityId={this.props.facilityId}
                    />
                  )}

                {this.props.facilityDashboardState.IsMemberAccordianLoading && (
                  <div className="row spinnerFacilityCategory">
                    <Spinner />
                  </div>
                )}
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(PermissionFeature.FacilityGrievances) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.Grievances
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.Grievances)
                }
              >
                <a className="font-italic">Grievances</a>

                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.Grievances
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={
                  currentActiveTab == FacilityDashboardAccordions.Grievances
                }
              >
                <Grievaneces />
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(PermissionFeature.PacMembers) ||
          AuthService.canFacilityView(PermissionFeature.PacSummary) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.PAC
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.PAC)
                }
              >
                <a className="font-italic">PAC</a>
                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.PAC
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab === FacilityDashboardAccordions.PAC}
              >
                <div className="accordianContainerBorder">
                  {AuthService.canFacilityView(PermissionFeature.PacMembers) &&
                    this.props.facility &&
                    currentActiveTab === FacilityDashboardAccordions.PAC && (
                      <Users
                        his={this.props.his}
                        isFacility={true}
                        isPAC={true}
                        facility={this.props.facility}
                        FacilityDashboard={true}
                        PACMembers={true}
                      />
                    )}

                  {this.props.facilityDashboardState
                    .IsMemberAccordianLoading && (
                    <div className="row spinnerFacilityCategory">
                      <Spinner />
                    </div>
                  )}
                </div>
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(
            PermissionFeature.FacilityNceptStaffing
          ) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.NCEPT
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.NCEPT)
                }
              >
                <a className="font-italic">NCEPT/Staffing </a>
                <span>
                  Visit{" "}
                  <a target="_blank" href="https://www.natca.org/ncept/">
                    <b>NATCA.org/NCEPT </b>
                  </a>
                  for more information on the NCEPT process
                </span>
                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.NCEPT
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab == FacilityDashboardAccordions.NCEPT}
              >
                <NCEPT facilityId={this.props.facilityId} />

                {/* <h5>NCEPT</h5> */}
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(PermissionFeature.Finance) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.Finance
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.Finance)
                }
              >
                <a className="font-italic">Finance</a>
                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.Finance
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab == FacilityDashboardAccordions.Finance}
              >
                <Finance facilityId={this.props.facilityId} />

                {/* <h5>NCEPT</h5> */}
              </Collapse>
            </div>
          ) : null}
          {AuthService.canFacilityView(
            PermissionFeature.FacilityTrafficCount
          ) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.TrafficCount
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(
                    FacilityDashboardAccordions.TrafficCount
                  )
                }
              >
                <a className="font-italic"> Traffic Count</a>

                <i
                  className={
                    currentActiveTab ===
                    FacilityDashboardAccordions.TrafficCount
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={
                  currentActiveTab == FacilityDashboardAccordions.TrafficCount
                }
              >
                <TrafficCount />
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(PermissionFeature.FacilityOsha) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.OSHA
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.OSHA)
                }
              >
                <a className="font-italic">OSHA</a>

                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.OSHA
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab == FacilityDashboardAccordions.OSHA}
              >
                <OSHA facilityId={this.props.facilityId} />
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(PermissionFeature.FacilityOwcp) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.OWCP
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.OWCP)
                }
              >
                <a className="font-italic">OWCP</a>
                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.OWCP
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab === FacilityDashboardAccordions.OWCP}
              >
                <OWCP />
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(
            PermissionFeature.FacilityLegislative
          ) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.Legislative
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.Legislative)
                }
              >
                <a className="font-italic">Legislative</a>
                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.Legislative
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={
                  currentActiveTab === FacilityDashboardAccordions.Legislative
                }
              >
                <Legislative />
              </Collapse>
            </div>
          ) : null}

          {AuthService.canFacilityView(PermissionFeature.FacilityMaps) ? (
            <div>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.Maps
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.Maps)
                }
              >
                <a className="font-italic">Maps</a>

                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.Maps
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={currentActiveTab === FacilityDashboardAccordions.Maps}
                className="accordianContainerBorder facilityAccordianHeight"
              >
                {!this.props.facilityDashboardState.isAccordionLoading && (
                  <Maps facilityId={this.props.facilityId} />
                )}
                {this.props.facilityDashboardState.isAccordionLoading && (
                  <div className="row spinnerFacilityCategory">
                    <Spinner />
                  </div>
                )}
              </Collapse>
            </div>
          ) : null}
          {/* {AuthService.canFacilityView(PermissionFeature.FacilityAssets) && (
            <div>
              <div
                className={
                  currentActiveTab === 14
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() => this.onAccordionClick(14)}
              >
                <a className="font-italic">Equipment</a>
  
                <i
                  className={
                    currentActiveTab === 14
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                ></i>
              </div>
  
              <Collapse isOpen={currentActiveTab == 14}>
                <EquipmentsCardList
                  recordId={this.props.facilityId}
                  tableType={FE_Equipment_Card_Types.Facility}
                />
              </Collapse>
            </div>
          )} */}
          {AuthService.canFacilityView(
            PermissionFeature.FacilityTrainedActivists
          ) && (
            <div>
              <div
                className={
                  currentActiveTab ===
                  FacilityDashboardAccordions.TrainedActivists
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(
                    FacilityDashboardAccordions.TrainedActivists
                  )
                }
              >
                <a className="font-italic">Trained Activists</a>

                <i
                  className={
                    currentActiveTab ===
                    FacilityDashboardAccordions.TrainedActivists
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={
                  currentActiveTab ==
                  FacilityDashboardAccordions.TrainedActivists
                }
              >
                {/* <TrainedActivists /> */}
                {/* <Files /> */}
              </Collapse>
            </div>
          )}
          {(AuthService.canView(PermissionFeature.EmailListAdmin) ||
            AuthService.canUpdate(PermissionFeature.EmailListAdmin) ||
            isRVP) && (
            <div ref={this.emailListRef}>
              <div
                className={
                  currentActiveTab === FacilityDashboardAccordions.EmailList
                    ? "facilityCollapsBtn mb-2"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(FacilityDashboardAccordions.EmailList)
                }
              >
                <a className="font-italic">Email List</a>

                <i
                  className={
                    currentActiveTab === FacilityDashboardAccordions.EmailList
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={
                  currentActiveTab == FacilityDashboardAccordions.EmailList
                }
              >
                <FacilityEmailList
                  facilityId={this.props.facilityId}
                  loadFacilityEmail={this.state.loadFacilityEmail}
                  facilityCode={this.props.facility}
                  scrollAccordion={this.scrollAccordion}
                  isRVP={isRVP}
                />
              </Collapse>
            </div>
          )}
          {AuthService.canFacilityView(
            PermissionFeature.FacilityActivityLogs
          ) && (
            <div>
              <div
                className={
                  currentActiveTab ===
                  FacilityDashboardAccordions.ActivityLogging
                    ? "facilityCollapsBtn"
                    : "facilityTabSection"
                }
                onClick={() =>
                  this.onAccordionClick(
                    FacilityDashboardAccordions.ActivityLogging
                  )
                }
              >
                <a className="font-italic">Activity Logging</a>

                <i
                  className={
                    currentActiveTab ===
                    FacilityDashboardAccordions.ActivityLogging
                      ? "fas arrow-icon fa-chevron-down"
                      : "fas arrow-icon fa-chevron-up"
                  }
                />
              </div>

              <Collapse
                isOpen={
                  currentActiveTab ==
                  FacilityDashboardAccordions.ActivityLogging
                }
              >
                <ActivityLogging recordId={this.props.facilityId} />
              </Collapse>
            </div>
          )}
        </Col>
      </>
    );
  }
}
export default connect(
  (state) => ({ facilityDashboardState: state.facilityDashboard }),
  {
    ...actionFilter,
    ...actionActivity,
  }
)(FacilityCategories);
