import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { MDBInput } from "mdbreact";
import AuthService from "../../../Auth/AuthService";
import Service from "./EditUserService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import { FaWizardsOfTheCoast } from "react-icons/fa";
let formate_amount = null;
let formate_textbox_amount = (n) => {
  var num_parts = n
    .toString()
    .replaceAll(",", "")
    .split(".");
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return num_parts.join(".");
  //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};
class PayAndSeniority extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: {
        id: this.props.memberid,
        employer: this.props.detail.employer,
        careerlevelid: this.props.detail.careerlevelid,
        careerlevel: this.props.detail.careerlevel,
        occupationseries: this.props.detail.occupationseries,
        occupationseriestitle: this.props.detail.occupationseriestitle,
        paygrade: this.props.detail.paygrade,
        hourlysalary: this.props.detail.hourlysalary,
        yearlysalary: this.props.detail.yearlysalary,
      },
      memberid: this.props.memberid,
      detailobj: _.clone(this.props.detail),
      hourlySalaryShow: false,
      yearlySalaryShow: false,
      paygrade_err: "",
      hourlysalary_err: "",
      yearlysalary_err: "",
      employerList: [],
      occupationSeriesList: [],
      careerLevelList: [],
    };
    formate_amount = this.props.formate_amount;
    this.getPayCardDropDown = this.getPayCardDropDown.bind(this);
    this.toggleMask = this.toggleMask.bind(this);
    this.resetValues = this.resetValues.bind(this);
    this.hourlySalary = this.hourlySalary.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.yearlySalary = this.yearlySalary.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  getPayCardDropDown = async () => {
    let data = await Service.PayCardDropdown();
    if (data) {
      await this.setState({
        employerList: data.edd,
        occupationSeriesList: data.osdd,
        careerLevelList: data.cldd,
      });
    }
  };
  toggleMask(mask, isShow) {
    switch (mask) {
      case "HourlySalary":
        this.setState({ hourlySalaryShow: isShow });
        break;
      case "YearlySalary":
        this.setState({ yearlySalaryShow: isShow });
        break;
      default:
    }
  }
  componentDidMount() {
    this.getPayCardDropDown();
  }
  yearlySalary(yearlysalary) {
    return (
      <span
        onMouseEnter={() => this.toggleMask("YearlySalary", true)}
        onMouseLeave={() => this.toggleMask("YearlySalary", false)}
        onClick={() =>
          this.toggleMask("YearlySalary", !this.state.yearlySalaryShow)
        }
        className="card-text position-relative"
      >
        {this.state.yearlySalaryShow
          ? yearlysalary != null && yearlysalary != ""
            ? formate_amount(yearlysalary)
            : "none"
          : "$*****"}
        {/*for avoid hover bouncing we have added this span*/}
        <span className="position-absolute left-0 opacity-0">
          {(yearlysalary != null && yearlysalary != ""
            ? formate_amount(yearlysalary)
            : "none"
          ).length > 6
            ? formate_amount(yearlysalary)
            : "$*****"}
        </span>
      </span>
    );
  }
  //detail.hourlysalary
  hourlySalary(hourlysalary) {
    return (
      <span
        onMouseEnter={() => this.toggleMask("HourlySalary", true)}
        onMouseLeave={() => this.toggleMask("HourlySalary", false)}
        onClick={() =>
          this.toggleMask("HourlySalary", !this.state.hourlySalaryShow)
        }
        className="card-text position-relative"
      >
        {this.state.hourlySalaryShow
          ? hourlysalary != null && hourlysalary != ""
            ? formate_amount(hourlysalary)
            : "none"
          : "$*****"}
        {/*for avoid hover bouncing we have added this span*/}
        <span className="position-absolute left-0 opacity-0">
          {(hourlysalary != null && hourlysalary != ""
            ? formate_amount(hourlysalary)
            : "none"
          ).length > 6
            ? formate_amount(hourlysalary)
            : "$*****"}
        </span>
      </span>
    );
  }
  amountValidation(amount) {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    const amount_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Invalid amount",
      },
    ];
    if (amountReg.test(amount)) {
      return amount_States.find(({ state }) => state == "Valid");
    } else {
      return amount_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  }
  handleChange = (evt) => {
    if (
      evt.target.name == "hourlysalary" ||
      evt.target.name == "yearlysalary"
    ) {
      this.state[evt.target.name + "_err"] = this.amountValidation(
        evt.target.value.replaceAll(",", "")
      ).error;

      this.state.detail[evt.target.name] = evt.target.value;
    } else {
      this.state.detail[evt.target.name] = evt.target.value;
    }

    this.setState({ detail: this.state.detail });
  };
  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.detail) !=
        JSON.stringify(this.state.detailobj) ||
      this.state.memberid != this.props.memberid
    ) {
      this.setState({
        detailobj: _.clone(this.props.detail),
        memberid: this.props.memberid,
      });
      //this.getJobsSeniorityHistory();
      this.resetValues();
    }
  }

  resetValues() {
    this.state.detail.employer = this.props.detail.employer;
    this.state.detail.careerlevelid = this.props.detail.careerlevelid;
    this.state.detail.careerlevel = this.props.detail.careerlevel;
    this.state.detail.occupationseries = this.props.detail.occupationseries;
    this.state.detail.occupationseriestitle = this.props.detail.occupationseriestitle;
    this.state.detail.paygrade = this.props.detail.paygrade;
    this.state.detail.hourlysalary = this.props.detail.hourlysalary;
    this.state.detail.yearlysalary = this.props.detail.yearlysalary;
    this.setState({ detail: this.state.detail });
  }
  async handleFormSubmit(e) {
    e.preventDefault();
    $(".loading").show();
    if (
      this.state.hourlysalary_err == "" &&
      this.state.yearlysalary_err == ""
    ) {
        let detail = this.state.detail;
        if (detail && detail.occupationseries == 0) {
            detail.occupationseries = null;
        }
        if (await Service.UpdatePaycard(detail)) {
        this.props.refreshData(this.props.memberid);
        $("#editPayInline").addClass("d-none");
        $("#PayInline").removeClass("d-none");
      }
    }
    $(".loading").hide();
  }
  render() {
    const { member } = this.props;
    const { detail, detailobj } = this.state;
    let permissions =
      AuthService.canOwnView(
        PermissionFeature.FinancialCareerLevel,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialCareerLevel) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialEmployer,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialEmployer) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialOccupationSeries,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialOccupationSeries) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialPayGrade,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialPayGrade) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialHourlySalary,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialHourlySalary) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialYearlySalary,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialYearlySalary) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoVetStatus,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoVetStatus) ||
      AuthService.canOwnView(
        PermissionFeature.DatesBargainingUnitDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesBargainingUnitDate) ||
      AuthService.canOwnView(
        PermissionFeature.DatesNatcaBargainingUnitDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.DatesNatcaBargainingUnitDate
      ) ||
      AuthService.canOwnView(
        PermissionFeature.DatesEntryOnDutyDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesEntryOnDutyDate) ||
      AuthService.canOwnView(
        PermissionFeature.DatesServiceComputationDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.DatesServiceComputationDate
      ) ||
      AuthService.canOwnView(
        PermissionFeature.DatesJoinedUnionDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesJoinedUnionDate) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoBargainingUnitStatus
      ) ||
      AuthService.canMemberView(
        PermissionFeature.PersonalInfoBargainingUnitStatus
      );

    let payPermission =
      AuthService.canOwnView(
        PermissionFeature.FinancialEmployer,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialEmployer) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialCareerLevel,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialCareerLevel) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialOccupationSeries,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialOccupationSeries) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialPayGrade,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialPayGrade) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialHourlySalary,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialHourlySalary) ||
      AuthService.canOwnView(
        PermissionFeature.FinancialYearlySalary,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.FinancialYearlySalary) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoVetStatus,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.PersonalInfoVetStatus);
    let EditPayCardPermission =
      AuthService.canOwnUpdate(
        PermissionFeature.FinancialPayCard,
        this.props.memberid
        ) || AuthService.canMemberUpdate(PermissionFeature.FinancialPayCard);
    let seniorityPermission =
      AuthService.canMemberView(PermissionFeature.FacilityMemberSeniority) ||
      AuthService.canOwnView(
        PermissionFeature.DatesBargainingUnitDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesBargainingUnitDate) ||
      AuthService.canOwnView(
        PermissionFeature.DatesNatcaBargainingUnitDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.DatesNatcaBargainingUnitDate
      ) ||
      AuthService.canOwnView(
        PermissionFeature.DatesEntryOnDutyDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesEntryOnDutyDate) ||
      AuthService.canOwnView(
        PermissionFeature.DatesServiceComputationDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(
        PermissionFeature.DatesServiceComputationDate
      ) ||
      AuthService.canOwnView(
        PermissionFeature.DatesJoinedUnionDate,
        this.props.memberid
      ) ||
      AuthService.canMemberView(PermissionFeature.DatesJoinedUnionDate) ||
      AuthService.canOwnView(
        PermissionFeature.PersonalInfoBargainingUnitStatus
      ) ||
      AuthService.canMemberView(
        PermissionFeature.PersonalInfoBargainingUnitStatus
      );

    let status = detailobj.status;
    let { employerList, careerLevelList, occupationSeriesList } = this.state;
    // if (permissions) {
    return (
      <Fragment>
        <div class="row mx-0">
          {seniorityPermission && (
            <div className="row col-12 px-0 mx-0 order-2 pl-md-2 ps-row ps-column">
              <div className="card directory-card border-rounded shadow w-100">
                <h5 className="card-header mt-0">
                  <i className="fas fa-calendar mr-2" />
                  Dates
                </h5>
                <div className="card-body pb-3 pt-1">
                  <div className="userAccount">
                    {(AuthService.canMemberView(
                      PermissionFeature.FacilityMemberSeniority
                    ) ||
                      AuthService.canOwnView(
                        PermissionFeature.DatesNatcaBargainingUnitDate,
                        this.props.memberid
                      ) ||
                      AuthService.canMemberView(
                        PermissionFeature.DatesNatcaBargainingUnitDate
                      )) && (
                      <div className="py-1">
                        <span className="card-subtitle pr-1 mb-2 text-muted">
                          <strong>Cumulative BU:</strong>
                        </span>
                        <span className="card-text">
                          {detailobj.natcabargainingunitdate == null ||
                          detailobj.natcabargainingunitdate == ""
                            ? "none"
                            : detailobj.natcabargainingunitdate}
                        </span>
                      </div>
                    )}
                    {(AuthService.canMemberView(
                      PermissionFeature.FacilityMemberSeniority
                    ) ||
                      AuthService.canOwnView(
                        PermissionFeature.DatesBargainingUnitDate,
                        this.props.memberid
                      ) ||
                      AuthService.canMemberView(
                        PermissionFeature.DatesBargainingUnitDate
                      )) && (
                      <div className="py-1">
                        <span className="card-subtitle pr-1 mb-2 text-muted">
                          <strong>Current BU:</strong>
                        </span>
                        <span className="card-text">
                          {detailobj.bargainingunitdate == null ||
                          detailobj.bargainingunitdate == ""
                            ? "none"
                            : detailobj.bargainingunitdate}
                        </span>
                      </div>
                    )}
                    {(AuthService.canMemberView(
                      PermissionFeature.FacilityMemberSeniority
                    ) ||
                      AuthService.canOwnView(
                        PermissionFeature.DatesEntryOnDutyDate,
                        this.props.memberid
                      ) ||
                      AuthService.canMemberView(
                        PermissionFeature.DatesEntryOnDutyDate
                      )) && (
                      <div className="py-1">
                        <span className="card-subtitle pr-1 mb-2 text-muted">
                          <strong>Entry on Duty Date:</strong>
                        </span>
                        <span className="card-text">
                          {detailobj.entryondutydate == null ||
                          detailobj.entryondutydate == ""
                            ? "none"
                            : detailobj.entryondutydate}
                        </span>
                      </div>
                    )}

                    {(AuthService.canMemberView(
                      PermissionFeature.FacilityMemberSeniority
                    ) ||
                      AuthService.canOwnView(
                        PermissionFeature.DatesServiceComputationDate,
                        this.props.memberid
                      ) ||
                      AuthService.canMemberView(
                        PermissionFeature.DatesServiceComputationDate
                      )) && (
                      <div className="py-1">
                        <span className="card-subtitle pr-1 mb-2 text-muted">
                          <strong>SCD Date:</strong>
                        </span>
                        <span className="card-text">
                          {detailobj.servicecomputationdate == null ||
                          detailobj.servicecomputationdate == ""
                            ? "none"
                            : detailobj.servicecomputationdate}
                        </span>
                      </div>
                    )}

                    {(AuthService.canOwnView(
                      PermissionFeature.DatesJoinedUnionDate,
                      this.props.memberid
                    ) ||
                      AuthService.canMemberView(
                        PermissionFeature.DatesJoinedUnionDate
                      )) && (
                      <div className="py-1">
                        <span className="card-subtitle pr-1 mb-2 text-muted">
                          <strong>Joined Union Date:</strong>
                        </span>
                        <span className="card-text">
                          {detailobj.joineduniondate == null ||
                          detailobj.joineduniondate == ""
                            ? "none"
                            : detailobj.joineduniondate}
                        </span>
                      </div>
                    )}
                    {(AuthService.canOwnView(
                      PermissionFeature.PersonalInfoBargainingUnitStatus
                    ) ||
                      AuthService.canMemberView(
                        PermissionFeature.PersonalInfoBargainingUnitStatus
                      )) && (
                      <div className="py-1">
                        <span className="card-subtitle mb-2 text-muted">
                          <strong>Bargaining Unit Status: </strong>
                        </span>
                        <span className="card-text">
                          {status == null || status == "" ? "none" : status}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {AuthService.canOwnView(
            PermissionFeature.FinancialPayCard,
            this.props.memberid
          ) ||
            (AuthService.canMemberView(PermissionFeature.FinancialPayCard) && (
          <div className="row col-12 px-0 mx-0 order-1 pr-md-2 ps-row ps-column">
            <div className="card directory-card border-rounded shadow w-100">
              <h5 className="card-header mt-0">
                <i className="fas fa-money-check-alt mr-2" />
                Pay
              </h5>
              <div className="card-body pb-3 pt-1">
                <div className="userAccount">
                  <div className="row" id="PayInline">
                    {EditPayCardPermission && (
                      <div className="col-12 pt-1">
                        <i
                          onClick={() => {
                            $("#editPayInline").removeClass("d-none");
                            $("#PayInline").addClass("d-none");
                          }}
                          className="fas fa-pencil-alt edit-pencil-icon float-right"
                        ></i>
                      </div>
                    )}
                    <div className="col-lg-12">
                      {(AuthService.canOwnView(
                        PermissionFeature.FinancialEmployer,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.FinancialEmployer
                        )) && (
                        <div
                          className={`py-1 ${
                            EditPayCardPermission ? "" : " pt-2"
                          }mx-0`}
                        >
                          {/*(AuthService.canOwnUpdate(
                                                        PermissionFeature.FinancialPayCard,
                                                        this.props.memberid
                                                    ) ||
                                                        AuthService.canMemberUpdate(
                                                            PermissionFeature.FinancialPayCard
                                                        )) && (

                                                            <i
                                                                onClick={() => {
                                                                    $("#editPayInline").removeClass("d-none");
                                                                    $("#PayInline").addClass("d-none");
                                                                }}
                                                                className="fas fa-pencil-alt edit-pencil-icon float-right"
                                                            ></i>
                                                        )*/}
                          <span className="pr-1 text-muted">
                            <strong>Employer:</strong>
                          </span>
                          <span>
                                {detail.employer == null ||
                                detail.employer == ""
                              ? "none"
                              : detail.employer}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.FinancialCareerLevel,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.FinancialCareerLevel
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle pr-1 mb-2 text-muted">
                            <strong>Career Level:</strong>
                          </span>
                          <span className="card-text">
                            {detail.careerlevel == null ||
                            detail.careerlevel == ""
                              ? "none"
                              : detail.careerlevel}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.FinancialOccupationSeries,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.FinancialOccupationSeries
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle pr-1 mb-2 text-muted">
                            <strong>Occupation Series:</strong>
                          </span>
                          <span className="card-text">
                            {detail.occupationseriestitle == null ||
                            detail.occupationseriestitle == ""
                              ? "none"
                              : detail.occupationseriestitle}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.FinancialPayGrade,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.FinancialPayGrade
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle pr-1 mb-2 text-muted">
                            <strong>Pay Grade:</strong>
                          </span>
                          <span className="card-text">
                                {detail.paygrade == null ||
                                detail.paygrade == ""
                              ? "none"
                              : detail.paygrade}
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.FinancialHourlySalary,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.FinancialHourlySalary
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle pr-1 mb-2 text-muted">
                            <strong>Hourly Salary:</strong>
                          </span>

                          <span
                            onMouseEnter={() =>
                              this.toggleMask("HourlySalary", true)
                            }
                            onMouseLeave={() =>
                              this.toggleMask("HourlySalary", false)
                            }
                            onClick={() =>
                              this.toggleMask(
                                "HourlySalary",
                                !this.state.hourlySalaryShow
                              )
                            }
                            className="card-text position-relative"
                          >
                            {this.state.hourlySalaryShow
                              ? detail.hourlysalary != null &&
                                detail.hourlysalary != ""
                                ? formate_amount(detail.hourlysalary)
                                : "none"
                              : "$*****"}
                            {/*for avoid hover bouncing we have added this span*/}
                            <span className="position-absolute left-0 opacity-0">
                              {(detail.hourlysalary != null &&
                              detail.hourlysalary != ""
                                ? formate_amount(detail.hourlysalary)
                                : "none"
                              ).length > 6
                                ? formate_amount(detail.hourlysalary)
                                : "$*****"}
                            </span>
                          </span>
                        </div>
                      )}
                      {(AuthService.canOwnView(
                        PermissionFeature.FinancialYearlySalary,
                        this.props.memberid
                      ) ||
                        AuthService.canMemberView(
                          PermissionFeature.FinancialYearlySalary
                        )) && (
                        <div className="py-1">
                          <span className="card-subtitle pr-1 mb-2 text-muted">
                            <strong>Yearly Salary:</strong>
                          </span>

                          <span
                            onMouseEnter={() =>
                              this.toggleMask("YearlySalary", true)
                            }
                            onMouseLeave={() =>
                              this.toggleMask("YearlySalary", false)
                            }
                            onClick={() =>
                              this.toggleMask(
                                "YearlySalary",
                                !this.state.yearlySalaryShow
                              )
                            }
                            className="card-text position-relative"
                          >
                            {this.state.yearlySalaryShow
                              ? detail.yearlysalary != null &&
                                detail.yearlysalary != ""
                                ? formate_amount(detail.yearlysalary)
                                : "none"
                              : "$*****"}
                            {/*for avoid hover bouncing we have added this span*/}
                            <span className="position-absolute left-0 opacity-0">
                              {(detail.yearlysalary != null &&
                              detail.yearlysalary != ""
                                ? formate_amount(detail.yearlysalary)
                                : "none"
                              ).length > 6
                                ? formate_amount(detail.yearlysalary)
                                : "$*****"}
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {(AuthService.canOwnUpdate(
                    PermissionFeature.FinancialPayCard,
                    this.props.memberid
                  ) ||
                    AuthService.canMemberUpdate(
                      PermissionFeature.FinancialPayCard
                    )) && (
                    <div className="row d-none" id="editPayInline">
                      <div className="col-12 pt-2">
                        <i
                          onClick={() => {
                            $("#editPayInline").addClass("d-none");
                            $("#PayInline").removeClass("d-none");
                            this.resetValues();
                          }}
                          className="fas fa-times edit-pencil-icon float-right"
                        ></i>
                      </div>

                      <div className="col-lg-12">
                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialEmployer,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialEmployer
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                              <strong>Employer:</strong>
                            </span>
                            {AuthService.canOwnUpdate(
                              PermissionFeature.FinancialEmployer,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.FinancialEmployer
                            ) ? (
                              <div className="">
                                <div class="md-form form-group mt-0 mb-2">
                                  <select
                                    //className="browser-default custom-select"
                                    onChange={this.handleChange}
                                    name="employer"
                                    className="form-control border-left-0 border-top-0 border-right-0 mt-2 py-0 mb-0"
                                    disabled={
                                      !AuthService.canOwnUpdate(
                                        PermissionFeature.FinancialEmployer,
                                        this.props.memberid
                                      ) &&
                                      !AuthService.canMemberUpdate(
                                        PermissionFeature.FinancialEmployer
                                      )
                                    }
                                  >
                                    <option value="" selected>
                                      Select Employer
                                    </option>
                                    {!_.isEmpty(employerList) &&
                                      employerList.map((item, index) => {
                                        return (
                                          <option
                                            value={item.options}
                                            selected={
                                                  detail.employer ==
                                                  item.options
                                            }
                                          >
                                            {item.options}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            ) : (
                              <span className="card-text">
                                {detail.employer == null ||
                                detail.employer == ""
                                  ? "none"
                                  : detail.employer}
                              </span>
                            )}
                          </div>
                        )}
                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialCareerLevel,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialCareerLevel
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                              <strong>Career Level:</strong>
                            </span>
                            {AuthService.canOwnUpdate(
                              PermissionFeature.FinancialCareerLevel,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.FinancialCareerLevel
                            ) ? (
                              <div className="">
                                <div class="md-form form-group mt-0 mb-2">
                                  <select
                                    //className="browser-default custom-select"
                                    onChange={this.handleChange}
                                    name="careerlevelid"
                                    className="form-control border-left-0 border-top-0 border-right-0 mt-2 py-0 mb-0"
                                    disabled={
                                      !AuthService.canOwnUpdate(
                                        PermissionFeature.FinancialCareerLevel,
                                        this.props.memberid
                                      ) &&
                                      !AuthService.canMemberUpdate(
                                        PermissionFeature.FinancialCareerLevel
                                      )
                                    }
                                  >
                                    <option value="" selected>
                                      Select Career Level
                                    </option>
                                    {!_.isEmpty(careerLevelList) &&
                                      careerLevelList.map((item, index) => {
                                        return (
                                          <option
                                            value={item.id}
                                            selected={
                                                  detail.careerlevelid ==
                                                  item.id
                                            }
                                          >
                                            {item.code}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            ) : (
                              <span className="card-text">
                                {detail.careerlevel == null ||
                                detail.careerlevel == ""
                                  ? "none"
                                  : detail.careerlevel}
                              </span>
                            )}
                          </div>
                        )}

                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialOccupationSeries,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialOccupationSeries
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                              <strong>Occupation Series:</strong>
                            </span>
                            {AuthService.canOwnUpdate(
                              PermissionFeature.FinancialOccupationSeries,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.FinancialOccupationSeries
                            ) ? (
                              <div className="">
                                <div class="md-form form-group mt-0 mb-2">
                                  <select
                                    //className="browser-default custom-select"
                                    onChange={this.handleChange}
                                    name="occupationseries"
                                    className="form-control border-left-0 border-top-0 border-right-0 mt-2 py-0 mb-0"
                                    disabled={
                                      !AuthService.canOwnUpdate(
                                        PermissionFeature.FinancialOccupationSeries,
                                        this.props.memberid
                                      ) &&
                                      !AuthService.canMemberUpdate(
                                        PermissionFeature.FinancialOccupationSeries
                                      )
                                    }
                                  >
                                    <option value="" selected>
                                      Select Occupation Series
                                    </option>
                                    {!_.isEmpty(occupationSeriesList) &&
                                      occupationSeriesList.map(
                                        (item, index) => {
                                          return (
                                            <option
                                              value={item.series}
                                              selected={
                                                detail.occupationseries ==
                                                item.series
                                              }
                                            >
                                              {item.title}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              </div>
                            ) : (
                              <span className="card-text">
                                {detail.occupationseriestitle == null ||
                                detail.occupationseriestitle == ""
                                  ? "none"
                                  : detail.occupationseriestitle}
                              </span>
                            )}
                          </div>
                        )}

                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialPayGrade,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialPayGrade
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle align-self-end pr-1 mb-2 text-secondary">
                              <strong>Pay Grade:</strong>
                            </span>
                            {AuthService.canOwnUpdate(
                              PermissionFeature.FinancialPayGrade,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.FinancialPayGrade
                            ) ? (
                              <div className="">
                                <MDBInput
                                  containerClass="mt-0 mb-2"
                                  className="py-0 mb-0"
                                  name="paygrade"
                                  hint="Provide your PayGrade"
                                  value={
                                    detail.paygrade != null
                                      ? detail.paygrade
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                  disabled={
                                    !AuthService.canOwnUpdate(
                                      PermissionFeature.FinancialPayGrade,
                                      this.props.memberid
                                    ) &&
                                    !AuthService.canMemberUpdate(
                                      PermissionFeature.FinancialPayGrade
                                    )
                                  }
                                  group
                                  type="text"
                                />
                                <span className="d-block text-danger">
                                  {this.state.paygrade_err}
                                </span>
                              </div>
                            ) : (
                              <span className="card-text">
                                {detail.paygrade == null ||
                                detail.paygrade == ""
                                  ? "none"
                                  : detail.paygrade}
                              </span>
                            )}
                          </div>
                        )}

                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialHourlySalary,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialHourlySalary
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle pr-1 mb-2 text-secondary">
                              <strong>Hourly Salary:</strong>
                            </span>
                            {AuthService.canOwnUpdate(
                              PermissionFeature.FinancialHourlySalary,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.FinancialHourlySalary
                            ) ? (
                              <div className="">
                                <MDBInput
                                  containerClass="mt-0 mb-2"
                                  className="py-0 mb-0"
                                  name="hourlysalary"
                                  hint="$$"
                                  icon="dollar-sign"
                                  value={
                                    detail.hourlysalary != null
                                      ? formate_textbox_amount(
                                          detail.hourlysalary
                                        )
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                  disabled={
                                    !(
                                      AuthService.canOwnUpdate(
                                        PermissionFeature.FinancialHourlySalary,
                                        this.props.memberid
                                      ) ||
                                      AuthService.canMemberUpdate(
                                        PermissionFeature.FinancialHourlySalary
                                      )
                                    )
                                  }
                                  group
                                  type="text"
                                />
                                <span className="d-block text-danger">
                                  {this.state.hourlysalary_err}
                                </span>
                              </div>
                            ) : (
                              this.hourlySalary(detail.hourlysalary)
                            )}
                          </div>
                        )}

                        {(AuthService.canOwnView(
                          PermissionFeature.FinancialYearlySalary,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FinancialYearlySalary
                          )) && (
                          <div className="py-1">
                            <span className="card-subtitle pr-1 mb-2 text-secondary">
                              <strong>Yearly Salary:</strong>
                            </span>
                            {AuthService.canOwnUpdate(
                              PermissionFeature.FinancialYearlySalary,
                              this.props.memberid
                            ) ||
                            AuthService.canMemberUpdate(
                              PermissionFeature.FinancialYearlySalary
                            ) ? (
                              <div className="">
                                <MDBInput
                                  containerClass="mt-0 mb-2"
                                  className="py-0 mb-0"
                                  name="yearlysalary"
                                  hint="$$"
                                  icon="dollar-sign"
                                  value={
                                    detail.yearlysalary != null
                                      ? formate_textbox_amount(
                                          detail.yearlysalary
                                        )
                                      : ""
                                  }
                                  onChange={this.handleChange}
                                  disabled={
                                    !(
                                      AuthService.canOwnUpdate(
                                        PermissionFeature.FinancialYearlySalary,
                                        this.props.memberid
                                      ) ||
                                      AuthService.canMemberUpdate(
                                        PermissionFeature.FinancialYearlySalary
                                      )
                                    )
                                  }
                                  group
                                  type="text"
                                />
                                <span className="d-block text-danger">
                                  {this.state.yearlysalary_err}
                                </span>
                              </div>
                            ) : (
                              this.yearlySalary(detail.yearlysalary)
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-12 py-3 text-left">
                        <button
                          onClick={this.handleFormSubmit}
                          className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                          //type="submit"
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-sm btn-danger w-md waves-effect waves-light"
                          type="button"
                          id="payCancelBtn"
                          onClick={() => {
                            $("#editPayInline").addClass("d-none");
                            $("#PayInline").removeClass("d-none");
                            this.resetValues();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
            ))}
        </div>
      </Fragment>
    );
    // } else {
    //   return null;
    // }
  }
}

export default PayAndSeniority;
