import actions from "./action";

const initialState = {
  filterData: {},
  filterResult: [],
  activeTab: "0",
  collaps: "0",
  isAdvancedFilterSelected: false,
  showWebSideBar: false,
  showMobileSideBar: false,
  resetAdvanceFilter: false,
  TempFilterData: [],
  returnResultNo: 0,
  basicSearchKeyWord: "",
  currentFilterResultPage: 1,
  currentFilterResultPageSize: 25,
  loadingAdvanceFilter: false,
  setSidebarState: false,
  directoryMenuState: false,
  currentActiveTab: "0",
  childAccordion: "0",
  localPaging: false,
  resultsAccordianType: 1,
  currentFilterResultTotalCount: 0,
  isBasicSearchSelected: false,
};

export default function advancedfilter(state = initialState, action) {
  switch (action.type) {
    case actions.SET_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };
    case actions.SET_TEMP_FILTER_DATA:
      return {
        ...state,
        TempFilterData: action.payload,
      };
    case actions.SET_RETURN_RESULTSNO:
      return {
        ...state,
        returnResultNo: action.payload,
      };
    case actions.SET_FILTER_RESULT:
      return {
        ...state,
        filterResult: action.payload,
      };
    case actions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case actions.SET_COLLAPSE_TAB:
      return {
        ...state,
        collaps:
          state.collaps !== action.payload ? action.payload : state.collaps,
      };

    case actions.SHOW_WEB_SIDE_BAR:
      return {
        ...state,
        showWebSideBar: action.payload,
      };

    case actions.SHOW_MOBILE_SIDE_BAR:
      return {
        ...state,
        showMobileSideBar: action.payload,
      };
    case actions.SET_FACILITY_CHILD_ACCORDION:
      return {
        ...state,
        childAccordion: action.payload,
      };

    case actions.SET_RESET_ADVANCE_FILTER:
      return {
        ...state,
        resetAdvanceFilter: action.payload,
      };

    case actions.SET_ADVANCED_FILTER:
      return {
        ...state,
        isAdvancedFilterSelected: action.payload,
      };
    case actions.SET_BASIC_SEARCH_KEYWORD:
      return {
        ...state,
        basicSearchKeyWord: action.payload,
      };
    case actions.SET_FILTER_RESULT_PAGE_NO:
      return {
        ...state,
        currentFilterResultPage: action.payload,
      };

    case actions.SET_NEW_FILTERED_DATA:
      console.log(action.payload.data, "421 applied New data");
      return {
        ...state,
        filterData: action.payload.data,
        TempFilterData: action.payload.data,
        loadingAdvanceFilter: false,
      };
    case actions.SET_ADVANCE_FILTER_LOADNG:
      return {
        ...state,
        loadingAdvanceFilter: action.payload,
      };
    case actions.SET_SIDEBAR_STATE:
      return {
        ...state,
        setSidebarState: action.payload,
      };
    case actions.SET_DIRECTORY_MENU_STATE:
      return {
        ...state,
        directoryMenuState: action.payload,
      };
    case actions.SET_CURRENT_ACTIVE_TAB:
      return {
        ...state,
        currentActiveTab: action.payload,
      };

    case actions.SET_LOCAL_PAGING:
      return {
        ...state,
        localPaging: action.payload,
      };
    case actions.RESET_LOCAL_PAGING:
      return {
        ...state,
        localPaging: action.payload,
      };
    case actions.SET_MEMBERS_ACCORDIAN_TYPE:
      return {
        ...state,
        resultsAccordianType: action.payload,
      };
    case actions.SET_FILTER_RESULT_PAGE_SIZE:
      return {
        ...state,
        currentFilterResultPageSize: action.payload,
      };
    case actions.SET_FILTER_RESULT_TOTAL_COUNT:
      return {
        ...state,
        currentFilterResultTotalCount: action.payload,
      };
    case actions.SET_IS_BASIC_SEARCH_SELECTED:
      return {
        ...state,
        isBasicSearchSelected: action.payload,
      };
    case action.SET_RESET_ALL:
      return {
        ...state,
        filterData: {},
        filterResult: [],
        activeTab: "0",
        collaps: "0",
        isAdvancedFilterSelected: false,
        showWebSideBar: false,
        showMobileSideBar: false,
        resetAdvanceFilter: false,
        TempFilterData: [],
        returnResultNo: 0,
        currentActiveTab: "0",
        childAccordion: "0",
        localPaging: false,
      };

    default:
      return state;
  }
}
