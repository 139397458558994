import React, { Component } from "react";
import { MDBInput } from "mdbreact";
import { connect } from "react-redux";
import actions from "../../../../store/facility/action";
import {
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import $ from "jquery";
import ConfirmDeleteModal from "../../../Shared/Modals";
import DeleteModal from "./DeleteModal";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";

export class MailingAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataIdx: 0,
      delId: 0,
      isValid: false,
      isConfirmModalOpen: false,
    };
    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        const formData = {
          addressId: this.state.delId,
          facilityId: this.props.facilityId,
        };
        this.props.removeFacilityAddress(formData); // api for removing address by id
        this.props.removeFacilityMailAddress(this.state.dataIdx);

        this.setState({ delId: 0 });
        this.setState({ dataIdx: 0 });
        this.toggleConfirmModal();
      };
      return this;
    };
  }
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };
  changeHandlerAddress = (e) => {
    // debugger
    const idx = e.target.parentElement.parentElement.dataset.id;
    this.props.facilityDashboardState[e.target.dataset.obj][idx][
      e.target.name
    ] = e.target.value;

    this.props.setFacilityMailingAddress(
      this.props.facilityDashboardState[e.target.dataset.obj]
    );
  };

  submitAddresses = () => {
    //debugger

    for (var key of Object.keys(this.refs)) {
      // console.log(key + ": " + this.refs[key]);
      this.refs[key].className += " was-validated";
      if (this.refs[key].checkValidity() === false) {
        this.setState({ isValid: false });
        return false;
      }
    }

    const formData = {
      addresses: this.props.facilityDashboardState.facilityMailingAddresses,
      id: this.props.facilityId,
      typeId: 1,
    };

    this.props.saveFacilityAddress(formData);
    this.props.toggleModal();
  };
  removeAddress = (addressId, idx) => {
    if (addressId > 0) {
      this.setState({ delId: addressId });
      this.setState({ dataIdx: idx });
      this.setState({ isConfirmModalOpen: true });
    } else {
      this.props.removeFacilityMailAddress(idx);
    }
  };

  addAddressClick = () => {
    for (var key of Object.keys(this.refs)) {
      // console.log(key + ": " + this.refs[key]);
      this.refs[key].className += " was-validated";
      if (this.refs[key].checkValidity() === false) {
        this.setState({ isValid: false });
        return false;
      }
    }
    this.props.addFacilityMailAddress();
  };
  render() {
    //debugger
    return (
      <>
        <Modal
          centered={true}
          className="mailing modal-lg"
          role="dialog"
          isOpen={this.props.isModalOpen}
          toggle={this.props.toggleModal}
        >
          <ModalHeader
            className="py-2 bg-secondary modal-title mt-0 text-white"
            toggle={this.props.toggleModal}
          >
            Edit Mailing Address
          </ModalHeader>

          <ModalBody className="modalshadow">
            {this.props.facilityDashboardState.facilityMailingAddresses &&
              this.props.facilityDashboardState.facilityMailingAddresses.map(
                (item, idx) => (
                  <div className="container-fluid" key={idx + 1}>
                    <form
                      ref={`${idx}-mailAddressForm`}
                      className="form-horizontal m-t-40"
                      onSubmit={this.handleFormSubmit}
                      noValidate
                    >
                      <Card>
                        <CardBody>
                          <div
                            id={`add${idx}`}
                            key={idx}
                            data-repeater-list="group-a"
                          >
                            <div data-repeater-item className="row mb-4">
                              <div className="row col-11">
                                <div data-id={idx} className="col-sm-6">
                                  <MDBInput
                                    name="address1"
                                    containerClass="mb-0"
                                    value={item.address1}
                                    onChange={this.changeHandlerAddress}
                                    label="Address 1"
                                    data-obj="facilityMailingAddresses"
                                    // disabled={this.canEdit("addresses")}
                                    required
                                    group
                                    type="text"
                                  >
                                    <div className="invalid-feedback">
                                      Address is required.
                                    </div>
                                  </MDBInput>
                                </div>

                                <div data-id={idx} className="col-sm-6">
                                  <MDBInput
                                    name="address2"
                                    containerClass="mb-0"
                                    value={item.address2}
                                    onChange={this.changeHandlerAddress}
                                    label="Address 2"
                                    // disabled={this.canEdit("addresses")}
                                    disabled={false}
                                    data-obj="facilityMailingAddresses"
                                    group
                                    type="text"
                                  />
                                </div>

                                <div data-id={idx} className="col-sm-4">
                                  <MDBInput
                                    name="city"
                                    containerClass="mb-0"
                                    value={item.city}
                                    onChange={this.changeHandlerAddress}
                                    // disabled={this.canEdit("addresses")}
                                    disabled={false}
                                    label="City"
                                    data-obj="facilityMailingAddresses"
                                    group
                                    required
                                    type="text"
                                  >
                                    <div className="invalid-feedback">
                                      City is required.
                                    </div>
                                  </MDBInput>
                                </div>

                                <div data-id={idx} className="col-sm-4">
                                  <MDBInput
                                    name="state"
                                    containerClass="mb-0"
                                    value={item.state}
                                    onChange={this.changeHandlerAddress}
                                    // disabled={this.canEdit("addresses")}
                                    disabled={false}
                                    label="State"
                                    data-obj="facilityMailingAddresses"
                                    group
                                    required
                                    type="text"
                                  >
                                    <div className="invalid-feedback">
                                      State is required.
                                    </div>
                                  </MDBInput>
                                </div>

                                <div data-id={idx} className="col-sm-4">
                                  <MDBInput
                                    name="zipcode"
                                    containerClass="mb-0"
                                    value={item.zipcode}
                                    onChange={this.changeHandlerAddress}
                                    // disabled={this.canEdit("addresses")}
                                    disabled={false}
                                    label="Zipcode"
                                    data-obj="facilityMailingAddresses"
                                    required
                                    group
                                  >
                                    <div className="invalid-feedback">
                                      ZIP Code is required.
                                    </div>
                                  </MDBInput>
                                </div>
                                <div className="col-sm-4 align-self-center d-none">
                                  <label>Address Type</label>
                                  <select
                                    className="browser-default custom-select"
                                    containerClass="mb-0"
                                    onChange={this.handleSelectChange}
                                    name="addresstypeid"
                                    data-id={idx}
                                    // disabled={this.canEdit("addresses")}
                                    disabled={true}
                                  >
                                    <option value="4" selected={true}>
                                      Physical
                                    </option>
                                  </select>
                                </div>
                                <div className="offset-sm-1 col-sm-4 pt-sm-4 d-none">
                                  <div data-id={idx} className="pt-3">
                                    <label className="mt-n1">Primary?</label>
                                    <input
                                      type="checkbox"
                                      id={`Primary-${idx}`}
                                      name="isprimary"
                                      // disabled={this.canEdit("addresses")}
                                      disabled={false}
                                      switch="bool"
                                      data-obj="facilityMailingAddresses"
                                      checked={item.isprimary}
                                      onChange={this.handleChangePAddress}
                                    />
                                    <label
                                      className="ml-4 mb-n2"
                                      htmlFor={`Primary-${idx}`}
                                      data-on-label="Yes"
                                      data-off-label="No"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-1 align-self-center">
                                {/* {this.canDelete("addresses") && ( */}
                                {AuthService.canFacilityDelete(
                                  PermissionFeature.FacilityAddress
                                ) && (
                                  <button
                                    type="button"
                                    data-id={idx}
                                    onClick={() =>
                                      this.removeAddress(item.id, idx)
                                    }
                                    className="btn btn-danger btn-sm"
                                    // data-target={item.id > 0 && ".delete-modal"}
                                    // data-toggle={item.id > 0 && "modal"}
                                    // size="sm"
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </form>
                  </div>
                )
              )}
            <hr className="mb-3" />
            <div className="row">
              <div className="col-8">
                <div className="float-left text-left">
                  <button
                    onClick={() => this.submitAddresses()}
                    className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-danger btn-sm w-md waves-effect waves-light"
                    type="button"
                    onClick={this.props.toggleModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-4 align-self-center">
                {/* {this.canAdd("addresses") && ( */}
                {AuthService.canFacilityAdd(
                  PermissionFeature.FacilityAddress
                ) && (
                  <a
                    onClick={() => this.addAddressClick()}
                    className="float-right c-pointer text-success mo-mt-2"
                  >
                    <i className="fas fa-plus"></i> Add Address
                  </a>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </>
    );
  }
}
export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(MailingAddressModal);
