import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import Services from "../GrievanceService";

class EditRecipientModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipientName: null,
      recipientName_err: "",
      id: 0,
    };
  }
  componentWillReceiveProps(prevProps) {
    if (
      prevProps.Id != this.state.id ||
      prevProps.Name != this.state.recipientName
    ) {
      this.setState({
        recipientName: prevProps.Name,
        id: prevProps.Id,
      });
    }
  }
  componentDidMount() {}

  handleClose = () => {
    this.props.OnClose();

    this.setState({
      recipientName_err: "",
      recipientName: null,
      id: 0,
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onShow = () => {};

  changeHandlerName = (e) => {
    this.setState({ recipientName: e.target.value });

    if (e.target.value == null || e.target.value == "") {
      this.state.recipientName_err = "Please Provide Recipient Name";
    } else {
      this.state.recipientName_err = "";
    }

    this.setState({
      recipientName: e.target.value,
      recipientName_err: this.state.recipientName_err,
    });
  };

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.showSpinner();
    if (this.state.recipientName_err == "") {
      let result = await Services.UpdateRecipient(
        this.state.id,
        this.state.recipientName
      );
      if (result) {
        this.props.GetRecipientByRepresentativeId();
        this.handleClose();
      }
    }
    this.hideSpinner();
  };

  render() {
    return (
      <>
        <Modal
          className="RecipientModal latest"
          show={this.props.ShowModal}
          onHide={this.handleClose}
          centered
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                EDIT RECIPIENT{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              id="EditRecipient"
              className="form-horizontal addressMemberForm"
              onSubmit={this.handleFormSubmit}
              noValidate
            >
              <div className="row mx-0">
                <div className="col-12">
                  <MDBInput
                    id="recipientName"
                    name="recipientName"
                    containerClass="mb-4"
                    value={this.state.recipientName}
                    onChange={this.changeHandlerName}
                    label="Recipient Name"
                    required
                    type="text"
                  ></MDBInput>
                  <div className="text-danger">
                    {this.state.recipientName_err}
                  </div>
                </div>

                <div className="col-12 text-centered d-flex justify-content-center">
                  <button
                    className="btn btn-sm btn-danger w-md cancelModal waves-effect waves-light"
                    type="button"
                    id="RecipientPopupCloseBtn"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>

                  <button
                    onClick={this.handleFormSubmit}
                    className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default EditRecipientModal;
