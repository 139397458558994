import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import $ from "jquery";
import Services from "../PermissionService";
import permissionAlgo from "./PermissionAlgo";
import MemberPermissionModal from "./MemberPermissionModal";
class ReviewPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberId: this.props.MemberId,
      openPermissionDetailModal: false,
      PermType: 0,
      PermissionFeatureId: -1,
      defaultPermissions: [],
      originalPermissions: [],
    };
    this.ConvertPermissionToNewScheme = this.ConvertPermissionToNewScheme.bind(
      this
    );
    this.cloneTeamPermission = this.cloneTeamPermission.bind(this);
    this.closePermissionDetailModal = this.closePermissionDetailModal.bind(
      this
    );
  }
  closePermissionDetailModal = () => {
    this.setState({ openPermissionDetailModal: false });
  };
  componentWillUnmount() {}
  componentDidMount() {
    this.handleConfigurePermissionSection();
    this.handleConfigureDefaultPermission();
  }
  async handleConfigureDefaultPermission() {
    $(".loading").show();
    let data = await Services.DefaultPermissions();

    if (!_.isEmpty(data)) {
      let defaultPermissions = permissionAlgo.ConvertPermissionToNewScheme(
        data.result
      );
      this.state.defaultPermissions = permissionAlgo.cloneDefaultPermission(
        defaultPermissions
      );
      await this.setState({
        defaultPermissions: this.state.defaultPermissions,
      });
    }

    $(".loading").hide();
  }
  ConvertPermissionToNewScheme(permissions) {
    let originalPermissions = [];
    const createPermissions = (permissions) => {
      permissions.map((item) => {
        originalPermissions.push(Object.assign({}, item));
        if (item.childs) {
          createPermissions(item.childs);
        }
      });
    };
    if (!_.isEmpty(permissions)) {
      createPermissions(permissions);
    }
    return originalPermissions;
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.MemberId != nextProps.MemberId) {
      this.state.MemberId = nextProps.MemberId;
      this.setState({ MemberId: this.state.MemberId });
      this.handleConfigurePermissionSection();
    }
  }
  cloneTeamPermission = (originalPermissions) =>
    originalPermissions.map((item) =>
      (({ isView, isAdd, isUpdate, isDelete, id }) => {
        return {
          id: item.teamPermissionID,
          permissionFeatureId: item.id,
          isView,
          isAdd,
          isUpdate,
          isDelete,
        };
      })(item)
    );
  handleConfigurePermissionSection = async (e) => {
    $(".loading").show();
    let data = await Services.ReviewPermissions(this.state.MemberId);
    !_.isEmpty(data) &&
      (await this.setState({
        originalPermissions: this.ConvertPermissionToNewScheme(data.result),
      }));

    $(".loading").hide();
  };
  loadPermission = (originalPermissions) => {
    const { defaultPermissions } = this.state;
    //console.log("REVIEW");
    //console.log(originalPermissions);
    return (
      <div className="mb-3 permissionsList">
        <table className="w-100">
          <thead>
            <th />
            <th className="text-center">VIEW</th>
            <th className="text-center">ADD</th>
            <th className="text-center">UPDATE</th>
            <th className="text-center">DELETE</th>
          </thead>
          <tbody>
            {originalPermissions.map((item, idx) => {
              let rowClasses = "";
              switch (this.state.originalPermissions[idx].featureType) {
                case 1:
                  rowClasses = "bg-dark text-white";
                  break;
                case 2:
                  rowClasses = "bg-perm-info text-white";
                  break;
                case 3:
                  rowClasses = "bg-primary text-white";
                  break;
                case 4:
                  rowClasses = "";
                  break;
                default:
              }
              let viewDFCond = false;
              let addDFCond = false;
              let updDFCond = false;
              let delDFCond = false;
              let checkDF = " checked-default";
              if (
                !this.props.fromDefaultPermission &&
                !_.isEmpty(defaultPermissions)
              ) {
                viewDFCond = defaultPermissions[idx].isView;
                addDFCond = defaultPermissions[idx].isAdd;
                updDFCond = defaultPermissions[idx].isUpdate;
                delDFCond = defaultPermissions[idx].isDelete;
              }
              return (
                <Fragment>
                  <tr className={rowClasses}>
                    <td className="p-2">{item.featureName}</td>
                    <td className="p-2 text-center">
                      {
                        <Fragment>
                          <div
                            class={
                              "round-check" +
                              (item.isView ? "" : viewDFCond ? checkDF : "")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={item.isView || viewDFCond}
                              data-index={idx}
                              data-permission="isView"
                              id={"subFeatures-isView-" + idx}
                              name={"subFeatures-isView-" + idx}
                            />
                            <label
                              onClick={async () => {
                                if (item.isView) {
                                  await this.setState({
                                    openPermissionDetailModal: true,
                                    PermType: 1,
                                    PermissionFeatureId: item.id,
                                  });
                                }
                              }}
                              for={"subFeatures-isView-" + idx}
                            />
                          </div>
                        </Fragment>
                      }
                    </td>
                    <td className="p-2 text-center">
                      {
                        <Fragment>
                          <div
                            className={
                              "round-check" +
                              (item.isAdd ? "" : addDFCond ? checkDF : "")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={item.isAdd || addDFCond}
                              data-index={idx}
                              data-permission="isAdd"
                              id={"subFeatures-isAdd-" + idx}
                              name={"subFeatures-isAdd-" + idx}
                            />
                            <label
                              onClick={async () => {
                                if (item.isAdd) {
                                  await this.setState({
                                    openPermissionDetailModal: true,
                                    PermType: 2,
                                    PermissionFeatureId: item.id,
                                  });
                                }
                              }}
                              for={"subFeatures-isAdd-" + idx}
                            />
                          </div>
                        </Fragment>
                      }
                    </td>
                    <td className="p-2 text-center">
                      {
                        <Fragment>
                          <div
                            class={
                              "round-check" +
                              (item.isUpdate ? "" : updDFCond ? checkDF : "")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={item.isUpdate || updDFCond}
                              data-index={idx}
                              data-permission="isUpdate"
                              id={"subFeatures-isUpdate-" + idx}
                              name={"subFeatures-isUpdate-" + idx}
                            />
                            <label
                              onClick={async () => {
                                if (item.isUpdate) {
                                  await this.setState({
                                    openPermissionDetailModal: true,
                                    PermType: 3,
                                    PermissionFeatureId: item.id,
                                  });
                                }
                              }}
                              for={"subFeatures-isUpdate-" + idx}
                            />
                          </div>
                        </Fragment>
                      }
                    </td>
                    <td className="p-2 text-center">
                      {
                        <Fragment>
                          <div
                            class={
                              "round-check" +
                              (item.isDelete ? "" : delDFCond ? checkDF : "")
                            }
                          >
                            <input
                              type="checkbox"
                              checked={item.isDelete || delDFCond}
                              data-index={idx}
                              data-permission="isDelete"
                              id={"subFeatures-isDelete-" + idx}
                              name={"subFeatures-isDelete-" + idx}
                            />
                            <label
                              onClick={async () => {
                                if (item.isDelete) {
                                  await this.setState({
                                    openPermissionDetailModal: true,
                                    PermType: 4,
                                    PermissionFeatureId: item.id,
                                  });
                                }
                              }}
                              for={"subFeatures-isDelete-" + idx}
                            />
                          </div>
                        </Fragment>
                      }
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <AUX>
        <div
          class="modal fade"
          id="ViewPermissionModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="ViewTeamModal"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5 class="modal-title text-uppercase text-white mt-0">
                  REVIEW PERMISSIONS
                </h5>

                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body py-4 review-permission">
                <div className="row">
                  <div className="col-12">
                    This user has all the following permission assigned.
                  </div>
                </div>

                {this.loadPermission(this.state.originalPermissions)}
              </div>
            </div>
          </div>
        </div>
        <MemberPermissionModal
          closePermissionDetailModal={this.closePermissionDetailModal}
          openPermissionDetailModal={this.state.openPermissionDetailModal}
          PermType={this.state.PermType}
          MemberId={this.state.MemberId}
          PermissionFeatureId={this.state.PermissionFeatureId}
          ModalHeading={"permissionDetailModal"}
        />
      </AUX>
    );
  }
}

export default ReviewPermissions;
