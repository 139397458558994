import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RegistrationsService from "../RegistrationsService";
import registrationsAction from "../../../store/registrations/action";
import Header from "../AdminHeader";
import _ from "lodash";
import AddDiscountModal from "./Modals/AddDiscountModal";
import { toast } from "react-toastify";
import DiscountsListTable from "../User/ListTables/DiscountsListTable";


export class DiscountsListing extends Component {
  state = {
    discountCodes: [],
    isLoading: false,
    occasionId: 0,
  };

  async componentDidMount() {
    await this.reloadDiscountCodes();
  }

  openAddDiscountForm = () => {
    this.props.openAddDiscountModal({ formTitle: "Add Discount Code" });
  }
  
  openEditDiscountForm = (discountId) => {
    this.props.openAddDiscountModal({ formTitle: "Edit Discount Code", discountId: discountId });
  }

  reloadDiscountCodes = async () => {
    this.setState({ isloading: true });
    let data = await RegistrationsService.GetDiscountCodes();
    this.state.discountCodes = !_.isEmpty(data) ? data : [];
    await this.setState({
      discountCodes: this.state.discountCodes,
      isloading: false,
    });
  };

  deleteDiscountCode = async (id) => {
    
    let data = await RegistrationsService.DeleteDiscountCode(id);
    if(!_.isEmpty(data))
    {
      this.reloadDiscountCodes();
    } 
    else
    {
      toast.error("Something went wrong.");
    }
  };

  render(){
    return(
      <>
        <Header />
        <div className="fet-container container-fluid">
          <br />
          <button
            type="button"
            className="c-pointer mb-1 px-3 btn btn-sm btn-outline-success pl-2 mt-4"
            onClick={this.openAddDiscountForm}
          >
            <i class="cursorPointer pr-1 fas fa-plus" />
            Add Discount Code
          </button>

          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">Discount Codes</h5>
            <div className="card-body pb-2 px-0 pt-0">
              <DiscountsListTable
                listData={this.state.discountCodes}
                deleteDiscount={this.deleteDiscountCode}
                editDiscount={this.openEditDiscountForm}
                isloading={this.state.isloading}
              />
            </div>
          </div>
        </div>

        <AddDiscountModal
          showModal={this.props.registrationsState.openAddDiscountModal}
          reloadDiscountCodes={this.reloadDiscountCodes}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(DiscountsListing)
);