import React, { Component } from "react";
import { Row, Col, Form } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import _ from "lodash";

export class TeamPosition extends Component {
  constructor(props) {
    super(props);
  }

  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && !this.props.isMobile == true ? (
          <label>{label} </label>
        ) : (
          <label></label>
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          autoComplete="off"
          placeholder={!this.props.isMobile == true ? "" : placeholder}
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Team Position</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}

        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-sm-1 mt-md-2 pl-2 pr-1">
              <Field
                name="positionName"
                type="text"
                classForm="form-control"
                label="Position Name"
                placeholder="Position Name"
                component={this.renderInput}
              />
            </Col>
            <Col className="m-sm-1 m-md-2 pr-2 pl-1">
              <Field
                name="teamName"
                type="text"
                classForm="form-control"
                placeholder="Team Name"
                label="Team Name"
                component={this.renderInput}
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
const validate = (formValues) => {
  const errors = {};
  if (!/^[a-zA-Z']*$/.test(formValues.teamPosition)) {
    errors.teamPosition = "Please enter alpha characters only.";
  }
  if (!/^[a-zA-Z']*$/.test(formValues.teamPosition)) {
    errors.teamName = "Please enter alpha characters only";
  }
  return errors;
};
const formWrapped = reduxForm({
  form: "teamPositionForm",
  destroyOnUnmount: false,
  validate,
})(TeamPosition);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
