import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import jsPDF from "jspdf";
import FEService from "../F&ERequests/FEService";
import FERequestsType from "../FERequestsType";

const budgetlineActions = (cell, row, viewBl, deleteBl) => {
  return (
    <div>
      <div className="actionIcons">
        <i
          className="fas fa-pencil-alt fa-md pl-1 pt-2 cursorPointer"
          title="View Budget Line"
          onClick={() => viewBl(row)}
        ></i>
      </div>
      <div className="actionIcons">
        <i
          className="fas fa-trash fa-md text-danger pl-1 pt-2 cursorPointer"
          title="Delete Budget Line"
          onClick={() => deleteBl(row)}
        ></i>
      </div>
    </div>
  );
};

const MyExportCSV = (props) => {
  const handleClick = async () => {
    const formData = {
      recordtype: props.requestType, // Active/InAccountReview || My/Review Requests
      isExportTable: true,
      PageNo: 1,
      PageSize: 1,
      SortOrder: "id",
      activeTab: props.activeSubHeader, // Inventory Or Requests
      isAdvanceSearchApplied: props.isAdvanceSearchApplied,
      searchCriteria: props.isAdvanceSearchApplied ? props.searchCriteria : {},
      showClosed: props.showClosed,
    };
    var response = await FEService.getDataForFEExport(formData);
    if (!_.isEmpty(response)) {
      props.onExport(response.data);
    }
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Requests Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
const columns = (viewBudgetLine, deleteBudgetLine) => [
  {
    text: "BudgetLine",
    dataField: "name",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white pl-3 px-1 py-2",
  },
  {
    text: "Member Name",
    dataField: "budgetApprover",
    sort: true,
    headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
  },

  // {
  //   text: "",
  //   dataField: "actions",
  //   isDummyField: true,
  //   headerClasses: "text-center text-nowrap bg-dark text-white px-sm-1 py-2",
  //   headerAttrs: {
  //     width: "60px",
  //   },
  //   formatter: (cell, row) => {
  //     return budgetlineActions(cell, row, viewBudgetLine, deleteBudgetLine);
  //   },
  //   csvExport: false,
  // },
];

const getExportFileName = (tblType, filetype) => {
  if (tblType === FERequestsType.MyRequests) {
    return `${Date.now()}_MyRequestsList.${filetype}`;
  }
  if (tblType > FERequestsType.MyRequests) {
    return `${Date.now()}_ReviewRequests.${filetype}`;
  }
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.requestType, "pdf");
  const formData = {
    recordtype: allprops.requestType, // Active/InAccountReview || My/Review Requests
    isExportTable: true,
    PageNo: 1,
    PageSize: 10,
    SortOrder: "id",
    activeTab: allprops.activeSubHeader, // Inventory Or Requests
    isAdvanceSearchApplied: allprops.isAdvanceSearchApplied,
    searchCriteria: allprops.isAdvanceSearchApplied
      ? allprops.searchCriteria
      : {},
    showClosed: allprops.showClosed,
  };
  var response = await FEService.getDataForFEExport(formData);
  if (!_.isEmpty(response)) {
    var tableBody = response.data.map((val) => {
      var listRow = [];
      listRow.push(val.userName);
      listRow.push(val.description);
      listRow.push(val.requesterName);
      listRow.push(val.budgetLineName);
      listRow.push(val.categoryName);
      listRow.push(val.typeName);
      listRow.push(val.statusName);
      return listRow;
    });
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "USER",
          "DESCRIPTION",
          "REQUESTER",
          "BUDGET",
          "CATEGORY",
          "TYPE",
          "STAUS",
        ],
      ],
      body: tableBody,
      tableWidth: "auto",
      styles: { cellPadding: 0.7, fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },

        // etc
      },
    });
    doc.save(filename);
  }
};
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="budgetApproverKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder="Member Search.."
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};

const RemotePagination = (data, viewBudgetLine, deleteBudgetLine) => (
  <ToolkitProvider
    keyField="id"
    data={data}
    columns={columns(viewBudgetLine, deleteBudgetLine)}
    search
  >
    {(props) => (
      <div>
        <MySearch {...props.searchProps} />
        <BootstrapTable {...props.baseProps} />

        <br />
      </div>
    )}
  </ToolkitProvider>
);

const BudgetApproverListTable = (props) => {
  const { listData, viewBudgetLine, deleteBudgetLine } = props;
  return <>{RemotePagination(listData, viewBudgetLine, deleteBudgetLine)}</>;
};

BudgetApproverListTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

BudgetApproverListTable.defaultProps = {
  listData: [],
};

export default BudgetApproverListTable;
