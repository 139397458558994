export const DEFAULT_DOMAIN = "@member.natca.org";
export const AREA_EMAIL_TYPE_ID = 1;
export const FACILITY_EMAIL_TYPE_ID = 2;
export const REGION_EMAIL_TYPE_ID = 3;
export const TEAM_EMAIL_TYPE_ID = 4;

export const PERSONAL_INFO_FIELDS_HEADER = 1;
export const CONTACT_FIELDS_HEADER = 2;
export const MEMBERSHIP_FIELDS_HEADER = 3;
export const ACCOUNT_FIELDS_HEADER = 4;
export const DATE_FIELDS_HEADER = 5;
export const FINANCIAL_FIELDS_HEADER = 6;
export const JOBS_FIELD_HEADER = 7;
export const POSITIONS_FIELD_HEADER = 8;
export const FILTER_FIELD_HEADER = 9;
export const COMMUNICATION_FIELD_HEADER = 10;

export const ADVANCE_SEARCH = 1;
export const SAVED_SEARCH = 2;
export const OTHERS = 3;
