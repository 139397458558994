import $ from "jquery";
import _ from "lodash";
export default {
  changeChild: (permissions, e) => {
    let Index = -1;
    Index = e.target.dataset.index;
    let disable = e.target.dataset.disabled;
    let ListId = $(e.target)
      .parents(".permissionsList")
      .attr("id");
    let checked = false;
    if (disable == "false") {
      checked = e.target.checked;
      permissions[Index][e.target.dataset.permission] = checked;
      $(e.target).attr("data-checked", checked);
    }
    if (
      ((navigator.platform.toUpperCase().indexOf("MAC") >= 0 &&
        e.nativeEvent.metaKey) ||
        (navigator.platform.toUpperCase().indexOf("MAC") == -1 &&
          e.nativeEvent.ctrlKey)) &&
      !_.isEmpty(ListId)
    ) {
      if (disable == "true") {
        checked = $(e.target).attr("data-checked") == "false";
        $(e.target).attr("data-checked", checked);
      }
      ListId = "#" + ListId;
      const toggleChild = (parentid) => {
        $(
          ListId +
            " [data-parent='" +
            parentid +
            "'][data-permission='" +
            e.target.dataset.permission +
            "']"
        ).each(function(i) {
          let parentid2 = $(this).attr("data-index");
          let disableChild = $(this).attr("data-disabled");
          permissions[parentid2][e.target.dataset.permission] =
            disableChild == "false" ? checked : false;
          $(this).attr("data-checked", checked);
          if (
            $(
              ListId +
                " [data-parent='" +
                parentid2 +
                "'][data-permission='" +
                e.target.dataset.permission +
                "']"
            ).length > 0
          ) {
            toggleChild(parentid2);
          }
        });
      };
      toggleChild(Index);
    }
    return permissions;
  },
  // Special Team permissions
  changeChildST: (permissions, e) => {
    let Index = -1;
    Index = e.target.dataset.index;
    let disable = e.target.dataset.disabled;
    let ListId = $(e.target)
      .parents(".permissionsList")
      .attr("id");
    let value = -1;
    value = parseInt(e.target.dataset.value, 10);
    switch (value) {
      case 1:
        value = 0;
        break;
      case 0:
        value = -1;
        break;
      case -1:
        value = 1;
        break;
      case -2:
        value = 1;
        break;
      default:
        value = value;
        break;
    }

    $(e.target).attr("data-value", value);
    if (disable == "false") {
      permissions[Index][e.target.dataset.permission] = value;
    }
    if (
      ((navigator.platform.toUpperCase().indexOf("MAC") >= 0 &&
        e.nativeEvent.metaKey) ||
        (navigator.platform.toUpperCase().indexOf("MAC") == -1 &&
          e.nativeEvent.ctrlKey)) &&
      !_.isEmpty(ListId)
    ) {
      ListId = "#" + ListId;
      const toggleChild = (parentid) => {
        $(
          ListId +
            " [data-parent='" +
            parentid +
            "'][data-permission='" +
            e.target.dataset.permission +
            "']"
        ).each(function(i) {
          let parentid2 = $(this).attr("data-index");
          let disableChild = $(this).attr("data-disabled");
          permissions[parentid2][e.target.dataset.permission] =
            disableChild == "false" ? value : -2;
          $(this).attr("data-value", value);
          if (
            $(
              ListId +
                " [data-parent='" +
                parentid2 +
                "'][data-permission='" +
                e.target.dataset.permission +
                "']"
            ).length > 0
          ) {
            toggleChild(parentid2);
          }
        });
      };
      toggleChild(Index);
    }
    return permissions;
  },
  changeSpecialPermissionsToByte: (Permissions) => {
    if (Permissions) {
      const byteModified = Permissions.map((item) => {
        item.isView = item.isView ? -1 : -2;
        item.isAdd = item.isAdd ? -1 : -2;
        item.isUpdate = item.isUpdate ? -1 : -2;
        item.isDelete = item.isDelete ? -1 : -2;
        return item;
      }); //Array.isArray(item) ? clone(item) : item);
      return byteModified;
    }
    return [];
  },
  cloneDefaultPermission: (originalPermissions) =>
    originalPermissions.map((item) =>
      (({ isView, isAdd, isUpdate, isDelete, id }) => {
        return {
          id: item.defaultPermissionID,
          permissionFeatureId: item.id,
          isView,
          isAdd,
          isUpdate,
          isDelete,
        };
      })(item)
    ),
  //FromEditTeam
  ConvertPermissionToNewScheme: (permissions) => {
    let originalPermissions = [];
    const createPermissions = (permissions) => {
      permissions.map((item) => {
        originalPermissions.push(Object.assign({}, item));
        if (item.childs) {
          createPermissions(item.childs);
        }
      });
    };
    createPermissions(permissions);
    return originalPermissions;
  },
  cloneTeamPermission: (originalPermissions) =>
    originalPermissions.map((item) =>
      (({ isView, isAdd, isUpdate, isDelete, id }) => {
        return {
          permissionFeatureId: item.id,
          isView,
          isAdd,
          isUpdate,
          isDelete,
        };
      })(item)
    ),
  createPositionPermissions: (teamPermissions) => {
    if (teamPermissions) {
      const clone = (originalPermissions) =>
        originalPermissions.map((item) =>
          (({ isView, isAdd, isUpdate, isDelete }) => ({
            permissionFeatureId: item.permissionFeatureId,
            isView,
            isAdd,
            isUpdate,
            isDelete,
          }))(item)
        ); //Array.isArray(item) ? clone(item) : item);
      return clone(teamPermissions);
    }
    return [];
  },
  changeToFalse(data) {
    return data.map((item) => {
      item.isChecked = false;
      if (item.facility) {
        this.changeToFalse(item.facility);
      }
      return item;
    });
  },
  createSubmissionScopeObject(scopeData) {
    let filterScopes = this.filterAllCheckedScope(scopeData);
    let scopesArray = [];
    filterScopes.map((item) => {
      if (item.isChecked) {
        scopesArray.push({ RegionId: item.id, FacilityId: null });
      } else {
        item.facility.map((itemf) => {
          if (itemf.isChecked) {
            scopesArray.push({
              RegionId: itemf.regionId,
              FacilityId: itemf.id,
            });
          }
        });
      }
    });
    return scopesArray;
  },
  filterAllCheckedScope(scopeData) {
    var result = _.filter(_.cloneDeep(scopeData), {
      facility: [{ isChecked: true }],
    });
    result = _.map(result, (item) => {
      item.facility = item.facility.filter((x) => x.isChecked === true);
      return item;
    });
    return result;
  },

  cloneEditPositionPermission(originalPermissions) {
    return originalPermissions.map((item) =>
      (({ isView, isAdd, isUpdate, isDelete, id }) => {
        return {
          id: item.teamPositionPermissionID,
          permissionFeatureId: item.id,
          isView,
          isAdd,
          isUpdate,
          isDelete,
        };
      })(item)
    );
  },
  textValidation(text) {
    let textReg = /^[a-zA-z0-9\- ]*$/;
    const text_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Please provide only text.",
      },
    ];
    if (textReg.test(text)) {
      return text_States.find(({ state }) => state == "Valid").error;
    } else {
      return text_States.find(({ state }) => state == "Not_Match_To_Regex")
        .error;
    }
  },
  notNullValidation(text) {
    if (text == null || text == "") {
      return "Please provide the above value";
    }
    return null;
  },
  handleChangeScopes(e, gameChanger, Data) {
    let obj = e.target.dataset.obj;
    const checkAll = (scopeData, value) => {
      return scopeData.map((item) => {
        item.isChecked = value;
        if (Array.isArray(item.facility)) {
          checkAll(item.facility, value);
        }
        return item;
      });
    };

    let index = -1;
    switch (obj) {
      case "National":
        gameChanger.isNational = e.target.checked;
        gameChanger.Scopes = e.target.checked && [];
        if (Data) {
          Data = checkAll(Data, e.target.checked);
        }
        break;

      case "Region":
        index = Data.findIndex((el) => el.id == e.target.dataset.id);
        Data[index].isChecked = e.target.checked;
        gameChanger.isNational = false;
        if (Array.isArray(Data[index].facility)) {
          Data[index].facility = checkAll(
            Data[index].facility,
            e.target.checked
          );
        }
        gameChanger.Scopes = [];
        gameChanger.Scopes = this.createSubmissionScopeObject(Data);

        break;

      case "Facility":
        gameChanger.isNational = false;
        let regionIndex = Data.findIndex((el) => el.id == e.target.dataset.rid);
        let f = Data[regionIndex].facility;
        index = Data[regionIndex].facility.findIndex(
          (el) => el.id == e.target.dataset.id
        );
        Data[regionIndex].isChecked = !e.target.checked && false;
        Data[regionIndex].facility[index].isChecked = e.target.checked;

        gameChanger.Scopes = [];
        gameChanger.Scopes = this.createSubmissionScopeObject(Data);
        break;

      default:
    }
    return { gameChanger: gameChanger, Data: Data };
  },
};
