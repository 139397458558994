import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../payfilestyle.css";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PayfileTwoAction from "../../../store/payfileTwo/action";
import Services from "../PayfileService";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import JobHistoryFormModal from "./JobHistoryFormModal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { debounce } from "throttle-debounce";
import TextField from "@material-ui/core/TextField";
import ConfirmDeleteModal from "./General/DeleteModal";
import AuthService from "../../Auth/AuthService";
import { toast } from "react-toastify";
import $ from "jquery";
const status = [
  {
    text: "Active",
    value: "Active",
  },
  {
    text: "Retired",
    value: "Retired",
  },
  {
    text: "Terminated",
    value: "Terminated",
  },
  {
    text: "Supervisor",
    value: "Supervisor",
  },
  {
    text: "Resigned",
    value: "Resigned",
  },
  {
    text: "Deceased",
    value: "Deceased",
  },
  {
    text: "pass",
    value: "pass",
  },
];

class ImportDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberId: -1,
      ECI: -1,
      deleteJobIndex: -1,
      PayFileTwoId: -1,
      openModal: false,
      updateData: [],
      Members: [],
      FormCode: -1,
      SubmissionObj: {
        BargainingUnitDate: "",
        NatcaBargainingUnitDate: "",
        UnionStartDate: "",
        PayFileTwoId: -1,
        MemberID: -1,
        ECI: -1,
        Status: "",
        Jobs: [],
      },
      BackUp_SubmissionObj: {},
      NatcaBargainingUnits: [],

      MemberInfoData: null,
      MemberJobHistory: null,
      PayFileUpdate: null,
      NBU: -1,
      NBU_Err: "",

      MemberInfoLoader: true,
      PayfileUpdateLoader: true,
      MemberJobHistoryLoader: true,
      JobDetail: {},
      deleteModal: false,
      sizePerPageUpdateData: 5,
    };
    this.closeModal = this.closeModal.bind(this);
    this.LoadUpdatesData = this.LoadUpdatesData.bind(this);
    this.UpdateDataSizePerPageChange = this.UpdateDataSizePerPageChange.bind(
      this
    );
    this.changeHandlerStatus = this.changeHandlerStatus.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.handleRemoveJob = this.handleRemoveJob.bind(this);
    this.jobUpdate = this.jobUpdate.bind(this);
    this.jobIgnores = this.jobIgnores.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.cancelUpdate = this.cancelUpdate.bind(this);
    this.UpdateColumns = [
      {
        text: "Noa 1/2",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <>
              {row.noA1 != null ? row.noA1 : "--"} /{" "}
              {row.noA2 != null ? row.noA2 : "--"}
            </>
          );
        },
      },

      {
        text: "Year/PP",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return <>{row.year + "/" + row.payPeriod}</>;
        },
      },
      {
        text: "Org To/From",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <>
              {row.orgTo != null ? row.orgTo : "--"} /{" "}
              {row.orgFrom != null ? row.orgFrom : "--"}
            </>
          );
        },
      },
      {
        text: "Facility To/From",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <>
              {row.facilityCodeTo != null ? row.facilityCodeTo : "--"} /{" "}
              {row.facilityCodeFrom != null ? row.facilityCodeFrom : "--"}
            </>
          );
        },
      },
      {
        text: "Effective",
        dataField: "dateEffective",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          let d = "";
          if (cellContent) {
            d = cellContent.split("T")[0];
          }
          return moment(d).isValid() ? moment(d).format("MM/DD/YYYY") : "";
        },
      },
    ];
  }
  handleRemoveJob = async () => {
    this.setState({ deleteModal: false });
    if (
      this.state.deleteJobIndex == -1 ||
      _.isEmpty(this.state.SubmissionObj.Jobs)
    ) {
      return false;
    }
    let job = this.state.SubmissionObj.Jobs[this.state.deleteJobIndex];
    if (job.id > 0) {
      job.isDeleted = true;
      job.isEdited = true;
    } else {
      this.state.SubmissionObj.Jobs.splice(this.state.deleteJobIndex, 1);
    }
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  UpdateDataSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageUpdateData: sizePerPage });
  };
  loadMemberData = async (SearchTerms) => {
    let data = await Services.LoadMembers(SearchTerms, 0, 5);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.setState({ Members: data.result });
      }
    }
  };
  async loadBargainingUnit() {
    let data = await Services.LoadBargainingUnit();
    if (data != null) {
      this.setState({ NatcaBargainingUnits: data.result });
    }
  }
  componentDidMount() {
    this.loadMemberData("");
    this.loadBargainingUnit();
  }
  async LoadUpdatesData() {
    let data = await Services.PayfileTwoMemberInfo(this.state.MemberId);
    let updateData = await Services.PayfileTwoUpdates(this.state.ECI);
    let jobHistory = await Services.MemberJobHistory(this.state.MemberId);
    if (data != null) {
      let obj = this.state.SubmissionObj;
      obj.ECI = data.result.eci;
      obj.FileId = this.props.fileId;
      obj.PayFileTwoId = this.state.PayFileTwoId;
      obj.MemberID = data.result.id;
      obj.Status = data.result.status;
      obj.NatcaBargainingUnitDate = data.result.natcaBargainingUnitDate;
      obj.BargainingUnitDate = data.result.bargainingUnitDate;
      obj.UnionStartDate = data.result.unionStartDate;
      this.setState({
        MemberInfoLoader: false,
        SubmissionObj: obj,
        MemberInfoData: data.result,
      });
    } else {
      let obj = this.state.SubmissionObj;
      obj.BargainingUnitDate = "";
      obj.NatcaBargainingUnitDate = "";
      obj.UnionStartDate = "";
      obj.MemberID = -1;
      obj.Status = "";
      obj.ECI = this.state.ECI;
      obj.FileId = this.props.fileId;
      obj.PayFileTwoId = this.state.PayFileTwoId;
      this.setState({
        MemberInfoLoader: false,
        SubmissionObj: obj,
        MemberInfoData: obj,
      });
    }
    if (updateData != null) {
      this.setState({
        PayfileUpdateLoader: false,
        PayFileUpdate: updateData.result,
      });
    } else {
      this.setState({ PayfileUpdateLoader: false, PayFileUpdate: null });
    }

    if (jobHistory != null) {
      this.state.SubmissionObj.Jobs = jobHistory.result;
      this.state.FormCode = this.state.SubmissionObj.Jobs.length + 1;
      for (var i = 0; i < this.state.SubmissionObj.Jobs.length; i++) {
        this.state.SubmissionObj.Jobs[i].FormCode = i + 1;
      }

      this.setState({
        MemberJobHistoryLoader: false,
        SubmissionObj: this.state.SubmissionObj,
        MemberJobHistory: data.result,
        FormCode: this.state.FormCode,
      });
    } else {
      this.state.SubmissionObj.Jobs = [];
      this.setState({
        MemberJobHistoryLoader: false,
        FormCode: 1,
        SubmissionObj: this.state.SubmissionObj,
        MemberJobHistory: [],
      });
    }
    this.setState({
      BackUp_SubmissionObj: _.cloneDeep(this.state.SubmissionObj),
    });
  }
  ECISearchHandler = (SearchTerms) => {
    this.setState({ searchTerm: SearchTerms }, () => {
      this.autocompleteSearchDebounced();
    });
  };
  changeHandlerStatus = (e) => {
    this.state.SubmissionObj.Status = e.target.value;
    this.statusValidate(e.target.value);
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  changeHandlerNBU = (e) => {
    this.state.SubmissionObj.NatcaBargainingUnitID = e.target.value;
    this.setState({ SubmissionObj: this.state.SubmissionObj });
  };
  async handleChangeECI(value) {
    if (this.state.SubmissionObj != null) {
      this.state.SubmissionObj.ECI = value;
      this.ECIValidate(value);
      this.setState({
        SubmissionObj: this.state.SubmissionObj,
      });
    }
  }
  ECIValidate(val) {
    let error = "";
    if (_.isEmpty(val)) {
      error = "Please, provide appropriate ECI";
    }
    this.state.SubmissionObj.ECI_err = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  statusValidate(val) {
    let error = "";
    if (_.isEmpty(val)) {
      error = "Please, select appropriate status";
    }
    this.state.SubmissionObj.Status_Err = error;
    this.setState({
      SubmissionObj: this.state.SubmissionObj,
    });
    return error;
  }
  jobUpdate(job) {
    let obj = this.state.SubmissionObj;
    if (job.id != 0) {
      let index = obj.Jobs.findIndex((x) => x.id == job.id);
      if (index > -1) {
        obj.Jobs[index] = job;

        this.setState({ SubmissionObj: obj });
      }
    } else {
      let index = obj.Jobs.findIndex((x) => x.FormCode == job.FormCode);
      if (index > -1) {
        obj.Jobs[index] = job;
      } else {
        obj.Jobs.push(job);
      }

      this.setState({ SubmissionObj: obj });
    }

    if (!_.isEmpty(obj.Jobs)) {
      let jobs = _.orderBy(_.cloneDeep(obj.Jobs), ["startDate"], ["asc"]);
      var L_Job = jobs[0];
      if (jobs.length > 0) {
        for (var i = 0; i < jobs.length; i++) {
          // compare start date with end date of previous one
          // if not greater or null then change the previous end date at -1 or at start date

          if (
            !jobs[i].isDeleted &&
            !_.isEmpty(jobs[i].endDate) &&
            jobs[i].startDate > jobs[i].endDate
          ) {
            jobs[i].endDate = moment(jobs[i].startDate)
              .add(1, "days")
              .format("YYYY-MM-DD");
            jobs[i].isEdited = true;
          }

          if (i != 0 && !jobs[i].isDeleted) {
            var previousNonDelete = -1;
            for (var j = i - 1; j >= 0; j--) {
              if (!jobs[j].isDeleted) {
                previousNonDelete = j;
                break;
              }
            }
            if (previousNonDelete != -1) {
              if (
                jobs[i].startDate < jobs[previousNonDelete].endDate ||
                _.isEmpty(jobs[previousNonDelete].endDate)
              ) {
                jobs[previousNonDelete].endDate =
                  jobs[previousNonDelete].startDate < jobs[i].startDate
                    ? moment(jobs[i].startDate)
                        .subtract(1, "days")
                        .format("YYYY-MM-DD")
                    : jobs[i].startDate;

                jobs[previousNonDelete].isEdited = true;
              }
            }
          }
        }
      }
      this.state.SubmissionObj.Jobs = jobs;
      this.setState({ SubmissionObj: this.state.SubmissionObj });
    }
  }
  async jobIgnores() {
    if (!_.isEmpty(this.state.ECI) && !_.isEmpty(this.props.fileId)) {
      $(".loading").show();
      var data = await Services.IgnoreEmployeeJobs(
        this.state.ECI,
        this.props.fileId
      );
      $(".loading").hide();
      if (data && data.statusCode == 200) {
        this.closeModal();
        this.props.refreshOnIgnore();
      }
    } else {
      toast.error("Invalid state, Tray again after few moments");
      this.closeModal();
    }
  }
  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.loadMemberData(this.state.searchTerm);
  };
  async submitForm() {
    let obj = this.state.SubmissionObj;
    if (
      this.ECIValidate(obj.ECI) == "" &&
      this.statusValidate(obj.Status) == ""
    ) {
      $(".loading").show();
      var data = await Services.PayFileUpdate(this.state.SubmissionObj);
      $(".loading").hide();
      if (data && data.statusCode == 200) {
        this.props.refreshOnUpdate();
        this.closeModal();
      }
    }
  }
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    //totalSize,
    rowEvents
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      paginationTotalRenderer: TableCustomTotal,
      sizePerPage: sizePerPage,
      //hidePageListOnlyOnePage: true
    };
    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowEvents={rowEvents}
        />
      </div>
    );
  }
  async componentDidUpdate(prevprops) {
    if (
      this.props.payfileTwo.openImportDetailModal &&
      this.props.ImportData !== null
    ) {
      if (
        this.props.ImportData.eci != this.state.ECI ||
        this.state.MemberId != this.props.ImportData.memberID ||
        this.props.ImportData.id != this.state.PayFileTwoId
      ) {
        await this.setState({
          ECI: this.props.ImportData.eci,
          PayFileTwoId: this.props.ImportData.id,
          MemberId: this.props.ImportData.memberID,
          PayfileUpdateLoader: true,
          MemberInfoLoader: true,
          MemberJobHistoryLoader: true,
        });
        this.LoadUpdatesData();
      }

      this.openModal();
    }
  }

  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeImportDetailModal();
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
  }
  cancelUpdate() {
    this.setState({
      SubmissionObj: this.state.BackUp_SubmissionObj,
      FormCode: -1,
    });
    this.closeModal();
  }

  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          fontSize: 18,
        },
      },
    });
    let { NatcaBargainingUnits } = this.state;
    let { ImportData } = this.props;
    let header = "";
    if (ImportData != null) {
      header =
        ImportData.firstName.toUpperCase() +
        " " +
        ImportData.lastName.toUpperCase() +
        " (" +
        (!_.isEmpty(ImportData.regionCode) ? ImportData.regionCode : "--") +
        "/" +
        (!_.isEmpty(ImportData.facilityCodeTo)
          ? ImportData.facilityCodeTo
          : "--") +
        ")";
    }

    return (
      <>
        <Modal
          centered={true}
          autoFocus={true}
          isOpen={this.state.openModal}
          className="errorignoremodel styled-modal"
          backdrop="static"
        >
          <ModalHeader
            className="modal-header bg-secondary py-2"
            toggle={this.cancelUpdate}
          >
            PAY FILE 2 UPDATE - {header}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div class="col-12">
                To create a new assign it a and assign it a scope.
              </div>

              <div
                className="col-12"
                data-toggle="collapse"
                data-target="#M-Verification"
                aria-expanded="true"
                aria-controls="M-Verification"
              >
                <div className="ignore-gray">
                  <h5>Member Verifications</h5>
                </div>
              </div>
              <div className="col-12 collapse show" id="M-Verification">
                {this.state.MemberInfoLoader ? (
                  <div
                    class="m-3 position-relative d-block mx-auto spinner-border text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  !this.state.MemberInfoLoader &&
                  this.state.MemberInfoData != null && (
                    <div className="modalinput">
                      <div className="row mt-2 mb-2">
                        {this.state.BackUp_SubmissionObj.MemberID == -1 && (
                          <div className="col-3">
                            <label className="ml-0">Employee Common Id</label>
                            <Autocomplete
                              //  style={{ width: 300 }}
                              options={
                                !_.isEmpty(this.state.Members)
                                  ? this.state.Members.map((x) => x.faaID)
                                  : []
                              }
                              //classes={{option: classes.option}}

                              autoComplete={true}
                              //autoSelect={true}
                              autoHighlight={true}
                              onChange={(e, value) => {
                                this.handleChangeECI(value);
                              }}
                              onInputChange={(e, value, reason) => {
                                this.ECISearchHandler(value);
                              }}
                              value={this.state.SubmissionObj.ECI}
                              getOptionLabel={(option) => {
                                if (option != undefined) {
                                  return option;
                                } else {
                                  return "";
                                }
                              }}
                              renderOption={(option) => (
                                <React.Fragment>{option}</React.Fragment>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Provide Employee Common Id"
                                  variant="standard"
                                  fullWidth
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "disabled", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                            <span className="text-danger">
                              {this.state.SubmissionObj.ECI_err}
                            </span>
                          </div>
                        )}
                        <div className="col-3">
                          <label>Status</label>
                          <select
                            //className="browser-default custom-select"
                            onChange={this.changeHandlerStatus}
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Value</option>
                            {status.map((item, index) => {
                              return (
                                <option
                                  value={item.value}
                                  selected={
                                    this.state.SubmissionObj.Status.toUpperCase() ==
                                    item.value.toUpperCase()
                                  }
                                >
                                  {item.text}
                                </option>
                              );
                            })}
                          </select>
                          <label className="text-danger">
                            {this.state.SubmissionObj.Status_Err}
                          </label>
                        </div>
                        <div className="col-3">
                          <label>Natca Bargaining Units</label>
                          <select
                            //className="browser-default custom-select"
                            onChange={this.changeHandlerNBU}
                            name="status"
                            className="form-control"
                          >
                            <option value="">Select Value</option>

                            {NatcaBargainingUnits.map((item, index) => {
                              return (
                                <option
                                  value={item.id}
                                  selected={
                                    this.state.SubmissionObj
                                      .NatcaBargainingUnitID == item.id
                                  }
                                >
                                  {item.name} - {item.code}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div
                className="col-12"
                data-toggle="collapse"
                data-target="#Seniority"
                aria-expanded="true"
                aria-controls="Seniority"
              >
                <div className="ignore-gray">
                  <h5>Verify Serniority Dates</h5>
                </div>
              </div>
              <div className="col-12 collapse" id="Seniority">
                {this.state.MemberInfoLoader ? (
                  <div
                    class="m-3 position-relative d-block mx-auto spinner-border text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  !this.state.MemberInfoLoader &&
                  this.state.MemberInfoData != null && (
                    <div className="modalinput">
                      <div className="row mt-2 mb-2">
                        <div className="col-3">
                          <label>Natca Bargaining Unit Date</label>
                          <DatePicker
                            dateForm="MM/DD/YYYY"
                            peekNextMonth
                            className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            onChange={(date) => {
                              this.state.SubmissionObj.NatcaBargainingUnitDate = date;
                              this.setState({
                                SubmissionObj: this.state.SubmissionObj,
                              });
                            }}
                            selected={
                              this.state.SubmissionObj.NatcaBargainingUnitDate
                                ? moment(
                                    this.state.SubmissionObj
                                      .NatcaBargainingUnitDate
                                  ).toDate()
                                : ""
                            }
                            placeholderText={"MM/DD/YYYY"}
                          />
                        </div>
                        <div className="col-3">
                          <label>Bargaining Unit Date</label>
                          <DatePicker
                            dateForm="MM/DD/YYYY"
                            peekNextMonth
                            className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            onChange={(date) => {
                              this.state.SubmissionObj.BargainingUnitDate = date;
                              this.setState({
                                SubmissionObj: this.state.SubmissionObj,
                              });
                            }}
                            selected={
                              this.state.SubmissionObj.BargainingUnitDate
                                ? moment(
                                    this.state.SubmissionObj.BargainingUnitDate
                                  ).toDate()
                                : ""
                            }
                            placeholderText={"MM/DD/YYYY"}
                          />
                        </div>
                        <div className="col-3">
                          <label>Union Start Date</label>
                          <DatePicker
                            dateForm="MM/DD/YYYY"
                            peekNextMonth
                            className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            onChange={(date) => {
                              this.state.SubmissionObj.UnionStartDate = date;
                              this.setState({
                                SubmissionObj: this.state.SubmissionObj,
                              });
                            }}
                            selected={
                              this.state.SubmissionObj.UnionStartDate
                                ? moment(
                                    this.state.SubmissionObj.UnionStartDate
                                  ).toDate()
                                : ""
                            }
                            placeholderText={"MM/DD/YYYY"}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              {((!_.isEmpty(this.state.PayFileUpdate) &&
                !this.state.PayfileUpdateLoader) ||
                (_.isEmpty(this.state.PayFileUpdate) &&
                  this.state.PayfileUpdateLoader)) && (
                <>
                  <div
                    className="col-12"
                    data-toggle="collapse"
                    data-target="#Updates"
                    aria-expanded="true"
                    aria-controls="Updates"
                  >
                    <div className="ignore-gray">
                      <h5>PAY FILE UPDATES</h5>
                    </div>
                  </div>

                  <div className="collapse col-12 mt-2" id="Updates">
                    {this.state.PayfileUpdateLoader ? (
                      <div
                        class="m-3 position-relative d-block mx-auto spinner-border text-info"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      !this.state.PayfileUpdateLoader &&
                      this.state.PayFileUpdate && (
                        <div className="ignore-table-Change">
                          {this.RemotePagination(
                            this.UpdateColumns,
                            "Id",
                            this.state.PayFileUpdate,
                            this.UpdateDataSizePerPageChange,
                            this.state.sizePerPageUpdateData
                            // this.rowEvents
                          )}
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
              <div
                className="col-12"
                data-toggle="collapse"
                data-target="#JobHistory"
                aria-expanded="true"
                aria-controls="JobHistory"
              >
                <div className="ignore-gray">
                  <h5>Member Job History</h5>
                </div>
              </div>
              <div className="col-12 mt-2 collapse" id="JobHistory">
                {this.state.MemberJobHistoryLoader ? (
                  <div
                    class="spinner-border m-3 position-relative d-block mx-auto text-info"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  !this.state.MemberJobHistoryLoader && (
                    <div className="ignore-table-Change">
                      <table>
                        <thead className="bg-dark">
                          <tr>
                            <th>REGION/FACILITY</th>
                            <th>BUS CODE</th>
                            <th>ORG CODE</th>
                            <th>SUPERVISOR</th>
                            <th>START</th>
                            <th>END</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.SubmissionObj.Jobs.map(
                            (row, idx) =>
                              !row.isDeleted && (
                                <tr>
                                  <td>
                                    {!_.isEmpty(row.regionCode)
                                      ? row.regionCode
                                      : "--"}{" "}
                                    /{" "}
                                    {!_.isEmpty(row.facilityCode)
                                      ? row.facilityCode
                                      : "--"}
                                  </td>
                                  <td>
                                    {!_.isEmpty(row.busTo) ? row.busTo : "--"}
                                  </td>
                                  <td>{row.organizationCode}</td>
                                  <td>{row.isSupervisor ? "YES" : "NO"}</td>
                                  <td>
                                    {moment(row.startDate).isValid()
                                      ? moment(row.startDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {moment(row.endDate).isValid()
                                      ? moment(row.endDate).format("MM/DD/YYYY")
                                      : ""}
                                  </td>
                                  <td>
                                    <div className="ignore-delete-edit">
                                      <i
                                        class="fas fa-pen"
                                        onClick={() => {
                                          this.setState({
                                            JobDetail: row,
                                          });
                                          this.props.openJobHistoryFormModal();
                                        }}
                                      ></i>
                                      <i
                                        class="fas fa-trash"
                                        onClick={(e) => {
                                          this.setState({
                                            deleteJobIndex: idx,
                                            deleteModal: true,
                                          });
                                        }}
                                      ></i>
                                    </div>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                      <div class="text-right mt-2">
                        <button
                          class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light"
                          onClick={() => {
                            this.state.FormCode++;
                            this.state.JobDetail = {
                              FormCode: this.state.FormCode,
                              id: 0,
                              region: "",
                              facility: "",
                              busTo: "",

                              organizationCode: "",
                              isSuperVisor: false,
                              isTempSuperVisor: false,
                              isEdited: true,
                              startDate: moment(new Date()).toDate(),
                              endDate: "",
                            };
                            this.setState({ JobDetail: this.state.JobDetail });
                            this.props.openJobHistoryFormModal();
                          }}
                        >
                          New Job
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div class="text-right">
              <Button
                className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
                onClick={this.cancelUpdate}
              >
                Cancel
              </Button>
              {!this.props.ignoreStatus && (
                <button
                  class="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.jobIgnores}
                >
                  Ignore Update
                </button>
              )}
              <button
                class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
                type="submit"
                onClick={this.submitForm}
              >
                Process Update
              </button>
            </div>
          </ModalFooter>
        </Modal>
        <JobHistoryFormModal
          JobDetail={this.state.JobDetail}
          jobUpdate={this.jobUpdate}
        />
        <ConfirmDeleteModal
          id={"DeleteJob"}
          content={"Job"}
          confirm={() => {
            this.handleRemoveJob();
          }}
          closeModal={() => {
            this.setState({ deleteModal: false });
          }}
          openModal={this.state.deleteModal}
        />
      </>
    );
  }
}
export default connect((state) => ({ payfileTwo: state.payfileTwo }), {
  ...PayfileTwoAction,
})(ImportDetailModal);
