import React, { Component } from "react";
import _ from "lodash";
import advanceFilterActions from "../../../../store/advancedfilter/action";
import fetrackerActions from "../../../../store/feTracker/action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class FE_Types extends Component {
  onChange(item) {
    const actions = this.props.isMemberAF
      ? this.props.advanceFilterActions
      : this.props.fetrackerActions;

    const reduxState = this.props.isMemberAF
      ? this.props.filterState
      : this.props.festate;

    if (this.props.isMemberAF && this.props.isInventoryTab) {
      var fetypes = reduxState.filterData.fe_inventory_types;
    } else if (!this.props.isMemberAF && !this.props.isInventoryTab) {
      var fetypes = reduxState.filterData.fetypes;
    }
    var index = _.findIndex(fetypes, (o) => {
      return o.type === item.type;
    });
    item.checked = !fetypes[index].checked;
    fetypes[index] = item;
    actions.setFilterData({
      ...reduxState.filterData,
    });
  }
  chechboxes(item, mySelf) {
    // const reduxState = this.props.isMemberAF
    //   ? this.props.filterState
    //   : this.props.festate;

    // if (this.props.isMemberAF && this.props.isInventoryTab) {
    //   var fetypes = reduxState.filterData.fetypes;
    // } else if (!this.props.isMemberAF && !this.props.isInventoryTab) {
    //   var fetypes = reduxState.filterData.fetypes;
    // }
    return (
      <li class="chechbox-list_item" key={"msliitem-" + item.type}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"msitem-" + item.type}
        >
          <input
            type="checkbox"
            htmlFor={"msitem-" + item.type}
            value={item.type}
            checked={item.checked}
            className="custom-control-input"
            id={"msitem-" + item.type}
            name={"msitem-" + item.type}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            htmlFor={"msitem-" + item.type}
            style={{ color: this.props.isMobile ? "white" : null }}
          >
            {item.type}
            {mySelf.props.isMemberAF && (
              <small className="totalCount">{`(${item.total})`}</small>
            )}
          </label>
        </span>
      </li>
    );
  }

  render() {
    const reduxState = this.props.isMemberAF
      ? this.props.filterState
      : this.props.festate;

    if (this.props.isMemberAF && this.props.isInventoryTab) {
      var fetypes = reduxState.filterData.fe_inventory_types;
    } else if (!this.props.isMemberAF && !this.props.isInventoryTab) {
      var fetypes = reduxState.filterData.fetypes;
    }
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4> Type</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="filter-fields-scroll">
          <ul className="chechbox-list">
            {fetypes &&
              !_.isEmpty(reduxState) &&
              fetypes.map((item) => this.chechboxes(item, this))}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    festate: state.fetracker,
    filterState: state.advancedFilter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetrackerActions: bindActionCreators(fetrackerActions, dispatch),
    advanceFilterActions: bindActionCreators(advanceFilterActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FE_Types);
