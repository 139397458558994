import AUX from "../../hoc/Aux_";
import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import axios from "./auth-header";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";
import * as Constants from "../Admin/Users/EditUser/PrimaryEnum";
import InputMask from "react-input-mask";

class PhonesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      number: "",
      phonetypeid: 1,
      phone_err: "",
      phonetype_err: "",
      isValid: false,
    };
  }
  componentWillReceiveProps(nextProps) {}

  resetModal = () => {
    this.setState({
      number: "",
      phonetypeid: 1,
      phone_err: "",
      phonetype_err: "",
      isValid: false,
    });
  };
  handleClose = () => {
    this.props.onClose();
    this.setState({
      showModal: false,
    });
    this.resetModal();
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onShow = () => {};

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validatePhone = (phone) => {
    let PhoneReg = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    const Phone_States = [
      {
        state: "Valid",
        error: "",
      },
      {
        state: "Null",
        error: "Please provide your Phone.",
      },
      {
        state: "Not_Match_To_Regex",
        error: "Please provide valid Phone.",
      },
    ];
    if (phone == "") {
      return Phone_States.find(({ state }) => state == "Null");
    } else if (PhoneReg.test(phone)) {
      return Phone_States.find(({ state }) => state == "Valid");
    } else {
      return Phone_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };

  handleChangePhone = (e) => {
    this.setState({ number: e.target.value });
    let PhoneReg = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
    if (e.target.value === "(___) ___-____" || e.target.value === "") {
      this.setState({ phone_err: "Required Field" });
      this.setState({ isValid: false });
    } else if (PhoneReg.test(e.target.value)) {
      this.setState({ phone_err: "" });
      this.setState({ isValid: true });
    } else {
      this.setState({ phone_err: "Enter valid phone" });
      this.setState({ isValid: false });
    }
  };

  handleFormSubmit = (e) => {
    if (this.state.isValid === false) {
      let PhoneReg = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
      if (this.state.number === "(___) ___-____" || this.state.number === "") {
        this.setState({ phone_err: "Required Field" });
        this.setState({ isValid: false });
      } else if (PhoneReg.test(this.state.number)) {
        this.setState({ phone_err: "" });
        this.setState({ isValid: true });
      } else {
        this.setState({ phone_err: "Enter valid phone" });
        this.setState({ isValid: false });
      }
      return false;
    }
    const body = {
      number: this.state.number,
      phonetypeid: this.state.phonetypeid,
    };

    let Url = "/api/Member/CreatePhone?memberid=" + this.props.memberid;
    this.showSpinner();
    axios.post(Url, body).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          toast.success(data.message);
          this.props.refreshData(this.props.memberid);
          this.handleClose();
        } else {
          this.hideSpinner();
          toast.error(data.message);
          this.handleClose();
        }
      } else {
        toast.error("Could not complete the opertaion.");
      }
    });
  };
  getPhoneValidationError = () => {
    return <span className="text-danger">{this.state.phone_err}</span>;
  };

  render() {
    const { phoneTypes } = this.props;
    return (
      <AUX>
        <Modal
          className="PhonesModal latest"
          show={this.props.showModal}
          onHide={this.handleClose}
          centered
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                ADD PHONE{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="row mb-4">
                    <div className="col-11 row">
                      <div className="col-sm-8">
                        <div>
                          <div class="md-form form-group mb-0">
                            <InputMask
                              value={this.state.number}
                              onChange={this.handleChangePhone}
                              id="number"
                              name="number"
                              disabled={
                                !AuthService.canOwnAdd(
                                  PermissionFeature.ContactPhones,
                                  this.props.memberid
                                ) &&
                                !AuthService.canMemberAdd(
                                  PermissionFeature.ContactPhones
                                )
                              }
                              mask="(999) 999-9999"
                              className="form-control"
                              type="text"
                              placeholder="Number"
                              //pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                              //type="tel"
                              //required="Phone No. is required"
                            ></InputMask>
                            {this.getPhoneValidationError()}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="align-self-center">
                          <label>Phone Type</label>
                          <select
                            className="browser-default custom-select"
                            onChange={this.handleSelectChange}
                            name="phonetypeid"
                            value={this.state.phonetypeid}
                            //data-id={idx}
                            required
                            data-obj="phones"
                            disabled={
                              !AuthService.canOwnAdd(
                                PermissionFeature.ContactPhones,
                                this.props.memberid
                              ) &&
                              !AuthService.canMemberAdd(
                                PermissionFeature.ContactPhones
                              )
                            }
                          >
                            <div className="invalid-feedback">
                              Phone Type is required.
                            </div>
                            {phoneTypes.map((item1, idx) => (
                              <>
                                <option
                                  value={item1.id}
                                  key={idx}
                                  // {`${item1.phonetypeid === this.state.phonetypeid }`
                                >
                                  {item1.phonetype}
                                </option>
                              </>
                            ))}
                          </select>
                          <span className="text-danger">
                            {this.state.phonetype_err}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 text-centered d-flex justify-content-center">
                  <button
                    className="btn btn-sm btn-danger w-md waves-effect waves-light"
                    type="button"
                    id="phonePopupCloseBtn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>

                  <button
                    onClick={this.handleFormSubmit}
                    className="btn btn-success btn-sm ml-2 w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default PhonesModal;
