import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import PACHeader from "./General/PACHeader";
import Services from "./PACService";
import AuthService from "../../modules/Auth/AuthService";
import PermissionFeature from "../../modules/Permissions/PermissionFeature";
import CandidateDelailModal from "./Candidate/CandidateDetailModal";
import RequestModal from "./Request/RequestModal";
import RequestDetailModal from "./Request/RequestDetailModal";
import "../../assets/css/PacStyle.css";
import { connect } from "react-redux";
import PACTrackerAction from "../../store/pacTracker/action";
import moment from "moment";
import DeleteModal from "../Dashboard/FacilityDashboard/Modals/DeleteModal";

class PACTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includeClosed: false,
      includeClosedTitle: "Show Closed",
      myRequestData: [],
      myRequestViewer: [],
      positionsOptions: [],
      selectAll: false,
      searchTerm: "",
      sizePerPage: 10,
      requestType: this.props.pacState.ReviewRequestType.Requester,
      isConfirmModalOpen: false,
      delId: 0,
      isNotificationCall: false,
      currentRequestId: 0,
      identificationNum: Math.random(),
    };
    this.rowClasses = (row, rowIndex) => {
      let classes = null;

      if (row.status == "Draft") {
        classes = "bg-light-blue";
      }

      return classes;
    };
    this.setReloader = this.setReloader.bind(this);
    this.formate_amount = this.formate_amount.bind(this);
    this.filterMyRequest = this.filterMyRequest.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.SizePerPageChange = this.SizePerPageChange.bind(this);
    this.getColumn = this.getColumn.bind(this);
    this.getMyRequests = this.getMyRequests.bind(this);
    this.getMyRequests();
    this.viewAllLink = this.viewAllLink.bind(this);
    this.deleteRequest = this.deleteRequest.bind(this);
    this.deleteRequestCall = this.deleteRequestCall.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.toggleClosedRequests = this.toggleClosedRequests.bind(this);

    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        this.deleteRequestCall();

        this.setState({ delId: 0 });
        this.toggleConfirmModal();
      };
      return this;
    };
  }

  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  toggleClosedRequests = () => {
    var title = this.state.includeClosedTitle;
    if (this.state.includeClosed) {
      title = "Show Closed";
      this.state.includeClosed = false;
    } else {
      title = "Hide Closed";
      this.state.includeClosed = true;
    }
    this.setState({
      includeClosed: this.state.includeClosed,
      includeClosedTitle: title,
    });

    this.getMyRequests();
  };

  deleteRequest = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      delId: id,
    });
    this.toggleConfirmModal();
  };

  async deleteRequestCall() {
    $(".loading").show();
    let data = await Services.DeleteRequest(this.state.delId);

    if (!_.isEmpty(data)) {
      $(".loading").hide();
      this.getMyRequests();
    }
    $(".loading").hide();
  }
  viewAllLink = (data) => {
    this.setState({ sizePerPage: data.length });
  };
  formate_amount = (n) => {
    var num_parts = n.toString().split(".");
    num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };
  async getMyRequests() {
    $(".loading").show();
    let data = await Services.GetMyRequests(this.state.includeClosed);
    if (!_.isEmpty(data)) {
      this.setState({
        myRequestData: data.data,
      });
    }
    $(".loading").hide();
    $("#tableRow").removeClass("d-none");
  }
  getColumn() {
    return [
      {
        dataField: "candidateName",
        text: "Candidate Name",
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row, rowIndex /*, extraData*/) => {
          return (
            <div>
              {AuthService.canView(PermissionFeature.Candidates) ? (
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.props.openCandidateDetailModal({
                      candidateId: row.candidateId,
                      candidateModalType: 2,
                    });
                  }}
                >
                  {row.candidateName}
                </a>
              ) : (
                row.candidateName
              )}
            </div>
          );
        },
      },
      {
        text: "Event Date",
        dataField: "eventDate",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          return (
            <div>
              {moment(row.eventDate).isValid()
                ? moment(row.eventDate).format("MM/DD/YYYY")
                : ""}
            </div>
          );
        },
      },
      {
        text: "State",
        dataField: "state",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
      },
      {
        text: "Event Name",
        dataField: "eventName",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
      },
      {
        isDummyField: true,
        text: "Amount",
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "pl-3 pr-2 py-2",
        formatter: (cellContent, row) => {
          return (
            <div>
              {!row.approvedamount && this.formate_amount(row.requestedamount)}
              {row.approvedamount && this.formate_amount(row.approvedamount)}
            </div>
          );
        },
      },
      {
        text: "Status",
        dataField: "status",
        headerClasses: "px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          if (row.status == "CheckIssued") return "Check Issued";
          else return row.status;
        },
      },
      {
        isDummyField: true,
        headerClasses: "text-nowrap px-sm-3 px-2 py-2",
        classes: "py-2",
        formatter: (cellContent, row) => {
          return (
            <div className="text-center">
              {AuthService.canDelete(PermissionFeature.Requests) &&
                row.status == "Draft" && (
                  <span
                    className="text-danger fas fa-trash"
                    onClick={(e) => {
                      this.deleteRequest(e, row.id);
                    }}
                  />
                )}
            </div>
          );
        },
      },
    ];
  }

  filterMyRequest() {
    return this.state.myRequestData.filter((x) =>
      _.toLower(x.candidateName).includes(
        _.toLower(this.state.searchTerm || "")
      )
    );
  }

  componentDidMount() {
    //this.props.history.push("/PACTracker");
    ///Incase notificaiton is clicked
    const id = this.props.location.state ? this.props.location.state.reqID : "";
    if (
      !this.state.isNotificationCall &&
      !_.isEmpty(id) &&
      this.state.currentRequestId != id
    ) {
      var notificationCall = this.props.location.state
        ? this.props.location.state.notificationCall
        : false;
      const identificationNum = this.props.location.state
        ? this.props.location.state.identificationNum
        : Math.random();

      if (notificationCall) {
        this.setState({
          isNotificationCall: true,
          currentRequestId: id,
          identificationNum: identificationNum,
        });
        this.showMyRequest(id);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const id = this.props.location.state ? this.props.location.state.reqID : "";
    const identificationNum = this.props.location.state
      ? this.props.location.state.identificationNum
      : Math.random();

    if (!_.isEmpty(id) && this.state.identificationNum != identificationNum) {
      var notificationCall = this.props.location.state
        ? this.props.location.state.notificationCall
        : false;
      if (notificationCall) {
        this.setState({
          isNotificationCall: true,
          currentRequestId: id,
          identificationNum: identificationNum,
        });
        this.showMyRequest(id);
      }
    }
  }

  showMyRequest = async (id) => {
    $(".loading").show();
    let data = await Services.GetReviewRequestDetail(id);
    let status = !_.isEmpty(data.data) ? data.data.status : "";
    $(".loading").hide();

    switch (status) {
      case "Draft":
        this.props.openRequestModal({
          requestId: id,
          requestType: "Draft",
        });
        return;
      case "Submitted":
        this.props.openRequestModal({
          requestId: id,
          requestType: "Submitted",
        });
        return;
      case "Returned":
        this.props.openRequestModal({
          requestId: id,
          requestType: "Returned",
        });
        return;
      case "Supported":
        if (AuthService.canUpdate(PermissionFeature.ReviewRequests)) {
          this.props.openRequestModal({
            requestId: id,
            requestType: "Supported",
          });
        } else {
          this.props.openRequestDetailModal({
            reviewRequestId: id,
            type: this.state.requestType,
          });
        }
        return;
      default:
        this.props.openRequestDetailModal({
          reviewRequestId: id,
          type: this.state.requestType,
        });
        return;
    }
  };

  setReloader() {
    this.setState({ reloadEverything: true });
  }

  rowEvents = {
    onClick: (e, row, rowIndex) => {
      e.preventDefault();
      e.stopPropagation();
      switch (row.status) {
        case "Draft":
          this.props.openRequestModal({
            requestId: row.id,
            requestType: "Draft",
          });
          return;
        case "Submitted":
          if (!row.isrecallfromsubmitted) {
            this.props.openRequestDetailModal({
              reviewRequestId: row.id,
              type: this.state.requestType,
            });
          } else {
            this.props.openRequestModal({
              requestId: row.id,
              requestType: "Submitted",
            });
          }

          return;
        case "Returned":
          this.props.openRequestModal({
            requestId: row.id,
            requestType: "Returned",
          });
          return;
        case "Supported":
          this.props.openRequestDetailModal({
            reviewRequestId: row.id,
            type: this.state.requestType,
          });
          return;
        default:
          this.props.openRequestDetailModal({
            reviewRequestId: row.id,
            type: this.state.requestType,
          });
          return;
      }
    },
  };
  SizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  RemotePagination(
    columns,
    keyFields,
    data,
    SizePerPageChange,
    sizePerPage,
    rowClasses
  ) {
    let paginationOptions = {
      totalSize: data && data.length,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
    };
    return (
      <div>
        <BootstrapTable
          keyField={keyFields}
          data={data}
          columns={columns}
          className="table"
          pagination={
            data.length > sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          rowClasses={rowClasses}
          rowEvents={this.rowEvents}
        />
        {data.length > sizePerPage && (
          <a
            href="#"
            className="pl-1"
            onClick={(e) => {
              e.preventDefault();
              this.viewAllLink(data);
            }}
          >
            View All
          </a>
        )}
      </div>
    );
  }
  render() {
    const columns = this.getColumn();

    const { myRequestData } = this.state;
    const showTotal = true;
    return (
      <AUX>
        <PACHeader />
        <div className="pac-container container-fluid">
          <div className="page-title-box">
            <div className="row pt-3 align-items-center">
              <div className="col-12">
                <h4 className="text-uppercase mt-0 mb-3 page-title-search">
                  My Request
                </h4>
              </div>
              <div className="col-lg-6">
                <form role="search" className="app-search mt-0">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      id="keyword"
                      className="form-control rounded border mb-1 w-100"
                      placeholder="Search By Candidate Name.."
                      onChange={(event) =>
                        this.setState({ searchTerm: event.target.value })
                      }
                    />
                    <button
                      type="button"
                      className="c-pointer text-secondary bg-transparent border-0"
                      onClick={(event) =>
                        this.setState({ searchTerm: $("#keyword").val() })
                      }
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row d-none" id="tableRow">
            <div className="col-12">
              <div className="cursorPointer hover pb-2 text-right">
                <a onClick={this.toggleClosedRequests}>
                  {this.state.includeClosedTitle}
                </a>
              </div>
              {!_.isEmpty(this.filterMyRequest()) ? (
                this.RemotePagination(
                  columns,
                  "id",
                  this.filterMyRequest(),
                  this.SizePerPageChange,
                  this.state.sizePerPage,
                  this.rowClasses
                )
              ) : (
                <p className="mt-4 text-center">No Request Are Available</p>
              )}
            </div>
          </div>
        </div>
        <CandidateDelailModal history={this.props.history} />
        <RequestDetailModal getMyRequests={this.getMyRequests} />
        <RequestModal getMyRequests={this.getMyRequests} />
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </AUX>
    );
  }
}
export default connect(
  (state) => ({ pacState: state.pacTracker }),
  {
    ...PACTrackerAction,
  }
)(PACTracker);
