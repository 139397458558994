import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import "../../../assets/css/TeamStyle.css";
import _ from "lodash";
import Services from "../PACService";
import { connect } from "react-redux";
import {
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import PACTrackerAction from "../../../store/pacTracker/action";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";

class AddCandidateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalHeading: "AddNewCandidate",
      modalHeadingLabel: "Add New Candidate",
      candidateId: 0,
      candidateName: "",
      addressState: "",
      addressDistrict: "",
      candidateParty: "",
    };
    this.openModal = this.openModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.SaveCandidate = this.SaveCandidate.bind(this);
  }
  componentWillUnmount() {}
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.pacState.openAddCandidateModal) {
      if (nextProps.pacState.editCandidateData) {
        let data = nextProps.pacState.editCandidateData;
        this.setState({
          modalHeadingLabel: "Edit Candidate",
          candidateId: data.id,
          candidateName: data.name,
          candidateParty: data.party,
          addressState: data.state,
          addressDistrict: data.district,
        });
      }
      this.openModal();
    }
  }

  openModal() {
    window.$("#" + this.state.modalHeading).modal("show");
    this.props.closeAddCandidateModal();
  }

  cancelModal() {
    window.$("#" + this.state.modalHeading).modal("hide");

    this.resetModal();
  }

  resetModal() {
    this.setState({
      candidateId: 0,
      candidateName: "",
      addressState: "",
      addressDistrict: "",
      candidateParty: "",
      modalHeadingLabel: "Add New Candidate",
    });
    $("form").removeClass("was-validated");
  }

  changeHandler = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleFormSubmit = (evt) => {
    evt.preventDefault();

    evt.target.className += " was-validated";
    if (evt.target.checkValidity() === false) {
      return false;
    }

    const formdata = {
      id: this.state.candidateId,
      name: this.state.candidateName,
      state: this.state.addressState,
      district: this.state.addressDistrict,
      party: this.state.candidateParty,
    };

    this.SaveCandidate(formdata);
  };

  async SaveCandidate(formdata) {
    $(".loading").show();
    let data = await Services.SaveCandidate(formdata);
    if (!_.isEmpty(data)) {
      toast.success(data.message);
      this.props.getCandidates();
      this.cancelModal();
    }
    $(".loading").hide();
  }

  render() {
    return (
      <AUX>
        <div
          class="modal fade"
          id={this.state.modalHeading}
          tabindex="-1"
          role="dialog"
          aria-labelledby={this.state.modalHeading + "Title"}
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content">
              <div class="modal-header bg-secondary py-2">
                <h5 class="modal-title text-uppercase text-white mt-0">
                  {this.state.modalHeadingLabel}
                </h5>

                <button
                  type="button"
                  class="close text-white"
                  onClick={this.cancelModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form
                className="form-horizontal needs-validation"
                onSubmit={this.handleFormSubmit}
                noValidate
              >
                <div className="modal-body pac-form">
                  <div className="container-fluid m-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <MDBInput
                          name="candidateName"
                          containerClass="mb-0"
                          value={this.state.candidateName}
                          onChange={this.changeHandler}
                          label="Candidate Name"
                          disabled={
                            !AuthService.canUpdate(
                              PermissionFeature.CandidateName
                            )
                          }
                          required
                          group
                          type="text"
                        >
                          <div className="invalid-feedback">
                            Candidate Name is required.
                          </div>
                        </MDBInput>
                      </div>

                      <div className="col-sm-4">
                        <MDBInput
                          name="addressState"
                          containerClass="mb-0"
                          value={this.state.addressState}
                          onChange={this.changeHandler}
                          label="State"
                          disabled={
                            !AuthService.canUpdate(
                              PermissionFeature.CandidateState
                            )
                          }
                          required
                          group
                          type="text"
                        >
                          <div className="invalid-feedback">
                            State is required.
                          </div>
                        </MDBInput>
                      </div>

                      <div className="col-sm-4">
                        <MDBInput
                          name="addressDistrict"
                          containerClass="mb-0"
                          value={this.state.addressDistrict}
                          onChange={this.changeHandler}
                          disabled={
                            !AuthService.canUpdate(
                              PermissionFeature.CandidateDistrict
                            )
                          }
                          label="District"
                          group
                          required
                          type="text"
                        >
                          <div className="invalid-feedback">
                            District is required.
                          </div>
                        </MDBInput>
                      </div>

                      <div className="col-sm-4">
                        <MDBInput
                          name="candidateParty"
                          containerClass="mb-0"
                          value={this.state.candidateParty}
                          onChange={this.changeHandler}
                          disabled={
                            !AuthService.canUpdate(
                              PermissionFeature.CandidateParty
                            )
                          }
                          label="Party"
                          group
                          required
                          type="text"
                        >
                          <div className="invalid-feedback">
                            Party is required.
                          </div>
                        </MDBInput>
                      </div>
                    </div>
                  </div>
                  <hr className="mb-3" />

                  <div className="row">
                    <div className="col-sm-8">
                      <div className="float-left text-left">
                        <button
                          className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                          type="submit"
                          //onClick={() => this.handleFormSubmit()}
                          //onClick={this.handleFormSubmit}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-danger btn-sm w-md waves-effect waves-light"
                          type="button"
                          onClick={this.cancelModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default connect((state) => ({ pacState: state.pacTracker }), {
  ...PACTrackerAction,
})(AddCandidateModal);
