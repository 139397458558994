import React, { Component, Fragment } from "react";
import moment from "moment";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import PopoverItem from "../../../Shared/PopoverItem";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import _ from "lodash";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import JobHistoryFormModal from "./JobHistoryFormModal";
import ConfirmDeleteModal from "../../../Payfile/Two/General/DeleteModal";
import Services from "./EditUserService";
class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      ActiveIndex: 0,
      animating: false,
      popoverOpen: false,
      deleteModal: false,
      deleteJobId: -1,
      openJobHistoryFormModal: false,
      jobFormHeading: "",
    };

    //popover binding
    this.togglePopover = this.togglePopover.bind(this);
    // carousel data binding
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
  }

  //popover
  togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };
  //Carousel methods
  next = () => {
    this.setState({ popoverOpen: false });
    if (this.state.animating) return;
    const nextIndex =
      this.state.ActiveIndex === this.state.jobs.length - 1
        ? 0
        : this.state.ActiveIndex + 1;

    this.setState({ ActiveIndex: nextIndex });
  };
  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.jobs) != JSON.stringify(prevProps.jobs)) {
      this.setState({ jobs: this.props.jobs });
    }
  }
  componentDidMount() {
    this.setState({ jobs: this.props.jobs });
  }
  previous = () => {
    this.setState({ popoverOpen: false });
    if (this.state.animating) return;
    const nextIndex =
      this.state.ActiveIndex === 0
        ? this.state.jobs.length - 1
        : this.state.ActiveIndex - 1;
    this.setState({ ActiveIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setState({ ActiveIndex: newIndex });
  };
  render() {
    return (
      <>
        {!_.isEmpty(this.state.jobs) && (
          <div className="card directory-card border-rounded shadow">
            <Carousel
              activeIndex={this.state.ActiveIndex}
              next={this.next}
              className="jobcarousel"
              previous={this.previous}
              interval={false}
            >
              <CarouselIndicators
                items={this.state.jobs}
                activeIndex={this.state.ActiveIndex}
                onClickHandler={this.goToIndex}
              />
              {this.state.jobs.map((item, index) => {
                return (
                  <CarouselItem
                    className="custom-tag"
                    tag="div"
                    key={item.id}
                    onExiting={() => this.setState({ animating: true })}
                    onExited={() => this.setState({ animating: false })}
                  >
                    <div class="carousel-item active">
                      <h5 className="card-header mt-0">
                        <i className="fas fa-building mr-2" />
                        {(AuthService.canOwnView(
                          PermissionFeature.FacilityDescription,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FacilityDescription
                          )) &&
                                    (item.facility && item.facility.description) && item.facility.description}
                        {(AuthService.canOwnView(
                          PermissionFeature.FacilityDescription,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FacilityDescription
                          )) &&
                          (AuthService.canOwnView(
                            PermissionFeature.FacilityCode,
                            this.props.memberid
                          ) ||
                            AuthService.canMemberView(
                              PermissionFeature.FacilityCode
                                        )) &&
                                    item.facility != null &&
                          item.facility.code != null &&
                          item.facility.code != "" &&
                          item.facility.description != null &&
                          item.facility.description != "" &&
                          " - "}
                        {(AuthService.canOwnView(
                          PermissionFeature.FacilityCode,
                          this.props.memberid
                        ) ||
                          AuthService.canMemberView(
                            PermissionFeature.FacilityCode
                          )) &&
                                    (item.facility && item.facility.code) && item.facility.code}
                      </h5>
                      <div className="card-body pb-5 pt-1">
                        <div>
                          {/*Current Member Widget*/}
                          <div className="border-bottom mb-3">
                            <h6>
                              <span className="text-dark">
                                {moment(item.startdate).isValid()
                                  ? moment(item.startdate).format("MM/DD/YYYY")
                                  : "UNKNOWN"}{" "}
                                -{" "}
                                {item.enddate != null && item.enddate != ""
                                  ? moment(item.enddate).isValid() &&
                                    moment(item.enddate).format("MM/DD/YYYY")
                                  : item.jobstatus == "Active"
                                  ? "PRESENT"
                                  : ""}
                              </span>
                              <span className="float-right font-weight-normal">
                                <span
                                  data-toggle="popover"
                                  className="text-success"
                                  id={"PopoverClick" + index}
                                >
                                  <i class="fas fa-info-circle"></i>
                                </span>
                              </span>
                              <PopoverItem
                                key={index}
                                item={item}
                                index={index}
                                activeIndex={this.state.ActiveIndex}
                              />
                            </h6>
                          </div>

                          <div className="clearfix" />
                          {/*Address, Phone, Allow Call*/}

                          <div id="JobInline">
                            {(AuthService.canOwnView(
                              PermissionFeature.FinancialOrganizationalCode,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.FinancialOrganizationalCode
                              ) ||
                              AuthService.canOwnView(
                                PermissionFeature.FinancialOrganizationalTitle,
                                this.props.memberid
                              ) ||
                              AuthService.canMemberView(
                                PermissionFeature.FinancialOrganizationalTitle
                              )) && (
                              <div className="py-1 row">
                                <span className="text-right mt-0 col-4 card-subtitle text-muted">
                                  <strong>Organization: </strong>
                                </span>
                                <span className="card-text col-8">
                                  {(AuthService.canOwnView(
                                    PermissionFeature.FinancialOrganizationalCode,
                                    this.props.memberid
                                  ) ||
                                    AuthService.canMemberView(
                                      PermissionFeature.FinancialOrganizationalCode
                                    )) &&
                                    (item.organizationcode != null &&
                                    item.organizationcode != ""
                                      ? item.organizationcode
                                      : "NONE")}
                                  {/*
                                                                        {item.organization != null &&
                                                                            (AuthService.canOwnView(
                                                                                PermissionFeature.FinancialOrganizationalCode,
                                                                                this.props.memberid
                                                                            ) ||
                                                                                AuthService.canMemberView(
                                                                                    PermissionFeature.FinancialOrganizationalCode
                                                                                )) &&
                                                                            (AuthService.canOwnView(
                                                                                PermissionFeature.FinancialOrganizationalTitle,
                                                                                this.props.memberid
                                                                            ) ||
                                                                                AuthService.canMemberView(
                                                                                    PermissionFeature.FinancialOrganizationalTitle
                                                                                )) &&
                                                                            " - "}

                                                                        {(AuthService.canOwnView(
                                                                            PermissionFeature.FinancialOrganizationalTitle,
                                                                            this.props.memberid
                                                                        ) ||
                                                                            AuthService.canMemberView(
                                                                                PermissionFeature.FinancialOrganizationalTitle
                                                                            )) &&
                                                                            item.organization != null &&
                                                                            (item.organization.description != null &&
                                                                                item.organization.description != ""
                                                                                ? item.organization.description
                                                                                : "NONE")}
                                                                                */}
                                </span>
                              </div>
                            )}
                            {(AuthService.canOwnView(
                              PermissionFeature.FinancialUnit,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.FinancialUnit
                              )) && (
                              <div className="py-1 row">
                                <span className="card-subtitle col-4 text-right mt-0 text-muted">
                                  <strong>Unit: </strong>
                                </span>
                                <span className="card-text col-8">
                                  {item.busto == null || item.busto == ""
                                    ? "none"
                                    : item.busto}
                                </span>
                              </div>
                            )}
                            {(AuthService.canOwnView(
                              PermissionFeature.FinancialDutyStationDescription,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.FinancialDutyStationDescription
                              ) ||
                              AuthService.canOwnView(
                                PermissionFeature.FinancialDutyStationCode,
                                this.props.memberid
                              ) ||
                              AuthService.canMemberView(
                                PermissionFeature.FinancialDutyStationCode
                              )) && (
                              <div className="py-1 row">
                                <span className="card-subtitle mt-0 col-4 text-right text-muted">
                                  <strong>Duty Station: </strong>
                                </span>
                                <span className="card-text col-8">
                                  {(AuthService.canOwnView(
                                    PermissionFeature.FinancialDutyStationCode,
                                    this.props.memberid
                                  ) ||
                                    AuthService.canMemberView(
                                      PermissionFeature.FinancialDutyStationCode
                                    )) &&
                                    (item.dutystationcode != null &&
                                    item.dutystationcode != ""
                                      ? item.dutystationcode
                                      : "NONE")}
                                  {(AuthService.canOwnView(
                                    PermissionFeature.FinancialDutyStationDescription,
                                    this.props.memberid
                                  ) ||
                                    AuthService.canMemberView(
                                      PermissionFeature.FinancialDutyStationDescription
                                    ) ||
                                    AuthService.canOwnView(
                                      PermissionFeature.FinancialDutyStationCode,
                                      this.props.memberid
                                    ) ||
                                    AuthService.canMemberView(
                                      PermissionFeature.FinancialDutyStationCode
                                    )) &&
                                    " - "}
                                  {(AuthService.canOwnView(
                                    PermissionFeature.FinancialDutyStationDescription,
                                    this.props.memberid
                                  ) ||
                                    AuthService.canMemberView(
                                      PermissionFeature.FinancialDutyStationDescription
                                    )) &&
                                  item.dutystationdescription != null &&
                                  item.dutystationdescription != ""
                                    ? item.dutystationdescription
                                    : "NONE "}
                                </span>
                              </div>
                            )}
                            {(AuthService.canOwnView(
                              PermissionFeature.FinancialFacilityAddressRoutingSymbol,
                              this.props.memberid
                            ) ||
                              AuthService.canMemberView(
                                PermissionFeature.FinancialFacilityAddressRoutingSymbol
                              )) && (
                              <div className="row py-1">
                                <span className="card-subtitle col-4 text-right mt-0 text-muted">
                                  <strong>Routing Symbol: </strong>
                                </span>
                                <span className="card-text col-8">
                                  {item.facilityroutingsymbolfrom != null &&
                                  item.facilityroutingsymbolfrom != ""
                                    ? item.facilityroutingsymbolfrom
                                    : "NONE"}
                                  {" - "}
                                  {item.facilityroutingsymbolto != null &&
                                  item.facilityroutingsymbolto != ""
                                    ? item.facilityroutingsymbolto
                                    : "NONE "}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CarouselItem>
                );
              })}
              <CarouselControl
                cssModule="fas fa-chevron-right"
                direction="prev"
                directionText="Previous"
                onClickHandler={this.previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={this.next}
              />
            </Carousel>
          </div>
        )}
      </>
    );
  }
}

export default Jobs;
