import React, { Component } from "react";
import _ from "lodash";
import AUX from "../../hoc/Aux_";
import $ from "jquery";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "../Shared/auth-header";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Accordion, Card, Button, Dropdown } from "react-bootstrap";
import TableCustomTotal from "../Shared/TableCustomTotal";
import EmailListMemType from "../Email/Enums/EmailListMemType";
import { Spinner } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import mailGunEmailActions from "../../store/mailgunEmails/action";
import { connect } from "react-redux";
import MG_ReSubscriberModal from "../Email/ModalPopups/MG_ReSubscriberModal";
import { toast } from "react-toastify";
import RemoveActionType from "../Email/Enums/RemoveActionType";
import { Link } from "react-router-dom";
import "bootstrap4-toggle";
import "bootstrap4-toggle/css/bootstrap4-toggle.min.css";
import MailGunEmailTypes from "../Email/Enums/MailGunEmailTypes";
const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
class MailGunMembersList extends Component {
  constructor(props) {
    super(props);
    let pagingModel = {
      currentPage: 1,
      pageSize: 25,
      orderBy: "name",
      sortOrder: "asc",
    };

    this.state = {
      toggleBtnId: "",
      allDefaultMembers: [],
      loadingUnSubs: false,
      loadingSubs: false,
      loadingNonNatcaUnSubs: false,
      loadingAdditionalTeamUnSubs: false,
      //mgListAddress: props.mgListAddress,
      subscribers: [],
      totalSubscribers: 0,
      unsubscribers: [],
      totalUnsubscribers: 0,
      nonNatcaUnSubscribers: [],
      additionalTeamUnSubscribers: [],
      totalNonNatcaUnsubscribers: 0,
      totalAdditionalTeamUnSubscribers: 0,
      currentPage: 1,
      pageSize: 25,
      sortOrder: "asc",
      orderBy: "name",
      listPaging: {
        sub: { ...pagingModel },
        unsub: { ...pagingModel },
      },
      columns: [
        {
          text: "Name",
          headerAttrs: {
            width: "5%",
          },
          dataField: "name",
          sort: true,
        },
        {
          text: "Email",
          headerAttrs: {
            width: "5%",
          },
          dataField: "address",
          sort: true,
        },
        {
          text: "Facility",
          headerAttrs: {
            width: "5%",
          },
          dataField: "facilityRegion",
          sort: false,
        },
      ],
      teamColumns: [{ text: "Name", dataField: "teamName", sort: true }],
      paginationSettings: {
        showTotal: true,
      },
      subSearchText: "",
      unsubSearchText: "",
      nonNatcaUnsubSearchText: "",
      toggleUNSUB: false,
      toggleSUB: true,
      allDefaultSubscribers: [],
      allDefaultUnSubscribers: [],
      allDefaultNonNatcaUnSubscribers: [],
      allDefaultAdditionalTeamUnSubscribers: [],
      resubscribeEmail: "",
      showReSubscribeModal: false,
      unSubTeam: "",
      unSubTeamId: 0,
      teamMembers: [],
      teamMapId: 0,
      currentPage: 1,
    };
    this.confirmResubscribersModal = () => {
      this.confirmed = () => {
        this.showSpinner();
        const { resubscribeEmail, removeActionType } = this.state;
        const { mgListAddress } = this.props;
        let url = "";
        if (removeActionType == RemoveActionType.UnBlock) {
          url = `/api/MailGun/UnBlockMember?mailAddress=${mgListAddress}&email=${resubscribeEmail}`;
        } else {
          url = `/api/MailGun/ResubscribeMember?mailAddress=${mgListAddress}&email=${resubscribeEmail}`;
        }
        axios
          .put(url)
          .then(({ data: resp }) => {
            if (resp.status.toLowerCase() == "success") {
              ///this.updateData();
              if (this.props.emailListMemType == EmailListMemType.Subscribers) {
                //Reload First Table i.e Subscribers
                const subscribersListRef = this.props.subscribersListRef
                  .current;
                subscribersListRef.resetData();
                subscribersListRef.reload();
              }

              // else if (
              //   this.props.emailListMemType ==
              //   EmailListMemType.NonNatcaUnSubscribers
              // ) {
              //   const nonNatcaUnsubscribersListRef = this.props
              //     .nonNatcaUnsubscribersListRef.current;
              //   nonNatcaUnsubscribersListRef.resetData();
              //   nonNatcaUnsubscribersListRef.reload();
              // }

              //Reload All Additional Subscriber
              //Tables(Addtl=> Members+Teams+NonNatca) on Subscribers Tabs
              this.props.reloadAdditionalSubsribersTables();
              this.toggleResubscribersModal();
            } else {
              toast.error("Something went wrong!");
            }
          })

          .catch((err) => {
            toast.error("Something went wrong!");
            this.hideSpinner();
          })
          .finally(() => {
            this.hideSpinner();
          });
      };

      return this;
    };

    this.confirmResubscribersModalForTeams = () => {
      this.confirmed = () => {
        this.showSpinner();
        let apiParams = {
          mailingListAddress: this.props.mgListAddress,
          TeamId: this.state.unSubTeamId,
          teamMembers: this.state.teamMembers,
          teamMapId: this.state.teamMapId,
        };
        let url = `/api/MailGun/ResubscribeTeam`;
        axios
          .put(url, apiParams)
          .then(({ data: resp }) => {
            if (resp.status.toLowerCase() == "success") {
              ///this.updateData();

              //Reload First Table i.e Subscribers
              const additionalTeamUnsubscribersListRef = this.props
                .additionalTeamUnsubscribersListRef.current;
              additionalTeamUnsubscribersListRef.reload();
              //Reload All Additional Subscriber
              //Tables(Addtl=> Members+Teams+NonNatca) on Subscribers Tabs
              this.props.reloadAdditionalSubsribersTables();
              this.toggleResubscribersModal();
            } else {
              toast.error("Something went wrong!");
            }
          })

          .catch((err) => {
            toast.error("Something went wrong!");
            this.hideSpinner();
          })
          .finally(() => {
            this.hideSpinner();
          });
      };

      return this;
    };
  }

  componentDidMount = () => {
    this.getToggleBtnId();
    //this.initToggles();
  };
  initToggles() {
    const { toggleBtnId } = this.state;
    window.$("#" + toggleBtnId).bootstrapToggle();
    window.$("#" + toggleBtnId).change(this.handleToggleChange);
  }
  resetData = () => {
    if (this.props.emailListMemType == EmailListMemType.Subscribers) {
      this.setState({ subscribers: [] });
    } else if (this.props.emailListMemType == EmailListMemType.UnSubscribers) {
      this.setState({ unsubscribers: [] });
    } else if (
      this.props.emailListMemType == EmailListMemType.NonNatcaUnSubscribers
    ) {
      this.setState({ nonNatcaUnSubscribers: [] });
    }
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleClose = () => {
    this.props.onModalClose();
  };

  toggleResubscribersModal = () => {
    this.setState({
      showReSubscribeModal: !this.state.showReSubscribeModal,
    });
  };

  handleToggleChange = (e) => {
    let showUnsubscribers = e.target.checked;
    this.toggleUnSubscribers(showUnsubscribers);
  };
  renderSubscribed = (
    subscriberList,
    page,
    sizePerPage,
    loading,
    showReSubscribeModal,
    shrinkActionCol,
    isModalPopUpOpended,
    emailEntityType
  ) => {
    let {
      hideActionColumn,
      confirmDeleteMember,
      setCurrentSenderInfo,
      keyIdfr,
    } = this.props;
    let columnsToRender = {};
    columnsToRender = [...this.state.columns];
    let actionCol = {
      text: "Action",
      hidden: !hideActionColumn,
      headerAttrs: {
        // width:
        //   (shrinkActionCol && !isModalPopUpOpended) ||
        //   (emailEntityType == MailGunEmailTypes.Facility && shrinkActionCol)
        //     ? "4%"
        //     : isModalPopUpOpended //For Team Modal PopUp
        //     ? "6%"
        //     : "18%",
        width: (() => {
          if (window.innerWidth < 1460) {
            if (emailEntityType == MailGunEmailTypes.Facility) {
              if (!isModalPopUpOpended) {
                return "6%"; // On Facility Dashboad Accordion
              } else if (shrinkActionCol) {
                return "6%"; // Inside Modal Popup
              }
            } else if (
              isModalPopUpOpended &&
              emailEntityType == MailGunEmailTypes.Team
            ) {
              //For Team Modal PopUp
              return "6%";
            } else {
              // For Saved Filters Subscribers
              return "12%";
            }
          } else if (window.innerWidth < 1560) {
            if (emailEntityType == MailGunEmailTypes.Facility) {
              if (!isModalPopUpOpended) {
                return "4%"; // On Facility Dashboad Accordion
              } else if (shrinkActionCol) {
                return "4.8%"; // Inside Modal Popup
              }
            } else if (
              isModalPopUpOpended &&
              emailEntityType == MailGunEmailTypes.Team
            ) {
              //For Team Modal PopUp
              return "6%";
            } else {
              // For Saved Filters Subscribers
              return "8%";
            }
          } else if (window.innerWidth > 1860) {
            // For Facility dashboard
            if (emailEntityType == MailGunEmailTypes.Facility) {
              if (!isModalPopUpOpended) {
                return "2.5%"; // On Facility Dashboad Accordion
              } else if (shrinkActionCol) {
                return "5%"; // Inside Modal Popup
              }
            } else if (
              isModalPopUpOpended &&
              emailEntityType == MailGunEmailTypes.Team
            ) {
              //For Team Modal PopUp
              return "6%";
            } else {
              // For Saved Filters Subscribers
              return "7.5%";
            }
          } else {
            return "5%";
          }
        })(),
      },
      formatter: (cell, row) => {
        return (
          <div className="mobileActions">
            <div className="actionIcons">
              {row && row.subscribed && (
                <button
                  type="button"
                  disabled={row.blocked}
                  className="btn btn-danger unsubscrActionBtn mr-1"
                  onClick={() => {
                    confirmDeleteMember(
                      row.id,
                      row.memberId,
                      keyIdfr, // Default Members
                      RemoveActionType.UnSubscribe
                    );
                    setCurrentSenderInfo(
                      row.firstName,
                      row.lastName,
                      row.memberId,
                      RemoveActionType.UnSubscribe,
                      row.name
                    );
                  }}
                >
                  Unsubscribe
                  <i
                    className="fa fa-bell-slash-o cursorPointer"
                    aria-hidden="true"
                    title="UnSubscribe"
                  ></i>
                </button>
              )}
              {row && !row.subscribed && (
                <button
                  type="button"
                  disabled={row.blocked}
                  className="btn btn-success unsubscrActionBtn mr-1"
                  onClick={() => {
                    showReSubscribeModal(
                      row,
                      keyIdfr,
                      RemoveActionType.ReSubscribe
                    );
                  }}
                >
                  Resubscribe
                  <i
                    className="fa fa-bell-o cursorPointer"
                    aria-hidden="true"
                    title="ReSubscribe"
                  ></i>
                </button>
              )}

              {row && !row.blocked && (
                <button
                  type="button"
                  className="btn btn-danger unsubscrActionBtn mr-1"
                  onClick={() => {
                    confirmDeleteMember(
                      row.id,
                      row.memberId,
                      keyIdfr, // Default Members
                      RemoveActionType.Block
                    );
                    setCurrentSenderInfo(
                      row.firstName,
                      row.lastName,
                      row.memberId,
                      RemoveActionType.Block,
                      row.name
                    );
                  }}
                >
                  Block
                  <i
                    className="fa fa-ban cursorPointer ml-1"
                    aria-hidden="true"
                    title="Block"
                  ></i>
                </button>
              )}
              {row && row.blocked && (
                <button
                  type="button"
                  className="btn btn-success unsubscrActionBtn mr-1"
                  onClick={() => {
                    showReSubscribeModal(
                      row,
                      keyIdfr,
                      RemoveActionType.UnBlock
                    );
                  }}
                >
                  UnBlock
                  <i
                    className="fa fa-bell-o cursorPointer ml-1"
                    aria-hidden="true"
                    title="UnBlock"
                  ></i>
                </button>
              )}

              <div className="action-def-mems">
                {/* Trash-Bin Icon */}
                <Link
                  to={{
                    pathname: "/directory/userAction/" + row.memberId,
                  }}
                  target="_blank"
                >
                  <i
                    className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
                    title="View Member"
                  ></i>
                </Link>
                {/* <i
                  title="Remove"
                  className="fas fa-trash-alt cursorPointer text-danger pl-2"
                  //style={{ marginLeft: "10%" }}
                  onClick={() => {
                    this.props.confirmDeleteMember(
                      row.id,
                      row.address,
                      EmailConfigTables.NonNatcaMembers,
                      RemoveActionType.Delete
                    );
                    this.props.setCurrentSenderInfo(row.firstName, row.lastName, row.memberId, RemoveActionType.Delete);
                  }}
                ></i> */}
              </div>
            </div>
          </div>
        );
      },
    };
    columnsToRender.push(actionCol);

    const Style = {
      overflow: "auto !important",
    };
    if (subscriberList != null) {
      const customTotal = (from, to, size) => (
        <span
          className="react-bootstrap-table-pagination-total"
          style={{ float: "left", marginTop: "3px", marginRight: "4px" }}
        >
          {from}
          {" - "}
          {to} of {size} {"   "}
          <b style={{ marginLeft: "4px" }}> Show: </b>
        </span>
      );
      let paginationOptions = {
        paginationTotalRenderer: TableCustomTotal,
        sizePerPageList: [
          {
            text: "10",
            value: 10,
          },
          {
            text: "25",
            value: 25,
          },
          {
            text: "30",
            value: 30,
          },
          {
            text: "50",
            value: 50,
          },
          {
            text: "All",
            value: subscriberList && subscriberList.length,
          },
        ],
        showTotal: true,
      };
      const defaultSorted = [
        {
          dataField: "address",
          order: "asc",
        },
      ];
      return (
        <div
          className="table-responsive scroller"
          ref={(node) => {
            if (node) {
              node.style.setProperty("min-height", "auto", "important");
            }
          }}
          key={this.props.emailListMemType}
        >
          <ToolkitProvider
            keyField={`address`}
            data={subscriberList}
            columns={columnsToRender}
          >
            {(props) => (
              <>
                <BootstrapTable
                  noDataIndication={() => {
                    return loading === false ? (
                      <NoDataIndication />
                    ) : (
                      <div className="d-flex justify-content-center p-4">
                        <Spinner />
                      </div>
                    );
                  }}
                  keyField="address"
                  caption={<h6 className="sub_tbl_caption">DEFAULT MEMBERS</h6>}
                  pagination={
                    subscriberList.length > sizePerPage
                      ? paginationFactory(paginationOptions)
                      : null
                  }
                  defaultSorted={defaultSorted}
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
      );
    }
  };

  // reSubscribe(row) {
  //   if (row.address) {
  //     this.setState({ resubscribeEmail: row.address });
  //     this.setState({ showReSubscribeModal: true });
  //   } else if (this.props.isTeamsTable) {
  //     this.setState({ unSubTeam: row.teamName });
  //     this.setState({ unSubTeamId: row.teamId });
  //     this.setState({ teamMembers: row.members });
  //     this.setState({ showReSubscribeModal: true });
  //     this.setState({ teamMapId: row.id });
  //   }
  // }
  showDuplicateIcon = () => {
    return (
      <i
        className="fa fa-files-o"
        aria-hidden="true"
        title="Duplicate Email"
      ></i>
    );
  };

  handleTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    let subSearchText = e.target.value;
    this.handleSearch(subSearchText);
  };

  handleSearch = (val) => {
    this.searchMembersList(val);
  };
  toggleUnSubscribers = (showUnsubVal) => {
    let { allDefaultSubscribers } = this.props.melstate;
    if (showUnsubVal) {
      let filteredData =
        allDefaultSubscribers.filter((x) => !x.subscribed || x.subscribed) ||
        [];
      this.props.setDefaultSubscribers(filteredData);
    } else {
      let filteredData =
        allDefaultSubscribers.filter((x) => x.subscribed) || [];
      this.props.setDefaultSubscribers(filteredData);
    }
    const { toggleBtnId } = this.state;
    $("#" + toggleBtnId)
      .prop("checked", showUnsubVal)
      .change();
  };
  searchMembersList = (val) => {
    // New Implementation, Search as per basic search conditions
    val = val.trimLeft();
    let { allDefaultSubscribers } = this.props.melstate;

    if (_.isEmpty(val)) {
      // If val is empty, show all members
      this.props.setDefaultSubscribers(allDefaultSubscribers);
    }
    else {
      if (!_.isEmpty(allDefaultSubscribers)) {
        ////////////////// Commented code is responsible to search as per member number, if required later
        ///////////////// Currenly not required, and also the member number is not available in mailgun's members list

        // var firstChar = val.charAt(0);

        // if (firstChar <= "9" && firstChar >= "0") {
        //   // If the first character is a number, use the value to search as a member number
        //   val = val
        //     .replace(/[A-Za-z]*$/gi, "")
        //     .replace(/[^\w\s]/gi, "");

        //   let filteredData = allDefaultSubscribers.filter(
        //     (x) =>
        //       _.toLower(x.memberNumber).startsWith(
        //         _.toLower(val.trim())
        //       )
        //   );
        //   this.props.setDefaultSubscribers(filteredData);
        // }
        // else 

        ///////////////// ends here

        if (this.validateEmail(val)) {
          // If the value is a valid email address, search as per primary email
          let filteredData = allDefaultSubscribers.filter(
            (x) =>
              _.toLower(x.address).startsWith(
                _.toLower(val.trim())
              )
          );
          this.props.setDefaultSubscribers(filteredData);
        }
        else {
          // If the value contains space, consider it as first name and last name
          // If space is not present, consider it as last name
          if (val.indexOf(" ") > -1) {
            let name = val.split(" ");
            if (name.length > 1) {
              // If the value has 2 names, search as per first name and last name
              let filteredData = allDefaultSubscribers.filter(
                (x) =>
                  _.toLower(x.firstName).startsWith(
                    _.toLower(name[0])
                  ) &&
                  _.toLower(x.lastName).startsWith(
                    _.toLower(name[1])
                  )
              );
              this.props.setDefaultSubscribers(filteredData);
            }
            else {
              // If the value has only one name but contains a space after, search as per first name
              let filteredData = allDefaultSubscribers.filter(
                (x) =>
                  _.toLower(x.firstName).startsWith(
                    _.toLower(name[0])
                  )
              );
              this.props.setDefaultSubscribers(filteredData);
            }
          }
          else {
            // If the value does not contain a space, search as per last name
            let filteredData = allDefaultSubscribers.filter(
              (x) =>
                _.toLower(x.lastName).startsWith(
                  _.toLower(val)
                )
            );
            this.props.setDefaultSubscribers(filteredData);
          }
        }
      }
    }
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  toggleUnSubscriber = () => {
    if (this.state.toggleUNSUB === true) {
      this.setState({ toggleUNSUB: false });
    } else {
      this.setState({ toggleUNSUB: true });
    }
  };

  toggleSubscriber = () => {
    if (this.state.toggleSUB === true) {
      this.setState({ toggleSUB: false });
    } else {
      this.setState({ toggleSUB: true });
    }
  };

  getToggleBtnId = () => {
    const { isModalPopUpOpended } = this.props;
    if (isModalPopUpOpended) {
      this.setState({ toggleBtnId: "xyz-toggle-subUnsub" }, () => {
        this.initToggles();
      });
    } else {
      this.setState({ toggleBtnId: "toggle-subUnsub" }, () => {
        this.initToggles();
      });
    }
  };
  // Main Entry Point
  render() {
    // let {
    //   subscribers,
    //   totalSubscribers,
    //   nonNatcaUnSubscribers,
    //   additionalTeamUnSubscribers,
    //   unsubscribers,
    //   //totalUnsubscribers,
    // } = this.state;
    let {
      shrinkActionCol,
      hideActionColumn,
      showReSubscribeModal,
      listData = [],
      isModalPopUpOpended,
      emailEntityType,
    } = this.props;
    const { isLoadingDefaultSubscriber } = this.props.melstate;
    const { toggleBtnId } = this.state;
    ////console.log(toggleBtnId);
    return (
      <AUX>
        <Accordion defaultActiveKey="1">
          <Card>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-1">
                    <div className="input-group width100">
                      <input
                        name="subSearchText"
                        type="text"
                        id="subSearchText"
                        value={this.state.subSearchText}
                        onChange={this.handleTextChange}
                        // onKeyUp={(e) => {
                        //   if (e.keyCode === 13) {
                        //     this.handleSearch(
                        //       e.target.value,
                        //       true,
                        //       this.props.emailListMemType
                        //     );
                        //   }
                        // }}
                        className="form-control DefaultMemberSearchField"
                        placeholder="Search Subscriber by Name/Email"
                      />
                      {/* <div className="input-group-append">
                          <button
                            type="submit"
                            className="topBarSearchBtn"
                            title="Search subscriber now"
                            onClick={(e) => {
                              let { subSearchText } = this.state;
                              this.handleSearch(
                                subSearchText,
                                true,
                                this.props.emailListMemType
                              );
                            }}
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div> */}
                      {/* <small className="text-danger w-100">
                    {this.state.memberSearchText_err}
                  </small> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <input
                      id={toggleBtnId}
                      type="checkbox"
                      checked
                      data-toggle="toggle"
                      data-on="<i class='fa fa-bell-o'><i/> Hide UnSubscribers"
                      data-off="<i class='fa fa-bell-slash-o'></i>Show UnSubscribers"
                      data-onstyle="success"
                      data-offstyle="danger"
                      data-style="android"
                      data-width="180"
                      data-height="20"
                    //onClick={this.handleToggleChange}
                    ></input>
                  </div>
                </div>
                <div className="row">
                  {/* ///The first Lists Showed for Default Subscribers/// */}
                  <div class="col-12">
                    {this.renderSubscribed(
                      listData,
                      1,
                      10,
                      isLoadingDefaultSubscriber,
                      showReSubscribeModal,
                      shrinkActionCol,
                      isModalPopUpOpended,
                      emailEntityType
                    )}

                    {/* {totalSubscribers == 0 && !this.state.loadingSubs && (
                        <div class="container h-100">
                          <div class="row NoDataFoundTable">
                            <h5>No Data Found!</h5>
                          </div>
                        </div>
                      )}

                      {this.state.loadingSubs && (
                        <div className="d-flex justify-content-center p-4">
                          <Spinner />
                        </div>
                      )} */}
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <MG_ReSubscriberModal
          email={this.state.resubscribeEmail}
          teamName={this.state.unSubTeam}
          modal={this.state.showReSubscribeModal}
          confirm={
            this.state.unSubTeam
              ? this.confirmResubscribersModalForTeams
              : this.confirmResubscribersModal
          }
          toggle={this.toggleResubscribersModal}
        />
      </AUX>
    );
  }
}

///export default MailGunMembersList;

export default connect(
  (state) => ({
    melstate: state.mailGunEmail,
  }),
  {
    ...mailGunEmailActions,
  },
  null,
  { forwardRef: true }
)(MailGunMembersList);
