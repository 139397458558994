import React, { Component } from "react";
import InputRange from "react-input-range";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
import _ from "lodash";

export class DOB extends Component {
  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)
    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <div class="mb-3">
            <label>Min </label>
            <label style={{ float: "right" }}>Max</label>
          </div>
        ) : (
          <label> </label>
        )}
        <div class="range-slider">
          <InputRange
            maxValue={150}
            minValue={0}
            value={input.value}
            onChange={input.onChange}
          />
        </div>
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  errorMessage() {
    if (this.props.errorMessage) {
      return (
        <div className="text-danger">
          {/* {this.props.errorMessage} */}
          {/* {this.props.errorMessage.map(home => <div>{home.name}</div>)} */}
          {/* {console.log(this.props.errorMessage)}; */}
        </div>
      );
    }
  }

  onSubmit = (formValues) => {
    // console.log(formValues);
    // this.props.Login(formValues, this.props.history);
    // this.props.fetchUserProfile();
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Age</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}

        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2 ad-range-box">
              <Field
                name="Age"
                id="Age"
                classForm="form-control"
                component={this.renderInput}
                labelFor="Age"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const validate = (formValues) => {
  // console.log(formValues)
  const errors = {};
  let dateRegex = new RegExp("^[0-9]{1,3}$");
  if (!dateRegex.test(formValues.memberAgeFrom) && formValues.memberAgeFrom) {
    errors.memberAgeFrom = "Please enter up to 3 numeric characters";
  }
  if (!dateRegex.test(formValues.memberAgeTo) && formValues.memberAgeTo) {
    errors.memberAgeTo = "Please enter up to 3 numeric characters";
  }

  return errors;
};

const formWrapped = reduxForm({
  form: "ageForm",
  destroyOnUnmount: false,
  initialValues: {
    Age: { min: 0, max: 150 },
  },
})(DOB);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
