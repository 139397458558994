import React, { Component } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
export class PlannedRetirement extends Component {
  handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    //console.log(meta)

    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <label>{label} </label>
        ) : (
          <label> </label>
        )}
        <DatePicker
          {...input}
          dateForm="MM/DD/YYYY"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          onChangeRaw={this.handleDateChangeRaw}
          className={classForm}
          selected={input.value ? moment(input.value).toDate() : null}
          placeholderText={this.props.isMobile === true ? label : ""}
        />
        {/* <input
          {...input}
         
          type={type}
          autoComplete="off"
          placeholder={this.props.isMobile == true ? placeholder : ""}
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        /> */}
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };

  validateNumber = (value) => {
    // value && isNaN(Number(value))
    // ? "Please enter numeric characters only."
    // : undefined;
  };
  normalizeDob = (value, prevVal) => {
    if (!value) {
      return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length === 2) {
      return onlyNums;
    }
    if (onlyNums.length == 4) {
      return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
    }
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
      4,
      8
    )}`;
    // if (isNaN(parseInt(val[val.length - 1], 10))) {
    //   return val.slice(0, -1);
    // }
    // if (prevVal && prevVal.length >= val.length) {
    //   return val;
    // }
    // if (val.length === 2 || val.length === 5) {
    //   val += "/";
    // }
    // if (val.length >= 10) {
    //   return val.slice(0, 10);
    // }
    // return val;
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Planned Retirement</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2">
              <Field
                name="FromDate"
                validate={[this.validateNumber]}
                id="FromDate"
                classForm="form-control"
                placeholder="From Date"
                label="From Date"
                component={this.renderInput}
                labelFor="FromDate"
                // normalize={this.normalizeDob}
              />
            </Col>
            <Col className="m-2 m-sm-2">
              <Field
                name="ToDate"
                id="ToDate"
                validate={[this.validateNumber]}
                classForm="form-control"
                placeholder="To Date"
                label="To Date"
                component={this.renderInput}
                labelFor="ToDate"
                // normalize={this.normalizeDob}
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "plannedRetirementForm",
  destroyOnUnmount: false,
})(PlannedRetirement);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
