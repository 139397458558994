import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";
import AuthService from "../../modules/Auth/AuthService";
import PermissionFeature from "../../modules/Permissions/PermissionFeature";
import _ from "lodash";
import RemoveActionType from "../../modules/Email/Enums/RemoveActionType";

export default {
  /// API for Getting Scope based Email lists for **Email-Dashboard's** All Email List screen
  GetEmailList: async () => {
    let url = `/api/Email/EmailList`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  /// API for Getting All (without Scope based) Email lists for **Email-Dashboard's** All Email List screen
  GetAdminEmailList: async () => {
    let url = `/api/Email/GetAdminEmailList`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },

  // Fetches all the logged in user Emails for**Email-Dashboard's** My List screen
  GetMyAllEmailList: async (id) => {
    let url = `/api/Email/GetMyAllEmailList`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  LoadTeams: async () => {
    let url = `/api/Email/AuthorizedSenderTeams`;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
      toast.error("Invalid Request");
      return null;
    }
  },
  //Used for Both Default and Additional Members
  RemoveAddlRecipient: async (requestBody) => {
    $(".loading").show();
    let url = `/api/MailGun/DeleteAdditionalRecipient`;
    var { data } = await axios
      .delete(url, { data: requestBody })
      .catch((ex) => {
        console.log(ex);
        $(".loading").hide();
      });
    if (data.statusCode == 200 && data.status.toLowerCase() == "success") {
      $(".loading").hide();
      toast.success(data.message);
      return data;
    } else {
      toast.error("Something went Wrong!");
      $(".loading").hide();
      return null;
    }
  },
  ResubscribeUnBlockRecipient: async (
    removeActionType,
    memberEmailAddress,
    listAddress,
    messageBody
  ) => {
    $(".loading").show();
    // const { resubscribeEmail, removeActionType } = self.state;
    let url = "";
    if (removeActionType == RemoveActionType.UnBlock) {
      url = `/api/MailGun/UnBlockMember?mailAddress=${listAddress}&email=${memberEmailAddress}`;
    } else {
      url = `/api/MailGun/ResubscribeMember?mailAddress=${listAddress}&email=${memberEmailAddress}`;
    }
    var { data } = await axios.put(url, messageBody).catch((ex) => {
      console.log(ex);
      $(".loading").hide();
    });
    if (data.status.toLowerCase() == "success") {
      $(".loading").hide();
      toast.success(data.message);
      return data;
    } else {
      toast.error("Something went Wrong!");
      $(".loading").hide();
      return null;
    }
  },
  ResubscribeUnBlockTeamRecipient: async (apiParams) => {
    $(".loading").show();
    let url = "";
    if (apiParams.removeActionType == RemoveActionType.UnBlock) {
      url = `/api/MailGun/UnBlockTeam`;
    } else if (apiParams.removeActionType == RemoveActionType.ReSubscribe) {
      url = `/api/MailGun/ResubscribeTeam`;
    }
    var { data } = await axios.put(url, apiParams).catch((ex) => {
      toast.error("Something went wrong!");
      console.log(ex);
      $(".loading").hide();
    });
    if (data.status.toLowerCase() == "success") {
      $(".loading").hide();
      toast.success(data.message);
      return data;
    } else {
      toast.error("Something went Wrong!");
      $(".loading").hide();
      return null;
    }
  },
  DeleteSharedSearch: async (params) => {
    $(".loading").show();
    let { data } = await axios
      .post(`/api/SavedSearch/DeleteSharedSearch/`, params)
      .catch((ex) => {
        console.log(ex);
        $(".loading").hide();
      });
    if (data.status === "success") {
      $(".loading").hide();
      return data;
    } else {
      $(".loading").hide();
      return null;
    }
  },
  SaveSharedSearchMembers: async (params) => {
    const body = params.membersList;
    let { data } = await axios
      .post(
        `/api/SavedSearch/SaveSharedSearch?searchId=${params.searchId}`,
        body
      )
      .catch((ex) => {
        console.log(ex);
        $(".loading").hide();
      });
    if (data.status === "success") {
      return data;
    } else {
      return null;
    }
  },
  MapColumnsWithSearch: async (params) => {
    let { data } = await axios
      .post(
        `/api/SavedSearch/MapcolumnsWithSearch?savedSearchId=${params.searchId}&memberId=${params.memberId}&searchName=${params.search_name}`,
        params.selectedColumns
      )
      .catch((ex) => {
        console.log(ex);
        $(".loading").hide();
      });
    if (data.status === "success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetSearchColumns: async (searchId, memberId, searchType) => {
    const url = `/api/SavedSearch/GetColumns/`;
    const body = {
      SearchId: searchId,
      MemberId: memberId,
      SearchType: searchType,
    };
    try {
      const response = await axios.post(url, body);
      const { status, data = {} } = response;

      ///debugger;
      if (data.status === "success") {
        return data;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      if (error) {
        if (error.status === 400) {
          // Handle the 400 Bad Request error here
          console.error("400 Bad Request:", error.data);
          toast.error(error.data);
          return null;
        } else {
          // Handle other response errors here
          console.error("Response Error:", error.status, error.data);
          toast.error(error.data);
          return null;
        }
      } else {
        console.error("Network Error or Other Error:", error.message);
        return null;
      }
    }
  },

  RemoveAuthSender: async (id, emailListType) => {
    $(".loading").show();
    let url = `/api/Email/DeleteAuthorizedSender/${id}?emailListType=${emailListType}`;
    var { data } = await axios.delete(url).catch((ex) => {
      console.log(ex);
      $(".loading").hide();
    });
    if (data.statusCode == 200) {
      $(".loading").hide();
      toast.success(data.message);
      return data;
    } else {
      toast.error("Something went Wrong!");
      $(".loading").hide();
      return null;
    }
  },
  updateSubscriberList: async (params) => {
    let url = `/api/MailGun/GetListMembersPage`;
    if (!_.isEmpty(params.mgListAddress)) {
      return await axios
        .post(url, {
          listAddress: params.mgListAddress,
          subscriberType: params.subscribersTablesTypes,
        })
        .catch((ex) => {
          console.log(ex);
        });
    } else {
      console.log("params.mgListAddress is empty");
      return Promise.resolve({
        data: {
          message: "params.mgListAddress is empty",
          success: false,
        },
      });
    }
  },
  getAdditionalSubsribersEmailAddresses: async (params) => {
    let url = `/api/MailGun/GetAdditionalSubsribersEmailAddresses`;

    return await axios
      .post(url, {
        listAddress: params.mgListAddress,
        subscriberType: params.subscribersTablesTypes,
      })
      .catch((ex) => {
        console.log(ex);
      });
  },
  updateUnsubscriberList: (params) => {
    let url = `/api/MailGun/GetListMembersPage`;
    return axios
      .post(url, {
        listAddress: params.mgListAddress,
        subscriberType: params.unSubscribersTablesTypes,
      })
      .catch((ex) => {
        console.log(ex);
      });
  },
  DeleteFRTEmailList: async (id) => {
    $(".loading").show();
    let url = `/api/FacilityRegionEmail/DeleteEmailList?emailListId=${id}`;
    return await axios.delete(url).catch((ex) => {
      console.log(ex);
      toast.error("Something went wrong!");
      $(".loading").hide();
    });
  },
  //Deletes the MyNatca List from the System for Saved Searches.
  DeleteSSEmailList: async (id, deleteFilter = false) => {
    $(".loading").show();
    let url = `/api/SavedSearchEmailList/DeleteEmailList?emailListId=${id}&deleteFilter=${deleteFilter}`;
    return await axios.delete(url).catch((ex) => {
      console.log(ex);
      toast.error("Something went wrong!");
      $(".loading").hide();
    });
  },
  GetEmailPriviliges: async () => {
    let url = `/api/SavedSearchEmailList/GetEmailPriviliges`;
    return await axios.get(url);
  },
  GetDomainName: async () => {
    let url = `/api/SavedSearchEmailList/GetDomainName`;
    return await axios.get(url);
  },
  GetSavedSearchDetail: async (id) => {
    let url = `/api/SavedSearchEmailList/GetSavedSearchDetail?savedSearchId=${id}`;
    return await axios.get(url);
  },
  GetMailingListMemberCount: async (address) => {
    let url = `/api/MailGun/GetMailingListTotalCount?mailListAddress=${address}`;
    return await axios.get(url);
  },
  GetRegistrantsEmailList: async (id) => {
    let url = `/api/SavedSearchEmailList/GetRegistrantsEmailList?registrationId=${id}`;
    return await axios.get(url);
  },
  canView: () => {
    return (
      AuthService.canView(PermissionFeature.EmailListsLists) ||
      AuthService.canView(PermissionFeature.EmailListAdmin)
    );
  },
  canAdd: () => {
    return (
      AuthService.canAdd(PermissionFeature.EmailListsLists) ||
      AuthService.canAdd(PermissionFeature.EmailListAdmin)
    );
  },
  canUpdate: () => {
    return (
      AuthService.canUpdate(PermissionFeature.EmailListsLists) ||
      AuthService.canUpdate(PermissionFeature.EmailListAdmin)
    );
  },
  canDeleteEmailList: () => {
    return (
      AuthService.canDelete(PermissionFeature.EmailListsLists) ||
      AuthService.canDelete(PermissionFeature.EmailListAdmin)
    );
  },
  isEmailListAdmin: () => {
    return AuthService.canView(PermissionFeature.EmailListAdmin);
  },
  shouldHideActionIcons: () => {
    let view =
      AuthService.canView(PermissionFeature.EmailListsLists) ||
      AuthService.canView(PermissionFeature.EmailListAdmin);
    let update =
      AuthService.canUpdate(PermissionFeature.EmailListsLists) ||
      AuthService.canUpdate(PermissionFeature.EmailListAdmin);
    let result = false;

    //console.log("update", update);
    //console.log("view", view);
    if (view && !update) {
      // Just View Permission,has not update permission.
      result = false; // Hide the Action Icons for prohibiting the user to trigger any save changes.
    } else {
      result = true;
    }
    //console.log("hide icon", result);
    return result;
  },
  sendSupportEmailListRequest: (params) => {
    let url = `/api/MailGun/SendSupportEmailListRequest`;
    return axios
      .post(url, null, {
        params: {
          filterName: params.filterName,
        },
      })
      .catch((ex) => {
        console.log(ex);
      });
  },
  createFacilityEmail: async (params) => {
    let {
      facilityId,
      emailAlias,
      entityType,
      replyPreference,
      selectedPrivilige,
      mailingListName,
      emailAuthorizedSenders = [],
    } = params;
    if (facilityId > 0) {
      $(".loading").show();
      let url = `/api/FacilityRegionEmail/CreateEntityEmail?emailAlias=${emailAlias}&entityId=${facilityId}&entityType=${entityType}&replyPref=${replyPreference}&mailingListName=${mailingListName}&selectedPrivilige=${selectedPrivilige}`;
      var { data } = await axios
        .post(url, emailAuthorizedSenders)
        .catch((ex) => {
          console.log(ex);
          $(".loading").hide();
        });
      ///debugger;
      if (data && data.status.toLowerCase() === "success") {
        $(".loading").hide();
        toast.success(data.message);
        return data;
      } else {
        $(".loading").hide();
        toast.error(data.message);
        return null;
      }
    }
  },
  // deleteFilter: (searchId, searchType, memberId, _this) => {
  //   let deleteUrl =
  //     "/api/SavedSearch/DeleteSearch?savedSearchId=" +
  //     searchId +
  //     "&searchType=" +
  //     searchType +
  //     "&memberId=" +
  //     memberId;
  //   $(".loading").show();
  //   axios.delete(deleteUrl).then(({ data }) => {
  //     if (data.status) {
  //       if (data.status.toLowerCase() === "success") {
  //         toast.success(data.message);
  //         _this.refreshState();
  //         _this.props.resetSavedSearch();
  //       } else {
  //         toast.error(data.message);
  //       }
  //     } else {
  //       toast.error("Search cannot be deleted.");
  //     }
  //   });
  //   $(".loading").hide();
  // },
  //******************Removes both Filter and Email List(If Exists)*********************//
  // deleteFilterAPI: (searchId, searchType, memberId) => {
  //   let deleteUrl =
  //     "/api/SavedSearch/DeleteSearch?savedSearchId=" +
  //     searchId +
  //     "&searchType=" +
  //     searchType +
  //     "&memberId=" +
  //     memberId;
  //   $(".loading").show();
  //   return axios.delete(deleteUrl).then(({ data }) => {
  //     if (data.status) {
  //       if (data.status.toLowerCase() === "success") {
  //         ///toast.success(data.message);
  //         $(".loading").hide();
  //         return { data: data };
  //       } else {
  //         toast.error(data.message);
  //         $(".loading").hide();
  //         return null;
  //       }
  //     } else {
  //       toast.error("Search cannot be deleted.");
  //       $(".loading").hide();
  //       return null;
  //     }
  //   });
  // },
  getFRTEmailDetails: async (entityId, mailGunEmailType) => {
    if (entityId > 0) {
      let url = `/api/FacilityRegionEmail/EntityEmailExists?entityId=${entityId}&entityType=${mailGunEmailType}`;
      var response = await axios.get(url).catch((ex) => {
        console.log(ex);
      });

      if (response.data.status.toLowerCase() == "success") {
        return response.data;
      } else {
        toast.warn(response.data.message || "Error");
        return null;
      }
    }
  },

  ///Thinking about using it in redux actions
  // SaveAuthSenders: () => {
  //   this.showSpinner();
  //   let id =
  //     this.props.isTeam == true ? this.props.TeamId : this.props.FacilityId;

  //   let authSenders = [
  //     ...this.props.melstate.authorizedSendersMembers,
  //     ...this.props.melstate.authorizedSendersTeams,
  //   ];

  //   let emailListAdditionalRecpts = [
  //     ...this.props.melstate.additionalRecipientsMembers,
  //     ...this.props.melstate.additionalRecipientsTeams,
  //   ];

  //   let emailConfigData = {
  //     authSenders,
  //     emailListAdditionalRecpts,
  //   };

  //   if (this.state.emailAlias === this.state.emailAliasOld) {
  //     let url = `/api/FacilityRegionEmail/UpdateEmailPrivilege?id=${id}&accessLevel=${this.state.selectedPrivilige}&reply_preference=${this.state.reply_preference}&name=${this.state.name}`;
  //     axios.post(url, emailConfigData).then(({ data }) => {
  //       if (data.status && data.status.toLowerCase() == "success") {
  //         this.hideSpinner();
  //         toast.success(data.message);
  //       } else {
  //         this.hideSpinner();
  //         toast.error(data.message);
  //       }
  //     });
  //   } else {
  //     let url = `/api/FacilityRegionEmail/RecreateEmail?id=${id}&emailAlias=${this.state.emailAlias}&accessLevel=${this.state.selectedPrivilige}&reply_preference=${this.state.reply_preference}`;
  //     axios.post(url).then(({ data }) => {
  //       if (data.status && data.status.toLowerCase() == "success") {
  //         this.hideSpinner();
  //         toast.success(data.message);
  //         this.setState({ emailAliasOld: this.state.emailAlias });
  //       } else {
  //         this.hideSpinner();
  //         toast.error(data.message);
  //       }
  //     });
  //   }
  // },
};
