import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";

import _ from "lodash";
export default {
  GetOfficersPositions: async (id) => {
    let url = "/api/Facilities/FacilityOfficers?facilityId=" + id;
    var { data } = await axios.get(url);
    if (data.statusCode == 200) {
      return data.result;
    } else {
        toast.error(data.message);
      return null;
    }
    },
    GetPendingOfficersPositionsRequest: async (id) => {
        let url = "/api/Facilities/PendingOfficersPositionRequest?facilityId=" + id;
        var { data } = await axios.get(url);
        if (data.statusCode == 200) {
            return data.result;
        } else {
            toast.error(data.message);
            return null;
        }
    },
    CreateOfficersPositionsRequest: async (body) => {
        let url = "/api/Facilities/OfficersPositionRequest"
        var { data } = await axios.post(url,body);
        if (data.statusCode == 200) {
            toast.success(data.message);
            return true;
        } else {
            toast.error(data.message);
            return false;
        }
    },
    UpdateOfficerPositionsRequest: async (requestid, status) => {
        let url = "/api/Facilities/OfficerPositionRequest?requestId=" + requestid + "&status=" + status;
        var { data } = await axios.put(url);
        if (data.statusCode == 200) {
            toast.success(data.message);
            return true;
        } else {
            toast.error(data.message);
            return false;
        }
    }
};
