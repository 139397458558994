import React, { Component } from "react";
import { MDBInput } from "mdbreact";
import { connect } from "react-redux";
import actions from "../../../../store/facility/action";
import {
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import DeleteModal from "./DeleteModal";
import InputMask from "react-input-mask";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";

export class PhoneModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataIdx: 0,
      delId: 0,
      isValid: false,
      isConfirmModalOpen: false,
    };
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);

    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.delId == 0) {
          return false;
        }

        const formData = {
          phoneId: this.state.delId,
          facilityId: this.props.facilityId,
        };
        this.props.deleteFacilityPhoneNo(formData);
        this.props.removeFacilityPhoneNo(this.state.dataIdx);

        this.setState({ delId: 0 });
        this.setState({ dataIdx: 0 });
        this.toggleConfirmModal();
      };
      return this;
    };
  }
  toggleConfirmModal = () => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
  };

  changeHandler = (e) => {
    //debugger
    const idx = e.target.parentElement.parentElement.dataset.id;

    if (e.target.name == "isprimary") {
      this.props.facilityDashboardState[e.target.dataset.obj][idx][
        e.target.name
      ] = e.target.checked;
    } else {
      this.props.facilityDashboardState[e.target.dataset.obj][idx][
        e.target.name
      ] = e.target.value;
    }

    this.props.setFacilityPhoneNumbers(
      this.props.facilityDashboardState[e.target.dataset.obj]
    );
  };
  submitForm = () => {
    // debugger

    for (var key of Object.keys(this.refs)) {
      //  console.log(key + ": " + this.refs[key]);
      this.refs[key].className += " was-validated";
      if (this.refs[key].checkValidity() === false) {
        this.setState({ isValid: false });
        return false;
      }
    }

    const formData = {
      phoneNumbers: this.props.facilityDashboardState.facilityPhoneNumbers,
      id: this.props.facilityId,
    };

    this.props.saveFacilityPhone(formData);
    this.props.toggleModal();
  };

  removeRow = (addressId, idx) => {
    addressId > 0 && this.setState({ isConfirmModalOpen: true });
    if (addressId > 0) {
      this.setState({ delId: addressId });
      this.setState({ dataIdx: idx });
    } else {
      this.props.removeFacilityPhoneNo(idx);
    }
  };

  addRowClick = () => {
    for (var key of Object.keys(this.refs)) {
      // console.log(key + ": " + this.refs[key]);
      this.refs[key].className += " was-validated";
      if (this.refs[key].checkValidity() === false) {
        this.setState({ isValid: false });
        return false;
      }
    }
    this.props.addFacilityPhoneNo();
  };
  componentDidMount() {}

  render() {
    return (
      <>
        <Modal
          centered={true}
          className="phoneNo modal-lg"
          role="dialog"
          isOpen={this.props.isModalOpen}
          toggle={this.props.toggleModal}
        >
          <ModalHeader
            className="py-2 bg-secondary modal-title mt-0 text-white"
            toggle={this.props.toggleModal}
          >
            Edit Phone Numbers
          </ModalHeader>

          <ModalBody className="modalshadow">
            {this.props.facilityDashboardState &&
              this.props.facilityDashboardState.facilityPhoneNumbers.map(
                (item, idx) => (
                  <div className="container-fluid" key={idx + 1}>
                    <form ref={`${idx}-phoneNoForm`}>
                      <Card>
                        <CardBody>
                          <div key={idx} data-repeater-list="group-a">
                            <div data-repeater-item className="row mb-4">
                              <div className="col-11 row">
                                <div data-id={idx} className="col-sm-4">
                                  <div class="md-form form-group mb-0">
                                    <InputMask
                                      name="number"
                                      containerClass="mb-0"
                                      value={item.number}
                                      onChange={this.changeHandler}
                                      label={`Phone ${idx + 1}`}
                                      data-obj="facilityPhoneNumbers"
                                      data-type="P"
                                      mask="(999) 999-9999"
                                      className="form-control"
                                      // disabled={this.canEdit("phones")}
                                      group
                                      required
                                      type="text"
                                    ></InputMask>
                                    <div className="invalid-feedback">
                                      Phone No. is required.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 pb-3">
                                  <div
                                    data-id={idx}
                                    className="align-self-center"
                                  >
                                    <label>Phone Type</label>
                                    <div class="1stParent">
                                      <select
                                        className="browser-default custom-select"
                                        onChange={this.changeHandler}
                                        name="phonetypeid"
                                        /// data-id={idx}
                                        data-obj="facilityPhoneNumbers"
                                        //disabled={this.canEdit("phones")}
                                      >
                                        {this.props.phoneTypes &&
                                          this.props.phoneTypes.map(
                                            (item1, idx) => (
                                              <option
                                                key={idx + 1}
                                                value={item1.id}
                                                // selected={item1.id == 2}
                                                selected={
                                                  item1.id == item.phonetypeid
                                                }
                                              >
                                                {item1.phonetype}
                                              </option>
                                            )
                                          )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 pt-3">
                                  <div data-id={idx} className="pt-sm-4">
                                    <div className="1stParent">
                                      <label>Primary?</label>
                                      <input
                                        type="checkbox"
                                        id={`PhonePrimary-${idx}`}
                                        name="isprimary"
                                        data-obj="facilityPhoneNumbers"
                                        //disabled={this.canEdit("phones")}
                                        switch="bool"
                                        checked={item.isprimary}
                                        onChange={this.changeHandler}
                                      />
                                      <label
                                        className="ml-4 mb-n2"
                                        htmlFor={`PhonePrimary-${idx}`}
                                        data-on-label="Yes"
                                        data-off-label="No"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {AuthService.canFacilityDelete(
                                PermissionFeature.FacilityPhone
                              ) && (
                                <div className="col-1 pt-sm-4 align-self-center">
                                  {
                                    //this.canDelete("phones") &&
                                    <button
                                      type="button"
                                      data-id={idx}
                                      onClick={() =>
                                        this.removeRow(item.id, idx)
                                      }
                                      className="btn btn-danger btn-sm"
                                    >
                                      <i className="fas fa-trash-alt" />
                                    </button>
                                  }
                                </div>
                              )}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </form>
                  </div>
                )
              )}
            <hr className="mb-3" />
            <div className="row">
              <div className="col-8">
                <div className="float-left text-left">
                  <button
                    onClick={() => this.submitForm()}
                    className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-danger btn-sm w-md waves-effect waves-light"
                    type="button"
                    onClick={this.props.toggleModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              {AuthService.canFacilityAdd(PermissionFeature.FacilityPhone) && (
                <div className="col-4 align-self-center">
                  {
                    //  this.canAdd("phones") &&
                    <a
                      onClick={() => this.addRowClick()}
                      //disabled={!this.canAdd("phones")}
                      className="float-right c-pointer text-success mo-mt-2"
                    >
                      <i className="fas fa-plus"></i> Add Phone
                    </a>
                  }
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </>
    );
  }
}
export default connect(
  (state) => ({
    facilityDashboardState: state.facilityDashboard,
  }),
  {
    ...actions,
  }
)(PhoneModal);
