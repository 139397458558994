import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import advanceFilterActions from "../../../../store/advancedfilter/action";
import fetrackerActions from "../../../../store/feTracker/action";
import { bindActionCreators } from "redux";

class FE_Facilities extends Component {
  getCurrentOptions = () => {
    let options;
    if (this.props.isInventoryTab) {
      // FE Module's Equipment's Tab
      options = this.props.festate.filterData.fe_equipment_facilities;
    } else {
      // FE Module's Request's Tab
      options = this.props.festate.filterData.fe_request_facilities;
    }
    return options;
  };

  onChange(item) {
    let options = this.getCurrentOptions();
    var index = _.findIndex(options, (o) => {
      return o.id === item.id;
    });
    item.checked = !options[index].checked;
    options[index] = item;
    this.props.fetrackerActions.setFilterData({
      ...this.props.festate.filterData,
    });
    }
    chechboxes(item, isInventory) {
        return (
            <li
                class="chechbox-list_item"
                key={`msfefac_liitem-${isInventory ? "inv" : "req"}` + item.id}
            >
                <span
                    className="card-text custom-checkbox ml-3 mr-4"
                    key={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
                >
                    <input
                        type="checkbox"
                        htmlFor={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
                        value={item.id}
                        checked={item.checked}
                        className="custom-control-input"
                        id={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
                        name={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
                        onChange={() => this.onChange(item)}
                    />

                    <label
                        className="custom-control-label"
                        htmlFor={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
                        style={{ color: this.props.isMobile ? "white" : null }}
                    >
                        {item.code}

                        <small>{item.total}</small>
                    </label>
                </span>
            </li>
        );
    }
  chechboxesV2(item, isInventory) {
    return (
      <div className="form-group checkboxSp">
        <div className="custom-control custom-checkbox ">
          <input
            className="custom-control-input"
            type="checkbox"
            id={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
            name={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
            htmlFor={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
            value={item.id}
            checked={item.checked}
            onChange={() => this.onChange(item)}
            aria-label="..."
          />
          <label
            className="custom-control-label"
            htmlFor={`fefacitem-${isInventory ? "inv" : "req"}` + item.id}
            style={{ color: this.props.isMobile ? "white" : null }}
          >
            {item.code}

            <small>{item.total}</small>
          </label>
        </div>
      </div>
    );
  }

  render() {
    let options = this.getCurrentOptions();
    let isInventory = this.props.isInventoryTab;
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Facility</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div className="filter-fields-scroll">
          {options &&
            !_.isEmpty(this.props.festate) &&
                    options.map((item) => this.chechboxesV2(item, isInventory))}
          {_.isEmpty(options) && "No filters available"}
        </div>

        <div className="height-scroll" />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    festate: state.fetracker,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetrackerActions: bindActionCreators(fetrackerActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FE_Facilities);
