import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "../../assets/css/TeamStyle.css";
import AUX from "../../hoc/Aux_";
import PayFileOneColumns from "./PayFileOneColumns";
import PayFileTwoColumns from "./PayFileTwoColumns";
import FinanceFileColumns from "./FinanceFileColumns";
export default class PayFileColumnsModal extends Component {
  onShow = () => {};

  render() {
    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="TextSubscription"
          size="lg"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.props.handleClose}
        >
          <Modal.Header className="modal-header py-2" closeButton>
            <Modal.Title>
              {(this.props.payfiletype == 1 || this.props.payfiletype == 2) && (
                <h5>{`Pay File ${this.props.payfiletype} Columns`}</h5>
              )}
              {this.props.payfiletype == 3 && (
                <h5>{`Dues Remittance Columns`}</h5>
              )}
              {this.props.payfiletype == 4 && (
                <h5>{`PAC Remittance Columns`}</h5>
              )}
              {this.props.payfiletype == 5 && (
                <h5>{`DRF Remittance Columns`}</h5>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12 mb-4">
              <p className="h6 text-secondary mb-3 font-weight-normal">
                Following are the columns that is used for this file:
              </p>

              {this.props.payfiletype == 1 && (
                <ol>
                  {PayFileOneColumns.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol>
              )}
              {this.props.payfiletype == 2 && (
                <ol>
                  {PayFileTwoColumns.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol>
              )}
              {this.props.payfiletype == 3 && (
                <ol>
                  {FinanceFileColumns.DUES.map((item) => (
                    <li>{item.name}</li>
                  ))}
                </ol>
              )}
              {(this.props.payfiletype == 4 || this.props.payfiletype == 5) && (
                <ol>
                  {FinanceFileColumns.PAC_DRF_NCF.map((item) => (
                    <li>{item.name}</li>
                  ))}
                </ol>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
