import React, { Component } from "react";
import CustomizeSearchResults from "./CustomizeSearchResults";
import { connect } from "react-redux";
import AUX from "../../../hoc/Aux_";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as Permissions from "../../Permissions/PermissionValues";
import AuthService from "../../Auth/AuthService";

class SavedSearchCustomizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedSearchId: this.props.savedSearchId,
      searchTitle: this.props.searchTitle,
      searchType: this.props.searchType,
      showModal: false,
    };
  }
  canCustomizeResults = () => {
    return AuthService.canView(Permissions.Customize_Fields);
  };
  showModal = () => {
    return this.canCustomizeResults() === true && this.state.savedSearchId > 0;
  };
  customizeSearchDialogClosed = (requiresRefresh, selectedFields) => {
    this.setState({
      showModal: false,
    });
    this.props.forceSearchRefresh(requiresRefresh, selectedFields);
  };

  render() {
    const { isOpenFilterModal = false } = this.props.savedSearchState;

    if (
      // this.props.savedSearchId &&
      // this.props.savedSearchId > 0 &&
      // this.props.searchType != SavedSearchTypes.SHARED_ITEM &&
      this.canCustomizeResults() === true
    ) {
      return (
        <AUX>
          {!isOpenFilterModal ? (
            <div className="inline-item">
              {this.props.FacilityDashboard ? (
                <React.Fragment>
                  <UncontrolledDropdown className="inline-item facility-filter">
                    <DropdownToggle tag="a" className="nav-link-font-size">
                      <i
                        className={
                          this.props.pencilClass === "pencil"
                            ? "fas fa-pencil-alt edit-pencil-icon"
                            : "fas fa-cog"
                        }
                        title={`Customization`}
                      ></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-body">
                      <DropdownItem
                        className="dropdown-item-custom"
                        onClick={() => {
                          this.setState({
                            showModal: true,
                          });
                        }}
                      >
                        <i className="fas fa-pencil-alt ffi"></i>
                        Customize Results
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </React.Fragment>
              ) : (
                <i
                  className={
                    this.props.pencilClass === "pencil"
                      ? "fas fa-pencil-alt edit-pencil-icon"
                      : "fas fa-cog"
                  }
                  title={`Customize ${this.props.searchTitle} result(s).`}
                  onClick={() => {
                    this.setState({
                      showModal: true,
                    });
                  }}
                ></i>
              )}

              <CustomizeSearchResults
                showModal={this.state.showModal}
                savedSearchId={this.props.savedSearchId}
                customizeSearchDialogClosed={this.customizeSearchDialogClosed}
                selectedFields={this.props.selectedFields}
                PACMembers={this.props.PACMembers}
                FacilityMembers={this.props.FacilityMembers}
              ></CustomizeSearchResults>
            </div>
          ) : this.props.showConfigColsBtn ? (
            <>
              {/* //For Edit Filter Modal// */}
              <div className="row">
                <button
                  type="button"
                  onClick={() => {
                    this.setState({
                      showModal: true,
                    });
                  }}
                  className={`btn btn-primary mt-2 float-left`}
                >
                  Configure Columns
                </button>
              </div>

              <CustomizeSearchResults
                showModal={this.state.showModal}
                savedSearchId={this.props.savedSearchId}
                customizeSearchDialogClosed={this.customizeSearchDialogClosed}
                selectedFields={this.props.selectedFields}
                PACMembers={this.props.PACMembers}
                FacilityMembers={this.props.FacilityMembers}
              ></CustomizeSearchResults>
            </>
          ) : null}
        </AUX>
      );
    } else {
      return null;
    }
  }
}
export default connect((state) => ({
  melstate: state.mailGunEmail,
  filterState: state.advancedFilter,
  savedSearchState: state.saveSearch,
}))(SavedSearchCustomizer);
