import React, { Component, Fragment } from "react";
import _ from "lodash";
import "../../../assets/css/TeamStyle.css";
import permissionAlgo from "./PermissionAlgo";
import Services from "../PermissionService";
import $ from "jquery";

class Permissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultPermissions: [],
      prefix: "",
      collapse: false,
    };
  }
  componentDidMount() {
    if (this.props.prefix) {
      this.setState({ prefix: this.props.prefix });
    }
    if (!this.props.fromDefaultPermission) {
      this.handleConfigureDefaultPermission();
    }
  }
  async handleConfigureDefaultPermission() {
    $(".loading").show();
    let data = await Services.DefaultPermissions();

    if (!_.isEmpty(data)) {
      let defaultPermissions = permissionAlgo.ConvertPermissionToNewScheme(
        data.result
      );
      this.state.defaultPermissions = permissionAlgo.cloneDefaultPermission(
        defaultPermissions
      );
      await this.setState({
        defaultPermissions: this.state.defaultPermissions,
      });
    }

    $(".loading").hide();
  }
  performSlide(parentid) {
    let prefix = this.state.prefix;
    let element = $(
      "." +
        prefix +
        "slide-collapsers[data-slide-Id='" +
        prefix +
        "slide" +
        parentid +
        "']"
    );
    let expand = element.attr("data-slide-expand");

    const collapseChild = (parentid, expand) => {
      let element = $(
        "." + prefix + "slide-collapsers[data-slide-Id='" + parentid + "']"
      );

      if (expand) {
        $(
          "." +
            prefix +
            "slide-collapsers[data-slide-parentId='" +
            parentid +
            "']"
        ).addClass("perm-row-hide");
      } else {
        let expandChild = element.attr("data-slide-expand");

        if (expandChild == "true") {
          $(
            "." +
              prefix +
              "slide-collapsers[data-slide-parentId='" +
              parentid +
              "']"
          ).removeClass("perm-row-hide");
          //this.setState({collapse: false});
        }
        //in case false
        else {
          expand = true;
        }
      }
      $(
        "." +
          prefix +
          "slide-collapsers[data-slide-parentId='" +
          parentid +
          "']"
      ).each(function(i) {
        let parentid2 = $(this).attr("data-slide-Id");
        if (
          $(
            "." +
              prefix +
              "slide-collapsers[data-slide-parentId='" +
              parentid2 +
              "']"
          ).length > 0
        ) {
          collapseChild(parentid2, expand);
        }
      });
    };

    if (expand == "true") {
      $(
        "." +
          prefix +
          "slide-collapsers[data-slide-parentId='" +
          prefix +
          "slide" +
          parentid +
          "']"
      ).addClass("perm-row-hide");
      element.attr("data-slide-expand", false);
      $("#" + prefix + "slide" + parentid).addClass("fa-level-down-alt");
      $("#" + prefix + "slide" + parentid).removeClass("fa-level-up-alt");
    } else {
      $(
        "." +
          prefix +
          "slide-collapsers[data-slide-parentId='" +
          prefix +
          "slide" +
          parentid +
          "']"
      ).removeClass("perm-row-hide");
      $("#" + prefix + "slide" + parentid).removeClass("fa-level-down-alt");
      $("#" + prefix + "slide" + parentid).addClass("fa-level-up-alt");
      element.attr("data-slide-expand", true);
    }
    collapseChild(prefix + "slide" + parentid, expand == "true" ? true : false);
  }

  checkChildNode(parentid) {
    let { prefix } = this.state;
    var getChildNode = document.querySelectorAll(
      "." +
        prefix +
        "slide-collapsers[data-slide-parentid ='" +
        prefix +
        "slide" +
        parentid +
        "']"
    );
    if (getChildNode.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const {
      permissionFeature,
      permissions,
      originalPermissions,
      uncheckClass,
      handleChangePermission,
      hasParent,
      listId,
    } = this.props;
    const { defaultPermissions, prefix } = this.state;

    return (
      <div className="mb-3 permissionsList" id={listId}>
        <div className="w-100">
          <div className="row mx-0">
            <div className="perm-col-1"></div>
            <div className="perm-col-2 text-center">VIEW</div>
            <div className="perm-col-2 text-center">ADD</div>
            <div className="perm-col-2 text-center">UPDATE</div>
            <div className="perm-col-2 text-center">DELETE</div>
          </div>
          <div>
            {permissions.map((item, idx) => {
              if (permissionFeature[idx]) {
                let rowClasses = "";
                switch (permissionFeature[idx].featureType) {
                  case 1:
                    rowClasses = " bg-dark text-white";
                    break;
                  case 2:
                    rowClasses = " bg-perm-info text-white perm-row-hide";
                    break;
                  case 3:
                    rowClasses = " bg-primary text-white perm-row-hide";
                    break;
                  case 4:
                    rowClasses = " perm-row-hide";
                    break;
                  default:
                }
                let pId2 = $(this).attr("data-slide-Id");
                let tempData = _.cloneDeep(permissionFeature)
                  .splice(0, idx)
                  .reverse();
                let lessIndex = tempData.findIndex(
                  (x) => x.featureType < permissionFeature[idx].featureType
                );
                let currentParent = lessIndex != -1 ? idx - lessIndex - 1 : -1;
                let viewCond =
                  (originalPermissions[idx].isView &&
                    permissionFeature[idx].isViewAvail &&
                    hasParent) ||
                  (!hasParent && permissionFeature[idx].isViewAvail);
                let addCond =
                  (originalPermissions[idx].isAdd &&
                    permissionFeature[idx].isAddAvail &&
                    hasParent) ||
                  (!hasParent && permissionFeature[idx].isAddAvail);
                let updCond =
                  (originalPermissions[idx].isUpdate &&
                    permissionFeature[idx].isUpdateAvail &&
                    hasParent) ||
                  (!hasParent && permissionFeature[idx].isUpdateAvail);
                let delCond =
                  (originalPermissions[idx].isDelete &&
                    permissionFeature[idx].isDeleteAvail &&
                    hasParent) ||
                  (!hasParent && permissionFeature[idx].isDeleteAvail);
                let viewChildCheck = false;
                let addChildCheck = false;
                let updChildCheck = false;
                let delChildCheck = false;
                let lastIndex = permissionFeature.length - 1;
                if (idx != lastIndex) {
                  //creating Default permission verifier
                  let viewDFChildCheck = false;
                  let addDFChildCheck = false;
                  let updDFChildCheck = false;
                  let delDFChildCheck = false;
                  //Splicing the permissions
                  let tempData2 = _.cloneDeep(permissionFeature).splice(
                    idx + 1,
                    lastIndex
                  );
                  //filtering the childrens of the current permission
                  let lessIndex2 = tempData2.findIndex(
                    (x) => x.featureType <= permissionFeature[idx].featureType
                  );
                  //if there is all are child no next item with greater them then
                  if (lessIndex2 == -1) {
                    //since ther is no greater item then we will save the lover items length for splice
                    lessIndex2 = tempData2.filter(
                      (x) => x.featureType > permissionFeature[idx].featureType
                    ).length;
                  }
                  //first we check we have default permission or not
                  if (
                    !this.props.fromDefaultPermission &&
                    !_.isEmpty(defaultPermissions)
                  ) {
                    //splice for get all children
                    let tempDFData2 = _.cloneDeep(defaultPermissions).splice(
                      idx + 1,
                      lessIndex2
                    );
                    viewDFChildCheck =
                      tempDFData2.findIndex((x) => x.isView == true) != -1;
                    addDFChildCheck =
                      tempDFData2.findIndex((x) => x.isAdd == true) != -1;
                    updDFChildCheck =
                      tempDFData2.findIndex((x) => x.isUpdate == true) != -1;
                    delDFChildCheck =
                      tempDFData2.findIndex((x) => x.isDelete == true) != -1;
                  }

                  //splice for get all children
                  tempData2 = _.cloneDeep(permissions).splice(
                    idx + 1,
                    lessIndex2
                  );
                  viewChildCheck = viewDFChildCheck
                    ? true
                    : tempData2.findIndex((x) => x.isView == true) != -1;
                  addChildCheck = addDFChildCheck
                    ? true
                    : tempData2.findIndex((x) => x.isAdd == true) != -1;
                  updChildCheck = updDFChildCheck
                    ? true
                    : tempData2.findIndex((x) => x.isUpdate == true) != -1;
                  delChildCheck = delDFChildCheck
                    ? true
                    : tempData2.findIndex((x) => x.isDelete == true) != -1;
                }
                let viewDFCond = false;
                let addDFCond = false;
                let updDFCond = false;
                let delDFCond = false;
                let uncheckDF = " uncheck-default";
                if (
                  !this.props.fromDefaultPermission &&
                  !_.isEmpty(defaultPermissions)
                ) {
                    try {

                        viewDFCond = defaultPermissions[idx].isView;
                        addDFCond = defaultPermissions[idx].isAdd;
                        updDFCond = defaultPermissions[idx].isUpdate;
                        delDFCond = defaultPermissions[idx].isDelete;
                    } catch (e) {
                      
                    }
                }

                return (
                  <Fragment>
                    <div
                      className={
                        prefix +
                        "slide-collapsers perm-row row mx-0" +
                        rowClasses
                      }
                      onClick={(e) => {
                        this.performSlide(idx);
                      }}
                      data-slide-expand={false}
                      data-slide-Id={prefix + "slide" + idx}
                      data-slide-parentId={
                        currentParent != -1 && prefix + "slide" + currentParent
                      }
                      featureTypeId={permissionFeature[idx].featureType}
                    >
                      <div className="perm-col-1 p-2">
                        {permissionFeature[idx].featureName}
                        {permissionFeature[idx].featureType != 4 &&
                          this.checkChildNode(idx) == true && (
                            <i
                              id={prefix + "slide" + idx}
                              class="fas fa-level-down-alt"
                              style={{ float: "right" }}
                            ></i>
                          )}
                      </div>
                      <div className="perm-col-2 text-center p-2">
                        <div
                          class={
                            "round-check" +
                            (viewDFCond
                              ? uncheckDF
                              : viewCond
                              ? uncheckClass
                              : "")
                          }
                        >
                          <input
                            type="checkbox"
                            checked={item.isView}
                            data-disabled={!viewCond}
                            data-checked={item.isView}
                            data-child-check={viewChildCheck}
                            data-index={idx}
                            data-permission="isView"
                            data-parent={currentParent}
                            onChange={handleChangePermission}
                            id={prefix + "subFeatures-isView-" + idx}
                            name={prefix + "subFeatures-isView-" + idx}
                          />
                          <label
                            for={prefix + "subFeatures-isView-" + idx}
                          ></label>
                        </div>
                      </div>
                      <div className="perm-col-2 text-center p-2">
                        <div
                          class={
                            "round-check" +
                            (addDFCond
                              ? uncheckDF
                              : addCond
                              ? uncheckClass
                              : "")
                          }
                        >
                          <input
                            type="checkbox"
                            checked={item.isAdd}
                            //         disabled = {!addCond}
                            data-index={idx}
                            data-permission="isAdd"
                            data-disabled={!addCond}
                            data-checked={item.isAdd}
                            data-child-check={addChildCheck}
                            data-parent={currentParent}
                            onChange={handleChangePermission}
                            id={prefix + "subFeatures-isAdd-" + idx}
                            name={prefix + "subFeatures-isAdd-" + idx}
                          />
                          <label
                            for={prefix + "subFeatures-isAdd-" + idx}
                          ></label>
                        </div>
                      </div>
                      <div className="perm-col-2 text-center p-2">
                        <div
                          class={
                            "round-check" +
                            (updDFCond
                              ? uncheckDF
                              : updCond
                              ? uncheckClass
                              : "")
                          }
                        >
                          <input
                            type="checkbox"
                            checked={item.isUpdate}
                            data-index={idx}
                            data-permission="isUpdate"
                            data-child-check={updChildCheck}
                            data-disabled={!updCond}
                            data-checked={item.isUpdate}
                            data-parent={currentParent}
                            onChange={handleChangePermission}
                            id={prefix + "subFeatures-isUpdate-" + idx}
                            name={prefix + "subFeatures-isUpdate-" + idx}
                          />
                          <label
                            for={prefix + "subFeatures-isUpdate-" + idx}
                          ></label>
                        </div>
                      </div>
                      <div className="perm-col-2 text-center p-2">
                        <div
                          class={
                            "round-check" +
                            (delDFCond
                              ? uncheckDF
                              : delCond
                              ? uncheckClass
                              : "")
                          }
                        >
                          <input
                            type="checkbox"
                            checked={item.isDelete}
                            data-index={idx}
                            data-permission="isDelete"
                            data-parent={currentParent}
                            data-child-check={delChildCheck}
                            data-disabled={!delCond}
                            data-checked={item.isDelete}
                            onChange={handleChangePermission}
                            id={prefix + "subFeatures-isDelete-" + idx}
                            name={prefix + "subFeatures-isDelete-" + idx}
                          />
                          <label
                            for={prefix + "subFeatures-isDelete-" + idx}
                          ></label>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Permissions;
