import React, { useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import jsPDF from "jspdf";
// import RegService from "../RegistrationsService";
import overlayFactory from "react-bootstrap-table2-overlay";
import {
  RegistrationStatuses,
  RegistrationTableTypes,
  CompositionModalMode,
  RegisTableType,
} from "../Helpers/LookUpValues";
// import {
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
// import chistory from "../../Shared/history";
const { ExportCSVButton } = CSVExport;
const TableHeader = (props) => (
  <h6 className="sub_tbl_caption">{props.header}</h6>
);
const RegistrantsActions = (
  cell,
  row,
  handleRegistrationViewClick,
  deleteRegistrant,
  regTableType,
  viewEditReg
) => {
  return (
    <div>
      <div className="actionIcons">
        {regTableType == RegisTableType.AdditionalRegistrants && (
          <i
            className="fas fa-trash c-pointer"
            title="Delete Registrant"
            aria-hidden="true"
            onClick={() => deleteRegistrant(row.id)}
          ></i>
        )}
      </div>
    </div>
  );
};

const NoDataIndication = () => (
  <div class="container h-100">
    <div class="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const Empty = () => <div></div>;
const columns = (handleRegistration, deleteRegistration, regTableType) => [
  {
    text: "FIRST NAME",
    dataField: "firstName",
    sort: true,
  },
  {
    text: "LAST NAME",
    dataField: "lastName",
    sort: true,
  },
  {
    text: "REGION",
    dataField: "region",
    sort: true,
  },
  {
    text: "FACILITY",
    dataField: "facility",
    sort: true,
  },
  {
    text: "EMAIL",
    dataField: "email",
    sort: true,
  },
  {
    text: "REGIS. STATUS",
    dataField: "registrationStatus",
    sort: true,
  },
  {
    text: "Id",
    dataField: "registrationId",
    hidden: true,
    csvExport: false,
  },
  {
    text: "",
    dataField: "actions",
    isDummyField: true,
    hidden: regTableType == RegistrationTableTypes.MemberProfileRegistrations,
    headerClasses: "text-center text-nowrap   text-white px-sm-1 py-2",
    headerAttrs: {
      width: "60px",
    },
    formatter: (cell, row) => {
      return RegistrantsActions(
        cell,
        row,
        handleRegistration,
        deleteRegistration,
        regTableType
      );
    },
    csvExport: false,
  },
];

const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="MemberSearchKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder="Member Search.."
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
    //props.onExport(products.filter((r) => r.id > 2));
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv pl-1"
        onClick={handleClick}
      ></i>
    </div>
  );
};

///////////********  PDF Export work ***********//////////
const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const _html2canvas = async (allprops) => {
  var { data, registrationTitle, regStartDate } = allprops;

  let filename = getExportFileName("pdf", registrationTitle, regStartDate);
  if (!_.isEmpty(data)) {
    var tableBody = data.map((val) => {
      var listRow = [];
      listRow.push(val.firstName);
      listRow.push(val.lastName);
      listRow.push(val.region);
      listRow.push(val.facility);
      listRow.push(val.email);
      listRow.push(val.registrationStatus);
      return listRow;
    });
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["FIRST NAME", "LAST NAME", "REGION", "FACILITY", "EMAIL", "STATUS"],
      ],
      body: tableBody,
      tableWidth: "auto",
      styles: { cellPadding: 0.7, fontSize: 8 },
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 25 },

        // etc
      },
    });
    doc.save(filename);
  }
};
// const getExportFileName = (filetype, title) => {
//   const currentDate = new Date();
//   const formattedDate = currentDate
//     .toLocaleDateString("en-US", {
//       year: "2-digit",
//       month: "2-digit",
//       day: "2-digit",
//     })
//     .replace(/\//g, ".");
//   return `Registrants ${title} ${formattedDate}.${filetype}`;
// };
const getExportFileName = (filetype, title, startDate) => {
  const [month, day, year] = startDate.split("/");
  const formattedDate = `${month.padStart(2, "0")}.${day.padStart(
    2,
    "0"
  )}.${year.slice(-2)}`;
  return `Registrants ${title} ${formattedDate}.${filetype}`;
};
///############################(Main function of component)#################################
const RegistrantsMembersList = (props) => {
  const {
    listData,
    handleRegistrationViewClick,
    deleteRegistration,
    isloading,
    registrantTableType,
    openRegistrationForApproval,
    setSelectedRegistrationIds,
    caption,
    registrationTitle,
    registrationWindow,
  } = props;
  const [regStartDate = ""] = registrationWindow.split("-");
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      props.getRegistrantQuestions(row);
    },
  };
  const [selected, setSelected] = useState([]);
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelected([...selected, row.registrationId]);
      setSelectedRegistrationIds([...selected, row.registrationId]);
    } else {
      setSelected(selected.filter((x) => x !== row.registrationId));
      setSelectedRegistrationIds(
        selected.filter((x) => x !== row.registrationId)
      );
    }
  };

  const getNonSelectable = (tblType) => {
    ///Additional & MarkAttendance are both tables on the Attendance modal
    if (
      tblType == RegisTableType.MarkAttendance ||
      tblType == RegisTableType.AdditionalRegistrants
    ) {
      // Mark Attendance for only Members which are ***Registered***...
      return listData
        .filter(
          (r) => r.registrationStatusId !== RegistrationStatuses.Registered
        )
        .reduce((acc, r) => {
          acc.push(r.registrationId);
          return acc;
        }, []);
    } else if (tblType == RegisTableType.ReviewRequest) {
      // return listData
      //   .filter(
      //     (r) => r.registrationStatusId == RegistrationStatuses.Registered
      //   )
      //   .reduce((acc, r) => {
      //     acc.push(r.registrationId);
      //     return acc;
      //   }, []);
    }
    {
      return [];
    }
  };
  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.registrationId);
    if (isSelect) {
      setSelected(ids);
      setSelectedRegistrationIds(ids);
    } else {
      setSelected([]);
      setSelectedRegistrationIds([]);
    }
  };

  const selectRow = (tblType) => {
    return {
      mode: "checkbox",
      clickToSelect: false,
      selected: selected,
      onSelect: handleOnSelect,
      onSelectAll: handleOnSelectAll,
      nonSelectable: getNonSelectable(tblType),
    };
  };

  const RemotePagination = (
    data,
    handleRegistration,
    deleteRegistration,
    isloading,
    registrantTableType,
    openRegistrationForApproval,
    rowEvents,
    regStartDate
  ) => (
    <ToolkitProvider
      keyField="registrationId"
      data={data}
      exportCSV={{
        fileName: getExportFileName("csv", registrationTitle, regStartDate),
        exportAll: false,
        onlyExportFiltered: false,
      }}
      columns={columns(
        handleRegistration,
        deleteRegistration,
        registrantTableType,
        openRegistrationForApproval,
        rowEvents
      )}
      search
    >
      {(props) => (
        <div>
          <div className="d-flex justify-content-end">
            <MyExportCSV {...props.csvProps} />
            <MyExportPDF
              data={data}
              registrationTitle={registrationTitle}
              regStartDate={regStartDate}
            />
          </div>

          <MySearch {...props.searchProps} />

          <BootstrapTable
            keyField="registrationId"
            selectRow={selectRow(registrantTableType)}
            overlay={overlayFactory({
              spinner: true,
              styles: {
                overlay: (base) => ({
                  ...base,
                  background: "rgba(40, 45, 42, 0.19)",
                }),
              },
            })}
            noDataIndication={() => {
              return isloading === false ? <NoDataIndication /> : <Empty />;
            }}
            loading={isloading}
            rowEvents={
              registrantTableType == RegisTableType.ReviewRequest
                ? rowEvents
                : {}
            }
            rowClasses={`${
              registrantTableType == RegisTableType.ReviewRequest
                ? "c-pointer"
                : ""
            }`}
            caption={<TableHeader header={caption} />}
            {...props.baseProps}
          />
          <br />
        </div>
      )}
    </ToolkitProvider>
  );

  return (
    <>
      {RemotePagination(
        listData,
        handleRegistrationViewClick,
        deleteRegistration,
        isloading,
        registrantTableType,
        openRegistrationForApproval,
        rowEvents,
        regStartDate.trim()
      )}
    </>
  );
};

RegistrantsMembersList.propTypes = {
  listData: PropTypes.array.isRequired,
};

RegistrantsMembersList.defaultProps = {
  listData: [],
};

export default RegistrantsMembersList;
