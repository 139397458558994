import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class Unit extends Component {
  onChange(item) {
    var unitIndex = _.findIndex(this.props.filterState.filterData.unit, (o) => {
      return o.unitCode === item.unitCode;
    });
    item.checked = !this.props.filterState.filterData.unit[unitIndex].checked;
    this.props.filterState.filterData.unit[unitIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item) {
    return (
      <li class="chechbox-list_item" key={"gliitem-" + item.unitCode}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"gitem-" + item.unitCode}
        >
          <input
            type="checkbox"
            htmlFor={"gitem-" + item.unitCode}
            value={item.unitCode}
            checked={item.checked}
            className="custom-control-input"
            id={"gitem-" + item.unitCode}
            name={"gitem-" + item.unitCode}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"gitem-" + item.unitCode}
          >
            {item.unitCode}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Unit</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div class="filter-fields-scroll">
          <ul class="chechbox-list">
            {this.props.filterState.filterData.unit &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.unit.map((item) =>
                this.chechboxes(item)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(Unit);
