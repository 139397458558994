import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
export default {
    
    CreateRegionTeam: async (regionId) => {
        if (regionId == null) {
            return null;
        }
        let url = `/api/Region/CreateRegionTeam?regionId=${regionId}`;
        var { data } = await axios.post(url);
        if (data && data.statusCode == 200) {
            toast.success(data.message)
            return data;
        } else {
            toast.error(data.message);
            return null;
        }
    },
    CreateFacilityTeam: async (facilityId) => {
        if (facilityId == null) {
            return null;
        }
        let url = `/api/Facilities/CreateFacilityTeam?facilityId=${facilityId}`;
        var { data } = await axios.post(url);
        if (data && data.statusCode == 200) {
            toast.success(data.message)
            return data;
        } else {
            toast.error(data.message);
            return null;
        }
    },
    CreateBulkTeams: async () => {
        
        let url = `/api/Permission/CreateBulkTeams`; //`/api/Region/CreateBulkTeams`;
        var { data } = await axios.post(url);
        if (data && data.statusCode == 200) {
            toast.success(data.message)
            return data;
        } else {
            toast.error(data.message);
            return null;
        }
    },
    GetTeamsCreateStatus: async (byUser) => {
        
        let url = `/api/Permission/TeamCreateStatus`;
        var { data } = await axios.get(url);
        if (data && data.statusCode == 200) {
            return data;
        } else {
            if (byUser) {
                toast.error(data.message);
            }
            
            return null;
        }
    }, 
};
