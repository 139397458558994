import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import FEService from "../F&ERequests/FEService";
import InventoryStatus from "../InventoryStatus";
import { toast } from "react-toastify";

class DeleteEquipmentModal extends Component {
  DeleteEquipment = async (id) => {
    var response = await FEService.DeleteEquipment(id);
    if (response == null) {
      toast.error("Something went wrong, please try again later");
    } else {
      this.props.closeModal();
      var activeSections = [];
      activeSections.push(InventoryStatus.InAccReview);
      activeSections.push(InventoryStatus.NonAccReview);
      const formData = {
        recordsTypeList: activeSections,
        PageNo: 1,
        PageSize: 10,
        SortOrder: "id", //"firstnameAsc",
      };
      this.props.refreshInvList(formData);
    }
  };
  render() {
    return (
      <>
        <Modal
          centered={true}
          autoFocus={true}
          isOpen={this.props.openModal}
          className="styled-modal"
          backdrop="static"
          id={this.props.id}
        >
          <ModalHeader
            className="modal-header bg-secondary py-2"
            toggle={this.props.closeModal}
          >
            Confirm Delete
          </ModalHeader>
          <ModalBody>
            <div className="col-12 pb-2 text-danger text-center">
              <span className="h1 fa fa-trash-alt" />
            </div>
            <div className="col-12 mb-4">
              <p className="h5 text-center">
                Are you sure you want to delete this "{this.props.content}"?
              </p>
            </div>
            <div className="col-12 text-center mb-3 border-top pt-4">
              <button
                className="btn btn-outline-secondary w-md waves-effect cancelModal waves-light mr-2"
                onClick={() => {
                  this.props.closeModal();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-outline-danger w-md waves-effect waves-light"
                onClick={() => this.DeleteEquipment(this.props.equipmentDelId)}
              >
                Delete
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default DeleteEquipmentModal;
