import React, { Component } from "react";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Form } from "reactstrap";
export class OrgCode extends Component {
  renderInput = ({ input, label, classForm, type, meta, placeholder }) => {
    return (
      <div className="form-group">
        {label !== "" && this.props.isMobile !== true ? (
          <label>{label} </label>
        ) : (
          <label> </label>
        )}
        <input
          {...input}
          className={classForm}
          type={type}
          autoComplete="off"
          placeholder={this.props.isMobile == true ? placeholder : ""}
          style={
            meta.error && meta.touched ? { border: "1px solid #ff0000" } : null
          }
        />
        <div>{this.renderError(meta)}</div>
      </div>
    );
  };

  renderError({ error, touched }) {
    if (touched && error) {
      return <span className="text-danger">{error}</span>;
    }
  }

  onSubmit = (formValues) => {
    console.log(formValues);
  };

  validateNumber = (value) => {
    // value && isNaN(Number(value))
    //   ? "Please enter numeric characters only."
    //   : undefined;
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Organization Code</h4>
            <hr />
            <br></br>
          </>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Row>
            <Col className="m-2 m-sm-2 input-group">
              <Field
                name="OrgCode"
                validate={[this.validateNumber]}
                id="OrgCode"
                classForm="form-control"
                placeholder="e.g TWAA-A11"
                label="Organization Code"
                component={this.renderInput}
                labelFor="OrgCode"
              />
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

const formWrapped = reduxForm({
  form: "orgCodeForm",
  destroyOnUnmount: false,
})(OrgCode);

const mapStateToProps = (state) => ({
  errorMessage: {},
});

export default connect(mapStateToProps, {})(formWrapped);
