import React, { Component } from "react";
import Header from "../RegistrationHeader";
import RegistrationsListTable from "./ListTables/RegistrationsListTable";
import _ from "lodash";
import registrationsAction from "../../../store/registrations/action";
import RegistrationsService from "../RegistrationsService";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  PaymentStatuses,
  RegistrationTableTypes,
} from "../Helpers/LookUpValues";
import RegisterMemberModal from "./ModalPopUps/RegisterMemberModal";
import DeleteModal from "../../Dashboard/FacilityDashboard/Modals/DeleteModal";
// import PrebuiltCheckout from "../../Payments/PrebuiltCheckout";
import CancelMyRegisConfirmModal from "./ModalPopUps/CancelMyRegisConfirmModal";
import { toast } from "react-toastify";

export class MyRegistrationsListing extends Component {
  state = {
    registrations: [],
    isLoading: false,
    toggleRegisterMemberModal: false,
    occasionId: 0,
    registrationId: 0,
    isConfirmModalOpen: false,
    isCancelConfirmModalOpen: false,
    delId: 0,
    cancelId: 0,
    paymentStatusId: 0,
    regisExists: false,
    showSuccessPage: false,
  };
  confirmCancelReg = () => {
    this.confirmed = async () => {
      const { cancelId } = this.state;
      if (!cancelId) {
        return false;
      }
      if (cancelId > 0) {
        await RegistrationsService.CancelRegistration(cancelId)
          .then((data) => {
            if (!_.isEmpty(data)) {
              if (data.statusCode == 200) {
                this.toggleCancelConfirmModal();
                this.reloadRegistrations();
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    return this;
  };
  confirm = () => {
    this.confirmed = async () => {
      const { delId } = this.state;
      if (!delId) {
        return false;
      }
      if (delId > 0) {
        await RegistrationsService.RemoveRegistration(delId)
          .then((data) => {
            if (!_.isEmpty(data)) {
              if (data.statusCode == 200) {
                this.toggleConfirmModal();
                this.reloadRegistrations();
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    return this;
  };
  async componentDidMount() {
    await this.reloadRegistrations();

    //#region Redirect work after callback from stripe
    const query = new URLSearchParams(window.location.search);

    if (query.get("CheckoutSuccess")) {
      //toast.success("Order placed.");
    }
    //let regisId = query.get("registrationId");
    let regisId = query.get("registrationId");

    if (regisId) {
      let registrationrow = this.state.registrations.find(
        (f) => f.id == regisId
      );
      if (registrationrow) {
        if (registrationrow.paymentStatus == PaymentStatuses.Success) {
          //toast.success("Order placed.");
          console.log("Order status success.");
          this.setState({ showSuccessPage: true }, () => {
            this.openRegisModelSuccessStep(registrationrow);
          });
        } else if (registrationrow.paymentStatus == PaymentStatuses.UnPaid) {
          //toast.success("Order placed.");
          console.log("Order status unpaid.");
        }
      }
    }
    if (query.get("canceled")) {
      //toast.success("Transaction Failed.");
    }
    //endregion
  }
  reloadRegistrations = async () => {
    this.setState({ isloading: true });
    let data = await RegistrationsService.GetMyRegistrations();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.registrations = data.result != null ? data.result : [];
        await this.setState({
          registrations: this.state.registrations,
          isloading: false,
        });
      }
    }
  };
  deleteMyRegistration = async (regId) => {
    this.setState({ delId: regId }, () => {
      this.toggleConfirmModal();
    });
  };
  cancelMyRegistration = async (cancelId) => {
    this.setState({ cancelId: cancelId }, () => {
      this.toggleCancelConfirmModal();
    });
  };
  handleRegistrationClick = (registrationRow) => {
    if (registrationRow.id > 0) {
      this.setState({ regisExists: true });
    }
    this.setState({
      toggleRegisterMemberModal: true,
      occasionId: registrationRow.childTemplateId,
      registrationId: registrationRow.id,
      paymentStatusId: registrationRow.paymentStatusId,
      registrationRow: registrationRow,
      showSuccessPage: false,
    });
    const query = new URLSearchParams(window.location.search);
    if (query.get("CheckoutSuccess")) {
      this.props.history.push("/MyRegistrations");
    }

    this.props.setSelectedOpenRegRow(registrationRow);
  };
  openRegisModelSuccessStep = (registrationRow) => {
    if (registrationRow.id > 0) {
      this.setState({ regisExists: true });
    }
    this.setState({
      toggleRegisterMemberModal: true,
      occasionId: registrationRow.childTemplateId,
      registrationId: registrationRow.id,
      paymentStatusId: registrationRow.paymentStatusId,
      registrationRow: registrationRow,
    });
    this.props.setSelectedOpenRegRow(registrationRow);
  };
  onClose = () => {
    this.setState({ toggleRegisterMemberModal: false });
    this.reloadRegistrations();
  };
  toggleConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  toggleCancelConfirmModal = () => {
    this.setState({
      isCancelConfirmModalOpen: !this.state.isCancelConfirmModalOpen,
    });
  };
  render() {
    let listData = this.state.registrations;
    let regTableType = RegistrationTableTypes.MyRegistrations;
    return (
      <>
        <Header />
        <div className="fet-container container-fluid">
          <br />

          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">My Registrations</h5>
            <div className="card-body pb-2 px-0 pt-0">
              <RegistrationsListTable
                handleRegistrationViewClick={(row) =>
                  this.handleRegistrationClick(row)
                }
                listData={listData}
                reloadRegistrations={this.reloadRegistrations}
                isloading={this.state.isloading}
                regTableType={regTableType}
                deleteRegistration={(regId) => this.deleteMyRegistration(regId)}
                cancelMyRegistration={(regId) =>
                  this.cancelMyRegistration(regId)
                }
              />
            </div>
          </div>
          <RegisterMemberModal
            showModal={this.state.toggleRegisterMemberModal}
            occasionId={this.state.occasionId}
            registrationId={this.state.registrationId}
            onClose={this.onClose}
            regTableType={RegistrationTableTypes.MyRegistrations}
            registrationRow={this.state.registrationRow}
            paymentStatusId={this.state.paymentStatusId}
            regisExists={this.state.regisExists}
            //updateRegistrationId={this.updateRegistrationId} No need to update registration Id
            //bcz myregistrations will already have
            // db generated id, this prop used for Payment related registrations.
            showSuccessPage={this.state.showSuccessPage}
          />
        </div>
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
        <CancelMyRegisConfirmModal
          confirm={this.confirmCancelReg}
          modal={this.state.isCancelConfirmModalOpen}
          toggle={this.toggleCancelConfirmModal}
        />
        {/* <PrebuiltCheckout /> */}
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(MyRegistrationsListing)
);
