import React, { Component } from "react";
import {
  CardBody,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { MDBInput } from "mdbreact";

export default class MemberAddressModal extends Component {
  render() {
    ///debugger;
    return (
      <div>
        <Modal isOpen={this.props.isModalOpen} toggle={this.props.toggle}>
          <ModalHeader toggle={this.props.toggle}>
            {this.props.modalLabel}
          </ModalHeader>
          <form
            className="form-horizontal m-t-40"
            onSubmit={this.props.handleFormSubmitAddresses}
            noValidate
          >
            <ModalBody>
              <div className="col-12">
                <select
                  className="browser-default custom-select"
                  onChange={this.props.handleSelectChange}
                  name="addresstypeid"
                  data-id={this.props.currentaddress.id}
                  data-obj="addresses"
                  // // disabled={
                  // //   !AuthService.canUpdate(
                  // //     PermissionFeature.ContactAddresses
                  // //   )
                  // }
                >
                  {this.props.addressTypes &&
                    this.props.addressTypes.map((item1, idx) => (
                      <option
                        value={item1.id}
                        selected={
                          item1.id == this.props.currentaddress.addresstypeid
                        }
                      >
                        {item1.code}
                      </option>
                    ))}
                </select>
              </div>

              <div data-id={this.props.currentaddress.id} className="col-sm-12">
                <MDBInput
                  name="address1"
                  value={this.props.currentaddress.address1}
                  onChange={this.props.changeHandlerAddress}
                  label="Address 1"
                  group
                  required
                  type="text"
                />
                <div className="invalid-feedback">Address 1 is required.</div>
              </div>

              <div data-id={this.props.currentaddress.id} className="col-sm-12">
                <MDBInput
                  name="address2"
                  containerClass="mb-0"
                  value={this.props.currentaddress.address2}
                  onChange={this.props.changeHandlerAddress}
                  label="Address 2"
                  group
                  required
                  type="text"
                />
              </div>

              <div className="row">
                <div className="col-8"></div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="float-left text-left">
                <button
                  className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="btn btn-danger btn-sm w-md waves-effect waves-light"
                  type="button"
                  onClick={this.props.toggleModal}
                >
                  Cancel
                </button>
              </div>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}
