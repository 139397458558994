import React, { Component } from "react";
import { connect } from "react-redux";
import actionFilter from "../../../../store/feTracker/action";

import AuthService from "../../../../modules/Auth/AuthService";
import PermissionFeature from "../../../../modules/Permissions/PermissionFeature";
import { TabContent, TabPane, Row, Col } from "reactstrap";

import FE_Statuses from "../NavItemsContent/FE_Statuses";
import FE_Budgets from "../NavItemsContent/FE_Budgets";
import FE_Types from "../NavItemsContent/FE_Types";
import FE_Description from "../NavItemsContent/FE_Description";
import FE_AF_Nav_Items from "../FE_AF_Nav_Items";
import FE_INV_Purchase_Date from "../../../F&E/AdvanceFilter/NavItemsContent/FE_INV_Purchase_Date";
import FE_ActualVals from "../../../F&E/AdvanceFilter/NavItemsContent/FE_ActualVals";
import FE_INV_Years from "../../../F&E/AdvanceFilter/NavItemsContent/FE_INV_Years";
import FE_INV_StickerNo from "../../../F&E/AdvanceFilter/NavItemsContent/FE_INV_StickerNo";

import "react-input-range/lib/css/index.css";
import { Collapse, CardBody } from "reactstrap";
import _ from "lodash";
import FE_Tabs from "../../FE_Tabs";
import FE_BookVals from "../NavItemsContent/FE_BookVals";
import FE_Facilities from "../NavItemsContent/FE_Facilities";
import FE_Regions from "../NavItemsContent/FE_Regions";
import FE_Teams from "../NavItemsContent/FE_Teams";
import FE_Users from "../NavItemsContent/FE_Users";
import FE_INV_Users from "../NavItemsContent/FE_INV_Users";
import FE_NA_Office from "../NavItemsContent/FE_NA_Office";
export class MobileSideBar extends Component {
  constructor(props) {
    super(props);

    this.DEBUG = process.env.NODE_ENV === "development";
    if (this.DEBUG) {
      // console.log("log permissons" + JSON.stringify(this.permissions))
    }
  }

  render() {
    const checkWidth = window.innerWidth <= 897;
    const isMobile = checkWidth; //this.props.festate.showMobileSideBar;
    return (
      <div
        className="col parentMobileDiv fe-sidebar-adv pr-0 pl-0"
        //style={{ maxHeight: "calc(100vh - 510px)", overflowY: "auto" }}
      >
        {/*################## ////Equipment/Request Details Tab############################# */}
        <div
          className={
            this.props.festate.collaps == 2
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.setCollapseTab(2)}
        >
          <a>
            {this.props.festate.activeSubHeader > FE_Tabs.Inventory
              ? "REQUEST DETAILS"
              : "EQUIPMENT DETAILS"}
          </a>
          <i
            className={
              this.props.festate.collaps == 2
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          />
        </div>

        <Collapse isOpen={this.props.festate.collaps == 2}>
          <CardBody>
            {/*#############Common status Tabs for Inventory/Requests######################*/}
            {// (this.props.festate.activeSubHeader == FE_Tabs.Inventory &&
            //   AuthService.canView(PermissionFeature.FEAccountingStatus)) ||
            this.props.festate.activeSubHeader > FE_Tabs.Inventory &&
            AuthService.canView(PermissionFeature.FERequestStatus) ? (
              <div>
                <div
                  className={
                    this.props.festate.activeTab === FE_AF_Nav_Items.Status
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() =>
                    this.props.onAccordionClick(FE_AF_Nav_Items.Status)
                  }
                >
                  <a>STATUS</a>

                  <i
                    className={
                      this.props.festate.activeTab === FE_AF_Nav_Items.Status
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  />
                </div>

                <Collapse
                  isOpen={
                    this.props.festate.activeTab == FE_AF_Nav_Items.Status
                  }
                >
                  <FE_Statuses
                    isInventoryTab={
                      this.props.festate.activeSubHeader == FE_Tabs.Inventory
                        ? true
                        : false
                    }
                    isMemberAF={false}
                    isMobile={true}
                  />
                </Collapse>
              </div>
            ) : null}

            {/*######################Starting Requests nav items############################# */}
            {(this.props.festate.activeSubHeader == FE_Tabs.MyRequests ||
              this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests) && (
              <>
                {AuthService.canView(PermissionFeature.FERequestType) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab === FE_AF_Nav_Items.Type
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.Type)
                      }
                    >
                      <a>TYPE</a>

                      <i
                        className={
                          this.props.festate.activeTab === FE_AF_Nav_Items.Type
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab == FE_AF_Nav_Items.Type
                      }
                    >
                      <FE_Types
                        isInventoryTab={
                          this.props.festate.activeSubHeader == 1 ? true : false
                        }
                        isMemberAF={false}
                        isMobile={true}
                      />
                    </Collapse>
                  </div>
                ) : null}
                {AuthService.canView(PermissionFeature.FERequestDescription) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.Description
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.Description)
                      }
                    >
                      <a>DESCRIPTION</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.Description
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.Description
                      }
                    >
                      <FE_Description
                        formId="fe_req_descriptionForm"
                        isMobile={true}
                      />
                    </Collapse>
                  </div>
                ) : null}
                {AuthService.canView(PermissionFeature.FERequestBookValue) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.BookValueRequest
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.BookValueRequest
                        )
                      }
                    >
                      <a>BOOK VALUE</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.BookValueRequest
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.BookValueRequest
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.BookValueRequest}>
                        <FE_BookVals
                          formId="fe_req_bookValsForm"
                          isMobile={true}
                          isFERequests={true}
                        />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
                {AuthService.canView(PermissionFeature.FERequestUser) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.UserRequest
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.UserRequest)
                      }
                    >
                      <a>USER</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.UserRequest
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.UserRequest
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.UserRequest}>
                        <FE_Users isMobile={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
              </>
            )}
            {/* ##############################################################*/}
            {/* ############Starting equipments nav items for Equipment Details####################*/}
            {/* ##############################################################*/}
            {this.props.festate.activeSubHeader == FE_Tabs.Inventory && (
              <>
                {AuthService.canView(
                  PermissionFeature.FEAccountingStickerNumber
                ) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.StickerNo
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.StickerNo)
                      }
                    >
                      <a> STICKER NUMBER</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.StickerNo
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.StickerNo
                      }
                    >
                      <FE_INV_StickerNo isMobile={true} />
                    </Collapse>
                  </div>
                ) : null}

                {AuthService.canView(
                  PermissionFeature.FEAccountingPurchaseDate
                ) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.PurchaseDate
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.PurchaseDate
                        )
                      }
                    >
                      <a> PURCHASE DATE</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.PurchaseDate
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.PurchaseDate
                      }
                    >
                      <FE_INV_Purchase_Date isMobile={true} />
                    </Collapse>
                  </div>
                ) : null}

                {AuthService.canView(PermissionFeature.FEAccountingUser) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.UserInventory
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.UserInventory
                        )
                      }
                    >
                      <a>USER</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.UserInventory
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.UserInventory
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.UserInventory}>
                        <FE_INV_Users isMobile={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
              </>
            )}
          </CardBody>
        </Collapse>

        {/* New Accordion OWNER */}
        <div
          className={
            this.props.festate.collaps == 1
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.setCollapseTab(1)}
        >
          <a>OWNER</a>
          <i
            className={
              this.props.festate.collaps == 1
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          />
        </div>
        <Collapse isOpen={this.props.festate.collaps == 1}>
          <CardBody>
            {/*#################### F/R/T for Equipments#################### */}
            {(this.props.festate.activeSubHeader == FE_Tabs.Inventory &&
              AuthService.canView(PermissionFeature.FEAccountingBudgetID)) ||
            (this.props.festate.activeSubHeader > FE_Tabs.Inventory &&
              AuthService.canView(PermissionFeature.FERequestBudgetID)) ? (
              <div>
                <div
                  className={
                    this.props.festate.activeTab === FE_AF_Nav_Items.Budget
                      ? "mobileCollapsBtn active"
                      : "tabSection"
                  }
                  onClick={() =>
                    this.props.onAccordionClick(FE_AF_Nav_Items.Budget)
                  }
                >
                  <a>BUDGET</a>

                  <i
                    className={
                      this.props.festate.activeTab === FE_AF_Nav_Items.Budget
                        ? "fas arrow-icon fa-chevron-down"
                        : "fas arrow-icon fa-chevron-up"
                    }
                  />
                </div>

                <Collapse
                  isOpen={
                    this.props.festate.activeTab == FE_AF_Nav_Items.Budget
                  }
                >
                  <FE_Budgets
                    isInventoryTab={
                      this.props.festate.activeSubHeader == 1 ? true : false
                    }
                    isMemberAF={false}
                    isMobile={true}
                  />
                </Collapse>
              </div>
            ) : null}
            {/* ///Filters for Inventory */}
            {this.props.festate.activeSubHeader == FE_Tabs.Inventory && (
              <>
                {/* ####REQ-1 Inventory CheckBoxes #### */}
                {AuthService.canView(PermissionFeature.FEAccountingCategory) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.RegionInventory
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.RegionInventory
                        )
                      }
                    >
                      <a>REGION</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.RegionInventory
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.RegionInventory
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.RegionInventory}>
                        <FE_Regions isMobile={true} isInventoryTab={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
                {/* {AuthService.canView(PermissionFeature.FERequestCategory) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.FacilityRequest
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.FacilityRequest
                        )
                      }
                    >
                      <a>FACILITY</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.FacilityRequest
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      ></i>
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.FacilityRequest
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.FacilityRequest}>
                        <FE_Facilities isMobile={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null} */}

                {AuthService.canView(PermissionFeature.FERequestCategory) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.TeamInventory
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.TeamInventory
                        )
                      }
                    >
                      <a>TEAM</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.TeamInventory
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.TeamInventory
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.TeamInventory}>
                        <FE_Teams isMobile={true} isInventoryTab={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
                {AuthService.canView(PermissionFeature.FEAccountingCategory) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.NAOffice
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.NAOffice)
                      }
                    >
                      <a>NATIONAL OFFICE</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.NAOffice
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab == FE_AF_Nav_Items.NAOffice
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.NAOffice}>
                        <FE_NA_Office isMobile={true} isInventoryTab={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
              </>
            )}
            {/*#################### F/R/T for Requests######################### */}
            {(this.props.festate.activeSubHeader == FE_Tabs.MyRequests ||
              this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests) && (
              <>
                {/* ####REQ-1 Requests CheckBoxes #### */}
                {/* {AuthService.canView(PermissionFeature.FERequestCategory) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.FacilityRequest
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.FacilityRequest
                        )
                      }
                    >
                      <a>FACILITY</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.FacilityRequest
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.FacilityRequest
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.FacilityRequest}>
                        <FE_Facilities isMobile={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null} */}
                {AuthService.canView(PermissionFeature.FERequestCategory) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.RegionRequest
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.RegionRequest
                        )
                      }
                    >
                      <a>REGION</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.RegionRequest
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.RegionRequest
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.RegionRequest}>
                        <FE_Regions isMobile={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
                {AuthService.canView(PermissionFeature.FERequestCategory) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.TeamRequest
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.TeamRequest)
                      }
                    >
                      <a>TEAM</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.TeamRequest
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.TeamRequest
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.TeamRequest}>
                        <FE_Teams isMobile={true} />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
                {/* ####REQ-1 Requests CheckBoxes #### */}
              </>
            )}
          </CardBody>
        </Collapse>

        {/* ########################Last Accordion (Values)############################ */}
        <div
          className={
            this.props.festate.collaps == 3
              ? "collapsParentAccordActive"
              : "collapsParentAccord"
          }
          onClick={() => this.props.setCollapseTab(3)}
        >
          <a>VALUES</a>
          <i
            className={
              this.props.festate.collaps == 3
                ? "fas arrow-icon fa-chevron-down"
                : "fas arrow-icon fa-chevron-up"
            }
          />
        </div>
        <Collapse isOpen={this.props.festate.collaps == 3}>
          <CardBody>
            {/*############# Tabs for Inventory######################*/}
            {this.props.festate.activeSubHeader == FE_Tabs.Inventory && (
              <>
                {AuthService.canView(PermissionFeature.FEAccountingBookVal) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.BookValueInventory
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.BookValueInventory
                        )
                      }
                    >
                      <a> BOOK VALUE</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.BookValueInventory
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.BookValueInventory
                      }
                    >
                      <FE_BookVals
                        formId="fe_Inv_bookValsForm"
                        isMobile={true}
                        isFEInventoryTab={true}
                      />
                    </Collapse>
                  </div>
                ) : null}
                {AuthService.canView(PermissionFeature.FEAccountingCost) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.ActualCost
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.ActualCost)
                      }
                    >
                      <a> ACTUAL COST</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.ActualCost
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.ActualCost
                      }
                    >
                      {/* In Inventory Block */}
                      <FE_ActualVals isMobile={true} />
                    </Collapse>
                  </div>
                ) : null}
                {AuthService.canView(
                  PermissionFeature.FEAccountingUsefulLife
                ) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab === FE_AF_Nav_Items.Year
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.Year)
                      }
                    >
                      <a>YEAR</a>

                      <i
                        className={
                          this.props.festate.activeTab === FE_AF_Nav_Items.Year
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab == FE_AF_Nav_Items.Year
                      }
                    >
                      <FE_INV_Years isMobile={true} />
                    </Collapse>
                  </div>
                ) : null}
              </>
            )}

            {/*############# Tabs for Request######################*/}
            {(this.props.festate.activeSubHeader == FE_Tabs.MyRequests ||
              this.props.festate.activeSubHeader == FE_Tabs.ReviewRequests) && (
              <>
                {AuthService.canView(PermissionFeature.FERequestBookValue) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.BookValueRequest
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(
                          FE_AF_Nav_Items.BookValueRequest
                        )
                      }
                    >
                      <a>BOOK VALUE</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.BookValueRequest
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.BookValueRequest
                      }
                    >
                      <TabPane tabId={FE_AF_Nav_Items.BookValueRequest}>
                        <FE_BookVals
                          formId="fe_req_bookValsForm"
                          isMobile={true}
                          isFERequests={true}
                        />
                      </TabPane>
                    </Collapse>
                  </div>
                ) : null}
                {/* {AuthService.canView(PermissionFeature.FEAccountingCost) ? (
                  <div>
                    <div
                      className={
                        this.props.festate.activeTab ===
                        FE_AF_Nav_Items.ActualCost
                          ? "mobileCollapsBtn active"
                          : "tabSection"
                      }
                      onClick={() =>
                        this.props.onAccordionClick(FE_AF_Nav_Items.ActualCost)
                      }
                    >
                      <a> ACTUAL COST</a>

                      <i
                        className={
                          this.props.festate.activeTab ===
                          FE_AF_Nav_Items.ActualCost
                            ? "fas arrow-icon fa-chevron-down"
                            : "fas arrow-icon fa-chevron-up"
                        }
                      />
                    </div>

                    <Collapse
                      isOpen={
                        this.props.festate.activeTab ==
                        FE_AF_Nav_Items.ActualCost
                      }
                    >
                     
                      <FE_ActualVals isMobile={true} />
                    </Collapse>
                  </div>
                ) : null} */}
              </>
            )}
          </CardBody>
        </Collapse>
      </div>
    );
  }
}
export default connect((state) => ({ festate: state.fetracker }), {
  ...actionFilter,
})(MobileSideBar);
