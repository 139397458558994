import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
// import actionFilter from "../../store/advancedfilter/action";
import actionFilter from "../../../store/advancedfilter/action";

class PayGrade extends Component {
  onChange(item) {
    var payGradeIndex = _.findIndex(
      this.props.filterState.filterData.payGrade,
      (o) => {
        return o.grade === item.grade;
      }
    );
    item.checked = !this.props.filterState.filterData.payGrade[payGradeIndex]
      .checked;
    this.props.filterState.filterData.payGrade[payGradeIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item, index) {
    return (
      <li class="chechbox-list_item" key={"pgliitem-" + index}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"pgitem-" + index}
        >
          <input
            type="checkbox"
            htmlFor={"pgitem-" + index}
            value={index}
            checked={item.checked}
            className="custom-control-input"
            id={"pgitem-" + index}
            name={"pgitem-" + index}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"pgitem-" + index}
          >
            {item.grade}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }

  render() {
    return (
      <>
        {this.props.isMobile !== true ? (
          <>
            <h4>Pay Grade</h4>
            <hr />
          </>
        ) : (
          ""
        )}
        <div class="filter-fields-scroll">
          <ul class="chechbox-list">
            {this.props.filterState.filterData.payGrade &&
              !_.isEmpty(this.props.filterState.filterData) &&
              this.props.filterState.filterData.payGrade.map((item, index) =>
                this.chechboxes(item, index)
              )}
          </ul>
        </div>
        <div className="height-scroll"></div>
      </>
    );
  }
}

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(PayGrade);
