import AUX from "../../hoc/Aux_";
import React, { Component } from "react";
import PositionsLookup from "./PositionsLookup";

class PositionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            positionMethods: this.props.positionMethods
        };
    }
    render() {
        return (
            <AUX>
                <div
                    className="modal fade position-modal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="Add/Edit Postion"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Select Position</h5>
                                <button type="button" className="close" data-dismiss="position-modal" aria-hidden="true">
                                    ×
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 mb-4">
                                    <PositionsLookup positionMethods={this.state.positionMethods} />
                                </div>
                                <div className="col-12 text-center mb-50 mt-50 pt-4">
                                    <button
                                        className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2"
                                        data-dismiss="position-modal"
                                        aria-hidden="true">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default PositionsModal;
