
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default function DeleteModal(props) {
    const {
        modal,
        toggle
    } = props;

    return (
        <Modal centered={true} autoFocus={true} isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
            <ModalBody>
                <div className="col-12 mb-4">
                    <h5>Are you sure you want to delete?</h5>
                </div>        </ModalBody>
            <ModalFooter>
                <Button className="btn btn-primary w-md waves-effect waves-light"
                    onClick={() => {
                        props.confirm().confirmed();
                    }}>Delete</Button>{' '}
                <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}
