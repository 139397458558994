import React from "react";
import { Modal } from "react-bootstrap";
import { Collapse, Card } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import RegistrationsAction from "../../../../../store/registrations/action";
import RegistrationsService from "../../../RegistrationsService";
import _ from "lodash";
import RegistrantsMembersList from "../../RegistrantsMembersList";
import $ from "jquery";
import ReviewRequestQuestions from "./ReviewRequestQuestions";
import {
  CompositionModalMode,
  RegisTableType,
  RegistrationStatuses,
  RegistrationStatusesOptions,
  ReviewRequestActions,
} from "../../../Helpers/LookUpValues";
import { toast } from "react-toastify";
import CompositionModal from "../../../../AdvanceSearch/SavedSearches/CompositionModal";
import MailGunEmailTypes from "../../../../Email/Enums/MailGunEmailTypes";
import MailGunEmailActions from "../../../../../store/mailgunEmails/action";
import MailGunMembersListModal from "../../../../Shared/MailGunMembersListModal";
import InstructorsMembersList from "../../InstructorsMembersList";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "../../../registration.css";
import { bool } from "yup";
import AuthService from "../../../../Auth/AuthService";
import PermissionFeature from "../../../../Permissions/PermissionFeature";

const filter = createFilterOptions();

class ReviewRegistrationRequestsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      detailsAccOpen: true,
      registraintsAccOpen: true,
      registrants: [],
      filteredRegistrants: [],
      instructors: [],
      selectedRegistrationIds: [],
      showQuestionsModal: false,
      showCompositionModal: false,
      compositionModalMode: "",
      composeModalEmails: [],
      composeModalPhoneNos: [],
      showConfigEmailMembersModal: false,
      selectedInstrIds: [],
      regisStatusesOptions: RegistrationStatusesOptions,
    };
  }
  onShow = async () => {
    const { props } = this;

    const { result: data = {} } =
      (await RegistrationsService.GetChildTemplateRegistrants(
        props.clickedChildTemplateId
      )) || {};
    debugger;
    //console.log(data);
    if (!_.isEmpty(data)) {
      await this.setState({
        registrants: data,
        isloading: false,
        filteredRegistrants: data,
      });
    } else {
      this.setState({
        registrants: [],
        filteredRegistrants: [],
        isloading: false,
      });
    }
    {
      const { result: data = {} } =
        (await RegistrationsService.GetChildTemplateInstructors(
          props.clickedChildTemplateId
        )) || {};
      //console.log(data);
      if (!_.isEmpty(data)) {
        await this.setState({
          instructors: data,
          isloading: false,
        });
      } else {
        this.setState({
          instructors: [],
          isloading: false,
        });
      }
    }

    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const filterId = searchParams.get("showEmailConfigModal");
    // Check if the query parameter showEmailConfig is true
    if (filterId) {
      this.showConfigEmailModal();
    }
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  /// Gets the Questions with choosen Answers
  getRegistrantQuestions = async (row) => {
    await RegistrationsService.GetOccasionRegQuestionsWithAnswers(
      this.props.clickedChildTemplateId, //occasionId,
      row.registrationId
    )
      .then((data) => {
        if (data.statusCode == 200) {
          let questionsResponse = data.result;
          this.props.setRegistrationQuestions(questionsResponse);
          this.setState({
            showQuestionsModal: true,
          });
          this.hideSpinner();
        } else {
          this.hideSpinner();
        }
      })
      .catch((error) => {
        this.hideSpinner();
      });
  };
  handleSubmitModal = async (btnAction) => {
    let formData = {
      childTemplateId: this.props.clickedChildTemplateId,
      registrationIds: this.state.selectedRegistrationIds,
      action: btnAction,
    };
    const data = await RegistrationsService.ReviewRequest(formData);
    if (!_.isEmpty(data) && data.status == "Success") {
      toast.success("Request has been Reviewed Successfully.");
      this.props.onClose(); // Hide the Modal
    } else {
      toast.error("Something went wrong");
    }
  };
  setSelectedRegistrationIds = (selectedIds) => {
    this.setState({
      selectedRegistrationIds: selectedIds,
    });
  };
  setSelectedInstrIds = (selectedIds) => {
    this.setState({
      selectedInstrIds: selectedIds,
    });
  };
  showCompositionModal = async (mode) => {
    const { clickedChildTemplateRow } = this.props;
    const {
      instructors,
      selectedInstrIds,
      selectedRegistrationIds,
      registrants,
    } = this.state;
    ///****************  Email Icon Clicked****************************/
    if (mode == CompositionModalMode.Email) {
      if (
        clickedChildTemplateRow.emailAlias &&
        _.isEmpty(selectedRegistrationIds)
      ) {
        let { response = [] } =
          (await RegistrationsService.GetMailingListSubsribersEmailAddresses(
            clickedChildTemplateRow.emailAlias
          )) || {};
        debugger;
        if (!_.isEmpty(response)) {
          await this.setState({
            composeModalEmails: response,
          });
        } else {
          this.setState({
            composeModalEmails: [],
          });
        }
      } else if (!_.isEmpty(selectedRegistrationIds)) {
        //If checkboxes are selected for Registrant Accordion..
        let regisEmails = registrants
          .filter((i) => selectedRegistrationIds.includes(i.registrationId))
          .map((m) => m.email);
        let instrEmails = instructors
          .filter((i) => selectedInstrIds.includes(i.id))
          .map((m) => m.email);
        let allEmails = [...regisEmails, ...instrEmails];
        this.setState({
          composeModalEmails: allEmails,
        });
      }
    } ///****************  SMS Icon Clicked****************************/
    else if (mode == CompositionModalMode.SMS) {
      let validRegisPhoneNo = true;
      let validInstrPhonNo = true;
      let instrPhoneNos = instructors
        .filter(
          (i) =>
            selectedInstrIds.includes(i.id) &&
            !_.isEmpty(i.phoneNo) &&
            i.phoneNo != "N/A" //N/A => Not allowed to text alerts, setting in Profile.
        )
        .map((m) => m.phoneNo);
      let regisPhoneNos = registrants
        .filter(
          (i) =>
            selectedRegistrationIds.includes(i.registrationId) &&
            !_.isEmpty(i.phoneNo) &&
            i.phoneNo != "N/A" //N/A => Not allowed to text alerts, setting in Profile.
        )
        .map((m) => m.phoneNo);
      let allPhoneNos = [...instrPhoneNos, ...regisPhoneNos];
      if (_.isEmpty(selectedRegistrationIds) && _.isEmpty(selectedInstrIds)) {
        toast.warn("Please Select Members.");
        return;
      }
      if (_.isEmpty(allPhoneNos)) {
        toast.warn("Selected Member(s) doesn't have valid Phone Number(s).");
        return;
      }
      this.setState({
        composeModalPhoneNos: allPhoneNos,
      });

      if (!_.isEmpty(selectedInstrIds) && _.isEmpty(instrPhoneNos)) {
        // For Instructors
        var everyPhoneNA = instructors
          .filter((i) => selectedInstrIds.includes(i.id))
          .every((e) => e.phoneNo == "N/A");
        var everyPhoneInvalid = instructors
          .filter((i) => selectedInstrIds.includes(i.id))
          .every((e) => _.isEmpty(e.phoneNo));
        if (everyPhoneNA) {
          toast.warn(
            "Selected Instructor(s) are not allowed to have text alerts."
          );
        }
        if (everyPhoneInvalid) {
          toast.warn("Selected Instructor(s) doesn't have valid Phone No.");
        }
        //return //=> commented because there could be valid Registrants below code
        validInstrPhonNo = false;
      }
      if (!_.isEmpty(selectedRegistrationIds) && _.isEmpty(regisPhoneNos)) {
        // For Registrants
        var everyPhoneNA = registrants
          .filter((i) => selectedRegistrationIds.includes(i.registrationId))
          .every((e) => e.phoneNo == "N/A");
        var everyPhoneInvalid = registrants
          .filter((i) => selectedRegistrationIds.includes(i.registrationId))
          .every((e) => _.isEmpty(e.phoneNo));
        if (everyPhoneNA) {
          toast.warn(
            "Selected Registrants(s) are not allowed to have text alerts."
          );
        }
        if (everyPhoneInvalid) {
          toast.warn("Selected Registrants(s) doesn't have valid Phone No.");
        }
        validRegisPhoneNo = false;
      }

      if (!validInstrPhonNo && !everyPhoneNA) {
        return; //Don't show Modal
      }
    }

    this.setState({
      showCompositionModal: true,
      compositionModalMode: mode,
    });
  };
  hideCompositionModel = () => {
    this.setState({
      showCompositionModal: false,
    });
  };
  hideConfigEmailModal = () => {
    this.setState({
      showConfigEmailMembersModal: false,
    });
  };
  closeReviewRequestModal = () => {
    this.props.onClose();
  };
  showConfigEmailModal = () => {
    this.setState({
      showConfigEmailMembersModal: true,
    });
  };
  onDeleteEmailList = async () => {
    await this.props.getPendingApprovalRegistrations();
  };
  ///Change Handler for Status
  selectStatus = async (value, reason, details) => {
    let { regisStatusesOptions, registrants = [] } = this.state;
    var allChecked = regisStatusesOptions.filter((a) =>
      value.some((curVal) => a.status == curVal.status)
    );
    if (!_.isEmpty(allChecked)) {
      regisStatusesOptions.forEach((element) => {
        if (allChecked.some((s) => s.status == element.status)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    } else {
      regisStatusesOptions.forEach((element) => {
        element.checked = false;
      });
    }
    if (reason == "remove-option") {
      let { option } = details || {};
      this.setState({
        regisStatusesOptions: regisStatusesOptions.map((item) => {
          if (item.id == option.id) {
            item.checked = false;
            return item;
          } else {
            return item;
          }
        }),
      });
      this.filterRegistrants(allChecked);
    } else if (reason == "clear") {
      this.setState({
        regisStatusesOptions: regisStatusesOptions.map((m) => ({
          ...m,
          [m.checked]: true,
        })),
      });
      this.filterRegistrants(allChecked);
    } else {
      this.setState({ regisStatusesOptions });
      //Filter Registrants
      this.filterRegistrants(allChecked);
    }
  };
  filterRegistrants = (allChecked) => {
    let { registrants = [] } = this.state;
    if (allChecked.length > 0) {
      let filteredRecords = registrants.filter((reg) => {
        if (
          allChecked.some(
            (checkedRegis) => checkedRegis.id == reg.registrationStatusId
          )
        ) {
          return reg;
        }
      });
      this.setState({ filteredRegistrants: filteredRecords });
    } else {
      this.setState({ filteredRegistrants: registrants });
    }
  };
  render() {
    const { props } = this;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { clickedChildTemplateRow } = this.props;
    let { regisStatusesOptions } = this.state;

    const selectedStatuses =
      regisStatusesOptions.length > 0
        ? regisStatusesOptions.filter((f) => f.checked)
        : [];
    return (
      <>
        <Modal
          className="modal fade team-style"
          id="reviewRegRequestsModal"
          size="xl"
          backdrop="static"
          show={props.showModal}
          centered
          onShow={this.onShow}
          onHide={props.onClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title className=" w-100 modal-title font-italic">
              <div className="feModalHeader align-items-center text-uppercase d-flex">
                <div className="col-sm-6 mt-2">
                  <h5 className="text-white mt-0">
                    Review Registration Request
                  </h5>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body reviewRegRequestBody">
            {/* ///First Accordion of Registration Details/// */}
            <div className="row">
              <div className="col">
                <Card className="mb-1">
                  <h6
                    className="card-header bg-primary text-white mb-3 mt-0 cursorPointer"
                    onClick={() => {
                      this.setState({
                        detailsAccOpen: !this.state.detailsAccOpen,
                      });
                    }}
                  >
                    <i class="fa fa-address-card" aria-hidden="true" />{" "}
                    Registration Details
                  </h6>

                  <Collapse isOpen={this.state.detailsAccOpen}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small>Title</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.title}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Dates</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.dates}
                          </b>

                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Window</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.registrationWindow}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Location</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.location}
                          </b>
                        </div>
                        <div className="col-md-6">
                          <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                            <small> Category</small>
                          </span>
                          <b className="h5 text-secondary d-block">
                            {clickedChildTemplateRow.category}
                          </b>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Card>
              </div>
            </div>
            {/* // Filter Dropdown && Actions Icons for EMail Config,SMS// */}
            <div className="regisMems">
              <div className="row p-1 regisMemActs">
                <div className="col-6 mb-2">
                  <Autocomplete
                    //loading={this.state.loadingOptions}
                    multiple
                    limitTags={2}
                    id="statusFilter"
                    options={regisStatusesOptions}
                    size="small"
                    disableCloseOnSelect
                    //filterOptions={(options, state) => options}
                    autoHighlight={true}
                    getOptionLabel={(option) => {
                      if (option != undefined) {
                        return option.status;
                      } else {
                        return "";
                      }
                    }}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(e, value, reason, details) => {
                      this.selectStatus(value, reason, details);
                    }}
                    // For searching options inside the client's array
                    // filterOptions={(options, params) => {
                    //   const inputValue = params.inputValue || "";
                    //   if (inputValue !== "") {
                    //     //when there is no space in search text
                    //     if (/^[a-zA-Z-]*$/.test(inputValue)) {
                    //       //if it is alphabets
                    //       let result = options.filter((val, ind, arr) => {
                    //         return val.status
                    //           .toLowerCase()
                    //           .includes(inputValue.toLowerCase());
                    //       });
                    //       return result;
                    //     } else if (!isNaN(inputValue) && isFinite(inputValue)) {
                    //       return [];
                    //     }
                    //   }
                    //   const filtered = filter(options, params);

                    //   return filtered;
                    // }}
                    value={selectedStatuses}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selectedStatuses.some(
                            (s) => s.id == option.id
                          )}
                        />
                        {option.status}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Filter Statuses"
                        placeholder="Select Status"
                      />
                    )}
                  />
                </div>
                <div className="col-6 d-flex justify-content-end align-items-center">
                  <React.Fragment>
                    <UncontrolledDropdown className="inline-item facility-filter">
                      <DropdownToggle tag="a" className="nav-link-font-size">
                        <i className={"fas fa-cog"} title={`Customization`}></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-body">
                        {!clickedChildTemplateRow.isMailingListExists
                          ? AuthService.canAdd(
                              PermissionFeature.EmailListAdmin
                            ) && (
                              <DropdownItem
                                className="dropdown-item-custom"
                                onClick={this.showConfigEmailModal}
                              >
                                <i className="fas fa-pencil-alt ffi"></i>
                                Create Email List
                              </DropdownItem>
                            )
                          : AuthService.canView(
                              PermissionFeature.EmailListAdmin
                            ) ||
                            AuthService.canUpdate(
                              PermissionFeature.EmailListAdmin
                            )(
                              <DropdownItem
                                className="dropdown-item-custom"
                                onClick={this.showConfigEmailModal}
                              >
                                <i className="fas fa-pencil-alt ffi"></i>
                                Configure Email List
                              </DropdownItem>
                            )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </React.Fragment>
                  {
                    <i
                      className="csvIconSize fas fa-envelope pl-1"
                      onClick={() =>
                        this.showCompositionModal(CompositionModalMode.Email)
                      }
                      //title={`Send email to members ${this.state.data.length}`}
                    ></i>
                  }

                  <i
                    className="csvIconSize fas fa-sms pl-1"
                    onClick={() =>
                      this.showCompositionModal(CompositionModalMode.SMS)
                    }
                    title="Send SMS to members"
                  ></i>
                </div>
              </div>
              {/* ///Second Accordion Members (Registrants + Instructors) */}
              <div className="row">
                <div className="col-12">
                  <Card className="mb-1">
                    <h6
                      className="card-header bg-primary text-white mb-3 mt-0 cursorPointer"
                      onClick={() => {
                        this.setState({
                          registraintsAccOpen: !this.state.registraintsAccOpen,
                        });
                      }}
                    >
                      {/* Members which have requested for an Occasion Registration */}
                      <i className="fa fa-users" aria-hidden="true" /> Members
                    </h6>
                    {/* ***************************************Call for Members List(Registrants) Component************************************* */}
                    <Collapse isOpen={this.state.registraintsAccOpen}>
                      <div className="col-12">
                        <RegistrantsMembersList
                          listData={this.state.filteredRegistrants}
                          //reloadRegistrations={this.reloadRegistrations}
                          isloading={this.state.isloading}
                          getRegistrantQuestions={this.getRegistrantQuestions}
                          setSelectedRegistrationIds={
                            this.setSelectedRegistrationIds
                          }
                          registrantTableType={RegisTableType.ReviewRequest}
                          caption={"Registrants"}
                          registrationTitle={
                            clickedChildTemplateRow
                              ? clickedChildTemplateRow.title
                              : ""
                          }
                          registrationWindow={
                            clickedChildTemplateRow
                              ? clickedChildTemplateRow.dates
                              : ""
                          }
                        />
                        {/* How to handle Instructors */}
                        <InstructorsMembersList
                          listData={this.state.instructors}
                          //reloadRegistrations={this.reloadRegistrations}
                          isloading={this.state.isloading}
                          setSelectedInstrIds={this.setSelectedInstrIds}
                          registrationTitle={
                            clickedChildTemplateRow
                              ? clickedChildTemplateRow.title
                              : ""
                          }
                          registrationWindow={
                            clickedChildTemplateRow
                              ? clickedChildTemplateRow.dates
                              : ""
                          }
                        />
                      </div>
                    </Collapse>
                  </Card>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="card-footer text-center border-top-0">
            {this.state.registrants.some(
              (s) =>
                s.registrationStatusId == RegistrationStatuses.PendingApproval
            ) && (
              <>
                <div className="btn-group">
                  <button
                    type="button"
                    onClick={() =>
                      this.handleSubmitModal(ReviewRequestActions.Approve)
                    }
                    className="btn btn-success pr-1"
                  >
                    Approve {/* Main Button */}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Toggle Dropdown</span>{" "}
                    {/* Hidden*/}
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleSubmitModal(ReviewRequestActions.ApproveAll)
                      }
                    >
                      Approve All {/* Child Button */}
                    </a>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleSubmitModal(
                          ReviewRequestActions.ApproveAndDenyOthers
                        )
                      }
                    >
                      Approve and Deny Other {/* Child Button */}
                    </a>
                  </div>
                </div>
                <div className="btn-group">
                  <button
                    type="button"
                    onClick={() =>
                      this.handleSubmitModal(ReviewRequestActions.Deny)
                    }
                    class="btn btn-danger ml-1"
                  >
                    Deny {/* Main Button */}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Toggle Dropdown</span>{" "}
                    {/* Hidden*/}
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleSubmitModal(ReviewRequestActions.DenyAll)
                      }
                    >
                      Deny All {/* Child Button */}
                    </a>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() =>
                        this.handleSubmitModal(
                          ReviewRequestActions.DenyAndApproveOthers
                        )
                      }
                    >
                      Deny and Approve Others {/* Child Button */}
                    </a>
                  </div>
                </div>
              </>
            )}

            <button
              type="button"
              className="btn btn-primary ml-1 cancelModal"
              onClick={props.onClose}
            >
              Close
            </button>
          </div>
          {/* For showing the Questions Answered by the Registrants during making a Registration Request */}
          <ReviewRequestQuestions
            showModal={this.state.showQuestionsModal}
            onClose={() =>
              this.setState({
                showQuestionsModal: false,
              })
            }
          />
          {/* Used for Modal of SMS and Emails */}
          <CompositionModal
            showModal={this.state.showCompositionModal}
            onClose={this.hideCompositionModel}
            check={this.state.compositionModalMode}
            //addresses={this.state.emails}
            addresses={
              this.state.compositionModalMode == CompositionModalMode.Email
                ? [
                    ...(this.state.composeModalEmails || []),
                    ...(this.props.melstate.addtlMemEmails || []),
                  ]
                : this.state.composeModalPhoneNos // Phone Numbers
            }
            listType={MailGunEmailTypes.Registrants}
            entityId={this.props.clickedChildTemplateId}
            getAdditionalSubsribersEmailAddresses={
              this.props.getAdditionalSubsribersEmailAddresses
            }
            isSaveSearchFacilityCS={false}
            isBucketFilled={
              !_.isEmpty(JSON.parse(localStorage.getItem("BucketSearch")))
            }
            isFRTCS={false}
          />
        </Modal>
        {/********Email List Config Modal********/}
        <MailGunMembersListModal
          showModal={this.state.showConfigEmailMembersModal}
          onModalClose={this.hideConfigEmailModal}
          mgListAddress={clickedChildTemplateRow.mgListAddress}
          childTemplateAbbr={
            clickedChildTemplateRow.abbreviation &&
            clickedChildTemplateRow.abbreviation
          }
          TeamCheck={true}
          FacilityId={null}
          isFacility={false}
          ChildTemplateId={this.props.clickedChildTemplateId}
          isRegistration={true}
          MailGunEmailType={MailGunEmailTypes.Registrants}
          emailListId={clickedChildTemplateRow.emailListId}
          onDeleteEmailList={this.onDeleteEmailList}
          closeReviewRequestModal={this.closeReviewRequestModal}
        ></MailGunMembersListModal>
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
      melstate: state.mailGunEmail,
    }),
    {
      ...RegistrationsAction,
      ...MailGunEmailActions,
    }
  )(ReviewRegistrationRequestsModal)
);
