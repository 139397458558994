import React, { Component, Fragment } from "react";
import FinanceTable from "../../../Finance/General/FinanceTable";
import FinanceForm from "../../../Finance/General/FinanceFormModal";
import _ from "lodash";
import $ from "jquery";
import { connect } from "react-redux";
import FinanceAction from "../../../../store/finance/action";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";

class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.setFinanceFormMode("Member");
    this.props.setFinanceFormMember(this.props.MemberId);
  }
  render() {
    let { member } = this.props;

    return (
      <>
        <div className="card directory-card border-rounded shadow">
          <h5 className="card-header mt-0">
            <i className="fas fa-dollar mr-2" />
            Finance
            <span className="float-right font-weight-normal">
              {(AuthService.canOwnAdd(
                PermissionFeature.FinancesRecords,
                this.props.memberid
              ) ||
                AuthService.canMemberAdd(
                  PermissionFeature.FinancesRecords
                )) && (
                <span
                  className="text-primary c-pointer pr-1"
                  onClick={() => {
                    this.props.setFinanceFormMemberHeader(
                      member.firstname,
                      member.lastname,
                      member.facility.code,
                      member.region.code
                    );

                    this.props.openFinanceModal(null);
                  }}
                >
                  <i class="fas fa-plus"></i>
                </span>
              )}
            </span>
          </h5>
          <div className="card-body py-3 px-3">
            <FinanceTable Mode="Member" MemberId={this.props.MemberId} />
          </div>
        </div>
        <FinanceForm MemberId={this.props.MemberId} />
      </>
    );
  }
}

export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(Finance);
