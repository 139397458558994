const actions = {
  REFRESH_FINANCE_TABLE: "REFRESH_FINANCE_TABLE",
  SET_FINANCE_FORM_DATA: "SET_FINANCE_FORM_DATA",
  OPEN_FINANCE_FORM: "OPEN_FINANCE_FORM",
  CLOSE_FINANCE_FORM: "CLOSE_FINANCE_FORM",
  REFRESH_TRANSACTION_TABLE: "REFRESH_TRANSACTION_TABLE",
  OPEN_TRANSACTION_FORM: "OPEN_TRANSACTION_FORM",
  SET_TRANSACTION_FORM_FINANCE_OBJ: "SET_TRANSACTION_FORM_FINANCE_OBJ",
  CLOSE_TRANSACTION_FORM: "CLOSE_TRANSACTION_FORM",
  SET_FINANCE_FORM_MODE: "SET_FINANCE_FORM_MODE",
  SET_FINANCE_FORM_MEMBER: "SET_FINANCE_FORM_MEMBER",
  SET_FINANCE_FORM_MEMBER_HEADER_DATA: "SET_FINANCE_FORM_MEMBER_HEADER_DATA",
  CLEAR_FINANCE_FORM_MEMBER_HEADER_DATA:
    "CLEAR_FINANCE_FORM_MEMBER_HEADER_DATA",
  SET_FINANCE_FAC_EMP_FORM_MODE: "SET_FINANCE_FAC_EMP_FORM_MODE",
  SET_FINANCE_FAC_EMP_FORM_FACILITY: "SET_FINANCE_FAC_EMP_FORM_FACILITY",
  SET_FINANCE_FAC_EMP_FORM_EMPLOYER: "SET_FINANCE_FAC_EMP_FORM_EMPLOYER",
  OPEN_FINANCE_FAC_EMP_FORM: "OPEN_FINANCE_FAC_EMP_FORM",
  CLOSE_FINANCE_FAC_EMP_FORM: "CLOSE_FINANCE_FAC_EMP_FORM",
  refreshFinanceTable: (refresh = true) => (dispatch) => {
    dispatch({
      type: actions.REFRESH_FINANCE_TABLE,
      payload: refresh,
    });
  },
  refreshTransactionTable: (refresh = true) => (dispatch) => {
    dispatch({
      type: actions.REFRESH_TRANSACTION_TABLE,
      payload: refresh,
    });
  },
  setFinanceFormMemberHeader: (
    firstName = null,
    lastName = null,
    facilityCode = null,
    regionCode = null
  ) => (dispatch) => {
    dispatch({
      type: actions.SET_FINANCE_FORM_MEMBER_HEADER_DATA,
      payload: { firstName, lastName, facilityCode, regionCode },
    });
  },
  clearFinanceFormMemberHeader: () => (dispatch) => {
    dispatch({
      type: actions.CLEAR_FINANCE_FORM_MEMBER_HEADER_DATA,
      payload: null,
    });
  },
  setFinanceFormMode: (mode = null) => (dispatch) => {
    dispatch({
      type: actions.SET_FINANCE_FORM_MODE,
      payload: mode,
    });
  },
  setFinanceFacEmpFormMode: (mode = false) => (dispatch) => {
    dispatch({
      type: actions.SET_FINANCE_FAC_EMP_FORM_MODE,
      payload: mode,
    });
  },
  setFinanceFacEmpFormFacility: (
    facilityId = null,
    facilityCode = null,
    regionId = null
  ) => (dispatch) => {
    dispatch({
      type: actions.SET_FINANCE_FAC_EMP_FORM_FACILITY,
      payload: { facilityId, facilityCode, regionId },
    });
  },
  setTransactionFormFinanceObj: (obj = null) => (dispatch) => {
    dispatch({
      type: actions.SET_TRANSACTION_FORM_FINANCE_OBJ,
      payload: obj,
    });
  },

  openTransactionModal: (data = null) => (dispatch) => {
    dispatch({
      type: actions.OPEN_TRANSACTION_FORM,
      payload: data,
    });
  },
  closeTransactionModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_TRANSACTION_FORM,
    });
  },
  setFinanceFacEmpFormEmployer: (id = null, employer = null) => (dispatch) => {
    dispatch({
      type: actions.SET_FINANCE_FAC_EMP_FORM_EMPLOYER,
      payload: { id, employer },
    });
  },
  setFinanceFormMember: (memberId = null) => (dispatch) => {
    dispatch({
      type: actions.SET_FINANCE_FORM_MEMBER,
      payload: memberId,
    });
  },
  openFinanceModal: (data) => (dispatch) => {
    dispatch({
      type: actions.OPEN_FINANCE_FORM,
      payload: data,
    });
  },
  closeFinanceModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_FINANCE_FORM,
    });
  },
  openFinanceFacEmpModal: () => (dispatch) => {
    dispatch({
      type: actions.OPEN_FINANCE_FAC_EMP_FORM,
    });
  },
  closeFinanceFacEmpModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_FINANCE_FAC_EMP_FORM,
    });
  },
};

export default actions;
