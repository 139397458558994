import FacilitiesLookup from "./FacilitiesLookup";
import AUX from "../../hoc/Aux_";
import React, { Component } from "react";

class FacilityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facilityMethods: this.props.facilityMethods
        };
    }
    render() {
        return (
            <AUX>
                <div
                    className="modal fade facility-modal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="Add/Edit Postion"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Select Facility</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                                    ×
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 mb-4">
                                    {this.props.getFacilities && <FacilitiesLookup facilityMethods={this.state.facilityMethods} arg={this.props.arg} />}
                                </div>
                                <div className="col-12 text-center mb-50 mt-50 pt-4">
                                    <button
                                        className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2"
                                        data-dismiss="modal"
                                        aria-hidden="true">
                                        Cancel
                                    </button>
                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default FacilityModal;
