const actions = {
    SET_PERMISSIONS: "SET_PERMISSIONS",
    SET_SENIORITY:"SET_SENIORITY",
    setPermissions: data => dispatch => {
    dispatch({
      type: actions.SET_PERMISSIONS,
      payload: data
    });
    },
     setSeniority: (NBU,BU)=> dispatch => {
        dispatch({
            type: actions.SET_SENIORITY,
            payload: {NatcaBargainingUnitDate:NBU,BargainingUnitDate:BU}
        });
    }
};

export default actions;
