import React, { Component } from "react";

import { connect } from "react-redux";
import "rc-datepicker/lib/style.css";
import _ from "lodash";
import $ from "jquery";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import Services from "../GrievanceService";
import { PdfDocument } from "../General/GrievanceStepPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "../../Grievances/grievances.css";
import GrievanceAction from "../../../store/grievance/action";
import GrievanceCloseReasonModal from "../General/GrievanceCloseReasonModal";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode
);

class StepResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      GrievanceStepId: this.props.stepsData.stepId,
      FAANumber: "",
      selectedFiles: [],
      pdfData: {},
      showPdfComponnent: false,
      isReasonModalOpen: false,
      grievanceReason: "",
      reason_err: "",
      randomId: Math.round(Math.random() * (100 - 10) + 10),
      saveDataForModal: {},
      responseDate: new Date(),
    };
    this.cancelStep = () => {
      this.props.history.push({
        pathname: "/grievances",
        type: "list",
      });
    };
    this.updateFiles = (fileItems) => {
      try {
        this.setState({
          selectedFiles: fileItems
            .filter((el) => {
              return el.status !== 8;
            })
            .map((fileItem) => fileItem.file),
        });
      } catch (err) {
        console.log(err);
      }
    };
  }
  handleCollapse = (id) => {
    if (
      $("#label" + id)
        .text()
        .trim() == "collapse"
    ) {
      $("#collapse" + id).hide(1000);
      $("#label" + id).text("<i class='fas fa-angle-down'></i>");
    } else {
      $("#collapse" + id).show(1000);
      $("#label" + id).text("<i class='fas fa-angle-up'></i>");
    }
  };
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  componentDidMount() {
    Services.GetPDFData(
      this.props.match.params.Id,
      this.props.stepsData.stepId
    ).then((response) => {
      if (!_.isEmpty(response)) {
        this.setState({ pdfData: response.data });
      }
    });
  }
  sendBlobToServer(blob) {
    var fd = new FormData();
    fd.append("data", blob);
    Services.SavePDFOnSubmission(fd, this.props.match.params.Id);
    this.props.reloadAttachments(true);
  }
  saveWithdrawResponse = () => {
    if (this.state.grievanceReason != "") {
      var data = {
        ...this.state.saveDataForModal,
        WithdrawReason: this.state.grievanceReason,
      };
      let formdata = new FormData();
      formdata.append("data", JSON.stringify(data));
      Services.SaveStep(formdata).then((response) => {
        if (!_.isEmpty(response)) {
          Services.SaveSnapShot(data, response.stepId).then(() => {
            if (!_.isEmpty(response)) {
              toast.success("Request saved successfully");
              this.props.rerenderParentCallback();
            }
          });
        }
      });
    } else {
      this.setState({
        reason_err: "Reason For Withdrawing Grievance is required.",
      });
    }
  };
  toggleConfirmModal = () => {
    this.setState({ isReasonModalOpen: !this.state.isReasonModalOpen });
  };
  onChangeReason = (e) => {
    this.setState({ grievanceReason: e.target.value });
  };

  undoElevation() {
    var grievanceId = this.props.match.params.Id;
    Services.UndoElevation(grievanceId).then((response) => {
      if (!_.isEmpty(response)) {
        toast.success("Request processed successfully");
        window.location.reload(true);
      }
      $(".loading").hide();
    });
  }
  saveResponse(isElevated, closeStatus) {
    $(".loading").show();
    var data = {
      stepName: this.props.stepsData.stepName,
      meetingDate: this.props.stepsData.meetingDate,
      isMeetingRequired: true,
      grievanceId: this.props.match.params.Id,
      stepType: "Response",
      isAdverse: this.props.stepsData.isAdverse,
      dateOfVoilation: this.props.stepsData.dateOfVoilation,
      dateSubmitted: this.props.stepsData.dateSubmitted,
      subject: this.props.stepsData.subject,
      fAANumber: this.props.stepsData.fAANumber,
      statementOfFacts: this.props.stepsData.statementOfFacts,
      remedySought: this.props.stepsData.remedySought,
      lrRepresentativeId: this.props.stepsData.lrRepresentativeId,
      grtRepresentativeId: this.props.stepsData.grtRepresentativeId,
      representativeId: this.props.stepsData.representativeId,
      recipientId: this.props.stepsData.recipientId,
      additionalFacts: "",
      responseDate: this.state.responseDate,
      contractId: this.props.stepsData.contractId,
    };
    if (isElevated) {
    } else {
      switch (closeStatus) {
        case "Denied":
          data.statusId = 6;
          break;
        case "Sustained":
          data.statusId = 5;
          break;
        case "Withdrawn":
          data.statusId = 7;
          break;
        case "Settled":
          data.statusId = 8;
          break;
      }
    }
    let formdata = new FormData();
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      formdata.append("File" + i, this.state.selectedFiles[i]);
    }
    if (closeStatus == "Withdrawn") {
      this.setState({
        saveDataForModal: data,
        reason_err: "",
        grievanceReason: "",
        isReasonModalOpen: !this.state.isReasonModalOpen,
      });
      $(".loading").hide();
    } else {
      formdata.append("data", JSON.stringify(data));
      Services.SaveStep(formdata).then((response) => {
        if (!_.isEmpty(response)) {
          Services.SaveSnapShot(data, response.stepId).then(() => {
            if (!_.isEmpty(response)) {
              toast.success("Request saved successfully");
              this.props.rerenderParentCallback();
            }
          });
        }
        $(".loading").hide();
      });
    }
  }

  saveToArbitration() {
    $(".loading").show();
    var data = {
      stepName: this.props.stepsData.stepName,
      meetingDate: this.props.stepsData.meetingDate,
      shouldSubmitArbitration: true,
      isMeetingRequired: true,
      grievanceId: this.props.match.params.Id,
      stepType: "Response",
      isAdverse: this.props.stepsData.isAdverse,
      dateOfVoilation: this.props.stepsData.dateOfVoilation,
      dateSubmitted: this.props.stepsData.dateSubmitted,
      subject: this.props.stepsData.subject,
      fAANumber: this.props.stepsData.fAANumber,
      statementOfFacts: this.props.stepsData.statementOfFacts,
      remedySought: this.props.stepsData.remedySought,
      lrRepresentativeId: this.props.stepsData.lrRepresentativeId,
      grtRepresentativeId: this.props.stepsData.grtRepresentativeId,
      representativeId: this.props.stepsData.representativeId,
      recipientId: this.props.stepsData.recipientId,
      additionalFacts: "",
      responseDate: this.state.responseDate,
      contractId: this.props.stepsData.contractId,
    };

    let formdata = new FormData();
    for (var i = 0; i < this.state.selectedFiles.length; i++) {
      formdata.append("File" + i, this.state.selectedFiles[i]);
    }
    formdata.append("data", JSON.stringify(data));
    Services.SaveStep(formdata, true).then((response) => {
      if (!_.isEmpty(response)) {
        Services.SaveSnapShot(data, response.stepId).then(() => {
          if (!_.isEmpty(response)) {
            toast.success("Request saved successfully");
            this.props.rerenderParentCallback();
          }
        });
      }
      $(".loading").hide();
    });
  }
  render() {
    return (
      <>
        {(() => {
          if (!this.props.stepCreateMode) {
            return (
              <li>
                <div class="node green" />
                <div class="stepPara">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <div className="float-left">
                          <h5>{this.props.stepsData.stepName}</h5>
                        </div>
                      </div>
                      <div class="col-md-4">
                        Response Received {this.props.stepsData.responseDate}{" "}
                        <PDFDownloadLink
                          document={<PdfDocument data={this.state.pdfData} />}
                          fileName={this.props.stepsData.stepName + ".pdf"}
                          className="d-inline-block"
                        >
                          {({ blob, url, loading, error }) => {
                            //this.sendBlobToServer(blob);
                            return (
                              <div>
                                <i class="fas fa-file-pdf" />{" "}
                              </div>
                            );
                          }}
                        </PDFDownloadLink>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          } else {
            return (
              <li>
                <div class="node meeting" />
                <div class="stepPara">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-md-12">
                        <div className="float-left">
                          <h5>{this.props.stepsData.stepName}</h5>{" "}
                        </div>
                        <div
                          style={{
                            float: "left",
                            marginTop: "13px",
                            marginLeft: "10px",
                          }}
                        >
                          <label
                            onClick={() => {
                              this.handleCollapse(this.state.randomId);
                            }}
                            id={"label" + this.state.randomId}
                          >
                            <i class="fas fa-level-down"> </i>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        {this.props.stepsData.stepDueByDate !== "" && (
                          <p>Due By {this.props.stepsData.stepDueByDate} </p>
                        )}
                        <div
                          className="col-md-10 mb-2"
                          style={{
                            border: "1px solid darkgrey",
                            padding: "15px",
                          }}
                          id={"collapse" + this.state.randomId}
                        >
                          <div>
                            <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                              <small>Response Date:</small>
                            </span>
                            <DatePicker
                              dateForm="MM/DD/YYYY"
                              className="form-control bg-light rounded-0 "
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              onChange={(date) => {
                                if (moment(date).isValid()) {
                                  this.state.responseDate = moment(date).format(
                                    "MM/DD/YYYY"
                                  );
                                } else {
                                  this.state.responseDate = date;
                                }
                                this.setState({
                                  responseDate: this.state.responseDate,
                                });
                              }}
                              selected={
                                this.state.responseDate
                                  ? moment(this.state.responseDate).toDate()
                                  : moment(new Date()).toDate()
                              }
                              placeholderText={"MM/DD/YYYY"}
                            />
                            <span className="text-danger">{""}</span>
                          </div>

                          <div className="col-md-12">
                            <span className="">
                              Upload Response and Additional Documentation :
                            </span>
                            <FilePond
                              ref={(ref) => (this.pond = ref)}
                              files={this.props.attachedFiles}
                              allowMultiple={true}
                              allowFileTypeValidation={true}
                              allowImageExifOrientation={true}
                              allowImagePreview={true}
                              allowImageCrop={true}
                              allowFileEncode={true}
                              acceptedFileTypes={this.state.acceptedFileTypes}
                              fileValidateTypeDetectType={(source, type) =>
                                new Promise((resolve, reject) => {
                                  let rejectedExtensions = [
                                    "ade",
                                    "adp",
                                    "apk",
                                    "bat",
                                    "chm",
                                    "cmd",
                                    "com",
                                    "cpl",
                                    "dll",
                                    "dmg",
                                    "exe",
                                    "hta",
                                    "ins",
                                    "isp",
                                    "jar",
                                    "js",
                                    "jse",
                                    "lib",
                                    "lnk",
                                    "mde",
                                    "msc",
                                    "msi",
                                    "msp",
                                    "mst",
                                    "nsh",
                                    "pif",
                                    "scr",
                                    "sct",
                                    "shb",
                                    "sys",
                                    "vb",
                                    "vbe",
                                    "vbs",
                                    "vxd",
                                    "wsc",
                                    "wsf",
                                    "wsh",
                                    "cab",
                                  ];
                                  // If the file extension is not in our rejected list.
                                  if (
                                    !rejectedExtensions.some((ext) =>
                                      source.name.endsWith("." + ext)
                                    )
                                  ) {
                                    resolve(type);
                                  } else {
                                    // Otherwise it is rejected.
                                    //toast.error("File type is unsupported!");
                                    //reject(new Error('Files contains unsupported format!'));
                                    reject(type);
                                  }
                                })
                              }
                              onupdatefiles={(fileItems) => {
                                this.updateFiles(fileItems);
                              }}
                            />
                          </div>
                          <div className="col-md-12">
                            <div className="text-center">
                              <button
                                className="btn btn-dark btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                type="button"
                                onClick={() => {
                                  this.cancelStep();
                                }}
                                style={{
                                  marginRight: "30px",
                                  marginTop: "7px",
                                  height: "33px",
                                }}
                              >
                                Cancel
                              </button>

                              <div
                                class="btn-group"
                                style={{ marginLeft: "-20px" }}
                              >
                                <button type="button" class="btn btn-primary">
                                  Close Grievance
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span class="sr-only">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu">
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Denied");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Denied
                                  </a>
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Sustained");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Sustained
                                  </a>
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Withdrawn");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Withdrawn
                                  </a>
                                  {/* <div class="dropdown-divider"></div> */}
                                  <a
                                    onClick={() => {
                                      this.saveResponse(false, "Settled");
                                    }}
                                    class="dropdown-item"
                                    href="#"
                                  >
                                    Settled
                                  </a>
                                </div>
                              </div>

                              <button
                                className="btn btn-warning btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                onClick={() => {
                                  this.undoElevation();
                                }}
                                type="submit"
                                style={{
                                  marginLeft: "30px !important",
                                  marginTop: "7px",
                                  height: "33px",
                                }}
                              >
                                Undo Elevation
                              </button>
                              {[
                                "Step 2 Response",
                                "Arbitration Response",
                                "Expedited Arbitration Response",
                              ].indexOf(this.props.stepsData.stepName) ==
                                -1 && (
                                <button
                                  className="btn btn-danger btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light mb-2"
                                  onClick={() => {
                                    this.saveResponse(true, "");
                                  }}
                                  type="submit"
                                  style={{
                                    marginLeft: "30px !important",
                                    marginTop: "7px",
                                    height: "33px",
                                  }}
                                >
                                  Elevate
                                </button>
                              )}

                              {this.props.stepsData.stepName ==
                                "Step 2 Response" && (
                                <div
                                  class="btn-group"
                                  style={{ marginLeft: "15px" }}
                                >
                                  <button type="button" class="btn btn-primary">
                                    Elevate
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <span class="sr-only">Toggle Dropdown</span>
                                  </button>
                                  <div class="dropdown-menu">
                                    <a
                                      onClick={() => {
                                        this.saveResponse(true, "");
                                      }}
                                      class="dropdown-item"
                                      href="#"
                                    >
                                      PAR
                                    </a>
                                    <a
                                      onClick={() => {
                                        this.saveToArbitration();
                                      }}
                                      class="dropdown-item"
                                      href="#"
                                    >
                                      Arbitration
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <GrievanceCloseReasonModal
                      modal={this.state.isReasonModalOpen}
                      saveWithdrawnResponse={this.saveWithdrawResponse}
                      reason={this.state.grievanceReason}
                      toggle={this.toggleConfirmModal}
                      onChangeReason={this.onChangeReason}
                      reason_err={this.state.reason_err}
                    />
                  </div>
                </div>
              </li>
            );
          }
        })()}
      </>
    );
  }
}

export default withRouter(
  connect((state) => ({ grievanceState: state.grievances }), {
    ...GrievanceAction,
  })(StepResponse)
);
