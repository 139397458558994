import React, { Component } from "react";
import BorderedSection from "../../Shared/borderedSection";
import AddIcon from "@material-ui/icons/AddCircle";
import { MDBInput } from "mdbreact";
import AreasService from "./AreasService";
import _ from "lodash";
import { toast } from "react-toastify";

class AreaForm extends Component {
  onSubmit = (evt) => {
    evt.preventDefault();

    if (this.customValidation()) {
      var formdata = {
        Id: this.props.areaState.areaId,
        code: this.props.areaState.areaCode,
        description: this.props.areaState.areaDescription,
        facilityid: this.props.FacilityId,
      };

      this.saveArea(formdata);
    }
  };

  async saveArea(formdata) {
    let response = await AreasService.SaveArea(formdata);
    if (!_.isEmpty(response)) {
      this.props.reloadListing();
      this.props.onClear();
    }
  }

  customValidation = () => {
    var isValid = true;

    if (
      _.isEmpty(this.props.areaState.areaCode.trim()) ||
      _.isEmpty(this.props.areaState.areaDescription.trim())
    ) {
      isValid = false;
      toast.error("All fields area mandatory.");
    }
    else if (this.checkAreaCodeDuplication()) {
      // area id duplication check
      isValid = false;
      toast.error("Area code already exists.");
    }

    return isValid;
  };

  checkAreaCodeDuplication = () => {
    return this.props.areasList.some((area) => {
      return ((area.code.toLowerCase() === this.props.areaState.areaCode.trim().toLowerCase()) && (area.id !== this.props.areaState.areaId));
    })
  };

  render() {
    return (
      <BorderedSection icon={AddIcon} title="Add or Edit">
        <form
          className="needs-validation"
          id="areaForm"
          onSubmit={this.onSubmit}
          noValidate
        >
          <div className="row">
            <div className="col-lg-9 mt-3 text_ctrl">
              <div className="row">
                <div className="col-lg-4 text_ctrl">
                  <div className="form-group">
                    <MDBInput
                      id="areaCode"
                      value={this.props.areaState.areaCode}
                      name="areaCode"
                      onChange={this.props.valueChangeHandler}
                      label="Area ID"
                      hint="Enter Area Code..."
                      group
                      type="text"
                      disabled={false}
                      required
                    >
                      <div className="invalid-feedback">Required</div>
                    </MDBInput>
                  </div>
                </div>
                <div className="col-lg-8 text_ctrl">
                  <div className="form-group">
                    <MDBInput
                      id="areaDescription"
                      value={this.props.areaState.areaDescription}
                      name="areaDescription"
                      onChange={this.props.valueChangeHandler}
                      label="Area Name"
                      hint="Enter Area Description..."
                      group
                      type="text"
                      disabled={false}
                      required
                    >
                      <div className="invalid-feedback">Required</div>
                    </MDBInput>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-3">
              <div className="row">
                <div className="col-12 m-t-20 mx-auto text-center">
                  {
                    <>
                      <button
                        className="btn btn-success w-md mc mr-2"
                        disabled={""}
                        type="submit"
                      >
                        Submit
                      </button>
                      <button
                        className="btn btn-primary w-md waves-effect cancelModal waves-light mr-2  mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                        type="reset"
                        onClick={this.props.onClear}
                      >
                        Clear
                      </button>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </BorderedSection>
    );
  }
}

export default AreaForm;
