import React, { Component } from "react";
import { MDBInput } from "mdbreact";
import moment from "moment";
import _ from "lodash";

class GrievanceComments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
    };
  }

  render() {
    return (
      <>
        <div className="container h-100">
          {this.props.commentsData && this.props.commentsData.length < 1 ? (
            <div className="row rowLabelCenter">
              <h5>No Comments Found!</h5>
            </div>
          ) : (
            <>
              {!_.isEmpty(this.props.commentsData) &&
                this.props.commentsData.map((item) => (
                  <div className="row py-1">
                    <div className="col-4 px-2 inlineDiv">
                      {moment(item.actionDate).isValid()
                        ? moment(item.actionDate).format("MM/DD/YY")
                        : ""}
                    </div>
                    <div className="col-4 px-2 inlineDiv">
                      <b>{item.memberName}</b>
                    </div>
                    <div className="col-4 px-2 inlineDiv">{item.comments}</div>
                  </div>
                ))}
            </>
          )}
          { ( this.props.disableComments  && <div className="row mt-3">
            <div className="col-md-8">
              <MDBInput
                name=""
                containerClass="my-0"
                label="Comment"
                value={this.state.comment}
                type="text"
                onChange={(e) => {
                  this.setState({ comment: e.currentTarget.value });
                }}
                required
              />
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 waves-effect waves-light mb-2"
                onClick={() => {
                  if (this.state.comment !== "") {
                    this.props.submitComment(this.state.comment);
                    this.setState({ comment: "" });
                  }
                }}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>)}
          
        </div>
      </>
    );
  }
}

export default GrievanceComments;
