import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import registrationsAction from "../../../../../store/registrations/action";
import { RegisWizardSteps } from "../../../Helpers/LookUpValues";

class StepOne extends Component {
  render() {
    let idx = this.props.steps.findIndex(
      (i) => i.key == RegisWizardSteps.FirstStep
    );
    const { currentStep } = this.props;
    if (currentStep !== idx) {
      // Prop: The current step
      return null;
    }
    const { selectedOpenRegRow } = this.props.registrationsState || {};
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                <small>Title</small>
              </span>
              <b className="h5 text-secondary d-block">
                {selectedOpenRegRow.title}
              </b>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                <small> Dates</small>
              </span>
              <b className="h5 text-secondary d-block">
                {selectedOpenRegRow.dates}
              </b>

              <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                <small> Window</small>
              </span>
              <b className="h5 text-secondary d-block">
                {selectedOpenRegRow.registrationWindow}
              </b>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-6">
              <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                <small> Location</small>
              </span>
              <b className="h5 text-secondary d-block">
                {selectedOpenRegRow.location}
              </b>
            </div>
            <div className="col-md-6">
              <span className="mb-1 h6 font-weight-normal text-secondary d-block">
                <small> Category</small>
              </span>
              <b className="h5 text-secondary d-block">
                {selectedOpenRegRow.category}
              </b>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      registrationsState: state.registrations,
    }),
    {
      ...registrationsAction,
    }
  )(StepOne)
);
