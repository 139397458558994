import React, { Component } from 'react'

export default class TrainedActivists extends Component {
    render() {
        return (
            <div>
                <h5>Trained Activists</h5>
            </div>
        )
    }
}
