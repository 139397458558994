import React, { Component } from "react";
import ActivityLoggingComponent from "../../Shared/ActivityLogging";
import AuditModuleTypes from "../../Shared/AuditModuleTypes";
import _ from "lodash";
import { Card, CardBody, Collapse } from "reactstrap";

export class FE_ActivityLogging extends Component {
  state = {
    logRecordId: 0,
  };
  fetchLogs = () => {
    const { id } = this.props;
    if (id) {
      this.props.toggleEquipmentLogAccordion(
        !this.props.festate.equipmentLoggingVisible
      );
      this.setState({ logRecordId: id }, () => {
        this.props.festate.equipmentLoggingVisible &&
          this.props.getActicityLoggingByModuleId(
            AuditModuleTypes.FETracker,
            id,
            0,
            10,
            "equipment"
          );
      });
    }
  };

  refreshLogs = (Id) => {
    if (Id) {
      this.props.toggleEquipmentLogAccordion(true);
      this.setState({ logRecordId: Id });
      this.props.getActicityLoggingByModuleId(
        AuditModuleTypes.FETracker,
        Id,
        0,
        10,
        "equipment"
      );
    }
  };
  render() {
    return (
      <>
        {/* ActivityLogging Work */}
        <Card>
          {/* Card Header */}

          <h6 className="card-header cursorPointer" onClick={this.fetchLogs}>
            <i class="fa fa-history" aria-hidden="true"></i> ACTIVITY LOGGING
            <i
              className={`pull-right float-right ${
                this.props.festate.equipmentLoggingVisible
                  ? "fas arrow-icon fa-chevron-down"
                  : "fas arrow-icon fa-chevron-up"
              }`}
            ></i>
          </h6>

          <Collapse
            className="memberCardCollapse"
            // style={{ transition: "height 2s ease" }}
            isOpen={this.props.festate.equipmentLoggingVisible}
          >
            <CardBody className="pl-1 pr-2">
              <ActivityLoggingComponent recordId={this.state.logRecordId} />
            </CardBody>
          </Collapse>
        </Card>
        {/* ActivityLogging Work */}
      </>
    );
  }
}
export default FE_ActivityLogging;
