import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import ChangePasswordForm from "../Auth/ChangePasswordForm";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h1 className="page-title">Change Password</h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mx-auto">
                            <div className="card">
                                <div className="card-body">
                                    <ChangePasswordForm type="account" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default ChangePassword;
