import Aux from "../../hoc/Aux_";
import TopBar from "./TopBar";
import Footer from "./Footer";
import SideBar from "./SideBar";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthConsumer } from "../../Auth/Auth";
import { isMobile } from "../../modules/Shared/Helpers";
import { TopSearchBar } from "../../components/Layout/TopSearchBar";
import { connect } from "react-redux";
import actionFilter from "../../store/advancedfilter/action";
import actionSaveSearch from "../../store/saveSearch/action";
import AuthService from "../../modules/Auth/AuthService";
import PermissionFeature from "../../modules/Permissions/PermissionFeature";
class layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRightbar: false,
    };
    this.toggleSidebarCallback = this.toggleSidebarCallback.bind(this);
    this.closeSidebarCallback = this.closeSidebarCallback.bind(this);
    this.removeSidebarOpenedCallback = this.removeSidebarOpenedCallback.bind(
      this
    );
    this.addSidebarOpenedCallback = this.addSidebarOpenedCallback.bind(this);
  }

  toggleSidebarCallback = () => {
    this.setState({ isRightbar: !this.state.isRightbar });
    document.body.classList.toggle("enlarged");
    if (this.props.filterState.setSidebarState) {
      this.props.setSidebarState(false);
    } else {
      this.props.setSidebarState(true);
    }
  };

  removeSidebarOpenedCallback = () => {
    if (document.body.classList.contains("sidebarOpened")) {
      document.body.classList.toggle("sidebarOpened");
      this.toggleSidebarCallback();
    }
  };

  addSidebarOpenedCallback = () => {
    if (!document.body.classList.contains("sidebarOpened")) {
      document.body.classList.toggle("sidebarOpened");
      this.toggleSidebarCallback();
    }
  };

  closeSidebarCallback = () => {
    if (this.props.filterState.setSidebarState) {
      this.toggleSidebarCallback();
    }
  };
  SetSideBarHeight = () => {
    const element = document.getElementById("sidebar-menu");
    const element1 = document.getElementById("sideNav-scroll");
    var isSidebar = element.clientWidth > 80 ? true : false;
    if (isSidebar) {
      const element2 = document.getElementById("PayfileIndicatorStatus");
      const element3 = document.getElementById("sidemenu-container");
      element2.style.display = "flex";

      // Get the heights of the elements
      const height1 = element3.clientHeight;
      const height2 = element2.clientHeight;

      // Calculate the height difference
      const heightDifference = height1 - height2 - 20;

      if (element1) {
        // Set the max height of the first element
        element1.style.maxHeight = heightDifference + "px";
        element.style.overflowY = "unset";
      } else {
        if (element) {
          // Set the max height of the first element
          element.style.maxHeight = heightDifference + "px";
          element.style.overflowY = "scroll";
        }
      }
    } else {
      const element2 = document.getElementById("PayfileIndicatorStatus");
      element2.style.display = "none";

      if (element) {
        // Set the max height of the first element
        element.style.maxHeight = "none";
        element.style.overflowY = "scroll";
      }
      if (element1) {
        // Set the max height of the first element
        element1.style.maxHeight = "none";
        element.style.overflowY = "hidden";
      }
    }
  };
  componentDidMount() {
    if (window.innerWidth < 1025) {
      this.props.setSidebarState(false);
    } else {
      this.props.setSidebarState(true);
    }
    let loggeduser = AuthService.getProfile();
    // document.body.classList.toggle('enlarged');
  }
  render() {
    return (
      <Aux>
        <div className="loading">Loading&#8230;</div>
        {!this.props.loginpage ? (
          <main>
            <div id="wrapper">
              {this.props.topbar ? (
                <TopBar
                  toggleSidebarCallback={this.toggleSidebarCallback}
                  history={this.props.history}
                  setAdvancedSearch={this.props.setAdvancedSearch}
                  setBasicSearchKeyWord={this.props.setBasicSearchKeyWord}
                  setFilterResultPageNo={this.props.setFilterResultPageNo}
                  setFilterResultPageSize={this.props.setFilterResultPageSize}
                  setMemberAccordianType={this.props.setMemberAccordianType}
                  setFilterData={this.props.setFilterData}

                  //isSavedSearchSelected={this.props.isSavedSearchSelected}
                  // directoryMenuState={this.props.filterState.directoryMenuState}
                />
              ) : null}
              <nav className="navbar-custom-searchbox display-none">
                {AuthService.canView(PermissionFeature.Member) && (
                  <TopSearchBar
                    history={this.props.history}
                    setAdvancedSearch={this.props.setAdvancedSearch}
                    setBasicSearchKeyWord={this.props.setBasicSearchKeyWord}
                    basicSearchKeyWord={
                      this.props.filterState.basicSearchKeyWord
                    }
                    setFilterResultPageNo={this.props.setFilterResultPageNo}
                    setFilterResultPageSize={this.props.setFilterResultPageSize}
                    setMemberAccordianType={this.props.setMemberAccordianType}
                    setFilterData={this.props.setFilterData}
                    isSavedSearchSelected={this.props.isSavedSearchSelected}
                    // directoryMenuState={this.props.filterState.directoryMenuState}
                  />
                )}
              </nav>
              {this.props.sidebar ? (
                <SideBar
                  isEnlarged={this.state.isRightbar}
                  toggleSidebarCallback={this.toggleSidebarCallback}
                  closeSidebarCallback={this.closeSidebarCallback}
                  removeSidebarOpenedCallback={this.removeSidebarOpenedCallback}
                  addSidebarOpenedCallback={this.addSidebarOpenedCallback}
                  SetSideBarHeight={this.SetSideBarHeight}
                />
              ) : null}
              <div className="content-page">
                <div className="content">{this.props.children}</div>
                {this.props.footer ? <Footer /> : null}
              </div>
            </div>
          </main>
        ) : (
          this.props.children
        )}
      </Aux>
    );
  }
}

// export default withRouter(layout);

export default withRouter(
  connect(
    (state) => ({
      filterState: state.advancedFilter,
      SavedSearch: state.saveSearch,
      formValues: state.form,
      currentState: state,
    }),
    { ...actionFilter, ...actionSaveSearch }
  )(layout)
);

// export default connect(
//     state => ({ filterState: state.advancedFilter, formValues: state.form, currentState: state }),
//     { ...actionFilter }
// )(layout);
