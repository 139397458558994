import React, { Component, Fragment } from "react";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import AuthService from "../../../Auth/AuthService";
import PermissionFeature from "../../../Permissions/PermissionFeature";
import TableCustomTotal from "../../../Shared/TableCustomTotal";
import EditUserAction from "../../../../store/EditUser/action";
import moment from "moment";
import _ from "lodash";
import $ from "jquery";
import Services from "./EditUserService";
class ReCalculateSeniority extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      sizePerPageJobs: 10,
      newSeniority: null,
      loader: false,
    };

    this.jobsColumns = [
      {
        text: "Facility ID",
        dataField: "facility[code]",
        //sort: true,
        classes: "c-pointer",
        hidden:
          !AuthService.canOwnView(
            PermissionFeature.FacilityCode,
            this.props.memberid
          ) && !AuthService.canMemberView(PermissionFeature.FacilityCode),
        formatter: (cellContent, row) => {
          return row.facility ? row.facility.code : "-";
        },
      },
      {
        text: "Unit",
        dataField: "busto",
        //sort: true,
        classes: "c-pointer",
        hidden:
          !AuthService.canOwnView(
            PermissionFeature.FinancialUnit,
            this.props.memberid
          ) && !AuthService.canMemberView(PermissionFeature.FinancialUnit),
      },
      {
        text: "Start Date",
        dataField: "startdate",
        classes: "c-pointer",
        //sort: true,
        formatter: (cellContent, row) => {
          return moment(row.startdate).isValid()
            ? moment(row.startdate).format("MM/DD/YYYY")
            : "-";
        },
      },
      {
        text: "End Date",
        dataField: "enddate",
        classes: "c-pointer",
        //sort: true,
        formatter: (cellContent, row) => {
          return moment(row.enddate).isValid()
            ? moment(row.enddate).format("MM/DD/YYYY")
            : "-";
        },
      },
      {
        text: "NATCA BU",
        dataField: "natcabargainingunitdate",
        classes: "c-pointer",
        formatExtraData: JSON.stringify(this.state.newSeniority),

        //sort: true,
        formatter: (cellContent, row, rowIndex) => {
          let divClass = "";
          if (rowIndex == 0) {
            if (!_.isEmpty(this.state.jobs) && this.state.jobs.length > 1) {
              if (
                (moment(this.state.jobs[0].natcabargainingunitdate).isValid()
                  ? moment(this.state.jobs[0].natcabargainingunitdate).format(
                      "MM/DD/YYYY"
                    )
                  : "") !=
                (moment(
                  this.props.editUserState.NatcaBargainingUnitDate
                ).isValid()
                  ? moment(
                      this.props.editUserState.NatcaBargainingUnitDate
                    ).format("MM/DD/YYYY")
                  : "")
              ) {
                divClass = " bg-success text-white";
              }
            } else if (
              !_.isEmpty(this.state.jobs) &&
              this.state.jobs.length == 1
            ) {
              divClass = " bg-success text-white";
            }
          }

          return (
            <div className={"d-block px-1 mx-n1" + divClass}>
              {moment(row.natcabargainingunitdate).isValid()
                ? moment(row.natcabargainingunitdate).format("MM/DD/YYYY")
                : "-"}
            </div>
          );
        },
      },
      {
        text: "BU",
        dataField: "bargainingunitdate",
        classes: "c-pointer",
        formatExtraData: JSON.stringify(this.state.newSeniority),
        //sort: true,
        formatter: (cellContent, row, rowIndex) => {
          let divClass = "";
          if (rowIndex == 0) {
            if (!_.isEmpty(this.state.jobs) && this.state.jobs.length > 1) {
              if (
                (moment(this.state.jobs[0].bargainingunitdate).isValid()
                  ? moment(this.state.jobs[0].bargainingunitdate).format(
                      "MM/DD/YYYY"
                    )
                  : "") !=
                (moment(this.props.editUserState.BargainingUnitDate).isValid()
                  ? moment(this.props.editUserState.BargainingUnitDate).format(
                      "MM/DD/YYYY"
                    )
                  : "")
              ) {
                divClass = " bg-success text-white";
              }
            } else if (
              !_.isEmpty(this.state.jobs) &&
              this.state.jobs.length == 1
            ) {
              divClass = " bg-success text-white";
            }
          }
          return (
            <div className={"d-block px-1 mx-n1" + divClass}>
              {moment(row.bargainingunitdate).isValid()
                ? moment(row.bargainingunitdate).format("MM/DD/YYYY")
                : "-"}
            </div>
          );
        },
      },

      {
        text: "Manager",
        dataField: "issupervisor",
        classes: "c-pointer",
        //sort: true,
        formatter: (cellContent, row) => {
          return row.isSupervisor || row.isTempSupervisor ? "T" : "F";
        },
      },
    ];
    this.jobSizePerPageChange = this.jobSizePerPageChange.bind(this);
    this.RemotePagination = this.RemotePagination.bind(this);
    this.reCalculateJobsSeniority = this.reCalculateJobsSeniority.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  async reCalculateJobsSeniority() {
    await this.setState({
      loader: true,
    });
    let data = await Services.ReCalculateJobSeniority(this.props.memberId);
    if (!_.isEmpty(data)) {
      let jobs = _.cloneDeep(this.props.jobsHistory);
      //debugger;
      let index = jobs.findIndex((x) => x.id == data.id);
      if (index > -1) {
        try {
          jobs.splice(index, 1);
        } catch (e) {
          jobs.splice(0, 1);
        }
      } else {
        jobs.splice(0, 1);
      }

      await this.setState({
        jobs: [data, ...jobs],
        newSeniority: data,

        loader: false,
      });
    } else {
      if (
        this.props.editUserState.NatcaBargainingUnitDate ||
        this.props.editUserState.BargainingUnitDate
      ) {
        this.props.setSeniority(null, null);
        this.props.getJobs();
      }

      await this.setState({ jobs: [], newSeniority: null, loader: false });
    }
  }
  openModal() {
    this.setState({
      openModal: true,
    });
    this.props.closeReCalculateSeniorityModal();
  }
  componentDidUpdate(prevprops) {
    if (this.props.openReCalculateSeniorityModal) {
      this.reCalculateJobsSeniority();
      this.openModal();

      this.setState({
        JobHistory: this.props.JobHistory,
      });
    }
  }
  closeModal() {
    this.setState({
      openModal: false,
    });
  }
  async submitForm() {
    $(".loading").show();
    if (this.state.newSeniority != null) {
      let {
        id,
        facilityid,
        busto,
        startdate,
        enddate,
        bargainingunitdate,
        natcabargainingunitdate,
        isSupervisor,
      } = this.state.newSeniority;

      let data = await Services.SaveJobSeniority({
        Member_Id: this.props.memberId,
        SH: {
          jobhistoryid: id,
          facilityid,
          bargainingunit: busto,
          startdate,
          enddate,
          bargainingunitdate,
          natcabargainingunitdate,
          issupervisor: isSupervisor,
        },
      });
      if (data) {
        this.props.setSeniority(natcabargainingunitdate, bargainingunitdate);
        this.props.getJobs();
        this.closeModal();
      }
    }
    $(".loading").hide();
  }
  jobSizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPageJobs: sizePerPage });
  };
  RemotePagination(columns, keyFields, data, SizePerPageChange, sizePerPage) {
    let paginationOptions = {
      totalSize: data && data.length,
      paginationTotalRenderer: TableCustomTotal,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      sizePerPage: 10,
    };
    return (
      <>
        <div>
          {!_.isEmpty(data) && (
            <BootstrapTable
              keyField={keyFields}
              data={data}
              columns={columns}
              className="table mb-0"
              headerClasses="text-nowrap"
              rowClasses={(row, rowIndex) => {
                let classes = null;

                if (rowIndex == 0) {
                  classes = "bg-light";
                }

                return classes;
              }}
              sort={{ dataField: "startdate", order: "desc" }}
              pagination={
                data.length > sizePerPage
                  ? paginationFactory({ ...paginationOptions })
                  : false
              }
            />
          )}
        </div>
      </>
    );
  }

  render() {
    return (
      <Modal
        centered={true}
        autoFocus={true}
        isOpen={this.state.openModal}
        className="errorignoremodel styled-modal"
        backdrop="static"
        id="ReCalculateSeniority"
      >
        <ModalHeader
          className="modal-header bg-secondary py-2"
          toggle={this.closeModal}
        >
          ReCalculate Seniority
        </ModalHeader>
        <ModalBody>
          <div className="pb-2">
            The first row of the following data contains new calculation of
            NATCA BU and BU dates. Click accept to apply changes.
          </div>
          {!_.isEmpty(this.state.jobs) &&
            !this.state.loader &&
            this.RemotePagination(
              this.jobsColumns,
              "id",
              this.state.jobs,
              this.jobSizePerPageChange,
              this.state.sizePerPageJobs
            )}
          {this.state.loader && (
            <div className="w-100 text-center">
              <div class="spinner-border text-info" role="status">
                <span class="sr-only">Data is loading...</span>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <div class="text-right">
            <Button
              className="btn btn-secondary btn-x-sm btn-sm w-md waves-effect waves-light mr-2"
              onClick={this.closeModal}
            >
              Cancel
            </Button>

            <button
              class="btn btn-success btn-x-sm btn-sm ml-2 w-md waves-effect waves-light plr-2"
              type="submit"
              onClick={this.submitForm}
            >
              Accept
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    editUserState: state.editUser,
  }),
  { ...EditUserAction }
)(ReCalculateSeniority);
