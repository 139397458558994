export default {
  //"TransactionDetailID",
  //"FinanceTransactionHeaderID",
  //"TransactionDate",
  //"Amount",
  //"TypeCode",
  //"TypeName",
  //"Period",
  //"Year",
  //"AmountNumber",
  //"DetailRecordID",
  //"ExpectedAmount",
  //"Refund",
  //"MemberPersonID",
  //"MemberPersonName",
  //"FinanceCheckNumber",
  //"UWBatchID",
  //"BatchName",
  //"FinanceHeaderRecordID",
  //"JobID",
  //"FinanceNotes",
  //"FinanceCreatedOn",
  //"UserCreatedBy",
  //"FacilityID",
  //"FacilityCode",
  //"FacilityName",
  //"CommitteeID",
  //"CommitteeCode",
  //"CommitteeName",
  //"RegionID",
  //"RegionCode",
  //"RegionName",
  //"Source",
  //"BatchID"
  DUES: [
    {
      name: "YYPPxxx",
      start: 0,
      width: 11,
      regex: /^\d{7}$/,
    },
    //the Width of column set based on new requirement. If it will require in future we can simply uncomment it but keep in mind that we need to change parsing
    //{
    //  name: "ORG CD.",
    //  start: 11,
    //  width: 8,
    //},
    {
      name: "LNAME, FNAME",
      start: 20,
      width: 27,
      regex: /.+(,).+$/,
    },
    {
      name: "AMOUNT",
      start: 47,
      width: 8,
      regex: /^\d{2,}$/,
    },
    {
      name: "ECI",
      start: 56,
      width: 10,
      regex: /^\d{6,}$/,
    },
    {
      name: "NOTES",
      start: 67,
      width: 30,
    },
  ],
  PAC_DRF_NCF: [
    {
      name: "YYPPxx",
      start: 0,
      width: 11,
      regex: /^\d{6}$/,
    },
    {
      name: "REGION",
      start: 11,
      width: 9,
      regex: /^[A-Z]{2,3}$/,
    },
    {
      name: "LNAME, FNAME",
      start: 20,
      width: 26,
      regex: /.+(,).+$/,
    },
    {
      name: "AMOUNT",
      start: 56,
      width: 10,
      regex: /^\d{2,}$/,
    },
    {
      name: "ECI",
      start: 66,
      width: 10,
      regex: /^\d{6,}$/,
    },
  ],
};

// export default [
//   "Employee Common ID",
//   "BUS To",
//   "BUS From",
//   "Nature of Action 1",
//   "Nature of Action 2",
//   "Begin Date",
//   "Organization Code",
//   "Facility Routing Symbol From",
//   "Facility Routing Symbol To",
//   "Supervisor Stat",
//   "Supervisor Level Code",
//   "Supervisor Level Description",
// ];
