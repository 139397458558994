import React, { Component } from "react";
import classnames from "classnames";
import "react-image-lightbox/style.css";
import Gallery from "react-grid-gallery";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import FE_NonImage_Files from "./FE_NonImage_Files";
import { FaTrashAlt } from "react-icons/fa";
import DeleteModal from "../../Dashboard/FacilityDashboard/Modals/DeleteModal";
export default class FE_Files extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      currentImage: -1,
      isConfirmModalOpen: false,
      NonImageFile: "/",
      currentView: "",
    };
    this.deleteImage = this.deleteImage.bind(this);

    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.gallaryComponent = React.createRef();
    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.currentImage === -1 && this.state.NonImageFile === "/") {
          return false;
        }

        const { data } = this.props;

        if (this.state.activeTab === "1") {
          var delImg = data
            .filter(
              (item) => item.filePath.match(/\.(jpeg|jpg|gif|png)$/) != null
            )
            .find((el, index) => {
              return index === this.state.currentImage;
            });

          delImg &&
            this.props.deleteFile(
              this,
              delImg.filePath.split("assets/").pop(),
              delImg.id
            );
        } else if (this.state.activeTab === "2") {
          var delImg = data.find((el, index) => {
            return el.filePath.split("build")[1] === this.state.NonImageFile;
          });

          delImg &&
            this.props.deleteFile(
              this,
              delImg.filePath.split("assets/").pop(),
              delImg.id
            );
        }
      };
      return this;
    };
  }
  toggleConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  deleteImage() {
    this.toggleConfirmModal();
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onClickThumbnailHandler = (e) => {
    console.log(e);
  };
  onCurrentImageChange(index) {
    console.log("current image index ", index);
    this.setState({ currentImage: index });
  }

  componentDidMount() {
    this.toggle("1");
  }

  deleteFileAttach = (path) => {
    this.setState({ NonImageFile: path });
    this.toggleConfirmModal();
  };
  getImages = (data) => {
    var myImages = [];
    try {
      myImages =
        typeof data !== "undefined"
          ? data
              .filter(
                (item) => item.filePath.match(/\.(jpeg|jpg|gif|png)$/) !== null
              )
              .map((item) => ({
                src: `/assets${item.filePath.split("assets")[1]}`,
                thumbnail: `/assets${item.filePath.split("assets")[1]}`,
                thumbnailWidth: 320,
                thumbnailHeight: 212,
              }))
          : [];
    } catch (err) {
      console.log(err);
    }

    return myImages;
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const { data } = this.props;
    const self = this;
    // console.log("data is ", JSON.stringify(data));
    return (
      <div>
        <Modal
          centered={true}
          className="modal-lg"
          role="dialog"
          autoFocus={true}
          isOpen={this.props.modal}
          toggle={this.props.toggle}
          backdrop="static"
        >
          <ModalHeader
            toggle={this.props.toggle}
            className="AttachmentsHeader font-italic"
          >
            <div className="headerColor align-items-center d-flex">
              {/* <div className="col-sm-3 pl-0 pr-0">
                <h5 className="mt-0">Attachments</h5>
              </div>
              <div className="col-sm-9 float-right text-right pl-0 pr-0 mt-0">
                <span>{this.props.currentEqUserAttachments}</span>
              </div> */}
              <span>{this.props.currentEqUserAttachments}</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Images
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Files
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Gallery
                      ref="GallaryLightBox"
                      customControls={[
                        <button
                          key="deleteImage"
                          className="customDelete"
                          onClick={this.deleteImage}
                        >
                          {" "}
                          <FaTrashAlt size={16} />
                        </button>,
                      ]}
                      currentImageWillChange={this.onCurrentImageChange}
                      enableImageSelection={false}
                      images={this.getImages(data)}
                    />

                    {data === undefined ||
                      (data.filter(
                        (item) =>
                          item.filePath.match(/\.(jpeg|jpg|gif|png)$/) !== null
                      ).length === 0 && (
                        <div class="container h-100">
                          <div class="row spinnerFacilityCategory">
                            <h6>No Images Attached!</h6>
                          </div>
                        </div>
                      ))}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Col>
                  <FE_NonImage_Files
                    files={
                      data &&
                      data
                        .filter(
                          (item) =>
                            item.filePath.match(/\.(jpeg|jpg|gif|png)$/) ===
                            null
                        )
                        .map((item) => ({
                          src: `/assets${item.filePath.split("assets")[1]}`,
                        }))
                    }
                    deleteFile={this.deleteFileAttach}
                  />
                </Col>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
        <DeleteModal
          confirm={this.confirm}
          modal={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
        />
      </div>
    );
  }
}
