import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
import TableCustomTotal from '../Shared/TableCustomTotal';
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const { SearchBar } = Search;
const columns = [
    {
        text: "Area Name",
        dataField: "description",
        sort: true
    },
    {
        text: "Code",
        dataField: "code",
        sort: true
    },
    {
        text: "Facility",
        dataField: "facility",
        sort: true
    }
];

class AreasLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            facilityid: this.props.facilityid,
            areaMethods: this.props.areaMethods
        };
        this.rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.state.areaMethods().updateArea(row);
            }
        };
    }

    componentWillMount() {
        $(".loading").show();
        axios.get("/api/Areas").then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
                this.setState({ rows: data.data });
                $(".loading").hide();
            } else {
                $(".loading").hide();
                toast.error(data.message);
            }
        });
    }
    render() {
        return (
            <AUX>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">Areas</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <ToolkitProvider keyField="id" data={this.state.rows} columns={columns} search>
                                {props => (
                                    <Fragment>
                                        <div className="d-flex justify-content-center mb-2">
                                            <div className="col-6 text-center app-search">
                                                <SearchBar className="form-control mb-1 w-100" {...props.searchProps} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <BootstrapTable
                                                    striped
                                                    hover
                                                    pagination={paginationFactory({ paginationTotalRenderer: TableCustomTotal })}
                                                    {...props.baseProps}
                                                    rowEvents={this.rowEvents}
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </ToolkitProvider>
                        </div>
                    </div>
                </div>
            </AUX>
        );
    }
}

export default AreasLookup;
