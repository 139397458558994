const actions = {
  SET_IS_REPRESENTATIVE: "SET_IS_REPRESENTATIVE",
  TOGGLE_GRIEVANCE_LOG_CARD: "TOGGLE_GRIEVANCE_LOG_CARD",
  RELOAD_ATTACHMENTS: "RELOAD_ATTACHMENTS",

  setRepresentative: (isRepresentative) => (dispatch) => {
    dispatch({
      type: actions.SET_IS_REPRESENTATIVE,
      payload: isRepresentative,
    });
  },
  reloadAttachments: (isReloadAttachments) => (dispatch) => {
    dispatch({
      type: actions.RELOAD_ATTACHMENTS,
      payload: isReloadAttachments,
    });
  },
  toggleGrievanceLogAccordion: (val) => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_GRIEVANCE_LOG_CARD,
      payload: val,
    });
  },
};

export default actions;
