import React, { Component, Fragment } from "react";
import AUX from "../../../../hoc/Aux_";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import registrationAction from "../../../../store/registrations/action";
import $ from "jquery";
import { MDBInput } from "mdbreact";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "moment/locale/en-ca";
import RegistrationsService from "../../RegistrationsService";
import { Modal, Card, Table, Accordion, Button } from "react-bootstrap";

const initialState = {
    id: -1,
    code: "",
    code_err: "",
    amount: 0,
    amount_err: "",
    type: {key: 1, val: "Percentage"},
};

class AddDiscountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      formTitle: "Add Discount Code",
      typeOptions: [
        { key: 1, val: "Percentage" },
        { key: 2, val: "Fixed" },
      ]
    };
    this.onShow = this.onShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.customValidation = this.customValidation.bind(this);
  }

  componentWillUnmount() {}

  async componentDidMount() {
  }

  loadPreviousData = async (id) => {
    let data = await RegistrationsService.GetDiscountCodeById(id);

    this.loadStateProperties(data);
  };

  loadStateProperties = async (data) => {
    if (data != null) {

        let type = 1;

        if (_.isEmpty(data.type) && data.type != 0) {
            type = this.state.typeOptions.find((el, index) => {
            if (el.key == data.type) return el;
            });
        }      

        await this.setState({
            id: this.props.registrationState.discountObj.discountId ? this.props.registrationState.discountObj.discountId : -1,
            code: data.code,
            type: type,
            amount: data.amount.toString(),        
        });
    } 
    else {
        this.setState(initialState);
    }
  };

  componentDidUpdate(prevProps) {}

  valueChangeHandler = (evt) => {
    this.setState({
        [evt.target.name]: evt.target.value,
        [evt.target.name + "_err"]: "",
    });
  };

  dropdownChangeHandler = (evt, value, field) => {
    this.setState({
      [field]: value,
    });
  };

  amountChangeHandler = (evt) => {
    if (evt.target.value != "") {
      var expression = /^[\d.,]+$/;
      if (!expression.test(evt.target.value)) {
        this.state.amount_err =
          "Invalid amount entered. Only numbers are allowed.";
      } else {
        this.setState({
            amount: evt.target.value,
            amount_err: ""
        });
      }
    } else {
      this.state({
        amount_err: "Amount is required"
      });
    }
    
  };

  customValidation() {
    var isValid = true;
    let codeErr, typeErr, amountErr = "";
    if (_.isEmpty(this.state.code)) {
      isValid = false;
      codeErr= "Please enter code.";
    }
    if (_.isEmpty(this.state.type)) 
    {
      isValid = false;
      typeErr = "Please select type.";
    }
    if (_.isEmpty(this.state.amount)) 
    {
      isValid = false;
      amountErr = "Please enter amount";
    }
    
    this.setState({
        code_err: codeErr,
        type_err: typeErr,
        amount_err: amountErr,
    });

    
    return isValid;
  }

  onSubmit = (evt) => {
    evt.preventDefault();

    if (this.customValidation()) {
      
      const formdata = {
          id: this.state.id,
          code: this.state.code,
          amount: this.state.amount,
          type: this.state.type.key,          
        };

        this.saveDiscountObject(formdata);
    } 
    else {
      toast.error("Please fill mandatory(*) fields.");
    }
  };

  async saveDiscountObject(formdata) {
    let response = await RegistrationsService.SaveDiscountCode(formdata);
    if (!_.isEmpty(response)) {
      await this.props.reloadDiscountCodes();
      this.handleClose();
    }
  }

  onShow() {   
    this.setState({
      formTitle: this.props.registrationState.discountObj.formTitle,
    })

    if(this.props.registrationState.discountObj.discountId)
    {
        this.loadPreviousData(this.props.registrationState.discountObj.discountId);
    }
  }

  handleClose() {
    this.props.closeAddDiscountModal();
    this.setState(initialState);
  }

  render() {
    let classes = makeStyles({
      option: {
        fontSize: 15,
        "& > span": {
          marginRight: 10,
          fontSize: 18,
        },
      },
    });

    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="addDiscountCodeModal"
          //size="sm"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title className=" w-100 modal-title font-italic">
              <div className="feModalHeader align-items-center text-uppercase d-flex">
                <div className="col-sm-8 mt-2">
                  <h5 className="text-white mt-0">
                    {this.state.formTitle}
                  </h5>
                </div>
                <div className="col-sm-6 float-right text-right w-25 text-white mt-0">
                  <h5></h5>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <form
                className="needs-validation"
                id="addDiscountCodeForm"
                onSubmit={this.onSubmit}
                noValidate
              >
                <div className="row mt-1">
                    <div className="col-lg-6 mt-3 text_ctrl">
                        <div className="form-group">
                        <MDBInput
                            id="code"
                            value={this.state.code}
                            name="code"
                            onChange={this.valueChangeHandler}
                            label="Code *"
                            hint="Enter Discount Code"
                            group
                            type="text"
                            disabled={false}
                            required
                        >
                            <div className="invalid-feedback">Required</div>
                        </MDBInput>
                        </div>
                    </div>

                    <div className=" col-lg-6 mt-3 text_ctrl">
                        <Autocomplete
                        id="type"
                        options={this.state.typeOptions}
                        getOptionLabel={(option) => {
                            if (typeof option === "object") {
                            return option.val;
                            } else {
                            return "";
                            }
                        }}
                        onChange={(e, newValue, reason) =>
                            this.dropdownChangeHandler(e, newValue, "type")
                        }
                        disabled={false}
                        value={this.state.type}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            label="Type"
                            variant="standard"
                            value={this.state.type}
                            placeholder="Select Discount Type"
                            InputLabelProps={{
                                shrink: true,
                                classes: { shrink: "mt-n1" },
                            }}
                            error={
                                this.state.issubmit &&
                                _.isEmpty(this.state.type)
                                ? true
                                : false
                            }
                            helperText={
                                this.state.issubmit &&
                                _.isEmpty(this.state.type)
                                ? "Required"
                                : ""
                            }
                            required={true}
                            />
                        )}
                        />
                    </div>                 
                    
                </div>

                <div className="row">
                    <div className="col-lg-6  mt-3 text_ctrl">
                        <div className="form-group">
                          <MDBInput
                            id="amount"
                            value={this.state.amount}
                            name="amount"
                            onChange={this.amountChangeHandler}
                            label="Amount"
                            hint="Enter Amount"
                            group
                            type="text"
                            disabled={false}
                            required
                          />
                          <span className="text-danger">
                            {this.state.amount_err}
                          </span>
                        </div>
                    </div>
                </div>

                <div className="form-group mb-4 row">
                  <div className="col-12 m-t-20 mx-auto text-center">
                    {
                      <>
                        <button
                          className="btn btn-success w-md mc mr-2"
                          disabled={""}
                          type="submit"
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-primary w-md waves-effect cancelModal waves-light mr-2  mc"
                          data-dismiss="modal"
                          aria-hidden="true"
                          type="reset"
                          onClick={this.handleClose}
                        >
                          Close
                        </button>
                      </>
                    }
                  </div>
                </div>
              </form>
            </>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
export default withRouter(
  connect(
  (state) => ({ registrationState: state.registrations }),
  {
    ...registrationAction,
  }
)(AddDiscountModal)
);
