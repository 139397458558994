import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
export default function HistoricMembersModel(props) {
  const { modal, InActiveMembers, toggle } = props;
  const history = useHistory();
  const handleClose = () => {
    toggle();
  };
  const applyUniqueKey = (data) => {
    for (let i = 0; i < data.length; i++) {
      data[i].uniqueKey = i + 1;
    }
    return data;
  };
  const column = [
    {
      text: "Member Name (Reg/Facility)",
      dataField: "memberName",
      headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
      classes: "text-nowrap px-3 py-2 c-pointer",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <a
            alt="show Member profile"
            href={"/directory/userAction/" + row.memberID}
            onClick={(e) => {
              e.stopPropagation();

              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            {row.memberName} ({row.region != null ? row.region : "--"}/
            {row.facility != null ? row.facility : "--"})
          </a>
        );
      },
    },
    {
      text: "Position",
      dataField: "positionName",

      headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
      classes: "text-nowrap px-3 py-2 c-pointer",
      sort: true,
    },
    {
      text: "Start Date",
      dataField: "startDate",

      headerClasses: "text-nowrap bg-dark text-white px-sm-3 px-2 py-2",
      classes: "text-nowrap px-3 py-2 c-pointer",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <>
            {row.startDate
              ? moment(row.startDate.split("T")[0]).format("MM/DD/YYYY")
              : "--"}
          </>
        );
      },
    },
    {
      text: "End Date",
      dataField: "endDate",

      sort: true,
      headerClasses: "text-nowrap bg-dark text-white px-3 py-2",
      classes: "px-3 py-2 c-pointer",
      formatter: (cellContent, row) => {
        return (
          <>
            {row.endDate
              ? moment(row.endDate.split("T")[0]).format("MM/DD/YYYY")
              : "--"}
          </>
        );
      },
    },
    {
      text: "Change Submitted By",
      dataField: "changeSubmitBy",

      sort: true,
      headerClasses: "text-nowrap bg-dark text-white px-3 py-2",
      classes: "px-3 py-2 c-pointer",
      formatter: (cellContent, row) => {
        return (
          <>
            {row.changeSubmitBy ? (
              <a
                alt="show Member profile"
                href={"/directory/userAction/" + row.changeSubmitById}
                onClick={(e) => {
                  e.stopPropagation();

                  e.nativeEvent.stopImmediatePropagation();
                }}
              >
                {row.changeSubmitBy}
              </a>
            ) : (
              "--"
            )}
          </>
        );
      },
    },
    {
      text: "Change Date",
      dataField: "changeDate",
      sort: true,
      headerClasses: "text-nowrap bg-dark text-white px-3 py-2",
      classes: "px-3 py-2 c-pointer",
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.changeDate
              ? moment(row.changeDate.split("T")[0]).format("MM/DD/YYYY")
              : "--"}
          </div>
        );
      },
    },
  ];
  const [state, setState] = useState({
    sizePerPage: 10,
  });
  const SizePerPageChange = (sizePerPage) => {
    setState({ ...state, sizePerPage: sizePerPage });
  };
  const RemotePagination = (data) => {
    let paginationOptions = {
      totalSize: !_.isEmpty(data) && data.length > 0 ? data.length : 0,
      showTotal: true,
      onSizePerPageChange: SizePerPageChange,
      sizePerPage: state.sizePerPage,

      //hidePageListOnlyOnePage: true
    };
    let defaultsort = [
      {
        dataField: "memberName",
        order: "asc",
      },
    ];
    return (
      <div>
        <BootstrapTable
          // remote
          keyField="uniqueKey"
          id="HistoricOfficer"
          data={data}
          defaultSortDirection="asc"
          defaultSorted={defaultsort}
          columns={column}
          className="table"
          pagination={
            (!_.isEmpty(data) && data.length > 0 ? data.length : 0) >
            state.sizePerPage
              ? paginationFactory({ ...paginationOptions })
              : false
          }
          // onTableChange={this.handleTableChange}
        />
      </div>
    );
  };

  return (
    <Modal
      centered={true}
      size="xl"
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader>Representative History</ModalHeader>
      <ModalBody>
        <div className="row" id="tableRow">
          <div className="col-12" style={{ overflow: "scroll" }}>
            {!_.isEmpty(InActiveMembers) ? (
              RemotePagination(applyUniqueKey(InActiveMembers))
            ) : (
              <p className="mt-4 text-center">No Members Available!</p>
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
