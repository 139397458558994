import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import FEService from "./FEService";
import InventoryStatus from "../InventoryStatus";
import { toast } from "react-toastify";
import FEServices from "../F&ERequests/FEService";
import { withRouter } from "react-router-dom";
import FETrackerActions from "../../../store/feTracker/action";
import { connect } from "react-redux";
import FERequestsType from "../FERequestsType";
import _ from "lodash";

class DeleteRequestModal extends Component {
  RemoveMyRequest = async (id) => {
    let response = await FEServices.RemoveRequest(id);
    if (!_.isEmpty(response)) {
      toast.success("Request Removed successfully");
    }
    const formData = {
      Keyword: "MyRequestsReload",
      isMyRequests: true,
      feRequestsTypeList: [FERequestsType.MyRequests],
      showClosed: false,
      PageNo: this.props.festate.erPageNo||1, // My Requests List Current PageNo
      PageSize: this.props.festate.erSizePerPage||10, // My Requests List PageSize
      SortOrder: "id", //"firstnameAsc",
    };

    this.props.searchFeRequestsRecords(formData);
    this.props.closeModal();
  };
  render() {
    return (
      <>
        <Modal
          centered={true}
          autoFocus={true}
          isOpen={this.props.openModal}
          className="styled-modal"
          backdrop="static"
          id={this.props.id}
        >
          <ModalHeader
            className="modal-header bg-secondary py-2"
            toggle={this.props.closeModal}
          >
            Confirm Delete
          </ModalHeader>
          <ModalBody>
            <div className="col-12 pb-2 text-danger text-center">
              <span className="h1 fa fa-trash-alt" />
            </div>
            <div className="col-12 mb-4">
              <p className="h5 text-center">
                Are you sure you want to delete this "{this.props.content}"?
              </p>
            </div>
            <div className="col-12 text-center mb-3 border-top pt-4">
              <button
                className="btn btn-outline-secondary w-md waves-effect cancelModal waves-light mr-2"
                onClick={() => {
                  this.props.closeModal();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-outline-danger w-md waves-effect waves-light"
                onClick={() => this.RemoveMyRequest(this.props.requestDelId)}
              >
                Delete
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      festate: state.fetracker,
    }),
    {
      ...FETrackerActions,
    }
  )(DeleteRequestModal)
);
