import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import actionFilter from "../../../store/advancedfilter/action";
import { Row, Col } from "reactstrap";

export class Address extends Component {
  // standardCheckBox = ({ input, label, labelFor, type }) => {
  //   return (
  //     <span className="card-text custom-checkbox ml-3 mr-4">
  //       <input
  //         {...input}
  //         type={type}
  //         className="custom-control-input"
  //         htmlFor={labelFor}
  //         id={labelFor}
  //         style={{ color: this.props.isMobile ? "white" : null }}
  //         name={labelFor}
  //       />

  //       <label
  //         className="custom-control-label"
  //         style={{ color: this.props.isMobile ? "white" : null }}
  //         htmlFor={labelFor}
  //       >
  //         {label}
  //       </label>
  //     </span>
  //   );
  // };

  // renderInput = ({
  //   input,
  //   label,
  //   classForm,
  //   type,
  //   meta,
  //   name,
  //   placeholder
  // }) => {
  //   //console.log(meta)
  //   console.log(label);
  //   return (
  //     <div className="form-group">
  //       {label !== "" && !this.props.isMobile ? (
  //         <label htmlFor={name}>{label} </label>
  //       ) : placeholder == "Miles" ? (
  //         ""
  //       ) : (
  //         <label></label>
  //       )}
  //       <input
  //         {...input}
  //         className={classForm}
  //         type={type}
  //         placeholder={placeholder}
  //         autoComplete="off"
  //         style={
  //           (placeholder === "Miles" ? { width: "auto" } : null,
  //           { color: this.props.isMobile ? "white" : null })
  //         }
  //       />
  //       <div>{this.renderError(meta)}</div>
  //     </div>
  //   );
  // };

  // renderError({ error, touched }) {
  //   if (touched && error) {
  //     return <span className="text-danger">{error}</span>;
  //   }
  // }

  // errorMessage() {
  //   if (this.props.errorMessage) {
  //     return (
  //       <div className="text-danger">
  //         {/* {this.props.errorMessage} */}
  //         {/* {this.props.errorMessage.map(home => <div>{home.name}</div>)} */}
  //         {/* {console.log(this.props.errorMessage)}; */}
  //       </div>
  //     );
  //   }
  // }

  // onSubmit = formValues => {
  //   console.log(formValues);

  //   // this.props.Login(formValues, this.props.history);
  //   // this.props.fetchUserProfile();
  // };
  chechboxesWZ(item, index) {
    return (
      <div key={"wzitem"}>
        <span className="card-text custom-checkbox ml-3 mr-4" key={"wzitem"}>
          <input
            type="checkbox"
            htmlFor={"wzitem"}
            value={index}
            checked={item.checked == true}
            className="custom-control-input"
            id={"wzitem"}
            name={"wzitem"}
            onChange={() => this.onChangeWithoutZip(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"wzitem"}
          >
            {item.zipcodeWithout4}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </div>
    );
  }
  chechboxesVA(item, index) {
    return (
      <li class="chechbox-list_item" key={"valiitem-" + index}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"vaitem-" + index}
        >
          <input
            type="checkbox"
            htmlFor={"vaitem-" + index}
            value={index}
            checked={item.checked}
            className="custom-control-input"
            id={"vaitem-" + index}
            name={"vaitem-" + index}
            onChange={() => this.onChangeVerified(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"vaitem-" + index}
          >
            {item.verifiedAddress}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }
  chechboxesHP(item, index) {
    return (
      <li class="chechbox-list_item" key={"hpitem-" + index}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"hpitem-" + index}
        >
          <input
            type="checkbox"
            htmlFor={"hpitem-" + index}
            value={index}
            checked={item.checked}
            className="custom-control-input"
            id={"hpitem-" + index}
            name={"hpitem-" + index}
            onChange={() => this.onChangeHasPrimary(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile ? "white" : null }}
            htmlFor={"hpitem-" + index}
          >
            {item.hasPrimaryAddress}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }
  onChange(item) {
    var addressTypeIndex = _.findIndex(
      this.props.filterState.filterData.address.addressTypes,
      (o) => {
        return o.id === item.id;
      }
    );
    item.checked = !this.props.filterState.filterData.address.addressTypes[
      addressTypeIndex
    ].checked;
    this.props.filterState.filterData.address.addressTypes[
      addressTypeIndex
    ] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  onChangeWithoutZip(item) {
    item.checked = !this.props.filterState.filterData.address.zipWithout4
      .checked;
    this.props.filterState.filterData.address.zipWithout4 = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  onChangeHasPrimary(item) {
    var hasPrimaryIndex = _.findIndex(
      this.props.filterState.filterData.address.nullPrimaryAddress,
      (o) => {
        return o.hasPrimaryAddress === item.hasPrimaryAddress;
      }
    );
    item.checked = !this.props.filterState.filterData.address
      .nullPrimaryAddress[hasPrimaryIndex].checked;
    this.props.filterState.filterData.address.nullPrimaryAddress[
      hasPrimaryIndex
    ] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  onChangeVerified(item) {
    var verifiedAddressIndex = _.findIndex(
      this.props.filterState.filterData.address.verifiedAddress,
      (o) => {
        return o.verifiedAddress === item.verifiedAddress;
      }
    );
    item.checked = !this.props.filterState.filterData.address.verifiedAddress[
      verifiedAddressIndex
    ].checked;
    this.props.filterState.filterData.address.verifiedAddress[
      verifiedAddressIndex
    ] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes(item) {
    return (
      <li class="chechbox-list_item" key={"atliitem-" + item.id}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"atitem-" + item.id}
        >
          <input
            type="checkbox"
            htmlFor={"atitem-" + item.id}
            value={item.id}
            checked={item.checked}
            className="custom-control-input"
            id={"atitem-" + item.id}
            name={"atitem-" + item.id}
            onChange={() => this.onChange(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile && "white" }}
            htmlFor={"atitem-" + item.id}
          >
            {item.code}
          </label>
        </span>
      </li>
    );
  }
  onChange2(item) {
    var stateIndex = _.findIndex(
      this.props.filterState.filterData.address.states,
      (o) => {
        return o.stateName === item.stateName;
      }
    );
    item.checked = !this.props.filterState.filterData.address.states[stateIndex]
      .checked;
    this.props.filterState.filterData.address.states[stateIndex] = item;
    this.props.setFilterData({
      ...this.props.filterState.filterData,
    });
  }
  chechboxes2(item) {
    return (
      <li class="chechbox-list_item" key={"stliitem-" + item.stateName}>
        <span
          className="card-text custom-checkbox ml-3 mr-4"
          key={"stitem-" + item.stateName}
        >
          <input
            type="checkbox"
            htmlFor={"stitem-" + item.stateName}
            value={item.stateName}
            checked={item.checked}
            className="custom-control-input"
            id={"stitem-" + item.stateName}
            name={"stitem-" + item.stateName}
            onChange={() => this.onChange2(item)}
          />

          <label
            className="custom-control-label"
            style={{ color: this.props.isMobile && "white" }}
            htmlFor={"stitem-" + item.stateName}
          >
            {item.stateName}
            <small className="totalCount">{`(${item.total})`}</small>
          </label>
        </span>
      </li>
    );
  }
  changeValue(value, type) {
    switch (type) {
      case "country":
        this.props.setFilterData({
          ...this.props.filterState.filterData,
          address: {
            ...this.props.filterState.filterData.address,
            country: value,
          },
        });
        break;
      case "city":
        this.props.setFilterData({
          ...this.props.filterState.filterData,
          address: {
            ...this.props.filterState.filterData.address,
            city: value,
          },
        });
        break;
      case "zip":
        this.props.setFilterData({
          ...this.props.filterState.filterData,
          address: {
            ...this.props.filterState.filterData.address,
            zip: value,
          },
        });
        break;
      default:
        break;
    }
  }
  render() {
    // debugger
    let verifiedAddress =
      this.props.filterState.filterData &&
      this.props.filterState.filterData.address &&
      this.props.filterState.filterData.address.verifiedAddress
        ? this.props.filterState.filterData.address.verifiedAddress
        : null;
    let nullPrimaryAddress =
      this.props.filterState.filterData &&
      this.props.filterState.filterData.address &&
      this.props.filterState.filterData.address.nullPrimaryAddress
        ? this.props.filterState.filterData.address.nullPrimaryAddress
        : null;
    let ZipWithout4 =
      this.props.filterState.filterData &&
      this.props.filterState.filterData.address &&
      this.props.filterState.filterData.address.zipWithout4
        ? this.props.filterState.filterData.address.zipWithout4
        : null;
    //console.log("ZipWithout4", ZipWithout4);
    let noData = <>No filters available</>;
    return (
      <>
        {this.props.isMobile !== true && (
          <>
            <h4>Address</h4>
            <hr />
          </>
        )}
        <Row className="mx-0 p-0 filter-fields-scroll">
          <Col xs="12">
            <div className="address-padding">
              <ul class="chechbox-list">
                <li
                  className="subheading-chks"
                  style={{ color: this.props.isMobile && "white" }}
                >
                  Has Primary Address
                </li>
                {this.props.filterState.filterData &&
                  nullPrimaryAddress &&
                  nullPrimaryAddress.map((item, index) =>
                    this.chechboxesHP(item, index)
                  )}
              </ul>
            </div>
          </Col>
          <Col xs="12">
            <div className="address-padding">
              <ul class="chechbox-list">
                <li
                  className="subheading-chks"
                  style={{ color: this.props.isMobile && "white" }}
                >
                  Verified Addresss
                </li>
                {this.props.filterState.filterData &&
                  verifiedAddress &&
                  verifiedAddress.map((item, index) =>
                    this.chechboxesVA(item, index)
                  )}
              </ul>
            </div>
          </Col>

          <Col md={3}>
            <div className="address-padding">
              <ul className="chechbox-list">
                <li
                  className="subheading-chks"
                  style={{ color: this.props.isMobile && "white" }}
                >
                  Address Type
                </li>
                {!_.isEmpty(this.props.filterState.filterData) &&
                this.props.filterState.filterData.address &&
                this.props.filterState.filterData.addressTypes &&
                this.props.filterState.filterData.address.addressTypes
                  .length === 0
                  ? noData
                  : !_.isEmpty(this.props.filterState.filterData) &&
                    this.props.filterState.filterData.address.addressTypes.map(
                      (item) => {
                        if (item.id !== 4) {
                          return this.chechboxes(item);
                        }
                      }
                    )}
              </ul>
            </div>
          </Col>
          <Col md={3}>
            <div className="address-padding">
              <Row>
                <Col>
                  <div className="form-group">
                    {this.props.isMobile !== true && <label>Country</label>}
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      value={
                        _.isEmpty(this.props.filterState.filterData.address) ===
                        true
                          ? ""
                          : this.props.filterState.filterData.address.country
                      }
                      onChange={(event) =>
                        this.changeValue(event.target.value, "country")
                      }
                      placeholder={this.props.isMobile == true && "Country"}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    {this.props.isMobile !== true && <label>City</label>}
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      placeholder={this.props.isMobile == true && "City"}
                      value={
                        _.isEmpty(this.props.filterState.filterData.address) ===
                        true
                          ? ""
                          : this.props.filterState.filterData.address.city
                      }
                      onChange={(event) =>
                        this.changeValue(event.target.value, "city")
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    {this.props.isMobile !== true && <label>Zip Code</label>}
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="off"
                      placeholder={this.props.isMobile == true && "Zip Code"}
                      value={
                        _.isEmpty(this.props.filterState.filterData.address) ===
                        true
                          ? ""
                          : this.props.filterState.filterData.address.zip
                      }
                      onChange={(event) =>
                        this.changeValue(event.target.value, "zip")
                      }
                    />
                  </div>
                </Col>
              </Row>
              {ZipWithout4 && (
                <Row>
                  <Col>
                    <div className="form-group">
                      {this.chechboxesWZ(ZipWithout4)}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div class="">
              <ul class="chechbox-list">
                <li
                  className="subheading-chks"
                  style={{ color: this.props.isMobile && "white" }}
                >
                  State
                </li>
                {!_.isEmpty(this.props.filterState.filterData) &&
                  this.props.filterState.filterData.address.states.map((item) =>
                    this.chechboxes2(item)
                  )}
              </ul>
            </div>
            <div className="height-scroll" />
          </Col>
        </Row>
      </>
    );
  }
  // render() {
  //   const { handleSubmit, pristine, reset, submitting } = this.props;

  //   return (
  //     <>
  //       {this.props.isMobile !== true ? (
  //         <>
  //           <h5>Address</h5>
  //           <hr />
  //         </>
  //       ) : (
  //         ""
  //       )}

  //       <Form onSubmit={handleSubmit(this.onSubmit)}>
  //         <Row>
  //           <Col className="m-3 m-sm-3 pr-0 mr-0">
  //             <h6 style={{ color: this.props.isMobile ? "white" : null }}>
  //               Address Type
  //             </h6>
  //             <div>
  //               <Field
  //                 name="isMailed"
  //                 id="isMailed"
  //                 label="Mailing"
  //                 component={this.standardCheckBox}
  //                 labelFor="isMailed"
  //                 type="checkbox"
  //               />
  //               <Field
  //                 name="isHome"
  //                 id="isHome"
  //                 label="Home"
  //                 component={this.standardCheckBox}
  //                 labelFor="isHome"
  //                 type="checkbox"
  //               />
  //             </div>
  //           </Col>
  //           <Col className="m-3 m-sm-3 p-0 m-0">
  //             <Field
  //               name="city"
  //               type="text"
  //               placeholder="City"
  //               classForm="form-control"
  //               label="City"
  //               component={this.renderInput}
  //             />

  //             <Field
  //               name="country"
  //               type="text"
  //               placeholder="Country"
  //               classForm="form-control"
  //               label="Country"
  //               component={this.renderInput}
  //             />
  //           </Col>

  //           <Col className="m-3 m-sm-3 p-0 m-0">
  //             <h6 style={{ color: this.props.isMobile ? "white" : null }}>
  //               State
  //             </h6>
  //             <div>
  //               <Field
  //                 name="alabama"
  //                 id="alabama"
  //                 label="Alabama"
  //                 labelFor="alabama"
  //                 component={this.standardCheckBox}
  //                 type="checkbox"
  //               />
  //               <Field
  //                 name="alaska"
  //                 id="alaska"
  //                 label="Alaska"
  //                 labelFor="alaska"
  //                 component={this.standardCheckBox}
  //                 type="checkbox"
  //               />
  //             </div>
  //           </Col>

  //           <Col className="m-3 m-sm-3 p-0 m-0">
  //             {/* <h6>Zip</h6> */}
  //             <div className="m-3 m-sm-3">
  //               <div className="col-sm-3 col-md-4 col-lg-7">
  //                 <Field
  //                   name="zip"
  //                   type="text"
  //                   classForm="form-control"
  //                   label="Zip"
  //                   placeholder="Zip"
  //                   component={this.renderInput}
  //                 />
  //               </div>
  //               <div className="m-4 m-sm-4 col-lg-2 displayInline">
  //                 <Field
  //                   name="withIn"
  //                   id="withIn"
  //                   label="WithIn"
  //                   labelFor="Within"
  //                   classForm="pr-0"
  //                   component={this.standardCheckBox}
  //                   type="checkbox"
  //                 />
  //                 <Field
  //                   name="miles"
  //                   type="text"
  //                   classForm="form-control"
  //                   label=""
  //                   placeholder="Miles"
  //                   component={this.renderInput}
  //                 />
  //               </div>
  //             </div>
  //           </Col>
  //         </Row>
  //         {this.errorMessage()}
  //       </Form>
  //     </>
  //   );
  // }
}

// const validate = formValues => {
//   const errors = {};
//   // if (!formValues.userName) {
//   //     errors.userName = "Email is required."
//   // }
//   // if (!formValues.password) {
//   //     errors.password = "Password is required."
//   // }
//   return errors;
// };

// const formWrapped = reduxForm({
//   form: "addressForm",
//   validate
// })(Address);

// const mapStateToProps = state => ({
//   errorMessage: {}
// });

// export default connect(mapStateToProps, {})(formWrapped);

export default connect((state) => ({ filterState: state.advancedFilter }), {
  ...actionFilter,
})(Address);
