import AUX from "../../../hoc/Aux_";
import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import _ from "lodash";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import { Modal } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import savedSearchAction from "../../../store/saveSearch/action";
import { withRouter } from "react-router-dom";

class SaveBucketSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SaveBucketName: "",
      showSaveBucketModal: this.props.showModal,
    };
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      showSaveBucketModal: false,
    });
  };

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  redirectToFiltersList = () => {
    const currentLocation = window.location.pathname;
    if (!currentLocation.includes("filters")) {
      this.props.history.push("/directory/myfilters", {
        reloadMyFilters: true,
      });
      this.props.history.location.lastPlace = currentLocation;
    }
  };
  onSaveBucketSearch = () => {
    if (this.state.SaveBucketName === "") {
      toast.error("Enter Bucket Name before saving.");
      return;
    }

    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    const member_ids = [];
    for (let i = 0; i < items.length; i++) {
      member_ids.push(items[i].id);
    }

    let postBucketSearch = "/api/SavedSearch/SaveBucketResult";
    const body = {
      SearchName: this.state.SaveBucketName,
      SearchType: 2,
      MemberId: this.props.memberId,
      member_ids: member_ids,
      SearchId: this.props.SavedSearch.BucketSearchId,
      search_criteria: this.props.search_criteria,
    };

    this.showSpinner();
    axios.post(postBucketSearch, body).then(({ data }) => {
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          this.hideSpinner();
          this.state.SaveBucketName = "";
          toast.success(data.message);

          localStorage.removeItem("BucketSearch");
          this.handleClose();
          this.props.ParentClose();

          //this.forceSearchRefresh(true, data.searchId);
          this.props.setSavedSearchCriteria(null);
          this.props.updateAndSelect(data.searchId, true);
          // this.props.setSavedSearchCriteria(
          //   data,
          //   body.SearchType,
          //   body.SearchId
          // );
          this.redirectToFiltersList(); //Redirect to My Filters Listing after creating Bucket Search
        } else {
          this.hideSpinner();
          toast.error(data.message);
        }
      } else {
        this.hideSpinner();
        toast.error("Search cannot be saved.");
      }
    });
  };

  forceSearchRefresh = (forceRefresh, SearchId) => {
    if (forceRefresh === true) {
      const body = {
        SearchId: SearchId,
        MemberId: AuthService.getProfile().memberData.id,
        SearchType: 2,
      };
      console.log(body);
      this.showSpinner();
      axios
        .post("/api/SavedSearch/GetSavedSearchFilter", body)
        .then(({ data: response }) => {
          this.hideSpinner();
          if (response.status) {
            if (response.status.toLowerCase() === "success") {
              //this.props.resetSavedSearch();
              // this.props.setSavedSearchCriteria(response, body.SearchType,
              //   body.SearchId);
            } else {
              toast.error(response.message);
            }
          } else {
            toast.error("Could not retrieve saved search detail.");
          }
        });
    }
  };

  render() {
    return (
      <AUX>
        <Modal
          className="SaveBucketModal"
          show={this.props.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
          onShow={this.onShow}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h5 className="modal-title mt-0"> SAVE MEMBER BUCKET </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-12">
              <form role="search" className="mt-0">
                <MDBInput
                  onChange={(event) => {
                    this.setState({ SaveBucketName: event.target.value });
                  }}
                  className="bold-input"
                  containerClass="mt-0 mb-2"
                  group
                  type="text"
                  hint="Enter bucket Name"
                  required
                ></MDBInput>
                {/*  <div className="form-group mb-0">
                      <input
                        type="text"
                        className="form-control rounded border mb-1 w-100"
                        placeholder="Enter bucket Name"
                        onChange={(event) => {
                          this.setState({ SaveBucketName: event.target.value });
                        }}
                      />
                    </div>*/}
              </form>
            </div>

            <div className="text-center mb-4 mt-5 pt-4">
              <button
                className="btn btn-success w-md mc m-1 m-sm-1"
                onClick={() => this.onSaveBucketSearch()}
              >
                Save
              </button>

              <button
                className="btn btn-danger w-md waves-effect cancelModal waves-light mr-2 mc m-1 m-sm-1"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={this.handleClose}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      SavedSearch: state.saveSearch,
    }),
    {
      ...savedSearchAction,
    }
  )(SaveBucketSearch)
);
