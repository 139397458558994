import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function UnSubscribeModal(props) {
  const { modal, toggle, listName } = props;

  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>Confirm Unsubscribe</ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          <h5>
            You will no longer receive emails from the {listName} list. Are you
            sure you want to unsubscribe?
          </h5>
        </div>{" "}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary w-md waves-effect waves-light"
          onClick={() => {
            props.confirm().confirmed();
          }}
        >
          UnSubscribe
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
