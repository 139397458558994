import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function MapsModal(props) {
    const {
        modal,
        toggle,
        headerText,
        type,
        latitude,
        longitude,
        apiKey
    } = props;



    let zoom = 10;

    if (type === "ehc") {
        zoom = 8;
    }
    return (

        <Modal centered={true} className="modal-lg" role="dialog" autoFocus={true} isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
            <ModalBody>
                <div>
                    <img src={`http://vfrmap.com/api?req=map&type=${type}&lat=${latitude}&lon=${longitude}&width=768&height=768&zoom=${zoom}&api_key=${apiKey}`} class={`img-fluid`} alt={headerText} />

                </div>

                {/* <iframe src={`http://vfrmap.com/?type=vfrc&lat=42.5&lon=-120.5&zoom=10&api_key=763xxE1MJHyhr48DlAP2qQ`} style={{ width: '-webkit-fill-available', height: '70vh' }} /> */}
                {/* <img src={`http://vfrmap.com/api?req=map&type=sectc&lat=61.2&lon=-149.7&zoom=10&api_key=763xxE1MJHyhr48DlAP2qQ`} style={{ width: '-webkit-fill-available', height: '70vh' }} /> */}
            </ModalBody>

        </Modal>
    )
}



