import _ from "lodash";
import { toast } from "react-toastify";
import RegistrationsService from "../../modules/Registrations/RegistrationsService";
const actions = {
  SET_USER_HEADER_ACTIVE_TAB: "SET_USER_HEADER_ACTIVE_TAB",
  SET_ADMIN_HEADER_ACTIVE_TAB: "SET_ADMIN_HEADER_ACTIVE_TAB",
  OPEN_ADD_TEMPLATE_MODAL: "OPEN_ADD_TEMPLATE_MODAL",
  CLOSE_ADD_TEMPLATE_MODAL: "CLOSE_ADD_TEMPLATE_MODAL",
  OPEN_ADD_DISCOUNT_MODAL: "OPEN_ADD_DISCOUNT_MODAL",
  CLOSE_ADD_DISCOUNT_MODAL: "CLOSE_ADD_DISCOUNT_MODAL",
  TOGGLE_ADD_QUESTION_MODAL: "TOGGLE_ADD_QUESTION_MODAL",
  SET_OPEN_REG_ROW: "SET_OPEN_REG_ROW",
  SET_REG_QUESTIONS: "SET_REG_QUESTIONS",
  GET_MEMBER_PROFILE_REGISTRATIONS: "GET_MEMBER_PROFILE_REGISTRATIONS",
  SET_PROFILE_REGIS_LOADING: "SET_PROFILE_REGIS_LOADING",
  TOGGLE_MEM_REG_ACCORDION: "TOGGLE_MEM_REG_ACCORDION",
  SET_OPEN_REGISTRATIONS: "SET_OPEN_REGISTRATIONS",
  SET_ADMIN_CHILD_TEMPLATES: "SET_ADMIN_CHILD_TEMPLATES",
  SET_PAYMENT_STATUS: "SET_PAYMENT_STATUS",
  SET_PENDING_APVL_REGIS_LIST: "SET_PENDING_APVL_REGIS_LIST",
  SET_ADMIN_REGIS_LIST: "SET_ADMIN_REGIS_LIST",
  SET_ADMIN_REGIS_INSTR_LIST: "SET_ADMIN_REGIS_INSTR_LIST",

  SET_PENDING_APVL_LST_LOADER: "SET_PENDING_APVL_LST_LOADER",
  SET_ADMIN_REGIS_LST_LOADER: "SET_ADMIN_REGIS_LST_LOADER",
  SELECT_ADDTL_REGISTRANT_MEMBER: "SELECT_ADDTL_REGISTRANT_MEMBER",
  DE_SELECT_ADDTL_REGISTRANT_MEMBER: "DE_SELECT_ADDTL_REGISTRANT_MEMBER",
  ADD_MULTIPLE_REGISTRANT_MEMBERS: "ADD_MULTIPLE_REGISTRANT_MEMBERS",
  REMOVE_STAND_BY_ADDTL_REGIS_MEMS: "REMOVE_STAND_BY_ADDTL_REGIS_MEMS",
  SET_TEMPLATE_ATTACHMENTS_LOADING: "SET_TEMPLATE_ATTACHMENTS_LOADING",
  SET_TEMPLATE_ATTACHMENTS_FILES: "SET_TEMPLATE_ATTACHMENTS_FILES",
  SLICE_TEMPLATE_ATTACHMENTS_FILES: "SLICE_TEMPLATE_ATTACHMENTS_FILES",
  DELETE_TEMPLATE_FILE: "DELETE_TEMPLATE_FILE",

  SET_ADDTL_STD: "SET_ADDTL_STD",
  // ************************Action Methods*************************/
  setUserHeaderActiveTab: (tab) => (dispatch) => {
    dispatch({
      type: actions.SET_USER_HEADER_ACTIVE_TAB,
      payload: tab,
    });
  },

  setAdminHeaderActiveTab: (tab) => (dispatch) => {
    dispatch({
      type: actions.SET_ADMIN_HEADER_ACTIVE_TAB,
      payload: tab,
    });
  },

  openAddTemplateModal: (detail) => (dispatch) => {
    dispatch({
      type: actions.OPEN_ADD_TEMPLATE_MODAL,
      payload: detail,
    });
  },

  closeAddTemplateModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_ADD_TEMPLATE_MODAL,
    });
  },

  openAddDiscountModal: (detail) => (dispatch) => {
    dispatch({
      type: actions.OPEN_ADD_DISCOUNT_MODAL,
      payload: detail,
    });
  },

  closeAddDiscountModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_ADD_DISCOUNT_MODAL,
    });
  },

  toggleAddQuestionModal: () => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_ADD_QUESTION_MODAL,
    });
  },

  setSelectedOpenRegRow: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_OPEN_REG_ROW,
      payload: val,
    });
  },
  setRegistrationQuestions: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_REG_QUESTIONS,
      payload: val,
    });
  },

  toggleMemberRegistrationsAccordion: (val) => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_MEM_REG_ACCORDION,
      payload: val,
    });
  },
  getMemberProfileRegistrations: (memberId) => async (dispatch) => {
    dispatch({
      type: actions.SET_PROFILE_REGIS_LOADING,
      payload: true,
    });
    let data = await RegistrationsService.GetRegistrationsByMemberId(memberId);
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        dispatch({
          type: actions.GET_MEMBER_PROFILE_REGISTRATIONS,
          payload: data.result != null ? data.result : [],
        });
      }
    }
    dispatch({
      type: actions.SET_PROFILE_REGIS_LOADING,
      payload: false,
    });
  },
  reloadOpenRegistrations: (
    includeClosed,
    isSuperAdmin,
    isBasicAdmin
  ) => async (dispatch) => {
    let data = await RegistrationsService.GetOpenedRegistrations(
      includeClosed,
      isSuperAdmin,
      isBasicAdmin
    );
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        var response = data.result != null ? data.result : [];
        if (isSuperAdmin || isBasicAdmin) {
          dispatch({
            type: actions.SET_ADMIN_CHILD_TEMPLATES,
            payload: response,
          });
        } else {
          dispatch({
            type: actions.SET_OPEN_REGISTRATIONS,
            payload: response,
          });
        }
      }
    } else {
      dispatch({
        type: actions.SET_OPEN_REGISTRATIONS,
        payload: [],
      });
      dispatch({
        type: actions.SET_ADMIN_CHILD_TEMPLATES,
        payload: [],
      });
    }
  },
  setRegPaymentStatus: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_PAYMENT_STATUS,
      payload: val,
    });
  },
  setRegisChildTemplateInstructors: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_ADMIN_REGIS_INSTR_LIST,
      payload: val,
    });
  },
  getPendingApprovalRegistrations: () => async (dispatch) => {
    dispatch({
      type: actions.SET_PENDING_APVL_LST_LOADER,
      payload: true,
    });
    let data = await RegistrationsService.GetPendingApprovalRegistrations();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        let registrations = data.result != null ? data.result : [];

        dispatch({
          type: actions.SET_PENDING_APVL_LST_LOADER,
          payload: false,
        });
        await dispatch({
          type: actions.SET_PENDING_APVL_REGIS_LIST,
          payload: registrations,
        });
      }
    } else {
      await dispatch({
        type: actions.SET_PENDING_APVL_REGIS_LIST,
        payload: [],
      });
    }
  },
  selectMember: (item) => (dispatch) => {
    dispatch({
      type: actions.SELECT_ADDTL_REGISTRANT_MEMBER,
      payload: { ...item },
    });
  },
  deSelectMember: (id) => (dispatch) => {
    dispatch({
      type: actions.DE_SELECT_ADDTL_REGISTRANT_MEMBER,
      payload: id,
    });
  },
  removeStandByAddtlMembers: () => (dispatch) => {
    dispatch({
      type: actions.REMOVE_STAND_BY_ADDTL_REGIS_MEMS,
    });
  },
  getAdditionalStudents: (childTemplateId) => async (dispatch) => {
    if (childTemplateId && typeof childTemplateId == "number") {
      let response =
        (await RegistrationsService.GetAdditionalStudents(childTemplateId)) ||
        [];

      await dispatch({
        type: actions.SET_ADDTL_STD,
        payload: response,
      });
    } else {
      toast.error("Invalid api Parameters.");
    }
  },
  selectMultipleRegistrantMembers: (childTemplateId) => async (
    dispatch,
    getstate
  ) => {
    if (!childTemplateId) {
      toast.error("Invalid Model.");
      return;
    }

    let { selectedAddtlRegistrantMembers } = getstate().registrations;
    let newMembers = selectedAddtlRegistrantMembers
      .filter((f) => f.id == 0)
      .map((m) => m.memberId);
    if (newMembers.length > 0) {
      let formdata = {
        members: newMembers,
        childTemplateId,
      };
      let response = await RegistrationsService.AddAdditionalStudent(formdata);
      if (!_.isEmpty(response)) {
        await dispatch({
          type: actions.ADD_MULTIPLE_REGISTRANT_MEMBERS,
          payload: response, //Data with DB generated ids..
        });
      }
    } else {
      toast.warn("Please select Members!");
    }
  },
  getAdminRegisRecords: () => async (dispatch) => {
    dispatch({
      type: actions.SET_ADMIN_REGIS_LST_LOADER,
      payload: true,
    });
    let data = await RegistrationsService.GetAdminRegisRecords();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        let registrations = data.result != null ? data.result : [];

        dispatch({
          type: actions.SET_ADMIN_REGIS_LST_LOADER,
          payload: false,
        });
        await dispatch({
          type: actions.SET_ADMIN_REGIS_LIST,
          payload: registrations,
        });
      }
    } else {
      await dispatch({
        type: actions.SET_ADMIN_REGIS_LIST,
        payload: [],
      });
    }
  },

  getTemplateAttachments: (self, templateId) => async (dispatch) => {
    dispatch({
      type: actions.SET_TEMPLATE_ATTACHMENTS_LOADING,
      payload: true,
    });

    let data = await RegistrationsService.GetTemplateAttachments(templateId);

    if (!_.isEmpty(data)) {
      dispatch({
        type: actions.SET_TEMPLATE_ATTACHMENTS_LOADING,
        payload: false,
      });

      dispatch({
        type: actions.SET_TEMPLATE_ATTACHMENTS_FILES,
        payload: data,
      });

    } else {
      dispatch({
        type: actions.SET_TEMPLATE_ATTACHMENTS_LOADING,
        payload: false,
      });
      dispatch({
        type: actions.SET_TEMPLATE_ATTACHMENTS_FILES,
        payload: [],
      });
    }
    
    self.toggleModal();
  },

  sliceImageFromAttachment: (id) => (dispatch) => {
    dispatch({
      type: actions.SLICE_TEMPLATE_ATTACHMENTS_FILES,
      payload: id,
    });
  },

  deleteTemplateFile: (mySelf, filepath) => async (dispatch) => {
    dispatch({
      type: actions.DELETE_TEMPLATE_FILE,
      payload: true,
    });

    let data = await RegistrationsService.DeleteTemplateFile(filepath);
    if (!_.isEmpty(data)) {
      mySelf.setState({ currentImage: -1 });
      mySelf.setState({ NonImageFile: "/" });
      mySelf.toggleConfirmModal();

      const allrefs = mySelf.refs;
      const lightBox = allrefs.GallaryLightBox;

      lightBox.closeLightbox();

      var action = actions.sliceImageFromAttachment(filepath);
      dispatch(action);

    } else {
    }
  },

};

export default actions;
