import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import ReactEcharts from "echarts-for-react";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import classnames from 'classnames';
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";

export default class NCEPTChartsModal extends Component {

    constructor(props)
    {
        super(props);
        this.state = {
            chartOptions:
            {
                "current cpc on board" : [ "CPC On Board", "Current % to CPC Target" ],
                "projections" : [ "Possible Gains", "Projected % to CPC Target" ],
                "training" : [ "Training Time", "Training Success Rate", "ATCS in Training (minus LTH)" ],
                "inbounds/outbounds" : ["Inbounds/Outbounds"], //[ "Inbounds", "Outbounds", "Inbounds/Outbounds" ],
                "other information" : [ "ERR Category", "Current CPC to Trainee %", "Possible Losses" ],
                "national data" : [ "CPCs", "CPC %" ]
            }
        };
        this.toggle = evt => {
            this.props.GetChartDataHandler(this.props.modalTitle, evt.currentTarget.getAttribute('name'), this.props.isYearActive, evt.currentTarget.id);
        };
        this.GetChartDataHandler = (modalTitle, chartName, yearorall, activeTab) => {
            
            if(yearorall)
            {
                $('#allTimeBtn').prop('disabled', true)
                $('#yearBtn').prop('disabled', false)
            }
            else
            {
                $('#yearBtn').prop('disabled', true)
                $('#allTimeBtn').prop('disabled', false)
            }
                
            this.props.GetChartDataHandler(modalTitle, chartName, yearorall, activeTab);
        };
    }   

    returnNavItems = () => {  
             
        let navHtml = this.state.chartOptions[this.props.modalTitle.toLowerCase()].map((element, index) => {
            return (
                <NavItem>
                    <NavLink
                        id={index}
                        name={element}
                        className={classnames({ active: this.props.activeTab === index.toString() })}
                        onClick={ this.toggle }
                    >
                        {element}
                    </NavLink>
                </NavItem>
            )
        })
        return navHtml;
    };

    render() {
        return (
            <Modal centered={true} size="xl" role="dialog" isOpen={this.props.isModalOpen} toggle={this.props.toggleModal}>
                <ModalHeader className="py-2 bg-secondary modal-title mt-0 text-white" toggle={this.props.toggleModal}> {this.props.modalTitle} </ModalHeader>

                <ModalBody className="modalshadow"> 
                    { this.state.chartOptions[this.props.modalTitle.toLowerCase()] && (
                        <Row className="mb-3">
                            <Col>
                                <Nav tabs>
                                    {
                                        this.returnNavItems()
                                    }
                                </Nav>                           
                            </Col>
                        </Row>
                    )}
                    <Row className="mb-3">
                        <Col>                  
                            <ReactEcharts
                                option={this.props.options}
                                notMerge={true}
                                lazyUpdate={true}
                                // theme={"theme_name"}
                                // onChartReady={this.onChartReadyCallback}
                                // onEvents={EventsDict}
                                //opts={}
                            />
                        </Col>
                    </Row> 
                    <Row>
                        <Col>
                            {/* <BootstrapSwitchButton
                                checked={this.props.isYearActive}
                                onlabel='All Time'
                                onstyle='danger'
                                offlabel='Year'
                                offstyle='success'
                                style='w-100 mx-3 radius-2px'
                                onChange={(checked) => {
                                    this.props.GetChartDataHandler(this.props.modalTitle, this.props.chartName, checked, this.props.activeTab);
                                }}
                            /> */}
                            <button
                                disabled={!this.props.isYearActive}
                                onClick={() => this.props.GetChartDataHandler(this.props.modalTitle, this.props.chartName, false, this.props.activeTab)}
                                className="btn btn-success btn-sm mr-2 w-md waves-effect waves-light"
                                type="button"
                                id="yearBtn"
                            >
                                Year
                            </button>
                            <button
                                disabled={this.props.isYearActive}
                                className="btn btn-success btn-sm w-md waves-effect waves-light"
                                type="button"
                                onClick={() => this.props.GetChartDataHandler(this.props.modalTitle, this.props.chartName, true, this.props.activeTab)}
                                id="allTimeBtn"
                            >
                                All Time
                            </button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}
