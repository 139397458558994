import React, { Component, Fragment } from "react";
import axios from "../../Shared/auth-header";
import AUX from "../../../hoc/Aux_";
import AuthService from "../../Auth/AuthService";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import FinanceAction from "../../../store/finance/action";
import paginationFactory from "react-bootstrap-table2-paginator";
import $ from "jquery";
import _ from "lodash";
import Services from "../FinanceService";
import moment from "moment";
import DeleteModal from "./DeleteModal";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { toast } from "react-toastify";
const filter = createFilterOptions();
class TransactionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      data: [],
      memberId: "",
      financeObj: null,
      deleteId: null,
      deleteModal: false,
      FinanceLoader: false,
      totalAmount: null,
      totalExpectedAmount: null,
    };
    this.handleRemoveTransactions = this.handleRemoveTransactions.bind(this);
    this.columns = this.columns.bind(this);
  }
  formate_amount(n) {
    try {
      var num_parts = n.toString().split(".");
      num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    } catch (e) {}
    //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }

  handleRemoveTransactions = async () => {
    $(".loading").show();

    if (
      this.state.deleteId != null &&
      this.state.deleteId != "" &&
      this.state.deleteId != undefined
    ) {
      var data = await Services.DeleteFinanceTransaction(this.state.deleteId);
      if (data != null) {
        // await this.LoadData();
        this.props.refreshTransactionTable();
        await this.setState({
          deleteModal: false,
          deleteStatus: "",
        });
      } else {
        await this.setState({ deleteModal: false });
      }
    } else {
      toast.error("Invalid state, Try again after few moments.");
      await this.setState({ deleteModal: false });
    }
    $(".loading").hide();
  };
  async componentDidUpdate(prevProps) {
    //Props Details {Mode,MemberId,FacilityId}
    let { transactionFinanceObj } = this.props.Finance;
    let { financeObj } = this.state;
    let change = false;
    if (JSON.stringify(financeObj) != JSON.stringify(transactionFinanceObj)) {
      await this.setState({ financeObj: transactionFinanceObj });
      change = true;
    }
    if (this.props.Finance.refreshTransactionTable == true) {
      this.getTransactions();
      this.props.refreshTransactionTable(false);
    } else if (change) {
      this.getTransactions();
    }
  }
  columns = () => {
    return [
      {
        text: "Amount",
        dataField: "amount",
        headerStyle: {
          minWidth: "81px",
        },
        hidden: !AuthService.canView(
          PermissionFeature.FinanceTransactionAmount
        ),
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          let { financeObj } = this.state;
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceTransactionAmount,
                financeObj.regionId,
                financeObj.facilityId,
                financeObj.memberID
              ) && <>{row.amount && this.formate_amount(row.amount)}</>}
            </div>
          );
        },
      },

      {
        text: "Refund",
        dataField: "refund",
        headerStyle: {
          minWidth: "81px",
        },
        sort: true,
        hidden: !AuthService.canView(
          PermissionFeature.FinanceTransactionRefund
        ),
        headerClasses: "bg-dark text-white px-3 py-2",
        classes: "px-3 py-2 c-pointer",
        formatter: (cellContent, row) => {
          let { financeObj } = this.state;
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceTransactionRefund,
                financeObj.regionId,
                financeObj.facilityId,
                financeObj.memberID
              ) && (row.refund && row.refund == true ? "Yes" : "No")}
            </div>
          );
        },
      },
      {
        headerStyle: {
          minWidth: "89px",
        },
        hidden: !AuthService.canView(PermissionFeature.FinanceTransactionDate),
        text: "Transaction Date",
        dataField: "transactionDate",
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          let { financeObj } = this.state;
          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceTransactionDate,
                financeObj.regionId,
                financeObj.facilityId,
                financeObj.memberID
              ) && (
                <>
                  {row.transactionDate &&
                    moment(row.transactionDate).format("MM/DD/YYYY")}
                </>
              )}
            </div>
          );
        },
      },

      {
        text: "Check Number",
        headerStyle: {
          minWidth: "120px",
        },
        dataField: "financeCheckNumber",
        hidden: !AuthService.canView(PermissionFeature.FinanceType),
        headerClasses: "bg-dark text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: true,
        formatter: (cellContent, row) => {
          let { financeObj } = this.state;

          return (
            <div>
              {AuthService.canSPView(
                PermissionFeature.FinanceType,
                financeObj.regionId,
                financeObj.facilityId,
                financeObj.memberID
              ) && row.financeCheckNumber}
            </div>
          );
        },
      },
      {
        headerStyle: {
          minWidth: "50px",
        },
        text: "",

        headerClasses: "bg-dark text-center text-white px-sm-3 px-2 py-2",
        classes: "px-3 py-2 c-pointer",
        sort: false,
        formatter: (cellContent, row) => {
          let { financeObj } = this.state;

          return (
            <div>
              {AuthService.canSPUpdate(
                PermissionFeature.FinanceTransaction,
                financeObj.regionId,
                financeObj.facilityId,
                financeObj.memberID
              ) && (
                <i
                  className="fas pr-1 text-primary fa-pen c-pointer"
                  onClick={() => {
                    this.props.openTransactionModal(row);
                  }}
                />
              )}
              {AuthService.canSPDelete(
                PermissionFeature.FinanceTransaction,
                financeObj.regionId,
                financeObj.facilityId,
                financeObj.memberID
              ) && (
                <i
                  onClick={(e) => {
                    this.state.deleteId = row.id;
                    this.setState({
                      deleteId: this.state.deleteId,
                      deleteModal: true,
                    });
                  }}
                  className="fas fa-trash text-danger c-pointer"
                />
              )}
            </div>
          );
        },
      },
    ];
  };

  SizePerPageChange = (sizePerPage) => {
    this.setState({ sizePerPage: sizePerPage });
  };
  RemotePagination = (data) => {
    return (
      <div>
        <BootstrapTable
          remote
          keyField="id"
          id="TransactionsListing"
          data={data}
          pagination={paginationFactory()}
          columns={this.columns()}
          className="table"
        />
      </div>
    );
  };
  async getTransactions(hideLoader = false, overlayLoader = false) {
    if (!hideLoader) {
      await this.setState({ FinanceLoader: true });
    }
    if (overlayLoader) {
      $(".loading").show();
    }
    let { financeObj } = this.state;
    if (financeObj) {
      let data = await Services.LoadFinanceTransactionList(financeObj.id);
      if (data != null) {
        await this.setState({
          data: data,
        });
      }
    }
    if (!hideLoader) {
      await this.setState({ FinanceLoader: false });
    }
    if (overlayLoader) {
      $(".loading").hide();
    }
  }
  componentDidMount() {
    this.getTransactions();
  }

  componentWillUnmount() {}
  render() {
    // New Work
    const { data, financeObj } = this.state;

    return (
      <AUX>
        {/*Filters Section*/}

        {data && !_.isEmpty(data) && (
          <div className="row">
            <div className="mb-3 col-10" id="Detail">
              <span className="pl-2 font-weight-light h6">
                <b>Total Expected Amount:</b>
                <i>
                  {financeObj
                    ? this.formate_amount(financeObj.expectedAmount)
                    : 0}
                </i>
              </span>
            </div>
            <div className="clearfix" />
          </div>
        )}
        {/*Table Section*/}
        <div className="row">
          <div className="col-12">
            {this.state.FinanceLoader ? (
              <div
                class="m-3 position-relative d-block mx-auto spinner-border text-info"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            ) : !_.isEmpty(data) ? (
              this.RemotePagination(data)
            ) : (
              <p className="text-center">No data found!</p>
            )}
          </div>
        </div>
        {/*End Of table Section*/}
        {AuthService.canDelete(PermissionFeature.FinanceTransaction) && (
          <DeleteModal
            confirm={() => {
              this.handleRemoveTransactions();
            }}
            content="the Selected Transaction"
            closeModal={() => {
              this.setState({ deleteModal: false });
            }}
            openModal={this.state.deleteModal}
          />
        )}
      </AUX>
    );
  }
}
export default connect((state) => ({ Finance: state.finance }), {
  ...FinanceAction,
})(TransactionTable);
