import actions from "./action";
const candidateType = {
  onCandidatePage: 1,
  onOtherPage: 2,
};
const initialState = {
  openCandidateModal: false,
  candidateId: 0,
  candidateModalType: candidateType.onCandidatePage,
  candidateData: {},
  editCandidateData: {},
  ReviewRequestType: {
    Reviewer: "ReviewRequest",
    Requester: "MyRequest",
  },
  RequestDetail: {},
  openRequestDetailModal: false,
  request: {},
  openRequestModal: false,
  openAddCandidateModal: false,
};

export default function pacTracker(state = initialState, action) {
  switch (action.type) {
    case actions.OPEN_CANDIDATE_MODAL:
      return {
        ...state,
        openCandidateModal: true,
        candidateId: action.payload.candidateId,
        candidateModalType: action.payload.candidateModalType,
        candidateData: action.payload.candidateData,
      };
    case actions.CLOSE_CANDIDATE_MODAL:
      return {
        ...state,
        openCandidateModal: false,
      };
    case actions.OPEN_REQUEST_DETAIL_MODAL:
      return {
        ...state,
        openRequestDetailModal: true,
        RequestDetail: action.payload,
      };
    case actions.CLOSE_REQUEST_DETAIL_MODAL:
      return {
        ...state,
        openRequestDetailModal: false,
      };
    case actions.OPEN_REQUEST_MODAL:
      return {
        ...state,
        openRequestModal: true,
        request: action.payload,
      };
    case actions.OPEN_ADD_CANDIDATE_MODAL:
      return {
        ...state,
        openAddCandidateModal: true,
        editCandidateData: action.payload,
      };
    case actions.CLOSE_REQUEST_MODAL:
      return {
        ...state,
        openRequestModal: false,
      };
    case actions.CLOSE_ADD_CANDIDATE_MODAL:
      return {
        ...state,
        openAddCandidateModal: false,
      };
    default:
      return state;
  }
}
