import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FETrackerAction from "../../../store/feTracker/action";
import FEHeader from "../FEHeader";
import BudgetApproverListTable from "./BudgetApproverListTable";
import FEServices from "../F&ERequests/FEService";
import _ from "lodash";
import AddBudgetApprover from "./AddBudgetApprover";

export class BudgetApprovers extends Component {
  state = {
    budgetLines: [],
    editBudgetline: {},
    isConfirmModalOpen: false,
    deleteId: 0,
  };
  async componentDidMount() {
    await this.reloadBudgetlines();
  }

  toggleConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  confirm = () => {
    this.confirmed = async () => {
      if (this.state.deleteId == 0) {
        return false;
      }
      var result = await FEServices.DeleteBudgetline(this.state.deleteId);

      if (result !== null) {
        this.setState({ deleteId: 0 });
        this.toggleConfirmModal();
        await this.reloadBudgetlines();
      }
    };
    return this;
  };

  reloadBudgetlines = async () => {
    let data = await FEServices.LoadAllBudgetLines();
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        this.state.budgetLines = data.result != null ? data.result : [];
        await this.setState({
          budgetLines: this.state.budgetLines,
        });
      }
    }
  };
  onClose = async () => {
    this.props.toggleBudgetLineModal();
    this.setState({ editBudgetline: {} });
  };
  viewBudgetLine = (row) => {
    let editBL = {
      id: row.id,
      approverMember: row.approverId,
      name: row.name,
      approverName: row.budgetApprover,
    };
    this.setState({ editBudgetline: editBL });
    this.props.toggleBudgetLineModal();
  };
  deleteBudgetLine = (row) => {
    this.setState({ isConfirmModalOpen: !this.state.isConfirmModalOpen });
    this.setState({ deleteId: row.id });
  };
  render() {
    return (
      <>
        <FEHeader />
        <br />
        <div className="fet-container container-fluid">
          <div className="card directory-card border-rounded shadow">
            <h5 className="card-header mt-0  px-3 py-1">Budget Approvers</h5>
            <div className="card-body pb-2 px-0 pt-0">
              <BudgetApproverListTable
                viewBudgetLine={this.viewBudgetLine}
                deleteBudgetLine={this.deleteBudgetLine}
                listData={this.state.budgetLines}
              />
            </div>
          </div>
        </div>
        <AddBudgetApprover
          showModal={this.props.festate.openAddBudgetApproverModal}
          onClose={this.onClose}
          editBudgetline={this.state.editBudgetline}
          toggleModal={this.props.toggleBudgetLineModal}
          refreshBudgetLines={this.reloadBudgetlines}
          isConfirmModalOpen={this.state.isConfirmModalOpen}
          toggleConfirmModal={this.toggleConfirmModal}
          confirmDeleteAction={this.confirm}
        />
      </>
    );
  }
}
export default withRouter(
  connect(
    (state) => ({
      festate: state.fetracker,
    }),
    {
      ...FETrackerAction,
    }
  )(BudgetApprovers)
);
