import React, { Component, Fragment } from "react";
import AUX from "../../hoc/Aux_";
import $ from "jquery";

class ConfirmLoadBucketSearchModal extends Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextprops) {}
  render() {
    let confirmId = "CLBSModal";
    return (
      <AUX>
        <div
          className="modal fade delete-modal p-0"
          role="dialog"
          id={confirmId}
          aria-labelledby=""
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header text-dark">
                <h5 className="modal-title mt-0">Confirm Load Saved Bucket</h5>
              </div>
              <div className="modal-body">
                <div className="col-12 pb-2 text- text-center">
                  <span className="h1 fab fa-bitbucket text-info"></span>
                </div>
                <div className="col-12 mb-4">
                  <p className="h5 text-center">
                    Loading this saved bucket will clear the current bucket. Any
                    unsaved changes will be lost. Do you want to proceed?
                  </p>
                </div>

                <div className="col-12 text-center mb-3 border-top pt-4">
                  <button
                    className="btn btn-outline-success w-md waves-effect waves-light mr-2"
                    onClick={() => {
                      window.$("#" + confirmId).modal("hide");
                      this.props.confirm();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-outline-danger w-md waves-effect cancelModal waves-light"
                    onClick={() => {
                      window.$("#" + confirmId).modal("hide");
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default ConfirmLoadBucketSearchModal;
