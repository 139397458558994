import React, { Component } from "react";
import AddressPopup from "../../Shared/AddressPopup";
import EmailPopup from "../../Shared/EmailPopup";
import PhonePopup from "../../Shared/PhonePopup";
import PrimaryStar from "../../Shared/PrimaryStar";
import _ from "lodash";
import $ from "jquery";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { Spinner } from "reactstrap";

import * as Constants from "../../Admin/Users/EditUser/PrimaryEnum";
import AuthService from "../../Auth/AuthService";

export default class Step3 extends Component {
  render() {
    if (this.props.currentStep !== 3) {
      return null;
    }
    if (this.props.hasLoadedContactInfo === false) {
      return (
        <div className="row spinnerHeight">
          <Spinner className="spinnerCard" />
        </div>
      );
    }
    return (
      <>
        <h4 className="headerPos">Review Information</h4>
        <p>
          Please review your contact information below. 
          You will be able to update your information once the registration process is complete. 
          We take your privacy seriously. We do not sell, share, or rent any personally identifying information about you.
        </p>
        <div className="addressList">
          <h6 className="card-subtitle mb-2 text-muted">Addresses </h6>

          {this.props.addresses &&
            this.props.addresses.map((item, idx) => (
              <div className="" key={"index-" + idx}>
                {/*item.isprimary && <PrimaryStar />*/}
                <a
                  target="_blank"
                  href={
                    "http://maps.google.com/?q=" +
                    item.address1 +
                    ", " +
                    item.address2 +
                    item.address1 +
                    ", " +
                    item.city +
                    ", " +
                    item.state +
                    " " +
                    item.zipcode +
                    " "
                  }
                >
                  <p className="pt-0 pb-0 mt-0 mb-0">{item.address1} </p>
                  <p className="pt-0 pb-0 mt-0 mb-0">{item.address2} </p>
                  <p className="pt-0 pb-0 mt-0 mb-0">
                    {item.city} {item.state} {item.zipcode}{" "}
                  </p>
                </a>
              </div>
            ))}
          {!this.props.addresses ||
            (typeof this.props.addresses !== "undefined" &&
              _.isEmpty(this.props.addresses) && <h6>No address exist.</h6>)}
        </div>
        <div className="col-12 pl-0">
          {
            /*AuthService.canView(PermissionFeature.ContactEmailAddresses) && */ <div className="mb-1">
              <h6 className="card-subtitle mb-2 text-muted reviewInfoHeading">
                Email
              </h6>
              <p className="card-text">
                {this.props.emails &&
                  this.props.emails.map((item, idx) => (
                    <div classname="position-relative">
                      {/*item.isprimary && <PrimaryStar />*/}
                      {!this.props.editItem && (
                        <a target="_blank" href={"mailto:" + item.email}>
                          {" "}
                          <p className="pt-0 pb-0 mt-0 mb-0">{item.email} </p>
                        </a>
                      )}
                    </div>
                  ))}

                {!this.props.emails ||
                  (typeof this.props.emails !== "undefined" &&
                    _.isEmpty(this.props.emails) && <h6>No email exist.</h6>)}
              </p>
            </div>
          }
          {
            /*AuthService.canView(PermissionFeature.ContactPhones) && */ <>
              <div className="mb-1">
                <h6 className="card-subtitle mb-2 text-muted reviewInfoHeading">
                  Phone{" "}
                </h6>
                <p className="card-text">
                  {this.props.phonenumbers &&
                    this.props.phonenumbers.map((item, idx) => (
                      <div className="position-relative">
                        {/*item.isprimary && <PrimaryStar />*/}

                        <a href={"tel:" + item.number}>
                          {" "}
                          {item.number != "" && item.number != null ? (
                            <p className="pt-0 pb-0 mt-0 mb-0">
                              {item.phonetype == null ? "" : item.phonetype}{" "}
                              {item.number}{" "}
                            </p>
                          ) : (
                            ""
                          )}
                        </a>
                      </div>
                    ))}
                  {!this.props.phonenumbers ||
                    (typeof this.props.phonenumbers !== "undefined" &&
                      _.isEmpty(this.props.phonenumbers) && (
                        <h6>No phone number exist.</h6>
                      ))}
                </p>
              </div>
            </>
          }
        </div>
        <div className="form-group m-t-10 mb-4 row">
          <div className="col-12 m-t-20 mx-auto text-center">
            <button
              className="btn btn-primary btn-block w-md waves-effect waves-light"
              type="button"
              onClick={this.props.myInfoCorrectBtnClick}
            >
              Continue
            </button>
          </div>
        </div>
      </>
    );
  }
}
