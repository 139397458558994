import React, { Component } from "react";
import moment from "moment";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";

import AuthService from "../Auth/AuthService";
import PermissionFeature from "../Permissions/PermissionFeature";
class PopoverItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
      //this is for check carousel slide change
      ActiveIndex: this.props.activeIndex,
    };
    //popover binding
    this.togglePopover = this.togglePopover.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    //if slide change then
    // console.log(this.state.ActiveIndex + ' ' + nextProps.activeIndex);
    if (this.state.ActiveIndex != nextProps.activeIndex) {
      this.setState({ popoverOpen: false, ActiveIndex: nextProps.activeIndex });
    }
  }
  //popover
  togglePopover = () => this.setState({ popoverOpen: !this.state.popoverOpen });

  render() {
    const { index, item } = this.props;
    //console.log(item);
    return (
      <Popover
        placement="left-start"
        isOpen={this.state.popoverOpen}
        toggle={this.togglePopover}
        target={"PopoverClick" + index}
      >
        <PopoverBody>
          <div className="row" id="JobInline">
            <div className="col-6 text-right">
              <div className="py-1">
                <span className="card-subtitle mb-2 text-muted">
                  <strong>Effective Date: </strong>
                </span>
                <span className="card-text">
                  {item.startdate == null || item.startdate == ""
                    ? "none"
                    : moment(item.startdate).format("MM/DD/YYYY")}
                </span>
              </div>
            </div>
            <div className="col-6">
              <div className="py-1">
                <span className="card-subtitle mb-2 text-muted">
                  <strong>Status Date: </strong>
                </span>
                <span className="card-text">
                  {item.jobstatusdate == null || item.jobstatusdate == ""
                    ? "none"
                    : moment(item.jobstatusdate).format("MM/DD/YYYY")}
                </span>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6 text-right">
                  <b>Previous Facility</b>
                </div>
                <div className="col-6">
                  {AuthService.canView(PermissionFeature.FinancialUnit) &&
                    (item.busto == null || item.busto == ""
                      ? "none"
                      : item.busto)}
                  {AuthService.canView(PermissionFeature.FinancialUnit) &&
                    AuthService.canView(
                      PermissionFeature.FinancialFacilityAddressRoutingSymbol
                    ) &&
                    "/"}
                  {AuthService.canView(
                    PermissionFeature.FinancialFacilityAddressRoutingSymbol
                  ) &&
                    (item.facilityroutingsymbolto == null ||
                    item.facilityRoutingSymbolTo == ""
                      ? "none"
                      : item.facilityroutingsymbolto)}
                </div>
                <div className="col-6 text-right">
                  <b>NOA</b>
                </div>
                <div className="col-6">
                  {" "}
                  {item.noa1 == null || item.noa1 == "" ? "--" : item.noa1}
                  {"/"}
                  {item.noa2 == null || item.noa2 == "" ? "--" : item.noa2}
                </div>
              </div>
            </div>
          </div>
          {/* 
                <hr />
                <div className="row" id="JobInline">

                    <div className="col-6 text-right">
                        <div className="py-1">
                            <span className="card-subtitle mb-2 text-muted">
                                <strong>Effective Date: </strong>
                            </span>
                            <span className="card-text">04/12/2018</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="py-1">
                            <span className="card-subtitle mb-2 text-muted">
                                <strong>Status Date: </strong>
                            </span>
                            <span className="card-text">{(item.jobstatusdate == null || item.jobstatusdate == '') ? "none" : item.jobstatusdate}</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6 text-right"><b>Previous Facility</b></div>
                            <div className="col-6"> {(item.busTo == null || item.busTo == '') ? "none" : item.busTo}{((item.busTo != null && item.busTo != '') && (item.facilityRoutingSymbolTo != null && item.facilityRoutingSymbolTo != '')) && ("/")}{(item.facilityRoutingSymbolTo == null || item.facilityRoutingSymbolTo == '') ? "none" : item.facilityRoutingSymbolTo}</div>
                            <div className="col-6 text-right"><b>NOA</b></div>
                            <div className="col-6"> {(item.noa1 == null || item.noa1 == '') ? "--" : item.noa1}{"/"}{(item.noa2 == null || item.noa2 == '') ? "--" : item.noa2}</div>
                        </div>
                    </div>

                </div> */}
        </PopoverBody>
      </Popover>
    );
  }
}
export default PopoverItem;
