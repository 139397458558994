import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { toast } from "react-toastify";
import "../payfilestyle.css";
import Services from "../PayfileService";
import _ from "lodash";
import { debounce } from "throttle-debounce";
import $ from "jquery";
import ImportDetailModal from "./ImportDetailModal";
import ConfirmDeleteModal from "./General/DeleteModal";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "../../../modules/Shared/auth-header";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
class FinanceImportSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      fileId: -1,
      offset: 0,
      fetch: 20,
      errorsOffset: 0,
      updatesOffset: 0,
      ignoresOffset: 0,
      deleteRefresh: false,
      deleteStatus: "",
      searchTerm: "",
      financeData: null,
      errorsIsMore: true,
      ignoresIsMore: true,
      updatesIsMore: true,
      detailIgnoreStatus: false,
      deleteImport: null,
      openImportDetailModal: false,
      deleteModal: false,
      deleteId: "",
      importData: null,
      updatedFinanceAndField: [],
      financeDetail: null,
    };
    this.searchKeyword = this.searchKeyword.bind(this);
    this.handleRemoveEmpoloyeeFinances = this.handleRemoveEmpoloyeeFinances.bind(
      this
    );
    this.refreshOnIgnore = this.refreshOnIgnore.bind(this);
    this.refreshOnUpdate = this.refreshOnUpdate.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.openImportDetailModal = this.openImportDetailModal.bind(this);
    this.closeImportDetailModal = this.closeImportDetailModal.bind(this);
  }

  closeImportDetailModal() {
    this.setState({ openImportDetailModal: false });
  }
  openImportDetailModal() {
    this.setState({ openImportDetailModal: true });
  }
  autocompleteSearch = () => {
    this._fetch();
  };

  _fetch = async () => {
    var data = await this.searchKeyword();
  };
  Status = {
    Skip: "Skip",
    Update: "Update",
    Error: "Error",
  };
  Modes = {
    excel: "excel",
    pdf: "pdf",
  };
  refreshOnUpdate = async () => {
    $(".loading").show();
    this.state.deleteRefresh = true;
    await this.setState({ deleteRefresh: this.state.deleteRefresh });
    if (this.state.detailIgnoreStatus) {
      await this.LoadPaginationData("skip");
    } else {
      await this.LoadPaginationData("error");
    }
    await this.LoadPaginationData("update");
    // await this.LoadData();
    this.LoadDetail();
    $(".loading").hide();
    await this.setState({ deleteRefresh: false });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (!id) {
      this.props.history.push("/admin/uploads");
    }
    this.state.fileId = id;
    this.setState({ fileId: this.state.fileId });
    this.LoadData();
    this.LoadDetail();
  }

  async LoadData() {
    await this.setState({
      ignoresOffset: 0,
      updatesOffset: 0,
      errorsOffset: 0,
    });
    let data = await Services.LoadFinanceData(
      this.state.searchTerm,
      this.state.fileId,
      this.state.offset,
      this.state.fetch
    );
    if (data && data.statusCode == 200) {
      this.state.financeData = data.result;
      if (
        this.state.financeData.errors &&
        !_.isEmpty(this.state.financeData.errors)
      ) {
        this.state.errorsIsMore =
          this.state.financeData.errors.length ==
          this.state.fetch + this.state.errorsOffset;
      }
      if (
        this.state.financeData.updates &&
        !_.isEmpty(this.state.financeData.updates)
      ) {
        this.state.updatesIsMore =
          this.state.financeData.updates.length ==
          this.state.fetch + this.state.updatesOffset;
      }
      if (
        this.state.financeData.ignores &&
        !_.isEmpty(this.state.financeData.ignores)
      ) {
        this.state.ignoresIsMore =
          this.state.financeData.ignores.length ==
          this.state.fetch + this.state.ignoresOffset;
      }
      this.setState({
        financeData: this.state.financeData,
        ignoresIsMore: this.state.ignoresIsMore,
        updatesIsMore: this.state.updatesIsMore,
        errorsIsMore: this.state.errorsIsMore,
        ignoresOffset: this.state.fetch,
        errorsOffset: this.state.fetch,
        updatesOffset: this.state.fetch,
      });
    }
  }
  async LoadDetail() {
    let data = await Services.LoadFinanceDetail(
      this.state.searchTerm,
      this.state.fileId
    );
    if (data && data.statusCode == 200) {
      this.setState({ financeDetail: data.result });
    }
  }
  async LoadPaginationData(status) {
    switch (status) {
      case "skip": {
        let data = null;
        if (this.state.deleteRefresh) {
          data = await Services.LoadFinancePGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            0,
            this.state.ignoresOffset
          );
        } else {
          data = await Services.LoadFinancePGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            this.state.ignoresOffset,
            this.state.fetch
          );
        }
        if (data && data.statusCode == 200) {
          if (this.state.financeData.ignores) {
            if (this.state.deleteRefresh) {
              this.state.financeData.ignores = data.result;
            } else {
              this.state.financeData.ignores.push(...data.result);
            }
            this.state.ignoresIsMore =
              this.state.financeData.ignores.length ==
              (!this.state.deleteRefresh
                ? this.state.fetch + this.state.ignoresOffset
                : this.state.ignoresOffset);
            this.state.ignoresOffset =
              this.state.ignoresIsMore && !this.state.deleteRefresh
                ? this.state.ignoresOffset + this.state.fetch
                : this.state.ignoresOffset;
          }

          this.setState({
            financeData: this.state.financeData,
            ignoresIsMore: this.state.ignoresIsMore,
            ignoresOffset: this.state.ignoresOffset,
          });
        }
        break;
      }
      case "update": {
        let data = null;
        if (this.state.deleteRefresh) {
          data = await Services.LoadFinancePGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            0,
            this.state.updatesOffset
          );
        } else {
          data = await Services.LoadFinancePGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            this.state.updatesOffset,
            this.state.fetch
          );
        }
        if (data && data.statusCode == 200) {
          if (this.state.financeData.updates) {
            if (this.state.deleteRefresh) {
              this.state.financeData.updates = data.result;
            } else {
              this.state.financeData.updates.push(...data.result);
            }
            this.state.updatesIsMore =
              this.state.financeData.updates.length ==
              (!this.state.deleteRefresh
                ? this.state.fetch + this.state.updatesOffset
                : this.state.updatesOffset);
            this.state.updatesOffset =
              this.state.updatesIsMore && !this.state.deleteRefresh
                ? this.state.updatesOffset + this.state.fetch
                : this.state.updatesOffset;
          }

          this.setState({
            financeData: this.state.financeData,
            updatesIsMore: this.state.updatesIsMore,
            updatesOffset: this.state.updatesOffset,
          });
        }
        break;
      }
      case "error": {
        let data = null;
        if (this.state.deleteRefresh) {
          data = await Services.LoadFinancePGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            0,
            this.state.errorsOffset
          );
        } else {
          data = await Services.LoadFinancePGData(
            this.state.searchTerm,
            this.state.fileId,
            status,
            this.state.errorsOffset,
            this.state.fetch
          );
        }
        if (data && data.statusCode == 200) {
          if (this.state.financeData.errors) {
            if (this.state.deleteRefresh) {
              this.state.financeData.errors = data.result;
            } else {
              this.state.financeData.errors.push(...data.result);
            }
            this.state.errorsIsMore =
              this.state.financeData.errors.length ==
              (!this.state.deleteRefresh
                ? this.state.fetch + this.state.errorsOffset
                : this.state.errorsOffset);

            this.state.errorsOffset =
              this.state.errorsIsMore && !this.state.deleteRefresh
                ? this.state.errorsOffset + this.state.fetch
                : this.state.errorsOffset;
          }
          this.setState({
            financeData: this.state.financeData,
            errorsIsMore: this.state.errorsIsMore,
            errorsOffset: this.state.errorsOffset,
          });
        }
        break;
      }
      default:
    }
  }

  async LoadUpdates(financeFileID) {
    // on expand it will call
    // result will stored with memberId as a key
    // next time if key not found then object will be create.
    // NB ==> flag assign to those whose value not exist in DB
    let update = false;
    let index = this.state.updatedFinanceAndField.findIndex(
      (x) => x.Id == financeFileID
    );
    let Obj = null;
    if (index > -1) {
      Obj = this.state.updatedFinanceAndField[index];
    } else {
      update = true;
      Obj = this.updatedJobAndFieldObj(financeFileID);
    }
    if (Obj.Fields == null && Obj.Fields != "NB") {
      update = true;
      let data = await Services.LoadUpdateFinanceField(financeFileID);
      if (data != null) {
        Obj.Fields = data.result;
      } else {
        Obj.Fields = "NB";
      }
    }
    if (Obj.Finances == null && Obj.Finances != "NB") {
      update = true;
      let data = await Services.LoadUpdateFinance(financeFileID);
      if (data != null) {
        Obj.Finances = data.result;
      } else {
        Obj.Finances = "NB";
      }
    }
    if (!(index > -1)) {
      this.state.updatedFinanceAndField.push(Obj);
    }
    if (update) {
      this.setState({ updatedJobAndField: this.state.updatedFinanceAndField });
    }
  }
  updatedJobAndFieldObj(financeFileID) {
    return {
      Id: financeFileID,
      Finances: null,
      Fields: null,
    };
  }
  erroroggle = () => {
    this.setState({ isError: !this.state.isError });
  };
  refreshOnIgnore = async () => {
    $(".loading").show();
    this.state.deleteRefresh = true;
    await this.setState({ deleteRefresh: this.state.deleteRefresh });
    await this.LoadPaginationData("error");
    await this.LoadPaginationData("skip");
    // await this.LoadData();
    this.LoadDetail();
    $(".loading").hide();
    await this.setState({ deleteRefresh: false });
  };
  handleRemoveEmpoloyeeFinances = async () => {
    $(".loading").show();

    if (
      this.state.deleteId != null &&
      this.state.deleteId != "" &&
      this.state.deleteId != undefined &&
      this.state.fileId != null &&
      this.state.fileId != "" &&
      this.state.fileId != undefined
    ) {
      var data = await Services.DeleteFinanceFileEntry(this.state.deleteId);
      if (data != null) {
        this.state.deleteRefresh = true;
        await this.setState({ deleteRefresh: this.state.deleteRefresh });
        await this.LoadPaginationData(this.state.deleteStatus);
        // await this.LoadData();
        this.LoadDetail();
        $(".loading").hide();
        await this.setState({
          deleteRefresh: false,
          deleteModal: false,
          deleteStatus: "",
        });
      } else {
        $(".loading").hide();
        await this.setState({ deleteModal: false });
      }
    } else {
      toast.error("Invalid state, Try again after few moments.");
      await this.setState({ deleteModal: false });
      $(".loading").hide();
    }
  };
  formate_amount(n) {
    var num_parts = n.toString().split(".");
    num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
    //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }
  async searchKeyword() {
    $(".show:not(.scroll-view)").removeClass("show");
    this.state.searchTerm = $("#keywordIS2").val();
    this.state.ignoresOffset = 0;
    this.state.updatesOffset = 0;
    this.state.errorsOffset = 0;
    this.setState({
      searchTerm: $("#keywordIS2").val(),
      ignoresOffset: 0,
      updatesOffset: 0,
      errorsOffset: 0,
    });
    await this.LoadData();
    await this.LoadDetail();
  }
  render() {
    return (
      <AUX>
        <div className="container-fluid">
          {AuthService.canView(PermissionFeature.FinanceFile) ? (
            <React.Fragment>
              <div className="page-title-box">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <h4 className="text-uppercase page-title-search">
                      Finance Import Summary
                    </h4>
                    <div role="search" className="app-search mt-0">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          id="keywordIS2"
                          className="form-control rounded border mb-1 w-100"
                          placeholder="Import Member Name Search.."
                          onChange={(e) => {
                            e.preventDefault();
                            this.autocompleteSearchDebounced();
                          }}
                        />
                        <button
                          type="button"
                          className="c-pointer text-secondary bg-transparent border-0"
                          onClick={this.searchKeyword}
                        >
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="col-lg-12 text-right payfiledetail">
                    {AuthService.canView(
                      PermissionFeature.FinanceFileRecordDetail
                    ) &&
                      !_.isEmpty(this.state.financeDetail) && (
                        <div className="row">
                          <div className="col-md-2">
                            <h4>Imported Date</h4>
                            <p>
                              {moment(
                                this.state.financeDetail.createDate
                              ).format("MM/DD/YYYY")}
                            </p>
                          </div>
                          <div className="col-md-2">
                            <h4>Total Records</h4>
                            <p>{this.state.financeDetail.totalRecord}</p>
                          </div>
                          <div className="col-md-2">
                            <h4>Year/PP</h4>
                            <p>
                              {this.state.financeDetail.year}/
                              {this.state.financeDetail.payPeriod}
                            </p>
                          </div>
                          <div className="col-md-3">
                            <h4>Expected Amount</h4>
                            <p>
                              {this.formate_amount(
                                this.state.financeDetail.totalExpectedAmount
                              )}
                            </p>
                          </div>
                          <div className="col-md-3">
                            <h4>Amount</h4>
                            <p>
                              {this.formate_amount(
                                this.state.financeDetail.totalAmount
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="table-summary-main">
                <div className="import-table">
                  <div className="top-th dark-gray">
                    {AuthService.canView(
                      PermissionFeature.PersonalInfoFirstName
                    ) && <div className="w17-5 td">FIRST NAME</div>}
                    {AuthService.canView(
                      PermissionFeature.PersonalInfoLastName
                    ) && <div className="w17-5 td">LAST NAME</div>}

                    {AuthService.canView(PermissionFeature.Region) &&
                      AuthService.canView(PermissionFeature.RegionCode) && (
                        <div className="w12-5 td">REGION</div>
                      )}
                    {AuthService.canView(PermissionFeature.Facility) &&
                      AuthService.canView(PermissionFeature.FacilityCode) && (
                        <div className="w12-5 td">FACILITY</div>
                      )}
                    <div className="w15 td">EXP AMOUNT</div>
                    <div className="w12-5 td">AMOUNT</div>
                    {AuthService.canView(
                      PermissionFeature.PersonalInfoMemberNumber
                    ) && <div className="w12-5 td">MEMBER #</div>}
                  </div>
                  {this.state.financeData &&
                    this.state.financeData.errors &&
                    !_.isEmpty(this.state.financeData.errors) &&
                    AuthService.canView(
                      PermissionFeature.FinanceFileErrors
                    ) && (
                      <>
                        <div className="top-th red-white">
                          <div
                            data-toggle="collapse"
                            data-target="#errors"
                            aria-expanded="true"
                            aria-controls="errors"
                            className="w93 td"
                          >
                            IMPORT ERRORS{" "}
                            {!_.isEmpty(this.state.financeDetail) &&
                              AuthService.canView(
                                PermissionFeature.FinanceFileErrorsSummary
                              ) && (
                                <>
                                  <span className="sectionCount">
                                    (
                                    {
                                      this.state.financeDetail
                                        .totalErrorEffective
                                    }{" "}
                                    members effective out of{" "}
                                    {this.state.financeDetail.totalError}{" "}
                                    finance)
                                  </span>
                                  <span className="pl-2">
                                    (<b>Total Error Exp Amount:</b>
                                    <i>
                                      {this.formate_amount(
                                        this.state.financeDetail
                                          .totalErrorExpectedAmount
                                      )}
                                    </i>{" "}
                                    <b>Total Error Amount:</b>
                                    <i>
                                      {this.formate_amount(
                                        this.state.financeDetail
                                          .totalErrorAmount
                                      )}
                                      )
                                    </i>
                                  </span>
                                </>
                              )}
                          </div>
                          <div className="w7 icons-file">
                            {AuthService.canView(
                              PermissionFeature.FinanceFileErrorsCSV
                            ) && (
                              <span
                                className="text-white pr-1"
                                onClick={async (e) => {
                                  $(".loading").show();

                                  let data = await Services.GenerateFinanceFileExcel(
                                    this.state.searchTerm,
                                    this.Status.Error,
                                    this.state.fileId
                                  );

                                  $(".loading").hide();
                                }}
                              >
                                <i class="fas fa-file-csv" />
                              </span>
                            )}
                            {AuthService.canView(
                              PermissionFeature.FinanceFileErrorsPDF
                            ) && (
                              <span
                                className="text-white"
                                onClick={async (e) => {
                                  $(".loading").show();
                                  if (
                                    this.state.financeDetail.totalError >= 10000
                                  ) {
                                    toast.warn(
                                      "PDF will contains only 10,000 rows."
                                    );
                                  }
                                  let data = await Services.GenerateFinanceFilePDF(
                                    this.state.searchTerm,
                                    this.Status.Error,
                                    this.state.fileId
                                  );

                                  $(".loading").hide();
                                }}
                              >
                                <i class="fas fa-file-pdf" />
                              </span>
                            )}
                          </div>
                        </div>
                        <div id="errors" className="collapse show scroll-view">
                          <InfiniteScroll
                            dataLength={this.state.financeData.errors.length}
                            next={() => {
                              this.LoadPaginationData("error");
                            }}
                            hasMore={this.state.errorsIsMore}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="errors"
                          >
                            {this.state.financeData.errors.map(
                              (item, idx) =>
                                AuthService.canSPView(
                                  PermissionFeature.FinanceFileErrors,
                                  item.regionId,
                                  item.facilityId,
                                  item.memberID
                                ) && (
                                  <>
                                    <div
                                      className="top-td light-white"
                                      data-toggle="collapse"
                                      data-target={"#err-" + idx}
                                      aria-expanded={
                                        idx == 0 ? "true" : "false"
                                      }
                                      aria-controls="errorsdivs"
                                    >
                                      {AuthService.canView(
                                        PermissionFeature.PersonalInfoFirstName
                                      ) && (
                                        <div className="w17-5 td resW99">
                                          {AuthService.canSPView(
                                            PermissionFeature.FinanceFileErrorsMemberName,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.PersonalInfoFirstName,
                                              item.regionId,
                                              item.facilityId,
                                              item.memberID
                                            ) && <>{item.firstName}</>}
                                        </div>
                                      )}
                                      {AuthService.canView(
                                        PermissionFeature.PersonalInfoLastName
                                      ) && (
                                        <div className="w17-5 td resW99">
                                          {AuthService.canSPView(
                                            PermissionFeature.FinanceFileErrorsMemberName,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.PersonalInfoLastName,
                                              item.regionId,
                                              item.facilityId,
                                              item.memberID
                                            ) && <>{item.lastName}</>}
                                        </div>
                                      )}
                                      {AuthService.canView(
                                        PermissionFeature.Region
                                      ) &&
                                        AuthService.canView(
                                          PermissionFeature.RegionCode
                                        ) && (
                                          <div className="w12-5 td resW72">
                                            {AuthService.canSPView(
                                              PermissionFeature.Region,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.RegionCode,
                                                item.regionId,
                                                item.facilityId,
                                                item.memberID
                                              ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.FinanceFileErrorsRegion,
                                                item.regionId,
                                                item.FacilityId,
                                                item.memberID
                                              ) && <>{item.regionCode}</>}
                                          </div>
                                        )}
                                      {AuthService.canView(
                                        PermissionFeature.Facility
                                      ) &&
                                        AuthService.canView(
                                          PermissionFeature.FacilityCode
                                        ) && (
                                          <div className="w12-5 td resW72">
                                            {AuthService.canSPView(
                                              PermissionFeature.Facility,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.FacilityCode,
                                                item.regionId,
                                                item.facilityId,
                                                item.memberID
                                              ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.FinanceFileErrorsFacility,
                                                item.regionId,
                                                item.FacilityId,
                                                item.memberID
                                              ) && <>{item.facilityCode}</>}
                                          </div>
                                        )}
                                      <div className="w15 td resW65">
                                        {AuthService.canSPView(
                                          PermissionFeature.FinanceFileErrorsExpectedAmount,
                                          item.regionId,
                                          item.FacilityId,
                                          item.memberID
                                        ) && (
                                          <>
                                            {item.expectedAmount &&
                                              this.formate_amount(
                                                item.expectedAmount
                                              )}
                                          </>
                                        )}
                                      </div>
                                      <div className="w12-5 td resW85">
                                        {AuthService.canSPView(
                                          PermissionFeature.FinanceFileErrorsAmount,
                                          item.regionId,
                                          item.FacilityId,
                                          item.memberID
                                        ) && (
                                          <>
                                            {item.amount &&
                                              this.formate_amount(item.amount)}
                                          </>
                                        )}
                                      </div>
                                      {AuthService.canView(
                                        PermissionFeature.PersonalInfoMemberNumber
                                      ) && (
                                        <div className="w12-5 td resW85">
                                          {AuthService.canSPView(
                                            PermissionFeature.PersonalInfoMemberNumber,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.FinanceFileErrorsMemberNumber,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) && <>{item.memberNumber}</>}
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className={
                                        "w100 bdr-bottom collapse" +
                                        (idx == 0 ? " show" : "")
                                      }
                                      id={"err-" + idx}
                                    >
                                      <div className="rowdiv pt-3">
                                        <div className="w100 pdl-45">
                                          <p className="infoError reason-area">
                                            Reason: <span>{item.reason}</span>
                                          </p>
                                        </div>
                                      </div>

                                      <h6 className="error-i mb-1  mt-0">
                                        Errors{" "}
                                        {AuthService.canUpdate(
                                          PermissionFeature.FinanceFile
                                        ) &&
                                          AuthService.canSPUpdate(
                                            PermissionFeature.FinanceFileErrors,
                                            item.regionId,
                                            item.facilityId,
                                            item.memberID
                                          ) && (
                                            <i
                                              className="fas fa-pen c-pointer"
                                              onClick={() => {
                                                this.setState({
                                                  importData: item,
                                                  detailIgnoreStatus: false,
                                                });
                                                this.openImportDetailModal();
                                              }}
                                            />
                                          )}
                                        {AuthService.canDelete(
                                          PermissionFeature.FinanceFile
                                        ) &&
                                          AuthService.canSPDelete(
                                            PermissionFeature.FinanceFileErrors,
                                            item.regionId,
                                            item.facilityId,
                                            item.memberID
                                          ) && (
                                            <i
                                              onClick={(e) => {
                                                this.state.deleteId = item.id;
                                                this.setState({
                                                  deleteId: this.state.deleteId,
                                                  deleteModal: true,
                                                  deleteStatus: "error",
                                                });
                                              }}
                                              className="fas fa-trash c-pointer"
                                            />
                                          )}
                                      </h6>
                                    </div>
                                  </>
                                )
                            )}
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                  {this.state.financeData &&
                    this.state.financeData.ignores &&
                    !_.isEmpty(this.state.financeData.ignores) &&
                    AuthService.canView(
                      PermissionFeature.FinanceFileIgnore
                    ) && (
                      <>
                        <div className="top-th yellow-black">
                          <div
                            data-toggle="collapse"
                            data-target="#ignore"
                            aria-expanded="false"
                            aria-controls="ignore"
                            className="w93 td"
                          >
                            IGNORED UPDATES{" "}
                            {!_.isEmpty(this.state.financeDetail) &&
                              AuthService.canView(
                                PermissionFeature.FinanceFileIgnoreSummary
                              ) && (
                                <>
                                  <span className="sectionCount">
                                    (
                                    {
                                      this.state.financeDetail
                                        .totalIgnoreEffective
                                    }{" "}
                                    members effective out of{" "}
                                    {this.state.financeDetail.totalIgnore}{" "}
                                    finance)
                                  </span>
                                  <span className="pl-2">
                                    (<b>Total Ignore Exp Amount:</b>
                                    <i>
                                      {this.formate_amount(
                                        this.state.financeDetail
                                          .totalIgnoreExpectedAmount
                                      )}
                                    </i>{" "}
                                    <b>Total Ignore Amount:</b>
                                    <i>
                                      {this.formate_amount(
                                        this.state.financeDetail
                                          .totalIgnoreAmount
                                      )}
                                      )
                                    </i>
                                  </span>
                                </>
                              )}
                          </div>
                          <div className="w7 icons-file">
                            {AuthService.canView(
                              PermissionFeature.FinanceFileIgnoreCSV
                            ) && (
                              <span
                                className="text-dark"
                                onClick={async (e) => {
                                  $(".loading").show();

                                  let data = await Services.GenerateFinanceFileExcel(
                                    this.state.searchTerm,
                                    this.Status.Skip,
                                    this.state.fileId
                                  );

                                  $(".loading").hide();
                                }}
                              >
                                <i class="fas fa-file-csv" />
                              </span>
                            )}
                            {AuthService.canView(
                              PermissionFeature.FinanceFileIgnorePDF
                            ) && (
                              <span
                                className="text-dark"
                                onClick={async (e) => {
                                  $(".loading").show();
                                  if (
                                    this.state.financeDetail.totalIgnore >=
                                    10000
                                  ) {
                                    toast.warn(
                                      "PDF will contains only 10,000 rows."
                                    );
                                  }
                                  let data = await Services.GenerateFinanceFilePDF(
                                    this.state.searchTerm,
                                    this.Status.Skip,
                                    this.state.fileId
                                  );

                                  $(".loading").hide();
                                }}
                              >
                                <i class="fas fa-file-pdf" />
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          id="ignore"
                          className={
                            "collapse scroll-view" +
                            (!_.isEmpty(this.state.searchTerm) ||
                            (this.state.financeData.errors &&
                              _.isEmpty(this.state.financeData.errors))
                              ? " show"
                              : "")
                          }
                        >
                          <InfiniteScroll
                            dataLength={this.state.financeData.ignores.length}
                            next={() => {
                              this.LoadPaginationData("skip");
                            }}
                            hasMore={this.state.ignoresIsMore}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="ignore"
                          >
                            {this.state.financeData.ignores.map(
                              (item, idx) =>
                                AuthService.canSPView(
                                  PermissionFeature.FinanceFileIgnore,
                                  item.regionId,
                                  item.facilityId,
                                  item.memberID
                                ) && (
                                  <>
                                    <div
                                      className="top-td light-white"
                                      data-toggle="collapse"
                                      data-target={"#igr-" + idx}
                                      aria-expanded={
                                        idx == 0 ? "true" : "false"
                                      }
                                      aria-controls="ignoresdivs"
                                    >
                                      {" "}
                                      {AuthService.canView(
                                        PermissionFeature.PersonalInfoFirstName
                                      ) && (
                                        <div className="w17-5 td resW99">
                                          {AuthService.canSPView(
                                            PermissionFeature.FinanceFileIgnoreMemberName,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.PersonalInfoFirstName,
                                              item.regionId,
                                              item.facilityId,
                                              item.memberID
                                            ) && <>{item.firstName}</>}
                                        </div>
                                      )}
                                      {AuthService.canView(
                                        PermissionFeature.PersonalInfoLastName
                                      ) && (
                                        <div className="w17-5 td resW99">
                                          {AuthService.canSPView(
                                            PermissionFeature.FinanceFileIgnoreMemberName,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.PersonalInfoLastName,
                                              item.regionId,
                                              item.facilityId,
                                              item.memberID
                                            ) && <>{item.lastName}</>}
                                        </div>
                                      )}
                                      {AuthService.canView(
                                        PermissionFeature.Region
                                      ) &&
                                        AuthService.canView(
                                          PermissionFeature.RegionCode
                                        ) && (
                                          <div className="w12-5 td resW72">
                                            {AuthService.canSPView(
                                              PermissionFeature.Region,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.RegionCode,
                                                item.regionId,
                                                item.facilityId,
                                                item.memberID
                                              ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.FinanceFileIgnoreRegion,
                                                item.regionId,
                                                item.FacilityId,
                                                item.memberID
                                              ) && <>{item.regionCode}</>}
                                          </div>
                                        )}
                                      {AuthService.canView(
                                        PermissionFeature.Facility
                                      ) &&
                                        AuthService.canView(
                                          PermissionFeature.FacilityCode
                                        ) && (
                                          <div className="w12-5 td resW72">
                                            {AuthService.canSPView(
                                              PermissionFeature.Facility,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.FacilityCode,
                                                item.regionId,
                                                item.facilityId,
                                                item.memberID
                                              ) &&
                                              AuthService.canSPView(
                                                PermissionFeature.FinanceFileIgnoreFacility,
                                                item.regionId,
                                                item.FacilityId,
                                                item.memberID
                                              ) && <>{item.facilityCode}</>}
                                          </div>
                                        )}
                                      <div className="w15 td resW65">
                                        {AuthService.canSPView(
                                          PermissionFeature.FinanceFileIgnoreExpectedAmount,
                                          item.regionId,
                                          item.FacilityId,
                                          item.memberID
                                        ) && (
                                          <>
                                            {item.expectedAmount &&
                                              this.formate_amount(
                                                item.expectedAmount
                                              )}
                                          </>
                                        )}
                                      </div>
                                      <div className="w12-5 td resW85">
                                        {AuthService.canSPView(
                                          PermissionFeature.FinanceFileIgnoreAmount,
                                          item.regionId,
                                          item.FacilityId,
                                          item.memberID
                                        ) && (
                                          <>
                                            {item.amount &&
                                              this.formate_amount(item.amount)}
                                          </>
                                        )}
                                      </div>
                                      {AuthService.canView(
                                        PermissionFeature.PersonalInfoMemberNumber
                                      ) && (
                                        <div className="w12-5 td resW85">
                                          {AuthService.canSPView(
                                            PermissionFeature.PersonalInfoMemberNumber,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.FinanceFileIgnoreMemberNumber,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) && <>{item.memberNumber}</>}
                                        </div>
                                      )}
                                    </div>

                                    <div
                                      className={
                                        "w100 bdr-bottom collapse" +
                                        (idx == 0 ? " show" : "")
                                      }
                                      id={"igr-" + idx}
                                    >
                                      <div className="rowdiv pt-3">
                                        <div className="w100 pdl-45">
                                          <p className="infoError reason-area">
                                            Reason: <span>{item.reason}</span>
                                          </p>
                                        </div>
                                      </div>
                                      <h6 className="ignore-i mb-1 pdl-45 mt-0">
                                        Import Values
                                        {AuthService.canUpdate(
                                          PermissionFeature.FinanceFile
                                        ) &&
                                          AuthService.canSPUpdate(
                                            PermissionFeature.FinanceFileIgnore,
                                            item.regionId,
                                            item.facilityId,
                                            item.memberID
                                          ) && (
                                            <i
                                              className="fas fa-pen c-pointer"
                                              onClick={() => {
                                                this.setState({
                                                  importData: item,
                                                  detailIgnoreStatus: true,
                                                });
                                                this.openImportDetailModal();
                                              }}
                                            />
                                          )}
                                        {AuthService.canDelete(
                                          PermissionFeature.FinanceFile
                                        ) &&
                                          AuthService.canSPDelete(
                                            PermissionFeature.FinanceFileIgnore,
                                            item.regionId,
                                            item.facilityId,
                                            item.memberID
                                          ) && (
                                            <i
                                              onClick={(e) => {
                                                this.state.deleteId = item.id;
                                                this.setState({
                                                  deleteId: this.state.deleteId,
                                                  deleteModal: true,
                                                  deleteStatus: "skip",
                                                });
                                              }}
                                              className="fas fa-trash c-pointer"
                                            />
                                          )}
                                      </h6>
                                    </div>
                                  </>
                                )
                            )}
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                  {this.state.financeData &&
                    this.state.financeData.updates &&
                    !_.isEmpty(this.state.financeData.updates) &&
                    AuthService.canView(
                      PermissionFeature.FinanceFileSuccess
                    ) && (
                      <>
                        <div className="top-th light-gray">
                          <div
                            data-toggle="collapse"
                            data-target="#success"
                            aria-expanded="false"
                            aria-controls="success"
                            className="w93 td"
                          >
                            SUCCESSFULL UPDATES{" "}
                            {!_.isEmpty(this.state.financeDetail) &&
                              AuthService.canView(
                                PermissionFeature.FinanceFileSuccessSummary
                              ) && (
                                <>
                                  <span className="sectionCount">
                                    (
                                    {
                                      this.state.financeDetail
                                        .totalUpdateEffective
                                    }{" "}
                                    members effective out of{" "}
                                    {this.state.financeDetail.totalUpdate}{" "}
                                    payfiles)
                                  </span>
                                  <span className="pl-2">
                                    (<b>Total Successfull Exp Amount:</b>
                                    <i>
                                      {this.formate_amount(
                                        this.state.financeDetail
                                          .totalUpdateExpectedAmount
                                      )}
                                    </i>{" "}
                                    <b>Total Successfull Amount:</b>
                                    <i>
                                      {this.formate_amount(
                                        this.state.financeDetail
                                          .totalUpdateAmount
                                      )}
                                      )
                                    </i>
                                  </span>
                                </>
                              )}
                          </div>
                          <div className="w7 icons-file">
                            {AuthService.canView(
                              PermissionFeature.FinanceFileSuccessCSV
                            ) && (
                              <span
                                className="text-white pr-1 "
                                onClick={async (e) => {
                                  $(".loading").show();

                                  let data = await Services.GenerateFinanceFileExcel(
                                    this.state.searchTerm,
                                    this.Status.Update,
                                    this.state.fileId
                                  );

                                  $(".loading").hide();
                                }}
                              >
                                <i class="fas fa-file-csv" />
                              </span>
                            )}
                            {AuthService.canView(
                              PermissionFeature.FinanceFileSuccessPDF
                            ) && (
                              <span
                                className="text-white"
                                onClick={async (e) => {
                                  $(".loading").show();
                                  if (
                                    this.state.financeDetail.totalUpdate >=
                                    10000
                                  ) {
                                    toast.warn(
                                      "PDF will contains only 10,000 rows."
                                    );
                                  }
                                  let data = await Services.GenerateFinanceFilePDF(
                                    this.state.searchTerm,
                                    this.Status.Update,
                                    this.state.fileId
                                  );

                                  $(".loading").hide();
                                }}
                              >
                                <i class="fas fa-file-pdf" />
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          id="success"
                          className={
                            "collapse scroll-view" +
                            (!_.isEmpty(this.state.searchTerm) ||
                            (this.state.financeData.errors &&
                              _.isEmpty(this.state.financeData.errors) &&
                              this.state.financeData.ignores &&
                              _.isEmpty(this.state.financeData.ignores))
                              ? " show"
                              : "")
                          }
                        >
                          <InfiniteScroll
                            dataLength={this.state.financeData.updates.length}
                            next={() => {
                              this.LoadPaginationData("update");
                            }}
                            hasMore={this.state.updatesIsMore}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="success"
                          >
                            {this.state.financeData.updates.map((item, idx) => {
                              if (
                                !AuthService.canSPView(
                                  PermissionFeature.FinanceFileSuccess,
                                  item.regionId,
                                  item.facilityId,
                                  item.memberID
                                )
                              ) {
                                return <></>;
                              }
                              if (idx == 0) {
                                this.LoadUpdates(item.id);
                              }
                              return (
                                <>
                                  <div
                                    className="top-td light-white"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.LoadUpdates(item.id);
                                      if (
                                        $("#upd-" + item.id).hasClass("show")
                                      ) {
                                        $("#upd-" + item.id).removeClass(
                                          "show"
                                        );
                                      } else {
                                        $("#upd-" + item.id).addClass("show");
                                      }
                                    }}
                                    aria-expanded={
                                      idx == 0 &&
                                      !_.isEmpty(
                                        this.state.updatedFinanceAndField
                                      ) &&
                                      this.state.updatedFinanceAndField.findIndex(
                                        (x) => x.Id == item.id
                                      ) > -1
                                        ? "true"
                                        : "false"
                                    }
                                    aria-controls="updatesdivs"
                                  >
                                    {AuthService.canView(
                                      PermissionFeature.PersonalInfoFirstName
                                    ) && (
                                      <div className="w17-5 td resW99">
                                        {AuthService.canSPView(
                                          PermissionFeature.FinanceFileSuccessMemberName,
                                          item.regionId,
                                          item.FacilityId,
                                          item.memberID
                                        ) &&
                                          AuthService.canSPView(
                                            PermissionFeature.PersonalInfoFirstName,
                                            item.regionId,
                                            item.facilityId,
                                            item.memberID
                                          ) && <>{item.firstName}</>}
                                      </div>
                                    )}
                                    {AuthService.canView(
                                      PermissionFeature.PersonalInfoLastName
                                    ) && (
                                      <div className="w17-5 td resW99">
                                        {AuthService.canSPView(
                                          PermissionFeature.FinanceFileSuccessMemberName,
                                          item.regionId,
                                          item.FacilityId,
                                          item.memberID
                                        ) &&
                                          AuthService.canSPView(
                                            PermissionFeature.PersonalInfoLastName,
                                            item.regionId,
                                            item.facilityId,
                                            item.memberID
                                          ) && <>{item.lastName}</>}
                                      </div>
                                    )}
                                    {AuthService.canView(
                                      PermissionFeature.Region
                                    ) &&
                                      AuthService.canView(
                                        PermissionFeature.RegionCode
                                      ) && (
                                        <div className="w12-5 td resW72">
                                          {AuthService.canSPView(
                                            PermissionFeature.Region,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.RegionCode,
                                              item.regionId,
                                              item.facilityId,
                                              item.memberID
                                            ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.FinanceFileSuccessRegion,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) && <>{item.regionCode}</>}
                                        </div>
                                      )}
                                    {AuthService.canView(
                                      PermissionFeature.Facility
                                    ) &&
                                      AuthService.canView(
                                        PermissionFeature.FacilityCode
                                      ) && (
                                        <div className="w12-5 td resW72">
                                          {AuthService.canSPView(
                                            PermissionFeature.Facility,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.FacilityCode,
                                              item.regionId,
                                              item.facilityId,
                                              item.memberID
                                            ) &&
                                            AuthService.canSPView(
                                              PermissionFeature.FinanceFileSuccessFacility,
                                              item.regionId,
                                              item.FacilityId,
                                              item.memberID
                                            ) && <>{item.facilityCode}</>}
                                        </div>
                                      )}
                                    <div className="w15 td resW65">
                                      {AuthService.canSPView(
                                        PermissionFeature.FinanceFileSuccessExpectedAmount,
                                        item.regionId,
                                        item.FacilityId,
                                        item.memberID
                                      ) && (
                                        <>
                                          {item.expectedAmount &&
                                            this.formate_amount(
                                              item.expectedAmount
                                            )}
                                        </>
                                      )}
                                    </div>
                                    <div className="w12-5 td resW85">
                                      {AuthService.canSPView(
                                        PermissionFeature.FinanceFileSuccessAmount,
                                        item.regionId,
                                        item.FacilityId,
                                        item.memberID
                                      ) && (
                                        <>
                                          {item.amount &&
                                            this.formate_amount(item.amount)}
                                        </>
                                      )}
                                    </div>
                                    {AuthService.canView(
                                      PermissionFeature.PersonalInfoMemberNumber
                                    ) && (
                                      <div className="w12-5 td resW85">
                                        {AuthService.canSPView(
                                          PermissionFeature.PersonalInfoMemberNumber,
                                          item.regionId,
                                          item.FacilityId,
                                          item.memberID
                                        ) &&
                                          AuthService.canSPView(
                                            PermissionFeature.FinanceFileSuccessMemberNumber,
                                            item.regionId,
                                            item.FacilityId,
                                            item.memberID
                                          ) && <>{item.memberNumber}</>}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      "w100 bdr-bottom collapse" +
                                      (idx == 0 &&
                                      !_.isEmpty(
                                        this.state.updatedFinanceAndField
                                      ) &&
                                      this.state.updatedFinanceAndField.findIndex(
                                        (x) => x.Id == item.id
                                      ) > -1
                                        ? " show"
                                        : "")
                                    }
                                    id={"upd-" + item.id}
                                  >
                                    {!_.isEmpty(
                                      this.state.updatedFinanceAndField
                                    ) &&
                                      this.state.updatedFinanceAndField.findIndex(
                                        (x) => x.Id == item.id
                                      ) > -1 &&
                                      (() => {
                                        let indexx = this.state.updatedFinanceAndField.findIndex(
                                          (x) => x.Id == item.id
                                        );
                                        return (
                                          <div className="rowdiv pb-3">
                                            <div className="width35 resW31person pdl-45 respdl-20">
                                              {!_.isEmpty(
                                                this.state
                                                  .updatedFinanceAndField[
                                                  indexx
                                                ].Fields
                                              ) &&
                                                this.state
                                                  .updatedFinanceAndField[
                                                  indexx
                                                ].Fields != "NB" && (
                                                  <>
                                                    <h6 className="ignore-i mb-1">
                                                      Update Fields
                                                    </h6>
                                                    {this.state.updatedFinanceAndField[
                                                      indexx
                                                    ].Fields.map((itemF) => (
                                                      <p className="infoError">
                                                        {itemF.fieldName}:{" "}
                                                        <span>
                                                          {_.isEmpty(
                                                            itemF.fieldValue
                                                          )
                                                            ? "Null"
                                                            : itemF.fieldValue}
                                                        </span>
                                                      </p>
                                                    ))}
                                                  </>
                                                )}
                                            </div>

                                            {!_.isEmpty(
                                              this.state.updatedFinanceAndField[
                                                indexx
                                              ].Finances
                                            ) &&
                                              this.state.updatedFinanceAndField[
                                                indexx
                                              ].Finances != "NB" && (
                                                <div className="width40  resW69person">
                                                  <h6 className="ignore-i mb-1">
                                                    Finance Changes
                                                  </h6>
                                                  <div className="mini-table">
                                                    <div className="tablehead">
                                                      <div className="width15 th-mini td">
                                                        STATUS
                                                      </div>
                                                      {AuthService.canView(
                                                        PermissionFeature.FinanceFileSuccessExpectedAmount
                                                      ) && (
                                                        <div class="width25 th-mini td">
                                                          EXPECTED AMOUNT
                                                        </div>
                                                      )}
                                                      {AuthService.canView(
                                                        PermissionFeature.FinanceFileSuccessAmount
                                                      ) && (
                                                        <div class="width20 th-mini td">
                                                          AMOUNT
                                                        </div>
                                                      )}
                                                      {AuthService.canView(
                                                        PermissionFeature.FinanceFileSuccessRefund
                                                      ) && (
                                                        <div class="width15 th-mini td">
                                                          REFUND
                                                        </div>
                                                      )}
                                                      {AuthService.canView(
                                                        PermissionFeature.FinanceFileSuccessDuesCollectionType
                                                      ) && (
                                                        <div class="width25 th-mini td">
                                                          DUES COLLECTION TYPE
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="tablebody">
                                                      {this.state.updatedFinanceAndField[
                                                        indexx
                                                      ].Finances.map(
                                                        (itemFi) => (
                                                          <div className="tr-body">
                                                            <div className="width15 th-mini td bold-dark">
                                                              {itemFi.status}
                                                            </div>
                                                            <div class="width25 th-mini td">
                                                              {AuthService.canSPView(
                                                                PermissionFeature.FinanceFileSuccessExpectedAmount,
                                                                itemFi.regionId,
                                                                itemFi.facilityId,
                                                                item.memberID
                                                              ) && (
                                                                <>
                                                                  {itemFi.expectedAmount &&
                                                                    this.formate_amount(
                                                                      itemFi.expectedAmount
                                                                    )}
                                                                </>
                                                              )}
                                                            </div>
                                                            <div class="width20 th-mini td">
                                                              {AuthService.canSPView(
                                                                PermissionFeature.FinanceFileSuccessAmount,
                                                                itemFi.regionId,
                                                                itemFi.facilityId,
                                                                item.memberID
                                                              ) && (
                                                                <>
                                                                  {itemFi.amount &&
                                                                    this.formate_amount(
                                                                      itemFi.amount
                                                                    )}
                                                                </>
                                                              )}
                                                            </div>
                                                            <div class="width15 th-mini td">
                                                              {AuthService.canSPView(
                                                                PermissionFeature.FinanceFileSuccessRefund,
                                                                itemFi.regionId,
                                                                itemFi.facilityId,
                                                                item.memberID
                                                              ) && (
                                                                <>
                                                                  {itemFi.refund &&
                                                                  itemFi.refund ==
                                                                    true
                                                                    ? "Yes"
                                                                    : "No"}
                                                                </>
                                                              )}
                                                            </div>
                                                            <div class="width25 th-mini td">
                                                              {AuthService.canSPView(
                                                                PermissionFeature.FinanceFileSuccessRefund,
                                                                itemFi.regionId,
                                                                itemFi.facilityId,
                                                                item.memberID
                                                              ) && (
                                                                <>
                                                                  {
                                                                    itemFi.duesCollectionType
                                                                  }
                                                                </>
                                                              )}
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        );
                                      })()}
                                  </div>
                                </>
                              );
                            })}
                          </InfiniteScroll>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-lg-2" />
                <div className="col-lg-6 text-center">
                  <h4 className=" text-center text-uppercase page-title-search">
                    You're unauthorized to access the finance
                  </h4>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
          )}
        </div>
        {AuthService.canUpdate(PermissionFeature.FinanceFile) && (
          <ImportDetailModal
            ImportData={this.state.importData}
            refreshOnIgnore={this.refreshOnIgnore}
            refreshOnUpdate={this.refreshOnUpdate}
            openImportDetailModal={this.state.openImportDetailModal}
            closeImportDetailModal={this.closeImportDetailModal}
            fileId={this.state.fileId}
            ignoreStatus={this.state.detailIgnoreStatus}
          />
        )}
        {AuthService.canDelete(PermissionFeature.FinanceFile) && (
          <ConfirmDeleteModal
            id={"DeleteECI"}
            content={"Employee Finances"}
            confirm={() => {
              this.handleRemoveEmpoloyeeFinances();
            }}
            closeModal={() => {
              this.setState({ deleteModal: false });
            }}
            openModal={this.state.deleteModal}
          />
        )}
      </AUX>
    );
  }
}
export default FinanceImportSummary;
