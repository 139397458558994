import AUX from "../../../hoc/Aux_";
import React, { Component } from "react";
import $ from "jquery";
import _ from "lodash";
import axios from "../../Shared/auth-header";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableCustomTotal from "../../Shared/TableCustomTotal";
import AuthService from "../../Auth/AuthService";

class MembersModalAll extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      membersMethods: this.props.membersMethods,
      memberName: this.props.memberName,
      isLookup: this.props.isLookup || false,
      page: 1,
      facility: this.props.facility ? this.props.facility : "",
      data: this.props.data,
      sizePerPage: 25,
      totalCount: 0,
      keyword: "",
      SortOrder: "seniorityasc",
      isAdvanceSearch: false,
      delId: 0,
      filters: [],
      showActive: true,
      rows: [],
      totalCount: "",
      isOpen: this.props.isOpen,
    };

    this.columns = [
      {
        text: "First Name",
        dataField: "firstName",
        sort: true,

        formatter: (cell, row) => {
          //if(row.permissions.find(el => el.name == "firstName").permissions.canView)
          return cell;
          //else return "";
        },
      },
      {
        text: "Last Name",
        dataField: "lastName",
        sort: true,
        formatter: (cell, row) => {
          // if(row.permissions.find(el => el.name == "lastName").permissions.canView)
          return cell;
          //else return "";
        },
      },
      {
        text: "Region",
        dataField: "region",
        sort: true,
        formatter: (cell, row) => {
          //if(row.permissions.find(el => el.name == "region").permissions.canView)
          return cell;
          //else return "";
        },
      },
      {
        text: "Facility",
        dataField: "facility",
        sort: true,
        formatter: (cell, row) => {
          //if(row.permissions.find(el => el.name == "facility").permissions.canView)
          return cell;
          //else return "";
        },
      },
      {
        text: "Member Type",
        dataField: "memberType",
        sort: true,
        formatter: (cell, row) => {
          // if(row.permissions.find(el => el.name == "memberType").permissions.canView)
          return cell;
          // else return "";
        },
      },
      {
        text: "Primary Email",
        dataField: "emailAddress",
        sort: true,
        formatter: (cell, row) => {
          //if(row.permissions.find(el => el.name == "emailAddress").permissions.canView)
          {
            return (
              <a
                target="_blank"
                href={"mailto:" + cell}
                onClick={this.doPreventDefault}
              >
                {cell}
              </a>
            );
          }
          //else return "";
        },
      },
      {
        text: "Primary Phone",
        dataField: "phoneNumber",
        sort: true,
        formatter: (cell, row) => {
          //if(row.permissions.find(el => el.name == "phoneNumber").permissions.canView) {
          return (
            <a
              target="_blank"
              href={"tel:" + cell}
              onClick={this.doPreventDefault}
            >
              {cell}
            </a>
          );
          // } else return "";
        },
      },
    ];

    this.handleTableChange = this.handleTableChange.bind(this);

    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.membersMethods().setMember(row);
      },
    };
  }

  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    console.log("In handleTableChange");
    let url = `/api/member/SearchMembers`;
    this.props.setFilterResultPageNo(page);
    this.props.setFilterResultPageSize(sizePerPage);
    this.setState({
      page: page,
    });
    if (type === "sort") {
      this.state.SortOrder = sortField + sortOrder;
      this.setState({ SortOrder: this.state.SortOrder });
    }
    let formData = {};
    if (this.props.facility) {
      formData = {
        Keyword: "",
        PageNo: page,
        Facility: this.state.facility,
        PageSize: sizePerPage,
        SortOrder: this.state.SortOrder,
        ShowOnlyActive: this.state.showActive,
      };
    } else if (
      this.props.filterState.isAdvancedFilterSelected === false &&
      !this.props.facility &&
      this.props.filterState.basicSearchKeyWord &&
      this.props.filterState.basicSearchKeyWord !== ""
    ) {
      formData = {
        Keyword: this.props.filterState.basicSearchKeyWord,
        PageNo: page,
        Facility: "",
        PageSize: sizePerPage,
        SortOrder: this.state.SortOrder,
        ShowOnlyActive: this.state.showActive,
      };
    }
    $(".loading").show();
    if (!this.props.filterState.isAdvancedFilterSelected) {
      axios.post(url, formData).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.setState({
            data: data.data,
            totalCount: data.totalCount,
            page,
            sizePerPage,
          });
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      });
    } else {
      if (!_.isEmpty(this.props.filterState.filterData)) {
        var offset = (page - 1) * sizePerPage;

        url =
          "/api/Member/AdvancedFilter?pageOffset=" +
          offset +
          "&pageFetch=" +
          sizePerPage +
          "&sortName=" +
          this.state.SortOrder;

        axios.post(url, this.props.filterState.filterData).then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            this.setState({
              data: data.data,
              totalCount: data.totalCount,
              page,
              sizePerPage,
            });

            this.props.setFilterResult(data);
            this.props.setAdvancedSearch(true);
            this.props.setIsBasicSearchSelected(false);
          } else {
            console.log(data);
            toast.error(data.message);
          }
          $(".loading").hide();
        });
      }
    }
  };

  render() {
    const { data, sizePerPage, page, totalCount } = this.state;
    const showTotal = true;
    let ViewAll = null; //<button className="btn btn-primary" onClick={this.ViewAllLink}>View All Links</button>;
    return (
      <AUX>
        <div
          id="myModal"
          className="modal fade members-modal-all "
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Add/Edit Postion"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">Select Member</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  ×
                </button>
              </div>
              <div className="modal-body">
                <div className="col-12 mb-4">
                  {/* <MembersLookup isLookup={true} membersMethods={this.state.membersMethods} />*/}
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      {!this.props.isFacility && (
                        <h4 className="page-title-search">Members</h4>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <BootstrapTable
                      hover
                      keyField="id"
                      data={data}
                      columns={this.columns}
                      pagination={paginationFactory({
                        paginationTotalRenderer: TableCustomTotal,
                      })}
                      rowEvents={this.rowEvents}
                      id="membersListing"
                    />
                  </div>
                </div>
                <div className="col-12 text-center mb-50 mt-50 pt-4">
                  <button
                    className="btn btn-danger w-md waves-effect cancel-Modal waves-light mr-2"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={this.props.triggerState}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default MembersModalAll;
