import React, { Component } from "react";
import * as actionTypes from "../../../store/action";
import EditUserAction from "../../../store/EditUser/action";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import $ from "jquery";
import { Search } from "react-bootstrap-table2-toolkit";
import moment from "moment";
import FinanceTable from "../../Finance/General/FinanceTable";
import _ from "lodash";
import AUX from "../../../hoc/Aux_";
import axios from "../../Shared/auth-header";
import Users from "../Users/Users";
import "../../../assets/css/EditUserStyle.css";
import Directory from "../../Directory/Directory";
import ConfirmDeleteModal from "../../Shared/Modals";
import Services from "./EditUser/EditUserService";
import actionFilter from "../../../store/advancedfilter/action";
import { connect } from "react-redux";
import ReviewPermissions from "../../Permissions/General/ReviewPermissions";
import AuthService from "../../Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import UserAccount from "./EditUser/UserAccount";
import Finance from "./EditUser/Finance";
import PayAndSeniority from "./EditUser/PayAndSeniority";
import Position from "./EditUser/Position";
import EquipmentsCardList from "./EditUser/EquipmentsCardList";
import Jobs from "./EditUser/Jobs";
import CivicAPI from "./EditUser/CivicAPI";
import JobsHistory from "./EditUser/JobsHistory";
import UserProfile from "./EditUser/UserProfile";
import Membership from "./EditUser/Membership";
import actionActivity from "../../../store/activitylogging/action";
import actionUserNoti from "../../../store/userNotification/action";
import UserActivityLogging from "../Users/EditUser/UserActivityLogging";
import UnSubscribeModal from "../../AdvanceSearch/SavedSearches/UnSubscribeModal";
import FE_Equipment_Card_Types from "../../../modules/F&E/FE_Equipment_Card_Types";
import MemberProfileRegistrations from "../../Registrations/User/MemberProfileRegistrations";
import MailGunEmailTypes from "../../Email/Enums/MailGunEmailTypes";

const { SearchBar } = Search;

const shirtSizes = [
  {
    text: "XS",
    value: "XS",
  },
  {
    text: "SM",
    value: "SM",
  },
  {
    text: "MD",
    value: "MD",
  },
  {
    text: "LG",
    value: "LG",
  },
  {
    text: "XL",
    value: "XL",
  },
  {
    text: "2X",
    value: "2X",
  },
  {
    text: "3X",
    value: "3X",
  },

  {
    text: "4X",
    value: "4X",
  },
];

const ButtonToNavigate = ({ title, history, color, classes }) => (
  <Button
    type="button"
    onClick={() =>
      history.push({ pathname: "/directory", tab: Users, name: "Members" })
    }
    color={color}
    className={classes}
  >
    {title}
  </Button>
);

const items = [
  {
    id: 1,
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    id: 2,
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    id: 3,
    altText: "Slide 3",
    caption: "Slide 3",
  },
];
class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAnyEquipment: false,
      validateUSPS: true,
      state_error: true,
      States: [],
      openVerificationModal: false,
      reloadCivic: false,
      logRecordId: 0,
      isPasswordShown: false,
      type: this.props.location.type,
      password_err: "",
      uid: this.props.location.uid,
      valid: true,
      jobs: [],
      backRef: this.props.location.backRef || {},
      plannedretirementdate_err: "",
      retirementdate_err: "",
      username_err: "",
      dateofbirth_err: "",
      bargainingunitdate_err: "",
      natcabargainingunitdate_err: "",
      servicecomputationdate_err: "",
      joineduniondate_err: "",
      entryondutydate_err: "",
      facilitiesLookup: false,
      member: {},
      positions: [],
      emailListDisplayer: [],
      emailHistoryAvail: true,
      showEmailHistory: true,
      emailList: [],
      canSeeMD: false,
      shirtsizes: shirtSizes,
      addresses_err: [],
      permissions: [],
      profile: {
        isenabled: false,
        islocked: false,
        isverified: false,
        ispassword: false,
        isusername: false,
        username: "",
        password: "",
        addresses: [],
        latitude: null,
        longitude: null,
        addressIdLatLong: null,
        phones: [],
        emailAddresses: [],
        phoneTypes: [],
        addressTypes: [],
      },
      allowance: {
        allowpostalmail: false,
        allowphonecall: false,
        allowfacebook: false,
        allowtextmessages: false,
        allowemails: false,
        allowtext_newsalerts: false,
        allowtext_legislative: false,
      },
      profileform: {
        Id: "",
        gender: "",
        dateofbirth: "",
        pacdonation: "",
        drfdonation: "",
        ncf: "",
        nickname: "",
        shirtsize: "",
        previouslastname: "",
        plannedretirementdate: "",
        mandatoryretirementdate: "",
        retirementdate: "",
        spousename: "",
        vetstatus: "",
      },

      detail: {
        status: "",
        region: "",
        facility: "",
        facilityid: 1,
        membertype: "",
        membertypeid: 1,
        bargainingunitdate: "",
        natcabargainingunitdate: "",
        servicecomputationdate: "",
        joineduniondate: "",
        paygrade: "",
        careerlevel: "",
        employer: "",
        careerlevelid: 1,
        classification: "",
        yearlysalary: "",
        hourlysalary: "",
        entryondutydate: "",
        unit: "",
        flsacategory: "",
        occupationseries: "",
        occupationseriestitle: "",
        positiontitleopm: "",
        bargainingunitregion: "",
        organizationcode: "",
        organizationtitle: "",
        facilityaddressroutingsymbol: "",
        opmsstatuscode: "",
        expecteddues: "",
        dutystationcode: "",
        dutystattiondescription: "",
        vetstatus: false,
        lifeinsurance: false,
        longtermdisability: false,
        retiredmilitarycontroller: false,
        retiredmilitarycontroller: false,
        membertypedetail: {},
        profile: {},
        isverified: false,
      },

      animating: false,
      popoverOpen: false,

      primaryNumber: "",
      isMyProfile: false,
    };
    this.userProfile = React.createRef();
    this.changeHandlerP = this.changeHandlerP.bind(this);
    this.handleFormSubmitProfile = this.handleFormSubmitProfile.bind(this);
    this.handleFacebookOnChange = this.handleFacebookOnChange.bind(this);
    this.resetFacebookSubscription = this.resetFacebookSubscription.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeP = this.handleChangeP.bind(this);
    this.handleChangePwd = this.handleChangePwd.bind(this);
    this.handleChangePAddress = this.handleChangePAddress.bind(this);
    this.handleChangePEmail = this.handleChangePEmail.bind(this);
    this.handleRemoveRow = this.handleRemoveRow.bind(this);
    this.changeHandlerAddress = this.changeHandlerAddress.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.civicReloadDone = this.civicReloadDone.bind(this);
    this.handleSelectChangePhoneType = this.handleSelectChangePhoneType.bind(
      this
    );
    this.profileValidation = this.profileValidation.bind(this);
    this.detailValidation = this.detailValidation.bind(this);
    this.calculate_age = this.calculate_age.bind(this);
    this.handleFormSubmitAddresses = this.handleFormSubmitAddresses.bind(this);
    this.handleFormSubmitEmail = this.handleFormSubmitEmail.bind(this);
    this.handleFormSubmitPhone = this.handleFormSubmitPhone.bind(this);
    this.handleResetProfile = this.handleResetProfile.bind(this);
    this.handleAddAddressErrorRow = this.handleAddAddressErrorRow.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.emailValidation = this.emailValidation.bind(this);
    this.textValidation = this.textValidation.bind(this);
    this.formate_amount = this.formate_amount.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.changeHandlerUsername = this.changeHandlerUsername.bind(this);
    this.changeHandlerPf_text = this.changeHandlerPf_text.bind(this);
    this.changeHandlerPf_amount = this.changeHandlerPf_amount.bind(this);
    this.ReSubscribedEmailList = this.ReSubscribedEmailList.bind(this);
    this.changeHandlerPf_date = this.changeHandlerPf_date.bind(this);
    this.calculateMandatoryDate = this.calculateMandatoryDate.bind(this);
    this.amountValidation = this.amountValidation.bind(this);
    this.dateValidation = this.dateValidation.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleChangeAllowance = this.handleChangeAllowance.bind(this);
    this.shirtsizeValidation = this.shirtsizeValidation.bind(this);
    this.changeHandlerShirtSizes = this.changeHandlerShirtSizes.bind(this);
    this.genderValidation = this.genderValidation.bind(this);
    this.changeHandlerGender = this.changeHandlerGender.bind(this);
    this.handleFormSubmitUsername = this.handleFormSubmitUsername.bind(this);
    this.handleFormSubmitPassword = this.handleFormSubmitPassword.bind(this);
    this.handleChangIsLocked = this.handleChangIsLocked.bind(this);
    this.handleChangeWallCalendarRequest = this.handleChangeWallCalendarRequest.bind(this);
    this.handleResetUsername = this.handleResetUsername.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleUsernameErrChange = this.handleUsernameErrChange.bind(this);
    this.passwordValidation = this.passwordValidation.bind(this);
    this.preventDefaultCustom = this.preventDefaultCustom.bind(this);
    this.setStateError = this.setStateError.bind(this);
    this.setValidateUSPS = this.setValidateUSPS.bind(this);
    this.LoadStates = this.LoadStates.bind(this);
    this.getJobs = this.getJobs.bind(this);
    this.changeHandlerVetStatus = this.changeHandlerVetStatus.bind(this);
    if (
      AuthService.getProfile().memberData != null &&
      AuthService.getProfile().memberData != undefined &&
      AuthService.getProfile().memberData != ""
    ) {
      this.loggedInUserId = AuthService.getProfile().memberData.id;
    }

    this.confirm = () => {
      this.confirmed = () => {
        if (this.state.uid == 0) {
          $(".cancelModal").trigger("click");
          return false;
        }
        if (this.loggedInUserId == this.props.location.pathname.split("/")[3]) {
          toast.error("You cannot delete your own profile.");
          $(".cancelModal").trigger("click");
          return false;
        }
        axios
          .delete("/api/Member/" + this.state.uid)
          .then(({ data }) => {
            if (data.status && data.status == "Success") {
              toast.success("Member deleted successfuly.");
              //this.setState({ delId: 0 });
              // $(".cancelModal").trigger("click");
              this.props.history.push("/directory/members");
              //this.getMembers();
            } else {
              toast.error(data.message);
            }
            $(".cancelModal").trigger("click");
          })
          .catch(function (error) {
            $(".cancelModal").trigger("click");
          });
      };
      return this;
    };
    //    this.jobslides = this.jobslides.bind(this);
    this.confirm_unsubcribe = () => {
      this.confirmed = () => {
        if (this.state.ListName == "") {
          return false;
        }
        $(".loading").show();
        const url = "/api/Mailgun/UnsubscribeMemberFromList";
        var primaryEmail = this.state.member.emailAddresses.filter((item) => {
          if (item.isprimary) {
            return item;
          }
        });
        const body = {
          address: primaryEmail[0].email,
          ListName: this.state.ListName,
          subscribed: false,
        };

        axios.post(url, body).then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            $(".loading").hide();
            toast.success(data.message);
            this.MemberSubscribedEmailList();
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        });

        this.toggleConfirm_unsubscribe();
      };
      return this;
    };
  }
  async componentWillReceiveProps(nextProps) {
    if (nextProps.location.uid !== this.props.location.uid) {
      await this.setState({ uid: nextProps.location.uid });
      this.loadData(nextProps.location.uid);
      this.getJobs();
    }
  }

  async setStateError(value) {
    await this.setState({ state_error: value });
  }
  async setValidateUSPS(value) {
    await this.setState({ validateUSPS: value });
  }
  toggleConfirm_unsubscribe = () => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };
  LoadStates = async () => {
    let States = await Services.States();
    if (!_.isEmpty(States) != null) {
      this.setState({ States: States });
    }
  };
  //Will Call in Jobs and JobsHistory
  async getJobs() {
    if (
      this.state.uid != null &&
      this.state.uid != "" &&
      this.state.uid != undefined
    ) {
      var data = await Services.Jobs(this.state.uid);
      if (data != null) {
        await this.setState({ jobs: data.data });
        if (
          !_.isEmpty(this.state.jobs) &&
          (this.state.detail.natcabargainingunitdate !=
            (moment(this.props.editUserState.NatcaBargainingUnitDate).isValid()
              ? moment(this.props.editUserState.NatcaBargainingUnitDate).format(
                "MM/DD/YYYY"
              )
              : "-") ||
            this.state.detail.bargainingunitdate !=
            (moment(this.props.editUserState.BargainingUnitDate).isValid()
              ? moment(this.props.editUserState.BargainingUnitDate).format(
                "MM/DD/YYYY"
              )
              : "-"))
        ) {
          this.state.detail.natcabargainingunitdate = moment(
            this.props.editUserState.NatcaBargainingUnitDate
          ).isValid()
            ? moment(this.props.editUserState.NatcaBargainingUnitDate).format(
              "MM/DD/YYYY"
            )
            : "none";
          this.state.detail.bargainingunitdate = moment(
            this.props.editUserState.BargainingUnitDate
          ).isValid()
            ? moment(this.props.editUserState.BargainingUnitDate).format(
              "MM/DD/YYYY"
            )
            : "none";
          await this.setState({ detail: this.state.detail });
        }
      }
    }
  }
  handleShowAllEmails = () => {
    this.setState({
      emailListDisplayer: _.cloneDeep(this.state.emailList),
      showEmailHistory: false,
    });
  };
  handleLessAllEmails = () => {
    this.state.emailListDisplayer = this.state.emailList.filter(
      (x) => x.subscribed == true
    );
    this.setState({
      emailListDisplayer: this.state.emailListDisplayer,
      showEmailHistory: true,
    });
  };

  MemberSubscribedEmailList = () => {
    $(".loading").show();
    this.setState({
      subscribedList: [],
    });

    //if (this.state.member.emailAddresses.length > 0) {
    //  //var primaryEmail = "";
    //  var primaryEmail = this.state.member.emailAddresses.filter((item) => {
    //    if (item.isprimary) {
    //      return item;
    //    }
    //  });

    //  if (primaryEmail.length > 0) {
    let id = this.state.uid;
    if (id != null && id != "" && id != undefined) {
      axios
        .get(
          //`/api/Mailgun/GetML_SingleMembers?memberaddress=${primaryEmail[0].email}`
          `/api/Email/EmailListByMemberId?memberId=${id}`
        )
        .then(({ data: res }) => {
          if (res.statusCode && res.statusCode == 200) {
            if (!_.isEmpty(res.result)) {
              this.state.emailList = res.result;
              this.state.emailListDisplayer = _.cloneDeep(
                res.result.filter((x) => x.subscribed == true)
              );

              if (
                JSON.stringify(this.state.emailList) ==
                JSON.stringify(this.state.emailListDisplayer)
              ) {
                this.state.emailHistoryAvail = false;
              } else {
                this.state.emailHistoryAvail = true;
              }
              this.setState({
                emailHistoryAvail: this.state.emailHistoryAvail,
                emailList: this.state.emailList,
                emailListDisplayer: this.state.emailListDisplayer,
              });
              if (
                this.state.emailHistoryAvail &&
                !this.state.showEmailHistory
              ) {
                this.handleShowAllEmails();
              }
            }
            $(".loading").hide();
          } else {
            $(".loading").hide();
            if (res.message != null) {
              toast.error(res.message);
            }
          }
        });
    }
    //  }
    //}
  };

  ReSubscribedEmailList = async (mailAddress) => {
    if (this.state.ListName == "") {
      return false;
    }
    $(".loading").show();
    const url = "/api/Mailgun/UnsubscribeMemberFromList";
    var primaryEmail = this.state.member.emailAddresses.filter((item) => {
      if (item.isprimary) {
        return item;
      }
    });
    if (primaryEmail != null && !_.isEmpty(primaryEmail)) {
      let data = await Services.ReSubscribedEmail(
        mailAddress,
        primaryEmail[0].email
      );
      if (data) {
        this.MemberSubscribedEmailList();
      }
    } else {
      toast.error("No primary email is set for this member.");
    }
    $(".loading").hide();
  };

  setListName = (ListName) => {
    this.setState({
      ListName: ListName,
    });
  };
  preventDefaultCustom = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  showAllowedContacts = (chk) => {
    if (chk == "edit") {
      return (
        AuthService.canOwnUpdate(
          PermissionFeature.CommunicationAllowPostalMail,
          this.state.uid
        ) ||
        AuthService.canMemberUpdate(
          PermissionFeature.CommunicationAllowPostalMail
        ) ||
        AuthService.canOwnUpdate(
          PermissionFeature.CommunicationAllowPhoneCall,
          this.state.uid
        ) ||
        AuthService.canMemberUpdate(
          PermissionFeature.CommunicationAllowPhoneCall
        ) ||
        AuthService.canOwnUpdate(
          PermissionFeature.CommunicationAllowTextMessages,
          this.state.uid
        ) ||
        AuthService.canMemberUpdate(
          PermissionFeature.CommunicationAllowTextMessages
        ) ||
        AuthService.canOwnUpdate(
          PermissionFeature.CommunicationAllowEmails,
          this.state.uid
        ) ||
        AuthService.canMemberUpdate(PermissionFeature.CommunicationAllowEmails)
      );
    } else {
      return (
        AuthService.canOwnView(
          PermissionFeature.CommunicationAllowPostalMail,
          this.state.uid
        ) ||
        AuthService.canMemberView(
          PermissionFeature.CommunicationAllowPostalMail
        ) ||
        AuthService.canOwnView(
          PermissionFeature.CommunicationAllowPhoneCall,
          this.state.uid
        ) ||
        AuthService.canMemberView(
          PermissionFeature.CommunicationAllowPhoneCall
        ) ||
        AuthService.canOwnView(
          PermissionFeature.CommunicationAllowTextMessages,
          this.state.uid
        ) ||
        AuthService.canMemberView(
          PermissionFeature.CommunicationAllowTextMessages
        ) ||
        AuthService.canOwnView(
          PermissionFeature.CommunicationAllowEmails,
          this.state.uid
        ) ||
        AuthService.canMemberView(PermissionFeature.CommunicationAllowEmails)
      );
    }
  };

  handleChangePwd(e) {
    this.state.profile.password = e.target.value;
    this.setState({ profile: this.state.profile });
    this.setState({ password_err: this.passwordValidation(e.target.value) });
  }
  handleChangeWallCalendarRequest = async (e) => {
    e.preventDefault();
    this.state.member.wallCalendarRequest = e.target.checked;
    this.setState({ member: this.state.member });
    $(".loading").show();

    try {
      let id = this.state.uid;
      let data = await Services.UpdateWallCalendarRequest(id, this.state.member.wallCalendarRequest);
      if (data != null) {
        this.state.member.wallCalendarRequest = this.state.member.wallCalendarRequest;
        await this.setState({ member: this.state.member });
      }
      $(".loading").hide();
    } catch (e) {
      toast.error("An error occured. Please try again later");
      $(".loading").hide();
    }
  };
  handleChangIsLocked = async (e) => {
    e.preventDefault();
    this.state.profile.islocked = e.target.checked;
    this.setState({ profile: this.state.profile });
    $(".loading").show();

    try {
      let islocked = this.state.profile.islocked;
      let id = this.state.uid;
      let data = await Services.UpdateIsLocked(id, islocked);
      if (data != null) {
        this.state.profile.islocked = islocked;
        await this.setState({ profile: this.state.profile });
        this.loadData(this.state.uid);
      }
      $(".loading").hide();
    } catch (e) {
      toast.error("An error occured. Please try again later");
      $(".loading").hide();
    }
  };
  handleChangIsEnabled = async (e) => {
    e.preventDefault();
    $(".loading").show();

    try {
      let isenabled = e.target.checked;
      let id = this.state.uid;
      let data = await Services.UpdateIsEnabled(id, isenabled);
      if (data != null) {
        this.state.profile.isenabled = isenabled;
        await this.setState({ profile: this.state.profile });
        this.loadData(this.state.uid);
      }
      $(".loading").hide();
    } catch (e) {
      toast.error("An error occured. Please try again later");
      $(".loading").hide();
    }
  };
  handleChangIsVerified = async (e) => {
    e.preventDefault();

    $(".loading").show();

    try {
      let isverified = e.target.checked;
      let id = this.state.uid;
      let data = await Services.UpdateIsVerified(id, isverified);
      if (data != null) {
        this.state.profile.isverified = isverified;
        await this.setState({ profile: this.state.profile });
        this.loadData(this.state.uid);
      }
      $(".loading").hide();
    } catch (e) {
      toast.error("An error occured. Please try again later");
      $(".loading").hide();
    }
  };
  passwordValidation = (password) => {
    let passRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    if (password === "") {
      return "Required field";
    } else if (!passRegex.test(password)) {
      return "Password must be 8 characters long and must contain 1 uppercase, 1 lower case and 1 number.";
    }
    //else if (e.target.value != this.state.RePassword) {
    //    this.setState({ Password_err: "Password should be the same." });
    //    this.setState({ valid: false });
    else {
      return "";
    }
  };
  handleChangeEmail(e, idx) {
    //const idx = e.target.parentElement.parentElement.dataset.id;
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.value;
    this.setState({ profile: this.state.profile });
    this.state.profile[e.target.dataset.obj][
      idx
    ].email_err = this.validateEmail(e.target.value).error;
  }
  // EmailhandleChange(email){
  //   this.state.profile.
  // }
  validateEmail = (email) => {
    let EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const Email_States = [
      {
        state: "Valid",
        error: "",
      },
      {
        state: "Null",
        error: "Please provide your email.",
      },
      {
        state: "Not_Match_To_Regex",
        error: "Please provide valid email.",
      },
    ];
    if (email == "") {
      return Email_States.find(({ state }) => state == "Null");
    } else if (EmailReg.test(email)) {
      return Email_States.find(({ state }) => state == "Valid");
    } else {
      return Email_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };

  handleChangePhone(e) {
    const idx = e.target.parentElement.parentElement.dataset.id;
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.value;
    this.setState({ profile: this.state.profile });
    this.state.profile[e.target.dataset.obj][
      idx
    ].number_err = this.validatePhone(e.target.value).error;
  }
  validatePhone = (phone) => {
    let PhoneReg = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    const Phone_States = [
      {
        state: "Valid",
        error: "",
      },
      {
        state: "Null",
        error: "Please provide your Phone.",
      },
      {
        state: "Not_Match_To_Regex",
        error: "Please provide valid Phone.",
      },
    ];
    if (phone == "") {
      return Phone_States.find(({ state }) => state == "Null");
    } else if (PhoneReg.test(phone)) {
      return Phone_States.find(({ state }) => state == "Valid");
    } else {
      return Phone_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };

  changeHandlerAddress = (e) => {
    const idx = e.target.parentElement.parentElement.dataset.id;
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.value;
    let name = e.target.name;
    if (name != "address2") {
      if (e.target.value == null || e.target.value == "") {
        this.state.profile[e.target.dataset.obj][idx][e.target.name + "_err"] =
          "Please Provide " + e.target.dataset.name;
      } else {
        this.state.profile[e.target.dataset.obj][idx][e.target.name + "_err"] =
          "";
      }
    }

    this.setState({ validateUSPS: true, profile: this.state.profile });
  };
  validateUsername = (username) => {
    let UsernameReg = /^[A-Za-z]+[A-Za-z0-9]*$/;

    if (username == "" || username == null) {
      return "Please provide Username";
    } else if (UsernameReg.test(username)) {
      return null;
    } else {
      return "Username should contain only letters or letters with numbers and no special characters";
    }
  };
  changeHandlerUsername = (e) => {
    this.state.profile.username = e.target.value;
    this.setState({ profile: this.state.profile });
    let error = this.validateUsername(e.target.value);
    if (error == null) {
      const id = this.state.uid;
      const username = this.state.profile.username;
      axios
        .post("/api/Member/CheckUsernameExists", null, {
          params: {
            id,
            username,
          },
        })
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            this.setState({ username_err: "" });
          } else {
            this.setState({ username_err: data.message });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    this.setState({ username_err: error });

    this.setState({ profile: this.state.profile });
  };
  handleFormSubmitPassword = (e) => {
    e.preventDefault();
    const password = this.state.profile.password;
    let valid = this.passwordValidation(password);
    $(".loading").show();
    if (valid == "") {
      const id = this.state.uid;

      axios
        .post("/api/Member/UpdateMemberPassword", null, {
          params: {
            id,
            password,
          },
        })
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Password has been updated successfully.");
            $(".loading").hide();
            this.loadData(this.state.uid);
            $("#password-btn-inline").removeClass("d-none");
            $("#password-edit").addClass("d-none");
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      $(".loading").hide();
      toast.error(valid);
    }
  };
  handleUsernameErrChange(val) {
    this.state.username_err = val;
    this.setState({ username_err: val });
  }
  handleResetUsername() {
    this.state.profile.username = this.state.member.username;
    this.setState({
      profile: this.state.profile,
    });
  }
  handleResetPassword() {
    this.state.profile.password =
      this.state.member.password == null ? "" : this.state.member.password;
    this.state.password_err = "";
    this.setState({
      profile: this.state.profile,
      password_err: this.state.password_err,
    });
  }
  handleFormSubmitUsername = (e) => {
    e.preventDefault();
    const username = this.state.profile.username;
    $(".loading").show();
    let error = this.validateUsername(username);
    if (error == null) {
      this.setState({ username_err: error });
      const id = this.state.uid;

      axios
        .post("/api/Member/UpdateMemberUsername", null, {
          params: {
            id,
            username,
          },
        })
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Username has been updated successfully.");
            $(".loading").hide();
            this.loadData(this.state.uid);
            $("#username-inline").removeClass("d-none");
            $("#username-edit").addClass("d-none");
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      $(".loading").hide();
      toast.error(error);
    }
  };
  changeHandlerShirtSizes = (e) => {
    this.state.profileform[e.target.name] = e.target.value;
    this.setState({ profileform: this.state.profileform });

    this.state.profileform[e.target.name + "_err"] = this.shirtsizeValidation(
      e.target.value
    );
  };
  shirtsizeValidation = (shirtsize) => {
    let shirtReg = new RegExp("^(XS|SM|MD|LG|XL|2X|3X|4X)$");
    if (shirtReg.test(shirtsize)) {
      return "";
    }
    return "Please provide valid shirt size!";
  };
  changeHandlerVetStatus = (e) => {
    this.state.profileform.vetstatus = e.target.value;
    this.setState({ profileform: this.state.profileform });

    this.state.profileform.vetstatus_err = this.VetStatusValidation(
      e.target.value
    );
  };
  VetStatusValidation = (VetStatus) => {
    let VetStatusReg = new RegExp("^(B|V|P|X|N)$");
    if (VetStatusReg.test(VetStatus)) {
      return "";
    }
    return "Please provide valid vet status!";
  };

  changeHandlerGender = (e) => {
    this.state.profileform[e.target.name] = e.target.value;
    this.setState({ profileform: this.state.profileform });

    this.state.profileform[e.target.name + "_err"] = this.genderValidation(
      e.target.value
    );
  };
  genderValidation = (gender) => {
    let genderReg = new RegExp("^(Male|Female|X|N)$");
    if (genderReg.test(gender)) {
      return "";
    }
    return "Please provide valid gender!";
  };
  changeHandlerPf_text = (e) => {
    this.state.profileform[e.target.name] = e.target.value;
    this.state.profileform[e.target.name + "_err"] = this.textValidation(
      e.target.value
    ).error;

    this.setState({ profileform: this.state.profileform });
  };
  textValidation = (text) => {
    let textReg = /^[a-zA-z ]*$/;
    const text_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Please provide only text.",
      },
    ];
    if (textReg.test(text)) {
      return text_States.find(({ state }) => state == "Valid");
    } else {
      return text_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };
  changeHandlerPf_amount = (e) => {
    this.state.profileform[e.target.name] = e.target.value;
    this.state.profileform[e.target.name + "_err"] = this.amountValidation(
      e.target.value
    ).error;

    this.setState({ profileform: this.state.profileform });
  };
  changeHandlerPf_date = (e) => {
    var selectedDate = e.target.value;
    this.state.profileform[e.target.name] = selectedDate;
    var validity = this.dateValidation(selectedDate);
    this.state.profileform[e.target.name + "_err"] = validity.error;

    // if(validity.error === "" && e.target.name === "dateofbirth" && this.state.profileform["plannedretirementdate"] === "")
    // {
    //   this.state.profileform["plannedretirementdate"] = this.calculateMandatoryDate(selectedDate);
    //   this.state.profileform["plannedretirementdate_err"] = "";
    // }

    this.setState({ profileform: this.state.profileform });
  };
  calculateMandatoryDate = (date) => {
    var bDate = new Date(date);
    var bYear = bDate.getFullYear();
    var bMonth = bDate.getMonth();
    var rDate = new Date(bYear + 56, bMonth + 1, 0);
    var rMonth = rDate.getMonth() + 1;
    if (rMonth < 10) {
      rMonth = "0" + rMonth;
    }
    return rMonth + "/" + rDate.getDate() + "/" + rDate.getFullYear();
  };

  dateValidation = (date) => {
    let dateReg = new RegExp(
      "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$"
    );
    const date_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Invalid date",
      },
    ];
    if (dateReg.test(date)) {
      return date_States.find(({ state }) => state == "Valid");
    } else {
      return date_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };

  amountValidation = (amount) => {
    let amountReg = /^(([0-9]*)|([0-9]+(\.[0-9]+)?))$/;
    const amount_States = [
      {
        state: "Valid",
        error: "",
      },

      {
        state: "Not_Match_To_Regex",
        error: "Invalid amount",
      },
    ];
    if (amountReg.test(amount)) {
      return amount_States.find(({ state }) => state == "Valid");
    } else {
      return amount_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };

  validatePhone = (phone) => {
    let PhoneReg = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    const Phone_States = [
      {
        state: "Valid",
        error: "",
      },
      {
        state: "Null",
        error: "Please provide your Phone.",
      },
      {
        state: "Not_Match_To_Regex",
        error: "Please provide valid Phone.",
      },
    ];
    if (phone == "") {
      return Phone_States.find(({ state }) => state == "Null");
    } else if (PhoneReg.test(phone)) {
      return Phone_States.find(({ state }) => state == "Valid");
    } else {
      return Phone_States.find(({ state }) => state == "Not_Match_To_Regex");
    }
  };

  changeHandlerP = (e) => {
    this.state.profile[e.target.name] = e.target.value;
    this.setState({ profile: this.state.profile });
  };

  profileValidation() {
    let Valid = "valid";
    let validprimary = false;

    let obj = this.state.profileform;
    for (var key in obj) {
      if (
        this.state.profileform[key] != null &&
        this.state.profileform[key] != ""
      ) {
        if (
          key == "nickname" ||
          key == "spousename" ||
          key == "previouslastname"
        ) {
          this.state.profileform[key + "_err"] = this.textValidation(
            this.state.profileform[key]
          ).error;
        }
        if (key == "gender") {
          this.state.profileform[key + "_err"] = this.genderValidation(
            this.state.profileform[key]
          );
        }
        if (key == "vetstatus") {
          this.state.profileform.vetstatus_err = this.VetStatusValidation(
            this.state.profileform.vetstatus
          );
        }
        if (key == "shirtsize") {
          this.state.profileform[key + "_err"] = this.shirtsizeValidation(
            this.state.profileform[key]
          );
        } else if (
          key == "ncf" ||
          key == "drfdonation" ||
          key == "pacdonation"
        ) {
          this.state.profileform[key + "_err"] = this.amountValidation(
            this.state.profileform[key]
          ).error;
        } else if (
          key == "dateofbirth" ||
          key == "plannedretirementdate" ||
          key == "retirementdate"
        ) {
          this.state.profileform[key + "_err"] = this.dateValidation(
            this.state.profileform[key]
          ).error;
        }
      }
    }

    this.setState({ profileform: this.state.profileform });
    for (var key in obj) {
      if (key.indexOf("_err") > -1) {
        if (obj[key] !== null && obj[key] != "" && obj[key] != undefined) {
          Valid =
            "There are errors on the form. Please fix them before continuing.";
          break;
        }
      }
    }
    return Valid;
  }

  addressValidation() {
    let Valid = "valid";

    let length = this.state.profile.addresses.length;
    if (length == 0) {
      Valid = "Please add some addresses for submit data.";
    }
    for (var i = 0; i < length; i++) {
      let obj = this.state.profile.addresses[i];
      for (var key in obj) {
        if (
          (obj[key] == null || obj[key] == "") &&
          key != "isprimary" &&
          key != "address2" &&
          key != "address3" &&
          key != "county" &&
          key.indexOf("_err") == -1
        ) {
          this.state.profile.addresses[i][key + "_err"] =
            "Please provide the above value.";
        }
      }
    }

    this.setState({ profile: this.state.profile });

    this.state.profile.addresses.map((obj) => {
      for (var key in obj) {
        if (key.indexOf("_err") > -1) {
          if (obj[key] !== null && obj[key] != "") {
            Valid =
              "There are errors on the form. Please fix them before continuing.";
            return false;
          }
        }
      }
    });
    return Valid;
  }
  emailValidation() {
    let Valid = "valid";
    let validprimary = false;
    let length = this.state.profile.emailAddresses.length;
    if (length == 0) {
      Valid = "Please add some Emails for submit data.";
      return Valid;
    }

    for (var i = 0; i < length; i++) {
      let obj = this.state.profile.emailAddresses[i];
      for (var key in obj) {
        if (key == "email") {
          this.state.profile.emailAddresses[i][
            key + "_err"
          ] = this.validateEmail(
            this.state.profile.emailAddresses[i][key]
          ).error;
        }
        // if (key == "isprimary") {
        //   if (obj[key] == true) {
        //     validprimary = true;
        //   }
        // }
      }
    }
    // if (length > 0 && !validprimary) {
    //   this.state.profile.emailAddresses[0]["isprimary_err"] =
    //     "Please assign at least one Email as primary.";
    // }
    this.setState({ profile: this.state.profile });
    this.state.profile.emailAddresses.map((obj) => {
      for (var key in obj) {
        if (key.indexOf("_err") > -1) {
          if (obj[key] !== null && obj[key] != "") {
            Valid =
              "There are errors on the form. Please fix them before continuing.";
            return false;
          }
        }
      }
    });
    return Valid;
  }

  phoneValidation() {
    let Valid = "valid";
    let validprimary = false;
    let length = this.state.profile.phones.length;
    if (length == 0) {
      Valid = "Please add some Phones for submit data.";
      return Valid;
    }

    for (var i = 0; i < length; i++) {
      let obj = this.state.profile.phones[i];
      for (var key in obj) {
        if (key == "number") {
          this.state.profile.phones[i][key + "_err"] = this.validatePhone(
            this.state.profile.phones[i][key]
          ).error;
        }
        // if (key == "isprimary") {
        //   if (obj[key] == true) {
        //     validprimary = true;
        //   }
        // }
      }
    }
    // if (length > 0 && !validprimary) {
    //   this.state.profile.phones[0]["isprimary_err"] =
    //     "Please assign at least one Phone as primary.";
    // }
    this.setState({ profile: this.state.profile });
    this.state.profile.phones.map((obj) => {
      for (var key in obj) {
        if (key.indexOf("_err") > -1) {
          if (obj[key] !== null && obj[key] != "") {
            Valid =
              "There are errors on the form. Please fix them before continuing.";
            return false;
          }
        }
      }
    });
    return Valid;
  }

  async handleFormSubmitAddresses(e) {
    e.preventDefault();
    let valid = "invalid";
    e.target.className += " was-validated";
    var v = false;
    try {
      v = e.target.checkValidity();
    } catch (ex) {
      v = document.getElementById(e.target.id).checkValidity();
    }
    if (!_.isEmpty(this.state.profile.addresses)) {
      var stateval = this.state.profile.addresses[0].state;
      e.target.className += " was-validated";
      if (stateval == null || stateval == "" || stateval == undefined) {
        await this.setState({ state_error: true });
      } else {
        await this.setState({ state_error: false });
      }
    } else {
      toast.error("An error occurred, please try again!");
      return false;
    }
    if (!v || this.state.state_error == true) {
      return false;
    } else {
      valid = "valid";
    }
    if (this.state.validateUSPS) {
      $(".loading").show();
      let Address = this.state.profile.addresses[0];
      let response = await Services.AddressUSPS(Address);
      if (response != null) {
        if (
          response.AddressValidateResponse &&
          response.AddressValidateResponse != null &&
          response.AddressValidateResponse.Address &&
          response.AddressValidateResponse.Address != null
        ) {
          if (
            response.AddressValidateResponse.Address.Error &&
            response.AddressValidateResponse.Address.Error != null
          ) {
            toast.error(
              response.AddressValidateResponse.Address.Error.Description
            );
            this.setState({ isValidAddress: false });
          } else {
            let USPSaddress = response.AddressValidateResponse.Address;
            Address.address1 = USPSaddress.Address2;
            Address.address2 = USPSaddress.Address1;
            Address.city = USPSaddress.City;
            Address.state = USPSaddress.State;
            Address.zipcode =
              USPSaddress.Zip5 +
              (USPSaddress.Zip4 != undefined &&
                USPSaddress.Zip4 != null &&
                USPSaddress.Zip4 != ""
                ? "-" + USPSaddress.Zip4
                : "");
            this.state.profile.addresses[0] = Address;
            await this.setState({
              validateUSPS: false,
              profile: this.state.profile,
            });
            toast.success("USPS validation done successfully!");
            this.setState({ isValidAddress: true });
          }
        } else {
          if (response.Error) {
            toast.error(response.Error.Description);
          } else {
            toast.error("An error occurred, please try later!");
          }
          this.setState({ isValidAddress: false });
        }
      }
      $(".loading").hide();
    } else {
      $(".loading").show();
      // let valid = this.addressValidation();
      if (valid == "valid") {
        const data = { User_Id: 0, Addresses: [] };
        data.Addresses = this.state.profile.addresses;
        data.User_Id = this.state.uid;
        data.Addresses = [
          ...data.Addresses.map((address) => ({
            ...address,
            isValidAddress: this.state.isValidAddress,
          })),
        ];
        axios
          .post("/api/Member/UpdateMemberAddresses", data)
          .then(({ data }) => {
            if (data.status && data.status == "Success") {
              toast.success("Addresses have been updated.");
              $(".loading").hide();
              $("#addressPopupCloseBtn").click();
              this.setState({
                validateUSPS: true,
              });
              this.loadData(this.state.uid);
            } else {
              $(".loading").hide();
              toast.error(data.message);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        $(".loading").hide();
        toast.error(valid);
      }
    }
  }

  handleFormSubmitEmail(e) {
    e.preventDefault();
    e.target.className += " was-validated";
    if (e.target.checkValidity() === false) {
      return false;
    }
    $(".loading").show();
    let valid = this.emailValidation();
    if (valid == "valid") {
      const data = { User_Id: 0, EmailAddresses: [] };
      data.EmailAddresses = this.state.profile.emailAddresses;
      data.User_Id = this.state.uid;

      axios
        .post("/api/Member/UpdateMemberEmailAddresses", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Email Addresses have been updated.");
            $(".loading").hide();
            $("#emailPopupCloseBtn").click();
            // this.loadData(this.state.uid);
            // UserProfile.OnCancel();
            this.userProfile.current.OnCancel();
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      $(".loading").hide();
      toast.error(valid);
    }
  }
  handleFormSubmitPhone(e) {
    e.preventDefault();
    $(".loading").show();
    let valid = this.phoneValidation();
    if (valid == "valid") {
      const data = { User_Id: 0, Phones: [] };
      data.Phones = this.state.profile.phones;
      data.User_Id = this.state.uid;

      axios
        .post("/api/Member/UpdateMemberPhones", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Phones have been updated.");
            $(".loading").hide();
            $("#phonePopupCloseBtn").click();
            this.loadData(this.state.uid);
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      $(".loading").hide();
      toast.error(valid);
    }
  }
  handleFormSubmitProfile(e) {
    e.preventDefault();
    $(".loading").show();
    let valid = this.profileValidation();
    if (valid == "valid") {
      const data = this.state.profileform;
      data.Id = this.state.uid;
      axios
        .post("/api/Member/UpdateMemberProfile", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            toast.success("Member Profile has been updated.");
            $(".loading").hide();
            $("#memberDetailCancelBtn").click();
            this.loadData(this.state.uid);
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      toast.error(valid);
      $(".loading").hide();
    }
  }
  //Separate Handler for Message Checkbox
  handleTextMessageOnChange = (e) => {
    if (!e.currentTarget.checked) {
      this.updateMemberAllowanceCheckboxes(e);
    }

    this.state.allowance[e.target.name] = e.currentTarget.checked;
    this.setState({ allowance: this.state.allowance });
    var stateMember = this.state.member;
    stateMember[e.target.name] = e.currentTarget.checked;
    this.setState({ member: stateMember });
    // this.setState({ openVerificationModal: true });
  };
  resetTextMessageSubscription = () => {
    this.state.allowance["allowtextmessages"] = false;
    this.setState({ allowance: this.state.allowance });
    var stateMember = this.state.member;
    stateMember["allowtextmessages"] = false;
    this.setState({ member: stateMember });
  };
  handleFacebookOnChange = (e) => {
    if (!e.currentTarget.checked) {
      this.updateMemberAllowanceCheckboxes(e);
    }

    this.state.allowance[e.target.name] = e.currentTarget.checked;
    this.setState({ allowance: this.state.allowance });
    var stateMember = this.state.member;
    stateMember[e.target.name] = e.currentTarget.checked;
    this.setState({ member: stateMember });
    // this.setState({ openVerificationModal: true });
  };
  resetFacebookSubscription = () => {
    this.state.allowance["allowfacebook"] = false;
    this.setState({ allowance: this.state.allowance });
    var stateMember = this.state.member;
    stateMember["allowfacebook"] = false;
    this.setState({ member: stateMember });
  };

  ////////////////////////////Latest Work////////////////////////////////////
  handleChangeAllowance(e) {
    e.preventDefault();
    this.state.allowance[e.target.name] = e.target.checked;
    this.setState({ allowance: this.state.allowance });
    this.updateMemberAllowanceCheckboxes(e);
  }

  updateMemberAllowanceCheckboxes = (e) => {
    $(".loading").show();
    let dataname = e.target.dataset.name;
    try {
      const data = this.state.allowance;
      data.Id = this.state.uid;
      axios
        .post("/api/Member/UpdateMemberAllowance", data)
        .then(({ data }) => {
          if (data.status && data.status == "Success") {
            $(".loading").hide();
            this.loadData(this.state.uid);
          } else {
            $(".loading").hide();
            toast.error(data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      toast.error("An error occured. Please try again later");
      $(".loading").hide();
    }
  };

  detailValidation() {
    let errors = [];
    if (this.state.bargainingunitdate_err != "") {
      this.setState({ bargainingunitdate_err: "Fix the error" });
      errors.push(true);
    }
    if (this.state.natcabargainingunitdate_err != "") {
      this.setState({ natcabargainingunitdate_err: "Fix the error" });
      errors.push(true);
    }
    if (this.state.servicecomputationdate_err != "") {
      this.setState({ servicecomputationdate_err: "Fix the error" });
      errors.push(true);
    }
    if (this.state.joineduniondate_err != "") {
      this.setState({ joineduniondate_err: "Fix the error" });
      errors.push(true);
    }
    if (this.state.entryondutydate_err != "") {
      this.setState({ entryondutydate_err: "Fix the error" });
      errors.push(true);
    }
    return errors.length < 1;
  }

  handleChangeP = (e) => {
    this.state.profile[e.target.name] = e.target.checked;
    this.setState({ profile: this.state.profile });
  };
  handleChangePAddress = (e) => {
    const idx = e.target.parentElement.dataset.id;
    for (var i = 0; i < this.state.profile[e.target.dataset.obj].length; i++) {
      this.state.profile[e.target.dataset.obj][i][e.target.name] = false;
      this.state.profile[e.target.dataset.obj][i][e.target.name + "_err"] = "";
      //this.state[e.target.dataset.obj + "_err"][i][e.target.name + "_err"] = "";
    }
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.checked;
    if (!e.target.checked) {
      //this.state[e.target.dataset.obj + "_err"][idx][e.target.name + "_err"] = "Please assign atleast one address as primary";
      this.state.profile[e.target.dataset.obj][idx][e.target.name + "_err"] =
        "Please assign atleast one address as primary";
    }
    this.setState({ profile: this.state.profile });
  };
  handleChangePEmail = (e) => {
    const idx = e.target.parentElement.dataset.id;
    for (var i = 0; i < this.state.profile[e.target.dataset.obj].length; i++) {
      this.state.profile[e.target.dataset.obj][i][e.target.name] = false;
      this.state.profile[e.target.dataset.obj][i][e.target.name + "_err"] = "";
    }
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.checked;
    if (!e.target.checked) {
      this.state.profile[e.target.dataset.obj][idx][e.target.name + "_err"] =
        "Please assign atleast one " + e.target.dataset.name + " as primary";
    }
    this.setState({ profile: this.state.profile });
  };
  handleSelectChange = (e) => {
    const idx = e.target.dataset.id;
    this.state.profile.addresses[idx][e.target.name] = e.target.value;
    this.setState({ profile: this.state.profile });
  };
  handleSelectChangePhoneType = (e) => {
    const idx = e.target.parentElement.dataset.id;
    this.state.profile[e.target.dataset.obj][idx][e.target.name] =
      e.target.value;
    this.state.profile[e.target.dataset.obj][idx]["phonetype"] = $(e.target)
      .find("option:selected")
      .text();
    this.setState({ profile: this.state.profile });
  };

  handleAddRow = () => {
    const item = {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      isprimary: false,
      addresstypeid: 1,
    };
    const p = this.state.profile;
    p.addresses = [...this.state.profile.addresses, item];
    this.setState({
      profile: p,
    });
    this.handleAddAddressErrorRow();
  };
  handleAddAddressErrorRow = () => {
    const item = {
      address1_err: "",
      city_err: "",
      state_err: "",
      zipcode_err: "",
      isprimary_err: "",
    };
    let address_err = this.state.addresses_err;
    address_err = [...this.state.addresses_err, item];
    this.setState({
      addresses_err: address_err,
    });
  };

  handleRemoveRow = (e) => {
    e.preventDefault();
    const idx = parseInt(e.target.dataset.id);
    this.state.profile.addresses.splice(idx, 1);
    this.state.addresses_err.splice(idx, 1);
    this.setState({
      addresses_err: this.state.addresses_err,
      profile: this.state.profile,
    });
  };

  handleResetProfile = () => {
    const p = this.state.profileform;
    for (let key in p) {
      p[key] = this.state.member[key];
    }
    this.setState({
      profileform: p,
    });
  };
  handleResetProfileAddress = () => {
    // for avoid mutation we have to work like that by creating new fresh object.
    const addresses = [];
    this.state.addresses_err = [];
    this.setState({ addresses_err: this.state.addresses_err });
    for (var i = 0; i < this.state.member.addresses.length; i++) {
      let address = Object.assign({}, this.state.member.addresses[i]);
      addresses.push(address);
      this.handleAddAddressErrorRow();
    }
    this.state.profile.addresses = addresses;
    this.setState({ profile: this.state.profile, validateUSPS: true });
  };

  handleResetProfileEmailAddress = () => {
    // for avoid mutation we have to work like that by creating new fresh object.
    const emailAddresses = [];
    for (var i = 0; i < this.state.member.emailAddresses.length; i++) {
      let emailAddress = Object.assign({}, this.state.member.emailAddresses[i]);
      emailAddresses.push(emailAddress);
    }
    this.state.profile.emailAddresses = emailAddresses;
    this.setState({
      validateUSPS: true,
      profile: this.state.profile,
    });
  };
  handleResetProfilePhones = () => {
    // for avoid mutation we have to work like that by creating new fresh object.
    const phones = [];
    for (var i = 0; i < this.state.member.phones.length; i++) {
      let phone = Object.assign({}, this.state.member.phones[i]);
      phones.push(phone);
    }
    this.state.profile.phones = phones;
    this.setState({ profile: this.state.profile });
  };
  formate_amount = (n) => {
    var num_parts = n.toString().split(".");
    num_parts[0] = "$" + num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
    //return "$" + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  };
  handleAddPhone = () => {
    const item = {
      number: "",
      isprimary: false,
      phonetypeid: this.state.profile.phoneTypes[0].id,
      phonetype: this.state.profile.phoneTypes[0].phonetype,
    };
    const p = this.state.profile;
    p.phones = [...this.state.profile.phones, item];
    this.setState({
      profile: p,
    });
  };
  handleRemovePhone = (e) => {
    const idx = parseInt(e.target.dataset.id);
    this.state.profile.phones.splice(idx, 1);
    this.setState({
      profile: this.state.profile,
    });
  };
  handleAddEmail = () => {
    const item = {
      email: "",
      //email_err: "",
      isprimary: false,
    };
    const p = this.state.profile;
    p.emailAddresses = [...this.state.profile.emailAddresses, item];
    this.setState({
      emailAddresses: p,
    });
  };
  handleRemoveEmail = (e) => {
    const idx = parseInt(e.target.dataset.id);
    this.state.profile.emailAddresses.splice(idx, 1);
    this.setState({
      profile: this.state.profile,
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    //console.log("ROUTE CHANGED");
    this.props.resetLogs();
  }
  async componentDidMount() {
    const { id } = this.props.match.params;
    var _isMyProfile = this.props.location.pathname
      .toLowerCase()
      .includes("myprofile");
    this.setState({ isMyProfile: _isMyProfile });
    await this.setState({ uid: id });
    if (!id) {
      this.props.history.push("/directory/members");
    }

    if (
      AuthService.getProfile().memberData != null &&
      AuthService.getProfile().memberData != undefined &&
      AuthService.getProfile().memberData != ""
    ) {
      this.loggedInUserId = AuthService.getProfile().memberData.id;
    }
    this.getJobs();
    this.loadData(id);
    this.props.resetLogs();
  }
  civicReloadDone() {
    this.setState({ reloadCivic: false });
  }
  async loadData(id) {
    if (id == null || id == "" || id == undefined) {
      id = this.state.uid;
    }
    $(".loading").show();
    let { data } = await axios.get("/api/Member/" + id);
    await this.LoadStates();
    this.setState({ reloadCivic: true });
    if (data.status && data.status.toLowerCase() == "success") {
      const p = this.state.profile;
      const d = this.state.detail;
      const pf = this.state.profileform;
      const a = this.state.allowance;
      // for avoid mutation we have to work like that by creating new fresh object.
      const addresses = [];
      for (var i = 0; i < data.data.addresses.length; i++) {
        let address = Object.assign({}, data.data.addresses[i]);
        addresses.push(address);
        this.handleAddAddressErrorRow();
      }
      const emailAddresses = [];
      for (var i = 0; i < data.data.emailAddresses.length; i++) {
        let emailAddress = Object.assign({}, data.data.emailAddresses[i]);
        emailAddresses.push(emailAddress);
      }
      const phones = [];
      for (var i = 0; i < data.data.phones.length; i++) {
        let phone = Object.assign({}, data.data.phones[i]);
        phones.push(phone);
      }
      //if (_.isArray(data.permissions) && !_.isEmpty(data.permissions)) {
      //  sessionStorage.setItem(
      //    "memberdetailpermissions",
      //    JSON.stringify(data.permissions)
      //  );
      //}
      if (data.data != null) {
        let region =
          data.data.region != null &&
            data.data.region != "" &&
            data.data.region.id != 0
            ? data.data.region.id
            : null;
        let facility =
          data.data.facility != null &&
            data.data.facility != "" &&
            data.data.facility.id != 0
            ? data.data.facility.id
            : null;
        sessionStorage.setItem(
          "memberdetailpermissions",
          JSON.stringify({
            regionId: region,
            facilityId: facility,
            memberId: data.data.id,
          })
        );
      }
      for (let key in a) {
        a[key] = data.data[key];
      }
      for (let key in pf) {
        pf[key] = data.data[key];
      }
      for (let key in p) {
        p[key] = data.data[key];
      }
      for (let key in d) {
        d[key] = data.data[key];
      }
      //From EditEditUserAction
      this.props.setSeniority(d.natcabargainingunitdate, d.bargainingunitdate);
      this.setState({
        profile: p,
        detail: d,
        allowance: a,
        profileform: pf,
        member: data.data,

        positions: data.data.positions,
      });

      this.state.member.addresses = addresses;
      this.state.member.emailAddresses = emailAddresses;
      this.state.member.phones = phones;
      this.setState({ member: this.state.member });
      this.MemberSubscribedEmailList();

      var verifiedPhone = this.state.member.phones.filter(
        (x) => x.isVerified === true
      );

      if (verifiedPhone.length > 0) {
        this.setState({ primaryNumber: verifiedPhone[0].number });
      }
      this.setState({ hasAnyEquipment: data.data.hasAnyEquipment });
      $(".loading").hide();
    } else {
      $(".loading").hide();
      toast.error(data.message);
    }
  }
  componentWillUnmount() {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname !== "/directory/members"
    ) {
      /// this.props.setResetAdvanceFilter(true);// this line is resetting the AF upon navigation to Profile-page from Search List Results
    }
    if (this.props.history.action === "POP") {
      if (this.props.location.backRef) {
        this.props.history.location.uid =
          this.props.location.backRef.teamId || this.props.location.backRef.uid;
        this.props.history.location.backRef = this.props.location.backRef.backRef;
        this.props.history.location.keyword = this.props.location.keyword;
        this.props.history.location.page = this.props.location.page;
      } else {
        this.props.history.location.keyword = this.props.location.keyword;
        this.props.history.location.tab = Users;
        this.props.history.location.showActive = this.props.location.showActive;
        this.props.history.location.name = "Members";
        this.props.history.location.page = this.props.location.page;
      }
    }
    $(".modal-backdrop").length && $(".modal-backdrop").remove();
  }
  componentWillMount() {
    if (this.props.loginpage) {
      let loggeduser = AuthService.getProfile();
      if (loggeduser.memberData != null && AuthService.isAuthenticated()) {
        this.props.UpdateLoginAgain();
      }
    }
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };
  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  };
  render() {
    const {
      member,
      profile,
      detail,
      profileform,
      isPasswordShown,
    } = this.state;
    return (
      <AUX>
        <Directory {...this.props} />
        {!_.isEmpty(this.state.member) && (
          <React.Fragment>
            <div className="container-fluid pt-3 member-container-fluid">
              <div className="row">
                <div className="col-xs-12 col-xl-6">
                  {(AuthService.canOwnView(
                    PermissionFeature.MembersPersonalInfo,
                    member.id
                  ) ||
                    AuthService.canMemberView(
                      PermissionFeature.MembersPersonalInfo
                    )) && (
                      <UserProfile
                        isMyProfile={this.state.isMyProfile}
                        detail={detail}
                        member={member}
                        headerLabel={this.state.headerLabel}
                        profileform={profileform}
                        handleChangeAllowance={this.handleChangeAllowance}
                        handleChangeWallCalendarRequest={this.handleChangeWallCalendarRequest}
                        calculate_age={this.calculate_age}
                        formate_amount={this.formate_amount}
                        handleResetProfile={this.handleResetProfile}
                        changeHandlerPf_text={this.changeHandlerPf_text}
                        changeHandlerShirtSizes={this.changeHandlerShirtSizes}
                        shirtsizes={this.state.shirtsizes}
                        showAllowedContacts={this.showAllowedContacts}
                        changeHandlerGender={this.changeHandlerGender}
                        changeHandlerPf_date={this.changeHandlerPf_date}
                        changeHandlerPf_amount={this.changeHandlerPf_amount}
                        handleFormSubmitProfile={this.handleFormSubmitProfile}
                        changeHandlerVetStatus={this.changeHandlerVetStatus}
                        //For Address Popup
                        memberid={this.state.uid}
                        States={this.state.States}
                        LoadData={this.loadData}
                        profile={this.state.profile}
                        setValidateUSPS={this.setValidateUSPS}
                        setStateError={this.setStateError}
                        validateUSPS={this.state.validateUSPS}
                        changeHandlerAddress={this.changeHandlerAddress}
                        handleChangePAddress={this.handleChangePAddress}
                        handleRemoveRow={this.handleRemoveRow}
                        handleFormSubmitAddresses={this.handleFormSubmitAddresses}
                        handleResetProfileAddress={this.handleResetProfileAddress}
                        handleAddRow={this.handleAddRow}
                        handleSelectChange={this.handleSelectChange}
                        addressTypes={member.addressTypes}
                        //For EmailAddress Popup
                        // emailAddresses={profile.emailAddresses}
                        handleChangeEmail={this.handleChangeEmail}
                        handleChangePEmail={this.handleChangePEmail}
                        handleRemoveEmail={this.handleRemoveEmail}
                        handleFormSubmitEmail={this.handleFormSubmitEmail}
                        handleResetProfileEmailAddress={
                          this.handleResetProfileEmailAddress
                        }
                        handleAddEmail={this.handleAddEmail}
                        //For Phones Popup
                        phones={profile.phones}
                        handleChangePhone={this.handleChangePhone}
                        handleChangePPhone={this.handleChangePEmail}
                        handleRemovePhone={this.handleRemovePhone}
                        handleFormSubmitPhone={this.handleFormSubmitPhone}
                        handleResetProfilePhones={this.handleResetProfilePhones}
                        handleAddPhone={this.handleAddPhone}
                        handleSelectChangePhoneType={
                          this.handleSelectChangePhoneType
                        }
                        phoneTypes={member.phoneTypes}
                        memberPhone={this.state.primaryNumber}
                        allowance={this.state.allowance}
                        handleFacebookOnChange={this.handleFacebookOnChange}
                        resetFacebookSubscription={this.resetFacebookSubscription}
                        handleTextMessageOnChange={this.handleTextMessageOnChange}
                        openVerificationModal={this.state.openVerificationModal}
                        resetTextMessageSubscription={
                          this.resetTextMessageSubscription
                        }
                        ref={this.userProfile}
                      />
                    )}
                </div>
                <div className="col-xs-12 col-xl-6">
                  <div class="row mx-0">
                    {(AuthService.canOwnView(
                      PermissionFeature.MembersAccount,
                      member.id
                    ) ||
                      AuthService.canMemberView(
                        PermissionFeature.MembersAccount
                      )) && (
                        <UserAccount
                          handleChangeUsernameErr={this.handleUsernameErrChange}
                          changeHandlerUsername={this.changeHandlerUsername}
                          handleFormSubmitUsername={this.handleFormSubmitUsername}
                          handleChangIsLocked={this.handleChangIsLocked}
                          handleChangIsEnabled={this.handleChangIsEnabled}
                          handleChangIsVerified={this.handleChangIsVerified}
                          handleChangePwd={this.handleChangePwd}
                          togglePasswordVisiblity={this.togglePasswordVisiblity}
                          username={profile.username}
                          password={profile.password}
                          islocked={profile.islocked}
                          isPasswordShown={isPasswordShown}
                          status={detail.status}
                          isenabled={profile.isenabled}
                          ispassword={profile.ispassword}
                          isusername={profile.isusername}
                          isverified={profile.isverified}
                          password_err={this.state.password_err}
                          username_err={this.state.username_err}
                          handleFormSubmitPassword={this.handleFormSubmitPassword}
                          handleResetUsername={this.handleResetUsername}
                          handleResetPassword={this.handleResetPassword}
                          memberid={this.state.uid}
                        />
                      )}
                    <Membership
                      Membership={{
                        memberType: member.membertypeid,
                        joinUnionDate: detail.joineduniondate,
                        bargainingUnitStatus: detail.status,
                      }}
                      memberId={this.state.uid}
                      reloadData={this.loadData}
                    />
                  </div>

                  {(member.membertypeid == 6 ||
                    member.membertypeid == 8 ||
                    member.membertypeid == 10 ||
                    !this.state.isMyProfile) && (
                      <PayAndSeniority
                        formate_amount={this.formate_amount}
                        detail={detail}
                        refreshData={this.loadData}
                        memberid={this.state.uid}
                      />
                    )}
                </div>
                <div className="col-12">
                  {AuthService.canMTView() &&
                    (AuthService.canOwnView(
                      PermissionFeature.Finance,
                      member.id
                    ) ||
                      AuthService.canMemberView(PermissionFeature.Finance)) && (
                      <Finance member={member} MemberId={member.id} />
                    )}
                </div>

                <div className="col-xs-12 col-xl-6">
                  {AuthService.canMTView() &&
                    <CivicAPI
                      memberid={this.state.uid}
                      reload={this.state.reloadCivic}
                      reloadDone={this.civicReloadDone}
                    />
                  }
                  {AuthService.canMTView() &&
                    (AuthService.canOwnView(
                      PermissionFeature.MembersJobs,
                      member.id
                    ) ||
                      AuthService.canMemberView(
                        PermissionFeature.MembersJobs
                      )) && (
                      <JobsHistory
                        jobs={this.state.jobs}
                        getJobs={this.getJobs}
                        memberid={this.state.uid}
                        loadData={this.loadData}
                      />
                    )}
                  {AuthService.canMTView() &&
                    (AuthService.canOwnView(
                      PermissionFeature.MembersJobs,
                      member.id
                    ) ||
                      AuthService.canMemberView(
                        PermissionFeature.MembersJobs
                      )) && (
                      <Jobs
                        jobs={
                          !_.isEmpty(this.state.jobs) &&
                            !_.isEmpty(
                              this.state.jobs.filter(
                                (item) => item.isbreak != true
                              )
                            )
                            ? this.state.jobs.filter(
                              (item) => item.isbreak != true
                            )
                            : []
                        }
                        getJobs={this.getJobs}
                        memberid={this.state.uid}
                      />
                    )}
                  {AuthService.canMTView() && <MemberProfileRegistrations />}
                </div>
                <div className="col-xs-12 col-xl-6">
                  {AuthService.canMemberView(
                    PermissionFeature.EmailListsLists
                  ) && (
                      <div className="card directory-card border-rounded shadow">
                        <h5 className="card-header mt-0">
                          <i className="mdi mdi-view-list mr-2" />
                          Email List
                        </h5>
                        <div className="card-body pb-3 pt-1">
                          <div className="pb-2 text-right">
                            {this.state.emailHistoryAvail &&
                              (this.state.showEmailHistory ? (
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleShowAllEmails();
                                  }}
                                >
                                  Show Unsubscribed
                                </a>
                              ) : (
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleLessAllEmails();
                                  }}
                                >
                                  Hide Unsubscribed
                                </a>
                              ))}
                          </div>
                          <table className="table table-striped table-hover table-bordered">
                            <thead>
                              <tr>
                                <td>Subscribed List</td>
                                <td>Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {(this.state.emailList ||
                                this.state.emailListDisplayer) &&
                                this.state.emailListDisplayer.map((email) => {
                                  let haveEditPermission = false;
                                  let isOwner = false;
                                  if (
                                    email.ownerId &&
                                    email.ownerId.includes(member.id)
                                  ) {
                                    haveEditPermission = true;
                                    isOwner = true;
                                  } else {
                                    if (
                                      AuthService.canSPUpdate(
                                        PermissionFeature.EmailListsLists,
                                        email.national,
                                        email.regionIds,
                                        email.regionIds
                                      )
                                    ) {
                                      haveEditPermission = true;
                                    } else {
                                      //can assign true
                                      const { id = 0 } = this.props.match.params;
                                      if (this.loggedInUserId == id) {
                                        haveEditPermission = true;
                                        //every user can subscribe/unsubscribe his/her list
                                      }
                                    }
                                  }
                                  let Action = null;
                                  if (haveEditPermission) {
                                    if (email.subscribed) {
                                      Action = (
                                        <button
                                          className="btn btn-danger btnElAction"
                                          onClick={(e) => {
                                            this.preventDefaultCustom(e);
                                            this.toggleConfirm_unsubscribe();
                                            this.setListName(email.email_Address);
                                          }}
                                        >
                                          UnSubscribe
                                        </button>
                                      );
                                    } else {
                                      Action = (
                                        <button
                                          className="btn btn-success btnElAction"
                                          onClick={(e) => {
                                            this.preventDefaultCustom(e);
                                            this.ReSubscribedEmailList(
                                              email.email_Address
                                            );
                                          }}
                                        >
                                          ReSubscribe
                                        </button>
                                      );
                                    }
                                  }
                                  // Doesn't have Edit Permission
                                  else {
                                    if (email.subscribed) {
                                      Action = <label>UnSubscribe</label>;
                                    } else {
                                      Action = <label>ReSubscribe</label>;
                                    }
                                  }

                                  return (
                                    <tr
                                      key={email.id}
                                      className={`${isOwner ? "c-pointer" : ""}`}
                                      onClick={() => {
                                        if (isOwner) {
                                          const {
                                            email_List_Type_Id,
                                            entity_Key,
                                          } = email;
                                          if (
                                            email_List_Type_Id ==
                                            MailGunEmailTypes.Facility
                                          ) {
                                            this.props.history.push(
                                              `/directory/facility/view/${entity_Key}?defaultAccordion=emailList`,
                                              {
                                                emailListDetails: { ...email },
                                              }
                                            );
                                            //**Saved Searches**
                                          } else if (
                                            email_List_Type_Id ==
                                            MailGunEmailTypes.Member
                                          ) {
                                            this.props.history.push(
                                              `/directory/myfilters?showEmailConfigModal=${entity_Key}`,
                                              {
                                                emailListDetails: { ...email },
                                              }
                                            );
                                          } else if (
                                            email_List_Type_Id ==
                                            MailGunEmailTypes.Team
                                          ) {
                                            this.props.history.push(
                                              `/permissions/teams/teamDetail/${entity_Key}?showEmailConfigModal=true`,
                                              {
                                                emailListDetails: { ...email },
                                              }
                                            );
                                          } else if (
                                            email_List_Type_Id ==
                                            MailGunEmailTypes.Registrants
                                          ) {
                                            this.props.history.push(
                                              `/admin/requests?showEmailConfigModal=${entity_Key}`,
                                              {
                                                emailListDetails: { ...email },
                                              }
                                            );
                                          }
                                          // this.props.history.push(
                                          //   `/admin/email-list/view/${email.id}`,
                                          //   {
                                          //     emailListDetails: { ...email },
                                          //   }
                                          // );
                                        }
                                      }}
                                    //console.log("click rowEvents disabled in prod.");
                                    >
                                      <td>
                                        {email.email_Address}{" "}
                                        {isOwner && (
                                          <i className="text-primary fas fa-arrow-circle-right"></i>
                                        )}
                                        {/* //console.log("click rowEvents disabled in prod."); */}
                                      </td>
                                      <td>{Action}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                  {(AuthService.canOwnView(
                    PermissionFeature.MembersPositions,
                    member.id
                  ) ||
                    AuthService.canMemberView(
                      PermissionFeature.MembersPositions
                    )) && <Position MemberId={this.state.uid} />}

                  {(AuthService.canOwnView(
                    PermissionFeature.MembersActivityLogs,
                    member.id
                  ) ||
                    AuthService.canMemberView(
                      PermissionFeature.MembersActivityLogs
                    )) &&
                    (member.membertypeid == 6 ||
                      member.membertypeid == 8 ||
                      member.membertypeid == 10 ||
                      !this.state.isMyProfile) && (
                      <UserActivityLogging paramId={this.props.match.params} />
                    )}
                  {this.state.hasAnyEquipment &&
                    (AuthService.canOwnView(
                      PermissionFeature.FEMemberEquipment,
                      member.id
                    ) ||
                      AuthService.canMemberView(
                        PermissionFeature.FEMemberEquipment
                      )) && (
                      <EquipmentsCardList
                        recordId={this.state.uid}
                        tableType={FE_Equipment_Card_Types.Member}
                      />
                    )}
                </div>
              </div>
            </div>
            <ConfirmDeleteModal confirm={this.confirm} />
            <UnSubscribeModal
              confirm={this.confirm_unsubcribe}
              modal={this.state.isConfirmModalOpen}
              toggle={this.toggleConfirm_unsubscribe}
              listName={this.state.ListName}
            />
            <ReviewPermissions MemberId={this.state.uid} />
          </React.Fragment>
        )}
      </AUX>
    );
  }
}

const mapDispatchtoProps = {
  UpdateLogin: () => (dispatch) =>
    dispatch({ type: actionTypes.LOGINPAGE, value: true }),
  UpdateLoginAgain: () => (dispatch) =>
    dispatch({ type: actionTypes.LOGINPAGE, value: false }),
};

//export default EditUser;
export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    formValues: state.form,
    currentState: state,
    editUserState: state.editUser,
    activityLoggingState: state.activityLogging,
    currentState: state,
    loginpage: state.ui_red.loginpage,
  }),
  {
    ...EditUserAction,
    ...actionFilter,
    ...actionActivity,
    ...mapDispatchtoProps,
    ...actionUserNoti,
  }
)(EditUser);
