import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  ToggleSwitchV2,
  TextField,
  SelectField,
  CheckBoxes,
  RadioButtons,
  TextFieldV2,
  DropDownField,
  Switch,
  StyledCheckBoxes,
  StyledRadioButtons,
} from "../../../Shared/CustomizedForms/FormElements";
import styled from "styled-components";
import { QuestionTypes } from "../../Helpers/LookUpValues";
import RegistrationsService from "../../RegistrationsService";
import _ from "lodash";

/// This Component shows the Attached Questions of Child Template in Step 2
function RegistrationQuestions(props) {
  const getFormElement = (
    idx,
    elementSchema,
    values,
    setValues,
    isDisabled
  ) => {
    const props = {
      name: elementSchema.uid,
      label: elementSchema.label,
      options: elementSchema.questionOptions,
      disabled: isDisabled,
      fee: elementSchema.fee || "0",
      // value: values.questions[idx][elementSchema.uid] || elementSchema.value,
    };
    if (elementSchema.questionType == QuestionTypes.Text) {
      return <TextField {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.DropDown) {
      return <SelectField {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.Switch) {
      return <ToggleSwitchV2 {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.Checkboxes) {
      // return <CheckBoxes {...props} />;
      return <StyledCheckBoxes {...props} />;
    } else if (elementSchema.questionType == QuestionTypes.Radiobuttons) {
      return <StyledRadioButtons {...props} />;
    }
  };

  return (
    <div className="card m-3">
      <div className="row">
        {props.questions &&
          props.values &&
          Object.keys(props.questions).map((key, ind) => (
            <div className="col-md-6 com-sm-12">
              <FieldArray name="questions" key={ind}>
                <div name="form-control" key={ind + 1}>
                  {getFormElement(
                    ind,
                    props.questions[key],
                    props.values,
                    props.setValues,
                    props.isDisabled
                  )}
                </div>
              </FieldArray>
            </div>
          ))}
      </div>
    </div>
  );
}

export default RegistrationQuestions;
const SwitchDiv = styled.div`
  @media (min-width: 576px) {
    padding-left: 2rem;
  }
`;
