import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import React, { Component } from "react";
export default function AuditChangeModal(props) {
  const { modal, toggle, changeLogData } = props;

  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader
        centered={true}
        className="py-2 bg-secondary modal-title mt-0 text-white"
        role="dialog"
        autoFocus={true}
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
      >
        Modified Fields
      </ModalHeader>
      <ModalBody>
        <div style={{ minHeight: "25vh" }}>
          <Table hover responsive size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Field Name</th>
                <th>Value Before</th>
                <th>Value After</th>
              </tr>
            </thead>
            <tbody>
              {changeLogData &&
                changeLogData.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <th scope="row">{idx + 1}</th>
                      <td>{item.FieldName}</td>
                      <td>
                        {item.ValueBefore.includes("12:00:00 AM")
                          ? item.ValueBefore.split(" ")[0]
                          : item.ValueBefore}
                      </td>
                      <td>
                        {item.ValueAfter.includes("12:00:00 AM")
                          ? item.ValueAfter.split(" ")[0]
                          : item.ValueAfter}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
}
