import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import $ from "jquery";
import PayFileOneFields from "./PayFileOneColumns";
import _ from "lodash";
import { connect } from "react-redux";
import payfileActions from "../../store/payfile/action";
import { bindActionCreators } from "redux";
import InputMask from "react-input-mask";

export class PayfileErrorModal extends Component {
  state = {
    valid: false,
    fieldsList: [],
  };
  onShow = () => {
    // debugger;

    if (this.props.errors) var result = _.cloneDeep(this.props.errors);

    this.setState({ fieldsList: result.fieldMessages });
    this.setState({ valid: false });
    var totalErrorsArr = this.props.errors;
    var anyECIError =
      totalErrorsArr &&
      totalErrorsArr["fieldMessages"] &&
      totalErrorsArr["fieldMessages"].some(
        (val) => val.fieldName === "EMPLOYEE_COMMON_ID"
      );
    ////only one error and it is of ECI not found,then don't disable submit action

    if (anyECIError && totalErrorsArr) {
      //For Add the in case of ECI
      result.fieldMessages.push({
        fieldId: 33,
        fieldName: "MEMBERNO",
        fieldValue: "",
        id: -1,
        message: "",
      });
      this.setState({ fieldsList: result.fieldMessages });
      if (totalErrorsArr["fieldMessages"].length == 1) {
        this.setState({ valid: true });
      }
    }
    result &&
      result["fieldMessages"] &&
      result["fieldMessages"].map((v, i, arr) => {
        this.setState({ [`${v.fieldName}`]: v.fieldValue });
      });
  };
  handleClose = () => {
    // debugger;
    this.props.closePayfileErrorModal();
  };
  componentDidMount() {
    this.props.errors &&
      this.props.errors["fieldMessages"] &&
      this.props.errors["fieldMessages"].map((v, i, arr) => {
        this.setState({ [`${v.fieldName}`]: v.fieldName });
      });
  }
  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      [name]: value,
    });
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  onSkipUpdate = () => {
    const formData = {
      importedfileId: this.props.importfileid,
      payFileId: this.props.payfileid,
    };
    this.props.actions.ignoreUpdateError(formData);
    this.props.closePayfileErrorModal();
  };
  validateBtn = () => {
    var result = false;
    var regex = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=  .-]+$/g;
    //if valid is false, disable the btn
    if (!this.state.valid) {
      return true;
    }
    var submissionObj = this.state.fieldsList;
    var anyEmptyVal =
      submissionObj &&
      submissionObj.some(
        (val) =>
          _.isEmpty(val.fieldValue) && val.fieldName !== "EMPLOYEE_COMMON_ID"
      );
    var anySpecialChar =
      submissionObj &&
      submissionObj.some(
        (val) => regex.test(val.fieldValue) || !val.fieldName.includes("NAME_")
      );

    //if any field value empty, disable the btn
    if (anyEmptyVal) {
      return true;
    }
    //if any field value empty, disable the btn
    if (!anySpecialChar) {
      return true;
    }

    return result;
  };

  onProcessUpdate = () => {
    var myself = this;
    if (this.state.valid) {
      const formData = {
        importedfileId: this.props.importfileid,
        payFileId: this.props.payfileid,
        correctedErrorFields: this.state.fieldsList,
      };
      this.props.actions.processUpdateError(formData, myself);
      this.props.closePayfileErrorModal();
    }
  };
  searchTextChangeHandler = (event) => {
    var enteredVal = event.target.name.includes("MEMBERNO")
      ? event.target.value.replaceAll("_", "")
      : event.target.value;
    enteredVal = enteredVal.toString().trim();
    this.setState({
      [event.target.name]: event.target.name.includes("MEMBERNO")
        ? event.target.value.replaceAll("_", "")
        : event.target.value,
    });

    const elementsIndex = this.state.fieldsList.findIndex(
      (f) => f.fieldName === event.target.name
    );
    let newArray = [...this.state.fieldsList];
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      fieldValue: enteredVal,
    };

    this.setState({ fieldsList: newArray });

    // this.setState((prevState) => {
    //   let fieldsList = Object.assign({}, prevState.fieldsList); // creating copy of state variable jasper
    //   fieldsList.fieldMessages = newArray; // update the name property, assign a new value
    //   return { fieldsList }; // return new object jasper object
    // });

    if (event.target.name.includes("FACILITY")) {
      if (
        event.target.value &&
        event.target.value.toString().trim().length === 3
      ) {
        this.setState({
          [event.target.name + "_err"]: "",
        });
        this.setState({ valid: true });
      } else {
        this.setState({
          [event.target.name + "_err"]: "Invalid Facility Code Format.",
        });
        this.setState({ valid: false });
      }
    }
    if (event.target.name.includes("MEMBERNO")) {
      event.target.value = event.target.value.replaceAll("_", "");
      if (event.target.value != "" && event.target.value != null) {
        var regex = /^([0-9]){5}$/;
        if (!regex.test(event.target.value)) {
          this.setState({
            [event.target.name +
            "_err"]: "Enter valid member no. between 10000 - 99999.",
          });
          this.setState({ valid: false });
        } else {
          this.setState({
            [event.target.name + "_err"]: "",
          });
          this.setState({ valid: true });
        }
      } else {
        this.setState({
          [event.target.name + "_err"]: "",
        });
        this.setState({ valid: true });
      }
    }

    ///////////////////////////////END of Setting State///////////////////////////////
    if (event.target.name.includes("DT_") || event.target.name == "SCD") {
      ///Invalid Date error

      let dateRegex = new RegExp(
        "^(0[1-9]|1[012])[-/.](0[1-9]|[12][0-9]|3[01])[-/.](19|20)\\d\\d$"
      );
      if (event.target.value.trim() === "") {
        this.setState({
          [event.target.name + "_err"]: "Required Field",
        });
        this.setState({ valid: false });
      } else if (!dateRegex.test(event.target.value)) {
        this.setState({
          [event.target.name +
          "_err"]: "Please enter Date in correct format mm/dd/yyyy",
        });
        this.setState({ valid: false });
      } else {
        this.setState({ [event.target.name + "_err"]: "" });
        this.setState({ valid: true });
      }
    }

    //////////////////////////END of Date Validation Check////////////////////////////////////

    if (
      event.target.name === "NAME_LAST" ||
      event.target.name === "NAME_FIRST" ||
      event.target.name === "NAME_MIDDLE"
    ) {
      var inputValue = event.target.value.trim();
      if (inputValue !== "") {
        if (
          inputValue.indexOf(" ") > 0 &&
          !(
            /^[a-zA-Z'. -]*$/.test(inputValue.split(" ")[0]) &&
            /^[a-zA-Z'. -]*$/.test(inputValue.split(" ")[1])
          )
        ) {
          /// Contains 2 words &  One of them contains character other than alphabets -- Invalid
          //$("#" + event.target.id).addClass("inputBorderRed");
          $(`.${event.target.name}`).addClass("inputBorderRed");
          this.setState({
            [event.target.name + "_err"]: "Name should contain alphabets only.",
          });
          this.setState({ valid: false });
        } else if (
          !(/^[a-zA-Z-.' ]*$/.test(inputValue) || /^[0-9]*$/.test(inputValue))
        ) {
          /// alphanumeric or special characers with one word
          $(`.${event.target.name}`).addClass("inputBorderRed");
          this.setState({
            [event.target.name + "_err"]: "Alphanumeric is not allowed.",
          });
          this.setState({ valid: false });
        } else {
          $(`.${event.target.name}`).removeClass("inputBorderRed");
          this.setState({ [event.target.name + "_err"]: "" });
          this.setState({ valid: true });
        }
      } else {
        this.setState({ valid: false });
        this.setState({ [event.target.name + "_err"]: "" });
        $(`.${event.target.name}`).removeClass("inputBorderRed");
      }
    }
  };
  render() {
    return (
      <Modal
        className="ErrorReview"
        show={this.props.showModal}
        size="lg"
        backdrop="static"
        centered
        onShow={this.onShow}
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="modal-title mt-0">
              {" "}
              PAY FILE 1 IMPORT -{" "}
              {`${this.props.name} ${this.props.regionFacility}`}{" "}
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <span>
                The following errors were encountered during import and must be
                resolved manually.
              </span>
            </div>
          </div>
          <div className="row mainDivErrors">
            <div className="col">
              {this.props.errors &&
                this.props.errors["fieldMessages"] &&
                this.props.errors["fieldMessages"].map((m) => {
                  return (
                    <>
                      <div className="font-weight-bold col-sm-12 bgFieldMessage mt-1">
                        {m.message}
                      </div>

                      {m.fieldName === "EMPLOYEE_COMMON_ID" ? (
                        /*MEMBERNO*/
                        <div className="col-sm-5">
                          <div className="form-group">
                            <div class="md-form form-group">
                              <InputMask
                                value={this.state[`MEMBERNO`]}
                                name={`MEMBERNO`}
                                onChange={this.searchTextChangeHandler}
                                label={`MEMBER NO`}
                                className={`MEMBERNO form-control`}
                                group
                                type="text"
                                aria-disabled="false"
                                data-type="P"
                                mask="99999"
                                placeholder="Member No."
                              />
                              <label
                                for="MEMBERNO"
                                class=""
                                data-error=""
                                data-success=""
                                id=""
                                aria-labelledby=""
                              />
                              <span className="text-danger">
                                {this.state[`MEMBERNO_err`]}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-sm-5">
                          <div className="form-group">
                            <MDBInput
                              value={this.state[`${m.fieldName}`]}
                              name={`${m.fieldName}`}
                              onChange={this.searchTextChangeHandler}
                              label={`${m.fieldName}`}
                              className={`${m.fieldName}`}
                              group
                              type="text"
                              required
                            >
                              <span className="text-danger">
                                {this.state[`${m.fieldName}_err`]}
                              </span>
                            </MDBInput>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 m-t-20 mx-auto text-center">
            <button
              className="btn btn-success w-md mr-2 mc"
              type="button"
              disabled={this.validateBtn()}
              onClick={this.onProcessUpdate}
            >
              Process Update
            </button>
            {!this.props.isRemovedSection && (
              <button
                className="btn btn-danger w-md waves-effect cancelModal waves-light mc"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={this.onSkipUpdate}
              >
                Ignore Update
              </button>
            )}
            {this.props.isRemovedSection && (
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    payFileState: state.payfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(payfileActions, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayfileErrorModal);
