const actions = {
  SET_PERMISSION_ACTIVE_TAB: "SET_PERMISSION_ACTIVE_TAB",
  OPEN_DEFAULT_PERMISSION_MODAL: "OPEN_DEFAULT_PERMISSION_MODAL",
  CLOSE_DEFAULT_PERMISSION_MODAL: "CLOSE_DEFAULT_PERMISSION_MODAL",
  OPEN_SPECIAL_TEAM_PERMISSION_MODAL: "OPEN_SPECIAL_TEAM_PERMISSION_MODAL",
  CLOSE_SPECIAL_TEAM_PERMISSION_MODAL: "CLOSE_SPECIAL_TEAM_PERMISSION_MODAL",
  SHOW_DEFAULT_PERMISSION_MODAL: "SHOW_DEFAULT_PERMISSION_MODAL",
  COMPLETE_LOAD_DATA_DEFAULT_PERMISSION:
    "COMPLETE_LOAD_DATA_DEFAULT_PERMISSION",
  COMPLETE_LOAD_DATA_OWN_PERMISSION: "COMPLETE_LOAD_DATA_OWN_PERMISSION",
  OPEN_ASSIGN_MEMBER_MODAL: "OPEN_ASSIGN_MEMBER_MODAL",
  CLOSE_ASSIGN_MEMBER_MODAL: "CLOSE_ASSIGN_MEMBER_MODAL",
  OPEN_ASSIGN_MULTI_MEMBER_MODAL: "OPEN_ASSIGN_MULTI_MEMBER_MODAL",
  CLOSE_ASSIGN_MULTI_MEMBER_MODAL: "CLOSE_ASSIGN_MULTI_MEMBER_MODAL",
  OPEN_POSITION_MEMBERS_MODAL: "OPEN_POSITION_MEMBERS_MODAL",
  CLOSE_POSITION_MEMBERS_MODAL: "CLOSE_POSITION_MEMBERS_MODAL",
  OPEN_POSITION_PERMISSION_SCOPE_MODAL: "OPEN_POSITION_PERMISSION_SCOPE_MODAL",
  CLOSE_POSITION_PERMISSION_SCOPE_MODAL:
    "CLOSE_POSITION_PERMISSION_SCOPE_MODAL",
  OPEN_EDIT_POSITION_MODAL: "OPEN_EDIT_POSITION_MODAL",
  CLOSE_EDIT_POSITION_MODAL: "CLOSE_EDIT_POSITION_MODAL",
  HIDE_DEFAULT_PERMISSION_MODAL: "HIDE_DEFAULT_PERMISSION_MODAL",
  SET_DEFAULT_PERMISSION_DATA: "SET_DEFAULT_PERMISSION_DATA",
  SET_USER_OWN_RECORD_DATA: "SET_USER_OWN_RECORD_DATA",
  setPermissionActiveTab: (tab) => (dispatch) => {
    dispatch({
      type: actions.SET_PERMISSION_ACTIVE_TAB,
      payload: tab,
    });
  },
  openAssignMemberModal: () => (dispatch) => {
    dispatch({
      type: actions.OPEN_ASSIGN_MEMBER_MODAL,
    });
  },
  closeAssignMemberModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_ASSIGN_MEMBER_MODAL,
    });
  },
  openAssignMultiMemberModal: () => (dispatch) => {
    dispatch({
      type: actions.OPEN_ASSIGN_MULTI_MEMBER_MODAL,
    });
  },
  closeAssignMultiMemberModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_ASSIGN_MULTI_MEMBER_MODAL,
    });
  },

  openSpecialTeamPermissionModal: () => (dispatch) => {
    dispatch({
      type: actions.OPEN_SPECIAL_TEAM_PERMISSION_MODAL,
    });
  },
  closeSpecialTeamPermissionModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_SPECIAL_TEAM_PERMISSION_MODAL,
    });
  },
  openPositionMembersModal: () => (dispatch) => {
    dispatch({
      type: actions.OPEN_POSITION_MEMBERS_MODAL,
    });
  },
  closePositionMembersModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_POSITION_MEMBERS_MODAL,
    });
  },
  openPositionPSModal: () => (dispatch) => {
    dispatch({
      type: actions.OPEN_POSITION_PERMISSION_SCOPE_MODAL,
    });
  },
  closePositionPSModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_POSITION_PERMISSION_SCOPE_MODAL,
    });
  },
  openEditPositionModal: (editPositionNew) => (dispatch) => {
    dispatch({
      type: actions.OPEN_EDIT_POSITION_MODAL,
      payload: editPositionNew,
    });
  },
  closeEditPositionModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_EDIT_POSITION_MODAL,
    });
  },

  openDefaultPermissionModal: () => (dispatch) => {
    dispatch({
      type: actions.OPEN_DEFAULT_PERMISSION_MODAL,
    });
  },
  completeLoadDataDefaultPermissiion: () => (dispatch) => {
    dispatch({
      type: actions.COMPLETE_LOAD_DATA_DEFAULT_PERMISSION,
    });
  },
  completeLoadDataOwnPermissiion: () => (dispatch) => {
    dispatch({
      type: actions.COMPLETE_LOAD_DATA_OWN_PERMISSION,
    });
  },
  closeDefaultPermissionModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_DEFAULT_PERMISSION_MODAL,
    });
  },
  showDefaultPermissionModal: () => (dispatch) => {
    dispatch({
      type: actions.SHOW_DEFAULT_PERMISSION_MODAL,
    });
  },
  hideDefaultPermissionModal: () => (dispatch) => {
    dispatch({
      type: actions.HIDE_DEFAULT_PERMISSION_MODAL,
    });
  },
  setDefaultPermissionData: (data) => (dispatch) => {
    dispatch({
      type: actions.SET_DEFAULT_PERMISSION_DATA,
      payload: data,
    });
  },
  setUserOwnRecordData: (data) => (dispatch) => {
    dispatch({
      type: actions.SET_USER_OWN_RECORD_DATA,
      payload: data,
    });
  },
};

export default actions;
