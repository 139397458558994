import axios from "../../modules/Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
import FE_Tabs_Type from "../../modules/F&E/FE_Tabs";
import _ from "lodash";
import InventoryRequestStatus from "../../modules/F&E/InventoryRequestStatus";
import FE_AF_Nav_Items from "../../modules/F&E/AdvanceFilter/FE_AF_Nav_Items";
import FE_Tabs from "../../modules/F&E/FE_Tabs";
import InventoryStatus from "../../modules/F&E/InventoryStatus";
import FERequestsType from "../../modules/F&E/FERequestsType";
import FE_AF_SearchType from "../../modules/F&E/FE_AF_SEARCH_TYPE";
import { reset } from "redux-form";
import FE_API_Actions from "../../modules/F&E/FE_API_Actions";

const actions = {
  // SET_LOADING: "SET_LOADING",
  SET_LOADING_FE: "SET_LOADING_FE",
  SET_LOADING_REQ: "SET_LOADING_REQ",
  SET_LIST_SCREEN_DATA_REQ: "SET_LIST_SCREEN_DATA_REQ",
  SET_TOTAL_DATA_COUNT_REQ: "SET_TOTAL_DATA_COUNT_REQ",
  SET_LIST_SCREEN_DATA: "SET_LIST_SCREEN_DATA",
  SET_PAGINATION_INFO_REQ: "SET_PAGINATION_INFO_REQ",
  SET_PAGINATION_INFO_INV: "SET_PAGINATION_INFO_INV",
  OPEN_EQUIPMENT_MODAL: "OPEN_EQUIPMENT_MODAL",
  CLOSE_EQUIPMENT_MODAL: "CLOSE_EQUIPMENT_MODAL",
  RESET_EQUIPMENT_ROW: "RESET_EQUIPMENT_ROW",
  OPEN_FILES_MODAL: "OPEN_FILES_MODAL",
  OPEN_MYREQUEST_MODAL: "OPEN_MYREQUEST_MODAL",
  OPEN_REVIEW_REQUEST_MODAL: "OPEN_REVIEW_REQUEST_MODAL",
  SET_REVIEW_REQUEST_OPEN: "SET_REVIEW_REQUEST_OPEN",
  CLOSE_MYREQUEST_MODAL: "CLOSE_MYREQUEST_MODAL",
  CLOSE_REVIEW_REQUEST_MODAL: "CLOSE_REVIEW_REQUEST_MODAL",
  RESET_MYREQUEST_ROW: "RESET_MYREQUEST_ROW",
  RESET_REVIEW_REQUEST_ROW: "RESET_REVIEW_REQUEST_ROW",
  RESET_EQUIPMENT_ROW: "RESET_EQUIPMENT_ROW",
  CLOSE_FILES_MODAL: "CLOSE_FILES_MODAL",
  DELETE_EQUIPMENT_FILE: "DELETE_EQUIPMENT_FILE",
  SLICE_ATTACHMENTS_FILES: "SLICE_ATTACHMENTS_FILES",
  OPEN_DISPOSE_MODAL: "OPEN_DISPOSE_MODAL",
  CLOSE_DISPOSE_MODAL: "CLOSE_DISPOSE_MODAL",
  CLOSE_EQUIP_DEL_MODAL: "CLOSE_EQUIP_DEL_MODAL",
  OPEN_EQUIP_DEL_MODAL: "OPEN_EQUIP_DEL_MODAL",
  SET_FE_HEADER_ACTIVE_TAB: "SET_FE_HEADER_ACTIVE_TAB",
  TOGGLE_EQUIP_LOG_CARD: "TOGGLE_EQUIP_LOG_CARD",

  //Advance Filter
  OPEN_FE_ADVANCE_FILTER: "OPEN_FE_ADVANCE_FILTER",

  SET_FE_FILTER_DATA: "SET_FE_FILTER_DATA",
  SET_FE_AF_ACTIVE_TAB: "SET_FE_AF_ACTIVE_TAB",
  SET_FE_COLLAPSE_TAB: "SET_FE_COLLAPSE_TAB",
  SET_FE_OPEN_TAB: "SET_FE_OPEN_TAB",
  SHOW_FE_WEB_SIDE_BAR: "SHOW_FE_WEB_SIDE_BAR",
  SHOW_FE_MOBILE_SIDE_BAR: "SHOW_FE_MOBILE_SIDE_BAR",
  SET_FE_FILTER_RESULT: "SET_FE_FILTER_RESULT",
  SET_FE_ADVANCED_FILTER: "SET_FE_ADVANCED_FILTER",
  SET_FE_TEMP_FILTER_DATA: "SET_FE_TEMP_FILTER_DATA",
  SET_FE_RETURN_RESULTSNO: "SET_FE_RETURN_RESULTSNO",
  SET_FE_BASIC_SEARCH_KEYWORD: "SET_FE_BASIC_SEARCH_KEYWORD",
  SET_FE_FILTER_RESULT_PAGE_NO: "SET_FE_FILTER_RESULT_PAGE_NO",
  SET_FE_FILTER_RESULT_PAGE_SIZE: "SET_FE_FILTER_RESULT_PAGE_SIZE",
  SET_FE_FILTER_RESULT_TOTAL_COUNT: "SET_FE_FILTER_RESULT_TOTAL_COUNT",
  SET_FE_ADVANCE_FILTER_LOADNG: "SET_FE_ADVANCE_FILTER_LOADNG",
  SET_FE_NEW_FILTERED_DATA: "SET_FE_NEW_FILTERED_DATA",
  SET_FE_SIDEBAR_STATE: "SET_FE_SIDEBAR_STATE",
  SET_FE_RESET_ADVANCE_FILTER: "SET_FE_RESET_ADVANCE_FILTER",
  SET_FE_FACILITY_CHILD_ACCORDION: "SET_FE_FACILITY_CHILD_ACCORDION",
  SET_FE_LOCAL_PAGING: "SET_FE_LOCAL_PAGING",
  FE_RESET_LOCAL_PAGING: "FE_RESET_LOCAL_PAGING",
  SET_FE_ACCORDIAN_TYPE: "SET_FE_ACCORDIAN_TYPE",
  SET_FE_RESET_ALL: "SET_FE_RESET_ALL",
  SET_FE_AF_FLAG: "SET_FE_AF_FLAG",
  SET_FE_MY_REQ_SHOW_HIS: "SET_FE_MY_REQ_SHOW_HIS",
  RESET_FE_SEARCH_APPLIED: "RESET_FE_SEARCH_APPLIED",
  IS_BUG_APPROVER_PERSON: "IS_BUG_APPROVER_PERSON",
  SET_IS_ACC_PERSON: "SET_IS_ACC_PERSON",
  CHECKING_APPROVER_PERSON: "CHECKING_APPROVER_PERSON",
  SET_FILTER_STATUS_ACTIVE: "SET_FILTER_STATUS_ACTIVE",
  SET_YEARS_FILTER_DATA: "SET_YEARS_FILTER_DATA",
  // BudgetLine
  TOGGLE_BUDGET_LINE_MODAL: "TOGGLE_BUDGET_LINE_MODAL",
  SET_FE_SEARCH_APPLIED: "SET_FE_SEARCH_APPLIED",

  SET_DATA_SIZE_ACTIVE_EQUIP: "SET_DATA_SIZE_ACTIVE_EQUIP",
  SET_DATA_SIZE_INACC_EQUIP: "SET_DATA_SIZE_INACC_EQUIP",
  RESET_PAGINATION: "RESET_PAGINATION",
  toggleBudgetLineModal: () => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_BUDGET_LINE_MODAL,
    });
  },
  setHeaderActiveTab: (tab) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_HEADER_ACTIVE_TAB,
      payload: tab,
    });
  },

  sliceImageFromAttachment: (id) => (dispatch) => {
    dispatch({
      type: actions.SLICE_ATTACHMENTS_FILES,
      payload: id,
    });
  },
  closeFilesModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_FILES_MODAL,
    });
  },
  openFilesModal: (row) => (dispatch) => {
    dispatch({
      type: actions.OPEN_FILES_MODAL,
      payload: row,
    });
  },

  //Equipments
  openEquipmentModal: (row) => (dispatch) => {
    dispatch({
      type: actions.RESET_EQUIPMENT_ROW,
    });
    dispatch({
      type: actions.OPEN_EQUIPMENT_MODAL,
      payload: row,
    });
  },
  closeEquipmentModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_EQUIPMENT_MODAL,
      payload: false,
    });
  },

  resetEquipmentRow: () => (dispatch) => {
    dispatch({
      type: actions.RESET_EQUIPMENT_ROW,
      payload: false,
    });
  },

  openEquipmentDisposeModal: (row) => (dispatch) => {
    dispatch({
      type: actions.OPEN_DISPOSE_MODAL,
      payload: row.id,
    });
  },
  closeEquipmentDisposeModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_DISPOSE_MODAL,
      payload: false,
    });
  },
  closeEquipmentDeleteModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_EQUIP_DEL_MODAL,
      payload: false,
    });
  },
  openEquipmentDeleteModal: (row) => (dispatch) => {
    dispatch({
      type: actions.OPEN_EQUIP_DEL_MODAL,
      payload: row.id,
    });
  },
  //My Requests, event for opening Request from list of My-requests

  ///http:baseURL/FETracker/myRequests Page
  openMyRequestModal: (row) => (dispatch) => {
    if (
      row &&
      (row.status == InventoryRequestStatus.Approved ||
        row.status == InventoryRequestStatus.Closed)
    ) {
      // Open Inventory/Equipment view Modal
      dispatch({
        type: actions.RESET_EQUIPMENT_ROW,
      });
      dispatch({
        type: actions.OPEN_EQUIPMENT_MODAL,
        payload: row,
      });
    } else {
      // Open My Request Modal showing request view Modal
      dispatch({
        type: actions.RESET_MYREQUEST_ROW,
      });
      dispatch({
        type: actions.OPEN_MYREQUEST_MODAL,
        payload: row,
      });
    }
  },
  openEquipmentRelatedRequest: (row) => (dispatch) => {
    dispatch({
      type: actions.RESET_MYREQUEST_ROW,
    });
    dispatch({
      type: actions.OPEN_MYREQUEST_MODAL,
      payload: row,
    });
  },
  closeMyRequestModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_MYREQUEST_MODAL,
      payload: false,
    });
    dispatch({
      type: actions.RESET_MYREQUEST_ROW,
    });
  },
  //Review Requests
  openReviewRequestModal: (row) => (dispatch) => {
    dispatch({
      type: actions.RESET_MYREQUEST_ROW,
    });
    dispatch({
      type: actions.OPEN_MYREQUEST_MODAL,
      payload: row,
    });

    dispatch({
      type: actions.SET_REVIEW_REQUEST_OPEN,
      payload: true,
    });
  },
  closeReviewRequestModal: () => (dispatch) => {
    dispatch({
      type: actions.CLOSE_MYREQUEST_MODAL,
      payload: false,
    });
    dispatch({
      type: actions.RESET_MYREQUEST_ROW,
    });
    dispatch({
      type: actions.SET_REVIEW_REQUEST_OPEN,
      payload: false,
    });
  },

  deleteFile: (mySelf, filePath, fileId) => (dispatch) => {
    //debugger
    dispatch({
      type: actions.DELETE_EQUIPMENT_FILE,
      payload: true,
    });
    //mySelf.props.facilityId
    axios
      .post(`/api/Equipment/RemoveFile?filePath=${filePath}&fileId=${fileId}`)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          mySelf.setState({ currentImage: -1 });
          mySelf.setState({ NonImageFile: "/" });
          mySelf.toggleConfirmModal();

          const allrefs = mySelf.refs;
          const lightBox = allrefs.GallaryLightBox;

          lightBox.closeLightbox();

          var action = actions.sliceImageFromAttachment(filePath);
          dispatch(action);

          toast.success(" Deleted Successfuly.");
        } else {
          toast.error(data.message);
        }
      });
  },
  searchFeInventoryRecords: (formData) => (dispatch) => {
    formData.recordsTypeList.forEach((element) => {
      dispatch({
        type: actions.SET_LOADING_FE,
        payload: { val: true, type: element },
      });
    });

    axios
      .post("/api/Equipment/GetFEInventoryList/", formData)
      .then(({ data }) => {
        if (data && data.status.toLowerCase() == "success") {
          data.data.forEach((element) => {
            dispatch({
              type: actions.SET_LIST_SCREEN_DATA,
              payload: { data: element, type: element.recordType },
            });
          });

          formData.recordsTypeList.forEach((element) => {
            dispatch({
              type: actions.SET_LOADING_FE,
              payload: { val: false, type: element },
            });
          });
          dispatch({
            type: actions.SET_FE_AF_FLAG,
            payload: {
              val: formData.EventType == FE_API_Actions.AdvanceSearch,
              type: FE_AF_SearchType.Inventory,
            },
          });
        } else {
          //Something went wrong
          console.log(data.status);
          formData.recordsTypeList.forEach((element) => {
            dispatch({
              type: actions.SET_LOADING_FE,
              payload: { val: false, type: element },
            });
          });
          dispatch({
            type: actions.SET_FE_AF_FLAG,
            payload: { val: false, type: FE_AF_SearchType.Inventory },
          });
        }
      })
      .catch((error) => {
        console.log("error block", error);
        formData.recordsTypeList.forEach((element) => {
          dispatch({
            type: actions.SET_LOADING_FE,
            payload: { val: false, type: element },
          });
        });
        dispatch({
          type: actions.SET_FE_AF_FLAG,
          payload: { val: false, type: FE_AF_SearchType.Inventory },
        });
      });
  },
  searchFeRequestsRecords: (formData) => (dispatch) => {
    formData.feRequestsTypeList.forEach((element) => {
      dispatch({
        type: actions.SET_LOADING_REQ,
        payload: { val: true, type: element },
      });
    });
    axios
      .post("/api/Equipment/GetFeRequests/", formData)
      .then(({ data }) => {
        if (data && data.status.toLowerCase() == "success") {
          data.data.forEach((element) => {
            dispatch({
              type: actions.SET_LIST_SCREEN_DATA_REQ,
              payload: { data: element, type: element.recordType },
            });
          });
        } else {
          console.log(data.status);
        }

        data.data.forEach((element) => {
          dispatch({
            type: actions.SET_LOADING_REQ,
            payload: { val: false, type: element.recordType },
          });
        });
        dispatch({
          type: actions.SET_FE_AF_FLAG,
          payload: { val: false, type: FE_AF_SearchType.Request },
        });
      })
      .catch((error) => {
        console.log("error block", error);
        formData.feRequestsTypeList.forEach((element) => {
          dispatch({
            type: actions.SET_LOADING_REQ,
            payload: { val: false, type: element },
          });
        });
      });
  },
  setInventoryTablePaginationInfo: (pageNo, sizePerPage, tableType) => (
    dispatch
  ) => {
    var payLoadData = {
      pageNo,
      sizePerPage,
      tableType,
    };
    dispatch({
      type: actions.SET_PAGINATION_INFO_INV,
      payload: payLoadData,
    });
  },
  toggleEquipmentLogAccordion: (val) => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_EQUIP_LOG_CARD,
      payload: val,
    });
  },
  setRequestsTablePaginationInfo: (pageNo, sizePerPage, tableType) => (
    dispatch
  ) => {
    var payLoadData = {
      pageNo,
      sizePerPage,
      tableType,
    };
    dispatch({
      type: actions.SET_PAGINATION_INFO_REQ,
      payload: payLoadData,
    });
  },

  //////////////////////////AdvanceFilter Starting Here/////////////////////////////////////////////
  activeFacilityChildAccordion: (Id) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_FACILITY_CHILD_ACCORDION,
      payload: Id,
    });
  },

  setFilterData: (FilterData) => async (dispatch) => {
    await dispatch({
      type: actions.SET_FE_FILTER_DATA,
      payload: FilterData,
    });
  },
  setFilterResult: (Result) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_FILTER_RESULT,
      payload: Result,
    });
  },
  setTempFilterData: (Result) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_TEMP_FILTER_DATA,
      payload: Result,
    });
  },
  setReturnResultNo: (Result, type) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_RETURN_RESULTSNO,
      payload: { Result, type },
    });
  },

  setCollapseTab: (name) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_COLLAPSE_TAB,
      payload: name,
    });
  },

  setOpenCard: (name) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_OPEN_TAB,
      payload: name,
    });
  },

  showWebSideBar: (val) => (dispatch) => {
    dispatch({
      type: actions.SHOW_FE_WEB_SIDE_BAR,
      payload: val,
    });
  },

  showMobileSideBar: (val) => (dispatch) => {
    dispatch({
      type: actions.SHOW_FE_MOBILE_SIDE_BAR,
      payload: val,
    });
  },

  setResetAdvanceFilter: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_RESET_ADVANCE_FILTER,
      payload: val,
    });
  },

  setAdvancedSearch: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_ADVANCED_FILTER,
      payload: val,
    });
  },

  // Triggers on Apply Filter Btn click
  getMemberTotalCount: (body) => (dispatch, getState) => {
    let { activeSubHeader } = getState().fetracker;
    var formData = {
      ...body,
      activeTab: activeSubHeader,
      isAdvanceSearch: true,
      isTotalCount: true,
    };
    dispatch({
      type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
      payload: true,
    });
    if (activeSubHeader == FE_Tabs_Type.Inventory) {
      axios
        .post("/api/Equipment/GetFEInventoryList/", formData)
        .then(({ data }) => {
          if (data && data.status.toLowerCase() == "success") {
            dispatch({
              type: actions.SET_FE_RETURN_RESULTSNO,
              payload: {
                Result: data.totalCount,
                type: FE_AF_SearchType.Inventory,
              },
            });
          } else {
            console.log(data.status);
          }
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        }) ///END of then
        .catch((error) => {
          console.log("error block", error);
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        });
    } else {
      dispatch({
        type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
        payload: true,
      });
      if (activeSubHeader == FE_Tabs_Type.MyRequests) {
        formData = { isMyRequests: true, ...formData };
      }
      axios
        .post("/api/Equipment/GetFeRequests", formData)
        .then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            dispatch({
              type: actions.SET_FE_RETURN_RESULTSNO,
              payload: {
                Result: data.totalCount,
                type: FE_AF_SearchType.Request,
              },
            });
            dispatch({
              type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
              payload: false,
            });
          } else {
            toast.error(data.message);
            dispatch({
              type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
              payload: false,
            });
          }
        }) ///END of then
        .catch((error) => {
          console.log("error block", error);
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        });
    }
  },
  getResultRowsForAdvanceFilter: (body) => (dispatch, getState) => {
    let {
      activeSubHeader,
      isEVPApprover,
      isBudgetlineApprover,
    } = getState().fetracker;
    var formData = {
      ...body,
      activeTab: activeSubHeader,
      isAdvanceSearch: true,
      isTotalCount: false,
    };
      
    if (activeSubHeader == FE_Tabs_Type.Inventory) {
      var activeSections = [];
      if (body.EventType == FE_API_Actions.TableChange) {
        activeSections = body.recordsTypeList;
      } else if (body.EventType == FE_API_Actions.AdvanceSearch) {
        activeSections.push(InventoryStatus.NonAccReview);
        activeSections.push(InventoryStatus.InAccReview);
      }

      formData.recordsTypeList = activeSections;
      formData.recordsTypeList.forEach((element) => {
        dispatch({
          type: actions.SET_LOADING_FE,
          payload: { val: true, type: element },
        });
      });

      dispatch({
        type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
        payload: true,
      });
      axios
        .post("/api/Equipment/GetFEInventoryList/", formData)
        .then(({ data }) => {
          if (data && data.status.toLowerCase() == "success") {
            data.data.forEach((element) => {
              dispatch({
                type: actions.SET_LIST_SCREEN_DATA,
                payload: { data: element, type: element.recordType },
              });
            });

            formData.recordsTypeList.forEach((element) => {
              dispatch({
                type: actions.SET_LOADING_FE,
                payload: { val: false, type: element },
              });
            });
            dispatch({
              type: actions.OPEN_FE_ADVANCE_FILTER,
              payload: false,
            });
            $("html").removeClass("removeYScroll");
            if (formData.refreshCR) {
              let totalSumRecords = data.data.reduce(
                (pv, cv) => pv + (cv.totalCount || 0),
                0
              );
              dispatch({
                type: actions.SET_FE_RETURN_RESULTSNO,
                payload: {
                  Result: totalSumRecords,
                  type: FE_AF_SearchType.Inventory,
                },
              });
            }
            dispatch({
              type: actions.SET_FE_AF_FLAG,
              payload: { val: true, type: FE_Tabs.Inventory },
            });
            dispatch({
              type: actions.SET_FE_SEARCH_APPLIED,
            });
          } else {
            console.log(data.status);
          }
          //Inside then
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        }) ////END of then
        .catch((error) => {
          console.log("error block", error);
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        });
    } else {
      /// Advance Filter for Requests
      var activeSections = [];
      if (body.EventType == FE_API_Actions.TableChange) {
        activeSections = body.feRequestsTypeList;
      } else if (body.EventType == FE_API_Actions.AdvanceSearch) {
        if (isEVPApprover && !isBudgetlineApprover) {
          // only evp approver
          activeSections.push(FERequestsType.PendingEVPReviewRequests);
        } else if (!isEVPApprover && isBudgetlineApprover) {
          //Only budget approver
          activeSections.push(FERequestsType.SubmittedReviewRequests);
          activeSections.push(FERequestsType.ApprovedReviewRequests);
          activeSections.push(FERequestsType.MyRequests);
          activeSections.push(FERequestsType.PendingEVPReviewRequests);
        } else {
          // Both EVP and Budget Approver
          activeSections.push(FERequestsType.SubmittedReviewRequests);
          activeSections.push(FERequestsType.ApprovedReviewRequests);
          activeSections.push(FERequestsType.MyRequests);
          activeSections.push(FERequestsType.PendingEVPReviewRequests);
        }
      }
      ///Advance filter will search all Requests related Accordions

      formData.feRequestsTypeList = activeSections;

      if (activeSubHeader == FE_Tabs_Type.MyRequests) {
        formData = { isMyRequests: true, ...formData };
      }

      formData.feRequestsTypeList.forEach((element) => {
        dispatch({
          type: actions.SET_LOADING_REQ,
          payload: { val: true, type: element },
        });
      });
      dispatch({
        type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
        payload: true,
      });
      axios
        .post("/api/Equipment/GetFeRequests", formData)
        .then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            data.data.forEach((element) => {
              dispatch({
                type: actions.SET_LIST_SCREEN_DATA_REQ,
                payload: { data: element, type: element.recordType },
              });
            });

            // if (formData.refreshCR) {
            //   let totalSumRecords = data.data.reduce(
            //     (pv, cv) => pv + (cv.totalCount || 0),
            //     0
            //   );
            //   dispatch({
            //     type: actions.SET_FE_RETURN_RESULTSNO,
            //     payload: {
            //       Result: totalSumRecords,
            //       type: FE_AF_SearchType.Request,
            //     },
            //   });
            // }
            dispatch({
              type: actions.OPEN_FE_ADVANCE_FILTER,
              payload: false,
            });
            $("html").removeClass("removeYScroll");
            data.data.forEach((element) => {
              dispatch({
                type: actions.SET_LOADING_REQ,
                payload: { val: false, type: element.recordType },
              });
            });
            dispatch({
              type: actions.SET_FE_AF_FLAG,
              payload: { val: true, type: FE_AF_SearchType.Request },
            });
          } else {
            toast.error(data.message);
          }
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        }) ///END of then
        .catch((error) => {
          console.log("error block", error);
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        });
    }
  },

  toggleFEAdvanceFilter: (param) => (dispatch) => {
    dispatch({
      type: actions.OPEN_FE_ADVANCE_FILTER,
      payload: param,
    });
  },
  setFilterResultPageNo: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_FILTER_RESULT_PAGE_NO,
      payload: val,
    });
  },
  setCurrentAFActiveTab: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_AF_ACTIVE_TAB,
      payload: val,
    });
  },
  getNewFilteredData: (body, isRefresh) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
      payload: true,
    });
    window.innerWidth <= 897 && $(".loading").show();
    axios
      .post(
        `/api/Member/AdvancedFilteredData?getRefreshData=${isRefresh}`,
        body
      )
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          window.innerWidth <= 897 && $(".loading").hide();

          dispatch({
            type: actions.SET_FE_NEW_FILTERED_DATA,
            payload: data,
          });
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        } else {
          toast.error(data.message);
          window.innerWidth <= 897 && $(".loading").hide();
          dispatch({
            type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
            payload: false,
          });
        }
      })
      .catch((error) => {
        console.log("error block", error);
        dispatch({
          type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
          payload: false,
        });
      }); ///END of then
  },

  setAdvanceFilterLoader: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_ADVANCE_FILTER_LOADNG,
      payload: val,
    });
  },

  setSidebarState: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_SIDEBAR_STATE,
      payload: val,
    });
  },

  ResetlocalPaging: () => (dispatch) => {
    dispatch({
      type: actions.FE_RESET_LOCAL_PAGING,
      payload: false,
    });
  },
  setMemberAccordianType: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_ACCORDIAN_TYPE,
      payload: val,
    });
  },
  setFilterResultPageSize: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_FILTER_RESULT_PAGE_SIZE,
      payload: val,
    });
  },
  setFilterResultTotalCount: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_FILTER_RESULT_TOTAL_COUNT,
      payload: val,
    });
  },
  setResetAll: () => (dispatch) => {
    dispatch({
      type: actions.SET_FE_RESET_ALL,
    });
  },
  toggleFEAdvanceSearchFlag: (param) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_AF_FLAG,
      payload: param,
    });
  },
  handleTagsVisibility: () => (dispatch, getState) => {
    let { filterData, activeSubHeader } = getState().fetracker;

    if (activeSubHeader == FE_Tabs_Type.Inventory) {
      if (
        $(".setFETagsPadding").is(":visible") &&
        _.isEmpty(filterData.searchApplied.inventories)
      ) {
        $(".setFETagsPadding").css("display", "none");
      } else if (
        !$(".setFETagsPadding").is(":visible") &&
        !_.isEmpty(filterData.searchApplied.inventories)
      ) {
        $(".setFETagsPadding").show();
      }
      ////For Moblie
      if (
        $(".bottomBorderMobile").is(":visible") &&
        _.isEmpty(filterData.searchApplied.inventories)
      ) {
        $(".bottomBorderMobile").css("display", "none");
      } else if (
        !$(".bottomBorderMobile").is(":visible") &&
        !_.isEmpty(filterData.searchApplied.inventories)
      ) {
        $(".bottomBorderMobile").show();
      }
    } else if (activeSubHeader > FE_Tabs_Type.Inventory) {
      if (
        $(".setFETagsPadding").is(":visible") &&
        _.isEmpty(filterData.searchApplied.requests)
      ) {
        $(".setFETagsPadding").css("display", "none");
      } else if (
        !$(".setFETagsPadding").is(":visible") &&
        !_.isEmpty(filterData.searchApplied.requests)
      ) {
        $(".setFETagsPadding").show();
      }
      ////For Moblie
      if (
        $(".bottomBorderMobile").is(":visible") &&
        _.isEmpty(filterData.searchApplied.requests)
      ) {
        $(".bottomBorderMobile").css("display", "none");
      } else if (
        !$(".bottomBorderMobile").is(":visible") &&
        !_.isEmpty(filterData.searchApplied.requests)
      ) {
        $(".bottomBorderMobile").show();
      }
    }
  },
  getSearchAppliedList: () => (dispatch, getState) => {
    let { filterData, activeSubHeader } = getState().fetracker;

    if (activeSubHeader == FE_Tabs_Type.Inventory) {
      return filterData.searchApplied["inventories"];
    } else if (activeSubHeader > FE_Tabs_Type.Inventory) {
      return filterData.searchApplied["requests"];
    }
  },
  setSearchAppliedList: (param) => (dispatch, getState) => {
    let { filterData, activeSubHeader } = getState().fetracker;

    if (activeSubHeader == FE_Tabs_Type.Inventory) {
      return (filterData.searchApplied["inventories"] = param);
    } else if (activeSubHeader > FE_Tabs_Type.Inventory) {
      return (filterData.searchApplied["requests"] = param);
    }
  },
  resetSearchAppliedList: (param) => (dispatch, getState) => {
    dispatch({
      type: actions.RESET_FE_SEARCH_APPLIED,
      payload: param,
    });
  },
  resetAdvanceFilterTabStateById: (id, optionText) => async (
    dispatch,
    getState
  ) => {
    let { filterData, activeSubHeader } = getState().fetracker;
    let formValues = getState().form;
    let currentFilters = [];
    switch (+id) {
      case FE_AF_Nav_Items.Description:
        filterData.description = null;
        dispatch(reset("fe_req_descriptionForm"));
        break;
      case FE_AF_Nav_Items.Status:
        if (activeSubHeader > FE_Tabs.Inventory) {
          filterData.fe_request_statuses = filterData.fe_request_statuses.map(
            (item) => {
              if (_.isEmpty(optionText)) {
                //means reset btn click
                if (item.status != "Active") {
                  return {
                    ...item,
                    checked: false,
                  };
                } else {
                  return {
                    ...item,
                    checked: true,
                  };
                }
              } else {
                if (item.status === optionText) {
                  return {
                    ...item,
                    checked: false,
                  };
                } else {
                  return {
                    ...item,
                  };
                }
              }
            }
          );
        }

        if (activeSubHeader === FE_Tabs.Inventory) {
          filterData.fe_equipment_statuses = filterData.fe_equipment_statuses.map(
            (item) => {
              if (_.isEmpty(optionText)) {
                //means reset btn click
                if (item.status != "Active") {
                  return {
                    ...item,
                    checked: false,
                  };
                } else {
                  return {
                    ...item,
                    checked: true,
                  };
                }
              } else {
                if (item.status === optionText) {
                  return {
                    ...item,
                    checked: false,
                  };
                } else {
                  return {
                    ...item,
                  };
                }
              }
            }
          );
        }
        break;
      case FE_AF_Nav_Items.Type:
        filterData.fetypes = filterData.fetypes.map((item) => {
          if (_.isEmpty(optionText)) {
            //means reset btn click
            return {
              ...item,
              checked: false,
            };
          } else {
            if (item.type === optionText) {
              return {
                ...item,
                checked: false,
              };
            } else {
              return {
                ...item,
              };
            }
          }
        });

        break;
      case FE_AF_Nav_Items.Budget:
        if (activeSubHeader > FE_Tabs.Inventory) {
          // Requests Tabs
          filterData.fe_request_budgetlines = filterData.fe_request_budgetlines.map(
            (item) => {
              if (_.isEmpty(optionText)) {
                //means reset btn click
                return {
                  ...item,
                  checked: false,
                };
              } else {
                if (item.budgetline === optionText) {
                  return {
                    ...item,
                    checked: false,
                  };
                } else {
                  return {
                    ...item,
                  };
                }
              }
            }
          );
        } else if (activeSubHeader === FE_Tabs.Inventory) {
          // Equipments Tab
          filterData.fe_equipments_budgetlines = filterData.fe_equipments_budgetlines.map(
            (item) => {
              if (_.isEmpty(optionText)) {
                //means reset btn click
                return {
                  ...item,
                  checked: false,
                };
              } else {
                if (item.budgetline === optionText) {
                  return {
                    ...item,
                    checked: false,
                  };
                } else {
                  return {
                    ...item,
                  };
                }
              }
            }
          );
        }

        break;

      case FE_AF_Nav_Items.BookValueRequest: //BookValue Requests
        filterData.ferequests_bookValues = {
          FromValue: formValues.fe_Inv_bookValsForm.values.BookValue.min,
          ToValue: formValues.fe_Inv_bookValsForm.values.BookValue.max,
        };
        dispatch(reset("fe_req_bookValsForm"));
        break;
      case FE_AF_Nav_Items.BookValueInventory: //BookValue Equipments
        filterData.feinv_bookValues = {
          FromValue: formValues.fe_Inv_bookValsForm.values.BookValue.min,
          ToValue: formValues.fe_Inv_bookValsForm.values.BookValue.max,
        };
        dispatch(reset("fe_Inv_bookValsForm"));
        break;
      case FE_AF_Nav_Items.ActualCost: //Actual Costs
        filterData.actualCosts = null;
        dispatch(reset("fe_actualValsForm"));
        break;
      case FE_AF_Nav_Items.Year: //Years
        if (_.isEmpty(optionText)) {
          //means reset btn click
          filterData.years = [new Date().getFullYear().toString()];
          dispatch(reset("FE_INV_YearsForm"));
        } else {
          filterData.years = filterData.years.filter((yr) => yr != optionText);
          let { FE_INV_YearsForm } = formValues;
          let rightOptions = FE_INV_YearsForm.values.inventoryYear.filter(
            (f) => f !== optionText
          );
          formValues.FE_INV_YearsForm.values.inventoryYear = rightOptions;
          // dispatch(change("FE_INV_YearsForm",""));
        }

        break;
      case FE_AF_Nav_Items.PurchaseDate: //Years
        filterData.purchaseDates = null;
        dispatch(reset("purchaseDateForm"));
        break;
      case FE_AF_Nav_Items.StickerNo: //Sticker No
        filterData.stickerNo = null;
        dispatch(reset("FE_INV_StickerNoForm"));
        break;
      //##################REQ-1 Tabs Inventory##############################
      // case FE_AF_Nav_Items.FacilityInventory:
      //   filterData.fe_equipment_facilities = filterData.fe_equipment_facilities.map(
      //     (item) => {
      //       if (_.isEmpty(optionText)) {
      //         //means reset btn click
      //         return { ...item, checked: false };
      //       } else {
      //         if (item.code === optionText) {
      //           return { ...item, checked: false };
      //         } else {
      //           return { ...item };
      //         }
      //       }
      //     }
      //   );
      //   break;
      case FE_AF_Nav_Items.RegionInventory:
        filterData.fe_equipment_regions = filterData.fe_equipment_regions.map(
          (item) => {
            if (_.isEmpty(optionText)) {
              //means reset btn click
              return {
                ...item,
                checked: false,
              };
            } else {
              if (item.code === optionText) {
                return {
                  ...item,
                  checked: false,
                };
              } else {
                return {
                  ...item,
                };
              }
            }
          }
        );
        break;
      case FE_AF_Nav_Items.TeamInventory:
        filterData.fe_equipment_teams = filterData.fe_equipment_teams.map(
          (item) => {
            if (_.isEmpty(optionText)) {
              //means reset btn click
              return {
                ...item,
                checked: false,
              };
            } else {
              if (item.teamName === optionText) {
                return {
                  ...item,
                  checked: false,
                };
              } else {
                return {
                  ...item,
                };
              }
            }
          }
        );

        break;
      case FE_AF_Nav_Items.NAOffice:
        filterData.fe_equipment_naoffices = filterData.fe_equipment_naoffices.map(
          (item) => {
            if (_.isEmpty(optionText)) {
              //means reset btn click
              return {
                ...item,
                checked: false,
              };
            } else {
              if (item.name === optionText) {
                return {
                  ...item,
                  checked: false,
                };
              } else {
                return {
                  ...item,
                };
              }
            }
          }
        );

        break;
      //############################REQ-1 Tabs Requests#####################
      // case FE_AF_Nav_Items.FacilityRequest:
      //   filterData.fe_request_facilities = filterData.fe_request_facilities.map(
      //     (item) => {
      //       if (_.isEmpty(optionText)) {
      //         //means reset btn click
      //         return {
      //           ...item,
      //           checked: false,
      //         };
      //       } else {
      //         if (item.code === optionText) {
      //           return {
      //             ...item,
      //             checked: false,
      //           };
      //         } else {
      //           return {
      //             ...item,
      //           };
      //         }
      //       }
      //     }
      //   );
      //   break;
      case FE_AF_Nav_Items.RegionRequest:
        filterData.fe_request_regions = filterData.fe_request_regions.map(
          (item) => {
            if (_.isEmpty(optionText)) {
              //means reset btn click
              return {
                ...item,
                checked: false,
              };
            } else {
              if (item.code === optionText) {
                return {
                  ...item,
                  checked: false,
                };
              } else {
                return {
                  ...item,
                };
              }
            }
          }
        );
        break;
      case FE_AF_Nav_Items.TeamRequest:
        filterData.fe_request_teams = filterData.fe_request_teams.map(
          (item) => {
            if (_.isEmpty(optionText)) {
              //means reset btn click
              return {
                ...item,
                checked: false,
              };
            } else {
              if (item.teamName === optionText) {
                return {
                  ...item,
                  checked: false,
                };
              } else {
                return {
                  ...item,
                };
              }
            }
          }
        );

        break;
      case FE_AF_Nav_Items.UserInventory: //Name search for Inventory
        filterData.InventoryUserName = null;
        dispatch(reset("fe_Inv_bookValsForm"));
        break;
      case FE_AF_Nav_Items.UserRequest: //Name search for Request
        filterData.RequestUserName = null;
        dispatch(reset("fe_req_NameForm"));
        break;
      default:
        break;
    }
    ///////For Removing Related Selected ***(Tags)***///////////////
    // Don't reset the Status Filter Tag, Other than Active in case Reset button is clicked, optionText would be empty...
    if (id !== 1 && _.isEmpty(optionText)) {
      // Tag is other Than ((Status Tag))
      // Reset Tag => function name=> resetAdvanceFilterTabStateById
      if (activeSubHeader > FE_Tabs.Inventory) {
        currentFilters = filterData.searchApplied.requests.filter((el) => {
          return el.searchType !== id;
        });
        filterData = {
          ...filterData,
          searchApplied: {
            ...filterData.searchApplied,
            requests: currentFilters,
          },
        };
      } else {
        //Remove all Tags of the Selected Search Filter
        currentFilters = filterData.searchApplied.inventories.filter((el) => {
          return el.searchType !== id;
        });
        filterData = {
          ...filterData,
          searchApplied: {
            ...filterData.searchApplied,
            inventories: currentFilters,
          },
        };
      }
      await dispatch({
        type: actions.RESET_FE_SEARCH_APPLIED,
        payload: currentFilters,
      });
    } //Handle Status Filter Tags Removal if id ==1(Status Filter Tag) && optionText !=='Active'
    else if (
      id == 1 &&
      activeSubHeader == FE_Tabs.Inventory &&
      _.isEmpty(optionText)
    ) {
      let statusIndex = filterData.searchApplied.inventories.findIndex((el) => {
        return el.searchType == id;
      });
      // That means "Reset Button Mini Clicked
      if (statusIndex !== -1) {
        filterData.searchApplied.inventories[statusIndex].selectedItems = [
          "Active",
        ];
        currentFilters = filterData.searchApplied.inventories;
        filterData = {
          ...filterData,
          searchApplied: {
            ...filterData.searchApplied,
            inventories: currentFilters,
          },
        };

        dispatch({
          type: actions.RESET_FE_SEARCH_APPLIED,
          payload: currentFilters,
        });
      }
    }
    /////////////////////Getting Updated Count////////////////
    //Non Empty means "Cross Tag" Clicked
    if (!_.isEmpty(optionText)) {
      var action = actions.getMemberTotalCount(filterData);
      dispatch(action);
    }

    /////////////////////////////////////////////////////////////
  },
  setFEShowHistory: (flag, type) => (dispatch) => {
    dispatch({
      type: actions.SET_FE_MY_REQ_SHOW_HIS,
      payload: { val: flag, type },
    });
  },
  setFilterStatusActive: () => async (dispatch) => {
    await dispatch({
      type: actions.SET_FILTER_STATUS_ACTIVE,
    });
  },
  //Used for Redux forms in Advance Filter
  setAFTabFormsById: (id) => (dispatch, getState) => {
    let { filterData } = getState().fetracker;
    let formValues = getState().form;
    switch (id) {
      case FE_AF_Nav_Items.Description: //description
        filterData.description =
          formValues.fe_req_descriptionForm.values.Description;
        break;
      case FE_AF_Nav_Items.BookValueRequest: //BookValue Requests
        filterData.ferequests_bookValues = {
          FromValue: formValues.fe_req_bookValsForm.values.BookValue.min,
          ToValue: formValues.fe_req_bookValsForm.values.BookValue.max,
        };

        break;
      case FE_AF_Nav_Items.BookValueInventory: //BookValue Equipments
        filterData.feinv_bookValues = {
          FromValue: formValues.fe_Inv_bookValsForm.values.BookValue.min,
          ToValue: formValues.fe_Inv_bookValsForm.values.BookValue.max,
        };
        break;
      case FE_AF_Nav_Items.ActualCost: //Actual Costs
        filterData.actualCosts = {
          FromValue: formValues.fe_actualValsForm.values.ActualCost.min,
          ToValue: formValues.fe_actualValsForm.values.ActualCost.max,
        };
        break;
      case FE_AF_Nav_Items.Year: //Years
        filterData.years = formValues.FE_INV_YearsForm.values.inventoryYear;
        break;
      case FE_AF_Nav_Items.PurchaseDate: //Purchase
        filterData.purchaseDates = formValues.purchaseDateForm.values;
        break;
      case FE_AF_Nav_Items.StickerNo: //Sticker No
        filterData.stickerNo = formValues.FE_INV_StickerNoForm.values.StickerNo;
        break;
      case FE_AF_Nav_Items.UserInventory: //Name search for Inventory
        filterData.InventoryUserName = formValues.fe_inv_NameForm.values;
        break;
      case FE_AF_Nav_Items.UserRequest: //Name search for Request
        filterData.RequestUserName = formValues.fe_req_NameForm.values;
        break;

      default:
        break;
    }
  },
  //InFE module Work
  resetALLFilters: () => async (dispatch, getState) => {
    let { filterData, activeSubHeader } = getState().fetracker;

    if (activeSubHeader == FE_AF_SearchType.Inventory) {
      filterData = {
        ...filterData,
        searchApplied: {
          inventories: [
            {
              searchType: 1,
              isApplied: true,
              isSearched: false,
              selectedItems: ["Active"],
            },
          ],
          requests: filterData.searchApplied.requests,
        },
        years: [new Date().getFullYear().toString()],
      };
    } else if (activeSubHeader > FE_AF_SearchType.Inventory) {
      filterData = {
        ...filterData,
        searchApplied: {
          requests: [
            //{
            //  searchType: 1,
            //  isApplied: true,
            //  isSearched: false,
            //  selectedItems: ["Active"],
            //},
          ],
          inventories: filterData.searchApplied.inventories,
        },
      };
    }
    //#####################For Requests realted Tabs####################//////////
    if (
      activeSubHeader === FE_Tabs.MyRequests ||
      activeSubHeader === FE_Tabs.ReviewRequests
    ) {
      filterData.fetypes = filterData.fetypes.map((item) => {
        item.checked = false;
        return item;
      });
      filterData.fe_request_statuses = filterData.fe_request_statuses.map(
        (item) => {
          //if (item.status == "Active") {
          //  item.checked = true;
          //} else {
          item.checked = false;
          //}
          return item;
        }
      );
      filterData.fe_request_budgetlines = filterData.fe_request_budgetlines.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      //######REQ-1 Tabs Requests#########
      // filterData.fe_request_facilities = filterData.fe_request_facilities.map(
      //   (item) => {
      //     item.checked = false;
      //     return item;
      //   }
      // );
      filterData.fe_request_regions = filterData.fe_request_regions.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      filterData.fe_request_teams = filterData.fe_request_teams.map((item) => {
        item.checked = false;
        return item;
      });
      //#########REQ-1 Tabs Requests##########

      dispatch({
        type: actions.SET_FE_AF_FLAG,
        payload: { val: false, type: FE_AF_SearchType.Request },
      });
      filterData.description = null;
      dispatch(reset("fe_req_bookValsForm"));
      dispatch(reset("fe_req_descriptionForm"));
      dispatch(reset("fe_req_NameForm"));
    }

    ///////////#####################For Equipments realted Tabs##################################//////////
    else if (activeSubHeader === FE_Tabs.Inventory) {
      filterData.fe_equipment_statuses = filterData.fe_equipment_statuses.map(
        (item) => {
          if (item.status == "Active") {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        }
      );
      filterData.fe_equipments_budgetlines = filterData.fe_equipments_budgetlines.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );

      //########REQ-1 Tabs Inventory##########
      // filterData.fe_equipment_facilities = filterData.fe_equipment_facilities.map(
      //   (item) => {
      //     item.checked = false;
      //     return item;
      //   }
      // );
      filterData.fe_equipment_regions = filterData.fe_equipment_regions.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      filterData.fe_equipment_teams = filterData.fe_equipment_teams.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      filterData.fe_equipment_naoffices = filterData.fe_equipment_naoffices.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      //#########REQ-1 Tabs Inventory END#############
      dispatch({
        type: actions.SET_FE_AF_FLAG,
        payload: {
          val: false,
          type: FE_AF_SearchType.Inventory,
        },
      });
      dispatch(reset("fe_Inv_bookValsForm"));
      dispatch(reset("fe_actualValsForm"));
      dispatch(reset("FE_INV_YearsForm")); ///Years Filter
      dispatch(reset("purchaseDateForm"));
      dispatch(reset("FE_INV_StickerNoForm"));
      dispatch(reset("fe_inv_NameForm"));
      filterData.actualCosts = null;
      filterData.years = [new Date().getFullYear().toString()];
      filterData.purchaseDates = null;
      filterData.stickerNo = null;
    }
    await dispatch({
      type: actions.SET_FE_FILTER_DATA,
      payload: { ...filterData },
    });

    // var setData = actions.setFilterData(filterData);

    var action = actions.getMemberTotalCount(filterData);
    dispatch(action);
    // if (isAdvancedFilterSelected) {
    //    var data = { totalCount: 0, page: 1, sizePerPage: 25 };
    //    dispatch({
    //      type: actions.REST_TABLE,
    //      payload: data,
    //    });
    // }
    // this.onReturnNo();
    // this.props.handleTagsVisibility();
  },

  checkIsBudgetApprover: () => (dispatch) => {
    dispatch({
      type: actions.CHECKING_APPROVER_PERSON,
      payload: true,
    });
    return axios.get(`/api/Equipment/IsBudgetApprover`).then(({ data }) => {
      if (data.status) {
        if (data.result && data.result.showReviewRequest) {
          dispatch({
            type: actions.IS_BUG_APPROVER_PERSON,
            payload: data.result,
          });
        } else {
          dispatch({
            type: actions.IS_BUG_APPROVER_PERSON,
            payload: false,
          });
        }
        dispatch({
          type: actions.SET_IS_ACC_PERSON,
          payload: data.result.is_accounting_person,
        });
        dispatch({
          type: actions.CHECKING_APPROVER_PERSON,
          payload: false,
        });
      } else {
        dispatch({
          type: actions.CHECKING_APPROVER_PERSON,
          payload: false,
        });

        toast.error(data.message);
      }
    });
  },
  setFEAFSearchApplied: () => (dispatch) => {
    dispatch({
      type: actions.SET_FE_SEARCH_APPLIED,
    });
  },

  setInAccEquipSize: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_DATA_SIZE_ACTIVE_EQUIP,
      payload: val,
    });
  },
  setActiveEquipSize: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_DATA_SIZE_INACC_EQUIP,
      payload: val,
    });
  },
  resetPagination: (val) => (dispatch) => {
    dispatch({
      type: actions.RESET_PAGINATION,
      payload: val,
    });
  },
  setYearsFilterData: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_YEARS_FILTER_DATA,
      payload: val,
    });
  },
};

export default actions;
